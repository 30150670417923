import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, FormControl, MenuItem, Select } from '@material-ui/core';
import ScheduleCardListItemComponent from './ScheduleCardListItemComponent';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { inject, observer } from 'mobx-react';
import { Timeline } from '@material-ui/lab';
import { RoomSortType } from '../../stores/RoomStore';
import { PATH_UTIL } from '../../common/util/path.util';

const styles = _theme => ({
    root: {
        '& ul, ol': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    controlBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    buttonBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 0 auto',
        '& .MuiTabs-indicator': {
            display: 'none',
            width: 0,
        },
        '& button': {
            opacity: 0.5,
            minHeight: 'auto',
            minWidth: 'auto',
            '& .MuiTab-wrapper': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            },
            '& .MuiTab-wrapper > *:first-child': {
                marginBottom: 0,
            },
        },
        '& button:hover': {
            backgroundColor: '#fff',
        },
        '& .Mui-selected': {
            fontWeight: 700,
        },
    },
    btnActiveStyle: {
        opacity: '1!important',
        fontWeight: 700,
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
});

class ClassScheduleListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            situation: RoomSortType.All,
            page: 1,
        };
    }

    componentDidMount() {
        const pathName = window.location.pathname;
        const groupId = PATH_UTIL.getClassId(pathName);
        this.props.roomStore.setRoomRowsPerPage(5);
        this.setState({ page: 1 });
        this.props.roomStore.getRoomListGroupByStartTime(
            groupId,
            this.props.authStore.loginUser.id,
            'today',
            this.state.page,
            undefined,
            undefined,
            this.state.situation,
        );
    }

    componentDidUpdate(prevProps: Readonly<P>, _prevState: Readonly<S>, _snapshot: SS) {
        const { roomStore, authStore } = this.props;

        if (roomStore.roomListGroupByStartTime.length === 0 && this.state.page > 1) {
            this.setState({ page: 1 });
        }

        if (
            prevProps.isScrollEnd !== this.props.isScrollEnd &&
            this.props.isScrollEnd &&
            roomStore.roomTotalCount > roomStore.roomListGroupByStartTime.length
        ) {
            const pathName = window.location.pathname;
            const groupId = PATH_UTIL.getClassId(pathName);
            this.setState({ page: this.state.page + 1 }, () =>
                roomStore.getRoomListGroupByStartTime(
                    groupId,
                    authStore.loginUser.id,
                    'today',
                    this.state.page,
                    undefined,
                    undefined,
                    this.state.situation,
                ),
            );
        }
    }

    handleChangeList = event => {
        if (this.state.situation !== event.target.value) {
            this.props.roomStore.initRoom();
            this.setState({ page: 1 });
            const pathName = window.location.pathname;
            const groupId = PATH_UTIL.getClassId(pathName);
            this.setState({ situation: event.target.value }, () =>
                this.props.roomStore.getRoomListGroupByStartTime(
                    groupId,
                    this.props.authStore.loginUser.id,
                    'today',
                    1,
                    undefined,
                    undefined,
                    this.state.situation,
                ),
            );
        }
    };

    // handleChangeFilter = event => {
    //     this.setState({ filter: event.target.value });
    // };

    // handleListTabChange = (event, value) => {
    //     this.setState({ value });
    // };

    render() {
        const { classes, handleClickDetail, isMember, isLeader } = this.props;
        const { roomListGroupByStartTime, likeLoading, isLoadRoomLoading } = this.props.roomStore;

        return (
            <div className={classes.root}>
                <Box className={classes.controlBox}>
                    {/*<Tabs value={value} onChange={this.handleListTabChange} className={classes.buttonBox}>*/}
                    {/*    <Tab label="리스트" icon={<RowsListIcon />} disableRipple />*/}
                    {/*<Tab label="보드" icon={<SquaresFourIcon />} disableRipple />*/}
                    {/*</Tabs>*/}
                    <FormControl className={classes.formControl}>
                        <Select
                            value={this.state.situation}
                            onChange={this.handleChangeList}
                            displayEmpty
                            name="상황"
                            IconComponent={() => (
                                <Box
                                    style={{
                                        width: 16,
                                        height: 16,
                                        marginLeft: 5,
                                    }}
                                >
                                    <ArrowDownIcon />{' '}
                                </Box>
                            )}
                        >
                            <MenuItem value={RoomSortType.All} className={classes.menuItem}>
                                전체
                            </MenuItem>
                            <MenuItem value={RoomSortType.Live} className={classes.menuItem}>
                                진행중
                            </MenuItem>
                            <MenuItem value={RoomSortType.Future} className={classes.menuItem}>
                                진행예정
                            </MenuItem>
                            <MenuItem value={RoomSortType.Ended} className={classes.menuItem}>
                                종료된
                            </MenuItem>
                        </Select>
                        {/*{value === 1 && (*/}
                        {/*    <Select*/}
                        {/*        value={this.state.filter}*/}
                        {/*        onChange={this.handleChangeFilter}*/}
                        {/*        displayEmpty*/}
                        {/*        name="상황"*/}
                        {/*        className={classes.select}*/}
                        {/*        IconComponent={() => (*/}
                        {/*            <Box*/}
                        {/*                style={{*/}
                        {/*                    width: 16,*/}
                        {/*                    height: 16,*/}
                        {/*                    marginLeft: 5,*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                <ArrowDownIcon />{' '}*/}
                        {/*            </Box>*/}
                        {/*        )}*/}
                        {/*    >*/}
                        {/*        <MenuItem value="최근 등록순" className={classes.menuItem}>*/}
                        {/*            최근 등록순*/}
                        {/*        </MenuItem>*/}
                        {/*        <MenuItem value="참석 일정말" className={classes.menuItem}>*/}
                        {/*            참석 일정만*/}
                        {/*        </MenuItem>*/}
                        {/*    </Select>*/}
                        {/*)}*/}
                    </FormControl>
                </Box>

                <Timeline>
                    {!isLoadRoomLoading ? (
                        <ScheduleCardListItemComponent
                            handleClickDetail={handleClickDetail}
                            loginUser={this.props.authStore.loginUser.id}
                            roomListGroupByStartTime={roomListGroupByStartTime}
                            likeLoading={likeLoading}
                            // isFirstByDate={sameDateList.findIndex(t => t.startDatetime === roomData.startDatetime) === 0}
                            changeLikeState={(groupId, roomId, likeId) =>
                                this.props.roomStore.changeLikeState(this.props.authStore.loginUser, groupId, roomId, likeId)
                            }
                            isMember={isMember}
                            isLeader={isLeader}
                            situation={this.state.situation}
                        />
                    ) : (
                        <Box style={{ textAlign: 'center', marginTop: 100, marginBottom: 1000 }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Timeline>
                {/*{value === 1 && (*/}
                {/*    <div>*/}
                {/*        <ScheduleCardBoardItemComponent roomListGroupByStartTime={roomListGroupByStartTime} />*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
        );
    }
}

export default withStyles(styles)(inject('roomStore', 'authStore', 'classStore')(observer(ClassScheduleListComponent)));
