import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, FormHelperText, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import clsx from 'clsx';
import { ReactComponent as CheckCircleAgreeOffIcon } from '../../common/images/CheckCircleAgreeOffLineIcon.svg';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../common/images/CheckCircleAgreeOnFillIcon.svg';
import { DEFAULT_GUEST_EMAIL_ADDRESS } from '../../stores/ManagementStore';
import { checkLetterNumber } from '../../common/Validation';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 320,
            padding: 20,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 601px)': {
                maxWidth: 665,
                width: 614,
                padding: '24px 24px 24px 35px',
            },
        },
        '& .MuiDialog-paper': {
            '@media all and (max-width: 600px)': {
                margin: 0,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 18,
        '@media all and (max-width: 600px)': {
            marginBottom: 5,
        },
    },
    textStyle: {
        fontSize: '1rem',
        fontWeight: 'normal',
        color: '#333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    textStyle1: {
        fontSize: '1rem',
        fontWeight: 'normal',
        color: '#333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    bold: {
        width: 110,
        fontWeight: 'bold',
        // marginTop: 7,
        '&::after': {
            content: "'*'",
            color: '#ff0000',
        },
        '@media all and (max-width: 600px)': {
            width: 85,
        },
    },
    smartPenTextBold: {
        width: 110,
        fontWeight: 'bold',
        // marginTop: 7,
        '@media all and (max-width: 600px)': {
            width: 85,
        },
    },
    avatar: {
        width: 120,
        height: 120,
        marginBottom: '21px',
        marginLeft: '110px',
        background: '#a3a8af',
        '& svg': {
            width: 72,
            height: 72,
            '& path': {
                fill: '#fff',
            },
        },
    },
    boxMarginBottom: {
        marginBottom: 29,
        display: 'flex',
        alignItems: 'flex-start',
        '@media all and (max-width: 600px)': {
            flexDirection: 'column',
            marginBottom: 10,
        },
    },
    flexBox: {
        display: 'flex',
        alignItems: 'center',
        '@media all and (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    buttonStyle: {
        width: '440px',
        margin: '26px auto 0',
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        padding: '12px 0',
        borderRadius: 7,
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            width: '100%',
            fontSize: '0.75rem',
            padding: '6px 0',
            borderRadius: 4,
            marginTop: 10,
        },
    },
    inputStyle: {
        padding: '8px 10px',
        height: 40,
        borderRadius: 4,
        border: '1px solid #c0c2c3',
        background: '#fff',
        boxSizing: 'border-box',
        '&:focus': {
            outline: 'none',
        },
        '@media all and (max-width: 600px)': {
            padding: '4px 5px',
            height: 25,
            fontSize: '0.75rem',
        },
    },
    emailWidth: {
        width: 130,
    },
    emailAddress: {
        fontSize: '1rem',
        color: '#333',
        margin: '0 20px 0 6px',
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    emailButton: {
        borderRadius: 6,
        width: 132,
        height: 40,
        background: '#fff',
        border: '1px solid #0097ff',
        color: '#0097ff',
        fontSize: '0.813rem',
        fontWeight: 600,
        '&:hover': {
            background: '#fff',
        },
        '&.Mui-disabled': {
            border: '1px solid #c2c2c2',
            background: '#c2c2c2',
            color: '#fff',
            '&:hover': {
                background: '#fff',
            },
        },
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
            width: 186,
            height: 25,
            borderRadius: 4,
            marginLeft: 0,
            marginTop: 10,
        },
    },
    textMarginTop: {
        marginTop: 5,
        display: 'flex',
        alignItems: 'center',
        '@media all and (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    passwordWidth: {
        width: 250,
    },
    numberWidth: {
        width: 32,
        '@media all and (max-width: 600px)': {
            width: 20,
        },
    },
    inputMargin: {
        margin: '0 2px',
    },
    buttonMarginLeft: {
        width: 162,
        marginLeft: 5,
    },
    numberText: {
        fontWeight: 'bold',
        marginRight: 4,
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    checkText: {
        fontSize: '0.75rem',
        color: '#a3a8af',
        margin: '0 7px 0 4px',
    },
    bottomText: {
        fontSize: '0.75rem',
        color: '#707276',
        lineHeight: 1.8,
        marginTop: 5,
    },
    iconSize: {
        width: 14,
        height: 14,
    },
    iconBtn: {
        padding: 0,
        margin: '0 11px',
        '&:hover': {
            background: 'transparent',
        },
    },
    passwordBtn: {
        fontSize: '0.75rem',
        fontWeight: 'normal',
        color: '#0097ff',
        padding: 0,
        justifyContent: 'flex-start',
        textDecoration: 'underline',
        background: '#fff',
        '&:hover': {
            background: '#fff',
            textDecoration: 'underline',
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    errorText: {
        fontSize: '0.813rem',
        color: '#ff0000',
        margin: '4px 0 0',
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
});

class CreateAccountDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            isEmailInputBlur: false,
        };

        this.el = document.getElementById('email');
    }

    handleChangeShowPasswordState = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword,
        }));
    };

    handleChangeSmartPenAddress = (e, index) => {
        console.log('handleChangeSmartPenAddress : >> ', e);
        checkLetterNumber(e);
        this.props.changeNewGuestSmartPenAddress(e.target.value, index);
        if (e.target.value.length === e.target.maxLength) {
            if (e.target.nextElementSibling) {
                e.target.nextElementSibling.focus();
            }
        }
    };

    render() {
        const { classes, validationEmail, validationPenAddress, newGuest, notEmptyNewGuestPenAddress, isAvailableSmartPen } = this.props;
        const isSmartPenAddressEmpty = newGuest.smartPenAddress.join('') === '';
        console.log('validationPenAddress : >> ', validationPenAddress);
        return (
            <Dialog open={this.props.createAccount} className={classes.dialogBox}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" className={classes.titleBox}>
                    <IconButton className={classes.iconButton} onClick={this.props.handleClickCreateAccountClose} disableRipple>
                        <DialogCloseIcon />
                    </IconButton>
                </Box>
                <Box>
                    {/*<Box>*/}
                    {/*    <Avatar className={classes.avatar}>*/}
                    {/*        <AsideUserIcon />*/}
                    {/*    </Avatar>*/}
                    {/*</Box>*/}
                    <Box className={classes.boxMarginBottom}>
                        <Typography className={clsx(classes.textStyle, classes.bold)}>이메일</Typography>
                        <Box>
                            <Box className={classes.flexBox}>
                                <Box display="flex" alignItems="center">
                                    <input
                                        name="email"
                                        type="text"
                                        tabIndex={1}
                                        id="email"
                                        autoFocus={true}
                                        className={clsx(classes.inputStyle, classes.emailWidth)}
                                        value={newGuest.email}
                                        onChange={this.props.changeNewGuestEmail}
                                    />
                                    <Typography className={classes.emailAddress}>{DEFAULT_GUEST_EMAIL_ADDRESS}</Typography>
                                </Box>
                                <Button
                                    className={classes.emailButton}
                                    disableRipple
                                    onClick={this.props.checkExistEmail}
                                    disabled={!validationEmail}
                                >
                                    이메일 중복 체크
                                </Button>
                            </Box>
                            <Box className={classes.textMarginTop}>
                                <Box display="flex" alignItems="center">
                                    {validationEmail ? (
                                        <CheckCircleAgreeOnIcon className={classes.iconSize} />
                                    ) : (
                                        <CheckCircleAgreeOffIcon className={classes.iconSize} />
                                    )}
                                    <Typography className={classes.checkText}>영문자, 점(.), 하이픈(-) 포함 가능</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    {validationEmail ? (
                                        <CheckCircleAgreeOnIcon className={classes.iconSize} />
                                    ) : (
                                        <CheckCircleAgreeOffIcon className={classes.iconSize} />
                                    )}
                                    <Typography className={classes.checkText}>첫 글자는 반드시 문자로 시작</Typography>
                                </Box>
                            </Box>
                            {newGuest.email && !this.props.isAvailableEmail && (
                                <FormHelperText id="component-error-text" className={classes.errorText}>
                                    {'이메일 중복체크를 해주세요.'}
                                </FormHelperText>
                            )}
                        </Box>
                    </Box>

                    <Box className={classes.boxMarginBottom}>
                        <Typography className={clsx(classes.textStyle, classes.bold)}>비밀번호</Typography>
                        <Box>
                            <Typography className={classes.textStyle1}>
                                *초기 비밀번호는 이메일 주소 아이디와 동일합니다.
                                <br />
                                *학생은 로그인 후 비밀번호를 수정할 수 있습니다.
                            </Typography>
                            {/*<Box display="flex" alignItems="center">*/}
                            {/*    <input*/}
                            {/*        name="password"*/}
                            {/*        type={this.state.showPassword ? 'text' : 'password'}*/}
                            {/*        tabIndex={2}*/}
                            {/*        id="password"*/}
                            {/*        // autoFocus={true}*/}
                            {/*        className={clsx(classes.inputStyle, classes.passwordWidth)}*/}
                            {/*        minLength={10}*/}
                            {/*        value={newGuest.password}*/}
                            {/*        onChange={this.props.changeNewGuestPassword}*/}
                            {/*    />*/}
                            {/*    <IconButton id="password" className={classes.iconBtn} onClick={this.handleChangeShoPasswordState} disableRipple>*/}
                            {/*        /!*<EyeIcon />*!/*/}
                            {/*        {this.state.showPassword ? <LoginEyeIcon /> : <LoginEyeSlashIcon />}*/}
                            {/*    </IconButton>*/}
                            {/*    <Button className={classes.passwordBtn} disableRipple onClick={this.props.createRandomPassword}>*/}
                            {/*        자동 생성*/}
                            {/*    </Button>*/}
                            {/*</Box>*/}
                            {/*<Box className={classes.textMarginTop}>*/}
                            {/*    <Box display="flex" alignItems="center" className={classes.textMarginTop}>*/}
                            {/*        <Box display="flex" alignItems="center">*/}
                            {/*            <CheckCircleAgreeOffIcon className={classes.iconSize} />*/}
                            {/*            <Typography className={classes.checkText}>영문, 숫자, 특수문자 중 2가지 이상 조합</Typography>*/}
                            {/*        </Box>*/}
                            {/*        <Box display="flex" alignItems="center">*/}
                            {/*            <CheckCircleAgreeOnIcon className={classes.iconSize} />*/}
                            {/*            <Typography className={classes.checkText}>10자 이상</Typography>*/}
                            {/*        </Box>*/}
                            {/*    </Box>*/}
                            {/*    <Typography className={classes.bottomText}>*/}
                            {/*        *자동 생성을 권장합니다.*/}
                            {/*        <br />*/}
                            {/*        *학생은 로그인 후 비밀번호를 수정할 수 있습니다.*/}
                            {/*    </Typography>*/}
                            {/*</Box>*/}
                        </Box>
                    </Box>

                    <Box className={classes.boxMarginBottom}>
                        <Typography className={clsx(classes.textStyle, classes.bold)}>이름</Typography>
                        <Box>
                            <Box className={classes.flexBox}>
                                <input
                                    name="name"
                                    type="text"
                                    tabIndex={3}
                                    id="name"
                                    maxLength={50}
                                    // autoFocus={true}
                                    placeholder={'이름'}
                                    className={clsx(classes.inputStyle, classes.passwordWidth)}
                                    value={newGuest.name}
                                    onChange={this.props.changeNewGuestName}
                                />
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography className={classes.bottomText}>*최대 50자까지 입력 가능합니다.</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box className={classes.boxMarginBottom}>
                        {/*<Typography className={clsx(classes.textStyle, classes.bold)}>스마트펜 정보</Typography>*/}
                        <Typography className={clsx(classes.textStyle, classes.smartPenTextBold)}>스마트펜 정보</Typography>
                        <Box>
                            <Box className={classes.flexBox}>
                                <Box display="flex" alignItems="center">
                                    <Typography className={classes.numberText}>******</Typography>
                                    {newGuest.smartPenAddress.map((value, index) => (
                                        <input
                                            key={`${index}_${value}`}
                                            name={`number${5 + index}`}
                                            type="text"
                                            tabIndex={4 + index}
                                            id={`number${5 + index}`}
                                            maxLength={1}
                                            // minLength={'1'}
                                            value={value}
                                            autoFocus={isSmartPenAddressEmpty ? index === 0 : value === ''}
                                            className={clsx(classes.inputStyle, classes.numberWidth, classes.inputMargin)}
                                            onChange={e => this.handleChangeSmartPenAddress(e, index)}
                                        />
                                    ))}
                                </Box>
                                <Button
                                    className={clsx(classes.emailButton, classes.buttonMarginLeft)}
                                    disableRipple
                                    disabled={!notEmptyNewGuestPenAddress}
                                    onClick={this.props.checkExistSmartPenAddress}
                                >
                                    정보 중복 체크
                                </Button>
                            </Box>
                            {validationPenAddress && !isAvailableSmartPen && (
                                <FormHelperText id="component-error-text" className={classes.errorText}>
                                    {'스마트펜정보 중복체크를 해주세요.'}
                                </FormHelperText>
                            )}
                        </Box>
                    </Box>
                </Box>

                <Button className={classes.buttonStyle} disableRipple disabled={!this.props.isAvailableSaveGuest} onClick={this.props.createGuest}>
                    저장
                </Button>
            </Dialog>
        );
    }
}

export default withStyles(styles)(CreateAccountDialogComponent);
