import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { ReactComponent as PlayIcon } from '../../common/images/PlayIcon.svg';
import { ReactComponent as NeoPenIconColor } from '../../common/images/NeoPenIconColor.svg';
import { ReactComponent as PencilIcon } from '../../common/images/PencilIcon.svg';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { ReactComponent as CheckCircleAgreeOffIcon } from '../../common/images/CheckCircleAgreeOffIcon.svg';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../common/images/CheckCircleAgreeOnIcon.svg';
import { withRouter } from '../../components/WithRouter';
import { PATH_UTIL } from '../../common/util/path.util';
import { NOTE_CORRECTION_HANDLE_OPERATION } from '../../stores/ClassStore';

const styles = _theme => ({
    root: {
        padding: '0 25px 30px',
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    flexCenter: {
        width: 'calc(100% - 255px)',
        display: 'flex',
        alignItems: 'center',
    },
    titleBox: {
        marginBottom: 10,
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    btnStyle: {
        border: '1px solid #bfbfbf',
        borderRadius: 4,
        marginLeft: 10,
        color: '#000',
        padding: '1px 8px',
        fontWeight: 400,
        fontSize: '0.875rem',
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 4,
            width: 20,
            height: 20,
            '& .neo-pen, & path': {
                fill: '#000',
            },
        },
    },
    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    checkBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#a9adb4',
        fontSize: '0.875rem',
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 4,
        },
    },
    checkBoxStyleOn: {
        color: '#0097FF',
    },
    userStyle: {
        color: '#a3a8af',
        fontSize: '0.813rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            marginRight: 4,
        },
    },
    scrollBox: {
        maxHeight: 380,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '3px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 10,
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 ',
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        color: '#fff',
        fontSize: '1.5rem',
        fontWeight: 600,
        '& svg': {
            width: 25,
            height: 25,
            '& path': {
                fill: '#fff',
            },
        },
    },
    listItemTitle: {
        maxWidth: 'calc(100% - 50px)',
        fontSize: '1rem',
        fontWeight: 600,
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    tagRedStyle: {
        fontSize: '0.625rem',
        color: '#fff',
        backgroundColor: '#fb4a59',
        padding: '3px 4px',
        fontWeight: 700,
    },
    tagGreenStyle: {
        backgroundColor: '#00c880',
    },
    tagTeacherStyle: {
        fontSize: '0.625rem',
        color: '#fff',
        backgroundColor: '#00c880',
        padding: '3px 4px',
        fontWeight: 700,
        marginLeft: 7,
    },
    formControl: {
        minWidth: 100,
        width: 100,
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
});

class RecordInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '최근 작성일 순',
            isFiltered: false,
            writeDataFilter: 'date',
        };
    }

    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        if (this.props.isOpen) {
            const { classStore } = this.props;
            if (classStore.selectedAllGroupUsers.length > 0) {
                classStore.selectedAllGroupUsers.map(user => this.findAvatar(user));
            }
        }
    }

    findAvatar = user => {
        const { avatarStore } = this.props;
        if (!user.image) {
            avatarStore.findAvatar(user.userId, {
                setImage: data => {
                    user.image = data;
                },
            });
        }
    };

    handleChangeCheckBox = () => {
        this.setState({ isFiltered: !this.state.isFiltered });
    };

    handleChangeDataSort = event => {
        this.setState({ writeDataFilter: event.target.value });
    };

    render() {
        const { classes, neoPenStore, classStore, selectedPaperGroup } = this.props;
        const nPS = this.props.neoPenStore;
        const cs = this.props.classStore;

        let hasDataMemberCnt = 0;

        if (nPS.userNotes[selectedPaperGroup.paperGroupId] !== undefined) {
            if (this.state.isFiltered)
                hasDataMemberCnt = nPS.userNotes[selectedPaperGroup.paperGroupId].filter(n => {
                    // if (n.note === undefined || n.note.modifiedIds === undefined) return false;
                    //
                    // return n.note.modifiedIds.length >= 2;
                    const findIndex = cs.paperGroupCorrections.findIndex(correction => (correction.paperId = selectedPaperGroup.id));
                    if (findIndex >= 0) {
                        return n;
                    }
                }).length;
            else hasDataMemberCnt = nPS.userNotes[selectedPaperGroup.paperGroupId].length;
        }

        return (
            <div className={classes.root}>
                <Box className={classes.filterStyle}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                        <Box
                            onClick={this.handleChangeCheckBox}
                            className={!this.state.isFiltered ? classes.checkBoxStyle : clsx(classes.checkBoxStyle, classes.checkBoxStyleOn)}
                        >
                            {!this.state.isFiltered ? <CheckCircleAgreeOffIcon /> : <CheckCircleAgreeOnIcon />}
                            첨삭 업로드 완료만
                        </Box>
                        {/*<FormControl className={classes.formControl}>*/}
                        {/*    <Select*/}
                        {/*        value={this.state.writeDataFilter}*/}
                        {/*        displayEmpty*/}
                        {/*        onChange={this.handleChangeDataSort}*/}
                        {/*        IconComponent={() => (*/}
                        {/*            <Box style={{ width: 16, height: 16, marginLeft: 5 }}>*/}
                        {/*                <ArrowDownIcon />*/}
                        {/*            </Box>*/}
                        {/*        )}*/}
                        {/*    >*/}
                        {/*        <MenuItem value="date" className={classes.menuItem}>*/}
                        {/*            최근 작성일 순*/}
                        {/*        </MenuItem>*/}
                        {/*        <MenuItem value="alpha" className={classes.menuItem}>*/}
                        {/*            가나다 순*/}
                        {/*        </MenuItem>*/}
                        {/*    </Select>*/}
                        {/*</FormControl>*/}
                    </Box>

                    {/*TODO : 사실상 sorting 힘듦 */}
                    {/*<Box>*/}
                    {/*    <FormControl className={classes.formControl}>*/}
                    {/*        <Select*/}
                    {/*            value={this.props.filter}*/}
                    {/*            displayEmpty*/}
                    {/*            onChange={this.props.handleChangeSort}*/}
                    {/*            IconComponent={() => (*/}
                    {/*                <Box style={{ width: 16, height: 16, marginLeft: 5 }}>*/}
                    {/*                    <ArrowDownIcon />{' '}*/}
                    {/*                </Box>*/}
                    {/*            )}*/}
                    {/*        >*/}
                    {/*            <MenuItem value="최근 작성일 순" className={classes.menuItem}>*/}
                    {/*                최근 작성일 순*/}
                    {/*            </MenuItem>*/}
                    {/*            <MenuItem value="최근 등록일 순" className={classes.menuItem}>*/}
                    {/*                최근 등록일 순*/}
                    {/*            </MenuItem>*/}
                    {/*        </Select>*/}
                    {/*    </FormControl>*/}
                    {/*</Box>*/}
                </Box>
                <Box className={classes.filterStyle}>
                    <Box className={classes.userStyle}>
                        <AsideUserIcon />
                        {hasDataMemberCnt}명
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" className={classes.scrollBox}>
                    {classStore.selectedAllGroupUsers.map((user, i) => {
                        const note = nPS.getNote(selectedPaperGroup, user.email);
                        const latestCorrection = cs.getLatestCorrection(selectedPaperGroup, user.userId);
                        console.log('latestCorrections : >> ', latestCorrection);
                        return (
                            <Box key={`${user.userId}_${user.email}_${i}`}>
                                {note !== undefined &&
                                    ((this.state.isFiltered && latestCorrection !== undefined && latestCorrection) || !this.state.isFiltered) && (
                                        <Box key={`${user.userId}_${user.email}_${i}`} className={classes.listItemStyle}>
                                            <Box className={classes.flexCenter}>
                                                <Box className={classes.avatar}>
                                                    <Avatar src={user.image} alt="profile image">
                                                        <AsideUserIcon />
                                                    </Avatar>
                                                </Box>
                                                <Box flexDirection="column" style={{ width: 'calc(100% - 50px)' }}>
                                                    <Box display="flex" alignItems="center" flexDirection="row" style={{ width: '100%' }}>
                                                        <Typography variant="h5" className={classes.listItemTitle}>
                                                            {user.name}
                                                        </Typography>
                                                        {user.type === 'Leader' && <span className={classes.tagTeacherStyle}>교사</span>}
                                                    </Box>
                                                    {note.lastStrokeAt !== null && (
                                                        <Typography variant="body2" className={classes.listItemDate}>
                                                            {`마지막 업로드: ${nPS.convertTimestamp(note.lastStrokeAt).split(',')[0]}`}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                {latestCorrection !== undefined && (
                                                    <span
                                                        className={
                                                            latestCorrection.state.toLowerCase() ===
                                                            NOTE_CORRECTION_HANDLE_OPERATION.Upload.toLowerCase()
                                                                ? classes.tagRedStyle
                                                                : clsx(classes.tagRedStyle, classes.tagGreenStyle)
                                                        }
                                                    >
                                                        첨삭됨
                                                    </span>
                                                )}
                                                <Button
                                                    className={classes.btnStyle}
                                                    onClick={() => {
                                                        neoPenStore.selectUser(user);

                                                        const groupId = PATH_UTIL.getClassId(this.props.location.pathname);

                                                        window.open(
                                                            `/penview/${groupId}/${nPS.selectedUser.email}/${selectedPaperGroup.paperGroupId}`,
                                                        );
                                                    }}
                                                    disableRipple
                                                >
                                                    <PencilIcon /> 필기 확인
                                                </Button>

                                                <Button
                                                    className={classes.btnStyle}
                                                    onClick={() => {
                                                        neoPenStore.selectUser(user);

                                                        const groupId = PATH_UTIL.getClassId(this.props.location.pathname);

                                                        window.open(
                                                            `/penrecordview/${groupId}/${nPS.selectedUser.email}/${selectedPaperGroup.paperGroupId}`,
                                                        );

                                                        // this.props.navigate('/penrecordview');
                                                    }}
                                                    disableRipple
                                                >
                                                    <PlayIcon /> 필기 재생
                                                </Button>
                                            </Box>
                                        </Box>
                                    )}
                            </Box>
                        );
                    })}
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('authStore', 'neoPenStore', 'classStore', 'avatarStore')(observer(RecordInfoComponent))));
