import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Tab, Tabs } from '@material-ui/core';
import { ReactComponent as PlusCircleIcon } from '../../../common/images/PlusCircleIcon.svg';
import YesOrNoDialog from '../../../components/common/YesOrNoDialog';
import { inject, observer } from 'mobx-react';
import { ClassMainPath } from '../../../common/ClassMainPath';
import { GroupJoinRequestPath } from '../../../stores/ClassStore';
import { ToastsStore } from 'react-toasts';
import { withRouter } from '../../../components/WithRouter';
import { PATH_UTIL } from '../../../common/util/path.util';
import ScheduleRegistrationComponent from '../../dialog/ScheduleRegistrationComponent';
import { ReactComponent as BedgeNewIcon } from '../../../common/images/BedgeNewIcon.svg';
import { NOTIFICATION_TYPE } from '../../../stores/NoticeStore';

const styles = theme => ({
    root: {
        '& *': {
            fontFamily: 'NanumSquareRoundOTF!important',
        },
        backgroundColor: '#fff',
        borderBottom: '1px solid #D3D5D5',
    },
    wrap: {
        margin: '0 auto',
        padding: '0px 16px ',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        flexDirection: 'column',
    },
    trigger: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 0 auto',
        width: 'calc(100vw - 32px)',
        '& > div': {
            overflow: 'auto!important',
            minHeight: '40px!important',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        '& button': {
            minWidth: 81,
            minHeight: 32,
            position: 'relative',
            opacity: 1,
            marginRight: 7,
            padding: '3px 10px',
            letterSpacing: '-0.2px',
            '&:hover': {
                fontWeight: 500,
            },
            '&:disabled': {
                opacity: 1,
            },
        },
        '& button.Mui-selected': {
            backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
            color: '#fff',
            fontWeight: 700,
            borderRadius: 50,
            overflow: 'inherit',
        },
        '& .MuiTabs-indicator': {
            display: 'none',
            width: 0,
        },
    },
    btnStyle: {
        backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        color: '#fff',
        borderRadius: 7,
        width: '100%',
        marginBottom: 8,
        '& svg': {
            marginRight: 4,
        },
        '&:hover': {
            fontWeight: 800,
            backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
    emptyBtnStyle: {
        '@media all and (min-width: 1500px)': {
            width: 235,
            marginLeft: 90,
        },
        width: 230,
        marginLeft: 30,
    },
    iconStyle: {
        position: 'absolute',
        right: '-7px',
        top: '-2px',
        zIndex: 10,
    },
    noDisplay: {
        display: 'none',
    },
});

class MobileClassTabTriggerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            openClassJoinConfirmDialog: false,
        };
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (prevProps.isMember !== this.props.isMember) {
            const { loginUser, noticeStore } = this.props;
            const pathName = window.location.pathname;
            const groupId = PATH_UTIL.getClassId(pathName);
            if (this.props.isMember) {
                const type = NOTIFICATION_TYPE.NoteCorrection;
                noticeStore.requestNotReadNotificationsByGroupUser(groupId, loginUser.email, type);
            } else {
                this.props.classStore.checkIsJoinApprove(loginUser.id, groupId);
            }
        }
    }

    handleClickOpen = () => {
        this.props.roomStore.initCreateRoom();
        this.setState({ dialogOpen: true });
    };

    handleClose = () => {
        this.props.handleInitRoom();
        this.setState({ dialogOpen: false });
    };

    handleChange = (_e, value) => {
        this.props.handleChange(value);
        if (this.props.classTab !== value) {
            this.props.roomStore.initRoom();
            this.props.roomStore.initTag();
        }
    };

    handleClickJoinBtn = () => {
        const { isWaitJoinApproval } = this.props.classStore;

        if (isWaitJoinApproval) {
            const { loginUser } = this.props.authStore;
            this.props.classStore.requestCancelGroupJoin(loginUser.email, {
                alert: () => {
                    ToastsStore.warning('잘못된 요청입니다. 클래스 정보를 확인해주세요.', 1500);
                },
            });
        } else {
            this.setState({ openClassJoinConfirmDialog: true });
        }
    };

    render() {
        const { classes, classTab, isMember, isLeader } = this.props;
        const { groupUserAuthority, isWaitJoinApproval, isCheckJoinApprovalLoading, isInitializingGroupDetail } = this.props.classStore;
        const { hasNotReadNoteCorrections } = this.props.noticeStore;

        return (
            <div className={classes.root}>
                <Box className={classes.wrap}>
                    <Tabs value={classTab} onChange={this.handleChange} className={classes.trigger}>
                        <Tab
                            label={'필기 수업'}
                            value={ClassMainPath.penRecord}
                            style={!isMember ? { display: 'none' } : { position: 'relative' }}
                            // disabled={classTab === ClassMainPath.penRecord}
                            disableRipple
                            icon={<BedgeNewIcon className={hasNotReadNoteCorrections ? classes.iconStyle : classes.noDisplay} />}
                        />
                        <Tab
                            label="오늘 수업"
                            value={ClassMainPath.todaySchedule}
                            // disabled={classTab === ClassMainPath.todaySchedule}
                            disableRipple
                            // icon={<BedgeNewIcon className={classes.iconStyle} />}
                            style={{ display: 'none' }}
                        />
                        <Tab
                            label="화상 수업"
                            value={ClassMainPath.allSchedule}
                            // disabled={classTab === ClassMainPath.allSchedule}
                            disableRipple
                        />
                        <Tab
                            label="캘린더"
                            value={ClassMainPath.calendarSchedule}
                            // disabled={classTab === ClassMainPath.calendarSchedule}
                            disableRipple
                            style={{ display: 'none' }}
                        />
                        <Tab
                            label="자료실"
                            value={ClassMainPath.referenceRoom}
                            // disabled={classTab === ClassMainPath.referenceRoom}
                            disableRipple
                            style={!isMember ? { display: 'none' } : null}
                            // icon={<BedgeNewIcon className={classes.iconStyle} />}
                        />
                        <Tab
                            label="커뮤니티"
                            value={ClassMainPath.community}
                            // disabled={classTab === ClassMainPath.community}
                            disableRipple
                        />
                        <Tab
                            label="멤버"
                            value={ClassMainPath.member}
                            // disabled={classTab === ClassMainPath.member}
                            disableRipple
                        />
                        <Tab
                            label="설정"
                            value={ClassMainPath.setting}
                            // disabled={classTab === ClassMainPath.setting}
                            disableRipple
                            style={{ display: 'none' }}
                        />
                        <Tab
                            label="일정 상세"
                            value={ClassMainPath.scheduleDetail}
                            // disabled={classTab === ClassMainPath.scheduleDetail}
                            disableRipple
                            style={{ display: 'none' }}
                        />
                        <Tab
                            label="게시글 상세"
                            value={ClassMainPath.postDetail}
                            // disabled={classTab === ClassMainPath.postDetail}
                            disableRipple
                            style={{ display: 'none' }}
                        />
                        <Tab
                            label="태그"
                            value={ClassMainPath.tag}
                            // disabled={classTab === ClassMainPath.tag}
                            disableRipple
                            style={{ display: 'none' }}
                        />
                        <Tab
                            label="검색"
                            value={ClassMainPath.search}
                            // disabled={classTab === ClassMainPath.tag}
                            disableRipple
                            style={{ display: 'none' }}
                        />
                    </Tabs>

                    {isMember === undefined || isInitializingGroupDetail || isCheckJoinApprovalLoading ? (
                        <div className={classes.emptyBtnStyle} disableripple="true"></div>
                    ) : isMember && classTab === ClassMainPath.allSchedule && groupUserAuthority.RoomCreate ? (
                        <Button className={classes.btnStyle} onClick={this.handleClickOpen} disableripple="true">
                            <PlusCircleIcon /> 화상수업 개설하기
                        </Button>
                    ) : !isMember ? (
                        <Button className={classes.btnStyle} disableripple="true" onClick={this.handleClickJoinBtn}>
                            <PlusCircleIcon /> {isWaitJoinApproval ? '가입 요청 취소' : '클래스 가입하기'}
                        </Button>
                    ) : (
                        <div className={classes.emptyBtnStyle} disableripple="true"></div>
                    )}
                </Box>

                <ScheduleRegistrationComponent
                    handleClose={this.handleClose}
                    dialogOpen={this.state.dialogOpen}
                    classTab={this.props.classTab}
                    scrollMove={this.props.scrollMove}
                />

                <YesOrNoDialog
                    open={this.state.openClassJoinConfirmDialog}
                    title={'클래스 가입'}
                    msg={'해당 클래스에 가입하시겠습니까?'}
                    changeDialogOpen={() => this.setState({ openClassJoinConfirmDialog: false })}
                    submit={() =>
                        this.props.classStore.joinGroup(this.props.authStore.loginUser.id, GroupJoinRequestPath.Search, {
                            requestGroupDetailAndCheckMember: groupId => {
                                this.props.requestGroupDetail(groupId);
                            },
                            alert: () => {
                                ToastsStore.info('가입 요청이 승인대기 중입니다.', 3000, classes.toasts);
                            },
                        })
                    }
                />
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('authStore', 'classStore', 'roomStore', 'noticeStore')(observer(MobileClassTabTriggerComponent))),
);
