import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MobileClassNoticeComponent from './MobileClassNoticeComponent';
import MobileScheduleCardListItemComponent from './MobileScheduleCardListItemComponent';
import { Box, CircularProgress, FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from '../../../common/images/ArrowDownIcon.svg';
import { inject, observer } from 'mobx-react';
import dayjs from 'dayjs';
import { DATE_UTIL } from '../../../common/util/date.util';
import { PATH_UTIL } from '../../../common/util/path.util';
import { loadingSate, RoomSortType } from '../../../stores/RoomStore';
import { UTIL } from '../../../common/util/common.util';

const styles = _theme => ({
    root: {
        width: 'calc(100vw - 32px)',
        paddingBottom: 70,
    },
    controlBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 7,
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.813rem',
        color: '#0d0d0d',
        minHeight: 40,
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    datePickers: {
        fontFamily: 'NanumSquareRoundOTF',
        display: 'flex',
        alignItems: 'center',
        '& input': {
            padding: 5,
            fontSize: '0.813rem',
            '@media (min-width: 319px) and (max-width: 374px)': {
                paddingRight: 0,
                fontSize: '0.75rem',
            },
        },
        '& .Mui-focused': {
            borderColor: '#0097FF',
        },
    },
    marginAuto: {
        margin: '0 10px',
        '@media (min-width: 319px) and (max-width: 374px)': {
            margin: 0,
        },
    },
});

class MobileFullScheduleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            situation: RoomSortType.All,
            scheduleDetail: false,
            startDatetime: DATE_UTIL.getTimeZoneDate(dayjs().add(-1, 'week'), DATE_UTIL.FORMAT.DATE_FORMAT),
            endDatetime: DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT),
            page: 1,
        };
    }

    componentDidMount() {
        const pathName = window.location.pathname;
        const path = PATH_UTIL.getClassId(pathName);
        this.setState({ page: 1 });
        const convertingStartDatetime = new Date(this.state.startDatetime).setHours(0, 0, 0);

        this.props.roomStore.getRoomListGroupByStartTime(
            path,
            this.props.authStore.loginUser.id,
            'today',
            1,
            convertingStartDatetime,
            this.state.endDatetime,
            this.state.situation,
        );
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        const { roomStore, authStore } = this.props;
        const isCreateNewRoom = prevProps.createRoomLoading !== this.props.createRoomLoading && prevProps.createRoomLoading === loadingSate.PENDING;

        if (roomStore.roomListGroupByStartTime.length === 0 && this.state.page > 1) {
            this.setState({ page: 1 });
        }

        if (isCreateNewRoom) {
            this.handleInitRoomsPage();
        }

        if (
            prevProps.isScrollEnd !== this.props.isScrollEnd &&
            this.props.isScrollEnd &&
            roomStore.roomTotalCount > roomStore.roomListGroupByStartTime.length
        ) {
            const pathName = window.location.pathname;
            const groupId = PATH_UTIL.getClassId(pathName);
            const convertingStartDatetime = new Date(this.state.startDatetime).setHours(0, 0, 0);
            this.setState({ page: this.state.page + 1 }, () =>
                roomStore.getRoomListGroupByStartTime(
                    groupId,
                    authStore.loginUser.id,
                    'today',
                    this.state.page,
                    convertingStartDatetime,
                    this.state.endDatetime,
                    this.state.situation,
                ),
            );
        }
    }

    handleInitRoomsPage = () => {
        this.setState({ page: 1 }, () => {
            this.fetchRoomListGroupByDateTime();
        });
    };

    fetchRoomListGroupByDateTime = (startDatetime = this.state.startDatetime, endDatetime = this.state.endDatetime) => {
        const { selectedGroupId } = this.props.classStore;
        const { loginUser } = this.props.authStore;
        const convertingStartDatetime = new Date(startDatetime).setHours(0, 0, 0);

        this.props.roomStore.initRoom();

        this.props.roomStore.getRoomListGroupByStartTime(
            selectedGroupId,
            loginUser.id,
            'today',
            this.state.page,
            convertingStartDatetime,
            endDatetime,
            this.state.situation,
            undefined,
            this.state.order,
        );
    };

    handleChange = event => {
        if (this.state.situation !== event.target.value) {
            this.props.roomStore.initRoom();
            this.setState({ page: 1 });
            const pathName = window.location.pathname;
            const groupId = PATH_UTIL.getClassId(pathName);
            const convertingStartDatetime = new Date(this.state.startDatetime).setHours(0, 0, 0);
            this.setState({ situation: event.target.value }, () =>
                this.props.roomStore.getRoomListGroupByStartTime(
                    groupId,
                    this.props.authStore.loginUser.id,
                    'today',
                    1,
                    convertingStartDatetime,
                    this.state.endDatetime,
                    this.state.situation,
                ),
            );
        }
    };

    handleClickDetail = roomId => {
        this.props.handleClickDetail(roomId);
    };

    fetchRoomListGroupByStartTime = () => {
        const { selectedGroupDetail } = this.props.classStore;
        const { loginUser } = this.props.authStore;
        this.props.roomStore.initRoom();
        this.props.roomStore.getRoomListGroupByStartTime(
            selectedGroupDetail.group.id,
            loginUser.id,
            'today',
            1,
            this.state.startDatetime,
            this.state.endDatetime,
            this.state.situation,
        );
    };

    fetchRoomListGroupByEndTime = () => {
        const { selectedGroupDetail } = this.props.classStore;
        const { loginUser } = this.props.authStore;
        this.props.roomStore.initRoom();
        this.props.roomStore.getRoomListGroupByStartTime(
            selectedGroupDetail.group.id,
            loginUser.id,
            'today',
            1,
            this.state.startDatetime,
            this.state.endDatetime,
            this.state.situation,
        );
    };

    handleChangeStartTime = e => {
        this.setState({ startDatetime: e.target.value, page: 1 });
        if (dayjs(this.state.endDatetime).isBefore(dayjs(e.target.value))) {
            this.setState({ endDatetime: e.target.value });
        }

        UTIL.debouncedFunc(this.fetchRoomListGroupByStartTime, 1000);
    };

    handleChangeEndTime = e => {
        this.setState({ endDatetime: e.target.value, page: 1 });
        this.props.roomStore.initRoom();
        if (dayjs(e.target.value).isBefore(dayjs(this.state.startDatetime))) {
            this.setState({ startDatetime: e.target.value });
        }

        UTIL.debouncedFunc(this.fetchRoomListGroupByEndTime, 1000);
    };

    render() {
        const { classes, isMember, isLeader, classPath } = this.props;
        const { roomListGroupByStartTime, likeLoading, isLoadRoomLoading } = this.props.roomStore;

        return (
            <div className={classes.root}>
                <MobileClassNoticeComponent isMember={isMember} classPath={classPath} />
                <Box className={classes.controlBox}>
                    <Box className={classes.datePickers}>
                        <form className={classes.container} noValidate>
                            <TextField
                                id="start-date"
                                // label="일정날짜"
                                type="date"
                                value={this.state.startDatetime}
                                className={classes.textField}
                                variant="outlined"
                                onChange={e => this.handleChangeStartTime(e)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </form>
                        <span className={classes.marginAuto}>~</span>
                        <form className={classes.container} noValidate>
                            <TextField
                                id="end-date"
                                // label="일정날짜"
                                type="date"
                                value={this.state.endDatetime}
                                className={classes.textField}
                                variant="outlined"
                                onChange={e => this.handleChangeEndTime(e)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </form>
                    </Box>

                    <FormControl className={classes.formControl}>
                        <Select
                            value={this.state.situation}
                            onChange={this.handleChange}
                            displayEmpty
                            className={classes.select}
                            IconComponent={() => (
                                <Box
                                    style={{
                                        width: 16,
                                        height: 16,
                                        marginLeft: 5,
                                    }}
                                >
                                    <ArrowDownIcon />{' '}
                                </Box>
                            )}
                        >
                            <MenuItem value={RoomSortType.All} className={classes.menuItem}>
                                전체
                            </MenuItem>
                            <MenuItem value={RoomSortType.Live} className={classes.menuItem}>
                                진행중
                            </MenuItem>
                            <MenuItem value={RoomSortType.Future} className={classes.menuItem}>
                                진행예정
                            </MenuItem>
                            <MenuItem value={RoomSortType.Ended} className={classes.menuItem}>
                                종료된
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {!isLoadRoomLoading ? (
                    <MobileScheduleCardListItemComponent
                        loginUser={this.props.authStore.loginUser.id}
                        handleClickDetail={this.handleClickDetail}
                        roomListGroupByStartTime={roomListGroupByStartTime}
                        likeLoading={likeLoading}
                        changeLikeState={(groupId, roomId, likeId) =>
                            this.props.roomStore.changeLikeState(this.props.authStore.loginUser, groupId, roomId, likeId)
                        }
                        isMember={isMember}
                        isLeader={isLeader}
                        situation={this.state.situation}
                    />
                ) : (
                    <Box style={{ textAlign: 'center', marginTop: 100, marginBottom: 1000 }}>
                        <CircularProgress />
                    </Box>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(inject('roomStore', 'authStore', 'classStore')(observer(MobileFullScheduleComponent)));
