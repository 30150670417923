import React, { Component } from 'react';
import { Box, Button, Dialog, IconButton, InputBase, Radio, Typography, Tooltip, RadioGroup } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { withRouter } from '../../components/WithRouter';
import { ReactComponent as MagnifyingGlassIcon } from '../../common/images/MagnifyingGlassIcon.svg';
import { ReactComponent as GraduationCapIcon } from '../../common/images/GraduationCapIcon.svg';
import { ReactComponent as SchoolIcon } from '../../common/images/SchoolIcon.svg';
import { ReactComponent as UnCheckedIcon } from '../../common/images/UnCheckedIcon.svg';
import { ReactComponent as CheckedIcon } from '../../common/images/CheckedIcon.svg';
import { UTIL } from '../../common/util/common.util';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 390,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleText: {
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    contentsBox: {
        margin: '24px 0 32px',
    },
    textStyle: {
        fontSize: '1rem',
        color: '#333',
        letterSpacing: '-0.4px',
        '@media all and (max-width: 600px)': {
            fontSize: '0.875rem',
        },
    },
    searchBox: {
        display: 'flex',
        boxShadow: 'none',
        border: '1px solid #C4C4C4',
        padding: '4px 9px',
        margin: '12px 0 20px',
        borderRadius: 7,
        '& .MuiInputBase-root': {
            width: '100%',
        },
        '& input': {
            width: '100%',
            fontSize: '1rem',
            '@media all and (max-width: 600px)': {
                fontSize: '0.875rem',
            },
        },
    },
    searchIconButton: {
        padding: 6,
        backgroundColor: '#f8f8f8',
        marginLeft: 5,
        '&:hover': {
            backgroundColor: '#f8f8f8',
        },
    },
    numberText: {
        fontSize: '0.813rem',
        marginLeft: 4,
        color: '#a3a8af',
    },
    listBox: {
        maxHeight: 285,
        marginTop: 10,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '15px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '5px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
        '& .Mui-checked': {
            color: '#0097FF',
        },
        '@media all and (max-width: 600px)': {
            marginTop: 0,
        },
    },
    listBoxIn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px 0',
    },
    lightTooltip: {
        backgroundColor: '#FFFFF5',
        color: '#000',
        border: '1px solid #000',
        fontSize: '0.688rem',
        borderRadius: 0,
        marginLeft: 0,
        marginTop: '0 !important',
    },
    textStyle2: {
        width: 235,
        fontSize: '1rem',
        color: '#333',
        letterSpacing: '-0.32px',
        fontWeight: 'bold',
        marginLeft: 7.5,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '@media all and (max-width: 600px)': {
            fontSize: '0.875rem',
            width: 160,
        },
    },
    subStyle: {
        fontSize: '0.875rem',
        color: '#666',
        letterSpacing: '-0.28px',
        fontWeight: 'bold',
        marginLeft: 7.5,
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    radioButton: {
        '&.MuiButtonBase-root': {
            '&:hover': {
                background: 'transparent',
            },
        },
    },
    buttonStyle: {
        width: '100%',
        height: 48,
        background: '#0097FF',
        borderRadius: 8,
        fontSize: '1.125rem',
        color: '#fff',
        fontWeight: 'bold',
        '&:hover': {
            background: '#0097FF',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
            '&:hover': {
                background: '#fff',
            },
        },
        '@media all and (max-width: 600px)': {
            height: 31,
            fontSize: '0.75rem',
            borderRadius: 5,
        },
    },
});

class LicenseKeyDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schoolList: [
                { id: '1', name: '학교명학교명학교명학교명학교명학교명학교명', key: 'xxxx - xxxx - xxxx - xxxx' },
                { id: '2', name: '학교명', key: 'xxxx - xxxx - xxxx - xxxx' },
                { id: '3', name: '학교명', key: 'xxxx - xxxx - xxxx - xxxx' },
                { id: '4', name: '학교명', key: 'xxxx - xxxx - xxxx - xxxx' },
                { id: '5', name: '학교명', key: 'xxxx - xxxx - xxxx - xxxx' },
                { id: '6', name: '학교명', key: 'xxxx - xxxx - xxxx - xxxx' },
                { id: '7', name: '학교명', key: 'xxxx - xxxx - xxxx - xxxx' },
            ],
            value: '',
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const licenseKeys = this.props?.orgStore?.licenseKeys;
        const isOpen = this.props?.orgStore?.isOpenLicenseSearchDialog;
        if (licenseKeys?.length > 0) {
            return;
        }

        if (isOpen) {
            console.log('request license keys');
            this.props?.orgStore?.findAllLicenseKeys();
        }
    }

    handleChange = event => {
        const selectedLicenseKey = event.target.value;
        console.log('handleChange selectedLicenseKey : >> ', selectedLicenseKey);

        if (!selectedLicenseKey) {
            return;
        }

        this.props?.orgStore?.changeSelectedLicenseKey(selectedLicenseKey);
    };

    handleClose = () => {
        this.props?.orgStore?.initFindLicenseKeys();
        // this.props.handleCloseLicenseKeyDialog && this.props.handleCloseLicenseKeyDialog();
        this.props?.orgStore?.changeLicenseSearchDialogOpenState(false);
    };

    handleInputChange = e => {
        const keyword = e?.target?.value;
        console.log('handleInputChange : >> ', keyword);
        UTIL.debouncedFunc(() => {
            this.props?.orgStore?.changeSelectedLicenseKey('');
            this.props?.orgStore?.changeLicenseSearchKeyword(keyword);
        }, 1000);
    };

    handleClickAddSelectedLicenseKey = () => {
        const selectedLicenseKey = this.props?.orgStore?.selectedLicenseKey;
        const currentPathName = this.props?.location?.pathname;
        console.log('handleClickAddSelectedLicenseKey selectedLicenseKey : >> ', currentPathName);
        if (!selectedLicenseKey || !currentPathName) {
            return;
        }

        if (currentPathName === '/profilesettings') {
            this.props?.userStore?.changeEditUserLicenseKey(selectedLicenseKey);
        }

        if (currentPathName === '/signup') {
            this.props?.authStore?.changeSignUpLicenseKey(selectedLicenseKey);
        }

        this.handleClose();
    };

    render() {
        const { classes } = this.props;
        const open = this.props?.orgStore?.isOpenLicenseSearchDialog;
        const licenseKeys = this.props?.orgStore?.searchLicenseKeys;
        const selectedLicenseKey = this.props?.orgStore?.selectedLicenseKey;

        return (
            <Dialog open={open} className={classes.dialogBox}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.titleText}>학교별 라이선스 키 찾기</Typography>
                    <IconButton className={classes.iconButton} onClick={this.handleClose} disableRipple>
                        <DialogCloseIcon />
                    </IconButton>
                </Box>

                <Box className={classes.contentsBox}>
                    <Typography className={classes.textStyle}>해당 학교의 라이선스 키를 선택해주세요.</Typography>
                    <Box className={classes.searchBox}>
                        <InputBase placeholder="학교명 또는 라이선스 키 검색" onChange={this.handleInputChange} />
                        <IconButton className={classes.searchIconButton} aria-label="Search" disableRipple>
                            <MagnifyingGlassIcon />
                        </IconButton>
                    </Box>

                    <Box display="flex" alignItems="center">
                        <GraduationCapIcon />
                        <span className={classes.numberText}>{`${licenseKeys?.length}개`}</span>
                    </Box>
                    <Box className={classes.listBox}>
                        {licenseKeys?.length <= 0 ? (
                            <Typography className={classes.textStyle}>검색어를 입력해주세요.</Typography>
                        ) : (
                            licenseKeys.map((key, i) => (
                                <Box key={key?.id} className={classes.listBoxIn}>
                                    <Box display="flex" alignItems="center">
                                        <SchoolIcon />
                                        <Box>
                                            <Tooltip title={key?.organization} classes={{ tooltip: classes.lightTooltip }}>
                                                <Typography className={classes.textStyle2}>{key?.organization}</Typography>
                                            </Tooltip>
                                            <Typography className={classes.subStyle}>{key?.licenseKey}</Typography>
                                        </Box>
                                    </Box>
                                    <Radio
                                        checked={selectedLicenseKey === key?.licenseKey}
                                        onChange={this.handleChange}
                                        value={key?.licenseKey}
                                        icon={<UnCheckedIcon />}
                                        checkedIcon={<CheckedIcon />}
                                        disableRipple
                                        className={classes.radioButton}
                                    />
                                </Box>
                            ))
                        )}
                    </Box>
                </Box>

                <Box display="flex" justifyContent="center">
                    <Button
                        className={classes.buttonStyle}
                        onClick={this.handleClickAddSelectedLicenseKey}
                        disableRipple
                        disabled={!selectedLicenseKey}
                    >
                        선택 추가
                    </Button>
                </Box>
            </Dialog>
        );
    }
}

export default withRouter(withStyles(styles)(inject('orgStore', 'authStore', 'userStore')(observer(LicenseKeyDialogComponent))));
