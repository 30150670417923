import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Badge, Box, Button, IconButton, MenuItem, MenuList, Popover, Typography } from '@material-ui/core';
import { ReactComponent as BellRingingIcon } from '../../../common/images/BellRingingIcon.svg';
import { ReactComponent as CircleWavyQuestionIcon } from '../../../common/images/CircleWavyQuestionIcon.svg';
import LogoPencilIcon from '../../../common/images/LogoPencilIcon.svg';
import { inject, observer } from 'mobx-react';
import { withRouter } from '../../../components/WithRouter';
import { ReactComponent as BedgeNewIcon } from '../../../common/images/BedgeNewIcon.svg';
import NotificationComponent from '../../notification/NotificationComponent';
import { UTIL } from '../../../common/util/common.util';
import { NOTICE_STATE_HANDLE_OPERATION, Request_Notice_IntervalTime } from '../../../stores/NoticeStore';
import { USER_TYPE } from '../../../stores/UserStore';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { ReactComponent as SearchIcon } from '../../../common/images/SearchIcon.svg';
import { PATH_UTIL } from '../../../common/util/path.util';
import { MOBILE_APP_BAR_MODE } from './MobileAppBar';
import { ReactComponent as PencilCircleIcon } from '../../../common/images/PencilCircleIcon.svg';
import { ReactComponent as ModalCloseIcon } from '../../../common/images/ModalCloseIcon.svg';
import { TooltipType } from '../../../stores/TooltipStore';

const styles = _theme => ({
    root: {
        width: '100%',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        background: '#fff',
        borderBottom: '1px solid #c0c2c3',
        zIndex: 1000,
    },
    hiddenRoot: {
        display: 'none',
    },
    appBar: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '13px 10px',
        boxSizing: 'border-box',
    },
    logoImgBox: {
        maxWidth: 80,
        width: 'auto',
        '@media all and (min-width: 601px)': {
            maxWidth: 140,
            maxHeight: 32,
        },
        '@media (min-width: 319px) and (max-width: 374px)': {
            maxWidth: 25,
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            width: 20,
            height: 20,
        },
        '@media all and (min-width: 601px)': {
            '& svg': {
                width: 27,
                height: 27,
            },
        },
        '@media (min-width: 319px) and (max-width: 374px)': {
            '& svg': {
                width: 18,
                height: 18,
            },
        },
    },
    searchIcon: {
        margin: '0 8px',
        '& svg': {
            '& path': {
                stroke: '#A3A8AF',
            },
        },
    },
    iconMargin: {
        margin: '0 8px',
        '@media all and (min-width: 601px)': {
            margin: '0 25px',
        },
    },
    search: {
        width: 500,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#fff',
        border: '2px solid #e1e1e1',
        padding: '3px 6px 3px 22px',
        borderRadius: 50,
        '& .MuiInputBase-input::placeholder': {
            opacity: 1,
            fontSize: '1.125rem',
            color: '#92979e',
        },
        '& .MuiInputBase-input': {
            padding: '6px 7px 4px',
        },
    },
    buttonStyle: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.938rem',
        },
        minWidth: 30,
        fontSize: '0.688rem',
        color: '#000',
        fontWeight: 600,
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    lineStyle: {
        width: 1,
        height: 17,
        background: '#e1e1e1',
        margin: '0 8px 0 8px',
        '@media all and (min-width: 601px)': {
            margin: '0 24px 0 12px',
        },
    },
    badgeBox: {
        position: 'relative',
        '@media (min-width: 319px) and (max-width: 374px)': {
            margin: '0 8px',
        },
    },
    badge: {
        position: 'absolute',
        right: -8,
        top: -5,
    },
    inputRoot: {
        color: '#000',
        fontSize: '1.125rem',
        width: '100%',
        marginRight: 10,
    },
    avatar: {
        width: 30,
        height: 30,
        // background: '#0097ff',
        backgroundColor: 'rgba(0, 0, 0, 0.16)',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 2px;',
        '@media all and (min-width: 601px)': {
            width: 40,
            height: 40,
        },
    },
    iconColor: {
        '& .question-icon': {
            fill: '#0097ff',
        },
        '& .bellringing-icon': {
            fill: '#0097ff',
        },
    },
    avatarMember: {
        width: 40,
        height: 40,
        background: '#a3a8af',
        boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 1px;',
        '& img': {
            objectFit: 'cover',
            width: '100%',
        },
        '@media all and (min-width: 601px)': {
            width: 60,
            height: 60,
        },
    },
    popover: {
        '& .MuiPaper-root': {
            borderRadius: 10,
            boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
        },
        '& .MuiPopover-paper': {
            '@media all and (min-width: 601px)': {
                width: 300,
            },
            width: 200,
            padding: '25px 10px 10px',
            overflow: 'inherit',
            marginTop: 18,
            marginLeft: 12,
            '&::before': {
                backgroundColor: '#fff',
                content: "''",
                display: 'block',
                height: '13px',
                position: 'absolute',
                top: -6,
                right: '15px',
                transform: 'rotate( -45deg ) skew( 0deg )',
                width: '13px',
                boxShadow: '2px -2px 2px 0 rgb(178 178 178 / 20%)',
                zIndex: 200,
            },
        },
    },
    popoverIn: {
        fontFamily: 'NanumSquareRoundOTF',
        position: 'relative',
    },
    avatarMargin: {
        marginRight: 12,
    },
    nameText: {
        fontSize: '1rem',
        color: '#000',
        fontWeight: 600,
        lineHeight: 1.2,
        '@media all and (min-width: 601px)': {
            fontSize: '1.063rem',
        },
    },
    emailText: {
        maxWidth: 220,
        fontSize: '0.75rem',
        color: '#a3a8af',
        marginBottom: 5,
        '@media all and (min-width: 601px)': {
            fontSize: '0.875rem',
        },
    },
    formControl: {
        '& .MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                borderColor: '#a3a8af',
                borderWidth: 1,
            },
        '& .MuiOutlinedInput-input': {
            padding: '5px 10px',
        },
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '0.875rem',
        color: '#000',
        minHeight: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (min-width: 601px)': {
            fontSize: '1.063rem',
            minHeight: 48,
        },
    },
    menuItemLine: {
        borderBottom: '1px solid #c0c2c3',
    },
    iconButton2: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.938rem',
            marginLeft: 15,
        },
        '@media (min-width: 319px) and (max-width: 374px)': {
            marginTop: 2,
        },
        fontSize: '0.688rem',
        color: '#000',
        fontWeight: 600,
        padding: 0,
        marginLeft: 5,
        marginRight: 5,
        minWidth: 40,
        '&:hover': {
            background: 'transparent',
        },
    },
    toastsStyle: {
        '& .toast': {
            minHeight: '25px !important',
            borderRadius: '30px !important',
            fontSize: '0.938rem !important',
            color: '#fff!important',
            backgroundColor: '#858585!important',
        },
    },
    tooltipBox: {
        position: 'absolute',
        left: -118,
        top: 32,
        width: 200,
        padding: '8px 10px',
        background: '#2078e8',
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 3,
        whiteSpace: 'pre-wrap',
        zIndex: 100,
        '&::before': {
            backgroundColor: '#2078e8',
            content: "''",
            display: 'block',
            height: '10px',
            position: 'absolute',
            top: -5,
            left: 123,
            transform: 'rotate( -45deg ) skew( 0deg )',
            width: '10px',
            boxShadow: '2px -2px 2px 0 rgb(178 178 178 / 20%)',
            zIndex: 200,
        },
        '@media all and (min-width: 601px)': {
            top: 40,
            '&::before': {
                left: 126,
            },
        },
        '@media all and (max-width: 374px)': {
            left: -102,
            top: 33,
            '&::before': {
                left: 106,
            },
        },
    },
    closeBtn: {
        padding: 0,
        '& svg': {
            width: 20,
            height: 20,
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    tooltipText: {
        fontSize: '0.813rem',
        fontWeight: 500,
        color: '#fff',
        marginTop: 5,
    },
});

const StyledBadge = withStyles(theme => ({
    badge: {
        right: 2,
        top: 19,
        border: `1px solid #fff`,
        width: 12,
        height: 12,
        borderRadius: '50%',
        background: '#00c880',
    },
}))(Badge);

const StyledBadgeMember = withStyles(theme => ({
    badge: {
        right: 6,
        top: 37,
        border: `1px solid #fff`,
        width: 15,
        height: 15,
        borderRadius: '50%',
        background: '#00c880',
    },
}))(Badge);

class MobileTopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            notificationOpen: false,
            filter: '온라인',
            isAlertOpen: true,
        };
    }

    getNotificationsInterval = undefined;

    getNotifications = () => {
        const { noticeStore, authStore } = this.props;
        noticeStore.requestNotifications(authStore.loginUser.email);
    };

    setNotificationInterval = () => {
        this.getNotificationsInterval = setInterval(this.getNotifications, Request_Notice_IntervalTime); //30초마다 알림조회 인터벌 설정
        this.getNotifications();
    };

    cancelNotificationInterval = () => {
        if (this.getNotificationsInterval !== undefined) {
            clearInterval(this.getNotificationsInterval);
            this.getNotificationsInterval = undefined;
        }
        if (this.props.noticeStore.cancelTokenSource) {
            this.props.noticeStore.cancelTokenSource.cancel();
        }
    };

    componentDidMount() {
        const { loginUser } = this.props.authStore;
        this.props.userStore.checkCoachState(loginUser.email);
        this.props.avatarStore.getAvatar(loginUser.id);

        if (loginUser.type !== USER_TYPE.Admin && this.getRoomNoticeInterval === undefined) {
            this.setNotificationInterval();
        }

        this.initNeoPen();
    }

    initNeoPen = async () => {
        const loginUser = this.props.authStore.loginUser;

        await this.props.userStore.getUserInfo(loginUser.email);

        const macAddress = this.props.userStore.fullSmartPenAddress;

        this.props.neoPenStore.initNeoPen(loginUser, macAddress);
    };

    componentDidUpdate(_prevProps, prevState, _snapshot) {
        if (prevState.notificationOpen !== this.state.notificationOpen && this.state.notificationOpen) {
            if (this.getNotificationsInterval) {
                this.cancelNotificationInterval();
            }
        } else if (prevState.notificationOpen !== this.state.notificationOpen && !this.state.notificationOpen) {
            const { newNotifications } = this.props.noticeStore;
            const { loginUser } = this.props.authStore;
            if (newNotifications.length) {
                const notificationIds = newNotifications.map(notice => notice.id);
                this.props.noticeStore.requestUpdateNotifiedNotifications(loginUser.email, NOTICE_STATE_HANDLE_OPERATION.Notified, notificationIds);
            }
            if (!this.getNotificationsInterval) {
                this.setNotificationInterval();
            }
        }
    }

    componentWillUnmount() {
        if (this.getNotificationsInterval) {
            this.cancelNotificationInterval();
        }

        const { neoPenStore } = this.props;

        if (neoPenStore.isInit) {
            neoPenStore.changeInitState(!neoPenStore.isInit);
        }
    }

    handleChangeFilter = event => {
        this.setState({ filter: event.target.value });
    };

    handleNotificationOpen = () => {
        this.setState({ notificationOpen: true });
    };

    handleNotificationClose = () => {
        this.setState({ notificationOpen: false });
    };

    handleClickServiceCenter = e => {
        this.props.navigate('/servicecenter');
    };

    handleClickProfileSettings = e => {
        this.props.navigate('/profilesettings');
        this.setState({
            anchorEl: null,
        });
    };

    handleClickDashboard = isCommonUser => {
        const movePath = isCommonUser ? '/classmanagement' : '/mylearning';
        this.props.navigate(movePath);
    };

    handleClickSearch = _e => {
        this.props.changeMode(MOBILE_APP_BAR_MODE.search);
    };

    handleClickMyLearning = () => {
        this.props.navigate('/mylearning');
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClickLogo = path => {
        if (path !== 'rooms') {
            this.props.searchStore.initSearchKeyword();
            this.props.roomStore.initRoom();
            this.props.navigate('/rooms');
        }
    };

    handleRequestNextPageNotifications = (nextPage, rowsPerPage) => {
        const { noticeStore, authStore } = this.props;
        const { loginUser } = authStore;
        noticeStore.requestNotifications(loginUser.email, nextPage, rowsPerPage);
    };

    selectRootClassName = path => {
        const { classes } = this.props;
        const notAllowedPathNames = ['studylive', 'termsofuse', 'privacypolicy', 'penview', 'penrecordview', 'realtimeview', 'statistics'];
        if (notAllowedPathNames.includes(path)) {
            return classes.hiddenRoot;
        }
        return classes.root;
    };

    selectQuestionIconClassName = path => {
        const { classes } = this.props;
        if (path === 'servicecenter') {
            return classes.iconColor;
        }
        return null;
    };

    render() {
        const { classes, navigate, location } = this.props;
        const { anchorEl, notificationOpen } = this.state;
        const { orgImages } = this.props.orgStore;
        const { loginUser } = this.props.authStore;
        const { loadAvatarImage } = this.props.avatarStore;
        const { notifications, newNotifications } = this.props.noticeStore;
        const { rowsPerPage, page, changePage } = this.props.paginationStore;
        const { user } = this.props.userStore;
        const open = Boolean(anchorEl);
        const mainPathName = PATH_UTIL.getPath(location.pathname);
        const questionIconClassName = this.selectQuestionIconClassName(mainPathName);
        const bellIconClassName = notificationOpen ? classes.iconColor : null;
        const rootClassName = this.selectRootClassName(mainPathName);
        const isCommonUser = loginUser.type !== USER_TYPE.Guest;
        const dashBoardBtnName = isCommonUser ? '클래스 관리' : '내 학습';
        const isSmallBashBoardBtnName = isCommonUser ? '클래스' : '내 학습';
        const isSmall = window.innerWidth < 374;
        const isHaveNewNotice = newNotifications.length > 0;

        return (
            <div className={rootClassName}>
                <Box className={classes.appBar}>
                    {/*<Box display="flex" alignItems="center">*/}
                    {orgImages.Logo !== undefined && orgImages.Logo !== '' && (
                        <img
                            src={!isSmall ? orgImages.Logo : LogoPencilIcon}
                            alt="logo"
                            className={classes.logoImgBox}
                            style={{
                                objectFit: 'contain',
                                cursor: 'pointer',
                                verticalAlign: 'middle',
                            }}
                            onClick={e => this.handleClickLogo(e)}
                        />
                    )}

                    <Box display="flex" alignItems="center">
                        <Box style={{ position: 'relative' }}>
                            <IconButton
                                className={classes.iconButton}
                                onClick={() => {
                                    this.props.tooltipStore.closeTooltip(TooltipType.MobileRealTimeOpen);

                                    this.setState({ isAlertOpen: false });

                                    window.open(`/realtimeview/${loginUser.email}`);
                                }}
                                disableRipple
                            >
                                <PencilCircleIcon />
                            </IconButton>
                            {!this.props.tooltipStore.isClosed[TooltipType.MobileRealTimeOpen] && (
                                <Box className={classes.tooltipBox}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <IconButton
                                            className={classes.closeBtn}
                                            disableRipple
                                            onClick={() => {
                                                this.props.tooltipStore.closeTooltip(TooltipType.MobileRealTimeOpen);
                                            }}
                                        >
                                            <ModalCloseIcon />
                                        </IconButton>
                                    </Box>

                                    <Typography className={classes.tooltipText}>
                                        등록된 서식에 필기하고 바로 확인 하려면 여기를 눌러 새창에서 펜을 연결해주세요.
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        <IconButton className={clsx(classes.iconButton, classes.searchIcon)} onClick={this.handleClickSearch} disableRipple>
                            <SearchIcon />
                        </IconButton>
                        <>
                            <Button className={classes.buttonStyle} disableRipple onClick={() => this.handleClickDashboard(isCommonUser)}>
                                {!isSmall ? <>{dashBoardBtnName}</> : <>{isSmallBashBoardBtnName}</>}
                            </Button>
                            <span className={classes.lineStyle} />
                        </>

                        <Box className={classes.badgeBox}>
                            <IconButton className={classes.iconButton} onClick={this.handleNotificationOpen} disableRipple>
                                <BellRingingIcon className={clsx(bellIconClassName)} />
                            </IconButton>
                            {isHaveNewNotice && <BedgeNewIcon className={classes.badge} />}
                        </Box>

                        {/*알림*/}
                        <NotificationComponent
                            notificationOpen={notificationOpen}
                            handleNotificationClose={this.handleNotificationClose}
                            requestNotifications={this.handleRequestNextPageNotifications}
                            loginUser={loginUser}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            changePage={changePage}
                            notifications={notifications}
                        />

                        <IconButton className={clsx(classes.iconButton, classes.iconMargin)} onClick={this.handleClickServiceCenter} disableRipple>
                            <CircleWavyQuestionIcon className={clsx(questionIconClassName)} />
                        </IconButton>

                        <IconButton
                            className={classes.iconButton}
                            aria-owns={open ? 'simple-popper' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleClick}
                            disableRipple
                        >
                            {/*<StyledBadge*/}
                            {/*    anchorOrigin={{*/}
                            {/*        vertical: 'bottom',*/}
                            {/*        horizontal: 'right',*/}
                            {/*    }}*/}
                            {/*    variant="dot"*/}
                            {/*>*/}
                            <Avatar src={loadAvatarImage} alt="profile image" className={classes.avatar}>
                                {UTIL.getFirstCapitalLetter(user.name)}
                            </Avatar>
                            {/*</StyledBadge>*/}
                        </IconButton>

                        <Popover
                            id="simple-popper"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={this.handleClose}
                            className={classes.popover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Box className={classes.popoverIn}>
                                <Box display="flex" alignItems="center" style={{ marginLeft: 15 }}>
                                    <Box className={classes.avatarMargin}>
                                        <Avatar src={loadAvatarImage} alt="profile image" className={classes.avatarMember}>
                                            {UTIL.getFirstCapitalLetter(user.name)}
                                        </Avatar>
                                        {/*</StyledBadgeMember>*/}
                                    </Box>
                                    <Box>
                                        <Typography className={classes.nameText}>{user.name}</Typography>
                                        <Typography className={classes.emailText} noWrap>
                                            {user.email}
                                        </Typography>
                                    </Box>
                                </Box>
                                <MenuList>
                                    <MenuItem
                                        className={clsx(classes.menuItem, classes.menuItemLine)}
                                        onClick={this.handleClickProfileSettings}
                                        disableRipple
                                    >
                                        프로필 설정
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuItem}
                                        disableRipple
                                        onClick={() => {
                                            if(window.flutter_inappwebview)
                                                window.flutter_inappwebview.callHandler('Logout');

                                            this.props.authStore.doLogout({
                                                moveToHome: () => {
                                                    navigate('/');
                                                },
                                            });
                                        }}
                                    >
                                        로그아웃
                                    </MenuItem>
                                </MenuList>
                            </Box>
                        </Popover>
                    </Box>
                </Box>
                <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} lightBackground className={classes.toastsStyle} />
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(
        inject(
            'orgStore',
            'authStore',
            'classStore',
            'userStore',
            'avatarStore',
            'roomStore',
            'searchStore',
            'noticeStore',
            'paginationStore',
            'neoPenStore',
            'tooltipStore',
        )(observer(MobileTopBar)),
    ),
);
