import React, { Component, forwardRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from '@nivo/core';
import MaterialTable from 'material-table';
import { FormattedMessage, injectIntl } from 'react-intl';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const style = theme => ({
    root: {
        padding: '0 20px',
        position: 'relative',
        display: 'flex',
        // width: '100%',
        height: window.innerHeight / 1.8,
        '& .MuiInputBase-root': {
            width: 150,
        },
        '& .MuiInputBase-input': {
            '&::placeholder': {
                fontFamily: 'NanumSquareRoundOTF',
                fontSize: '0.8rem',
            },
        },
        '& .MuiIcon-root': {
            fontSize: '1.5rem',
        },
        '& .MuiTablePagination-select > div': {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '0.8rem',
            position: 'absolute',
        },
        '& .MuiTypography-caption': {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '0.8rem',
        },
        '& .MuiIconButton-root': {
            padding: 5,
        },
    },
    chartBox: {
        position: 'absolute',
        right: 20,
    },
    textStyle1: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.9rem',
        fontWeight: 300,
        color: '#5a6069',
    },
    circleStyle1: {
        width: 6,
        height: 6,
        borderRadius: '50%',
        background: '#0024ff',
        content: '',
        marginRight: 5,
    },
    circleStyle2: {
        width: 6,
        height: 6,
        borderRadius: '50%',
        background: '#4fb6fe',
        content: '',
        marginRight: 5,
    },
});

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class MobileQuizChart extends Component {
    ellipsisText = text => (text.length > 10 ? text.substring(0, 10) + '...' : text);

    render() {
        const { classes, intl, type, data } = this.props;
        const isChoice = type === 'SingleChoice' || type === 'MultipleChoice';
        const getColor = isCorrect => (isCorrect ? '#0047ac' : '#b7defe');

        const chartData = isChoice
            ? data.map(qa => {
                  return {
                      name:
                          qa.itemId +
                          `${
                              qa.correct
                                  ? '(' + intl.formatMessage({ id: 'correct_answer' }) + ')'
                                  : '(' + intl.formatMessage({ id: 'incorrect_answer' }) + ')'
                          }`,
                      isCorrect: qa.correct,
                      color: getColor(qa.correct),
                      count: qa.responseCount,
                      users: qa.users,
                  };
              })
            : data.map(qa => {
                  return {
                      userName: this.ellipsisText(qa.userName),
                      answer: qa.answer,
                  };
              });

        const columns = [
            {
                title: intl.formatMessage({ id: 'student' }),
                field: 'userName',
                minWidth: 150,
                cellStyle: {
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    background: '#fff',
                    fontFamily: 'NanumSquareRoundOTF',
                    fontSize: '1rem',
                    fontWeight: 300,
                    color: '#000',
                },
                headerStyle: {
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                },
            },
            { title: intl.formatMessage({ id: 'answer' }), field: 'answer' },
        ];

        const CustomBottomTick = tick => {
            const theme = useTheme();

            const isCorrect = tick.value.endsWith('(' + intl.formatMessage({ id: 'correct_answer' }) + ')');

            const TEXT_MAX_LENGTH = 6;
            const getShortenedText = (length, text) => (text.length > length ? text.substr(0, TEXT_MAX_LENGTH) + '...' : text);
            return (
                <g transform={`translate(${tick.x},${tick.y + 22})`}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            ...theme.axis.ticks.text,
                            fill: isCorrect ? '#0047ac' : '#7b8288',
                            fontSize: '0.8rem',
                            fontFamily: 'NanumSquareRoundOTF',
                            fontWeight: isCorrect ? 800 : 600,
                        }}
                    >
                        {getShortenedText(TEXT_MAX_LENGTH, tick.value)}
                    </text>
                </g>
            );
        };

        const CustomLeftTick = tick => {
            const theme = useTheme();
            return (
                <g transform={`translate(${tick.x - 22},${tick.y})`}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            ...theme.axis.ticks.text,
                            fill: '#7b8288',
                            fontSize: '0.8rem',
                            fontFamily: 'NanumSquareRoundOTF',
                            fontWeight: 600,
                        }}
                    >
                        {tick.value}
                    </text>
                </g>
            );
        };

        const TotalLabels = ({ bars, yScale }) => {
            const labelMargin = 20;
            return bars.map(({ data: { data, indexValue }, x, width }, i) => {
                const yScaleStandard = data.count;
                return (
                    <g transform={`translate(${x}, ${yScale(yScaleStandard ? yScaleStandard : 0) - labelMargin})`} key={`${indexValue}-${i}`}>
                        <text
                            // add any class to the label here
                            className="bar-total-label"
                            x={width / 2}
                            y={labelMargin / 2}
                            textAnchor="middle"
                            alignmentBaseline="central"
                            // add any style to the label here
                            style={{
                                fill: '#0047ac',
                                fontSize: '0.8rem',
                                fontFamily: 'NanumSquareRoundOTF',
                                fontWeight: 600,
                            }}
                        >
                            {data.count}
                            {data.count > 0 && intl.formatMessage({ id: 'person' })}
                        </text>
                    </g>
                );
            });
        };

        const MyResponsiveBar = ({ data }) => (
            <ResponsiveBar
                data={data}
                keys={['count']}
                indexBy="name"
                margin={{ top: 40, right: 50, bottom: 35, left: 50 }}
                padding={0.2}
                // groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                borderRadius={5}
                borderColor={{ from: 'color', modifiers: [['darker', '0.8']] }}
                colors={({ id, data }) => data.color}
                label={false}
                isInteractive={false}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                axisBottom={{
                    renderTick: CustomBottomTick,
                }}
                axisLeft={{
                    renderTick: CustomLeftTick,
                }}
                layers={['grid', 'axes', 'bars', TotalLabels, 'markers', 'legends']}
                tooltip={props => {
                    return (
                        <Typography style={{ fontFamily: 'NanumSquareRoundOTF', color: '#0047ac', fontSize: '1.2rem' }}>
                            <span style={{ fontWeight: 600 }}>{props.data.users}</span>
                        </Typography>
                    );
                }}
            />
        );

        return (
            <div className={classes.root}>
                {isChoice ? (
                    <React.Fragment>
                        {MyResponsiveBar({ data: chartData })}
                        <Box className={classes.chartBox}>
                            <Box display="flex" alignItems="center" pb={1}>
                                <div className={classes.circleStyle1} />
                                <Typography className={classes.textStyle1}>
                                    <FormattedMessage id="correct_answer" />
                                </Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <div className={classes.circleStyle2} />
                                <Typography className={classes.textStyle1}>
                                    <FormattedMessage id="incorrect_answer" />
                                </Typography>
                            </Box>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <MaterialTable
                            style={{
                                boxShadow: 'none',
                                border: 'none',
                                padding: 0,
                                width: '100%',
                                // height: '100%',
                            }}
                            title=""
                            icons={tableIcons}
                            columns={columns}
                            data={chartData}
                            options={{
                                pageSize: 5,
                                headerStyle: {
                                    wordBreak: 'keep-all',
                                    fontFamily: 'NanumSquareRoundOTF',
                                    fontSize: '0.8rem',
                                    fontWeight: 600,
                                    color: '#000',
                                    paddingTop: 20,
                                    paddingBottom: 5,
                                },
                                cellStyle: {
                                    wordBreak: 'keep-all',
                                    fontFamily: 'NanumSquareRoundOTF',
                                    fontSize: '0.8rem',
                                    fontWeight: 300,
                                    color: '#000',
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                },
                                pageSizeOptions: [5],
                            }}
                            localization={{
                                pagination: {
                                    labelRowsSelect: intl.formatMessage({ id: 'table_rows_count' }),
                                    labelDisplayedRows: intl.formatMessage({ id: 'table_bottom_text' }),
                                },
                            }}
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(MobileQuizChart));
