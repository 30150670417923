import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, MobileStepper, Typography } from '@material-ui/core';
import MobileQuiz from './MobileQuiz';
import { ReactComponent as ArrowIcon } from '../../../common/images/ArrowIcon.svg';
import MobileQuizChart from './MobileQuizChart';
import { inject, observer } from 'mobx-react';
import { ReactComponent as ProhibitIcon } from '../../../common/images/ProhibitIcon.svg';
import { FormattedMessage } from 'react-intl';

const style = theme => ({
    root: {
        '& .MuiButton-root.Mui-disabled': {
            '& path': {
                fill: '#a3a8af',
            },
        },
    },
    titleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.1rem',
        fontWeight: 600,
        color: '#0d0d0d',
        padding: '0 20px',
    },
    mobileStepper: {
        width: 300,
        margin: '0 auto',
        background: 'transparent',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.9rem',
    },
    imageText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.1rem',
        fontWeight: 800,
        color: '#0d0d0d',
    },
    noDataBox: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate( -50%, -50% )',
    },
});

class MobileQuizResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
        };
    }

    handleNext = () => {
        this.setState(prevState => ({
            activeStep: prevState.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState(prevState => ({
            activeStep: prevState.activeStep - 1,
        }));
    };

    render() {
        const { classes, statisticsStore, theme } = this.props;
        const { activeStep } = this.state;
        const maxSteps = statisticsStore.quizList.length;

        const mapStepData = (quizData, index) => ({
            label: (
                <Box display="flex" flexDirection="column" justifyalign={'flex-start'} aligntext={'flex-start'} mb={3}>
                    <Grid item xs={12}>
                        <MobileQuiz type={quizData.type} data={quizData.data} index={index} />
                    </Grid>
                    <Grid item xs={12}>
                        <MobileQuizChart type={quizData.type} data={quizData.chartData} />
                    </Grid>
                </Box>
            ),
        });

        const quizResults = [];

        statisticsStore.getQuizData.forEach((q, index) => {
            quizResults.push(mapStepData(q, index));
        });

        return (
            <div className={classes.root}>
                <Typography className={classes.titleText}>
                    <FormattedMessage id="quiz_participation_status" />
                </Typography>
                {quizResults.length === 0 && !statisticsStore.getIsLoading && (
                    <div style={{ position: 'relative', width: '100%' }}>
                        <img style={{ width: '100%', height: '100%' }} src="/images/NoData_QuizResult.png" alt="No Data" />
                        <div className={classes.noDataBox}>
                            <ProhibitIcon />
                            <Typography className={classes.imageText}>
                                <FormattedMessage id="msg.no_display_data" />
                            </Typography>
                        </div>
                    </div>
                )}
                <div>{quizResults.length > 0 && quizResults[activeStep].label}</div>
                {quizResults.length > 0 && (
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        className={classes.mobileStepper}
                        nextButton={
                            <Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1}>
                                {theme.direction === 'rtl' ? <ArrowIcon /> : <ArrowIcon style={{ transform: 'rotate(180deg)' }} />}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? <ArrowIcon style={{ transform: 'rotate(180deg)' }} /> : <ArrowIcon />}
                            </Button>
                        }
                    />
                )}
            </div>
        );
    }
}

MobileQuizResult.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(style, { withTheme: true })(inject('statisticsStore')(observer(MobileQuizResult)));
