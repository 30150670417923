import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MobileClassNoticeComponent from './MobileClassNoticeComponent';
import MobileClassScheduleListComponent from './MobileClassScheduleListComponent';

const styles = _theme => ({
    root: {
        width: 'calc(100vw - 32px)',
        paddingBottom: 70,
    },
});

class MobileTodayScheduleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scheduleDetail: false,
        };
    }

    handleClickDetail = roomId => {
        this.props.handleClickDetail(roomId);
    };

    render() {
        const { classes, isScrollEnd, isMember, isLeader, classPath } = this.props;

        return (
            <div className={classes.root}>
                <MobileClassNoticeComponent isMember={isMember} classPath={classPath} />
                <MobileClassScheduleListComponent
                    handleClickDetail={this.handleClickDetail}
                    isScrollEnd={isScrollEnd}
                    isMember={isMember}
                    isLeader={isLeader}
                />
            </div>
        );
    }
}

export default withStyles(styles)(MobileTodayScheduleComponent);
