import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { validateEmail, validateName, validatePasswordCombination, validatePasswordLength } from '../common/Validation';
import fileDownload from 'js-file-download';

const logPrefix = ' [ ManagementStore ] ';

const MANAGEMENT_API_URL = '/api/v1/management';

const LOADING_STATE = {
    READY: 'ready',
    PENDING: 'pending',
    SUCCESS: 'success',
    FAILED: 'failed',
};

const EmptyGuest = {
    email: '',
    password: '',
    name: '',
    smartPenAddress: new Array(6).fill(''),
};

export const DEFAULT_GUEST_EMAIL_ADDRESS = '@studylive.ai';

export default class ManagementStore {
    constructor(serverContextPath, avatarStore) {
        this.serverContextPath = serverContextPath;
        this.avatarStore = avatarStore;
        makeAutoObservable(this);
    }

    allUsersForSearch = [];
    allUsers = [];
    allUsersTotalCount = 0;
    allMembersMyGroup = [];
    selectedGroupMembers = [];
    selectedGroupTeams = [];
    newGuest = { ...EmptyGuest };
    isAvailableEmail = false;
    isAvailableSmartPen = false;
    selectedUserEmail = '';
    selectedUserAvatar = '';
    excelLoadingState = LOADING_STATE.READY;
    selectedUser = {};
    allUsersLoadingState = LOADING_STATE.READY;

    setSelectedUser = user => {
        console.log(logPrefix, 'setSelectedUser user = ', user);
        this.selectedUser = user;
    };

    initAllUsers = () => {
        this.allUsers = [];
    };

    setSelectedUserEmail = email => {
        this.selectedUserEmail = email;
    };

    setSelectedUserAvatar = selectedGroupId => {
        console.log(logPrefix, `setSelectedUserAvatar selectedGroupId = ${selectedGroupId}`);
        const searchMembers = selectedGroupId === 0 ? this.allUsers.slice() : this.selectedGroupMembers.slice();
        console.log(logPrefix, `setSelectedUserAvatar searchMembers = `, searchMembers);
        const findUser = searchMembers.find(m => m.email === this.selectedUserEmail);
        console.log(logPrefix, `setSelectedUserAvatar findUser = `, findUser);

        if (findUser) {
            this.selectedUserAvatar = findUser.image;
        }
    };

    get isAllUsersLoading() {
        return this.allUsersLoadingState === LOADING_STATE.PENDING;
    }

    get isExcelLoading() {
        return this.excelLoadingState !== LOADING_STATE.READY;
    }

    get findAllUsers() {
        return this.allUsers.slice();
    }

    get newGuestPenAddressValidation() {
        console.log(logPrefix, 'this.newGuest.smartPenAddress : >> ', this.newGuest.smartPenAddress.indexOf(''));
        return this.newGuest.smartPenAddress.indexOf('') === -1 || this.newGuest.smartPenAddress.join('') !== '';
    }

    get notEmptyNewGuestPenAddress() {
        console.log(logPrefix, 'this.newGuest.smartPenAddress : >> ', this.newGuest.smartPenAddress.indexOf(''));
        return this.newGuest.smartPenAddress.indexOf('') === -1;
    }

    get newGuestNameValidation() {
        return validateName(this.newGuest.name);
    }

    get newGuestEmailValidation() {
        return validateEmail(`${this.newGuest.email}${DEFAULT_GUEST_EMAIL_ADDRESS}`);
    }

    get newGuestPasswordValidation() {
        return validatePasswordCombination(this.newGuest.password) && validatePasswordLength(this.newGuest.password);
    }

    get editSmartPenAddress() {
        return this.newGuest.smartPenAddress.slice();
    }

    get isAvailableSaveGuest() {
        const isEmptyEmail = this.newGuest.email.trim() === '' || this.newGuest.email === null;
        const isEmptyName = this.newGuest.name.trim() === '' || this.newGuest.name === null;
        const isCheckedExistPenAddress = this.newGuestPenAddressValidation ? this.isAvailableSmartPen : true;
        return (
            this.isAvailableEmail &&
            isCheckedExistPenAddress &&
            !isEmptyEmail &&
            this.newGuestEmailValidation &&
            !isEmptyName &&
            this.newGuestNameValidation
        );
    }

    get allMembers() {
        return this.allMembersMyGroup.slice();
    }

    resetAllUsers = users => {
        this.allUsers = users;
    };

    randomPassword = () => {
        this.newGuest.password = '';
        let pw = '';
        const str1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const str2 = 'abcdefghijklmnopqrstuvwxyz';
        const num = '1234567890';
        const spc = '!@#$%^&*';

        for (let i = 0; i < 15; i++) {
            switch (i % 4) {
                case 0:
                    pw += str1.charAt(Math.floor(Math.random() * str1.length));
                    break;
                case 1:
                    pw += str2.charAt(Math.floor(Math.random() * str2.length));
                    break;
                case 2:
                    pw += num.charAt(Math.floor(Math.random() * num.length));
                    break;
                case 3:
                    pw += spc.charAt(Math.floor(Math.random() * spc.length));
                    break;
                default:
                    break;
            }
        }
        pw = this._shuffle(pw);
        return pw;
    };

    _shuffle = pw => {
        const pwToList = pw.split('');
        let copied = pwToList.slice();
        for (let i = 0; i < copied.length; i++) {
            let random = Math.floor(Math.random() * copied.length);
            let temp = copied[random];
            copied[random] = copied[i];
            copied[i] = temp;
        }
        return copied.join('');
    };

    initNewGuest = () => {
        this.newGuest = { ...EmptyGuest };
        this.isAvailableEmail = false;
        this.isAvailableSmartPen = false;
    };

    changeNewGuestEmail = value => {
        if (this.newGuest.email.length < 64) {
            this.newGuest = { ...this.newGuest, email: value };
        }
    };

    changeNewGuestPassword = value => {
        this.newGuest = { ...this.newGuest, password: value };
    };

    changeNewGuestName = value => {
        if (this.newGuest.name.length < 50) {
            this.newGuest = { ...this.newGuest, name: value };
        }
    };

    changeNewGuestSmartPenMacAddress = (value, index) => {
        console.log(logPrefix, 'changeNewGuestSmartPenMacAddress : >>', value, index);
        const address = this.newGuest.smartPenAddress.slice();
        address[index] = value.toUpperCase();
        this.newGuest = { ...this.newGuest, smartPenAddress: address.slice() };
    };

    getResponseFileName = contentDisposition => {
        let fileName = 'unknown';
        if (contentDisposition) {
            const findFileName = decodeURIComponent(contentDisposition)
                .split(';')
                .filter(value => value.indexOf('filename') > -1)
                .map(value => value.replace(/"/g, '').split('=')[1]);
            if (findFileName[0]) fileName = findFileName[0];
        }

        return fileName;
    };

    *checkExistSmartPenAddress(requestUserEmail, callbacks) {
        console.log(logPrefix, 'Start checkExistSmartPenAddress = ', this.newGuest.smartPenAddress.join('').trim());
        const penAddress = this.newGuest.smartPenAddress.join('').trim();
        if (penAddress === '' || penAddress === null || penAddress.length < 6) {
            callbacks && callbacks.alert();
            return false;
        }
        try {
            const response = yield axios.get(this.serverContextPath + `${MANAGEMENT_API_URL}/guest/exist/smartpen`, {
                params: { requestUserEmail: requestUserEmail, penAddress: this.newGuest.smartPenAddress.join('') },
            });

            console.log(logPrefix, 'Success checkExistSmartPenAddress');
            console.log(logPrefix, 'Result = ', response.data);

            if (!response.data) {
                callbacks && callbacks.info();
                this.isAvailableSmartPen = true;
            } else {
                callbacks && callbacks.alert();
            }
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed checkExistEmail', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed checkExistEmail', e.request);
            } else {
                console.log(logPrefix, 'Failed checkExistEmail', e.message);
            }
            callbacks && callbacks.error();
        }
    }

    *checkExistEmail(requestUserEmail, callbacks) {
        console.log(logPrefix, 'Start checkExistEmail');
        if (this.newGuest.email.trim() === '' || this.newGuest.email === null) {
            callbacks.error();
            return false;
        }

        const checkEmail = `${this.newGuest.email}${DEFAULT_GUEST_EMAIL_ADDRESS}`;
        try {
            const response = yield axios.get(this.serverContextPath + `${MANAGEMENT_API_URL}/guest/exist/email`, {
                params: { requestUserEmail: requestUserEmail, checkEmail: checkEmail },
            });

            console.log(logPrefix, 'Success checkExistEmail');
            console.log(logPrefix, 'Result = ', response.data);
            this.isAvailableEmail = response.data;
            if (response.data) {
                callbacks && callbacks.info();
            } else {
                callbacks && callbacks.alert();
            }
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed checkExistEmail', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed checkExistEmail', e.request);
            } else {
                console.log(logPrefix, 'Failed checkExistEmail', e.message);
            }
        }
    }

    *getAllUsers(leaderEmail, page, rowsPerPage, sort, isNoClassFilter, searchKeyword, callbacks) {
        console.log(logPrefix, 'Start getAllUsers searchKeyword: >> ', searchKeyword);
        this.allUsersLoadingState = LOADING_STATE.PENDING;
        try {
            const response = yield axios.get(this.serverContextPath + `${MANAGEMENT_API_URL}/all/guests`, {
                params: {
                    leaderEmail: leaderEmail,
                    page: page,
                    rowsPerPage: rowsPerPage,
                    sort: sort,
                    isFiltered: isNoClassFilter,
                    searchKeyword: searchKeyword,
                },
            });

            console.log(logPrefix, 'Success getAllUsers ');
            console.log(logPrefix, 'Result = {} ', response.data);

            const responseAllUsers = response.data.myGroupUsers;
            const responseTotalCount = response.data.myGroupUsersTotalCount;

            this.allUsersTotalCount = responseTotalCount;
            callbacks && callbacks.setTotalCount(responseTotalCount);

            this.avatarStore.setAvatars(responseAllUsers).then(r => {
                console.log(logPrefix, 'responseAllUsers : >> ', r);
                this.allUsers = r;
            });

            this.allUsersLoadingState = LOADING_STATE.SUCCESS;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getAllUsers', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getAllUsers', e.request);
            } else {
                console.log(logPrefix, 'Failed getAllGroupMembersByLeader', e.message);
            }

            this.allUsersLoadingState = LOADING_STATE.FAILED;
        } finally {
            this.allUsersLoadingState = LOADING_STATE.READY;
        }
    }

    *getAllUsersForSearch(leaderEmail, callbacks) {
        console.log(logPrefix, 'Start getAllUsersForSearch : >> ', leaderEmail);

        try {
            const response = yield axios.get(this.serverContextPath + `${MANAGEMENT_API_URL}/all/guests`, {
                params: { leaderEmail: leaderEmail },
            });

            console.log(logPrefix, 'Success getAllUsersForSearch ');
            console.log(logPrefix, 'Result = {} ', response.data);
            const responseAllUsers = response.data.myGroupUsers;

            if (responseAllUsers.length) {
                this.avatarStore.setAvatars(responseAllUsers).then(r => {
                    console.log(logPrefix, 'responseAllUsers for search : >> ', r);
                    this.allUsersForSearch = r;
                });
            }
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getAllUsersForSearch', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getAllUsersForSearch', e.request);
            } else {
                console.log(logPrefix, 'Failed getAllUsersForSearch', e.message);
            }
        }
    }
    // *getAllGroupMembersByLeader(leaderEmail, page, rowsPerPage, callbacks) {
    //     console.log(logPrefix, 'Start getAllGroupMembersByLeader : >> ', leaderEmail);
    //
    //     try {
    //         const response = yield axios.get(this.serverContextPath + `${MANAGEMENT_API_URL}/mygroup/all/users`, {
    //             params: { leaderEmail: leaderEmail, page: page, rowsPerPage: rowsPerPage },
    //         });
    //
    //         console.log(logPrefix, 'Success getAllGroupMembersByLeader ');
    //         console.log(logPrefix, 'Result = {} ', response.data);
    //
    //         this.allMembersMyGroup = response.data.myGroupUsers;
    //         callbacks && callbacks.setTotalCount(response.data.myGroupUsersTotalCount);
    //     } catch (e) {
    //         if (e.response) {
    //             console.log(logPrefix, 'Failed getAllGroupMembersByLeader', e.response.data);
    //             console.log(' >> ', e.response.status);
    //             console.log(' >> ', e.response.headers);
    //         } else if (e.request) {
    //             console.log(logPrefix, 'Failed getAllGroupMembersByLeader', e.request);
    //         } else {
    //             console.log(logPrefix, 'Failed getAllGroupMembersByLeader', e.message);
    //         }
    //     }
    // }

    *createGuest(userEmail, callbacks) {
        console.log(logPrefix, 'Start createGuest : >> ', userEmail);
        this.newGuest.email = `${this.newGuest.email}${DEFAULT_GUEST_EMAIL_ADDRESS}`;
        const data = { ...this.newGuest, smartPenAddress: this.newGuest.smartPenAddress.join('') };
        try {
            const response = yield axios.post(this.serverContextPath + `${MANAGEMENT_API_URL}/create/guest`, data, {
                params: { userEmail: userEmail },
            });

            console.log(logPrefix, 'Success createGuest ');
            console.log(logPrefix, 'Result = {} ', response.data);
            callbacks && callbacks.closeDialog();
            callbacks && callbacks.getAllUsers();
            callbacks && callbacks.initCoachMarkState && callbacks.initCoachMarkState();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed createGuest', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed createGuest', e.request);
            } else {
                console.log(logPrefix, 'Failed createGuest', e.message);
            }
            callbacks && callbacks.error();
        }
    }

    *resetGuestPassword(requestUserEmail, resetGuest, callbacks) {
        console.log(logPrefix, `Start resetGuestPassword requestUserEmail = ${requestUserEmail}`);
        console.log(logPrefix, `Start resetGuestPassword resetGuest = `, resetGuest);

        try {
            const response = yield axios.put(this.serverContextPath + `${MANAGEMENT_API_URL}/reset/password/${requestUserEmail}`, resetGuest);

            callbacks && callbacks.info();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed resetGuestPassword', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed resetGuestPassword', e.request);
            } else {
                console.log(logPrefix, 'Failed resetGuestPassword', e.message);
            }
            callbacks && callbacks.error();
        }
    }

    *modifyGuestSmartPenAddress(requestUserEmail, modifyGuest, callbacks) {
        console.log(logPrefix, 'Start modifyGuestSmartPenAddress');

        const param = this.newGuest.smartPenAddress.join('');
        try {
            const response = yield axios.put(
                this.serverContextPath + `${MANAGEMENT_API_URL}/modify/smartpenaddress/${requestUserEmail}`,
                modifyGuest,
                {
                    params: { penAddress: param },
                },
            );
            callbacks && callbacks.getAllUsers();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed modifyGuestSmartPenAddress', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed modifyGuestSmartPenAddress', e.request);
            } else {
                console.log(logPrefix, 'Failed modifyGuestSmartPenAddress', e.message);
            }
            callbacks && callbacks.error();
        }
    }

    *requestAllUsersExcelExport(requestUserEmail, callbacks) {
        console.log(logPrefix, 'Start requestAllUsersExcelExport requestUserEmail = ', requestUserEmail);
        this.excelLoadingState = LOADING_STATE.PENDING;
        try {
            const response = yield axios.get(this.serverContextPath + `${MANAGEMENT_API_URL}/excel/user/all`, {
                params: { parentUserEmail: requestUserEmail },
                headers: { 'Content-Type': 'application/vnd.ms-excel' },
                responseType: 'arraybuffer',
            });
            const file = response.data;
            const fileName = this.getResponseFileName(response.headers['content-disposition']);
            console.log(logPrefix, 'Success requestAllUsersExcelExport', fileName);
            fileDownload(file, fileName);

            this.excelLoadingState = LOADING_STATE.SUCCESS;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed requestAllUsersExcelExport', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestAllUsersExcelExport', e.request);
            } else {
                console.log(logPrefix, 'Failed requestAllUsersExcelExport', e.message);
            }
            callbacks && callbacks.error();

            this.excelLoadingState = LOADING_STATE.FAILED;
        } finally {
            this.excelLoadingState = LOADING_STATE.READY;
        }
    }

    *requestGroupUsersExcelExport(requestUserEmail, groupId, callbacks) {
        console.log(logPrefix, 'Start requestAllUsersExcelExport');
        this.excelLoadingState = LOADING_STATE.PENDING;
        try {
            const response = yield axios.get(this.serverContextPath + `${MANAGEMENT_API_URL}/excel/user/group/${groupId}`, {
                params: { parentUserEmail: requestUserEmail },
                headers: { 'Content-Type': 'application/vnd.ms-excel' },
                responseType: 'arraybuffer',
            });

            const file = response.data;
            const fileName = this.getResponseFileName(response.headers['content-disposition']);
            console.log(logPrefix, 'Success requestGroupUsersExcelExport', fileName);
            fileDownload(file, fileName);

            this.excelLoadingState = LOADING_STATE.SUCCESS;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed requestAllUsersExcelExport', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestAllUsersExcelExport', e.request);
            } else {
                console.log(logPrefix, 'Failed requestAllUsersExcelExport', e.message);
            }
            this.excelLoadingState = LOADING_STATE.FAILED;
            callbacks && callbacks.error();
        } finally {
            this.excelLoadingState = LOADING_STATE.READY;
        }
    }

    *createGuests(guests, requestUserEmail, callbacks) {
        console.log(logPrefix, 'Start createGuest : >> ', requestUserEmail);
        const data = [...guests];
        try {
            const response = yield axios.post(this.serverContextPath + `${MANAGEMENT_API_URL}/create/guests`, data, {
                params: { userEmail: requestUserEmail },
            });

            const createFailedGuestEmails = response.data;
            console.log(logPrefix, 'Success createGuests ');
            console.log(logPrefix, 'Result = {} ', createFailedGuestEmails);
            if (!createFailedGuestEmails.length) {
                callbacks && callbacks.success();
            } else {
                callbacks && callbacks.alert(createFailedGuestEmails);
            }

            callbacks && callbacks.getAllUsers();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed createGuests', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed createGuests', e.request);
            } else {
                console.log(logPrefix, 'Failed createGuests', e.message);
            }
            callbacks && callbacks.error();
        }
    }
}
