import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Slider, Typography, Box, Tooltip } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import makeStyles from '@material-ui/styles/makeStyles';

import { useResizeDetector } from 'react-resize-detector';
// import { useLocation } from "react-router-dom";
// import { LessonStudyAnalyzer } from "../../../../nl-edu/analyzer/LessonStudyAnalyzerMain";
// import { uploadStrokesToInkstore } from "../../../../nl-edu/data_clients/upload_strokes";
// import { parseQuery } from "../../../../nl-edu/data_hooks/parseQuery";
// import { useTeacher } from "../../../../nl-edu/players/useTeacher";
import {
    getDateTimeString,
    getTimeString,
    getTimeStringFormatted,
    InkStorage,
    isSameDay,
    makeNPageIdStr,
    PenEventName,
    PLAYSTATE,
    ZoomFitEnum,
} from 'nl-lib';
// } from '../../nl-lib';
// import { getElementPosition } from "../../../../nl-lib/common/util/getElementPosition";
// import { ShowErrorToast, ShowInfoToast } from "../../../redux/reducers/ui";
// import { NeoTheme } from "../../../theme/theme";
// import { useStudentId } from "../__hooks/useCurrStudentInfo";
import NeoStrokeGraph from './NeoStrokeGraph';
import clsx from 'clsx';
import { ReactComponent as SkipBack } from '../../common/images/SkipBack.svg';
import { ReactComponent as SkipForward } from '../../common/images/SkipForward.svg';
import { ReactComponent as Play } from '../../common/images/Play.svg';
import { ReactComponent as Pause } from '../../common/images/Pause.svg';

export const playBarHeight = 110;

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiSlider-markLabel[data-index="0"]': {
            transform: 'translateX(0%)',
        },
        '& .MuiSlider-markLabel[data-index="1"]': {
            transform: 'translateX(-100%)',
        },
    },
    bottom: {
        // marginTop: "auto",
        // position: "absolute",
        bottom: 0,
        // width: "100%",
        height: playBarHeight,
        // backgroundColor: theme.palette.background.paper, //default or paper
        zIndex: 1100,
    },
    itembar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 60,
        borderBottom: '1px solid #c0c2c3',
    },
    numberText: {
        fontSize: '0.75rem',
        color: '#656565',
    },
    margin: {
        margin: '0 10px',
    },
    bold: {
        color: '#000',
    },
    icon: {
        margin: 'auto 0',
        '&:hover': {
            background: 'transparent',
        },
    },
    iconText: {
        '& p': {
            fontSize: '0.75rem',
            fontWeight: 600,
            color: '#000',
        },
    },
    textButton: {
        margin: 'auto 0',
        height: 32,
        color: 'inherit',
    },
    valueLabel: {
        '& > span > span': {
            color: 'black',
            fontWeight: 500,
            textAlign: 'center',
            fontSize: '15px',
        },
    },
    thumb: {
        color: '#ea0',
    },
    markLabel: {
        '&[data-index="0"]': {
            transform: 'translateX(0%)',
        },
        '&[data-index="1"]': {
            transform: 'translateX(-100%)',
        },
    },
    subheader: {
        textTransform: 'capitalize',
    },
    labelStyleOuter: {
        width: '30px',
        height: '30px',
        borderRadius: '50% 50% 50% 0',
        background: 'rgba(0,188,212,1)',
        position: 'absolute',
        transform: 'rotate(-45deg)',
        top: '-40px',
        left: '-9px',
    },
    labelStyleInner: {
        transform: 'rotate(45deg)',
        color: 'white',
        textAlign: 'center',
        position: 'relative',
        top: '3px',
        right: '0px',
        fontSize: '10px',
    },
    lightTooltip: {
        backgroundColor: '#FFFFF5',
        color: '#000',
        border: '1px solid #000',
        fontSize: '0.688rem',
        borderRadius: 0,
        marginLeft: 0,
        marginTop: 15,
    },
    slider: {
        paddingRight: 25,
        paddingLeft: 25,
        height: 49,
        width: '100%',
        backgroundColor: '#ffffff',
        boxSizing: 'border-box',
        borderBottom: '1px solid #c0c2c3',
    },
}));
const timeOut = n => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve(true);
        }, n);
    });
};
export function getPageTitleForController(sobp) {
    // if (analyzer) return analyzer.getPageName(sobp);
    return makeNPageIdStr(sobp);
}
export default function PlayController(props) {
    // const location = useLocation();
    // const paramMap = parseQuery(location.search);
    // const { eduClassId, curriculumId } = paramMap;
    // const analyzerHook = useStudyData({ eduClassId, curriculumId, section: paramMap.section, owner: paramMap.owner, book: paramMap.book, ncodeStartPage: paramMap.ncodeStartPage, ncodeEndPage: paramMap.ncodeEndPage, });
    // const [analyzer, setAnalyzer] = useState(undefined as LessonStudyAnalyzer);
    // 2021/12/27
    const [shouldUploadStrokes, setShouldUploadStrokes] = useState(false);
    const [uploadedStrokesSerial, setUploadedStrokesSerial] = useState(0);
    const [numUnsyncedStrokes, setNumUnsyncedStrokes] = useState(0);
    // const studentId = useStudentId();
    // const { teacher } = useTeacher();
    useEffect(() => {
        const is = InkStorage.getInstance();
        is.addEventListener(PenEventName.ON_PEN_UP, onStoragePenUp, undefined);
        return function unsubscribe() {
            is.removeEventListener(PenEventName.ON_PEN_UP, onStoragePenUp);
        };
    }, []);
    const onStoragePenUp = event => {
        setShouldUploadStrokes(true);
        const is = InkStorage.getInstance();
        setNumUnsyncedStrokes(is.strokes.length - uploadedStrokesSerial);
    };
    const uploadStrokes = () => {
        // if (!shouldUploadStrokes) return;
        // const is = InkStorage.getInstance();
        //
        // // 로컬 잉크스토리지에 있는 것들 모두
        // const strokes = is.strokes.filter(st => !st.uploaded);
        // uploadStrokesToInkstore(strokes).then((ret) => {
        //   if (ret.ok) {
        //     setUploadedStrokesSerial(is.strokes.length);
        //     setNumUnsyncedStrokes(is.strokes.length - uploadedStrokesSerial);
        //
        //     setShouldUploadStrokes(false);
        //
        //     // 재로드
        //     if (props.reloadStrokeData) {
        //       props.reloadStrokeData();
        //     }
        //     ShowInfoToast("첨삭 정보를 업로드했습니다.");
        //   }
        //   else {
        //     ShowErrorToast("첨삭 정보가 업로드되지 않았습니다. 다시 시도해 주십시오.");
        //   }
        // })
    };
    // useEffect(() => {
    //   if (props.analyzer) {
    //     setAnalyzer(props.analyzer);
    //   }
    // }, [props.analyzer]); //, analyzerHook]);
    // useEffect(() => {
    //   if (analyzer) {
    //     strokeGraphElem?.setAnalyzer(analyzer);
    //   }
    // }, [analyzer]); //, analyzerHook]);
    const [strokeGraphElem, setStrokeGraphElem] = useState(null);
    const { width: graph_width, height: graph_height, ref: graph_ref } = useResizeDetector();
    // 학생 데이터를 읽어 오기
    const [replayStorage, setReplayStorage] = useState(props.replayStorage);
    const [autoStop, setAutoStop] = useState(props.autoStop);
    const graph_width_queue = useRef([]);
    useEffect(() => {
        if (strokeGraphElem) {
            graph_width_queue.current.push(graph_width);
            timeOut(1).then(() => {
                const last_width = graph_width_queue.current[graph_width_queue.current.length - 1];
                graph_width_queue.current = graph_width_queue.current.splice(1);
                const width_check = last_width && graph_width !== last_width;
                if (!width_check) {
                    strokeGraphElem.resizeCanvas({ width: graph_width, height: graph_height });
                    graph_width_queue.current = [];
                }
            });
        }
    }, [graph_width]);
    useEffect(() => {
        if (props.deltaTime !== undefined && props.replayStorage) {
            const ratio = props.deltaTime / props.replayStorage.duration;
            setPlayTimeRatio(ratio * 100);
        }
    }, [props.deltaTime, props.replayStorage]);
    // 2021/12/28
    const [duration, setDuration] = useState(1);
    const [beginAbsTime, setBeginAbsTime] = useState(0);
    const [endAbsTime, setEndAbsTime] = useState(0);
    useEffect(() => {
        if (props.replayStorage) {
            // 2021/12/28
            const realBeginTime = props.replayStorage?.startTime || 0;
            let dTime = props.replayStorage?.duration || 0;
            const realEndTime = realBeginTime + dTime;
            const beginTime = realBeginTime + 1500000;
            setBeginAbsTime(beginTime);
            if (beginTime + dTime > realEndTime) {
                dTime = Math.max(0, realEndTime - beginTime + 1);
            }
            setEndAbsTime(beginTime + dTime);
            setDuration(dTime);
            // stroke graph
            const sg = new NeoStrokeGraph('stroke_graph', props.replayStorage);
            sg.init({ width: graph_width, height: graph_height });
            setStrokeGraphElem(sg);
            sg.setTimeRange({
                begin: beginAbsTime,
                end: endAbsTime,
            });
        }
    }, [props.replayStorage]);
    useEffect(() => {
        if (strokeGraphElem) {
            strokeGraphElem.setTimeRange({
                begin: beginAbsTime,
                end: endAbsTime,
            });
        }
    }, [beginAbsTime, endAbsTime]);
    useEffect(() => {
        if (strokeGraphElem) {
            strokeGraphElem.setActiveChunk(props.activeChunk);
        }
    }, [props.activeChunk]);
    const beginStr = getDateTimeString(beginAbsTime);
    const lastStr = isSameDay(beginAbsTime, endAbsTime) ? getTimeString(endAbsTime) : getDateTimeString(endAbsTime);
    const [playTimeRatio, setPlayTimeRatio] = useState(0);
    const handleSliderChanged = (event, newValue) => {
        // console.log(newValue);
        setPlayTimeRatio(newValue);
        const playTime = (newValue * duration) / 100;
        props.playTimeHandler(playTime);
    };
    const valuetext = value => {
        return `${value}:${value}:${value}°C`;
    };
    const getSliderString = slider_val => {
        const time_ms = (slider_val * duration) / 100;
        const timeStr = getTimeStringFormatted(time_ms, 'hh:mm:ss');
        return timeStr;
    };
    const classes = useStyles();
    const widthDown = false; // isWidthDown("sm", props.width as Breakpoint);
    const zoomIn = () => {
        if (props.onZoomInOut) props.onZoomInOut(true);
    };
    const zoomOut = () => {
        if (props.onZoomInOut) props.onZoomInOut(false);
    };
    const zoomAtWidth = () => {
        if (props.onFitChanged) props.onFitChanged(ZoomFitEnum.WIDTH);
    };
    const zoomAtHeight = () => {
        if (props.onFitChanged) props.onFitChanged(ZoomFitEnum.FULL);
    };
    const replay = () => {
        if (props.onReplayStateChanged) props.onReplayStateChanged(PLAYSTATE.play);
    };
    const stop = () => {
        if (props.onReplayStateChanged) props.onReplayStateChanged(PLAYSTATE.stop);
    };
    const rewind = () => {
        if (props.onReplayStateChanged) props.onReplayStateChanged(PLAYSTATE.rewind);
    };
    const marks = [
        {
            value: 0,
            label: beginStr,
        },
        {
            value: 100,
            label: lastStr,
        },
    ];
    let pageStr = '';
    if (props.replayStorage && props.activeChunk !== undefined) {
        const pageInfo = props.replayStorage.getChunkPageInfo(props.activeChunk);
        pageStr = getPageTitleForController(pageInfo);
        // pageStr = makeNPageIdStr(pageInfo);
    }
    // console.log(`PlayState = ${props.playState} `);
    // 2021/12/29
    const sliderRef = useRef(null);
    const onMouseWheel = e => {
        // // e.preventDefault();
        // const { top, left } = getElementPosition(sliderRef.current);
        // const deltaX = e.pageX - left;
        // let percent = deltaX / sliderRef.current.clientWidth;
        // if (percent < 0) percent = 0;
        // if (percent > 1) percent = 1;
        // const d0 = endAbsTime - beginAbsTime;
        // const pointedTime = beginAbsTime + d0 * percent;
        // let d1: number;
        // if (e.deltaY < 0) {
        //   d1 = d0 * 0.8333333333333;
        // }
        // else {
        //   d1 = d0 * 1.2;
        // }
        // let x0 = pointedTime - (d1 * percent);
        // let x1 = pointedTime + (d1 * (1 - percent));
        // const rs = props.replayStorage;
        // if (rs) {
        //   if (x0 < rs.startTime) x0 = rs.startTime;
        //   if (x1 > rs.endTime + 10) x1 = rs.endTime + 10;
        // }
        // setBeginAbsTime(x0);
        // setEndAbsTime(x1);
    };
    // 그래프 드래그를 위한 루틴
    const [capturedX, setCapturedX] = useState(0);
    const [dragging, setDragging] = useState(false);
    const onMouseDown = e => {
        // const { top, left } = getElementPosition(sliderRef.current);
        // const x = e.pageX - left;
        // setCapturedX(x);
        // setDragging(true);
    };
    const onMouseMove = e => {
        // if (dragging) {
        //   const { top, left } = getElementPosition(sliderRef.current);
        //   const x = e.pageX - left;
        //   let dx = x - capturedX;
        //   let d1 = dx, d0 = dx;
        //   setCapturedX(x);
        //   const ratio = (endAbsTime - beginAbsTime) / graph_width;
        //   let x0 = beginAbsTime - dx * ratio;
        //   let x1 = endAbsTime - dx * ratio;
        //   const rs = props.replayStorage;
        //   if (rs) {
        //     if (x0 < rs.startTime) d0 = (x0 - rs.startTime) / ratio;
        //     if (x1 > rs.endTime + 10) d1 = (rs.endTime + 10 - x1) / ratio;
        //     dx = Math.min(dx, d0, d1);
        //   }
        //   setBeginAbsTime(beginAbsTime - dx * ratio);
        //   setEndAbsTime(endAbsTime - dx * ratio);
        // }
    };
    const onMouseUp = e => {
        // setCapturedX(0);
        // setDragging(false);
    };
    return (
        <div className={classes.bottom} onWheel={onMouseWheel} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
            <div className={classes.itembar}>
                {/* <Checkbox checked={autoStop} onChange={(e) => setAutoStop(!autoStop)} name="autoStop" />
        <Typography align="left" style={{ width: 150, margin: "auto 0" }} >
          {pageStr}
        </Typography> */}
                <Tooltip
                    title="재생 시간"
                    placement="bottom"
                    classes={{
                        tooltip: classes.lightTooltip,
                    }}
                >
                    <Box display="flex" alignItems="center">
                        <Box className={classes.icon} aria-label="playingTime">
                            <Typography className={classes.numberText} variant="body2">
                                {' '}
                                {props.caption}
                            </Typography>
                        </Box>
                        <Typography className={clsx(classes.numberText, classes.margin)}>/</Typography>
                        <Box className={classes.icon} aria-label="playingTime">
                            <Typography className={clsx(classes.numberText, classes.bold)} variant="body2">
                                {' '}
                                {props.caption}
                            </Typography>
                        </Box>
                    </Box>
                </Tooltip>

                {/*<IconButton className={classes.icon} aria-label="playingTime">*/}
                {/*    <Typography> {`${pageStr}`}</Typography>*/}
                {/*</IconButton>*/}

                <Box display="flex" alignItems="center" style={{ margin: '0 30px' }}>
                    <Tooltip
                        title="처음부터 재생"
                        placement="bottom"
                        classes={{
                            tooltip: classes.lightTooltip,
                        }}
                    >
                        <IconButton className={classes.icon} aria-label="Rewind" onClick={rewind}>
                            <SkipBack />
                        </IconButton>
                    </Tooltip>

                    <IconButton
                        className={classes.icon}
                        aria-label="Play"
                        onClick={e => {
                            if (props.playState === PLAYSTATE.play) {
                                stop();
                            } else {
                                replay();
                            }
                        }}
                    >
                        {props.playState !== PLAYSTATE.play ? <Play /> : <Pause />}
                    </IconButton>

                    {/*<Tooltip*/}
                    {/*    title="다음 시간"*/}
                    {/*    placement="bottom"*/}
                    {/*    classes={{*/}
                    {/*        tooltip: classes.lightTooltip,*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <IconButton className={classes.icon}>*/}
                    {/*        <SkipForward />*/}
                    {/*    </IconButton>*/}
                    {/*</Tooltip>*/}
                </Box>
                <Tooltip
                    title="재생 속도"
                    placement="bottom"
                    classes={{
                        tooltip: classes.lightTooltip,
                    }}
                >
                    <IconButton
                        className={clsx(classes.icon, classes.iconText)}
                        aria-label="replaySpeed"
                        onClick={e => {
                            props.replaySpeedHandler();
                        }}
                    >
                        <Typography> {'x ' + props.replaySpeed}</Typography>
                    </IconButton>
                </Tooltip>

                {/* <IconButton className={classes.icon} aria-label="ZoomOut" onClick={zoomOut} >
          <Icon.Remove />
        </IconButton>

        <Typography variant="button" align="center" style={{ width: 50, margin: "auto 0" }} >
          {props.scale + "%"}
        </Typography>

        <IconButton className={classes.icon} aria-label="ZoomIn" onClick={zoomIn} >
          <Icon.Add />
        </IconButton> */}

                {/*<IconButton className={classes.icon} onClick={zoomAtHeight}>*/}
                {/*    <Icon.Fullscreen />*/}
                {/*</IconButton>*/}

                {/*{!widthDown && (*/}
                {/*    <IconButton className={classes.icon} onClick={zoomAtWidth}>*/}
                {/*        <Icon.ZoomOutMapRounded />*/}
                {/*    </IconButton>*/}
                {/*)}*/}

                {/*<Button*/}
                {/*    className={classes.textButton}*/}
                {/*    onClick={uploadStrokes}*/}
                {/*    disabled={!shouldUploadStrokes}*/}
                {/*    variant={shouldUploadStrokes ? 'contained' : 'outlined'}*/}
                {/*>*/}
                {/*    /!*{`첨삭 업로드 (${studentId})`}*!/*/}
                {/*</Button>*/}

                {/*
        <IconButton className={classes.icon} aria-label="Stop" onClick={stop} >
          <Icon.Stop />
        </IconButton> */}
            </div>

            <div id="slider_container" className={classes.slider}>
                <div
                    style={{
                        position: 'relative',
                        top: 0,
                        width: '100%',
                        height: 49,
                        zIndex: 2,
                    }}
                    onWheel={onMouseWheel}
                >
                    <Slider
                        id="sliderControl"
                        ref={sliderRef}
                        step={0.05}
                        value={playTimeRatio}
                        onChange={handleSliderChanged}
                        valueLabelDisplay="auto"
                        aria-labelledby="continuous-slider"
                        // getAriaValueText={valuetext}
                        getAriaLabel={getSliderString}
                        valueLabelFormat={getSliderString}
                        marks={marks}
                        classes={{ valueLabel: classes.valueLabel, markLabel: classes.markLabel, thumb: classes.thumb }}
                        onWheel={onMouseWheel}
                    />
                </div>

                <div
                    style={{
                        position: 'relative',
                        top: -45,
                        width: '100%',
                        height: 25,
                        backgroundColor: 'rgba(255,0,0,0.0)',
                        zIndex: 1,
                    }}
                    ref={graph_ref}
                    onWheel={onMouseWheel}
                >
                    <canvas id="stroke_graph" style={{ height: playBarHeight }} />
                </div>
            </div>
        </div>
    );
}
