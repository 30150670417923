import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Drawer, IconButton } from '@material-ui/core';
import { ReactComponent as Close } from '../../../../common/images/Close.svg';
import MobileNeoPenScreenWriteComponent from './neoPenScreenWrite/MobileNeoPenScreenWriteComponent';

const styles = theme => ({
    root: {
        position: 'relative',
    },
    drawerBox: {
        '& .MuiDrawer-paper': {
            overflow: 'visible',
            borderRadius: '0 10px 10px 0',
            boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.25)',
            padding: '10px 10px 20px',
            boxSizing: 'border-box',
            '& svg': {
                width: 20,
                height: 20,
                '& path': {
                    strokeWidth: 2,
                },
            },
            '@media all and (min-width: 600px)': {
                '& svg': {
                    width: 30,
                    height: 30,
                },
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    drawerWrap: {
        overflow: 'visible',
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
});

class MobileScreenWriteDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes, screenWriteDrawer, handleCloseDrawer } = this.props;

        return (
            <div className={classes.root}>
                <Drawer anchor="left" open={screenWriteDrawer} onClose={handleCloseDrawer} className={classes.drawerBox}>
                    <div className={classes.drawerWrap}>
                        <Box display="flex" justifyContent="flex-end">
                            <IconButton className={classes.iconButton} onClick={handleCloseDrawer} disableRipple>
                                <Close />
                            </IconButton>
                        </Box>
                        <Box>
                            <MobileNeoPenScreenWriteComponent />
                        </Box>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default withStyles(styles)(MobileScreenWriteDrawer);
