import React, { Component } from 'react';
import { Box, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import ExpireTime from './ExpireTime';
import InvitationCalendarShape from './InvitationCalendarShape';
import { DATE_UTIL } from '../../common/util/date.util';
import { ToastsStore } from 'react-toasts';

const style = theme => ({
    root: {
        padding: 15,
        borderRadius: 10,
        display: 'flex',
        '& .MuiButton-root': {
            minWidth: 0,
        },
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        fontSize: 14,
        fontWeight: 800,
        textAlign: 'center',
    },
    buttonStyle: {
        padding: '7px 23px',
        borderRadius: 6,
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        fontFamily: 'NanumSquareRoundOTF',
        color: '#fff',
        fontSize: 14,
        '&:hover': {
            fontWeight: 800,
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            padding: '5px 23px',
            borderRadius: 5,
            fontSize: '0.75rem',
        },
    },
    titleStyle1: {
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        fontSize: 14,
        fontWeight: 600,
    },
});

export const AM_PM = {
    AM: 'AM',
    PM: 'PM',
};

class InvitationCalendarBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expireDatetime: {
                date: dayjs(new Date(DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT)).setHours(0, 0, 0)).tz(
                    DATE_UTIL.getTimeZone(),
                    true,
                ),
                hour: '',
                min: '',
                am: AM_PM.AM,
            },
            endDatetime: {
                hour: '',
                min: '',
            },
            checkNull: false,
        };
    }

    onChangeDate = props => {
        this.setState({ expireDatetime: { ...this.state.expireDatetime, date: props } });
    };

    onChangeStartTime = (time, minute, radio) => {
        this.setState({ expireDatetime: { ...this.state.expireDatetime, hour: time, min: minute, am: radio } }, this.InvalidationDate);
    };

    onChangeStartMin = minute => {
        this.setState({ expireDatetime: { ...this.state.expireDatetime, min: minute } }, this.InvalidationDate);
    };

    onChangeAm = radio => {
        this.setState({ expireDatetime: { ...this.state.expireDatetime, am: radio } });
    };

    InvalidationDate = () => {
        let checkNull = false;
        if (this.state.expireDatetime.hour === '' || this.state.expireDatetime.hour === '시간') {
            checkNull = true;
        } else if (this.state.expireDatetime.min === '' || this.state.expireDatetime.min === '분') {
            checkNull = true;
        } else if (this.state.endDatetime.hour === '' || this.state.endDatetime.hour === '시간') {
            checkNull = true;
        } else if (
            this.state.endDatetime.min === '' ||
            (this.state.endDatetime.hour === '00' && (this.state.endDatetime.min === '00' || this.state.endDatetime.min === ''))
        ) {
            checkNull = true;
        }
        if (!checkNull) {
            this.props.roomStore.onChangeTemporaryDate(this.state, this.props.intl);
        }
        this.setState({ checkNull: checkNull });
    };

    handleSave = () => {
        const { expireDatetime } = this.state;
        const convertedHourToNumber = Number(expireDatetime.hour);
        const calcHour =
            expireDatetime.am === AM_PM.PM
                ? convertedHourToNumber < 12
                    ? convertedHourToNumber + 12
                    : convertedHourToNumber
                : convertedHourToNumber === 12
                ? convertedHourToNumber - 12
                : convertedHourToNumber;
        const convertedSelectedExpireDate = DATE_UTIL.getTimeZoneDate(expireDatetime.date, DATE_UTIL.FORMAT.DATE_FORMAT);
        const convertedExpireDatetime = `${convertedSelectedExpireDate} ${calcHour.toString().padStart(2, '0')}:${expireDatetime.min}`;
        if (DATE_UTIL.getTimeZoneDateNoFormat(convertedExpireDatetime).isBefore(DATE_UTIL.getNow())) {
            ToastsStore.warning('만료시각은 현재시간 이후로 설정할 수 있습니다.', 1500);
        } else {
            const { loginUser } = this.props.authStore;
            const invitationExpireDatetime = DATE_UTIL.convertTimeZoneToUTC(convertedExpireDatetime);
            this.props.classStore.setSelectedInviteExpireDatetime(invitationExpireDatetime);
            this.props.classStore.modifyInviteAuthenticationExpireDatetime(loginUser.email, invitationExpireDatetime, {
                closeCalendar: () => this.props.onClickSave(),
            });
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <InvitationCalendarShape onChangeDate={this.onChangeDate} />
                <Box style={{ paddingLeft: 15 }}>
                    <ExpireTime onChangeStartTime={this.onChangeStartTime} onChangeStartMin={this.onChangeStartMin} onChangeAm={this.onChangeAm} />
                    <Box display="flex" justifyContent="center" pt={2}>
                        <Button className={classes.buttonStyle} onClick={() => this.handleSave(this.state)}>
                            완료
                        </Button>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(inject('classStore', 'authStore')(observer(InvitationCalendarBox))));
