import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import { DATE_UTIL } from '../../../common/util/date.util';

const style = theme => ({
    root: {
        // 달력
        '& .react-calendar': {
            width: 250,
            maxWidth: 250,
            borderRadius: 16,
            border: '1px solid #d7dbdf',
            padding: '10px 5px',
        },
        '& .react-calendar__navigation__label__labelText': {
            fontFamily: 'NanumSquareRoundOTF',
            color: '#000',
            fontSize: 15,
            fontWeight: 800,
        },
        '& .react-calendar__navigation button:enabled:hover': {
            background: 'transparent',
        },
        '& .react-calendar__month-view__weekdays__weekday': {
            '& abbr': {
                fontFamily: 'NanumSquareRoundOTF',
                fontSize: 14,
                color: '#4f5660',
                fontWeight: 'normal',
                textDecoration: 'none',
            },
        },
        '& .react-calendar__month-view__days__day': {
            borderRadius: '50%',
            '& abbr': {
                fontFamily: 'NanumSquareRoundOTF',
                fontSize: 12,
                fontWeight: 800,
            },
        },
        '& .react-calendar__navigation button[disabled]': {
            background: 'transparent',
        },
        '& .react-calendar__tile': {
            lineHeight: '13px',
        },
        '& .react-calendar__tile--now': {
            background: '#fff',
            color: 'black',
        },
        '& .react-calendar__tile--now && today': {
            background: '#006edc !important',
            color: 'black',
        },
        '& .react-calendar__tile--now:hover': {
            background: '#e6e6e6',
            color: 'black',
        },
        '& .react-calendar__tile--active:hover': {
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
            color: 'white',
        },
        '& .react-calendar__tile--active': {
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
            color: 'white !important',
        },
        '@media all and (max-width: 600px)': {
            '& .react-calendar': {
                width: 200,
                maxWidth: 200,
                borderRadius: 16,
            },
            '& .react-calendar__tile': {
                lineHeight: '6px',
            },
            '& .react-calendar__navigation__label__labelText': {
                fontSize: '0.75rem',
            },
            '& .react-calendar__month-view__weekdays__weekday': {
                '& abbr': {
                    fontSize: '0.75rem',
                },
            },
            '& .react-calendar__month-view__days__day': {
                '& abbr': {
                    fontSize: '0.688rem',
                },
            },
        },
    },
});

class CalendarShape extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: new Date(),
        };
    }

    onChange = value => {
        this.setState({ value: value });
        let convertTimeZone = dayjs(value).tz(DATE_UTIL.getTimeZone(), true);
        this.props.onChangeDate(convertTimeZone);
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Calendar
                    value={this.state.value}
                    locale="ko"
                    calendarType="US"
                    onChange={this.onChange}
                    minDetail="month"
                    prev2Label={null}
                    next2Label={null}
                    formatDay={(locale, date) => dayjs(date).format('D')}
                    tileDisabled={({ date }) => DATE_UTIL.getTimeZoneDateNoFormat(date).isBefore(DATE_UTIL.getNowDate())}
                />
            </div>
        );
    }
}

export default withStyles(style)(CalendarShape);
