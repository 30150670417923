import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import dayjs from 'dayjs';
import { DATE_UTIL } from '../common/util/date.util';
import { loadingSate } from './RoomStore';
import { base64StringToBlob } from 'blob-util';
import _ from 'lodash';

const logPrefix = ' [ CommentStore ] ';

export const LoadingSate = {
    READY: 'READY',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
};

const EmptyComment = {
    id: '',
    groupId: '',
    userId: '',
    text: '',
    emotion: '',
    commentFiles: [],
};

export const CommentType = {
    ROOM: 'Room',
    POST: 'Post',
};

export const SortType = {
    TIME: 'Time',
    RECENT: 'Recent',
};

const EmptyCommentAttachments = {
    file: '',
};

export default class CommentStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    comment = Object.assign({}, EmptyComment);
    modifyComment = Object.assign({}, EmptyComment);
    commentList = [];
    commentAttachments = { ...EmptyCommentAttachments };
    isOverSize = false;

    rowsPerPage = 10;
    currentPage = 1;
    currentSortType = SortType.TIME;

    commentLoading = LoadingSate.READY;

    get isCommentLoading() {
        return this.commentLoading === LoadingSate.PENDING;
    }

    initModifyComment = () => {
        this.modifyComment = this.comment;
        this.commentAttachments = { ...EmptyCommentAttachments };
    };

    changeCommentFile = () => {
        const param = {
            fileId: 0,
            name: this.commentAttachments.file.name,
            type: this.commentAttachments.file.type,
            size: this.commentAttachments.file.size,
        };
        this.modifyComment.commentFiles[0] = param;
        this.modifyComment = { ...this.modifyComment };
    };

    removeCommentFile = fileIndex => {
        this.modifyComment.commentFiles.splice(fileIndex, 1);
        this.modifyComment = { ...this.modifyComment, commentFiles: [...this.modifyComment.commentFiles] };
    };

    setOverSize = isOverSize => {
        this.isOverSize = isOverSize;
    };

    changeCommentAttachments = file => {
        this.commentAttachments.file = file;
    };

    initCommentAttachments = () => {
        this.commentAttachments = { ...EmptyCommentAttachments };
        this.isOverSize = false;
    };

    initComment = () => {
        this.commentList = [];
        this.commentAttachments = { ...EmptyCommentAttachments };
    };

    setModifyComment = comment => {
        this.modifyComment = _.cloneDeep(comment);
    };

    changeCommentText = value => {
        this.comment.text = value;
    };

    changeModifyCommentText = value => {
        this.modifyComment.text = value;
    };

    calcCreatedDatetime = () => {
        this.commentList.map(c => {
            const currentDatetime = dayjs(DATE_UTIL.getNow());
            const updateDatetime = dayjs(DATE_UTIL.convertUTCToTimeZone(c.updatedDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT));

            const month = currentDatetime.diff(updateDatetime, 'month');
            const days = currentDatetime.diff(updateDatetime, 'day');
            const hour = currentDatetime.diff(updateDatetime, 'hours');
            const minutes = currentDatetime.diff(updateDatetime, 'minutes');

            let dateBefore = '';
            if (month > 0) {
                dateBefore = DATE_UTIL.convertUTCToTimeZone(c.updatedDatetime, DATE_UTIL.FORMAT.SINGLE_DIGIT_DOT_DATE_FORMAT);
            } else if (days > 0) {
                dateBefore = `${days}일 전`;
            } else if (hour > 0) {
                dateBefore = `${hour}시간 전`;
            } else if (minutes > 0) {
                dateBefore = `${minutes}분 전`;
            } else {
                dateBefore = '방금 전';
            }
            c.dateBefore = dateBefore;
        });
    };

    setCurrentPage = () => {
        this.currentPage = 1;
    };

    *getCommentList(groupId, typeId, commentType, page, sortType, callBack) {
        console.log(
            logPrefix,
            'getCommentList starting... groupId={}, typeId={}, page={}, commentType={}, sortType={}',
            groupId,
            typeId,
            page,
            commentType,
            sortType,
        );
        if (page !== 1 && this.currentPage !== 1) {
            this.commentLoading = loadingSate.PENDING;
        }
        try {
            let url =
                this.serverContextPath +
                `/api/v1/comment/list?group-id=${groupId}&type-id=${typeId}&page=${page !== undefined ? page : this.currentPage}&rows-per-page=${
                    this.rowsPerPage
                }&comment-type=${commentType}&sort-type=${sortType}`;

            const response = yield axios.get(url);
            console.log(logPrefix, 'getCommentList response={}', response.data);

            if (page !== undefined) {
                this.currentPage = ++page;
            } else {
                this.currentPage = ++this.currentPage;
            }
            if (sortType !== this.currentSortType) {
                this.commentList = response.data;
                this.currentSortType = sortType;
            } else {
                this.commentList = this.commentList.concat(response.data);
            }
            this.calcCreatedDatetime();
            callBack && callBack.getCommentCount();
            this.commentLoading = loadingSate.SUCCESS;
        } catch (e) {
            this.commentLoading = loadingSate.FAILED;
            console.log(logPrefix, 'getCommentList Failed ...');
        }
    }

    *createComment(loginUser, groupId, commentType, typeId, sortType, callBack) {
        console.log(logPrefix, 'createComment starting... loginUser={}', loginUser);
        this.commentLoading = loadingSate.PENDING;
        try {
            if (this.comment.text === '' && this.commentAttachments.file === '' && this.isOverSize) {
                this.commentLoading = loadingSate.FAILED;
                return false;
            }
            const commentParam = Object.assign({}, this.comment);
            commentParam.userId = loginUser.id;
            commentParam.groupId = groupId;
            commentParam.typeId = typeId;
            commentParam.type = commentType;

            const param = new FormData();
            const blobComment = new Blob([JSON.stringify(commentParam)], { type: 'application/json' });

            if (this.commentAttachments.file !== '' && !this.isOverSize) {
                const fileData = Buffer.from(yield this.commentAttachments.file.arrayBuffer()).toString('base64');
                const blobFile = base64StringToBlob(fileData, 'application/octet-stream');
                param.append('attachments', blobFile, this.commentAttachments.file.name);
            }

            param.append('comment', blobComment);
            console.log(logPrefix, 'createComment Param={}', param);

            const response = yield axios.post(this.serverContextPath + `/api/v1/comment/create`, param);
            console.log(logPrefix, 'createRoomComment response={}', response.data);
            if (response.data) {
                this.commentList = [];
                this.comment = Object.assign({}, EmptyComment);
                this.commentAttachments = Object.assign({}, EmptyCommentAttachments);
                this.getCommentList(groupId, typeId, commentType, 0, this.currentSortType, callBack && callBack.getCommentCount());
            }
            this.commentLoading = loadingSate.SUCCESS;
        } catch (e) {
            this.commentLoading = loadingSate.FAILED;
            console.log(logPrefix, 'createRoomComment Failed ...');
        }
    }

    *requestModifyComment(groupId, commentType, typeId, loginUser, sortType, callBack) {
        console.log(logPrefix, 'modifyComment starting... loginUser={}', loginUser);
        try {
            const commentParam = Object.assign({}, this.modifyComment);
            commentParam.userId = loginUser.id;
            commentParam.typeId = typeId;
            commentParam.groupId = groupId;
            commentParam.type = commentType;

            const param = new FormData();
            const blobComment = new Blob([JSON.stringify(commentParam)], { type: 'application/json' });

            if (this.commentAttachments.file !== '' && !this.isOverSize) {
                const fileData = Buffer.from(yield this.commentAttachments.file.arrayBuffer()).toString('base64');
                const blobFile = base64StringToBlob(fileData, 'application/octet-stream');
                param.append('attachments', blobFile, this.commentAttachments.file.name);
            }

            param.append('comment', blobComment);

            const response = yield axios.post(this.serverContextPath + `/api/v1/comment/modify`, param);
            if (response.data) {
                this.commentList = [];
                // this.modifyComment = Object.assign({}, EmptyComment);
                this.getCommentList(groupId, typeId, commentType, 0, this.currentSortType);
            }
            console.log(logPrefix, 'modifyRoomComment response={}', response.data);
        } catch (e) {
            console.log(logPrefix, 'modifyRoomComment Failed ...');
        } finally {
            this.modifyComment = Object.assign({}, EmptyComment);
            this.commentAttachments = { ...EmptyCommentAttachments };
            callBack && callBack.closeModify();
        }
    }

    *deleteComment(groupId, typeId, commentType, comment, userId, callBack) {
        console.log(logPrefix, 'deleteComment starting... commentId={}, loginUser={}', comment, userId);
        try {
            const param = Object.assign({}, comment);
            param.userId = userId;
            const response = yield axios.post(this.serverContextPath + `/api/v1/comment/delete`, param);
            console.log(logPrefix, 'deleteComment response={}', response.data);

            if (response.data > 0) {
                this.commentList = [];
                callBack && callBack.scrollMove && callBack.scrollMove();
                this.getCommentList(groupId, typeId, commentType, 0, this.currentSortType, callBack && callBack.getCommentCount());
            }
        } catch (e) {
            console.log(logPrefix, 'deleteComment Failed ...');
        }
    }

    *selectEmoji(comment, emotionId, userId) {
        console.log(logPrefix, 'selectEmoji starting... comment={}, emotionId={}', comment, emotionId, userId);
        try {
            const response = yield axios.post(this.serverContextPath + `/api/v1/comment/${comment.commentId}/emoji/create/${emotionId}/${userId}`);
            console.log(logPrefix, 'selectEmoji response={}', response.data);
            if (response.data.length > 0) {
                this.commentList.filter(c => c.commentId === comment.commentId)[0].emojiHistory = response.data;
            }
        } catch (e) {
            console.log(logPrefix, 'selectEmoji Failed ...');
        }
    }

    *modifyEmoji(comment, emotionId, userId) {
        console.log(logPrefix, 'modifyEmoji starting... comment={}, emotionId={}', comment, emotionId, userId);
        try {
            const response = yield axios.post(this.serverContextPath + `/api/v1/comment/${comment.commentId}/emoji/modify/${emotionId}/${userId}`);
            console.log(logPrefix, 'modifyEmoji response={}', response.data);
            if (response.data) {
                this.commentList.filter(c => c.commentId === comment.commentId)[0].emojiHistory = response.data;
            }
        } catch (e) {
            console.log(logPrefix, 'modifyEmoji Failed ...');
        }
    }

    *deleteEmoji(comment, emotionId, userId) {
        console.log(logPrefix, 'deleteEmoji starting... comment={}, emotionId={}', comment, emotionId, userId);
        try {
            const response = yield axios.post(this.serverContextPath + `/api/v1/comment/${comment.commentId}/emoji/delete/${emotionId}/${userId}`);
            console.log(logPrefix, 'deleteEmoji response={}', response.data);
            if (response.data) {
                this.commentList.filter(c => c.commentId === comment.commentId)[0].emojiHistory = response.data;
            }
        } catch (e) {
            console.log(logPrefix, 'deleteEmoji Failed ...');
        }
    }
}
