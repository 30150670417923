import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE } from './PaginationStore';

export const _ = require('lodash');

export const NOTICE_STATE_HANDLE_OPERATION = {
    Notified: 'notified',
    Read: 'read',
};

export const NOTICE_TYPE = {
    CreateRoom: 'CreateRoom',
    NoteCorrection: 'NoteCorrection',
};

export const NOTICE_STATE = {
    READY: 'READY',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
};

export const ROOM_TYPE = {
    Education: 'education',
    Conference: 'conference',
    Seminar: 'seminar',
};

export const NOTIFICATION_TYPE = {
    CreateRoom: 'CreateRoom',
    NoteCorrection: 'NoteCorrection',
};

export const ToolTip_Close_Time = 5000;
// export const Request_Notice_IntervalTime = 30000; //30초
export const Request_Notice_IntervalTime = 20000; //20초

const URL = '/api/v1/notices';

const logPrefix = ' [ NoticeStore ] ';

const CancelToken = axios.CancelToken;

export default class NoticeStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    roomNotices = [];
    notifications = [];

    notReadNoteCorrections = [];
    notReadCreatedRooms = [];
    isRoomNoticeLoading = false;
    isNotificationsLoading = false;

    requestState = NOTICE_STATE.READY;
    requestUpdateState = NOTICE_STATE.READY;

    notReadNotificationsByType = {
        CreateRoom: [],
        NoteCorrection: [],
    };

    /** 알림 공지 리스트 비교 **/
    compareNoticeList = (prev, next) => {
        if (!next) {
            return false;
        }
        if (prev.length !== next.length) {
            return false;
        }

        for (let i = 0, l = prev.length; i < l; i++) {
            for (const prevProp in prev[i]) {
                if (prev[i][prevProp] !== next[i][prevProp]) {
                    return false;
                } else if (typeof prev[i][prevProp] != typeof next[i][prevProp]) {
                    return false;
                }
            }
        }
        return true;
    };

    initRoomNotices = () => {
        this.roomNotices = [];
    };

    initNotifications = () => {
        this.notifications = [];
    };

    get getIsRoomNoticeLoading() {
        return this.isRoomNoticeLoading;
    }

    get getIsNotificationsLoading() {
        return this.isNotificationsLoading;
    }

    get isHaveNewNotice() {
        if (this.roomNotices && this.roomNotices.length) {
            const newNotices = this.roomNotices.filter(notice => !notice.notify);
            return newNotices.length > 0;
        }

        return false;
    }

    get getIsRoomNoticeLoading() {
        return this.isRoomNoticeLoading;
    }

    get newNotifications() {
        let result = [];
        if (this.notifications && this.notifications.length) {
            result = this.notifications.filter(notice => !notice.notified);
        }

        return result;
    }

    // get notReadRoomNotifications() {
    //     let result = [];
    //     if (this.notifications && this.notifications.length) {
    //         result = this.notifications.filter(notice => !notice.read);
    //     }
    //
    //     return result;
    // }

    // get notReadNoteCorrectionNotifications() {
    //     let result = [];
    //     if (this.notifications && this.notifications.length) {
    //         result = this.notifications.filter(notice => notice.type === NOTICE_TYPE.NoteCorrection && !notice.read);
    //     }
    //
    //     return result;
    // }

    get hasNotReadNoteCorrections() {
        return this.notReadNotificationsByType[NOTIFICATION_TYPE.NoteCorrection].length;
    }

    *requestGetRoomNotice(userEmail, page = DEFAULT_PAGE, rowsPerPage = DEFAULT_ROWS_PER_PAGE) {
        console.log(logPrefix, 'Starting requestGetRoomNotice');

        const checkedFirst = (prev, next) => {
            if (!next) {
                return true;
            }
            if (prev.length !== next.length) {
                return false;
            }
        };

        this.isRoomNoticeLoading = true;

        try {
            if (this.cancelTokenSource) {
                // console.log("axios source cancel")
                this.cancelTokenSource.cancel();
            } else {
                // console.log("create axios source")
                this.cancelTokenSource = CancelToken.source();
            }
            const response = yield axios.get(this.serverContextPath + `${URL}/${userEmail}/rooms`, {
                params: { page: page, rowsPerPage: rowsPerPage },
                cancelToken: this.cancelTokenSource.token,
            });

            const compareResult = _.isEqualWith(this.roomNotices, response.data, checkedFirst);
            if (compareResult) {
                console.log(logPrefix, 'Notice list equal');
            } else {
                console.log(logPrefix, 'notice list not equal');
                if (page > 0) {
                    this.roomNotices = this.roomNotices.concat(response.data);
                } else {
                    this.roomNotices = response.data;
                }
                console.log(logPrefix, 'Result : >> ', response.data);
            }
        } catch (e) {
            this.isRoomNoticeLoading = false;
            if (e.response) {
                console.log(logPrefix, 'Failed RequestGetRoomNotice', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed RequestGetRoomNotice', e.request);
            } else {
                console.log(logPrefix, 'Failed RequestGetRoomNotice', e.message);
            }
        } finally {
            this.isRoomNoticeLoading = false;
            if (this.cancelTokenSource) {
                this.cancelTokenSource = undefined;
            }
        }
    }

    *requestNotifications(userEmail, page = DEFAULT_PAGE, rowsPerPage = DEFAULT_ROWS_PER_PAGE) {
        console.log(logPrefix, 'Starting requestNotifications');

        const checkedFirst = (prev, next) => {
            if (!next) {
                return true;
            }
            if (prev.length !== next.length) {
                return false;
            }
        };

        this.isNotificationsLoading = true;

        try {
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel();
            } else {
                this.cancelTokenSource = CancelToken.source();
            }
            const response = yield axios.get(this.serverContextPath + `${URL}/${userEmail}`, {
                params: { page: page, rowsPerPage: rowsPerPage },
                cancelToken: this.cancelTokenSource.token,
            });

            const compareResult = _.isEqualWith(this.notifications, response.data, checkedFirst);
            if (compareResult) {
                console.log(logPrefix, 'Notice list equal');
            } else {
                console.log(logPrefix, 'notice list not equal');
                if (page > 0) {
                    this.notifications = this.notifications.concat(response.data);
                } else {
                    this.notifications = response.data;
                }
                console.log(logPrefix, 'Result : >> ', response.data);
            }
        } catch (e) {
            this.isNotificationsLoading = false;
            if (e.response) {
                console.log(logPrefix, 'Failed RequestGetRoomNotice', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed RequestGetRoomNotice', e.request);
            } else {
                console.log(logPrefix, 'Failed RequestGetRoomNotice', e.message);
            }
        } finally {
            this.isNotificationsLoading = false;
            if (this.cancelTokenSource) {
                this.cancelTokenSource = undefined;
            }
        }
    }

    *requestUpdateRoomNotifyState(userEmail, roomIdList) {
        // console.log(logPrefix, "Starting requestUpdateRoomNotifyState");

        this.isRoomNoticeLoading = true;
        try {
            const response = yield axios.post(this.serverContextPath + `${URL}/${userEmail}/rooms`, roomIdList);

            this.roomNotices = response.data;
            this.isRoomNoticeLoading = false;
        } catch (e) {
            this.isRoomNoticeLoading = false;
            if (e.response) {
                console.log(logPrefix, 'Failed requestUpdateRoomNotifyState', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestUpdateRoomNotifyState', e.request);
            } else {
                console.log(logPrefix, 'Failed requestUpdateRoomNotifyState', e.message);
            }
        } finally {
            this.isRoomNoticeLoading = false;
        }
    }

    *requestCreateNotification(groupId, userEmail, paperGroupId, notificationType) {
        console.log(logPrefix, 'Starting requestCreateNotification');
        console.log(logPrefix, 'param groupId : ', groupId);
        console.log(logPrefix, 'param userEmail : ', userEmail);
        console.log(logPrefix, 'param paperGroupId : ', paperGroupId);

        this.isNotificationsLoading = true;

        try {
            const params = { groupId: groupId, userEmail: userEmail, type: notificationType, key: paperGroupId };
            yield axios.put(this.serverContextPath + `${URL}`, params);
            this.isNotificationsLoading = false;
        } catch (e) {
            this.isNotificationsLoading = false;
            if (e.response) {
                console.log(logPrefix, 'Failed requestCreateNotification', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestCreateNotification', e.request);
            } else {
                console.log(logPrefix, 'Failed requestCreateNotification', e.message);
            }
        } finally {
            this.isNotificationsLoading = false;
        }
    }

    *requestUpdateNotifiedNotifications(userEmail, operation, notificationIds = []) {
        console.log(logPrefix, `Starting requestUpdateNotificationState userEmail = ${userEmail}, operation = ${operation}`);
        console.log(logPrefix, `notificationIds = `, notificationIds);

        this.isNotificationsLoading = true;
        try {
            const response = yield axios.post(this.serverContextPath + `${URL}/${userEmail}/${operation}`, notificationIds);

            this.notifications = response.data;
            this.isNotificationsLoading = false;
        } catch (e) {
            this.isNotificationsLoading = false;
            if (e.response) {
                console.log(logPrefix, 'Failed requestUpdateNotificationState', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestUpdateNotificationState', e.request);
            } else {
                console.log(logPrefix, 'Failed requestUpdateNotificationState', e.message);
            }
        } finally {
            this.isNotificationsLoading = false;
        }
    }

    *requestUpdateReadNotifications(userEmail, operation, type, groupId, key) {
        console.log(logPrefix, `Starting requestUpdateNotificationState userEmail = ${userEmail}, operation = ${operation}`);
        console.log(logPrefix, `type = ${type}`);
        console.log(logPrefix, `key = ${key}`);

        this.isNotificationsLoading = true;
        try {
            const dataBody = {
                userEmail: userEmail,
                groupId: groupId,
                type: type,
                key: key,
            };

            const response = yield axios.post(this.serverContextPath + `${URL}/${operation}`, dataBody);

            this.notReadNotificationsByType[type] = response.data;
            this.isNotificationsLoading = false;
        } catch (e) {
            this.isNotificationsLoading = false;
            if (e.response) {
                console.log(logPrefix, 'Failed requestUpdateNotificationState', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestUpdateNotificationState', e.request);
            } else {
                console.log(logPrefix, 'Failed requestUpdateNotificationState', e.message);
            }
        } finally {
            this.isNotificationsLoading = false;
        }
    }

    *requestNotReadNotificationsByGroupUser(groupId, userEmail, type) {
        console.log(logPrefix, 'Start requestNotReadNotificationsByGroupUser');
        console.log(logPrefix, `groupId = ${groupId}`);
        console.log(logPrefix, `userEmail = ${userEmail}`);
        console.log(logPrefix, `type = ${type}`);

        this.isNotificationsLoading = true;

        try {
            const response = yield axios.get(this.serverContextPath + `${URL}/${groupId}/${userEmail}/${type}`);

            this.notReadNotificationsByType[type] = response.data;
            this.isNotificationsLoading = false;
        } catch (e) {
            this.isNotificationsLoading = false;
            if (e.response) {
                console.log(logPrefix, 'Failed requestNotReadNotificationsByGroupUser', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestNotReadNotificationsByGroupUser', e.request);
            } else {
                console.log(logPrefix, 'Failed requestNotReadNotificationsByGroupUser', e.message);
            }
        } finally {
            this.isNotificationsLoading = false;
        }
    }
}
