import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as AsideUsersThreeIcon } from '../../common/images/AsideUsersThreeIcon.svg';
import SearchEmptyComponent from './SearchEmptyComponent';
import { withRouter } from '../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { ClassMainPath } from '../../common/ClassMainPath';

const styles = theme => ({
    root: {
        marginTop: 30,
    },
    roomCreateButton: {
        '@media all and (min-width: 1500px)': {
            width: 322,
            height: 360,
            marginRight: 30,
            marginBottom: 30,
        },
        width: 270,
        height: 350,
        background: '#fff',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        marginRight: 20,
        marginBottom: 20,
        padding: 0,
        overflow: 'hidden',
        '&:nth-child(4n+0)': {
            marginRight: 0,
        },
        '&:hover': {
            background: '#fff',
        },
        '& span': {
            display: 'flex',
            flexDirection: 'column',
            height: 'inherit',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        '& img': {
            '@media all and (min-width: 1500px)': {
                // width: 322,
            },
            // borderRadius: '10px 10px 0 0',
            // width: 270,
            height: 180,
            width: '100%',
            // height: '100%',
            objectFit: 'cover',
        },
    },
    roomTextBox: {
        '@media all and (min-width: 1500px)': {
            height: 'calc(360px - 180px)',
        },
        width: '100%',
        height: 'calc(350px - 180px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '12px 20px 14px',
        boxSizing: 'border-box',
    },
    chipBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 12,
    },
    chip: {
        minWidth: 50,
        height: '21!important',
        backgroundColor: '#eee',
        color: '#656565',
        marginRight: 6,
        padding: '0px 7px',
        fontSize: '0.75rem',
        border: '1px solid #eee',
        borderRadius: 50,
        boxSizing: 'border-box',
        '&:last-child': {
            marginRight: 0,
        },
    },
    buttonText: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1.125rem',
        },
        width: '100%',
        fontSize: '0.938rem',
        color: '#000',
        fontWeight: 600,
    },
    buttonTitle: {
        width: '100%',
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
    },
    subText: {
        fontSize: '0.875rem',
        color: '#000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        margin: '5px 0',
        textAlign: 'justify',
    },
    svgIcon: {
        marginRight: 5,
        width: 15,
        height: 15,
    },
    buttonSubText: {
        fontSize: '0.75rem',
        color: '#828282',
    },
});

class SearchStudyComponent extends Component {
    render() {
        const { classes, studyGroupList, navigate, authStore } = this.props;
        const { isSearchGroupListLoading } = this.props.classStore;
        return (
            <div className={classes.root}>
                {!isSearchGroupListLoading ? (
                    studyGroupList.length > 0 ? (
                        studyGroupList.map((g, i) => (
                            <Button
                                key={i}
                                className={classes.roomCreateButton}
                                disableRipple
                                onClick={() => {
                                    this.props.classStore.selectedGroup = g;
                                    this.props.roomStore.initRoom();
                                    this.props.postStore.initPostList();
                                    this.props.orgStore.setGroupColor(g.group.color);
                                    navigate(`/class/${g.group.id}/${ClassMainPath.todaySchedule}`);
                                }}
                            >
                                <img src={g.image} alt="room" />
                                <Box display="flex" flexDirection="column" alignItems="flex-start" className={classes.roomTextBox}>
                                    <Box display="flex" flexDirection="column" alignItems="flex-start" style={{ width: '100%' }}>
                                        <Box display="flex" alignItems="center" style={{ width: '100%' }}>
                                            <Box className={classes.chipBox}>
                                                {g.tagList.map((tag, i) => (
                                                    <Typography key={i} className={classes.chip} noWrap>
                                                        {tag.name}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        </Box>
                                        <Typography className={clsx(classes.buttonText, classes.buttonTitle)}>{g.group.name}</Typography>
                                        <Typography className={classes.subText}>{g.group.comment}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <AsideUsersThreeIcon className={classes.svgIcon} />
                                        <Typography className={classes.buttonSubText}>멤버:{g.group.maxUsers}명</Typography>
                                    </Box>
                                </Box>
                            </Button>
                        ))
                    ) : (
                        <SearchEmptyComponent loginUser={authStore.loginUser} />
                    )
                ) : (
                    <Box style={{ textAlign: 'center', marginTop: 100 }}>
                        <CircularProgress />
                    </Box>
                )}
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('classStore', 'roomStore', 'postStore', 'orgStore', 'authStore')(observer(SearchStudyComponent))),
);
