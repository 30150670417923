import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import MobileClassTitleComponent from './MobileClassTitleComponent';
import MobileClassTabTriggerComponent from './MobileClassTabTriggerComponent';
import MobileClassTagComponent from './MobileClassTagComponent';
import MobileClassAsideComponent from './MobileClassAsideComponent';
import MobileTodayScheduleComponent from './MobileTodayScheduleComponent';
import MobileFullScheduleComponent from './MobileFullScheduleComponent';
import MobileReferenceRoomComponent from './MobileReferenceRoomComponent';
import MobileClassCommunityComponent from './MobileClassCommunityComponent';
import MobileMemberListComponent from './MobileMemberListComponent';
import MobileClassCalendarComponent from './MobileClassCalendarComponent';
import MobileSettingComponent from '../setting/MobileSettingComponent';
import { inject, observer } from 'mobx-react';
import { PATH_UTIL } from '../../../common/util/path.util';
import { withRouter } from '../../../components/WithRouter';
import MobileScheduleClassificationComponent from './MobileScheduleClassificationComponent';
import { Route, Routes } from 'react-router-dom';
import { ClassMainPath } from '../../../common/ClassMainPath';
import MobileClassScheduleDetailContentComponent from './MobileClassScheduleDetailContentComponent';
import { injectIntl } from 'react-intl';
import MobileClassBoardDetailViewComponent from './MobileClassBoardDetailViewComponent';
import MobileWrittenRecordComponent from './MobileWrittenRecordComponent';
import RoomInvitationDialogComponent from '../../invitation/RoomInvitationDialogComponent';
import RoomInvitationConfirmDialogComponent from '../../invitation/RoomInvitationConfirmDialogComponent';
import { LoadingState, SessionStorageGroupInviteToken } from '../../../stores/ClassStore';
import MobileClassSelectSearchComponent from './MobileClassSelectSearchComponent';
import EmptyPage from '../../../components/common/EmptyPage';
import { CLASSIFICATION_TYPE } from '../../class/ScheduleClassificationComponent';
import LoadingComponent from '../../../components/common/LoadingComponent';
import { NOTICE_STATE_HANDLE_OPERATION, NOTICE_TYPE } from '../../../stores/NoticeStore';

const useStyles = makeStyles(_theme => ({
    root: {
        height: 'calc(100vh - 57px)',
        marginTop: 57,
        paddingTop: 8,
        boxSizing: 'content-box',
    },
    wrap: {
        margin: '0 auto',
        padding: '8px 16px',
        boxSizing: 'border-box',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
}));

export const SORT_BY = {
    name: 'name',
    createdDatetime: 'createdDatetime',
    updateDatetime: 'updatedDatetime',
};

function useMergeState(initializeState) {
    const [state, setState] = useState(initializeState);
    const setMergedState = newState => setState(prevState => Object.assign({}, prevState, newState));

    return [state, setMergedState];
}

function MobileClassMainComponent(props) {
    const classes = useStyles();
    const [userRequests, setUserRequests] = useMergeState({
        classTab: PATH_UTIL.getMainTabName(props.location.pathname),
        noticeBoard: false,
        classSelectTag: undefined,
        isMember: undefined,
        selectedClassification: CLASSIFICATION_TYPE.None,
        isGroupJoinResultDialogOpen: false,
        isInviteDialogOpen: false,
        isLeader: false,
    });
    const loginUser = props.authStore.loginUser;
    const pathName = useMemo(() => {
        return PATH_UTIL.getMainTabName(props.location.pathname);
    });

    const receiveMessage = event => {
        if (event.origin !== window.origin) {
            return false;
        }
        const { groupId, paperId, operation } = event.data;
        console.log(`[ClassMainComponent] message params groupId=${groupId}, paperId=${paperId}, operation=${operation} `);
        if (groupId && paperId && operation) {
            const originGroupId = PATH_UTIL.getClassId(props.location.pathname);
            const isInvalidMessage = Number(groupId) !== originGroupId;
            if (isInvalidMessage) {
                return false;
            }

            const nPS = props.neoPenStore;
            const ns = props.noticeStore;
            const { loginUser } = props.authStore;

            ns.requestUpdateReadNotifications(loginUser.email, NOTICE_STATE_HANDLE_OPERATION.Read, NOTICE_TYPE.NoteCorrection, groupId, paperId);
        }
    };

    useEffect(() => {
        function waitMessage() {
            window.addEventListener('message', receiveMessage, false);
        }
        waitMessage();

        return () => {
            window.removeEventListener('message', receiveMessage);
        };
    }, []);

    useEffect(() => {
        if (pathName !== ClassMainPath.tag) {
            setUserRequests(
                userRequests.selectedClassification === CLASSIFICATION_TYPE.None
                    ? { classTab: pathName, classSelectTag: undefined }
                    : { classTab: pathName, classSelectTag: undefined, selectedClassification: CLASSIFICATION_TYPE.None },
            );
            props.roomStore.changeClassTap(pathName);
        }

        if (props.fixedTopBarHeight) {
            props.scrollMove();
        }
    }, [pathName]);

    const handleChange = newValue => {
        moveTo(newValue);
    };

    const moveTo = classMainPath => {
        props.navigate(classMainPath);
    };

    const handleChangeClassSelectTag = tag => {
        setUserRequests({ selectedClassification: CLASSIFICATION_TYPE.Tag, classSelectTag: tag });
        if (userRequests.classTab === ClassMainPath.community || userRequests.classTab === ClassMainPath.postDetail) {
            props.postStore.getPostListByTag(tag);
        } else {
            props.roomStore.getRoomListBySelectedTag(tag);
        }

        props.navigate(`${ClassMainPath.tag}`, { replace: false });
    };

    const handleChangeSearch = keyword => {
        setUserRequests({ classSelectTag: undefined });
        props.roomStore.changeClassTap(ClassMainPath.search);
        props.searchStore.initSubCurrentPage();
        props.navigate(`${ClassMainPath.search}/${keyword}`, { replace: false });
    };

    const handleInitRoom = () => {
        props.roomStore.initCreateRoom();
    };

    const handleSetClassificationScheduleView = () => {
        setUserRequests({ selectedClassification: CLASSIFICATION_TYPE.Owner });
        props.navigate(ClassMainPath.tag, { replace: false });
    };

    const handleInitSelectedClassification = () => {
        setUserRequests({ selectedClassification: CLASSIFICATION_TYPE.None });
    };

    const handleClickScheduleDetail = roomId => {
        props.navigate(`${ClassMainPath.scheduleDetail}/${roomId}`, { replace: false });
        props.scrollMove();
    };

    const handleClickPostDetail = (e, postId) => {
        e.stopPropagation();
        props.navigate(`${ClassMainPath.postDetail}/${postId}`, { replace: false });
        props.scrollMove();
    };

    // useEffect 에서 비동기로 사용 - june
    const requestGroupDetail = async groupId => {
        await props.classStore.getGroupDetail(groupId, {
            checkMember: () =>
                setUserRequests({
                    isMember: props.classStore.isGroupMember(props.authStore.loginUser.id),
                    isLeader: props.classStore.isGroupLeader(props.authStore.loginUser.id),
                }),
            getGroupAuthority: () => requestGroupAuthority(groupId),
            setGroupColor: groupColor => props.orgStore.setGroupColor(groupColor),
        });
    };

    const requestJoinGroupFromInvite = (userId, requestPath) => {
        props.classStore.joinGroup(userId, requestPath, {
            requestGroupDetailAndCheckMember: groupId => {
                requestGroupDetail(groupId);
            },
            openJoinSuccessDialog: () => {
                sessionStorage.removeItem(SessionStorageGroupInviteToken);
                setUserRequests({ isGroupJoinResultDialogOpen: true });
            },
        });
    };

    const handleInitPagination = () => {
        props.paginationStore.initPagination();
    };

    const requestGroupAuthority = groupId => {
        props.classStore.getGroupAuthorities(groupId, {
            setGroupUserAuthority: authorities => {
                props.classStore.setGroupUserAuthority(props.authStore.loginUser.id, authorities);
            },
        });
    };

    const handleChangePage = page => {
        props.paginationStore.changePage(page);
    };

    useEffect(() => {
        const inviteToken = sessionStorage.getItem(SessionStorageGroupInviteToken);
        if (inviteToken) {
            setUserRequests({ isInviteDialogOpen: true });
        } else {
            setUserRequests({ isInviteDialogOpen: false });
        }
    }, [sessionStorage.getItem(SessionStorageGroupInviteToken)]);

    useEffect(async () => {
        const groupId = PATH_UTIL.getClassId(props.location.pathname);
        props.roomStore.initRoom();
        if (groupId) {
            if (groupId !== props.classStore.selectedGroupId) {
                props.classStore.setSelectedGroupId(groupId);
            }
            const nPS = props.neoPenStore;

            nPS.resetGroupData();

            await requestGroupDetail(groupId);

            const isLeader = props.classStore.isGroupLeader(loginUser.id);
            const userEmail = loginUser.email;

            await props.classStore.getAllGroupUsers(userEmail, groupId);

            nPS.setIsLeader(isLeader);
            nPS.setGroupUsers(props.classStore.selectedAllGroupUsers);

            await nPS.getAllPaperGroups(groupId, userEmail);
            await nPS.getUserNotes(props.classStore.selectedAllGroupUsers, isLeader);
            await nPS.getAllFolders(groupId);
        } else {
            console.log('NotAcceptable Path');
            props.navigate('/rooms', { replace: true });
        }
        return () => {
            props.roomStore.initRoom();
            props.classStore.initClass();
        };
    }, []);

    useEffect(() => {
        const header = document.getElementById('myHeader');
        const center = document.getElementById('myCenter');
        const left = document.getElementById('myLeft');
        const Right = document.getElementById('myRight');
        if (props.fixedTopBarHeight) {
            header && header.classList.add('sticky');
            center && center.classList.add('stickyCenter');
            left && left.classList.add('stickyLeft');
            Right && Right.classList.add('stickyRight');
        } else {
            header && header.classList.remove('sticky');
            center && center.classList.remove('stickyCenter');
            left && left.classList.remove('stickyLeft');
            Right && Right.classList.remove('stickyRight');
        }
    }, [props.fixedTopBarHeight]);

    return (
        <div className={classes.root}>
            {props.classStore.groupDetailInitializeState !== LoadingState.READY ? (
                <LoadingComponent />
            ) : (
                <>
                    <MobileClassTitleComponent
                        selectedGroupDetail={props.classStore.selectedGroupDetail}
                        scrollMove={props.scrollMove}
                        handleChange={handleChange}
                    />
                    <Box id="myRight">
                        <MobileClassAsideComponent
                            handleChangeSearch={handleChangeSearch}
                            scrollMove={props.scrollMove}
                            moveTo={moveTo}
                            classPath={props.params}
                            classTab={userRequests.classTab}
                            classSelectTag={userRequests.classSelectTag}
                            noticeBoard={userRequests.noticeBoard}
                            selectedGroupDetail={props.classStore.selectedGroupDetail}
                            isMember={userRequests.isMember}
                        />
                    </Box>
                    <Box id="myLeft">
                        {pathName === ClassMainPath.setting ||
                        userRequests.classTab === ClassMainPath.referenceRoom ||
                        userRequests.classTab === ClassMainPath.member ||
                        userRequests.classTab === ClassMainPath.penRecord ? (
                            <Box className={classes.leftBox} />
                        ) : (
                            <MobileClassTagComponent
                                classTab={userRequests.classTab}
                                classPath={props.params}
                                handleChangeClassSelectTag={handleChangeClassSelectTag}
                                classSelectTag={userRequests.classSelectTag}
                                roomTagList={props.classStore.detailTagList}
                            />
                        )}
                    </Box>
                    <Box id="myHeader">
                        <MobileClassTabTriggerComponent
                            loginUser={loginUser}
                            classTab={userRequests.classTab}
                            classPath={props.params}
                            handleChange={handleChange}
                            handleInitRoom={handleInitRoom}
                            isMember={userRequests.isMember}
                            isLeader={userRequests.isLeader}
                            requestGroupDetail={requestGroupDetail}
                            scrollMove={props.scrollMove}
                        />
                    </Box>

                    <Box className={classes.wrap}>
                        <Box id="myCenter">
                            <Routes>
                                <Route
                                    path={ClassMainPath.todaySchedule}
                                    element={
                                        <MobileTodayScheduleComponent
                                            isScrollEnd={props.isScrollEnd}
                                            handleClickDetail={handleClickScheduleDetail}
                                            classPath={props.params}
                                            isMember={userRequests.isMember}
                                            isLeader={userRequests.isLeader}
                                        />
                                    }
                                />
                                <Route
                                    path={ClassMainPath.allSchedule}
                                    element={
                                        <MobileFullScheduleComponent
                                            isScrollEnd={props.isScrollEnd}
                                            handleClickDetail={handleClickScheduleDetail}
                                            classPath={props.params}
                                            isMember={userRequests.isMember}
                                            isLeader={userRequests.isLeader}
                                            createRoomLoading={props.roomStore.createRoomLoading}
                                        />
                                    }
                                />
                                <Route
                                    path={ClassMainPath.calendarSchedule}
                                    element={
                                        <MobileClassCalendarComponent
                                            handleClickDetail={handleClickScheduleDetail}
                                            currentTab={props.params}
                                            groupDetail={props.classStore.selectedGroupDetail}
                                            loginUser={loginUser}
                                            isLeader={userRequests.isLeader}
                                        />
                                    }
                                />
                                <Route
                                    path={ClassMainPath.referenceRoom}
                                    element={<MobileReferenceRoomComponent changePage={handleChangePage} initPagination={handleInitPagination} />}
                                />
                                <Route path={ClassMainPath.penRecord} element={<MobileWrittenRecordComponent isLeader={userRequests.isLeader} />} />
                                <Route
                                    path={ClassMainPath.community}
                                    element={
                                        <MobileClassCommunityComponent
                                            classTab={userRequests.classTab}
                                            handleClickPostDetail={handleClickPostDetail}
                                            currentTab={props.params}
                                            topicList={props.classStore.selectedGroupDetail.topicList}
                                            isScrollEnd={props.isScrollEnd}
                                            isMember={userRequests.isMember}
                                        />
                                    }
                                />
                                <Route
                                    path={ClassMainPath.member}
                                    element={
                                        <MobileMemberListComponent
                                            loginUser={loginUser}
                                            classTab={userRequests.classTab}
                                            setClassificationView={handleSetClassificationScheduleView}
                                        />
                                    }
                                />
                                <Route
                                    path={`${ClassMainPath.setting}/*`}
                                    element={
                                        userRequests.isMember ? (
                                            <MobileSettingComponent
                                                classMainNavigate={props.navigate}
                                                selectedGroupDetail={props.classStore.selectedGroupDetail}
                                                loginUser={loginUser}
                                            />
                                        ) : (
                                            <EmptyPage />
                                        )
                                    }
                                />
                                <Route
                                    path={ClassMainPath.tag}
                                    element={
                                        <MobileScheduleClassificationComponent
                                            handleClickDetail={handleClickScheduleDetail}
                                            handleClickPostDetail={handleClickPostDetail}
                                            classSelectTag={userRequests.classSelectTag}
                                            classTab={userRequests.classTab}
                                            classification={userRequests.selectedClassification}
                                            initClassification={handleInitSelectedClassification}
                                            isMember={userRequests.isMember}
                                            isLeader={userRequests.isLeader}
                                            isScrollEnd={props.isScrollEnd}
                                            scrollHeight={props.scrollHeight}
                                            calcScrollEnd={props.calcScrollEnd}
                                        />
                                    }
                                />
                                <Route
                                    path={`${ClassMainPath.scheduleDetail}/:roomId`}
                                    element={
                                        <MobileClassScheduleDetailContentComponent
                                            currentTab={props.params}
                                            classTab={userRequests.classTab}
                                            isMember={userRequests.isMember}
                                            isLeader={userRequests.isLeader}
                                            isScrollEnd={props.isScrollEnd}
                                            scrollMove={props.scrollMove}
                                        />
                                    }
                                />
                                <Route
                                    path={`${ClassMainPath.postDetail}/:postId`}
                                    element={
                                        <MobileClassBoardDetailViewComponent
                                            currentTab={props.params}
                                            isMember={userRequests.isMember}
                                            isScrollEnd={props.isScrollEnd}
                                            scrollMove={props.scrollMove}
                                        />
                                    }
                                />
                                <Route
                                    path={`${ClassMainPath.search}/:keyword`}
                                    element={
                                        <MobileClassSelectSearchComponent
                                            currentTab={props.params}
                                            isMember={userRequests.isMember}
                                            isScrollEnd={props.isScrollEnd}
                                            handleClickDetail={handleClickScheduleDetail}
                                        />
                                    }
                                />
                            </Routes>
                        </Box>
                    </Box>
                    <RoomInvitationDialogComponent
                        open={userRequests.isInviteDialogOpen}
                        inviteGroup={props.classStore.inviteGroup}
                        joinGroup={requestJoinGroupFromInvite}
                        loginUser={loginUser}
                        requestGroupDetail={requestGroupDetail}
                    />
                    <RoomInvitationConfirmDialogComponent
                        open={userRequests.isGroupJoinResultDialogOpen}
                        onClose={() => setUserRequests({ isGroupJoinResultDialogOpen: false })}
                    />
                    {/*{window.flutter_inappwebview && <UploadOfflineDataButton />}*/}
                </>
            )}
        </div>
    );
}
export default withRouter(
    inject(
        'classStore',
        'authStore',
        'roomStore',
        'postStore',
        'classTeamStore',
        'paginationStore',
        'avatarStore',
        'orgStore',
        'searchStore',
        'neoPenStore',
        'noticeStore',
    )(injectIntl(observer(MobileClassMainComponent))),
);
