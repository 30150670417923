import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Dialog, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { LoadingState } from '../../stores/ClassStore';

const style = theme => ({
    root: {},
    dialogBox: {
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
        '& .MuiDialog-paper': {
            position: 'relative',
            borderRadius: 10,
            padding: 24,
        },
        '& .MuiDialogContentText-root': {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.25rem',
            color: '#000',
            letterSpacing: '-0.25px',
            marginTop: 30,
            marginBottom: 30,
            '@media all and (max-width: 600px)': {
                fontSize: '1rem',
            },
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    description: {
        color: '#333',
        borderBottom: '1px solid #d9dbde',
        paddingBottom: 8,
        marginBottom: 30,
        marginTop: 23,
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    subtitle: {
        fontSize: '1rem',
        marginBottom: 15,
        color: '#333333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    groupList: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    groupImg: {
        minWidth: 81,
        width: 81,
        height: 45,
        borderRadius: 3,
        marginRight: 12,
        backgroundColor: '#ddd',
        overflow: 'hidden',
        objectFit: 'cover',
        '@media all and (max-width: 600px)': {
            minWidth: 76,
            width: 76,
            height: 40,
        },
    },
    groupName: {
        width: '60%',
        color: '#333333',
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: 1.3,
        letterSpacing: '-0.32px',
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    outlineButton: {
        width: 82,
        minWidth: 82,
        height: 30,
        border: '1px solid transparent',
        color: 'transparent',
        fontWeight: 700,
        padding: ' 6px 0',
        fontSize: '0.875rem',
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            width: 70,
            minWidth: 60,
            height: 25,
            '& span': {
                fontSize: '0.688rem',
            },
        },
    },
    replayButton: {
        borderColor: '#656565',
        color: '#656565',
    },
    hiddenButton: {
        borderColor: '#0097ff',
        color: '#0097ff',
    },
    closeBtn: {
        position: 'absolute',
        top: 16,
        right: 12,
        '&:hover': {
            background: 'transparent',
        },
    },
    gutter: {
        width: '100%',
        height: 1,
        backgroundColor: '#d9dbde',
        marginBottom: 30,
    },
    groupBox: {
        marginBottom: 30,
        overflow: 'auto',
        minHeight: 80,
        maxHeight: 180,
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#bebebe',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '3px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
    },
    hiddenBox: {
        marginBottom: 30,
        minHeight: 'auto',
        '& p': {
            color: '#a3a8af',
        },
        '@media all and (max-width: 600px)': {
            '& p': {
                fontSize: '0.813rem',
            },
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    buttonStyle: {
        width: 300,
        height: 40,
        background: '#c2c2c2',
        color: '#fff',
        fontSize: '1.063rem',
        fontWeight: 600,
        borderRadius: 7,
        '&:hover': {
            background: '#c2c2c2',
        },
        '@media all and (max-width: 600px)': {
            width: '80%',
            height: 35,
            fontSize: '1rem',
        },
    },
    saveButton: {
        background: '#0097ff',
        // marginLeft: 20,
        '&:hover': {
            background: '#0097ff',
        },
    },
});

class HiddenGroupManagementDialogComponent extends React.Component {
    componentDidMount() {
        this.props.classStore.getHideGroupList(this.props.authStore.loginUser.id);
    }

    handleHideGroup = groupId => {
        this.props.classStore.changeHideGroupList(groupId);
    };

    render() {
        const { classes, classStore } = this.props;
        const { simpleGroupListDummy } = classStore;
        const hideGroupList = simpleGroupListDummy.filter(g => g.hideGroup);
        const visibleGroupList = simpleGroupListDummy.filter(g => !g.hideGroup);

        return (
            <div className={classes.root}>
                <Dialog open={this.props.dialogOpen} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography className={classes.titleText}>숨김 목록 관리</Typography>
                        <IconButton className={classes.iconButton} onClick={this.props.handleClose} disableRipple>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>
                    <Typography className={classes.description}>아래 노출 목록에서 선택해주세요.</Typography>

                    <Typography className={classes.subtitle}>숨긴 클래스</Typography>
                    {!hideGroupList.length > 0 ? (
                        <Box className={classes.hiddenBox}>
                            <Typography>숨긴 클래스가 없습니다.</Typography>
                        </Box>
                    ) : (
                        <Box className={classes.groupBox}>
                            {hideGroupList.map(g => (
                                <Box className={classes.groupList} key={g.id}>
                                    <Box display="flex">
                                        <img src={`${g.image}`} className={classes.groupImg} />
                                        <Typography className={classes.groupName}>{g.name}</Typography>
                                    </Box>
                                    <Button
                                        className={clsx(classes.outlineButton, classes.replayButton)}
                                        disableRipple
                                        onClick={() => this.handleHideGroup(g.id)}
                                    >
                                        되돌리기
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                    )}

                    <span className={classes.gutter}></span>

                    <Typography className={classes.subtitle}>노출중인 클래스</Typography>
                    {!visibleGroupList.length > 0 ? (
                        <Box className={classes.hiddenBox}>
                            <Typography>노출중인 클래스가 없습니다.</Typography>
                        </Box>
                    ) : (
                        <Box className={classes.groupBox}>
                            {visibleGroupList.map((g, i) => (
                                <Box className={classes.groupList} key={g.id}>
                                    <Box display="flex">
                                        <img src={`${g.image}`} className={classes.groupImg} />
                                        <Typography className={classes.groupName}>{g.name}</Typography>
                                    </Box>
                                    <Button
                                        className={clsx(classes.outlineButton, classes.hiddenButton)}
                                        disableRipple
                                        onClick={() => this.handleHideGroup(g.id)}
                                    >
                                        숨기기
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                    )}
                    <Box display="flex" justifyContent="center">
                        {this.props.classStore.changeHideGroupLoading !== LoadingState.PENDING ? (
                            <Button
                                className={clsx(classes.buttonStyle, classes.saveButton)}
                                disabled={this.props.classStore.changeHideGroupLoading === LoadingState.PENDING}
                                onClick={this.props.handleSave}
                                disableRipple
                            >
                                확인
                            </Button>
                        ) : (
                            <Button className={clsx(classes.buttonStyle, classes.saveButton)} disableRipple>
                                <CircularProgress color={'inherit'} size={20} />
                            </Button>
                        )}
                    </Box>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(style)(inject('classStore', 'authStore')(observer(HiddenGroupManagementDialogComponent)));
