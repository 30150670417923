import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, FormControl, FormControlLabel, IconButton, OutlinedInput, Radio, RadioGroup, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import clsx from 'clsx';
import { inject } from 'mobx-react';
import { ToastsStore } from 'react-toasts';
import { CircularProgress } from '@mui/material';
import {PaperGroupType} from "../../stores/NeoPenStore";

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 320,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 600px)': {
                width: 500,
            },
        },
        '& .MuiDialog-paper': {
            '@media all and (max-width: 600px)': {
                margin: 0,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 30,
        '@media all and (max-width: 600px)': {
            marginBottom: 10,
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    boxStyle: {
        padding: '30px 0 0',
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    buttonStyle: {
        marginTop: 26,
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        color: '#fff',
        fontSize: '1.125rem',
        padding: '12px 0',
        borderRadius: 7,
        '&:hover': {
            fontWeight: 800,
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            height: 33,
            padding: '6px 0',
            fontSize: '0.75rem',
            borderRadius: 4,
        },
    },
    input: {
        display: 'none',
    },
    textField: {
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #d9dbde',
            },
        '& .MuiOutlinedInput-root': {
            borderRadius: 7,
            '&:hover': {
                borderColor: '#d9dbde',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px 10px',
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#a3a8af',
                opacity: 1,
                fontWeight: 300,
            },
        },
        '& .MuiOutlinedInput-multiline': {
            padding: '0!important',
        },
        '@media all and (max-width: 600px)': {
            '& .MuiOutlinedInput-input': {
                padding: '10px 10px',
                fontSize: '0.813rem',
            },
        },
    },
    boxMargin: {
        marginBottom: 30,
        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            marginRight: 0,
        },
        '@media all and (max-width: 600px)': {
            marginBottom: 15,
        },
    },
    marginBottom: {
        marginBottom: 15,
        '@media all and (max-width: 600px)': {
            marginBottom: 10,
        },
    },
    textStyle: {
        fontWeight: 600,
        fontSize: '1.125rem',
        color: '#333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    textStyleRequired: {
        '&:after': {
            content: '"*"',
            color: '#ff0000',
        },
    },
    textBold: {
        fontWeight: 600,
        textDecoration: 'underline',
    },
    autoCompleteBox: {
        marginBottom: 10,
        '& .MuiInputBase-root': {
            padding: '2.5px 6px',
            backgroundColor: '#f2f3f7',
            color: '#b6b6b6',
        },
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #f2f3f7',
            },
        '& .MuiAutocomplete-input': {
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#a3a8af',
                opacity: 1,
                fontWeight: 300,
            },
        },
        '& .MuiAutocomplete-popupIndicator': {
            display: 'none',
        },
    },
    searchBtn: {
        padding: '6px 10px',
        borderRadius: 4,
        fontSize: '0.875rem',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    btnStyle: {
        border: '1px solid #d9dbde',
        borderRadius: 8,
        marginLeft: 10,
        color: '#000',
        padding: '8px 12px',
        fontWeight: 400,
        fontSize: '0.875rem',
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
            height: 35,
            borderRadius: 4,
        },
    },
    downloadInputStyle: {
        '&>div': {
            width: 'calc(100% - 85px)',
        },
    },
    helperText: {
        color: '#ff0000',
        fontSize: '0.813rem',
        marginTop: 4,
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    helperText2: {
        color: '#a3a8af',
        fontSize: '0.813rem',
        marginTop: 4,
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    radioStyle: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        '& label:first-child': {
            marginRight: 40,
        },
        '& .MuiRadio-colorPrimary.Mui-checked': {
            color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '@media all and (max-width: 600px)': {
            '& .MuiSvgIcon-root': {
                fontSize: '1rem',
            },
            '& .MuiFormControlLabel-label': {
                fontSize: '0.813rem',
            },
        },
    },
    toasts: {
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
});

class FormRegistrationDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pdfTitle: '',
            originPdfName: '',
            value: '공개',
        };
    }

    handleChangeValue = event => {
        this.setState({ value: event.target.value });
    };

    handleSelectPdf = event => {
        const { classes } = this.props;
        const pdf = event.target.files[0];

        if (pdf.size >= 100000000) {
            ToastsStore.warning('최대 100MB 까지 업로드 가능합니다.', 3000, classes.toasts);

            return;
        }

        // this.setState({ pdfTitle: pdf.name.trim().replace(/(.pdf)$/, '') });

        this.setState({ originPdfName: pdf.name });

        this.props.neoPenStore.selectPdf(pdf);
    };

    render() {
        const { classes, neoPenStore } = this.props;
        let type = this.props.type;

        if(type === undefined)
            type = PaperGroupType.Study;

        const userEmail = this.props.authStore.loginUser.email;
        const groupId = this.props.classStore.selectedGroupDetail.group.id;

        return (
            <div className={classes.root}>
                <Dialog open={this.props.formRegistrationOpen} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.titleBox}>
                        <Typography className={classes.titleText}>서식 등록</Typography>
                        <IconButton
                            className={classes.iconButton}
                            onClick={() => {
                                this.setState({ pdfTitle: '', originPdfName: '', value: '공개' });
                                this.props.neoPenStore.selectPdf(undefined);

                                this.props.handleCloseFormRegistration();
                            }}
                            disableRipple
                        >
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>

                    <Box display="flex" flexDirection="column" className={classes.boxMargin}>
                        <FormControl className={clsx(classes.textField, classes.marginBottom)} variant="outlined">
                            <OutlinedInput
                                inputProps={{ 'aria-label': 'title input box' }}
                                id="outlined-title"
                                labelWidth={0}
                                placeholder="자료명 (최대 200자 이내)"
                                value={this.state.pdfTitle}
                                onChange={event => this.setState({ pdfTitle: event.target.value })}
                            />
                        </FormControl>
                        <FormControl className={classes.textField} variant="outlined">
                            <OutlinedInput
                                inputProps={{ 'aria-label': 'title input box' }}
                                id="outlined-title"
                                labelWidth={0}
                                multiline
                                rows="4"
                                placeholder="자료 설명 (최대 1000자 이내)"
                            />
                        </FormControl>
                    </Box>
                    <Box className={classes.boxMargin}>
                        <Typography className={clsx(classes.textStyle, classes.textStyleRequired, classes.marginBottom)}>파일 선택</Typography>
                        <Box
                            className={clsx(classes.textField, classes.downloadInputStyle)}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <FormControl className={classes.textField} variant="outlined">
                                <OutlinedInput
                                    inputProps={{ 'aria-label': 'file download text' }}
                                    id="outlined-title"
                                    labelWidth={0}
                                    placeholder="파일을 선택해주세요."
                                    disabled={true}
                                    value={this.state.originPdfName}
                                />
                            </FormControl>
                            <input id="upload-button-file" type="file" accept=".pdf" style={{ display: 'none' }} onChange={this.handleSelectPdf} />
                            <Button
                                component={'label'}
                                className={classes.btnStyle}
                                htmlFor={'upload-button-file'}
                                style={{ width: '85px' }}
                                disableRipple
                            >
                                파일 선택
                            </Button>
                        </Box>
                        <Typography variant="body2" className={classes.helperText}>
                            *한 번에 한 개의 pdf 파일만 업로드 가능합니다.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography className={clsx(classes.textStyle, classes.textStyleRequired)}>공개 여부</Typography>
                        <RadioGroup
                            aria-label="Gender"
                            name="gender1"
                            className={classes.radioStyle}
                            value={this.state.value}
                            onChange={this.handleChangeValue}
                            required
                        >
                            <FormControlLabel name="open" value="공개" control={<Radio color="primary" disableRipple />} label="공개" disableRipple />
                            <FormControlLabel
                                name="open"
                                value="비공개"
                                control={<Radio color="primary" disableRipple />}
                                label="비공개"
                                disableRipple
                            />
                        </RadioGroup>
                        {this.state.value === '공개' ? (
                            <Typography variant="body2" className={classes.helperText2}>
                                모든 학생이 이 파일을 보고 다운로드 받을 수 있습니다.
                            </Typography>
                        ) : (
                            <Typography variant="body2" className={classes.helperText2}>
                                학생이 이 파일을 보고 다운로드 받을 수 없습니다.
                            </Typography>
                        )}
                    </Box>

                    {!neoPenStore.isUploadingPdf ? (
                        <Button
                            disabled={this.state.pdfTitle === '' || this.props.neoPenStore.selectedPdf === undefined}
                            className={classes.buttonStyle}
                            disableRipple
                            onClick={async () => {
                                ToastsStore.info('서식 등록을 시작합니다.', 3000, classes.toasts);

                                this.props.handleCloseFormRegistration();

                                const isPublic = this.state.value === '공개';

                                const message = await neoPenStore.uploadPdf(
                                    this.state.pdfTitle,
                                    groupId,
                                    neoPenStore.currentFolder.id,
                                    userEmail,
                                    isPublic,
                                    type
                                );

                                this.setState({ pdfTitle: '', originPdfName: '', value: '공개' });
                                this.props.neoPenStore.selectPdf(undefined);

                                ToastsStore.info(message, 5000, classes.toasts);
                            }}
                        >
                            등록
                        </Button>
                    ) : (
                        <CircularProgress />
                    )}
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(inject('neoPenStore', 'authStore', 'classStore')(FormRegistrationDialogComponent));
