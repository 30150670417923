import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import { ReactComponent as UsersThreeIcon } from '../../common/images/UsersThreeIcon.svg';
import { ReactComponent as AlarmIcon } from '../../common/images/AlarmIcon.svg';
import { withRouter } from '../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { DATE_UTIL } from '../../common/util/date.util';
import { roomState, RoomStateFlagName } from '../../stores/RoomStore';
import dayjs from 'dayjs';

const styles = _theme => ({
    root: {},
    titleText: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1.875rem',
        },
        fontSize: '1.563rem',
        color: '#000',
    },
    scheduleBox: {
        '@media all and (min-width: 1500px)': {
            width: 1440,
            padding: '48px 30px 0',
        },
        width: 1200,
        background: '#fff',
        padding: '27px 30px 0',
        margin: '0 auto',
        boxSizing: 'border-box',
    },
    titleBox: {
        marginBottom: 25,
    },
    dateText: {
        fontSize: '0.938rem',
        color: '#656565',
        fontWeight: 600,
        marginLeft: 25,
    },
    textStyle: {
        fontSize: '1.25rem',
        color: '#656565',
    },
    noScheduleBox: {
        paddingBottom: 150,
    },
    scheduleBoxIn: {
        paddingBottom: 54,
    },
    scheduleBoxStyle: {
        '@media all and (min-width: 1500px)': {
            width: 320,
            height: 280,
            marginRight: 30,
            marginBottom: 30,
        },
        width: 268,
        height: 250,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        marginRight: 20,
        marginBottom: 20,
        borderTop: '7px solid #a3a8af',
        border: '1px solid rgba(163, 168, 175, 0.5)',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        '&:nth-child(4n+0)': {
            marginRight: 0,
        },
    },
    liveScheduleBox: {
        borderTop: '7px solid #00c880',
        border: '1px solid rgba(0, 200, 128, 0.5)',
    },
    roomNameBox: {
        padding: '7px 15px',
        borderBottom: '1px solid #d3d7db',
        '& svg': {
            marginRight: 8,
        },
    },
    roomNameText: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1.063rem',
        },
        width: 250,
        fontSize: '0.875rem',
        color: '#000',
    },
    contentsBox: {
        padding: '10px 35px 0px',
        boxSizing: 'border-box',
        '& svg': {
            width: 22,
            height: 22,
            '& .st0': {
                fill: '#00c880',
            },
        },
    },
    box: {
        width: 40,
        height: 16,
        background: '#a3a8af',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    liveBox: {
        background: '#fb4a59',
    },
    boxText: {
        fontFamily: 'Montserrat',
        fontSize: '0.625rem',
        color: '#fff',
        fontWeight: 600,
    },
    circle: {
        width: 4,
        height: 4,
        borderRadius: '50%',
        background: '#fff',
        marginRight: 4,
    },
    scheduleTitleText: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1.563rem',
        },
        fontSize: '1.313rem',
        color: '#000',
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        margin: '7px 0 13px',
    },
    timeText: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1.063rem',
        },
        fontSize: '0.875rem',
        color: '#656565',
        marginLeft: 7,
    },
    buttonStyle: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1rem',
            height: 40,
        },
        width: 200,
        height: 34,
        border: '1px solid #656565',
        color: '#656565',
        borderRadius: 7,
        fontWeight: 600,
        fontSize: '0.813rem',
        '&:hover': {
            background: 'transparent',
        },
    },
    liveButtonStyle: {
        border: '1px solid #0097ff',
        color: '#0097ff',
    },
    commentBox: {
        minHeight: 64,
    },
});

export const ENTER_BTN_NAME = {
    Live: '참석하기',
    Ended: '살펴보기',
    Future: '미리 살펴보기',
};

class TodayScheduleComponent extends Component {
    componentDidMount() {
        const start = new Date();
        this.props.roomStore.initRoom();
        this.props.roomStore.setRoomRowsPerPage(16);
        this.props.roomStore.getRoomListGroupByStartTime(
            undefined,
            this.props.authStore.loginUser.id,
            'today',
            1,
            start,
            undefined,
            undefined,
            undefined,
        );
    }

    componentDidUpdate(prevProps: Readonly<P>, _prevState: Readonly<S>, _snapshot: SS) {
        const { roomStore, authStore, isScrollEnd } = this.props;
        if (prevProps.isScrollEnd !== isScrollEnd && isScrollEnd && roomStore.roomTotalCount > roomStore.roomListGroupByStartTime.length) {
            const start = new Date();
            roomStore.changeCurrentPage();
            roomStore.getRoomListGroupByStartTime(undefined, authStore.loginUser.id, 'today', roomStore.currentPage, start);
        }
    }

    handleClickDetail = (groupId, roomId) => {
        window.scrollTo(0, 0);
        this.props.navigate(`/class/${groupId}/scheduledetail/${roomId}`);
    };

    handleClickEnter = (event, url) => {
        event.stopPropagation();
        window.open(url);
    };

    handleClickEnterBtn = (event, isLive, schedule) => {
        event.stopPropagation();

        if (isLive) {
            this.enterSchedule(schedule);
        } else {
            this.handleClickDetail(schedule.groupId, schedule.id);
        }
    };

    enterSchedule = schedule => {
        if (schedule) {
            const { loginUser } = this.props.authStore;
            const url = schedule.members.find(m => m.userId === loginUser.id).entranceUrl;
            if (url) window.open(url);
        }
    };

    checkRoomState = (startDatetime, endDatetime) => {
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedStartDatetime = DATE_UTIL.convertUTCToTimeZone(startDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedEndDatetime = DATE_UTIL.convertUTCToTimeZone(endDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        if (dayjs(convertedEndDatetime).isBefore(currentDatetime)) {
            return roomState.Ended;
        } else if (
            dayjs(currentDatetime).isBetween(convertedStartDatetime, convertedEndDatetime) ||
            dayjs(currentDatetime).isSame(convertedStartDatetime) ||
            dayjs(currentDatetime).isSame(convertedEndDatetime)
        ) {
            return roomState.Live;
        } else {
            return roomState.Future;
        }
    };

    selectRenderSchedulesComponent = () => {
        const { classes, roomStore } = this.props;
        if (!roomStore.roomListGroupByStartTime.length > 0) {
            return (
                <Box className={classes.noScheduleBox}>
                    <Typography className={classes.textStyle}>등록된 수업이 없습니다.</Typography>
                </Box>
            );
        }

        return (
            <Box display="flex" alignItems="center" flexWrap="wrap" className={classes.scheduleBoxIn}>
                {roomStore.roomListGroupByStartTime.map((schedule, i) => {
                    const state = this.checkRoomState(schedule.startDatetime, schedule.endDatetime);
                    const isLive = state === roomState.Live;
                    return (
                        <Box key={i} className={isLive ? clsx(classes.scheduleBoxStyle, classes.liveScheduleBox) : classes.scheduleBoxStyle}>
                            <Box display="flex" alignItems="center" className={classes.roomNameBox}>
                                <UsersThreeIcon />
                                <Typography className={classes.roomNameText} noWrap>
                                    {schedule.groupName}
                                </Typography>
                            </Box>
                            <Box className={classes.contentsBox}>
                                <Box className={isLive ? clsx(classes.box, classes.liveBox) : classes.box}>
                                    <Box className={classes.circle} />
                                    <Typography className={classes.boxText}>{RoomStateFlagName[state]} </Typography>
                                </Box>
                                <Box className={classes.commentBox}>
                                    <Typography className={classes.scheduleTitleText}>{schedule.name}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <AlarmIcon />
                                    <Typography className={classes.timeText}>
                                        {DATE_UTIL.convertUTCToTimeZone(schedule.startDatetime, DATE_UTIL.FORMAT.SIMPLE_TIME_AMPM_FORMAT)} ~{' '}
                                        {DATE_UTIL.convertUTCToTimeZone(schedule.endDatetime, DATE_UTIL.FORMAT.SIMPLE_TIME_AMPM_FORMAT)}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="center" style={{ marginTop: 20 }}>
                                    <Button
                                        className={clsx(classes.buttonStyle, isLive ? classes.liveButtonStyle : null)}
                                        disableRipple
                                        onClick={e => this.handleClickEnterBtn(e, isLive, schedule)}
                                    >
                                        {ENTER_BTN_NAME[state]}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        );
    };

    render() {
        const { classes } = this.props;
        const today = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DOT_FORMAT);
        const scheduleElement = this.selectRenderSchedulesComponent();

        return (
            <div className={classes.root}>
                <Box className={classes.scheduleBox}>
                    <Box display="flex" alignItems="center" className={classes.titleBox}>
                        <Typography className={classes.titleText}>오늘 수업 피드</Typography>
                        <Typography className={classes.dateText}>{today}</Typography>
                    </Box>

                    <Box>{scheduleElement}</Box>
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('authStore', 'roomStore', 'userStore')(observer(TodayScheduleComponent))));
