export default function configureServerContextPath(exists) {
    let serverContextPath = '';

    if (exists) {
        const { pathname } = window.location;

        if (pathname !== null && pathname !== undefined) {
            const tokens = pathname.split('/');
            if (tokens !== null && tokens !== undefined && tokens.length > 1) {
                serverContextPath = '/' + tokens[1];
            } else if (tokens !== null && tokens !== undefined && tokens.length > 0) {
                serverContextPath = tokens[0];
            }
        }
    }
    return serverContextPath;
}
