import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Checkbox, Typography } from '@material-ui/core';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../../common/images/CheckCircleAgreeOnIcon.svg';
import { ReactComponent as CheckCircleAgreeOffIcon } from '../../../common/images/CheckCircleAgreeOffIcon.svg';
import { withRouter } from '../../../components/WithRouter';
import { POLICY_AGREEMENT_LABELS, POLICY_AGREEMENT_TYPE, SOCIAL_PLATFORM_LOGO } from '../../../stores/AuthStore';
import { injectIntl, useIntl } from 'react-intl';
import ConfirmDialog from '../../../components/common/ConfirmDialog';
import { inject, observer } from 'mobx-react';
import { getURLParams } from '../../../common/util/Params';

const styles = theme => ({
    root: {
        width: '100%',
        height: 'calc(100vh - 130px)',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boxLine: {
        width: 540,
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #e5e6e8',
        padding: '50px 0 30px',
        boxSizing: 'border-box',
        borderRadius: 12,
        marginTop: 45,
    },
    titleText: {
        fontSize: '1.75rem',
        color: '#333',
        textAlign: 'center',
        marginBottom: 12,
    },
    subText: {
        textAlign: 'center',
        fontSize: '1rem',
        color: '#808080',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkBoxMargin: {
        margin: '30px 0',
        background: 'rgba(242, 245, 248, 0.5)',
        padding: '20px 80px',
    },
    buttonStyle: {
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        padding: '15px 0',
        borderRadius: 7,
        marginLeft: 80,
        marginRight: 80,
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    checkedListBoxIn: {
        '& .MuiCheckbox-colorSecondary:hover': {
            '&:hover': {
                background: 'transparent',
            },
        },
        '& label': {
            fontSize: '0.875rem',
            color: '#333',
        },
    },
    allCheckedBox: {
        '& .MuiCheckbox-colorSecondary:hover': {
            '&:hover': {
                background: 'transparent',
            },
        },
        '& label': {
            fontSize: '0.938rem',
            fontWeight: 600,
            color: '#000',
        },
    },
    checkedViewButton: {
        fontSize: '0.875rem',
        color: '#9a9a9a',
        textDecoration: 'underline',
        padding: 0,
        '&:hover': {
            background: 'transparent',
            textDecoration: 'underline',
        },
    },
});

function CheckBox({ name, value, tick, onCheck, type }) {
    const intl = useIntl();
    return (
        <label>
            <Checkbox
                name={name}
                value={value}
                checked={tick || false}
                onChange={onCheck}
                checkedIcon={<CheckCircleAgreeOnIcon />}
                icon={<CheckCircleAgreeOffIcon />}
                disableRipple
            />
            {name === 'select-all' ? null : type === POLICY_AGREEMENT_TYPE.Optional ? (
                <span>[{intl.formatMessage({ id: 'optional' })}] </span>
            ) : (
                <span style={{ color: '#ff0000' }}>[{intl.formatMessage({ id: 'required' })}] </span>
            )}

            {value}
        </label>
    );
}

function CheckBoxList({ options, isCheckedAll, onCheck, classes }) {
    const checkBoxOptions = (
        <div className="checkbox-list">
            {options.map((option, index) => {
                return (
                    <Box key={index} display="flex" alignItems="center" justifyContent="space-between" className={classes.checkedListBoxIn}>
                        <CheckBox
                            key={index}
                            name={option.name}
                            value={option.value}
                            tick={option.checked}
                            type={option.type}
                            onCheck={e => onCheck(option.value, e.target.checked)}
                        />
                        <Button className={classes.checkedViewButton} disableRipple>
                            보기
                        </Button>
                    </Box>
                );
            })}
        </div>
    );

    return (
        <Box className="checkbox-list">
            <Box display="flex" alignItems="center" className={classes.allCheckedBox}>
                <CheckBox name="select-all" value="전체 약관동의" tick={isCheckedAll} onCheck={e => onCheck('all', e.target.checked)} />
            </Box>
            {checkBoxOptions}
        </Box>
    );
}

class SocialAgreeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAllSelected: false,
            checkList: [
                {
                    name: POLICY_AGREEMENT_LABELS.TERMS_OF_SERVICE,
                    value: props.intl.formatMessage({ id: 'terms_of_service' }),
                    checked: false,
                    type: POLICY_AGREEMENT_TYPE.Required,
                },
                {
                    name: POLICY_AGREEMENT_LABELS.PRIVACY_POLICY,
                    value: props.intl.formatMessage({ id: 'privacy_policy' }),
                    checked: false,
                    type: POLICY_AGREEMENT_TYPE.Required,
                },
                {
                    name: POLICY_AGREEMENT_LABELS.ALLOWED_EMAIL,
                    value: props.intl.formatMessage({ id: 'allowed_email' }),
                    checked: false,
                    type: POLICY_AGREEMENT_TYPE.Optional,
                },
            ],
        };
    }

    componentDidMount() {
        const { ...searchParams } = getURLParams(window.location.search);
        const { params } = this.props;
        console.log(searchParams);
        // this.props.authStore.checkMember();
    }

    moveTo = path => {
        this.props.navigate(path);
    };

    handleClickSignUp = () => {
        const { ...searchParams } = getURLParams(window.location.search);
        const { params } = this.props;
        const isAllowEmail = this.state.checkList.find(check => check.name === POLICY_AGREEMENT_LABELS.ALLOWED_EMAIL).checked;
        this.props.authStore.callbackOAuthServiceForSignUp(params.platform, searchParams.code, isAllowEmail, {
            openSignUpDialog: signUpSuccessUserEmail => this.moveTo(`/signupDialog/${signUpSuccessUserEmail}`),
        });
    };

    onCheckBoxChange(checkName, isChecked) {
        let isAllChecked = checkName === 'all' && isChecked;
        let isAllUnChecked = checkName === 'all' && !isChecked;
        const checked = isChecked;

        const checkList = this.state.checkList.map((help, index) => {
            if (isAllChecked || help.value === checkName) {
                return Object.assign({}, help, {
                    checked,
                });
            } else if (isAllUnChecked) {
                return Object.assign({}, help, {
                    checked: false,
                });
            }

            return help;
        });

        let isAllSelected = checkList.findIndex(item => item.checked === false) === -1 || isAllChecked;

        this.setState({
            checkList,
            isAllSelected,
        });
    }

    render() {
        const { classes, intl, params } = this.props;
        const { isSignInAndUpResultDialogOpen, signInAndUpResultDialogMsgId } = this.props.authStore;
        const { closeSignInAndUpResultDialog } = this.props.authStore;
        const requireAgreements = this.state.checkList.filter(check => check.type === POLICY_AGREEMENT_TYPE.Required);
        // const agreements = this.state.isAllSelected || (this.state.checkList[0].checked && this.state.checkList[1].checked);
        const agreements = this.state.isAllSelected || requireAgreements.filter(check => check.checked).length === requireAgreements.length;
        return (
            <div className={classes.root}>
                <Box className={classes.boxLine}>
                    <Typography className={classes.titleText}>회원가입</Typography>
                    <Typography className={classes.subText}>
                        {SOCIAL_PLATFORM_LOGO[params.platform]}
                        &nbsp;<span style={{ color: '#333' }}>{intl.formatMessage({ id: `${params.platform}` })}</span> 계정으로 회원가입 진행
                        중입니다.
                    </Typography>
                    <Typography className={classes.subText}>아래 약관동의하면 가입이 완료됩니다.</Typography>

                    <Box className={classes.checkBoxMargin}>
                        <CheckBoxList
                            classes={this.props.classes}
                            options={this.state.checkList}
                            isCheckedAll={this.state.isAllSelected}
                            onCheck={this.onCheckBoxChange.bind(this)}
                        />
                    </Box>

                    <Button className={classes.buttonStyle} disableRipple onClick={this.handleClickSignUp} disabled={!agreements}>
                        가입
                    </Button>
                </Box>
                <ConfirmDialog
                    open={isSignInAndUpResultDialogOpen}
                    title={intl.formatMessage({ id: 'notification' })}
                    msg={intl.formatMessage({ id: `${signInAndUpResultDialogMsgId}` })}
                    changeDialogOpen={isOpen => closeSignInAndUpResultDialog(isOpen)}
                />
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('authStore')(injectIntl(observer(SocialAgreeComponent)))));
