import { ResponsiveBar } from '@nivo/bar'

const ScoreBarChart = ({ score }) => {
    const data = [
        {
            "domain": "문법 정확성",
            "문법 정확성": score["문법 정확성"],
        },
        {
            "domain": "내용 완결성",
            "내용 완결성": score["내용 완결성"],
        },
        {
            "domain": "어휘 유창성",
            "어휘 유창성": score["어휘 유창성"],
        },
        {
            "domain": "내용 구성력",
            "내용 구성력": score["내용 구성력"],
        }
    ];

    return (
        <ResponsiveBar
            data={data}
            keys={[
                '문법 정확성',
                '내용 완결성',
                '어휘 유창성',
                '내용 구성력',
            ]}
            indexBy="domain"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'pastel1' }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '영역',
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '점수',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            role="application"
            barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
        />
    )
}

export default ScoreBarChart;