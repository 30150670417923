import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Box, IconButton, Tab, Tabs, Typography } from '@material-ui/core';
import { ReactComponent as ArrowIcon } from '../../../common/images/ArrowIcon.svg';
import MobileAttendanceChart from './chart/MobileAttendanceChart';
import { inject, observer } from 'mobx-react';
import { ReactComponent as ProhibitIcon } from '../../../common/images/ProhibitIcon.svg';
import { FormattedMessage } from 'react-intl';

const style = theme => ({
    root: {
        '& .MuiTab-root': {
            minWidth: 0,
            padding: 6,
        },
        '& .MuiTabs-indicator': {
            display: 'none',
        },
        '& .MuiTab-textColorInherit.Mui-selected > span > div': {
            border: '1px solid #0097ff',
            padding: '7px 0',
        },
    },
    titleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.1rem',
        fontWeight: 600,
        color: '#0d0d0d',
        padding: '0 20px',
    },
    tabBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        margin: '20px auto 0',
        padding: '0 5px',
    },
    buttonStyle: {
        width: 45,
        padding: '9px 0',
        background: '#e1f3ff',
        borderRadius: 5,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.8rem',
        fontWeight: 600,
        color: '#1ea2ff',
        '&:hover': {
            background: '#e1f3ff',
        },
    },
    iconButton: {
        '&:hover': {
            background: 'transparent',
        },
    },
    iconButtonDisable: {
        '&:hover': {
            background: 'transparent',
        },
        '& path': {
            fill: '#a3a8af',
        },
    },
    imageText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.1rem',
        fontWeight: 800,
        color: '#0d0d0d',
    },
    noDataBox: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate( -50%, -50% )',
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: '0 20px' }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class MobileAttendanceCheck extends Component {
    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const { statisticsStore } = this.props;
        // progressStore.setIsLoading(statisticsStore.getIsLoading);
    }

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        };
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleLeftArrow = event => {
        const target = this.state.value - 1;
        const value = target <= 0 ? 0 : target;

        this.handleChange(event, value);
    };

    handleRightArrow = event => {
        const { attendanceResultList } = this.props;
        const target = this.state.value + 1;
        const value = target >= attendanceResultList.length - 1 ? attendanceResultList.length - 1 : target;

        this.handleChange(event, value);
    };

    render() {
        const { classes, statisticsStore, attendanceResultList } = this.props;
        const { value } = this.state;

        return (
            <div className={classes.root}>
                <Typography className={classes.titleText}>
                    <FormattedMessage id="attendance_check" />
                </Typography>
                {attendanceResultList.length > 0 && (
                    <React.Fragment>
                        <TabContainer>
                            {attendanceResultList
                                .filter((d, i) => value === i)
                                .map((d, i) => (
                                    <MobileAttendanceChart key={i} data={d} />
                                ))}
                        </TabContainer>
                        <Box className={classes.tabBox}>
                            <IconButton
                                disableRipple
                                className={this.state.value === 0 ? classes.iconButtonDisable : classes.iconButton}
                                onClick={this.handleLeftArrow}
                            >
                                <ArrowIcon style={{ width: 11, height: 11 }} />
                            </IconButton>
                            <Tabs
                                value={value}
                                onChange={this.handleChange}
                                // variant="fullWidth"
                                scrollButtons="on"
                            >
                                {attendanceResultList.map((d, i) => (
                                    <Tab
                                        key={i}
                                        label={
                                            <div className={classes.buttonStyle}>
                                                {i + 1}
                                                <FormattedMessage id="time" />
                                            </div>
                                        }
                                        disableRipple
                                    />
                                ))}
                            </Tabs>
                            <IconButton
                                disableRipple
                                className={this.state.value === attendanceResultList.length - 1 ? classes.iconButtonDisable : classes.iconButton}
                                style={{ marginLeft: 12 }}
                                onClick={this.handleRightArrow}
                            >
                                <ArrowIcon style={{ transform: 'rotate(180deg)', width: 11, height: 11 }} />
                            </IconButton>
                        </Box>
                    </React.Fragment>
                )}
                {attendanceResultList.length === 0 && !statisticsStore.getIsLoading && (
                    <div style={{ position: 'relative', width: '100%' }}>
                        <img style={{ width: '100%', height: '100%' }} src="/images/NoData_AttendanceCheck.png" alt="No Data" />
                        <div className={classes.noDataBox}>
                            <ProhibitIcon />
                            <Typography className={classes.imageText}>
                                <FormattedMessage id="msg.no_display_data" />
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

MobileAttendanceCheck.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(style)(inject('statisticsStore')(observer(MobileAttendanceCheck)));
