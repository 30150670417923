import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ClassNoticeComponent from './ClassNoticeComponent';
import ClassScheduleListComponent from './ClassScheduleListComponent';

const styles = _theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
    },
});

class TodayScheduleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scheduleDetail: false,
        };
    }

    handleClickDetail = roomId => {
        this.props.handleClickDetail(roomId);
    };

    render() {
        const { classes, isScrollEnd, isMember, isLeader, classPath } = this.props;

        return (
            <div className={classes.root}>
                <ClassNoticeComponent isMember={isMember} classPath={classPath} />
                <ClassScheduleListComponent
                    handleClickDetail={this.handleClickDetail}
                    isScrollEnd={isScrollEnd}
                    isMember={isMember}
                    isLeader={isLeader}
                />
            </div>
        );
    }
}

export default withStyles(styles)(TodayScheduleComponent);
