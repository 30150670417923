import React, { Component } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { ReactComponent as ProhibitIcon } from '../../../common/images/ProhibitIcon.svg';
import { FormattedMessage } from 'react-intl';

const style = theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: window.innerHeight / 3,
        '@media all and (min-width: 1400px)': {
            height: window.innerHeight / 1.8,
        },
    },
    arrowBox: {
        position: 'relative',
        background: '#fff',
        padding: '3px 7px',
        border: '1px solid #a3a8af',
        '&:after': {
            top: '100%',
            left: '50%',
            border: 'solid transparent',
            content: "''",
            height: 0,
            width: 0,
            position: 'absolute',
            pointerEvents: 'none',
            borderColor: 'rgba(255, 255, 255, 0)',
            borderTopColor: '#ffffff',
            borderWidth: '6px',
            marginLeft: '-6px',
        },
        '&:before': {
            top: '100%',
            left: '50%',
            border: 'solid transparent',
            content: "''",
            height: 0,
            width: 0,
            position: 'absolute',
            pointerEvents: 'none',
            borderColor: 'rgba(163, 168, 175, 0)',
            borderTopColor: '#a3a8af',
            borderWidth: '7px',
            marginLeft: '-7px',
        },
    },
    imageText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 18,
        fontWeight: 800,
        color: '#0d0d0d',
    },
    noDataBox: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate( -50%, -50% )',
    },
});

class ConnectChart extends Component {
    render() {
        const { classes, data, statisticsStore } = this.props;

        const MyResponsiveLine = ({ data }) => (
            <ResponsiveLine
                data={data}
                margin={{ top: 50, right: 40, bottom: 50, left: 60 }}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 0,
                    tickPadding: 15,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: 36,
                    legendPosition: 'middle',
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 0,
                    tickPadding: 15,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: -40,
                    legendPosition: 'middle',
                }}
                enableGridX={false}
                lineWidth={4}
                pointSize={15}
                pointColor="#0047ac"
                pointLabel="y"
                pointLabelYOffset={-15}
                // debugSlices={true}
                crosshairType="bottom"
                useMesh={true}
                colors="#0047ac"
                legends={[]}
                theme={{
                    crosshair: {
                        line: {
                            strokeWidth: 1,
                            stroke: '#0d0d0d',
                            strokeOpacity: 1,
                            strokeDasharray: '',
                        },
                    },
                }}
                tooltip={props => {
                    return (
                        <div className={classes.arrowBox}>
                            <Typography style={{ fontFamily: 'NanumSquareRoundOTF', color: '#0047ac', fontSize: '1rem' }}>
                                <FormattedMessage id="access_count" />/
                                <span style={{ fontWeight: 600 }}>
                                    {props.point.data.x}
                                    <FormattedMessage id="time" />/{props.point.data.y}
                                    <FormattedMessage id="person" />
                                </span>
                            </Typography>
                        </div>
                    );
                }}
            />
        );

        return (
            <div className={classes.root}>
                {data[0].data.length > 0 && MyResponsiveLine({ data: data })}
                {data[0].data.length === 0 && !statisticsStore.getIsLoading && (
                    <div style={{ position: 'relative', width: '100%' }}>
                        <img style={{ width: '100%', height: '100%' }} src="/images/NoData_ConnectStatus.png" alt="No Data" />
                        <div className={classes.noDataBox}>
                            <ProhibitIcon />
                            <Typography className={classes.imageText}>
                                <FormattedMessage id="msg.no_display_data" />
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withStyles(style)(inject('statisticsStore')(observer(ConnectChart)));
