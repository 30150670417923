import { inject, observer } from 'mobx-react';
import React from 'react';
import { isSamePage, makeNPageIdStr, PLAYSTATE, ViewControlSample } from 'nl-lib';
// import { isSamePage, makeNPageIdStr, PLAYSTATE, ViewControlSample } from '../../nl-lib';
import { withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ViewerType } from '../../stores/NeoPenStore';

const styles = () => ({
    videoBox: {
        height: 160,
        border: '1px solid #b4b4b4',
        background: '#f5f5f5',
        margin: 'auto',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#f5f5f5',
        },
    },

    videoBoxSelected: {
        height: 160,
        border: '3px solid #6e75d7',
        background: '#f5f5f5',
        margin: 'auto',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#f5f5f5',
        },
        // '@media screen and (max-width: 1300px)': {
        //     height : 50,
        //     width: '100%',
        //     overflowX: 'scroll',
        //     margin: '5px auto 0',
        // },
    },
    pdfBoxStyle: {
        height: 35,
        margin: '2px auto',
        padding: '3.5px 5.4px 5.2px 6px',
        borderRadius: 6,
        backgroundColor: '#eaeafa',
    },

    pdfBoxHoverStyle: {
        height: 35,
        margin: '2px auto',
        padding: '3.5px 5.4px 5.2px 6px',
        borderRadius: 6,
        cursor: 'pointer',
        backgroundColor: '#e6e6e6',
    },

    pdfBoxStyle1: {
        height: 35,
        margin: '2px auto',
        padding: '3.5px 5.4px 5.2px 6px',
        borderRadius: 6,
        // backgroundColor: '#eaeafa',
    },
    viewerBox: {
        margin: '8px auto 0',
    },

    textStyle2: {
        fontFamily: 'Noto Sans KR',
        fontSize: 5,
        color: '#fff',
        fontWeight: 500,
    },

    textStyle1: {
        fontFamily: 'Noto Sans KR',
        fontSize: 5,
        color: '#777',
        fontWeight: 500,
    },

    listHeader: {
        display: 'flex',
        flexBasis: '100%',
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.35)',
        fontSize: '12px',
        margin: '8px 0px',
        minWidth: 'max-content',
        '&:before': {
            content: "''",
            flexGrow: '1',
            background: 'rgba(0, 0, 0, 0.35)',
            height: '1px',
            fontSize: '0px',
            lineHeight: '0px',
            margin: '0px 16px',
        },
        '&:after': {
            content: "''",
            flexGrow: '1',
            background: 'rgba(0, 0, 0, 0.35)',
            height: '1px',
            fontSize: '0px',
            lineHeight: '0px',
            margin: '0px 16px',
        },
    },

    listHeaderSelected: {
        display: 'flex',
        flexBasis: '100%',
        alignItems: 'center',
        color: '#d93d1a',
        fontSize: '12px',
        margin: '8px 0px',
        minWidth: 'max-content',
        '&:before': {
            content: "''",
            flexGrow: '1',
            background: '#d93d1a',
            height: '1px',
            fontSize: '0px',
            lineHeight: '0px',
            margin: '0px 16px',
        },
        '&:after': {
            content: "''",
            flexGrow: '1',
            background: '#d93d1a',
            height: '1px',
            fontSize: '0px',
            lineHeight: '0px',
            margin: '0px 16px',
        },
    },

    pdfText: {
        color: '#222222',
        padding: '0 5px',
        width: 80,
    },
    pdfTextClick: {
        color: '#222222',
        padding: '0 5px',
        fontWeight: 'bold',
        width: 80,
    },
    textStyle: {
        fontFamily: 'Noto Sans KR',
        fontSize: '12px',
        textAlign: 'left',
    },
    pdfStyle: {
        width: 15,
        height: 15,
        borderRadius: '50%',
        background: '#6e75d7',
        margin: 'auto',
    },
    pdfStyle1: {
        width: 25,
        height: 15,
        borderRadius: 7,
        background: '#6e75d7',
        margin: 'auto',
    },
    scrollStyle: {
        width: 100,
        overflowX: 'auto',
        overflowY: 'hidden',
    },
    scrollStyleLandscape: {
        '@media screen and (max-width: 1280px)': {
            width: '100%',
            // height: 'inherit',
            overflowX: 'auto',
            overflowY: 'hidden',
        },
    },
    popoverBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 5px 7px 0 rgba(0, 0, 0, 0.45)',
            borderRadius: 4,
        },
        '& .MuiMenuItem-root': {
            justifyContent: 'flex',
            padding: '10px 28px',
            fontFamily: 'Noto Sans KR',
            color: '#6c6c70',
            fontSize: 15,
            '&:hover': {
                background: '#e8e8e8',
            },
        },
    },
});

class MobileNeoPenPageViewer extends React.Component {
    state = {
        pdfMoreBtn: false,
    };

    pageList = [];
    listRef = React.createRef();
    pdfMoreButtonRef = React.createRef();

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.listRef !== undefined && this.listRef.current) this.listRef.current.scrollToItem(this.activePageIndex, 'smart');
    }

    handleClickView = pageInfo => {
        const nPS = this.props.neoPenStore;

        console.log('handleClickView', pageInfo);

        nPS.setActivePage(pageInfo);
    };

    renderRow = ({ data, index, style }) => {
        const { classes, isMobile } = this.props;
        const nPS = this.props.neoPenStore;

        const pens = [...nPS.pens];
        const surfaceOwnerId = nPS.surfaceOwnerId;

        const pageInfoString = makeNPageIdStr(data[index].pageInfo) + surfaceOwnerId;

        const isSelected = data[index].isSelected;

        let pageNum = data[index].pageInfo.page - nPS.selectedPaperGroup.pageStart + 1;

        let pageImgUrl = nPS.thumbnailUrlWithSobp[makeNPageIdStr(data[index].pageInfo)];

        return (
            <Box key={pageInfoString + 'Box1' + index} style={style} onClick={() => this.handleClickView(data[index].pageInfo)}>
                <Box
                    key={pageInfoString + 'Box2' + index}
                    className={isSelected ? classes.videoBoxSelected : classes.videoBox}
                    style={{ pointerEvents: 'none', width: 110 }}
                >
                    {pageImgUrl &&
                        <Box
                            style={{width:"100%", height:"100%", objectFit:"contain"}}
                            component="img"
                            alt="page viewer"
                            src={pageImgUrl}
                        />
                    }
                </Box>

                {!isSelected ? (
                    <Typography key={pageInfoString + 'PageTypography'} className={classes.textStyle1} style={{ textAlign: 'center' }}>
                        {pageNum}
                    </Typography>
                ) : pageNum <= 99 ? (
                    <div className={classes.pdfStyle}>
                        <Typography key={pageInfoString + 'PageTypography' + index} className={classes.textStyle2} style={{ textAlign: 'center' }}>
                            <b>{pageNum}</b>
                        </Typography>
                    </div>
                ) : (
                    <div className={classes.pdfStyle1}>
                        <Typography key={pageInfoString + 'PageTypography' + index} className={classes.textStyle2} style={{ textAlign: 'center' }}>
                            <b>{pageNum}</b>
                        </Typography>
                    </div>
                )}
            </Box>
        );
    };

    render() {
        const { classes } = this.props;
        const nPS = this.props.neoPenStore;

        this.pageList = [];

        if (nPS.viewerType === ViewerType.Normal) {
            nPS.pageInfos.forEach(pageInfo => {
                let isSelected = false;

                if (isSamePage(pageInfo, nPS.activePage)) {
                    isSelected = true;

                    this.activePageIndex = this.pageList.length;
                }

                this.pageList.push({ pageInfo: pageInfo, isSelected: isSelected });
            });
        } else if (nPS.viewerType === ViewerType.RealTime) {
            nPS.realTimePageInfos.forEach(pageInfo => {
                let isSelected = false;

                if (isSamePage(pageInfo, nPS.activePage)) {
                    isSelected = true;

                    this.activePageIndex = this.pageList.length;
                }

                let selectedPaperGroup = undefined;

                nPS.realTimePaperGroups.forEach(paperGroup => {
                    if (nPS.isStrokeInPaperGroup(nPS.activePage, paperGroup)) {
                        selectedPaperGroup = paperGroup;
                    }
                });

                if (selectedPaperGroup !== undefined && nPS.isStrokeInPaperGroup(pageInfo, selectedPaperGroup)) {
                    this.pageList.push({ pageInfo: pageInfo, isSelected: isSelected });
                }
            });
        }

        let thumbnailLoadedLen = Object.keys(nPS.thumbnailUrlWithSobp).length;;

        console.log("thumbnailLoadedLen", thumbnailLoadedLen);

        return (
            <Box display="flex" flexDirection="column" className={classes.scrollStyleLandscape}>
                <div style={{ height: 185 }}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <List
                                className={classes.scrollStyle}
                                ref={this.listRef}
                                width={width}
                                height={220}
                                itemSize={130}
                                layout="horizontal"
                                itemCount={this.pageList.length}
                                itemData={this.pageList}
                            >
                                {this.renderRow}
                            </List>
                        )}
                    </AutoSizer>
                </div>
            </Box>
        );
    }
}

export default withStyles(styles)(inject('neoPenStore')(observer(MobileNeoPenPageViewer)));
