import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, IconButton, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as HandsClappingIcon } from '../../common/images/HandsClappingIcon.svg';
import { ReactComponent as ChatCircleDotsIcon } from '../../common/images/ChatCircleDotsIcon.svg';
import { ReactComponent as SmileyFillIcon } from '../../common/images/SmileyFillIcon.svg';
import { ReactComponent as AlarmFillIcon } from '../../common/images/AlarmFillIcon.svg';
import { ReactComponent as ChartBarFillIcon } from '../../common/images/ChartBarFillIcon.svg';
import CommentComponent from './CommentComponent';
import MemberProfileDialogComponent from '../dialog/MemberProfileDialogComponent';
import { inject, observer } from 'mobx-react';
import { DATE_UTIL } from '../../common/util/date.util';
import clsx from 'clsx';
import { loadingSate, roomState } from '../../stores/RoomStore';
import dayjs from 'dayjs';
import { injectIntl } from 'react-intl';
import MoreButton from '../../components/common/MoreButton';
import YesOrNoDialog from '../../components/common/YesOrNoDialog';
import { withRouter } from '../../components/WithRouter';
import ScheduleRegistrationComponent from '../dialog/ScheduleRegistrationComponent';
import { CommentType, SortType } from '../../stores/CommentStore';
import { ReactComponent as DotIcon } from '../../common/images/DotIcon.svg';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { ToastsStore } from 'react-toasts';
import { SearchCategory, SearchScope } from '../../common/SearchItems';
import { Skeleton } from '@material-ui/lab';
import { getNoticeContents } from '../../common/Validation';
import { TagType } from '../../stores/ClassStore';

const styles = theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '0 auto',
        boxSizing: 'border-box',
        'button:hover': {
            background: 'transparent',
        },
        '& ul, ol': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    center: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
    },
    right: {
        '@media all and (min-width: 1500px)': {
            width: 235,
            marginLeft: 90,
        },
        width: 230,
        marginLeft: 30,
    },
    titleStyle: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginTop: 30,
        paddingBottom: 7,
        marginBottom: 25,
        '& h3': {
            fontSize: '1.875rem',
            fontWeight: 500,
            marginBottom: 8,
            wordBreak: 'break-all',
        },
        '& p': {
            fontSize: '0.75em',
            color: '#0d0d0d',
            marginBottom: 10,
        },
    },
    content: {
        fontSize: '0.875rem',
        lineHeight: 1.45,
    },
    contentsText: {
        fontSize: '0.875rem',
        marginBottom: 30,
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
    },
    listStyle: {
        margin: '8px 0!important',
        '& li': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.875rem',
            marginTop: 7,
            '&:first-child': {
                marginTop: 0,
            },
            '& svg': {
                marginRight: 4,
            },
        },
    },
    avatarList: {
        display: 'flex',
        cursor: 'pointer',
        '& li': {
            marginTop: 5,
            marginRight: '-6px',
            '&>div': {
                width: 30,
                height: 30,
                zIndex: 10,
                border: '1px solid #fff',
                overflow: 'hidden',
            },
            '&:last-child': {
                zIndex: 20,
            },
        },
    },
    avatar: {
        background: '#a3a8af',
        boxShadow: 'rgb(0 0 0 / 38%) 0px 1px 1px',
        '& svg': {
            width: 18,
            height: 18,
            '& path': {
                fill: '#fff',
            },
        },
    },
    avatarLastStyle: {
        width: 30,
        height: 30,
        backgroundColor: '#F1F1F1',
        border: '1px solid #fff',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
        fontSize: '0.688rem',
    },
    asideControl: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    btnOutlineStyle: {
        border: () => (theme.configs.MainBtnColor ? `1px solid ${theme.configs.MainBtnColor}` : '1px solid #0097FF'),
        color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        fontWeight: 600,
        padding: '6px 27px',
        borderRadius: 7,
        fontSize: '0.938rem',
        '&:hover': {
            fontWeight: 800,
            background: 'transparent',
        },
    },
    chipBox: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 12,
        flexWrap: 'wrap',
        // marginTop: 44,
    },
    chip: {
        textAlign: 'center',
        height: '21!important',
        backgroundColor: '#eee',
        color: '#656565',
        marginRight: 6,
        marginBottom: 4,
        padding: '1px 12px',
        fontSize: '0.938rem',
        border: '1px solid #eee',
        borderRadius: 50,
        '&:hover': {
            border: '1px solid #dbf0ff',
            backgroundColor: '#dbf0ff!important',
            color: '#0097ff!important',
            cursor: 'pointer',
        },
    },
    boxFooter: {
        border: '0 solid #e1e1e1',
        borderWidth: '1px 0',
        marginTop: 33,
        marginBottom: 19,
    },
    ftCount: {
        padding: '10px 0',
        borderBottom: '1px solid #fff',
        display: 'flex',
        '& div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.75rem',
            color: '#7F7F7F',
            marginRight: 7,
            '& svg': {
                width: 14,
                height: 14,
                opacity: 0.5,
                marginRight: 4,
            },
        },
    },
    ftAdded: {
        padding: '10px 60px',
        '& button': {
            width: '50%',
            textAlign: 'center',
            cursor: 'pointer',
            '&:hover': {
                background: 'transparent',
                fontWeight: 800,
            },
            '& svg': {
                marginRight: 4,
                opacity: 0.5,
            },
            '&.Mui-disabled': {
                color: '#0d0d0d',
            },
        },
    },
    active: {
        color: '#0097ff',
        fontWeight: 700,
    },
    iconActive: {
        fill: '#0097ff',
        '& path': {
            fill: '#0097ff',
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        textAlign: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    caption: {
        backgroundColor: 'transparent',
        color: '#fff',
        padding: '2px 6px',
        height: 19,
        boxSizing: 'border-box',
        fontSize: '0.625rem',
        fontFamily: 'Montserrat!important',
        textTransform: 'uppercase',
        fontWeight: 800,
        display: 'inline-flex',
        alignItems: 'center',
        marginBottom: 5,
        '& svg': {
            marginRight: 3,
        },
    },
    captionEnd: {
        backgroundColor: '#505050',
    },
    captionFuture: {
        backgroundColor: '#a3a8af',
    },
    captionLive: {
        backgroundColor: '#FB4A59',
    },
    skeletonStyle: {
        borderRadius: 10,
    },
    btnDefault: {
        padding: 0,
        marginBottom: 4,
        marginRight: 10,
    },
});

const LightTooltip = withStyles(_theme => ({
    tooltip: {
        padding: '4px 4px',
        border: '0.3px solid #000000',
        background: '#fffff5',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        color: '#000',
        borderRadius: '0',
        marginLeft: 5,
        marginTop: 5,
    },
}))(Tooltip);

class ClassScheduleDetailContentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            GoodToggle: false,
            memberDialog: false,
            dialogOpen: false,
            deleteDialogOpen: false,
            selectedRoomIsLive: false,
        };
    }

    componentDidMount() {
        const { params } = this.props;
        const roomId = Number(params.roomId);
        if (roomId) {
            this.props.roomStore.getRoomDetail(roomId, this.props.intl, {
                selectCommentList: groupId => {
                    this.props.commentStore.getCommentList(groupId, roomId, CommentType.ROOM, 0, SortType.TIME);
                },
            });
        } else {
            console.log('NotAcceptable Path');
            this.props.navigate('/rooms');
        }
    }

    componentWillUnmount() {
        this.props.roomStore.initRoom();
        this.props.roomStore.selectedRoomInit();
    }

    handleClose = () => {
        this.setState({
            memberDialog: false,
            dialogOpen: false,
            deleteDialogOpen: false,
        });
    };

    handleClickDialog = () => {
        this.setState({ memberDialog: true });
    };

    handleChangeGoodToggle = () => {
        this.setState({ GoodToggle: !this.state.GoodToggle });
    };

    handleClickEnter = (event, url) => {
        event.stopPropagation();
        window.open(url);
    };

    handleChangeLikeButton = (userId, groupId, roomId, likeId) => {
        this.props.roomStore.changeLikeState(userId, groupId, roomId, likeId, true);
    };

    checkRoomState = (startDatetime, endDatetime) => {
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedStartDatetime = DATE_UTIL.convertUTCToTimeZone(startDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedEndDatetime = DATE_UTIL.convertUTCToTimeZone(endDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        if (dayjs(convertedEndDatetime).isBefore(currentDatetime)) {
            return roomState.Ended;
        } else if (
            dayjs(currentDatetime).isBetween(convertedStartDatetime, convertedEndDatetime) ||
            dayjs(currentDatetime).isSame(convertedStartDatetime) ||
            dayjs(currentDatetime).isSame(convertedEndDatetime)
        ) {
            return roomState.Live;
        } else {
            return roomState.Future;
        }
    };

    handleClickCommentBtn = () => {
        const element = document.getElementsByClassName('react-input-emoji--input')[0];
        element.focus();
    };

    handleModifyRoom = () => {
        const state = this.checkRoomState(this.props.roomStore.roomDummy.startDatetime, this.props.roomStore.roomDummy.endDatetime);
        if (state && state === roomState.Live) {
            this.setState({ selectedRoomIsLive: true });
        } else {
            this.setState({ selectedRoomIsLive: false });
        }
        this.props.roomStore.createRoomDummy(this.props.authStore.loginUser.id, {
            changeState: () => this.setState({ dialogOpen: true }),
        });
    };

    handleDeleteRoom = () => {
        this.setState({ deleteDialogOpen: true });
    };

    handleSearchByTagName = (e, tagName) => {
        e.stopPropagation();
        const { classStore, roomStore, authStore, postStore, searchStore, navigate } = this.props;
        classStore.initSearchClass();
        roomStore.initRoom();
        postStore.initPost();
        searchStore.initCurrentPage();
        searchStore.changeSearchKeyword(tagName);
        classStore.getGroupListByKeyword(tagName, {
            moveToSearchPage: () => navigate(`/search/${SearchScope.All}/${SearchCategory.Study}/${tagName}`),
            getRoomList: () =>
                roomStore.getRoomListGroupByStartTime(undefined, authStore.loginUser.id, 'all', 1, undefined, undefined, undefined, tagName),
            getPostList: () => postStore.getPostList(undefined, authStore.loginUser.id, tagName, 1, 16),
        });
    };

    handleClickStatistics = (e, room) => {
        e.preventDefault();
        e.stopPropagation();
        // window.open(`/class/${room.groupId}/rooms/${room.id}/statistics`);
        window.open(`/statistics/${room.id}`);
    };

    render() {
        const { classes, isScrollEnd, currentTab, params, isMember, isLeader, scrollMove, intl } = this.props;
        const { roomDummy, isLoadRoomLoading } = this.props.roomStore;
        const { loginUser } = this.props.authStore;
        const { groupUserAuthority } = this.props.classStore;
        const state = this.checkRoomState(roomDummy.startDatetime, roomDummy.endDatetime);
        const me = roomDummy.members && roomDummy.members.find(m => m.userId === this.props.authStore.loginUser.id);

        return (
            <div className={classes.root}>
                <Box className={classes.center}>
                    <Box className={classes.titleStyle}>
                        <Box>
                            {state === roomState.Ended && <Box className={clsx(classes.caption, classes.captionEnd)}>종료</Box>}
                            {state === roomState.Future && (
                                <Box className={clsx(classes.caption, classes.captionFuture)}>
                                    <DotIcon />
                                    예정
                                </Box>
                            )}
                            {state === roomState.Live && (
                                <Box className={clsx(classes.caption, classes.captionLive)}>
                                    <DotIcon />
                                    LIVE
                                </Box>
                            )}
                            <Typography variant="h3">
                                {isLoadRoomLoading ? (
                                    <Skeleton
                                        className={classes.skeletonStyle}
                                        variant="rect"
                                        width={100}
                                        height={35}
                                        style={{ marginBottom: 100 }}
                                    />
                                ) : (
                                    <React.Fragment> {roomDummy.name} </React.Fragment>
                                )}{' '}
                            </Typography>
                        </Box>
                        {groupUserAuthority.RoomModify && state !== roomState.Live && (
                            <MoreButton
                                menuItems={
                                    state === roomState.Future
                                        ? [
                                              {
                                                  name: intl.formatMessage({ id: 'modify' }),
                                                  menuFunc: () => this.handleModifyRoom(),
                                              },
                                              // {
                                              //     name: intl.formatMessage({ id: 'copy' }),
                                              //     menuFunc: () => this.handleClose(),
                                              // },
                                              // {
                                              //     name: '공유',
                                              //     menuFunc: () => this.handleClose(),
                                              // },
                                              {
                                                  name: intl.formatMessage({ id: 'delete' }),
                                                  menuFunc: () => this.handleDeleteRoom(),
                                              },
                                          ]
                                        : [
                                              // {
                                              //     name: intl.formatMessage({ id: 'copy' }),
                                              //     menuFunc: () => this.handleClose(),
                                              // },
                                              // {
                                              //     name: '공유',
                                              //     menuFunc: () => this.handleClose(),
                                              // },
                                              {
                                                  name: intl.formatMessage({ id: 'delete' }),
                                                  menuFunc: () => this.handleDeleteRoom(),
                                              },
                                          ]
                                }
                            />
                        )}
                    </Box>
                    <Box className={classes.content}>
                        <div className={classes.contentsText}>{getNoticeContents(roomDummy.comment)}</div>
                        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                            <Box>
                                <ul className={classes.listStyle}>
                                    <li>
                                        <SmileyFillIcon />{' '}
                                        {isLoadRoomLoading ? (
                                            <Skeleton className={classes.skeletonStyle} variant="rect" width={100} height={20} />
                                        ) : (
                                            <React.Fragment> {roomDummy.userName} </React.Fragment>
                                        )}
                                    </li>
                                    <li>
                                        <AlarmFillIcon />
                                        {isLoadRoomLoading ? (
                                            <Skeleton className={classes.skeletonStyle} variant="rect" width={300} height={20} />
                                        ) : (
                                            <React.Fragment>
                                                {DATE_UTIL.convertUTCToTimeZone(roomDummy.startDatetime, DATE_UTIL.FORMAT.FULL_DATE_AMPM_DOT_FORMAT)}{' '}
                                                ~ {DATE_UTIL.convertUTCToTimeZone(roomDummy.endDatetime, DATE_UTIL.FORMAT.FULL_DATE_AMPM_DOT_FORMAT)}
                                            </React.Fragment>
                                        )}
                                    </li>
                                </ul>
                                <ul className={classes.avatarList} onClick={this.handleClickDialog}>
                                    {roomDummy.members && roomDummy.members.length > 0 && (
                                        <React.Fragment>
                                            {roomDummy.members.slice(0, 5).map((user, i) => (
                                                <li key={i}>
                                                    <Avatar className={classes.avatar} src={user.image}>
                                                        <AsideUserIcon />
                                                    </Avatar>
                                                </li>
                                            ))}
                                            {roomDummy.members.length > 5 && (
                                                <li>
                                                    <div className={classes.avatarLastStyle}>+{roomDummy.members.length - 5}</div>
                                                </li>
                                            )}
                                        </React.Fragment>
                                    )}
                                </ul>
                            </Box>
                            {state === roomState.Live && me && (
                                <Button className={classes.btnOutlineStyle} disableRipple onClick={e => this.handleClickEnter(e, me.entranceUrl)}>
                                    참석하기
                                </Button>
                            )}
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={6}>
                        {roomDummy.tagList.length > 0 && (
                            <Box className={classes.chipBox}>
                                {roomDummy.tagList.map((tag, i) => (
                                    <Typography
                                        key={`${tag.tagId}_${i}`}
                                        className={classes.chip}
                                        onClick={e => this.handleSearchByTagName(e, tag.name)}
                                    >
                                        {tag.name}
                                    </Typography>
                                ))}
                            </Box>
                        )}
                        {state === roomState.Ended && isLeader && roomDummy.statistics && (
                            <LightTooltip title="학습 결과" placement="bottom" className={classes.btnDefault}>
                                <IconButton disableRipple onClick={e => this.handleClickStatistics(e, roomDummy)}>
                                    <ChartBarFillIcon />
                                </IconButton>
                            </LightTooltip>
                        )}
                    </Box>
                    <Box className={classes.boxFooter}>
                        <Box className={classes.ftCount}>
                            <Box>
                                <HandsClappingIcon
                                    className={roomDummy.likeList.find(user => user.userId === loginUser.id) ? classes.iconActive : null}
                                />{' '}
                                {roomDummy.likeList.length}{' '}
                            </Box>
                            <Box>
                                <ChatCircleDotsIcon />
                                {roomDummy.commentCount}{' '}
                            </Box>
                        </Box>
                        <Box className={classes.ftAdded}>
                            <Button
                                disableRipple
                                disabled={this.props.roomStore.likeLoading === loadingSate.PENDING}
                                onClick={() =>
                                    this.handleChangeLikeButton(
                                        loginUser,
                                        roomDummy.groupId,
                                        roomDummy.id,
                                        roomDummy.likeList.find(user => user.userId === loginUser.id)
                                            ? roomDummy.likeList.find(user => user.userId === loginUser.id).likeId
                                            : 0,
                                    )
                                }
                                className={
                                    roomDummy.likeList.find(user => user.userId === loginUser.id) ? clsx(classes.active, classes.iconActive) : null
                                }
                            >
                                <HandsClappingIcon /> 좋아요
                            </Button>
                            <Button
                                disableRipple
                                onClick={
                                    isMember && groupUserAuthority.RoomComment
                                        ? this.handleClickCommentBtn
                                        : () =>
                                              ToastsStore.error(
                                                  isMember ? '댓글 작성 권한이 없습니다.' : '클래스에 가입하여야 댓글을 사용할 수 있습니다.',
                                                  1500,
                                              )
                                }
                            >
                                <ChatCircleDotsIcon /> 댓글 달기
                            </Button>
                        </Box>
                    </Box>
                    <CommentComponent
                        isScrollEnd={isScrollEnd}
                        currentTab={currentTab}
                        typeId={roomDummy.id}
                        type={CommentType.ROOM}
                        groupId={params.id}
                        isMember={isMember}
                        scrollMove={scrollMove}
                    />
                </Box>

                {/*<Box className={classes.right}>*/}
                {/*    <ClassContentSearchComponent />*/}
                {/*</Box>*/}

                <MemberProfileDialogComponent memberDialog={this.state.memberDialog} handleClose={this.handleClose} members={roomDummy.members} />
                <ScheduleRegistrationComponent
                    handleClose={this.handleClose}
                    dialogOpen={this.state.dialogOpen}
                    isModify={true}
                    selectedRoomIsLive={this.state.selectedRoomIsLive}
                    scrollMove={this.props.scrollMove}
                />
                <YesOrNoDialog
                    open={this.state.deleteDialogOpen}
                    title={'일정'}
                    msg={'수업을 삭제하시겠습니까?'}
                    changeDialogOpen={this.handleClose}
                    submit={() =>
                        this.props.roomStore.deleteRoom({
                            getTagList: () => this.props.classStore.getTagList(roomDummy.groupId, TagType.ROOM),
                            moveToPrevPage: () => this.props.navigate(-1),
                        })
                    }
                />
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(
        inject(
            'authStore',
            'roomStore',
            'commentStore',
            'classStore',
            'postStore',
            'searchStore',
        )(injectIntl(observer(ClassScheduleDetailContentComponent))),
    ),
);
