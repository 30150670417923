import React, { Component } from 'react';
import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import BlockIcon from '@mui/icons-material/Block';
import { withRouter } from '../WithRouter';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0',
        marginTop: 56,
        boxSizing: 'border-box',
        '@media all and (min-width: 601px)': {
            padding: '0',
            marginTop: 66,
        },
        '@media all and (min-width: 1200px)': {
            padding: '0',
            marginTop: 0,
        },
        '& .MuiIconButton-edgeEnd': {
            marginRight: 0,
        },
    },
    bottomBox: {
        // background: '#fbfbfb',
        width: '100%',
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '20px 0',
        '@media all and (min-width: 1200px)': {
            padding: '60px 0',
            marginTop: 50,
        },
    },
    bottomText: {
        fontSize: '1rem',
        fontWeight: '600',
        marginBottom: 20,
        '@media all and (min-width: 601px)': {
            fontSize: '2rem',
        },
    },
    bottomText2: {
        fontSize: '0.813rem',
        fontWeight: '600',
        marginTop: 20,
        marginBottom: 20,
        '@media all and (min-width: 601px)': {
            fontSize: '1rem',
            marginTop: 25,
            marginBottom: 25,
        },
    },
    bottomButton: {
        background: '#0097ff',
        borderRadius: 7,
        width: 110,
        height: 35,
        color: '#fff',
        fontSize: '0.95rem',
        fontWeight: 800,
        textDecoration: 'none',
        '&:hover': {
            background: '#0097ff',
        },
        '@media all and (min-width: 601px)': {
            width: 184,
            height: 48,
            '& a': {
                fontSize: '0.875rem',
            },
        },
    },
});

class EmptyPage extends Component {
    constructor(props) {
        super(props);
    }

    handleGoToBack = () => {
        this.props.navigate(-1);
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Box className={classes.bottomBox}>
                    <BlockIcon fontSize={'large'} />
                    <Typography className={classes.bottomText}>해당페이지를 찾을 수 없습니다.</Typography>
                    {/*<Typography className={classes.bottomText2}>도입 및 기술지원, 사용에 대해 언제든 문의주세요</Typography>*/}
                    <Button className={classes.bottomButton} disableRipple onClick={this.handleGoToBack}>
                        돌아가기
                    </Button>
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(EmptyPage));
