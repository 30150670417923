import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import MobileDashboardTabTriggerComponent from './MobileDashboardTabTriggerComponent';
import MobileMyManagementContentsComponent from './MobileMyManagementContentsComponent';
import { inject } from 'mobx-react';
import { MyClassRoomSortType } from '../../../stores/RoomStore';
import CustomSearchComponent from '../../../components/CustomSearchComponent';
import { INIT_EMPTY_SEARCH_KEYWORD } from '../../../stores/PaginationStore';
import ClassManagementTabComponent from '../../dashboard/ClassManagementTabComponent';
import MobileClassManagementRecordComponent from '../class/MobileClassManagementRecordComponent';

const styles = _theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    wrap: {
        width: 'calc(100vw - 32px)',
        margin: '0 auto',
        padding: 0,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    contentsWrap: {
        position: 'relative',
        width: 'calc(100vw - 32px)',
        margin: '0 auto',
        padding: '20px 0px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        '@media all and (max-width: 600px)': {
            padding: '10px 0px',
        },
    },
    contents: {
        width: 'calc(100vw - 32px)',
        margin: '0px auto 30px',
    },
    searchStyle: {
        padding: '0 16px',
    },
    gutter: {
        backgroundColor: 'rgba(192, 194, 195, 0.7)',
        width: '100%',
        height: 1,
    },

    titleCaption: {
        fontSize: '0.938rem',
    },
    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
        '@media all and (max-width: 600px)': {
            margin: '10px 0 0px',
        },
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: 12,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listItemTitle: {
        fontSize: '0.875rem',
        display: 'flex',
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
});
class MobileClassManagementComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 0,
            classManagementTabs: 0,
            page: 1,
            rowsPerPage: 5,
            filter: MyClassRoomSortType.Recent,
            selectedGroupId: 0,
            recordDialogOpen: false,
            isSearching: false,
            // addAndDeleteDialogOpen: false,
            isLeader: true,
        };
    }

    componentDidMount() {
        const { searchKeyword } = this.props.roomStore;
        if (searchKeyword) {
            this.handleChangeSearchKeywordForRooms();
        }

        this.props.roomStore.getSimpleRoomListByGroupId(this.state.selectedGroupId, this.props.authStore.loginUser.id, this.state.filter, 1);
    }

    componentWillUnmount() {
        this.props.roomStore.initRoom();
    }

    handleChangeSort = event => {
        this.setState({ filter: event.target.value, page: 1 }, () => {
            this.props.roomStore.getSimpleRoomListByGroupId(
                this.state.selectedGroupId,
                this.props.authStore.loginUser.id,
                this.state.filter,
                this.state.page,
            );
        });
    };

    handleChange = (event, tabs) => {
        this.setState({ tabs, page: 1 });
    };

    handleClose = () => {
        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
            // addAndDeleteDialogOpen: false,
        });
    };

    handleClickGeneral = event => {
        this.setState({ anchorGeneral: event.currentTarget });
    };

    handleClickRecordInfo = () => {
        this.setState({
            recordDialogOpen: true,
        });
    };

    handleCloseRecordDialog = () => {
        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
            recordDialogOpen: false,
        });
    };

    // handleClickAddAndDelete = () => {
    //     this.setState({
    //         addAndDeleteDialogOpen: true,
    //     });
    // };

    setGroupId = groupId => {
        this.setState({ selectedGroupId: groupId });
        // this.setState({ selectedGroupId: groupId }, () => {
        //     this.requestGroupAuthority(groupId);
        // });
    };

    requestGroupAuthority = groupId => {
        this.props.classStore.getGroupAuthorities(groupId, {
            setGroupUserAuthority: authorities => {
                this.props.classStore.setGroupUserAuthority(this.props.authStore.loginUser.id, authorities);
            },
        });
    };

    handleChangeRowsPerPage = e => {
        this.setState({ rowsPerPage: e.target.value, page: 1 }, () => {
            this.props.roomStore.changeMyClassRoomRowsPerPage(this.state.rowsPerPage);
            this.props.roomStore.getSimpleRoomListByGroupId(
                this.state.selectedGroupId,
                this.props.authStore.loginUser.id,
                this.state.filter,
                this.state.page,
            );
        });
    };

    handleClickNextBtn = () => {
        this.setState({ page: this.state.page + 1 }, () => {
            this.props.roomStore.getSimpleRoomListByGroupId(
                this.state.selectedGroupId,
                this.props.authStore.loginUser.id,
                this.state.filter,
                this.state.page,
            );
        });
    };

    handleClickPrevBtn = () => {
        this.setState({ page: this.state.page - 1 }, () => {
            this.props.roomStore.getSimpleRoomListByGroupId(
                this.state.selectedGroupId,
                this.props.authStore.loginUser.id,
                this.state.filter,
                this.state.page,
            );
        });
    };

    requestSearchRoomsByKeyword = keyword => {
        const { classGroupList } = this.props;
        this.handleChangeSearchKeywordForRooms(keyword);
        this.setState({ page: 1, tabs: classGroupList.length + 1 }, () => {
            this.props.roomStore.getSimpleRoomListByGroupId(
                this.state.selectedGroupId,
                this.props.authStore.loginUser.id,
                this.state.filter,
                this.state.page,
            );
        });
    };

    handleChangeSearchKeywordForRooms = (keyword = INIT_EMPTY_SEARCH_KEYWORD) => {
        this.props.roomStore.setSearchKeyword(keyword);
    };

    handleChangeClassManagementTabs = (event, classManagementTabs) => {
        this.setState({ classManagementTabs });
    };

    render() {
        //TODO : 디자인 수정 필요
        const { classes, classGroupList, myClassRoomList, myClassRoomCount, isMobile } = this.props;
        const { tabs, anchorGeneral, page, rowsPerPage } = this.state;
        const general = Boolean(anchorGeneral);

        return (
            <div className={classes.root}>
                <Box className={classes.wrap}>
                    <MobileDashboardTabTriggerComponent
                        tabs={this.state.tabs}
                        filter={this.state.filter}
                        handleChange={this.handleChange}
                        setGroupId={this.setGroupId}
                        classGroupList={classGroupList}
                        parentTab={this.props.parentTab}
                        searchingKeyword={this.props.roomStore.searchKeyword}
                        initSearchKeyword={this.handleChangeSearchKeywordForRooms}
                    />
                </Box>
                <Box className={classes.gutter} />
                {/*<div className={classes.searchStyle}>*/}
                {/*    <CustomSearchComponent*/}
                {/*        searchingKeyword={this.props.roomStore.searchKeyword}*/}
                {/*        isMobile={isMobile}*/}
                {/*        placeholderText={'수업 검색'}*/}
                {/*        isLoading={this.props.roomStore.isLoadRoomLoading}*/}
                {/*        fetchDataByKeyword={this.requestSearchRoomsByKeyword}*/}
                {/*    />*/}
                {/*</div>*/}
                <Box className={classes.contentsWrap}>
                    <div className={classes.contents}>
                        {/*{tabs === 0 && (*/}
                        <div className={classes.contents}>
                            <ClassManagementTabComponent
                                classManagementTabs={this.state.classManagementTabs}
                                handleChangeClassManagementTabs={this.handleChangeClassManagementTabs}
                                selectedGroupId={this.state.selectedGroupId}
                                myClassRoomCount={myClassRoomCount}
                            />
                            {this.state.classManagementTabs === 0 && (
                                <MobileClassManagementRecordComponent selectedGroupId={this.state.selectedGroupId} />
                            )}
                            {this.state.classManagementTabs === 1 && (
                                <MobileMyManagementContentsComponent
                                    myClassRoomList={myClassRoomList}
                                    myClassRoomCount={myClassRoomCount}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    handleClickPrevBtn={this.handleClickPrevBtn}
                                    handleClickNextBtn={this.handleClickNextBtn}
                                    handleClose={this.handleClose}
                                    handleClickGeneral={this.handleClickGeneral}
                                    handleChangeSort={this.handleChangeSort}
                                    filter={this.state.filter}
                                    anchorGeneral={anchorGeneral}
                                    handleClickRecordInfo={this.handleClickRecordInfo}
                                    handleCloseRecordDialog={this.handleCloseRecordDialog}
                                    recordDialogOpen={this.state.recordDialogOpen}
                                    // handleClickAddAndDelete={this.handleClickAddAndDelete}
                                    addAndDeleteDialogOpen={this.state.addAndDeleteDialogOpen}
                                    isSearchingRoom={this.props.roomStore.isSearchingRoom}
                                />
                            )}
                            {/*{tabs === 1 && <div>클래스2</div>}*/}
                        </div>
                        {/*<div className={classes.searchStyle}>*/}
                        {/*    <CustomSearchComponent*/}
                        {/*        searchingKeyword={this.props.roomStore.searchKeyword}*/}
                        {/*        isMobile={isMobile}*/}
                        {/*        placeholderText={'수업 검색'}*/}
                        {/*        isLoading={this.props.roomStore.isLoadRoomLoading}*/}
                        {/*        fetchDataByKeyword={this.requestSearchRoomsByKeyword}*/}
                        {/*    />*/}
                        {/*</div>*/}

                        {/*<MobileMyManagementContentsComponent*/}
                        {/*    myClassRoomList={myClassRoomList}*/}
                        {/*    myClassRoomCount={myClassRoomCount}*/}
                        {/*    page={page}*/}
                        {/*    rowsPerPage={rowsPerPage}*/}
                        {/*    handleChangeRowsPerPage={this.handleChangeRowsPerPage}*/}
                        {/*    handleClickPrevBtn={this.handleClickPrevBtn}*/}
                        {/*    handleClickNextBtn={this.handleClickNextBtn}*/}
                        {/*    handleClose={this.handleClose}*/}
                        {/*    handleClickGeneral={this.handleClickGeneral}*/}
                        {/*    handleChangeSort={this.handleChangeSort}*/}
                        {/*    filter={this.state.filter}*/}
                        {/*    anchorGeneral={this.state.anchorGeneral}*/}
                        {/*    handleClickRecordInfo={this.handleClickRecordInfo}*/}
                        {/*    handleCloseRecordDialog={this.handleCloseRecordDialog}*/}
                        {/*    recordDialogOpen={this.state.recordDialogOpen}*/}
                        {/*    // handleClickAddAndDelete={this.handleClickAddAndDelete}*/}
                        {/*    addAndDeleteDialogOpen={this.state.addAndDeleteDialogOpen}*/}
                        {/*    isSearchingRoom={this.props.roomStore.isSearchingRoom}*/}
                        {/*/>*/}
                        {/*)}*/}
                        {/*{tabs === 1 && <div>클래스2</div>}*/}
                    </div>
                </Box>
            </div>
        );
    }
}

export default withStyles(styles)(inject('roomStore', 'authStore', 'classStore', 'neoPenStore')(MobileClassManagementComponent));
