import React, { useEffect } from 'react';
import { Box, Button, Dialog, FormControl, IconButton, makeStyles, OutlinedInput, TextField, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TEAM_OPERATION } from '../../stores/ClassTeamStore';

const useStyles = makeStyles(theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            minWidth: 320,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 601px)': {
                width: 500,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 30,
        '@media all and (max-width: 600px)': {
            marginBottom: 20,
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    boxStyle: {
        padding: '30px 0 0',
        '@media all and (max-width: 600px)': {
            padding: '15px 0 0',
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    buttonStyle: {
        marginTop: 26,
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        color: '#fff',
        fontSize: '1.125rem',
        padding: '12px 0',
        borderRadius: 7,
        '&:hover': {
            fontWeight: 800,
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            marginTop: 0,
            fontSize: '0.75rem',
            padding: '6px 0',
            borderRadius: 4,
        },
    },
    input: {
        display: 'none',
    },
    textField: {
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #d9dbde',
            },
        '& .MuiOutlinedInput-root': {
            borderRadius: 7,
            '&:hover': {
                borderColor: '#d9dbde',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px 10px',
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#a3a8af',
                opacity: 1,
                fontWeight: 300,
            },
        },
        '@media all and (max-width: 600px)': {
            '& .MuiOutlinedInput-input': {
                padding: '10px 10px',
                fontSize: '0.813rem',
            },
        },
    },
    textFieldMargin: {
        margin: '0 10px',
    },
    textareaStyle: {
        width: '100%',
        height: 140,
        margin: '20px 0',
        border: '1px solid #d9dbde',
        borderRadius: 7,
        padding: '10px 10px',
        overflowY: 'auto',
        resize: 'none',
        boxSizing: 'border-box',
        fontSize: '1rem',
        '& li': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& svg': {
                width: 16,
                height: 16,
                '& path': {
                    stroke: '#bbb',
                },
            },
        },
        '@media all and (max-width: 600px)': {
            margin: '10px 0',
            '& li': {
                '& p': {
                    fontSize: '0.813rem',
                },
            },
        },
    },
    boxMargin: {
        marginBottom: 30,
        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            marginRight: 0,
        },
        '@media all and (max-width: 600px)': {
            marginBottom: 15,
        },
    },
    marginBottom: {
        marginBottom: 15,
    },
    textStyle: {
        fontWeight: 600,
        fontSize: '1.125rem',
        color: '#333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    textStyleRequired: {
        '&:after': {
            content: '"*"',
            color: '#ff0000',
        },
    },
    textBold: {
        fontWeight: 600,
        textDecoration: 'underline',
    },
    autoCompleteBox: {
        marginBottom: 10,
        '& .MuiInputBase-root': {
            padding: '2.5px 6px',
            backgroundColor: '#f2f3f7',
            color: '#b6b6b6',
        },
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #f2f3f7',
            },
        '& .MuiAutocomplete-input': {
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#a3a8af',
                opacity: 1,
                fontWeight: 300,
            },
            '@media all and (max-width: 600px)': {
                fontSize: '0.813rem',
            },
        },
        '& .MuiAutocomplete-popupIndicator': {
            display: 'none',
        },
        '@media all and (max-width: 600px)': {
            '& .MuiAutocomplete-input': {
                fontSize: '0.813rem',
            },
        },
    },
    option: {
        fontSize: '0.875rem',
        color: '#333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    searchBtn: {
        padding: '6px 10px',
        borderRadius: 4,
        fontSize: '0.875rem',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
            padding: '0 10px',
            height: 33,
            borderRadius: 4,
        },
    },
}));

function AddGroupDialogComponent(props) {
    const classes = useStyles();
    const isCreate = props.operation === TEAM_OPERATION.Create;
    const handleChangeAutoComplete = selectedMember => {
        props.changeTeamMembers(selectedMember);
    };

    useEffect(() => {
        if (props.addGroupDialogOpen) {
            if (props.groupMembers.length && !props.teamMembers.length) {
                const findGroupMember = props.groupMembers.find(m => m.userId === props.loginUser.id);
                if (findGroupMember) {
                    props.changeTeamMembers(findGroupMember);
                }
            }
        }
    }, [props.addGroupDialogOpen]);

    return (
        <div className={classes.root}>
            <Dialog open={props.addGroupDialogOpen} className={classes.dialogBox}>
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.titleBox}>
                    <Typography className={classes.titleText}>{isCreate ? '그룹 추가' : '그룹 수정'}</Typography>
                    <IconButton className={classes.iconButton} onClick={props.closeAddGroupDialog} disableRipple>
                        <DialogCloseIcon />
                    </IconButton>
                </Box>

                <Box display="flex" flexDirection="column" className={classes.boxMargin}>
                    <Typography className={clsx(classes.textStyle, classes.marginBottom, classes.textStyleRequired)}>그룹 명</Typography>
                    <FormControl className={classes.textField} variant="outlined">
                        <OutlinedInput
                            defaultValue={props.team.name}
                            inputProps={{ 'aria-label': 'title input box' }}
                            id="outlined-title"
                            labelWidth={0}
                            placeholder="그룹명 (최대 50자 이내)"
                            onChange={e => props.changeTeamName(e)}
                        />
                    </FormControl>
                </Box>
                <Box className={classes.boxMargin}>
                    <Typography className={clsx(classes.textStyle, classes.textStyleRequired)}>그룹 멤버</Typography>
                    <Box className={classes.textField}>
                        <ul className={classes.textareaStyle}>
                            {props.teamMembers && props.teamMembers.length
                                ? props.teamMembers.map(member => {
                                      return (
                                          <li key={`${member.userId}_${member.name}`}>
                                              <Typography>{member.name}</Typography>
                                              <IconButton
                                                  className={classes.iconButton}
                                                  disableRipple
                                                  onClick={() => props.changeTeamMembers(member)}
                                              >
                                                  <DialogCloseIcon />
                                              </IconButton>
                                          </li>
                                      );
                                  })
                                : null}
                        </ul>
                    </Box>

                    <Autocomplete
                        // freeSolo
                        id="multiple-limit-tags"
                        options={props.groupMembers}
                        classes={{
                            option: classes.option,
                        }}
                        getOptionLabel={option => {
                            return `${option.name}` || '';
                        }}
                        getOptionSelected={(option, value) => {
                            return option.groupId === value.groupId;
                        }}
                        noOptionsText={'검색결과 없음'}
                        filterSelectedOptions
                        size="small"
                        renderInput={params => (
                            <TextField
                                disabled
                                inputProps={{ 'aria-label': 'search name input box' }}
                                className={classes.textField}
                                {...params}
                                variant="outlined"
                                label=""
                                placeholder="이름을 입력 또는 선택해주세요."
                            />
                        )}
                        className={classes.autoCompleteBox}
                        onChange={(e, value, reason, details) => {
                            if (reason === 'select-option') {
                                handleChangeAutoComplete(value);
                            }
                        }}
                    />
                    <Button className={classes.searchBtn} variant="outlined" onClick={props.openMemberGroupSearchDialog} disableRipple>
                        멤버 목록에서 검색
                    </Button>
                </Box>
                <Button
                    className={classes.buttonStyle}
                    disableRipple
                    onClick={isCreate ? props.createTeam : props.modifyTeam}
                    disabled={props.team.name.trim() === '' || props.teamMembers.length === 0}
                >
                    {isCreate ? '추가' : '수정'}
                </Button>
            </Dialog>
        </div>
    );
}

export default React.memo(AddGroupDialogComponent);

// const memberList = [
//     { title: '김영희', type: 'member' },
//     { title: '김철수', type: 'member' },
//     { title: '아이유', type: 'member' },
//     { title: '홍길동', type: 'member' },
//     { title: '유관순', type: 'member' },
//     { title: '세종대왕', type: 'member' },
// ];
