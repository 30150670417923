import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, Dialog, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import clsx from 'clsx';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { checkLetterNumber } from '../../common/Validation';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            padding: 20,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 601px)': {
                padding: 24,
                maxWidth: 665,
                width: 614,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 18,
        '@media all and (max-width: 600px)': {
            marginBottom: 5,
        },
    },
    textStyle: {
        fontSize: '1rem',
        fontWeight: 'normal',
        color: '#333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    bold: {
        width: 110,
        fontWeight: 'bold',
        // marginTop: 7,
        '&::after': {
            content: "'*'",
            color: '#ff0000',
        },
        '@media all and (max-width: 600px)': {
            width: 85,
        },
    },
    smartPenAddressBold: {
        width: 110,
        fontWeight: 'bold',
        // marginTop: 7,
        '@media all and (max-width: 600px)': {
            width: 85,
        },
    },
    penBox: {
        display: 'flex',
        alignItems: 'center',
        '@media all and (max-width: 600px)': {
            flexDirection: 'column',
        },
    },
    avatar: {
        width: 120,
        height: 120,
        margin: '0 auto 21px',
        background: '#a3a8af',
        '& svg': {
            width: 72,
            height: 72,
            '& path': {
                fill: '#fff',
            },
        },
        '@media all and (max-width: 600px)': {
            width: 80,
            height: 80,
            margin: '0 auto 15px',
            '& svg': {
                width: 52,
                height: 52,
            },
        },
    },
    boxMarginBottom: {
        marginBottom: 29,
    },
    buttonStyle: {
        width: '440px',
        margin: '26px auto 0',
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        padding: '12px 0',
        borderRadius: 7,
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            width: '100%',
            marginTop: 0,
            fontSize: '0.75rem',
            padding: '6px 0',
            borderRadius: 4,
            margin: '10px auto 0',
        },
    },
    inputStyle: {
        padding: '8px 10px',
        height: 40,
        borderRadius: 4,
        border: '1px solid #c0c2c3',
        background: '#fff',
        boxSizing: 'border-box',
        '&:focus': {
            outline: 'none',
        },
        '@media all and (max-width: 600px)': {
            padding: '4px 5px',
            height: 25,
        },
    },
    emailWidth: {
        width: 130,
    },
    emailAddress: {
        fontSize: '1rem',
        color: '#333',
        margin: '0 20px 0 6px',
    },
    emailButton: {
        borderRadius: 6,
        width: 132,
        height: 40,
        background: '#fff',
        border: '1px solid #0097ff',
        color: '#0097ff',
        fontSize: '0.813rem',
        fontWeight: 600,
        '&:hover': {
            background: '#fff',
        },
        '&.Mui-disabled': {
            border: '1px solid #c2c2c2',
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    textMarginTop: {
        marginTop: 5,
    },
    passwordWidth: {
        width: 250,
    },
    numberWidth: {
        width: 32,
        '@media all and (max-width: 600px)': {
            width: 20,
        },
    },
    inputMargin: {
        margin: '0 2px',
    },
    buttonMarginLeft: {
        width: 162,
        marginLeft: 10,
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
            width: 186,
            height: 25,
            borderRadius: 4,
            marginLeft: 0,
            marginTop: 10,
        },
    },
    numberText: {
        fontWeight: 'bold',
        marginRight: 4,
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    checkText: {
        fontSize: '0.75rem',
        color: '#a3a8af',
        margin: '0 7px 0 4px',
    },
    bottomText: {
        fontSize: '0.75rem',
        color: '#707276',
        lineHeight: 1.8,
        marginTop: 5,
    },
    iconSize: {
        width: 14,
        height: 14,
    },
    iconBtn: {
        padding: 0,
        margin: '0 11px',
        '&:hover': {
            background: 'transparent',
        },
    },
    passwordBtn: {
        fontSize: '0.75rem',
        fontWeight: 'normal',
        color: '#0097ff',
        padding: 0,
        justifyContent: 'flex-start',
        textDecoration: 'underline',
        background: '#fff',
        '&:hover': {
            background: '#fff',
            textDecoration: 'underline',
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    errorText: {
        fontSize: '0.813rem',
        color: '#ff0000',
        margin: '4px 0 0',
    },
    redBox: {
        border: '1px solid #ff0000',
    },
});

class ModifyAccountDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.smartPenAddress !== this.props.smartPenAddress) {
            Array.from(this.props.smartPenAddress, (value, index) => {
                this.props.changeSmartPenAddress(value, index);
            });
        }

        if (prevProps.open !== this.props.open && !this.props.open) {
            this.props.initNewGuest();
        }
    }

    handleChangeSmartPenAddress = (e, index) => {
        checkLetterNumber(e);
        this.props.changeSmartPenAddress(e.target.value, index);
        if (e.target.value) {
            const element = document.getElementById(`number${1 + index + 1}`);
            if (element) {
                element.focus();
            }
        }
    };

    render() {
        const { classes, open, userDetail, smartPenAddress, editSmartPenAddress, selectedUser } = this.props;
        const isEditSmartPenAddressEmpty = editSmartPenAddress.join('') === '';
        const indexEmptyEditSmartPenAddress = editSmartPenAddress && editSmartPenAddress.length ? editSmartPenAddress.indexOf('') : -1;

        return (
            <Dialog open={open} className={classes.dialogBox}>
                <Box display="flex" justifyContent="flex-end" alignItems="center" className={classes.titleBox}>
                    <IconButton className={classes.iconButton} onClick={this.props.handleClickModifyAccountClose} disableRipple>
                        <DialogCloseIcon />
                    </IconButton>
                </Box>
                <Box>
                    <Avatar className={classes.avatar} src={selectedUser && selectedUser.image ? selectedUser.image : ''}>
                        <AsideUserIcon />
                    </Avatar>
                    <Box display="flex" justifyItems={'center'} alignItems="flex-start" className={classes.boxMarginBottom}>
                        <Typography className={clsx(classes.textStyle, classes.bold)}>이메일</Typography>
                        <Typography className={classes.textStyle}>{userDetail.email}</Typography>
                    </Box>

                    {/*<Box display="flex" alignItems="flex-start" className={classes.boxMarginBottom}>*/}
                    {/*    <Typography className={clsx(classes.textStyle, classes.bold)}>비밀번호</Typography>*/}
                    {/*    <Typography className={classes.textStyle}>****************</Typography>*/}
                    {/*</Box>*/}

                    <Box display="flex" alignItems="flex-start" className={classes.boxMarginBottom}>
                        <Typography className={clsx(classes.textStyle, classes.bold)}>이름</Typography>
                        <Typography className={classes.textStyle}>{userDetail.name}</Typography>
                    </Box>

                    <Box display="flex" alignItems="flex-start" className={classes.boxMarginBottom}>
                        <Typography className={clsx(classes.textStyle, classes.smartPenAddressBold)}>스마트펜 정보</Typography>
                        <Box className={classes.penBox}>
                            <Box display="flex" alignItems="center">
                                <Typography className={classes.numberText}>******</Typography>
                                {editSmartPenAddress.map((value, index) => {
                                    return (
                                        <input
                                            key={`${index}_${value}`}
                                            type="text"
                                            tabIndex={4 + index}
                                            id={`number${1 + index}`}
                                            maxLength={1}
                                            value={value}
                                            autoFocus={isEditSmartPenAddressEmpty ? index === 0 : value === ''}
                                            className={
                                                smartPenAddress !== editSmartPenAddress.join('') && value === ''
                                                    ? clsx(classes.inputStyle, classes.numberWidth, classes.inputMargin, classes.redBox)
                                                    : clsx(classes.inputStyle, classes.numberWidth, classes.inputMargin)
                                            }
                                            onChange={e => this.handleChangeSmartPenAddress(e, index)}
                                        />
                                    );
                                })}
                            </Box>
                            <Button
                                className={clsx(classes.emailButton, classes.buttonMarginLeft)}
                                disableRipple
                                onClick={this.props.checkExistSmartPenAddress}
                                disabled={smartPenAddress === editSmartPenAddress.join('') || indexEmptyEditSmartPenAddress > -1}
                            >
                                등록 정보 및 중복 체크
                            </Button>
                        </Box>
                    </Box>
                </Box>

                <Button
                    className={classes.buttonStyle}
                    disableRipple
                    disabled={!this.props.isAvailableSmartPen}
                    onClick={this.props.modifyGuestSmartPenAddress}
                >
                    스마트펜 정보 수정
                </Button>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ModifyAccountDialogComponent);
