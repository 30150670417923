import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ClassNoticeComponent from './ClassNoticeComponent';
import ScheduleCardListItemComponent from './ScheduleCardListItemComponent';
import { Box, CircularProgress, FormControl, MenuItem, Popover, Select, TextField, Typography } from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { inject, observer } from 'mobx-react';
import dayjs from 'dayjs';
import { DATE_UTIL } from '../../common/util/date.util';
import { PATH_UTIL } from '../../common/util/path.util';
import { loadingSate, MyClassRoomSortType, RoomSortType } from '../../stores/RoomStore';
import { UTIL } from '../../common/util/common.util';
import Button from '@material-ui/core/Button';
import DateFilterComponent from './DateFilterComponent';

const styles = _theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
    },
    controlBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 7,
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    datePickers: {
        fontFamily: 'NanumSquareRoundOTF',
        display: 'flex',
        alignItems: 'center',
        '& input': {
            padding: '7px 10px',
        },
        '& .Mui-focused': {
            borderColor: '#0097FF',
        },
    },
    buttonStyle: {
        padding: 0,
        marginBottom: 10,
        '&:hover': {
            background: 'transparent',
        },
    },
    textStyle: {
        fontSize: '0.75rem',
        fontWeight: 600,
        marginRight: 5,
    },
});

class FullScheduleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            situation: RoomSortType.All,
            scheduleDetail: false,
            startDatetime: DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT),
            endDatetime: DATE_UTIL.getTimeZoneDate(dayjs().add(1, 'months'), DATE_UTIL.FORMAT.DATE_FORMAT),
            page: 1,
            sort: MyClassRoomSortType.Recent,
            anchorEl: null,
            order: MyClassRoomSortType.Recent,
        };
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        const { roomStore, authStore } = this.props;
        const isCreateNewRoom = prevProps.createRoomLoading !== this.props.createRoomLoading && prevProps.createRoomLoading === loadingSate.PENDING;

        if (roomStore.roomListGroupByStartTime.length === 0 && this.state.page > 1) {
            this.setState({ page: 1 });
        }

        if (isCreateNewRoom) {
            this.handleInitRoomsPage();
        }

        if (
            prevProps.isScrollEnd !== this.props.isScrollEnd &&
            this.props.isScrollEnd &&
            roomStore.roomTotalCount > roomStore.roomListGroupByStartTime.length
        ) {
            this.setState({ page: this.state.page + 1 }, () => {
                this.fetchRoomListGroupByDateTime();
            });
        }
    }

    componentWillUnmount() {
        this.props.roomStore.initRoom();
    }

    handleInitRoomsPage = () => {
        this.setState({ page: 1 }, () => {
            this.fetchRoomListGroupByDateTime();
        });
    };

    handleChange = event => {
        if (this.state.situation !== event.target.value) {
            this.props.roomStore.initRoom();
            this.setState({ page: 1 });
            const pathName = window.location.pathname;
            const groupId = PATH_UTIL.getClassId(pathName);
            const convertingStartDatetime = new Date(this.state.startDatetime).setHours(0, 0, 0);
            this.setState({ situation: event.target.value }, () =>
                this.props.roomStore.getRoomListGroupByStartTime(
                    groupId,
                    this.props.authStore.loginUser.id,
                    'today',
                    1,
                    convertingStartDatetime,
                    this.state.endDatetime,
                    this.state.situation,
                ),
            );
        }
    };

    handleChangeSituation = situation => {
        if (this.state.situation !== situation) {
            this.setState({ situation: situation, page: 1 });
        }
    };

    handleChangeOrder = order => {
        if (this.state.order !== order) {
            this.setState({ order: order, page: 1 });
        }
    };

    handleClickDetail = roomId => {
        this.props.handleClickDetail(roomId);
    };

    fetchRoomListGroupByDateTime = (start = this.state.startDatetime, end = this.state.endDatetime) => {
        const { selectedGroupId } = this.props.classStore;
        const { loginUser } = this.props.authStore;
        const convertingStartDatetime = new Date(start).setHours(0, 0, 0);

        if (this.state.page === 1) {
            this.props.roomStore.initRoom();
        }

        this.props.roomStore.getRoomListGroupByStartTime(
            selectedGroupId,
            loginUser.id,
            'today',
            this.state.page,
            convertingStartDatetime,
            end,
            this.state.situation,
            undefined,
            this.state.order,
        );
    };

    handleChangeStartTime = datetime => {
        this.setState({ startDatetime: datetime, page: 1 });
        if (dayjs(this.state.endDatetime).isBefore(dayjs(datetime))) {
            this.setState({ endDatetime: datetime });
        }
    };

    handleChangeEndTime = datetime => {
        this.setState({ endDatetime: datetime, page: 1 });
        if (dayjs(datetime).isBefore(dayjs(this.state.startDatetime))) {
            this.setState({ startDatetime: datetime });
        }
    };

    handleResetStartAndEndTime = (start, end) => {
        if (dayjs(end).isBefore(dayjs(start))) {
            return false;
        }
        this.setState({ startDatetime: start, endDatetime: end, page: 1 });
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    render() {
        const { classes, isMember, isLeader, classPath } = this.props;
        const { anchorEl } = this.state;
        const { roomListGroupByStartTime, likeLoading, isLoadRoomLoading } = this.props.roomStore;
        const open = Boolean(anchorEl);

        return (
            <div className={classes.root}>
                <ClassNoticeComponent isMember={isMember} classPath={classPath} />
                <DateFilterComponent
                    open={open}
                    closeDateFilter={this.handleClose}
                    startDatetime={this.state.startDatetime}
                    endDatetime={this.state.endDatetime}
                    changeStartDatetime={this.handleChangeStartTime}
                    changeEndDatetime={this.handleChangeEndTime}
                    situation={this.state.situation}
                    changeSituation={this.handleChangeSituation}
                    order={this.state.order}
                    changeOrder={this.handleChangeOrder}
                    fetchData={this.fetchRoomListGroupByDateTime}
                    resetDatetime={this.handleResetStartAndEndTime}
                />
                {!isLoadRoomLoading ? (
                    <ScheduleCardListItemComponent
                        loginUser={this.props.authStore.loginUser.id}
                        handleClickDetail={this.handleClickDetail}
                        roomListGroupByStartTime={roomListGroupByStartTime}
                        likeLoading={likeLoading}
                        changeLikeState={(groupId, roomId, likeId) =>
                            this.props.roomStore.changeLikeState(this.props.authStore.loginUser, groupId, roomId, likeId)
                        }
                        isMember={isMember}
                        isLeader={isLeader}
                        situation={this.state.situation}
                    />
                ) : (
                    <Box style={{ textAlign: 'center', marginTop: 100, marginBottom: 1000 }}>
                        <CircularProgress />
                    </Box>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(inject('roomStore', 'authStore', 'classStore')(observer(FullScheduleComponent)));
