import React from 'react';
import {CircularProgress, Box, Typography} from '@material-ui/core';

export default function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', marginLeft: 10 }}>
            <CircularProgress style={{ color: 'white' }} size={45} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    style={{color: "white", fontWeight: "bold"}}

                >{`${props.value}`}</Typography>
            </Box>
        </Box>
    );
}