import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SearchScheduleComponent from './SearchScheduleComponent';
import SearchBoardComponent from './SearchBoardComponent';
import SearchMyGroupTabComponent from './SearchMyGroupTabComponent';
import { inject, observer } from 'mobx-react';

const styles = theme => ({
    root: {
        background: '#f3f3f3',
    },
});

class SearchMyGroupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTab: 0,
        };
    }

    handleChange = (event, searchTab) => {
        this.setState({ searchTab });
    };

    render() {
        const { classes, isScrollEnd, keyword, classStore } = this.props;
        const { isSearchGroupListLoading } = classStore;

        return (
            <div className={classes.root}>
                <SearchMyGroupTabComponent searchTab={this.state.searchTab} handleChange={this.handleChange} />
                {this.state.searchTab === 0 && (
                    <SearchScheduleComponent isScrollEnd={isScrollEnd} isSearchGroupListLoading={isSearchGroupListLoading} />
                )}
                {this.state.searchTab === 1 && (
                    <SearchBoardComponent isScrollEnd={isScrollEnd} keyword={keyword} isSearchGroupListLoading={isSearchGroupListLoading} />
                )}
            </div>
        );
    }
}

export default withStyles(styles)(inject('classStore')(observer(SearchMyGroupComponent)));
