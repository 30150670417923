import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import { withRouter } from '../../../components/WithRouter';
import { getURLParams } from '../../../common/util/Params';
import { inject, observer } from 'mobx-react';
import { EMAIL_AUTHENTICATION_RESULT_STATE } from '../../../stores/AuthStore';

const styles = theme => ({
    root: {
        width: '100%',
        // height: 'calc(100vh - 130px)',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boxLine: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1px solid #e5e6e8',
        padding: '50px 90px 30px',
        boxSizing: 'border-box',
        borderRadius: 12,
        marginTop: 45,
    },
    titleText: {
        fontSize: '1.75rem',
        color: '#333',
        textAlign: 'center',
        marginBottom: 40,
    },
    textStyle: {
        fontSize: '1.125rem',
        color: '#333',
        display: 'flex',
        alignItems: 'center',
    },
    emailText: {
        fontWeight: 600,
        textDecoration: 'underline',
    },
    buttonStyle: {
        width: 340,
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        padding: '15px 0',
        borderRadius: 7,
        marginTop: 40,
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
});

class SignUpDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        console.log('[SignUpDialogComponent] did mount');
        if (this.props.authStore.emailAuthenticationResult === EMAIL_AUTHENTICATION_RESULT_STATE.None) {
            const { ...params } = getURLParams(window.location.search);
            this.props.authStore.requestConfirmEmailAuthentication(params.email, params.token);
        }
    }

    componentWillUnmount() {
        const { authStore } = this.props;
        if (authStore.emailAuthenticationResult !== EMAIL_AUTHENTICATION_RESULT_STATE.None) {
            authStore.initEmailAuthenticationResult();
        }
    }

    moveTo = url => {
        this.props.navigate(url);
    };

    handleClickLogin = e => {
        this.moveTo('/signin');
    };

    handleClickTabClose = e => {
        window.close();
    };

    render() {
        const { classes } = this.props;
        const { isEmailAuthenticationConfirmed, isEmailAuthenticationFailed, isEmailNotAllowed } = this.props.authStore;
        const { ...params } = getURLParams(window.location.search);

        return (
            <div className={classes.root}>
                <Box className={classes.boxLine}>
                    <Typography className={classes.titleText}>{isEmailAuthenticationConfirmed ? '회원가입 완료' : '회원가입 실패'}</Typography>
                    <Typography className={clsx(classes.textStyle, classes.emailText)}>{params.email}</Typography>
                    <Typography className={classes.textStyle}>
                        {isEmailAuthenticationConfirmed
                            ? '이메일 주소가 인증되었습니다.'
                            : isEmailNotAllowed
                            ? '유효하지 않은 접근입니다.'
                            : '이메일 주소가 인증되지 않았습니다.'}
                    </Typography>
                    {isEmailAuthenticationFailed && <Typography className={classes.textStyle}>다시 시도해주세요.</Typography>}
                    <Button
                        className={classes.buttonStyle}
                        disableRipple
                        onClick={isEmailAuthenticationConfirmed ? this.handleClickLogin : this.handleClickTabClose}
                    >
                        {isEmailAuthenticationConfirmed ? '로그인' : '창닫기'}
                    </Button>
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('authStore')(observer(SignUpDialogComponent))));
