import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ScheduleCardListItemComponent from './MobileScheduleCardListItemComponent';
import { Box, Button, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import dayjs from 'dayjs';
import { roomState } from '../../../stores/RoomStore';
import clsx from 'clsx';
import { ReactComponent as DotIcon } from '../../../common/images/DotIcon.svg';
import { DATE_UTIL } from '../../../common/util/date.util';
import { withRouter } from '../../../components/WithRouter';
import { injectIntl } from 'react-intl';
import { ClassMainPath } from '../../../common/ClassMainPath';
import FreeBoardComponent from './MobileFreeBoardComponent';
import { ReactComponent as ChartBarFillIcon } from '../../../common/images/ChartBarFillIcon.svg';

const styles = theme => ({
    root: {
        width: 'calc(100vw - 32px)',
        paddingBottom: 70,
        '& h4': {
            fontSize: '1.5rem',
            marginTop: 20,
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                width: 24,
                height: 24,
                marginRight: 4,
            },
        },
        '& h6': {
            fontSize: '1.2rem',
            marginTop: 25,
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                width: 24,
                height: 24,
                marginRight: 4,
            },
        },
    },
    titleStyle: {
        fontSize: '1.375rem',
        fontWeight: 600,
    },
    textStyle: {
        fontSize: '0.875rem',
        color: '#7d7d7d',
        margin: '14px 0 17px',
    },
    dailyBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
        border: '1px solid #e1e4e7',
        borderRadius: 8,
        padding: '10px 0',
        marginTop: 20,
        cursor: 'pointer',
    },
    dailyBox1: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
        border: '1px solid #e1e4e7',
        borderRadius: 8,
        padding: '10px 0',
        marginTop: 20,
        cursor: 'pointer',
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        fontSize: '0.875rem',
        width: 80,
        '& b': {
            fontSize: '1.5rem',
            fontWeight: 700,
        },
    },
    content: {
        width: '65%',
        '& h5': {
            fontSize: '0.938rem',
            fontWeight: 600,
            margin: '3px 0',
        },
        '& p': {
            fontSize: '0.813rem',
        },
    },
    caption: {
        backgroundColor: 'transparent',
        color: '#fff',
        padding: '2px 6px',
        fontSize: '0.625rem',
        fontFamily: 'Montserrat!important',
        textTransform: 'uppercase',
        fontWeight: 800,
        display: 'inline-flex',
        alignItems: 'center',
        '& svg': {
            marginRight: 3,
        },
    },
    captionEnd: {
        backgroundColor: '#505050',
    },
    captionFuture: {
        backgroundColor: '#a3dfff',
    },
    captionLive: {
        backgroundColor: '#FB4A59',
    },
    btnOutlineStyle: {
        border: () => (theme.configs.MainBtnColor ? `1px solid ${theme.configs.MainBtnColor}` : '1px solid #0097FF'),
        color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        fontWeight: 600,
        padding: '6px 27px',
        borderRadius: 7,
        '&:hover': {
            fontWeight: 800,
            background: 'transparent',
        },
    },
    btnOutlineStyleColor: {
        color: '#4f5660',
        border: '1px solid #4f5660',
    },
    btnChartStyle: {
        marginLeft: 'auto',
    },
});

class MobileClassSelectTagComponent extends Component {
    checkRoomState = (startDatetime, endDatetime) => {
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedStartDatetime = DATE_UTIL.convertUTCToTimeZone(startDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedEndDatetime = DATE_UTIL.convertUTCToTimeZone(endDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        if (dayjs(convertedEndDatetime).isBefore(currentDatetime)) {
            return roomState.Ended;
        } else if (
            dayjs(currentDatetime).isBetween(convertedStartDatetime, convertedEndDatetime) ||
            dayjs(currentDatetime).isSame(convertedStartDatetime) ||
            dayjs(currentDatetime).isSame(convertedEndDatetime)
        ) {
            return roomState.Live;
        } else {
            return roomState.Future;
        }
    };

    handleClickDetail = roomId => {
        this.props.handleClickDetail(roomId);
    };

    handleClickEnter = (event, url) => {
        event.stopPropagation();
        window.open(url);
    };

    handleClickStatistics = (e, room) => {
        e.preventDefault();
        e.stopPropagation();
        // window.open(`/class/${room.groupId}/rooms/${room.id}/statistics`);
        window.open(`/statistics/${room.id}`);
    };

    render() {
        const { classes, classSelectTag, classTab, handleClickPostDetail, isLeader } = this.props;
        const { roomListByTagName, isLoadRoomLoading, roomListByTagNameCount } = this.props.roomStore;
        const { postList, isPostListLoading } = this.props.postStore;

        return (
            <div className={classes.root}>
                <Typography variant="h4">
                    {/*<AsideUserIcon /> */}
                    <b>#{classSelectTag.name}</b>
                </Typography>
                {!isLoadRoomLoading && !isPostListLoading ? (
                    classTab !== ClassMainPath.calendarSchedule ? (
                        classTab === ClassMainPath.community || classTab === ClassMainPath.postDetail ? (
                            <React.Fragment>
                                <Typography variant="body2" className={classes.textStyle}>
                                    {postList.length}개의 게시물이 등록되어 있습니다.
                                </Typography>
                                <FreeBoardComponent posts={postList} handleClickPostDetail={handleClickPostDetail} />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Typography variant="body2" className={classes.textStyle}>
                                    {roomListByTagName.length}개의 일정이 등록되어 있습니다.
                                </Typography>
                                <ScheduleCardListItemComponent
                                    roomListGroupByStartTime={roomListByTagName}
                                    loginUser={this.props.authStore.loginUser.id}
                                    likeLoading={this.props.roomStore.likeLoading}
                                    changeLikeState={(groupId, roomId, likeId) =>
                                        this.props.roomStore.changeLikeState(this.props.authStore.loginUser, groupId, roomId, likeId)
                                    }
                                    handleClickDetail={this.handleClickDetail}
                                    isMember={this.props.isMember}
                                    isLeader={isLeader}
                                />
                            </React.Fragment>
                        )
                    ) : (
                        <React.Fragment>
                            <Typography variant="body2" className={classes.textStyle}>
                                {roomListByTagNameCount}개의 일정이 등록되어 있습니다.
                            </Typography>
                            {roomListByTagName.map((d, index) => (
                                <React.Fragment key={index}>
                                    <Typography className={classes.titleStyle} style={{ marginTop: 15 }}>
                                        {d.date.split('-')[0]}년 {d.date.split('-')[1]}월
                                    </Typography>
                                    {d.rooms.map(r => {
                                        const state = this.checkRoomState(r.startDatetime, r.endDatetime);
                                        return (
                                            <Box key={r.id} className={clsx(state === roomState.Live ? classes.dailyBox : classes.dailyBox1)}>
                                                <Box className={classes.date}>
                                                    {' '}
                                                    <b>{DATE_UTIL.convertUTCToTimeZone(r.startDatetime, DATE_UTIL.FORMAT.DAY)}</b>
                                                    {DATE_UTIL.convertUTCToTimeZone(r.startDatetime, DATE_UTIL.FORMAT.SIMPLE_DAY)}
                                                </Box>
                                                <Box className={classes.content} onClick={() => this.handleClickDetail(r.id)}>
                                                    {state === roomState.Ended && (
                                                        <Box className={clsx(classes.caption, classes.captionEnd)}>종료</Box>
                                                    )}
                                                    {state === roomState.Future && (
                                                        <Box className={clsx(classes.caption, classes.captionFuture)}>예정</Box>
                                                    )}
                                                    {state === roomState.Live && (
                                                        <Box>
                                                            <span className={classes.circle} />{' '}
                                                            <Typography className={clsx(classes.caption, classes.captionLive)}>
                                                                <DotIcon />
                                                                LIVE
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    <Typography variant="h5">{r.name}</Typography>
                                                    <Typography variant="body2">
                                                        {DATE_UTIL.convertUTCToTimeZone(r.startDatetime, DATE_UTIL.FORMAT.SIMPLE_TIME_AMPM_FORMAT)} ~{' '}
                                                        {DATE_UTIL.convertUTCToTimeZone(r.endDatetime, DATE_UTIL.FORMAT.SIMPLE_TIME_AMPM_FORMAT)}
                                                    </Typography>
                                                </Box>
                                                {state === roomState.Live && (
                                                    <Button
                                                        className={classes.btnOutlineStyle}
                                                        disableRipple
                                                        onClick={e =>
                                                            this.handleClickEnter(
                                                                e,
                                                                r.members.find(m => m.userId === this.props.authStore.loginUser.id).entranceUrl,
                                                            )
                                                        }
                                                    >
                                                        참석하기
                                                    </Button>
                                                )}
                                                {state === roomState.Ended && isLeader && r.statistics && (
                                                    <IconButton
                                                        disableRipple
                                                        className={classes.btnChartStyle}
                                                        onClick={e => this.handleClickStatistics(e, r)}
                                                    >
                                                        <ChartBarFillIcon />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        );
                                    })}
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    )
                ) : (
                    <Box style={{ textAlign: 'center', marginTop: 100, height: 400 }}>
                        <CircularProgress />
                    </Box>
                )}
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(injectIntl(inject('roomStore', 'authStore', 'commentStore', 'postStore')(observer(MobileClassSelectTagComponent)))),
);
