import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, IconButton, Slider } from '@material-ui/core';
import { ReactComponent as NeoPenIconColor } from '../../../common/images/NeoPenIconColor.svg';
import { ReactComponent as NeoPenMarker } from '../../../common/images/NeoPenMarker.svg';
import { ReactComponent as EraserIcon } from '../../../common/images/EraserIcon.svg';
import { ReactComponent as PaletteICon } from '../../../common/images/PaletteICon.svg';
import { ReactComponent as LineIcon } from '../../../common/images/LineIcon.svg';
import { ReactComponent as PointIcon } from '../../../common/images/PointIcon.svg';
import { ReactComponent as Close } from '../../../common/images/Close.svg';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { inject, observer } from 'mobx-react';
import { IBrushType } from 'nl-lib';
// import { IBrushType } from '../../../nl-lib';

const styles = _theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    iconButton: {
        padding: 0,
        margin: '0 10px',
        '&:hover': {
            background: 'transparent',
        },
    },
    iconButtonColor: {
        '& .neo-pen, .neon-pen, .eraser, .palette, .line, .point': {
            fill: '#6d6d70',
        },
    },
    lineStyle: {
        width: 1,
        height: 22,
        background: '#d9d9d9',
        margin: '0 5px',
    },
    //
    closeBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '1px',
        '& button': {
            padding: 0,
            marginBottom: 10,
            '&:hover': {
                background: 'transparent',
            },
        },
        '& svg': {
            width: 20,
            height: 20,
        },
    },
    closeIcon: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    closeBox2: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        '& button': {
            marginBottom: 0,
        },
    },
    eraserWritingOption: {
        position: 'absolute',
        top: 30,
        right: 20,
        zIndex: 1000,
    },
    whiteBoardControlBox: {
        padding: '10px 14px 10px',
        borderRadius: 6,
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.16)',
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        boxSizing: 'border-box',
        marginTop: 5,
        '& .MuiSlider-root': {
            color: '#0ed145',
        },
        '& .MuiSlider-track': {},
        '& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover, .MuiSlider-thumb.MuiSlider-active': {
            boxShadow: 'none',
        },
        '& .MuiSlider-thumb': {
            width: 10,
            height: 10,
            marginTop: -4,
        },
    },
    eraserBtn: {
        width: 88,
        padding: '6px 8px',
        borderRadius: 6,
        fontSize: '0.875rem',
        fontWeight: 400,
    },
    pixelBtn: {
        background: '#6d6d70',
        color: '#fff',
        border: '1px solid #6d6d70',
        marginRight: 7,
        '&:hover': {
            background: '#6d6d70',
            border: '1px solid #6d6d70',
        },
    },
    allBtn: {
        background: '#fff',
        border: '1px solid #6d6d70',
        color: '#6d6d70',
        '&:hover': {
            background: '#fff',
            border: '1px solid #6d6d70',
        },
    },
    //
    PaletteWritingOption: {
        position: 'absolute',
        top: 30,
        right: 20,
        zIndex: 1000,
    },
    paletteBox: {
        width: 25,
        height: 25,
        cursor: 'pointer',
    },
    //
    thicknessSetterWritingOption: {
        position: 'absolute',
        top: 30,
        right: 20,
        zIndex: 1000,
    },
    textStyle: {
        fontSize: '0.875rem',
        color: '#1e2121',
        fontWeight: 400,
    },
});
class NeoPenScreenWriteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            neoPen: true,
            penMarker: false,
            eraser: false,
            palette: false,
            line: false,
            point: false,

            eraserOpen: false,
            paletteOpen: false,
            lineOpen: false,
            lineValue: 5,
        };
    }

    handleClickNeoPen = () => {
        this.setState({
            neoPen: true,
            penMarker: false,
            eraser: false,
            palette: false,
            line: false,
            point: false,
            //
            eraserOpen: false,
            paletteOpen: false,
            lineOpen: false,
        });
    };

    handleClickPenMarker = () => {
        this.setState({
            penMarker: true,
            neoPen: false,
            eraser: false,
            palette: false,
            line: false,
            point: false,
            //
            eraserOpen: false,
            paletteOpen: false,
            lineOpen: false,
        });
    };

    handleClickEraser = () => {
        this.setState({
            eraser: true,
            neoPen: false,
            penMarker: false,
            palette: false,
            line: false,
            point: false,
            //
            eraserOpen: true,
            paletteOpen: false,
            lineOpen: false,
        });
    };

    handleClickPalette = () => {
        this.setState({
            palette: true,
            neoPen: false,
            penMarker: false,
            eraser: false,
            line: false,
            point: false,
            //
            eraserOpen: false,
            paletteOpen: true,
            lineOpen: false,
        });
    };

    handleClickLine = () => {
        this.setState({
            line: true,
            neoPen: false,
            penMarker: false,
            eraser: false,
            palette: false,
            //
            eraserOpen: false,
            paletteOpen: false,
            lineOpen: true,
        });
    };

    handleClickPoint = () => {
        this.setState({
            point: true,
            neoPen: false,
            penMarker: false,
            eraser: false,
            palette: false,
            line: false,
            //
            eraserOpen: false,
            paletteOpen: false,
            lineOpen: false,
        });
    };

    handleClickClose = () => {
        this.setState({
            eraserOpen: false,
            paletteOpen: false,
            lineOpen: false,
        });
    };

    handleChangeLineSlider = (_event, lineValue) => {
        this.props.neoPenStore.setThickness(lineValue);

        this.setState({ lineValue });
    };

    render() {
        const { classes } = this.props;
        const { palette, line, paletteOpen, lineOpen } = this.state;

        const nPS = this.props.neoPenStore;

        const eraserComponent = () => {
            return (
                <Box display="flex" flexDirection="row" justifyContent={'flex-end'} alignItems={'flex-end'} className={classes.eraserWritingOption}>
                    <Box className={classes.whiteBoardControlBox} style={{ width: 210 }}>
                        <Box className={classes.closeBox}>
                            <IconButton className={classes.closeIcon} onClick={this.handleClickClose} disableRipple>
                                <Close />
                            </IconButton>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Button className={clsx(classes.eraserBtn, classes.pixelBtn)} disableRipple>
                                선 지우기
                            </Button>
                            <Button className={clsx(classes.eraserBtn, classes.allBtn)} disableRipple>
                                모두 지우기
                            </Button>
                        </Box>
                    </Box>
                </Box>
            );
        };

        const paletteComponent = () => {
            return (
                <Box display="flex" flexDirection="row" justifyContent={'flex-end'} alignItems={'flex-end'} className={classes.PaletteWritingOption}>
                    <Box className={classes.whiteBoardControlBox}>
                        <Box className={classes.closeBox}>
                            <IconButton className={classes.closeIcon} onClick={this.handleClickClose} disableRipple>
                                <Close />
                            </IconButton>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box className={classes.paletteBox} style={{ background: '#ffffff' }} onClick={() => nPS.setColor('#ffffff')} />
                            <Box className={classes.paletteBox} style={{ background: '#c3c3c3' }} onClick={() => nPS.setColor('#c3c3c3')} />
                            <Box className={classes.paletteBox} style={{ background: '#585858' }} onClick={() => nPS.setColor('#585858')} />
                            <Box className={classes.paletteBox} style={{ background: '#000000' }} onClick={() => nPS.setColor('#000000')} />
                            <Box className={classes.paletteBox} style={{ background: '#be0027' }} onClick={() => nPS.setColor('#be0027')} />
                            <Box className={classes.paletteBox} style={{ background: '#fe0000' }} onClick={() => nPS.setColor('#fe0000')} />
                            <Box className={classes.paletteBox} style={{ background: '#f48825' }} onClick={() => nPS.setColor('#f48825')} />
                            <Box className={classes.paletteBox} style={{ background: '#fab035' }} onClick={() => nPS.setColor('#fab035')} />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box className={classes.paletteBox} style={{ background: '#ffc847' }} onClick={() => nPS.setColor('#ffc847')} />
                            <Box className={classes.paletteBox} style={{ background: '#ffdd00' }} onClick={() => nPS.setColor('#ffdd00')} />
                            <Box className={classes.paletteBox} style={{ background: '#e4ea32' }} onClick={() => nPS.setColor('#e4ea32')} />
                            <Box className={classes.paletteBox} style={{ background: '#2d9f47' }} onClick={() => nPS.setColor('#2d9f47')} />
                            <Box className={classes.paletteBox} style={{ background: '#00bbe4' }} onClick={() => nPS.setColor('#00bbe4')} />
                            <Box className={classes.paletteBox} style={{ background: '#037ef5' }} onClick={() => nPS.setColor('#037ef5')} />
                            <Box className={classes.paletteBox} style={{ background: '#361677' }} onClick={() => nPS.setColor('#361677')} />
                            <Box className={classes.paletteBox} style={{ background: '#7e3f98' }} onClick={() => nPS.setColor('#7e3f98')} />
                        </Box>
                    </Box>
                </Box>
            );
        };
        const thicknessSetterComponent = () => {
            return (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent={'flex-end'}
                    alignItems={'flex-end'}
                    className={classes.thicknessSetterWritingOption}
                >
                    <Box className={classes.whiteBoardControlBox} style={{ width: 210 }}>
                        <Box className={clsx(classes.closeBox, classes.closeBox2)}>
                            <Typography className={classes.textStyle}>브러쉬 굵기</Typography>
                            <IconButton className={classes.closeIcon} onClick={this.handleClickClose} disableRipple>
                                <Close />
                            </IconButton>
                        </Box>
                        <Box display="flex" alignItems="center" style={{ width: '100%', marginTop: 5 }}>
                            <Slider min={0.1} max={0.8} step={0.01} value={nPS.thickness} onChange={this.handleChangeLineSlider} />
                            {/*<Box className={classes.numberBox}><Typography className={clsx(classes.textStyle, classes.textStyle1)}>{nPS.thickness}</Typography></Box>*/}
                        </Box>
                    </Box>
                </Box>
            );
        };

        return (
            <div className={classes.root}>
                <Box>
                    <IconButton
                        className={nPS.brushType === IBrushType.PEN ? clsx(classes.iconButton, classes.iconButtonColor) : classes.iconButton}
                        onClick={() => nPS.setBrushType(IBrushType.PEN)}
                        disableRipple
                    >
                        <NeoPenIconColor />
                    </IconButton>
                    <IconButton
                        className={nPS.brushType === IBrushType.MARKER ? clsx(classes.iconButton, classes.iconButtonColor) : classes.iconButton}
                        onClick={() => nPS.setBrushType(IBrushType.MARKER)}
                        disableRipple
                    >
                        <NeoPenMarker />
                    </IconButton>
                    {/*<IconButton*/}
                    {/*    className={nPS.brushType === IBrushType.ERASER ? clsx(classes.iconButton, classes.iconButtonColor) : classes.iconButton}*/}
                    {/*    onClick={() => nPS.setBrushType(IBrushType.ERASER)}*/}
                    {/*    disableRipple*/}
                    {/*>*/}
                    {/*    <EraserIcon />*/}
                    {/*</IconButton>*/}
                    <IconButton
                        className={palette ? clsx(classes.iconButton, classes.iconButtonColor) : classes.iconButton}
                        onClick={this.handleClickPalette}
                        disableRipple
                    >
                        <PaletteICon />
                    </IconButton>
                    <IconButton
                        className={line ? clsx(classes.iconButton, classes.iconButtonColor) : classes.iconButton}
                        onClick={this.handleClickLine}
                        disableRipple
                    >
                        <LineIcon />
                    </IconButton>
                    <IconButton
                        className={nPS.isLaserEnabled ? clsx(classes.iconButton, classes.iconButtonColor) : classes.iconButton}
                        onClick={nPS.onLaserChanged}
                        disableRipple
                    >
                        <PointIcon />
                    </IconButton>
                </Box>

                <Box className={classes.lineStyle} />

                {/*{eraserOpen && eraserComponent()}*/}
                {paletteOpen && paletteComponent()}
                {lineOpen && thicknessSetterComponent()}
            </div>
        );
    }
}

export default withStyles(styles)(inject('neoPenStore')(observer(NeoPenScreenWriteComponent)));
