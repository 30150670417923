import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography, IconButton, Avatar, Tooltip } from '@material-ui/core';
import { ReactComponent as PencilSimpleLine } from '../../common/images/PencilSimpleLine.svg';
import clsx from 'clsx';
import { ReactComponent as NotePencilIcon } from '../../common/images/NotePencilIcon.svg';
import { ReactComponent as BackArrowIcon } from '../../common/images/BackArrowIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../common/images/ArrowRightIcon.svg';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { withRouter } from '../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { Bar } from '@nivo/bar';
import { AutoSizer } from 'react-virtualized';

const styles = theme => ({
    root: {
        paddingTop: 40,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    noContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '50vh',
        '& .MuiTypography-root': {
            color: '#a9adb4',
            fontSize: '1.063rem',
            marginTop: 14,
        },
    },
    avatar: {
        width: 45,
        height: 45,
        backgroundColor: '#e9e9e9',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatar2: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        '& .aside-lmsUser': {
            fill: '#fff',
        },
    },
    name: {
        fontSize: '0.875rem',
        display: 'flex',
        color: '#000',
        fontWeight: 600,
    },
    date: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    titleStyle: {
        fontSize: '1.25rem',
        fontWeight: 600,
        marginBottom: 76,
    },
    boxStyle: {
        padding: 40,
        marginBottom: 20,
        borderRadius: 10,
        boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.25)',
    },
    boxStyleHalf: {
        width: 'calc(50% - 8px)',
    },
    subTitleStyle: {
        fontSize: '1.063rem',
        fontWeight: 600,
        marginBottom: 10,
        '& span': {
            fontSize: '0.938rem',
            fontWeight: 400,
        },
    },
    ulStyle: {
        paddingLeft: 20,
        marginBottom: 20,
        lineHeight: '1.5rem',
        fontSize: '0.875rem',
        listStyle: 'none',
        '& li': {
            position: 'relative',
            '&:before': {
                content: '""',
                width: 6,
                height: 6,
                backgroundColor: '#000',
                borderRadius: 50,
                display: 'flex',
                position: 'absolute',
                left: -14,
                top: 8,
            },
        },
    },
    graphBox: {
        backgroundColor: '#f7f7f7',
        height: 260,
        textAlign: 'center',
        fontSize: '1.25rem',
        fontWeight: 600,
        borderRadius: 10,
    },
    iconBtn: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '&.MuiIconButton-root.Mui-disabled': {
            opacity: 0.2,
        },
    },
    pageNumber: {
        fontSize: '0.75rem',
        color: '#757575',
        margin: '0 12px',
        lineHeight: 1.2,
        '& b': {
            color: '#000',
        },
    },
});
class ByStudentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noContent: false,

            currentUserIndex: 1,
        };
    }

    responsiveBar = (users, user, key, keyIds) => {
        const nPSS = this.props.neoPenStatisticsStore;

        if (keyIds === undefined || _.isEmpty(nPSS.statisticsData[keyIds])) return <Box />;

        const data = [];
        let averageData = {
            name: '평균',
            amount: Math.round(nPSS.totalStatisticsData[keyIds].amount / (users.length - 1)),
            time: Math.round(nPSS.totalStatisticsData[keyIds].time / (users.length - 1)),
        };

        data.push(averageData);

        const statisticsData = nPSS.statisticsData[keyIds][user.userId];

        if (statisticsData === undefined) {
            data.push({
                name: user.name,
                amount: 0,
                time: 0,
            });
        } else {
            data.push({
                name: user.name,
                amount: statisticsData.totalStrokeCount,
                time: statisticsData.totalStrokeWritingTime,
            });
        }

        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Bar
                        width={width}
                        height={height}
                        margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
                        data={data}
                        indexBy="name"
                        keys={[key]}
                        labelTextColor="inherit:darker(1.4)"
                        enableGridX={true}
                        colors={bar => (bar.indexValue === '평균' ? '#0097ff' : '#78C8FF')}
                        axisBottom={{
                            format: v => {
                                return v.length > 3 ? (
                                    <Tooltip title={v}>
                                        <tspan>
                                            {v.substring(0, 3) + '...'}
                                            <title>{v}</title>
                                        </tspan>
                                    </Tooltip>
                                ) : (
                                    v
                                );
                            },
                        }}
                        theme={{
                            axis: {
                                ticks: {
                                    line: {
                                        stroke: '#0097ff',
                                    },
                                    text: {
                                        fill: '#333',
                                    },
                                },
                            },
                            grid: {
                                line: {
                                    stroke: '#eee',
                                    strokeWidth: 1,
                                    // strokeDasharray: '4 4',
                                },
                            },
                        }}
                    />
                )}
            </AutoSizer>
        );
    };

    render() {
        const { classes } = this.props;

        const nPSS = this.props.neoPenStatisticsStore;
        const nPS = this.props.neoPenStore;
        const cS = this.props.classStore;

        const users = cS.selectedGroupDetail.lmsUser;

        let title = nPS.selectedPaperGroup ? nPS.selectedPaperGroup.title : '';

        let keyIds = nPS.selectedPaperGroup ? nPSS.getKeyFromIds(this.props.gruopId, nPS.selectedPaperGroup.paperGroupId) : undefined;

        return (
            <div className={classes.root}>
                {_.isEmpty(nPSS.statisticsData[keyIds]) ? (
                    <Box className={classes.noContent}>
                        <Box className={classes.avatar}>
                            <PencilSimpleLine />
                        </Box>
                        <Typography>필기 기록이 없습니다.</Typography>
                    </Box>
                ) : (
                    <Box>
                        <Typography variant="h3" className={classes.titleStyle}>
                            {title}
                        </Typography>
                        <Box display="flex" justifyContent="flex-end" style={{ marginBottom: 20 }}>
                            <Box className={classes.flexCenter} style={{ marginRight: 40 }}>
                                <Avatar className={clsx(classes.avatar, classes.avatar2)}>
                                    <AsideUserIcon />
                                </Avatar>
                                <Box flexDirection="column">
                                    <Typography variant="h5" className={classes.name} style={{ height: 22 }}>
                                        {users[this.state.currentUserIndex].name}
                                    </Typography>
                                    {/*<Box display="flex" alignItems="flex-end"> TODO : 마지막 업로드일 추가*/}
                                    {/*    <Typography variant="body2" className={classes.date}>*/}
                                    {/*        마지막 업로드 : 2022.01.01*/}
                                    {/*    </Typography>*/}
                                    {/*</Box>*/}
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="flex-end">
                                <IconButton
                                    disableRipple
                                    disabled={this.state.currentUserIndex === 1}
                                    className={classes.iconBtn}
                                    onClick={() => {
                                        this.setState({
                                            currentUserIndex: this.state.currentUserIndex - 1 > 1 ? this.state.currentUserIndex - 1 : 1,
                                        });
                                    }}
                                >
                                    <BackArrowIcon />
                                </IconButton>
                                <Typography className={classes.pageNumber}>
                                    <b>{this.state.currentUserIndex}</b>/{users.length - 1}
                                </Typography>
                                <IconButton
                                    disableRipple
                                    className={classes.iconBtn}
                                    disabled={this.state.currentUserIndex === users.length - 1}
                                    onClick={() => {
                                        this.setState({
                                            currentUserIndex:
                                                this.state.currentUserIndex + 1 < users.length - 1
                                                    ? this.state.currentUserIndex + 1
                                                    : users.length - 1,
                                        });
                                    }}
                                >
                                    <ArrowRightIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box className={classes.boxStyle} display="flex">
                            <Box className={classes.boxStyleHalf} style={{ marginRight: 16 }}>
                                <Typography variant="h4" className={classes.subTitleStyle}>
                                    {users[this.state.currentUserIndex].name}
                                    <span>의 필기량</span>
                                </Typography>
                                <Box className={classes.graphBox}>
                                    {this.responsiveBar(users, users[this.state.currentUserIndex], 'amount', keyIds)}
                                </Box>
                                <ul className={classes.ulStyle}>
                                    <li>학생들의 평균 필기량과 학생별 필기량을 표시한 그래프입니다.</li>
                                    <li>필기량은 학생들이 필기활동 중 필기한 획의 개수의 합을 나타냅니다.</li>
                                    <li>필기활동은 글자를 쓰거나, 밑줄 및 그림을 표시하는 등의 활동을 의미합니다.</li>
                                </ul>
                            </Box>
                            <Box className={classes.boxStyleHalf}>
                                <Typography variant="h4" className={classes.subTitleStyle}>
                                    {users[this.state.currentUserIndex].name}
                                    <span>의 필기시간</span>
                                </Typography>
                                <Box className={classes.graphBox}>
                                    {this.responsiveBar(users, users[this.state.currentUserIndex], 'time', keyIds)}
                                </Box>
                                <ul className={classes.ulStyle}>
                                    <li>학생들의 평균 필기량과 학생별 필기량을 표시한 그래프입니다.</li>
                                    <li>필기량은 학생들이 필기활동 중 필기한 획의 개수의 합을 나타냅니다.</li>
                                    <li>필기활동은 글자를 쓰거나, 밑줄 및 그림을 표시하는 등의 활동을 의미합니다.</li>
                                </ul>
                            </Box>
                        </Box>
                    </Box>
                )}
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('authStore', 'classStore', 'neoPenStore', 'neoPenStatisticsStore')(observer(ByStudentComponent))),
);
