import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { ReactComponent as EyeTinyIcon } from '../../../common/images/EyeIcon.svg';
import { ReactComponent as HandsClappingIcon } from '../../../common/images/HandsClappingIcon.svg';
import { ReactComponent as ChatCircleDotsIcon } from '../../../common/images/ChatCircleDotsIcon.svg';
import { ReactComponent as MoreIcon } from '../../../common/images/DotsThreeOutlineVerticalIcon.svg';
import CommentComponent from './MobileCommentComponent';
import { inject, observer } from 'mobx-react';
import { CommentType, SortType } from '../../../stores/CommentStore';
import { withRouter } from '../../../components/WithRouter';
import clsx from 'clsx';
import { loadingSate } from '../../../stores/RoomStore';
import YesOrNoDialog from '../../../components/common/YesOrNoDialog';
import { ReactComponent as PaperclipIcon } from '../../../common/images/PaperclipIcon.svg';
import { UTIL } from '../../../common/util/common.util';
import { FILE_POSTED_TYPE } from '../../../stores/ClassFileStore';
import { ReactComponent as DownloadIcon } from '../../../common/images/DownloadIcon.svg';
import WritingDialogComponent from '../../dialog/WritingDialogComponent';
import { ToastsStore } from 'react-toasts';
import { SearchCategory, SearchScope } from '../../../common/SearchItems';
import { TagType } from '../../../stores/ClassStore';
import { getNoticeContents } from '../../../common/Validation';

const styles = _theme => ({
    root: {
        width: 'calc(100vw - 32px)',
        paddingBottom: 70,
        'button:hover': {
            background: 'transparent',
        },
        wordBreak: 'break-all',
    },
    titleStyle: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginTop: 30,
        borderBottom: '1px solid #d3d7db',
        paddingBottom: 7,
        marginBottom: 25,
        '& h3': {
            fontSize: '1.25rem',
            fontWeight: 500,
            marginBottom: 8,
        },
        '& p': {
            fontSize: '0.75em',
            color: '#0d0d0d',
            marginBottom: 10,
        },
    },
    tagWriter: {
        fontSize: '0.625rem',
        backgroundColor: '#5778ed',
        color: '#fff',
        padding: '2px 6px',
        marginLeft: 4,
    },
    content: {
        fontSize: '0.938rem',
        lineHeight: 1.45,
        whiteSpace: 'pre-wrap',
    },
    count: {
        display: 'flex',
        '& div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.75rem',
            color: '#7F7F7F',
            marginRight: 7,
            '& svg': {
                width: 14,
                height: 14,
                opacity: 0.5,
                marginRight: 4,
            },
        },
    },
    boxFooter: {
        border: '0 solid #e1e1e1',
        borderWidth: '1px 0',
        marginTop: 33,
        marginBottom: 19,
    },
    ftCount: {
        padding: '10px 0',
        borderBottom: '1px solid #fff',
        display: 'flex',
        '& div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.75rem',
            color: '#7F7F7F',
            marginRight: 7,
            '& svg': {
                width: 14,
                height: 14,
                opacity: 0.5,
                marginRight: 4,
            },
        },
    },
    ftAdded: {
        padding: '10px 60px',
        '@media  (min-width: 320px) and (max-width: 601px)': {
            padding: '10px 40px',
        },
        '& button': {
            width: '50%',
            textAlign: 'center',
            cursor: 'pointer',
            '&:hover': {
                background: 'transparent',
            },
            '& svg': {
                marginRight: 4,
                opacity: 0.5,
            },
        },
    },
    active: {
        color: '#0097ff',
        fontWeight: 700,
    },
    iconActive: {
        fill: '#0097ff',
        '& path': {
            fill: '#0097ff',
        },
    },
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.813rem',
        color: '#0d0d0d',
        minHeight: 40,
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    chipBox: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 12,
        marginTop: 44,
    },
    chip: {
        height: '21!important',
        backgroundColor: '#eee',
        color: '#656565',
        marginRight: 6,
        padding: '1px 12px',
        fontSize: '0.813rem',
        border: '1px solid #eee',
        borderRadius: 50,
        '&:hover': {
            border: '1px solid #dbf0ff',
            backgroundColor: '#dbf0ff!important',
            color: '#0097ff!important',
            cursor: 'pointer',
        },
    },
    controlBox: {
        borderRadius: 5,
        background: '#f8f8f8',
        padding: '10px 20px',
        marginTop: 21,
        textOverflow: 'ellipsis',
    },
    fileNameText: {
        fontSize: '0.875rem',
        color: '#000',
        marginLeft: 8,
        textOverflow: 'ellipsis',
    },
    fileSizeText: {
        fontSize: '0.75rem',
        color: '#848484',
        marginLeft: 8,
    },
});

class MobileClassBoardDetailViewComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            GoodToggle: false,
            openDeleteConfirmDialog: false,
            writingDialogOpen: false,
            post: '',
        };
    }

    componentDidMount() {
        const { params } = this.props;
        const postId = Number(params.postId);
        if (postId) {
            this.props.postStore.increasePostViews(postId, this.props.authStore.loginUser.id, {
                selectCommentList: groupId => {
                    this.props.commentStore.getCommentList(groupId, postId, CommentType.POST, 0, SortType.TIME);
                },
            });
        } else {
            console.log('NotAcceptable Path');
            this.props.navigate('/rooms');
        }
    }

    handleClickEdit = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    componentWillUnmount() {
        this.props.postStore.initPost();
        this.props.commentStore.initComment();
    }

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleWritingDialogClose = () => {
        this.setState({ writingDialogOpen: false, anchorEl: null });
    };

    handleChangeGoodToggle = (loginUser, groupId, postId, likeId) => {
        this.props.postStore.changeLikeState(loginUser, groupId, postId, likeId);
    };

    handleModifyPost = () => {
        this.props.postStore.setPostDummy({
            changeState: () => this.setState({ anchorEl: null, writingDialogOpen: true }),
        });
    };

    handleDeletePost = post => {
        this.setState({ openDeleteConfirmDialog: true, post: post, anchorEl: null });
    };

    handleSetRemovePostFile = postFile => {
        this.props.classFileStore.setDeleteFileInfo(postFile.groupId, FILE_POSTED_TYPE.Post, postFile.postId, postFile.fileId, postFile.name);
    };

    handleDownLoadPostFile = postFile => {
        this.props.classFileStore.downloadFile(postFile.groupId, FILE_POSTED_TYPE.Post, postFile.postId, postFile.fileId, postFile.name);
    };

    handleWritingComment = () => {
        const element = document.getElementsByClassName('react-input-emoji--input')[0];
        element.focus();
    };

    handleSearchByTagName = (e, tagName) => {
        e.stopPropagation();
        const { classStore, roomStore, authStore, postStore, searchStore, navigate } = this.props;
        classStore.initSearchClass();
        roomStore.initRoom();
        postStore.initPost();
        searchStore.initCurrentPage();
        searchStore.changeSearchKeyword(tagName);
        classStore.getGroupListByKeyword(tagName, {
            moveToSearchPage: () => navigate(`/search/${SearchScope.All}/${SearchCategory.Study}/${tagName}`),
            getRoomList: () =>
                roomStore.getRoomListGroupByStartTime(undefined, authStore.loginUser.id, 'all', 1, undefined, undefined, undefined, tagName),
            getPostList: () => postStore.getPostList(undefined, authStore.loginUser.id, tagName, 1, 16),
        });
    };

    render() {
        const { classes, postStore, currentTab, params, isMember, isScrollEnd, scrollMove, authStore, classStore } = this.props;
        const { postDummy } = postStore;
        const { anchorEl } = this.state;
        const hasAuthorityForPost = postDummy.userId === authStore.loginUser.id || classStore.groupUserAuthority.PostDelete;
        const isMyPost = postDummy.userId === authStore.loginUser.id;

        return (
            <div className={classes.root}>
                <Box>자유 게시판</Box>
                <Box className={classes.titleStyle}>
                    <Box>
                        <Typography variant="h3"> {postDummy.title} </Typography>
                        <Typography variant="body1">{postDummy.userName}</Typography>
                    </Box>
                    {hasAuthorityForPost && (
                        <>
                            <IconButton
                                disableRipple
                                aria-owns={anchorEl ? 'simple-menu' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleClickEdit}
                            >
                                <MoreIcon />
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose}
                                className={classes.menuBox}
                            >
                                {isMyPost && (
                                    <MenuItem onClick={this.handleModifyPost} className={classes.menuItem}>
                                        수정
                                    </MenuItem>
                                )}
                                <MenuItem onClick={() => this.handleDeletePost(postDummy)} className={classes.menuItem}>
                                    삭제
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </Box>
                <Box className={classes.content}>
                    {getNoticeContents(postDummy.body)}
                    <br />
                    {postDummy.tagList && postDummy.tagList.length > 0 && (
                        <Box className={classes.chipBox}>
                            {postDummy.tagList.map((tag, i) => (
                                <Typography key={`${tag.tagId}_${i}`} className={classes.chip} onClick={e => this.handleSearchByTagName(e, tag.name)}>
                                    {tag.name}
                                </Typography>
                            ))}
                        </Box>
                    )}
                    {postDummy.postFiles && postDummy.postFiles.length > 0 && (
                        <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.controlBox}>
                            {postDummy.postFiles.map(file => {
                                return (
                                    <Box key={`${file.id}_${file.name}`}>
                                        <Box display="flex" alignItems="flex-start">
                                            <PaperclipIcon />
                                            <Box style={{ textOverflow: 'ellipsis' }}>
                                                <Typography className={classes.fileNameText}>{file.name}</Typography>
                                                <Typography className={classes.fileSizeText}>{UTIL.formatBytes(file.size)}</Typography>
                                            </Box>
                                        </Box>
                                        <IconButton className={classes.iconButton} disableRipple onClick={() => this.handleDownLoadPostFile(file)}>
                                            <DownloadIcon />
                                        </IconButton>
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Box>
                <Box className={classes.boxFooter}>
                    <Box className={classes.ftCount}>
                        <Box>
                            <EyeTinyIcon />
                            {postDummy.viewCount}{' '}
                        </Box>
                        <Box>
                            <HandsClappingIcon
                                className={
                                    postDummy.likeList && postDummy.likeList.find(user => user.userId === this.props.authStore.loginUser.id)
                                        ? classes.iconActive
                                        : null
                                }
                            />{' '}
                            {postDummy.likeList ? postDummy.likeList.length : 0}{' '}
                        </Box>
                        <Box>
                            <ChatCircleDotsIcon /> {postDummy.commentCount}{' '}
                        </Box>
                    </Box>
                    <Box className={classes.ftAdded}>
                        <Button
                            disableRipple
                            disabled={this.props.postStore.likeLoading === loadingSate.PENDING}
                            onClick={() =>
                                this.handleChangeGoodToggle(
                                    this.props.authStore.loginUser,
                                    postDummy.groupId,
                                    postDummy.postId,
                                    postDummy.likeList.find(user => user.userId === this.props.authStore.loginUser.id)
                                        ? postDummy.likeList.find(user => user.userId === this.props.authStore.loginUser.id).likeId
                                        : 0,
                                )
                            }
                            className={
                                postDummy.likeList && postDummy.likeList.find(user => user.userId === this.props.authStore.loginUser.id)
                                    ? clsx(classes.active, classes.iconActive)
                                    : null
                            }
                        >
                            <HandsClappingIcon /> 좋아요
                        </Button>
                        <Button
                            disableRipple
                            onClick={
                                isMember && this.props.classStore.groupUserAuthority.MemberComment
                                    ? this.handleWritingComment
                                    : () =>
                                          ToastsStore.error(
                                              isMember ? '댓글 작성 권한이 없습니다.' : '클래스에 가입하여야 댓글을 사용할 수 있습니다.',
                                              3000,
                                          )
                            }
                        >
                            <ChatCircleDotsIcon /> 댓글 달기
                        </Button>
                    </Box>
                </Box>
                <CommentComponent
                    groupId={params.id}
                    typeId={postDummy.postId}
                    type={CommentType.POST}
                    currentTab={currentTab}
                    isMember={isMember}
                    isScrollEnd={isScrollEnd}
                    scrollMove={scrollMove}
                />

                <WritingDialogComponent
                    handleClose={this.handleWritingDialogClose}
                    writingDialogOpen={this.state.writingDialogOpen}
                    isModify={true}
                />

                <YesOrNoDialog
                    open={this.state.openDeleteConfirmDialog}
                    title={'게시물 삭제'}
                    msg={'해당 게시물을 삭제하시겠습니까? \n*게시물에 달린 모든 댓글과 자료도 삭제됩니다.'}
                    changeDialogOpen={() => this.setState({ openDeleteConfirmDialog: false })}
                    submit={() =>
                        this.props.postStore.deletePost(this.state.post, this.props.authStore.loginUser.id, {
                            getTagList: () => this.props.classStore.getTagList(this.state.post.groupId, TagType.POST),
                            moveToPrevPage: () => this.props.navigate(-1),
                        })
                    }
                />
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(
        inject(
            'postStore',
            'commentStore',
            'authStore',
            'classFileStore',
            'classStore',
            'roomStore',
            'searchStore',
        )(observer(MobileClassBoardDetailViewComponent)),
    ),
);
