import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, DialogContent, DialogContentText, IconButton, DialogActions, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { ReactComponent as FolderPlusFillIcon } from '../../common/images/FolderPlusFillIcon.svg';
import { ReactComponent as FolderNotchFillIcon } from '../../common/images/FolderNotchFillIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../common/images/ArrowRightIcon.svg';
import clsx from 'clsx';
import { Link } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../components/WithRouter';
import { MovingObjectType } from '../../stores/NeoPenStore';

const style = theme => ({
    root: {
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    dialogBox: {
        '& .MuiDialog-paper': {
            position: 'relative',
            borderRadius: 12,
            padding: '26px 22px',
            width: 400,
        },
        '& .MuiDialogContentText-root': {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1rem',
            color: '#000',
            letterSpacing: '-0.25px',
            marginBottom: 14,
        },
        '& .MuiDialogContent-root': {
            padding: 0,
        },
    },
    titleBox: {
        // marginBottom: 20,
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        fontFamily: 'NanumSquareRoundOTF',
    },
    titleSubBox: {
        borderBottom: '1px solid #D3D7DB',
        paddingLeft: 15,
        paddingBottom: 10,
        '& p': {
            fontSize: '0.75rem',
        },
    },
    errorTxt: {
        color: '#ff0000',
        marginBottom: 14,
    },
    contentList: {
        paddingLeft: 0,
        marginBottom: 20,
        '& ul': {
            paddingInlineStart: 0,
        },
        '& li': {
            marginTop: 16,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'relative',
        },
        '& .MuiLink-root': {
            textDecoration: 'none',
            color: '#000',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'flex-end',
            '& svg': {
                marginRight: 6,
            },
        },
    },
    linkBox: {
        fontWeight: 600,
        '&:before': {
            content: "''",
            width: 24,
            height: 14,
        },
    },
    list2Depth: {
        marginLeft: 24,
    },
    list3Depth: {
        marginLeft: 28,
    },
    closeBtn: {
        '&:hover': {
            background: 'transparent',
        },
    },
    buttonStyle: {
        width: '50%',
        height: 48,
        background: '#1a457e',
        color: '#fff',
        fontSize: '1.125rem',
        fontWeight: 400,
        borderRadius: 7,
        '&:hover': {
            background: '#1a457e',
        },
        '&:disabled, &[disabled]': {
            background: '#c7c9cc',
            color: '#fff',
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    selected: {
        '& svg path': {
            fill: '#1a457e',
        },
        '& span': {
            color: '#1a457e',
        },
    },
    cannotSelected: {
        '& svg path': {
            fill: '#dcdcdc',
        },
        '& span': {
            color: '#c2cbe0',
        },
    },
    IconBtnStyle: {
        position: 'absolute',
        left: -22,
        top: 4,
        padding: 0,
        '& svg': {
            width: 14,
            height: 14,
            transition: '0.2s',
            transform: 'rotate( 0deg );',
            '& path': {
                stroke: '#929292',
            },
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    selectedIconBtn: {
        '& svg': {
            transition: '0.2s',
            transform: 'rotate( 90deg );',
            '& path': {
                stroke: '#1a457e',
            },
        },
    },
});

class SelectFolderDialogComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFolderId: undefined,
            isCreateFolder: false,
            newFolderName: '',
        };
    }

    folderContents = (folder, depth, type) => {
        const { classes, neoPenStore } = this.props;

        const folders = [];

        if (folder === undefined) return <Box />;

        for (let childId of folder.childIds) {
            const childFolder = neoPenStore.folders[childId];

            if (childFolder === undefined) return <Box />;

            if(childFolder.type === type) {
                if (neoPenStore.movingObjectType === MovingObjectType.Folder && childId === neoPenStore.selectedObjectIdToMove) {
                    folders.push(
                        <li>
                            <Link className={classes.cannotSelected}>
                                <FolderNotchFillIcon /> <span>{childFolder.name}</span>
                            </Link>
                        </li>,
                    );

                    continue;
                }

                folders.push(
                    <li>
                        {childFolder.childIds.length > 0 && (
                            <IconButton
                                disableRipple
                                className={childFolder.moreDepth ? clsx(classes.IconBtnStyle, classes.selectedIconBtn) : classes.IconBtnStyle}
                                onClick={() => (childFolder.moreDepth = !childFolder.moreDepth)}
                            >
                                <ArrowRightIcon />
                            </IconButton>
                        )}
                        <Link
                            onClick={() => this.setState({ selectedFolderId: childFolder.id })}
                            className={this.state.selectedFolderId === childFolder.id ? classes.selected : null}
                        >
                            <FolderNotchFillIcon /> <span>{childFolder.name}</span>
                        </Link>

                        {neoPenStore.folders[childId].moreDepth && (
                            <ul style={{ marginLeft: 24 + depth * 4 }}>{this.folderContents(childFolder, depth + 1, type)}</ul>
                        )}
                    </li>,
                );
            }
        }

        return folders;
    };

    render() {
        const { classes, neoPenStore, classStore, type } = this.props;

        const groupName = classStore.selectedGroupDetail.group.name;

        return (
            <div className={classes.root}>
                <Dialog open={this.props.moveFolderDialog} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.titleBox}>
                        <Typography className={classes.titleText}>폴더 선택</Typography>
                        <IconButton
                            disableRipple
                            onClick={() => neoPenStore.setSelectFolderDialogOpened(false, undefined)}
                            className={classes.closeBtn}
                        >
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>
                    <Box className={classes.titleSubBox}>
                        <Typography>
                            서식 탭에서 폴더를 생성하거나 서식을 폴더로 이동하면 '필기 기록'
                            <br /> 탭에도 동일하게 적용됩니다.
                        </Typography>
                    </Box>

                    <DialogContent style={{ marginBottom: 20 }}>
                        <ul className={classes.contentList}>
                            <Link
                                className={this.state.selectedFolderId === null ? classes.selected : null}
                                onClick={() => this.setState({ selectedFolderId: null })}
                            >
                                <FolderNotchFillIcon />
                                {groupName}
                            </Link>

                            <li>
                                <ul className={classes.list2Depth}>{this.folderContents(neoPenStore.folders[null], 0, type)}</ul>
                            </li>
                        </ul>
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <IconButton disableRipple className={classes.iconButton}/>

                        <Button
                            className={classes.buttonStyle}
                            onClick={() => neoPenStore.moveObjectToFolder(this.props.params.id, this.state.selectedFolderId)}
                            disableRipple
                            disabled={this.state.selectedFolderId === undefined}
                        >
                            이동
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withRouter(
    withStyles(style)(inject('neoPenStore', 'authStore', 'classStore', 'classFileStore')(injectIntl(observer(SelectFolderDialogComponent)))),
);
