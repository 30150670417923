import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, FormControl, IconButton, Menu, MenuItem, Select, Typography } from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from '../../../common/images/ArrowDownIcon.svg';
import { ReactComponent as NotePencilIcon } from '../../../common/images/NotePencilIcon.svg';
import { ReactComponent as MoreIcon } from '../../../common/images/DotsThreeOutlineVerticalIcon.svg';
import { inject, observer } from 'mobx-react';
import { withRouter } from '../../../components/WithRouter';
import { PATH_UTIL } from '../../../common/util/path.util';

const styles = theme => ({
    root: {
        // '@media all and (min-width: 1500px)': {
        //     width: 730,
        // },
        // width: 620,
        width: 'calc(100vw - 32px)',
        paddingBottom: 70,
        '& table button.MuiButton-root': {
            minWidth: 'unset',
            padding: 0,
        },
    },
    titleCaption: {
        fontSize: '0.938rem',
    },

    btnStyle: {
        '@media all and (min-width: 1500px)': {
            width: 235,
            marginLeft: 90,
        },
        width: 230,
        height: 40,
        marginLeft: 30,
        backgroundColor: '#0097FF',
        color: '#fff',
        borderRadius: 7,
        '& svg': {
            marginRight: 4,
        },
        '&:hover': {
            backgroundColor: '#0097FF',
        },
    },

    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 0',
        '@media all and (min-width: 601px)': {
            padding: '17px 0 17px 26px',
        },
    },
    flexCenter: {
        width: 'calc(100% - 48px)',
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listItemTitle: {
        maxWidth: 'calc(100% - 43px)',
        fontSize: '0.875rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    tagRedStyle: {
        fontSize: '0.625rem',
        color: '#fff',
        backgroundColor: '#fb4a59',
        padding: '3px 4px',
        fontWeight: 700,
        marginLeft: 7,
    },
    menuBox: {
        '& .MuiPaper-root': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        },
        '& .MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
});
class MobileMyLearningContentsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;
        const { tabs, anchorGeneral } = this.state;
        const nPS = this.props.neoPenStore;

        let paperGroupCnt = nPS.singleUserAllNotes.length;

        if (this.props.seletedGroupId !== undefined)
            paperGroupCnt = nPS.singleUserAllNotes.filter(n => this.props.seletedGroupId === n.group.id).length;

        return (
            <div className={classes.root}>
                <Typography className={classes.titleCaption}>
                    <b>내 필기</b> • {paperGroupCnt}개
                </Typography>
                <Box className={classes.filterStyle}>
                    <Box>
                        <FormControl className={classes.formControl}>
                            <Select
                                value={this.props.filter}
                                displayEmpty
                                onChange={this.props.handleChangeSort}
                                IconComponent={() => (
                                    <Box style={{ width: 16, height: 16, marginLeft: 5 }}>
                                        <ArrowDownIcon />
                                    </Box>
                                )}
                            >
                                <MenuItem value="date" className={classes.menuItem}>
                                    최근 작성일 순
                                </MenuItem>
                                <MenuItem value="alpha" className={classes.menuItem}>
                                    가나다 순
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                {nPS.singleUserAllNotes.map((n, i) => {
                    let lastUploadDateStr = '';

                    if (n.note.lastStrokeAt !== undefined) lastUploadDateStr = `${nPS.convertTimestamp(n.note.lastStrokeAt).split(',')[0]}`;

                    if (this.props.seletedGroupId !== undefined && this.props.seletedGroupId !== n.group.id) return <Box />;

                    return (
                        <Box key={i} className={classes.listItemStyle}>
                            <Box className={classes.flexCenter}>
                                <Box className={classes.avatar}>
                                    <NotePencilIcon />
                                </Box>
                                <Box flexDirection="column" style={{ width: 'calc(100% - 50px)' }}>
                                    <Box display="flex" alignItems="center" flexDirection="row" style={{ width: '100%' }}>
                                        <Typography variant="h5" className={classes.listItemTitle}>
                                            {n.paperGroup && n.paperGroup.title.trim().replace(/(.pdf)$/, '')}
                                        </Typography>
                                        {n.note.modifiedIds !== undefined && n.note.modifiedIds.length >= 2 && (
                                            <span className={classes.tagRedStyle}>첨삭됨</span>
                                        )}
                                    </Box>
                                    <Typography variant="body2" className={classes.listItemDate}>
                                        {`${n.group.name} | ${lastUploadDateStr}`}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <IconButton
                                    disableRipple
                                    //aria-owns={anchorEl ? 'simple-menu' : undefined}
                                    aria-haspopup="true"
                                    onClick={e => {
                                        nPS.selectPaperGroup(n.paperGroup);
                                        nPS.selectUser(nPS.user);
                                        nPS.selectGroup(n.group);

                                        console.log('selectGroup', n.group);

                                        this.props.handleClickGeneral(e);
                                    }}
                                >
                                    <MoreIcon />
                                </IconButton>
                            </Box>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.props.anchorGeneral}
                                open={this.props.anchorGeneral}
                                onClose={this.props.handleClose}
                                className={classes.menuBox}
                            >
                                <MenuItem
                                    onClick={() => {
                                        this.props.handleClose();

                                        window.open(
                                            `/penview/${nPS.selectedGroup.id}/${nPS.selectedUser.email}/${nPS.selectedPaperGroup.paperGroupId}`,
                                        );
                                    }}
                                    className={classes.menuItem}
                                >
                                    필기 확인
                                </MenuItem>
                                {/*<MenuItem onClick={this.props.handleClose} className={classes.menuItem}>*/}
                                {/*    사용한 수업보기*/}
                                {/*</MenuItem>*/}
                            </Menu>
                        </Box>
                    );
                })}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('authStore', 'classStore', 'neoPenStore')(observer(MobileMyLearningContentsComponent))));
