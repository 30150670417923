import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Tab, Tabs } from '@material-ui/core';
import MobileSearchStudyComponent from './MobileSearchStudyComponent';
import { inject, observer } from 'mobx-react';

const styles = theme => ({
    root: {
        width: '100%',
        padding: '20px 10px 15px',
        margin: '0 auto',
        boxSizing: 'border-box',
        background: '#f3f3f3',
    },
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        minHeight: 0,
        '&:after': {
            content: '""',
            width: '100%',
            height: 3,
            backgroundColor: '#d3d4d5',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: 0,
        },
        '& button': {
            minWidth: 70,
            fontSize: '0.688rem',
            color: '#000',
            opacity: 1,
            minHeight: 30,
            '&.Mui-selected': {
                color: '#0097ff',
            },
        },
        '& .MuiTabs-indicator': {
            height: 2,
            backgroundColor: '#0097ff',
            zIndex: 1,
        },
        '& .MuiTab-root': {
            padding: '0 0px',
        },
        '@media all and (min-width: 601px)': {
            minHeight: 48,
            '& button': {
                minWidth: 100,
                minHeight: 48,
                fontSize: '0.938rem',
            },
            '& .MuiTabs-indicator': {
                height: 3,
            },
        },
    },
});

class MobileSearchAllGroupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 0,
        };
    }

    handleTabChange = (event, tabs) => {
        this.setState({ tabs });
    };

    render() {
        const { classes, mobileLandscape, landscape } = this.props;
        const { tabs } = this.state;
        const { searchGroupList } = this.props.classStore;

        return (
            <div className={classes.root}>
                <Box>
                    <Tabs value={tabs} onChange={this.handleTabChange} className={classes.tabStyle}>
                        <Tab label={`클래스 (${searchGroupList.length > 999 ? '999+' : searchGroupList.length})`} disableRipple />
                    </Tabs>
                </Box>
                <MobileSearchStudyComponent studyGroupList={searchGroupList} landscape={landscape} mobileLandscape={mobileLandscape} />
            </div>
        );
    }
}

export default withStyles(styles)(inject('classStore')(observer(MobileSearchAllGroupComponent)));
