import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MobileSearchScheduleComponent from './MobileSearchScheduleComponent';
import MobileSearchBoardComponent from './MobileSearchBoardComponent';
import MobileSearchMyGroupTabComponent from './MobileSearchMyGroupTabComponent';

const styles = theme => ({
    root: {
        background: '#f3f3f3',
    },
});

class MobileSearchMyGroupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTab: 0,
        };
    }

    handleChange = (event, searchTab) => {
        this.setState({ searchTab });
    };

    render() {
        const { classes, isScrollEnd, keyword, mobileLandscape, landscape } = this.props;

        return (
            <div className={classes.root}>
                <MobileSearchMyGroupTabComponent searchTab={this.state.searchTab} handleChange={this.handleChange} />
                {this.state.searchTab === 0 && (
                    <MobileSearchScheduleComponent isScrollEnd={isScrollEnd} mobileLandscape={mobileLandscape} landscape={landscape} />
                )}
                {this.state.searchTab === 1 && (
                    <MobileSearchBoardComponent isScrollEnd={isScrollEnd} keyword={keyword} mobileLandscape={mobileLandscape} landscape={landscape} />
                )}
            </div>
        );
    }
}

export default withStyles(styles)(MobileSearchMyGroupComponent);
