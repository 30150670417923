import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import { ReactComponent as UsersThreeIcon } from '../../../common/images/UsersThreeIcon.svg';
import { ReactComponent as AlarmIcon } from '../../../common/images/AlarmIcon.svg';
import { withRouter } from '../../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { DATE_UTIL } from '../../../common/util/date.util';
import { roomState, RoomStateFlagIcon } from '../../../stores/RoomStore';
import dayjs from 'dayjs';
import { ENTER_BTN_NAME } from '../../myRoom/TodayScheduleComponent';

const styles = _theme => ({
    root: {},
    titleText: {
        '@media all and (min-width: 601px)': {
            fontSize: '1.125rem',
        },
        fontSize: '1rem',
        color: '#000',
    },
    scheduleBox: {
        width: '100%',
        background: '#fff',
        padding: '20px 10px 0',
        margin: '0 auto',
        boxSizing: 'border-box',
    },
    titleBox: {
        '@media all and (min-width: 601px)': {
            marginBottom: 25,
        },
        marginBottom: 10,
    },
    dateText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.75rem',
            marginLeft: 10,
        },
        fontSize: '0.625rem',
        color: '#656565',
        fontWeight: 600,
        marginLeft: 5,
        marginBottom: 2,
    },
    textStyle: {
        '@media all and (min-width: 601px)': {
            fontSize: '1rem',
        },
        fontSize: '0.875rem',
        color: '#656565',
    },
    noScheduleBox: {
        '@media all and (min-width: 601px)': {
            paddingBottom: 100,
        },
        paddingBottom: 50,
    },
    scheduleBoxIn: {
        width: '100%',
    },
    landscapeScheduleBoxIn: {
        '@media all and (min-width: 601px)': {
            height: 250,
            borderTop: '7px solid #a3a8af',
        },
        width: 'calc((100% / 3) - 7px)',
        marginRight: 10,
        height: 160,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        marginBottom: 10,
        borderTop: '5px solid #a3a8af',
        border: '1px solid rgba(163, 168, 175, 0.5)',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        '&:nth-child(3n+0)': {
            marginRight: 0,
        },
    },
    scheduleBoxStyle: {
        '@media all and (min-width: 601px)': {
            height: 250,
            borderTop: '7px solid #a3a8af',
        },
        width: '49%',
        height: 160,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        marginBottom: 10,
        borderTop: '5px solid #a3a8af',
        border: '1px solid rgba(163, 168, 175, 0.5)',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        '@media (min-width: 319px) and (max-width: 374px)': {
            height: 170,
        },
    },
    liveScheduleBox: {
        '@media all and (min-width: 601px)': {
            borderTop: '7px solid #00c880',
        },
        borderTop: '5px solid #00c880',
        border: '1px solid rgba(0, 200, 128, 0.5)',
    },
    roomNameBox: {
        padding: '3px 10px',
        borderBottom: '1px solid #d3d7db',
        '& svg': {
            width: 15,
            height: 18,
            marginRight: 8,
        },
        '@media all and (min-width: 601px)': {
            padding: '7px 15px',
            '& svg': {
                width: 19,
                height: 22,
            },
        },
    },
    roomNameText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.875rem',
        },
        width: '95%',
        fontSize: '0.625rem',
        color: '#000',
    },
    contentsBox: {
        padding: '5px 15px 0px',
        boxSizing: 'border-box',
    },
    iconBox: {
        height: 22,
        '@media all and (min-width: 601px)': {
            height: 34,
            '& svg': {
                width: 40,
                height: 32,
            },
        },
    },
    scheduleTitleText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.938rem',
            margin: '7px 0 13px',
        },
        fontSize: '0.813rem',
        color: '#000',
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        margin: '0',
    },
    alarmBox: {
        '& svg': {
            width: 15,
            height: 15,
            '& .st0': {
                fill: '#00c880',
            },
        },
        '@media all and (min-width: 601px)': {
            '& svg': {
                width: 22,
                height: 22,
            },
        },
    },
    timeText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.875rem',
        },
        fontSize: '0.625rem',
        color: '#656565',
        marginLeft: 5,
    },
    buttonStyle: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.813rem',
            height: 34,
            marginTop: 20,
            borderRadius: 7,
        },
        width: '100%',
        height: 28,
        border: '1px solid #656565',
        color: '#656565',
        borderRadius: 5,
        fontWeight: 600,
        fontSize: '0.625rem',
        marginTop: 10,
        '&:hover': {
            background: 'transparent',
        },
    },
    liveButtonStyle: {
        border: '1px solid #0097ff',
        color: '#0097ff',
    },
    commentBox: {
        '@media all and (min-width: 601px)': {
            minHeight: 59,
        },
        minHeight: 35,
    },
});

class MobileTodayScheduleComponent extends Component {
    componentDidMount() {
        const start = new Date();
        this.props.roomStore.initRoom();
        this.props.roomStore.setRoomRowsPerPage(16);
        this.props.roomStore.getRoomListGroupByStartTime(
            undefined,
            this.props.authStore.loginUser.id,
            'today',
            1,
            start,
            undefined,
            undefined,
            undefined,
        );
    }

    componentDidUpdate(prevProps: Readonly<P>, _prevState: Readonly<S>, _snapshot: SS) {
        const { roomStore, authStore, isScrollEnd } = this.props;
        if (prevProps.isScrollEnd !== isScrollEnd && isScrollEnd && roomStore.roomTotalCount > roomStore.roomListGroupByStartTime.length) {
            const start = new Date();
            roomStore.changeCurrentPage();
            roomStore.getRoomListGroupByStartTime(undefined, authStore.loginUser.id, 'today', roomStore.currentPage, start);
        }
    }

    handleClickDetail = (groupId, roomId) => {
        window.scrollTo(0, 0);
        this.props.navigate(`/class/${groupId}/scheduledetail/${roomId}`);
    };

    handleClickEnter = (event, url) => {
        event.stopPropagation();
        window.open(url);
    };

    handleClickEnterBtn = (event, isLive, schedule) => {
        event.stopPropagation();

        if (isLive) {
            this.enterSchedule(schedule);
        } else {
            this.handleClickDetail(schedule.groupId, schedule.id);
        }
    };

    enterSchedule = schedule => {
        if (schedule) {
            const { loginUser } = this.props.authStore;
            const url = schedule.members.find(m => m.userId === loginUser.id).entranceUrl;
            if (url) window.open(url);
        }
    };

    checkRoomState = (startDatetime, endDatetime) => {
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedStartDatetime = DATE_UTIL.convertUTCToTimeZone(startDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedEndDatetime = DATE_UTIL.convertUTCToTimeZone(endDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        if (dayjs(convertedEndDatetime).isBefore(currentDatetime)) {
            return roomState.Ended;
        } else if (
            dayjs(currentDatetime).isBetween(convertedStartDatetime, convertedEndDatetime) ||
            dayjs(currentDatetime).isSame(convertedStartDatetime) ||
            dayjs(currentDatetime).isSame(convertedEndDatetime)
        ) {
            return roomState.Live;
        } else {
            return roomState.Future;
        }
    };

    selectRenderSchedulesComponent = () => {
        const { classes, isMobile, roomStore } = this.props;
        if (!roomStore.roomListGroupByStartTime.length > 0) {
            return (
                <Box className={classes.noScheduleBox}>
                    <Typography className={classes.textStyle}>등록된 수업이 없습니다.</Typography>
                </Box>
            );
        }

        return (
            <Box display="flex" justifyContent="space-between" flexWrap="wrap" className={classes.scheduleBoxIn}>
                {roomStore.roomListGroupByStartTime.map((schedule, i) => {
                    const state = this.checkRoomState(schedule.startDatetime, schedule.endDatetime);
                    const isLive = state === roomState.Live;
                    return (
                        <Box
                            key={i}
                            className={
                                isLive
                                    ? !isMobile
                                        ? clsx(classes.liveScheduleBox, classes.landscapeScheduleBoxIn)
                                        : clsx(classes.scheduleBoxStyle, classes.liveScheduleBox)
                                    : !isMobile
                                    ? classes.landscapeScheduleBoxIn
                                    : classes.scheduleBoxStyle
                            }
                        >
                            <Box display="flex" alignItems="center" className={classes.roomNameBox}>
                                <UsersThreeIcon />
                                <Typography className={classes.roomNameText} noWrap>
                                    {schedule.groupName}
                                </Typography>
                            </Box>
                            <Box className={classes.contentsBox}>
                                <Box className={classes.iconBox}>{RoomStateFlagIcon[state]}</Box>
                                <Box className={classes.commentBox}>
                                    <Typography className={classes.scheduleTitleText}>{schedule.name}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.alarmBox}>
                                    <AlarmIcon />
                                    <Typography className={classes.timeText}>
                                        {DATE_UTIL.convertUTCToTimeZone(schedule.startDatetime, DATE_UTIL.FORMAT.SIMPLE_TIME_AMPM_FORMAT)} ~{' '}
                                        {DATE_UTIL.convertUTCToTimeZone(schedule.endDatetime, DATE_UTIL.FORMAT.SIMPLE_TIME_AMPM_FORMAT)}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="center">
                                    <Button
                                        className={clsx(classes.buttonStyle, isLive ? classes.liveButtonStyle : null)}
                                        disableRipple
                                        onClick={e => this.handleClickEnterBtn(e, isLive, schedule)}
                                    >
                                        {ENTER_BTN_NAME[state]}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        );
    };

    render() {
        const { classes, isMobile } = this.props;
        const today = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DOT_FORMAT);
        const scheduleElement = this.selectRenderSchedulesComponent();

        return (
            <div className={classes.root}>
                <Box className={classes.scheduleBox}>
                    <Box display="flex" alignItems="flex-end" className={classes.titleBox}>
                        <Typography className={classes.titleText}>오늘 수업 피드</Typography>
                        <Typography className={classes.dateText}>{today}</Typography>
                    </Box>

                    <Box display="flex" flexWrap="wrap" justifyContent={!isMobile ? 'flex-start' : 'space-between'} style={{ width: '100%' }}>
                        {scheduleElement}
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('authStore', 'roomStore', 'userStore')(observer(MobileTodayScheduleComponent))));
