import React, { Component } from 'react';
import { Button, Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { inject } from 'mobx-react';

const styles = theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        margin: '0 0 auto',
    },
    trigger: {
        minHeight: 48,
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: '620px!important',
        '&.MuiTab-root': {
            letterSpacing: 0,
        },
        '& button': {
            '@media all and (min-width: 1500px)': {
                minWidth: 95,
            },
            minWidth: 80,
            minHeight: 'inherit',
            position: 'relative',
            opacity: 1,
            marginRight: 10,
            marginBottom: 10,
            '& span': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
            },
            '&:hover': {
                // fontWeight: 700,
            },
        },
        '& button.Mui-selected': {
            backgroundColor: '#0097FF',
            color: '#fff',
            // fontWeight: 700,
            borderRadius: 50,
            overflow: 'inherit',
        },
        '& .MuiTabs-indicator': {
            display: 'none',
            width: 0,
        },
        '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
        },
        '& .MuiTab-root': {
            maxWidth: '80%',
        },
    },
    iconStyle: {
        position: 'absolute',
        right: '-2px',
        top: '-2px',
        zIndex: 10,
    },
    moreBtn: {
        fontSize: '0.813rem',
        color: '#0097ff',
        minWidth: 70,
    },
});
class DashboardTabTriggerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMoreBtn: false,
            classBoxHeight: 0,
        };
    }
    componentDidMount() {
        const target = document.getElementsByClassName('MuiTabs-flexContainer')[1];
        if (target) {
            const classBoxHeight = target.clientHeight;
            if (classBoxHeight > 50) {
                this.setState({ classBoxHeight: classBoxHeight });
                target.style.maxHeight = '48px';
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, _prevState: Readonly<S>, _snapshot: SS) {
        if (prevProps.classGroupList !== this.props.classGroupList) {
            const target = document.getElementsByClassName('MuiTabs-flexContainer')[1];
            const classBoxHeight = target.clientHeight;
            if (classBoxHeight > 50) {
                this.setState({ classBoxHeight: classBoxHeight });
                target.style.maxHeight = '48px';
            }
        }
    }

    handleClassOpen = () => {
        this.setState({ isMoreBtn: true });
        let triggerContainer = document.getElementsByClassName('MuiTabs-flexContainer')[1];
        triggerContainer.style.maxHeight = '100%';
    };

    handleClassClose = () => {
        this.setState({ isMoreBtn: false });
        let triggerContainer = document.getElementsByClassName('MuiTabs-flexContainer')[1];
        triggerContainer.style.maxHeight = '48px';
    };

    handleChangeClass = (groupId, groupName) => {
        console.log('handleChangeClass');
        this.checkSearchKeywordAndInitialize();

        this.props.setGroupId(groupId, groupName);
        if (this.props.parentTab === 0) {
            this.props.roomStore.getSimpleRoomListByGroupId(groupId, this.props.authStore.loginUser.id, this.props.filter, 1);
        }
    };

    checkSearchKeywordAndInitialize = () => {
        const { searchingKeyword } = this.props;
        if (searchingKeyword) {
            this.props.initSearchKeyword();
        }
    };

    render() {
        const { classes, classGroupList } = this.props;
        const { classBoxHeight } = this.state;

        return (
            <div className={classes.root}>
                <Tabs value={this.props.tabs} onChange={this.props.handleChange} className={classes.trigger} id={'trigger-root'}>
                    <Tab
                        label="전체"
                        disableRipple
                        // icon={<BedgeNewIcon className={classes.iconStyle} />}
                        onClick={() => this.handleChangeClass(0)}
                    />
                    {classGroupList &&
                        classGroupList.length > 0 &&
                        classGroupList.map((g, _i) => {
                            return (
                                <Tab
                                    key={g.group.id}
                                    label={g.group.name}
                                    disableRipple
                                    onClick={() => this.handleChangeClass(g.group.id, g.group.name)}
                                />
                            );
                        })}
                    <Tab
                        label="검색"
                        // disabled={classTab === ClassMainPath.tag}
                        disableRipple
                        style={{ display: 'none' }}
                    />
                </Tabs>
                {classBoxHeight > 50 && (
                    <React.Fragment>
                        {!this.state.isMoreBtn ? (
                            <Button disableRipple className={classes.moreBtn} onClick={this.handleClassOpen}>
                                + 더보기
                            </Button>
                        ) : (
                            <Button disableRipple className={classes.moreBtn} onClick={this.handleClassClose}>
                                - 닫기
                            </Button>
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(inject('roomStore', 'authStore')(DashboardTabTriggerComponent));
