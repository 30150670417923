import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as StudyLiveLogo } from '../../common/images/StudyLiveLogo.svg';
import { Box, Typography } from '@material-ui/core';
import { withRouter } from '../../components/WithRouter';

const styles = _theme => ({
    root: {
        background: '#fbfbfb',
        borderTop: '1px solid #c0c2c3',
        display: 'flex',
        justifyContent: 'center',
    },
    boxIn: {
        '@media all and (min-width: 1500px)': {
            width: 1440,
        },
        width: 1200,
        height: 98,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '0 32px',
    },
    textStyle: {
        fontSize: '0.875rem',
        color: '#787878',
        fontWeight: '600',
    },
    lineStyle: {
        width: 1,
        height: 16,
        background: '#a8a8a8',
        margin: '0 10px',
    },
    marginLeft: {
        marginLeft: 100,
    },
    cursor: {
        cursor: 'pointer',
    },
});

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleClickServiceCenter = e => {
        this.props.navigate('/servicecenter');
    };

    handleClickLogo = e => {
        this.props.navigate('/rooms');
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Box className={classes.boxIn}>
                    <Box display="flex" alignItems="center">
                        <Box onClick={this.handleClickLogo} className={classes.cursor}>
                            <StudyLiveLogo />
                        </Box>
                        <Box display="flex" alignItems="center" className={classes.marginLeft}>
                            <Typography className={clsx(classes.textStyle, classes.cursor)} onClick={() => window.open('/termsofuse', '_blank')}>
                                이용약관
                            </Typography>
                            <span className={classes.lineStyle} />
                            <Typography className={clsx(classes.textStyle, classes.cursor)} onClick={() => window.open('/privacypolicy', '_blank')}>
                                개인정보처리방침
                            </Typography>
                            <span className={classes.lineStyle} />
                            <Typography className={clsx(classes.textStyle, classes.cursor)} onClick={this.handleClickServiceCenter}>
                                고객센터
                            </Typography>
                        </Box>
                    </Box>

                    <Typography className={classes.textStyle}>STUDYLIVE © 2022</Typography>
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(Footer));
