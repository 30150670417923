import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import _, { cloneDeep } from 'lodash';
import { ApiErrorCode } from '../common/ApiErrorCode';
import { validateFileOrFolderName } from '../common/Validation';
import { loadingSate } from './PostStore';
import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE } from './PaginationStore';
import dayjs from 'dayjs';
import {Box} from "@mui/joy";
import {Tooltip} from "@material-ui/core";

const logPrefix = ' [ ClassStore ] ';

const criteria = ["문법 정확성", "내용 완결성", "어휘 유창성", "내용 구성력"];
    //     "다음 내용은 학생이 글쓰기 대회에 출품한 글이야 이 글을 평가하기 위한 기준을 위에 적었어. 위 기준을 기반으로 학생의 수준을 평가해줘",
    // "어휘 유창성": "기준 1. 어휘력은 문서를 구성하기 위해 활용된 어휘의 다양성과 함께 중복되지 않은 어휘의 구사능력을 말함\n" +
    //     "기준 2. 유창성은 문서 내 주제의 전달이 자연스러운지를 평가하는 것으로 문장구성의 난이도와 함께 문서 내 토픽 구성의 차별성을 말함\n" +
    //     "다음 내용은 학생이 글쓰기 대회에 출품한 글이야 이 글을 평가하기 위한 기준을 위에 적었어. 위 기준을 기반으로 학생의 수준을 평가해줘",
    // "내용 구성력": "기준 1. 간결성은 문서의 내용 전달이 간결하게 이루어지고 있는지를 평가하는 것으로 중복된 단어의 수와 함께 형용사와 부사 사용의 적절성을 말함\n" +
    //     "기준 2. 결속성은 문서 내 문단 및 문장의 연결이 자연스러운지와 함께 문장 안에서 사용된 단어들이 적절하게 연결되고 있는 지를 말함\n" +
    //     "다음 내용은 학생이 글쓰기 대회에 출품한 글이야 이 글을 평가하기 위한 기준을 위에 적었어. 위 기준을 기반으로 학생의 수준을 평가해줘",
    // "총평": "기준 1. 문법 정확성은 문서 내 맞춤법 오류, 띄어쓰기 오류, 문장 부호 오류의 정도를 말함\n" +
    //     "기준 2. 일관성은 문서 내 등장한 문서들이 특정 어휘가 중심이 되어 타 어휘와 연결되어 있는 정도를 말함\n" +
    //     "기준 3. 어휘력은 문서를 구성하기 위해 활용된 어휘의 다양성과 함께 중복되지 않은 어휘의 구사능력을 말함\n" +
    //     "기준 4. 유창성은 문서 내 주제의 전달이 자연스러운지를 평가하는 것으로 문장구성의 난이도와 함께 문서 내 토픽 구성의 차별성을 말함\n" +
    //     "기준 5. 간결성은 문서의 내용 전달이 간결하게 이루어지고 있는지를 평가하는 것으로 중복된 단어의 수와 함께 형용사와 부사 사용의 적절성을 말함\n" +
    //     "기준 6. 결속성은 문서 내 문단 및 문장의 연결이 자연스러운지와 함께 문장 안에서 사용된 단어들이 적절하게 연결되고 있는 지를 말함\n" +
    //     "다음 내용은 학생이 글쓰기 대회에 출품한 글이야 이 글을 평가하기 위한 기준을 위에 적었어. 위 기준을 기반으로 학생의 수준을 평가해줘"

export const SessionStorageGroupInviteToken = '_ONTHELIVE_GROUP_INVITE_AUTHENTICATION_TOKEN_';

export const NOTE_CORRECTION_HANDLE_OPERATION = {
    Upload: 'upload',
    Read: 'read',
};

export const GroupFeatureKeyName = {
    GroupModify: 'GroupModify',
    GroupNotice: 'GroupNotice',
    GroupInvite: 'GroupInvite',
    GroupApproval: 'GroupApproval',
    GroupMember: 'GroupMember',
    RoomCreate: 'RoomCreate',
    RoomModify: 'RoomModify',
    RoomComment: 'RoomComment',
    MemberComment: 'MemberComment',
    PostNotice: 'PostNotice',
    PostDelete: 'PostDelete',
    FolderCreate: 'FolderCreate',
};

export const GroupAuthorityTypeName = {
    Leader: 'Leader',
    Operator: 'Operator',
    Member: 'Member',
    Owner: 'Owner',
    All: 'All',
    Anonymous: 'Anonymous',
};

export const GroupJoinRequestPath = {
    Search: 'search',
    Invitation: 'invitation',
};

export const GroupJoinApprovalOperation = {
    Approve: 'approve',
    Refuse: 'refuse',
};

export const GroupJoinApprovalState = {
    None: 'None',
    Wait: 'Wait',
    Approved: 'Approved',
    Refused: 'Refused',
    Canceled: 'Canceled',
};

export const GroupOpenType = {
    unknown: 'unknown',
    public: 'public',
    private: 'private',
};
export const CLASS_STORE_MESSAGE_ID = {
    Default: { empty: 'default_empty' },
    Error: {
        exist_folder_name: 'msg.exist_folder_name',
        not_allowed_folder_name: 'msg.not_allowed_folder_name',
    },
};

export const LoadingState = {
    READY: 'READY',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
};

export const LmsGroupType = {
    study: 'Study',
    webinar: 'Webinar',
    coding: 'Coding',
};

export const LmsGroupUserType = {
    leader: 'Leader',
    operator: 'Operator',
    member: 'Member',
};

export const LMS_GROUP_USER_TYPE_NAME_TAG = {
    KO: {
        Leader: '교사',
        Operator: '관리자',
        Member: '학생',
    },
    EN: {
        Leader: 'Leader',
        Operator: 'Operator',
        Member: 'Member',
    },
};

export const ClassSortType = {
    create: 'create',
    update: 'update',
    abc: 'abc',
};

export const TagType = {
    ROOM: 'Room',
    POST: 'Post',
};

export const GroupClassType = {
    Normal: 'Normal',
    MyClass: 'MyClass',
};

const EmptyClass = {
    id: '',
    name: '',
    comment: '',
    type: LmsGroupType.study,
    public: true,
    maxUsers: '',
    color: '',
};

const EmptyTag = {
    id: '',
    groupId: '',
    userId: '',
    name: '',
};

const EmptyGroupTransfer = {
    group: { name: '', comment: '', id: '' },
    lmsUser: [{ name: '', type: '' }],
    noticeList: [],
    tagList: Array(3).fill({ ...EmptyTag }),
    teamList: [],
    latestRegistrationFiles: [],
    image: '',
};

const EmptyGroupNotice = {
    groupId: '',
    noticeId: '',
    title: '',
    content: '',
    userId: '',
};
const EmptyInviteGroup = {
    id: '',
    groupId: '',
    userId: '',
    url: '',
    expireDatetime: '',
    userName: '',
    groupName: '',
    groupComment: '',
    groupImage: '',
    groupUsers: [],
};

const EmptyInvite = {
    id: '',
    groupId: '',
    userId: '',
    url: '',
    expireDatetime: '',
};

const EmptyGroupUserAuthorityMap = {
    GroupModify: false,
    GroupNotice: false,
    GroupInvite: false,
    GroupApproval: false,
    GroupMember: false,
    RoomCreate: false,
    RoomModify: false,
    RoomComment: false,
    MemberComment: false,
    PostNotice: false,
    PostDelete: false,
    FolderCreate: false,
};

const noteDummyData = {
    groupId : 0,
    userEmail : '',
    paperGroupId : '',
    editText : '원본 필기 데이터 입니다.',
    result : undefined,
};

export const GROUP_FOLDER_TYPE = {
    Root: 'Root',
    Sub: 'Sub',
};

export const ALLOWED_FOLDER_BREADCRUMBS_LENGTH = 5;

export default class ClassStore {
    constructor(serverContextPath, avatarStore) {
        this.serverContextPath = serverContextPath;
        this.avatarStore = avatarStore;
        makeAutoObservable(this);
    }

    class = Object.assign({}, EmptyClass);
    tag = Object.assign({}, EmptyTag);
    editNotice = Object.assign({}, EmptyGroupNotice);
    tagList = Array(3).fill({ ...EmptyTag });
    groupList = [];
    classGroupList = [];
    groupImage = '';
    groupNotice = Object.assign({}, EmptyGroupNotice);
    selectedGroupDetail = Object.assign({}, EmptyGroupTransfer);
    selectedFolderId = 0;
    selectedFileId = 0;
    createFolderName = '';
    groupFolderAndFiles = [];
    searchGroupList = [];
    groupListCount = 0;
    groupCurrentCount = 0;
    classSortType = ClassSortType.create;
    simpleGroupList = [];
    simpleGroupListDummy = [];
    // folderOrFileInputState = false;
    isCreateFolder = false;
    groupRootFolderId = 0;

    detailTagList = [];

    groupListFirstLoading = LoadingState.READY;
    groupListLoading = LoadingState.READY;
    searchGroupListLoading = LoadingState.READY;
    imageLoading = LoadingState.READY;
    changeHideGroupLoading = LoadingState.READY;

    editFolderName = '';
    folderLoadState = LoadingState.READY;
    folderLoadResultDialogMsgId = CLASS_STORE_MESSAGE_ID.Default.empty;
    editFolderId = 0;
    deleteFolderId = 0;
    folderBreadcrumbs = [];
    userIdsInLivRooms = [];
    searchRooms = [];

    editPublicGroup = GroupOpenType.unknown;
    selectedNotice = '';

    groupModifyLoading = LoadingState.READY;

    groupJoinApprovals = [];
    joinApprovalState = GroupJoinApprovalState.None;
    invite = { ...EmptyInvite };
    selectedInviteExpireDatetime = '';

    selectedGroupUsers = [];
    selectedGroupUsersTotalCount = 0;
    selectedGroupId = 0;

    selectedGroupMemberAndOperators = [];
    selectedGroupMemberAndOperatorsTotalCount = 0;

    selectedAllGroupUsers = [];
    selectedAllGroupUsersTotalCount = 0;

    inviteGroup = { ...EmptyInviteGroup };

    groupAuthorities = [];
    groupAuthorityMap = {};

    groupAuthorityLoading = LoadingState.READY;

    selectedForcedLeaveGroupUserEmail = '';

    groupUserAuthority = { ...EmptyGroupUserAuthorityMap };

    openConfirmDialog = false;
    errMsg = '';

    groupDetailInitializeState = LoadingState.READY;
    groupUserLoadingState = LoadingState.READY;

    groupCurrentFiles = [];

    groupFolderAndFilesLoadingState = LoadingState.READY;
    isEndFolderAndFiles = false;

    invitationLoadingState = LoadingState.READY;

    checkJoinApprovalLoading = LoadingState.READY;

    inviteToken = undefined;

    groupInvitees = [];
    inviteGroupList = [];

    paperGroupCorrections = [];
    paperGroupCorrection = {};

    noteEditable = false;
    noteEvaluationResult = Object.assign({}, noteDummyData);
    chartData = undefined;
    copyResult = undefined;
    duplicateValues = undefined;
    doAnalysisState = LoadingState.READY;
    convertedText = undefined;
    convertedFrequency = undefined;
    average = undefined;
    average1 = undefined;
    average2 = undefined;
    average3 = undefined;
    average4 = undefined;
    average5 = undefined;
    average6 = undefined;
    analysisList = undefined;

    explains = {};
    opinions = {};
    scores = {};

    init;

    initPaperGroupCorrections = () => {
        this.paperGroupCorrections = [];
    };

    getLatestCorrection = (selectedPaperGroup, userId) => {
        if (!selectedPaperGroup || !this.paperGroupCorrections.length) {
            return undefined;
        }
        console.log('selectedPaperGroup : >> ', selectedPaperGroup);
        console.log('userId : >> ', userId);

        const correctionsByUser = this.paperGroupCorrections.filter(
            correction => correction.userId === userId && correction.paperId === selectedPaperGroup.id,
        );
        console.log('paperGroupCorrections : >> ', this.paperGroupCorrections);
        console.log('correctionsByUser : >> ', correctionsByUser);
        if (!correctionsByUser.length) {
            return undefined;
        }

        return correctionsByUser.reduce((prevValue, currentValue) => {
            return dayjs(prevValue.createdDatetime) > dayjs(currentValue.createdDatetime) ? prevValue : currentValue;
        });
    };

    initGroupInvitees = () => {
        this.groupInvitees = [];
    };

    initGroupFolderAndFiles = () => {
        this.groupFolderAndFiles = [];
        this.isEndFolderAndFiles = false;
    };

    setSelectedInviteExpireDatetime = expireDatetime => {
        this.selectedInviteExpireDatetime = expireDatetime;
    };

    setSelectedGroupDetail = group => {
        this.selectedGroupDetail = group;
    };

    resetSelectedGroupUsers = groupUsers => {
        console.log(logPrefix, 'resetSelectedGroupUsers : >> ', groupUsers);
        this.selectedGroupUsers = groupUsers.slice();
    };

    resetSelectedGroupMembersAndOperators = groupUsers => {
        console.log(logPrefix, 'resetSelectedGroupUsers : >> ', groupUsers);
        this.selectedGroupMemberAndOperators = groupUsers.slice();
    };

    addSelectedGroupUsers = groupUsers => {
        console.log(logPrefix, 'addSelectedGroupUsers : >> ', groupUsers);
        if (groupUsers.length) {
            this.selectedGroupUsers = this.selectedGroupUsers.concat(groupUsers);
        }
    };

    initSelectedGroupUsers = () => {
        this.selectedGroupUsers = [];
        this.selectedGroupUsersTotalCount = 0;
    };

    initSelectedGroupMemberAndOperators = () => {
        this.selectedGroupMemberAndOperators = [];
        this.selectedGroupMemberAndOperatorsTotalCount = 0;
    };

    setSelectedForcedLeaveGroupUserEmail = groupUserEmail => {
        this.selectedForcedLeaveGroupUserEmail = groupUserEmail;
    };

    setSelectedGroupId = groupId => {
        this.selectedGroupId = groupId;
    };

    setGroupAuthorityMap = authorities => {
        console.log(logPrefix, 'Start setGroupAuthorityMap : >> ', this.groupAuthorities);
        const authorityMap = {};
        authorities.forEach(a => {
            authorityMap[a.featureKey] = a.authority;
        });
        // this.groupAuthorities.forEach(a => {
        //     authorityMap[a.featureKey] = a.authority;
        // });
        this.groupAuthorityMap = { ...authorityMap };
        console.log(logPrefix, 'setGroupAuthorityMap result = ', this.groupAuthorityMap);
    };

    setGroupUserAuthority = (loginUserId, authorities) => {
        console.log(logPrefix, 'Start setGroupUserAuthority : >> ', authorities);
        const authorityMap = {};
        authorities.forEach(a => {
            if (a.authority === GroupAuthorityTypeName.Operator) {
                authorityMap[a.featureKey] = [GroupAuthorityTypeName.Leader, GroupAuthorityTypeName.Operator].slice();
            } else if (a.authority === GroupAuthorityTypeName.Member) {
                authorityMap[a.featureKey] = [GroupAuthorityTypeName.Leader, GroupAuthorityTypeName.Operator, GroupAuthorityTypeName.Member].slice();
            } else {
                authorityMap[a.featureKey] = [a.authority].slice();
            }
        });
        const findMe = this.selectedGroupDetail.lmsUser.find(user => user.userId === loginUserId);
        if (findMe) {
            for (const [key, values] of Object.entries(authorityMap)) {
                this.groupUserAuthority = { ...this.groupUserAuthority, [key]: values.includes(findMe.type) };
            }
        }
    };

    get invitees() {
        return this.groupInvitees.slice();
    }

    get isInvitationLoading() {
        return this.invitationLoadingState === LoadingState.PENDING;
    }

    get isEndGroupUsers() {
        return this.selectedGroupUsersTotalCount === this.selectedGroupUsers.length;
    }

    get isFolderAndFilesLoading() {
        return this.groupFolderAndFilesLoadingState === LoadingState.PENDING;
    }

    get isGroupUserLoading() {
        return this.groupUserLoadingState === LoadingState.PENDING;
    }

    get allGroupUsers() {
        return this.selectedAllGroupUsers.slice();
    }

    get isWaitJoinApproval() {
        return this.joinApprovalState === GroupJoinApprovalState.Wait;
    }

    get isGroupJoinApproval() {
        return this.selectedGroupDetail.group.joinApproval && this.selectedGroupDetail.group.joinApproval;
    }

    // get isInviteUser() {
    //     let isInvite = false;
    //     // const inviteToken = localStorage.getItem(SessionStorageGroupInviteToken);
    //     // if (this.inviteToken) {
    //     //     isInvite = true;
    //     // }
    //     // return isInvite;
    //
    //     return Boolean(this.inviteToken);
    // }

    get isCheckJoinApprovalLoading() {
        return this.checkJoinApprovalLoading === LoadingState.PENDING;
    }

    get isGroupListLoading() {
        return this.groupListLoading === LoadingState.PENDING;
    }

    get isGroupAuthorityLoading() {
        return this.groupAuthorityLoading === LoadingState.PENDING;
    }

    get isSearchGroupListLoading() {
        return this.searchGroupListLoading === LoadingState.PENDING;
    }

    get isEditable() {
        return this.noteEditable === true;
    }

    initModifyGroup = () => {
        this.class = { ...EmptyClass };
        this.tagList = Array(3).fill({ ...EmptyTag });
    };

    changeGroupColor = color => {
        this.class = { ...this.class, color: color };
    };

    initTag = () => {
        this.detailTagList = [];
    };

    changeEditPublicGroup = type => {
        this.editPublicGroup = type;
    };

    setSearchRooms = rooms => {
        this.searchRooms = [...rooms];
    };

    setGroupTeams = teams => {
        this.selectedGroupDetail = { ...this.selectedGroupDetail, teamList: teams };
    };

    get editTags() {
        return [...this.tagList];
    }

    get editGroup() {
        return { ...this.class };
    }

    get editGroupInfo() {
        const editGroup = { ...EmptyGroupTransfer, group: this.class, tagList: this.tagList, image: this.groupImage };

        return editGroup;
    }

    get isModifyGroup() {
        const originGroupDetail = { ...this.selectedGroupDetail };
        const originTagList = [...this.detailTagList];
        const tagNames = this.tagList.map(tag => tag.name).join('');
        const isEditName = this.class.name !== originGroupDetail.group.name;
        const isEditTag = tagNames !== originTagList.map(tag => tag.name).join('');
        const isEditColor = this.class.color !== originGroupDetail.group.color;
        const isEditaImage = this.groupImage !== originGroupDetail.image;

        return this.class.name && tagNames && (isEditTag || isEditColor || isEditaImage || isEditName);
    }

    get groupLeaderAndOperators() {
        return this.selectedGroupDetail.lmsUser.filter(
            member => member.type === LmsGroupUserType.leader || member.type === LmsGroupUserType.operator,
        );
    }

    get groupLeaderAndOperatorsFromSelectedGroupUsers() {
        return this.selectedGroupUsers.filter(member => member.type === LmsGroupUserType.leader || member.type === LmsGroupUserType.operator);
    }

    get groupAllMembers() {
        const members = this.selectedGroupDetail.lmsUser.filter(member => member.type === LmsGroupUserType.member);
        return members;
    }

    get groupAllMemberAndOperators() {
        const members = this.selectedGroupDetail.lmsUser.filter(member => member.type !== LmsGroupUserType.leader);
        return members;
    }

    get groupUsers() {
        return this.selectedGroupUsers.slice();
    }

    get groupMembers() {
        return this.selectedGroupUsers.filter(member => member.type === LmsGroupUserType.member);
    }

    get groupMemberAndOperators() {
        return this.selectedGroupUsers.filter(member => member.type !== LmsGroupUserType.leader);
    }

    get groupLeader() {
        // const leader = this.selectedGroupUsers.find(member => member.type === LmsGroupUserType.leader);
        // return leader;

        const leader = this.selectedGroupDetail.lmsUser.find(member => member.type === LmsGroupUserType.leader);
        return leader;
    }

    get isGroupNameValidation() {
        const target = this.class.name;
        return target !== null && target !== '' && target !== undefined;
    }

    get isGroupTagValidation() {
        const target = this.tagList.map(tag => tag.name).join('');
        return target !== null && target !== '';
    }

    initFolderBreadcrumbs = () => {
        this.folderBreadcrumbs = [];
    };

    setNotice = notice => {
        this.selectedNotice = notice;
    };

    addFolderBreadcrumb = (folderId, folderName, callbacks) => {
        const selectedFolderIndex = this.folderBreadcrumbs.findIndex(folder => folder.id === this.selectedFolderId);

        if (this.folderBreadcrumbs.length < ALLOWED_FOLDER_BREADCRUMBS_LENGTH && selectedFolderIndex < 0) {
            this.folderBreadcrumbs.push({
                id: folderId,
                name: folderName,
                func: folderId => {
                    this.changeSelectedFolderId(folderId);
                    this.removeFolderBreadcrumb();
                    this.getGroupFolderAndFiles({
                        changePage: () => {
                            callbacks.changePage();
                        },
                    });
                },
            });
        }
    };

    removeFolderBreadcrumb = () => {
        console.log('do removeFolderBreadcrumb : >> ', this.selectedFolderId);
        const selectedFolderIndex = this.folderBreadcrumbs.findIndex(folder => folder.id === this.selectedFolderId);
        console.log('remove index : >> ', selectedFolderIndex, this.folderBreadcrumbs.length - 1 - selectedFolderIndex);
        if (selectedFolderIndex !== -1) {
            this.folderBreadcrumbs.splice(selectedFolderIndex + 1, this.folderBreadcrumbs.length - 1 - selectedFolderIndex);
            // this.folderBreadcrumbs = this.folderBreadcrumbs.slice(0, selectedFolderIndex + 1);
        }
    };

    changeDeleteFolderId = id => {
        this.deleteFolderId = id;
    };

    initFolderLoadResult = () => {
        this.folderLoadState = LoadingState.READY;
        this.folderLoadResultDialogMsgId = CLASS_STORE_MESSAGE_ID.Default.empty;
    };

    changeFolderLoadResultDialogMsgId = id => {
        this.folderLoadResultDialogMsgId = id;
    };

    changeFolderLoadState = state => {
        this.folderLoadState = state;
    };

    changeNoteText = (value) => {
        this.noteEvaluationResult.editText = value;
    }

    setNoteEditable = (bool) => {
        this.noteEditable = bool;
    }

    setChartData = () => {
        console.log("setChartData start...");
        // TODO : 미구현 기능 - 해당 데이터가 들어오는 key 값 재확인 필요
        this.chartData = [
            {
                "id": "어휘력",
                "data": [
                    {
                        "x": "어휘력",
                        "y": 50
                        // "y": this.copyResult["accuracy"]["어휘력"]
                    },
                ]
            },
            {
                "id": "정확성",
                "data": [
                    {
                        "x": "정확성",
                        "y": 60
                        // "y": this.copyResult["accuracy"]["정확성"]
                    },
                ]
            },
            {
                "id": "일관성",
                "data": [
                    {
                        "x": "일관성",
                        "y": 70
                        // "y": this.copyResult["accuracy"]["일관성"]
                    },
                ]
            },
            {
                "id": "유창성",
                "data": [
                    {
                        "x": "유창성",
                        "y": 47
                        // "y": this.copyResult["accuracy"]["유창성"]
                    },
                ]
            },
            {
                "id": "간결성",
                "data": [
                    {
                        "x": "간결성",
                        "y": 58
                        // "y": this.copyResult["accuracy"]["간결성"]
                    },
                ]
            },
            {
                "id": "결속성",
                "data": [
                    {
                        "x": "결속성",
                        "y": 39
                        // "y": this.copyResult["accuracy"]["결속성"]
                    },
                ]
            }
        ]
    }

    initSelectedGroupFolder = () => {
        this.selectedFolderId = 0;
        this.groupRootFolderId = 0;
    };

    initGroupReferenceRoom = () => {
        // this.folderBreadcrumbs = [];
        this.groupFolderAndFiles = [];
        this.isCreateFolder = false;
        this.createFolderName = '';
        this.isEditFolderName = '';
        this.folderLoadState = LoadingState.READY;
        this.folderLoadResultDialogMsgId = CLASS_STORE_MESSAGE_ID.Default.empty;
        this.editFolderId = 0;
        this.deleteFolderId = 0;
    };

    isGroupMember = userId => {
        let isGroupMember = false;
        const me = this.selectedGroupDetail.lmsUser.find(user => user.userId === userId);
        if (me) {
            isGroupMember = true;
        }
        return isGroupMember;
    };

    isGroupLeader = userId => {
        let isLeader = false;
        const leader = this.selectedGroupDetail.lmsUser.find(user => user.type === LmsGroupUserType.leader);
        if (leader) {
            isLeader = leader.userId === userId;
        }
        return isLeader;
    };

    changeCreateFolder = isCreate => {
        this.isCreateFolder = isCreate;
    };

    initEditFolder = () => {
        this.editFolderId = 0;
        this.editFolderName = '';
    };

    changeEditFolderId = selectedId => {
        this.editFolderId = selectedId;
    };

    changeSelectedFolderId = selectedId => {
        console.log(logPrefix, 'changeSelectedFolderId = ', selectedId);
        this.selectedFolderId = selectedId;
    };

    changeEditFolderName = folderName => {
        console.log(logPrefix, 'changeModifyFolderFileName : >> ', folderName);
        this.editFolderName = folderName;
    };

    changeCreateFolderName = folderName => {
        this.createFolderName = folderName;
    };

    initClass = () => {
        this.class = Object.assign({}, EmptyClass);
        this.groupImage = '';
        this.selectedGroupDetail = Object.assign({}, EmptyGroupTransfer);
        this.groupList = [];
        this.groupCurrentCount = 0;
        this.classSortType = ClassSortType.create;
        this.detailTagList = [];
        this.tagList = Array(3).fill({ ...EmptyTag });
        //extension
        this.inviteGroupList = [];
    };

    initSearchClass = () => {
        this.searchGroupList = [];
    };

    initNotice = () => {
        this.groupNotice = Object.assign({}, EmptyGroupNotice);
    };

    selectRoomType = type => {
        // this.class.type = LmsGroupType[type];
        this.class.type = LmsGroupType.study;
    };

    changeGroupName = name => {
        this.class.name = name;
    };

    changeGroupComment = comment => {
        this.class.comment = comment;
    };

    changeIsPublicGroup = value => {
        this.class.public = value;
    };

    changeTag = e => {
        const tagIndex = parseInt(e.target.id);
        console.log(logPrefix, 'changeTag target id = ', parseInt(e.target.id));
        console.log(logPrefix, 'changeTag target id = ', this.tagList);
        if (this.tagList.length >= tagIndex + 1) {
            console.log(logPrefix, 'find target tag = ', this.tagList[e.target.id]);
            this.tagList[tagIndex].name = e.target.value;
        } else {
            const t = _.cloneDeep(this.tag);
            t.name = e.target.value.trim();
            this.tagList[tagIndex] = t;
        }

        console.log(logPrefix, 'changeTag result = ', this.tagList);
    };

    changeGroupImage = image => {
        this.groupImage = image;
    };

    /** Notice **/
    changeGroupNoticeTitle = title => {
        this.groupNotice.title = title;
    };

    changeGroupEditNoticeTitle = title => {
        this.editNotice.title = title;
    };
    changeGroupNoticeContent = content => {
        this.groupNotice.content = content;
    };

    changeGroupEditNoticeContent = content => {
        this.editNotice.content = content;
    };

    get isInitializingGroupDetail() {
        return this.groupDetailInitializeState !== LoadingState.READY;
    }

    get isDoAnalysisLoading() {
        return this.doAnalysisState !== LoadingState.READY;
    }

    get isGroupModifyLoading() {
        return this.groupModifyLoading !== LoadingState.READY;
    }

    get findSearchRooms() {
        return this.searchRooms.slice();
    }

    get findUserIdsInLiveRooms() {
        return this.userIdsInLivRooms.slice();
    }

    get findFolderBreadcrumbs() {
        return this.folderBreadcrumbs.slice(-2);
    }

    get isFolderLoadResultDialogOpen() {
        return this.folderLoadState === LoadingState.FAILED;
    }

    get isImageLoading() {
        return this.imageLoading === LoadingState.PENDING;
    }

    get isSaveNotice() {
        if (this.groupNotice.title !== '' && this.groupNotice.content !== '') {
            return true;
        } else {
            return false;
        }
    }

    get isEditSaveNotice() {
        if (this.editNotice.title !== '' && this.editNotice.content !== '') {
            return true;
        } else {
            return false;
        }
    }

    get isExistFolder() {
        let result = false;
        const checkFolderName = this.isCreateFolder ? this.createFolderName : this.editFolderName;
        const folders = this.groupFolderAndFiles.filter(data => data.fileId === null && Boolean(GROUP_FOLDER_TYPE[data.type]));
        if (folders.length) {
            const findExistFolder = folders.find(folder => folder.name === checkFolderName);
            result = !!findExistFolder;
        }
        return result;
    }

    get inviteUrl() {
        return this.invite.url;
    }

    get isExpiredInvitation() {
        return !Boolean(this.inviteToken);
    }

    setEditNotice = notice => {
        this.editNotice = cloneDeep(notice);
    };

    /** Notice END **/

    onClickGroupHideList = () => {
        this.simpleGroupListDummy = cloneDeep(this.simpleGroupList);
    };

    changeHideGroupList = groupId => {
        this.simpleGroupListDummy.map(g => {
            if (g.id === groupId) {
                return (g.hideGroup = !g.hideGroup);
            }
        });
    };

    handleFoldBtn = () => {
        this.groupList.splice(6);
        this.groupCurrentCount = 6;
    };

    changeSortType = (value, callback) => {
        this.classSortType = value;
        this.groupList = [];
        this.groupCurrentCount = 0;
        callback && callback();
    };

    convertTagList = data => {
        this.detailTagList = [];
        data.map(tag => {
            if (!this.detailTagList.find(t => t.name === tag.name)) {
                tag.idList = [];
                tag.idList.push(tag.tagId);
                this.detailTagList.push(tag);
            } else {
                this.detailTagList.find(t => t.name === tag.name).idList.push(tag.tagId);
            }
        });
        console.log(logPrefix, 'convertTagList end... detailTagList', this.detailTagList);
    };

    sortingList = () => {
        console.log(logPrefix, 'changeSortType staring... this.classSortType={}', this.classSortType);
        if (this.classSortType === ClassSortType.create) {
            this.groupList = this.groupList.slice().sort(function (a, b) {
                return new Date(a.group.createdDatetime) - new Date(b.group.createdDatetime);
            });
        } else if (this.classSortType === ClassSortType.update) {
            this.groupList = this.groupList.slice().sort(function (a, b) {
                return new Date(b.group.updatedDatetime) - new Date(a.group.updatedDatetime);
            });
        } else {
            this.groupList = this.groupList.slice().sort(function (a, b) {
                let x = a.group.name;
                let y = b.group.name;
                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
                return 0;
            });
        }
    };

    isInputValidation = () => {
        let checkInput = true;
        const tagNameList = this.tagList.filter(tag => tag.name !== '').map(tag => tag.name);
        const set = new Set(tagNameList);
        if (this.class.name === '') {
            checkInput = false;
            this.errMsg = '모임의 이름을 입력하세요.';
        } else if (tagNameList.length !== set.size) {
            checkInput = false;
            this.errMsg = '중복된 태그는 사용할 수 없습니다.';
        }
        this.openConfirmDialog = !checkInput;
        return checkInput;
    };

    closeConfirmDialog = () => {
        this.openConfirmDialog = false;
    };

    *getGroupList(loginUser, type) {
        console.log(logPrefix, 'getGroupList starting... loginUser={}, type={}', loginUser, type);
        this.groupListLoading = LoadingState.PENDING;
        try {
            if (this.groupCurrentCount === 0) this.groupListFirstLoading = LoadingState.PENDING;
            const groupListCount = yield axios.get(this.serverContextPath + `/api/v1/group/count/${loginUser.id}`);
            this.groupListCount = groupListCount.data;
            let url = this.serverContextPath + `/api/v1/group/${loginUser.id}?count=${this.groupCurrentCount}&sortType=${this.classSortType}`;
            if (type) {
                url = url + `&type=${type}`;
            }
            const response = yield axios.get(url);
            console.log(logPrefix, 'getGroupList.response={}', response.data);
            if (type !== undefined && type === GroupClassType.MyClass) {
                this.classGroupList = response.data;
            } else {
                this.groupList = [...new Set([...this.groupList, ...response.data])];
                this.groupCurrentCount = this.groupList.length;
            }
            this.groupListLoading = LoadingState.SUCCESS;
            this.groupListFirstLoading = LoadingState.SUCCESS;
        } catch (e) {
            console.log(logPrefix, 'getGroupList Failed...');
        }
    }

    *getInviteGroupList(loginUser) {
        console.log(logPrefix, 'getInviteGroupList starting... loginUser={}', loginUser);
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/invite/list/${loginUser.id}`);
            console.log(logPrefix, 'getInviteGroupList.response={}', response.data);
            this.inviteGroupList = response.data;
        } catch (e) {
            console.log(logPrefix, 'getGroupList Failed...');
        }
    }

    *joinGroup(userId, requestPath, callbacks) {
        console.log(logPrefix, 'JoinClass starting... userId={}, group={}', userId, this.selectedGroupDetail);
        try {
            const response = yield axios.post(
                this.serverContextPath + `/api/v1/group/${this.selectedGroupDetail.group.id}/join/${userId}`,
                {},
                {
                    params: { requestPath: requestPath },
                },
            );
            console.log(logPrefix, 'JoinClass.response={}', response.data);
            if (response.data) {
                /** 바로가입 msg */
                this.joinApprovalState = GroupJoinApprovalState.Wait;
                callbacks && callbacks.alert();
            } else {
                /** 관리자 승인 대기 msg */
                callbacks && callbacks.openJoinSuccessDialog();
            }
        } catch (e) {
            console.log(logPrefix, 'JoinClass Failed...', e);
        } finally {
            callbacks && callbacks.initClass && callbacks.initClass();
            callbacks && callbacks.getGroupList && callbacks.getGroupList();
            callbacks && callbacks.getHideGroupList && callbacks.getHideGroupList();
            callbacks && callbacks.getInviteGroupList && callbacks.getInviteGroupList();
            callbacks && callbacks.requestGroupDetailAndCheckMember && callbacks.requestGroupDetailAndCheckMember(this.selectedGroupDetail.group.id);
        }
    }

    *getGroupListByKeyword(keyword, callback) {
        console.log(logPrefix, 'getGroupListByKeyword starting... keyword={}', keyword);
        this.searchGroupListLoading = LoadingState.PENDING;

        try {
            if (keyword === null || keyword === '') {
                callback && callback();
                this.searchGroupListLoading = LoadingState.FAILED;
                return false;
            }
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/list/${keyword}`);
            console.log(logPrefix, 'getGroupList.response={}', response.data);
            this.searchGroupList = response.data;
            callback && callback.moveToSearchPage();
            callback && callback.getRoomList();
            callback && callback.getPostList();
            // this.imageLoading = LoadingSate.PENDING;
            // this.imageLoading = LoadingSate.SUCCESS;
            this.searchGroupListLoading = LoadingState.SUCCESS;
        } catch (e) {
            this.searchGroupListLoading = LoadingState.FAILED;
            console.log(logPrefix, 'getGroupList Failed...');
        }
    }

    // *getGroupImage(groupId) {
    //     console.log(logPrefix, 'getGroupImage starting... groupId={}', groupId);
    //     try {
    //         const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/image`);
    //         return response.data;
    //     } catch (e) {
    //         console.log(logPrefix, 'getGroupImage Failed...');
    //     }
    // }

    *getHideGroupList(userId) {
        console.log(logPrefix, 'getHideGroupList starting... userId={} ', userId);
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/hide/${userId}`);
            console.log(logPrefix, 'getHideGroupList.response={}', response.data);
            this.simpleGroupList = response.data;
        } catch (e) {
            console.log(logPrefix, 'getHideGroupList Failed...');
        }
    }

    *getGroupDetail(groupId, callback) {
        console.log(logPrefix, 'getGroupDetail starting... groupId={} ', groupId);
        this.groupDetailInitializeState = LoadingState.PENDING;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/detail/${groupId}`);
            console.log(logPrefix, 'getGroupDetail.response={}', response.data);

            this.selectedGroupDetail = response.data;
            this.groupRootFolderId = response.data.rootFolderId;
            callback && callback.checkMember();
            callback && callback.getGroupAuthority();
            callback && callback.setGroupColor && callback.setGroupColor(response.data.group.color);
            this.groupDetailInitializeState = LoadingState.SUCCESS;
        } catch (e) {
            console.log(logPrefix, 'getGroupDetail Failed...');
            this.groupDetailInitializeState = LoadingState.FAILED;
        } finally {
            this.groupDetailInitializeState = LoadingState.READY;
        }
    }

    *getTagList(groupId, type) {
        console.log(logPrefix, 'getTagList starting... groupId={}, type={}', groupId, type);
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/tag/${groupId}/${type}`);
            this.convertTagList(response.data);
        } catch (e) {
            console.log(logPrefix, 'getTagList Failed...');
        }
    }

    *createGroup(loginUser, callback) {
        console.log(logPrefix, 'createGroup starting...');
        try {
            if (!this.isInputValidation()) {
                return false;
            }
            this.class.name = this.class.name.trim();
            const param = {
                group: this.class,
                lmsUser: [{ userId: loginUser.id, type: 'Leader' }],
                tagList: this.tagList.map(t => {
                    t.name = t.name.trim();
                    return t;
                }),
                image: this.groupImage,
            };
            const response = yield axios.post(this.serverContextPath + '/api/v1/group/create', param);
            console.log(logPrefix, 'createGroup response...', response);

            callback && callback.moveTo();
            callback && callback.initCoachMarkState();
        } catch (e) {
            console.log(logPrefix, 'createGroup Failed...', e);
        }
    }

    *changeHideGroup(loginUser, callback) {
        console.log(logPrefix, 'changeHideGroup starting...loginUser={}', loginUser);
        this.changeHideGroupLoading = LoadingState.PENDING;
        try {
            const param = this.simpleGroupListDummy;
            const response = yield axios.post(this.serverContextPath + `/api/v1/group/${loginUser.id}/hide?sortType=${this.classSortType}`, param);
            console.log('response.data', response.data);
            this.groupList = [];
            this.groupCurrentCount = 0;
            this.getGroupList(loginUser);
            this.getHideGroupList(loginUser.id);
            this.changeHideGroupLoading = LoadingState.SUCCESS;

            callback && callback();
        } catch (e) {
            console.log(logPrefix, 'changeHideGroup Failed...');
        }
    }

    *createGroupNotice(loginUser) {
        console.log(logPrefix, 'createGroupNotice starting...loginUser={}', loginUser);
        try {
            const param = Object.assign({}, this.groupNotice);
            param.groupId = this.selectedGroupDetail.group.id;
            param.userId = loginUser.id;
            const response = yield axios.post(this.serverContextPath + '/api/v1/group/notice/create', param);
            console.log(logPrefix, 'createGroupNotice.response.data', response.data);
            this.selectedGroupDetail.noticeList = response.data;
            this.groupNotice = Object.assign({}, EmptyGroupNotice);
        } catch (e) {
            console.log(logPrefix, 'createGroupNotice Failed...');
        }
    }

    *modifyGroupNotice(loginUser) {
        console.log(logPrefix, 'modifyGroupNotice starting...loginUser={}', loginUser);
        try {
            const param = Object.assign({}, this.editNotice);
            param.groupId = this.selectedGroupDetail.group.id;
            param.userId = loginUser.id;
            const response = yield axios.put(this.serverContextPath + '/api/v1/group/notice/modify', param);
            console.log(logPrefix, 'modifyGroupNotice.response.data', response.data);
            this.selectedGroupDetail.noticeList = response.data;
        } catch (e) {
            console.log(logPrefix, 'modifyGroupNotice Failed...');
        }
    }

    *deleteGroupNotice() {
        console.log(logPrefix, 'deleteGroupNotice starting... notice={}', this.selectedNotice);
        try {
            const response = yield axios.put(
                this.serverContextPath + `/api/v1/group/notice/delete/${this.selectedNotice.groupId}/${this.selectedNotice.noticeId}`,
            );
            console.log(logPrefix, 'deleteGroupNotice.response.data', response.data);
            this.selectedGroupDetail.noticeList = response.data;
        } catch (e) {
            console.log(logPrefix, 'deleteGroupNotice Failed...');
        }
    }

    *getGroupFolderAndFiles(callbacks, page = DEFAULT_PAGE, rowsPerPage = DEFAULT_ROWS_PER_PAGE) {
        console.log(logPrefix, `getGroupFolderFiles starting... groupId=${this.selectedGroupId}, page=${page}, rowsPerPage=${rowsPerPage}`);
        const groupId = this.selectedGroupId;
        const folderId = this.selectedFolderId;

        this.groupFolderAndFilesLoadingState = LoadingState.PENDING;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/folder/${folderId}`, {
                params: { page: page, rowsPerPage: rowsPerPage },
            });
            console.log(logPrefix, 'Success getGroupFolderFiles result = ', response.data);
            if (page > 0) {
                this.groupFolderAndFiles = this.groupFolderAndFiles.concat(response.data);
            } else {
                this.groupFolderAndFiles = response.data;
            }

            if (response.data.length) {
                if (this.isEndFolderAndFiles) {
                    this.isEndFolderAndFiles = false;
                }
                const nextPage = page + 1;
                callbacks && callbacks.changePage(nextPage);
            } else {
                if (!this.isEndFolderAndFiles) {
                    this.isEndFolderAndFiles = true;
                }
            }

            this.groupFolderAndFilesLoadingState = LoadingState.SUCCESS;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getGroupFolderFiles', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getGroupFolderFiles', e.request);
            } else {
                console.log(logPrefix, 'Failed getGroupFolderFiles', e.message);
            }
            this.groupFolderAndFilesLoadingState = LoadingState.FAILED;
        } finally {
            this.groupFolderAndFilesLoadingState = LoadingState.READY;
        }
    }

    *createGroupFileFolder(callbacks) {
        console.log(logPrefix, 'createGroupFolder starting...');
        this.folderLoadState = LoadingState.PENDING;

        const groupId = this.selectedGroupDetail.group.id;
        const folderId = this.selectedFolderId;
        const folderName = this.createFolderName;

        if (!validateFileOrFolderName(folderName)) {
            this.folderLoadResultDialogMsgId = CLASS_STORE_MESSAGE_ID.Error.not_allowed_folder_name;
            this.folderLoadState = LoadingState.FAILED;
            return false;
        }
        try {
            const response = yield axios.post(this.serverContextPath + `/api/v1/group/${groupId}/folder/${folderId}/${folderName}`);
            console.log(logPrefix, 'Success createGroupFolder');
            console.log(logPrefix, 'Result = ', response.data);
            const { fileFolder, folderAndFiles } = response.data;
            this.groupFolderAndFiles = folderAndFiles;
            this.selectedFolderId = fileFolder.fileFolderId;
            this.addFolderBreadcrumb(fileFolder.fileFolderId, fileFolder.name);
            this.folderLoadState = LoadingState.SUCCESS;
            callbacks && callbacks.initPagination();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed createGroupFolder', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
                if (e.response.data.errorCode === ApiErrorCode.NotAcceptableId) {
                    this.folderLoadResultDialogMsgId = CLASS_STORE_MESSAGE_ID.Error.exist_folder_name;
                }
            } else if (e.request) {
                console.log(logPrefix, 'Failed createGroupFolder', e.request);
            } else {
                console.log(logPrefix, 'Failed createGroupFolder', e.message);
            }
            this.folderLoadState = LoadingState.FAILED;
        } finally {
            callbacks.closeCreateFolder();
        }
    }

    *deleteGroupFileFolder(callbacks) {
        console.log(logPrefix, 'deleteGroupFolder starting...');
        const groupId = this.selectedGroupDetail.group.id;
        const folderId = this.deleteFolderId;
        try {
            const response = yield axios.put(this.serverContextPath + `/api/v1/group/${groupId}/folder/delete/${folderId}`);
            console.log('Success deleteGroupFileFolder = {}', response.data);
            // this.groupFolderAndFiles = response.data;

            callbacks && callbacks.getGroupFolderAndFiles();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed deleteGroupFolder', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed deleteGroupFolder', e.request);
            } else {
                console.log(logPrefix, 'Failed deleteGroupFolder', e.message);
            }
        }
    }

    *modifyGroupFileFolder(callbacks) {
        this.folderLoadState = LoadingState.PENDING;
        console.log(logPrefix, 'modifyGroupFileFolder starting...');
        const groupId = this.selectedGroupDetail.group.id;
        const folderId = this.editFolderId;
        const folderName = this.editFolderName;

        if (!validateFileOrFolderName(folderName)) {
            this.folderLoadResultDialogMsgId = CLASS_STORE_MESSAGE_ID.Error.not_allowed_folder_name;
            this.folderLoadState = LoadingState.FAILED;
            return false;
        }

        try {
            const response = yield axios.put(this.serverContextPath + `/api/v1/group/${groupId}/folder/modify/${folderId}/${folderName}`);
            console.log('Success modifyGroupFileFolder = {}', response.data);
            // this.groupFolderAndFiles = response.data;
            callbacks && callbacks.getGroupFolderAndFiles();
            this.folderLoadState = LoadingState.SUCCESS;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed modifyGroupFileFolder', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
                if (e.response.data.errorCode === ApiErrorCode.NotAcceptableId) {
                    this.folderLoadResultDialogMsgId = CLASS_STORE_MESSAGE_ID.Error.exist_folder_name;
                }
            } else if (e.request) {
                console.log(logPrefix, 'Failed modifyGroupFileFolder', e.request);
            } else {
                console.log(logPrefix, 'Failed modifyGroupFileFolder', e.message);
            }
            this.folderLoadState = LoadingState.FAILED;
        } finally {
            callbacks && callbacks.closeModifyFolder();
        }
    }

    *getUserIdsInLiveRooms(groupId, callbacks) {
        console.log(logPrefix, 'Start getUserIdsInCurrentTimeRoom groupId = {}', groupId);

        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/users/incurrentroom`);
            console.log(logPrefix, 'Success getUserIdsInCurrentTimeRooms');
            console.log(logPrefix, 'Result = ', response.data);
            this.userIdsInLivRooms = response.data;
            if (!this.userIdsInLivRooms.length) {
                callbacks && callbacks.alertMessage();
            }
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getUserIdsInCurrentTimeRoom', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getUserIdsInCurrentTimeRoom', e.request);
            } else {
                console.log(logPrefix, 'Failed getUserIdsInCurrentTimeRoom', e.message);
            }
        }
    }

    *modifyGroupOpenState(groupId, userEmail, callbacks) {
        console.log(logPrefix, 'Start modifyGroupOpenState groupId = {}', groupId, userEmail);
        const params = this.editPublicGroup;
        this.groupModifyLoading = LoadingState.PENDING;
        try {
            const response = yield axios.put(
                this.serverContextPath + `/api/v1/group/${groupId}/openstate/${userEmail}`,
                {},
                {
                    params: { openType: params },
                },
            );
            this.selectedGroupDetail = response.data;
            this.groupModifyLoading = LoadingState.SUCCESS;
            callbacks && callbacks.closeRoomOpenDialog();
        } catch (e) {
            this.groupModifyLoading = LoadingState.FAILED;
            if (e.response) {
                console.log(logPrefix, 'Failed modifyGroupOpenState', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed modifyGroupOpenState', e.request);
            } else {
                console.log(logPrefix, 'Failed modifyGroupOpenState', e.message);
            }
        } finally {
            this.groupModifyLoading = LoadingState.READY;
        }
    }

    *modifyGroupInfo(userEmail, callbacks) {
        console.log(logPrefix, 'Start modifyGroupInfo userEmail = {}', userEmail);
        this.groupModifyLoading = LoadingState.PENDING;
        if (!this.isInputValidation()) {
            return false;
        }
        const { lmsUser, ...params } = EmptyGroupTransfer;
        params.group = this.class;
        params.image = this.groupImage;
        params.tagList = this.tagList;
        console.log('modifyGroupInfo setParams :>> ', params);

        try {
            const response = yield axios.put(this.serverContextPath + `/api/v1/group/modify/${userEmail}`, params);

            callbacks && callbacks.moveToPrevPage();
            this.groupModifyLoading = LoadingState.SUCCESS;
        } catch (e) {
            this.groupModifyLoading = LoadingState.FAILED;
            if (e.response) {
                console.log(logPrefix, 'Failed modifyGroupInfo', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed modifyGroupInfo', e.request);
            } else {
                console.log(logPrefix, 'Failed modifyGroupInfo', e.message);
            }
        } finally {
            this.groupModifyLoading = LoadingState.READY;
        }
    }

    *getGroupInfo(groupId, userEmail) {
        console.log(logPrefix, `getGroupInfo starting... groupId = ${groupId}`);
        this.groupListLoading = LoadingState.PENDING;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/info/${userEmail}`);

            console.log(logPrefix, 'getGroupInfo.response={}', response.data);
            this.class = response.data.group;
            this.tagList = response.data.tagList;
            this.groupImage = response.data.image;
            this.groupListLoading = LoadingState.SUCCESS;
        } catch (e) {
            this.groupListLoading = LoadingState.FAILED;
            if (e.response) {
                console.log(logPrefix, 'Failed getGroupInfo', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getGroupInfo', e.request);
            } else {
                console.log(logPrefix, 'Failed getGroupInfo', e.message);
            }
        } finally {
            this.groupListLoading = LoadingState.READY;
        }
    }

    *modifyGroupJoinApprovalState(groupId, userEmail, callbacks) {
        console.log(logPrefix, `Start modifyGroupJoinApprovalState groupId=${groupId}, userEmail=${userEmail}`);
        this.groupModifyLoading = LoadingState.PENDING;
        try {
            const response = yield axios.put(this.serverContextPath + `/api/v1/group/${groupId}/modify/join/approval/${userEmail}`);

            console.log(logPrefix, 'Success modifyGroupJoinApprovalState', response.data);
            this.selectedGroupDetail = response.data;
            this.groupModifyLoading = LoadingState.SUCCESS;
        } catch (e) {
            this.groupModifyLoading = LoadingState.FAILED;
            if (e.response) {
                console.log(logPrefix, 'Failed modifyGroupJoinApprovalState', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed modifyGroupJoinApprovalState', e.request);
            } else {
                console.log(logPrefix, 'Failed modifyGroupJoinApprovalState', e.message);
            }
        } finally {
            this.groupModifyLoading = LoadingState.READY;
        }
    }

    *getGroupJoinApprovals(groupId, userEmail) {
        console.log(logPrefix, `Start getGroupJoinApprovals groupId=${groupId}, userEmail=${userEmail}`);

        this.groupListLoading = LoadingState.PENDING;

        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/join/approvals/${userEmail}`);

            console.log(logPrefix, 'Success getGroupJoinApprovals, result = {}', response.data);
            this.groupJoinApprovals = response.data;
            this.groupListLoading = LoadingState.SUCCESS;
        } catch (e) {
            this.groupListLoading = LoadingState.FAILED;
            if (e.response) {
                console.log(logPrefix, 'Failed getGroupJoinApprovals', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getGroupJoinApprovals', e.request);
            } else {
                console.log(logPrefix, 'Failed getGroupJoinApprovals', e.message);
            }
        } finally {
            this.groupListLoading = LoadingState.READY;
        }
    }

    *checkIsJoinApprove(userId, groupId) {
        console.log(logPrefix, `Start checkIsJoinApprove userId=${groupId}`);
        this.checkJoinApprovalLoading = LoadingState.PENDING;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/check/join/approval/${userId}`);
            console.log(logPrefix, 'Success checkIsJoinApprove');
            console.log(logPrefix, 'Result = ', response.data);
            this.joinApprovalState = response.data;

            this.checkJoinApprovalLoading = LoadingState.SUCCESS;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed checkIsJoinApprove', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed checkIsJoinApprove', e.request);
            } else {
                console.log(logPrefix, 'Failed checkIsJoinApprove', e.message);
            }

            this.checkJoinApprovalLoading = LoadingState.FAILED;
        } finally {
            this.checkJoinApprovalLoading = LoadingState.READY;
        }
    }

    *operateUserJoinApprovalState(operation, requestApproval, userEmail, callbacks) {
        console.log(logPrefix, `Start operateUserJoinApprovalState operation=${operation}`);
        console.log(logPrefix, `Start operateUserJoinApprovalState requestApproval = `, requestApproval);

        try {
            const params = { ...requestApproval };

            const response = yield axios.put(this.serverContextPath + `/api/v1/group/operate/join/approval/${operation}/${userEmail}`, params);

            console.log(logPrefix, 'Success operateUserJoinApprovalState');
            console.log(logPrefix, 'Result = ', response.data);
            this.groupJoinApprovals = response.data;
            if (operation === GroupJoinApprovalOperation.Approve) {
                this.getGroupDetail(requestApproval.groupId);
                callbacks && callbacks.moveToMemberTab();
            }
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed operateUserJoinApprovalState', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed operateUserJoinApprovalState', e.request);
            } else {
                console.log(logPrefix, 'Failed operateUserJoinApprovalState', e.message);
            }
        }
    }

    *changeGroupUserType(modifyMembers, userEmail, newType) {
        console.log(logPrefix, `changeGroupUserType starting, newAdmins =`, modifyMembers);
        try {
            const response = yield axios.put(this.serverContextPath + `/api/v1/group/user/type/${newType}/${userEmail}`, modifyMembers);
            console.log(logPrefix, 'Success changeGroupUserType');
            console.log(logPrefix, 'Result = ', response.data);
            this.selectedGroupDetail = response.data;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed changeGroupUserType', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed changeGroupUserType', e.request);
            } else {
                console.log(logPrefix, 'Failed changeGroupUserType', e.message);
            }
        }
    }

    *createInviteAuthentication(userEmail, callbacks) {
        console.log(logPrefix, `changeGroupUserType starting`);
        const groupId = this.selectedGroupDetail.group.id;
        try {
            const response = yield axios.post(this.serverContextPath + `/api/v1/group/${groupId}/invite/${userEmail}`);
            console.log(logPrefix, 'Success createInviteAuthentication');
            console.log(logPrefix, 'Result : >> ', response.data);
            this.invite = response.data;
            callbacks && callbacks.openInviteDialog();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed createInviteAuthentication', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed createInviteAuthentication', e.request);
            } else {
                console.log(logPrefix, 'Failed createInviteAuthentication', e.message);
            }
            callbacks && callbacks.alert();
        }
    }

    setAvatars = async users => {
        if (users.length) {
            await users.map(user => {
                if (!user.image) {
                    this.avatarStore.findAvatar(user.userId, {
                        setImage: data => {
                            user = { ...user, image: data };
                            console.log('find avatar result  :>>', user);
                        },
                    });
                }
            });
        }

        return users;
    };

    *getGroupUsers(userEmail, groupId, page, rowsPerPage, sort, callbacks) {
        console.log(logPrefix, `getGroupUsers starting userEmail = ${userEmail}, groupId=${groupId}, page=${page}, rowsPerPage=${rowsPerPage}`);
        this.groupUserLoadingState = LoadingState.PENDING;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/users`, {
                params: { userEmail: userEmail, page: page, rowsPerPage: rowsPerPage, sort: sort },
            });

            console.log(logPrefix, 'Success getGroupUsers');
            console.log(logPrefix, 'Result : >> ', response.data);

            const responseUsers = response.data.groupUsers;
            const responseGroupUsersTotalCount = response.data.groupUsersTotalCount;

            if (this.selectedGroupUsersTotalCount !== responseGroupUsersTotalCount) {
                this.selectedGroupUsersTotalCount = responseGroupUsersTotalCount;
            }

            this.avatarStore.setAvatars(responseUsers).then(r => {
                callbacks && callbacks.successAction(r, responseGroupUsersTotalCount);
            });

            this.groupUserLoadingState = LoadingState.SUCCESS;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getGroupUsers', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getGroupUsers', e.request);
            } else {
                console.log(logPrefix, 'Failed getGroupUsers', e.message);
            }
            callbacks && callbacks.alert && callbacks.alert();
            this.groupUserLoadingState = LoadingState.FAILED;
        } finally {
            this.groupUserLoadingState = LoadingState.READY;
        }
    }

    *getGroupMemberAndOperators(userEmail, groupId, page, rowsPerPage, sort, callbacks) {
        console.log(
            logPrefix,
            `getGroupMemberAndOperators starting userEmail = ${userEmail}, groupId=${groupId}, page=${page}, rowsPerPage=${rowsPerPage}`,
        );
        this.groupUserLoadingState = LoadingState.PENDING;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/memberandoperators`, {
                params: { userEmail: userEmail, page: page, rowsPerPage: rowsPerPage, sort: sort },
            });

            console.log(logPrefix, 'Success getGroupMemberAndOperators');
            console.log(logPrefix, 'Result : >> ', response.data);

            const responseUsers = response.data.groupUsers;
            const responseGroupUsersTotalCount = response.data.groupUsersTotalCount;

            if (this.selectedGroupMemberAndOperatorsTotalCount !== responseGroupUsersTotalCount) {
                this.selectedGroupMemberAndOperatorsTotalCount = responseGroupUsersTotalCount;
            }

            this.avatarStore.setAvatars(responseUsers).then(r => {
                console.log(logPrefix, 'r : >> ', r);
                callbacks && callbacks.successAction(r, responseGroupUsersTotalCount);
            });

            this.groupUserLoadingState = LoadingState.SUCCESS;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getGroupMemberAndOperators', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getGroupMemberAndOperators', e.request);
            } else {
                console.log(logPrefix, 'Failed getGroupMemberAndOperators', e.message);
            }
            callbacks && callbacks.alert && callbacks.alert();
            this.groupUserLoadingState = LoadingState.FAILED;
        } finally {
            this.groupUserLoadingState = LoadingState.READY;
        }
    }

    *getGroupMemberAndOperatorsTotalCount(groupId) {
        console.log(logPrefix, `getGroupMemberAndOperatorsTotalCount starting, groupId=${groupId}`);
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/memberandoperators/count`);

            console.log(logPrefix, 'Success getGroupMemberAndOperatorsTotalCount result = ', response.data);

            this.selectedGroupMemberAndOperatorsTotalCount = response.data;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getGroupMemberAndOperatorsTotalCount', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getGroupMemberAndOperatorsTotalCount', e.request);
            } else {
                console.log(logPrefix, 'Failed getGroupMemberAndOperatorsTotalCount', e.message);
            }
        }
    }

    *getAllGroupUsers(userEmail, groupId) {
        console.log(logPrefix, `getAllGroupUsers starting userEmail = ${userEmail}, groupId=${groupId}`);

        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/users/all`, {
                params: { userEmail: userEmail },
            });

            console.log(logPrefix, 'Success getAllGroupUsers');
            console.log(logPrefix, 'Result : >> ', response.data);

            const responseAllGroupUsers = response.data.groupUsers;
            const responseTotalCount = response.data.groupUsersTotalCount;

            if (this.selectedAllGroupUsersTotalCount !== responseTotalCount) {
                this.selectedAllGroupUsersTotalCount = responseTotalCount;
            }

            this.selectedAllGroupUsers = yield this.avatarStore.setAvatars(responseAllGroupUsers);

            console.log(logPrefix, 'getAllGroupUsers result = ', this.selectedAllGroupUsers);
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getAllGroupUsers', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getAllGroupUsers', e.request);
            } else {
                console.log(logPrefix, 'Failed getAllGroupUsers', e.message);
            }
        }
    }

    *getInviteGroup(accessToken) {
        console.log(logPrefix, 'getInviteGroup start');
        this.invitationLoadingState = LoadingState.PENDING;

        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/invite/${accessToken}`);

            console.log(logPrefix, 'Success getInviteGroup');
            console.log(logPrefix, 'Result = ', response.data);
            this.inviteGroup = response.data;
            sessionStorage.setItem(SessionStorageGroupInviteToken, accessToken);
            this.inviteToken = accessToken;

            this.invitationLoadingState = LoadingState.SUCCESS;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getInviteGroup', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getInviteGroup', e.request);
            } else {
                console.log(logPrefix, 'Failed getInviteGroup', e.message);
            }
            this.invitationLoadingState = LoadingState.FAILED;
        } finally {
            this.invitationLoadingState = LoadingState.READY;
        }
    }

    *getGroupAuthorities(groupId, callbacks) {
        this.groupAuthorityLoading = loadingSate.PENDING;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/authorities/${groupId}`);

            console.log(logPrefix, 'Success getGroupAuthorities');
            console.log(logPrefix, 'Result = ', response.data);
            this.groupAuthorities = response.data;
            this.setGroupAuthorityMap(response.data);
            callbacks && callbacks.setGroupUserAuthority(response.data);
            this.groupAuthorityLoading = loadingSate.SUCCESS;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getGroupAuthorities', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getGroupAuthorities', e.request);
            } else {
                console.log(logPrefix, 'Failed getGroupAuthorities', e.message);
            }
            this.groupAuthorityLoading = loadingSate.FAILED;
        } finally {
            this.groupAuthorityLoading = loadingSate.READY;
        }
    }

    *modifyGroupAuthority(requestUserEmail, featureKey, authorityType, callbacks) {
        this.groupAuthorityLoading = loadingSate.PENDING;
        const authority = this.groupAuthorities.find(auth => auth.featureKey === featureKey);
        if (!authority) {
            console.log('Failed modifyGroupAuthority, not found featureKey');
            return false;
        }
        try {
            const response = yield axios.put(this.serverContextPath + `/api/v1/group/authorities/${requestUserEmail}`, authority, {
                params: { modifyTypeName: authorityType },
            });

            console.log('Success modifyGroupAuthority');
            this.groupAuthorities = response.data;
            this.setGroupAuthorityMap(response.data);
            this.groupAuthorityLoading = loadingSate.SUCCESS;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getGroupAuthorities', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getGroupAuthorities', e.request);
            } else {
                console.log(logPrefix, 'Failed getGroupAuthorities', e.message);
            }
            callbacks && callbacks.error();
            this.groupAuthorityLoading = loadingSate.FAILED;
        } finally {
            this.groupAuthorityLoading = loadingSate.READY;
        }
    }

    *requestDeleteGroup(requestUserEmail, groupId, callbacks) {
        try {
            const response = yield axios.put(this.serverContextPath + `/api/v1/group/delete/${groupId}`, null, {
                params: { requestUserEmail: requestUserEmail },
            });
            callbacks && callbacks.closeDialog();
            callbacks && callbacks.moveToRooms();
            console.log('Success requestDeleteGroup');
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed requestDeleteGroup', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestDeleteGroup', e.request);
            } else {
                console.log(logPrefix, 'Failed requestDeleteGroup', e.message);
            }
            if (e.response.errorCode && e.response.errorCode === 'CanNotDelete') {
                callbacks && callbacks.alert();
            } else {
                callbacks && callbacks.error();
            }
        }
    }

    *requestLeaveGroup(requestUserEmail, groupId, callbacks) {
        try {
            const response = yield axios.put(this.serverContextPath + `/api/v1/group/leave/${groupId}`, null, {
                params: { requestUserEmail: requestUserEmail },
            });

            console.log('Success requestLeaveGroup');
            callbacks && callbacks.doSuccessAction();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed requestLeaveGroup', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestLeaveGroup', e.request);
            } else {
                console.log(logPrefix, 'Failed requestLeaveGroup', e.message);
            }
            if (e.response.errorCode && e.response.errorCode === 'BadRequest') {
                callbacks && callbacks.alert();
            } else {
                callbacks && callbacks.error();
            }
        }
    }

    *modifyInviteAuthenticationExpireDatetime(requestUserEmail, expireDatetime, callbacks) {
        console.log(logPrefix, 'Start modifyInviteAuthenticationExpireTime, expireDatetime = >>', expireDatetime);
        const params = { ...this.invite };

        try {
            params.expireDatetime = expireDatetime;
            const response = yield axios.put(this.serverContextPath + `/api/v1/group/modify/invite/${requestUserEmail}`, params);

            console.log(logPrefix, 'Success modifyInviteAuthenticationExpireDatetime');

            callbacks && callbacks.closeCalendar();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed requestLeaveGroup', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestLeaveGroup', e.request);
            } else {
                console.log(logPrefix, 'Failed requestLeaveGroup', e.message);
            }
        }
    }

    *requestCancelGroupJoin(userEmail, callbacks) {
        console.log(logPrefix, 'Start requestCancelGroupJoin');
        const groupId = this.selectedGroupId;

        try {
            const response = yield axios.put(this.serverContextPath + `/api/v1/group/${groupId}/join/${userEmail}`);

            this.joinApprovalState = response.data;
            console.log(logPrefix, 'Success modifyInviteAuthenticationExpireDatetime');
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed requestLeaveGroup', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestLeaveGroup', e.request);
            } else {
                console.log(logPrefix, 'Failed requestLeaveGroup', e.message);
            }
            callbacks && callbacks.alert();
        }
    }

    *guestInvitation(groupId, hostId, userList, callback) {
        console.log(logPrefix, 'Start requestGuestInvitationGroup');
        this.invitationLoadingState = LoadingState.PENDING;
        try {
            if (!userList.length > 0) {
                console.log(logPrefix, 'Can Not Found UserList...');
                return false;
            }

            const params = userList;
            yield axios.post(this.serverContextPath + `/api/v1/group/${groupId}/invite/${hostId}/guest`, params);

            callback && callback.dialogClose();
            callback && callback.success();
            callback && callback.initCoachMarkState();
            callback && callback.resetGroupInvitees();
            this.invitationLoadingState = LoadingState.SUCCESS;
        } catch (e) {
            callback && callback.error();
            if (e.response) {
                console.log(logPrefix, 'Failed requestGuestInvitationGroup', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestGuestInvitationGroup', e.request);
            } else {
                console.log(logPrefix, 'Failed requestGuestInvitationGroup', e.message);
            }
            this.invitationLoadingState = LoadingState.FAILED;
        } finally {
            this.invitationLoadingState = LoadingState.READY;
        }
    }

    *getGroupNoticeList(groupId) {
        console.log(logPrefix, 'Start getGroupNoticeList');
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/notice/list`);
            this.selectedGroupDetail.noticeList = response.data;
            console.log(logPrefix, 'Start getGroupNoticeList response={}', response.data);
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getGroupNoticeList', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getGroupNoticeList', e.request);
            } else {
                console.log(logPrefix, 'Failed getGroupNoticeList', e.message);
            }
        }
    }

    *getGroupLeader(groupId, userEmail, callbacks) {
        console.log(logPrefix, `Start getGroupLeader groupId = ${groupId}`);

        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/find/leader/${userEmail}`);

            console.log(logPrefix, `Success get group leader =`, response.data);

            callbacks && callbacks.checkIsLeader(response.data);
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getGroupLeader', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getGroupLeader', e.request);
            } else {
                console.log(logPrefix, 'Failed getGroupLeader', e.message);
            }
        }
    }

    *getGroupCurrentRegistrationFiles(groupId) {
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/files/current`);
            console.log(logPrefix, 'Success getGroupLatestRegistrationFiles');
            console.log(logPrefix, 'Result = ', response.data);

            this.groupCurrentFiles = response.data;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getGroupLatestRegistrationFiles', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getGroupLatestRegistrationFiles', e.request);
            } else {
                console.log(logPrefix, 'Failed getGroupLatestRegistrationFiles', e.message);
            }
        }
    }

    *getGroupInvitee(requestUserEmail, groupId) {
        console.log(logPrefix, 'start getGroupInvitee');
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/invitees?requestUserEmail=${requestUserEmail}`);
            console.log(logPrefix, 'Success getGroupInvitee');
            console.log(logPrefix, 'Result = ', response.data);

            // this.groupCurrentFiles = response.data;
            this.groupInvitees = response.data;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getGroupInvitee', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getGroupInvitee', e.request);
            } else {
                console.log(logPrefix, 'Failed getGroupInvitee', e.message);
            }
        }
    }

    *requestCreateNoteCorrection(groupId, userEmail, paperGroupId) {
        console.log(logPrefix, 'start requestCreateNoteCorrection');
        try {
            yield axios.put(
                this.serverContextPath +
                    `/api/v1/group/${groupId}/smartpen/papergroup/correction?userEmail=${userEmail}&paperGroupId=${paperGroupId}`,
            );
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed requestCreateNoteCorrection', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestCreateNoteCorrection', e.request);
            } else {
                console.log(logPrefix, 'Failed requestCreateNoteCorrection', e.message);
            }
        }
    }

    *requestUpdateNoteCorrection(groupId, userEmail, paperId, correctionId, operation) {
        console.log(logPrefix, 'start requestUpdateNoteCorrection');
        console.log(logPrefix, `groupId = ${groupId}`);
        console.log(logPrefix, `userEmail = ${userEmail}`);
        console.log(logPrefix, `paperId = ${paperId}`);
        console.log(logPrefix, `correctionId = ${correctionId}`);
        console.log(logPrefix, `operation = ${operation}`);
        try {
            yield axios.post(
                this.serverContextPath +
                    `/api/v1/group/${groupId}/smartpen/papergroup/correction?userEmail=${userEmail}&paperId=${paperId}&correctionId=${correctionId}&operation=${operation}`,
            );
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed requestUpdateNoteCorrection', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestUpdateNoteCorrection', e.request);
            } else {
                console.log(logPrefix, 'Failed requestUpdateNoteCorrection', e.message);
            }
        }
    }

    *requestGetPaperGroupCorrections(groupId, paperId) {
        console.log(logPrefix, 'start requestGetPaperGroupCorrections');
        console.log(logPrefix, `groupId = ${groupId}`);
        console.log(logPrefix, `paperId = ${paperId}`);
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/smartpen/papergroup/correction?&paperId=${paperId}`);
            console.log(logPrefix, 'requestGetPaperGroupCorrections result = {}', response.data);
            this.paperGroupCorrections = response.data;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed requestUpdateNoteCorrection', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestUpdateNoteCorrection', e.request);
            } else {
                console.log(logPrefix, 'Failed requestUpdateNoteCorrection', e.message);
            }
        }
    }

    *requestGetPaperGroupCorrectionsForUser(groupId, userEmail) {
        console.log(logPrefix, 'start requestGetPaperGroupCorrectionsForUser');
        console.log(logPrefix, `groupId = ${groupId}`);
        console.log(logPrefix, `userEmail = ${userEmail}`);
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/smartpen/papergroup/correction/${userEmail}`);
            console.log(logPrefix, 'requestGetPaperGroupCorrectionsForUser result = {}', response.data);
            this.paperGroupCorrections = response.data;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed requestGetPaperGroupCorrectionsForUser', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestGetPaperGroupCorrectionsForUser', e.request);
            } else {
                console.log(logPrefix, 'Failed requestGetPaperGroupCorrectionsForUser', e.message);
            }
        }
    }

    // -- 이하 임시 위치 -- //
    getEvaluationPathInfo = () => {
        const path = window.location.pathname;

        path && console.log("path info : ", path);
        if(path){
            let pathArr = path.split('/');
            console.log("pathArr : ", pathArr);

            if(pathArr[4]) {
                if(pathArr[1] === "evaluation"){
                    this.noteEvaluationResult.groupId = pathArr[2];
                    this.noteEvaluationResult.userEmail = pathArr[3];
                    this.noteEvaluationResult.paperGroupId = pathArr[4];
                }
            }
        }
    }

    findDuplicateValues = (...args) => {
        let values = args[0].map(item => item[args[3]]);
        let duplicates = [];
        for (let i = 0; i < values.length; i++) {
            if (values.indexOf(values[i]) !== i) {
                duplicates.push(values[i]);
            }
        }
        // console.log("duplicates !! : ",duplicates);
        let result = [];
        for (let j = 1; j < args.length; j++) {
            let array = args[j];
            for (let k = 0; k < array.length; k++) {
                if (values.includes(array[k][args[3]])) {
                    result.push(array[k][args[3]]);
                }
            }
        }
        let values2 = args[1].map(item => item[args[3]]);
        for (let k = 0; k < args[2].length; k++) {
            if (values2.includes(args[2][k][args[3]])) {
                result.push(args[2][k][args[3]]);
            }
        }

        console.log("result !! : ", [...new Set(result)]);
        return [...new Set(result)];
    }

    convertFrequencyToWordCloudData = (frequency) => {
        const keys = Object.keys(frequency);
        let result = [];
        for (let i = 0; i < keys.length; i++) {

        }

        for (let i = 0; i < keys.length; i++) {
            let temp = {
                text : '',
                value : 0,
            }
            temp.text = keys[i];
            temp.value = frequency[keys[i]];
            result.push(temp);
        }

        return result;
    }

    convertTextForHighlight = (segmentList) => {
        console.log("convertTextForHighlight start - segmentList : {}", segmentList);
        let result = '';
        for(let segment of segmentList){
            const keys = Object.keys(segment);

            for (let i = 0; i < keys.length; i++) {
                if(segment[keys[i]] !== 0){
                    let errorText = '';
                    let color = '';
                    switch (segment[keys[i]]){
                        case 1 :
                            errorText = "맞춤법에 문제가 있는 단어 또는 구절";
                            color = "#d35f5f";
                            break;
                        case 2 :
                            errorText = "띄어쓰기에 문제가 있는 단어 또는 구절";
                            color = "#e8b46a";
                            break;
                        case 3 :
                            errorText = "표준어가 의심되는 단어 또는 구절";
                            color = "#6aa5e8";
                            break;
                        case 4 :
                            errorText = "통계적 교정에 따른 단어 또는 구절";
                            color = "#b6e86a";
                            break;
                    }
                    //result = result + `<span class='convert-text' style='background : #eecd92; color: #fff; margin: 0px 2px '>${keys[i]}</span>`;
                    result = result + `<span class="tooltip" style='background : ${color}; color: #fff; margin: 0px 2px '>${keys[i]}<span class="tooltip-text">${errorText}</span></span>`;
                } else {
                    result = result + `${keys[i]} `;
                }
                if((i+1) === keys.length){
                    // 마지막이면 개행
                    result = result + `<br/>`;
                }
            }
        }
        return <Box dangerouslySetInnerHTML={{__html: result}}/>;
    }

    sendSummaryMessageToOpenAI = async () => {
        const summaryMessages = [
            {role: "system", content: "당신은 학생의 글에 대한 전문가의 평가를 요약하는 전문가입니다. 간결하고 명확하게 250글자 이내의 한글로 답변해 주세요."},
        ]

        for(const criterion of criteria)
            summaryMessages.push({role: "user", content: `${criterion}: ${this.explains[criterion]}`});

        const res = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
                model: "gpt-3.5-turbo-1106",
                messages: summaryMessages
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer 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`,
                },
            }
        );

        console.log(`sendSummaryMessageToOpenAI prompt: 총평 fin ... `, res.data);

        this.explains = {
            ...this.explains,
            ["총평"]: res.data?.choices[0]?.message?.content
        };

        this.noteEvaluationResult.aiExplain = JSON.stringify(this.explains);

        await this.createAndUpdateNoteEvaluationInfo();
    }

    sendMessageToOpenAiToMakeScore = async (message) => {
        let systemMessage = "당신은 학생의 글을 평가하는 전문가입니다. 주어진 기준에 따라 글을 정확하게 점수로 평가해주세요. 평가 결과는 JSON으로 출력해주세요."

        const res = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
                model: "gpt-3.5-turbo-1106",
                messages: [
                    {role: "system", content: systemMessage},
                    {role: "user", content: `다음은 학생이 제출한 글입니다: ${message}. 이 글의 "문법 정확성", "내용 완결성", "어휘 유창성", "내용 구성력"에 대해 각각 0~100점, 5점 단위로 평가해주세요. 점수는 JSON 형태로 출력해주세요. json 형식은 다음과 같습니다. {"문법 정확성": number, "내용 완결성": number, "어휘 유창성": number, "내용 구성력": number}`},
                ],
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer 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`,
                },
            }
        );

        this.scores = JSON.parse(res.data?.choices[0]?.message?.content);
        this.noteEvaluationResult.scores = res.data?.choices[0]?.message?.content;

        console.log(`sendMessageToOpenAiToMakeScore fin ... `, this.scores);

        await this.createAndUpdateNoteEvaluationInfo();

        return res.data;
    }

    sendMessageToOpenAI = async (criterion, message) => {
        let systemMessage = "당신은 학생의 글을 평가하는 전문가입니다. 주어진 기준에 따라 글을 정확하게 한글로 평가해주세요. 짧고 간결하게, 250자 이내로 답변해 주세요."

        if(criterion === "문법 정확성")
            systemMessage = "당신은 학생의 글을 평가하는 전문가입니다. 글의 문법적 정확성을 기준으로 평가해주세요. 단, 문법에 대한 직접적인 언급 없이 글이 주는 전반적인 인상과 효과에 대해 한글로 이야기해주세요. 짧고 간결하게, 250자 이내로 답변해 주세요.";

        const res = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
                model: "gpt-3.5-turbo-1106",
                messages: [
                    {role: "system", content: systemMessage},
                    {role: "user", content: `다음은 학생이 제출한 글입니다: ${message}. 이 글의 ${criterion}은 어떠한가요?`},
                ],
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer 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`,
                },
            }
        );

        console.log(`sendMessageToOpenAI prompt: ${criterion} fin ... `, res.data);

        this.explains = {
            ...this.explains,
            [criterion]: res.data?.choices[0]?.message?.content
        };

        if(Object.keys(this.explains).length === 4) {
            this.sendSummaryMessageToOpenAI("총평")
        }

        return res.data;
    };

    // text analysis 데이터를 가져오되, 데이터가 없으면 null 을 반환 하도록 하여
    // 아직 데이터가 없음을 확인할 수 있도록 한다.
    // 또한 이후 create or update 등 의 로직에서 데이터 유무를 체크하는 값을 항상 갱신 할 수 있도록 해준다.
    *getTextAnalysisResult(){
        console.log(logPrefix, 'start getTextAnalysisResult');

        try {
            let url = this.serverContextPath + `/api/v1/textAnalysis/note/evaluation`;

            const response = yield axios.post(url, this.noteEvaluationResult);
            console.log(logPrefix, 'getTextAnalysisResult result = {}', response.data);

            if(response.data){
                // 데이터 유무를 체크하는 값 -> true
                // 결과가 있으므로 세팅 및 표기 하도록 함.
                this.noteEvaluationResult = response.data;

                if(this.noteEvaluationResult.aiExplain) {
                    this.explains = JSON.parse(this.noteEvaluationResult.aiExplain);
                }

                if(this.noteEvaluationResult.opinion) {
                    this.opinions = JSON.parse(this.noteEvaluationResult.opinion);
                }

                if(this.noteEvaluationResult.scores) {
                    this.scores = JSON.parse(this.noteEvaluationResult.scores);
                }


                if(this.noteEvaluationResult.result){
                    let temp = _.cloneDeep(JSON.parse(this.noteEvaluationResult.result))

                    // 유창성 부분에서 중복 항목 강조용으로 사용
                    this.duplicateValues = this.findDuplicateValues(temp["fluency"]["topic1"], temp["fluency"]["topic2"], temp["fluency"]["topic3"], "word");
                    console.log("duplicateValues : ", this.duplicateValues);
                    this.convertedText = this.convertTextForHighlight(temp["accuracy"]["오류 정보"]);
                    this.convertedFrequency = this.convertFrequencyToWordCloudData(temp["wordFrequency"]["빈도"]);
                    const analysis = temp["accuracy"];
                    this.average = 53.5;
                    this.average = ((analysis["어휘력"] + analysis["정확성"] + analysis["일관성"] + analysis["유창성"] + analysis["간결성"] + analysis["결속성"]) / 6).toFixed(1);

                    this.copyResult = _.cloneDeep(temp);
                    this.setChartData();

                    this.getTextAnalysisAverage();
                }
            } else {
                // 데이터 유무를 체크하는 값 -> false
            }
        } catch (e) {
            console.log(logPrefix, 'Failed getTextAnalysisResult', e);
        }
    }

    *createAndUpdateNoteEvaluationInfo() {
        console.log(logPrefix, 'start createAndUpdateNoteEvaluationInfo', this.noteEvaluationResult);

        try {
            let url = this.serverContextPath + `/api/v1/textAnalysis/note/evaluation/create`;

            yield axios.post(url, this.noteEvaluationResult);

        } catch (e) {
            console.log(logPrefix, 'Failed createAndUpdateNoteEvaluationInfo', e.response.data);
        }
    }

    saveOpinion = (key, text) => {
        this.opinions = {
            ...this.opinions,
            [key]: text
        };

        this.noteEvaluationResult.opinion = JSON.stringify(this.opinions);

        this.createAndUpdateNoteEvaluationInfo();
    }

    runOpenAI = async () => {
        this.explains = {};

        this.sendMessageToOpenAiToMakeScore(this.noteEvaluationResult.editText);

        for(const criterion of criteria) {
            this.sendMessageToOpenAI(criterion, this.noteEvaluationResult.editText);
        }
    }

    // 내부적으로 평가결과를 가져오며, 동시에 result 를 update 함.
    // 따라서 별도로 result 를 업데이트 할 필요가 아직은 없음.
    *doTextAnalysis() {
        //request openAI
        this.runOpenAI();

        console.log(logPrefix, 'start doTextAnalysis');
        this.doAnalysisState = LoadingState.PENDING;
        try {
            let url = this.serverContextPath + `/api/v1/textAnalysis`;
            if(this.noteEvaluationResult.result){
                // 새로 result 를 쌓으러 가는것임. 백엔드에 전달 할 필요가 없다.( 전달시 변환 등의 절차가 필요)
                this.noteEvaluationResult.result = undefined;
            }

            const param = this.noteEvaluationResult;

            const response = yield axios.post(url, param);
            console.log(logPrefix, 'doTextAnalysis result = {}', response.data);
            //여기서 서버로 부터 받은 response.data 는 이미 json 형태이다.

            this.noteEvaluationResult.result = response.data;
            const temp = _.cloneDeep(this.noteEvaluationResult.result);
            if(temp){
                // 유창성 부분에서 중복 항목 강조용으로 사용
                this.duplicateValues = this.findDuplicateValues(temp["fluency"]["topic1"], temp["fluency"]["topic2"], temp["fluency"]["topic3"], "word");
                console.log("duplicateValues : ", this.duplicateValues);
                this.convertedText = this.convertTextForHighlight(temp["accuracy"]["오류 정보"]);
                this.convertedFrequency = this.convertFrequencyToWordCloudData(temp["wordFrequency"]["빈도"]);
                const analysis = temp["accuracy"];
                this.average = 53.5;
                // this.average = ((analysis["어휘력"] + analysis["정확성"] + analysis["일관성"] + analysis["유창성"] + analysis["간결성"] + analysis["결속성"])/6).toFixed(1);

                // 서순상 늦게 셋팅해서 랜더링을 늦게 해준다.
                this.copyResult = _.cloneDeep(this.noteEvaluationResult.result);
                this.setChartData();

                this.getTextAnalysisAverage();
            }
            this.doAnalysisState = LoadingState.SUCCESS;
        } catch (e) {
            console.log(logPrefix, 'Failed doTextAnalysis', e);
            this.doAnalysisState = LoadingState.FAILED;
        } finally {
            this.doAnalysisState = LoadingState.READY;
        }
    }

    *getTextAnalysisAverage() {
        console.log(logPrefix, 'start getTextAnalysisAverage');

        try {
            let url = this.serverContextPath + `/api/v1/textAnalysis/note/evaluation/average`;

            // result 를 백엔드에 넘기려면 변환을 해야하는데, 굳이 사용하지 않으므로.
            this.noteEvaluationResult.result = undefined;


            const response = yield axios.post(url, this.noteEvaluationResult);
            this.analysisList = response.data;
            console.log(logPrefix, 'analysisList = {}', this.analysisList);
            if(this.analysisList){
                // 평균 계산 및 저장
                this.average1 = this.calculate1(this.analysisList); // 어휘력
                this.average2 = this.calculate2(this.analysisList); // 정확성
                this.average3 = this.calculate3(this.analysisList); // 일관성
                this.average4 = this.calculate4(this.analysisList); // 유창성
                this.average5 = this.calculate5(this.analysisList); // 간결성
                this.average6 = this.calculate6(this.analysisList); // 결속성
            }

        } catch (e) {
            console.log(logPrefix, 'Failed getTextAnalysisAverage', e);
        }
    }

    //어휘력
    calculate1 = (list) => {
        let sum = 0;
        let average = 0;
        for(let o of list){
            o.result = JSON.parse(o.result);
            sum = sum + o.result["vocabulary"]["대명사"]["빈도"] + o.result["vocabulary"]["명사"]["빈도"] + o.result["vocabulary"]["형용사"]["빈도"] + o.result["vocabulary"]["동사"]["빈도"] + o.result["vocabulary"]["부사"]["빈도"] + o.result["vocabulary"]["접속사"]["빈도"];
        }
        if(list.length > 0){
            average = sum / list.length;
        }
        console.log("calculate1 어휘력 : ", average.toFixed(0));
        return average.toFixed(0);
    }

    // 정확성
    calculate2 = (list) => {
        let sum = 0;
        let average = 0;
        for(let o of list){
            sum = sum + (o.result["accuracy"]["문법오류 어절 수"] / o.result["accuracy"]["전체 어절 수"])*100;
        }
        if(list.length > 0){
            average = sum / list.length;
        }
        console.log("calculate2 정확성 : ", average.toFixed(0));

        return average.toFixed(0);
    }

    // 일관성
    calculate3 = (list) => {
        let sum = 0;
        let average = 0;
        for(let o of list){
            sum = sum + o.result["consistency"]["network"];
        }
        if(list.length > 0){
            average = sum / list.length;
        }
        console.log("calculate3 일관성 : ", average.toFixed(1));
        console.log("calculate3 일관성 : ", average);
        return average.toFixed(1);
    }

    // 유창성 TODO : 계산법 점검 필요
    calculate4 = (list) => {
        let sum = 0;
        let average = 0;
        for(let o of list){
            let count = o.result["fluency"]["topic1"].length + o.result["fluency"]["topic2"].length + o.result["fluency"]["topic3"].length;
            sum = sum + (o.result["fluency"]["중복 어휘 수"]/count)*100;
        }
        if(list.length > 0){
            average = sum / list.length;
        }
        console.log("calculate4 유창성 : ", average);
        console.log("calculate4 유창성 : ", Math.ceil(average));
        return Math.ceil(average);
    }

    // 간결성 TODO : 계산법 점검 필요
    calculate5 = (list) => {
        let sum = 0;
        let average = 0;
        for(let o of list){
            sum = sum + (o.result["concision"]["info"]["중복사용 수식어"] / o.result["concision"]["info"]["전체 수식어"])*100;
        }
        if(list.length > 0){
            average = sum / list.length;
        }
        console.log("calculate5 간결성 : ", Math.ceil(average));
        return Math.ceil(average);
    }

    // 결속성
    calculate6 = (list) => {
        let sum = 0;
        let average = 0;
        for(let o of list){
            sum = sum + o.result["cohesion"]["network"];
        }
        if(list.length > 0){
            average = sum / list.length;
        }
        console.log("calculate6 결속성 : ", average.toFixed(1));
        return average.toFixed(1);
    }
}
