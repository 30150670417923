import React, { Component } from 'react';
import { Avatar, Box, Button, Checkbox, CircularProgress, Dialog, IconButton, InputBase, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as MagnifyingGlassIcon } from '../../common/images/MagnifyingGlassIcon.svg';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { UTIL } from '../../common/util/common.util';
import { inject, observer } from 'mobx-react';
import { PATH_UTIL } from '../../common/util/path.util';
import { ToastsStore } from 'react-toasts';
import clsx from 'clsx';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 400,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 600px)': {
                width: 400,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    textStyle: {
        fontSize: '1rem',
        color: '#333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    marginTop: {
        marginTop: 27,
        '@media all and (max-width: 600px)': {
            marginTop: 15,
        },
    },
    searchBox: {
        display: 'flex',
        boxShadow: 'none',
        border: '1px solid #C4C4C4',
        padding: '4px 9px',
        margin: '12px 0 21px',
        borderRadius: 7,
        '& .MuiInputBase-root': {
            width: '100%',
        },
        '& input': {
            width: '100%',
            fontSize: '1rem',
        },
        '@media all and (max-width: 600px)': {
            padding: '5px 10px',
            margin: '10px 0 20px',
            '& input': {
                fontSize: '0.813rem',
            },
        },
    },
    searchIconButton: {
        padding: 6,
        backgroundColor: '#f8f8f8',
        '&:hover': {
            backgroundColor: '#f8f8f8',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 18,
                height: 18,
            },
        },
    },
    svgSize: {
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 18,
                height: 18,
            },
        },
        '& .Mui-checked': {
            color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
    numberText: {
        fontSize: '0.813rem',
        marginLeft: 4,
        color: '#a3a8af',
    },
    listBox: {
        height: 250,
        // maxHeight: 280,
        marginTop: 20,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '15px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '5px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
        '& .Mui-checked': {
            color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '@media all and (max-width: 600px)': {
            marginTop: 0,
        },
    },
    marginBottom: {
        marginBottom: 15,
    },
    avatar: {
        width: 45,
        height: 45,
        '& svg': {
            width: 28,
            height: 28,
            '& path': {
                fill: '#fff',
            },
        },
        '@media all and (max-width: 600px)': {
            width: 35,
            height: 35,
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    nameText: {
        marginLeft: 7,
        fontSize: '1rem',
        color: '#333',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '0.875rem',
        },
    },
    checkedBox: {
        '& .MuiIconButton-root': {
            padding: 0,
        },
        '& .MuiSvgIcon-root': {
            '@media all and (max-width: 600px)': {
                fontSize: '1rem',
            },
        },
    },
    addMarginRight: {
        marginRight: '15px',
    },
    buttonStyle1: {
        width: '100%',
        height: 48,
        borderRadius: 8,
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        color: '#fff',
        fontSize: '1.125rem',
        marginTop: 25,
        '&:hover': {
            fontWeight: 800,
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            height: 33,
            padding: '6px 0',
            fontSize: '0.75rem',
            borderRadius: 4,
        },
    },
});

class InvitationStudentsDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { searchKeyword: '', members: [], isAllCheck: false };
    }

    setSearchKeyword = keyword => {
        this.setState({ searchKeyword: keyword });
    };

    filteredBySearchKeyword = member => {
        if (!this.state.searchKeyword.trim()) return member;
        return member && UTIL.keywordContains(member.name, String(this.state.searchKeyword), 0);
    };

    handleSetAllMember = allMembers => {
        if (!allMembers.length) return;

        if (this.state.members.length === 0 || this.state.members.length < allMembers.length) {
            this.setState({ members: [...allMembers] });
        } else {
            this.setState({ members: [] });
        }
    };

    handleChangeCheckBox = member => {
        if (this.state.members.length > 0) {
            const memberList = this.state.members;
            let index = memberList.findIndex(u => u.id === member.id);
            if (index === -1) {
                this.setState({ members: [...this.state.members, member] });
            } else {
                memberList.splice(index, 1);
                this.setState({ members: memberList });
            }
        } else {
            this.setState({ members: [...this.state.members, member] });
        }
    };

    handleClickGuestInvitation = () => {
        const { classes } = this.props;
        const pathName = window.location.pathname;
        const groupId = PATH_UTIL.getClassId(pathName);
        this.props.classStore.guestInvitation(groupId, this.props.loginUser.id, this.state.members, {
            dialogClose: () => this.props.handleCloseInvitationStudentDialog(),
            success: () => ToastsStore.info('초대 메시지를 보냈습니다.', 1500, classes.toasts),
            error: () => ToastsStore.error('멤버 초대 실패', 1500, classes.toasts),
            initCoachMarkState: () => {
                this.props.userStore.checkCoachState(this.props.loginUser.email);
            },
            resetGroupInvitees: () => {
                this.props.classStore.getGroupInvitee(this.props.loginUser.email, groupId);
            },
        });
    };

    render() {
        const { classes, isLoading, invitees } = this.props;
        const invitationUserListFilteredKeyword = invitees.filter(this.filteredBySearchKeyword);

        return (
            <div className={classes.root}>
                <Dialog open={this.props.invitationStudentDialogOpen} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography className={classes.titleText}>학생 초대</Typography>
                        <IconButton className={classes.iconButton} onClick={this.props.handleCloseInvitationStudentDialog} disableRipple>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>
                    {isLoading ? (
                        <Box style={{ textAlign: 'center', marginTop: 100, marginBottom: 1000 }}>
                            <CircularProgress />
                        </Box>
                    ) : !invitees.length ? (
                        <Box className={classes.textStyle}>
                            <Typography>초대 가능한 학생이 없습니다.</Typography>
                        </Box>
                    ) : (
                        <>
                            <Box className={classes.marginTop}>
                                <Typography className={classes.textStyle}>교사가 클래스 관리에서 추가한 학생을 초대합니다.</Typography>
                                <Box className={classes.searchBox}>
                                    <InputBase placeholder="이름검색" onChange={e => this.setSearchKeyword(e.target.value)} />
                                    <IconButton className={classes.searchIconButton} aria-label="Search" disableRipple>
                                        <MagnifyingGlassIcon />
                                    </IconButton>
                                </Box>
                            </Box>

                            <Box>
                                <Box display="flex" alignItems="center" className={classes.svgSize} justifyContent="space-between">
                                    <Box display="flex" alignItems="center">
                                        <AsideUserIcon />
                                        <span className={classes.numberText}>
                                            {invitationUserListFilteredKeyword && invitationUserListFilteredKeyword.length}명
                                        </span>
                                    </Box>
                                    <Box>
                                        <Checkbox
                                            className={
                                                invitationUserListFilteredKeyword.length > 6
                                                    ? clsx(classes.checkedBox, classes.addMarginRight)
                                                    : classes.checkedBox
                                            }
                                            disableRipple
                                            disabled={!invitationUserListFilteredKeyword.length}
                                            checked={this.state.members.length === invitees.length}
                                            onChange={() => this.handleSetAllMember(invitationUserListFilteredKeyword)}
                                        />
                                    </Box>
                                </Box>
                                <Box className={classes.listBox}>
                                    {invitationUserListFilteredKeyword.map((member, i) => (
                                        <Box
                                            key={`${member.id}_${member.name}`}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            mt={1}
                                            mb={2}
                                        >
                                            <Box display="flex" alignItems="center">
                                                <Avatar src={member.image} alt="profile image" className={classes.avatar}>
                                                    <AsideUserIcon />
                                                </Avatar>
                                                <Typography className={classes.nameText}>{member.name}</Typography>
                                            </Box>
                                            <Checkbox
                                                className={classes.checkedBox}
                                                disableRipple
                                                checked={this.state.members.findIndex(m => m.id === member.id) !== -1}
                                                onChange={() => this.handleChangeCheckBox(member)}
                                            />
                                        </Box>
                                    ))}
                                </Box>

                                <Button
                                    className={classes.buttonStyle1}
                                    disableRipple
                                    disabled={!this.state.members.length > 0 || this.props.classStore.isInvitationLoading}
                                    onClick={this.handleClickGuestInvitation}
                                >
                                    선택 추가
                                </Button>
                            </Box>
                        </>
                    )}
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(inject('classStore', 'userStore')(observer(InvitationStudentsDialogComponent)));
