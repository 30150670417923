import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { validateEmail, validateName } from '../common/Validation';
import { DEFAULT_GUEST_EMAIL_ADDRESS } from './ManagementStore';

const logPrefix = ' [ ExcelStore ] ';

export const TeamType = {
    Group: 'Group',
    Team: 'Team',
};

export const TeamUserType = {
    Leader: 'Leader',
    Member: 'Member',
};

export const UserType = {
    Admin: 'Admin',
    Normal: 'Normal',
};

export const RoomType = {
    Education: 'Education',
    Conference: 'Conference',
    Seminar: 'Seminar',
};
export const State = {
    Init: 'Init',
    Pending: 'Pending',
    Failed: 'Failed',
    Done: 'Done',
};
const EmptyDialog = {
    open: false,
    title: '',
    msg: '',
};
export default class ExcelStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }
    excelConfirmDialog = { ...EmptyDialog };

    createExcelState = State.Init;
    validationState = State.Init;
    isTeamCreate = true;
    teamValidationState = true;
    memberValidationState = true;

    memberJsonByExcel = [];
    teamJsonByExcel = {};

    teamForView = {};
    memberForView = [];

    teamTransfer = {};
    newMemberTransferList = [];
    teamUserTransferList = [];

    memberExcelModal = false;
    teamLeaderCount = 0;

    excelSampleDownloadState = State.Init;
    excelParingState = State.Init;
    guestJsonByExcel = [];

    get isExcelSampleDownloading() {
        return this.excelSampleDownloadState === State.Pending;
    }

    get isExcelParing() {
        return this.excelParingState === State.Pending;
    }

    validatorParsingGuestData = guests => {
        let validateResult = true;
        const parsingGuests = guests.slice();
        if (parsingGuests.length) {
            parsingGuests.forEach((guest, index) => {
                const currentIndex = index + 4;
                if (!validateEmail(guest.email)) {
                    const title = '업로드 실패';
                    const errMsg = `${currentIndex} 행에 올바르지 않은 이메일 주소가 입력되었습니다.`;
                    this.setExcelConfirmDialog(errMsg, title);
                    validateResult = false;
                    return false;
                } else if (!guest.name) {
                    const title = '업로드 실패';
                    const errMsg = `${currentIndex} 행에 이름이 입력되지 않았습니다.`;
                    this.setExcelConfirmDialog(errMsg, title);
                    validateResult = false;
                    return false;
                } else if (!validateName(guest.name)) {
                    const title = '업로드 실패';
                    const errMsg = `${currentIndex} 행에 올바르지 않은 이름이 입력되었습니다.`;
                    this.setExcelConfirmDialog(errMsg, title);
                    validateResult = false;
                    return false;
                } else if (guest.smartPenAddress && guest.smartPenAddress.length !== 6) {
                    const title = '업로드 실패';
                    const errMsg = `${currentIndex} 행에 올바르지 않은 스마트펜 정보가 입력되었습니다.`;
                    this.setExcelConfirmDialog(errMsg, title);
                    validateResult = false;
                    return false;
                }
            });
        }
        return validateResult;
    };

    get getIsLoading() {
        return this.createExcelState === State.Pending || this.validationState === State.Pending;
    }
    ////////////////////////////////////////////////////////////////
    //common

    initExcelStore = () => {
        this.createExcelState = State.Init;
        this.validationState = State.Init;
        this.isTeamCreate = true;
        this.teamValidationState = true;
        this.memberValidationState = true;

        this.memberJsonByExcel = [];
        this.teamJsonByExcel = {};

        this.teamForView = {};
        this.memberForView = [];

        this.teamTransfer = {};
        this.newMemberTransferList = [];
        this.teamUserTransferList = [];
    };

    changeExcelConfirmDialogOpen = open => {
        if (open) {
            this.excelConfirmDialog.open = open;
        } else {
            this.excelConfirmDialog = { ...EmptyDialog };
        }
    };
    changeExcelConfirmDialogTitle = title => {
        this.excelConfirmDialog.title = title;
    };
    changeExcelConfirmDialogMsg = msg => {
        this.excelConfirmDialog.msg = msg;
    };

    setExcelConfirmDialog = (msg, title) => {
        this.changeExcelConfirmDialogOpen(true);
        this.changeExcelConfirmDialogTitle(title);
        this.changeExcelConfirmDialogMsg(msg);
    };

    setMemberExcelModal = modal => {
        this.memberExcelModal = modal;
    };

    ////////////////////////////////////////////////////////////////
    //team

    checkTeamValidation = intl => {
        this.teamValidationState = true;
        const team = this.teamJsonByExcel;
        team.validationState = true;
        team.msg = [];

        this.checkTeamNameValidation(intl);
        this.checkTeamCommentValidation(intl);
        this.checkTeamMaxUsersValidation(intl);
    };

    checkTeamNameValidation = function* checkTeamNameValidation(intl) {
        console.log(logPrefix, 'checkTeamNameValidation Start...');
        const team = this.teamJsonByExcel;

        if (team.name) {
            if (team.name.toString().length <= 20) {
                try {
                    const response = yield axios({
                        method: 'get',
                        url: this.serverContextPath + `/api/v1/teams/check/name`,
                        params: { name: team.name },
                    });
                    if (response.data === 0) {
                        // team.validationState = true;
                    } else {
                        const msg = intl.formatMessage({ id: 'msg.already_using_team_name' });
                        team.msg.push(msg);
                        this.teamValidationState = false;
                        team.validationState = false;
                    }
                } catch (e) {
                    console.log(e);
                }
            } else {
                const msg = intl.formatMessage({ id: 'msg.limit_team_name' });
                team.msg.push(msg);
                this.teamValidationState = false;
                team.validationState = false;
            }
        } else {
            const msg = intl.formatMessage({ id: 'msg.required_team_name' });
            team.msg.push(msg);
            this.teamValidationState = false;
            team.validationState = false;
        }
    };

    checkTeamCommentValidation = intl => {
        const team = this.teamJsonByExcel;

        if (team.comment && 200 < team.comment.length) {
            const msg = intl.formatMessage({ id: 'msg.insert_room_description' });
            team.msg.push(msg);
            this.teamValidationState = false;
            team.validationState = false;
        }
    };

    checkTeamMaxUsersValidation = intl => {
        const team = this.teamJsonByExcel;
        const memberCount = this.memberJsonByExcel.length;

        if (team.maxUsers || team.maxUsers < 1) {
            if (memberCount <= team.maxUsers) {
                // pass
                // team.validationState = true;
            } else {
                const msg = intl.formatMessage({ id: 'msg.error_too_many_members' });
                team.msg.push(msg);
                this.teamValidationState = false;
                team.validationState = false;
            }
        } else {
            const msg = intl.formatMessage({ id: 'msg.required_max_user_count' });
            team.msg.push(msg);
            this.teamValidationState = false;
            team.validationState = false;
        }
    };

    ////////////////////////////////////////////////////////////////
    //member

    checkMemberValidation = async intl => {
        this.memberValidationState = true;
        for (let index in this.memberJsonByExcel) {
            this.memberJsonByExcel[index].validationState = true;
            this.memberJsonByExcel[index].msg = [];

            const member = this.memberJsonByExcel[index];
            const count = this.memberJsonByExcel.filter(item => item.employeeNumber.trim() === member.employeeNumber.trim()).length;
            if (1 < count) {
                const msg = intl.formatMessage({ id: 'msg.overlap_member' });
                member.msg.push(msg);
                this.memberValidationState = false;
                member.validationState = false;
            } else {
                await this.getMemberByEmployeeNumber(index, intl);

                if (!this.memberJsonByExcel[index].isExist) {
                    // this.checkMemberTypeValidation(index, intl);
                    this.checkMemberEmailValidation(index, intl);
                    // this.checkMemberPasswordValidation(index, intl);
                    // this.checkMemberEmployeeNumberValidation(index, intl);
                    // this.checkMemberNameValidation(index, intl);
                    // this.checkMemberTitleValidation(index, intl);
                    // this.checkMemberCenterNameValidation(index, intl);
                }
            }
        }
    };

    getMemberByName = function* getMemberByName(index, intl) {
        let member = this.memberJsonByExcel[index];

        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + '/api/v1/users/name',
                params: {
                    userName: member.name,
                },
            });
            const selectedMember = response.data;
            if (selectedMember) {
                if (selectedMember.email === member.email && selectedMember.name === member.name && selectedMember.enabled) {
                    member.id = selectedMember.id;
                    member.isExist = true;
                    member.password = '****';
                    member.title = selectedMember.title;
                } else {
                    const msg = intl.formatMessage({ id: 'msg.already_using_nickname' });
                    member.msg.push(msg);
                    this.memberValidationState = false;
                    member.validationState = false;
                }
            } else {
                this.getMemberByEmail(intl, index);
            }
        } catch (e) {
            console.log(e);
        }
    };

    getMemberByEmployeeNumber = function* getMemberByEmployeeNumber(index, intl) {
        let member = this.memberJsonByExcel[index];

        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + '/api/v1/users/employeeNumber',
                params: {
                    employeeNumber: member.employeeNumber.trim(),
                },
            });
            const selectedMember = response.data;
            if (selectedMember) {
                if (selectedMember.employeeNumber === String(member.employeeNumber.trim()) && selectedMember.enabled) {
                    member.isExist = true;
                    member.id = selectedMember.id;
                    // member.name = selectedMember.name;
                    // member.password = "****";
                    // member.centerName = selectedMember.centerName;
                    member.email = selectedMember.email;
                    // member.title = selectedMember.title;
                    const msg = '등록 되어있는 이메일 입니다. 기존 정보를 유지합니다.';
                    member.msg.push(msg);
                    // this.memberValidationState = false;
                    // member.validationState = false;
                } else {
                    const msg = '이미 등록 되어있는 사용이 중지 된 이메일 입니다.';
                    member.msg.push(msg);
                    this.memberValidationState = false;
                    member.validationState = false;
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    getMemberByEmail = function* getMamberByEmail(intl, index) {
        let member = this.memberJsonByExcel[index];

        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + '/api/v1/users/email',
                params: {
                    email: member.email,
                },
            });

            const selectedMember = response.data;
            if (selectedMember) {
                const msg = intl.formatMessage({ id: 'msg.already_using_email' });
                member.msg.push(msg);
                this.memberValidationState = false;
                member.validationState = false;
            } else {
                // new
                // 사용 가능
            }
        } catch (e) {
            console.log(e);
        }
    };

    checkMemberTypeValidation = (index, intl) => {
        const member = this.memberJsonByExcel[index];

        if (member.type) {
            if (TeamUserType[member.type]) {
                // pass
                // member.validationState = true;
            } else {
                const msg = intl.formatMessage({ id: 'msg.violation_member_role' });
                member.msg.push(msg);
                this.memberValidationState = false;
                member.validationState = false;
            }
        } else {
            const msg = intl.formatMessage({ id: 'msg.required_member_role' });
            member.msg.push(msg);
            this.memberValidationState = false;
            member.validationState = false;
        }
        //TeamUserType과 대소문자 다를 때 처리 방법 생각하기
    };

    checkMemberEmailValidation = (index, intl) => {
        const member = this.memberJsonByExcel[index];

        if (member.email.trim() && !validateEmail(member.email.trim())) {
            const msg = intl.formatMessage({ id: 'msg.email_incorrect' });
            member.msg.push(msg);
            this.memberValidationState = false;
            member.validationState = false;
        }
    };

    checkMemberNameValidation = (index, intl) => {
        const member = this.memberJsonByExcel[index];

        if (member.name) {
            if (validateName(member.name)) {
                // pass
            } else {
                const msg = intl.formatMessage({ id: 'msg.re_enter_nickname' });
                member.msg.push(msg);
                this.memberValidationState = false;
                member.validationState = false;
            }
        }
        //name null
        // else {
        //     const msg = intl.formatMessage({ id : "msg.required_nickname"});
        //     member.msg.push(msg);
        //     this.memberValidationState = false;
        //     member.validationState = false;
        // }
    };

    checkMemberTitleValidation = (index, intl) => {
        const member = this.memberJsonByExcel[index];

        if (member.title && !validateName(member.title)) {
            const msg = intl.formatMessage({ id: 'msg.violation_member_title' });
            member.msg.push(msg);
            this.memberValidationState = false;
            member.validationState = false;
        }
    };

    // checkMemberCenterNameValidation = (index, intl) => {
    //     const member = this.memberJsonByExcel[index];
    //
    //     if (member.centerName && !validateLength(member.centerName)) {
    //         const msg = intl.formatMessage({ id: 'msg.violation_member_center_name' });
    //         member.msg.push(msg);
    //         this.memberValidationState = false;
    //         member.validationState = false;
    //     }
    // };

    ////////////////////////////////////////////////////////////////
    //request

    convertingTeamTransfer = () => {
        const team = this.teamJsonByExcel;
        this.teamTransfer = {
            name: team.name,
            comment: team.comment,
            maxUsers: team.maxUsers,
            type: TeamType.Team,
            enabled: true,
        };
    };

    convertingMemberTransfer = () => {
        this.newMemberTransferList = this.memberJsonByExcel.map(member => {
            return {
                user: {
                    id: member.id ? member.id : 0,
                    employeeNumber: member.employeeNumber,
                    name: member.name,
                    password: member.password,
                    email: member.email,
                    centerName: member.centerName,
                    title: member.title,
                    type: UserType.Normal,
                    enabled: true,
                    teamUserType: member.type,
                },
                teamUserListToCreate: [
                    {
                        teamId: 0,
                        userId: member.id ? member.id : 0,
                        type: member.type,
                        enabled: true,
                    },
                ],
            };
        });
    };

    createTeamByExcel = function* createTeam(intl) {
        this.createExcelState = State.Pending;
        try {
            yield this.convertingTeamTransfer();
            yield this.convertingMemberTransfer();

            yield axios({
                method: 'post',
                url: '/api/v1/excel/team',
                data: {
                    team: this.teamTransfer,
                    newDetailUserTransfer: this.newMemberTransferList,
                },
            });
            const msg = intl.formatMessage({ id: 'msg.registration_success' });
            const title = intl.formatMessage({ id: 'excel_create_team_and_member' });
            this.setExcelConfirmDialog(msg, title);
            this.createExcelState = State.Done;
        } catch (e) {
            console.log(e);
            this.createExcelState = State.Failed;
        }
    };

    createTeamMemberByExcel = function* createTeamMemberByExcel(intl, userStore) {
        this.createExcelState = State.Pending;
        try {
            yield this.convertingMemberTransfer();

            yield axios({
                method: 'post',
                url: '/api/v1/excel/member',
                data: {
                    // teamId : teamId,
                    newDetailUserTransfer: this.newMemberTransferList,
                },
            });
            const msg = intl.formatMessage({ id: 'msg.registration_success' });
            const title = intl.formatMessage({ id: 'excel_add_team_member' });

            this.setExcelConfirmDialog(msg, title);
            this.createExcelState = State.Done;

            this.initExcelStore();

            userStore.initUserTablePage();
            userStore.getUserTableListByAll();
        } catch (e) {
            if (e.response.data.code === 'CanNotInsertUserByMaxUsers') {
                const msg = intl.formatMessage({ id: 'msg.error_too_many_members' });
                const title = intl.formatMessage({ id: 'excel_add_team_member' });
                this.setExcelConfirmDialog(msg, title);
            } else {
                const msg = intl.formatMessage({ id: 'msg.error_unknown' });
                const title = intl.formatMessage({ id: 'excel_add_team_member' });
                this.setExcelConfirmDialog(msg, title);
            }

            this.createExcelState = State.Failed;
        }
    };

    *exportLmsExcelSample() {
        this.excelSampleDownloadState = State.Pending;
        try {
            const response = yield axios({
                method: 'get',
                url: '/api/v1/excel/guest',
                params: {
                    language: 'ko',
                },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Studylive_Upload student info.xlsx');
            document.body.appendChild(link);
            link.click();

            this.excelSampleDownloadState = State.Done;
        } catch (e) {
            console.log(e);
            this.excelSampleDownloadState = State.Failed;
        } finally {
            this.excelSampleDownloadState = State.Init;
        }
    }

    exportSurveyExcelSample = function* exportSurveyExcelSample() {
        try {
            const response = yield axios({
                method: 'get',
                url: '/api/v1/excel/survey',
                params: {
                    language: 'ko',
                },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'SurveyExcelForm_ko.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            console.log(e);
        }
    };

    // exportLmsMemberExcelSample = function* exportLmsMemberExcelSample(language){
    //     try{
    //         const response = yield axios({
    //             method : "get",
    //             url : "/api/v1/excel/member",
    //             params : {
    //                 language : language
    //             },
    //             responseType :'blob',
    //         });
    //
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', "LmsMemberExcelForm_"+language+".xlsx");
    //         document.body.appendChild(link);
    //         link.click();
    //     } catch(e){
    //         console.log(e);
    //     }
    // };

    // exportLmsExcelSample = function* exportLmsExcelSample(){
    //     try{
    //         const response = yield axios({
    //             method : "get",
    //             url : "/api/v1/excel/guest",
    //             params : {
    //                 language : 'ko'
    //             },
    //             responseType :'blob',
    //         });
    //
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', "LmsGuestExcelForm_ko.xlsx");
    //         document.body.appendChild(link);
    //         link.click();
    //     } catch(e){
    //         console.log(e);
    //     }
    // };

    getTeamLeaderCount = function* getTeamLeaderCount(teamId) {
        console.log(logPrefix, 'getTeamLeaderCount Start');
        try {
            const response = yield axios({
                method: 'get',
                url: `/api/v1/teams/${teamId}/leader-count`,
            });

            this.teamLeaderCount = response.data;
            console.log(logPrefix, 'getTeamLeaderCount Done');
        } catch (err) {
            console.log(logPrefix, 'getTeamLeaderCount Failed msg=', err.response.data.msg);
            console.log(err);
        }
    };

    parsingMemberExcel = function* parsingMemberExcel(reqURL, file, callback) {
        this.isSurveyLoading = true;
        try {
            const param = new FormData();
            param.append('file', file);

            // const response = yield axios.post(this.serverContextPath + `/api/v1/excel/member/parse`, param);
            const response = yield axios.post(`${reqURL}/api/v1/excel/member/parse`, param);
            this.memberJsonByExcel = [];
            for (let i = 0; i < response.data.length; i++) {
                this.memberJsonByExcel.push({
                    employeeNumber: response.data[i],
                    email: response.data[i],
                });
            }

            callback && callback();
        } catch (e) {
            const title = '업로드 실패';
            const errMsg = '엑셀파일 파싱에 실패하였습니다. \n업로드 양식을 다운받아 사용해주세요.';
            this.setExcelConfirmDialog(errMsg, title);
        } finally {
            this.isSurveyLoading = false;
        }
    };

    *parsingGuestExcel(file, callbacks) {
        this.excelParingState = State.Pending;
        try {
            this.guestJsonByExcel = [];

            const param = new FormData();
            param.append('file', file);

            // const response = yield axios.post(this.serverContextPath + `/api/v1/excel/member/parse`, param);
            const response = yield axios.post(this.serverContextPath + `/api/v1/excel/guest/parse`, param);
            const responseGuestJson = response.data;

            if (responseGuestJson.length) {
                this.guestJsonByExcel = responseGuestJson.map(guest => {
                    guest.email = `${guest.email.trim()}${DEFAULT_GUEST_EMAIL_ADDRESS}`;
                    guest.password = guest.password.trim();
                    guest.name = guest.name.trim();
                    guest.smartPenAddress = guest.smartPenAddress.trim();

                    return guest;
                });
            } else {
                const title = '업로드 실패';
                const errMsg = '엑셀파일 파싱에 실패하였습니다. \n빈 파일입니다.';
                this.setExcelConfirmDialog(errMsg, title);
                this.excelParingState = State.Failed;

                return;
            }
            console.log(logPrefix, 'parsingGuestExcel result : >> ', this.guestJsonByExcel);

            callbacks && callbacks.successAction(this.guestJsonByExcel);
            this.excelParingState = State.Done;
        } catch (e) {
            const title = '업로드 실패';
            const errMsg = '엑셀파일 파싱에 실패하였습니다. \n업로드 양식을 다운받아 사용해주세요.';
            this.setExcelConfirmDialog(errMsg, title);
            this.excelParingState = State.Failed;
        } finally {
            this.excelParingState = State.Init;
        }
    }
}
