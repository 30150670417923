import { NOTICE_TYPE } from '../../stores/NoticeStore';
class MessageUtil {
    getNotificationMessage(notificationType, userName) {
        let msg = '';
        switch (notificationType) {
            case NOTICE_TYPE.CreateRoom:
                msg = (
                    <div>
                        <span style={{ fontWeight: 600 }}>{userName}</span>님이 포함된 새 일정이 등록되었습니다.
                    </div>
                );
                break;
            case NOTICE_TYPE.NoteCorrection:
                msg = '선생님이 내 필기 기록에 첨삭 지도하였습니다.';
                break;
            default:
                console.log('Invalid type');
        }

        return msg;
    }
}

export const MESSAGE_UTIL = new MessageUtil();
