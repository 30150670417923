import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as ModalCloseIcon } from '../../../src/common/images/ModalCloseIcon.svg';

const useStyles = makeStyles(theme => ({
    explanationBox: {
        '@media all and (min-width: 1500px)': {
            width: 267,
            padding: 20,
        },
        '@media all and (max-width: 600px)': {
            width: 196,
            padding: 10,
        },
        '@media all and (max-width: 1024px)': {
            width: 267,
            padding: 10,
        },
        '@media all and (max-width: 390px)': {
            width: 196,
            padding: 10,
        },
        width: 320,
        padding: 15,
        background: '#2078e8',
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        position: 'absolute',
        whiteSpace: 'pre-wrap',
        zIndex: 100,
        '&::before': {
            backgroundColor: '#2078e8',
            content: "''",
            display: 'block',
            height: '13px',
            position: 'absolute',
            top: -7,
            left: props => (props.arrPosition ? props.arrPosition : 125),
            transform: 'rotate( -45deg ) skew( 0deg )',
            width: '13px',
            boxShadow: '2px -2px 2px 0 rgb(178 178 178 / 20%)',
            zIndex: 200,
            '@media all and (min-width: 1500px)': {
                left: props => (props.arrPosition ? props.arrPosition : 215),
            },
            '@media all and (min-width: 600px)': {
                left: props => (props.arrPosition ? props.arrPosition : 167),
            },
        },
    },
    titleBox: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1.125rem',
        },
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
            fontWeight: 500,
        },
        fontSize: '1.5rem',
        color: '#fff',
        fontWeight: 800,
        marginLeft: 15,
    },
    stepText: {
        '@media all and (min-width: 1500px)': {
            fontSize: '0.875rem',
        },
        '@media all and (max-width: 600px)': {
            fontSize: '0.688rem',
        },
        fontSize: '0.813rem',
        color: '#abd0fe',
        fontWeight: 300,
    },
    stepContents: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1.125rem',
        },
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
        lineHeight: 1.3,
        fontSize: '1rem',
        color: '#fff',
        margin: '14px 0 13px',
        fontWeight: 500,
    },
    stepCheckBtn: {
        '@media all and (min-width: 1500px)': {
            fontSize: '0.938rem',
        },
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
        fontSize: '0.875rem',
        padding: 0,
        background: 'transparent',
        color: '#fff',
        fontWeight: 300,
        '&:hover': {
            background: 'transparent',
        },
    },
    stepBtn: {
        height: 30,
        border: '1px solid #0097ff',
        background: '#fff',
        borderRadius: 7,
        color: '#2078e8',
        fontWeight: 600,
        padding: '0px 15px',
        marginLeft: 'auto',
        '&:hover': {
            fontWeight: 800,
            background: '#fff',
        },
        '& span': {
            fontSize: '1rem',
            '@media all and (max-width: 600px)': {
                fontSize: '0.813rem',
            },
        },
    },
    closeBtn: {
        float: 'right',
        padding: 0,
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
}));

export default function CoachMarkForm(props) {
    const classes = useStyles(props);
    const { open, title, msg, submit, currentStep, totalStep, btnText, arrPosition } = props;
    const [openState, setOpenState] = useState(false);

    useEffect(() => {
        setOpenState(open);
    }, [open]);

    const handleSubmit = () => {
        submit && submit();
        setOpenState(false);
    };

    const handleClose = () => {
        setOpenState(false);
    };

    return (
        <React.Fragment>
            <Box className={classes.explanationBox} hidden={!openState}>
                <IconButton className={classes.closeBtn} onClick={handleClose} disableRipple>
                    <ModalCloseIcon />
                </IconButton>
                <Box className={classes.titleBox}>
                    <Typography className={classes.stepText}>
                        단계 {currentStep} of {totalStep}
                    </Typography>{' '}
                    <Typography className={classes.title}>{title}</Typography>
                </Box>
                <Typography className={classes.stepContents}>{msg}</Typography>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                    {/*<Button*/}
                    {/*    className={classes.stepCheckBtn}*/}
                    {/*    startIcon={<PasswordNumberUnCheckedIcon />}*/}
                    {/*    onClick={this.handleCloseExplanationBox}*/}
                    {/*    disableRipple*/}
                    {/*>*/}
                    {/*    더 이상 안보기*/}
                    {/*</Button>*/}
                    <Button className={classes.stepBtn} onClick={handleSubmit} disableRipple>
                        {btnText ? btnText : '확인'}
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}
