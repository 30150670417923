import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Box, Button, DialogActions, DialogContent, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as Close } from '../../../common/images/Close.svg';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { PATH_UTIL } from '../../../common/util/path.util';

const useStyles = makeStyles(theme => ({
    dialogBox: {
        '& .MuiDialogContent-root': {
            padding: 0,
        },
        '& .MuiDialogActions-root': {
            padding: 16,
        },
    },
    dialogTitleBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 16,
        color: '#303030',
        fontWeight: 800,
    },
    textStyle: {
        borderTop: '1px solid #bfbfbf',
        borderBottom: '1px solid #bfbfbf',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#303030',
        lineHeight: 2,
        padding: '16px 20px',
        wordBreak: 'keep-all',
        alignContent: 'center',
    },
    btnStyle: {
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor),
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#fff',
        padding: '8px 12px',
        '&:hover': {
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor),
        },
    },
    emailText: {
        fontWeight: 600,
        textDecoration: 'underline',
    },
    reSendText: {
        color: '#0097ff',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
        marginLeft: '10px',
    },
}));

export default function EmailResendDialog({ ...props }) {
    const classes = useStyles();
    const { open, title, changeDialogOpen, userEmail, moveTo, resendEmail } = props;

    const handleDialogClose = () => {
        changeDialogOpen(false);
        moveTo && moveTo();
    };

    const path = PATH_UTIL.getPath(window.location.pathname);

    return (
        <Dialog
            open={open}
            // open={true}
            // fullWidth={true}
            // maxWidth={'xs'}
            className={classes.dialogBox}
        >
            <DialogTitle>
                <Box className={classes.dialogTitleBox}>
                    <Typography className={classes.titleStyle}>{title}</Typography>

                    <IconButton
                        onClick={() => {
                            handleDialogClose();
                        }}
                        style={{ padding: 5 }}
                    >
                        <Close style={{ width: 20, height: 20 }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Typography className={classes.textStyle}>
                        {path === 'signup' && (
                            <>
                                이미 등록된 이메일 주소입니다.
                                <br />
                                <br />
                            </>
                        )}
                        계정을 활성화하려면 아래 이메일로 보내드린 인증 메일을 확인해주세요.
                        <br />
                        <Typography display={'inline'} className={clsx(classes.emailText)}>
                            {userEmail}
                        </Typography>
                        <br />
                        <br />
                        <Typography>
                            인증 메일을 못 받으셨나요?
                            <Typography component={'a'} className={clsx(classes.reSendText)} onClick={() => resendEmail()}>
                                다시 받기
                            </Typography>
                        </Typography>
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleDialogClose();
                    }}
                    className={classes.btnStyle}
                >
                    <FormattedMessage id="ok" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
