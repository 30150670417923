export const ApiErrorCode = {
    NotAcceptableId: 'NotAcceptableId',
    AuthenticationFail: 'AuthenticationFail',
    CanNotFoundUser: 'CanNotFoundUser',
    CanNotFoundUserConfigs: 'CanNotFoundUserConfigs',
    CanNotFoundUserAvatar: 'CanNotFoundUserAvatar',
    CanNotFoundData: 'CanNotFoundData',
    CanNotFoundSurvey: 'CanNotFoundSurvey',
    CanNotInsertData: 'CanNotInsertData',
    DataMakeFail: 'DataMakeFail',
    CanNotUpdate: 'CanNotUpdate',
    CanNotDelete: 'CanNotDelete',
    FailedTransfer: 'FailedTransfer',
    BadRequest: 'BadRequest',
    GoogleReCaptchaAuthenticationFail: 'GoogleReCaptchaAuthenticationFail',
    FailedKakaoCommunication: 'FailedKakaoCommunication',
    FailedKakaoAccessToken: 'FailedKakaoAccessToken',
    FailedParsingJson: 'FailedParsingJson',
    NotAllowedPlatform: 'NotAllowedPlatform',
    NotRegisteredLmsUser: 'NotRegisteredLmsUser',
    NotFoundKakaoData: 'NotFoundKakaoData',
    AlreadyRegisteredLmsUser: 'AlreadyRegisteredLmsUser',
    CanNotInsertRoom: 'CanNotInsertRoom',
    CanNotInsertRoomMembers: 'CanNotInsertRoomMembers',
    CanNotReserveRoom: 'CanNotReserveRoom',
    CanNotRequestCreateRoom: 'CanNotRequestCreateRoom',
    FailedModifyUserInfo: 'FailedModifyUserInfo',
    FailedModifyUserPassword: 'FailedModifyUserPassword',
    IncorrectPassword: 'IncorrectPassword',
    FailedCreateGroupFileFolder: 'FailedCreateGroupFileFolder',
    FailedDeleteGroupFileFolder: 'FailedDeleteGroupFileFolder',
    FailedModifyGroupFileFolder: 'FailedModifyGroupFileFolder',
    FailedCreateFile: 'FailedCreateFile',
    FailedInsertFileInfo: 'FailedInsertFileInfo',
    FailedFileDownload: 'FailedFileDownload',
    NotAllowedExtension: 'NotAllowedExtension',
    NotAllowedFilePostType: 'NotAllowedFilePostType',
    CanNotSendEmail: 'CanNotSendEmail',
    FailedCreateUserEmailAuthentication: 'FailedCreateUserEmailAuthentication',
    FailedSendEmail: 'FailedSendEmail',
    AlreadyExistName: 'AlreadyExistName',
    Unknown: 'Unknown',
};
