import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel';
import NoticeDialogComponent from '../dialog/NoticeDialogComponent';
import { inject, observer } from 'mobx-react';
import MoreButton from '../../components/common/MoreButton';
import { FormattedMessage, injectIntl } from 'react-intl';
import YesOrNoDialog from '../../components/common/YesOrNoDialog';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import { getNoticeContents } from '../../common/Validation';

const styles = _theme => ({
    root: {
        marginBottom: 30,
        '& .focusRing___1airF': {
            outlineStyle: 'none !important',
        },
    },
    carousel: {
        position: 'relative',
        border: '1px solid #C4C4C4',
        borderRadius: 7,
        boxShadow: '0 1px 1px 0 #BEBEBE',
        height: 135,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #D3D7DB',
        padding: '10px 14px',
        '& h4': {
            textAlign: 'flex-start',
            width: '100%',
            fontSize: '1.063rem',
            fontWeight: 600,
            marginRight: '18px',
            '& span': {
                fontSize: '0.75em',
                color: '#004FC4',
                backgroundColor: '#E5F0FF',
                borderRadius: 30,
                padding: '4px 6px',
                marginRight: 7,
            },
        },
    },
    btnStyle: {
        fontSize: '0.875rem',
        padding: 0,
        border: '1px solid #BFBFBF',
        borderRadius: 3,
        minWidth: '2.8rem',
        '& span': {
            display: 'initial',
        },
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    iconButton: {
        padding: 0,
        marginLeft: 15,
        '&:hover': {
            background: 'transparent',
        },
    },
    contentsBox: {
        fontSize: '0.938rem',
        lineHeight: '1.45',
        overflowY: 'auto',
        height: 80,
        padding: '10px 16px 10px',
        boxSizing: 'border-box',
        wordBreak: 'break-all',
        '& ul': {
            paddingBottom: 20,
            whiteSpace: 'pre-wrap',
        },
        '& ul, ol': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#bebebe',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '3px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
    },
    dotGroup: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 12,
        '&>button': {
            backgroundColor: '#fff',
            width: 8,
            height: 8,
            borderRadius: 8,
            border: '1px solid #C4C4C4',
            padding: 0,
            margin: '0 3px',
        },
        '&>button.carousel__dot--selected': {
            backgroundColor: '#6D6D70',
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
    },
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
    moreStyle: {
        marginLeft: 15,
        '& .MuiIconButton-label': {
            width: 18,
            height: 18,
        },
        '& .MuiIconButton-root': {
            padding: 0,
        },
    },
    emptyNoticeBox: {
        border: '1px solid #D3D7DB',
        borderRadius: 5,
    },
    emptyNoticeTitle: {
        opacity: 0.5,
        fontSize: '0.938rem !important',
        fontWeight: 600,
    },
});

class ClassNoticeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            isCreate: true,
            deleteDialogOpen: false,
            maxNoticeDialogOpen: false,
        };
    }

    componentDidMount() {
        const groupId = this.props.classPath['id'];
        this.props.classStore.getGroupNoticeList(groupId);
    }

    handleClickOpen = (_e, noticeCount) => {
        if (noticeCount > 2) {
            this.setState({ maxNoticeDialogOpen: true });
        } else {
            this.setState({ dialogOpen: true, isCreate: true });
        }
    };

    handleClose = () => {
        this.setState({
            dialogOpen: false,
        });
    };

    handleDeleteDialogOpen = notice => {
        this.props.classStore.setNotice(notice);
        this.setState({ deleteDialogOpen: true });
    };
    handleDeleteDialogClose = () => {
        this.setState({ deleteDialogOpen: false });
    };

    handleNoticeModify = notice => {
        this.props.classStore.setEditNotice(notice);
        this.setState({ dialogOpen: true, isCreate: false });
    };

    handleNoticeDelete = () => {
        this.props.classStore.deleteGroupNotice();
    };

    render() {
        const { classes, isMember, intl } = this.props;
        const { groupUserAuthority } = this.props.classStore;
        const { noticeList } = this.props.classStore.selectedGroupDetail;
        const slidesTotalCount = noticeList.length > 0 ? noticeList.length : 1;

        return (
            <div className={classes.root}>
                {noticeList.length > 0 ? (
                    <CarouselProvider
                        naturalSlideWidth={100}
                        naturalSlideHeight={19}
                        totalSlides={slidesTotalCount}
                        visibleSlides={1}
                        step={1}
                        infinite={false}
                        dragEnabled={false}
                        isPlaying={true}
                        interval={5000}
                    >
                        <Slider className={classes.carousel}>
                            {noticeList.map((notice, i) => (
                                <Slide key={notice.noticeId} index={i}>
                                    <Box className={classes.title}>
                                        <Box>
                                            <Typography variant="h4">
                                                <span>
                                                    <FormattedMessage id={'simpleNotice'} />
                                                </span>
                                            </Typography>
                                        </Box>
                                        <Box width={'100%'}>
                                            <Typography variant="h4">{notice.title}</Typography>
                                        </Box>
                                        {groupUserAuthority.GroupNotice && (
                                            <>
                                                <Box display="flex" alignItems="center">
                                                    <Button
                                                        className={classes.btnStyle}
                                                        onClick={e => this.handleClickOpen(e, noticeList.length)}
                                                        disableRipple
                                                    >
                                                        <FormattedMessage id={'registration'} />
                                                    </Button>
                                                    <Box className={classes.moreStyle}>
                                                        <MoreButton
                                                            menuItems={[
                                                                {
                                                                    name: intl.formatMessage({ id: 'modify' }),
                                                                    menuFunc: () => this.handleNoticeModify(notice),
                                                                },
                                                                {
                                                                    name: intl.formatMessage({ id: 'delete' }),
                                                                    menuFunc: () => this.handleDeleteDialogOpen(notice),
                                                                },
                                                            ]}
                                                        />
                                                    </Box>
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                    <Box className={classes.contentsBox}>
                                        <ul>
                                            <li>{getNoticeContents(notice.content)}</li>
                                        </ul>
                                    </Box>
                                </Slide>
                            ))}
                        </Slider>
                        <DotGroup className={classes.dotGroup} />
                    </CarouselProvider>
                ) : (
                    isMember &&
                    groupUserAuthority.GroupNotice && (
                        <Box className={classes.emptyNoticeBox}>
                            <Box className={classes.title}>
                                <Typography variant="h4" className={classes.emptyNoticeTitle}>
                                    <span>
                                        <FormattedMessage id={'simpleNotice'} />
                                    </span>
                                    <FormattedMessage id={'msg.no_notice'} />
                                </Typography>
                                <Box display="flex" alignItems="center">
                                    <Button className={classes.btnStyle} onClick={this.handleClickOpen} disableRipple>
                                        <FormattedMessage id={'registration'} />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )
                )}

                <NoticeDialogComponent handleClose={this.handleClose} dialogOpen={this.state.dialogOpen} isCreate={this.state.isCreate} />
                <ConfirmDialog
                    open={this.state.maxNoticeDialogOpen}
                    title={'공지'}
                    msg={'공지사항은 3개까지 등록이 가능합니다. 이전 공지사항을 삭제하고 신규 등록해주세요.'}
                    changeDialogOpen={() => this.setState({ maxNoticeDialogOpen: false })}
                />
                <YesOrNoDialog
                    open={this.state.deleteDialogOpen}
                    title={'공지'}
                    msg={'공지사항을 삭제하시겠습니까?'}
                    changeDialogOpen={this.handleDeleteDialogClose}
                    submit={this.handleNoticeDelete}
                />
            </div>
        );
    }
}

export default withStyles(styles)(inject('authStore', 'classStore')(injectIntl(observer(ClassNoticeComponent))));
