import React, { Component } from 'react';
import { withRouter } from '../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { MixedPageView } from 'nl-lib';
// import { MixedPageView } from '../../nl-lib';

class NeoPenViewer extends Component {
    render() {
        const nPS = this.props.neoPenStore;

        const pens = [...nPS.pens];

        const { section, owner, book, page } = nPS.activePage;
        const activePage = { section, owner, book, page };

        console.log('NeoPenViewer activePage : ', activePage.page, activePage, nPS.replayStorage);

        return (
          <MixedPageView id="main"
                         key={"main-MixedPageView"}
                         pens={pens}
                         pageInfo={activePage}
                         basePageInfo={activePage}
                         isMainView={true}
                         padding={25}
                         fromStorage={false}
                         noMorePdfSignal={false}
                         renderCountNo={0}

                         disableAutoPageChange={false}

                         disableMousePen={false}
                         disableAutoFocus={false}
                         disablePanZoom={true}
                         showGrid={false}

                         onPdfPageFault={nPS.onPdfPageFault}

                         disableViewerOption={true}
                         laserPointer={nPS.isLaserEnabled}

                         surfaceOwnerIds={undefined}
                         writerIds={undefined}
                         debugInfo={false}
                         fitType={nPS.zoomFitType}

                         pageChangerWriters={nPS.pageChangerWriters}

                         onZoomChanged={nPS.onZoomChanged}
                         onViewPageChanged={nPS.setActivePage}

                         zoom={nPS.zoom}
            // hidden={nPS.activePage === undefined}

                         enablePlateInput={true}
                         pageInfos={[activePage]}

                         opacityConverter={nPS.opacityConverterFunc}

                         // playState={PLAYSTATE.stop}
                         // replayStorage={nPS.replayStorage}
                         // forceDeltaTime={nPS.forceDeltaTime}
                         // replaySpeed={1}

                         onNcodePageChanged={undefined} parentName={'mixed-main'} rotation={0} setViewFitFn={undefined}/>
            // <ViewControlSample
            //     id="main"
            //     key="replay-main-viewer"
            //     pens={pens}
            //     activePage={activePage}
            //     disableAutoPageChange={true}
            //     disableMousePen={false}
            //     // disableAutoFocus={false}
            //     disablePanZoom={true}
            //     showGrid={false}
            //     onPdfPageFault={nPS.onPdfPageFault}
            //     // disableViewerOption={true}
            //     // laserPointer={nPS.isLaserEnabled}
            //
            //     surfaceOwnerIds={undefined}
            //     // writerIds={undefined}
            //     debugInfo={false}
            //     fitType={nPS.zoomFitType}
            //     // pageChangerWriters={nPS.pageChangerWriters}
            //
            //     onZoomChanged={nPS.onZoomChanged}
            //     onViewPageChanged={nPS.setActivePage}
            //     zoom={nPS.zoom}
            //     enablePlateInput={true}
            //     // pageInfos={[activePage]}
            //
            //     // playState={PLAYSTATE.stop}
            //     // replayStorage={nPS.replayStorage}
            //     // forceDeltaTime={nPS.forceDeltaTime}
            //     // replaySpeed={1}
            //
            //     opacityConverter={nPS.opacityConverterFunc}
            //     isPriorityView={true}
            // />
        );
    }
}

export default withRouter(inject('neoPenStore')(observer(NeoPenViewer)));
