import galleryImg1 from '../../../common/images/galleryImg1.png';
import galleryImg2 from '../../../common/images/galleryImg2.png';
import galleryImg3 from '../../../common/images/galleryImg3.png';
import galleryImg4 from '../../../common/images/galleryImg4.png';
import galleryImg5 from '../../../common/images/galleryImg5.png';
import galleryImg6 from '../../../common/images/galleryImg6.png';
import galleryImg7 from '../../../common/images/galleryImg7.png';

const img = [
    { id: 1, image: galleryImg1, title: 'image1' },
    { id: 2, image: galleryImg2, title: 'image2' },
    { id: 3, image: galleryImg3, title: 'image3' },
    { id: 4, image: galleryImg4, title: 'image4' },
    { id: 5, image: galleryImg5, title: 'image5' },
    { id: 6, image: galleryImg6, title: 'image6' },
    { id: 7, image: galleryImg7, title: 'image7' },
];

export default img;
