import { Box, Button, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';

const styles = () => ({
    root: {
        '& .MuiButton-root': {
            minWidth: 40,
        },
    },
    breadcrumbsBtnStyle: {
        '&:hover': {
            background: 'transparent',
        },
    },
    tableBtnStyle: {
        '&:hover': {
            background: 'transparent',
        },
    },
});

class PaperGroupFolderBreadcrumbs extends Component {
    render() {
        const { classes } = this.props;

        const nPS = this.props.neoPenStore;

        let breadcrumbs = [];
        let tempFolder = nPS.currentFolder;

        while (tempFolder !== undefined && tempFolder.id !== null) {
            let currentFolder = tempFolder;

            breadcrumbs.push(
                <Box display="flex" alignItems="center">
                    <Button
                        className={classes.breadcrumbsBtnStyle}
                        disableRipple
                        key={tempFolder.id + 'breadcrumbs'}
                        onClick={() => nPS.selectFolder(currentFolder)}
                    >
                        {tempFolder.name}
                    </Button>
                    {breadcrumbs.length !== 0 && <Typography> > </Typography>}
                </Box>,
            );

            tempFolder = nPS.folders[tempFolder.parentId];
        }

        breadcrumbs = breadcrumbs.reverse();

        return (
            <Box display="flex" alignItems="center" className={classes.root}>
                <Button onClick={() => nPS.selectFolder(nPS.folders[null])} className={classes.tableBtnStyle} disableRipple>
                    TOP |
                </Button>
                {breadcrumbs.length > 0 && breadcrumbs}
            </Box>
        );
    }
}

export default withStyles(styles)(inject('neoPenStore')(injectIntl(observer(PaperGroupFolderBreadcrumbs))));
