import React, { Component } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MobileAttendanceCheck from './attendanceCheck/MobileAttendanceCheck';
// import MobileQuestions from './questions/MobileQuestions';
import MobileQuizResult from './quiz/MobileQuizResult';
import MobileMemberActivity from './memberActivity/MobileMemberActivity';
import MobileConnectStatus from './ConnectStatus/MobileConnectStatus';
import MobileDevice from './device/MobileDevice';
import ImagePopupModal from './memberActivity/ImagePopupModal';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
// import MobileStatisticsSurveyResult from './survey/MobileStatisticsSurveyResult';
import { withRouter } from '../../components/WithRouter';
import { ReactComponent as StudyLiveTopBarLogo } from '../../common/images/StudyLiveTopBarLogo.svg';

const style = theme => ({
    root: {
        width: '100%',
    },
    topBar: {
        background: '#fff',
        borderBottom: '1px solid #c0c2c3',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '15px 22px',
        boxSizing: 'border-box',
    },
    contents: {
        padding: '28px 10px',
    },
    titleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        fontWeight: 800,
        color: '#0d0d0d',
        marginBottom: 25,
        padding: '0 20px',
    },
    borderBottomStyle: {
        borderBottom: '2px solid #e2ebf1',
        paddingBottom: 30,
        marginBottom: 30,
    },
});

class MobileStatistics extends Component {
    constructor(props) {
        super(props);
        this.props.statisticsStore.getStatistics(props.params.roomId);
    }

    // componentDidMount() {
    //     const { topBarStore, params } = this.props;
    //     if (window.location.pathname === `/statistics/${params.roomId}`) {
    //         topBarStore.setIsSearchBarRender(false);
    //     }
    // }

    render() {
        const { classes } = this.props;
        const { attendanceResultList, userDeviceList, userSummaryList, userHistoryList } = this.props.statisticsStore;

        return (
            <div className={classes.root}>
                <Box className={classes.topBar}>
                    <StudyLiveTopBarLogo />
                </Box>
                <Box className={classes.contents}>
                    <Typography className={classes.titleText}>
                        <FormattedMessage id="statistics_status" />
                    </Typography>
                    <Grid item xs={12} className={classes.borderBottomStyle}>
                        <MobileAttendanceCheck attendanceResultList={attendanceResultList} />
                    </Grid>
                    {/*<Grid item xs={12} className={classes.borderBottomStyle}>*/}
                    {/*    <MobileQuestions />*/}
                    {/*</Grid>*/}

                    <Grid item xs={12} className={classes.borderBottomStyle}>
                        <MobileQuizResult />
                    </Grid>
                    {/*<Grid item xs={12} className={classes.borderBottomStyle}>*/}
                    {/*    <MobileStatisticsSurveyResult />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} className={classes.borderBottomStyle}>
                        <MobileConnectStatus userHistoryList={userHistoryList} />
                    </Grid>
                    <Grid item xs={12} className={classes.borderBottomStyle}>
                        <MobileDevice userDeviceList={userDeviceList} />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: 40 }}>
                        <MobileMemberActivity userSummaryList={userSummaryList} />
                    </Grid>
                    <ImagePopupModal />
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(inject('statisticsStore', 'topBarStore')(observer(MobileStatistics))));
