import { makeAutoObservable } from 'mobx';

export const DEFAULT_ROWS_PER_PAGE = 5;
export const DEFAULT_PAGE = 0;

export const SORT_TYPE = {
    time: 'time',
    recent: 'recent',
    name: 'name',
    createdDatetime: 'createdDatetime',
    updateDatetime: 'updatedDatetime',
};

export const INIT_EMPTY_SEARCH_KEYWORD = '';

export default class PaginationStore {
    constructor() {
        makeAutoObservable(this);
    }

    rowsPerPage = DEFAULT_ROWS_PER_PAGE;
    page = DEFAULT_PAGE;
    totalCount = 0;
    sort = SORT_TYPE.name;
    searchKeyword = INIT_EMPTY_SEARCH_KEYWORD;

    get isSearching() {
        return this.searchKeyword !== '';
    }

    changeSearchKeyword = keyword => {
        this.searchKeyword = keyword;
    };

    changeSort = sortType => {
        this.sort = sortType;
    };

    changePage = page => {
        this.page = page;
    };

    changeRowsPerPage = rowsPerPage => {
        this.rowsPerPage = rowsPerPage;
    };

    changeTotalCount = count => {
        this.totalCount = count;
    };

    initPagination = () => {
        this.rowsPerPage = DEFAULT_ROWS_PER_PAGE;
        this.page = DEFAULT_PAGE;
        this.sort = SORT_TYPE.name;
        this.searchKeyword = INIT_EMPTY_SEARCH_KEYWORD;
    };
}
