import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Tab, Tabs, Tooltip } from '@material-ui/core';
import AddGroupDialogComponent from '../dialog/AddGroupDialogComponent';
import InvitationDialogComponent from '../dialog/InvitationDialogComponent';
import InvitationStudentsDialogComponent from '../dialog/InvitationStudentsDialogComponent';
import { ReactComponent as FolderPlusIcon } from '../../common/images/FolderPlusFillIcon.svg';
import ClassMemberManagementComponent from './ClassMemberManagementComponent';
import ClassMemberGroupManagementComponent from './ClassMemberGroupManagementComponent';
import MemberGroupSearchDialogComponent from '../dialog/MemberGroupSearchDialogComponent';
import { inject, observer } from 'mobx-react';
import { checkSpace, checkTextValueLength } from '../../common/Validation';
import { ToastsStore } from 'react-toasts';
import { injectIntl } from 'react-intl';
import { TEAM_OPERATION } from '../../stores/ClassTeamStore';
import { withRouter } from '../../components/WithRouter';
import { PATH_UTIL } from '../../common/util/path.util';
import { ClassMainPath } from '../../common/ClassMainPath';
import { ReactComponent as DownloadSimpleIcon } from '../../common/images/DownloadSimpleIcon.svg';
import { ReactComponent as MemberListStudent } from '../../common/images/MemberListStudent.svg';
import { ReactComponent as MemberListLink } from '../../common/images/MemberListLink.svg';
import { DATE_UTIL } from '../../common/util/date.util';
import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE } from '../../stores/PaginationStore';
import { USER_TYPE } from '../../stores/UserStore';
import CoachMarkForm from '../../components/common/CoachMarkForm';
import WithInfiniteScroll from '../../components/WithInfiniteScroll';

const styles = theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
        '& ul, ol': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        '&:after': {
            content: '""',
            width: '100%',
            height: 3,
            backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: -1,
        },
        '& button': {
            minWidth: 100,
            fontSize: '0.938rem',
            '&.Mui-selected': {
                fontWeight: 700,
                color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
            },
        },
        '& .MuiTabs-indicator': {
            height: 3,
            backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    optionBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 9,
        '& button': {
            marginLeft: 14,
        },
    },
    btnStyle: {
        border: () => (theme.configs.MainBtnColor ? `1px solid ${theme.configs.MainBtnColor}` : '1px solid #0097FF'),
        borderRadius: 7,
        color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        padding: '7px 23px',
        fontWeight: 600,
        '&:hover': {
            background: 'transparent',
        },
    },
    popoverBox: {
        '& li': {
            '&:hover': {
                background: '#d3d7db',
            },
        },
        '& .MuiListItemIcon-root': {
            minWidth: 20,
        },
        '& .MuiListItemText-root': {
            '& span': {
                fontSize: '0.813rem',
                color: '#000',
            },
        },
    },
    btnStyle2: {
        '&:hover': {
            background: 'transparent',
        },
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
    },
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
    addMemberCoachBox: {
        position: 'absolute',
        '@media all and (min-width: 1500px)': {
            top: 40,
            left: -95,
        },
        top: 40,
        left: -95,
    },
});

const MEMBER_COMPONENT_TAB_NAME = {
    Member: 'member',
    Group: 'group',
};

const ClassMemberManagementComponentForwardingRef = React.forwardRef((props, ref) => (
    <ClassMemberManagementComponent {...props} forwardedRef={ref} />
));

const ClassMemberGroupManagementComponentForwardingRef = React.forwardRef((props, ref) => (
    <ClassMemberGroupManagementComponent {...props} forwardedRef={ref} />
));

const LightTooltip = withStyles(_theme => ({
    tooltip: {
        padding: '4px 4px',
        border: '0.3px solid #000000',
        background: '#fffff5',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        color: '#000',
        borderRadius: '0',
        marginLeft: 5,
        marginTop: 5,
    },
}))(Tooltip);

class MemberListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: MEMBER_COMPONENT_TAB_NAME.Member,
            invitationDialogOpen: false,
            invitationStudentDialogOpen: false,
            addGroupDialogOpen: false,
            memberGroupSearch: false,
            anchorEl: null,
        };

        this.groupTeamsInterSectRef = React.createRef();
        this.groupUsersInterSectRef = React.createRef();
        this.groupId = PATH_UTIL.getClassId(this.props.location.pathname);
    }

    componentDidMount() {
        const { loginUser, classTeamStore, managementStore, classStore } = this.props;

        classTeamStore.getTeamsTotalCount(this.groupId);

        managementStore.getAllUsers(loginUser.email);

        classStore.getAllGroupUsers(loginUser.email, this.groupId);

        classStore.getGroupInvitee(loginUser.email, this.groupId);
    }

    componentDidUpdate(_prevProps, prevState, _snapshot) {
        const { classStore, classTeamStore } = this.props;
        const initializeList = [];
        if (prevState.tabs !== this.state.tabs) {
            if (this.state.tabs === MEMBER_COMPONENT_TAB_NAME.Member) {
                classTeamStore.resetTeams(initializeList);
            } else {
                classStore.resetSelectedGroupUsers(initializeList);
            }
        }
    }

    componentWillUnmount() {
        const { classStore, classTeamStore } = this.props;
        classStore.initGroupInvitees();
        classStore.initSelectedGroupUsers();
        classTeamStore.initTeams();
    }

    fetchGroupUsers = page => {
        const { classStore, loginUser, paginationStore } = this.props;
        const { rowsPerPage, sort } = paginationStore;
        classStore.getGroupUsers(loginUser.email, this.groupId, page, rowsPerPage, sort, {
            successAction: groupUsers => {
                if (page > 0) {
                    classStore.addSelectedGroupUsers(groupUsers);
                } else {
                    classStore.resetSelectedGroupUsers(groupUsers);
                }
            },
        });
    };

    fetchGroupTeams = page => {
        const { classTeamStore, loginUser, paginationStore } = this.props;
        const { rowsPerPage, sort } = paginationStore;
        classTeamStore.getTeams(this.groupId, loginUser.email, page, rowsPerPage, sort, {
            successAction: teams => {
                if (page > 0) {
                    classTeamStore.addTeams(teams);
                } else {
                    classTeamStore.resetTeams(teams);
                }
            },
        });
    };

    handleChangeInvitationDialogOpen = () => {
        const { classes, loginUser, classStore } = this.props;
        classStore.createInviteAuthentication(loginUser.email, {
            openInviteDialog: () => {
                this.setState({
                    invitationDialogOpen: true,
                    anchorEl: null,
                });
            },
            alert: () => {
                ToastsStore.info('서버와의 통신이 원할하지 않습니다. 다시 시도해 주세요.', 3000, classes.toasts);
            },
        });
    };

    handleChangeAddGroupDialogOpen = () => {
        this.setState({ addGroupDialogOpen: true });
    };

    handleChangeMemberGroupSearch = () => {
        this.setState({ memberGroupSearch: true });
    };

    handleClose = () => {
        this.setState({
            tabs: MEMBER_COMPONENT_TAB_NAME.Member,
            anchorEl: null,
            invitationDialogOpen: false,
            invitationStudentDialogOpen: false,
            addGroupDialogOpen: false,
            memberGroupSearch: false,
        });
    };

    handleTabChange = (_event, tabs) => {
        this.setState({ tabs });
    };

    handleChangeTeamName = e => {
        checkSpace(e);
        checkTextValueLength(e);
        this.props.classTeamStore.changeTeamName(e.target.value);
    };

    handleChangeTeamMembers = member => {
        this.props.classTeamStore.changeTeamMembers(member);
    };

    handleInitTeamStore = () => {
        this.props.classTeamStore.initTeamStore();
    };

    reRequestTeams = () => {
        const { classStore, classTeamStore, paginationStore, loginUser } = this.props;
        classTeamStore.getTeams(this.groupId, loginUser.email, DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE, paginationStore.sort, {
            successAction: teams => {
                classTeamStore.resetTeams(teams);
                classStore.setGroupTeams(teams);
                paginationStore.changePage(DEFAULT_PAGE);
            },
        });
    };

    reRequestGroupUsers = () => {
        const { classStore, paginationStore, loginUser } = this.props;
        const { sort } = paginationStore;
        classStore.getGroupUsers(loginUser.email, this.groupId, DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE, sort, {
            successAction: groupUsers => {
                classStore.resetSelectedGroupUsers(groupUsers);
                paginationStore.changePage(DEFAULT_PAGE);
            },
        });
    };

    handleCreateTeam = () => {
        const { classes, intl, classStore, classTeamStore } = this.props;
        const { selectedGroupDetail } = classStore;

        const { team } = classTeamStore;
        const isExistTeamName = selectedGroupDetail.teamList.findIndex(t => t.name === team.name) !== -1;
        if (isExistTeamName) {
            ToastsStore.error(intl.formatMessage({ id: 'msg.already_exist_name' }), 3000, classes.toasts);
        } else {
            classTeamStore.createTeam(selectedGroupDetail.group.id, {
                getTeams: () => {
                    this.reRequestTeams();
                },
                closeAddGroupDialog: () => {
                    this.handleCloseAddGroupDialog();
                },
                alertError: () => {
                    ToastsStore.error(intl.formatMessage({ id: 'msg.already_exist_name' }), 3000, classes.toasts);
                },
            });
        }
    };

    handleCloseAddGroupDialog = () => {
        this.setState({ addGroupDialogOpen: false });
        this.handleInitTeamStore();
    };

    handleCloseMemberSearchDialog = () => {
        this.setState({ memberGroupSearch: false });
    };

    handleCloseInvitationDialog = () => {
        this.setState({ invitationDialogOpen: false });
    };

    handleCloseInvitationStudentDialog = () => {
        this.setState({ invitationStudentDialogOpen: false });
    };

    handleClickInvitationStudentDialog = () => {
        this.setState({
            invitationStudentDialogOpen: true,
            anchorEl: null,
        });
    };

    handleSetTeamInfo = team => {
        this.props.classTeamStore.setTeamInfo(team);
    };

    handleDeleteTeam = () => {
        const { loginUser, classTeamStore } = this.props;
        classTeamStore.deleteTeam(loginUser.email, {
            getTeams: () => {
                this.reRequestTeams();
            },
        });
    };

    handleModifyTeam = () => {
        const { loginUser, intl, classes, classTeamStore } = this.props;
        classTeamStore.modifyTeam(loginUser.email, {
            getTeams: () => {
                this.reRequestTeams();
            },
            alertError: () => {
                ToastsStore.error(intl.formatMessage({ id: `${classTeamStore.msgId}` }), 3000, classes.toasts);
            },
            closeAddGroupDialog: () => {
                this.handleCloseAddGroupDialog();
            },
        });
    };

    requestViewUserRegisteredSchedules = user => {
        const { roomStore } = this.props;
        roomStore.setSelectedRoomOwner(user);

        this.props.setClassificationView();
    };

    requestChangeInviteAuthenticationExpireTime = callbacks => {
        const { loginUser, roomStore, classStore } = this.props;
        const invitationExpireDatetime = DATE_UTIL.convertTimeZoneToUTC(roomStore.room.endDatetime);

        classStore.modifyInviteAuthenticationExpireDatetime(loginUser.email, invitationExpireDatetime, callbacks);
    };

    requestUserIdsInLiveRooms = () => {
        const { classStore } = this.props;
        classStore.getUserIdsInLiveRooms(this.groupId, {
            alertMessage: () => {
                ToastsStore.info('현재 진행중인 일정이 없습니다.', 1500);
            },
        });
    };

    handleChangeSortAndFetchData = sortType => {
        const { paginationStore } = this.props;
        paginationStore.changeSort(sortType);

        if (this.state.tabs === MEMBER_COMPONENT_TAB_NAME.Member) {
            this.reRequestGroupUsers();
        } else {
            this.reRequestTeams();
        }
    };

    handleLeaveGroup = () => {
        const { loginUser, classStore, navigate } = this.props;
        classStore.requestLeaveGroup(loginUser.email, classStore.selectedGroupId, {
            doSuccessAction: () => {
                navigate('/rooms');
            },
            alert: () => {
                ToastsStore.warning('잘못된 요청입니다. 클래스 정보를 확인해주세요.', 1500);
            },
            error: () => {
                ToastsStore.error('서버와의 연결이 원활하지 않습니다. 잠시 후 다시 시도해 주세요.', 1500);
            },
        });
    };

    findAvatar = user => {
        if (!user.image) {
            this.props.avatarStore.findAvatar(user.userId, {
                setImage: data => {
                    user.image = data;
                },
            });
        }
    };

    handleClickMember = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleCloseMember = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClickExcelExportBtn = () => {
        if (this.props.requestGroupUserExportExcel) {
            this.props.requestGroupUserExportExcel();
        }
    };

    render() {
        const { classes, loginUser } = this.props;
        const { classStore, managementStore, classTeamStore, paginationStore, userStore } = this.props;
        const {
            groupUsers,
            groupLeaderAndOperatorsFromSelectedGroupUsers,
            groupMembers,
            selectedGroupUsersTotalCount,
            invite,
            groupUserAuthority,
            selectedAllGroupUsers,
            invitees,
        } = classStore;
        const { coachState } = userStore;
        const { groupTeams, teamsTotalCount, team, teamMembers, changedTeamMembers, changeTeamOperation, teamOperation } = classTeamStore;
        const { sort } = paginationStore;
        const { tabs, anchorEl } = this.state;
        const memberOpen = Boolean(anchorEl);
        const groupMembersNotRegisteredTeam = selectedAllGroupUsers.length
            ? selectedAllGroupUsers.filter(m => teamMembers.findIndex(teamMember => teamMember.userId === m.userId) === -1)
            : [];
        const findTabInPath = PATH_UTIL.getMainTabName(this.props.location.pathname);
        return (
            <div className={classes.root}>
                {/*<Box><b>멤버</b> • 1,200명</Box>*/}
                <Tabs value={tabs} onChange={this.handleTabChange} className={classes.tabStyle}>
                    <Tab value={MEMBER_COMPONENT_TAB_NAME.Member} label={`멤버 (${selectedGroupUsersTotalCount})`} disableRipple />
                    <Tab value={MEMBER_COMPONENT_TAB_NAME.Group} label={`그룹 (${teamsTotalCount})`} disableRipple />
                </Tabs>

                <Box className={classes.optionBox}>
                    {groupUserAuthority.GroupMember && (
                        <LightTooltip title={'새 그룹 만들기'} placement={'bottom'}>
                            <IconButton
                                className={classes.btnStyle2}
                                onClick={() => {
                                    changeTeamOperation(TEAM_OPERATION.Create);
                                    this.handleChangeAddGroupDialogOpen();
                                }}
                                disableRipple
                            >
                                <FolderPlusIcon />
                            </IconButton>
                        </LightTooltip>
                    )}
                    {findTabInPath === ClassMainPath.member ? (
                        groupUserAuthority.GroupInvite && (
                            <>
                                <Box style={{ position: 'relative' }}>
                                    <Box className={classes.addMemberCoachBox}>
                                        <CoachMarkForm
                                            open={
                                                !coachState.hasMember &&
                                                coachState.hasGroup &&
                                                coachState.hasChild &&
                                                loginUser.type !== USER_TYPE.Guest
                                            }
                                            title={'학생 초대하기'}
                                            msg={
                                                "클래스 관리에서 등록한 학생을 '멤버 초대하기' \n버튼을 눌러'학생 초대' 목록에서 선택 초대해보\n세요. \n\n학생은 본인 계정으로 로그인 후' 초대 메시지>\n가입' 버튼을 눌러 클래스에 가입할 수 있습니다."
                                            }
                                            currentStep={3}
                                            totalStep={3}
                                            submit={this.handleClickInvitationStudentDialog}
                                        />
                                    </Box>
                                </Box>
                                <Popover
                                    id="simple-popper"
                                    open={memberOpen}
                                    anchorEl={anchorEl}
                                    onClose={this.handleCloseMember}
                                    className={classes.popoverBox}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <MenuItem onClick={this.handleClickInvitationStudentDialog}>
                                        <ListItemIcon>
                                            <MemberListStudent />
                                        </ListItemIcon>
                                        <ListItemText>학생 초대</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={this.handleChangeInvitationDialogOpen}>
                                        <ListItemIcon>
                                            <MemberListLink />
                                        </ListItemIcon>
                                        <ListItemText>링크로 초대</ListItemText>
                                    </MenuItem>
                                </Popover>
                                <Button className={classes.btnStyle} onClick={this.handleClickMember} disableRipple>
                                    멤버 초대하기
                                </Button>
                            </>
                        )
                    ) : (
                        <>
                            <Button className={classes.downloadBtnStyle} disableRipple onClick={this.handleClickExcelExportBtn}>
                                <DownloadSimpleIcon />
                            </Button>
                        </>
                    )}
                </Box>

                {tabs === MEMBER_COMPONENT_TAB_NAME.Member && (
                    <WithInfiniteScroll
                        ref={this.groupUsersInterSectRef}
                        fetchData={this.fetchGroupUsers}
                        isLoading={classStore.isGroupUserLoading}
                        noMore={classStore.isEndGroupUsers}
                    >
                        <ClassMemberManagementComponentForwardingRef
                            isGroupUsersLoading={classStore.isGroupUserLoading}
                            loginUser={loginUser}
                            groupMembers={groupMembers}
                            groupLeaderAndOperators={groupLeaderAndOperatorsFromSelectedGroupUsers}
                            userIdsInLiveRooms={classStore.findUserIdsInLiveRooms}
                            getUserIdsInLiveRooms={this.requestUserIdsInLiveRooms}
                            viewUserRegisteredSchedules={this.requestViewUserRegisteredSchedules}
                            groupUserAuthority={groupUserAuthority}
                            changeSort={this.handleChangeSortAndFetchData}
                            sort={sort}
                            leaveGroup={this.handleLeaveGroup}
                            ref={this.groupUsersInterSectRef}
                        />
                    </WithInfiniteScroll>
                )}
                {tabs === MEMBER_COMPONENT_TAB_NAME.Group && (
                    <WithInfiniteScroll
                        ref={this.groupTeamsInterSectRef}
                        fetchData={this.fetchGroupTeams}
                        isLoading={classTeamStore.isLoading}
                        noMore={classTeamStore.isEndTeams}
                    >
                        <ClassMemberGroupManagementComponentForwardingRef
                            loginUser={loginUser}
                            groupTeams={groupTeams}
                            setTeamInfo={this.handleSetTeamInfo}
                            deleteTeam={this.handleDeleteTeam}
                            openModifyTeamDialog={this.handleChangeAddGroupDialogOpen}
                            initTeamStore={this.handleInitTeamStore}
                            changeTeamOperation={changeTeamOperation}
                            groupUsers={groupUsers}
                            groupUserAuthority={groupUserAuthority}
                            findAvatar={this.findAvatar}
                            selectedTeam={team}
                            isTeamLoading={classTeamStore.isLoading}
                            sort={paginationStore.sort}
                            changeSortAndFetchData={this.handleChangeSortAndFetchData}
                            changeSort={paginationStore.changeSort}
                            ref={this.groupTeamsInterSectRef}
                        />
                    </WithInfiniteScroll>
                )}
                <InvitationDialogComponent
                    classTab={this.props.classTab}
                    invitationDialogOpen={this.state.invitationDialogOpen}
                    handleClose={this.handleCloseInvitationDialog}
                    requestChangeInvitationExpireTime={this.requestChangeInviteAuthenticationExpireTime}
                    invite={invite}
                    setInviteExpireDatetime={classStore.setSelectedInviteExpireDatetime}
                />
                <InvitationStudentsDialogComponent
                    loginUser={loginUser}
                    invitationStudentDialogOpen={this.state.invitationStudentDialogOpen}
                    handleCloseInvitationStudentDialog={this.handleCloseInvitationStudentDialog}
                    isLoading={managementStore.isAllUsersLoading}
                    invitees={invitees}
                />
                <AddGroupDialogComponent
                    operation={teamOperation}
                    loginUser={loginUser}
                    team={team}
                    teamMembers={changedTeamMembers}
                    groupMembers={groupMembersNotRegisteredTeam}
                    addGroupDialogOpen={this.state.addGroupDialogOpen}
                    closeAddGroupDialog={this.handleCloseAddGroupDialog}
                    openMemberGroupSearchDialog={this.handleChangeMemberGroupSearch}
                    changeTeamName={this.handleChangeTeamName}
                    changeTeamMembers={this.handleChangeTeamMembers}
                    createTeam={this.handleCreateTeam}
                    modifyTeam={this.handleModifyTeam}
                />
                <MemberGroupSearchDialogComponent
                    teamMembers={changedTeamMembers}
                    memberGroupSearch={this.state.memberGroupSearch}
                    handleClose={this.handleCloseMemberSearchDialog}
                    changeTeamMembers={this.handleChangeTeamMembers}
                    allGroupUsers={classStore.allGroupUsers}
                />
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(
        inject(
            'classTeamStore',
            'classStore',
            'roomStore',
            'paginationStore',
            'avatarStore',
            'managementStore',
            'authStore',
            'userStore',
        )(injectIntl(observer(MemberListComponent))),
    ),
);
