import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Tab, Tabs } from '@material-ui/core';
import FreeBoardComponent from './MobileFreeBoardComponent';
import WritingDialogComponent from '../../dialog/WritingDialogComponent';
import { inject, observer } from 'mobx-react';
import { ToastsStore } from 'react-toasts';

const styles = theme => ({
    root: {
        width: 'calc(100vw - 32px)',
        paddingBottom: 70,
    },
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        '&:after': {
            content: '""',
            width: '100%',
            height: 3,
            backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: -1,
        },
        '& button': {
            minWidth: 100,
            fontSize: '0.938rem',
            '&.Mui-selected': {
                fontWeight: 700,
                color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
            },
        },
        '& .MuiTabs-indicator': {
            height: 3,
            backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
    optionBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    btnStyle: {
        border: () => (theme.configs.MainBtnColor ? `1px solid ${theme.configs.MainBtnColor}` : '1px solid #0097FF'),
        borderRadius: 7,
        color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        padding: '7px 23px',
        fontWeight: 600,
        '&:hover': {
            fontWeight: 800,
            background: 'transparent',
        },
    },
});

class MobileClassCommunityComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 0,
            writingDialogOpen: false,
            topicDialogOpen: false,
        };
    }

    componentDidMount() {
        this.props.postStore.initPost();
        this.props.postStore.getPostList(this.props.currentTab.id, this.props.authStore.loginUser.id);
    }

    handleChangeWritingDialogOpen = () => {
        this.setState({ writingDialogOpen: true });
    };

    handleClose = () => {
        this.setState({
            writingDialogOpen: false,
            topicDialogOpen: false,
        });
    };

    handleChangeTab = (_event, tabs) => {
        this.setState({ tabs });
    };

    handleChangeTopicDialogOpen = () => {
        this.setState({ topicDialogOpen: true });
    };

    render() {
        const { classes, topicList, postStore, handleClickPostDetail, isScrollEnd, isMember, classTab } = this.props;
        const { postList, totalPostCount, postNoticeCount } = postStore;
        const { tabs } = this.state;

        return (
            <div className={classes.root}>
                <Tabs value={tabs} onChange={this.handleChangeTab} className={classes.tabStyle}>
                    <Tab label="자유 게시판" disableRipple />
                    {/*<Tab label="비밀 게시판" disableRipple />*/}
                </Tabs>
                <Box className={classes.optionBox}>
                    <Button
                        className={classes.btnStyle}
                        disableRipple
                        onClick={
                            isMember
                                ? this.handleChangeWritingDialogOpen
                                : () => ToastsStore.error('클래스에 가입하여야 글쓰기를 사용할 수 있습니다.', 3000)
                        }
                    >
                        글쓰기
                    </Button>
                </Box>
                {tabs === 0 && (
                    <FreeBoardComponent
                        classTab={classTab}
                        handleClickPostDetail={handleClickPostDetail}
                        handleChangeTopicDialogOpen={this.handleChangeTopicDialogOpen}
                        topicList={topicList}
                        totalPostCount={totalPostCount}
                        postNoticeCount={postNoticeCount}
                        posts={postList}
                        isScrollEnd={isScrollEnd}
                    />
                )}
                {/*{tabs === 1 && (*/}
                {/*    <SecretBoardComponent*/}
                {/*        handleChangeNoticeBoard={this.props.handleChangeNoticeBoard}*/}
                {/*        handleChangeTopicDialogOpen={this.handleChangeTopicDialogOpen}*/}
                {/*    />*/}
                {/*)}*/}
                <WritingDialogComponent
                    handleClose={this.handleClose}
                    writingDialogOpen={this.state.writingDialogOpen}
                    postNoticeCount={postNoticeCount}
                />
                {/*<TopicDialogComponent topicList={topicList} topicDialogOpen={this.state.topicDialogOpen} handleClose={this.handleClose} />*/}
            </div>
        );
    }
}

export default withStyles(styles)(inject('postStore', 'authStore')(observer(MobileClassCommunityComponent)));
