import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Chip, Typography } from '@material-ui/core';
import { ReactComponent as ArrowDownTinyIcon } from '../../common/images/ArrowDownTinyIcon.svg';
import { inject, observer } from 'mobx-react';
import { ClassMainPath } from '../../common/ClassMainPath';
import { TagType } from '../../stores/ClassStore';

const styles = _theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 235,
            marginRight: 90,
        },
        width: 230,
        marginRight: 30,
        '&>h3': {
            fontSize: '1.25rem',
            marginBottom: 30,
        },
    },
    chipBox: {
        margin: '30px 0 14px',
        '& > div': {
            backgroundColor: '#eee',
            color: '#656565',
            margin: '0 6px 10px 0',
            fontSize: '0.938rem',
            border: '1px solid #eee',
            cursor: 'pointer',
            '&:hover': {
                border: '1px solid #dbf0ff',
                backgroundColor: '#dbf0ff!important',
                color: '#0097ff!important',
            },
            '&:focus': {
                border: '1px solid #eee',
                backgroundColor: '#eee',
                color: '#656565',
            },
        },
    },
    chipActive: {
        backgroundColor: '#dbf0ff!important',
        color: '#0097ff!important',
    },
    chipBoxList: {
        overflow: 'hidden',
    },
    btnStyle: {
        border: '1px solid #a3a8af',
        padding: '2px 6px',
        fontSize: '0.75rem',
        borderRadius: 2,
        '& svg': {
            marginRight: 4,
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    chip: {
        maxWidth: '100%',
        height: 'auto',
        padding: '2px 0',
        textAlign: 'center',
        '& .MuiChip-label': {
            whiteSpace: 'normal',
        },
    },
});

class ClassTagComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tagListButton: 1,
            chipButton: false,
            selectedTag: false,
        };
    }
    componentDidMount() {
        this.getTagList();
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        const classTab = this.props.classTab;
        if (classTab !== prevProps.classTab && (classTab === ClassMainPath.community || prevProps.classTab === ClassMainPath.community)) {
            this.getTagList();
            this.setState({ tagListButton: 1 });
        }
    }

    componentWillUnmount() {
        this.props.classStore.initTag();
    }

    getTagList = () => {
        const groupId = this.props.classPath.id;
        this.props.classStore.getTagList(
            groupId,
            this.props.classTab !== ClassMainPath.community && this.props.classTab !== ClassMainPath.postDetail ? TagType.ROOM : TagType.POST,
        );
    };

    handleMoreBtnFold = () => {
        this.setState({ tagListButton: 1 });
    };

    handleClick = () => {
        this.setState({
            tagListButton: this.state.tagListButton + 1,
        });
    };

    render() {
        const { classes, roomTagList, handleChangeClassSelectTag, classTab } = this.props;
        const { tagListButton } = this.state;
        const emptyTagListMessage = classTab !== ClassMainPath.community ? '수업에 등록된 태그가 노출됩니다.' : '게시글 태그가 노출됩니다.';

        const sortedRoomTagList = roomTagList.slice().sort(function (a, b) {
            if (a.idList.length > b.idList.length) {
                return -1;
            }
            if (a.idList.length < b.idList.length) {
                return 1;
            }
            return 0;
        });

        return (
            <div className={classes.root}>
                <Typography variant="h3">태그</Typography>
                <Box className={classes.chipBox}>
                    {sortedRoomTagList.length > 0 ? (
                        sortedRoomTagList.map((tag, i) => {
                            if (i < tagListButton * 10) {
                                return (
                                    <Chip
                                        key={tag.tagId}
                                        label={tag.name}
                                        className={clsx(classes.chip, this.props.classSelectTag === tag && classes.chipActive)}
                                        onClick={() => handleChangeClassSelectTag(tag)}
                                    />
                                );
                            }
                        })
                    ) : (
                        <Typography>{emptyTagListMessage}</Typography>
                    )}
                </Box>
                {roomTagList.length > 10 && (
                    <Box>
                        {this.state.tagListButton === 3 || sortedRoomTagList.length <= this.state.tagListButton * 10 ? (
                            <Button className={classes.btnStyle} disableRipple onClick={this.handleMoreBtnFold}>
                                <ArrowDownTinyIcon style={{ transform: 'rotate(180deg)' }} /> 접기
                            </Button>
                        ) : (
                            <Button className={classes.btnStyle} disableRipple onClick={this.handleClick}>
                                <ArrowDownTinyIcon /> 더보기
                            </Button>
                        )}
                    </Box>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(inject('classStore')(observer(ClassTagComponent)));
