import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogContent, IconButton } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
// import SurveyCreateComponent from './survey/SurveyCreateComponent';
import { inject, observer } from 'mobx-react';

const styles = theme => ({
    root: {},
    videoPopup: {
        backgroundColor: 'rgba( 255, 255, 255, 0 )',
        boxShadow: 'none',
        border: 'none',
        textAlign: 'center',
    },
    btnStyle: {
        position: 'absolute',
        right: '-60px',
        top: '-30px',
        '& svg': {
            width: '32px',
            height: '32px',
            '& path': {
                stroke: '#fff',
            },
        },
    },
    videoWrapStyle: {
        position: 'absolute',
        left: 0,
        top: 0,
        transform: 'translate(50%, 50%)',
    },
});

class NotePlayDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Dialog open={this.props.open} fullScreen classes={{ paper: classes.videoPopup }}>
                    <DialogContent style={{ padding: 0, margin: 0 }}>
                        <Box className={classes.videoWrapStyle}>
                            <IconButton className={classes.btnStyle} onClick={this.props.onClose}>
                                <DialogCloseIcon />
                            </IconButton>
                            <video width={this.props.videoWidth} height={this.props.videoHeight} src={this.props.videoSrc} controls />
                        </Box>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(inject('classStore')(observer(NotePlayDialogComponent)));
