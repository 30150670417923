import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as AsideGlobalIcon } from '../../../common/images/AsideGlobalIcon.svg';
import { ReactComponent as AsideUsersThreeIcon } from '../../../common/images/AsideUsersThreeIcon.svg';
import { ReactComponent as AsideUserIcon } from '../../../common/images/AsideUserIcon.svg';
import { ReactComponent as AsideSettingIcon } from '../../../common/images/AsideSettingIcon.svg';
import { Button } from '@material-ui/core';
import { LmsGroupUserType } from '../../../stores/ClassStore';
import { ClassMainPath } from '../../../common/ClassMainPath';

const styles = _theme => ({
    root: {},
    listStyle: {
        fontSize: '1rem',
        display: 'inline-flex',
        '& li': {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '7px!important',
            marginRight: '8px!important',
            fontSize: '0.813rem',
            '@media (min-width: 319px) and (max-width: 374px)': {
                fontSize: '0.75rem',
                letterSpacing: '-0.2px',
            },
            '& svg': {
                marginRight: 4,
                width: 18,
                height: 18,
                '@media (min-width: 319px) and (max-width: 374px)': {
                    width: 14,
                    height: 14,
                    marginRight: 2,
                },
            },
            '& button': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.813rem',
                '& span': {
                    justifyContent: 'flex-start',
                },
            },
        },
    },
    buttonStyle: {
        padding: 0,
        fontSize: '1rem',
        height: 21,
        '&:hover': {
            background: 'transparent',
        },
    },
});

class MobileClassContentInfoComponent extends Component {
    handleChangeSetting = () => {
        this.props.moveTo(ClassMainPath.setting);
    };
    render() {
        const { classes, selectedGroupDetail, isMember } = this.props;

        return (
            <div>
                <ul className={classes.listStyle}>
                    <li>
                        <AsideGlobalIcon /> <span>{selectedGroupDetail.group.public ? '공개' : '비공개'}</span>
                    </li>
                    <li>
                        <AsideUsersThreeIcon /> <span>멤버: {selectedGroupDetail.lmsUser.length + '명'}</span>{' '}
                    </li>
                    <li>
                        <AsideUserIcon />{' '}
                        <span
                            style={{
                                maxWidth: 'calc(100vw - 270px)',
                                textAlign: 'left',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                            }}
                        >
                            교사: {selectedGroupDetail.lmsUser.filter(user => user.type === LmsGroupUserType.leader).map(user => user.name)}
                        </span>{' '}
                    </li>
                    {isMember && (
                        <li>
                            <Button className={classes.buttonStyle} disableRipple onClick={this.handleChangeSetting}>
                                <AsideSettingIcon /> <span>설정</span>
                            </Button>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}

export default withStyles(styles)(MobileClassContentInfoComponent);
