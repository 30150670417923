import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { ReactComponent as MoreIcon } from '../../common/images/DotsThreeOutlineVerticalIcon.svg';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
    tableBtnStyle: {
        // marginLeft: 15,
        '&:hover': {
            background: 'transparent',
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
}));

function MoreButton({ menuItems }) {
    const classes = useStyles();
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            <IconButton
                ref={anchorRef}
                disableRipple
                className={classes.tableBtnStyle}
                aria-owns={open ? `simple-menu` : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <MoreIcon />
            </IconButton>
            <Menu
                id={`simple-menu`}
                anchorEl={anchorRef.current}
                // open={Boolean(anchorEl)}
                open={open}
                onClose={handleClose}
                className={classes.menuBox}
                // anchorOrigin={{
                //     vertical: 'bottom',
                //     horizontal: 'right',
                // }}
                // transformOrigin={{
                //     vertical: 'top',
                //     horizontal: 'left',
                // }}
            >
                {menuItems &&
                    menuItems.length &&
                    menuItems.map(menu => {
                        return (
                            <MenuItem
                                key={menu.name}
                                onClick={e => {
                                    handleClose(e);
                                    menu.menuFunc && menu.menuFunc();
                                }}
                                className={classes.menuItem}
                            >
                                {menu.name}
                            </MenuItem>
                        );
                    })}
            </Menu>
        </React.Fragment>
    );
}

export default observer(MoreButton);
