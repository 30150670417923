import axios from 'axios';
import { makeAutoObservable } from 'mobx';

const MANUAL_API_URL = '/api/v1/files/manual';

export const MANUAL_TYPE = {
    teacher: 'teacher',
    student: 'student',
    vod: 'vod',
};

export const MANUAL_TYPE_NAME = {
    teacher: '교사',
    student: '학생',
    vod: '스마트펜 사용 영상',
};

export default class CommonServiceStore {
    constructor(serverContextPath) {
        makeAutoObservable(this);

        this.serverContextPath = serverContextPath;
    }

    get manualTypes() {
        return Object.values(MANUAL_TYPE);
    }

    *downloadManual(type, callback) {
        console.log('downloadManual type = ', type);
        try {
            const response = yield axios.get(this.serverContextPath + `${MANUAL_API_URL}/${type}`);

            window.open(response.data, '_blank');

            callback && callback();
        } catch (e) {
            console.log("can't download manual");
        }
    }
}
