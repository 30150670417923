import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as AsideGlobalIcon } from '../../common/images/AsideGlobalIcon.svg';
import { ReactComponent as AsideUsersThreeIcon } from '../../common/images/AsideUsersThreeIcon.svg';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { ReactComponent as AsideSettingIcon } from '../../common/images/AsideSettingIcon.svg';
import { Button } from '@material-ui/core';
import { LmsGroupUserType } from '../../stores/ClassStore';
import { ClassMainPath } from '../../common/ClassMainPath';

const styles = _theme => ({
    root: {},
    listStyle: {
        fontSize: '1rem',
        '& li': {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '7px!important',
            '& svg': {
                marginRight: 5,
            },
            '& button': {
                display: 'flex',
                alignItems: 'center',
                marginBottom: '7px!important',
                '& span': {
                    justifyContent: 'flex-start',
                },
            },
        },
    },
    buttonStyle: {
        padding: 0,
        fontSize: '1rem',
        height: 21,
        '&:hover': {
            background: 'transparent',
        },
    },
    spanWidth: {
        width: 38,
    },
    nameWidth: {
        width: 170,
    },
    liBox: {
        display: 'flex',
        alignItems: 'flex-start !important',
    },
});

class ClassContentInfoComponent extends Component {
    handleChangeSetting = () => {
        this.props.moveTo(ClassMainPath.setting);
    };
    render() {
        const { classes, selectedGroupDetail, isMember } = this.props;

        return (
            <div>
                <ul className={classes.listStyle}>
                    <li>
                        <AsideGlobalIcon /> <span>{selectedGroupDetail.group.public ? '공개' : '비공개'}</span>
                    </li>
                    <li>
                        <AsideUsersThreeIcon /> <span>멤버: {selectedGroupDetail.lmsUser.length + '명'}</span>{' '}
                    </li>
                    <li className={classes.liBox}>
                        <AsideUserIcon />
                        <span className={classes.spanWidth}>교사: </span>
                        <span className={classes.nameWidth}>
                            {selectedGroupDetail.lmsUser.filter(user => user.type === LmsGroupUserType.leader).map(user => user.name)}
                        </span>
                    </li>
                    {isMember && (
                        <li>
                            <Button className={classes.buttonStyle} disableRipple onClick={this.handleChangeSetting}>
                                <AsideSettingIcon /> <span>설정</span>
                            </Button>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}

export default withStyles(styles)(ClassContentInfoComponent);
