import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, CircularProgress, FormControl, IconButton, Menu, MenuItem, Select, Typography } from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { ReactComponent as MoreIcon } from '../../common/images/DotsThreeOutlineVerticalIcon.svg';
import clsx from 'clsx';
import { ReactComponent as CheckCircleAgreeOffIcon } from '../../common/images/CheckCircleAgreeOffIcon.svg';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../common/images/CheckCircleAgreeOnIcon.svg';
import { ReactComponent as DownloadSimpleIcon } from '../../common/images/DownloadSimpleIcon.svg';
import { SORT_TYPE } from '../../stores/PaginationStore';
import { DATE_UTIL } from '../../common/util/date.util';
import {inject, observer} from "mobx-react";
import {OfflineDataUploadState, PenState} from "../../stores/NeoPenStore";

const styles = theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
        '& table button.MuiButton-root': {
            minWidth: 'unset',
            padding: 0,
        },
    },
    titleCaption: {
        fontSize: '0.938rem',
    },

    btnStyle: {
        '@media all and (min-width: 1500px)': {
            width: 235,
            marginLeft: 90,
        },
        width: 230,
        height: 40,
        marginLeft: 30,
        backgroundColor: '#0097FF',
        color: '#fff',
        borderRadius: 7,
        '& svg': {
            marginRight: 4,
        },
        '&:hover': {
            backgroundColor: '#0097FF',
        },
    },
    downloadBtnStyle: {
        border: '1px solid #bfbfbf',
        borderRadius: 3,
        display: 'flex',
        marginLeft: 'auto',
        minWidth: 32,
        '& svg': {
            width: 18,
            height: 18,
            '& path': {
                stroke: '#000',
            },
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    checkBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#a9adb4',
        fontSize: '0.875rem',
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 4,
        },
    },
    checkBoxStyleOn: {
        color: '#0097FF',
    },
    caption: {
        backgroundColor: 'transparent',
        color: '#fff',
        padding: '0px 4px',
        fontSize: '0.625rem',
        fontFamily: 'Montserrat!important',
        marginLeft: 7,
        marginBottom: 2,
        textTransform: 'uppercase',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            marginRight: 3,
        },
    },
    captionLive: {
        width: 30,
        backgroundColor: '#FB4A59',
    },
    captionWait: {
        width: 20,
        backgroundColor: '#a3a8af',
    },
    captionEnd: {
        width: 20,
        backgroundColor: '#1a5177',
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 26px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        justifyContent: 'flex-start',
        width: '100%',
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .aside-lmsUser': {
            fill: '#fff',
        },
    },
    listItemTitle: {
        fontSize: '0.875rem',
        display: 'flex',
        fontWeight: 600,
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    checkStyle: {
        fontSize: '0.75rem',
        color: '#016ab2',
    },
});
class StudentManagmentContentsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.changeSort(SORT_TYPE.name);
    }

    handleChangeSortAndFetchData = event => {
        const sortType = event.target.value;
        this.props.changeSortAndFetchData(sortType);
    };

    handleChangeNoClassFilter = () => {
        if (this.props.isAllUsersLoading) {
            return;
        }
        this.props.changeNoClassFilterAndFetchData();
    };

    render() {
        const { classes, allMembers, allUsersExportExcel, isSearchingGuest } = this.props;
        const { anchorGeneral } = this.state;
        const general = Boolean(anchorGeneral);
        const sortedMembers = [...allMembers];
        const listTitle = isSearchingGuest ? '검색결과' : '학생목록';

        const nPS = this.props.neoPenStore;

        return (
            <div className={classes.root}>
                {!allMembers.length && this.props.isAllUsersLoading ? (
                    <Box style={{ textAlign: 'center', marginTop: 100, marginBottom: 1000 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Typography className={classes.titleCaption}>
                            <b>{listTitle}</b> • {this.props.allUsersTotalCount}개
                        </Typography>
                        <Button
                            className={classes.downloadBtnStyle}
                            disableRipple
                            disabled={!allMembers.length || this.props.isExcelLoading}
                            onClick={allUsersExportExcel}
                        >
                            <DownloadSimpleIcon />
                        </Button>
                        <Box className={classes.filterStyle}>
                            <Box>
                                <Box
                                    onClick={this.handleChangeNoClassFilter}
                                    className={
                                        !this.props.isNoClassFilter ? classes.checkBoxStyle : clsx(classes.checkBoxStyle, classes.checkBoxStyleOn)
                                    }
                                >
                                    {!this.props.isNoClassFilter ? <CheckCircleAgreeOffIcon /> : <CheckCircleAgreeOnIcon />}
                                    클래스 미가입 학생만
                                </Box>
                            </Box>
                            <Box>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        value={this.props.sort}
                                        displayEmpty
                                        onChange={this.handleChangeSortAndFetchData}
                                        disabled={this.props.isAllUsersLoading}
                                        IconComponent={() => (
                                            <Box style={{ width: 16, height: 16, marginLeft: 5 }}>
                                                <ArrowDownIcon />
                                            </Box>
                                        )}
                                    >
                                        <MenuItem value={SORT_TYPE.name} className={classes.menuItem}>
                                            이름순
                                        </MenuItem>
                                        <MenuItem value={SORT_TYPE.createdDatetime} className={classes.menuItem}>
                                            가입일순
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        {sortedMembers.length ? (
                            <>
                                {sortedMembers.map((member, i) => {
                                    return (
                                        <Box key={i} className={classes.listItemStyle}>
                                            <Box
                                                className={classes.flexCenter}
                                                onClick={() => {
                                                    this.props.handleClickGeneral(undefined, member);
                                                    this.props.handleClickViewAccount();
                                                }}
                                            >
                                                <Avatar className={classes.avatar} src={member.image}>
                                                    <AsideUserIcon />
                                                </Avatar>
                                                <Box flexDirection="column">
                                                    <Typography variant="h5" className={classes.listItemTitle}>
                                                        {member.name}
                                                    </Typography>
                                                    <Typography variant="body2" className={classes.listItemDate}>
                                                        {!member.joinedGroups.length
                                                            ? '소속 클래스 없음'
                                                            : member.joinedGroups.length > 1
                                                            ? `${member.joinedGroups[0].name} 외 ${member.joinedGroups.length}개`
                                                            : `${member.joinedGroups[0].name}`}{' '}
                                                        |{' '}
                                                        {DATE_UTIL.convertUTCToTimeZone(
                                                            member.createdDatetime,
                                                            DATE_UTIL.FORMAT.SINGLE_DIGIT_DOT_DATE_FORMAT,
                                                        )}{' '}
                                                        가입
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                <Button
                                                    style={{width: 150}}
                                                    onClick={() => {
                                                    if (nPS.penState === PenState.Disconnected) {
                                                        nPS.selectUser(member);

                                                        nPS.setOfflineDataUploadState(OfflineDataUploadState.Connecting);

                                                        nPS.onConnectionChange();
                                                    }
                                                }}
                                                > {(nPS.penState === PenState.Connecting && member.userId === nPS.selectedUser.userId)
                                                    ? '펜 연결중...'
                                                    : (nPS.penState === PenState.Connected && member.userId === nPS.selectedUser.userId)
                                                        ? '데이터 업로드 중...'
                                                        : '업로드'}
                                                </Button>

                                                <IconButton
                                                    disableRipple
                                                    //aria-owns={anchorEl ? 'simple-menu' : undefined}
                                                    aria-haspopup="true"
                                                    // onClick={e => this.props.handleClickGeneral(e, member.email)}
                                                    onClick={e => this.props.handleClickGeneral(e, member)}
                                                >
                                                    <MoreIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    );
                                })}
                                <Menu
                                    id="simple-menu"
                                    anchorEl={this.props.anchorGeneral}
                                    open={this.props.anchorGeneral !== undefined ? this.props.anchorGeneral : false}
                                    onClose={this.props.handleClose}
                                    className={classes.menuBox}
                                >
                                    <MenuItem onClick={this.props.handleClickViewAccount} className={classes.menuItem}>
                                        계정복사
                                    </MenuItem>
                                    <MenuItem onClick={this.props.handleClickModifyAccount} className={classes.menuItem}>
                                        편집
                                    </MenuItem>
                                </Menu>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={this.props.anchorGeneralCheck}
                                    open={this.props.anchorGeneralCheck !== undefined ? this.props.anchorGeneralCheck : false}
                                    onClose={this.props.handleClose}
                                    className={classes.menuBox}
                                >
                                    <MenuItem onClick={this.props.handleClose} className={classes.menuItem}>
                                        계정 복사
                                    </MenuItem>
                                    <MenuItem onClick={this.props.handleClose} className={classes.menuItem}>
                                        편집
                                    </MenuItem>
                                    <MenuItem onClick={this.props.handleClose} className={classes.menuItem}>
                                        삭제
                                    </MenuItem>
                                </Menu>
                            </>
                        ) : this.props.isAllUsersLoading ? (
                            <Box style={{ textAlign: 'center', marginTop: 100, marginBottom: 1000 }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Box className={classes.listItemStyle}>
                                <Box className={classes.flexCenter}>
                                    <Box className={classes.avatar}>
                                        <AsideUserIcon />
                                    </Box>
                                    <Typography>등록된 계정이 없습니다.</Typography>
                                </Box>
                            </Box>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export default inject('neoPenStore')(withStyles(styles)(observer(StudentManagmentContentsComponent)));
