import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { withRouter } from './components/WithRouter';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import clsx from 'clsx';
import _ from 'lodash';
import { IntlProvider } from 'react-intl';

import * as store from './stores/AuthStore';
import MainTopBar from './views/topBar/HomeTopBar';
import TopBar from './views/topBar/TopBar';
import MobileHomeTopBar from './views/mobile/topBar/MobileHomeTopBar';
import { getMobileRoutes, getWebRoutes } from './components/LayoutRoutes';
import { State } from './stores/AuthStore';
import MobileAppBar from './views/mobile/topBar/MobileAppBar';
import LoadingComponent from './components/common/LoadingComponent';
import { PATH_UTIL } from './common/util/path.util';
import SkeletonPenViewer from './views/class/skeleton/SkeletonPenViewer';

const LogPrefix = '[App] ';

const styles = _theme => ({
    root: {
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
        '& .MuiAvatar-root': {
            '& img': {
                objectFit: 'cover',
            },
        },
    },
    scroller: {
        height: '100vh',
        overflowY: 'scroll',
    },
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.scrollerref = React.createRef();
        this.state = {
            isMobile: window.innerWidth <= 900,
            isTablet: window.innerWidth <= 1200,
            isLandscape: window.innerWidth > window.innerHeight,
            isScrollEnd: false,
            fixedTopBarHeight: false,
        };
        this.handleScroll = _.throttle(this.handleScroll.bind(this));

        window.addEventListener('resize', this.onWindowResized);
    }

    componentDidMount() {
        const axiosRequestInterceptors = config => {
            const token = sessionStorage.getItem(store.SessionStorageTokenKey);

            if (token) {
                config.headers['X-Auth-Token'] = token;
            }

            return config;
        };

        const axiosRequestErrorHandler = error => {
            return Promise.reject(error);
        };

        const axiosResponseInterceptor = response => {
            if (response.status === 403) {
                this.props.authStore.invalidateLogin();
                this.props.authStore.invalidateAuthToken();
            }

            return response;
        };

        const axiosResponseErrorHandler = error => {
            if (error.response && error.response.status === 403) {
                this.props.authStore.invalidateLogin();
                this.props.authStore.invalidateAuthToken();
            }

            return Promise.reject(error);
        };

        console.log('========== RGate App componentDidMount ==========');
        axios.interceptors.request.use(axiosRequestInterceptors, axiosRequestErrorHandler);
        axios.interceptors.response.use(axiosResponseInterceptor, axiosResponseErrorHandler);

        // this.onWindowResized();

        this.props.authStore.checkLogin();
    }

    componentWillUnmount() {
        console.log(LogPrefix, 'AppWillUnmount');

        window.removeEventListener('resize', this.onWindowResized);
    }

    moveTo = pathName => {
        this.props.navigate(pathName);
    };

    onWindowResized = () => {
        console.log(LogPrefix, 'OnWindowResized');

        const width = window.innerWidth;
        const landscape = window.innerWidth > window.innerHeight;

        if (landscape) {
            if (!this.state.isLandscape) {
                this.setState({ isLandscape: true });
            }
        } else {
            if (this.state.isLandscape) {
                this.setState({ isLandscape: false });
            }
        }

        if (width <= 900) {
            console.log(LogPrefix, 'Change to mobile mode');
            this.setState({ isMobile: true, isTablet: false });
        } else if (width <= 1200) {
            console.log(LogPrefix, 'Change to tablet mode');
            this.setState({ isMobile: false, isTablet: true });
        } else {
            console.log(LogPrefix, 'Change to desktop mode');
            this.setState({ isMobile: false, isTablet: false });
        }
    };

    handleScroll() {
        const scroller = this.scrollerref.current;
        if (scroller.scrollTop >= 437) {
            if (!this.state.fixedTopBarHeight) this.setState({ fixedTopBarHeight: true });
        } else {
            if (this.state.fixedTopBarHeight) this.setState({ fixedTopBarHeight: false });
        }

        if (this.state.isScrollEnd) {
            this.setState({ isScrollEnd: false });
        }
        if (
            scroller.scrollTop &&
            scroller.clientHeight &&
            scroller.scrollHeight &&
            scroller.scrollTop + scroller.clientHeight >= scroller.scrollHeight - 0.5
        ) {
            if (!this.state.isScrollEnd) {
                this.setState({ isScrollEnd: true });
            }
        }
    }

    scrollMove = value => {
        const scroller = this.scrollerref.current;
        if (value == null) {
            scroller.scrollTo(0, 437);
        } else {
            scroller.scrollTo(0, value);
        }
    };

    calcScrollEnd = (scrollTop, clientHeight, scrollHeight) => {
        let isScrollEnd = false;
        if (scrollTop && clientHeight && scrollHeight) {
            isScrollEnd = scrollTop + clientHeight >= scrollHeight || scrollTop + clientHeight >= scrollHeight - 0.5;
        }

        return isScrollEnd;
    };

    getTopBarComponent = (path, isLogin = false, isMobile = false, isTablet = false) => {
        const notAllowedPathNames = [
            'studylive',
            'termsofuse',
            'privacypolicy',
            'penview',
            'penrecordview',
            'realtimeview',
            'statistics',
            'writingBoard',
            'publishing',
            'evaluation'
        ];

        if (notAllowedPathNames.includes(path)) {
            return null;
        }

        const isMobileDevice = isMobile || isTablet;

        if (isMobileDevice) {
            return isLogin ? <MobileAppBar /> : <MobileHomeTopBar />;
        }
        return isLogin ? <TopBar /> : <MainTopBar isMobile={isMobile} />;
    };

    getElementMatchDevice = () => {
        const { orgStore, authStore, location } = this.props;
        const mainPathName = PATH_UTIL.getPath(location.pathname);
        const notAllowedPenViewerPathNames = ['penview', 'penrecordview', 'realtimeview'];
        if (authStore.loginState === State.Pending) {
            if (notAllowedPenViewerPathNames.includes(mainPathName)) {
                return <SkeletonPenViewer />;
            }
            return <LoadingComponent />;
        }

        const { isMobile, isTablet } = this.state;
        // const { isMobile, isTablet } = this.props;
        const isLogin = authStore.loginState === State.Authenticated;
        const calcScrollResult = this.scrollerref.current
            ? this.calcScrollEnd(this.scrollerref.current.scrollTop, this.scrollerref.current.clientHeight, this.scrollerref.current.scrollHeight)
            : false;
        const calcScrollHeight = this.scrollerref.current ? this.scrollerref.current.scrollHeight : 0;
        const isMobileDevice = isMobile || isTablet;
        const routeProps = {
            configs: { ...orgStore.orgConfigs },
            isMobile: this.state.isMobile,
            isTablet: this.state.isTablet,
            loginState: authStore.loginState,
            isScrollEnd: this.state.isScrollEnd,
            fixedTopBarHeight: this.state.fixedTopBarHeight,
            scrollMove: this.scrollMove,
            calcScrollEnd: calcScrollResult,
            scrollHeight: calcScrollHeight,
        };

        const topBar = this.getTopBarComponent(mainPathName, isLogin, isMobile, isTablet);
        const routes = isMobileDevice ? getMobileRoutes(isLogin, routeProps) : getWebRoutes(isLogin, routeProps);

        return (
            <>
                {topBar}
                <Routes>
                    {routes.map((props, index) => {
                        return <Route path={props.path} element={props.element} key={index} />;
                    })}
                </Routes>
            </>
        );
    };

    render() {
        const { classes } = this.props;
        const locale = this.props.localeStore.locale;
        const localeMessage = this.props.localeStore.localeMessage;
        const mainElement = this.getElementMatchDevice();

        return (
            <IntlProvider messages={localeMessage} locale={locale} defaultLocale={'ko'}>
                <div className={clsx(classes.root, classes.scroller)} ref={this.scrollerref} onScroll={this.handleScroll}>
                    {mainElement}
                </div>
            </IntlProvider>
        );
    }
}

export default withRouter(withStyles(styles)(inject('authStore', 'localeStore', 'orgStore')(observer(App))));
