export const ClassMainPath = {
    todaySchedule: 'todayschedule',
    allSchedule: 'allschedule',
    calendarSchedule: 'calendar',
    referenceRoom: 'referenceroom',
    penRecord: 'penrecord',
    community: 'community',
    member: 'member',
    setting: 'setting',
    tag: 'tag',
    search: 'search',
    scheduleDetail: 'scheduledetail',
    postDetail: 'postdetail',
    evaluation: 'evaluation',
};
