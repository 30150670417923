export const SearchScope = {
    All: 'all',
    Joined: 'joined',
};

export const SearchCategory = {
    Study: 'study',
    Webinar: 'webinar',
    Coding: 'coding',
    Schedule: 'schedule',
    Post: 'post',
};

export const SearchMethod = {
    Keyword: 'keyword',
    Personal: 'personal',
};
