import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, IconButton, InputBase } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../common/images/SearchIcon.svg';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../components/WithRouter';
import { SearchCategory, SearchScope } from '../../common/SearchItems';
import ConfirmDialog from '../../components/common/ConfirmDialog';

const style = _theme => ({
    searchIcon: {
        padding: 7,
        background: '#f8f8f8',
        '&:hover': {
            background: '#f8f8f8',
        },
    },
    search: {
        width: 500,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#fff',
        border: '2px solid #e1e1e1',
        padding: '3px 6px 3px 22px',
        borderRadius: 50,
        '& .MuiInputBase-input::placeholder': {
            opacity: 1,
            fontSize: '1.125rem',
            color: '#92979e',
        },
        '& .MuiInputBase-input': {
            padding: '6px 7px 4px',
        },
    },
    inputRoot: {
        color: '#000',
        fontSize: '1.125rem',
        width: '100%',
        marginRight: 10,
    },
});

class TopSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openConfirmDialog: false,
        };
    }

    handleSearchButton = () => {
        const { classStore, roomStore, postStore, searchStore } = this.props;
        const { isSearchGroupListLoading } = classStore;
        const { isLoadRoomLoading } = roomStore;
        const { isPostListLoading } = postStore;
        const { searchKeyword } = searchStore;

        this.props.navigate(`/search/${SearchScope.All}/${SearchCategory.Study}/${searchKeyword}`);

        // if (!isSearchGroupListLoading && !isLoadRoomLoading && !isPostListLoading) {
        //     classStore.initSearchClass();
        //     roomStore.initRoom();
        //     postStore.initPost();
        //     searchStore.initCurrentPage();
        //
        //     roomStore.setRoomRowsPerPage(16);
        //
        //     this.props.classStore.getGroupListByKeyword(searchKeyword, {
        //         moveToSearchPage: () => this.props.navigate(`/search/${SearchScope.All}/${SearchCategory.Study}/${searchKeyword}`),
        //         getRoomList: () =>
        //             this.props.roomStore.getRoomListGroupByStartTime(
        //                 undefined,
        //                 this.props.authStore.loginUser.id,
        //                 'all',
        //                 1,
        //                 undefined,
        //                 undefined,
        //                 undefined,
        //                 searchKeyword,
        //             ),
        //         getPostList: () => this.props.postStore.getPostList(undefined, this.props.authStore.loginUser.id, searchKeyword, 1, 16),
        //     });
        // } else {
        //     this.setState({ openConfirmDialog: true });
        // }
    };

    handleChangeSearchKeyword = value => {
        this.props.searchStore.changeSearchKeyword(value);
    };

    handleKeyUpEnter = e => {
        if (e.key === 'Enter') {
            this.handleSearchButton();
        }
    };

    handleCloseDialog = () => {
        this.setState({ openConfirmDialog: false });
    };

    render() {
        const { classes, intl, classStore, roomStore, postStore, searchStore } = this.props;
        const { isGroupListLoading } = classStore;
        const { isLoadRoomLoading } = roomStore;
        const { isPostListLoading } = postStore;
        const { searchKeyword } = searchStore;

        return (
            <Box className={classes.search}>
                <InputBase
                    value={searchKeyword}
                    placeholder={intl.formatMessage({ id: 'search' })}
                    className={classes.inputRoot}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={event => this.handleChangeSearchKeyword(event.target.value)}
                    onKeyUp={this.handleKeyUpEnter}
                />
                <IconButton
                    className={classes.searchIcon}
                    onClick={this.handleSearchButton}
                    disabled={isGroupListLoading || isLoadRoomLoading || isPostListLoading || searchKeyword === ''}
                >
                    <SearchIcon />
                </IconButton>
                <ConfirmDialog
                    open={this.state.openConfirmDialog}
                    title={'알림'}
                    msg={'검색중 입니다. 잠시만 기다려주세요.'}
                    changeDialogOpen={this.handleCloseDialog}
                />
            </Box>
        );
    }
}

export default withRouter(
    withStyles(style)(
        inject('orgStore', 'localeStore', 'classStore', 'authStore', 'roomStore', 'postStore', 'searchStore')(injectIntl(observer(TopSearch))),
    ),
);
