import { makeAutoObservable } from 'mobx';
import axios from 'axios';
// import TopLogo from '../common/images/OntheliveLogo.svg';
import TopLogo from '../common/images/StudyLiveTopBarLogo.svg';
import MainImage from '../common/images/main_bg2.jpg';
import configureTheme from '../configureTheme';
import { COLOR_UTIL } from '../common/util/color.util';

const logPrefix = ' [ OrgStore ] ';

export const ORG_STATE = {
    READY: 'READY',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
};

const DEFAULT_ORG_IMAGE = {
    Main: MainImage,
    Logo: TopLogo,
    Favicon: '',
};

const DEFAULT_ORG_CONFIG = {
    MainText: '온택트시대,\n안전하고 편리한\n화상 서비스 플랫폼',
    SubText: '쌍방향 화상회의와 관리형 교육이 가능한 탁월한 플랫폼',
    // MainText : '',
    // SubText : '',
    MainTextColor: '#fff',
    SubTextColor: '#fff',
    MainColor: '#fff',
    SubColor: '#0097ff',
    Color: '#fff',
    OrganizationName: '',
    WebviewURL: '',
    //extension
    SignInBtnColor: '#dc524f',
    MainBtnColor: '#0097ff',
};

const ConfigKey = {
    MainText: 'MainText',
    SubText: 'SubText',
    MainTextColor: 'MainTextColor',
    SubTextColor: 'SubTextColor',
    MainColor: 'MainColor',
    SubColor: 'SubColor',
    OrganizationName: 'OrganizationName',
    WebviewURL: 'WebviewURL',
};

const ImageType = {
    Main: 'Main',
    Logo: 'Logo',
    Favicon: 'Favicon',
};

export const DEFAULT_COLORS = ['#cc00ff', '#004dc9', '#0097ff', '#996633', '#e03c3c', '#f87e55', '#ffd542', '#0dc268'];

export const DEFAULT_TEXT_COLORS = ['#000000', '#FFFFFF', '#004dc9', '#996633', '#e03c3c', '#f87e55', '#ffd542', '#0dc268'];

export const CONFIG_SELECTIONS = {
    Main: 'main',
    Logo: 'logo',
    Favicon: 'favicon',
    Sub: 'sub',
    Simple: 'simpleSelection',
    Direct: 'directSelection',
};

export const DEFAULT_UPLOAD_IMAGE_FILE_LIMIT = 1;
export const DEFAULT_UPLOAD_IMAGE_MAX_FILE_SIZE = 1000000;
export const DEFAULT_UPLOAD_IMAGE_ACCEPTED_FILES = ['image/jpeg', 'image/png'];

const URL = '/api/v1/organizations';

export default class OrgStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    orgTheme = undefined;

    orgConfigs = Object.assign({}, DEFAULT_ORG_CONFIG);
    orgImages = Object.assign({}, DEFAULT_ORG_IMAGE);

    mainImgData = [];
    logoImgData = [];
    faviconImgData = [];
    imgReadResult = '';

    previewConfigs = {};
    previewImages = {};

    requestState = ORG_STATE.READY;
    isOrganizationDataLoading = false;

    hasError = false;
    errorMessage = '';

    checkedOrgData = false;

    licenseKeys = [];
    licenseSearchKeyword = '';
    selectedLicenseKey = '';
    licenseSearchDialogOpenState = false;

    initialPreviewItems = () => {
        this.previewConfigs = {};
        this.previewImages = {};
        this.isOrganizationDataLoading = false;
        this.hasError = false;
        this.errorMessage = '';
    };

    initialImgData = () => {
        this.mainImgData = [];
        this.logoImgData = [];
        this.faviconImgData = [];
        this.imgReadResult = '';
    };

    initialOrgTheme = () => {
        this.orgTheme = configureTheme(this.orgConfigs, this.orgImages);
    };

    /** handle change configs and images **/

    changeMainImgData = newImgData => {
        this.mainImgData = newImgData;
        const imgSrc = data => {
            this.previewImages.Main = data;
        };
        this.setImgSrc(this.mainImgData, imgSrc);
    };

    changeLogoImgData = newImgData => {
        this.logoImgData = newImgData;
        const imgSrc = data => {
            this.previewImages.Logo = data;
        };
        this.setImgSrc(this.logoImgData, imgSrc);
    };

    changeFaviconImgData = newImgData => {
        this.faviconImgData = newImgData;
        const imgSrc = data => {
            this.previewImages.Favicon = data;
        };
        this.setImgSrc(this.faviconImgData, imgSrc);
    };

    changeOrgMainText = (intl, newText) => {
        if (newText.length >= 10000) {
            this.handleError(intl.formatMessage({ id: 'msg.error_up_to_characters' }), 'error');
            return;
        }
        this.previewConfigs.MainText = newText;
    };

    changeOrgSubText = (intl, newText) => {
        if (newText.length >= 10000) {
            this.handleError(intl.formatMessage({ id: 'msg.error_up_to_characters' }), 'error');
            return;
        }
        this.previewConfigs.SubText = newText;
    };

    changeOrgMainTextColor = newColor => {
        this.previewConfigs.MainTextColor = newColor;
    };

    changeOrgSubTextColor = newColor => {
        this.previewConfigs.SubTextColor = newColor;
    };

    changeOrgMainColor = newColor => {
        this.previewConfigs.MainColor = newColor;
    };

    changeOrgSubColor = newColor => {
        this.previewConfigs.SubColor = newColor;
    };

    changeOrgTitle = newTitle => {
        this.previewConfigs.OrganizationName = newTitle;
    };

    changeOrgURL = newURL => {
        this.previewConfigs.WebviewURL = newURL;
    };

    /** 드롭존 이미지 데이터 처리 **/
    imgDataReader = (imgData, callback) => {
        const reader = new FileReader();
        reader.readAsDataURL(imgData[0]);
        reader.onloadend = e => {
            let imgReadResult = reader.result;
            if (callback) {
                callback(imgReadResult);
            }
        };
    };

    setImgSrc = (imgData, imgSrc) => {
        if (imgData.length > 0) {
            this.imgDataReader(imgData, imgSrc);
        }
    };

    /** 메인 및 서브 텍스트 줄바꿈 처리 **/
    changeOrgSubTextNewLine = e => {
        if (e.keyCode === 13) this.orgSubText.replace(/\r?\n/gi, '');
    };

    /** handle DropZone **/
    handleDropRejectMessage = (intl, rejectedFile, acceptedFiles, maxFileSize) => {
        let message = intl.formatMessage({ id: 'msg.error_file_not_fit_format' }, { filename: rejectedFile.name });
        // let message = "파일 ".concat(rejectedFile.name, "이 입력양식에 맞지 않습니다. ");

        if (!acceptedFiles.includes(rejectedFile.type)) {
            message += ` ${intl.formatMessage({
                id: 'msg.error_file_format_not_supported',
            })}`;
            // message += '지원하지 않는 파일 형식입니다.';
        } else if (rejectedFile.size > maxFileSize) {
            message += intl.formatMessage({ id: 'msg.error_file_max_size' });
            // message += '1MB 미만 이미지 사용을 권장합니다';
        }
        // if(rejectedFile.size > maxFileSize) {
        //     message += intl.formatMessage({id: "msg.error_file_max_size"});
        //     // message += '1MB 미만 이미지 사용을 권장합니다';
        // }

        return message;
    };

    handleFileLimitExceedMessage = (intl, filesLimit) => {
        return intl.formatMessage(
            { id: 'msg.error_file_maximum_number' },
            {
                file_limit: filesLimit ? filesLimit : DEFAULT_UPLOAD_IMAGE_FILE_LIMIT,
            },
        );
        // return "허용되는 최대 파일 수를 초과하였습니다. 최대 파일 수 ".concat(filesLimit, " 개");
    };

    handleError = (message, variant) => {
        if (variant === 'error' || variant === 500 || variant === 404 || variant === 400 || variant === 401 || variant === 403) {
            this.errorMessage = message;
            this.hasError = true;
        }
    };

    changeHasError = hasError => {
        this.hasError = hasError;
    };

    /** 조직정보 프리뷰 및 조직정뷰 설정 사용되는 기본 설정값 세팅 **/
    setPreviewImages = () => {
        this.previewImages = Object.assign({}, this.orgImages);
    };

    setPreviewConfigs = () => {
        this.previewConfigs = Object.assign({}, this.orgConfigs);
    };

    checkOrgData = (hasConfig, hasImage) => {
        if ((hasConfig === null || hasConfig === undefined) && (hasImage === null || hasImage === undefined)) {
            console.log(logPrefix, 'Organization data is null');
        } else {
            if (hasConfig.length > 0 || hasImage.length > 0) {
                this.checkedOrgData = true;
                if (hasConfig.length > 0) {
                    const checkMainColor = hasConfig.find(
                        config => config.configKey === ConfigKey.MainColor && config.value !== DEFAULT_ORG_CONFIG.MainColor,
                    );
                    if (checkMainColor) {
                        delete this.orgConfigs.SignInBtnColor;
                        delete this.orgConfigs.MainBtnColor;
                    }
                }
                hasConfig.map(config => (this.orgConfigs[config.configKey] = config.value));
                hasImage.map(image => (this.orgImages[image.type] = image.image));

                document.getElementById('favicon').href = this.orgImages.Favicon; //set favicon
                //set service title
                if (this.orgConfigs.OrganizationName) {
                    document.title = this.orgConfigs.OrganizationName === '' ? 'OnTheLive LMS' : this.orgConfigs.OrganizationName;
                } else {
                    document.title = 'OnTheLive LMS';
                }
            } else {
            }
        }
    };

    setConfigParams = orgConfigs => {
        const configs = [];
        for (const [key, value] of Object.entries(orgConfigs)) {
            if (this.previewConfigs[key] !== value) {
                const config = {
                    configKey: ConfigKey[key],
                    value: this.previewConfigs[key],
                };
                configs.push(config);
            }
        }
        return configs;
    };

    setImageParams = orgImages => {
        const images = [];
        for (const [key, value] of Object.entries(orgImages)) {
            if (this.previewImages[key] !== value) {
                const image = {
                    type: ImageType[key],
                    image: this.previewImages[key],
                };
                images.push(image);
            }
        }
        return images;
    };

    setGroupColor = color => {
        this.orgConfigs['MainBtnColor'] = color;
    };

    initFindLicenseKeys = () => {
        this.licenseKeys = [];
        this.licenseSearchKeyword = '';
        this.selectedLicenseKey = '';
    };

    changeLicenseSearchDialogOpenState = state => {
        this.licenseSearchDialogOpenState = state;
    };

    changeLicenseSearchKeyword = value => {
        this.licenseSearchKeyword = value;
    };

    changeSelectedLicenseKey = value => {
        this.selectedLicenseKey = value;
    };

    get isOpenLicenseSearchDialog() {
        return this.licenseSearchDialogOpenState;
    }

    get searchLicenseKeys() {
        const keyword = this.licenseSearchKeyword?.replace('-', '');
        // const keys = [...this.licenseKeys];
        if (this.licenseKeys?.length <= 0 || !keyword) {
            return [];
        }
        const regex = new RegExp(`${keyword}`, 'i');

        const filteredKeys = this.licenseKeys?.filter(key => regex?.test(key?.organization) || regex?.test(key?.licenseKey?.replace('-', '')));

        return filteredKeys;
    }

    get haveConfigs() {
        return this.checkedOrgData;
    }

    get mode() {
        let mode = true;
        for (const [key, value] of Object.entries(this.orgConfigs)) {
            for (const [key, value] of Object.entries(this.orgImages)) {
                if (this.previewImages[key] !== value) mode = false;
            }
            if (this.previewConfigs[key] !== value) mode = false;
        }
        return mode;
    }

    get getIsOrganizationDataLoading() {
        return this.isOrganizationDataLoading;
    }

    get mainTextColorSelection() {
        return DEFAULT_TEXT_COLORS.includes(this.previewConfigs.MainTextColor);
    }

    get subTextColorSelection() {
        return DEFAULT_TEXT_COLORS.includes(this.previewConfigs.SubTextColor);
    }

    get mainColorSelection() {
        return DEFAULT_COLORS.includes(this.previewConfigs.MainColor);
    }

    get subColorSelection() {
        return DEFAULT_COLORS.includes(this.previewConfigs.SubColor);
    }

    get mainColorToHSLA() {
        return COLOR_UTIL.hexToHSLA(this.orgConfigs.MainColor);
    }

    get mainColorToHSL() {
        return COLOR_UTIL.hexToHSL(this.orgConfigs.MainColor);
    }

    /** Repository **/
    requestSaveOrgConfig = function* (intl) {
        // console.log(logPrefix, "Starting Insert Config")

        this.isOrganizationDataLoading = true;

        const params = Object.assign(
            {},
            {
                image: [],
                config: [],
            },
        );

        for (const [key, value] of Object.entries(this.previewImages)) {
            const image = { type: ImageType[key], image: value };
            params.image.push(image);
        }

        for (const [key, value] of Object.entries(this.previewConfigs)) {
            if (ConfigKey[key] === undefined || ConfigKey[key] === '') continue;
            const config = { configKey: ConfigKey[key], value: value };
            params.config.push(config);
        }

        try {
            const response = yield axios.put(`${this.serverContextPath}${URL}`, params);

            this.checkOrgData(response.data.orgConfigs, response.data.orgImages);
            this.isOrganizationDataLoading = false;
        } catch (e) {
            this.isOrganizationDataLoading = false;
            // this.handleError('조직정보 설정 중 오류가 발생하였습니다. \n 다시 시도해주세요', e.response.status || e);
            this.handleError(
                intl.formatMessage({
                    id: 'msg.has_error_set_organization_configs',
                }),
                e.response.status,
            );

            console.log(logPrefix, 'Failed Insert Config ', e.response);
        }
    };

    requestGetOrgConfig = function* () {
        // console.log(logPrefix, "Starting Get OrgConfig")

        this.isOrganizationDataLoading = true;
        try {
            const response = yield axios.get(`${this.serverContextPath}${URL}`);

            this.checkOrgData(response.data.orgConfigs, response.data.orgImages);
            this.isOrganizationDataLoading = false;
            // console.log(logPrefix, "Success Get OrgConfig")
        } catch (e) {
            this.isOrganizationDataLoading = false;

            console.log(logPrefix, 'Failed Get OrgConfig ', e);
        }
    };

    requestUpdateOrgConfig = function* (intl) {
        // console.log(logPrefix, "Starting Update OrgConfig")

        this.isOrganizationDataLoading = true;
        const params = Object.assign(
            {},
            {
                image: [],
                config: [],
            },
        );
        params.image = this.setImageParams(this.orgImages);
        params.config = this.setConfigParams(this.orgConfigs);

        try {
            const response = yield axios.post(`${this.serverContextPath}${URL}`, params);

            this.checkOrgData(response.data.orgConfigs, response.data.orgImages);
            this.isOrganizationDataLoading = false;
        } catch (e) {
            this.isOrganizationDataLoading = false;
            this.handleError(
                intl.formatMessage({
                    id: 'msg.has_error_set_organization_configs',
                }),
                e.response.status,
            );
            // this.handleError('조직정보 설정 중 오류가 발생하였습니다. \n 다시 시도해주세요', e.response.status);

            console.log(logPrefix, 'Failed Update OrgConfig ', e.response);
        }
    };

    *getGroupColor(groupId) {
        console.log(logPrefix, 'get GroupColor starting... groupId={}', groupId);
        try {
            if (groupId === 0 || groupId === null || groupId === undefined) {
                return false;
            }
            const response = yield axios.get(`${this.serverContextPath}${URL}/color/${groupId}`);
            this.setGroupColor(response.data);
        } catch (e) {
            console.log(logPrefix, 'Failed Update GroupColor ', e.response);
        }
    }

    *findLicenseKeys({ keyword = '' }) {
        console.log(logPrefix, `Start findLicenseKeys keyword = ${keyword}`);
        try {
            const response = yield axios.get(this.serverContextPath + `${URL}/license-keys?keyword=${keyword}`);
            console.log(logPrefix, 'Success findLicenseKeys');
            console.log(logPrefix, 'Result = ', response?.data);
            this.licenseKeys = response?.data;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed findLicenseKeys', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed findLicenseKeys', e.request);
            } else {
                console.log(logPrefix, 'Failed findLicenseKeys', e.message);
            }
        }
    }
    *findAllLicenseKeys() {
        console.log(logPrefix, `Start findAllLicenseKeys`);
        try {
            const response = yield axios.get(this.serverContextPath + `${URL}/license-keys/all`);
            console.log(logPrefix, 'Success findAllLicenseKeys');
            console.log(logPrefix, 'Result = ', response?.data);
            this.licenseKeys = response?.data;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed findAllLicenseKeys', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed findAllLicenseKeys', e.request);
            } else {
                console.log(logPrefix, 'Failed findAllLicenseKeys', e.message);
            }
        }
    }
}
