import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import MobileDashboardTabTriggerComponent from './MobileDashboardTabTriggerComponent';
import MobileStudentManagmentContentsComponent from './MobileStudentManagmentContentsComponent';
import MobileStudentGroupManagmentContentsComponent from './MobileStudentGroupManagmentContentsComponent';
import TablePagination from '@mui/material/TablePagination';
import { SORT_BY } from '../class/MobileClassMainComponent';
import { DEFAULT_ROWS_PER_PAGE } from '../../../stores/PaginationStore';
import ViewAccountDialogComponent from '../../dialog/ViewAccountDialogComponent';
import ModifyAccountDialogComponent from '../../dialog/ModifyAccountDialogComponent';
import { MEMBER_COMPONENT_TAB_NAME } from './MobileStudentListComponent';
import ConfirmDialog from '../../../components/common/ConfirmDialog';
import { injectIntl } from 'react-intl';
import YesOrNoDialog from '../../../components/common/YesOrNoDialog';
import CustomSearchComponent from '../../../components/CustomSearchComponent';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    wrap: {
        width: 'calc(100vw - 32px)',
        margin: '0 auto',
        // padding: '12px 0',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    contentsWrap: {
        position: 'relative',
        width: 'calc(100vw - 32px)',
        margin: '0 auto',
        padding: '8px 0px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    contents: {
        width: 'calc(100vw - 32px)',
        margin: '0px auto 30px',
    },
    searchStyle: {
        padding: '0 16px',
    },
    gutter: {
        backgroundColor: 'rgba(192, 194, 195, 0.7)',
        width: '100%',
        height: 1,
    },

    titleCaption: {
        fontSize: '0.938rem',
    },
    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: 12,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listItemTitle: {
        fontSize: '0.875rem',
        display: 'flex',
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    tablePaginationBox: {
        '@media all and (max-width: 600px)': {
            '& p, .MuiTablePagination-select': {
                fontSize: '0.813rem',
            },
            '& button': {
                padding: 0,
            },
            '& .MuiInputBase-root': {
                marginRight: 8,
                marginLeft: 5,
            },
        },
    },
});

export const TAB_NAME = {
    All: 'all',
    Member: 'member',
    Group: 'group',
};

class MobileStudentsManagementComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 0,
            groupId: 0,
            groupName: '',
            sort: SORT_BY.name,
            isFiltered: false,
            viewAccount: false,
            modifyAccount: false,
            childrenTab: MEMBER_COMPONENT_TAB_NAME.Member,
            isNoAuthorityDialogOpen: false,
            isCheckForcedLeaveGroupDialogOpen: false,
        };
    }

    componentWillUnmount() {
        this.props.initAllUsers();
        this.props.initSelectedGroupMemberAndOperators();
        this.props.initTeams();
    }

    componentDidUpdate(prevProps, prevState, _snapshot) {
        if (prevState.childrenTab !== this.state.childrenTab) {
            this.props.changeRowsPerPage(DEFAULT_ROWS_PER_PAGE, this.state.groupId, this.state.childrenTab);
        }

        if (prevState.groupId !== this.state.groupId) {
            if (this.state.groupId > 0) {
                if (prevState.groupId === 0) {
                    this.props.initAllUsers();
                }
                this.props.requestInitGroupMemberAndTeamInfo(this.state.groupId, this.state.childrenTab);
                this.props.checkGroupLeader(this.state.groupId);
            } else {
                this.props.initSelectedGroupMemberAndOperators();
                this.props.initTeams();

                if (this.props.isNoClassFilter) {
                    this.props.changeNoClassFilterAndFetchData();
                } else {
                    this.props.requestAllUsers();
                }
            }
        } else {
            if (prevState.tabs !== this.state.tabs && this.state.tabs === 0) {
                if (this.props.isNoClassFilter) {
                    this.props.changeNoClassFilterAndFetchData();
                } else {
                    this.props.requestAllUsers();
                }
            }
        }

        const isOpenViewAccountDialog = prevState.viewAccount !== this.state.viewAccount && this.state.viewAccount;
        const isOpenModifyAccountDialog = prevState.modifyAccount !== this.state.modifyAccount && this.state.modifyAccount;
        const isCloseViewAccountDialog = prevState.viewAccount !== this.state.viewAccount && !this.state.viewAccount;
        const isCloseModifyAccountDialog = prevState.modifyAccount !== this.state.modifyAccount && !this.state.modifyAccount;

        if (isOpenViewAccountDialog || isOpenModifyAccountDialog) {
            this.props.requestUserInfo();
        }

        if (isCloseViewAccountDialog || isCloseModifyAccountDialog) {
            this.props.initUserInfo();
        }

        if (prevProps.isLeader !== this.props.isLeader && !this.props.isLeader) {
            this.setState({ isNoAuthorityDialogOpen: true });
        }
    }

    handleChangeSortAndFetchData = sortType => {
        this.props.changeSortAndFetchData(sortType, this.state.groupId);
    };

    handleChange = (_e, tabs) => {
        this.setState({ tabs });
    };

    handleClose = () => {
        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
            anchorGeneralCheck: null,
        });
    };

    handleClickViewAccount = () => {
        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
            anchorGeneralCheck: null,
            viewAccount: true,
        });
    };

    handleClickViewAccountClose = () => {
        this.setState({
            viewAccount: false,
        });
    };

    handleClickModifyAccount = () => {
        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
            anchorGeneralCheck: null,
            modifyAccount: true,
        });
    };

    handleClickModifyAccountClose = () => {
        this.setState({
            modifyAccount: false,
        });
    };

    // handleClickGeneral = (event, selectedUserEmail) => {
    //     this.setState({ anchorGeneral: event.currentTarget });
    //     this.props.setSelectedUserEmail(selectedUserEmail);
    // };

    handleClickGeneral = (event, selectedUser) => {
        if (event) {
            this.setState({ anchorGeneral: event.currentTarget });
        }

        this.props.setSelectedUser(selectedUser);
    };

    // handleClickGeneralCheck = (event, selectedUserEmail) => {
    //     this.setState({ anchorGeneralCheck: event.currentTarget });
    //     this.props.setSelectedUserEmail(selectedUserEmail);
    // };

    handleClickGeneralCheck = (event, selectedUser) => {
        if (event) {
            this.setState({ anchorGeneralCheck: event.currentTarget });
        }

        this.props.setSelectedUser(selectedUser);
    };

    handleChangeCheckBox = () => {
        this.setState({ isFiltered: !this.state.isFiltered });
    };

    handleChangeRowsPerPage = e => {
        this.props.changeRowsPerPage(e.target.value, this.state.groupId, this.state.childrenTab);
    };

    handleChangePage = (_e, page) => {
        this.props.changePage(page, this.state.groupId, this.state.childrenTab);
    };

    handleChangeGroupId = (groupId, groupName) => {
        this.setState({ groupId: groupId, groupName: groupName });
    };

    filteredNotJoinedUser = member => {
        if (this.state.isFiltered) {
            if (!member.joinedGroups.length) {
                return member;
            }
        } else {
            return member;
        }
    };

    handleModifyUserPenAddress = () => {
        const { userDetail } = this.props;
        this.props.modifyGuestSmartPenAddres(userDetail);
        this.handleClickModifyAccountClose();
    };

    handleGroupUsersExportExcel = () => {
        this.props.requestGroupUserExportExcel(this.state.groupId);
    };

    setSelectedChildrenTab = tabName => {
        this.setState({ childrenTab: tabName });
    };

    handleForcedLeaveGroupUser = () => {
        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
            anchorGeneralCheck: null,
            isCheckForcedLeaveGroupDialogOpen: true,
        });
    };

    handleCloseResultDialog = () => {
        this.setState({ groupId: 0 });
    };

    handleCancelForcedLeaveGroupUser = () => {
        this.setState({ isCheckForcedLeaveGroupDialogOpen: false });
    };

    handleSubmitForcedLeaveGroupUser = () => {
        this.props.requestForcedLeaveGroupUser(this.state.groupId);
    };

    handleRequestSearchGuest = keyword => {
        this.setState({ tabs: this.props.classGroupList.length + 1 }, () => {
            this.props.requestSearchGuestUserByKeyword(keyword);
        });
    };

    render() {
        const { classes, intl, classGroupList, loginUser, selectedUser, isMobile } = this.props;
        const { rowsPerPage, page, totalCount, searchKeyword, isSearchingGuest, allMembers } = this.props;
        const { groupId, anchorGeneral, anchorGeneralCheck } = this.state;
        const general = Boolean(anchorGeneral);
        const generalCheck = Boolean(anchorGeneralCheck);

        return (
            <div className={classes.root}>
                <Box className={classes.wrap}>
                    <MobileDashboardTabTriggerComponent
                        tabs={this.state.tabs}
                        handleChange={this.handleChange}
                        classGroupList={classGroupList}
                        setGroupId={this.handleChangeGroupId}
                        parentTab={this.props.parentTab}
                        searchingKeyword={searchKeyword}
                        initSearchKeyword={this.props.changeSearchKeyword}
                    />
                </Box>
                <Box className={classes.gutter}></Box>
                <div className={classes.searchStyle}>
                    <CustomSearchComponent
                        searchingKeyword={searchKeyword}
                        isMobile={isMobile}
                        placeholderText={'학생, 스마트펜 검색'}
                        isLoading={this.props.isAllUsersLoading}
                        fetchDataByKeyword={this.handleRequestSearchGuest}
                    />
                </div>
                <Box className={classes.contentsWrap}>
                    <div className={classes.contents}>
                        {groupId === 0 && (
                            /** 전체 탭뷰*/
                            <MobileStudentManagmentContentsComponent
                                handleClose={this.handleClose}
                                handleClickGeneral={this.handleClickGeneral}
                                handleClickGeneralCheck={this.handleClickGeneralCheck}
                                changeSortAndFetchData={this.handleChangeSortAndFetchData}
                                sort={this.props.sort}
                                anchorGeneral={this.state.anchorGeneral}
                                anchorGeneralCheck={this.state.anchorGeneralCheck}
                                handleClickViewAccount={this.handleClickViewAccount}
                                handleClickModifyAccount={this.handleClickModifyAccount}
                                viewAccount={this.state.viewAccount}
                                modifyAccount={this.state.modifyAccount}
                                allMembers={allMembers}
                                totalCount={totalCount}
                                allUsersExportExcel={this.props.requestAllUsersExportExcel}
                                isExcelLoading={this.props.isExcelLoading}
                                allUsersTotalCount={this.props.allUsersTotalCount}
                                isAllUsersLoading={this.props.isAllUsersLoading}
                                changeSort={this.props.changeSort}
                                isNoClassFilter={this.props.isNoClassFilter}
                                changeNoClassFilterAndFetchData={this.props.changeNoClassFilterAndFetchData}
                                isSearchingGuest={isSearchingGuest}
                            />
                        )}
                        {groupId > 0 && (
                            /** 선택한 그룹 탭뷰*/
                            <MobileStudentGroupManagmentContentsComponent
                                handleClose={this.handleClose}
                                handleClickGeneral={this.handleClickGeneral}
                                handleClickGeneralCheck={this.handleClickGeneralCheck}
                                changeSortAndFetchData={this.handleChangeSortAndFetchData}
                                sort={this.props.sort}
                                anchorGeneral={this.state.anchorGeneral}
                                anchorGeneralCheck={this.state.anchorGeneralCheck}
                                handleChangeCheckBox={this.handleChangeCheckBox}
                                isFiltered={this.state.isFiltered}
                                loginUser={loginUser}
                                requestGroupUserExportExcel={this.handleGroupUsersExportExcel}
                                groupId={groupId}
                                setSelectedChildrenTab={this.setSelectedChildrenTab}
                                handleClickViewAccount={this.handleClickViewAccount}
                                handleClickModifyAccount={this.handleClickModifyAccount}
                                handleForcedLeaveGroupUser={this.handleForcedLeaveGroupUser}
                                findAvatar={this.props.findAvatar}
                                groupUserAuthority={this.props.groupUserAuthority}
                                isLeader={this.props.isLeader}
                                isGroupUserLoading={this.props.isGroupUserLoading}
                                isTeamLoading={this.props.isTeamLoading}
                                selectedGroupMemberAndOperators={this.props.selectedGroupMemberAndOperators}
                                initSelectedGroupMemberAndOperators={this.props.initSelectedGroupMemberAndOperators}
                                changeSort={this.props.changeSort}
                            />
                        )}
                        {/*{tabs === 2 && <div>클래스3</div>}*/}
                        <TablePagination
                            component={'div'}
                            count={totalCount}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                            rowsPerPageOptions={[5, 10, 15, 20]}
                            labelRowsPerPage={'페이지 당 행수'}
                            labelDisplayedRows={({ from, to, count, page }) => {
                                return `${from}-${to} 총 ${count}개`;
                            }}
                            className={classes.tablePaginationBox}
                        />
                    </div>
                </Box>
                <ViewAccountDialogComponent
                    viewAccount={this.state.viewAccount}
                    handleClickViewAccountClose={this.handleClickViewAccountClose}
                    userDetail={this.props.userDetail}
                    smartPenAddress={this.props.smartPenAddress}
                    handleClickModifyAccount={this.handleClickModifyAccount}
                    resetPassword={this.props.resetPassword}
                    // selectedUserAvatar={this.props.selectedUserAvatar}
                    selectedUser={this.props.selectedUser}
                />
                <ModifyAccountDialogComponent
                    open={this.state.modifyAccount}
                    handleClickModifyAccountClose={this.handleClickModifyAccountClose}
                    userDetail={this.props.userDetail}
                    smartPenAddress={this.props.smartPenAddress}
                    changeSmartPenAddress={this.props.changeNewGuestSmartPenAddress}
                    checkExistSmartPenAddress={this.props.checkExistSmartPenAddress}
                    isAvailableSmartPen={this.props.isAvailableSmartPen}
                    editSmartPenAddress={this.props.editSmartPenAddress}
                    initNewGuest={this.props.initNewGuest}
                    modifyGuestSmartPenAddress={this.handleModifyUserPenAddress}
                    allMembers={allMembers}
                    // selectedUserAvatar={this.props.selectedUserAvatar}
                    selectedUser={this.props.selectedUser}
                />
                <ConfirmDialog
                    open={this.state.groupId > 0 && this.state.isNoAuthorityDialogOpen}
                    title={intl.formatMessage({ id: 'notification' })}
                    msg={`${this.state.groupName} 클래스에 대한 권한이 없습니다.`}
                    changeDialogOpen={this.handleCloseResultDialog}
                />
                <YesOrNoDialog
                    open={this.state.isCheckForcedLeaveGroupDialogOpen}
                    title={intl.formatMessage({ id: 'notification' })}
                    msg={intl.formatMessage({ id: 'msg.check_leave_group' }, { name: selectedUser && selectedUser.name ? selectedUser.name : '' })}
                    submit={this.handleSubmitForcedLeaveGroupUser}
                    changeDialogOpen={this.handleCancelForcedLeaveGroupUser}
                />
            </div>
        );
    }
}

export default withStyles(styles)(injectIntl(MobileStudentsManagementComponent));
