import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import MyLearningContentsComponent from './MyLearningContentsComponent';
import { inject, observer } from 'mobx-react';

const styles = _theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },

    tabRoot: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        margin: '0 0 auto',
    },
    trigger: {
        minHeight: 48,
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: '620px!important',
        '&.MuiTab-root': {
            letterSpacing: 0,
        },
        '& button': {
            '@media all and (min-width: 1500px)': {
                minWidth: 95,
            },
            minWidth: 80,
            minHeight: 'inherit',
            position: 'relative',
            opacity: 1,
            marginRight: 10,
            marginBottom: 10,
            '& span': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
            },
            '&:hover': {
                // fontWeight: 700,
            },
        },
        '& button.Mui-selected': {
            backgroundColor: '#0097FF',
            color: '#fff',
            // fontWeight: 700,
            borderRadius: 50,
            overflow: 'inherit',
        },
        '& .MuiTabs-indicator': {
            display: 'none',
            width: 0,
        },
        '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
        },
        '& .MuiTab-root': {
            maxWidth: '80%',
        },
    },

    wrap: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        margin: '0 auto',
        padding: '17px 0',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    ContentsWrap: {
        position: 'relative',
        '@media all and (min-width: 1500px)': {
            width: 1440,
        },
        width: 1200,
        margin: '0 auto',
        padding: '20px 30px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    contents: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        margin: '0px auto 30px',
    },
    searchStyle: {
        '@media all and (min-width: 1500px)': {
            transform: 'translate(690px, 0)',
        },
        position: 'absolute',
        top: '-40px',
        right: '50%',
        transform: 'translate(570px, 0)',
    },
    gutter: {
        backgroundColor: 'rgba(192, 194, 195, 0.7)',
        width: '100%',
        height: 1,
    },
    titleStyle: {
        fontSize: '1.5rem',
        fontWeight: 600,
        margin: '20px 0 50px',
    },
    titleCaption: {
        fontSize: '0.938rem',
    },

    btnStyle: {
        '@media all and (min-width: 1500px)': {
            width: 235,
            marginLeft: 90,
        },
        width: 230,
        height: 40,
        marginLeft: 30,
        backgroundColor: '#0097FF',
        color: '#fff',
        borderRadius: 7,
        '& svg': {
            marginRight: 4,
        },
        '&:hover': {
            backgroundColor: '#0097FF',
        },
    },

    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 26px',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listItemTitle: {
        fontSize: '0.875rem',
        display: 'flex',
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
});
class MyLearningComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 0,
            selectedGroupId: undefined,
            filter: 'date',
        };
    }

    handleChangeSort = event => {
        this.setState({ filter: event.target.value });

        this.props.neoPenStore.sortSingleUserNotes(event.target.value);
    };

    handleChange = (_e, tabs) => {
        this.setState({ tabs });
    };

    handleClose = () => {
        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
        });
    };

    handleClickGeneral = event => {
        this.setState({ anchorGeneral: event.currentTarget });
    };

    setGroupId = groupId => {
        this.setState({ selectedGroupId: groupId });
    };

    componentDidMount = async () => {
        const nPS = this.props.neoPenStore;

        if (nPS.groups.length === 0) {
            const user = this.props.authStore.loginUser;

            nPS.setUser(user);

            const cS = this.props.classStore;

            await cS.getGroupList(user);

            nPS.setGroups(cS.groupList);
        }

        await nPS.getSingleUserNotes(nPS.user.email);
    };

    componentWillUnmount() {
        this.props.classStore.initClass();
    }

    render() {
        const { classes } = this.props;
        const { tabs, anchorGeneral } = this.state;
        const general = Boolean(anchorGeneral);

        const nPS = this.props.neoPenStore;

        return (
            <div className={classes.root}>
                <Box className={classes.wrap}>
                    <Typography className={classes.titleStyle}>내 학습</Typography>

                    <div className={classes.tabRoot}>
                        <Tabs value={tabs} onChange={this.handleChange} className={classes.trigger} id={'trigger-root'}>
                            <Tab label="전체" disableRipple onClick={() => this.setGroupId(undefined)} />
                            {nPS.groups &&
                                nPS.groups.map((g, i) => {
                                    return <Tab key={g.id} label={g.name} disableRipple onClick={() => this.setGroupId(g.id)} />;
                                })}
                        </Tabs>
                    </div>
                </Box>
                <Box className={classes.gutter} />
                <Box className={classes.ContentsWrap}>
                    <div className={classes.contents}>
                        <MyLearningContentsComponent
                            handleClose={this.handleClose}
                            handleClickGeneral={this.handleClickGeneral}
                            handleChangeSort={this.handleChangeSort}
                            filter={this.state.filter}
                            anchorGeneral={this.state.anchorGeneral}
                            seletedGroupId={this.state.selectedGroupId}
                        />
                    </div>
                </Box>
            </div>
        );
    }
}

export default withStyles(styles)(inject('neoPenStore', 'authStore', 'classStore')(observer(MyLearningComponent)));
