import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    LinearProgress,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Popover,
    Select,
    Tab,
    TableCell,
    TableRow,
    Tabs,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from '../../../common/images/ArrowDownIcon.svg';
import { ReactComponent as MoreIcon } from '../../../common/images/DotsThreeOutlineVerticalIcon.svg';
import { ReactComponent as NotePencilIcon } from '../../../common/images/NotePencilIcon.svg';
import { ReactComponent as LockLaminatedIcon } from '../../../common/images/LockLaminatedIcon.svg';
import { ReactComponent as CheckCircleAgreeOffIcon } from '../../../common/images/CheckCircleAgreeOffIcon.svg';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../../common/images/CheckCircleAgreeOnIcon.svg';
import { ReactComponent as MobilePrinterIcon } from '../../../common/images/MobilePrinterIcon.svg';
import RecordInfoComponent from './MobileRecordInfoComponent';
import {MovingObjectType, PaperGroupType, PrintState} from '../../../stores/NeoPenStore';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import FormRegistrationDialogComponent from '../../dialog/FormRegistrationDialogComponent';
import PrintPreviewDialogComponent from '../../dialog/PrintPreviewDialogComponent';
import NotePlayDialogComponent from '../../dialog/NotePlayDialogComponent';
import { withRouter } from '../../../components/WithRouter';
import WrittenDeleteDialogComponent from '../../dialog/WrittenDeleteDialogComponent';
import RegisterNoteIcon from '../../../common/images/RegisterNoteIcon.png';
import { ReactComponent as FilePdfIcon } from '../../../common/images/FilePdfIcon.svg';
import FormRegistrationNeoNoteDialogComponent from '../../dialog/FormRegistrationNeoNoteDialogComponent';
import { PATH_UTIL } from '../../../common/util/path.util';
import { ReactComponent as Mathematics } from '../../../common/images/Mathematics.svg';
import MathTextBookRegistrationDialogComponent from '../../dialog/MathTextBookRegistrationDialogComponent';
import { ReactComponent as FolderPlusFillIcon } from '../../../common/images/FolderPlusFillIcon.svg';
import { TooltipType } from '../../../stores/TooltipStore';
import { ReactComponent as Info } from '../../../common/images/Info.svg';
import { ReactComponent as X } from '../../../common/images/X.svg';
import PaperGroupFolderBreadcrumbs from '../../neoPen/PaperGroupFolderBreadcrumbs';
import { ReactComponent as FolderNotchIcon } from '../../../common/images/FolderNotchIcon.svg';
import MoreButton from '../../../components/common/MoreButton';
import { Skeleton } from '@material-ui/lab';
import SelectFolderDialogComponent from '../../dialog/SelectFolderDialogComponent';
import { ToastsStore } from 'react-toasts';
import MobileRecordComponent from './MobileRecordComponent';

const styles = theme => ({
    root: {
        width: 'calc(100vw - 32px)',
        paddingBottom: 70,
        '& table button.MuiButton-root': {
            minWidth: 'unset',
            padding: 0,
        },
    },
    flexCenter: {
        width: 'calc(100% - 22px - 46px)',
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
    },
    optionBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        '&:after': {
            content: '""',
            width: '100%',
            height: 3,
            backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: -1,
        },
        '& button': {
            minWidth: 100,
            fontSize: '0.875rem',
            paddingBottom: 0,
            '&.Mui-selected': {
                fontWeight: 700,
                color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
            },
        },
        '& .MuiTabs-indicator': {
            height: 3,
            backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
    btnDefault: {
        padding: 0,
        marginBottom: 4,
        marginRight: 20,
    },
    btnStyle: {
        border: '1px solid #bfbfbf',
        borderRadius: 4,
        marginLeft: 10,
        color: '#000',
        padding: '1px 8px',
        fontWeight: 400,
        fontSize: '0.875rem',
        '&:hover': {
            background: 'transparent',
        },
    },
    popoverBox: {
        '& .MuiPopover-paper': {
            '@media all and (min-width: 1500px)': {
                width: 185,
            },
            width: 180,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
            borderRadius: 7,
            padding: '8px 0',
        },
        '& .MuiListItem-root': {
            padding: '3px 10px',
            '&:hover': {
                background: '#d3d7db',
            },
        },
        '& .MuiListItemIcon-root': {
            minWidth: 20,
        },
        '& .MuiListItemText-root': {
            paddingLeft: 0,
            '& span': {
                fontSize: '0.813rem',
            },
        },
    },
    fileInfo: {
        fontSize: '0.75rem',
        color: '#979797',
        marginTop: 7,
    },
    tableBtnStyle: {
        width: '100%',
        '& span': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& p': {
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                textAlign: 'left',
                whiteSpace: 'nowrap',
            },
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    downloadBtn: {
        '& p': {
            marginTop: 3,
            fontSize: '0.75rem',
            color: '#4d5a69',
        },
        '& svg': {
            marginRight: 6,
        },
    },
    menuItem: {
        fontSize: '0.813rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        minHeight: 40,
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    textField: {
        padding: '5px 3px',
        border: '1px solid #c4c4c4',
        borderRadius: 3,
    },
    filterStyle: {
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    filterStyleEnd: {
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'flex-end',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    checkBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#a9adb4',
        fontSize: '0.875rem',
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 4,
        },
    },
    checkBoxStyleOn: {
        color: '#0097FF',
    },
    listStyle: {
        marginBottom: 60,
        '&:last-child': {
            marginBottom: 0,
        },
        '& h5': {
            borderBottom: '1px solid #d3d7db',
            fontSize: '0.875rem',
            fontWeight: 700,
            paddingBottom: 8,
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '12px 0 12px 12px',
    },
    listItemOpenStyle: {
        borderRadius: 12,
        boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
    },
    listItemOpenInStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 12,
        background: '#f8f8f8',
        borderRadius: '12px 12px 0 0',
        '& .more-icon': {
            fill: '#000',
        },
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listItemTitle: {
        fontSize: '0.875rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    listItemStyleWrap: {
        borderBottom: '1px solid #d3d7db',
        padding: 12,
        '&>div:first-child': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    progressBarStyle: {
        width: '100%',
        '& .MuiLinearProgress-root': {
            backgroundColor: '#E5E5E6',
            borderRadius: 10,
        },
        '& .MuiLinearProgress-bar': {
            backgroundColor: '#FFC56E',
        },
    },
    progressText: {
        color: '#333',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 4,
        '& p': {
            fontSize: '0.75rem',
        },
        '& p:first-child': {
            color: '#AEB3B9',
        },
    },
    tagRedStyle: {
        fontSize: '0.625rem',
        color: '#fff',
        backgroundColor: '#fb4a59',
        padding: '3px 4px',
        fontWeight: 700,
    },
    btnStyle2: {
        border: () => (theme.configs.MainBtnColor ? `1px solid ${theme.configs.MainBtnColor}` : '1px solid #0097FF'),
        borderRadius: 7,
        color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        padding: '5px 23px',
        fontWeight: 600,
        '&:hover': {
            fontWeight: 800,
            background: 'transparent',
        },
    },
    tableBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px solid #d3d7db',
        height: 80,
        '& td': {
            borderBottom: 0,
            padding: 0,
        },
    },
    folderBox: {
        width: 'calc(100% - 56px - 46px)',
    },
    skeletonStyle: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 26px',
    },
    stepContents: {
        fontSize: '0.813rem',
        color: '#fff',
        fontWeight: 300,
    },
    explanationBox: {
        width: 210,
        padding: '10px',
        background: '#2078e8',
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 3,
        position: 'absolute',
        zIndex: 100,
        bottom: -77,
        left: -175,
        '&::before': {
            backgroundColor: '#2078e8',
            content: "''",
            display: 'block',
            height: '13px',
            position: 'absolute',
            top: -6,
            right: 37,
            transform: 'rotate( -227deg ) skew( 0deg )',
            width: '13px',
            // boxShadow: '2px -2px 2px 0 rgb(178 178 178 / 20%)',
            zIndex: 200,
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    explanationBox2: {
        width: 230,
        zIndex: 10000,
        bottom: -142,
        left: -149,
        '&::before': {
            top: -6,
            right: 13,
        },
    },
    explanationBox3: {
        left: -200,
        '&::before': {
            right: 13,
        },
    },
    btnPrint: {
        marginRight: 20,
    },
    emptyText: {
        fontSize: '0.875rem',
        color: '#000',
        marginTop: 30,
        textAlign: 'center',
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    disabledIcon: {
        '& path': {
            fill: '#c2c2c2',
        },
    },
});

const RESPONSE_KEY_CODE = {
    ESC: 27,
    ENTER: 13,
};

const LightTooltip = withStyles(_theme => ({
    tooltip: {
        padding: '4px 4px',
        border: '0.3px solid #000000',
        background: '#fffff5',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        color: '#000',
        borderRadius: '0',
        marginLeft: 5,
        marginTop: 5,
    },
}))(Tooltip);

class MobileWrittenRecordComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 0,
            anchorEl: null,
            writeDataFilter: 'date',
            paperGroupFilter: 'date',
            recordDialogOpen: false,
            recordVideoOpen: false,
            formRegistrationOpen: false,
            open: false,
            anchorGeneral: null,
            writtenRecordAnchorEl: null,
            printPreviewOpen: false,
            isFiltered: false,
            completed: 0,
            deleteDialogOpen: false,
            mathTextBookDialogOpen: false,
            menuInfoTooltip: false,

            isCreateFolder: false,
            newFolderName: '',

            renamingFolderId: undefined,
            renamingFolderName: '',

            renamingPaperGroupId: undefined,
            renamingPaperGroupName: '',
        };
        this.anchorRef = React.createRef();
        this.openRecordVideoModal = this.openRecordVideoModal.bind(this);
    }

    handleTabChange = (_event, tabs) => {
        const nPS = this.props.neoPenStore;

        nPS.selectPaperGroup(undefined);

        if (nPS.folders[null] !== undefined) nPS.selectFolder(nPS.folders[null]);

        this.setState({ tabs });
    };

    handleOpenFormRegistration = () => {
        this.setState({
            formRegistrationOpen: true,
        });
    };

    handleCloseFormRegistration = () => {
        this.setState({
            formRegistrationOpen: false,
        });
    };

    handleChangeVideoDialogOpen = () => {
        this.setState({
            recordVideoOpen: true,
        });
    };

    handleOpenPreview = async () => {
        const url = await this.props.neoPenStore.getPdfDownloadUrl();

        window.open(url);
    };

    handleOpenPrintPreviewDialog = async paperGroup => {
        const { classes } = this.props;
        const nPS = this.props.neoPenStore;

        nPS.selectPaperGroup(paperGroup);

        if (nPS.selectedPaperGroup.section !== 10) {
            ToastsStore.info('프린트를 지원하지 않는 서식입니다.', 3000, classes.toasts);

            return;
        }

        const statusMsg = await nPS.printPOD();

        ToastsStore.info(statusMsg, 3000, classes.toasts);
    };

    handleClosePrintPreviewDialog = () => {
        this.setState({
            printPreviewOpen: false,
        });
    };

    openRecordVideoModal() {
        this.setState({ recordVideoOpen: true });
    }

    handleChangeLock = () => {
        this.props.neoPenStore.updatePaperGroupPublicState();

        this.setState(_state => ({
            anchorGeneral: null,
        }));
    };

    handleClickPopover = event => {
        this.setState({
            writtenRecordAnchorEl: event.currentTarget,
            menuInfoTooltip: true,
        });
    };

    handleClosePopover = () => {
        this.props.tooltipStore.closeTooltip(TooltipType.LimitUpload);

        this.setState({
            writtenRecordAnchorEl: null,
        });
    };

    handleDelete = () => {
        this.props.neoPenStore.deletePaperGroup();

        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
            deleteDialogOpen: false,
        });
    };

    handleMoveFolderDialog = () => {
        this.handleClose();

        const nPS = this.props.neoPenStore;

        nPS.setSelectFolderDialogOpened(true, MovingObjectType.PaperGroup, nPS.selectedPaperGroup.id);
    };

    handleDeleteDialogOpen = () => {
        this.setState({
            deleteDialogOpen: true,
        });
    };

    handleClickGeneral = (event, paperGroup) => {
        this.props.neoPenStore.selectPaperGroup(paperGroup);

        this.setState({ anchorGeneral: event.currentTarget });
    };

    handleClose = () => {
        this.props.tooltipStore.closeTooltip(TooltipType.LimitUpload);

        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
        });
    };

    handleCloseInfoTooltip = () => {
        this.props.tooltipStore.closeTooltip(TooltipType.Print);
    };

    handleCloseMenuInfoTooltip = () => {
        this.props.tooltipStore.closeTooltip(TooltipType.LimitUpload);
    };

    handleChangeCheckBox = () => {
        this.setState({ isFiltered: !this.state.isFiltered });
    };

    handleClickListOpen = paperGroup => {
        const isLeader = this.props.isLeader;
        const nPS = this.props.neoPenStore;

        if (isLeader) {
            if (nPS.selectedPaperGroup === paperGroup) {
                nPS.selectPaperGroup(undefined);

                return;
            }

            nPS.selectPaperGroup(paperGroup);
        } else {
            nPS.selectPaperGroup(paperGroup);
            nPS.selectUser(nPS.user);
            const groupId = PATH_UTIL.getClassId(this.props.location.pathname);

            window.open(`/penview/${groupId}/${nPS.selectedUser.email}/${nPS.selectedPaperGroup.paperGroupId}`);
        }
    };

    handleMathTextBookDialogOpen = () => {
        this.setState({
            mathTextBookDialogOpen: true,
        });
    };

    handleCloseMathTextBookDialog = () => {
        this.setState({
            mathTextBookDialogOpen: false,
        });
    };

    handleCreateFolder = event => {
        if (event.keyCode === RESPONSE_KEY_CODE.ESC) {
            this.setState({ isCreateFolder: false });
        } else if (event.keyCode === RESPONSE_KEY_CODE.ENTER && this.state.newFolderName.length > 0) {
            const nPS = this.props.neoPenStore;
            const groupId = this.props.params.id;

            nPS.createFolder(groupId, nPS.currentFolder.id, this.state.newFolderName);
            this.setState({ isCreateFolder: false });
        }
    };

    handleRenameFolder = event => {
        if (event.keyCode === RESPONSE_KEY_CODE.ESC) {
            this.setState({ renamingFolderId: undefined, renamingFolderName: '' });
        } else if (event.keyCode === RESPONSE_KEY_CODE.ENTER && this.state.renamingFolderName.length > 0) {
            const nPS = this.props.neoPenStore;

            nPS.renameFolder(this.state.renamingFolderId, this.state.renamingFolderName);
            this.setState({ renamingFolderId: undefined, renamingFolderName: '' });
        }
    };

    handleRenamePaperGroup = (event, paperGroup) => {
        if (event.keyCode === RESPONSE_KEY_CODE.ESC) {
            this.setState({ renamingPaperGroupId: undefined, renamingPaperGroupName: '' });
        } else if (event.keyCode === RESPONSE_KEY_CODE.ENTER && this.state.renamingPaperGroupName.length > 0) {
            const nPS = this.props.neoPenStore;

            nPS.renamePaperGroup(paperGroup, this.state.renamingPaperGroupName);
            this.setState({ renamingPaperGroupId: undefined, renamingPaperGroupName: '' });
        }
    };

    selectPaperGroupMenusInMoreBtn = () => {
        const { isLeader } = this.props;
        const nPS = this.props.neoPenStore;

        if (isLeader) {
            return [
                {
                    name: nPS.selectedPaperGroup !== undefined && !nPS.selectedPaperGroup.public ? '공개로 변경' : '비공개로 변경',
                    func: () => this.handleChangeLock(),
                },
                {
                    name: '이름 바꾸기',
                    func: () => {
                        this.setState({ renamingPaperGroupId: nPS.selectedPaperGroup.id, renamingPaperGroupName: nPS.selectedPaperGroup.title });
                        this.handleClose();
                    },
                },
                { name: '이동', func: () => this.handleMoveFolderDialog() },
                { name: '삭제', func: () => this.handleDeleteDialogOpen() },
            ];
        }

        return [];
    };

    render() {
        const { classes, isLeader, tooltipStore } = this.props;
        const { isUploading } = this.props.classFileStore;
        const { tabs, anchorGeneral, writtenRecordAnchorEl, menuInfoTooltip } = this.state;
        const general = Boolean(anchorGeneral);
        const openPopover = Boolean(writtenRecordAnchorEl);

        const nPS = this.props.neoPenStore;

        const paperGroupMenusInMoreBtn = this.selectPaperGroupMenusInMoreBtn();

        let viewingPaperGroups = isLeader ? (this.state.isFiltered ? nPS.publicPaperGroups : nPS.activePaperGroups) : nPS.publicPaperGroups;

        viewingPaperGroups = viewingPaperGroups.filter(paperGroup => paperGroup.folderId === nPS.currentFolder.id);

        return (
            <div className={classes.root}>
                <Tabs value={tabs} onChange={this.handleTabChange} className={classes.tabStyle}>
                    <Tab label={isLeader ? '필기 기록' : '내 필기'} disableRipple />
                    <Tab label="서식" disableRipple />
                </Tabs>

                {tabs === 0 && <MobileRecordComponent isLeader={isLeader} />}
                {tabs === 1 && (
                    <div>
                        <Box className={classes.optionBox}>
                            {isLeader && (
                                <LightTooltip title="새 폴더 만들기" placement="bottom" className={classes.btnDefault}>
                                    <IconButton
                                        disableRipple
                                        onClick={() => {
                                            if (!this.state.isCreateFolder) this.setState({ isCreateFolder: true, newFolderName: '' });
                                        }}
                                    >
                                        <FolderPlusFillIcon />
                                    </IconButton>
                                </LightTooltip>
                            )}
                            {isLeader && (
                                <Box style={{ position: 'relative' }}>
                                    <Button
                                        aria-owns={openPopover ? 'simple-popper' : undefined}
                                        onClick={this.handleClickPopover}
                                        className={classes.btnStyle2}
                                        disableRipple
                                    >
                                        서식등록
                                    </Button>
                                    <Popover
                                        id="simple-popper"
                                        open={openPopover}
                                        anchorEl={writtenRecordAnchorEl}
                                        onClose={this.handleClosePopover}
                                        className={classes.popoverBox}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                this.setState({
                                                    writtenRecordAnchorEl: null,
                                                });

                                                tooltipStore.closeTooltip(TooltipType.LimitUpload);

                                                nPS.selectNeolabNoteDialogOpenChanged();
                                            }}
                                            disabled={isUploading}
                                            component={'label'}
                                        >
                                            <ListItemIcon>
                                                <img src={RegisterNoteIcon} alt="전용 노트 아이콘" />
                                            </ListItemIcon>
                                            <ListItemText inset primary="전용 노트 등록" />
                                        </MenuItem>

                                        <MenuItem
                                            onClick={e => {
                                                e.stopPropagation();

                                                this.setState({
                                                    writtenRecordAnchorEl: null,
                                                });

                                                tooltipStore.closeTooltip(TooltipType.LimitUpload);

                                                this.handleOpenFormRegistration();
                                            }}
                                            component={'label'}
                                            disabled={isUploading}
                                        >
                                            <ListItemIcon>
                                                <FilePdfIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                inset
                                                primary={
                                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                                        직접 등록(.pdf)
                                                        <Info
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                tooltipStore.openTooltip(TooltipType.LimitUpload);
                                                            }}
                                                        />
                                                    </Box>
                                                }
                                            />
                                        </MenuItem>
                                        <MenuItem component={'label'} onClick={this.handleMathTextBookDialogOpen}>
                                            <ListItemIcon>
                                                <Mathematics />
                                            </ListItemIcon>
                                            <ListItemText inset primary="수학톡톡 교재 등록" />
                                        </MenuItem>
                                    </Popover>
                                    {!tooltipStore.isClosed[TooltipType.LimitUpload] && menuInfoTooltip && (
                                        <Box className={clsx(classes.explanationBox, classes.explanationBox2)}>
                                            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                                                {/*TODO : 디자인 수정 필요*/}
                                                <Typography className={classes.stepContents}>최대 100MB 까지 업로드 가능합니다.</Typography>
                                                <IconButton className={classes.iconButton} onClick={this.handleCloseMenuInfoTooltip} disableRipple>
                                                    <X />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>

                        <Box className={isLeader ? classes.filterStyle : classes.filterStyleEnd}>
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                {isLeader && ( // TODO : 디자인 수정 필요
                                    <Box
                                        className={
                                            !this.state.isFiltered ? classes.checkBoxStyle : clsx(classes.checkBoxStyle, classes.checkBoxStyleOn)
                                        }
                                        onClick={this.handleChangeCheckBox}
                                    >
                                        {!this.state.isFiltered ? <CheckCircleAgreeOffIcon /> : <CheckCircleAgreeOnIcon />}
                                        공개된 서식만
                                    </Box>
                                )}
                                {/*<FormControl className={classes.formControl}>*/}
                                {/*    <Select*/}
                                {/*        value={this.state.paperGroupFilter}*/}
                                {/*        displayEmpty*/}
                                {/*        onChange={this.handleChangePaperGroupSort}*/}
                                {/*        IconComponent={() => (*/}
                                {/*            <Box style={{ width: 16, height: 16, marginLeft: 5 }}>*/}
                                {/*                <ArrowDownIcon />*/}
                                {/*            </Box>*/}
                                {/*        )}*/}
                                {/*    >*/}
                                {/*        <MenuItem value="date" className={classes.menuItem}>*/}
                                {/*            최근 등록일 순*/}
                                {/*        </MenuItem>*/}
                                {/*        <MenuItem value="alpha" className={classes.menuItem}>*/}
                                {/*            가나다 순*/}
                                {/*        </MenuItem>*/}
                                {/*    </Select>*/}
                                {/*</FormControl>*/}
                            </Box>
                            <PaperGroupFolderBreadcrumbs />
                        </Box>

                        <Box>
                            {this.state.isCreateFolder && (
                                <Box style={{ width: '100%' }}>
                                    <TableRow className={classes.tableBox}>
                                        <TableCell align="center" style={{ width: 56 }}>
                                            <FolderNotchIcon />
                                        </TableCell>
                                        <TableCell className={classes.folderBox}>
                                            <div>
                                                <input
                                                    type="text"
                                                    autoFocus
                                                    className={classes.textField}
                                                    onChange={e => this.setState({ newFolderName: e.target.value })}
                                                    onKeyDown={e => this.handleCreateFolder(e)}
                                                    // onBlur={this.handleInitCreateFolder}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <MoreButton
                                                menuItems={[
                                                    {
                                                        name: '취소',
                                                        menuFunc: () => this.setState({ isCreateFolder: false }),
                                                    },
                                                ]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </Box>
                            )}

                            {nPS.currentFolder.childIds.map(folderId => {
                                return (
                                    <Box style={{ width: '100%' }}>
                                        <TableRow className={classes.tableBox}>
                                            <TableCell align="center" style={{ width: 56 }}>
                                                <FolderNotchIcon />
                                            </TableCell>
                                            <TableCell className={classes.folderBox}>
                                                {this.state.renamingFolderId === folderId ? (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            autoFocus
                                                            className={classes.textField}
                                                            onChange={e => this.setState({ renamingFolderName: e.target.value })}
                                                            onKeyDown={e => this.handleRenameFolder(e)}
                                                            // onBlur={this.handleInitCreateFolder}
                                                            value={this.state.renamingFolderName}
                                                        />
                                                    </div>
                                                ) : (
                                                    <Button
                                                        className={classes.tableBtnStyle}
                                                        disableRipple
                                                        onClick={() => nPS.selectFolder(nPS.folders[folderId])}
                                                    >
                                                        <Typography>{nPS.folders[folderId].name}</Typography>
                                                        <Typography variant="body2" className={classes.listItemDate}>
                                                            {nPS.folders[folderId].createdDatetime.split('T')[0]}
                                                        </Typography>
                                                    </Button>
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {isLeader && (
                                                    <MoreButton
                                                        menuItems={[
                                                            {
                                                                name: '이동',
                                                                menuFunc: () =>
                                                                    nPS.setSelectFolderDialogOpened(true, MovingObjectType.Folder, folderId),
                                                            },
                                                            {
                                                                name: '이름 바꾸기',
                                                                menuFunc: () =>
                                                                    this.setState({
                                                                        renamingFolderId: folderId,
                                                                        renamingFolderName: nPS.folders[folderId].name,
                                                                    }),
                                                            },
                                                        ]}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </Box>
                                );
                            })}

                            {nPS.isUploadingPdf && (
                                <Box key={'loadingSkeleton'}>
                                    <Box className={classes.skeletonStyle}>
                                        <Skeleton variant="circle" animation="wave" width={40} height={40} style={{ marginRight: 10 }} />
                                        <Box>
                                            <Skeleton variant="rect" animation="wave" width={300} height={15} />
                                            <Skeleton variant="rect" animation="wave" width={118} height={10} style={{ marginTop: 5 }} />
                                        </Box>
                                    </Box>
                                </Box>
                            )}

                            {viewingPaperGroups.map((paperGroup, i) => {
                                let lastDate = paperGroup.updatedDatetime.split('T')[0].replaceAll('-', '.');

                                return (
                                    <Box key={i} className={classes.listItemStyle}>
                                        <Box
                                            className={classes.flexCenter}
                                            style={isLeader ? { width: 'calc(100% - 22px - 46px)' } : { width: 'calc(100% - 25px)' }}
                                        >
                                            <Box className={classes.avatar}>
                                                <NotePencilIcon />
                                            </Box>

                                            {this.state.renamingPaperGroupId === paperGroup.id ? (
                                                <div>
                                                    <input
                                                        type="text"
                                                        autoFocus
                                                        className={classes.textField}
                                                        onChange={e => this.setState({ renamingPaperGroupName: e.target.value })}
                                                        onKeyDown={e => this.handleRenamePaperGroup(e, paperGroup)}
                                                        value={this.state.renamingPaperGroupName}
                                                        // onBlur={this.handleInitCreateFolder}
                                                    />
                                                </div>
                                            ) : (
                                                <Box flexDirection="column" style={{ flexGrow: 1, width: 'calc(100% - 50px)' }}>
                                                    <LightTooltip title={'클릭하여 미리보기'} placement="bottom-start">
                                                        <Typography
                                                            variant="h5"
                                                            className={classes.listItemTitle}
                                                            onClick={() => this.handleOpenPreview(paperGroup)}
                                                            style={{ cursor: this.state.isLoadingPreview ? 'wait' : 'pointer' }}
                                                        >
                                                            {paperGroup.title.trim().replace(/(.pdf)$/, '')}
                                                            {!paperGroup.public ? <LockLaminatedIcon style={{ marginLeft: 4 }} /> : ''}
                                                        </Typography>
                                                    </LightTooltip>
                                                    <Box display="flex" alignItems="flex-start" flexDirection="column">
                                                        <Typography variant="body2" className={classes.listItemDate}>
                                                            {lastDate}
                                                        </Typography>
                                                        {(nPS.printState[paperGroup.paperGroupId] === PrintState.Ready ||
                                                            nPS.printState[paperGroup.paperGroupId] === PrintState.Printing) && (
                                                            <Box className={classes.progressBarStyle}>
                                                                <Box className={classes.progressText}>
                                                                    {/*TODO : 디자인 수정 필요*/}
                                                                    <Typography variant="body2">인코딩 중...</Typography>
                                                                </Box>
                                                                <LinearProgress
                                                                    variant="determinate"
                                                                    value={nPS.printProgress[paperGroup.paperGroupId]}
                                                                />
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>

                                        <Box style={{ position: 'relative' }}>
                                            {paperGroup.section !== 10 ? (
                                                <Tooltip title={'Ncode 적용된 서식이므로 프린트가 필요 없습니다.'}>
                                                    <div>
                                                        <IconButton
                                                            disableRipple
                                                            className={clsx(classes.iconButton, classes.disabledIcon)}
                                                            onClick={() => this.handleOpenPrintPreviewDialog(paperGroup)}
                                                            disabled={true}
                                                        >
                                                            <MobilePrinterIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <IconButton
                                                    disableRipple
                                                    className={classes.iconButton}
                                                    onClick={() => this.handleOpenPrintPreviewDialog(paperGroup)}
                                                >
                                                    <MobilePrinterIcon />
                                                </IconButton>
                                            )}
                                            {i === 0 && !tooltipStore.isClosed[TooltipType.Print] && (
                                                <Box
                                                    className={
                                                        isLeader ? classes.explanationBox : clsx(classes.explanationBox, classes.explanationBox3)
                                                    }
                                                >
                                                    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                                                        <Typography className={classes.stepContents}>
                                                            서식을 필기 수업에 사용하기 위해 ‘프린트' 버튼을 꼭 눌러주세요.
                                                        </Typography>
                                                        <IconButton
                                                            className={classes.iconButton}
                                                            onClick={this.handleCloseInfoTooltip}
                                                            disableRipple
                                                        >
                                                            <X />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>

                                        {isLeader && (
                                            <IconButton
                                                disableRipple
                                                aria-haspopup="true"
                                                onClick={event => this.handleClickGeneral(event, paperGroup)}
                                            >
                                                <MoreIcon />
                                            </IconButton>
                                        )}
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorGeneral}
                                            open={general}
                                            onClose={this.handleClose}
                                            className={classes.menuBox}
                                        >
                                            {paperGroupMenusInMoreBtn.map((item, index) => {
                                                return (
                                                    <MenuItem key={`${item.name}_${index}`} onClick={() => item.func()} className={classes.menuItem}>
                                                        {item.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Menu>
                                    </Box>
                                );
                            })}

                            {viewingPaperGroups.length === 0 && (
                                <Box>
                                    <Typography className={classes.emptyText}>등록된 서식이 없습니다.</Typography>
                                </Box>
                            )}
                        </Box>
                    </div>
                )}

                <NotePlayDialogComponent
                    open={this.state.recordVideoOpen}
                    onClose={() => this.setState({ recordVideoOpen: false })}
                    videoSrc={'../../common/images/testVideo.mp4'}
                    videoWidth={1024}
                    videoHeight={720}
                />

                <FormRegistrationDialogComponent
                    handleCloseFormRegistration={this.handleCloseFormRegistration}
                    formRegistrationOpen={this.state.formRegistrationOpen}
                    type={PaperGroupType.Study}
                />

                <FormRegistrationNeoNoteDialogComponent
                    handleCloseFormRegistration={() => {
                        nPS.selectNeolabNoteDialogOpenChanged();
                    }}
                    formRegistrationOpen={nPS.selectNeolabNoteDialogOpen}
                    type={PaperGroupType.Study}
                />

                <PrintPreviewDialogComponent
                    handleClosePrintPreviewDialog={this.handleClosePrintPreviewDialog}
                    printPreviewOpen={this.state.printPreviewOpen}
                />

                <WrittenDeleteDialogComponent
                    title={this.props.neoPenStore.selectedPaperGroup ? this.props.neoPenStore.selectedPaperGroup.title : ''}
                    handleDelete={this.handleDelete}
                    deleteDialogOpen={this.state.deleteDialogOpen}
                    handleDeleteDialogClose={this.handleDeleteDialogClose}
                />
                <MathTextBookRegistrationDialogComponent
                    handleCloseMathTextBookDialog={this.handleCloseMathTextBookDialog}
                    mathTextBookDialogOpen={this.state.mathTextBookDialogOpen}
                />

                <SelectFolderDialogComponent moveFolderDialog={nPS.isSelectFolderDialogOpened} type={PaperGroupType.Study} />
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(
        inject('neoPenStore', 'authStore', 'classStore', 'classFileStore', 'tooltipStore')(injectIntl(observer(MobileWrittenRecordComponent))),
    ),
);
