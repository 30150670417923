import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import Footer from '../footer/Footer';
import * as store from '../../stores/AuthStore';
import { withRouter } from '../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { MANUAL_TYPE, MANUAL_TYPE_NAME } from '../../stores/CommonServiceStore';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0',
        marginTop: 56,
        boxSizing: 'border-box',
        '@media all and (min-width: 601px)': {
            padding: '0',
            marginTop: 66,
        },
        '@media all and (min-width: 1200px)': {
            padding: '0',
            marginTop: 0,
        },
        '& .MuiIconButton-edgeEnd': {
            marginRight: 0,
        },
    },
    rootIn: {
        width: '100%',
        padding: '25px 10px 30px',
        margin: '0 auto',
        boxSizing: 'border-box',
        '@media all and (min-width: 1200px)': {
            width: '870px',
            padding: '25px 10px 55px',
        },
    },
    titleText: {
        fontSize: '1rem',
        color: '#000',
        paddingBottom: 14,
        '@media all and (min-width: 601px)': {
            padding: '0 10px',
            fontSize: '1.5rem',
        },
        '@media all and (min-width: 1200px)': {
            fontSize: '1.875rem',
        },
    },
    accordionBox: {
        borderTop: '1px solid #c0c2c3',
        boxShadow: 'none',
        margin: '0 auto',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            marginBottom: 0,
        },
        '& .MuiAccordionSummary-content': {
            margin: '20px 0',
            '& p': {
                fontSize: '0.75rem',
                color: '#000',
                fontWeight: 600,
            },
            '@media all and (min-width: 601px)': {
                '& p': {
                    fontSize: '1rem',
                },
            },
            '@media all and (min-width: 1200px)': {
                '& p': {
                    fontSize: '1.063rem',
                },
            },
        },
        '& .MuiAccordionSummary-root': {
            padding: 0,
        },
        '&:last-child': {
            borderRadius: 0,
        },
    },
    accordionBoxTop: {
        borderTop: '3px solid #000',
    },
    accordionDetails: {
        padding: 22,
        flexDirection: 'column',
        background: '#f8f8f8',
        marginTop: 0,
        '& p': {
            fontSize: '0.75rem',
            color: '#333',
        },
        '@media all and (min-width: 601px)': {
            marginTop: 10,
            '& p': {
                fontSize: '1rem',
            },
        },
        '@media all and (min-width: 1200px)': {
            marginTop: 30,
            '& p': {
                fontSize: '1.063rem',
            },
        },
    },
    linkText: {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: '#0097ff',
    },
    bottomBox: {
        background: '#fbfbfb',
        width: '100%',
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '20px 0',
        '@media all and (min-width: 1200px)': {
            padding: '60px 0',
            marginTop: 50,
        },
    },
    bottomText: {
        fontSize: '1rem',
        fontWeight: '600',
        '@media all and (min-width: 601px)': {
            fontSize: '2rem',
        },
    },
    bottomText2: {
        fontSize: '0.813rem',
        fontWeight: '600',
        marginTop: 20,
        marginBottom: 20,
        '@media all and (min-width: 601px)': {
            fontSize: '1rem',
            marginTop: 25,
            marginBottom: 25,
        },
    },
    bottomExtendButton: {
        background: '#0097ff',
        borderRadius: 7,
        '&:hover': {
            background: '#0097ff',
        },
        color: '#fff',
        '& svg': {
            fill: '#fff',
        },
        margin: '10px',
        borderLeft: '0.1rem solid #fff',
        borderTop: 'none',
        borderRight: 'none',
        borderBottom: 'none',
    },
    bottomButton: {
        background: '#0097ff',
        borderRadius: 7,
        width: 110,
        height: 35,
        '&:hover': {
            background: '#0097ff',
        },
        '& a': {
            color: '#fff',
            fontSize: '0.75rem',
            textDecoration: 'none',
        },
        '@media all and (min-width: 601px)': {
            width: 184,
            height: 48,
            '& a': {
                fontSize: '0.875rem',
            },
        },
        margin: '10px',
        border: 'none',
    },
    bottomMenu: {
        width: 110,
        height: 35,
        '@media all and (min-width: 601px)': {
            width: 184,
            height: 48,
        },
    },
    serviceEmailText: {
        fontSize: 14,
        color: '#787878',
    },
});

class ServiceCenter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: null,
            manualPopOpen: false,
            selectedManualType: MANUAL_TYPE.teacher,
        };

        this.anchorRef = React.createRef();
    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handlePasswordChange = e => {
        this.props.navigate('/profilesettings');
    };

    handleManualButtonToggle = () => {
        this.setState(prevState => ({ manualPopOpen: !prevState.manualPopOpen }));
    };

    handleManualButtonPopClose = event => {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }

        this.setState({ manualPopOpen: false });
    };

    handleMenuItemClick = (event, type) => {
        console.log('handleMenuItemClick');
        event.preventDefault();
        this.props.commonServiceStore.downloadManual(type, () => {
            this.setState({ selectedManualType: type, manualPopOpen: false });
        });
    };

    render() {
        const { classes, loginState, isMobile } = this.props;
        const { expanded, manualPopOpen, selectedManualType } = this.state;
        const { manualTypes } = this.props.commonServiceStore;

        return (
            <div
                className={classes.root}
                style={loginState === store.State.Authenticated ? { height: 'calc(100vh - 87px)' } : { height: 'calc(100vh - 62px)' }}
            >
                <Box className={classes.rootIn}>
                    <Box>
                        <Typography className={classes.titleText}>고객센터</Typography>
                    </Box>
                    <Accordion
                        expanded={expanded === 'panel1'}
                        onChange={this.handleChange('panel1')}
                        className={clsx(classes.accordionBox, classes.accordionBoxTop)}
                    >
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                            <Typography className={classes.heading}>[회원정보 및 인증] 아이디(ID)와 비밀번호(PW)를 잊어버렸어요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                [본인이 직접 가입한 계정인 경우]
                                <br />
                                1. 아이디 찾기
                                <br />
                                • 스터디라이브에서 아이디는 ‘이메일 주소’를 사용합니다.
                                <br />
                                • 현재 사용 중인 ‘이름’과 개설 또는 가입한 ‘클래스명’, 연락 받으실 연락처를 기입하여
                                <br />
                                &nbsp;&nbsp;&nbsp;고객센터 (
                                <a href="mailto:support@studylive.ai" target="_blank" rel="noopener noreferrer" className={classes.linkText}>
                                    support@studylive.ai
                                </a>
                                ) 로 문의주세요.
                                <br />
                                • 소유자로 확인되면 문의하신 이메일 주소로 ‘가입한 이메일 주소’를 보내드립니다.
                                <br />
                                &nbsp;&nbsp;&nbsp;해당 이메일 주소로 로그인 진행해주세요.
                                <br />
                                <br />
                                ※ 회원정보가 불확실하여 확인 불가한 경우, 아이디를 찾을 수 있는 방법이 없습니다.
                                <br />
                                <br />
                                2. 비밀번호 찾기
                                <br />
                                • 비밀번호 찾기 페이지로 이동하여 가입한 이메일 주소를 입력해주세요.
                                <br />
                                • 입력하신 이메일 주소로 비밀번호 재설정을 위한 보안 링크를 보내드립니다.
                                <br />
                                <br />
                                [선생님이 발급해 주신 계정인 경우 - 학생]
                                <br />
                                1.발급해 주신 선생님께 아이디 확인 요청 및 비밀번호 초기화 요청해주세요.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
                            <Typography className={classes.heading}>[회원정보 및 인증] 계정을 삭제(탈퇴)하고 싶어요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                스터디라이브 홈 > 프로필 설정>{' '}
                                <span onClick={this.handlePasswordChange} className={classes.linkText}>
                                    탈퇴하기
                                </span>{' '}
                                버튼을 눌러 계정 삭제를 진행하세요.
                                <br />
                                <br />
                                ※ 탈퇴 전 확인 사항
                                <br />• 서비스를 탈퇴하면, 내 계정이 삭제되며 가입한 모든 모임과 내가 작성한 일정, 게시물, 댓글 등 모두 사라지고
                                복구가 &nbsp;&nbsp;&nbsp;불가능합니다. 필요하면 계정 삭제 전에 지우거나 수정, 보관해주세요.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={this.handleChange('panel3')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[클래스 설정] 가입한 클래스가 사라졌어요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                가입한 클래스가 목록에서 보이지 않는 경우는 보통 아래와 같아요.
                                <br />
                                <br />
                                1. 내가 사용하던 계정으로 로그인하지 않은 경우
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;기존에 사용하던 계정을 두고, 새로운 계정으로 가입하는 상황이 빈번히 일어납니다.
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;사용하는 이메일 주소를 입력하고 다시 로그인하세요.
                                <br />
                                <br />
                                2. 개별 클래스 리더(또는 권한이 있는 관리자)에 의해 강제 탈퇴된 경우
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={this.handleChange('panel4')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[클래스 설정] 가입중인 클래스에서 탈퇴하고 싶어요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                1. 멤버로 활동 중인 클래스에서 탈퇴하기
                                <br />
                                • 개별 클래스 홈 > 설정 > 클래스 탈퇴하기
                                <br />
                                <br />
                                2. [리더] 클래스 삭제하기
                                <br />
                                • 개별 클래스 홈 > 설정 > 클래스 삭제하기
                                <br />
                                <br />
                                ※ 클래스 탈퇴/삭제 후 활동했던 내용 수정이나 내용 복구가 어려우니, 안내 내용을 꼼꼼히 확인한 후 신중하게 진행하세요.
                                <br />
                                <br />
                                • 멤버가 있는 경우, 클래스을 삭제하려면 멤버들에게 공지, 게시물 또는 직접 메시지로 알림을 통해 탈퇴 요청하시고 <br />
                                &nbsp;&nbsp;&nbsp;삭제를 진행해주세요. 모든 데이터는 삭제와 동시에 복구할 수 없습니다.
                                <br />
                                &nbsp;&nbsp;&nbsp;필요하면 내가 작성한 수업, 게시물, 댓글 등 보관하거나 삭제해주세요.
                                <br />
                                &nbsp;&nbsp;&nbsp;클래스 삭제는 리더 외 멤버가 0명일 때 리더 권한으로 진행할 수 있습니다.
                                <br />
                                <br />• 멤버가 1명 이상인 경우에는 리더가 멤버를 강제 탈퇴시키거나, 클래스 삭제 공지를 통해 클래 삭제가 가능해요.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel5'} onChange={this.handleChange('panel5')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[클래스 설정] 클래스 이름은 어떻게 변경하나요?</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                클래스 리더가 관리자에게 설정 권한을 준 경우라면 관리자도 클래스 이름 및 커버 설정이 가능합니다.
                                <br />
                                리더와 설정 권한이 있는 관리자는 개별 클래스의 설정에서 클래스 이름과 커버를 변경할 수 있습니다.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel6'} onChange={this.handleChange('panel6')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[클래스 설정] 클래스에 멤버를 초대하고 싶어요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                리더는 각 클래스에서 아래의 경로로 멤버를 초대할 수 있습니다.
                                <br />
                                <br />
                                • 개별 클래스 홈 > 멤버 > 멤버 초대하기
                                <br />
                                - 학생 초대: 클래스 관리에서 등록한 학생이 있으면 선택 초대
                                <br />- 링크로 초대: 링크를 보내 내 학급 학생에게 공유하여 초대
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel7'} onChange={this.handleChange('panel7')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[클래스 설정] 탈퇴한 클래스에 다시 가입하고 싶어요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                클래스는 누구나 검색하여 확인할 수 있는 공개 클래스와 초대만으로 가입이 가능한 비공개 클래스가 있습니다.
                                <br />
                                <br />
                                • 공개 클래스는 서비스 내 클래스 검색을 통해 확인 및 재가입이 가능해요.
                                <br />• 비공개 클래스는 검색을 통해 확인할 수 없고 클래스 리더 또는 초대 권한이 있는 멤버의 초대로만 가입할 수 있어요.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel8'} onChange={this.handleChange('panel8')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[콘텐츠] 화상 수업을 시작하고 싶어요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                화상 수업은 클래스 리더만 등록이 가능합니다.
                                <br />
                                클래스 홈 > '화상수업' > '화상수업 개설하기' 버튼을 클릭하면 화상수업 개설에 필요한 정보를 입력하고 참석자를 초대할 수
                                있습니다.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel9'} onChange={this.handleChange('panel9')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[콘텐츠] 일반 자료를 등록하고 싶어요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                클래스 홈 > ‘자료실’ 메뉴에서 hwp,pdf 등 다양한 형식의 자료를 등록할 수 있습니다.
                                <br />
                                폴더를 생성하여 과목, 주제별로 자료 등록도 가능합니다.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel10'} onChange={this.handleChange('panel10')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[콘텐츠] ncode 자료를 등록하고 싶어요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                클래스 '홈' > '서식' 메뉴에서 전용노트, pdf, 수학톡톡 등 다양한 형식의 파일을 등록할 수 있습니다.
                                <br />
                                폴더를 생성하여 과목, 주제별로 자료 등록도 가능합니다.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel11'} onChange={this.handleChange('panel11')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[스마트펜] 스마트펜 전원이 켜지지 않아요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                스마트펜을 먼저 충전 해주세요. 정상 충전 시에는 빨간색 LED가 점등이 됩니다. <br />
                                100% 충전 시 빨간색 LED가 꺼집니다.
                                <br />
                                1. 전원 버튼을 길게 10초 이상 눌러 초기화 후 켜주세요.
                                <br />
                                2. 전원 버튼을 2초 이상 눌러주어야 켜집니다.
                                <br />
                                <br />※ 충전 연결 상태에서 충전 램프 반응이 없는 경우, 전원 버튼을 길게 10초 이상 눌러주시고 다시 전원 버튼을 눌러
                                정상 작동 유무를 확인해주세요.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel12'} onChange={this.handleChange('panel12')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[스마트펜] 스마트펜 전원이 꺼지지 않아요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>전원 버튼을 길게 10초 이상 눌러 초기화 후 정상 작동 유무를 확인해주세요.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel13'} onChange={this.handleChange('panel13')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[스마트펜] 스마트펜이 저절로 꺼져요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                기본 설정된 20분 동안 아무런 입력(사용)이 없을 경우 절전을 위해 스마트펜이 자동으로 꺼져요.
                                <br />
                                배터리 잔량이 부족한 상태일 수 있으니, 스마트펜을 충전해주세요.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel14'} onChange={this.handleChange('panel14')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[스마트펜] 스마트펜 필기 인식이 안돼요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                1. 학생 계정에서 스마트펜 필기 시 필기 자료가 등록되어 있는 클래스에 학생이 가입되어있는지 확인해주세요.
                                <br />
                                2. Ncode전용 노트 혹은 스터디라이브 클래스에서 등록한 학습자료를 인쇄한 것인지 확인해 주세요.
                                <br />
                                3. 그리고 실외나 직사광선이 강하면 필기 인식 결과가 좋지 못할 수 있어요. 실내에서 필기 인식을 확인해 주세요.
                                <br />
                                4. 펜의 센서 부위에 이물질이 들어갔는지, 손상이 있는지 확인해주세요.
                                <br />
                                5. 서식을 프린트 할때는 프린트 품질이 600bpi로 설정 되어 있는지 확인해주세요.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel15'} onChange={this.handleChange('panel15')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[스마트펜] 스마트펜이 기기와 연결이 안돼요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                1. 학생 계정에서 펜 연결 시 선생님이 등록한 펜의 MAC 정보와 연결을 시도하는 펜의 MAC 정보가 일치하는지
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;확인해주세요.
                                <br />
                                <br />
                                2. 사용 중인 스마트 디바이스의 운영체제가 스마트펜과 호환되는지 확인해주세요
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;(Android 5.0이상 / iOS 8.1[iPhone 5, iPad 3세대, iPad mini 1]이상)
                                <br />
                                <br />
                                ※ 윈도우 OS가 포함된 듀얼 OS 스마트 디바이스(윈도우 인텔 AP가 사용되는 기기)는 지원되지 않습니다.
                                <br />
                                <br />
                                3. 스마트 디바이스에 스마트펜이 블루투스로 연결되어 있는지 확인해주세요.
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;디바이스에 등록된 블루투스 장치가 많은 경우, 필요없는 장치를 연결 해제해주세요.
                                <br />
                                <br />
                                4. 스마트 워치를 사용하는 경우 간혹 영향이 있을 수 있어, 스마트 워치를 연결 해제해주세요.
                                <br />
                                <br />
                                5. 스마트 디바이스의 블루투스 정보를 재등록해주세요. (디바이스 재부팅 후 확인 권장)
                                <br />
                                ① 스마트 디바이스 자체 블루투스 설정에서 네오스마트펜을 삭제해주세요. (iOS 기기는 1번 항목 제외)
                                <br />
                                ② 스마트펜을 다시 등록해주세요. 등록 후 정상으로 작동되는지 확인해주세요.
                                <br />
                                <br />※ 스마트 디바이스에 등록된 블루투스 기기가 많은 경우(5~6개 이상) 제한이 있을 수 있습니다.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel16'} onChange={this.handleChange('panel16')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[스마트펜] 스마트펜에 액체가 들어갔어요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                즉시 마른 천으로 액체를 완전히 제거 후 통풍이 잘 되는 장소에서 건조(헤어드라이기 냉풍) 후 AS접수를 해주세요.
                                <br />
                                침수로 인한 고장은 무상 기간이라도 전액 유상 점검 대상입니다.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel17'} onChange={this.handleChange('panel17')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[스마트펜] 스마트펜을 흔들면 소리가 나요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                네오스마트펜의 압력 센서와 펜 심이 접촉하거나 전원 버튼과 기구물 사이의 이격으로 발생하는 소리로 자연스러운
                                현상이에요.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel18'} onChange={this.handleChange('panel18')} className={classes.accordionBox}>
                        <AccordionSummary expandIcon={<ArrowDownIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                            <Typography className={classes.heading}>[스마트펜] MAC주소를 잃어버렸어요.</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography>
                                NeoStudio 앱을 통해 Mac 주소를 확인해주세요.
                                <br />
                                <br />• 플레이스토어/앱스토어에서 NeoStudio검색 > NeoStudio 앱 설치 > 펜 연결 > 앱 하단 나타나는 MAC 주소 확인
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>

                {!isMobile && (
                    <Box className={classes.bottomBox}>
                        <Typography className={classes.bottomText}>문의 사항이 더 있으신가요?</Typography>
                        <Typography className={classes.bottomText2}>도입 및 기술지원, 사용에 대해 언제든 문의주세요</Typography>
                        {/*<Typography className={clsx(classes.bottomText2, classes.serviceEmailText)}>ts.kim@learningspark.io</Typography>*/}
                        <Box display={'flex'}>
                            <Button className={classes.bottomButton} disableRipple>
                                <a href="https://forms.gle/hsj2DDwHytgwF3xG7" target="_blank" rel="noreferrer">
                                    문의하기
                                </a>
                            </Button>
                            <ButtonGroup ref={this.anchorRef} aria-label="split button">
                                <Button
                                    style={{ width: 150, marginRight: 0 }}
                                    onClick={this.handleManualButtonToggle}
                                    className={classes.bottomButton}
                                    disableRipple
                                >
                                    <a>매뉴얼</a>
                                </Button>
                                <Button
                                    style={{ width: 10 }}
                                    className={classes.bottomExtendButton}
                                    size={'small'}
                                    aria-controls={manualPopOpen ? 'split-button-menu' : undefined}
                                    aria-expanded={manualPopOpen ? 'true' : undefined}
                                    aria-label="select merge strategy"
                                    aria-haspopup="menu"
                                    onClick={this.handleManualButtonToggle}
                                >
                                    <ArrowDownIcon />
                                </Button>
                            </ButtonGroup>
                            <Popper
                                style={{
                                    zIndex: 1,
                                }}
                                open={manualPopOpen}
                                anchorEl={this.anchorRef.current}
                                role={undefined}
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom' ? 'top' : 'bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleManualButtonPopClose}>
                                                <MenuList id="split-button-menu" autoFocusItem>
                                                    {manualTypes.map((option, index) => (
                                                        <MenuItem
                                                            className={classes.bottomMenu}
                                                            key={option}
                                                            onClick={event => this.handleMenuItemClick(event, option)}
                                                        >
                                                            {MANUAL_TYPE_NAME[option]}
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Box>
                    </Box>
                )}
                {!isMobile && <Footer />}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('commonServiceStore')(observer(ServiceCenter))));
