import React, { Component } from 'react';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Dialog,
    FormControlLabel,
    IconButton,
    InputBase,
    TextField,
    Typography
} from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as MagnifyingGlassIcon } from '../../common/images/MagnifyingGlassIcon.svg';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import TestAvatar from '../../common/images/TestAvatar.jpg';
import { ReactComponent as UnCheckedIcon } from '../../common/images/UnCheckedIcon.svg';
import { ReactComponent as CheckedIcon } from '../../common/images/CheckedIcon.svg';
import clsx from "clsx";

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 620,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleText: {
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    contentsBox:{
        margin: '24px 0 32px'
    },
    textStyle:{
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
        fontSize: '1rem',
        color: '#333',
        letterSpacing: '-0.32px',
        lineHeight: 1.4,
        '& a':{
            color: '#333'
        },
    },
    textStyle2:{
        fontWeight: 'bold',
        margin: '20px 0'
    },
    licenseBox:{
        width: 440,
        height: 40,
        boxSizing: 'border-box',
        border: '1px solid #c0c2c3',
        borderRadius: 8,
        marginTop: 8,
        background: '#fff',
        padding: '0 10px',
        display: 'flex',
        alignItems: 'center',
        '@media all and (max-width: 600px)': {
            width: '100%',
        },
    },
    hyphenText:{
        '&.MuiTypography-root':{
            fontSize: '1rem',
            color: '#a3a8af',
            letterSpacing: '-0.2px',
            margin: '0 8px 0 5px',
            '@media all and (max-width: 600px)': {
                fontSize: '0.813rem',
            },
        }
    },
    form: {
        width: 38,
        '& .MuiInputBase-root': {
            boxSizing: 'border-box',
            padding: '0',
            background: 'transparent',
        },
        '& .MuiOutlinedInput-input': {
            padding:0,
            boxSizing:'border-box'
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 0,
        },
        '& input': {
            fontSize: '1rem',
            color: '#333',
            letterSpacing: '-0.2px',
            '&::placeholder':{
                fontSize: '1rem',
                color: '#a3a8af',
                letterSpacing: '-0.2px',
                opacity:1,
                '@media all and (max-width: 600px)': {
                    fontSize: '0.813rem',
                },
            },
            '@media all and (max-width: 600px)': {
                fontSize: '0.813rem',
            },
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: '0',
        },
    },
    buttonStyle:{
        width: 207,
        height: 48,
        background: '#fff',
        border: '1px solid #c2c2c2',
        borderRadius: 8,
        fontSize: '1.125rem',
        color: '#c2c2c2',
        fontWeight: 'bold',
        padding:0,
        '&:hover':{
            background: '#fff'
        },
        '@media all and (max-width: 600px)': {
            width: 100,
            height: 31,
            fontSize: '0.75rem',
            borderRadius: 5,
        },
    },
    buttonStyle2:{
        width: 207,
        height: 48,
        background: '#0097FF',
        borderRadius: 8,
        fontSize: '1.125rem',
        color: '#fff',
        fontWeight: 'bold',
        marginLeft: 14,
        '&:hover':{
            background: '#0097FF'
        },
        '&.Mui-disabled':{
            background: '#c2c2c2',
            color: '#fff',
            '&:hover':{
                background: '#fff'
            },
        },
        '@media all and (max-width: 600px)': {
            width: 100,
            height: 31,
            fontSize: '0.75rem',
            borderRadius: 5,
        },
    },
    checkBox:{
        '&.MuiFormControlLabel-root':{
            margin:'8px 0 0',
        },
        '& .MuiButtonBase-root':{
            padding:0,
        },
        '& .MuiTypography-root':{
            fontSize: '0.875rem',
            color: '#000',
            marginLeft: 8,
        }
    },
    displayNone:{
        '@media all and (max-width: 600px)': {
            display:'none'
        },
    },
    displayNone2:{
        display:'none',
        '@media all and (max-width: 600px)': {
            display:'block'
        },
    }
});

class LicenseNotificationDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            license1: '',
            license2: '',
            license3: '',
            license4: '',
        };
    }

    handleChangeLicense1(e) {
        const license1 = (e.target.validity.valid) ? e.target.value : this.state.license1;
        this.setState({ license1 });
    }

    handleChangeLicense2(e) {
        const license2 = (e.target.validity.valid) ? e.target.value : this.state.license2;
        this.setState({ license2 });
    }

    handleChangeLicense3(e) {
        const license3 = (e.target.validity.valid) ? e.target.value : this.state.license3;
        this.setState({ license3 });
    }

    handleChangeLicense4(e) {
        const license4 = (e.target.validity.valid) ? e.target.value : this.state.license4;
        this.setState({ license4 });
    }

    render() {
        const { classes, open, close } = this.props;

        return (
            <Dialog open={open} className={classes.dialogBox}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.titleText}>서비스 사용 정책 변경 알림</Typography>
                    <IconButton className={classes.iconButton} onClick={close} disableRipple>
                        <DialogCloseIcon />
                    </IconButton>
                </Box>

                <Box className={classes.contentsBox}>
                    <Typography className={classes.textStyle}>
                        안녕하세요. 스터디라이브입니다.<br/>
                        스터디라이브를 이용해주시고 응원해주셔서 진심으로 감사드립니다.
                    </Typography>
                    <Typography className={clsx(classes.textStyle, classes.textStyle2)}>
                        Studylive 무료 서비스는 2023년 7월 20일부터 서비스를 종료합니다.
                    </Typography>
                    <Typography className={classes.textStyle}>
                        서비스 계속 사용을 위해,<br/>
                        제휴 맺은 학교의 교사는 발급 받은 서비스 라이선스 키를 로그인 이후 한번 등록<br/>
                        일반 사용자는 서비스 담당자에게 <a target="_blank" href="mailto:daewook86@neolab.net">메일</a>로 요청하고 발급 받으면 로그인 이후 한번 등록
                    </Typography>

                    <Typography className={classes.textStyle} style={{marginTop: 30}}>라이선스 키를 입력해주세요.</Typography>
                    <Box className={classes.licenseBox}>
                        <Box className={classes.form}>
                            <TextField id="license1"
                                       type="text"
                                       inputProps={{ maxLength: 4, inputMode: 'numeric', pattern: '[0-9]*' }}
                                       name="license1"
                                       onChange={this.handleChangeLicense1.bind(this)}
                                       value={this.state.license1}
                                       label=""
                                       variant="outlined"
                                       placeholder='xxxx'
                            />
                        </Box>
                        <Typography className={classes.hyphenText}>-</Typography>
                        <Box className={classes.form}>
                            <TextField id="license2"
                                       type="text"
                                       inputProps={{ maxLength: 4, inputMode: 'numeric', pattern: '[0-9]*' }}
                                       name="license2"
                                       onChange={this.handleChangeLicense2.bind(this)}
                                       value={this.state.license2}
                                       label=""
                                       variant="outlined"
                                       placeholder='xxxx'
                            />
                        </Box>
                        <Typography className={classes.hyphenText}>-</Typography>
                        <Box className={classes.form}>
                            <TextField id="license3"
                                       name="license3"
                                       type="text"
                                       inputProps={{ maxLength: 4, inputMode: 'numeric', pattern: '[0-9]*' }}
                                       label=""
                                       onChange={this.handleChangeLicense3.bind(this)}
                                       value={this.state.license3}
                                       variant="outlined"
                                       placeholder='xxxx'
                            />
                        </Box>
                        <Typography className={classes.hyphenText}>-</Typography>
                        <Box className={classes.form}>
                            <TextField id="license4"
                                       name="license4"
                                       type="text"
                                       inputProps={{ maxLength: 4, inputMode: 'numeric', pattern: '[0-9]*' }}
                                       label=""
                                       onChange={this.handleChangeLicense4.bind(this)}
                                       value={this.state.license4}
                                       variant="outlined"
                                       placeholder='xxxx'
                            />
                        </Box>
                    </Box>
                </Box>

                <Box display='flex' justifyContent='center'>
                    <Box display='flex' flexDirection='column'>
                        <Button className={classes.buttonStyle} onClick={close} disableRipple>
                            다음에
                        </Button>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value="checked"
                                    icon={<UnCheckedIcon />}
                                    checkedIcon={<CheckedIcon />}
                                    disableRipple
                                />
                            }
                            label={'1주일간 알림 받지 않기'}
                            className={clsx(classes.checkBox, classes.displayNone)}
                        />
                    </Box>
                    <Button className={classes.buttonStyle2} disabled={true} disableRipple>
                        등록
                    </Button>
                </Box>
                <Box display='flex' justifyContent='center'>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value="checked"
                                icon={<UnCheckedIcon />}
                                checkedIcon={<CheckedIcon />}
                                disableRipple
                            />
                        }
                        label={'1주일간 알림 받지 않기'}
                        className={clsx(classes.checkBox, classes.displayNone2)}
                    />
                </Box>


            </Dialog>
        );
    }
}

export default withStyles(styles)(LicenseNotificationDialogComponent);
