import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, FormControl, IconButton, Menu, MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { ReactComponent as NotePencilIcon } from '../../common/images/NotePencilIcon.svg';
import { ReactComponent as MoreIcon } from '../../common/images/DotsThreeOutlineVerticalIcon.svg';
import { ReactComponent as ArrowIcon } from '../../common/images/ArrowIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../common/images/ArrowRightIcon.svg';
import clsx from 'clsx';
import { ReactComponent as DotIcon } from '../../common/images/DotIcon.svg';
// import RecordInfoDialogComponent from '../dialog/RecordInfoDialogComponent';
import { DATE_UTIL } from '../../common/util/date.util';
import { MyClassRoomSortType, roomState } from '../../stores/RoomStore';
import dayjs from 'dayjs';
import { withRouter } from '../../components/WithRouter';
import { ClassMainPath } from '../../common/ClassMainPath';
import RecordInfoComponent from '../class/RecordInfoComponent';

const styles = theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
        '& table button.MuiButton-root': {
            minWidth: 'unset',
            padding: 0,
        },
    },
    titleCaption: {
        fontSize: '0.938rem',
    },

    btnStyle: {
        '@media all and (min-width: 1500px)': {
            width: 235,
            marginLeft: 90,
        },
        width: 230,
        height: 40,
        marginLeft: 30,
        backgroundColor: '#0097FF',
        color: '#fff',
        borderRadius: 7,
        '& svg': {
            marginRight: 4,
        },
        '&:hover': {
            backgroundColor: '#0097FF',
        },
    },

    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
    },
    caption: {
        backgroundColor: 'transparent',
        color: '#fff',
        padding: '0px 4px',
        fontSize: '0.625rem',
        fontFamily: 'Montserrat!important',
        marginLeft: 7,
        marginBottom: 2,
        textTransform: 'uppercase',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            marginRight: 3,
        },
    },
    captionLive: {
        minWidth: 30,
        backgroundColor: '#FB4A59',
    },
    captionWait: {
        minWidth: 20,
        backgroundColor: '#a3a8af',
    },
    captionEnd: {
        minWidth: 20,
        backgroundColor: '#1a5177',
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 26px',
    },
    listItemOpenStyle: {
        borderRadius: 12,
        boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
    },
    listItemOpenInStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '17px 0 17px 26px',
        background: '#f8f8f8',
        borderRadius: '12px 12px 0 0',
        '& .more-icon': {
            fill: '#000',
        },
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    widthBox: {
        width: '90%',
        justifyContent: 'flex-start',
        // cursor: 'pointer',
    },
    avatar: {
        minWidth: 40,
        minHeight: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listItemTitle: {
        fontSize: '0.875rem',
        // display: 'flex',
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        wordBreak: 'break-all',
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    pageCtlBox: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    pageCaption: {
        fontSize: '0.938rem',
        marginLeft: 5,
    },
    pageBtn: {
        '&.Mui-disabled': {
            opacity: 0.3,
        },
    },
});

const LightTooltip = withStyles(_theme => ({
    tooltip: {
        padding: '4px 4px',
        border: '0.3px solid #000000',
        background: '#fffff5',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        color: '#000',
        borderRadius: '0',
        marginLeft: 5,
        marginTop: 5,
    },
}))(Tooltip);

class MyManagementContentsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedGroup: '',
            selectedRoom: '',
            writtenRecordListOpen: false,
            filterSelect: '최근 작성일 순',
        };
    }

    checkRoomState = (startDatetime, endDatetime) => {
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedStartDatetime = DATE_UTIL.convertUTCToTimeZone(startDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedEndDatetime = DATE_UTIL.convertUTCToTimeZone(endDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        if (dayjs(convertedEndDatetime).isBefore(currentDatetime)) {
            return roomState.Ended;
        } else if (
            dayjs(currentDatetime).isBetween(convertedStartDatetime, convertedEndDatetime) ||
            dayjs(currentDatetime).isSame(convertedStartDatetime) ||
            dayjs(currentDatetime).isSame(convertedEndDatetime)
        ) {
            return roomState.Live;
        } else {
            return roomState.Future;
        }
    };

    handleViewRoomDetail = () => {
        this.props.handleClose();
        this.props.navigate(`/class/${this.state.selectedGroup}/${ClassMainPath.scheduleDetail}/${this.state.selectedRoom}`, { replace: false });
        window.scrollTo(0, 0);
    };

    handleClickMoreBtn = (e, groupId, roomId) => {
        this.props.handleClickGeneral(e);
        this.setState({ selectedGroup: groupId, selectedRoom: roomId });
    };

    handleClickListOpen = () => {
        // this.setState({
        //     writtenRecordListOpen: !this.state.writtenRecordListOpen,
        // });
    };

    handleChangeSortSelect = event => {
        this.setState({ filterSelect: event.target.value });
    };

    handleClickPrevBtn = () => {};

    handleClickNextBtn = () => {};

    render() {
        const { classes, myClassRoomList, myClassRoomCount, isSearchingRoom } = this.props;
        const general = Boolean(this.props.anchorGeneral);
        const listTitle = isSearchingRoom ? '검색결과' : '수업목록';

        return (
            <div className={classes.root}>
                <Typography className={classes.titleCaption}>
                    <b>{listTitle}</b> • {myClassRoomCount}개
                </Typography>
                {myClassRoomList.length > 0 && (
                    <Box className={classes.filterStyle}>
                        <FormControl className={classes.formControl}>
                            <Select
                                value={this.props.filter}
                                displayEmpty
                                onChange={this.props.handleChangeSort}
                                IconComponent={() => (
                                    <Box style={{ width: 16, height: 16, marginLeft: 5 }}>
                                        <ArrowDownIcon />
                                    </Box>
                                )}
                            >
                                <MenuItem value={MyClassRoomSortType.Recent} className={classes.menuItem}>
                                    최근 등록일순
                                </MenuItem>
                                <MenuItem value={MyClassRoomSortType.RoomState} className={classes.menuItem}>
                                    수업 진행일순
                                </MenuItem>
                                <MenuItem value={MyClassRoomSortType.MaxUsers} className={classes.menuItem}>
                                    학생수 많은순
                                </MenuItem>
                                <MenuItem value={MyClassRoomSortType.Alphabet} className={classes.menuItem}>
                                    가나다순
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                )}
                {myClassRoomList.map((room, i) => {
                    const state = this.checkRoomState(room.startDatetime, room.endDatetime);
                    return (
                        <Box key={i} className={this.state.writtenRecordListOpen === true ? classes.listItemOpenStyle : ''}>
                            <Box className={this.state.writtenRecordListOpen === true ? classes.listItemOpenInStyle : classes.listItemStyle}>
                                <Box
                                    className={clsx(classes.flexCenter, classes.widthBox)}
                                    // onClick={this.handleClickListOpen}
                                >
                                    <Box className={classes.avatar}>
                                        <NotePencilIcon />
                                    </Box>
                                    <Box flexDirection="column">
                                        <Box display="flex" flexDirection="row">
                                            <LightTooltip title={room.name} placement="bottom-start">
                                                <Typography variant="h5" className={classes.listItemTitle}>
                                                    {room.name}
                                                </Typography>
                                            </LightTooltip>
                                            {state === roomState.Live && (
                                                <Box className={clsx(classes.caption, classes.captionLive)}>
                                                    <DotIcon />
                                                    Live
                                                </Box>
                                            )}
                                            {state === roomState.Future && <Box className={clsx(classes.caption, classes.captionWait)}>예정</Box>}
                                            {state === roomState.Ended && <Box className={clsx(classes.caption, classes.captionEnd)}>종료</Box>}
                                        </Box>
                                        <Typography variant="body2" className={classes.listItemDate}>
                                            {room.groupName} |{' '}
                                            {DATE_UTIL.convertUTCToTimeZone(room.startDatetime, DATE_UTIL.FORMAT.FULL_DATE_AMPM_DOT_FORMAT)} ~{' '}
                                            {DATE_UTIL.convertUTCToTimeZone(room.endDatetime, DATE_UTIL.FORMAT.FULL_DATE_AMPM_DOT_FORMAT)} |{' '}
                                            {room.maxUsers}명
                                        </Typography>
                                    </Box>
                                </Box>
                                <IconButton
                                    disableRipple
                                    //aria-owns={anchorEl ? 'simple-menu' : undefined}
                                    aria-haspopup="true"
                                    onClick={e => this.handleClickMoreBtn(e, room.groupId, room.id)}
                                >
                                    <MoreIcon />
                                </IconButton>
                            </Box>
                            {this.state.writtenRecordListOpen === true && (
                                <RecordInfoComponent handleChangeSort={this.handleChangeSortSelect} filter={this.state.filterSelect} />
                            )}
                        </Box>
                    );
                })}
                {/*{myClassRoomList.map((room, i) => {*/}
                {/*    const state = this.checkRoomState(room.startDatetime, room.endDatetime);*/}
                {/*    return (*/}
                {/*        <Box key={i} className={classes.listItemStyle}>*/}
                {/*            <Box className={classes.flexCenter}>*/}
                {/*                <Box className={classes.avatar}>*/}
                {/*                    <NotePencilIcon />*/}
                {/*                </Box>*/}
                {/*                <Box flexDirection="column">*/}
                {/*                    <Box display="flex" flexDirection="row">*/}
                {/*                        <Typography variant="h5" className={classes.listItemTitle}>*/}
                {/*                            {room.name}*/}
                {/*                        </Typography>*/}
                {/*                        {state === roomState.Live && (*/}
                {/*                            <Box className={clsx(classes.caption, classes.captionLive)}>*/}
                {/*                                <DotIcon />*/}
                {/*                                Live*/}
                {/*                            </Box>*/}
                {/*                        )}*/}
                {/*                        {state === roomState.Future && <Box className={clsx(classes.caption, classes.captionWait)}>예정</Box>}*/}
                {/*                        {state === roomState.Ended && <Box className={clsx(classes.caption, classes.captionEnd)}>종료</Box>}*/}
                {/*                    </Box>*/}
                {/*                    <Typography variant="body2" className={classes.listItemDate}>*/}
                {/*                        {room.groupName} |{' '}*/}
                {/*                        {DATE_UTIL.convertUTCToTimeZone(room.startDatetime, DATE_UTIL.FORMAT.FULL_DATE_AMPM_DOT_FORMAT)} ~{' '}*/}
                {/*                        {DATE_UTIL.convertUTCToTimeZone(room.endDatetime, DATE_UTIL.FORMAT.FULL_DATE_AMPM_DOT_FORMAT)} |{' '}*/}
                {/*                        {room.maxUsers}명*/}
                {/*                    </Typography>*/}
                {/*                </Box>*/}
                {/*            </Box>*/}
                {/*            <IconButton*/}
                {/*                disableRipple*/}
                {/*                //aria-owns={anchorEl ? 'simple-menu' : undefined}*/}
                {/*                aria-haspopup="true"*/}
                {/*                onClick={e => this.handleClickMoreBtn(e, room.groupId, room.id)}*/}
                {/*            >*/}
                {/*                <MoreIcon />*/}
                {/*            </IconButton>*/}
                {/*        </Box>*/}
                {/*    );*/}
                {/*})}*/}
                {myClassRoomList.length > 0 && (
                    <Box className={classes.pageCtlBox}>
                        페이지당 행 수 :
                        <FormControl className={classes.formControl}>
                            <Select
                                value={this.props.rowsPerPage}
                                style={{ fontSize: 'initial', padding: 3, marginLeft: 10 }}
                                displayEmpty
                                onChange={this.props.handleChangeRowsPerPage}
                                IconComponent={() => (
                                    <Box style={{ width: 16, height: 16, marginLeft: 5 }}>
                                        <ArrowDownIcon />
                                    </Box>
                                )}
                            >
                                <MenuItem value={'5'} className={classes.menuItem}>
                                    5
                                </MenuItem>
                                <MenuItem value={'10'} className={classes.menuItem}>
                                    10
                                </MenuItem>
                                <MenuItem value={'15'} className={classes.menuItem}>
                                    15
                                </MenuItem>
                                <MenuItem value={'20'} className={classes.menuItem}>
                                    20
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <Typography className={classes.pageCaption}>
                            {(this.props.page - 1) * this.props.rowsPerPage + 1}-
                            {myClassRoomCount < this.props.page * this.props.rowsPerPage
                                ? myClassRoomCount
                                : this.props.page * this.props.rowsPerPage}{' '}
                            총 {myClassRoomCount} 개
                        </Typography>
                        <IconButton
                            disableRipple
                            className={classes.pageBtn}
                            disabled={this.props.page === 1}
                            //aria-owns={anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup="true"
                            onClick={this.props.handleClickPrevBtn}
                        >
                            <ArrowIcon />
                        </IconButton>
                        <IconButton
                            disableRipple
                            className={classes.pageBtn}
                            disabled={myClassRoomCount <= this.props.page * this.props.rowsPerPage}
                            //aria-owns={anchorEl ? 'simple-menu' : undefined}
                            aria-haspopup="true"
                            onClick={this.props.handleClickNextBtn}
                        >
                            <ArrowRightIcon />
                        </IconButton>
                    </Box>
                )}
                <Menu
                    id="simple-menu"
                    anchorEl={this.props.anchorGeneral}
                    open={general}
                    onClose={this.props.handleClose}
                    className={classes.menuBox}
                >
                    {/*<MenuItem onClick={this.props.handleClickRecordInfo} className={classes.menuItem}>*/}
                    {/*    학생 및 필기 정보*/}
                    {/*</MenuItem>*/}
                    <MenuItem onClick={this.handleViewRoomDetail} className={classes.menuItem}>
                        사용한 클래스 보기
                    </MenuItem>
                    {/*<MenuItem onClick={this.props.handleClickAddAndDelete} className={classes.menuItem}>*/}
                    {/*    학생 추가 및 삭제*/}
                    {/*</MenuItem>*/}
                </Menu>

                {/*<RecordInfoDialogComponent*/}
                {/*    recordDialogOpen={this.props.recordDialogOpen}*/}
                {/*    handleCloseRecordDialog={this.props.handleCloseRecordDialog}*/}
                {/*    handleChangeSort={this.props.handleChangeSort}*/}
                {/*    filter={this.props.filter}*/}
                {/*/>*/}
                {/*<AddAndDeleteStudentsDialogComponent*/}
                {/*    handleClose={this.props.handleClose}*/}
                {/*    addAndDeleteDialogOpen={this.props.addAndDeleteDialogOpen}*/}
                {/*/>*/}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(MyManagementContentsComponent));
