import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, ClickAwayListener, Paper, Popper, Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    buttonText: {
        fontSize: '1rem',
        fontWeight: 'normal',
        color: '#0097ff',
        padding: 0,
        justifyContent: 'flex-start',
        textDecoration: 'underline',
        background: '#fff',
        '&:hover': {
            background: '#fff',
            textDecoration: 'underline',
        },
        '@media all and (max-width: 600px)': {
            '& span': {
                fontSize: '0.813rem',
            },
        },
    },
    popper: {
        zIndex: 1300,
        borderRadius: 10,
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    paper: {
        padding: 15,
        maxWidth: 500,
        overflow: 'auto',
        boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.4)',
        borderRadius: 10,
    },
    topBox: {
        padding: '0 0 10px',
        borderBottom: '1.4px solid #c0c2c3',
        marginBottom: 20,
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
        '@media all and (max-width: 600px)': {
            display: 'none',
        },
    },
    dialogTitleText: {
        fontSize: '1.063rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    textStyle1: {
        fontSize: '1rem',
        color: '#000',
        fontWeight: 600,
        marginRight: 16,
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
            width: 70,
            marginRight: 0,
        },
    },
    textStyle: {
        fontSize: '0.85rem',
        color: '#000',
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
            width: 250,
        },
    },
    iconBtn: {
        padding: 0,
        margin: '0 11px',
        '&:hover': {
            background: 'transparent',
        },
    },
    inputStyle: {
        width: 250,
        padding: '8px 10px',
        borderRadius: 4,
        border: '1px solid #c0c2c3',
        background: '#fff',
        '&:focus': {
            outline: 'none',
        },
    },
    passwordBtn: {
        fontSize: '0.75rem',
        fontWeight: 'normal',
        color: '#0097ff',
        padding: 0,
        justifyContent: 'flex-start',
        textDecoration: 'underline',
        background: '#fff',
        '&:hover': {
            background: '#fff',
            textDecoration: 'underline',
        },
    },
    bottomBox: {
        marginLeft: 80,
    },
    checkText: {
        fontSize: '0.75rem',
        color: '#a3a8af',
        margin: '0 7px 0 4px',
    },
    iconSize: {
        width: 14,
        height: 14,
    },
    bottomText: {
        fontSize: '0.75rem',
        color: '#707276',
        lineHeight: 1.8,
        marginTop: 5,
    },
    actionButton: {
        width: 300,
        height: 40,
        borderRadius: 7,
        background: '#0097ff',
        fontSize: '0.875rem',
        color: '#fff',
        marginTop: 30,
        '&:hover': {
            background: '#0097ff',
        },
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
            height: 33,
            padding: '6px 0',
            borderRadius: 4,
            margin: '20px auto 0',
        },
    },
});

class ResetPasswordDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            arrow: true,
            arrowRef: null,
            disablePortal: false,
            flip: true,
            placement: 'right',
        };
    }

    handleClickButton = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleClickReset = () => {
        this.props.resetPassword();
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        const { placement, disablePortal, flip, arrow, arrowRef } = this.state;

        return (
            <ClickAwayListener onClickAway={this.handleClose}>
                <div className={classes.root}>
                    <Button
                        buttonRef={node => {
                            this.anchorEl = node;
                        }}
                        onClick={this.handleClickButton}
                        className={classes.buttonText}
                    >
                        초기화
                    </Button>
                    <Popper
                        open={this.state.open}
                        anchorEl={this.anchorEl}
                        placement="right"
                        disablePortal={false}
                        className={classes.popper}
                        modifiers={{
                            preventOverflow: {
                                enabled: true,
                                boundariesElement: 'viewport',
                            },
                            arrow: {
                                enabled: true,
                                element: arrowRef,
                            },
                        }}
                    >
                        <span className={classes.arrow} ref={this.handleArrowRef} />
                        <Paper className={classes.paper}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.topBox}>
                                <Typography className={classes.dialogTitleText}>비밀번호 초기화</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" pb={1}>
                                <Typography className={classes.textStyle1}>새비밀번호</Typography>
                                <Box display="flex" flexDirection="column">
                                    <Box display="flex" alignItems="center">
                                        <Typography className={classes.textStyle}>
                                            *비밀번호는 이메일 주소 아이디와 동일하게 초기화됩니다.
                                            <br />
                                            *학생은 로그인 후 비밀번호를 수정할 수 있습니다.
                                        </Typography>
                                        {/*<input*/}
                                        {/*    name="password"*/}
                                        {/*    type="password"*/}
                                        {/*    tabIndex={1}*/}
                                        {/*    id="password"*/}
                                        {/*    autoFocus={true}*/}
                                        {/*    className={classes.inputStyle}*/}
                                        {/*/>*/}
                                        {/*<IconButton id="password" className={classes.iconBtn} disableRipple>*/}
                                        {/*    <EyeIcon />*/}
                                        {/*</IconButton>*/}
                                        {/*<Button className={classes.passwordBtn} disableRipple>*/}
                                        {/*    자동 생성*/}
                                        {/*</Button>*/}
                                    </Box>
                                </Box>
                            </Box>

                            {/*<Box className={classes.bottomBox}>*/}
                            {/*    <Box display="flex" alignItems="center">*/}
                            {/*        <Box display="flex" alignItems="center">*/}
                            {/*            <CheckCircleAgreeOffIcon className={classes.iconSize} />*/}
                            {/*            <Typography className={classes.checkText}>영문, 숫자, 특수문자 중 2가지 이상 조합</Typography>*/}
                            {/*        </Box>*/}
                            {/*        <Box display="flex" alignItems="center">*/}
                            {/*            <CheckCircleAgreeOnIcon className={classes.iconSize} />*/}
                            {/*            <Typography className={classes.checkText}>10자 이상</Typography>*/}
                            {/*        </Box>*/}
                            {/*    </Box>*/}
                            {/*    <Typography className={classes.bottomText}>*/}
                            {/*        *자동 생성을 권장합니다.*/}
                            {/*        <br />*/}
                            {/*        *학생은 로그인 후 비밀번호를 수정할 수 있습니다.*/}
                            {/*    </Typography>*/}
                            {/*</Box>*/}
                            <Box display="flex" justifyContent="center">
                                <Button className={classes.actionButton} disableRipple onClick={this.handleClickReset}>
                                    초기화
                                </Button>
                            </Box>
                        </Paper>
                    </Popper>
                </div>
            </ClickAwayListener>
        );
    }
}

export default withStyles(styles)(ResetPasswordDialogComponent);
