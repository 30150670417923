import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Typography,
} from '@material-ui/core';
import { ReactComponent as LoginPasswordIcon } from '../../../../common/images/LoginPasswordIcon.svg';
import { ReactComponent as LoginEyeSlashIcon } from '../../../../common/images/LoginEyeSlashIcon.svg';
import { ReactComponent as LoginEyeIcon } from '../../../../common/images/LoginEyeIcon.svg';
import { ReactComponent as UnCheckedIcon } from '../../../../common/images/UnCheckedIcon.svg';
import { ReactComponent as CheckedIcon } from '../../../../common/images/CheckedIcon.svg';
import { inject, observer } from 'mobx-react';
import { withRouter } from '../../../../components/WithRouter';
import { FormattedMessage, injectIntl } from 'react-intl';
import ConfirmDialog from '../../../../components/common/ConfirmDialog';
import { OAUTH_OPERATION } from '../../../../stores/AuthStore';
import { checkSpace } from '../../../../common/Validation';
import { ClassMainPath } from '../../../../common/ClassMainPath';
import { SessionStorageGroupInviteToken } from '../../../../stores/ClassStore';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ReactComponent as Info2 } from '../../../../common/images/Info2.svg';
import LicenseKeyInfoBox from '../../../../components/LicenseKeyInfoBox';

const styles = _theme => ({
    root: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'center',
    },
    loginBox: {
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        borderRadius: 12,
        marginTop: 45,
    },
    loginText: {
        fontSize: '1.25rem',
        color: '#333',
        textAlign: 'center',
        marginBottom: 12,
        '@media all and (min-width: 601px)': {
            fontSize: '1.75rem',
        },
    },
    textField: {
        marginTop: 20,
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #d9dbde',
            },
        '& .MuiOutlinedInput-root': {
            width: 340,
            '@media (min-width: 319px) and (max-width: 374px)': {
                width: 'auto',
            },
            borderRadius: 7,
            '&:hover': {
                borderColor: '#d9dbde',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '13px 0px',
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#9d9d9d',
                opacity: 1,
            },
        },
    },
    errorTextField: {
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #ff0000',
            },
    },
    errorText: {
        fontSize: '0.813rem',
        color: '#ff0000',
        margin: '4px 0 0',
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    checkedBox: {
        margin: '10px 0 20px',
        '& .MuiIconButton-root': {
            padding: 0,
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '0.875rem',
            color: '#000',
            marginLeft: 10,
        },
    },
    buttonStyle: {
        background: '#0097ff',
        color: '#fff',
        fontSize: '1rem',
        padding: '8px 0',
        borderRadius: 7,
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (min-width: 601px)': {
            fontSize: '1.125rem',
            padding: '15px 0',
        },
    },
    textButton: {
        fontSize: '0.875rem',
        color: '#333',
    },
    underline: {
        fontWeight: '600',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    lineStyle: {
        display: 'none',
        width: '100%',
        height: 1,
        borderBottom: '1px dashed #dddddd',
        margin: '30px 0',
    },
    infoBox: {
        width: '100%',
        height: 115,
        borderRadius: 5,
        boxSizing: 'border-box',
        padding: '14px 17px',
        background: 'rgba(242, 245, 248, 0.5)',
        display: 'flex',
        marginTop: 30,
        '& p': {
            fontSize: '0.875rem',
            color: '#333',
            letterSpacing: '-0.2px',
            lineHeight: 1.4,
            '& span': {
                fontWeight: 'bold',
            },
            '& a': {
                color: '#0097ff',
            },
            '&:last-child': {
                marginTop: 8,
            },
        },
        '& svg': {
            marginRight: 4,
        },
    },
});

const RESPONSE_KEY_CODE = {
    ESC: 27,
    ENTER: 13,
};

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            emailCheck: false,
            emailValidation: true,
        };
    }

    componentDidMount() {
        const { savedEmail } = this.props.authStore;
        if (savedEmail) {
            this.props.authStore.changeIsSaveEmail(Boolean(savedEmail));
            this.props.authStore.changeSignInEmail(savedEmail);
        }
    }

    componentWillUnmount() {
        this.checkSavedEmail();
    }

    checkSavedEmail = () => {
        const { login, isSaveEmail } = this.props.authStore;
        if (isSaveEmail) {
            this.props.authStore.changeSavedEmail(login.email);
        } else {
            this.props.authStore.invalidateSavedEmail();
        }
    };

    getOAuthClientId = platformName => {
        this.props.authStore.getSocialOAuthClientId(platformName, OAUTH_OPERATION.SIGNIN, {
            requestAuthorizationCode: url => {
                window.location.href = url;
            },
        });
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleClickSingUp = _e => {
        this.props.navigate('/signup');
    };

    handleClickPasswordFind = _e => {
        this.props.navigate('/passwordfind');
    };

    changeSignInEmail = e => {
        checkSpace(e);
        this.setState({
            emailValidation: this.props.authStore.changeSignInEmail(e.target.value),
        });
    };

    changeLoginPassword = e => {
        checkSpace(e);
        this.props.authStore.changeLoginPassword(e.target.value);
    };

    handleSaveLoginEmail = e => {
        this.props.authStore.changeIsSaveEmail(e.target.checked);
    };

    handleKeyDown = (e, path) => {
        if (e.keyCode === RESPONSE_KEY_CODE.ENTER) {
            this.handleLogin(path);
        }
    };

    handleLogin = movePath => {
        const { navigate } = this.props;
        this.props.authStore.doLogin({
            moveTo: () => navigate(movePath),
            changePassword: (token, email) => navigate(`/passwordreset?token=${token}&email=${email}`),
        });
    };

    render() {
        const { classes, intl } = this.props;
        const { login, isSignInAndUpResultDialogOpen, signInAndUpResultDialogMsgId } = this.props.authStore;
        const { closeSignInAndUpResultDialog } = this.props.authStore;
        const { savedEmail } = this.props.authStore;
        const { inviteGroup } = this.props.classStore;

        const inviteToken = sessionStorage.getItem(SessionStorageGroupInviteToken);
        const signInSuccessMovePath = inviteToken ? `/class/${inviteGroup.groupId}/${ClassMainPath.todaySchedule}` : '/rooms';

        return (
            <div className={classes.root}>
                <Box className={classes.loginBox}>
                    <Typography className={classes.loginText}>
                        <FormattedMessage id={'sign_in'} />
                    </Typography>
                    <FormControl className={clsx(classes.textField, !this.state.emailValidation && classes.errorTextField)} variant="outlined">
                        <OutlinedInput
                            inputProps={{
                                'aria-label': 'email input box',
                                maxLength: 254,
                            }}
                            id="outlined-email"
                            startAdornment={
                                <InputAdornment position="start">
                                    {/*<LoginUserIcon />*/}
                                    <MailOutlineIcon style={{ color: '#C2C2C2' }} />
                                </InputAdornment>
                            }
                            labelWidth={0}
                            onChange={this.changeSignInEmail}
                            // onPaste={e => {
                            //     e.preventDefault();
                            //     return false;
                            // }}
                            placeholder="이메일"
                            defaultValue={savedEmail ? savedEmail : login.email}
                        />

                        {/* 이메일 형식이 아니면 */}
                        {!this.state.emailValidation && (
                            <FormHelperText id="component-error-text" className={classes.errorText}>
                                <FormattedMessage id={'msg.email_incorrect'} />
                            </FormHelperText>
                        )}
                    </FormControl>

                    <FormControl className={classes.textField} variant="outlined">
                        <OutlinedInput
                            inputProps={{
                                'aria-label': 'password input box',
                                maxLength: 20,
                            }}
                            id="outlined-password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            startAdornment={
                                <InputAdornment position="start">
                                    <LoginPasswordIcon />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton className={classes.iconButton} onClick={this.handleClickShowPassword} disableRipple>
                                        {this.state.showPassword ? <LoginEyeIcon /> : <LoginEyeSlashIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={0}
                            placeholder="비밀번호"
                            onChange={this.changeLoginPassword}
                            onKeyDown={e => this.handleKeyDown(e, signInSuccessMovePath)}
                            // onPaste={e => {
                            //     e.preventDefault();
                            //     return false;
                            // }}
                        />
                    </FormControl>

                    <FormControlLabel
                        control={
                            <Checkbox
                                className={'saveEmailCheckBox'}
                                icon={<UnCheckedIcon />}
                                checkedIcon={<CheckedIcon />}
                                value="emailCheck"
                                defaultChecked={Boolean(savedEmail)}
                            />
                        }
                        label="이메일 기억하기"
                        className={classes.checkedBox}
                        onChange={this.handleSaveLoginEmail}
                    />
                    <Button
                        className={classes.buttonStyle}
                        disableRipple
                        disabled={login.id === '' || login.password === '' || !this.state.emailValidation}
                        onClick={() => this.handleLogin(signInSuccessMovePath)}
                    >
                        <FormattedMessage id={'sign_in'} />
                    </Button>

                    {/* 로그인 실패시*/}
                    {/*<Typography className={classes.errorText}>일치하는 로그인 정보가 없습니다. 다시 확인하여 주시기 바랍니다.</Typography>*/}

                    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" mt={4}>
                        <Box display="flex" alignItems="center" mb={2}>
                            <Typography className={classes.textButton}>회원이 아니신가요?</Typography>
                            <Button
                                className={clsx(classes.iconButton, classes.textButton, classes.underline)}
                                disableRipple
                                onClick={this.handleClickSingUp}
                            >
                                회원가입
                            </Button>
                        </Box>
                        <Button className={clsx(classes.iconButton, classes.textButton)} disableRipple onClick={this.handleClickPasswordFind}>
                            비밀번호 찾기
                        </Button>
                    </Box>

                    {/*<LicenseKeyInfoBox />*/}

                    {/*<Box className={classes.infoBox}>*/}
                    {/*    <Info2 />*/}
                    {/*    <Box>*/}
                    {/*        <Typography>*/}
                    {/*            제휴 맺은 학교의 교사는 로그인 이후*/}
                    {/*            <br />*/}
                    {/*            <span>서비스 라이선 키 등록/인증</span>이 한번 필요합니다.*/}
                    {/*        </Typography>*/}
                    {/*        <Typography>*/}
                    {/*            일반 사용자는 서비스 담당자에게{' '}*/}
                    {/*            <a target="_blank" href="mailto:daewook86@neolab.net">*/}
                    {/*                메일*/}
                    {/*            </a>*/}
                    {/*            로 문의 및<br />*/}
                    {/*            요청해주세요.*/}
                    {/*        </Typography>*/}
                    {/*    </Box>*/}
                    {/*</Box>*/}

                    <Box className={classes.lineStyle} />

                    {/** coming soon */}

                    {/*<SocialLoginComponent*/}
                    {/*    naver={'네이버로 로그인'}*/}
                    {/*    kakao={'카카오로 로그인'}*/}
                    {/*    facebook={'페이스북으로 로그인'}*/}
                    {/*    google={'구글로 로그인'}*/}
                    {/*    getOAuthClientId={platformName => this.getOAuthClientId(platformName)}*/}
                    {/*/>*/}
                </Box>

                <ConfirmDialog
                    open={isSignInAndUpResultDialogOpen}
                    title={intl.formatMessage({ id: 'notification' })}
                    msg={intl.formatMessage({ id: `${signInAndUpResultDialogMsgId}` })}
                    changeDialogOpen={isOpen => closeSignInAndUpResultDialog(isOpen)}
                />
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(inject('authStore', 'classStore')(injectIntl(observer(SignIn)))));
