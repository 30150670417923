import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as StudyLiveTopBarLogo } from '../../../common/images/StudyLiveTopBarLogo.svg';
import { ReactComponent as StudyLiveArrowUp } from '../../../common/images/StudyLiveArrowUp.svg';
import StudyLiveImage4 from '../../../common/images/StudyLiveImage4.png';
import StudyLiveImage5 from '../../../common/images/StudyLiveImage5.png';
import StudyLiveImage7 from '../../../common/images/StudyLiveImage7.png';
import StudyLiveImage8 from '../../../common/images/StudyLiveImage8.png';
import StudyLiveImage9 from '../../../common/images/StudyLiveImage9.png';
import { Box, Button, Tab, Tabs, Typography } from '@material-ui/core';

import ModalImage1 from '../../../common/images/ModalImage1.jpg';
import ModalImage2 from '../../../common/images/ModalImage2.jpg';
import ModalImage3 from '../../../common/images/ModalImage3.jpg';
import ModalImage4 from '../../../common/images/ModalImage4.jpg';
import ModalImage5 from '../../../common/images/ModalImage5.jpg';
import ModalImage6 from '../../../common/images/ModalImage6.jpg';
import ModalImage7 from '../../../common/images/ModalImage7.PNG';
import ModalImage8 from '../../../common/images/ModalImage8.PNG';
import ModalImage9 from '../../../common/images/ModalImage9.PNG';
import ModalImage10 from '../../../common/images/ModalImage10.PNG';
import ModalImage11 from '../../../common/images/ModalImage11.PNG';
import ModalImage12 from '../../../common/images/ModalImage12.PNG';
import ModalImage13 from '../../../common/images/ModalImage13.jpg';
import ModalImage14 from '../../../common/images/ModalImage14.jpg';
import ModalImage15 from '../../../common/images/ModalImage15.PNG';
import ModalImage16 from '../../../common/images/ModalImage16.jpg';
import ModalImage17 from '../../../common/images/ModalImage17.PNG';
import ModalImage18 from '../../../common/images/ModalImage18.jpg';
import ModalImage19 from '../../../common/images/ModalImage19.PNG';
import ModalImage20 from '../../../common/images/ModalImage20.jpg';
import ModalImage21 from '../../../common/images/ModalImage21.PNG';
import ModalImage22 from '../../../common/images/ModalImage22.jpg';
import ModalImage23 from '../../../common/images/ModalImage23.png';
import ModalImage24 from '../../../common/images/ModalImage24.png';
import ModalImage25 from '../../../common/images/ModalImage25.png';
import ModalImage26 from '../../../common/images/ModalImage26.png';
import ModalImage27 from '../../../common/images/ModalImage27.png';
import ModalImage28 from '../../../common/images/ModalImage28.png';
import ModalImage29 from '../../../common/images/ModalImage29.PNG';
import ModalImage30 from '../../../common/images/ModalImage30.PNG';
import ModalImage31 from '../../../common/images/ModalImage31.PNG';
import ModalImage32 from '../../../common/images/ModalImage32.PNG';
import ModalImage33 from '../../../common/images/ModalImage33.PNG';
import ModalImage34 from '../../../common/images/ModalImage34.PNG';
import ModalImage35 from '../../../common/images/ModalImage35.png';

import Lightbox from 'react-image-lightbox';
import '../../studylive/modal.css';

const styles = theme => ({
    root: {},
    topBar: {
        background: '#fff',
        borderBottom: '1px solid #c0c2c3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0',
        boxSizing: 'border-box',
        '& svg': {
            width: 140,
            height: 20,
        },
        '@media all and (min-width: 601px)': {
            padding: '30px 0',
            '& svg': {
                width: 160,
                height: 27,
            },
        },
    },
    contentsBox: {
        width: '100%',
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 16px 20px',
        boxSizing: 'border-box',
        '@media all and (min-width: 601px)': {
            padding: '0 0 20px',
        },
    },
    titleMargin: {
        fontSize: '1.375rem',
        color: '#000000',
        fontWeight: '600',
        marginBottom: 20,
        marginTop: 30,
        textAlign: 'center',
        '@media all and (min-width: 601px)': {
            fontSize: '1.688rem',
            marginBottom: 30,
            marginTop: 58,
        },
    },
    table: {
        '& table': {
            width: '100%',
            borderBottom: '1px solid #1a1a1a',
            borderCollapse: 'collapse',
            marginBottom: 50,
            '@media all and (min-width: 601px)': {
                width: '550px',
            },
        },
        '& th': {
            background: '#1a1a1a',
            color: '#fff',
            fontWeight: 500,
        },
        '& th, td': {
            padding: '15px 10px',
            fontSize: '0.813rem',
            '@media all and (min-width: 700px)': {
                fontSize: '0.938rem',
            },
        },
    },
    tableWidth: {
        '& table': {
            '@media all and (min-width: 700px)': {
                width: '624px',
            },
        },
    },
    tdBold: {
        fontWeight: 'bold',
    },
    trBox: {
        background: '#e9e9e9',
    },
    trBox2: {
        background: '#fbfbfb',
    },
    alignTop: {
        verticalAlign: 'top',
    },
    titleStyle: {
        fontSize: '1rem',
        color: '#252525',
        margin: '20px 0 20px',
        textAlign: 'center',
        '@media all and (min-width: 601px)': {
            fontSize: '1.063rem',
            margin: '29px 0 40px',
        },
    },
    bold: {
        fontWeight: 'bold',
    },
    textStyle: {
        fontSize: '0.938rem',
        color: '#000000',
        fontWeight: 'bold',
        marginTop: 14,
    },
    smallText: {
        fontSize: '0.75rem',
        fontWeight: 'normal',
        color: '#252525',
        marginTop: 4,
        '@media all and (min-width: 601px)': {
            width: 428,
            marginLeft: 20,
        },
    },
    tabBox: {
        '& .MuiTab-root': {
            width: 150,
            minHeight: 30,
            height: 35,
            background: '#fff',
            border: '1px solid #0097ff',
            color: '#0097ff',
            fontSize: '0.938rem',
            borderRadius: 30,
            margin: '0 7px',
            boxSizing: 'border-box',
            fontWeight: '600',
            opacity: 1,
            '&:hover': {
                background: '#0097ff',
                color: '#fff',
            },
            '@media all and (min-width: 601px)': {
                width: 200,
                minHeight: 40,
                fontSize: '1rem',
            },
        },
        '& .Mui-selected': {
            width: 150,
            minHeight: 30,
            height: 35,
            background: '#0097ff',
            border: '1px solid #0097ff',
            color: '#fff',
            fontSize: '0.938rem',
            borderRadius: 30,
            margin: '0 7px',
            boxSizing: 'border-box',
            fontWeight: '600',
            '&:hover': {
                background: '#0097ff',
                color: '#fff',
            },
            '@media all and (min-width: 601px)': {
                width: 200,
                minHeight: 40,
                fontSize: '1rem',
            },
        },
        '& .MuiTabs-indicator': {
            display: 'none',
        },
    },
    widthText: {
        '@media all and (min-width: 601px)': {
            width: 642,
        },
    },
    imgBox: {
        '@media all and (min-width: 601px)': {
            display: 'flex',
            alignItems: 'center',
            marginTop: 20,
        },
    },
    imageBox: {
        width: 200,
        height: 200,
        background: '#fff',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        margin: '5px 7px',
        boxSizing: 'border-box',
        backgroundSize: 'cover',
        // '@media all and (min-width: 601px)': {
        //     width: 234,
        //     height: 232,
        // },
    },
    image4: {
        backgroundImage: `url(${StudyLiveImage4})`,
        cursor: 'pointer',
    },
    image5: {
        backgroundImage: `url(${StudyLiveImage5})`,
        cursor: 'pointer',
    },
    image7: {
        backgroundImage: `url(${StudyLiveImage7})`,
        cursor: 'pointer',
    },
    image8: {
        backgroundImage: `url(${StudyLiveImage8})`,
        cursor: 'pointer',
    },
    image9: {
        backgroundImage: `url(${StudyLiveImage9})`,
        cursor: 'pointer',
    },
    marginBottom: {
        marginBottom: '14px',
    },
    buttonStyle: {
        width: 150,
        height: 35,
        fontSize: '0.938rem',
        background: '#fff',
        border: '1px solid #0097ff',
        color: '#0097ff',
        borderRadius: 7,
        margin: '0 7px',
        boxSizing: 'border-box',
        fontWeight: 'bold',
        '&:hover': {
            background: '#0097ff',
            color: '#fff',
        },
        '@media all and (min-width: 601px)': {
            width: 234,
            height: 52,
            fontSize: '1rem',
        },
    },
    topButtonBox: {
        position: 'absolute',
        right: 16,
        bottom: 40,
        '@media all and (min-width: 601px)': {
            right: 30,
        },
    },
    topButton: {
        background: '#fff',
        boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        minWidth: 30,
        height: 30,
        '& svg': {
            width: 20,
            height: 20,
        },
        '&:hover': {
            background: '#fff',
        },
        '@media all and (min-width: 601px)': {
            minWidth: 50,
            height: 50,
            borderRadius: 17,
            '& svg': {
                width: 28,
                height: 28,
            },
        },
    },
});

const images1 = [ModalImage1, ModalImage2, ModalImage3];
const images2 = [ModalImage4, ModalImage5, ModalImage6];
const images3 = [ModalImage7, ModalImage8, ModalImage9, ModalImage10, ModalImage11, ModalImage12, ModalImage13];
const images4 = [ModalImage14, ModalImage15, ModalImage16, ModalImage17, ModalImage18, ModalImage19, ModalImage20, ModalImage21, ModalImage22];
const images5 = [
    ModalImage23,
    ModalImage24,
    ModalImage25,
    ModalImage26,
    ModalImage27,
    ModalImage28,
    ModalImage29,
    ModalImage30,
    ModalImage31,
    ModalImage32,
    ModalImage33,
    ModalImage34,
    ModalImage35,
];

class MobileStudyLive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            photoIndex: 0,
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
        };
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;
        const { value, isOpen1, isOpen2, isOpen3, isOpen4, isOpen5, photoIndex } = this.state;

        return (
            <div className={classes.root}>
                <Box className={classes.topBar} id={'top'}>
                    <StudyLiveTopBarLogo />
                </Box>
                <Box className={classes.contentsBox}>
                    <Typography className={classes.titleMargin}>스터디라이브 상품 안내</Typography>
                    <Button
                        className={classes.buttonStyle}
                        onClick={() => window.open('https://forms.gle/PtBYgQTXNz6KQZvP8', '_blank')}
                        disableRipple
                    >
                        구매 문의
                    </Button>
                    <Typography className={classes.titleStyle}>
                        <span className={classes.bold}>구매 문의</span>를 통해 <span className={classes.bold}>할인가</span>로 상품을 구매할 수
                        있습니다.
                    </Typography>
                    <Tabs value={value} onChange={this.handleChange} className={classes.tabBox}>
                        <Tab label="스마트펜" />
                        <Tab label="스마트펜 전용노트" />
                    </Tabs>
                    {value === 0 && (
                        <>
                            <Typography className={classes.titleMargin}>스마트펜 구입 가격 (할인가)</Typography>
                            <Box className={classes.table}>
                                <table>
                                    <th>스마트펜</th>
                                    <th>네오스마트펜 디모</th>
                                    <th>네오스마트펜 M1+</th>
                                    <tr align="center" className={classes.trBox2}>
                                        <td>50개 미만</td>
                                        <td>\45,000</td>
                                        <td>\134,000</td>
                                    </tr>
                                    <tr align="center" className={classes.trBox}>
                                        <td>50개 이상</td>
                                        <td>\42,000</td>
                                        <td>\126,000</td>
                                    </tr>
                                </table>
                            </Box>
                            <Box className={classes.imgBox}>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Typography className={clsx(classes.textStyle, classes.marginBottom)}>네오스마트펜 디모</Typography>
                                    <Box
                                        className={clsx(classes.imageBox, classes.image4)}
                                        onClick={() => this.setState({ isOpen1: true, photoIndex: 0 })}
                                    />
                                </Box>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Typography className={clsx(classes.textStyle, classes.marginBottom)}>네오스마트펜 M1+</Typography>
                                    <Box
                                        className={clsx(classes.imageBox, classes.image5)}
                                        onClick={() => this.setState({ isOpen2: true, photoIndex: 0 })}
                                    />
                                </Box>
                            </Box>
                            <Typography className={clsx(classes.textStyle, classes.smallText)}>
                                *스마트펜 이미지를 클릭하면 상세 이미지를 확인할 수 있습니다.
                            </Typography>
                            {isOpen1 && (
                                <Lightbox
                                    mainSrc={images1[photoIndex]}
                                    nextSrc={images1[(photoIndex + 1) % images1.length]}
                                    prevSrc={images1[(photoIndex + images1.length - 1) % images1.length]}
                                    onCloseRequest={() => this.setState({ isOpen1: false })}
                                    clickOutsideToClose={false}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + images1.length - 1) % images1.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + 1) % images1.length,
                                        })
                                    }
                                />
                            )}
                            {isOpen2 && (
                                <Lightbox
                                    mainSrc={images2[photoIndex]}
                                    nextSrc={images2[(photoIndex + 1) % images2.length]}
                                    prevSrc={images2[(photoIndex + images2.length - 1) % images2.length]}
                                    onCloseRequest={() => this.setState({ isOpen2: false })}
                                    clickOutsideToClose={false}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + images2.length - 1) % images2.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + 1) % images2.length,
                                        })
                                    }
                                />
                            )}
                        </>
                    )}
                    {value === 1 && (
                        <>
                            <Typography className={classes.titleMargin}>전용 노트구입 가격 (할인가)</Typography>
                            <Box className={clsx(classes.table, classes.tableWidth)}>
                                <table>
                                    <th>스마트펜 전용 노트</th>
                                    <th>노트 종류</th>
                                    <th>가격</th>
                                    <th>비고</th>
                                    <tr align="center" className={classes.trBox2}>
                                        <td rowspan="3" className={classes.tdBold}>
                                            디지털 노트
                                        </td>
                                        <td>디지털 노트 단품</td>
                                        <td>\900</td>
                                        <td rowspan="3" className={classes.alignTop}>
                                            색상 랜덤 배송
                                        </td>
                                    </tr>
                                    <tr align="center" className={classes.trBox2}>
                                        <td>디지털 스프링 노트 단품</td>
                                        <td>\3,000</td>
                                    </tr>
                                    <tr align="center" className={classes.trBox2}>
                                        <td>디지털 4종 set</td>
                                        <td>\5,000</td>
                                    </tr>

                                    <tr align="center" className={classes.trBox}>
                                        <td rowSpan="2" className={classes.tdBold}>
                                            프리노트
                                        </td>
                                        <td>프리노트 단품</td>
                                        <td>\4,050</td>
                                        <td rowSpan="2" className={classes.alignTop}>
                                            노트 종류 선택 필수
                                        </td>
                                    </tr>
                                    <tr align="center" className={classes.trBox}>
                                        <td>프리노트 4종 set</td>
                                        <td>\16,200</td>
                                    </tr>

                                    <tr align="center" className={classes.trBox2}>
                                        <td rowSpan="2" className={classes.tdBold}>
                                            초중고학년 노트
                                        </td>
                                        <td>초중고학년 노트 단품</td>
                                        <td>\1,000</td>
                                        <td rowSpan="2" className={classes.alignTop}>
                                            노트 종류 선택 필수
                                        </td>
                                    </tr>
                                    <tr align="center" className={classes.trBox2}>
                                        <td>초중고학년 노트 6종 set</td>
                                        <td>\6,000</td>
                                    </tr>
                                </table>
                            </Box>

                            <Box className={classes.imgBox}>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Typography className={clsx(classes.textStyle, classes.marginBottom)}>디지털노트 4종 세트</Typography>
                                    <Box
                                        className={clsx(classes.imageBox, classes.image7)}
                                        onClick={() => this.setState({ isOpen3: true, photoIndex: 0 })}
                                    />
                                </Box>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Typography className={clsx(classes.textStyle, classes.marginBottom)}>프리노트 세트</Typography>
                                    <Box
                                        className={clsx(classes.imageBox, classes.image8)}
                                        onClick={() => this.setState({ isOpen4: true, photoIndex: 0 })}
                                    />
                                </Box>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Typography className={clsx(classes.textStyle, classes.marginBottom)}>초등고학년노트 세트</Typography>
                                    <Box
                                        className={clsx(classes.imageBox, classes.image9)}
                                        onClick={() => this.setState({ isOpen5: true, photoIndex: 0 })}
                                    />
                                </Box>
                            </Box>
                            <Typography className={clsx(classes.textStyle, classes.smallText, classes.widthText)}>
                                *노트 이미지를 클릭하면 상세 이미지를 확인할 수 있습니다.
                            </Typography>
                            {isOpen3 && (
                                <Lightbox
                                    mainSrc={images3[photoIndex]}
                                    nextSrc={images3[(photoIndex + 1) % images3.length]}
                                    prevSrc={images3[(photoIndex + images3.length - 1) % images3.length]}
                                    onCloseRequest={() => this.setState({ isOpen3: false })}
                                    clickOutsideToClose={false}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + images3.length - 1) % images3.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + 1) % images3.length,
                                        })
                                    }
                                />
                            )}

                            {isOpen4 && (
                                <Lightbox
                                    mainSrc={images4[photoIndex]}
                                    nextSrc={images4[(photoIndex + 1) % images4.length]}
                                    prevSrc={images4[(photoIndex + images4.length - 1) % images4.length]}
                                    onCloseRequest={() => this.setState({ isOpen4: false })}
                                    clickOutsideToClose={false}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + images4.length - 1) % images4.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + 1) % images4.length,
                                        })
                                    }
                                />
                            )}

                            {isOpen5 && (
                                <Lightbox
                                    mainSrc={images5[photoIndex]}
                                    nextSrc={images5[(photoIndex + 1) % images5.length]}
                                    prevSrc={images5[(photoIndex + images5.length - 1) % images5.length]}
                                    onCloseRequest={() => this.setState({ isOpen5: false })}
                                    clickOutsideToClose={false}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + images5.length - 1) % images5.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + 1) % images5.length,
                                        })
                                    }
                                />
                            )}
                        </>
                    )}
                    <Box className={classes.topButtonBox}>
                        <Button
                            className={classes.topButton}
                            onClick={() => {
                                const buttonTopEl = document.getElementById('top');
                                buttonTopEl.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                            }}
                            disableRipple
                        >
                            <StudyLiveArrowUp />
                        </Button>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withStyles(styles)(MobileStudyLive);
