import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import * as validation from '../common/Validation';
import { OAUTH_UTIL } from '../common/util/oauth.util';
import { ReactComponent as LogoNaverIcon } from '../common/images/LogoNaverIcon.svg';
import { ReactComponent as LogoKakaoIcon } from '../common/images/LogoKakaoIcon.svg';
import { ReactComponent as LogoFacebookIcon } from '../common/images/LogoFacebookIcon.svg';
import { ReactComponent as LogoGoogleIcon } from '../common/images/LogoGoogleIcon.svg';
import { UTIL } from '../common/util/common.util';

const logPrefix = ' [ AuthStore ] ';

export const State = {
    Authenticated: 'Authenticated',
    NotAuthenticated: 'NotAuthenticated',
    Pending: 'Pending',
    Failed: 'Failed',
};

export const POLICY_AGREEMENT_LABELS = {
    TERMS_OF_SERVICE: 'TERMS_OF_SERVICE', //이용약관
    PRIVACY_POLICY: 'PRIVACY_POLICY', //개인정보
    ALLOWED_EMAIL: 'ALLOWED_EMAIL', //이메일 수신동의
};

export const POLICY_AGREEMENT_TYPE = {
    Basic: 'basic',
    Required: 'required',
    Optional: 'optional',
};

export const SOCIAL_PLATFORM_LOGO = {
    kakao: <LogoKakaoIcon />,
    naver: <LogoNaverIcon />,
    facebook: <LogoFacebookIcon />,
    google: <LogoGoogleIcon />,
};

export const OAUTH_OPERATION = {
    SIGNIN: 'signin',
    SIGNUP: 'signup',
};

export const EMAIL_AUTHENTICATION_RESULT_STATE = {
    Pending: 'Pending',
    Confirmed: 'Confirmed',
    Failed: 'Failed',
    Not_Allowed: 'Not_Allowed',
    Token_Expired: 'Token_Expired',
    None: 'None',
};

const Auth_API_ERROR_CODE = {
    Not_Acceptable_Id: 'NotAcceptableId',
    Authentication_Fail: 'AuthenticationFail',
    Can_Not_Found_Data: 'CanNotFoundData',
    Already_Registered_LmsUser: 'AlreadyRegisteredLmsUser',
    Not_Authenticated_User: 'NotAuthenticatedUser',
    Not_Allowed_LicenseKey: 'NotAllowedLicenseKey',
};

const MESSAGE_ID_BY_ERROR_CODE = {
    AlreadyRegisteredLmsUser: 'msg.incorrect_user',
    FailedKakaoCommunication: 'msg.error_server_bad_connection',
};

// export const LocalStorageTokenKey = '_BASKITOP_AUTHENTICATION_TOKEN_';
export const SessionStorageTokenKey = '_BASKITOP_AUTHENTICATION_TOKEN_';
export const LocalStorageSaveUserEmailToken = '_ONTHELIVE_SAVED_USER_EMAIL_';

const EmptyLogin = {
    email: '',
    password: '',
};

const EmptySignUp = {
    email: '',
    password: '',
    name: '',
    allowEmail: false,
    licenseKey: '',
};

const EmptyUser = {
    id: '',
    email: '',
    name: '',
    type: '',
    enabled: true,
    shouldChangePassword: false,
    createdDatetime: '',
    updatedDatetime: '',
};

const EmptyOauthClient = {
    clientId: '',
    redirectURI: '',
};

const AUTH_API_URL = '/api/v1/authentications';
const OAUTH_API_URL = '/api/v1/oauth';

export default class AuthStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    login = Object.assign({}, EmptyLogin);
    loginState = State.NotAuthenticated;
    loginToken = '';
    loginUser = Object.assign({}, EmptyUser);

    signup = Object.assign({}, EmptySignUp);

    captcha = false;
    captchaValue = '';

    isSignInAndUpResultDialogOpen = false;
    signInAndUpResultDialogMsgId = 'default_empty';

    isEmailAuthenticationResultDialogOpen = false;
    emailAuthenticationResultDialogMsgId = 'default_empty';

    emailAuthenticationResult = EMAIL_AUTHENTICATION_RESULT_STATE.None;

    isSaveEmail = false;

    isEmailResendDialogOpen = false;

    isExistEmail = undefined;

    initSignUp = () => {
        this.signup = Object.assign({}, EmptySignUp);
    };

    initEmailAuthenticationResult = () => {
        this.emailAuthenticationResult = EMAIL_AUTHENTICATION_RESULT_STATE.None;
    };

    initExistEmailState = () => {
        this.isExistEmail = undefined;
    };

    reSetLoginUserName = newName => {
        this.loginUser = { ...this.loginUser, name: newName };
    };

    changeCaptchaState = state => {
        this.captcha = state;
    };

    changeEmailResendDialogState = isOpen => {
        this.isEmailResendDialogOpen = isOpen;
    };

    get convertedLicenseKey() {
        const inputKey = this.signup?.licenseKey;
        return UTIL.licenseKeySplitter({ str: inputKey });
    }

    get savedEmail() {
        const savedEmail = localStorage.getItem(LocalStorageSaveUserEmailToken);
        return savedEmail;
    }

    get isEmailAuthenticationConfirmed() {
        return this.emailAuthenticationResult === EMAIL_AUTHENTICATION_RESULT_STATE.Confirmed;
    }

    get isEmailAuthenticationFailed() {
        return this.emailAuthenticationResult === EMAIL_AUTHENTICATION_RESULT_STATE.Failed;
    }

    get isEmailNotAllowed() {
        return (
            this.emailAuthenticationResult === EMAIL_AUTHENTICATION_RESULT_STATE.None ||
            this.emailAuthenticationResult === EMAIL_AUTHENTICATION_RESULT_STATE.Not_Allowed ||
            this.emailAuthenticationResult === EMAIL_AUTHENTICATION_RESULT_STATE.Token_Expired
        );
    }

    invalidateAuthToken = () => {
        const token = sessionStorage.getItem(SessionStorageTokenKey);
        if (token) {
            sessionStorage.removeItem(SessionStorageTokenKey);
        }
    };

    invalidateSavedEmail = () => {
        const savedEmail = localStorage.getItem(LocalStorageSaveUserEmailToken);
        if (savedEmail) {
            localStorage.removeItem(LocalStorageSaveUserEmailToken);
        }
    };

    changeIsSaveEmail = isSave => {
        this.isSaveEmail = isSave;
    };

    changeSavedEmail = email => {
        if (email.trim()) {
            console.log(logPrefix, 'saved email is not null = ', email);
            const savedEmail = localStorage.getItem(LocalStorageSaveUserEmailToken);
            if (!savedEmail || savedEmail !== email) {
                localStorage.setItem(LocalStorageSaveUserEmailToken, email);
            }
        }
    };

    openSignInAndUpResultDialog = (isOpen, msgId) => {
        this.signInAndUpResultDialogMsgId = msgId;
        this.isSignInAndUpResultDialogOpen = isOpen;
    };

    openEmailAuthenticationResultDialog = (isOpen, msgId) => {
        this.isEmailAuthenticationResultDialogOpen = isOpen;
        this.emailAuthenticationResultDialogMsgId = msgId;
    };

    closeSignInAndUpResultDialog = isOpen => {
        this.signInAndUpResultDialogMsgId = 'default_empty';
        this.isSignInAndUpResultDialogOpen = isOpen;
    };

    changeSignInEmail = email => {
        let result = true;
        if (email && email.trim()) {
            result = this.emailValidation(email);
            this.login = { ...this.login, email: email };
        }
        return result;
    };

    changeLoginPassword = password => {
        this.login = { ...this.login, password: password };
    };

    invalidateLogin = () => {
        this.login = Object.assign({}, EmptyLogin);
        this.loginState = State.NotAuthenticated;
        this.loginToken = '';
        this.loginUser = Object.assign({}, EmptyUser);
    };

    changeSignUpEmail = email => {
        let result = true;
        if (email && email.trim()) {
            result = this.emailValidation(email);
        }
        this.signup.email = email;
        return result;
    };

    changeSignUpPassword = password => {
        this.signup.password = password;
        return this.passwordValidation(password);
    };

    changeSignUpName = name => {
        let result = true;
        if (name && name.trim()) {
            result = this.nameValidation(name);
        }
        this.signup.name = name;
        return result;
    };

    changeSignUpLicenseKey = key => {
        this.signup.licenseKey = key?.trim();
    };

    emailValidation = id => {
        return validation.validateEmail(id);
    };

    nameValidation = name => {
        return validation.validateName(name);
    };

    passwordValidation = password => {
        let passwordCombination = validation.validatePasswordCombination(password);
        let passwordLength = validation.validatePasswordLength(password);
        let result = passwordCombination && passwordLength;
        return [password === '', result, passwordCombination, passwordLength];
    };

    verifyCaptcha = captchaValue => {
        this.captchaValue = captchaValue;
        this.verifyRecaptcha();
    };

    doLogin = function* doLogin(callbacks) {
        this.loginState = State.Pending;

        try {
            const param = { ...this.login };
            const response = yield axios.post(this.serverContextPath + `${AUTH_API_URL}/signin`, param);

            console.log('doLogin');

            if (!response.data.user.shouldChangePassword) {
                const token = response.data.token;
                const user = response.data.user;
                sessionStorage.setItem(SessionStorageTokenKey, token);
                this.loginState = State.Authenticated;
                this.loginToken = token;
                this.loginUser = user;
                callbacks.moveTo();
            } else {
                this.loginState = State.NotAuthenticated;
                callbacks.changePassword(response.data.token, response.data.user.email);
            }
        } catch (e) {
            console.log(logPrefix, 'failed sign in ', e.response);
            this.loginState = State.Failed;
            this.loginToken = '';
            this.loginUser = Object.assign({}, EmptyUser);

            if (e.response.data.errorCode === Auth_API_ERROR_CODE.Not_Authenticated_User) {
                this.changeEmailResendDialogState(true);
            } else {
                const errorMsgId = e.response.status === 500 ? 'msg.error_server_bad_connection' : 'msg.incorrect_id_or_password';
                this.openSignInAndUpResultDialog(true, errorMsgId);
            }

            // callbacks.openSignErrorNotificationDialog(true, errorMsgId);
        }
    };

    checkLogin = function* checkLogin(callbacks) {
        console.log(logPrefix, 'do check login');
        this.loginState = State.Pending;
        const token = sessionStorage.getItem(SessionStorageTokenKey);
        if (!token) {
            this.loginState = State.NotAuthenticated;
            this.loginToken = '';
            this.loginUser = Object.assign({}, EmptyUser);
            return false;
        }

        try {
            const response = yield axios.get(this.serverContextPath + `${AUTH_API_URL}/signcheck`);
            const user = response.data;

            this.loginState = State.Authenticated;
            this.loginUser = user;
        } catch (e) {
            this.loginState = State.NotAuthenticated;
            this.loginToken = '';
            this.loginUser = Object.assign({}, EmptyUser);
            sessionStorage.removeItem(SessionStorageTokenKey);
        }
    };

    doLogout = function* doLogout(callbacks) {
        sessionStorage.removeItem(SessionStorageTokenKey);

        try {
            const param = { ...this.loginUser };
            yield axios.post(this.serverContextPath + `${AUTH_API_URL}/signout`, param);

            this.invalidateLogin();
        } catch (e) {
            console.log(logPrefix, 'Failed logout');
            this.invalidateLogin();
        } finally {
            callbacks.moveToHome();
        }
    };

    verifyRecaptcha = function* verifyRecaptcha() {
        try {
            const captchaResponse = yield axios.get(this.serverContextPath + '/api/v1/captcha?captchaValue=' + this.captchaValue);
            if (captchaResponse.status === 200) {
                console.log('captcha 인증 성공');
                this.captcha = true;
            } else {
                console.log('captcha 인증 실패');
                this.captcha = false;
            }
        } catch (e) {
            console.log('captcha 인증 실패');
            this.captcha = false;
        }
    };

    signUp = function* signUp(isUserCheckedAllowedEmail, callbacks) {
        console.log('Start sign up = ', this.signup);
        const requestSignUpParams = { ...this.signup };
        requestSignUpParams.allowEmail = isUserCheckedAllowedEmail;
        requestSignUpParams.licenseKey = this.convertedLicenseKey;

        console.log('sign up requestSignUpParams = ', requestSignUpParams);
        try {
            const response = yield axios.post(this.serverContextPath + `${AUTH_API_URL}/signup`, requestSignUpParams);
            console.log('Success sign up = ', response.data);
            callbacks.openSignUpDialog(requestSignUpParams.email);
        } catch (e) {
            console.log(logPrefix, 'signUp Failed...');
            if (e.response.data.errorCode === Auth_API_ERROR_CODE.Not_Allowed_LicenseKey) {
                this.openSignInAndUpResultDialog(true, 'msg.incorrect_license_key');
            } else if (e.response.data.errorCode === Auth_API_ERROR_CODE.Not_Authenticated_User) {
                this.changeEmailResendDialogState(true);
            } else {
                const errorMsgId = 'msg.failed_sign_up';
                this.openSignInAndUpResultDialog(true, errorMsgId);
            }
        }
    };

    *getSocialOAuthClientId(platformName, operation, callbacks) {
        console.log('Start GET SocialOAuthClientId Platform = {}', platformName);
        let oAuthClient = { ...EmptyOauthClient };
        const params = { operation: operation };
        try {
            const response = yield axios.get(this.serverContextPath + `${OAUTH_API_URL}/${platformName}/clientid`, { params });
            console.log('Success GET SocialOAuthClientId');
            console.log('Result = {}', response.data);
            oAuthClient = response.data;
            const isEmptyClientId = oAuthClient.clientId === '' || oAuthClient.clientId === null;
            if (isEmptyClientId) {
                console.log(`Not Found ${platformName} client id`);
            } else {
                OAUTH_UTIL.setOAuthRedirectUri(oAuthClient.redirectURI);
                const oauthURL = OAUTH_UTIL.getOAuthURL(platformName, oAuthClient.clientId);

                callbacks.requestAuthorizationCode(oauthURL);
            }
        } catch (e) {
            console.log('Failed GET SocialOAuthClientId');
            console.debug('Result = {}', e.response);
        }
    }

    *callbackOAuthServiceForSignIn(platform, code, callbacks) {
        console.log(`Start callbackOAuthServiceForSignIn code = {} `, code);
        const params = { authorizationCode: code };
        const requestUrl = `${OAUTH_API_URL}/callback/${platform}/signin`;
        try {
            const response = yield axios.post(requestUrl, null, { params });
            console.log('Success GET SocialOAuthAccessKey');
            console.log('Result = {}', response.data);

            const token = response.data.token;
            const user = response.data.user;

            // localStorage.setItem(LocalStorageTokenKey, token);
            sessionStorage.setItem(SessionStorageTokenKey, token);

            console.log('doLogin');
            console.log(response.data);

            this.loginState = State.Authenticated;
            this.loginToken = token;
            this.loginUser = user;

            callbacks && callbacks.moveToRooms();
        } catch (e) {
            console.log('Failed GET SocialOAuthAccessKey');
            console.debug('Result = {}', e.response);
            console.log('Failed GET SocialOAuthAccessKey');
            console.log('Result = {}', e.response);
            const errorMsgId = e.response.data.errorCode ? MESSAGE_ID_BY_ERROR_CODE[e.response.data.errorCode] : 'msg.error_server_bad_connection';
            this.openSignInAndUpResultDialog(true, errorMsgId);
        }
    }

    *callbackOAuthServiceForSignUp(platform, code, isAllowEmail, callbacks) {
        console.log(`Start GET getSocialOAuthAccessKey platform = ${platform}, code = ${code}, allowedEmail = ${isAllowEmail} `);
        const params = { authorizationCode: code, allowEmail: isAllowEmail };
        const requestUrl = `${OAUTH_API_URL}/callback/${platform}/signup`;
        try {
            const response = yield axios.post(requestUrl, params);
            console.log('Success GET SocialOAuthAccessKey');
            console.log('Result = {}', response.data);
            if (response.data == null) {
                console.log('Failed GET OAuthServiceForSignUp');
                const errorMsgId = 'msg.error_server_bad_connection';
                this.openSignInAndUpResultDialog(true, errorMsgId);
            } else {
                callbacks && callbacks.openSignUpDialog(response.data);
            }
        } catch (e) {
            console.log('Failed GET SocialOAuthAccessKey');
            console.log('Result = {}', e.response);
            const errorMsgId = e.response.data.errorCode ? MESSAGE_ID_BY_ERROR_CODE[e.response.data.errorCode] : 'msg.error_server_bad_connection';
            this.openSignInAndUpResultDialog(true, errorMsgId);
        }
    }

    *sendAuthenticationEmail(userEmail, callbacks) {
        console.log(logPrefix, 'Start sendEmailAuthentication');
        try {
            const response = yield axios.post(this.serverContextPath + `/api/v1/users/email/authentication/${userEmail}`);
            console.log('Success sendAuthenticationEmail = ', response);
            callbacks && callbacks.successAction();
        } catch (e) {
            console.log(logPrefix, 'Failed sendAuthenticationEmail');
            console.log(logPrefix, e);
            callbacks && callbacks.failedAction();
            // callbacks && callbacks.openResultDialog();
        }
    }

    *requestConfirmEmailAuthentication(email, emailAuthToken) {
        console.log(logPrefix, `Start requestConfirmEmailAuthentication userEmail = ${email}, token = ${emailAuthToken}`);
        this.emailAuthenticationResult = EMAIL_AUTHENTICATION_RESULT_STATE.Pending;
        try {
            const response = yield axios.put(this.serverContextPath + `/api/v1/authentications/${email}`, null, {
                headers: { 'Email-Auth-Token': emailAuthToken },
            });
            const authentication = response.data;
            if (authentication.authToken === emailAuthToken) {
                this.emailAuthenticationResult = EMAIL_AUTHENTICATION_RESULT_STATE.Confirmed;
            } else {
                this.emailAuthenticationResult = EMAIL_AUTHENTICATION_RESULT_STATE.Not_Allowed;
            }
        } catch (e) {
            console.log(logPrefix, 'Failed requestConfirmEmailAuthentication');
            console.log(logPrefix, e.response);
            if (
                e.response.data.errorCode === Auth_API_ERROR_CODE.Not_Acceptable_Id ||
                e.response.data.errorCode === Auth_API_ERROR_CODE.Can_Not_Found_Data
            ) {
                this.emailAuthenticationResult = EMAIL_AUTHENTICATION_RESULT_STATE.Not_Allowed;
            } else if (e.response.data.errorCode === Auth_API_ERROR_CODE.Authentication_Fail) {
                this.emailAuthenticationResult = EMAIL_AUTHENTICATION_RESULT_STATE.Token_Expired;
            } else {
                this.emailAuthenticationResult = EMAIL_AUTHENTICATION_RESULT_STATE.Failed;
            }
            // this.openEmailAuthenticationResultDialog(true, 'error msg id');
        }
    }

    *requestCheckExistEmail(checkEmail) {
        console.log(logPrefix, 'Start requestCheckExistEmail request check email = {}', checkEmail);

        try {
            const response = yield axios.post(this.serverContextPath + `${AUTH_API_URL}/check/exist/${checkEmail}`);
            console.log(logPrefix, 'Available email : >> ', response);
            this.isExistEmail = false;
        } catch (e) {
            console.log(logPrefix, 'Not allowed email : >> ', e.response);
            this.isExistEmail = true;
        }
    }
}
