import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Box, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as PencilSimpleLine } from '../../common/images/PencilSimpleLine.svg';
import { Bar } from '@nivo/bar';
import { inject, observer } from 'mobx-react';
import { PATH_UTIL } from '../../common/util/path.util';
import { withRouter } from '../../components/WithRouter';
import _ from 'lodash';
import { AutoSizer } from 'react-virtualized';

const styles = theme => ({
    root: {
        paddingTop: 40,
    },
    noContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '50vh',
        '& .MuiTypography-root': {
            color: '#a9adb4',
            fontSize: '1.063rem',
            marginTop: 14,
        },
    },
    avatar: {
        width: 45,
        height: 45,
        backgroundColor: '#e9e9e9',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleStyle: {
        fontSize: '1.25rem',
        fontWeight: 600,
        marginBottom: 76,
    },
    boxStyle: {
        padding: 40,
        marginBottom: 20,
        borderRadius: 10,
        boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.25)',
    },
    boxStyleHalf: {
        width: 'calc(50% - 15px)',
    },
    subTitleStyle: {
        fontSize: '1.063rem',
        fontWeight: 600,
        marginBottom: 10,
    },
    descStyle: {
        fontSize: '0.813rem',
        color: '#666',
        '& span': {
            fontSize: '0.938rem',
            color: '#0097ff',
        },
    },
    ulStyle: {
        paddingLeft: 20,
        marginBottom: 20,
        lineHeight: '1.5rem',
        fontSize: '0.875rem',
        listStyle: 'none',
        '& li': {
            position: 'relative',
            '&:before': {
                content: '""',
                width: 6,
                height: 6,
                backgroundColor: '#000',
                borderRadius: 50,
                display: 'flex',
                position: 'absolute',
                left: -14,
                top: 8,
            },
        },
    },
    graphBox: {
        width: '100%',
        backgroundColor: '#f7f7f7',
        height: 260,
        textAlign: 'center',
        fontSize: '1.25rem',
        fontWeight: 600,
        borderRadius: 10,
        overflowX: 'auto',
        overflowY: 'hidden',
        '&::-webkit-scrollbar': {
            width: '15px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '5px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
    },

    tableStyle: {
        width: '100%',
        marginTop: 20,
        borderSpacing: 0,
        '& thead': {
            '& th': {
                fontSize: '0.875rem',
                fontWeight: 400,
                padding: 8,
                borderBottom: '2px solid #0097ff',
            },
        },
        '& tbody': {
            '& td': {
                fontSize: '0.813rem',
                borderBottom: '1px solid #e1e1e1',
                padding: 8,
            },
        },
    },
    center: {
        textAlign: 'center',
    },
    noLine: {
        border: '0 none!important',
    },
});
class ByFormatComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aboveAverageAmount: [],
            aboveAverageTime: [],
            belowAverageAmount: [],
            belowAverageTime: [],
        };
    }

    responsiveBar = (users, key, keyIds) => {
        const nPSS = this.props.neoPenStatisticsStore;

        if (keyIds === undefined || _.isEmpty(nPSS.statisticsData[keyIds])) return <Box />;

        const data = [];
        let averageData = {
            name: '평균',
            amount: Math.round(nPSS.totalStatisticsData[keyIds].amount / (users.length - 1)),
            time: Math.round(nPSS.totalStatisticsData[keyIds].time / (users.length - 1)),
        };

        const aboveAverageAmount = [];
        const aboveAverageTime = [];
        const belowAverageAmount = [];
        const belowAverageTime = [];

        data.push(averageData);

        for (const user of users) {
            const statisticsData = nPSS.statisticsData[keyIds][user.userId];

            if (statisticsData === undefined) {
                if (user.userId !== nPSS.teacherId && user.name.length > 0) {
                    data.push({
                        name: user.name,
                        amount: 0,
                        time: 0,
                    });

                    belowAverageAmount.push(user.name);
                    belowAverageTime.push(user.name);
                }
            } else {
                data.push({
                    name: user.name,
                    amount: statisticsData.totalStrokeCount,
                    time: statisticsData.totalStrokeWritingTime,
                });

                if (statisticsData.totalStrokeCount < averageData.amount) belowAverageAmount.push(user.name);
                else aboveAverageAmount.push(user.name);

                if (statisticsData.totalStrokeWritingTime < averageData.time) belowAverageTime.push(user.name);
                else aboveAverageTime.push(user.name);
            }
        }

        if (this.state.belowAverageAmount.length === 0 && belowAverageAmount.length > 0)
            this.setState({
                aboveAverageAmount: aboveAverageAmount,
                aboveAverageTime: aboveAverageTime,
                belowAverageAmount: belowAverageAmount,
                belowAverageTime: belowAverageTime,
            });

        const customWidth = users.length * 90;

        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Bar
                        width={users.length >= 10 ? customWidth : width}
                        height={height}
                        colors={bar => (bar.indexValue === '평균' ? '#0097ff' : '#78C8FF')}
                        margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
                        data={data}
                        indexBy="name"
                        keys={[key]}
                        labelTextColor="inherit:darker(1.4)"
                        enableGridX={true}
                        axisBottom={{
                            format: v => {
                                return v.length > 3 ? (
                                    <Tooltip title={v}>
                                        <tspan>
                                            {v.substring(0, 3) + '...'}
                                            <title>{v}</title>
                                        </tspan>
                                    </Tooltip>
                                ) : (
                                    v
                                );
                            },
                        }}
                        theme={{
                            axis: {
                                ticks: {
                                    line: {
                                        stroke: '#0097ff',
                                    },
                                    text: {
                                        fill: '#333',
                                    },
                                },
                            },
                            grid: {
                                line: {
                                    stroke: '#eee',
                                    strokeWidth: 1,
                                },
                            },
                        }}
                    />
                )}
            </AutoSizer>
        );
    };

    render() {
        const { classes } = this.props;

        const nPSS = this.props.neoPenStatisticsStore;
        const nPS = this.props.neoPenStore;
        const cS = this.props.classStore;

        const users = cS.selectedGroupDetail.lmsUser;

        let title = nPS.selectedPaperGroup ? nPS.selectedPaperGroup.title : '';

        let keyIds = nPS.selectedPaperGroup ? nPSS.getKeyFromIds(this.props.gruopId, nPS.selectedPaperGroup.paperGroupId) : undefined;

        const averageAmountPair = [];
        const averageTimePair = [];

        for (let i = 0; i < Math.max(this.state.aboveAverageAmount.length, this.state.belowAverageAmount.length); i++)
            averageAmountPair.push({ above: this.state.aboveAverageAmount[i], below: this.state.belowAverageAmount[i] });

        for (let i = 0; i < Math.max(this.state.aboveAverageTime.length, this.state.belowAverageTime.length); i++)
            averageTimePair.push({ above: this.state.aboveAverageTime[i], below: this.state.belowAverageTime[i] });

        return (
            <div className={classes.root}>
                {_.isEmpty(nPSS.statisticsData[keyIds]) ? (
                    <Box className={classes.noContent}>
                        <Box className={classes.avatar}>
                            <PencilSimpleLine />
                        </Box>
                        <Typography>필기 기록이 없습니다.</Typography>
                    </Box>
                ) : (
                    <Box>
                        <Typography variant="h3" className={classes.titleStyle}>
                            {title}
                        </Typography>
                        <Box className={classes.boxStyle}>
                            <Typography variant="h4" className={classes.subTitleStyle}>
                                평균대비 학생별 필기량
                            </Typography>
                            <ul className={classes.ulStyle}>
                                <li>서식별로 학생들의 평균 필기량과 학생별 필기량을 표시한 그래프입니다.</li>
                                <li>필기량은 학생들이 필기활동 중 필기한 획의 개수의 합을 나타냅니다.</li>
                                <li>필기활동은 글자를 쓰거나, 밑줄 및 그림을 표시하는 등의 활동을 의미합니다.</li>
                            </ul>
                            <Box className={classes.graphBox}>{this.responsiveBar(users, 'amount', keyIds)}</Box>
                        </Box>
                        <Box className={classes.boxStyle}>
                            <Typography variant="h4" className={classes.subTitleStyle}>
                                평균대비 학생별 필기시간
                            </Typography>
                            <ul className={classes.ulStyle}>
                                <li>서식별로 학생들의 평균 필기시간과 학생별 필기시간을 표시한 그래프입니다.</li>
                                <li>필기시간은 학생들이 필기활동을 수행한 시간을 의미합니다.</li>
                                <li>필기활동은 글자를 쓰거나, 밑줄 및 그림을 표시하는 등의 활동을 의미합니다.</li>
                            </ul>
                            <Box className={classes.graphBox}>{this.responsiveBar(users, 'time', keyIds)}</Box>
                        </Box>
                        <Box display="flex">
                            <Box className={clsx(classes.boxStyle, classes.boxStyleHalf)} style={{ marginRight: 30 }}>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="h5" className={classes.subTitleStyle}>
                                        필기량
                                    </Typography>
                                    <Typography variant="body1" className={classes.descStyle}>
                                        평균 보다 높은 학생 <span>{this.state.aboveAverageAmount.length}</span>명, 평균 보다 낮은 학생{' '}
                                        <span>{this.state.belowAverageAmount.length}</span>명
                                    </Typography>
                                </Box>
                                <table className={classes.tableStyle}>
                                    <thead>
                                        <tr>
                                            <th>평균 보다 높은 학생</th>
                                            <th>평균 보다 낮은 학생</th>
                                        </tr>
                                    </thead>
                                    <tbody className={classes.center}>
                                        {averageAmountPair.map((pair, i) => {
                                            const contents = [];

                                            if (pair.above) {
                                                if (pair.above.length > 5) {
                                                    contents.push(
                                                        <Tooltip title={pair.above}>
                                                            <td>{`${pair.above.substring(0, 5) + '...'}`}</td>
                                                        </Tooltip>,
                                                    );
                                                } else contents.push(<td>{`${pair.above}`}</td>);
                                            } else contents.push(<td></td>);

                                            if (pair.below) {
                                                if (pair.below.length > 5) {
                                                    contents.push(
                                                        <Tooltip title={pair.below}>
                                                            <td>{`${pair.below.substring(0, 5) + '...'}`}</td>
                                                        </Tooltip>,
                                                    );
                                                } else contents.push(<td>{`${pair.below}`}</td>);
                                            } else contents.push(<td></td>);

                                            return <tr>{contents}</tr>;
                                        })}
                                    </tbody>
                                </table>
                            </Box>
                            <Box className={clsx(classes.boxStyle, classes.boxStyleHalf)}>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="h5" className={classes.subTitleStyle}>
                                        필기시간
                                    </Typography>
                                    <Typography variant="body1" className={classes.descStyle}>
                                        평균 보다 높은 학생 <span>{this.state.aboveAverageTime.length}</span>명, 평균 보다 낮은 학생{' '}
                                        <span>{this.state.belowAverageTime.length}</span>명
                                    </Typography>
                                </Box>
                                <table className={classes.tableStyle}>
                                    <thead>
                                        <tr>
                                            <th>평균 보다 높은 학생</th>
                                            <th>평균 보다 낮은 학생</th>
                                        </tr>
                                    </thead>
                                    <tbody className={classes.center}>
                                        {averageTimePair.map((pair, i) => {
                                            const contents = [];

                                            if (pair.above) {
                                                if (pair.above.length > 5) {
                                                    contents.push(
                                                        <Tooltip title={pair.above}>
                                                            <td>{`${pair.above.substring(0, 5) + '...'}`}</td>
                                                        </Tooltip>,
                                                    );
                                                } else contents.push(<td>{`${pair.above}`}</td>);
                                            } else contents.push(<td></td>);

                                            if (pair.below) {
                                                if (pair.below.length > 5) {
                                                    contents.push(
                                                        <Tooltip title={pair.below}>
                                                            <td>{`${pair.below.substring(0, 5) + '...'}`}</td>
                                                        </Tooltip>,
                                                    );
                                                } else contents.push(<td>{`${pair.below}`}</td>);
                                            } else contents.push(<td></td>);

                                            return <tr>{contents}</tr>;
                                        })}
                                    </tbody>
                                </table>
                            </Box>
                        </Box>
                    </Box>
                )}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('authStore', 'classStore', 'neoPenStore', 'neoPenStatisticsStore')(observer(ByFormatComponent))));
