import { makeAutoObservable } from 'mobx';
import dayjs from 'dayjs';
import axios from 'axios';
import { DATE_UTIL } from '../common/util/date.util';
import _ from 'lodash';
import { ClassMainPath } from '../common/ClassMainPath';
import { PATH_UTIL } from '../common/util/path.util';
import { ReactComponent as MobileLivIcon } from '../common/images/MobileLivIcon.svg';
import { ReactComponent as MobileExpectedIcon } from '../common/images/MobileExpectedIcon.svg';
import { ReactComponent as MobileEndIcon } from '../common/images/MobileEndIcon.svg';

const logPrefix = ' [ RoomStore ] ';

export const RoomStateFlagName = {
    Live: 'Live',
    Ended: '종료',
    Future: '예정',
};

export const RoomStateFlagIcon = {
    Live: <MobileLivIcon />,
    Ended: <MobileEndIcon />,
    Future: <MobileExpectedIcon />,
};

export const roomState = {
    Live: 'Live',
    Ended: 'Ended',
    Future: 'Future',
};

export const loadingSate = {
    READY: 'READY',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
};

export const commentType = {
    ROOM: 'Room',
    POST: 'Post',
};

export const RoomSortType = {
    All: 'All',
    Live: 'Live',
    Future: 'Future',
    Ended: 'Ended',
};

export const MyClassRoomSortType = {
    Recent: 'Recent',
    RoomState: 'RoomState',
    MaxUsers: 'MaxUsers',
    Alphabet: 'Alphabet',
    Past: 'Past',
};

export const sortType = {
    TIME: 'Time',
    RECENT: 'Recent',
};

export const LmsRoomUserType = {
    OWNER: 'Owner',
    MEMBER: 'Member',
    GUEST: 'Guest',
};

const EmptyTag = {
    id: '',
    groupId: '',
    userId: '',
    name: '',
};

const EmptyRoom = {
    id: '',
    userEmail: '',
    apiRoomId: '',
    userId: '',
    userName: '',
    groupId: '',
    type: 'None',
    startDatetime: '',
    endDatetime: '',
    meetingTime: '',
    guestNum: '',
    name: '',
    comment: '',
    tagList: Array(3).fill({ ...EmptyTag }),
    likeList: [],
    commentCount: 0,
    members: [],
    guests: [],
    teams: [],
    maxUsers: '',
    all: false,
    isAllowGuest: false,
    invitationEmail: false,
};

const EmptyComment = {
    id: '',
    groupId: '',
    userId: '',
    text: '',
    emotion: '',
};

const EmptyGuestInfo = {
    name: '',
    email: '',
};

export const REQUEST_ROOM_DATE_RANGE = {
    today: 'today',
    all: 'all',
};

const CREATE_ROOM_REQUIRED_INPUT_TYPE = {
    title: 'title',
    contents: 'contents',
    tag: 'tag',
};

export default class RoomStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    tag = Object.assign({}, EmptyTag);
    tagList = [this.tag, this.tag, this.tag];
    room = Object.assign({}, EmptyRoom);
    roomDummy = Object.assign({}, EmptyRoom);
    comment = Object.assign({}, EmptyComment);
    modifyComment = Object.assign({}, EmptyComment);
    guestList = [];
    roomRowsPerPage = 5;
    commentRowsPerPage = 10;
    myClassRoomRowsPerPage = 5;
    currentPage = 1;
    currentSortType = sortType.TIME;
    roomSortType = RoomSortType.All;
    temporaryStartDatetime = '';
    temporaryEndDatetime = '';

    roomListGroupByStartTime = [];
    timelineOpenList = [];
    myClassRoomList = [];

    roomTotalCount = 0;
    searchRoomListCount = 0;
    myClassRoomCount = 0;

    calendarRoomList = [];
    searchRoomList = [];

    createRoomLoading = loadingSate.READY;
    loadRoomLoading = loadingSate.READY;
    searchRoomLoading = loadingSate.READY;
    calendarDataLoading = loadingSate.READY;
    likeLoading = loadingSate.READY;

    roomTagList = [];
    roomListByTagName = [];
    roomListByTagNameCount = 0;

    roomCommentList = [];

    classTap = PATH_UTIL.getMainTabName(window.location.pathname);
    selectedTag = undefined;

    timelineLinkViewOpenList = [];

    errMsg = '';

    openConfirmDialog = false;

    roomsByOwner = [];

    selectedRoomOwner = {};
    roomsByOwnerTotalCount = 0;

    searchKeyword = '';

    createRoomRequiredInputType = '';

    setSearchKeyword = keyword => {
        console.log(logPrefix, 'setSearchKeyword : >> ', keyword);
        this.searchKeyword = keyword.trim();
    };

    setCreateRoomRequiredInputType = value => {
        this.createRoomRequiredInputType = value;
    };

    get createRoomRequiredValidationByType() {
        const type = this.createRoomRequiredInputType;
        if (type) {
            if (type === CREATE_ROOM_REQUIRED_INPUT_TYPE.title) {
                return this.isRoomNameValidation;
            } else if (type === CREATE_ROOM_REQUIRED_INPUT_TYPE.contents) {
                return this.isRoomCommentValidation;
            } else {
                return this.isRoomTagValidation;
            }
        }
    }

    get isSearchingRoom() {
        return this.searchKeyword !== '';
    }

    get isLoadRoomLoading() {
        return this.loadRoomLoading === loadingSate.PENDING;
    }

    get isSearchRoomLoading() {
        return this.searchRoomLoading === loadingSate.PENDING;
    }

    get isRoomCreateValidation() {
        return (
            this.isRoomNameValidation &&
            this.isRoomCommentValidation &&
            this.isRoomTagValidation &&
            this.isRoomStartTimeValidation &&
            this.isRoomEndTimeValidation &&
            this.isRoomMeetingTimeValidation &&
            this.createRoomLoading !== loadingSate.PENDING
        );
    }

    get isRoomNameValidation() {
        const target = this.room.name;
        return target !== null && target !== '' && target !== undefined;
    }

    get isRoomCommentValidation() {
        const target = this.room.comment;
        return target !== null && target !== '' && target !== undefined;
    }

    get isRoomTagValidation() {
        const target = this.room.tagList.map(tag => tag.name).join('');
        return target !== null && target !== '';
    }

    get isRoomStartTimeValidation() {
        const target = this.room.startDatetime;
        return target !== null && target !== '' && target !== undefined;
    }

    get isRoomEndTimeValidation() {
        const target = this.room.endDatetime;
        return target !== null && target !== '' && target !== undefined;
    }

    get isRoomMeetingTimeValidation() {
        const target = this.room.meetingTime;
        return target !== null && target !== '' && target !== undefined;
    }

    setRoomsByOwnerTotalCount = count => {
        this.roomsByOwnerTotalCount = count;
    };

    initSelectRoomsByOwner = () => {
        this.roomsByOwner = [];
        // this.selectedRoomOwner = {};
    };

    setSelectedRoomOwner = user => {
        this.selectedRoomOwner = user;
    };

    setRoomsByOwner = (rooms, page) => {
        if (page > 0) {
            this.roomsByOwner = this.roomsByOwner.concat(rooms);
        } else {
            this.roomsByOwner = rooms;
        }
    };

    changeCurrentPage = () => {
        this.currentPage = this.currentPage + 1;
    };

    setRoomRowsPerPage = value => {
        this.roomRowsPerPage = value;
    };

    // setModifyComment = comment => {
    //     console.log('comment', comment);
    //     this.modifyComment = Object.assign({}, comment);
    //     console.log('this.modifyComment', this.modifyComment);
    // };

    changeModifyCommentText = value => {
        this.modifyComment.text = value;
    };

    changeClassTap = value => {
        this.classTap = value;
    };

    initRoom = () => {
        this.room = Object.assign({}, EmptyRoom);
        this.closeAllTimelineLinkView();
        this.guestList = [];
        this.roomCommentList = [];
        this.roomListGroupByStartTime = [];
        this.currentPage = 1;
        this.roomTotalCount = 0;
    };

    initCreateRoom = () => {
        this.room = Object.assign({}, EmptyRoom);
        this.guestList = [];
    };

    selectedRoomInit = () => {
        this.roomDummy = Object.assign({}, EmptyRoom);
    };

    initTag = () => {
        this.selectedTag = undefined;
    };

    initCalendarData = () => {
        this.calendarRoomList = [];
    };

    checkSpace = e => {
        const space = /\s/;
        if (space.exec(e.target.value)) {
            e.target.value = e.target.value.replace(' ', '');
            return false;
        }
    };

    changeRoomTitle = title => {
        this.room.name = title;
    };

    changeRoomComment = comment => {
        this.room.comment = comment;
    };

    changeRoomMember = memberList => {
        this.room.members = memberList
            .filter(o => !o.teamId)
            .map(o => {
                o.type = LmsRoomUserType.MEMBER;
                return o;
            });
        this.room.teams = memberList.filter(o => o.teamId);
    };

    changeIsRoomInvitationEmail = value => {
        this.room.invitationEmail = value;
    };

    changeIsAll = value => {
        this.room.all = value;
        this.room.members = [];
        this.room.teams = [];
    };

    changeIsAllowGuest = value => {
        this.room.isAllowGuest = value;
        if (value) {
            this.createGuestList();
        } else {
            this.room.guests = [];
        }
    };

    initGuestList = () => {
        this.room.guests = [];
        this.room.guests.push(EmptyGuestInfo);
        this.room.guestNum = this.room.guests.length.toString();
    };

    createGuestList = () => {
        if (this.room.guests.length < 199) {
            this.room.guests.push(EmptyGuestInfo);
            this.room.guestNum = this.room.guests.length.toString();
        }
    };
    changeGuestNum = value => {
        this.room.guestNum = value;
    };

    changeGuests = value => {
        if (value < 200) {
            this.room.guestNum = value;
            const guestNum = this.room.guests.length;
            if (guestNum < value) {
                for (let i = guestNum; i < value; i++) {
                    this.room.guests.push(EmptyGuestInfo);
                }
            } else {
                this.room.guests.splice(value, this.room.guests.length);
            }
        }
    };

    removeGuest = () => {
        if (this.room.guests.length > 1) {
            this.room.guests.splice(this.room.guests.length - 1, 1);
            this.room.guestNum = this.room.guests.length.toString();
        }
    };

    changeGuestNickName = (value, index) => {
        this.room.guests[index].name = value;
        console.log('this.room.guests', this.room.guests);
    };

    changeGuestEmail = (value, index) => {
        // console.log(logPrefix,"changeGuestEmail Start... value={}, index={}",value, index);
        this.room.guests[index].email = value;
        // console.log(logPrefix,"changeGuestEmail End... this.guestList={}",this.guestList);
    };

    deleteGuest = index => {
        // console.log(logPrefix,"deleteGuest Start... index={}", index);
        this.room.guestNum = this.room.guestNum - 1;
        this.room.guests.splice(index, 1);
        if (this.room.guests.length === 0) {
            this.room.guestNum = '';
        }
        // console.log(logPrefix,"deleteGuest End... guestList={}", this.guestList);
    };

    changeRoomTag = e => {
        if (this.roomDummy.tagList.length >= parseInt(e.target.id) + 1) {
            this.room.tagList[e.target.id].name = e.target.value;
        } else {
            const t = _.cloneDeep(this.tag);
            t.name = e.target.value;
            this.room.tagList[e.target.id] = t;
        }
    };

    setRoomMembersImage = newUserList => {
        const roomId = newUserList[0].roomId;
        this.roomListGroupByStartTime.filter(r => r.id === roomId)[0].members = newUserList;
    };

    onChangeDate = value => {
        console.log(logPrefix, 'onChangeDate Start ... value={}', value);
        let startDateTime = dayjs(value.startDatetime.date).add(
            value.startDatetime.am === 'AM'
                ? value.startDatetime.hour === '12'
                    ? parseInt(value.startDatetime.hour) - 12
                    : parseInt(value.startDatetime.hour)
                : value.startDatetime.hour === '12'
                ? parseInt(value.startDatetime.hour)
                : parseInt(value.startDatetime.hour) + 12,
            'hour',
        );
        startDateTime = dayjs(startDateTime).add(value.startDatetime.min, 'minute');
        this.room.startDatetime = startDateTime;
        this.room.endDatetime = dayjs(startDateTime).add(value.endDatetime.hour, 'hour').add(value.endDatetime.min, 'minute');
        this.room.meetingTime = value.endDatetime.hour + ':' + value.endDatetime.min;
        console.log(logPrefix, 'onChangeDate End ... startDatetime={}, endDatetime={}', this.room.startDatetime, this.room.endDatetime);
    };

    onChangeTemporaryDate = value => {
        console.log(logPrefix, 'onChangeDate Starting ... value={}', value);
        let startDateTime = dayjs(value.startDatetime.date).add(
            value.startDatetime.am === 'AM'
                ? value.startDatetime.hour === '12'
                    ? parseInt(value.startDatetime.hour) - 12
                    : parseInt(value.startDatetime.hour)
                : value.startDatetime.hour === '12'
                ? parseInt(value.startDatetime.hour)
                : parseInt(value.startDatetime.hour) + 12,
            'hour',
        );
        startDateTime = dayjs(startDateTime).add(value.startDatetime.min, 'minute');
        this.temporaryStartDatetime = startDateTime;
        this.temporaryEndDatetime = dayjs(startDateTime).add(value.endDatetime.hour, 'hour').add(value.endDatetime.min, 'minute');
        this.temporaryMeetingTime =
            // value.endDatetime.day !== '' && value.endDatetime.day !== '0'
            //     ? value.endDatetime.day + intl.formatMessage({ id: 'day' }) + ' ' + value.endDatetime.hour + ':' + value.endDatetime.min :
            value.endDatetime.hour + ':' + value.endDatetime.min;
        console.log(logPrefix, 'onChangeDate End ... startDatetime={}, endDatetime={}', this.room.startDatetime, this.room.endDatetime);
    };

    getTimelineLinkViewOpen = roomId => {
        const timelineLinkViewOpen = this.timelineLinkViewOpenList.find(t => t.id === roomId);
        return timelineLinkViewOpen ? timelineLinkViewOpen.value : false;
    };

    openTimelineLinkView = roomId => {
        this.closeAllTimelinePanel();
        const target = this.timelineLinkViewOpenList.find(t => t.id === roomId);
        if (target) {
            target.value = true;
        }
    };

    closeAllTimelinePanel = () => {
        this.closeAllTimelineDetail();
        this.closeAllTimelineLinkView();
    };

    closeAllTimelineDetail = () => {
        this.timelineOpenList = this.timelineOpenList.map(t => ({
            id: t.id,
            value: false,
        }));
    };

    closeAllTimelineLinkView = () => {
        this.timelineLinkViewOpenList = this.timelineLinkViewOpenList.map(t => ({
            id: t.id,
            value: false,
        }));
    };

    closeConfirmDialog = () => {
        this.openConfirmDialog = false;
    };

    changeRoomCommentText = value => {
        this.comment.text = value;
    };

    isInputValidation = intl => {
        let checkInput = true;
        const tagNameList = this.room.tagList.filter(tag => tag.name !== '').map(tag => tag.name.trim());
        const set = new Set(tagNameList);
        if (this.room.name === '') {
            checkInput = false;
            this.errMsg = intl.formatMessage({ id: 'msg.required_subject' });
        } else if (tagNameList.length !== set.size) {
            checkInput = false;
            this.errMsg = '중복된 태그는 사용할 수 없습니다.';
        } else if (this.room.startDatetime === '') {
            checkInput = false;
            this.errMsg = intl.formatMessage({ id: 'msg.required_time' });
        } else if (this.room.isAllowGuest && this.room.guests.length <= 0) {
            checkInput = false;
            this.errMsg = intl.formatMessage({ id: 'msg.required_guest_num' });
        }
        // else if (this.room.members.length <= 0 && this.room.guestNum === 0) {
        //     checkInput = false;
        //     this.errMsg = intl.formatMessage({ id: 'msg.required_attendees' });
        // }
        else if (
            !this.room.tagList.length > 0 ||
            (this.room.tagList[0].name === '' && this.room.tagList[1].name === '' && this.room.tagList[2].name === '')
        ) {
            checkInput = false;
            this.errMsg = intl.formatMessage({ id: 'msg.required_tag' });
        }
        // else if(!this.isGuestNumConfirm){
        //     this.errMsg = intl.formatMessage({id: "msg.required_check_guest"});
        //     checkInput = false;
        // }
        this.openConfirmDialog = !checkInput;
        return checkInput;
    };

    calMeetingTime = intl => {
        const time = dayjs(this.room.endDatetime).diff(dayjs(this.room.startDatetime), 'minute');
        this.room.meetingTime = this.MakeDateForm(time, intl);
    };

    calLocalDate = () => {
        this.room.startDatetime = DATE_UTIL.convertUTCToTimeZone(this.room.startDatetime, DATE_UTIL.FORMAT.FULL_FORMAT);
        this.room.endDatetime = DATE_UTIL.convertUTCToTimeZone(this.room.endDatetime, DATE_UTIL.FORMAT.FULL_FORMAT);
    };

    MakeDateForm = (min, intl) => {
        const days = Math.floor(min / 60 / 24);
        const hours = Math.floor((min - days * 60 * 24) / 60);
        const mins = min - days * 60 * 24 - hours * 60;
        const hourStr = hours > 9 ? hours : '0' + hours;
        const minStr = mins > 9 ? mins : '0' + mins;
        return days > 0 ? days + intl.formatMessage({ id: 'day' }) + ' ' + hourStr + ':' + minStr : hourStr + ':' + minStr;
    };

    createRoomDummy = (userId, callBack) => {
        // if (this.roomDummy.guests.length > 0) {
        //     this.roomDummy.guests.map(guest => {
        //         if (guest.name.match(/(?<=게스트)(.*?)(?=[0-9])/g)) {
        //             console.log('guest', guest.name);
        //             guest.name = '';
        //         }
        //     });
        // }
        this.room = _.cloneDeep(this.roomDummy);
        this.room.members = this.roomDummy.members.filter(user => user.userId !== userId);
        this.calLocalDate();
        callBack && callBack.changeState();
    };

    changeMyClassRoomRowsPerPage = value => {
        this.myClassRoomRowsPerPage = value;
    };

    *getRoomDetail(roomId, intl, callBack) {
        console.log(logPrefix, 'getRoomDetail starting... roomId={}', roomId);
        this.loadRoomLoading = loadingSate.PENDING;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/rooms/detail/${roomId}`);
            console.log(logPrefix, 'getRoomDetail response ={}', response.data);
            this.room = response.data;
            // this.calLocalDate();
            this.calMeetingTime(intl);
            this.updateRoom();
            this.loadRoomLoading = loadingSate.SUCCESS;
            callBack && callBack.selectCommentList(response.data.groupId);
        } catch (e) {
            this.loadRoomLoading = loadingSate.FAILED;
            console.log(logPrefix, 'getRoomDetail failed... ', e);
        }
    }

    *getRoomListGroupByStartTime(groupId, userId, range, page, startDatetime, endDatetime, sortType, searchKeyword, orderType) {
        if (page === 1) {
            this.loadRoomLoading = loadingSate.PENDING;
            this.roomListGroupByStartTime = [];
        }
        console.log(
            logPrefix,
            'getRoomListGroupByStartTime Starting... groupId={}, userId={}, range={}, page={}, startDatetime={}, endDatetime={}, roomRowsPerPage={}, sortType={}',
            groupId,
            userId,
            range,
            page,
            startDatetime,
            endDatetime,
            this.roomRowsPerPage,
            sortType,
        );
        try {
            // this.requestCancel();
            // this.setCancelTokenSource(axios.CancelToken.source());
            let start = DATE_UTIL.convertTimeZoneToUTC(dayjs(new Date().setHours(0, 0, 0))).format('YYYY-MM-DDTHH:mm:ss');
            let end = DATE_UTIL.convertTimeZoneToUTC(dayjs(new Date().setHours(23, 59, 59))).format('YYYY-MM-DDTHH:mm:ss');
            if (startDatetime) {
                start = DATE_UTIL.convertTimeZoneToUTC(startDatetime).format('YYYY-MM-DDTHH:mm:ss');
            }
            if (endDatetime) {
                end = DATE_UTIL.convertTimeZoneToUTC(dayjs(new Date(endDatetime).setHours(23, 59, 59))).format('YYYY-MM-DDTHH:mm:ss');
            }

            console.log(logPrefix, 'start date time :  >>', start);
            console.log(logPrefix, 'end date time :  >>', end);

            let url =
                this.serverContextPath +
                `/api/v1/rooms/timeline?group-id=${
                    groupId !== undefined ? groupId : 0
                }&user-id=${userId}&range=${range}&start-datetime=${start}&end-datetime=${end}&rows-per-page=${this.roomRowsPerPage}`;
            if (page !== 0) {
                url = url + `&page=${page}`;
            }
            if (sortType) {
                url = url + `&sort-type=${sortType}`;
            }
            if (searchKeyword !== '' && searchKeyword !== null && searchKeyword !== undefined) {
                url = url + `&search-keyword=${searchKeyword}`;
            }
            if (orderType) {
                url = url + `&order-type=${orderType}`;
            }

            const response = yield axios.get(
                url,
                // ,{ cancelToken: this.cancelTokenSource.token }
            );
            console.log(logPrefix, 'getRoomListGroupByStartTime response={}', response.data);
            this.getTimelineDataSuccessAction(response.data);
            this.loadRoomLoading = loadingSate.SUCCESS;
        } catch (e) {
            this.loadRoomLoading = loadingSate.FAILED;
            console.log('getRoomListGroupByStartTime Fail : >> ', e);
        }
    }

    *getSimpleRoomListByGroupId(groupId, userId, sortType, page) {
        this.loadRoomLoading = loadingSate.PENDING;
        console.log(logPrefix, 'getSimpleRoomListByGroupId Starting... groupId={}, userId={}, sortType={}, page={}', groupId, userId, sortType, page);
        this.myClassRoomList = [];
        try {
            // this.requestCancel();
            // this.setCancelTokenSource(axios.CancelToken.source());
            let url =
                this.serverContextPath +
                `/api/v1/rooms/myclass?group-id=${groupId !== undefined ? groupId : 0}&user-id=${userId}&rows-per-page=${
                    this.myClassRoomRowsPerPage
                }`;
            if (page !== 0) {
                url = url + `&page=${page}`;
            }
            if (sortType) {
                url = url + `&sort-type=${sortType}`;
            }
            if (this.searchKeyword !== '') {
                url = url + `&search-keyword=${this.searchKeyword}`;
            }

            const response = yield axios.get(
                url,
                // ,{ cancelToken: this.cancelTokenSource.token }
            );
            this.myClassRoomList = response.data.rooms;
            this.myClassRoomCount = response.data.roomCount;
            console.log(logPrefix, 'getSimpleRoomListByGroupId response={}', response.data);

            this.loadRoomLoading = loadingSate.SUCCESS;
        } catch (e) {
            this.loadRoomLoading = loadingSate.FAILED;
            console.log('getRoomListGroupByStartTime Fail : >> ', e);
        }
    }

    getTimelineDataSuccessAction = data => {
        this.roomListGroupByStartTime = this.roomListGroupByStartTime.concat(data.roomList);
        this.roomTotalCount = data.roomCount;
        data.roomList.forEach(roomData => {
            this.timelineOpenList.push({ id: roomData.id, value: false });
            this.timelineLinkViewOpenList.push({ id: roomData.id, value: false });
        });
    };

    filteringRoomListByTagName = tag => {
        console.log('filteringRoomListByTagName tag : >> ', tag);
        if (tag) {
            this.roomListByTagName = this.roomListGroupByStartTime.filter(room => room.tagList.find(t => t.name === tag.name) !== undefined);
        }
    };

    updateRoom = () => {
        this.room.isAllowGuest = this.room.guests.length > 0;
        this.room.guestNum = this.room.guests.length;
        this.roomDummy = _.cloneDeep(this.room);
    };

    *getRoomListBySelectedTag(selectedTag) {
        this.loadRoomLoading = loadingSate.PENDING;
        try {
            this.roomListByTagName = [];
            this.selectedTag = selectedTag;
            console.log(logPrefix, 'getRoomListBySelectedTag starting ... selectedTag={}', selectedTag);
            console.log(logPrefix, 'this.classTap', this.classTap);
            const param = selectedTag.idList;
            let url = this.serverContextPath + `/api/v1/rooms/detail/tag`;
            if (this.classTap === ClassMainPath.todaySchedule) {
                url = url + '?range=today';
            }
            const response = yield axios.post(url, param);
            console.log(logPrefix, 'getRoomListBySelectedTag end response ={}', response.data);
            if (this.classTap !== ClassMainPath.calendarSchedule) {
                this.roomListByTagName = response.data;
            } else {
                this.roomListByTagNameCount = response.data.length;
                const roomListByGroup = _.chain(response.data)
                    .groupBy(r => dayjs(new Date(r.startDatetime)).format('YYYY-MM'))
                    .map((value, key) => ({ date: key, rooms: value, count: value.length }))
                    .value();
                this.roomListByTagName = roomListByGroup.slice().sort(function (a, b) {
                    return new Date(b.date) - new Date(a.date);
                });
            }

            this.loadRoomLoading = loadingSate.SUCCESS;
        } catch (e) {
            console.log(logPrefix, 'getRoomListBySelectedTag Failed ...');
            this.loadRoomLoading = loadingSate.FAILED;
        }
    }

    *getRoomListBySubSearchKeyword(groupId, keyword, page, callback) {
        if (page === 1) {
            this.searchRoomLoading = loadingSate.PENDING;
        }
        try {
            if (keyword === '' || keyword === null || keyword === undefined || keyword.trim() === '') {
                this.searchRoomLoading = loadingSate.FAILED;
                return false;
            }
            console.log(
                logPrefix,
                'getRoomListBySubSearchKeyword starting ... groupId={}, keyword={}, page={}, rowsPerPage={}, sortType={}',
                groupId,
                keyword,
                page,
                // sortType,
            );
            const range = 'all';
            let url =
                this.serverContextPath +
                `/api/v1/rooms/timeline?group-id=${groupId}&page=${page}&range=${range}&rows-per-page=${this.roomRowsPerPage}`;
            // if (sortType) {
            //     url = url + `&sort-type=${sortType}`;
            // }
            if (keyword !== '' && keyword !== null && keyword !== undefined) {
                url = url + `&search-keyword=${keyword.trim()}`;
            }
            const response = yield axios.get(url);
            console.log(logPrefix, 'getRoomListBySubSearchKeyword end response ={}', response.data);

            if (page === 1) {
                this.searchRoomListCount = response.data.roomCount;
                this.searchRoomList = response.data.roomList;
            } else {
                this.searchRoomList = this.searchRoomList.concat(response.data.roomList);
            }

            this.searchRoomLoading = loadingSate.SUCCESS;
        } catch (e) {
            console.log(logPrefix, 'getRoomListBySubSearchKeyword Failed ...');
            this.searchRoomLoading = loadingSate.FAILED;
        }
    }

    *getCalendarSchedule(groupId, userId, startDate) {
        this.calendarDataLoading = loadingSate.PENDING;
        try {
            console.log(logPrefix, 'getCalendarSchedule starting ... groupId={} userId={} startDate={} ', groupId, userId, startDate);
            this.roomTagList = [];
            let startDatetime = DATE_UTIL.convertTimeZoneToUTC(dayjs(new Date(startDate).setHours(0, 0, 0))).format('YYYY-MM-DDTHH:mm:ss');
            let endDatetime = DATE_UTIL.convertTimeZoneToUTC(dayjs(new Date(startDate).setHours(23, 59, 59))).format('YYYY-MM-DDTHH:mm:ss');
            let url =
                this.serverContextPath +
                `/api/v1/rooms/calendar?group-id=${groupId}&user-id=${userId}&start-datetime=${startDatetime}&end-datetime=${endDatetime}`;
            const response = yield axios.get(url);
            console.log(logPrefix, 'getCalendarSchedule response ={}', response.data);
            this.calendarRoomList = response.data;
            this.calendarDataLoading = loadingSate.SUCCESS;
        } catch (e) {
            console.log(logPrefix, 'getCalendarSchedule Failed ...');
            this.calendarDataLoading = loadingSate.FAILED;
        }
    }

    *createRoom(loginUser, groupId, callbacks, intl, isModify) {
        this.createRoomLoading = loadingSate.PENDING;
        try {
            console.log(logPrefix, 'createRoom starting ... room={} userId={}', this.room, loginUser.id);

            if (!this.isInputValidation(intl)) {
                console.log(logPrefix, 'CreateRoom InputValidation Fail ... ');
                return false;
            }
            let response;
            this.room.userId = loginUser.id;
            this.room.groupId = groupId;
            this.room.name = this.room.name.trim();
            this.room.comment = this.room.comment.trim();
            this.room.tagList.map(t => {
                t.name = t.name.trim();
                return t;
            });
            const param = Object.assign({}, this.room);
            param.startDatetime = DATE_UTIL.getUTCDate(this.room.startDatetime).format('YYYY-MM-DD HH:mm:ss');
            param.endDatetime = DATE_UTIL.getUTCDate(this.room.endDatetime).format('YYYY-MM-DD HH:mm:ss');

            if (isModify) {
                response = yield axios.put(this.serverContextPath + '/api/v1/rooms', param);
                console.log(logPrefix, 'createRoom Modify param={}', param);
                console.log(logPrefix, 'createRoom Modify response ... response.data={}', response.data);

                callbacks && callbacks.dialogClose();
                callbacks && callbacks.getTagList(groupId);
                this.room = response.data;
                this.calMeetingTime(intl);
                this.updateRoom();
            } else {
                response = yield axios.post(this.serverContextPath + '/api/v1/rooms', param);
                if (response.data) this.createRoomSuccessAction(groupId, loginUser.id, param.startDatetime, callbacks);
                console.log(logPrefix, 'createRoom response ... response.data={}', response.data);
            }

            this.createRoomLoading = loadingSate.SUCCESS;
        } catch (e) {
            console.log(logPrefix, 'createRoom Failed ...', e.response.data);
            if (e.response.data.errorCode === 'CanNotRequestCreateRoom') {
                this.errMsg = '수업을 예약할 수 없습니다. [errorCode : CanNotReservePod]';
                this.openConfirmDialog = true;
            }
            this.createRoomLoading = loadingSate.FAILED;
        } finally {
            this.createRoomLoading = loadingSate.READY;
        }
    }

    // createRoomSuccessAction = (groupId, userId, startDatetime, callbacks) => {
    //     if (this.classTap !== ClassMainPath.calendarSchedule) {
    //         this.roomListGroupByStartTime = [];
    //
    //         const range = this.classTap === ClassMainPath.todaySchedule ? 'today' : 'all';
    //         this.getRoomListGroupByStartTime(groupId, userId, range, 1);
    //     } else {
    //         this.getCalendarSchedule(groupId, userId, startDatetime);
    //     }
    //     callbacks.dialogClose();
    //     callbacks.getTagList(groupId);
    // };

    createRoomSuccessAction = (groupId, userId, startDatetime, callbacks) => {
        // this.roomListGroupByStartTime = [];

        // this.getRoomListGroupByStartTime(groupId, userId, REQUEST_ROOM_DATE_RANGE.today, 1);

        callbacks.dialogClose();
        callbacks.getTagList(groupId);
    };

    *changeLikeState(loginUser, groupId, roomId, likeId, isDetailView) {
        console.log(logPrefix, 'changeLikeState starting... loginUser={}, groupId={}, roomId={}, likeId={}', loginUser, groupId, roomId, likeId);
        this.likeLoading = loadingSate.PENDING;
        try {
            const parma = {
                groupId: groupId,
                userId: loginUser.id,
            };
            const response = yield axios.post(this.serverContextPath + `/api/v1/rooms/${roomId}/like/${likeId}`, parma);
            console.log(logPrefix, 'changeLikeState response={}', response.data);
            if (!isDetailView) {
                if (this.selectedTag !== undefined) {
                    this.roomListByTagName.find(roomData => roomData.id === roomId).likeList = response.data;
                } else {
                    if (this.classTap === ClassMainPath.member) {
                        this.roomsByOwner.find(roomData => roomData.id === roomId).likeList = response.data;
                    } else if (this.classTap === ClassMainPath.search) {
                        this.searchRoomList.find(roomData => roomData.id === roomId).likeList = response.data;
                    } else {
                        this.roomListGroupByStartTime.find(roomData => roomData.id === roomId).likeList = response.data;
                    }
                }
            } else {
                this.room.likeList = response.data;
                this.updateRoom();
            }
        } catch (e) {
            console.log(logPrefix, 'changeLikeState Failed ...');
        } finally {
            this.likeLoading = loadingSate.SUCCESS;
        }
    }

    calcCreatedDatetime = () => {
        this.roomCommentList.map(c => {
            const currentDatetime = dayjs(DATE_UTIL.getNow());
            const updateDatetime = dayjs(DATE_UTIL.convertUTCToTimeZone(c.updatedDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT));

            const month = currentDatetime.diff(updateDatetime, 'month');
            const days = currentDatetime.diff(updateDatetime, 'day');
            const hour = currentDatetime.diff(updateDatetime, 'hours');
            const minutes = currentDatetime.diff(updateDatetime, 'minutes');

            let dateBefore = '';
            if (month > 0) {
                dateBefore = DATE_UTIL.convertUTCToTimeZone(c.updatedDatetime, DATE_UTIL.FORMAT.SINGLE_DIGIT_DOT_DATE_FORMAT);
            } else if (days > 0) {
                dateBefore = `${days}일 전`;
            } else if (hour > 0) {
                dateBefore = `${hour}시간 전`;
            } else if (minutes > 0) {
                dateBefore = `${minutes}분 전`;
            } else {
                dateBefore = '방금 전';
            }
            c.dateBefore = dateBefore;
        });
    };

    *deleteRoom(callback) {
        console.log(logPrefix, 'deleteRoom starting... room={}', this.room);
        try {
            const param = Object.assign({}, this.room);
            const response = yield axios.post(this.serverContextPath + `/api/v1/rooms/delete`, param);
            if (response.data) {
                callback && callback.getTagList && callback.getTagList();
                callback && callback.moveToPrevPage && callback.moveToPrevPage();
            }
            console.log(logPrefix, 'deleteRoom response={}', response.data);
        } catch (e) {
            console.log(logPrefix, 'deleteRoom Failed ...');
        }
    }

    *getCommentCount(roomId) {
        console.log(logPrefix, 'getRoomCommentCount starting... roomId={}', roomId);
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/rooms/comment/count/${roomId}`);
            console.log(logPrefix, 'getRoomCommentCount response={}', response.data);
            this.room.commentCount = response.data;
            this.roomDummy.commentCount = response.data;
        } catch (e) {
            console.log(logPrefix, 'getRoomCommentCount Failed ...');
        }
    }
}
