import React from 'react';
import PropTypes from 'prop-types';
import LicenseKeyDialogComponent from '../views/dialog/LicenseKeyDialogComponent';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { inject } from 'mobx-react';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    buttonStyle: props => ({
        width: props?.width,
        height: 46,
        border: '1px solid #0097ff',
        background: '#fff',
        borderRadius: 7,
        padding: 0,
        marginTop: props?.marginTop,
        marginLeft: 10,
        color: '#0097ff',
        fontSize: '1.063rem',
        fontWeight: 'bold',
        '&:hover': {
            background: '#fff',
        },
        '@media all and (max-width: 600px)': {
            width: 50,
            height: 26,
            fontSize: '0.875rem',
            borderRadius: 4,
        },
    }),
}));

const LicenseKeySearchButton = ({ width = '141px', label = '라이선스 키 검색', marginTop = 0, orgStore }) => {
    const classes = useStyles({ marginTop, width });
    const location = useLocation();
    // const [isOpenLicenseKeySearchDialog, setLicenseKeySearchDialogState] = useState(false);

    const handleOpenLicenseKeySearchDialog = () => {
        // setLicenseKeySearchDialogState(true);
        orgStore?.changeLicenseSearchDialogOpenState(true);
    };

    return (
        <>
            <Button
                className={location?.pathname === '/profilesettings' ? classes.buttonStyle : 'normal-button'}
                onClick={handleOpenLicenseKeySearchDialog}
                disableRipple
            >
                {label}
            </Button>
            <LicenseKeyDialogComponent />
        </>
    );
};

LicenseKeySearchButton.propTypes = {
    label: PropTypes.string,
    marginTop: PropTypes.number,
    orgStore: PropTypes.object,
};

export default inject('orgStore')(LicenseKeySearchButton);
