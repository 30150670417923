import React, { useEffect } from 'react';
import { Box, FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        marginTop: 20,
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    formControlBox: {
        width: '100%',
        '& .MuiOutlinedInput-input': {
            padding: '12px 14px',
        },
    },
    selectBox: {
        width: '100%',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.875rem',
        borderRadius: 4,
        '& :focus': {
            outline: 'none',
        },
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.875rem',
        color: '#303030',
    },
    '@media all and (max-width: 600px)': {
        titleStyle: {
            fontSize: '0.813rem',
        },
        formControlBox: {
            '& .MuiOutlinedInput-input': {
                padding: '5px 5px',
            },
        },
        selectBox: {
            fontSize: '0.813rem',
            '& .MuiSelect-iconOutlined': {
                right: 0,
            },
        },
        optionStyle: {
            fontSize: '0.813rem',
        },
    },
    '@media all and (max-width: 365px)': {
        root: {
            '& .MuiFormControlLabel-root': {
                width: '100%',
            },
        },
        selectBox: {
            '& .MuiSelect-iconOutlined': {
                top: 2,
            },
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
});

export default function MeetingTime(props) {
    const classes = useStyles();
    const [time, setTime] = React.useState('01');
    const [minute, setMinute] = React.useState('00');
    const handleTimeSelect = event => {
        setTime(event.target.value);
    };
    const handleMinuteSelect = event => {
        setMinute(event.target.value);
    };

    const onChangeEndTime = props.onChangeEndTime;

    useEffect(() => {
        onChangeEndTime(time, minute);
    }, [time, minute, onChangeEndTime]);

    return (
        <div className={classes.root}>
            <Typography className={classes.titleStyle}>소요 시간</Typography>

            <Box display="flex" alignItems="center" pt={1} pb={1}>
                <FormControl variant={'outlined'} className={classes.formControlBox}>
                    <Select value={time} onChange={handleTimeSelect} className={classes.selectBox}>
                        <MenuItem value="" className={classes.optionStyle}>
                            시간
                        </MenuItem>
                        {Array.from({ length: 13 }, (_, i) =>
                            i < 10 ? (
                                <MenuItem key={`0${i}`} value={`0${i}`} className={classes.optionStyle}>
                                    {i}
                                </MenuItem>
                            ) : (
                                <MenuItem key={i} value={i} className={classes.optionStyle}>
                                    {i}
                                </MenuItem>
                            ),
                        )}
                    </Select>
                </FormControl>
                <Typography style={{ paddingLeft: 10, paddingRight: 10 }}> : </Typography>
                <FormControl variant={'outlined'} className={classes.formControlBox}>
                    <Select value={minute} onChange={handleMinuteSelect} className={classes.selectBox}>
                        <MenuItem value="" className={classes.optionStyle}>
                            분
                        </MenuItem>
                        <MenuItem value="00" className={classes.optionStyle}>
                            00
                        </MenuItem>
                        <MenuItem value="05" className={classes.optionStyle}>
                            05
                        </MenuItem>
                        <MenuItem value="10" className={classes.optionStyle}>
                            10
                        </MenuItem>
                        <MenuItem value="15" className={classes.optionStyle}>
                            15
                        </MenuItem>
                        <MenuItem value="20" className={classes.optionStyle}>
                            20
                        </MenuItem>
                        <MenuItem value="25" className={classes.optionStyle}>
                            25
                        </MenuItem>
                        <MenuItem value="30" className={classes.optionStyle}>
                            30
                        </MenuItem>
                        <MenuItem value="35" className={classes.optionStyle}>
                            35
                        </MenuItem>
                        <MenuItem value="40" className={classes.optionStyle}>
                            40
                        </MenuItem>
                        <MenuItem value="45" className={classes.optionStyle}>
                            45
                        </MenuItem>
                        <MenuItem value="50" className={classes.optionStyle}>
                            50
                        </MenuItem>
                        <MenuItem value="55" className={classes.optionStyle}>
                            55
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </div>
    );
}
