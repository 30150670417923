import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, IconButton, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as DotIcon } from '../../../common/images/DotIcon.svg';
import { ReactComponent as ChartBarFillIcon } from '../../../common/images/ChartBarFillIcon.svg';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/ko';
import ArrowIcon from '../../../common/images/ArrowIcon.svg';
import { inject, observer } from 'mobx-react';
import { loadingSate, roomState } from '../../../stores/RoomStore';
import { DATE_UTIL } from '../../../common/util/date.util';
import dayjs from 'dayjs';
import { withRouter } from '../../../components/WithRouter';
import ClassScheduleDetailContentComponent from './MobileClassScheduleDetailContentComponent';

const styles = theme => ({
    root: {
        width: 'calc(100vw - 32px)',
        paddingBottom: 70,
    },
    calenderStyle: {
        '& .rbc-toolbar': {
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            margin: '10px 0',
        },
        '& .rbc-toolbar-label': {
            display: 'flex',
            fontSize: '1.25rem',
            fontWeight: 600,
            flexGrow: 0,
        },
        '& .rbc-btn-group': {
            position: 'relative',
            // width: 170,
            // marginRight: 275,
            '& button': {
                cursor: 'pointer',
            },
            '& button:hover, button:focus, button:active': {
                background: 'transparent',
                boxShadow: 'none',
            },
            '& button:first-child': {
                borderRadius: 4,
                // marginRight:20,
                position: 'absolute',
                left: -60,
            },
            '& button:not(:first-child)': {
                border: 0,
                textIndent: '-99999px',
                overflow: 'hidden',
                position: 'relative',
                '&:before': {
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    textIndent: 0,
                    transform: 'translate(50%, 50%)',
                },
            },
            '& button:nth-child(2)': {
                marginLeft: 15,
            },
            '& button:nth-child(2):before': {
                content: "''",
                width: 18,
                height: 18,
                backgroundImage: `url(${ArrowIcon})`,
                backgroundSize: '100%',
                marginLeft: -10,
                marginTop: -5,
            },
            '& button:nth-child(3):before': {
                content: "''",
                width: 18,
                height: 18,
                backgroundImage: `url(${ArrowIcon})`,
                backgroundSize: '100%',
                marginLeft: 15,
                transform: 'rotate(180deg) !important',
                marginTop: 4,
            },
            '&:last-child': {
                display: 'none',
                textIndent: '-999',
                size: 0,
                overflow: 'hidden',
            },
        },
        '& .rbc-month-view': {
            borderRadius: 10,
            borderColor: '#d7dbdf',
            '& .rbc-month-row': {
                '& .rbc-row-bg': {
                    '& .rbc-day-bg': {
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
            },
        },
        '& .rbc-header': {
            padding: 13,
            color: '#4f5660',
            '&:first-child': {
                color: '#e90326',
            },
            '&:last-child': {
                color: '#2789cc',
            },
            '& span': {
                fontWeight: 400,
            },
        },
        '& .rbc-month-row>div': {
            fontSize: '0.75rem',
        },
        '& .rbc-date-cell': {
            textAlign: 'left',
            padding: 9,
            fontWeight: 600,
            '&:hover': {
                cursor: 'pointer',
            },
            '&:first-child': {
                color: '#e90326',
            },
            '&:last-child': {
                color: '#2789cc',
            },
        },
        '& .rbc-today': {
            background: 'transparent',
        },
        '& .rbc-current a': {
            backgroundColor: '#a3dfff',
            color: '#fff',
            padding: 6,
            borderRadius: 20,
        },
        '& .rbc-off-range': {
            opacity: 0.5,
        },
        '& .rbc-off-range-bg': {
            background: 'transparent',
        },
    },
    dailyBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
        border: '1px solid #e1e4e7',
        borderRadius: 8,
        padding: '16px 8px',
        marginTop: 20,
        cursor: 'pointer',
    },
    dailyBox1: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
        border: '1px solid #e1e4e7',
        borderRadius: 8,
        padding: '16px 8px',
        marginTop: 20,
        cursor: 'pointer',
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        fontSize: '0.75rem',
        width: 80,
        '& b': {
            fontSize: '1.5rem',
            fontWeight: 700,
        },
    },
    content: {
        width: '65%',
        '& h5': {
            fontSize: '1rem',
            fontWeight: 600,
            margin: '3px 0',
        },
        '& .MuiTypography-body2': {
            fontSize: '0.813rem',
        },
    },
    caption: {
        backgroundColor: 'transparent',
        color: '#fff',
        padding: '2px 6px',
        fontSize: '0.625rem',
        fontFamily: 'Montserrat!important',
        textTransform: 'uppercase',
        fontWeight: 800,
        display: 'inline-flex',
        alignItems: 'center',
        '& svg': {
            marginRight: 3,
        },
    },
    captionEnd: {
        backgroundColor: '#505050',
    },
    captionFuture: {
        backgroundColor: '#a3dfff',
    },
    captionLive: {
        backgroundColor: '#FB4A59',
    },
    btnOutlineStyle: {
        border: () => (theme.configs.MainBtnColor ? `1px solid ${theme.configs.MainBtnColor}` : '1px solid #0097FF'),
        color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        fontWeight: 600,
        padding: 6,
        borderRadius: 7,
        fontSize: '0.813rem',
        '&:hover': {
            fontWeight: 800,
            background: 'transparent',
        },
    },
    btnOutlineStyleColor: {
        color: '#4f5660',
        border: '1px solid #4f5660',
    },
    progressBox: {
        justifyContent: 'center',
        height: 69.5,
    },
    emptyBox: {
        width: '60%',
        height: 69.5,
        '& h5': {
            fontSize: '0.875rem',
            fontWeight: 500,
            padding: '25px 0',
        },
    },
    btnChartStyle: {
        marginLeft: 'auto',
    },
});

class MobileClassCalendarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            selectedDay: new Date(),
            scheduleDetail: false,
        };
    }
    componentDidMount() {
        this.props.roomStore.initCalendarData();
        this.props.roomStore.getCalendarSchedule(this.props.groupDetail.group.id, this.props.loginUser.id, this.state.selectedDay);
    }

    handleClickDetail = roomId => {
        this.props.handleClickDetail(roomId);
    };

    checkRoomState = (startDatetime, endDatetime) => {
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedStartDatetime = DATE_UTIL.convertUTCToTimeZone(startDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedEndDatetime = DATE_UTIL.convertUTCToTimeZone(endDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        if (dayjs(convertedEndDatetime).isBefore(currentDatetime)) {
            return roomState.Ended;
        } else if (
            dayjs(currentDatetime).isBetween(convertedStartDatetime, convertedEndDatetime) ||
            dayjs(currentDatetime).isSame(convertedStartDatetime) ||
            dayjs(currentDatetime).isSame(convertedEndDatetime)
        ) {
            return roomState.Live;
        } else {
            return roomState.Future;
        }
    };

    handleClickEnter = (event, url) => {
        event.stopPropagation();
        window.open(url);
    };

    selectedDate = date => {
        this.setState({ selectedDay: date }, () =>
            this.props.roomStore.getCalendarSchedule(this.props.groupDetail.group.id, this.props.loginUser.id, this.state.selectedDay),
        );
    };

    handleClickStatistics = (e, room) => {
        e.preventDefault();
        e.stopPropagation();
        // window.open(`/class/${room.groupId}/rooms/${room.id}/statistics`);
        window.open(`/statistics/${room.id}`);
    };

    render() {
        const { classes, currentTab, isLeader } = this.props;
        const { calendarDataLoading, calendarRoomList } = this.props.roomStore;
        moment.locale('ko-KR');
        const localizer = momentLocalizer(moment);
        const formats = { monthHeaderFormat: 'yyyy년 MM월' };
        const messages = { today: '오늘' };
        const onSelectSlot = (slotInfo: { slots: Date[] | string[] }) => {
            this.selectedDate(slotInfo.start);
        };

        const onNavigate = (date: Date) => {
            this.selectedDate(date);
        };

        return (
            <div className={classes.root}>
                {!this.state.scheduleDetail ? (
                    <>
                        <Calendar
                            localizer={localizer}
                            events={[{ view: 'month' }]}
                            style={{ height: 500 }}
                            className={classes.calenderStyle}
                            formats={formats}
                            messages={messages}
                            // view="month"
                            defaultView="month"
                            views={['month']}
                            // onView={() => {
                            //     console.log('Calendar on view');
                            // }}
                            selectable={true}
                            date={this.state.selectedDay}
                            onSelectSlot={onSelectSlot}
                            onNavigate={onNavigate}
                        />
                        {calendarDataLoading !== loadingSate.PENDING ? (
                            calendarRoomList.length > 0 ? (
                                calendarRoomList.map((r, _index) => {
                                    const state = this.checkRoomState(r.startDatetime, r.endDatetime);
                                    return (
                                        <Box
                                            key={r.id}
                                            className={clsx(state === roomState.Live ? classes.dailyBox : classes.dailyBox1)}
                                            onClick={() => this.handleClickDetail(r.id)}
                                        >
                                            <Box className={classes.date}>
                                                {' '}
                                                <b>{dayjs(new Date(this.state.selectedDay)).format('DD')}</b>
                                                {dayjs(new Date(this.state.selectedDay)).format('ddd')}
                                            </Box>
                                            <Box className={classes.content}>
                                                {state === roomState.Ended && <Box className={clsx(classes.caption, classes.captionEnd)}>종료</Box>}
                                                {state === roomState.Future && (
                                                    <Box className={clsx(classes.caption, classes.captionFuture)}>예정</Box>
                                                )}
                                                {state === roomState.Live && (
                                                    <Box>
                                                        <span className={classes.circle}></span>{' '}
                                                        <Typography className={clsx(classes.caption, classes.captionLive)}>
                                                            <DotIcon />
                                                            LIVE
                                                        </Typography>
                                                    </Box>
                                                )}
                                                <Box style={state === roomState.Ended ? { opacity: 0.5 } : {}}>
                                                    <Typography variant="h5">{r.name}</Typography>
                                                    <Typography variant="body2">
                                                        {DATE_UTIL.convertUTCToTimeZone(r.startDatetime, DATE_UTIL.FORMAT.SIMPLE_TIME_AMPM_FORMAT)} ~{' '}
                                                        {DATE_UTIL.convertUTCToTimeZone(r.endDatetime, DATE_UTIL.FORMAT.SIMPLE_TIME_AMPM_FORMAT)}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            {state === roomState.Live && (
                                                <Button
                                                    className={classes.btnOutlineStyle}
                                                    disableRipple
                                                    onClick={e =>
                                                        this.handleClickEnter(
                                                            e,
                                                            r.members.find(m => m.userId === this.props.loginUser.id).entranceUrl,
                                                        )
                                                    }
                                                >
                                                    참석하기
                                                </Button>
                                            )}
                                            {state === roomState.Ended && isLeader && r.statistics && (
                                                <IconButton
                                                    disableRipple
                                                    className={classes.btnChartStyle}
                                                    onClick={e => this.handleClickStatistics(e, r)}
                                                >
                                                    <ChartBarFillIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    );
                                })
                            ) : (
                                <Box className={classes.dailyBox}>
                                    <Box className={classes.date}>
                                        {' '}
                                        <b>{dayjs(new Date(this.state.selectedDay)).format('DD')}</b>
                                        {dayjs(new Date(this.state.selectedDay)).format('ddd')}
                                    </Box>
                                    <Box className={classes.emptyBox}>
                                        <Typography variant="h5"> 예정된 일정이 없습니다</Typography>
                                    </Box>
                                </Box>
                            )
                        ) : (
                            <Box className={clsx(classes.progressBox, classes.dailyBox)}>
                                <CircularProgress size={20} />
                            </Box>
                        )}
                    </>
                ) : (
                    <ClassScheduleDetailContentComponent currentTab={currentTab} />
                )}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('roomStore')(observer(MobileClassCalendarComponent))));
