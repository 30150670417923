import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../components/WithRouter';
import { OAUTH_OPERATION, POLICY_AGREEMENT_LABELS, POLICY_AGREEMENT_TYPE } from '../../../stores/AuthStore';
import { injectIntl } from 'react-intl';
import ConfirmDialog from '../../../components/common/ConfirmDialog';
import { inject, observer } from 'mobx-react';
import { getURLParams } from '../../../common/util/Params';

const styles = theme => ({
    root: {
        width: '100%',
        height: 'calc(100vh - 130px)',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boxLine: {
        width: 540,
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #e5e6e8',
        padding: '50px 0 30px',
        boxSizing: 'border-box',
        borderRadius: 12,
        marginTop: 45,
    },
    titleText: {
        fontSize: '1.75rem',
        color: '#333',
        textAlign: 'center',
        marginBottom: 12,
    },
    subText: {
        textAlign: 'center',
        fontSize: '1rem',
        color: '#808080',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkBoxMargin: {
        margin: '30px 0',
        background: 'rgba(242, 245, 248, 0.5)',
        padding: '20px 80px',
    },
    buttonStyle: {
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        padding: '15px 0',
        borderRadius: 7,
        marginLeft: 80,
        marginRight: 80,
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    checkedListBoxIn: {
        '& .MuiCheckbox-colorSecondary:hover': {
            '&:hover': {
                background: 'transparent',
            },
        },
        '& label': {
            fontSize: '0.875rem',
            color: '#333',
        },
    },
    allCheckedBox: {
        '& .MuiCheckbox-colorSecondary:hover': {
            '&:hover': {
                background: 'transparent',
            },
        },
        '& label': {
            fontSize: '0.938rem',
            fontWeight: 600,
            color: '#000',
        },
    },
    checkedViewButton: {
        fontSize: '0.875rem',
        color: '#9a9a9a',
        textDecoration: 'underline',
        padding: 0,
        '&:hover': {
            background: 'transparent',
            textDecoration: 'underline',
        },
    },
});

class Oauth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAllSelected: false,
            checkList: [
                {
                    name: POLICY_AGREEMENT_LABELS.TERMS_OF_SERVICE,
                    value: props.intl.formatMessage({ id: 'terms_of_service' }),
                    checked: false,
                    type: POLICY_AGREEMENT_TYPE.Required,
                },
                {
                    name: POLICY_AGREEMENT_LABELS.PRIVACY_POLICY,
                    value: props.intl.formatMessage({ id: 'privacy_policy' }),
                    checked: false,
                    type: POLICY_AGREEMENT_TYPE.Required,
                },
                {
                    name: POLICY_AGREEMENT_LABELS.ALLOWED_EMAIL,
                    value: props.intl.formatMessage({ id: 'allowed_email' }),
                    checked: false,
                    type: POLICY_AGREEMENT_TYPE.Optional,
                },
            ],
        };
    }

    moveTo = path => {
        this.props.navigate(path);
    };

    componentDidMount() {
        const { ...searchParams } = getURLParams(window.location.search);
        const { params } = this.props;
        if (params.operation === OAUTH_OPERATION.SIGNUP) {
            setTimeout(() => {
                this.moveTo(`/socialagree/${params.platform}?code=${searchParams.code}`);
            }, 0);
        } else {
            this.props.authStore.callbackOAuthServiceForSignIn(params.platform, searchParams.code, {
                moveToRooms: () => {
                    setTimeout(() => {
                        this.moveTo('/rooms');
                    }, 0);
                },
            });
        }
    }

    handleClickSignUp = () => {
        const isAllowedEmail = this.state.checkList.find(check => check.name === POLICY_AGREEMENT_LABELS.ALLOWED_EMAIL).checked;
        this.props.authStore.signUp(isAllowedEmail, {
            openSignUpDialog: signUpSuccessUserEmail => this.moveTo(`/signupDialog/${signUpSuccessUserEmail}`),
        });
    };

    onCheckBoxChange(checkName, isChecked) {
        let isAllChecked = checkName === 'all' && isChecked;
        let isAllUnChecked = checkName === 'all' && !isChecked;
        const checked = isChecked;

        const checkList = this.state.checkList.map((help, index) => {
            if (isAllChecked || help.value === checkName) {
                return Object.assign({}, help, {
                    checked,
                });
            } else if (isAllUnChecked) {
                return Object.assign({}, help, {
                    checked: false,
                });
            }

            return help;
        });

        let isAllSelected = checkList.findIndex(item => item.checked === false) === -1 || isAllChecked;

        this.setState({
            checkList,
            isAllSelected,
        });
    }

    render() {
        const { classes, intl } = this.props;
        const { isSignInAndUpResultDialogOpen, signInAndUpResultDialogMsgId } = this.props.authStore;
        const { closeSignInAndUpResultDialog } = this.props.authStore;
        return (
            <div className={classes.root}>
                <ConfirmDialog
                    open={isSignInAndUpResultDialogOpen}
                    title={intl.formatMessage({ id: 'notification' })}
                    msg={intl.formatMessage({ id: `${signInAndUpResultDialogMsgId}` })}
                    changeDialogOpen={isOpen => closeSignInAndUpResultDialog(isOpen)}
                    moveTo={() => this.moveTo('/signin')}
                />
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('authStore')(injectIntl(observer(Oauth)))));
