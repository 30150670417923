import React, { Component } from 'react';
import MobileClassSelectTagComponent from './MobileClassSelectTagComponent';
import MobileClassSelectOwnerComponent from './MobileClassSelectOwnerComponent';
import { withRouter } from '../../../components/WithRouter';
import { CLASSIFICATION_TYPE } from '../../class/ScheduleClassificationComponent';

class MobileScheduleClassificationComponent extends Component {
    render() {
        const { classification, classSelectTag, classTab, handleClickDetail, handleClickPostDetail, isLeader } = this.props;
        const isSelectedTagComponent = classification === CLASSIFICATION_TYPE.Tag;

        return (
            <>
                {isSelectedTagComponent ? (
                    <MobileClassSelectTagComponent
                        classSelectTag={classSelectTag}
                        classTab={classTab}
                        handleClickDetail={handleClickDetail}
                        handleClickPostDetail={handleClickPostDetail}
                        isMember={this.props.isMember}
                        isLeader={isLeader}
                    />
                ) : (
                    <MobileClassSelectOwnerComponent
                        handleClickDetail={handleClickDetail}
                        isMember={this.props.isMember}
                        isScrollEnd={this.props.isScrollEnd}
                        scrollHeight={this.props.scrollHeight}
                        calcScrollEnd={this.props.calcScrollEnd}
                        isLeader={isLeader}
                    />
                )}
            </>
        );
    }
}

export default withRouter(MobileScheduleClassificationComponent);
