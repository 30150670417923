import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as UsersThreeIcon } from '../../common/images/UsersThreeIcon.svg';
import { ReactComponent as AlarmIcon } from '../../common/images/AlarmIcon.svg';
import dayjs from 'dayjs';
import { DATE_UTIL } from '../../common/util/date.util';
import { inject, observer } from 'mobx-react';
import { roomState } from '../../stores/RoomStore';
import { withRouter } from '../../components/WithRouter';

const styles = theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 1440,
        },
        width: 1200,
        margin: '0 auto',
        padding: '20px 30px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'column',
        '& h5': {
            fontSize: '1.25rem',
            marginBottom: 25,
        },
    },
    noScheduleBox: {
        paddingBottom: 150,
    },
    scheduleBoxIn: {
        paddingBottom: 54,
    },
    scheduleBoxStyle: {
        '@media all and (min-width: 1500px)': {
            width: 320,
            height: 280,
            marginRight: 30,
            marginBottom: 30,
        },
        width: 268,
        height: 250,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        marginRight: 20,
        marginBottom: 20,
        borderTop: '7px solid #a3a8af',
        border: '1px solid rgba(163, 168, 175, 0.5)',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        '&:nth-child(4n+0)': {
            marginRight: 0,
        },
    },
    liveScheduleBox: {
        borderTop: '7px solid #00c880',
        border: '1px solid rgba(0, 200, 128, 0.5)',
    },
    roomNameBox: {
        padding: '7px 15px',
        borderBottom: '1px solid #d3d7db',
        '& svg': {
            marginRight: 8,
        },
    },
    roomNameText: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1.063rem',
        },
        width: 250,
        fontSize: '0.875rem',
        color: '#000',
    },
    contentsBox: {
        padding: '10px 35px 0px',
        boxSizing: 'border-box',
        '& svg': {
            width: 22,
            height: 22,
            '& .st0': {
                fill: '#00c880',
            },
        },
    },
    box: {
        width: 40,
        height: 16,
        background: '#a3a8af',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    liveBox: {
        background: '#fb4a59',
    },
    boxText: {
        fontFamily: 'Montserrat',
        fontSize: '0.625rem',
        color: '#fff',
        fontWeight: 600,
    },
    circle: {
        width: 4,
        height: 4,
        borderRadius: '50%',
        background: '#fff',
        marginRight: 4,
    },
    scheduleTitleText: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1.563rem',
        },
        fontSize: '1.313rem',
        color: '#000',
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        margin: '7px 0 13px',
    },
    timeText: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1.063rem',
        },
        fontSize: '0.875rem',
        color: '#656565',
        marginLeft: 7,
    },
    buttonStyle: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1rem',
            height: 40,
        },
        width: 200,
        height: 34,
        border: '1px solid #656565',
        color: '#656565',
        borderRadius: 7,
        fontWeight: 600,
        fontSize: '0.813rem',
        '&:hover': {
            background: 'transparent',
        },
    },
    liveButtonStyle: {
        border: '1px solid #0097ff',
        color: '#0097ff',
    },
    commentBox: {
        minHeight: 64,
    },
});

class SearchScheduleComponent extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const { roomStore, authStore, searchStore } = this.props;
        const { searchKeyword } = searchStore;
        if (
            prevProps.isScrollEnd !== this.props.isScrollEnd &&
            this.props.isScrollEnd &&
            roomStore.roomTotalCount > roomStore.roomListGroupByStartTime.length
        ) {
            roomStore.changeCurrentPage();
            roomStore.getRoomListGroupByStartTime(
                undefined,
                authStore.loginUser.id,
                'all',
                roomStore.currentPage,
                undefined,
                undefined,
                undefined,
                searchKeyword,
            );
        }
    }

    checkRoomState = (startDatetime, endDatetime) => {
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedStartDatetime = DATE_UTIL.convertUTCToTimeZone(startDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedEndDatetime = DATE_UTIL.convertUTCToTimeZone(endDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        if (dayjs(convertedEndDatetime).isBefore(currentDatetime)) {
            return roomState.Ended;
        } else if (
            dayjs(currentDatetime).isBetween(convertedStartDatetime, convertedEndDatetime) ||
            dayjs(currentDatetime).isSame(convertedStartDatetime) ||
            dayjs(currentDatetime).isSame(convertedEndDatetime)
        ) {
            return roomState.Live;
        } else {
            return roomState.Future;
        }
    };

    handleClickEnter = (event, url) => {
        event.stopPropagation();
        // url = url + '&tz=' + 'UTC' + '&local=' + DATE_UTIL.getLanguage();
        window.open(url);
    };

    handleClickDetail = (groupId, roomId) => {
        window.scrollTo(0, 0);
        this.props.navigate(`/class/${groupId}/scheduledetail/${roomId}`);
    };

    render() {
        const { classes, roomStore, isSearchGroupListLoading } = this.props;
        const { roomListGroupByStartTime, roomTotalCount, isLoadRoomLoading } = roomStore;

        return (
            <div className={classes.root}>
                {!isLoadRoomLoading && !isSearchGroupListLoading && (
                    <Typography variant="h5">{`검색결과(${roomTotalCount > 999 ? '999+' : roomTotalCount})`}</Typography>
                )}
                <Box style={{ width: 'inherit' }}>
                    {!isLoadRoomLoading && !isSearchGroupListLoading ? (
                        !roomListGroupByStartTime.length ? (
                            <Box className={classes.noScheduleBox}>
                                <Typography className={classes.textStyle}>검색결과가 없습니다.</Typography>
                            </Box>
                        ) : (
                            <Box display="flex" alignItems="center" flexWrap="wrap" className={classes.scheduleBoxIn}>
                                {roomListGroupByStartTime.map((schedule, i) => {
                                    const state = this.checkRoomState(schedule.startDatetime, schedule.endDatetime);
                                    return (
                                        <Box
                                            key={i}
                                            className={
                                                state === roomState.Live
                                                    ? clsx(classes.scheduleBoxStyle, classes.liveScheduleBox)
                                                    : classes.scheduleBoxStyle
                                            }
                                        >
                                            <Box display="flex" alignItems="center" className={classes.roomNameBox}>
                                                <UsersThreeIcon />
                                                <Typography className={classes.roomNameText} noWrap>
                                                    {schedule.groupName}
                                                </Typography>
                                            </Box>
                                            <Box className={classes.contentsBox}>
                                                <Box className={state === roomState.Live ? clsx(classes.box, classes.liveBox) : classes.box}>
                                                    <Box className={classes.circle} />
                                                    <Typography className={classes.boxText}>
                                                        {state === roomState.Live ? 'LIVE' : state === roomState.Future ? '예정' : '종료'}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.commentBox}>
                                                    <Typography className={classes.scheduleTitleText}>{schedule.name}</Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center">
                                                    <AlarmIcon />
                                                    <Typography className={classes.timeText}>
                                                        {DATE_UTIL.convertUTCToTimeZone(
                                                            schedule.startDatetime,
                                                            DATE_UTIL.FORMAT.SIMPLE_TIME_AMPM_FORMAT,
                                                        )}{' '}
                                                        ~{' '}
                                                        {DATE_UTIL.convertUTCToTimeZone(
                                                            schedule.endDatetime,
                                                            DATE_UTIL.FORMAT.SIMPLE_TIME_AMPM_FORMAT,
                                                        )}
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" justifyContent="center" style={{ marginTop: 20 }}>
                                                    {state === roomState.Live ? (
                                                        <Button
                                                            className={clsx(classes.buttonStyle, classes.liveButtonStyle)}
                                                            disableRipple
                                                            onClick={e =>
                                                                this.handleClickEnter(
                                                                    e,
                                                                    schedule.members.find(m => m.userId === this.props.authStore.loginUser.id)
                                                                        .entranceUrl,
                                                                )
                                                            }
                                                        >
                                                            {'참석하기'}
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            className={classes.buttonStyle}
                                                            disableRipple
                                                            onClick={() => this.handleClickDetail(schedule.groupId, schedule.id)}
                                                        >
                                                            {'살펴보기'}
                                                        </Button>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                        )
                    ) : (
                        <Box style={{ textAlign: 'center', marginTop: 100 }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('roomStore', 'authStore', 'searchStore')(observer(SearchScheduleComponent))));
