import { makeAutoObservable } from 'mobx';
import axios from 'axios';

const logPrefix = ' [ OrganizationStore ] ';
const ORG_API_PATH = '/api/v1/organizations';

const CONFIG_KEY = {
    OrganizationName: 'OrganizationName',
};

export default class OrganizationStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;

        makeAutoObservable(this);
    }

    organizationConfigs = [];

    get organizationName() {
        let organizationName = '';
        if (this.organizationConfigs.length) {
            const organizationNameConfig = this.organizationConfigs.find(config => config.configKey === CONFIG_KEY.OrganizationName);
            if (organizationNameConfig) {
                organizationName = organizationNameConfig.value;
            }
        }
        return organizationName;
    }

    getOrganizationConfigs = function* () {
        console.log(logPrefix, 'Start getOrganizationConfigs');
        try {
            const response = yield axios.get(this.serverContextPath + `${ORG_API_PATH}`);
            console.log(logPrefix, 'Success getOrganizationConfigs');
            console.log(logPrefix, 'Result = ', response.data);
            this.organizationConfigs = response.data;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getOrganizationConfigs', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getOrganizationConfigs', e.request);
            } else {
                console.log(logPrefix, 'Failed getOrganizationConfigs', e.message);
            }
        }
    };
}
