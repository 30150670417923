import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { withRouter } from '../../components/WithRouter';

const styles = _theme => ({
    root: {
        padding: '0 25px 30px',
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleBox: {
        marginBottom: 10,
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    btnStyle: {
        border: '1px solid #bfbfbf',
        borderRadius: 4,
        marginLeft: 10,
        color: '#000',
        padding: '3px 8px',
        fontWeight: 400,
        fontSize: '0.875rem',
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 4,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    checkBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#a9adb4',
        fontSize: '0.875rem',
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 4,
        },
    },
    checkBoxStyleOn: {
        color: '#0097FF',
    },
    userStyle: {
        color: '#a3a8af',
        fontSize: '0.813rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            marginRight: 4,
        },
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    scrollBox: {
        maxHeight: 380,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '3px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 10,
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 ',
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        color: '#fff',
        fontSize: '1.5rem',
        fontWeight: 600,
        '& svg': {
            width: 25,
            height: 25,
            '& path': {
                fill: '#fff',
            },
        },
    },
    listItemTitle: {
        fontSize: '1rem',
        fontWeight: 600,
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
});

class ClassTeamInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '최근 작성일 순',
            isFiltered: false,
        };
    }

    componentDidMount() {
        this.props.teamMembers.map(member => {
            this.props.findAvatar(member);
        });
    }

    handleChangeCheckBox = () => {
        this.setState({ isFiltered: !this.state.isFiltered });
    };

    render() {
        const { classes, teamMembers } = this.props;

        return (
            <div className={classes.root}>
                <Box display="flex" flexDirection="column" className={classes.scrollBox}>
                    {teamMembers.map(member => {
                        return (
                            <Box key={`${member.userId}_${member.userName}`} className={classes.listItemStyle}>
                                <Box className={classes.flexCenter}>
                                    <Box>
                                        <Avatar src={member.image ? member.image : null} alt="profile image" className={classes.avatar}>
                                            <AsideUserIcon />
                                        </Avatar>
                                    </Box>
                                    <Box flexDirection="column">
                                        <Typography className={classes.listItemTitle}>{member.name}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('authStore', 'classTeamStore', 'classStore')(observer(ClassTeamInfoComponent))));
