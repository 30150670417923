import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Checkbox, FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput, Typography } from '@material-ui/core';
import { ReactComponent as LoginUserIcon } from '../../../common/images/LoginUserIcon.svg';
import { ReactComponent as LicenseIcon } from '../../../common/images/LicenseIcon.svg';
import { ReactComponent as LoginPasswordIcon } from '../../../common/images/LoginPasswordIcon.svg';
import { ReactComponent as LoginEyeIcon } from '../../../common/images/LoginEyeIcon.svg';
import { ReactComponent as LoginEyeSlashIcon } from '../../../common/images/LoginEyeSlashIcon.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import { ReactComponent as CheckCircleAgreeOffIcon } from '../../../common/images/CheckCircleAgreeOffIcon.svg';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../../common/images/CheckCircleAgreeOnIcon.svg';
import clsx from 'clsx';
import PasswordCheckComponent from '../PasswordCheckComponent';
import { withRouter } from '../../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { OAUTH_OPERATION, POLICY_AGREEMENT_LABELS, POLICY_AGREEMENT_TYPE } from '../../../stores/AuthStore';
import ConfirmDialog from '../../../components/common/ConfirmDialog';
import { checkSpace } from '../../../common/Validation';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EmailResendDialog from './EmailResendDialog';
import { ToastsStore } from 'react-toasts';
import LicenseKeyInfoBox from '../../../components/LicenseKeyInfoBox';
import { ReactComponent as MagnifyingGlassIcon } from '../../../common/images/MagnifyingGlassIcon.svg';
import { LICENSE_KEY_STR_PATTERN } from '../../../common/util/common.util';

// const ON_THE_LIVE_LOCAL_TEST_SITE_KEY = '6LcqKNseAAAAAP1589R78XtshX7cQHPlKhVEEu6o';
// const ON_THE_LIVE_DEV_SITE_KEY = '6Ld1LdseAAAAAIClP8YgWKt-pdfwLvS8WwxL02hH';
const STUDY_LIVE_SITE_KEY = '6LeX5GkfAAAAAMWZ8S2QPgI4i1qz7nDqk8luvMqE';

const styles = _theme => ({
    root: {
        width: 522,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 auto 0',
    },
    textButton: {
        fontSize: '0.875rem',
        color: '#333',
        marginBottom: 15,
        marginTop: 45,
    },
    underline: {
        fontWeight: '600',
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    loginBox: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #e5e6e8',
        padding: '50px 90px 30px',
        boxSizing: 'border-box',
        borderRadius: 12,
    },
    loginText: {
        fontSize: '1.75rem',
        color: '#333',
        textAlign: 'center',
        marginBottom: 12,
    },
    textField: {
        marginTop: 20,
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #d9dbde',
            },
        '& .MuiOutlinedInput-root': {
            width: 340,
            borderRadius: 7,
            '&:hover': {
                borderColor: '#d9dbde',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '13px 0px',
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#9d9d9d',
                opacity: 1,
            },
        },
        '& .MuiInputBase-root div:first-child::after': {
            display: 'block',
            content: "'*'",
            color: 'red',
            fontSize: 16,
            marginBottom: 8,
        },
    },
    errorTextField: {
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #ff0000',
            },
    },
    searchIconButton: {
        padding: 6,
        backgroundColor: '#f8f8f8',
        '&:hover': {
            backgroundColor: '#f8f8f8',
        },
    },
    errorText: {
        fontSize: '0.813rem',
        color: '#ff0000',
        margin: '4px 0 0',
    },
    checkBoxMargin: {
        marginTop: 30,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    buttonStyle: {
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        padding: '15px 0',
        borderRadius: 7,
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    lineStyle: {
        width: '100%',
        height: 1,
        borderBottom: '1px dashed #dddddd',
        margin: '30px 0',
    },
    checkBoxIcon: {},
    checkedListBoxIn: {
        '& .MuiCheckbox-colorSecondary:hover': {
            '&:hover': {
                background: 'transparent',
            },
        },
        '& label': {
            fontSize: '0.875rem',
            color: '#333',
        },
    },
    allCheckedBox: {
        '& .MuiCheckbox-colorSecondary:hover': {
            '&:hover': {
                background: 'transparent',
            },
        },
        '& label': {
            fontSize: '0.938rem',
            fontWeight: 600,
            color: '#000',
        },
    },
    checkedViewButton: {
        fontSize: '0.875rem',
        color: '#9a9a9a',
        textDecoration: 'underline',
        padding: 0,
        '&:hover': {
            background: 'transparent',
            textDecoration: 'underline',
        },
    },
    infoBox: {
        width: '100%',
        height: 115,
        borderRadius: 5,
        boxSizing: 'border-box',
        padding: '14px 17px',
        background: 'rgba(242, 245, 248, 0.5)',
        display: 'flex',
        marginTop: 14,
        '& p': {
            fontSize: '0.875rem',
            color: '#333',
            letterSpacing: '-0.2px',
            lineHeight: 1.4,
            '& span': {
                fontWeight: 'bold',
            },
            '& a': {
                color: '#0097ff',
            },
            '&:last-child': {
                marginTop: 8,
            },
        },
        '& svg': {
            marginRight: 4,
        },
    },
});

function CheckBox({ name, value, tick, onCheck, type }) {
    const intl = useIntl();
    return (
        <label>
            <Checkbox
                name={name}
                value={value}
                checked={tick || false}
                onChange={onCheck}
                checkedIcon={<CheckCircleAgreeOnIcon />}
                icon={<CheckCircleAgreeOffIcon />}
                disableRipple
            />
            {name === 'select-all' ? null : type === POLICY_AGREEMENT_TYPE.Optional ? (
                <span>[{intl.formatMessage({ id: 'optional' })}] </span>
            ) : (
                <span style={{ color: '#ff0000' }}>[{intl.formatMessage({ id: 'required' })}] </span>
            )}

            {value}
        </label>
    );
}

function CheckBoxList({ options, isCheckedAll, onCheck, classes }) {
    const checkBoxOptions = (
        <div className="checkbox-list">
            {options.map((option, index) => {
                return (
                    <Box key={index} display="flex" alignItems="center" justifyContent="space-between" className={classes.checkedListBoxIn}>
                        <CheckBox
                            key={index}
                            name={option.name}
                            value={option.value}
                            tick={option.checked}
                            type={option.type}
                            onCheck={e => onCheck(option.value, e.target.checked)}
                        />
                        <Button
                            onClick={() => {
                                if (option.name === 'TERMS_OF_SERVICE') {
                                    return window.open('/termsofuse', '_blank');
                                } else if (option.name === 'PRIVACY_POLICY') {
                                    return window.open('/privacypolicy', '_blank');
                                }
                            }}
                            className={classes.checkedViewButton}
                            disableRipple
                        >
                            보기
                        </Button>
                    </Box>
                );
            })}
        </div>
    );

    return (
        <Box className="checkbox-list">
            <Box display="flex" alignItems="center" className={classes.allCheckedBox}>
                <CheckBox name="select-all" value="전체 약관동의" tick={isCheckedAll} onCheck={e => onCheck('all', e.target.checked)} />
            </Box>
            {checkBoxOptions}
        </Box>
    );
}

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.recaptchaRef = React.createRef();
        this.state = {
            showPassword: false,
            isAllSelected: false,
            emailValidation: true,
            nameValidation: true,
            passwordValidation: [true, false, false, false],
            licenseKeyDialog: false,
            checkList: [
                {
                    name: POLICY_AGREEMENT_LABELS.TERMS_OF_SERVICE,
                    value: props.intl.formatMessage({ id: 'terms_of_service' }),
                    checked: false,
                    type: POLICY_AGREEMENT_TYPE.Required,
                },
                {
                    name: POLICY_AGREEMENT_LABELS.PRIVACY_POLICY,
                    value: props.intl.formatMessage({ id: 'privacy_policy' }),
                    checked: false,
                    type: POLICY_AGREEMENT_TYPE.Required,
                },
                // {
                //     name: POLICY_AGREEMENT_LABELS.ALLOWED_EMAIL,
                //     value: props.intl.formatMessage({ id: 'allowed_email' }),
                //     checked: false,
                //     type: POLICY_AGREEMENT_TYPE.Optional,
                // },
            ],
        };

        this.timeOut = undefined;
    }

    componentWillUnmount() {
        const { authStore } = this.props;

        authStore.initExistEmailState();
        authStore.initSignUp();
    }

    getOAuthClientId = platformName => {
        this.props.authStore.getSocialOAuthClientId(platformName, OAUTH_OPERATION.SIGNUP, {
            requestAuthorizationCode: url => {
                window.location.href = url;
            },
        });
    };

    moveTo = path => {
        this.props.navigate(path);
    };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleClickLicenseKeyDialog = () => {
        // this.setState({ licenseKeyDialog: true });
        this.props?.orgStore?.changeLicenseSearchDialogOpenState(true);
    };

    handleCloseLicenseKeyDialog = () => {
        this.setState({ licenseKeyDialog: false });
    };

    onCheckBoxChange(checkName, isChecked) {
        let isAllChecked = checkName === 'all' && isChecked;
        let isAllUnChecked = checkName === 'all' && !isChecked;
        const checked = isChecked;

        const checkList = this.state.checkList.map((help, _index) => {
            if (isAllChecked || help.value === checkName) {
                return Object.assign({}, help, {
                    checked,
                });
            } else if (isAllUnChecked) {
                return Object.assign({}, help, {
                    checked: false,
                });
            }

            return help;
        });

        let isAllSelected = checkList.findIndex(item => item.checked === false) === -1 || isAllChecked;

        this.setState({
            checkList,
            isAllSelected,
        });
    }

    handleClickLogin = _e => {
        this.moveTo('/signin');
    };

    handleClickSignUp = () => {
        // const isAllowedEmail = this.state.checkList.find(check => check.name === POLICY_AGREEMENT_LABELS.ALLOWED_EMAIL).checked;
        const isAllowedEmail = false;
        this.props.authStore.signUp(isAllowedEmail, {
            openSignUpDialog: signUpSuccessUserEmail => this.moveTo(`/signup/email/authentication/${signUpSuccessUserEmail}`),
        });
    };

    changeSignUpEmail = e => {
        checkSpace(e);
        const { authStore } = this.props;
        if (authStore.isExistEmail !== undefined) {
            authStore.initExistEmailState();
        }

        this.setState({
            emailValidation: authStore.changeSignUpEmail(e.target.value),
        });

        if (this.timeOut) this.timeOut = undefined;

        this.timeOut = setTimeout(() => {
            this.checkExistEmail();
        }, 300);
    };

    changeSignUpPassword = e => {
        checkSpace(e);
        this.setState({
            passwordValidation: this.props.authStore.changeSignUpPassword(e.target.value),
        });
    };

    changeSignUpName = e => {
        this.setState({
            nameValidation: this.props.authStore.changeSignUpName(e.target.value),
        });
    };

    handleChangeSignUpLicenseKey = e => {
        const value = e.target.value;
        // const regex = /^[A-Za-z0-9-]+$/;
        if (!!value && !LICENSE_KEY_STR_PATTERN?.test(value)) {
            return;
        }

        this.props.authStore.changeSignUpLicenseKey(value);
    };

    onSubmit = () => {
        const recaptchaValue = this.recaptchaRef.current.getValue();
        this.props.onSubmit(recaptchaValue);
    };

    handleResendEmail = () => {
        const { authStore } = this.props;
        const email = authStore.signup.email;
        authStore.sendAuthenticationEmail(email, {
            successAction: () => ToastsStore.info('이메일을 재발송 하였습니다.', 1500),
            failedAction: () => ToastsStore.warning('이메일 발송이 되지 않았습니다. 다시 시도해주세요.', 1500),
        });
    };

    handleRecaptchaExpired = () => {
        this.props.authStore.changeCaptchaState(false);
    };

    checkExistEmail = () => {
        const { authStore } = this.props;
        if (this.state.emailValidation && authStore.signup.email) {
            const email = authStore.signup.email;

            authStore.requestCheckExistEmail(email);
        }
    };

    render() {
        const { classes, intl, authStore } = this.props;
        const {
            signup,
            isSignInAndUpResultDialogOpen,
            signInAndUpResultDialogMsgId,
            isEmailResendDialogOpen,
            closeSignInAndUpResultDialog,
            changeEmailResendDialogState,
            captcha,
            isExistEmail,
            convertedLicenseKey,
        } = authStore;
        const isAllowedLicenseKey = !!convertedLicenseKey;
        const canSignUp =
            signup.id !== '' &&
            this.state.emailValidation &&
            signup.password !== '' &&
            this.state.passwordValidation[1] &&
            signup.name !== '' &&
            this.state.nameValidation;
        const requireAgreements = this.state.checkList.filter(check => check.type === POLICY_AGREEMENT_TYPE.Required);
        const agreements = this.state.isAllSelected || requireAgreements.filter(check => check.checked).length === requireAgreements.length;

        console.log('[SignUp] input signup >> ', signup);
        return (
            <div className={classes.root}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <Typography className={classes.textButton}>이미 회원이신가요?</Typography>
                    <Button className={clsx(classes.iconButton, classes.textButton, classes.underline)} disableRipple onClick={this.handleClickLogin}>
                        로그인
                    </Button>
                </Box>
                <Box className={classes.loginBox}>
                    <Typography className={classes.loginText}>회원가입</Typography>
                    <FormControl className={clsx(classes.textField, !this.state.emailValidation && classes.errorTextField)} variant="outlined">
                        <OutlinedInput
                            inputProps={{
                                'aria-label': 'email input box',
                                maxLength: 254,
                            }}
                            id="outlined-email"
                            startAdornment={
                                <InputAdornment position="start">
                                    <MailOutlineIcon style={{ color: '#C2C2C2' }} />
                                </InputAdornment>
                            }
                            labelWidth={0}
                            placeholder="이메일"
                            onChange={e => this.changeSignUpEmail(e)}
                        />
                    </FormControl>
                    {!this.state.emailValidation && (
                        <FormHelperText id="component-error-text" className={classes.errorText}>
                            <FormattedMessage id={'msg.email_incorrect'} />
                        </FormHelperText>
                    )}

                    {isExistEmail && (
                        <FormHelperText id="component-error-text" className={classes.errorText}>
                            <FormattedMessage id={'msg.exist_email'} />
                        </FormHelperText>
                    )}

                    {/* 비밀번호 형식이 아니면*/}
                    <FormControl
                        className={clsx(
                            classes.textField,
                            !this.state.passwordValidation[0] !== this.state.passwordValidation[1] && classes.errorTextField,
                        )}
                        variant="outlined"
                    >
                        {/*<FormControl className={classes.textField} variant="outlined">*/}
                        <OutlinedInput
                            inputProps={{
                                'aria-label': 'password input box',
                                maxLength: 20,
                            }}
                            id="outlined-password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            startAdornment={
                                <InputAdornment position="start">
                                    <LoginPasswordIcon />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton className={classes.iconButton} onClick={this.handleClickShowPassword} disableRipple>
                                        {this.state.showPassword ? <LoginEyeIcon /> : <LoginEyeSlashIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={0}
                            placeholder="비밀번호"
                            onChange={this.changeSignUpPassword}
                            onPaste={e => {
                                e.preventDefault();
                                return false;
                            }}
                        />
                    </FormControl>

                    <PasswordCheckComponent passwordValidation={this.state.passwordValidation} />
                    <FormControl className={clsx(classes.textField, !this.state.nameValidation && classes.errorTextField)} variant="outlined">
                        <OutlinedInput
                            inputProps={{
                                'aria-label': 'name input box',
                                maxLength: 30,
                            }}
                            id="outlined-name"
                            startAdornment={
                                <InputAdornment position="start">
                                    <LoginUserIcon />
                                </InputAdornment>
                            }
                            labelWidth={0}
                            placeholder="이름"
                            onChange={this.changeSignUpName}
                            onPaste={e => {
                                e.preventDefault();
                                return false;
                            }}
                        />
                    </FormControl>
                    {!this.state.nameValidation && (
                        <FormHelperText id="component-error-text" className={classes.errorText}>
                            <FormattedMessage id={'msg.name_incorrect'} />
                        </FormHelperText>
                    )}

                    <FormControl className={classes.textField} variant="outlined">
                        <OutlinedInput
                            inputProps={{
                                'aria-label': 'name input box',
                                maxLength: 19,
                            }}
                            id="outlined-name"
                            startAdornment={
                                <InputAdornment position="start">
                                    <LicenseIcon style={{ width: 24 }} />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton className={classes.searchIconButton} onClick={this.handleClickLicenseKeyDialog} disableRipple>
                                        <MagnifyingGlassIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={0}
                            placeholder="라이선스 키 찾기"
                            value={signup?.licenseKey}
                            onChange={this.handleChangeSignUpLicenseKey}
                        />
                    </FormControl>
                    {signup?.licenseKey && !isAllowedLicenseKey && (
                        <FormHelperText id="component-error-text" className={classes.errorText}>
                            <FormattedMessage id={'msg.incorrect_license_key'} />
                        </FormHelperText>
                    )}

                    <LicenseKeyInfoBox />

                    <Box className={classes.checkBoxMargin}>
                        <CheckBoxList
                            classes={this.props.classes}
                            options={this.state.checkList}
                            isCheckedAll={this.state.isAllSelected}
                            onCheck={this.onCheckBoxChange.bind(this)}
                        />
                    </Box>

                    <Box display="flex" justifyContent="center">
                        <ReCAPTCHA
                            style={{
                                display: 'inline-block',
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                            ref={this.recaptchaRef}
                            theme="light"
                            // sitekey={ON_THE_LIVE_LOCAL_TEST_SITE_KEY}
                            // sitekey={ON_THE_LIVE_DEV_SITE_KEY}
                            sitekey={STUDY_LIVE_SITE_KEY}
                            onChange={this.props.authStore.verifyCaptcha}
                            onExpired={this.handleRecaptchaExpired}
                        />
                    </Box>

                    <Button
                        className={classes.buttonStyle}
                        disableRipple
                        onClick={this.handleClickSignUp}
                        disabled={!canSignUp || !agreements || !captcha || isExistEmail || isExistEmail === undefined || !isAllowedLicenseKey}
                    >
                        가입
                    </Button>

                    <Box className={classes.lineStyle} />
                    {/** Coming soon */}
                    {/*<SocialLoginComponent*/}
                    {/*    naver={'네이버로 시작하기'}*/}
                    {/*    kakao={'카카오로 시작하기'}*/}
                    {/*    facebook={'페이스북으로 시작하기'}*/}
                    {/*    google={'구글로 시작하기'}*/}
                    {/*    getOAuthClientId={platformName => this.getOAuthClientId(platformName)}*/}
                    {/*/>*/}
                </Box>
                <ConfirmDialog
                    open={isSignInAndUpResultDialogOpen}
                    title={intl.formatMessage({ id: 'notification' })}
                    msg={intl.formatMessage({ id: `${signInAndUpResultDialogMsgId}` })}
                    changeDialogOpen={isOpen => closeSignInAndUpResultDialog(isOpen)}
                />
                <EmailResendDialog
                    open={isEmailResendDialogOpen}
                    title={intl.formatMessage({ id: 'notification' })}
                    userEmail={this.props.authStore.signup.email}
                    changeDialogOpen={changeEmailResendDialogState}
                    resendEmail={this.handleResendEmail}
                />
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('authStore', 'orgStore')(injectIntl(observer(SignUp)))));
