import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as AsideUsersThreeIcon } from '../../../common/images/AsideUsersThreeIcon.svg';
import MobileSearchEmptyComponent from './MobileSearchEmptyComponent';
import { withRouter } from '../../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { ClassMainPath } from '../../../common/ClassMainPath';

const styles = theme => ({
    root: {
        '@media all and (min-width: 601px)': {
            marginTop: 30,
        },
        marginTop: 15,
    },
    roomCreateButton: {
        '@media all and (min-width: 601px)': {
            height: 340,
        },
        width: '49%',
        height: 220,
        background: '#fff',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        marginRight: '2%',
        marginBottom: 10,
        padding: 0,
        overflow: 'hidden',
        '&:hover': {
            background: '#fff',
        },
        '& span': {
            display: 'flex',
            flexDirection: 'column',
            height: 'inherit',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        '& img': {
            '@media all and (min-width: 601px)': {
                height: 160,
            },
            height: 78,
            width: '100%',
            objectFit: 'cover',
        },
        '&:nth-child(2n)': {
            marginRight: 0,
        },
    },
    landscapeRoomCreateButton: {
        width: 'calc((100% / 3) - 7px)',
        marginRight: 10,
        '&:nth-child(3n+0)': {
            marginRight: 0,
        },
        '&:nth-child(2n)': {
            marginRight: 10,
        },
    },
    roomTextBox: {
        '@media all and (min-width: 601px)': {
            height: 'calc(340px - 160px)',
            padding: '12px 20px 14px',
        },
        width: '100%',
        height: 'calc(220px - 78px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '10px',
        boxSizing: 'border-box',
    },
    chipBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 12,
    },
    chip: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.75rem',
            minWidth: 50,
            height: '21px !important',
        },
        minWidth: 'calc((100% / 3) - 12px)',
        height: '18px !important',
        backgroundColor: '#eee',
        color: '#656565',
        marginRight: 6,
        padding: '0px 7px',
        border: '1px solid #eee',
        fontSize: '0.625rem',
        boxSizing: 'border-box',
        borderRadius: 50,
        '&:last-child': {
            marginRight: 0,
        },
    },
    buttonText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.938rem',
        },
        width: '100%',
        fontSize: '0.813rem',
        color: '#000',
        fontWeight: 600,
    },
    buttonTitle: {
        width: '100%',
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
    },
    subText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.875rem',
        },
        fontSize: '0.688rem',
        color: '#000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        margin: '5px 0',
        textAlign: 'justify',
    },
    svgIcon: {
        marginRight: 5,
        width: 13,
        height: 13,
        '@media all and (min-width: 601px)': {
            width: 15,
            height: 15,
        },
    },
    buttonSubText: {
        fontSize: '0.625rem',
        color: '#828282',
        '@media all and (min-width: 601px)': {
            fontSize: '0.75rem',
        },
    },
});

class MobileSearchStudyComponent extends Component {
    render() {
        const { classes, studyGroupList, navigate, mobileLandscape, landscape, authStore } = this.props;
        const { isImageLoading } = this.props.classStore;
        return (
            <div
                className={classes.root}
                style={landscape && mobileLandscape ? { justifyContent: 'flex-start' } : { justifyContent: 'space-between' }}
            >
                {studyGroupList.length > 0 ? (
                    studyGroupList.map((g, i) => (
                        <Button
                            key={i}
                            className={
                                landscape && mobileLandscape
                                    ? clsx(classes.roomCreateButton, classes.landscapeRoomCreateButton)
                                    : classes.roomCreateButton
                            }
                            disableRipple
                            onClick={() => {
                                this.props.classStore.selectedGroup = g;
                                this.props.roomStore.initRoom();
                                this.props.postStore.initPostList();
                                this.props.orgStore.setGroupColor(g.group.color);
                                navigate(`/class/${g.group.id}/${ClassMainPath.todaySchedule}`);
                            }}
                        >
                            <img src={g.image} alt="room image" />
                            <Box display="flex" flexDirection="column" alignItems="flex-start" className={classes.roomTextBox}>
                                <Box display="flex" flexDirection="column" alignItems="flex-start" style={{ width: '100%' }}>
                                    <Box display="flex" alignItems="center" style={{ width: '100%' }}>
                                        <Box className={classes.chipBox}>
                                            {g.tagList.map((tag, i) => (
                                                <Typography key={i} className={classes.chip} noWrap>
                                                    {tag.name}
                                                </Typography>
                                            ))}
                                        </Box>
                                    </Box>
                                    <Typography className={clsx(classes.buttonText, classes.buttonTitle)}>{g.group.name}</Typography>
                                    <Typography className={classes.subText}>{g.group.comment}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <AsideUsersThreeIcon className={classes.svgIcon} />
                                    <Typography className={classes.buttonSubText}>멤버:{g.group.maxUsers}명</Typography>
                                </Box>
                            </Box>
                        </Button>
                    ))
                ) : (
                    <MobileSearchEmptyComponent loginUser={authStore.loginUser} />
                )}
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('classStore', 'roomStore', 'postStore', 'orgStore', 'authStore')(observer(MobileSearchStudyComponent))),
);
