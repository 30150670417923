import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MobileTopSearch from '../search/MobileTopSearch';
import MobileTopBar from './MobileTopBar';
import { PATH_UTIL } from '../../../common/util/path.util';

export const MOBILE_APP_BAR_MODE = {
    search: 'search',
    normal: 'normal',
};
function checkSearchMode(mode) {
    return mode === MOBILE_APP_BAR_MODE.search;
}

function MobileAppBar(props) {
    const [mode, setMode] = useState(MOBILE_APP_BAR_MODE.normal);
    const mainPathName = PATH_UTIL.getPath(window.location.pathname);

    const changeModeByPathName = useCallback(() => {
        if (mainPathName === 'search') {
            setMode(MOBILE_APP_BAR_MODE.search);
        }
    }, [mainPathName]);

    useEffect(() => {
        changeModeByPathName();
    }, []);

    const isSearchMode = useMemo(() => checkSearchMode(mode), [mode]);

    const handleChangeAppBarMode = mode => {
        if (MOBILE_APP_BAR_MODE[mode]) {
            setMode(MOBILE_APP_BAR_MODE[mode]);
        }
    };

    return <>{isSearchMode ? <MobileTopSearch changeMode={handleChangeAppBarMode} /> : <MobileTopBar changeMode={handleChangeAppBarMode} />}</>;
}

export default MobileAppBar;
