import React, { useCallback, useEffect } from 'react';
import { inject, observer } from 'mobx-react';

const WithInfiniteScroll = React.forwardRef((props, ref) => {
    const { paginationStore, isLoading, noMore, fetchData } = props;
    const page = paginationStore.page;
    const options = {
        root: null,
        rootMargin: '20px',
        threshold: 1.0,
    };

    useEffect(() => {
        console.log('[WithInfiniteScroll] initialize pagination');
        paginationStore.initPagination();
    }, []);

    const handleObserver = useCallback(
        entries => {
            if (isLoading) return;
            const target = entries[0];
            console.log('target.isIntersecting : >>', target.isIntersecting);
            console.log('isLoading : >>', isLoading);
            console.log('noMore : >>', noMore);
            if (target.isIntersecting && !noMore) {
                console.log('is intersecting');
                const nextPage = page + 1;
                paginationStore.changePage(nextPage);
                // setPage(prev => prev + 1);
            }
        },
        [isLoading, noMore],
    );

    useEffect(() => {
        // if (ref.current === null || !ref.current) {
        //     return;
        // }
        console.log('[WithInfiniteScroll] load some data ref = ', ref);
        // const page = props.paginationStore.page;
        fetchData(page);
    }, [page]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, options);
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, [ref.current, handleObserver]);

    return <div>{props.children}</div>;
});

export default inject('paginationStore')(observer(WithInfiniteScroll));
