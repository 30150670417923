import React, { Component } from 'react';
import clsx from 'clsx';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputLabel,
    OutlinedInput,
    TextareaAutosize,
    TextField,
    Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { withStyles } from '@material-ui/core/styles';
import SelectSchedule from './calendar/SelectSchedule';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import { withRouter } from '../../components/WithRouter';
import { TagType } from '../../stores/ClassStore';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 490,
            padding: '24px 0 24px 24px',
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '&::-webkit-scrollbar': {
                width: '15px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#dbdbdb',
                borderRadius: '10px',
                backgroundClip: 'padding-box',
                border: '5px solid transparent',
            },
            '&::-webkit-scrollbar-track': {
                background: 'transparent',
                marginTop: 5,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 30,
        paddingRight: 24,
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    textField: {
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #d9dbde',
            },
        '& .MuiOutlinedInput-root': {
            borderRadius: 7,
            '&:hover': {
                borderColor: '#d9dbde',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px 10px',
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#a3a8af',
                opacity: 1,
                fontWeight: 300,
            },
        },
        '@media all and (max-width: 600px)': {
            '& .MuiOutlinedInput-input': {
                padding: '10px 10px',
                fontSize: '0.813rem',
            },
        },
        width: '100%',
    },
    textFieldMargin: {
        margin: '0 0 0 20px',
        '@media all and (max-width: 600px)': {
            margin: '0 0 0 5px',
        },
    },
    textareaStyle: {
        width: '100%',
        margin: '20px 20px 0px 0px',
        border: '1px solid #d9dbde',
        borderRadius: 7,
        padding: '10px 10px',
        overflowY: 'auto',
        resize: 'none',
        boxSizing: 'border-box',
        fontSize: '1rem',
        color: '#333',
        background: 'transparent',
        '&::placeholder': {
            color: '#a3a8af',
            opacity: 1,
            fontWeight: 300,
        },
        '&:focus': {
            outline: 'none',
        },
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#bebebe',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '3px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
        '@media all and (max-width: 600px)': {
            margin: '10px 0',
            fontSize: '0.813rem',
        },
    },
    boxMargin: {
        marginTop: 30,
        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            marginRight: 0,
        },
        '@media all and (max-width: 600px)': {
            marginTop: 20,
        },
    },
    marginBottom: {
        marginBottom: 15,
        '@media all and (max-width: 600px)': {
            marginBottom: 5,
        },
    },
    textStyle: {
        fontWeight: 600,
        fontSize: '1.125rem',
        color: '#333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    textStyleRequired: {
        '&:after': {
            content: '"*"',
            color: '#ff0000',
        },
    },
    checkedBox: {
        '& .MuiIconButton-root': {
            padding: 0,
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '1rem',
            color: '#333',
            marginLeft: 10,
            '@media all and (max-width: 600px)': {
                fontSize: '0.813rem',
                marginLeft: 5,
            },
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.5rem',
            '@media all and (max-width: 600px)': {
                fontSize: '1rem',
            },
        },
        '& .Mui-checked': {
            color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
    guestCheckText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#333',
    },
    autoCompleteBox: {
        '& .MuiInputBase-root': {
            padding: '2.5px 6px',
            '@media all and (max-width: 600px)': {
                paddingRight: '25px !important',
            },
        },
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #d9dbde',
            },
        '& .MuiAutocomplete-input': {
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#a3a8af',
                opacity: 1,
                fontWeight: 300,
            },
        },
        '& .MuiChip-root': {
            background: 'transparent',
            '& .MuiChip-label': {
                color: '#333',
                fontSize: '1rem',
                paddingLeft: 0,
                paddingRight: 5,
                '@media all and (max-width: 600px)': {
                    fontSize: '0.813rem',
                },
            },
            '& svg': {
                display: 'none',
            },
        },
        '& .MuiAutocomplete-popupIndicator': {
            display: 'none',
        },
        '@media all and (max-width: 600px)': {
            '& .MuiAutocomplete-input': {
                fontSize: '0.813rem',
            },
        },
    },
    option: {
        fontSize: '0.875rem',
        color: '#333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    buttonStyle: {
        marginTop: 26,
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        color: '#fff',
        fontSize: '1rem',
        padding: '12px 0',
        borderRadius: 7,
        '&:hover': {
            fontWeight: 800,
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            marginTop: 20,
            fontSize: '0.75rem',
            padding: '6px 0',
            borderRadius: 4,
        },
    },
    guestButtonStyle: {
        color: '#000000',
        background: '#fff',
        border: '1px solid #a3a8af',
        fontSize: '0.8rem',
        height: 40,
        minWidth: 43,
    },
    inputStyle: {
        width: '100%',
        marginRight: 10,
        padding: 10,
        borderRadius: 6,
        background: '#fff',
        border: '1px solid #d9dbde',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        color: '#0d0d0d',
        '&:focus': {
            outline: 'none !important',
        },
        '&::placeholder': {
            color: '#a3a8af',
            opacity: 1,
            fontWeight: 300,
        },
    },
    guestInputStyle: {
        width: '25%',
        marginRight: 3,
        background: '#fff',
        borderRadius: 5,
        border: '1px solid #d9dbde',
        padding: '0 8px',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.938rem',
        color: '#0d0d0d',
        '&:focus': {
            outline: 'none !important',
        },
        '&::placeholder': {
            color: '#a3a8af',
            opacity: 1,
            fontWeight: 300,
        },
    },
    firstGuestInputStyle: {
        marginRight: '46px',
    },
    guestNumberInputStyle: {
        width: 48,
        padding: 10,
        borderRadius: 6,
        background: '#f8f8f8',
        textAlign: 'center',
        border: '0 none',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        color: '#0d0d0d',
        boxSizing: 'border-box',
        '&:focus': {
            outline: 'none !important',
        },
        '&::placeholder': {
            color: '#a3a8af',
            opacity: 1,
            fontWeight: 300,
        },
        '&::-webkit-inner-spin-button ': {
            appearance: 'none',
            '-moz-appearance': 'none',
            '-webkit-appearance': 'none',
        },
    },
    emailInputStyle: {
        width: '100%',
    },
    lastEmailInputStyle: {
        paddingBottom: 0,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    guestIconButton: {
        // background: '#bebebe',
        padding: 0,
        margin: '0 10px',
        '&:hover': {
            background: 'transparent',
        },
    },
    scrollBox: {
        maxHeight: 'calc(100vh - 230px )',
        overflowY: 'auto',
        paddingRight: 24,
        '&::-webkit-scrollbar': {
            width: '15px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#bebebe',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '5px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
    },
    guestBox: {
        width: '100%',
        maxHeight: 270,
        overflowY: 'scroll',
        border: '1px solid #a3a8af',
        borderRadius: 7,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        background: '#EDEDED',
        '&::-webkit-scrollbar': {
            width: '15px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#fff',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '5px solid transparent',
        },
    },
    guestNumberBox: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    guestDetailBox: {
        display: 'flex',
    },
    helperText: {
        letterSpacing: '-.24px',
        fontSize: '0.813rem',
        fontWeight: 600,
        // color: '#ff0000',
    },
    helperText2: {
        letterSpacing: '-.24px',
        fontSize: '1rem',
        fontWeight: 500,
        color: '#333',
    },
    allowGuestStyle: {
        margin: '10px 0 30px 0',
        '& p': {
            color: '#a3a8af',
            fontSize: '0.813rem',
            marginBottom: 4,
            '&:before': {
                content: '"*"',
            },
        },
    },
    isLive: {
        opacity: 0.5,
    },
});

class ScheduleRegistrationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isRequired: {
                title: undefined,
                contents: undefined,
                tag: undefined,
            },
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dialogOpen !== this.props.dialogOpen && this.props.dialogOpen) {
            const { loginUser } = this.props.authStore;
            const { selectedGroupDetail } = this.props.classStore;

            this.props.classStore.getAllGroupUsers(loginUser.email, selectedGroupDetail.group.id);
        }
    }

    createRoom = event => {
        event.stopPropagation();
        this.props.scrollMove();

        this.props.roomStore.createRoom(
            this.props.authStore.loginUser,
            this.props.classStore.selectedGroupDetail.group.id,
            {
                dialogClose: () => this.props.handleClose(),
                getTagList: groupId => this.props.classStore.getTagList(groupId, TagType.ROOM),
            },
            this.props.intl,
            this.props.isModify,
        );
    };

    changeIsAllowGuest = e => {
        this.props.roomStore.changeIsAllowGuest(e.target.checked);
    };

    handleCheckInputsRequired = e => {
        console.log('handleCheckInputsRequired', e.target.name);
        const {
            target: { name },
        } = e;

        if (name) {
            this.props.roomStore.setCreateRoomRequiredInputType(name);

            this.setState(prevState => ({
                isRequired: { ...prevState.isRequired, [name]: this.props.roomStore.createRoomRequiredValidationByType },
            }));
        }
    };

    render() {
        const { classes, intl, classStore, roomStore, isModify, selectedRoomIsLive } = this.props;
        const { room, isRoomCreateValidation } = roomStore;
        const { selectedGroupDetail, allGroupUsers } = classStore;
        console.log('render schedule registration comp group users = ', selectedGroupDetail.lmsUser);
        // const attendantsList = selectedGroupDetail.lmsUser
        //     .filter(user => user.userId !== this.props.authStore.loginUser.id)
        //     .map(user => {
        //         user.isSelect = room.members.find(m => m.userId === user.userId) !== undefined;
        //         return user;
        //     })
        //     .concat(
        //         selectedGroupDetail.teamList
        //             .filter(team => team.teamUserList.length > 0)
        //             .map(team => {
        //                 team.isSelect = room.teams.find(t => t.teamId === team.teamId) !== undefined;
        //                 return team;
        //             }),
        //     );

        const attendantsList = allGroupUsers
            .filter(user => user.userId !== this.props.authStore.loginUser.id)
            .map(user => {
                user.isSelect = room.members.find(m => m.userId === user.userId) !== undefined;
                return user;
            })
            .concat(
                selectedGroupDetail.teamList
                    .filter(team => team.teamUserList.length > 0)
                    .map(team => {
                        team.isSelect = room.teams.find(t => t.teamId === team.teamId) !== undefined;
                        return team;
                    }),
            );

        console.log('attendantsList', attendantsList);
        const { isRequired } = this.state;
        return (
            <div className={classes.root}>
                <Dialog open={this.props.dialogOpen} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.titleBox}>
                        <Typography className={classes.titleText}>{!isModify ? '수업 등록' : '수업 수정'}</Typography>
                        <IconButton className={classes.iconButton} disableRipple onClick={this.props.handleClose}>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>

                    <Box display="flex" flexDirection="column" className={classes.scrollBox}>
                        <Box style={{ width: '100%' }}>
                            <FormControl className={classes.textField} variant="outlined">
                                <OutlinedInput
                                    value={room.name}
                                    inputProps={{ 'aria-label': 'title input box', maxLength: 100 }}
                                    id="outlined-title"
                                    name={'title'}
                                    labelWidth={0}
                                    placeholder="수업 제목 (최대 100자 이내)"
                                    onChange={e => this.props.roomStore.changeRoomTitle(e.target.value)}
                                    onBlur={e => this.handleCheckInputsRequired(e)}
                                    aria-describedby="outlined-title-helper-text"
                                />
                            </FormControl>
                            {isRequired.title !== undefined && !isRequired.title && (
                                <FormHelperText id="outlined-title-helper-text" style={{ color: '#ff0000' }}>
                                    필수 정보입니다.
                                </FormHelperText>
                            )}
                        </Box>
                        <Box style={{ width: '100%' }}>
                            <TextareaAutosize
                                id={'contents-text'}
                                aria-describedby={'contents-text-helper-text'}
                                value={room.comment}
                                name="contents"
                                minRows={5}
                                maxRows={5}
                                maxLength={1000}
                                aria-label="content input box"
                                className={classes.textareaStyle}
                                placeholder="수업 설명 (최대 1000자 이내) "
                                onChange={e => this.props.roomStore.changeRoomComment(e.target.value)}
                                onBlur={e => this.handleCheckInputsRequired(e)}
                            />
                            {isRequired.contents !== undefined && !isRequired.contents && (
                                <FormHelperText id="contents-text-helper-text" style={{ color: '#ff0000' }}>
                                    필수 정보입니다.
                                </FormHelperText>
                            )}
                        </Box>
                        {/* 태그 */}
                        <Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                                {Array.from({ length: 3 }, (_, i) => (
                                    <FormControl
                                        className={clsx(classes.textField, i !== 0 && classes.textFieldMargin)}
                                        variant="outlined"
                                        key={i}
                                        onBlur={e => this.handleCheckInputsRequired(e)}
                                    >
                                        <OutlinedInput
                                            value={i < room.tagList.length && room.tagList[i] ? room.tagList[i].name : ''}
                                            inputProps={{ 'aria-label': 'room tag input box', maxLength: 25 }}
                                            id={`${i}`}
                                            name={'tag'}
                                            labelWidth={0}
                                            placeholder="태그(최대 25자)"
                                            onChange={e => this.props.roomStore.changeRoomTag(e)}
                                            autoComplete={'false'}
                                        />
                                    </FormControl>
                                ))}
                            </Box>
                            {isRequired.tag !== undefined && !isRequired.tag && (
                                <FormHelperText id="tag-helper-text" style={{ color: '#ff0000' }}>
                                    필수 정보입니다.
                                </FormHelperText>
                            )}
                        </Box>

                        {/* 일시 */}
                        <Box className={classes.boxMargin}>
                            <Typography className={clsx(classes.textStyle, classes.marginBottom, classes.textStyleRequired)}>일시</Typography>
                            <SelectSchedule classTab={this.props.classTab} selectedRoomIsLive={selectedRoomIsLive} />
                        </Box>
                        {/* 보안 */}
                        {/*<Box className={classes.boxMargin}>*/}
                        {/*    <Typography className={clsx(classes.textStyle, classes.marginBottom)}>*/}
                        {/*        <FormattedMessage id="security" />*/}
                        {/*    </Typography>*/}
                        {/*    <Box display="flex" justifyContent="space-between" alignItems="center">*/}
                        {/*        <FormControlLabel*/}
                        {/*            control={<Checkbox icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} checked={room.isAllowGuest} />}*/}
                        {/*            label={`${intl.formatMessage({ id: 'allow_guest' })}`}*/}
                        {/*            className={classes.checkedBox}*/}
                        {/*            onChange={e => this.changeIsAllowGuest(e)}*/}
                        {/*        />*/}
                        {/*        {room.isAllowGuest && (*/}
                        {/*            <Box className={classes.guestNumberBox}>*/}
                        {/*                <Typography className={classes.textStyleRequired}>*/}
                        {/*                    <FormattedMessage id="guest_number" />*/}
                        {/*                </Typography>*/}
                        {/*                <IconButton className={classes.guestIconButton} onClick={() => roomStore.removeGuest()} disableRipple>*/}
                        {/*                    <MinusOutlineIcon />*/}
                        {/*                </IconButton>*/}
                        {/*                <input*/}
                        {/*                    name="Numbers"*/}
                        {/*                    type="number"*/}
                        {/*                    id="Numbers"*/}
                        {/*                    min={1}*/}
                        {/*                    max={199}*/}
                        {/*                    value={room.guestNum}*/}
                        {/*                    onKeyUp={e => (e.target.value = e.target.value.replace(/[^0-9]/g, ''))}*/}
                        {/*                    onBlur={e =>*/}
                        {/*                        room.guestNum === '' || room.guestNum === '0'*/}
                        {/*                            ? roomStore.initGuestList()*/}
                        {/*                            : roomStore.changeGuests(e.target.value)*/}
                        {/*                    }*/}
                        {/*                    placeholder={intl.formatMessage({ id: 'msg.add_or_excel' })}*/}
                        {/*                    className={classes.guestNumberInputStyle}*/}
                        {/*                    onChange={e => roomStore.changeGuestNum(e.target.value)}*/}
                        {/*                />*/}
                        {/*                <IconButton className={classes.guestIconButton} onClick={() => roomStore.createGuestList()} disableRipple>*/}
                        {/*                    <PlusOutlineIcon />*/}
                        {/*                </IconButton>*/}
                        {/*            </Box>*/}
                        {/*        )}*/}
                        {/*    </Box>*/}
                        {/*    {room.isAllowGuest && (*/}
                        {/*        <Box className={classes.allowGuestStyle}>*/}
                        {/*            <Typography>게스트에게 이메일을 발송하려면 ‘이름과 이메일’을 입력하세요.</Typography>*/}
                        {/*            <Typography>초대 가능한 게스트는 최대 200명입니다.</Typography>*/}
                        {/*            <Typography>*/}
                        {/*                <FormattedMessage id="msg.notice_guest_info" />*/}
                        {/*            </Typography>*/}
                        {/*            <Typography>*/}
                        {/*                이름은 최대 50자까지 입력 가능합니다.*/}
                        {/*                <span style={{ display: 'flex', paddingLeft: 5 }}>(‘게스트1’과 같은 기본값은 사용 불가)</span>*/}
                        {/*            </Typography>*/}
                        {/*            /!*<Typography style={{ margin: '8px 0' }}>*!/*/}
                        {/*            /!*    <span style={{ color: '#ff0000' }}>*</span>*!/*/}
                        {/*            /!*    <FormattedMessage id="nickname" /> : <FormattedMessage id="msg.validation_nickname" />*!/*/}
                        {/*            /!*</Typography>*!/*/}
                        {/*            /!*<Typography style={{ margin: '8px 0' }}>*!/*/}
                        {/*            /!*    <span style={{ color: '#ff0000' }}>*</span>*!/*/}
                        {/*            /!*    <FormattedMessage id="msg.notice_unavailable_nickname" />*!/*/}
                        {/*            /!*</Typography>*!/*/}
                        {/*        </Box>*/}
                        {/*    )}*/}
                        {/*    {room.guests.length > 0 && (*/}
                        {/*        <Box className={classes.guestBox}>*/}
                        {/*            {room.guests.map((guest, i) => (*/}
                        {/*                <Box key={i} style={{ margin: '10px 10px 10px 0' }}>*/}
                        {/*                    <Box className={classes.guestDetailBox}>*/}
                        {/*                        <Typography*/}
                        {/*                            style={{ margin: '10px 0 8px 0', display: 'inline-block', minWidth: '34px', textAlign: 'center' }}*/}
                        {/*                        >*/}
                        {/*                            {i + 1}*/}
                        {/*                        </Typography>*/}
                        {/*                        <input*/}
                        {/*                            id={`guestNickName${i}`}*/}
                        {/*                            name={'nickName'}*/}
                        {/*                            type="text"*/}
                        {/*                            maxLength="50"*/}
                        {/*                            placeholder={intl.formatMessage({ id: 'name' })}*/}
                        {/*                            onChange={e => roomStore.changeGuestNickName(e.target.value.trim(), i)}*/}
                        {/*                            value={guest.name}*/}
                        {/*                            className={classes.guestInputStyle}*/}
                        {/*                        />*/}
                        {/*                        <input*/}
                        {/*                            id={`guestEmail${i}`}*/}
                        {/*                            name={'email'}*/}
                        {/*                            type="email"*/}
                        {/*                            maxLength="50"*/}
                        {/*                            placeholder={*/}
                        {/*                                intl.formatMessage({ id: 'email' }) +*/}
                        {/*                                '( ' +*/}
                        {/*                                intl.formatMessage({ id: 'msg.notice_send_email' }) +*/}
                        {/*                                ' )'*/}
                        {/*                            }*/}
                        {/*                            onChange={e => roomStore.changeGuestEmail(e.target.value.trim(), i)}*/}
                        {/*                            value={guest.email || ''}*/}
                        {/*                            className={clsx(*/}
                        {/*                                classes.guestInputStyle,*/}
                        {/*                                classes.emailInputStyle,*/}
                        {/*                                i === 0 && classes.firstGuestInputStyle,*/}
                        {/*                            )}*/}
                        {/*                        />*/}
                        {/*                        {i !== 0 && (*/}
                        {/*                            <Button className={classes.guestButtonStyle} onClick={() => roomStore.deleteGuest(i)}>*/}
                        {/*                                <FormattedMessage id="delete" />*/}
                        {/*                            </Button>*/}
                        {/*                        )}*/}
                        {/*                    </Box>*/}
                        {/*                </Box>*/}
                        {/*            ))}*/}
                        {/*        </Box>*/}
                        {/*    )}*/}
                        {/*</Box>*/}
                        {/* 참석자 */}
                        <Box className={classes.boxMargin}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.marginBottom}>
                                <Typography className={clsx(classes.textStyle, classes.marginBottom)}>
                                    <FormattedMessage id="attendees" />
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            // icon={<UnCheckedIcon />}
                                            // checkedIcon={<CheckedIcon />}
                                            checked={roomStore.room.all}
                                            onChange={e => this.props.roomStore.changeIsAll(e.target.checked)}
                                            disabled={selectedRoomIsLive}
                                        />
                                    }
                                    label={`${intl.formatMessage({ id: 'all_members' })}`}
                                    className={clsx(classes.checkedBox, selectedRoomIsLive && classes.isLive)}
                                />
                            </Box>

                            <Autocomplete
                                multiple
                                id="multiple-limit-tags"
                                value={this.props.roomStore.room.members.concat(this.props.roomStore.room.teams)}
                                disabled={roomStore.room.all || selectedRoomIsLive}
                                options={attendantsList}
                                classes={{
                                    option: classes.option,
                                }}
                                noOptionsText={intl.formatMessage({ id: 'no_search_result' })}
                                loadingText={intl.formatMessage({ id: 'searching' })}
                                getOptionLabel={option => {
                                    if (option.teamId) {
                                        return `#${option.name}`;
                                    } else {
                                        return `@${option.name}`;
                                    }
                                }}
                                groupBy={option => {
                                    if (option.teamId) {
                                        return `${intl.formatMessage({ id: 'group' })}`;
                                    } else {
                                        return `${intl.formatMessage({ id: 'member' })}`;
                                    }
                                }}
                                // isOptionEqualToValue={(option, value) => {
                                //     if (option.teamId) {
                                //         return option.teamId === value.teamId;
                                //     } else {
                                //         return option.userId === value.userId;
                                //     }
                                // }}
                                getOptionDisabled={option => option.isSelect}
                                filterSelectedOptions={false}
                                size="small"
                                renderInput={params => {
                                    const placeHolderMsg = this.props.roomStore.room.all
                                        ? '해당 모임의 모든 멤버'
                                        : !this.props.roomStore.room.members.length && !this.props.roomStore.room.teams.length
                                        ? '참석자 닉네임을 입력 또는 선택해주세요.'
                                        : '';
                                    return <TextField {...params} variant="outlined" label="" placeholder={placeHolderMsg} />;
                                }}
                                className={classes.autoCompleteBox}
                                onChange={(e, value, option) => {
                                    this.props.roomStore.changeRoomMember(value);
                                }}
                            />
                            {/*<FormHelperText className={classes.helperText}>* 리더와 관리자에게는 수업이 항상 공개됩니다</FormHelperText>*/}
                        </Box>
                        {/*<Box className={classes.boxMargin}>*/}
                        {/*    <FormControlLabel*/}
                        {/*        control={*/}
                        {/*            <Checkbox*/}
                        {/*                icon={<UnCheckedIcon />}*/}
                        {/*                checkedIcon={<CheckedIcon />}*/}
                        {/*                checked={roomStore.room.invitationEmail}*/}
                        {/*                onChange={e => this.props.roomStore.changeIsRoomInvitationEmail(e.target.checked)}*/}
                        {/*            />*/}
                        {/*        }*/}
                        {/*        label={`${intl.formatMessage({ id: 'send_invitation_email' })}`}*/}
                        {/*        className={classes.checkedBox}*/}
                        {/*    />*/}
                        {/*</Box>*/}
                        <Button className={classes.buttonStyle} disabled={!isRoomCreateValidation} disableRipple onClick={this.createRoom}>
                            게시
                        </Button>
                    </Box>
                </Dialog>

                <ConfirmDialog
                    open={this.props.roomStore.openConfirmDialog}
                    title={intl.formatMessage({ id: 'notification' })}
                    msg={this.props.roomStore.errMsg}
                    changeDialogOpen={() => this.props.roomStore.closeConfirmDialog()}
                />
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('authStore', 'classStore', 'roomStore')(injectIntl(observer(ScheduleRegistrationComponent)))));
