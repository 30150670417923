import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { configure } from 'mobx';
import { Provider as MobxProvider } from 'mobx-react';
import { ThemeProvider } from '@material-ui/core/styles';
import configureTheme from './configureTheme';
import configureServerContextPath from './configureServerContextPath';
import configureStore from './stores/ConfigureStore';
import { BrowserRouter } from 'react-router-dom';
import { PATH_UTIL } from './common/util/path.util';

configure({ enforceActions: 'never' });
const serverContextPath = configureServerContextPath(false);
const originUrl = PATH_UTIL.getOriginURL();

if (process.env.NODE_ENV === 'production' && !originUrl.includes('slive2-dev')) {
    console.log = () => {};
}

const stores = configureStore(serverContextPath);
const themeConfigs = stores.orgStore.orgConfigs;
const themeImages = stores.orgStore.orgImages;
const theme = configureTheme(themeConfigs, themeImages);

ReactDOM.render(
    <BrowserRouter>
        <MobxProvider {...stores}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </MobxProvider>
    </BrowserRouter>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
