import { makeAutoObservable } from 'mobx';
import { base64StringToBlob } from 'blob-util';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { validateFileExtension, validateFileOrFolderName } from '../common/Validation';

const logPrefix = ' [ ClassFileStore ] ';

const FILE_API_URL = '/api/v1/files';

export const FILE_STORE_MESSAGE_ID = {
    Default: { empty: 'default_empty' },
    Error: {
        not_allowed_file_format: 'msg.error_not_allow_file_format',
        not_allowed_file_name: 'msg.not_allowed_file_name',
        is_over_sized_file: 'msg.is_over_sized_file',
        internal_server_error: 'msg.internal_server_error',
    },
};

export const FILE_LOAD_STATE = {
    Closed: 'Closed',
    Downloading: 'Downloading',
    Downloaded: 'Downloaded',
    DownloadFailed: 'DownloadFailed',
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
    Uploading: 'Uploading',
    Uploaded: 'Uploaded',
    UploadFailed: 'UploadFailed',
};
export const FILE_POSTED_TYPE = {
    Folder: 'folder',
    Post: 'post',
    Comment: 'comment',
    Reference: 'reference',
    Avatar: 'avatar',
    Cover: 'cover',
};

const EmptyModifyFileInfo = {
    groupId: 0,
    postedTypeName: '',
    postedTypeId: 0,
    fileId: 0,
    fileName: '',
    modifiedFileName: '',
};

const EmptyDeleteFileInfo = {
    groupId: 0,
    postedTypeName: '',
    postedTypeId: 0,
    fileId: 0,
    fileName: '',
};

const EmptyDownloadFileInfo = {
    groupId: 0,
    postedTypeName: '',
    postedTypeId: 0,
    fileId: 0,
};

const AllowedUploadFileNameLength = 256;

export default class ClassFileStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    editFileName = '';
    selectedFileId = 0;
    fileUploadState = FILE_LOAD_STATE.Closed;
    fileList = [];
    upload = { file: '' };
    isOverSize = false;
    modifyFileInfo = { ...EmptyModifyFileInfo };
    deleteFileInfo = { ...EmptyDeleteFileInfo };
    fileLoadResultDialogMsgId = FILE_STORE_MESSAGE_ID.Default.empty;
    fileDownloadState = FILE_LOAD_STATE.Closed;

    initFileLoadResult = () => {
        this.fileUploadState = FILE_LOAD_STATE.Closed;
        this.fileLoadResultDialogMsgId = FILE_STORE_MESSAGE_ID.Default.empty;
    };

    initClassFileStore = () => {
        this.selectedFileId = 0;
        this.editFileName = '';
        this.upload.file = '';
        this.isOverSize = false;
        this.modifyFileInfo = { ...EmptyModifyFileInfo };
        this.deleteFileInfo = { ...EmptyDeleteFileInfo };
        this.fileUploadState = FILE_LOAD_STATE.Closed;
        this.fileLoadResultDialogMsgId = FILE_STORE_MESSAGE_ID.Default.empty;
    };

    initEditFile = () => {
        console.log('do initEditFile');
        this.selectedFileId = 0;
        this.editFileName = '';
        this.modifyFileInfo = { ...EmptyModifyFileInfo };
    };

    initDeleteFile = () => {
        this.selectedFileId = 0;
        this.deleteFileInfo = { ...EmptyDeleteFileInfo };
    };

    changeDeleteFolderId = id => {
        this.deleteId = id;
    };

    changeEditFileName = fileName => {
        this.editFileName = fileName;
    };

    changeSelectedFileId = selectedId => {
        this.selectedFileId = selectedId;
    };

    changeFileLoadResultDialogMsgId = id => {
        this.fileLoadResultDialogMsgId = id;
    };

    changeFileUploadState = state => {
        this.fileUploadState = state;
    };

    setOverSize = overSize => (this.isOverSize = overSize);

    setModifyFileInfo = (groupId, postedTypeName, postedTypeId, fileId, fileName, modifiedFileName) => {
        this.modifyFileInfo.groupId = groupId;
        this.modifyFileInfo.postedTypeName = postedTypeName;
        this.modifyFileInfo.postedTypeId = postedTypeId;
        this.modifyFileInfo.fileId = fileId;
        this.modifyFileInfo.fileName = fileName;
        this.modifyFileInfo.modifiedFileName = modifiedFileName;
    };

    setDeleteFileInfo = (groupId, postedTypeName, postedTypeId, fileId, fileName) => {
        this.deleteFileInfo.groupId = groupId;
        this.deleteFileInfo.postedTypeName = postedTypeName;
        this.deleteFileInfo.postedTypeId = postedTypeId;
        this.deleteFileInfo.fileId = fileId;
        this.deleteFileInfo.fileName = fileName;
    };

    changeUploadFile = file => {
        this.upload.file = file;
    };

    get isDownLoading() {
        return this.fileDownloadState === FILE_LOAD_STATE.Downloading;
    }

    get isFileLoadResultDialogOpen() {
        // return this.fileUploadState === FILE_LOAD_STATE.Uploaded || this.fileUploadState === FILE_LOAD_STATE.UploadFailed;
        return this.fileUploadState === FILE_LOAD_STATE.UploadFailed;
    }

    get isUploading() {
        return this.fileUploadState === FILE_LOAD_STATE.Uploading;
    }

    get isUploadable() {
        return this.upload.file !== undefined && this.upload.file !== null && this.upload.file !== '';
    }

    get isUploadFailed() {
        return this.fileUploadState === FILE_LOAD_STATE.UploadFailed;
    }

    *uploadFile(groupId, userEmail, postedType, postedTypeId, callbacks) {
        this.fileUploadState = FILE_LOAD_STATE.Uploading;

        if (this.isOverSize) {
            this.changeFileLoadResultDialogMsgId(FILE_STORE_MESSAGE_ID.Error.is_over_sized_file);
            this.fileUploadState = FILE_LOAD_STATE.UploadFailed;
            return false;
        }

        if (!validateFileExtension(this.upload.file.name)) {
            this.changeFileLoadResultDialogMsgId(FILE_STORE_MESSAGE_ID.Error.not_allowed_file_format);
            this.fileUploadState = FILE_LOAD_STATE.UploadFailed;
            return false;
        }

        if (this.upload.file.name.length > AllowedUploadFileNameLength) {
            this.changeFileLoadResultDialogMsgId(FILE_STORE_MESSAGE_ID.Error.not_allowed_file_name);
            this.fileUploadState = FILE_LOAD_STATE.UploadFailed;
            return false;
        }

        try {
            const param = new FormData();

            const fileData = Buffer.from(yield this.upload.file.arrayBuffer()).toString('base64');

            const blobFile = base64StringToBlob(fileData, 'application/octet-stream');

            param.append('files', blobFile, this.upload.file.name);

            console.log('uploadFile : >>', fileData);
            console.log('uploadFile : >>', groupId, userEmail, postedType, postedTypeId);

            yield axios.post(this.serverContextPath + `${FILE_API_URL}/${groupId}/${userEmail}/${postedType}/${postedTypeId}`, param);

            this.upload.file = '';

            callbacks && callbacks.getGroupFolderAndFiles();
            this.fileUploadState = FILE_LOAD_STATE.Uploaded;
        } catch (e) {
            this.fileUploadState = FILE_LOAD_STATE.UploadFailed;
            if (e.response) {
                console.log(logPrefix, 'Failed uploadFile', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed uploadFile', e.request);
            } else {
                console.log(logPrefix, 'Failed uploadFile', e.message);
            }
            this.changeFileLoadResultDialogMsgId(FILE_STORE_MESSAGE_ID.Error.internal_server_error);
        }
    }

    *downloadFile(groupId, postedType, postedTypeId, fileId, fileName) {
        this.fileDownloadState = FILE_LOAD_STATE.Downloading;
        console.log(logPrefix, `Start...downloadFile...groupId=${groupId}, postedType=${postedType}, postedTypeId=${postedTypeId}, fileId=${fileId}`);
        const params = { ...EmptyDownloadFileInfo };
        params.groupId = groupId;
        params.postedTypeName = postedType;
        params.postedTypeId = postedTypeId;
        params.fileId = fileId;
        console.log(logPrefix, 'params  : >>', { ...params });

        if (window.flutter_inappwebview) {
            let url = `${FILE_API_URL}/download`;

            url += `?groupId=${groupId}`;
            url += `&postedTypeName=${postedType}`;
            url += `&postedTypeId=${postedTypeId}`;
            url += `&fileId=${fileId}`;

            window.flutter_inappwebview.callHandler('downloadFileLMS', url, fileName);

            this.fileDownloadState = FILE_LOAD_STATE.Downloaded;

            return;
        }

        try {
            // const response = yield axios.get(this.serverContextPath + `${FILE_API_URL}/${groupId}/${postedType}/${postedTypeId}/${fileId}`, {
            const response = yield axios.get(this.serverContextPath + `${FILE_API_URL}/download`, {
                params: { ...params },
                headers: { 'Content-Type': 'application/octet-stream' },
                responseType: 'arraybuffer',
            });
            const file = response.data;
            // const fileBuffer = Buffer.from(file, 'base64');

            // console.log(logPrefix, 'Success download file : >> ', typeof fileBuffer);

            fileDownload(file, fileName);
            this.fileDownloadState = FILE_LOAD_STATE.Downloaded;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed downloadFile', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed downloadFile', e.request);
            } else {
                console.log(logPrefix, 'Failed downloadFile', e.message);
            }
            this.fileDownloadState = FILE_LOAD_STATE.DownloadFailed;
        } finally {
            this.fileDownloadState = FILE_LOAD_STATE.Closed;
        }
    }

    *deleteFile(userEmail, callbacks) {
        console.log('starting delete file = >', userEmail);
        const params = { ...this.deleteFileInfo };
        try {
            const response = yield axios.put(this.serverContextPath + `${FILE_API_URL}/delete/${userEmail}`, params);
            console.log(logPrefix, 'Success modifyFile result = ', response);
            callbacks && callbacks.getGroupFolderAndFiles();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed deleteFile', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed deleteFile', e.request);
            } else {
                console.log(logPrefix, 'Failed deleteFile', e.message);
            }
        }
    }

    *modifyFile(userEmail, callbacks) {
        console.log(logPrefix, `Start modify file, modify info =`, this.modifyFileInfo);
        const params = { ...this.modifyFileInfo };
        if (!validateFileOrFolderName(params.modifiedFileName)) {
            console.log('Failed validation fileName');
            this.changeFileLoadResultDialogMsgId(FILE_STORE_MESSAGE_ID.Error.not_allowed_file_name);
            this.fileUploadState = FILE_LOAD_STATE.UploadFailed;
            return false;
        }
        try {
            const response = yield axios.put(this.serverContextPath + `${FILE_API_URL}/modify/${userEmail}`, params);
            console.log(logPrefix, 'Success modifyFile result = ', response);
            callbacks && callbacks.getGroupFolderAndFiles();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed modifyFile', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed modifyFile', e.request);
            } else {
                console.log(logPrefix, 'Failed modifyFile', e.message);
            }
        } finally {
            callbacks && callbacks.closeModifyFile();
        }
    }
}
