import React from 'react';
import { Avatar, Box, Button, FormControlLabel, Switch, Typography } from '@material-ui/core';
import clsx from 'clsx';
import SwitchCloseIcon from '../../../common/images/SwitchCloseIcon.svg';
import SwitchCheckIcon from '../../../common/images/SwitchCheckIcon.svg';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonNames, SettingsInnerPath } from './MobileSettingComponent';
import { injectIntl } from 'react-intl';
import { ReactComponent as AsideUserIcon } from '../../../common/images/AsideUserIcon.svg';

const useStyles = makeStyles(_theme => ({
    root: {
        paddingBottom: 70,
    },
    titleText: {
        fontSize: '0.938rem',
        fontWeight: 600,
        color: '#000',
        textAlign: 'left',
    },
    boxStyle: {
        background: '#f8f8f8',
        borderRadius: 10,
        padding: 12,
        marginTop: 10,
        marginBottom: 30,
    },
    avatarStyle: {
        width: 32,
        height: 32,
        marginRight: 7,
        background: '#a3a8af',
        '& svg': {
            width: 20,
            height: 20,
            '& path': {
                fill: '#fff',
            },
        },
    },
    textStyle: {
        fontSize: '0.875rem',
        color: '#000',
        letterSpacing: '-0.2px',
    },
    buttonStyle: {
        border: '1px solid #0097ff',
        color: '#0097ff',
        borderRadius: 7,
        background: '#fff',
        width: 90,
        // height: 40,
        fontSize: '0.813rem',
        fontWeight: 600,
        letterSpacing: '-0.2px',
        '&:hover': {
            border: '1px solid #0097ff',
            background: '#fff',
        },
        '@media all and (max-width: 600px)': {
            width: 70,
            padding: '6px 0',
        },
    },
    redButton: {
        border: '1px solid #ff0000',
        color: '#ff0000',
        borderRadius: 7,
        background: '#fff',
        width: 90,
        fontSize: '0.813rem',
        fontWeight: 600,
        letterSpacing: '-0.2px',
        '&:hover': {
            border: '1px solid #ff0000',
            background: '#fff',
        },
        '@media all and (max-width: 600px)': {
            width: 70,
        },
    },
    deleteButton: {
        border: '1px solid #ff0000',
        background: '#ff0000',
        color: '#fff',
        '&:hover': {
            background: '#ff0000',
        },
    },
    boxMargin: {
        paddingTop: 11,
        borderTop: '1px solid #e1e1e1',
        marginTop: 11,
    },
    blueText: {
        fontSize: '0.813rem',
        color: '#0097ff',
        marginRight: 30,
        letterSpacing: '-0.2px',
        '@media all and (max-width: 600px)': {
            marginRight: 10,
            fontSize: '0.75rem',
        },
    },
    switchBox: {
        width: 80,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            marginRight: 0,
        },
        '& .MuiSwitch-root': {
            width: 48,
            height: 24,
            padding: 0,
            borderRadius: 15,
        },
        '& .MuiIconButton-root': {
            padding: 0,
        },
        '& .MuiSwitch-thumb': {
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '3px solid #5e5e5e',
            boxSizing: 'border-box',
            '&:before': {
                content: "''",
                width: 18,
                height: 18,
                backgroundImage: `url(${SwitchCloseIcon})`,
                backgroundSize: '100%',
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            background: '#5e5e5e',
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            opacity: 1,
            background: '#1f75cb',
        },
        '& .MuiSwitch-colorSecondary.Mui-checked .MuiSwitch-thumb': {
            border: '3px solid #1f75cb',
            background: '#fff',
            '&:before': {
                content: "''",
                width: 18,
                height: 18,
                backgroundImage: `url(${SwitchCheckIcon})`,
                backgroundSize: '100%',
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(24px)',
        },
        '@media all and (max-width: 600px)': {
            width: 70,
        },
    },
}));

function MobileMainControlComponent(props) {
    const { intl, userDetail, groupDetail, groupLeader } = props;
    const classes = useStyles();
    const isMeLeader = groupLeader && userDetail ? groupLeader.userId === userDetail.id : false;

    const handelClickDialogOpen = e => {
        props.handleChangeDialogOpen(e.currentTarget.name);
    };

    const handleClickComponentOpen = e => {
        props.handleChangeComponentOpen(e.currentTarget.name);
    };

    const handleSwitch = e => {
        props.handleChangeJoinSwitch(e);
    };

    return (
        <>
            <Typography className={classes.titleText}>설정</Typography>
            <Box className={classes.boxStyle}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatarStyle} src={props.loadAvatarImage}>
                            <AsideUserIcon />
                        </Avatar>
                        <Typography className={classes.textStyle}>{userDetail.name}</Typography>
                    </Box>
                    <Button className={classes.buttonStyle} name={ButtonNames.ProfileDialogOpen} onClick={handelClickDialogOpen} disableRipple>
                        {intl.formatMessage({ id: 'profile_settings' })}
                    </Button>
                </Box>
                {/** 2차수 개발*/}
                {/*<Box display="flex" justifyContent="space-between" alignItems="center" className={classes.boxMargin}>*/}
                {/*    <Box display="flex" alignItems="center">*/}
                {/*        <Typography className={classes.textStyle}>알림</Typography>*/}
                {/*    </Box>*/}
                {/*    <Button className={classes.buttonStyle} name={ButtonNames.NoticeDialogOpen} onClick={handelClickDialogOpen} disableRipple>*/}
                {/*        변경*/}
                {/*    </Button>*/}
                {/*</Box>*/}
            </Box>
            {props.groupUserAuthority.GroupModify && (
                <>
                    <Typography className={classes.textStyle}>클래스 정보 설정</Typography>
                    <Box className={classes.boxStyle}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box display="flex" alignItems="center">
                                <Typography className={classes.textStyle}>클래스 이름 및 커버, 소개, 색상 테마</Typography>
                            </Box>
                            <Button className={classes.buttonStyle} onClick={props.handleClickRoomModifyOpen} disableRipple>
                                변경
                            </Button>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.boxMargin}>
                            <Box display="flex" alignItems="center">
                                <Typography className={classes.textStyle}>공개 여부</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography className={classes.blueText}>{groupDetail.group.public ? '공개' : '비공개'}</Typography>
                                <Button
                                    className={classes.buttonStyle}
                                    name={ButtonNames.RoomOpenDialog}
                                    onClick={handelClickDialogOpen}
                                    disableRipple
                                >
                                    변경
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </>
            )}

            {props.groupUserAuthority.GroupApproval && (
                <>
                    <Typography className={classes.textStyle}>가입 관리</Typography>
                    <Box className={classes.boxStyle}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box display="flex" alignItems="center">
                                <Typography className={classes.textStyle}>가입 신청 받기</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography className={classes.blueText}>
                                    {groupDetail.group && groupDetail.group.joinApproval ? '관리자 승인 필요' : '관리자 승인 없이 바로 가입 가능'}
                                </Typography>
                                <Box className={classes.switchBox}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disableRipple
                                                checked={groupDetail.group && groupDetail.group.joinApproval ? groupDetail.group.joinApproval : false}
                                                onChange={handleSwitch}
                                                value="joinSwitch"
                                            />
                                        }
                                        label=""
                                        disabled={props.isModifyLoading}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.boxMargin}>
                            <Box display="flex" alignItems="center">
                                <Typography className={classes.textStyle}>멤버 요청 및 수락</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography className={classes.blueText}>{`${props.groupJoinApprovals.length}명`}</Typography>
                                <Button
                                    className={classes.buttonStyle}
                                    name={SettingsInnerPath.approval}
                                    onClick={handleClickComponentOpen}
                                    disableRipple
                                >
                                    보기
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </>
            )}

            {props.groupUserAuthority.GroupMember && (
                <>
                    <Typography className={classes.textStyle}>활동 관리</Typography>
                    <Box className={classes.boxStyle}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box display="flex" alignItems="center">
                                <Typography className={classes.textStyle}>멤버 권한 설정</Typography>
                            </Box>
                            <Button
                                className={classes.buttonStyle}
                                name={SettingsInnerPath.permission}
                                onClick={handleClickComponentOpen}
                                disableRipple
                            >
                                변경
                            </Button>
                        </Box>
                        {/*<Box display="flex" justifyContent="space-between" alignItems="center" className={classes.boxMargin}>*/}
                        {/*    <Box display="flex" alignItems="center">*/}
                        {/*        <Typography className={classes.textStyle}>관리자 추가</Typography>*/}
                        {/*    </Box>*/}
                        {/*    <Box display="flex" alignItems="center">*/}
                        {/*        <Typography className={classes.blueText}>{groupLeaderAndAdmins.length}명</Typography>*/}
                        {/*        <Button className={classes.buttonStyle} name={SettingsInnerPath.admin} onClick={handleClickComponentOpen} disableRipple>*/}
                        {/*            변경*/}
                        {/*        </Button>*/}
                        {/*    </Box>*/}
                        {/*</Box>*/}
                    </Box>
                </>
            )}

            {/*<Typography className={classes.textStyle}>메뉴 관리</Typography>*/}
            {/*<Box className={classes.boxStyle}>*/}
            {/*    <Box display="flex" justifyContent="space-between" alignItems="center">*/}
            {/*        <Box display="flex" alignItems="center">*/}
            {/*            <Typography className={classes.textStyle}>커뮤니티 설정</Typography>*/}
            {/*        </Box>*/}
            {/*        <Button className={classes.buttonStyle} name={ButtonNames.CommunityDialogOpen} onClick={handelClickDialogOpen} disableRipple>*/}
            {/*            변경*/}
            {/*        </Button>*/}
            {/*    </Box>*/}
            {/*</Box>*/}

            {/*<Box className={classes.boxStyle}>*/}
            {/*    <Box display="flex" justifyContent="space-between" alignItems="center">*/}
            {/*        <Box display="flex" alignItems="center">*/}
            {/*            <Typography className={classes.textStyle}>클래스 탈퇴</Typography>*/}
            {/*        </Box>*/}
            {/*        <Button className={classes.redButton} name={ButtonNames.WithdrawalDialogOpen} onClick={handelClickDialogOpen} disableRipple>*/}
            {/*            탈퇴*/}
            {/*        </Button>*/}
            {/*    </Box>*/}
            {/*    {isMeLeader && (*/}
            {/*        <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.boxMargin}>*/}
            {/*            <Box display="flex" alignItems="center">*/}
            {/*                <Typography className={classes.textStyle}>클래스 삭제</Typography>*/}
            {/*            </Box>*/}

            {/*            <Button*/}
            {/*                className={clsx(classes.redButton, classes.deleteButton)}*/}
            {/*                name={ButtonNames.DeleteDialogOpen}*/}
            {/*                onClick={handelClickDialogOpen}*/}
            {/*                disableRipple*/}
            {/*            >*/}
            {/*                삭제*/}
            {/*            </Button>*/}
            {/*        </Box>*/}
            {/*    )}*/}
            {/*</Box>*/}

            <Box className={classes.boxStyle}>
                {isMeLeader ? (
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.boxMargin}>
                        <Box display="flex" alignItems="center">
                            <Typography className={classes.textStyle}>클래스 삭제</Typography>
                        </Box>

                        <Button
                            className={clsx(classes.redButton, classes.deleteButton)}
                            name={ButtonNames.DeleteDialogOpen}
                            onClick={handelClickDialogOpen}
                            disableRipple
                        >
                            삭제
                        </Button>
                    </Box>
                ) : (
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center">
                            <Typography className={classes.textStyle}>클래스 탈퇴</Typography>
                        </Box>
                        <Button className={classes.redButton} name={ButtonNames.WithdrawalDialogOpen} onClick={handelClickDialogOpen} disableRipple>
                            탈퇴
                        </Button>
                    </Box>
                )}
            </Box>
        </>
    );
}

export default injectIntl(MobileMainControlComponent);
