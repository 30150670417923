import { SORT_BY } from '../../views/class/ClassMainComponent';

class SortUtil {
    compareMembers = (a, b, sortType) => {
        if (sortType === SORT_BY.name) {
            const nameA = a.name ? a.name : a.userName ? a.userName : '';
            const nameB = b.name ? b.name : b.userName ? b.userName : '';
            if (nameA.toUpperCase() < nameB.toUpperCase()) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        } else if (sortType === SORT_BY.createdDatetime) {
            if (a.createdDatetime < b.createdDatetime) {
                return 1;
            }
            if (a.createdDatetime > b.createdDatetime) {
                return -1;
            }

            return 0;
        } else if (sortType === SORT_BY.updateDatetime) {
            if (a.updatedDatetime < b.updatedDatetime) {
                return 1;
            }
            if (a.updatedDatetime > b.updatedDatetime) {
                return -1;
            }

            return 0;
        }
    };
}

export const SORT_UTIL = new SortUtil();
