import React, { Component } from 'react';
import { Box, Button, Checkbox, Dialog, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as BooksIcon } from '../../common/images/BooksIcon.svg';
import { inject, observer } from 'mobx-react';
import { ToastsStore } from 'react-toasts';
import clsx from 'clsx';
import { ReactComponent as Mathematics } from '../../common/images/Mathematics.svg';
import {PaperGroupType} from "../../stores/NeoPenStore";

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 400,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 600px)': {
                width: 400,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    textStyle: {
        fontSize: '1rem',
        color: '#333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    marginTop: {
        marginTop: 27,
        '@media all and (max-width: 600px)': {
            marginTop: 15,
        },
    },
    searchBox: {
        display: 'flex',
        boxShadow: 'none',
        border: '1px solid #C4C4C4',
        padding: '4px 9px',
        margin: '12px 0 21px',
        borderRadius: 7,
        '& .MuiInputBase-root': {
            width: '100%',
        },
        '& input': {
            width: '100%',
            fontSize: '1rem',
        },
        '@media all and (max-width: 600px)': {
            padding: '5px 10px',
            margin: '10px 0 20px',
            '& input': {
                fontSize: '0.813rem',
            },
        },
    },
    searchIconButton: {
        padding: 6,
        backgroundColor: '#f8f8f8',
        '&:hover': {
            backgroundColor: '#f8f8f8',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 18,
                height: 18,
            },
        },
    },
    svgSize: {
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 18,
                height: 18,
            },
        },
        '& .Mui-checked': {
            color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
    numberText: {
        fontSize: '0.813rem',
        marginLeft: 4,
        color: '#a3a8af',
    },
    listBox: {
        height: 250,
        // maxHeight: 280,
        marginTop: 10,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '15px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '5px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
        '& .Mui-checked': {
            color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '@media all and (max-width: 600px)': {
            marginTop: 0,
        },
    },
    iconSize: {
        '& svg': {
            width: 30,
            height: 30,
        },
    },
    marginBottom: {
        marginBottom: 15,
    },
    avatar: {
        width: 45,
        height: 45,
        '& svg': {
            width: 28,
            height: 28,
            '& path': {
                fill: '#fff',
            },
        },
        '@media all and (max-width: 600px)': {
            width: 35,
            height: 35,
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    nameText: {
        marginLeft: 7,
        fontSize: '1rem',
        color: '#333',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '0.875rem',
        },
    },
    checkedBox: {
        '& .MuiIconButton-root': {
            padding: 0,
        },
        '& .MuiSvgIcon-root': {
            '@media all and (max-width: 600px)': {
                fontSize: '1rem',
            },
        },
    },
    addMarginRight: {
        marginRight: '15px',
    },
    buttonStyle1: {
        width: '100%',
        height: 48,
        borderRadius: 8,
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        color: '#fff',
        fontSize: '1.125rem',
        marginTop: 25,
        '&:hover': {
            fontWeight: 800,
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            height: 33,
            padding: '6px 0',
            fontSize: '0.75rem',
            borderRadius: 4,
        },
    },
});

const textBookList = [
    {
        id: 1,
        name: '수학톡톡 24학년도 3학년 1학기',
        section: 3,
        owner: 1012,
        book: 3158,
        page: 1,
    },
    {
        id: 2,
        name: '수학톡톡 24학년도 4학년 1학기',
        section: 3,
        owner: 1012,
        book: 3159,
        page: 1,
    },
    {
        id: 3,
        name: '수학톡톡 24학년도 5학년 1학기',
        section: 3,
        owner: 1012,
        book: 3160,
        page: 1,
    },
    {
        id: 4,
        name: '수학톡톡 24학년도 6학년 1학기',
        section: 3,
        owner: 1012,
        book: 3161,
        page: 1,
    },
    {
        id: 5,
        name: '수학톡톡 23학년도 3학년 2학기',
        section: 3,
        owner: 1012,
        book: 3142,
        page: 1,
    },
    {
        id: 6,
        name: '수학톡톡 23학년도 4학년 2학기',
        section: 3,
        owner: 1012,
        book: 3143,
        page: 1,
    },
    {
        id: 7,
        name: '수학톡톡 23학년도 5학년 2학기',
        section: 3,
        owner: 1012,
        book: 3144,
        page: 1,
    },
    {
        id: 8,
        name: '수학톡톡 23학년도 6학년 2학기',
        section: 3,
        owner: 1012,
        book: 3145,
        page: 1,
    },
];

class MathTextBookRegistrationDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textbook: [],
            checked: false,
        };
    }

    handleSetAllMember = allList => {
        if (!allList.length) return;

        if (this.state.textbook.length === 0 || this.state.textbook.length < allList.length) {
            this.setState({ textbook: [...allList] });
        } else {
            this.setState({ textbook: [] });
        }
    };

    handleChangeCheckBox = list => {
        if (this.state.textbook.length > 0) {
            const textBookList = this.state.textbook;
            let index = textBookList.findIndex(t => t.id === list.id);
            if (index === -1) {
                this.setState({ textbook: [...this.state.textbook, list] });
            } else {
                textBookList.splice(index, 1);
                this.setState({ textbook: textBookList });
            }
        } else {
            this.setState({ textbook: [...this.state.textbook, list] });
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Dialog open={this.props.mathTextBookDialogOpen} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography className={classes.titleText}>수학톡톡 교재 선택</Typography>
                        <IconButton className={classes.iconButton} onClick={this.props.handleCloseMathTextBookDialog} disableRipple>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>
                    <Box className={classes.marginTop}>
                        <Typography className={classes.textStyle}>해당 교재에서 선택해주세요.</Typography>
                    </Box>

                    <Box>
                        <Box display="flex" alignItems="center" className={classes.svgSize} justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                <BooksIcon /> <span className={classes.numberText}>{textBookList.length}권</span>
                            </Box>
                            <Box>
                                <Checkbox
                                    className={textBookList.length > 5 ? clsx(classes.checkedBox, classes.addMarginRight) : classes.checkedBox}
                                    disableRipple
                                    checked={this.state.textbook.length === textBookList.length}
                                    onChange={() => this.handleSetAllMember(textBookList)}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.listBox}>
                            {textBookList.map((list, i) => (
                                <Box key={i} display="flex" alignItems="center" justifyContent="space-between" mt={1} mb={2}>
                                    <Box display="flex" alignItems="center" className={classes.iconSize}>
                                        <Mathematics />
                                        <Typography className={classes.nameText}>{list.name}</Typography>
                                    </Box>
                                    <Checkbox
                                        className={classes.checkedBox}
                                        disableRipple
                                        checked={this.state.textbook.findIndex(e => e.id === list.id) !== -1}
                                        onChange={() => this.handleChangeCheckBox(list)}
                                    />
                                </Box>
                            ))}
                        </Box>

                        <Button
                            className={classes.buttonStyle1}
                            disableRipple
                            disabled={false}
                            onClick={async () => {
                                const userEmail = this.props.authStore.loginUser.email;
                                const groupId = this.props.classStore.selectedGroupDetail.group.id;

                                ToastsStore.info('서식 등록을 시작합니다.', 3000, classes.toasts);

                                this.props.handleCloseMathTextBookDialog();

                                for (let tb of this.state.textbook) {
                                    let paperGroup = await this.props.neoPenStore.getPaperGroupFromPaperHubBySOBP(userEmail, { ...tb });

                                    let message = await this.props.neoPenStore.uploadPaperGroup(
                                        groupId,
                                        this.props.neoPenStore.currentFolder.id,
                                        userEmail,
                                        paperGroup,
                                        tb.name,
                                        PaperGroupType.Study
                                    );

                                    ToastsStore.info(tb.name + ' ' + message, 3000, classes.toasts);
                                }
                            }}
                        >
                            선택 추가
                        </Button>
                    </Box>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(inject('classStore', 'authStore', 'neoPenStore')(observer(MathTextBookRegistrationDialogComponent)));
