import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { DATE_UTIL } from '../../common/util/date.util';
import dayjs from 'dayjs';
import { USER_TYPE } from '../../stores/UserStore';

const today = DATE_UTIL.getNowDate();
const EXPECTED_DATE_PAYMENT = '2023-07-21';
// const EXPECTED_DATE_PAYMENT = '2023-06-12';
export const LICENSE_ALERT_EXPIRED_DATE_SAVED_KEY = '__OTL_License_Alert_Expired_Date__';
export const getAfterExpectedDatePayment = () => {
    return DATE_UTIL.getIsSameOrAfter(today, dayjs(EXPECTED_DATE_PAYMENT));
};
const LicenseKeyInformation = props => {
    const user = props?.userStore?.user;
    const userLicenseKey = props?.userStore?.userLicenseKey;
    const isAfterExpectedDatePayment = getAfterExpectedDatePayment();

    useEffect(() => {
        console.log('Do check license dialog user = ', user);
        if (!user?.id || !user?.email || !user?.type) {
            return;
        }

        if (user?.type !== USER_TYPE.User) {
            return;
        }

        if (userLicenseKey) {
            console.log('Already has userLicenseKey  = ', userLicenseKey);
            return;
        }

        console.log('Do check license dialog isAfterExpectedDatePayment = ', isAfterExpectedDatePayment);
        // const today = DATE_UTIL.getNowDate();
        if (isAfterExpectedDatePayment) {
            //라이선스키 입력 다이얼로그 오픈
            console.log('Check expected date result = ', DATE_UTIL.getIsSameOrAfter(today, dayjs(EXPECTED_DATE_PAYMENT)));
            props?.userStore?.changeLicenseAlertOpenState(true);
            return;
        }

        const alertExpiredDate = localStorage.getItem(LICENSE_ALERT_EXPIRED_DATE_SAVED_KEY);
        console.log('Check alertExpiredDate result = ', alertExpiredDate);
        if (alertExpiredDate) {
            if (DATE_UTIL.getIsSameOrAfter(today, dayjs(alertExpiredDate))) {
                localStorage.removeItem(LICENSE_ALERT_EXPIRED_DATE_SAVED_KEY);
            } else {
                return;
            }
        }

        props?.userStore?.changeLicenseAlertOpenState(true);
    }, [user, userLicenseKey, isAfterExpectedDatePayment, props?.userStore]);

    return <div>{props?.children}</div>;
};

export default inject('userStore')(observer(LicenseKeyInformation));
