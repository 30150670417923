import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField } from '@material-ui/core';
import { LICENSE_KEY_DIGITS, LICENSE_KEY_STR_PATTERN, UTIL } from '../common/util/common.util';

const useStyles = makeStyles(theme => ({
    licenseBox: props => ({
        // width: '100%',
        width: props?.width,
        height: 40,
        boxSizing: 'border-box',
        // border: '1px solid #c0c2c3',
        borderRadius: 8,
        marginTop: props?.marginTop,
        background: '#fff',
        // padding: '0 10px',
        display: 'flex',
        alignItems: 'center',
        '@media all and (max-width: 600px)': {
            width: '100%',
        },
    }),
    form: props => ({
        // width: 38,
        // width: 350,
        width: props?.width,
        // minWidth: '350px',
        minWidth: `${props?.minWidth}px`,
        '@media all and (max-width: 600px)': {
            width: 168,
            minWidth: 168,
        },
        '& .MuiInputBase-root': {
            width: '100%',
            borderRadius: 4,
            '&:hover': {
                borderColor: '#c0c2c3',
            },
            '@media all and (max-width: 600px)': {
                width: 168,
                minWidth: 168,
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '13px 18px',
            color: '#000',
            fontSize: '1.063rem',
            '&::placeholder': {
                color: '#9d9d9d',
                opacity: 1,
            },
            '@media all and (max-width: 600px)': {
                padding: '5px 10px',
                fontSize: '0.813rem',
            },
        },
        '& input': {
            fontSize: '1rem',
            color: '#333',
            letterSpacing: '-0.2px',
            '&::placeholder': {
                fontSize: '1rem',
                color: '#a3a8af',
                letterSpacing: '-0.2px',
                opacity: 1,
                '@media all and (max-width: 600px)': {
                    fontSize: '0.813rem',
                },
            },
            '@media all and (max-width: 600px)': {
                fontSize: '0.813rem',
            },
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #c0c2c3',
            },
    }),
    buttonStyle: {
        width: 141,
        height: 46,
        border: '1px solid #0097ff',
        background: '#fff',
        borderRadius: 7,
        padding: 0,
        marginLeft: 10,
        color: '#0097ff',
        fontSize: '1.063rem',
        fontWeight: 'bold',
        '&:hover': {
            background: '#fff',
        },
        '@media all and (max-width: 600px)': {
            width: 50,
            height: 26,
            fontSize: '0.875rem',
            borderRadius: 4,
        },
    },
}));

const LicenseKeyInputBox = ({
    width = '100%',
    minWidth = 350,
    useFullWidth = true,
    marginTop = 8,
    licenseKey = '',
    onChangeLicenseKey = value => {},
}) => {
    const classes = useStyles({ width, minWidth, marginTop });
    const handleChangeLicense = e => {
        console.log('handleChangeLicense : >> ', e.target.value);
        if (!e.target.value) {
            onChangeLicenseKey('');
            return;
        }
        // const regex = /^[A-Za-z0-9-]+$/;
        if (!LICENSE_KEY_STR_PATTERN?.test(e.target.value)) {
            return;
        }

        // let licenseKey = e.target.validity.valid ? e.target.value : this.state.licenseKey;
        let inputLicenseKey = e.target.value;
        console.log('handleChangeLicense licenseKey : >> ', inputLicenseKey);
        if (inputLicenseKey?.replaceAll('-', '')?.length > LICENSE_KEY_DIGITS) {
            return;
        }

        if (inputLicenseKey?.replaceAll('-', '')?.length === LICENSE_KEY_DIGITS) {
            inputLicenseKey = UTIL.licenseKeySplitter({ str: inputLicenseKey });
        }

        onChangeLicenseKey(inputLicenseKey);
        // this.setState({ licenseKey });
    };
    return (
        // <Box className={classes.licenseBox}>
        <Box className={classes.form}>
            <TextField
                id="license1"
                type="text"
                // inputProps={{ maxLength: 19, inputMode: 'text', pattern: '/^[A-Za-z0-9+]*$/' }}
                inputProps={{ maxLength: 19 }}
                name="license1"
                onChange={handleChangeLicense}
                value={licenseKey}
                label=""
                variant="outlined"
                placeholder="xxxx - xxxx - xxxx - xxxx"
                fullWidth={useFullWidth}
            />
        </Box>
        // </Box>
    );
};

export default LicenseKeyInputBox;
