import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Badge, Box, Button, IconButton, MenuItem, MenuList, Popover, Typography } from '@material-ui/core';
import { ReactComponent as BellRingingIcon } from '../../common/images/BellRingingIcon.svg';
import { ReactComponent as PencilCircleIcon } from '../../common/images/PencilCircleIcon.svg';
import { ReactComponent as CircleWavyQuestionIcon } from '../../common/images/CircleWavyQuestionIcon.svg';
import { inject, observer } from 'mobx-react';
import { withRouter } from '../../components/WithRouter';
import TopSearch from './TopSearch';
import { ReactComponent as BedgeNewIcon } from '../../common/images/BedgeNewIcon.svg';
import NotificationComponent from '../notification/NotificationComponent';
import { UTIL } from '../../common/util/common.util';
import { NOTICE_STATE_HANDLE_OPERATION, Request_Notice_IntervalTime } from '../../stores/NoticeStore';
import { USER_TYPE } from '../../stores/UserStore';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { LoadingState } from '../../stores/ClassStore';
import { PATH_UTIL } from '../../common/util/path.util';
import { ReactComponent as ModalCloseIcon } from '../../common/images/ModalCloseIcon.svg';
import { OfflineDataUploadState, PenState } from '../../stores/NeoPenStore';
import { ReactComponent as X } from '../../common/images/X.svg';

const styles = _theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        background: '#fff',
        borderBottom: '1px solid #c0c2c3',
    },
    hiddenRoot: {
        display: 'none',
    },
    appBar: {
        '@media all and (min-width: 1500px)': {
            width: 1440,
        },
        width: 1200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 30px',
        boxSizing: 'border-box',
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    iconMargin: {
        margin: '0 25px',
    },
    searchIcon: {
        padding: 7,
        background: '#f8f8f8',
        '&:hover': {
            background: '#f8f8f8',
        },
    },
    search: {
        width: 500,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#fff',
        border: '2px solid #e1e1e1',
        padding: '3px 6px 3px 22px',
        borderRadius: 50,
        '& .MuiInputBase-input::placeholder': {
            opacity: 1,
            fontSize: '1.125rem',
            color: '#92979e',
        },
        '& .MuiInputBase-input': {
            padding: '6px 7px 4px',
        },
    },
    buttonStyle: {
        fontSize: '0.938rem',
        color: '#000',
        fontWeight: 600,
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    lineStyle: {
        width: 1,
        height: 17,
        background: '#e1e1e1',
        margin: '0 24px 0 12px',
    },
    tooltipBox: {
        position: 'absolute',
        left: -118,
        top: 45,
        width: 245,
        padding: '8px 10px',
        background: '#2078e8',
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 3,
        whiteSpace: 'pre-wrap',
        zIndex: 100,
        '&::before': {
            backgroundColor: '#2078e8',
            content: "''",
            display: 'block',
            height: '13px',
            position: 'absolute',
            top: -7,
            left: 125,
            transform: 'rotate( -45deg ) skew( 0deg )',
            width: '13px',
            boxShadow: '2px -2px 2px 0 rgb(178 178 178 / 20%)',
            zIndex: 200,
        },
    },
    closeBtn: {
        padding: 0,
        '& svg': {
            width: 20,
            height: 20,
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    tooltipText: {
        fontSize: '0.875rem',
        fontWeight: 500,
        color: '#fff',
        marginTop: 5,
    },
    badgeBox: {
        position: 'relative',
    },
    badge: {
        position: 'absolute',
        right: -8,
        top: -5,
    },
    inputRoot: {
        color: '#000',
        fontSize: '1.125rem',
        width: '100%',
        marginRight: 10,
    },
    avatar: {
        width: 40,
        height: 40,
        background: 'rgba(0, 0, 0, 0.16)',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 2px;',
    },
    iconColor: {
        '& .question-icon': {
            fill: '#0097ff',
        },
        '& .bellringing-icon': {
            fill: '#0097ff',
        },
    },
    avatarMember: {
        width: 60,
        height: 60,
        background: '#a3a8af',
        boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 1px;',
        '& img': {
            objectFit: 'cover',
        },
    },
    popover: {
        '& .MuiPaper-root': {
            borderRadius: 10,
            boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
        },
        '& .MuiPopover-paper': {
            width: 300,
            padding: '25px 10px 10px',
            overflow: 'inherit',
            marginTop: 18,
            marginLeft: 36,
            '&::before': {
                backgroundColor: '#fff',
                content: "''",
                display: 'block',
                height: '13px',
                position: 'absolute',
                top: -6,
                right: '50px',
                transform: 'rotate( -45deg ) skew( 0deg )',
                width: '13px',
                boxShadow: '2px -2px 2px 0 rgb(178 178 178 / 20%)',
                zIndex: 200,
            },
        },
    },
    popover2: {
        '& .MuiPaper-root': {
            borderRadius: 10,
            boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
        },
        '& .MuiPopover-paper': {
            width: 390,
            padding: '23px',
            overflow: 'inherit',
            marginTop: 18,
            marginLeft: 36,
            boxSizing: 'border-box',
        },
    },
    popoverTitle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        marginBottom: 12,
    },
    popoverText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        color: '#333',
    },
    popoverLine: {
        width: '100%',
        height: 1,
        background: 'rgba(192, 194, 195, 0.7)',
        margin: '35px 0 40px',
    },
    popoverButton: {
        width: '100%',
        height: 45,
        boxSizing: 'border-box',
        background: '#0097FF',
        borderRadius: 8,
        marginTop: 30,
        '&:hover': {
            background: '#0097FF',
        },
        '& span': {
            fontFamily: 'NanumSquareRoundOTF',
            color: '#fff',
            fontSize: '1.125rem',
            fontWeight: '600',
        },
    },
    popoverButton2: {
        background: '#fff',
        border: '1px solid #0097FF',
        '&:hover': {
            background: '#fff',
            border: '1px solid #0097FF',
        },
        '& span': {
            color: '#0097FF',
        },
    },
    popoverIn: {
        fontFamily: 'NanumSquareRoundOTF',
        position: 'relative',
    },
    avatarMargin: {
        marginRight: 12,
    },
    nameText: {
        fontSize: '1.063rem',
        color: '#000',
        fontWeight: 600,
        lineHeight: 1.2,
    },
    emailText: {
        maxWidth: 220,
        fontSize: '0.875rem',
        color: '#a3a8af',
        marginBottom: 5,
    },
    formControl: {
        '& .MuiOutlinedInput-root, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                borderColor: '#a3a8af',
                borderWidth: 1,
            },
        '& .MuiOutlinedInput-input': {
            padding: '5px 10px',
        },
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '1.063rem',
        color: '#000',
        '&:hover': {
            background: 'transparent',
        },
    },
    menuItemLine: {
        borderBottom: '1px solid #c0c2c3',
    },
    iconButton2: {
        fontSize: '0.938rem',
        color: '#000',
        fontWeight: 600,
        padding: 0,
        marginLeft: 20,
        '&:hover': {
            background: 'transparent',
        },
    },
    toastsStyle: {
        '& .toast': {
            minHeight: '25px !important',
            borderRadius: '30px !important',
            fontSize: '1rem!important',
            color: '#fff!important',
            backgroundColor: '#858585!important',
        },
    },
    explanationBox: {
        width: 110,
        padding: '10px',
        background: '#2078e8',
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 3,
        position: 'absolute',
        zIndex: 10000,
        bottom: -50,
        right: -16,
        '&::before': {
            backgroundColor: '#2078e8',
            content: "''",
            display: 'block',
            height: '13px',
            position: 'absolute',
            top: -6,
            left: 58,
            transform: 'rotate( -227deg ) skew( 0deg )',
            width: '13px',
            // boxShadow: '2px -2px 2px 0 rgb(178 178 178 / 20%)',
            zIndex: 200,
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    explanationBox2: {
        width: 160,
        right: -38,
        '&::before': {
            top: -6,
            left: 86,
        },
    },
    stepContents: {
        fontSize: '0.813rem',
        color: '#fff',
        fontWeight: 300,
    },
});

const StyledBadge = withStyles(_theme => ({
    badge: {
        right: 2,
        top: 19,
        border: `1px solid #fff`,
        width: 12,
        height: 12,
        borderRadius: '50%',
        background: '#00c880',
    },
}))(Badge);

const StyledBadgeMember = withStyles(_theme => ({
    badge: {
        right: 6,
        top: 37,
        border: `1px solid #fff`,
        width: 15,
        height: 15,
        borderRadius: '50%',
        background: '#00c880',
    },
}))(Badge);

class TopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            notificationOpen: false,
            filter: '온라인',
        };
    }

    getNotificationsInterval = undefined;

    getNotifications = () => {
        const { noticeStore, authStore } = this.props;
        noticeStore.requestNotifications(authStore.loginUser.email);
    };

    setNotificationInterval = () => {
        this.getNotificationsInterval = setInterval(this.getNotifications, Request_Notice_IntervalTime); //30초마다 알림조회 인터벌 설정
        this.getNotifications();
    };

    cancelNotificationInterval = () => {
        if (this.getNotificationsInterval !== undefined) {
            clearInterval(this.getNotificationsInterval);
            this.getNotificationsInterval = undefined;
        }
        if (this.props.noticeStore.cancelTokenSource) {
            this.props.noticeStore.cancelTokenSource.cancel();
        }
    };

    componentDidMount() {
        const { loginUser } = this.props.authStore;
        this.props.userStore.checkCoachState(loginUser.email);
        this.props.avatarStore.getAvatar(loginUser.id);

        if (loginUser.type !== USER_TYPE.Admin && this.getNotificationsInterval === undefined) {
            this.setNotificationInterval();
        }

        this.initNeoPen();
    }

    initNeoPen = async () => {
        const loginUser = this.props.authStore.loginUser;

        await this.props.userStore.getUserInfo(loginUser.email);

        const macAddress = this.props.userStore.fullSmartPenAddress;

        this.props.neoPenStore.initNeoPen(loginUser, macAddress);
    };

    componentDidUpdate(_prevProps, prevState, _snapshot) {
        if (prevState.notificationOpen !== this.state.notificationOpen && this.state.notificationOpen) {
            if (this.getNotificationsInterval) {
                this.cancelNotificationInterval();
            }
        } else if (prevState.notificationOpen !== this.state.notificationOpen && !this.state.notificationOpen) {
            const { newNotifications } = this.props.noticeStore;
            const { loginUser } = this.props.authStore;

            if (newNotifications.length) {
                const notificationIds = newNotifications.map(notice => notice.id);
                this.props.noticeStore.requestUpdateNotifiedNotifications(loginUser.email, NOTICE_STATE_HANDLE_OPERATION.Notified, notificationIds);
            }

            if (!this.getNotificationsInterval) {
                this.setNotificationInterval();
            }
        }
    }

    componentWillUnmount() {
        if (this.getNotificationsInterval) {
            this.cancelNotificationInterval();
        }

        const { neoPenStore } = this.props;

        if (neoPenStore.isInit) {
            neoPenStore.changeInitState(!neoPenStore.isInit);
        }
    }

    handleChangeFilter = event => {
        this.setState({ filter: event.target.value });
    };

    handleNotificationOpen = () => {
        this.setState({ notificationOpen: true });
    };

    handleNotificationClose = () => {
        this.setState({ notificationOpen: false });
    };

    handleClickServiceCenter = _e => {
        this.props.navigate('/servicecenter');
    };

    handleClickProfileSettings = _e => {
        this.props.navigate('/profilesettings');
        this.setState({
            anchorEl: null,
        });
    };

    handleClickDashboard = isCommonUser => {
        const movePath = isCommonUser ? '/classmanagement' : '/mylearning';
        this.props.navigate(movePath);
    };

    handleClickMyLearning = () => {
        this.props.navigate('/mylearning');
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });

        this.props.neoPenStore.setRealTimeViewAnchorEl(null);
    };

    handleClickLogo = path => {
        if (path !== 'rooms') {
            this.props.searchStore.initSearchKeyword();
            this.props.roomStore.initRoom();
            this.props.navigate('/rooms');
        }
    };

    handleRequestNextPageNotifications = (nextPage, rowsPerPage) => {
        const { noticeStore, authStore } = this.props;
        const { loginUser } = authStore;
        noticeStore.requestNotifications(loginUser.email, nextPage, rowsPerPage);
    };

    selectRootClassName = path => {
        const { classes } = this.props;
        const notAllowedPathNames = [
            'studylive',
            'termsofuse',
            'privacypolicy',
            'penview',
            'penrecordview',
            'realtimeview',
            'statistics',
            'writingBoard',
        ];
        if (notAllowedPathNames.includes(path)) {
            return classes.hiddenRoot;
        }
        return classes.root;
    };

    selectQuestionIconClassName = path => {
        const { classes } = this.props;
        if (path === 'servicecenter') {
            return classes.iconColor;
        }
        return null;
    };

    render() {
        const { classes, navigate, location } = this.props;
        const { anchorEl, notificationOpen } = this.state;
        const { orgImages } = this.props.orgStore;
        const { loginUser } = this.props.authStore;
        const { loadAvatarImage } = this.props.avatarStore;
        const { notifications, newNotifications } = this.props.noticeStore;
        const { rowsPerPage, page, changePage } = this.props.paginationStore;
        const { user } = this.props.userStore;
        const open = Boolean(anchorEl);
        const realTimeViewOpen = Boolean(this.props.neoPenStore.realTimeViewAnchorEl);
        const mainPathName = PATH_UTIL.getPath(location.pathname);
        const questionIconClassName = this.selectQuestionIconClassName(mainPathName);
        const bellIconClassName = notificationOpen ? classes.iconColor : null;
        const rootClassName = this.selectRootClassName(mainPathName);
        const isCommonUser = loginUser.type !== USER_TYPE.Guest;
        const dashBoardBtnName = isCommonUser ? '클래스 관리' : '내 학습';
        const isHaveNewNotice = newNotifications.length > 0;

        const nPS = this.props.neoPenStore;

        return (
            <div className={rootClassName}>
                <Box className={classes.appBar}>
                    <Box display="flex" alignItems="center">
                        {orgImages.Logo !== undefined && orgImages.Logo !== '' && (
                            <img
                                src={orgImages.Logo}
                                alt="LogoImage"
                                style={{
                                    width: 'auto',
                                    height: 'auto',
                                    maxWidth: 140,
                                    maxHeight: 32,
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                    verticalAlign: 'middle',
                                }}
                                onClick={() => this.handleClickLogo(mainPathName)}
                            />
                        )}
                        {isCommonUser && (
                            <Button className={classes.iconButton2} onClick={() => window.open('/studylive', '_blank')} disableRipple>
                                스토어
                            </Button>
                        )}
                    </Box>
                    {/* 검색 */}
                    <TopSearch />

                    <Box display="flex" alignItems="center">
                        <Box style={{ position: 'relative' }}>
                            <IconButton
                                className={classes.buttonStyle}
                                disableRipple
                                aria-owns={realTimeViewOpen ? 'simple-popper' : undefined}
                                aria-haspopup="true"
                                onClick={event => {
                                    nPS.setRealTimeViewAnchorEl(event.currentTarget);

                                    // window.open(`/realtimeview/${loginUser.email}`);
                                }}
                                disabled={this.props.classStore.groupListFirstLoading === LoadingState.PENDING}
                            >
                                <PencilCircleIcon />
                            </IconButton>

                            {/* tooltip*/}
                            {nPS.offlineDataUploadState === OfflineDataUploadState.UploadFinish && (
                                <Box className={classes.explanationBox}>
                                    <Typography className={classes.stepContents}>펜 기록 업로드 완료</Typography>
                                </Box>
                            )}

                            {nPS.offlineDataUploadState === OfflineDataUploadState.UploadNone && (
                                <Box className={clsx(classes.explanationBox, classes.explanationBox2)}>
                                    <Typography className={classes.stepContents}>업로드할 펜 기록이 없습니다.</Typography>
                                </Box>
                            )}

                            <Popover
                                id="simple-popper"
                                open={realTimeViewOpen}
                                anchorEl={nPS.realTimeViewAnchorEl}
                                onClose={this.handleClose}
                                className={classes.popover2}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <Typography className={classes.popoverTitle}>펜 데이터 이동</Typography>
                                <Typography className={classes.popoverText}>펜이 연결되면 필기 기록이 자동 업로드 됩니다.</Typography>
                                <Typography className={classes.popoverText}>펜 연결 버튼을 눌러 진행하세요.</Typography>
                                <Button
                                    disableRipple
                                    className={classes.popoverButton}
                                    onClick={() => {
                                        if (nPS.penState === PenState.Disconnected) {
                                            nPS.onConnectionChange();

                                            nPS.setOfflineDataUploadState(OfflineDataUploadState.Connecting);
                                        }
                                    }}
                                >
                                    {nPS.penState === PenState.Connecting
                                        ? '펜 연결중...'
                                        : nPS.penState === PenState.Connected
                                        ? '데이터 업로드 중...'
                                        : '펜 연결 후 자동 업로드'}
                                </Button>

                                <Box className={classes.popoverLine}></Box>

                                <Typography className={classes.popoverTitle}>실시간 필기 기록</Typography>
                                <Typography className={classes.popoverText}>등록된 서식에 필기하고 바로 확인하려면</Typography>
                                <Typography className={classes.popoverText}>여기를 눌러 새창에서 펜을 연결해주세요.</Typography>
                                <Button
                                    className={clsx(classes.popoverButton, classes.popoverButton2)}
                                    onClick={() => {
                                        window.open(`/realtimeview/${loginUser.email}`);
                                    }}
                                    disableRipple
                                >
                                    새창에서 실시간 기록하기
                                </Button>
                            </Popover>

                            <span className={classes.lineStyle} />
                        </Box>

                        <>
                            {/*{!isCommonUser && isHaveNewNotice && <BedgeNewIcon />}*/}
                            <Button
                                className={classes.buttonStyle}
                                disableRipple
                                onClick={() => this.handleClickDashboard(isCommonUser)}
                                disabled={this.props.classStore.groupListFirstLoading === LoadingState.PENDING}
                            >
                                {dashBoardBtnName}
                            </Button>
                            <span className={classes.lineStyle} />
                        </>

                        <Box className={classes.badgeBox}>
                            <IconButton className={classes.iconButton} onClick={this.handleNotificationOpen} disableRipple>
                                <BellRingingIcon className={clsx(bellIconClassName)} />
                            </IconButton>
                            {isHaveNewNotice && <BedgeNewIcon className={classes.badge} />}
                        </Box>

                        {/*알림*/}
                        <NotificationComponent
                            notificationOpen={notificationOpen}
                            handleNotificationClose={this.handleNotificationClose}
                            requestNotifications={this.handleRequestNextPageNotifications}
                            loginUser={loginUser}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            changePage={changePage}
                            notifications={notifications}
                        />

                        <IconButton className={clsx(classes.iconButton, classes.iconMargin)} onClick={this.handleClickServiceCenter} disableRipple>
                            <CircleWavyQuestionIcon className={clsx(questionIconClassName)} />
                        </IconButton>

                        <IconButton
                            className={classes.iconButton}
                            aria-owns={open ? 'simple-popper' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleClick}
                            disableRipple
                        >
                            {/*<StyledBadge*/}
                            {/*    anchorOrigin={{*/}
                            {/*        vertical: 'bottom',*/}
                            {/*        horizontal: 'right',*/}
                            {/*    }}*/}
                            {/*    variant="dot"*/}
                            {/*>*/}
                            <Avatar src={loadAvatarImage} alt="profile image" className={classes.avatar}>
                                {UTIL.getFirstCapitalLetter(user.name)}
                            </Avatar>
                            {/*</StyledBadge>*/}
                        </IconButton>

                        <Popover
                            id="simple-popper"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={this.handleClose}
                            className={classes.popover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Box className={classes.popoverIn}>
                                <Box display="flex" alignItems="center" style={{ marginLeft: 15 }}>
                                    <Box className={classes.avatarMargin}>
                                        {/*<StyledBadgeMember*/}
                                        {/*    anchorOrigin={{*/}
                                        {/*        vertical: 'bottom',*/}
                                        {/*        horizontal: 'right',*/}
                                        {/*    }}*/}
                                        {/*    variant="dot"*/}
                                        {/*>*/}
                                        <Avatar src={loadAvatarImage} alt="profile image" className={classes.avatarMember}>
                                            {UTIL.getFirstCapitalLetter(user.name)}
                                        </Avatar>
                                        {/*</StyledBadgeMember>*/}
                                    </Box>
                                    <Box>
                                        <Typography className={classes.nameText}>{user.name}</Typography>
                                        <Typography className={classes.emailText} noWrap>
                                            {user.email}
                                        </Typography>
                                        {/*<FormControl variant="outlined" className={classes.formControl}>*/}
                                        {/*    <Select*/}
                                        {/*        value={this.state.filter}*/}
                                        {/*        onChange={this.handleChangeFilter}*/}
                                        {/*        displayEmpty*/}
                                        {/*        name="상황"*/}
                                        {/*        className={classes.select}*/}
                                        {/*        IconComponent={() => (*/}
                                        {/*            <Box style={{ width: 16, height: 16, marginLeft: 5, marginRight: 5 }}>*/}
                                        {/*                <ArrowDownIcon />{' '}*/}
                                        {/*            </Box>*/}
                                        {/*        )}*/}
                                        {/*    >*/}
                                        {/*        <MenuItem value="온라인" className={classes.menuText}>*/}
                                        {/*            온라인*/}
                                        {/*        </MenuItem>*/}
                                        {/*    </Select>*/}
                                        {/*</FormControl>*/}
                                    </Box>
                                </Box>
                                <MenuList>
                                    <MenuItem
                                        className={clsx(classes.menuItem, classes.menuItemLine)}
                                        onClick={this.handleClickProfileSettings}
                                        disableRipple
                                    >
                                        프로필 설정
                                    </MenuItem>
                                    <MenuItem
                                        className={classes.menuItem}
                                        disableRipple
                                        onClick={() => {
                                            if(window.flutter_inappwebview)
                                                window.flutter_inappwebview.callHandler('Logout');

                                            this.props.authStore.doLogout({
                                                moveToHome: () => {
                                                    navigate('/');
                                                },
                                            });
                                        }}
                                    >
                                        로그아웃
                                    </MenuItem>
                                </MenuList>
                            </Box>
                        </Popover>
                    </Box>
                </Box>
                <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} lightBackground className={classes.toastsStyle} />
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(
        inject(
            'orgStore',
            'authStore',
            'classStore',
            'userStore',
            'avatarStore',
            'roomStore',
            'searchStore',
            'noticeStore',
            'paginationStore',
            'neoPenStore',
        )(observer(TopBar)),
    ),
);
