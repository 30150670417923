import React, { useEffect, useState } from 'react';
import { IconButton, InputBase, Paper } from '@material-ui/core';
import { ReactComponent as MagnifyingGlassIcon } from '../common/images/MagnifyingGlassIcon.svg';
import { makeStyles } from '@material-ui/core/styles';

const RESPONSE_KEY_CODE = {
    ENTER: 13,
};

const useStyles = makeStyles(_theme => ({
    root: {},
    searchBox: props =>
        props.isMobile
            ? {
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  boxShadow: 'none',
                  backgroundColor: '#eee',
                  padding: '0px 9px',
                  margin: '12px 0',
                  borderRadius: 7,
                  '& .MuiInputBase-root': {
                      width: '100%',
                  },
                  '& input': {
                      width: '98%',
                      fontSize: '0.75rem',
                  },
              }
            : {
                  display: 'flex',
                  boxShadow: 'none',
                  border: '1px solid #C4C4C4',
                  padding: '4px 9px',
                  margin: '50px 0 20px',
                  borderRadius: 7,
                  '& .MuiInputBase-root': {
                      width: '100%',
                  },
                  '& input': {
                      width: '98%',
                      fontSize: '0.75rem',
                  },
              },
    iconButton: props =>
        props.isMobile
            ? {
                  padding: 8,
                  // backgroundColor: '#f8f8f8',
                  backgroundColor: 'transparent',
                  '&:hover': {
                      backgroundColor: '#f8f8f8',
                  },
              }
            : {
                  padding: 8,
                  // backgroundColor: '#f8f8f8',
                  backgroundColor: 'transparent',
                  '&:hover': {
                      // backgroundColor: '#f8f8f8',
                      backgroundColor: 'transparent',
                  },
                  '& svg': {
                      width: 18,
                      height: 18,
                      opacity: 0.3,
                  },
              },
}));

export default function CustomSearchComponent({ searchingKeyword, isMobile, placeholderText, isLoading, fetchDataByKeyword }) {
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        console.log('useEffect : >> ', keyword);
        console.log('useEffect : >> ', searchingKeyword);
        if (searchingKeyword) {
            setKeyword(searchingKeyword);
        }
    }, [isLoading]);

    useEffect(() => {
        if (!searchingKeyword) {
            setKeyword(searchingKeyword);
        }
    }, [searchingKeyword]);

    const handleChangeKeyword = e => {
        const insertKeyword = e.target.value;
        console.log('[CustomSearchComponent] change Keyword = ', insertKeyword);
        setKeyword(insertKeyword);
    };

    const handleKeyUp = e => {
        if (e.keyCode === RESPONSE_KEY_CODE.ENTER) {
            console.log('[CustomSearchComponent] handleKeyUp keyword : >> ', keyword);
            const isInvalid = isLoading || !keyword;
            if (isInvalid) {
                return false;
            }

            fetchDataByKeyword(keyword);
        }
    };

    const handleClick = _e => {
        console.log('[CustomSearchComponent] handleClick keyword : >> ', keyword);
        const isInvalid = isLoading || !keyword;
        if (isInvalid) {
            return false;
        }
        fetchDataByKeyword(keyword);
    };

    const classes = useStyles({ isMobile: isMobile });

    return (
        <div>
            <Paper className={classes.searchBox}>
                <InputBase value={keyword} placeholder={placeholderText} onChange={handleChangeKeyword} onKeyDown={handleKeyUp} />
                <IconButton className={classes.iconButton} aria-label="Search" disableRipple onClick={handleClick}>
                    <MagnifyingGlassIcon />
                </IconButton>
            </Paper>
        </div>
    );
}
