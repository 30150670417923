import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import weekday from 'dayjs/plugin/weekday';
import duration from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isTomorrow from 'dayjs/plugin/isTomorrow';

dayjs.locale('ko');
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);
dayjs.extend(weekday);
dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(isTomorrow);

class DateUtil {
    constructor() {
        this.timeZone = 'Asia/Seoul';
        this.language = 'ko';
        this.FORMAT = {
            DEFAULT_FORMAT: 'YYYY-MM-DD HH:mm',
            FULL_FORMAT: 'YYYY-MM-DD HH:mm:ss',
            DATE_FORMAT: 'YYYY-MM-DD',
            DAY_FORMAT: 'YYYY-MM-DD-(ddd)',
            AMPM_FORMAT: 'YYYY-MM-DD hh:mm A',
            SIMPLE_TIME_FORMAT: 'hh:mm',
            SIMPLE_DATE_AND_TIME_AMPM_FORMAT: 'MM-DD hh:mm A',
            SIMPLE_TIME_AMPM_FORMAT: 'A hh:mm',
            DOT_DATE_FORMAT: 'YYYY.MM.DD.',
            SINGLE_DOT_DATE_FORMAT: 'YYYY.M.D.',
            DOT_FORMAT: 'YYYY.M.D. (ddd)',
            SINGLE_DIGIT_DOT_DATE_FORMAT: 'YYYY.M.D.',
            FULL_DATE_DOT_FORMAT: 'YYYY.M.D. HH:mm',
            FULL_DATE_AMPM_DOT_FORMAT: 'YYYY.M.D. A hh:mm',
            SIMPLE_DATE_AMPM_DOT_FORMAT: 'MM.DD. hh:mm A',
            SIMPLE_AMPM: 'A',
            DAY: 'DD',
            SIMPLE_DAY: 'ddd',
            SINGLE_DIGIT_DOT_DATE_FORMAT_KOREAN: 'YYYY년 M월 D일',
        };
    }

    setTimeZone(zone) {
        this.timeZone = zone;
    }

    setLanguage(languageCode) {
        this.language = languageCode;
    }

    getTimeZone() {
        return this.timeZone;
    }

    getLanguage() {
        return this.language;
    }

    getNow() {
        return this.getTimeZoneDate(dayjs(new Date()), this.FORMAT.DEFAULT_FORMAT);
    }

    getNowDate() {
        return this.getTimeZoneDate(dayjs(new Date()), this.FORMAT.DATE_FORMAT);
    }

    getTimeZoneDate(date, format) {
        return dayjs(date).tz(this.timeZone).locale(this.language).format(format);
    }

    getUTCDate(date) {
        return dayjs(date).tz('UTC');
    }

    getTimeZoneLanguage(date) {
        return dayjs(date).locale(this.language);
    }

    convertTimeZoneToUTC(date) {
        return dayjs(date).tz('UTC');
    }

    convertUTCToTimeZone(date, format) {
        return this.getTimeZoneDate(dayjs(date).tz('UTC', true), format);
    }

    getWeek(date) {
        // console.log("date", date);
        const selectedDay = date ? dayjs(date).format('YYYY-MM-DD') : dayjs().tz(this.timeZone).format('YYYY-MM-DD');
        // const timeZoneDayDiff = dayjs().diff(dayjs(dayjs().tz(this.timeZone).format('YYYY-MM-DD')), 'day');

        // const weeks = 6;
        // const currentWeekDay = date ? dayjs(selectedDay).weekday() : dayjs(selectedDay).weekday() + timeZoneDayDiff;
        // const startWeekDayDiff = currentWeekDay;
        // const endWeekDayDiff = weeks - currentWeekDay;

        // const weekOfStartDay = dayjs(date).subtract(startWeekDayDiff, 'day').format("YYYY-MM-DD");
        // const weekOfEndDay = dayjs(date).add(endWeekDayDiff, 'day').format("YYYY-MM-DD");

        return { selectedDay: selectedDay, weekOfStartDay: selectedDay, weekOfEndDay: selectedDay };
    }

    getLocalTimeZone() {
        return dayjs.tz.guess();
    }

    getStartOfDateTimeByUnit(dateTime, unitName) {
        return dayjs(dateTime).tz(this.timeZone).locale(this.language).startOf(unitName);
    }

    getEndOfDateTimeByUnit(unitName) {
        return dayjs().endOf(unitName);
    }

    getTimeZoneDateNoFormat(date) {
        return dayjs(date).tz(this.timeZone).locale(this.language);
    }

    getStartAndEndDateTimeOfMonth(dateTime) {
        const startDateTimeOfMonth = this.getTimeZoneDateNoFormat(dateTime).startOf('month');
        const endDateTimeOfMonth = this.getTimeZoneDateNoFormat(dateTime).endOf('month');

        return { startDateTime: startDateTimeOfMonth, endDateTime: endDateTimeOfMonth };
    }

    getTodayByCalendar() {
        const today = dayjs().tz(this.timeZone).locale(this.language).toDate();
        return { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() };
    }

    getNoticeTimeDifferentMessage(createdDatetime) {
        let timeDifferentMsg = '';
        const convertedStartDatetime = this.convertUTCToTimeZone(createdDatetime, this.FORMAT.FULL_FORMAT);

        const now = dayjs();
        const timeDiff = now.diff(convertedStartDatetime, 'minute');
        if (timeDiff < 1) {
            timeDifferentMsg = '방금전';
        } else if (timeDiff < 60) {
            timeDifferentMsg = `${timeDiff}분전`;
        } else if (timeDiff < 1440) {
            timeDifferentMsg = `${Math.floor(timeDiff / 60)}시간전`;
        } else {
            timeDifferentMsg = `${Math.floor(timeDiff / 1440)}일전`;
        }

        return timeDifferentMsg;
    }

    getIsSameOrAfter(from, to) {
        if (!from || !to) {
            return false;
        }

        return dayjs(from).isSameOrAfter(to);
    }

    getIsSameOrBefore(from, to) {
        if (!from || !to) {
            return false;
        }

        return dayjs(from).isSameOrBefore(to);
    }
}

export const DATE_UTIL = new DateUtil();
