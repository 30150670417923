import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, Dialog, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import clsx from 'clsx';
import ResetPasswordDialogComponent from './ResetPasswordDialogComponent';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastsStore } from 'react-toasts';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            minWidth: 300,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 601px)': {
                width: 390,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 18,
        '@media all and (max-width: 600px)': {
            marginBottom: 5,
        },
    },
    topButton: {
        borderRadius: 4,
        border: '1px solid #bfbfbf',
        padding: '6px 10px 3px',
        background: '#fff',
        fontSize: '0.875rem',
        color: '#000',
        '&:hover': {
            background: '#fff',
        },
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
            padding: '6px 10px 3px',
        },
    },
    textStyle: {
        fontSize: '1rem',
        fontWeight: 'normal',
        color: '#333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    bold: {
        width: 110,
        fontWeight: 'bold',
        '@media all and (max-width: 600px)': {
            width: 85,
        },
    },
    textWidth: {
        '@media all and (max-width: 600px)': {
            width: 'calc(100% - 85px)',
        },
    },
    boxMarginAuto: {
        margin: '0 auto',
    },
    avatar: {
        width: 120,
        height: 120,
        margin: '0 auto 24px',
        background: '#a3a8af',
        '& svg': {
            width: 72,
            height: 72,
            '& path': {
                fill: '#fff',
            },
        },
        '@media all and (max-width: 600px)': {
            width: 80,
            height: 80,
            margin: '0 auto 15px',
            '& svg': {
                width: 52,
                height: 52,
            },
        },
    },
    boxMarginBottom: {
        marginBottom: 21,
    },
    buttonStyle: {
        width: '100%',
        margin: '26px auto 0',
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        padding: '12px 0',
        borderRadius: 7,
        '&:hover': {
            background: '#0097ff',
        },
        '& .Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
            padding: '6px 0',
            borderRadius: 4,
            margin: '20px auto 0',
        },
    },
});

class ViewAccountDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleClickCopy = () => {
        ToastsStore.info('복사된 계정정보를 학생에게 전달해주세요.', 1500);
    };

    handleClickModify = () => {
        this.props.handleClickViewAccountClose();
        this.props.handleClickModifyAccount();
    };

    handleResetPassword = () => {
        const { userDetail } = this.props;
        this.props.resetPassword(userDetail);
    };

    render() {
        const { classes, userDetail, viewAccount, selectedUser } = this.props;
        const copyUserText = userDetail ? `${userDetail.email} / ${userDetail.name}` : '';

        return (
            <Dialog open={viewAccount} className={classes.dialogBox} disableEnforceFocus>
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.titleBox}>
                    {/* 학생이 직접 생성한 계정 보기 할땐 -> 게정복사 버튼 x */}
                    <CopyToClipboard text={copyUserText} onCopy={this.handleClickCopy}>
                        <Button className={classes.topButton} disableRipple>
                            계정 복사
                        </Button>
                    </CopyToClipboard>
                    <IconButton className={classes.iconButton} onClick={this.props.handleClickViewAccountClose} disableRipple>
                        <DialogCloseIcon />
                    </IconButton>
                </Box>
                <Box className={classes.boxMarginAuto}>
                    <Avatar className={classes.avatar} src={selectedUser.image ? selectedUser.image : ''}>
                        <AsideUserIcon />
                    </Avatar>
                    <Box display="flex" alignItems="center" className={classes.boxMarginBottom}>
                        <Typography className={clsx(classes.textStyle, classes.bold)}>이메일</Typography>
                        <Typography className={clsx(classes.textStyle, classes.textWidth)}>{this.props.userDetail.email}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" className={classes.boxMarginBottom}>
                        <Typography className={clsx(classes.textStyle, classes.bold)}>비밀번호</Typography>
                        {/* 학생이 직접 생성한 계정 보기 할땐 -> 초기화 버튼 x , ****비밀번호 처리 */}
                        {/*<Typography className={classes.textStyle}>초기화</Typography>*/}

                        <ResetPasswordDialogComponent resetPassword={this.handleResetPassword} />
                        {/*<Typography className={classes.textStyle}>****************</Typography>*/}
                    </Box>
                    <Box display="flex" alignItems="center" className={classes.boxMarginBottom}>
                        <Typography className={clsx(classes.textStyle, classes.bold)}>이름</Typography>
                        <Typography className={clsx(classes.textStyle, classes.textWidth)}>{this.props.userDetail.name}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Typography className={clsx(classes.textStyle, classes.bold)}>스마트펜 정보</Typography>
                        <Typography className={clsx(classes.textStyle, classes.textWidth)}>{`******${this.props.smartPenAddress}`}</Typography>
                    </Box>
                </Box>

                {/* 학생이 직접 생성한 계정 보기 할땐 -> 스마트펜 정보 수정으로 text 변경 */}
                <Button className={classes.buttonStyle} disableRipple onClick={this.handleClickModify}>
                    편집
                </Button>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ViewAccountDialogComponent);
