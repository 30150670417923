import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Tab, Tabs } from '@material-ui/core';

const styles = _theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        background: '#fff',
        marginTop: 56,
        '@media all and (min-width: 601px)': {
            marginTop: 66,
        },
    },
    appBar: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '18px 20px',
        boxSizing: 'border-box',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.25)',
        '& .MuiTabs-root': {
            minHeight: 0,
        },
        '& button': {
            minWidth: 50,
            minHeight: 25,
            position: 'relative',
            opacity: 1,
            marginRight: 10,
            '& .MuiTab-wrapper': {
                fontSize: '0.75rem',
                '@media all and (min-width: 601px)': {
                    fontSize: '0.875rem',
                },
            },
        },
        '& button.Mui-selected': {
            backgroundColor: '#0097FF',
            color: '#fff',
            borderRadius: 50,
            overflow: 'inherit',
        },
        '& .MuiTabs-indicator': {
            display: 'none',
            width: 0,
        },
        '@media all and (min-width: 601px)': {
            '& button': {
                minWidth: 90,
                minHeight: 40,
            },
        },
    },
});

class MobileSearchTopComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Box className={classes.appBar}>
                    <Tabs value={this.props.searchTab} onChange={this.props.handleChange}>
                        <Tab label="클래스" />
                        <Tab label="가입한 클래스" disableRipple />
                    </Tabs>
                </Box>
            </div>
        );
    }
}

export default withStyles(styles)(MobileSearchTopComponent);
