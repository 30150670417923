import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, InputBase } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { withRouter } from '../../../components/WithRouter';
import { PATH_UTIL } from '../../../common/util/path.util';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        background: '#f5f5f5',
        height: 61,
    },
    hiddenRoot: {
        display: 'none',
    },
    appBar: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '13px 10px',
        boxSizing: 'border-box',
    },
    logoImgBox: {
        maxWidth: 120,
        width: 'auto',
        '@media all and (min-width: 601px)': {
            maxWidth: 140,
            maxHeight: 32,
        },
    },
    selectBox: {
        width: '100%',
        fontSize: '0.875rem',
        '& svg': {
            marginLeft: -25,
        },
    },
    buttonStyle: {
        background: 'transparent',
        color: '#0d0d0d',
        fontWeight: 'bold',
        fontSize: '1rem',
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    iconButton: {
        fontSize: '0.938rem',
        color: '#000',
        fontWeight: 600,
        padding: 0,
        marginLeft: 20,
        '&:hover': {
            background: 'transparent',
        },
    },
    toastsStyle: {
        '& .toast': {
            minHeight: '25px !important',
            borderRadius: '30px !important',
            fontSize: '0.938rem !important',
            color: '#fff!important',
            backgroundColor: '#858585!important',
        },
    },
});

const BootstrapInput = withStyles(theme => ({
    root: {
        marginRight: 35,
    },
    input: {
        background: '#fff',
        border: '1px solid #bfbfbf',
        padding: '8px 10px',
        borderRadius: 4,
        fontSize: '0.875rem',
        '&:focus': {
            borderRadius: 4,
            background: '#fff',
        },
        '&.MuiSelect-select': {
            paddingRight: 25,
        },
    },
}))(InputBase);

class MobileHomeTopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLocale: 'ko',
            anchorEl: null,
        };
    }

    componentDidMount() {
        this.setState({ selectedLocale: this.props.localeStore.locale });
    }

    handleLanguageSelect = selectLanguage => {
        this.setState({ selectedLocale: selectLanguage }, () => {
            this.props.localeStore.changeLocale(this.state.selectedLocale);
        });
    };

    handleClickLogin = () => {
        this.props.navigate('/signin');
    };

    render() {
        const { classes, navigate } = this.props;
        const { orgImages } = this.props.orgStore;
        const path = PATH_UTIL.getPath(this.props.location.pathname);

        return (
            <div className={path === 'studylive' || path === 'termsofuse' || path === 'privacypolicy' ? classes.hiddenRoot : classes.root}>
                <Box className={classes.appBar}>
                    <Box display="flex" alignItems="center">
                        {orgImages.Logo !== undefined && orgImages.Logo !== '' && (
                            <img
                                src={orgImages.Logo}
                                alt="LogoImage"
                                style={{
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                    verticalAlign: 'middle',
                                }}
                                className={classes.logoImgBox}
                                onClick={() => navigate('/')}
                            />
                        )}
                        {/*<Button className={classes.iconButton} onClick={() => window.open('/studylive', '_blank')} disableRipple>*/}
                        {/*    스토어*/}
                        {/*</Button>*/}
                    </Box>
                    <Box display="flex" alignItems="center">
                        {/*<Select*/}
                        {/*    native*/}
                        {/*    value={this.state.selectedLocale}*/}
                        {/*    onChange={e => this.handleLanguageSelect(e.target.value)}*/}
                        {/*    className={classes.selectBox}*/}
                        {/*    input={<BootstrapInput />}*/}
                        {/*    IconComponent={() => <SelectArrow />}*/}
                        {/*>*/}
                        {/*    {Object.entries(Languages).map(([key, value]) => (*/}
                        {/*        <option key={key} value={key}>*/}
                        {/*            {value}*/}
                        {/*        </option>*/}
                        {/*    ))}*/}
                        {/*</Select>*/}
                        <Button className={classes.buttonStyle} disableRipple onClick={this.handleClickLogin}>
                            <FormattedMessage id={'sign_in'} />
                        </Button>
                    </Box>
                </Box>
                <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} lightBackground className={classes.toastsStyle} />
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('orgStore', 'localeStore')(injectIntl(observer(MobileHomeTopBar)))));
