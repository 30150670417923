import React, { Component } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {Box, Typography, Button, Grid, Tab, Tabs, Slider, Tooltip, CircularProgress, TextField} from '@material-ui/core';
import { ReactComponent as PlusCircleIcon } from '../../common/images/PlusCircleIcon.svg';
import TopBarResult from '../topBar/TopBarResult';
import { inject, observer } from 'mobx-react';
import { withRouter } from '../../components/WithRouter';
import { ReactComponent as ArrowRiseIcon } from '../../common/images/ArrowRiseIcon.svg';
import { ReactComponent as BoxInfoIcon } from '../../common/images/BoxInfoIcon.svg';
import { ReactComponent as RobotBtnIcon } from '../../common/images/RobotBtnIcon.svg';
import { ReactComponent as ConvertBtnIcon } from '../../common/images/ConvertBtnIcon.svg';
import { ReactComponent as ConvertRightIcon } from '../../common/images/ConvertRightIcon.svg';
import { ReactComponent as InfoAiIcon } from '../../common/images/InfoAiIcon.svg';
import { ReactComponent as LockedIcon } from '../../common/images/LockedIcon.svg';
import { ReactComponent as RobotBorderLine } from '../../common/images/RobotBorderLine.svg';
import { ReactComponent as CloseTabBtnIcon } from '../../common/images/CloseTabBtnIcon.svg';
import Textarea from '@mui/joy/Textarea'
import { ResponsiveRadialBar, RadialBar } from '@nivo/radial-bar';
import NeoPenViewer from "../neoPen/NeoPenViewer";
import CustomTooltip from "../../components/CustomTooltip";
import TopUpIcon from '../../common/images/TopUpIcon.png';
import ReactWordcloud from 'react-wordcloud';
import GradientRadialBarChart from "../../components/GradientRadialBarChart";
import ScoreBarChart from "../../components/ScoreBarChart";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel";

const styles = theme => ({
    root: {
        '& *': {
            fontFamily: 'NanumSquareRoundOTF!important',
        },
        // backgroundColor: '#fff',
        // borderBottom: '1px solid #D3D5D5',
    },
    wrap: {
        '@media all and (min-width: 1500px)': {
            width: 1440,
        },
        width: 1200,
        margin: '0 auto',
        padding: '17px 41px 17px 41px',
        boxSizing: 'border-box',

        // display: 'flex',
        // alignItems: 'flex-start',
        // justifyContent: 'flex-end',
    },
    noteEvaluation: {
        margin: '83px 0px 54px',
        height: '736px',
        background: '#eeeeee',
        borderRadius: '8px',
        padding: '17px',
        display: 'flex',
        justifyContent: 'center',
    },
    noteOriginal: {
        background: '#fff',
        boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.25)',
        height: '740px',
        width: '40%',
        borderRadius: '10px',
        // padding: '15px 18px 30px 18px',
        position: 'relative',
    },
    // noteEdit: {
    //     marginLeft: '24px',
    // },
    noteTag: {
        position: 'absolute',
        top: '-50px',
        left: '0px',
        borderRadius: '7px 7px 0px 0px',
        padding: '8px 21px',
        background: '#eeeeee',
    },
    noteTagRight: {
        left: '16px',
    },
    noteTagText: {
        fontSize: '1rem',
    },
    editBtnWrap: {
        display: 'flex',
        justifyContent: 'right',
        padding: '20px',
    },
    editBtn: {
        color: '#0097ff',
        border: '1px solid #0097ff',
        width: '80px',
        height: '38px',
        fontSize: '0.938rem',
    },
    editWrap: {
        minWidth: '300px',
        minHeight: '300px',
        marginTop: '30px',
        height: 'calc(100% - 225px)',
        marginBottom: '10px',
    },
    resultBtnWrap: {
        textAlign: 'center',
    },
    resultBtn: {
        background: '#0097ff',
        width: '200px',
        height: '44px',
        color: '#fff',
        fontSize: '0.875rem',
        letterSpacing: '-0.14px',
        fontWeight: 'bold',
        margin: '0 10px',
        '&:hover': {
            background: '#0097ff'
        },
        '& svg': {
            marginRight: '5px',
        }

    },
    convertBtn: {
        background: '#000',
        width: '200px',
        height: '44px',
        color: '#fff',
        fontSize: '0.875rem',
        letterSpacing: '-0.14px',
        fontWeight: 'bold',
        margin: '0 10px',
        '&:hover': {
            background: '#000'
        },
        '& svg': {
            marginRight: '5px',
        }

    },

    cancleBtn: {
        background: '#c2c2c2',
        '&:hover': {
            background: '#c2c2c2'
        },
    },

    //종합평가

    sectionTitle: {
        fontSize: '1.25rem',
        letterSpacing: '-0.6px',
        fontWeight: 600,
        color: '#000',
        lineHeight: 'normal',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginLeft: '7px',
        }
    },
    resultEvaluation: {
        background: 'rgba(72, 99, 131, 0.05)',
        border: '1px solid #e1e5f2',
        padding: '16px 22px',
        height: 'auto',
        marginTop: '14px',
        display: 'block',
        borderRadius: '8px'
    },


    BoxFlex: {
        display: 'flex',
        // alignItems: 'center',

    },
    boxTitleWrap: {
        background: 'rgba(232, 235, 239, 0.8)',
        height: '178px',
        width: '100%',
        maxWidth: '71px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    boxTitle: {
        fontSize: '0.875rem',
        color: '#1c1c1c',
    },
    scoreInner: {
        // display: 'flex',
        // justifyContent: 'row',
        width: '100%',
        textAlign: 'center',
        padding: '35px 0px',



    },
    score: {
        fontSize: '4.063rem',
        fontWeight: '800',
        '& span': {
            fontSize: '1.25rem',
            marginLeft: '7px',
        },

    },
    RiseWrap: {
      display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10px',
        '& span': {
          fontSize: '1.188rem',
            marginRight: '5px',
        },

    },
    leftWrap: {
        width: '100%',
        maxWidth: '249px',
        height: '178px',
        background: '#fff',
        borderRadius: '8px',
        border: '1px solid #d3d9e1',
        position: 'relative'

    },
    rightWrap: {
        width: '100%',

        height: '178px',
        marginLeft: '18px',
        background: '#fff',
        borderRadius: '8px',
        border: '1px solid #d3d9e1',
    },

    lockedWrap: {
      background: 'rgba(255, 255, 255, 0.85)',
        position: 'absolute',
        top: '0px',
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    lockedText: {
        fontSize: '1.188rem',
        fontWeight: 'bold',
        letterSpacing: '-0.57px',
        textAlign: 'center',
    },
    textArea: {
        padding: '16px 28px',
    },
    textAreaDetail: {
        padding: '16px 28px',
        borderRadius: '8px',
        background: 'rgba(243,240,240,0.85)',
        border: '1px solid #d3d9e1',
        maxWidth:715,
        minWidth:715,
        marginBottom:12,
    },
    textExplain: {
        fontSize: '0.938rem',
        lineHeight: '24px',
        color: '#000',
    },

    scoreArea: {
        padding: '37px 52px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

    },

    countWrap: {
        textAlign: 'center',
        borderRight: '1px solid #d3d9e1',
        paddingRight: '3%',
    },
    countNumber: {
        fontSize: '2.5rem',
        fontWeight: '900',
    },
    countAmount: {
        fontSize: '0.75rem',
    },
    countDesc: {
        fontSize: '0.813rem',
        color: '#666',
        fontWeight: 'bold',
        marginTop: '24px'
    },


    BorderLine: {
        textAlign: 'center',
        position: 'relative',
        marginBottom: '40px',
        '& svg': {
          background: '#fff',
        },
        '&:before': {
            content: '""',
            width: '300px',
            height: 2,
            backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: '20px',
            right: '51%',
            zIndex: -1,
        },
        '&:after': {
            content: '""',
            width: '300px',
            height: 2,
            backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: '20px',
            left: '51%',
            zIndex: -1,
        },
    },

    //분석차트 / 분석평가

    column: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '40px',

    },

    chartWrap: {
        width: '100%',
        maxWidth: '639px',
    },
    chartDescWrap: {
        width: '100%',
        maxWidth: '697px'
    },

    chart: {
        // height: '604px',
        display: 'block',
        marginBottom: '22px',
    },

    descEvaluation: {
        background: '#fff',
        border: '1px solid #d3d9e1',
        borderRadius: '8px',
        padding: '36px 32px',
        display: 'flex',
        justifyContent: 'space-between',
    },

    titleDesc: {
        fontSize: '1rem',
        marginTop: '38px',
        fontWeight: 'bold',
        color: '#666',
    },

    borderBlack: {
        border: '2px solid #292929',
        borderRadius: '8px',
        padding: '0px 0px 7px 19px',
        marginTop: '14px',
        height: '258px',

    },

    tabStyle: {
        minHeight: '35px',
        marginBottom: '22px',
        '& .MuiTab-root': {
            minWidth: '90px',
            padding: '7px 29px',
            minHeight: '35px',
            fontSize: '0.75rem',
            letterSpacing: '-0.36px',
            fontWeight: 'bold',
            borderBottom: '3px solid #d3d4d5',
        },

        '& .MuiTabs-indicator': {
            backgroundColor: '#0097ff'
        }
    },

    tabText: {
        fontSize: '0.938rem',
        lineHeight: '24px',
        overflowY: 'scroll',
        height: '200px'
    },



    //영역별 진단


    boxTopWrap: {
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',

    },

    boxTopText: {
        display: 'flex',
        alignItems: 'center',
    },

    innerTitle: {
        fontSize: '1.125rem',
        lineHeight: '0px',
        letterSpacing: '-0.54px',
        marginRight: '6px',
        minWidth:'50px'
    },

    graphWrap: {
        marginTop: '50px',
        width: '400px',
    },

    graphInner: {
        position: 'relative',
    },
    graphStart: {
        position: 'absolute',
        left: '0px',
        top: '-10px',
        fontSize: '0.938rem',
        color: 'rgba(0, 0, 0, 0.5)',
    },
    graphMiddle: {
        position: 'absolute',
        left: '170px',
        top: '-40px',
        fontSize: '0.938rem',
        color: 'rgba(0, 0, 0, 0.5)',
    },
    graphAverage: {
        position: 'absolute',
        left: '70%',
        top: '-40px',
    },
    graphAverageInner: {
        fontSize: '0.938rem',
        color: 'rgba(0, 0, 0, 0.5)',
        position: 'relative',
        marginTop:20,
        '&:after': {
            content: '""',
            width: 1,
            height: '55px',
            borderColor: 'rgba( 0, 0, 0, 0.5)',
            display: 'block',
            position: 'absolute',
            bottom: '-65px',
            // right: '0px',
            borderRightStyle: 'dashed',
            zIndex: '99',

        },

    },
    graphFinish: {
        position: 'absolute',
        right: '0px',
        top: '-10px',
        fontSize: '0.938rem',
        color: 'rgba(0, 0, 0, 0.5)',

    },


    sectionLeft: {
        width: '100%',
        maxWidth: '294px',
        height: '265px',
        background: '#fff',
        borderRadius: '8px',
        border: '1px solid #d3d9e1',
        justifyContent: 'center',
        position: 'relative',
    },

    sectionLeftHalf: {
        width: '100%',
        maxWidth: '642px',
        height: '265px',
        background: '#fff',
        borderRadius: '8px',
        border: '1px solid #d3d9e1',
        justifyContent: 'center',
        position: 'relative',

    },

    subInfo: {
        position: 'absolute',
        top: '-21px',
        right: '0px',
        fontSize: '0.875rem',
        color: '#666',
    },

    sectionRight: {
        width: '100%',
        height: '215px',
        marginLeft: '18px',
        background: '#fff',
        borderRadius: '8px',
        border: '1px solid #d3d9e1',
        padding: '25px',
        justifyContent: 'center',
    },
    rightInner: {
        display: 'flex',
    },
    wordLeft: {
        borderRight: '1px solid #d3d9e1',
        paddingRight: '92px',
        marginRight: '92px',

    },
    wordRight: {
        overflowY: 'auto'
    },
    sectionBoxTitle: {
        fontSize: '1.25rem',
        textAlign: 'center'
    },

    boxLineWrap: {
        display: 'flex',
    },
    boxLine: {
        border: '1px solid #dbe2f2',
        width: '83px',
        height: '40px',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.125rem',
        letterSpacing: '-0.36px',
        margin: '3px'

    },


    sectionScoreInner: {
        paddingTop: '52px',
        textAlign: 'center',

    },
    borderLineRight: {
        position: 'relative',
        '&:after': {
            content: '""',
            width: 1,
            height: '75%',
            backgroundColor: '#d3d9e1',
            display: 'block',
            position: 'absolute',
            bottom: '40px',
            right: '0px',

        },
    },


    sectionScore: {
        fontSize: '5rem',
        fontWeight: '800',

    },

    sectionRiseWrap: {
        marginTop: '58px',
        fontSize: '1.25rem',
        color: '#666',
    },

    rightInnerText: {
        fontSize: '1rem',
        lineHeight: '24px',
    },

    rightInnerHighlightText: {
        fontSize: '1rem',
        lineHeight: '21px',
        '& .convert-text': {
            '&:hover': {
                backgroundColor: '#A287D0',
            },
            '& .convert-group-description': {
                display: 'none'
            }
        },
    },


    boxListContent: {

    },
    listContent: {
        // display: 'flex',
        // justifyContent: 'center',
        margin: '0 48px'

    },

    listWrap: {
        display: 'flex',
        marginBottom: '3px',
    },

    listNumber: {
        border: '1px solid #d3d9e1',
        width: '41px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '3px',

    },

    listWord: {
        border: '1px solid #d3d9e1',
        width: '256px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    widthListWord: {
        width: '154px',

    },

    topicWrap: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },

    topicWord: {
        border: '1px solid #d3d9e1',
        width: '67px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        fontSize: '0.75rem',
    },

    topicWordOn: {
        background: '#8676ff',
        color: '#fff',
    },

    topicTitle: {
        textAlign: 'center',
        fontSize: '1.25rem',
        marginBottom: '10px'

    },

    topicContent: {
        margin: '0 20px'
    },
    topTitleText: {
        background: '#000',
        color: '#fff',
        padding: '10px 0px 10px 30px',
        borderRadius: '7px 7px 0px 0px',
    },
    rightTitle: {
        background: '#0097ff',
    },
    editWrapNew: {
        padding: '0px 20px',
        height: '550px',
    },
    wrapChart: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '50px',
    },
    chartBox: {
        width: '50%',
        maxWidth: '662px',
    },
    chartBoxInner: {
        background: 'rgba(72, 99, 131, 0.05)',
        borderRadius: '8px',
        padding: '20px 40px',
        boxSizing: 'border-box',
        marginTop: '10px',
    },
    chartContent: {
        background: '#fff',
        width: '574px',
        height: '574px',
        boxSizing: 'border-box'
    },
    chartTitle: {
        fontSize: '20px',
    },
    chartText: {
        fontSize: '50px',
        fontWeight: 900,
        '& span': {
            fontSize: '20px',
            fontWeight: 500,
            marginLeft: '10px'
        }
    },
    sectionResultBox: {
        background: 'rgba(72, 99, 131, 0.05)',
        borderRadius: '8px',
        padding: '30px 40px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    resultContent: {
        background: '#fff',
        width: '100%',
        maxWidth: '300px',
        height: '462px',
        padding: '20px 0px 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bottomBtnWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '250px',
    },
    btnResult: {
        border: '1px solid #000',
        borderRadius: '8px',
        width: '95px',
        height: '44px',
        fontSize: '1rem',
        fontWeight: 600,
    },
    // btnResultActive: {
    //     background: '#000',
    //     color: '#fff',
    // },
    commentText: {
        fontSize: '20px',
        fontWeight: 600,
    },
    commentBtn: {
        minWidth: 'auto',
        background: '#fff',
        borderRadius: '8px',
        border: '1px solid #000',
        padding: '0px 7px',
        marginLeft: '10px',
        '&:hover': {
            background: '#fff'
        }
    },
    closeTabBtn: {
        padding: '0px',
        minWidth: 'auto',
        '&:hover': {
            background: 'transparent'
        }
    },
    resultTitle: {
        fontSize: '1.875rem',
        fontWeight: 600,
    },
    detailBox: {
        background: '#dce7f5',
        borderRadius: '8px',
        padding: '20px',
    },
    activeOnWrap: {
        position: 'relative',
        '& button': {
            background: '#000',
            color: '#fff',
            '&:hover': {
                background: '#000'
            }
        },
    },
    activeOn: {
        position: 'absolute',
        bottom: '-78px',
    },
    activeOnWrapOff: {
        position: 'relative',
        '& div': {
            display: 'none',
        },
        '& button': {
            border: '1px solid #000',
            background: '#fff',
            color: '#000',
            '&:hover': {
                background: '#fff',
                color: '#000',
            }
        }
    },

    reCountWrap: {
        border: '1px solid #d3d9e1',
        width: '294px',
        height: '265px',
        boxSizing: 'border-box',
        borderRadius: '8px',
        background: '#fff',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    reWordListWrap: {
        border: '1px solid #d3d9e1',
        width: '996px',
        borderRadius: '8px',
        background: '#fff',
        padding: '30px',
        boxSizing: 'border-box'
    },
    reWordListLeft: {
        padding: '0px 80px 0px 50px',
        borderRight: '0.5px solid #333',
    },
    reCountNum: {
        fontSize: '80px',
        fontWeight: 900,
        marginBottom: '40px',
        marginTop: '20px',
    },
    reWordText: {
        fontSize: '20px',
        fontWeight: 600,
        color: '#666',
    },
    reWordSubText: {
        fontSize: '14px',
        color: '#666',
        marginLeft: '220px'
    },
    reDetailText: {
        fontSize: '18px',
        color: '#000',
        fontWeight: 600,
    },
    reDetailTitle: {
        fontSize: '14px',
        color: '#1c1c1c',
    },
    reAiInfoBtn: {
        padding: '0px',
        minWidth: 'auto',
        marginTop: '15px',
    },
    reEvaluationInfoBtn: {
        padding: '0px',
        minWidth: 'auto',
        marginRight: '7px',
    },
    reEvaluationText: {
        fontSize: '18px'
    },
    reNoticeText: {
        marginLeft: '40px',
        marginTop: '10px'
    },
    reTotalScore: {
        fontSize: '65px',
        color: '#1c1c1c',
        fontWeight: '800',
        '& span': {
            fontSize: '20px',
            marginLeft: '5px'
        },
    },
    reScorePersent: {
        fontSize: '19px',
        marginRight: '5px',
    },
    reBtnWrite: {
        width: '80px',
        height: '38px',
        border: '1px solid #0097ff',
        borderRadius: '7px',
        color: '#0097ff',
        fontSize: '15px',
        fontWeight: 600,
        '&:hover': {
            background: '#fff',
        }
    },
    reBtnDelete: {
        width: '80px',
        height: '38px',
        background: '#f2441d',
        borderRadius: '7px',
        color: '#fff',
        marginLeft: '20px',
        fontSize: '15px',
        fontWeight: 600,
        '&:hover': {
            background: '#f2441d',
        }
    },
    reBtnSave: {
        width: '80px',
        height: '38px',
        background: '#0097ff',
        borderRadius: '7px',
        color: '#fff',
        marginLeft: '20px',
        fontSize: '15px',
        fontWeight: 600,
        '&:hover': {
            background: '#0097ff',
        }
    },
    reBoxText: {
        width: '85px',
        height: '40px',
        border: '1px solid #dbe2f2',
        borderRadius: '4px',
        margin: '5px',
    },
    reText: {
        fontSize: '18px',
        fontWeight: 600,
        textAlign: 'center',
        lineHeight: '40px'
    },
    reTitleText: {
        fontSize: '20px',
        fontWeight: 600,
        textAlign: 'center',
        marginBottom: '20px',
    },
    reAiCountBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRight: '0.5px solid #e5e5e5',
        padding: '0 36px',
        marginTop: '20px'
    },
    reAiCountBoxLast: {
        borderRight: '0px',
    },
    reAiCountNum: {
        fontSize: '40px',
        fontWeight: 800,
        textAlign: 'center',
        margin: '0px 0 0px',
        lineHeight: '43px'
    },
    reAiSubText: {
        fontSize: '12px',
        textAlign: 'center',
        marginBottom: '20px'
    },
    reAiAllNum: {
        fontSize: '13px',
        fontWeight: 600,
        textAlign: 'center',
        color: '#666',
    },

    sectionBoxWrap: {
        background: 'rgba(72, 99, 131, 0.05)',
        border: '1px solid #e1e5f2',
        borderRadius: '8px',
        padding: '10px 10px',
        // marginTop: '10px',
        marginBottom: '50px'
    },
    aiBoxLeft: {
        border: '1px solid #d3d9e1',
        display: 'flex',
        width: '250px',
        height: '176px',
        borderRadius: '4px',
    },
    aiBoxLeftTitle: {
        background: 'rgba(232, 235, 239, 0.8)',
        padding: '10px 0px',
        width: '75px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    aiBoxLeftContent: {
        background: '#fff',
        width: '193px',
        borderRadius: '4px',
        padding: '25px 10px',
        boxSizing: 'border-box'
    },
    aiBoxRightContent: {
        background: '#fff',
        width: '100%',
        borderRadius: '4px',
        padding: '10px 0px',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    aiBoxBottomContent: {
        background: '#fff',
        width: '100%',
        borderRadius: '4px',
        padding: '10px 20px'
    },






    textField2:{
        width: "100%",
        '&.MuiFormControl-root':{
            margin: '7.5px 0 0'
        },
        '& .MuiInputBase-root':{
            height: 140,
            borderRadius: 6,
            boxSizing: 'border-box',
            paddingLeft: 11,
            paddingRight: 11,
            '& input':{
                padding: '8px 13px 8px 0',
                fontSize: '1.125rem',
                letterSpacing: '-0.5px',
                color: '#323232',
                '&::placeholder':{
                    opacity: 0,
                    color: 'red'
                }
            },
        },
        '& .MuiOutlinedInput-notchedOutline':{
            border: '1px solid #e5e5e5'
        },
        '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline':{
            border: '1px solid #444'
        },
        '& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline':{
            border: '1px solid #d91e50'
        },
    },

    // CustomTooltip = ({ value, color }) => {
    //     return (
    //         <div style={{ background: color, padding: '5px', borderRadius: '5px' }}>
    //             <span style={{ color: 'white' }}>{value}</span>
    //         </div>
    //     );
    // };
});

const options = {
    enableTooltip: true,
    deterministic: false,
    fontFamily: "impact",
    fontSizes: [20, 65],
    fontStyle: "normal",
    fontWeight: "bold",
    padding: 1,
    rotations: 3,
    rotationAngles: [0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000
};

function isEmptyObj(obj) {
    return !obj || Object.keys(obj).length === 0 && obj.constructor === Object;
}

class WrittenEvaluationResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 0,
            dialogOpen: false,
            openClassJoinConfirmDialog: false,
            anchorEl: null,
            recognizedData: "",
            isConverting: false,
            selectedFirstResult: undefined,
            modifingOpinion: "",
            modifingOpinionText: "",
            loadingSeconds: 30
        };
    }

    loadingInterval = null;

    componentDidMount() {
        const { classStore, userStore, neoPenStore } = this.props;
        this.initPenViewerData();

        classStore.getEvaluationPathInfo();
        classStore.getTextAnalysisResult();

        // 예상) 학생 email 로 이름 가져와야 할 수도 -> 맨위에 띄워줌
        // neoPenStore 에 user 를 셋팅하지만 , 해당 컴포넌트가 새로 생성되면서 스토어의 값을 사용할 수 없는 것으로 보임
        // 필기 제목? 이름? 도 필요함.
    }

    componentWillUnmount() {
        clearInterval(this.loadingInterval);
    }


    handleClickTabs = (event, tabs) => {
        this.setState({ tabs });
    };

    handleChangeNoteText = (e) => {
        const { classStore } = this.props;
        classStore.changeNoteText(e.target.value);
    }

    handleClickDoEdit = () => {
        const { classStore } = this.props;
        classStore.setNoteEditable(true);
    }

    handleClickShowResult = () => {
        const { classStore } = this.props;
        // classStore.setChartData();
        // classStore.createNoteEvaluationInfo();
        classStore.doTextAnalysis();

        this.resetTimer();
        this.startTimer();
    }
    initPenViewerData = async () => {
        const nPS = this.props.neoPenStore;

        const selectedGroupId = this.props.params.groupId;
        const selectedUserEmail = this.props.params.userEmail;
        const selectedPaperGroupId = this.props.params.paperGroupId;

        if (nPS.selectedUser !== undefined) {
            await nPS.interpreteInkstoreData();
        } else {
            const user = this.props.authStore.loginUser;
            nPS.initNeoPen(user);

            nPS.getSinglePaperGroupByPaperGroupId(selectedGroupId, selectedPaperGroupId);

            const selectedUser = await nPS.getUserInfo(selectedUserEmail);
            nPS.selectUser(selectedUser);

            await nPS.getUserNotes([selectedUser], true);

            await nPS.interpreteInkstoreData();
        }
    }

    startTimer = () => {
        this.loadingInterval = setInterval(() => {
            this.setState((prevState) => {
                if(!this.props.classStore.isDoAnalysisLoading)
                    this.stopTimer();

                return ({ loadingSeconds: Math.max(prevState.loadingSeconds - 1, 0) });
            });
        }, 1000);
    };

    stopTimer = () => {
        clearInterval(this.loadingInterval);
    };

    resetTimer = () => {
        this.stopTimer();
        this.setState({ loadingSeconds: 30 });
    };

    render() {
        const { classes, classStore, userStore } = this.props;

        const PrettoSlider1 = withStyles({
            root: {
                color: '#ff708b',
                height: 15,
            },
            thumb: {
                display: 'none',
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
                '&:focus, &:hover, &$active': {
                    boxShadow: 'inherit',
                },
            },
            active: {},
            valueLabel: {
                left: 'calc(-50% + 4px)',
            },
            track: {
                height: 15,
                borderRadius: 8,
            },
            rail: {
                height: 15,
                borderRadius: 8,
            },

        })(Slider);

        const PrettoSlider2 = withStyles({
            root: {
                color: '#ffba69',
                height: 15,
            },
            thumb: {
                display: 'none',
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
                '&:focus, &:hover, &$active': {
                    boxShadow: 'inherit',
                },
            },
            active: {},
            valueLabel: {
                left: 'calc(-50% + 4px)',
            },
            track: {
                height: 15,
                borderRadius: 8,
            },
            rail: {
                height: 15,
                borderRadius: 8,
            },

        })(Slider);

        const PrettoSlider3 = withStyles({
            root: {
                color: '#01f1e3',
                height: 15,
            },
            thumb: {
                display: 'none',
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
                '&:focus, &:hover, &$active': {
                    boxShadow: 'inherit',
                },
            },
            active: {},
            valueLabel: {
                left: 'calc(-50% + 4px)',
            },
            track: {
                height: 15,
                borderRadius: 8,
            },
            rail: {
                height: 15,
                borderRadius: 8,
            },

        })(Slider);

        const PrettoSlider4 = withStyles({
            root: {
                color: '#8676ff',
                height: 15,
            },
            thumb: {
                display: 'none',
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
                '&:focus, &:hover, &$active': {
                    boxShadow: 'inherit',
                },
            },
            active: {},
            valueLabel: {
                left: 'calc(-50% + 4px)',
            },
            track: {
                height: 15,
                borderRadius: 8,
            },
            rail: {
                height: 15,
                borderRadius: 8,
            },

        })(Slider);

        const PrettoSlider5 = withStyles({
            root: {
                color: '#7517f8',
                height: 15,
            },
            thumb: {
                display: 'none',
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
                '&:focus, &:hover, &$active': {
                    boxShadow: 'inherit',
                },
            },
            active: {},
            valueLabel: {
                left: 'calc(-50% + 4px)',
            },
            track: {
                height: 15,
                borderRadius: 8,
            },
            rail: {
                height: 15,
                borderRadius: 8,
            },

        })(Slider);

        const PrettoSlider6 = withStyles({
            root: {
                color: '#06b117',
                height: 15,
            },
            thumb: {
                display: 'none',
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
                '&:focus, &:hover, &$active': {
                    boxShadow: 'inherit',
                },
            },
            active: {},
            valueLabel: {
                left: 'calc(-50% + 4px)',
            },
            track: {
                height: 15,
                borderRadius: 8,
            },
            rail: {
                height: 15,
                borderRadius: 8,
            },

        })(Slider);

        const nPS = this.props.neoPenStore;

        const noteEvaluationResult = classStore.noteEvaluationResult;
        const isEditable = classStore.isEditable;

        const {copyResult, duplicateValues, isDoAnalysisLoading, convertedText, convertedFrequency,
            average1, average2, average3, average4, average5, average6, explains, opinions, scores
                } = classStore;

        // const sliderAverageValue1 = average1 ? average1.toString() + '%' : '0%';
        const sliderAverageValue2 = average2 ? average2.toString() + '%' : '0%';
        const sliderAverageValue3 = average3 ? (average3*100).toString() + '%' : '0%'; // 0~1 값이므로 100을 곱한다.
        const sliderAverageValue4 = average4 ? average4.toString() + '%' : '0%';
        const sliderAverageValue5 = average5 ? average5.toString() + '%' : '0%';
        const sliderAverageValue6 = average6 ? (average6*100).toString() + '%' : '0%'; // 0~1 값이므로 100을 곱한다.

        const analysis = copyResult ? copyResult["analysis"] : undefined; // 분석 평가 및 차트 데이터
        const vocabulary = copyResult ? copyResult["vocabulary"] : undefined; // 어휘력
        const cohesion = copyResult ? copyResult["cohesion"] : undefined; // 결속성
        const consistency = copyResult ? copyResult["consistency"] : undefined; // 일관성
        const fluency = copyResult ? copyResult["fluency"] : undefined; // 유창성
        const concision = copyResult ? copyResult["concision"] : undefined; // 간결성
        const accuracy = copyResult ? copyResult["accuracy"] : undefined; // 정확성 -> 현재 데이터 없음...
        const overAll = copyResult ? copyResult["overAll"] : undefined;

        const sortedConsistencyWords = consistency ? consistency["words"].slice().sort((a, b) => b["words"] - a["words"]) : undefined;
        const totalVocaCount = vocabulary ? vocabulary["대명사"]["빈도"] + vocabulary["명사"]["빈도"] + vocabulary["형용사"]["빈도"] + vocabulary["동사"]["빈도"] + vocabulary["부사"]["빈도"] + vocabulary["접속사"]["빈도"]
            : undefined;
        const totalTopicCount = fluency ? fluency["topic1"].length + fluency["topic2"].length + fluency["topic3"].length : undefined;
        const topic1Rows = fluency ? Math.ceil(fluency["topic1"].length / 2) : undefined;
        const topic2Rows = fluency ? Math.ceil(fluency["topic2"].length / 2) : undefined;
        const topic3Rows = fluency ? Math.ceil(fluency["topic3"].length / 2) : undefined;

        const max1 = totalVocaCount > average1 ? totalVocaCount : average1;
        // max1 은 그래프상 최대값임.
        // 따라서 평균(average1)이 max1 을 기준으로 몇 % 인지 계산 해야 함.
        const aver1 = (average1/max1)*100;
        const sliderAverageValue1 = average1 ? aver1.toString() + '%' : '0%';

        const score = !isEmptyObj(scores) ? {
            "문법 정확성": scores["문법 정확성"],
            "내용 완결성": scores["내용 완결성"],
            "어휘 유창성": scores["어휘 유창성"],
            "내용 구성력": scores["내용 구성력"],
            "평균" : (scores["문법 정확성"] + scores["내용 완결성"] + scores["어휘 유창성"] + scores["내용 구성력"]) / 4
        } : undefined;

        return (
            <div className={classes.root}>
                <TopBarResult/>
                <Box className={classes.wrap}>
                    <Box className={classes.noteEvaluation}>
                        <Box className={classes.noteOriginal}>

                            {/*<Box className={classes.noteTag}>*/}
                            {/*    <Typography className={classes.noteTagText}>*/}
                            {/*        필기 원본*/}
                            {/*    </Typography>*/}
                            {/*</Box>*/}
                            <Box className={classes.topTitleText}>필기 원본</Box>
                            {/*<Box className={classes.editBtnWrap}>*/}

                            {/*</Box>*/}
                            {nPS.activePage &&
                                <Box sx={{height: '628px'}}>
                                    <NeoPenViewer/>
                                </Box>
                            }
                            {/*기존 변환 버튼*/}
                            {/*<Button className={classes.editBtn} disableRipple>{this.state.isConverting ? <CircularProgress size={20}/> : "변환"}</Button>*/}
                            {/*변환하기 버튼 추가*/}
                            <Box className={classes.resultBtnWrap}>
                                <Button className={classes.convertBtn} disableRipple onClick={async () => {
                                    if(this.state.isConverting) return;

                                    this.setState({isConverting: true});

                                    const data = await nPS.convertPenData();

                                    this.setState({isConverting: false});

                                    // 변환이 끝난 직후 변환된 data 와 함께 db insert ( 이미 있으면 update)
                                    classStore.changeNoteText(data);
                                    classStore.createAndUpdateNoteEvaluationInfo();
                                }}><ConvertBtnIcon/>{this.state.isConverting ?
                                    <Box style={{height: "100%", display: 'flex'}}>
                                        <span style={{ marginRight: '10px' }}>변환중</span>
                                        <CircularProgress disableShrink style={{ color: 'white' }} size={30}/>
                                    </Box>
                                    : "변환하기"}</Button>
                            </Box>
                        </Box>
                        <Box sx={{margin: '0 20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',}}>
                            <ConvertRightIcon/>
                        </Box>
                        <Box  className={clsx(classes.noteOriginal, classes.noteEdit)}>
                            {/*<Box className={clsx(classes.noteTag, classes.noteTagRight)}>*/}
                            {/*    <Typography className={classes.noteTagText}>*/}
                            {/*        필기 변환*/}
                            {/*    </Typography>*/}
                            {/*</Box>*/}
                            <Box className={clsx(classes.topTitleText, classes.rightTitle)}>변환된 필기</Box>
                            <Box className={classes.editBtnWrap}><Button className={classes.editBtn} onClick={() => this.handleClickDoEdit()} disableRipple>편집</Button></Box>
                            <Box className={classes.editWrapNew}>
                                <textarea style={isEditable ? {
                                    minWidth: '100%',
                                    minHeight: '300px',
                                    marginTop: '30px',
                                    fontSize: '0.938rem',
                                    height: 'calc(100% - 50px)',
                                    lineHeight:'21px',
                                    marginBottom: '10px',
                                    overflow:'scroll',
                                } : {
                                    minWidth: '100%',
                                    minHeight: '300px',
                                    marginTop: '30px',
                                    fontSize: '0.938rem',
                                    height: 'calc(100% - 50px)',
                                    marginBottom: '10px',
                                    lineHeight:'21px',
                                    overflow:'scroll',
                                    color:'#807d7d',
                                }}
                                           id='note-edit-text'
                                           value={noteEvaluationResult.editText}
                                           disabled={!isEditable}
                                           onChange={(e) => this.handleChangeNoteText(e)}/>
                            </Box>
                            <Box className={classes.resultBtnWrap}>
                                <Button className={classes.resultBtn} disabled={isDoAnalysisLoading} onClick={() => this.handleClickShowResult()} disableRipple>
                                    <RobotBtnIcon/>평가 결과보기
                                    {isDoAnalysisLoading &&
                                        <CircularProgressWithLabel value={this.state.loadingSeconds}/>
                                    }
                                </Button>
                            </Box>

                            {/*필기 편집시 버튼*/}
                            {/*<Box className={classes.resultBtnWrap}>*/}
                            {/*    <Button className={clsx(classes.resultBtn, classes.cancleBtn)} disableRipple>취소</Button>*/}
                            {/*    <Button className={classes.resultBtn} disableRipple>저장</Button>*/}
                            {/*</Box>*/}
                        </Box>
                    </Box>
                </Box>


                {overAll &&
                <Box className={classes.wrap}>
                    <Typography className={classes.sectionTitle}>
                        텍스트 분석 결과
                    </Typography>
                    <Box className={classes.sectionBoxWrap} >
                        <Box sx={{display: 'flex' }}>
                            <Box sx={{border: '1px solid #d3d9e1', width: '100%', display: 'flex', boxSizing: 'border-box'}}>
                                <Box className={classes.aiBoxLeftTitle}>
                                    <Typography className={classes.reDetailTitle}>정량지표</Typography>
                                </Box>
                                <Box className={classes.aiBoxRightContent}>
                                    <Box className={classes.reAiCountBox} >
                                        <Typography className={classes.reAiCountNum}>{overAll["전체 글자 수"]}</Typography>
                                        <Typography className={classes.reAiSubText}>(개수)</Typography>
                                        <Typography className={classes.reAiAllNum}>전체 글자 수</Typography>
                                    </Box>
                                    <Box className={classes.reAiCountBox} >
                                        <Typography className={classes.reAiCountNum}>{overAll["전체 글자 수(공백제외)"]}</Typography>
                                        <Typography className={classes.reAiSubText}>(개수)</Typography>
                                        <Typography className={classes.reAiAllNum}>전체 글자 수<br/>(공백 제외)</Typography>
                                    </Box>
                                    <Box className={classes.reAiCountBox} >
                                        <Typography className={classes.reAiCountNum}>{overAll["전체 단어 수"]}</Typography>
                                        <Typography className={classes.reAiSubText}>(개수)</Typography>
                                        <Typography className={classes.reAiAllNum}>전체 단어 수</Typography>
                                    </Box>
                                    <Box className={classes.reAiCountBox} >
                                        <Typography className={classes.reAiCountNum}>{overAll["전체 문단 수"]}</Typography>
                                        <Typography className={classes.reAiSubText}>(개수)</Typography>
                                        <Typography className={classes.reAiAllNum}>전체 문단 수</Typography>
                                    </Box>
                                    <Box className={classes.reAiCountBox} >
                                        <Typography className={classes.reAiCountNum}>{overAll["전체 문장 수"]}</Typography>
                                        <Typography className={classes.reAiSubText}>(개수)</Typography>
                                        <Typography className={classes.reAiAllNum}>전체 문장 수</Typography>
                                    </Box>
                                    <Box className={classes.reAiCountBox} >
                                        <Typography className={classes.reAiCountNum}>{overAll["겹치는 단어 비율"]}</Typography>
                                        <Typography className={classes.reAiSubText}>(%)</Typography>
                                        <Typography className={classes.reAiAllNum}>겹치는 단어 비율</Typography>
                                    </Box>
                                    <Box className={clsx(classes.reAiCountBox, classes.reAiCountBoxLast)}>
                                        <Typography className={classes.reAiCountNum}>{overAll["문법오류 어절수"]}</Typography>
                                        <Typography className={classes.reAiSubText}>(개수)</Typography>
                                        <Typography className={classes.reAiAllNum}>문법 오류 어절 수</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                }

                {copyResult &&
                <Box className={classes.BorderLine}>
                    <RobotBorderLine/>
                </Box>
                }

                {explains && copyResult &&
                <Box className={classes.wrap}>
                    <Typography className={classes.sectionTitle}>
                        AI 분석 총평
                    </Typography>
                    <Box className={classes.sectionBoxWrap} >
                        <Box sx={{border: '1px solid #d3d9e1', display: 'flex', marginTop: '10px', height: '174px'}}>
                            <Box className={classes.aiBoxLeftTitle}>
                                <Typography className={classes.reDetailTitle}>평가 설명</Typography>
                            </Box>
                            <Box sx={{background: '#fff', width: '100%', borderRadius: '4px', padding: '10px 20px', overflowY: 'auto'}}>
                                <Typography className={classes.reDetailText}>{explains["총평"]}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                }

                {copyResult &&
                    <Box className={classes.BorderLine}>
                        <RobotBorderLine/>
                    </Box>
                }

                {score &&
                <Box className={clsx(classes.wrap, classes.wrapChart)}>
                    <Box className={classes.chartBox}>
                        <Typography className={classes.sectionTitle}>
                            AI 분석 결과
                        </Typography>
                        <Box className={classes.chartBoxInner}>
                            <Box className={classes.chartContent}>
                                <Box className={classes.barChart} sx={{padding: '30px 50px 20px 50px', boxSizing: 'border-box', borderBottom: '1px solid #e5e5e5'}}>
                                    <Typography className={classes.chartTitle}>AI 평가 점수</Typography>
                                    <Typography className={classes.chartText}>{Math.round(score["평균"])}<span>점</span></Typography>
                                </Box>
                                <Box style={{width: "100%", height: 400}}>
                                    <ScoreBarChart score={score}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.chartBox}>
                        <Typography className={classes.sectionTitle}>
                            사용 단어 빈도
                        </Typography>
                        <Box className={classes.chartBoxInner}>
                            <Box className={classes.chartContent}>
                                <div style={{height: 515, width: 600, padding:8, alignItems:'center', justifyContent:'center', display:'flex'}}>
                                    <ReactWordcloud
                                        words={convertedFrequency ? convertedFrequency : []}
                                        options={options}
                                    />
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                }
                {copyResult &&
                <Box className={classes.BorderLine}>
                    <RobotBorderLine/>
                </Box>
                }
                {copyResult &&
                <Box className={classes.wrap}>
                    <Typography className={classes.sectionTitle}>
                        AI 분석 영역별 진단
                    </Typography>
                    {score &&
                    <Box className={classes.sectionResultBox} sx={{marginTop:'10px'}}>
                        <Box className={classes.resultContent}>
                            <Typography className={classes.resultTitle}>문법 정확성</Typography>
                            <Box>
                                <GradientRadialBarChart h={10} s={100} l={70} value={score["문법 정확성"]} id={"GradientRadialBarChart-1"}/>
                            </Box>
                            <Box className={classes.bottomBtnWrap}>
                                <div/>
                                <Box className={this.state.selectedFirstResult === "문법 정확성" ? classes.activeOnWrap : clsx(classes.activeOnWrap, classes.activeOnWrapOff)}>
                                    <Button className={classes.btnResult} disableRipple onClick={() => this.setState({ selectedFirstResult: "문법 정확성"} )}>진단 보기</Button>
                                    <Box className={classes.activeOn}>
                                        <img src={TopUpIcon} style={{width:'100%'}}/>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.resultContent}>
                            <Typography className={classes.resultTitle}>내용 완결성</Typography>
                            <Box>
                                <GradientRadialBarChart h={200} s={100} l={70} value={score["내용 완결성"]} id={"GradientRadialBarChart-2"}/>
                            </Box>
                            <Box className={classes.bottomBtnWrap}>
                                <div/>
                                <Box className={this.state.selectedFirstResult === "내용 완결성" ? classes.activeOnWrap : clsx(classes.activeOnWrap, classes.activeOnWrapOff)}>
                                    <Button className={classes.btnResult} disableRipple onClick={() => this.setState({ selectedFirstResult: "내용 완결성"} )}>진단 보기</Button>
                                    <Box className={classes.activeOn}>
                                        <img src={TopUpIcon} style={{width:'100%'}}/>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.resultContent}>
                            <Typography className={classes.resultTitle}>어휘 유창성</Typography>
                            <Box>
                                <GradientRadialBarChart h={150} s={100} l={70} value={score["어휘 유창성"]} id={"GradientRadialBarChart-3"}/>
                            </Box>
                            <Box className={classes.bottomBtnWrap}>
                                <div/>
                                <Box className={this.state.selectedFirstResult === "어휘 유창성" ? classes.activeOnWrap : clsx(classes.activeOnWrap, classes.activeOnWrapOff)}>
                                    <Button className={classes.btnResult} disableRipple onClick={() => this.setState({ selectedFirstResult: "어휘 유창성"} )}>진단 보기</Button>
                                    <Box className={classes.activeOn}>
                                        <img src={TopUpIcon} style={{width:'100%'}}/>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.resultContent}>
                            <Typography className={classes.resultTitle}>내용 구성력</Typography>
                            <Box>
                                <GradientRadialBarChart h={260} s={100} l={70} value={score["내용 구성력"]} id={"GradientRadialBarChart-4"}/>
                            </Box>
                            <Box className={classes.bottomBtnWrap}>
                                <div/>
                                <Box className={this.state.selectedFirstResult === "내용 구성력" ? classes.activeOnWrap : clsx(classes.activeOnWrap, classes.activeOnWrapOff)}>
                                    <Button className={classes.btnResult} disableRipple onClick={() => this.setState({ selectedFirstResult: "내용 구성력"} )}>진단 보기</Button>
                                    <Box className={classes.activeOn}>
                                        <img src={TopUpIcon} style={{width:'100%'}}/>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    }

                    {accuracy && this.state.selectedFirstResult === "문법 정확성" &&
                        <Box className={classes.detailBox}>
                            <Box className={classes.boxTopWrap}>
                                <Box className={classes.boxTopText}>
                                    <Box className={classes.textAreaDetail}>
                                        <Box style={{display:'flex', alignItems: 'center'}}>
                                            <Typography className={classes.innerTitle}>
                                                문법 정확성
                                            </Typography>

                                            <Typography className={classes.textExplain} style={{marginLeft:20}}>
                                                - 문법 정확성과 관련하여 맞춤법, 띄어쓰기, 문장 부호의 오류를 기준으로 평가함
                                                <br/>* 문서 내 어절 단위 문법오류의 수
                                            </Typography>

                                        </Box>

                                    </Box>
                                </Box>


                                <Box className={classes.graphWrap}>
                                    <Box className={classes.graphInner}>
                                        <Box className={classes.graphStart}>0</Box>
                                        <Box className={classes.graphMiddle}>평균 : {sliderAverageValue2}</Box>
                                        <Box className={classes.graphAverage} style={{left:sliderAverageValue2}}>
                                            <Box className={classes.graphAverageInner}></Box>

                                        </Box>
                                        <div className={classes.root}>
                                            <PrettoSlider2 aria-label="slider" value={accuracy["문법오류 어절 수"] ? (accuracy["문법오류 어절 수"]/accuracy["전체 어절 수"])*100 : 0}/>
                                        </div>
                                        <Box className={classes.graphFinish}>100</Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box className={classes.BoxFlex} style={{zIndex:1}}>
                                    <Box className={clsx(classes.BoxFlex, classes.sectionLeftHalf)}>
                                        <Box className={clsx(classes.sectionScoreInner, classes.borderLineRight)}
                                             style={{paddingRight: '50px', marginRight: '50px'}}>
                                            <Box className={classes.sectionScore}>{accuracy["문법오류 어절 수"]}</Box>
                                            <Box className={classes.sectionRiseWrap}><span>문법 오류 어절 수</span></Box>
                                        </Box>
                                        <Box className={classes.sectionScoreInner}>
                                            <Box className={classes.sectionScore}>{accuracy["문법오류 어절 수"]}/{accuracy["전체 어절 수"]}</Box>
                                            <Box className={classes.sectionRiseWrap}><span>문법 오류 어절 수 / 전체 어절 수</span></Box>
                                        </Box>
                                    </Box>
                                    <Box className={clsx(classes.BoxFlex, classes.sectionRight)} style={{justifyContent:'start-end', alignItems:'center', zIndex:1}}>
                                        <Box className={classes.rightInnerHighlightText} style={{display: 'flex', height: '215px', overflowY: 'scroll', width:'100%', zIndex:1}}>
                                            {convertedText ? convertedText : ''}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{border: '1px solid #d3d9e1', display: 'flex', marginTop: '10px', height: '174px'}}>
                                <Box className={classes.aiBoxLeftTitle}>
                                    <Typography className={classes.reDetailTitle}>평가 설명</Typography>
                                </Box>
                                <Box sx={{background: '#fff', width: '100%', borderRadius: '4px', padding: '10px 20px', overflowY: 'auto'}}>
                                    <Typography className={classes.reDetailText}>{explains["문법 정확성"]}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{border: '1px solid #d3d9e1', display: 'flex', marginTop: '10px', height: '174px', position: 'relative'}}>
                                <Box className={classes.aiBoxLeftTitle}>
                                    <Typography className={classes.reDetailTitle}>평가 의견</Typography>
                                </Box>
                                <Box sx={{background: '#fff', width: '100%', borderRadius: '4px', padding: '10px 20px'}}>
                                    {this.state.modifingOpinion === "문법 정확성" ?
                                        <TextField
                                            id="outlined-bare"
                                            className={classes.textField2}
                                            placeholder=''
                                            margin="normal"
                                            variant="outlined"
                                            multiline
                                            maxRows={5}
                                            onChange={(e) => this.setState({modifingOpinionText: e.target.value})}
                                            value={this.state.modifingOpinionText}
                                        />
                                        :
                                        <Typography className={classes.reDetailText}>{opinions["문법 정확성"]}</Typography>
                                    }
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', position: 'absolute', bottom: '30px', right: '35px'}}>
                                    {this.state.modifingOpinion !== "문법 정확성" ?
                                        <Button className={classes.reBtnWrite} disableRipple onClick={() => {
                                            this.setState({
                                                modifingOpinion: "문법 정확성",
                                                modifingOpinionText: opinions["문법 정확성"],
                                            });
                                        }}>수정</Button>
                                        :
                                        <Button className={classes.reBtnSave} disableRipple onClick={() => {
                                            classStore.saveOpinion(this.state.modifingOpinion, this.state.modifingOpinionText);

                                            this.setState({
                                                modifingOpinion: "",
                                                modifingOpinionText: "",
                                            });
                                        }}>저장</Button>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    }

                    {consistency && this.state.selectedFirstResult === "내용 완결성" &&
                        <Box className={classes.detailBox}>
                            <Box className={classes.boxTopWrap}>
                                <Box className={classes.boxTopText}>
                                    <Box className={classes.textAreaDetail}>
                                        <Box style={{display:'flex', alignItems: 'center'}}>
                                            <Typography className={classes.innerTitle}>
                                                일관성
                                            </Typography>

                                            <Typography className={classes.textExplain} style={{marginLeft:20}}>
                                                - 구성된 문서가 내용적 일관성을 가지고 있는지를 평가하는 것으로 문서 내 등장한 문서들이 특정 어휘가 중심이 되어 타 어휘와 연결되어 있는 정도를 측정함
                                                <br/>* 문서 내 활용명사 전체의 연결중심성(Degree centralization)수치
                                                <br/>** Degree centralization: 문장단위 동시출현 빈도 메트릭스를 기반으로 단어가 단어사이의 연결성을 평가한 지표
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={classes.graphWrap}>
                                    <Box className={classes.graphInner}>
                                        <Box className={classes.graphStart}>0</Box>
                                        <Box className={classes.graphMiddle}>평균 : {sliderAverageValue3}</Box>
                                        <Box className={classes.graphAverage} style={{left:sliderAverageValue3}}>
                                            <Box className={classes.graphAverageInner}></Box>

                                        </Box>
                                        <div className={classes.root}>
                                            <PrettoSlider3
                                                aria-label="slider"
                                                value={consistency["network"] ? consistency["network"].toFixed(1) : 0}
                                                min={0}
                                                max={1}
                                            />
                                        </div>
                                        <Box className={classes.graphFinish}>1</Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box className={classes.BoxFlex}>
                                    <Box className={clsx(classes.BoxFlex, classes.sectionLeft)}>
                                        <Box className={classes.subInfo}>(※ 0~1 사이의 값으로 높을수록 좋음)</Box>
                                        <Box className={classes.sectionScoreInner}>
                                            <Box className={classes.sectionScore}>{consistency["network"].toFixed(1)}</Box>
                                            <Box className={classes.sectionRiseWrap}><span>주요 명사의 집중도※</span></Box>
                                        </Box>
                                    </Box>
                                    <Box className={clsx(classes.BoxFlex, classes.sectionRight)}>
                                        <Box className={classes.rightInner}>
                                            <Box className={classes.boxListContent}>
                                                <Typography className={classes.sectionBoxTitle}
                                                            style={{marginBottom: '20px'}}>
                                                    집중도가 높은 주요 명사
                                                </Typography>
                                                <Box style={{display: 'flex'}}>
                                                    <Box className={classes.listContent}>
                                                        {sortedConsistencyWords && sortedConsistencyWords.map((word, index) => {
                                                            if(index < 5){
                                                                return <Box className={classes.listWrap} key={index}>
                                                                    <Box className={classes.listNumber}>{index + 1}</Box>
                                                                    <Box className={classes.listWord}>{word["word"].split('/')[0]}</Box>
                                                                </Box>
                                                            }
                                                        })}
                                                    </Box>
                                                    <Box className={classes.listContent}>
                                                        {sortedConsistencyWords && sortedConsistencyWords.length > 5 && sortedConsistencyWords.map((word, index) => {
                                                            if(index >= 5 && index < 10){
                                                                return <Box className={classes.listWrap} key={index}>
                                                                    <Box className={classes.listNumber}>{index + 1}</Box>
                                                                    <Box className={classes.listWord}>{word["word"].split('/')[0]}</Box>
                                                                </Box>
                                                            }
                                                        })}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{border: '1px solid #d3d9e1', display: 'flex', marginTop: '10px', height: '174px'}}>
                                <Box className={classes.aiBoxLeftTitle}>
                                    <Typography className={classes.reDetailTitle}>평가 설명</Typography>
                                </Box>
                                <Box sx={{background: '#fff', width: '100%', borderRadius: '4px', padding: '10px 20px', overflowY: 'auto'}}>
                                    <Typography className={classes.reDetailText}>{explains["내용 완결성"]}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{border: '1px solid #d3d9e1', display: 'flex', marginTop: '10px', height: '174px', position: 'relative'}}>
                                <Box className={classes.aiBoxLeftTitle}>
                                    <Typography className={classes.reDetailTitle}>평가 의견</Typography>
                                </Box>
                                <Box sx={{background: '#fff', width: '100%', borderRadius: '4px', padding: '10px 20px'}}>
                                    {this.state.modifingOpinion === "내용 완결성" ?
                                        <TextField
                                            id="outlined-bare"
                                            className={classes.textField2}
                                            placeholder=''
                                            margin="normal"
                                            variant="outlined"
                                            multiline
                                            maxRows={5}
                                            onChange={(e) => this.setState({modifingOpinionText: e.target.value})}
                                            value={this.state.modifingOpinionText}
                                        />
                                        :
                                        <Typography className={classes.reDetailText}>{opinions["내용 완결성"]}</Typography>
                                    }
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', position: 'absolute', bottom: '30px', right: '35px'}}>
                                    {this.state.modifingOpinion !== "내용 완결성" ?
                                        <Button className={classes.reBtnWrite} disableRipple onClick={() => {
                                            this.setState({
                                                modifingOpinion: "내용 완결성",
                                                modifingOpinionText: opinions["내용 완결성"],
                                            });
                                        }}>수정</Button>
                                        :
                                        <Button className={classes.reBtnSave} disableRipple onClick={() => {
                                            classStore.saveOpinion(this.state.modifingOpinion, this.state.modifingOpinionText);

                                            this.setState({
                                                modifingOpinion: "",
                                                modifingOpinionText: "",
                                            });
                                        }}>저장</Button>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    }

                    {vocabulary && fluency && this.state.selectedFirstResult === "어휘 유창성" &&
                        <Box className={classes.detailBox}>
                            <Box className={classes.boxTopWrap}>
                                <Box className={classes.boxTopText}>
                                    <Box className={classes.textAreaDetail}>
                                        <Box style={{display:'flex', alignItems: 'center'}}>
                                            <Typography className={classes.innerTitle}>
                                                어휘력
                                            </Typography>

                                            <Typography className={classes.textExplain} style={{marginLeft:20}}>
                                                - 문서를 구성하기 위해 활용된 어휘의 다양성과 함께 중복되지 않은 어휘의 구사능력, <br/>주요 개체의 활용능력을 평가함
                                                <br/>* 문서 구성 사용 어휘 수, 품사의 구성비율, 활용된 주요 개체명의 수
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Box>
                                <Box className={classes.graphWrap}>
                                    <Box className={classes.graphInner}>
                                        <Box className={classes.graphStart}>0</Box>
                                        <Box className={classes.graphMiddle}>평균 : {average1}</Box>
                                        <Box className={classes.graphAverage} style={{left:sliderAverageValue1}}>
                                            <Box className={classes.graphAverageInner}></Box>
                                            {/*<Box className={classes.graphAverageInner}></Box>*/}

                                        </Box>
                                        <div className={classes.root}>
                                            <PrettoSlider1
                                                aria-label="slider"
                                                value={totalVocaCount ? totalVocaCount : 0}
                                                min={0}
                                                max={max1}
                                            />
                                        </div>
                                        <Box className={classes.graphFinish}>{max1}</Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box>
                                <Box className={classes.BoxFlex}>
                                    <Box className={clsx(classes.BoxFlex, classes.sectionLeft)}>
                                        <Box className={classes.sectionScoreInner}>
                                            <Box className={classes.sectionScore}>{totalVocaCount}</Box>
                                            <Box className={classes.sectionRiseWrap}><span>사용 어휘 수</span></Box>
                                        </Box>
                                    </Box>
                                    <Box className={clsx(classes.BoxFlex, classes.sectionRight)}>
                                        <Box className={classes.rightInner}>
                                            <Box className={classes.wordLeft}>
                                                <Typography className={classes.sectionBoxTitle}
                                                            style={{marginBottom: '30px'}}>
                                                    품사구성
                                                </Typography>
                                                <Box className={classes.boxLineWrap}>
                                                    <Box className={classes.boxLine}>대명사</Box>
                                                    <Box className={classes.boxLine}>명사</Box>
                                                    <Box className={classes.boxLine}>동사</Box>
                                                    <Box className={classes.boxLine}>형용서</Box>
                                                    <Box className={classes.boxLine}>부사</Box>
                                                    <Box className={classes.boxLine}>접속사</Box>
                                                </Box>
                                                <Box className={classes.boxLineWrap}>
                                                    <Box className={classes.boxLine}>{vocabulary["대명사"]["빈도"]}</Box>
                                                    <Box className={classes.boxLine}>{vocabulary["명사"]["빈도"]}</Box>
                                                    <Box className={classes.boxLine}>{vocabulary["동사"]["빈도"]}</Box>
                                                    <Box className={classes.boxLine}>{vocabulary["형용사"]["빈도"]}</Box>
                                                    <Box className={classes.boxLine}>{vocabulary["부사"]["빈도"]}</Box>
                                                    <Box className={classes.boxLine}>{vocabulary["접속사"]["빈도"]}</Box>
                                                </Box>
                                                <Box className={classes.boxLineWrap}>
                                                    <Box className={classes.boxLine}>{vocabulary["대명사"]["퍼센트"]}%</Box>
                                                    <Box className={classes.boxLine}>{vocabulary["명사"]["퍼센트"]}%</Box>
                                                    <Box className={classes.boxLine}>{vocabulary["동사"]["퍼센트"]}%</Box>
                                                    <Box className={classes.boxLine}>{vocabulary["형용사"]["퍼센트"]}%</Box>
                                                    <Box className={classes.boxLine}>{vocabulary["부사"]["퍼센트"]}%</Box>
                                                    <Box className={classes.boxLine}>{vocabulary["접속사"]["퍼센트"]}%</Box>
                                                </Box>
                                            </Box>
                                            <Box className={classes.wordRight}>
                                                <Typography className={classes.sectionBoxTitle}
                                                            style={{marginBottom: '30px'}}>
                                                    주요 개체명
                                                </Typography>
                                                {Object.keys(vocabulary["named_entity"]).map((key, index) => (
                                                    <Box className={classes.boxLineWrap} key={index}>
                                                        <Box className={classes.boxLine}>{key}</Box>
                                                        <Box className={classes.boxLine}>{vocabulary["named_entity"][key]}</Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box style={{marginTop: 20}}/>

                            <Box className={classes.boxTopWrap}>
                                <Box className={classes.boxTopText}>
                                    <Box className={classes.textAreaDetail}>
                                        <Box style={{display:'flex', alignItems: 'center'}}>
                                            <Typography className={classes.innerTitle}>
                                                유창성
                                            </Typography>

                                            <Typography className={classes.textExplain} style={{marginLeft:20}}>
                                                - 문서 내 주제의 전달이 자연스러운지를 평가하는 것으로 문장구성의 난이도와 함께 문서 내 <br/>토픽구성의 차별성을 측정함
                                                <br/>* 문장을 구성하는 평균 단어 수 및 토픽 구성 상위 키워드의 중복정도
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={classes.graphWrap}>
                                    <Box className={classes.graphInner}>
                                        <Box className={classes.graphStart}>0</Box>
                                        <Box className={classes.graphMiddle}>평균 : {sliderAverageValue4}</Box>
                                        <Box className={classes.graphAverage} style={{left:sliderAverageValue4}}>
                                            <Box className={classes.graphAverageInner}></Box>

                                        </Box>
                                        <div className={classes.root}>
                                            <PrettoSlider4 aria-label="slider" value={fluency["중복 어휘 수"] ? (fluency["중복 어휘 수"]/totalTopicCount)*100 : 0}/>
                                        </div>
                                        <Box className={classes.graphFinish}>100</Box>
                                    </Box>
                                </Box>

                            </Box>
                            <Box>
                                <Box className={classes.BoxFlex}>
                                    <Box className={clsx(classes.BoxFlex, classes.sectionLeftHalf)}>
                                        <Box className={clsx(classes.sectionScoreInner, classes.borderLineRight)}
                                             style={{paddingRight: '50px', marginRight: '50px'}}>
                                            <Box className={classes.sectionScore}>{Math.ceil(fluency["문장구성 평균 단어수"])}</Box>
                                            <Box className={classes.sectionRiseWrap}><span>문장 구성 평균 단어 수</span></Box>
                                        </Box>
                                        <Box className={classes.sectionScoreInner}>
                                            <Box className={classes.sectionScore}>{fluency["중복 어휘 수"]}/{totalTopicCount}</Box>
                                            <Box className={classes.sectionRiseWrap}><span>중복 어휘 수 / 토픽 전체 어휘 수</span></Box>
                                        </Box>
                                    </Box>
                                    <Box className={clsx(classes.BoxFlex, classes.sectionRight)}>
                                        <Box className={classes.rightInnerText}>
                                            <Box className={classes.topicWrap}>
                                                <Box className={classes.topicContent}>
                                                    <Box className={classes.topicTitle}>토픽1</Box>
                                                    {topic1Rows && Array(5).fill('').map((_, index) => {
                                                        let innerIndex = (index+1) * 2;
                                                        return <Box className={classes.listWrap} key={index}>
                                                            <Box className={
                                                                fluency["topic1"][innerIndex-2] && fluency["topic1"][innerIndex-2]["word"] &&
                                                                duplicateValues.indexOf(fluency["topic1"][innerIndex-2]["word"]) !== -1 ?
                                                                    clsx(classes.topicWord, classes.topicWordOn) : classes.topicWord
                                                            }>
                                                                {fluency["topic1"][innerIndex-2] ? fluency["topic1"][innerIndex-2]["word"].split('/')[0] : ''}
                                                            </Box>
                                                            <Box className={
                                                                fluency["topic1"][innerIndex-1] && fluency["topic1"][innerIndex-1]["word"] &&
                                                                duplicateValues.indexOf(fluency["topic1"][innerIndex-1]["word"]) !== -1 ?
                                                                    clsx(classes.topicWord, classes.topicWordOn) : classes.topicWord
                                                            }>
                                                                {fluency["topic1"][innerIndex-1] ? fluency["topic1"][innerIndex-1]["word"].split('/')[0] : ''}
                                                            </Box>
                                                        </Box>
                                                    })}
                                                </Box>
                                                <Box className={classes.topicContent}>
                                                    <Box className={classes.topicTitle}>토픽2</Box>
                                                    {topic2Rows && Array(5).fill('').map((_, index) => {
                                                        let innerIndex = (index+1) * 2;
                                                        return <Box className={classes.listWrap} key={index}>
                                                            <Box className={
                                                                fluency["topic2"][innerIndex-2] && fluency["topic2"][innerIndex-2]["word"] &&
                                                                duplicateValues.indexOf(fluency["topic2"][innerIndex-2]["word"]) !== -1 ?
                                                                    clsx(classes.topicWord, classes.topicWordOn) : classes.topicWord
                                                            }>
                                                                {fluency["topic2"][innerIndex-2] ? fluency["topic2"][innerIndex-2]["word"].split('/')[0] : ''}
                                                            </Box>
                                                            <Box className={
                                                                fluency["topic2"][innerIndex-1] && fluency["topic2"][innerIndex-1]["word"] &&
                                                                duplicateValues.indexOf(fluency["topic2"][innerIndex-1]["word"]) !== -1 ?
                                                                    clsx(classes.topicWord, classes.topicWordOn) : classes.topicWord
                                                            }>
                                                                {fluency["topic2"][innerIndex-1] ? fluency["topic2"][innerIndex-1]["word"].split('/')[0] : ''}
                                                            </Box>
                                                        </Box>
                                                    })}
                                                </Box>
                                                <Box className={classes.topicContent}>
                                                    <Box className={classes.topicTitle}>토픽3</Box>
                                                    {topic3Rows && Array(5).fill('').map((_, index) => {
                                                        let innerIndex = (index+1) * 2;
                                                        return <Box className={classes.listWrap} key={index}>
                                                            <Box className={
                                                                fluency["topic3"][innerIndex-2] && fluency["topic3"][innerIndex-2]["word"] &&
                                                                duplicateValues.indexOf(fluency["topic3"][innerIndex-2]["word"]) !== -1 ?
                                                                    clsx(classes.topicWord, classes.topicWordOn) : classes.topicWord
                                                            }>
                                                                {fluency["topic3"][innerIndex-2] ? fluency["topic3"][innerIndex-2]["word"].split('/')[0] : ''}
                                                            </Box>
                                                            <Box className={
                                                                fluency["topic3"][innerIndex-1] && fluency["topic3"][innerIndex-1]["word"] &&
                                                                duplicateValues.indexOf(fluency["topic3"][innerIndex-1]["word"]) !== -1 ?
                                                                    clsx(classes.topicWord, classes.topicWordOn) : classes.topicWord
                                                            }>
                                                                {fluency["topic3"][innerIndex-1] ? fluency["topic3"][innerIndex-1]["word"].split('/')[0] : ''}
                                                            </Box>
                                                        </Box>
                                                    })}
                                                </Box>
                                            </Box>

                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{border: '1px solid #d3d9e1', display: 'flex', marginTop: '10px', height: '174px'}}>
                                <Box className={classes.aiBoxLeftTitle}>
                                    <Typography className={classes.reDetailTitle}>평가 설명</Typography>
                                </Box>
                                <Box sx={{background: '#fff', width: '100%', borderRadius: '4px', padding: '10px 20px', overflowY: 'auto'}}>
                                    <Typography className={classes.reDetailText}>{explains["어휘 유창성"]}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{border: '1px solid #d3d9e1', display: 'flex', marginTop: '10px', height: '174px', position: 'relative'}}>
                                <Box className={classes.aiBoxLeftTitle}>
                                    <Typography className={classes.reDetailTitle}>평가 의견</Typography>
                                </Box>
                                <Box sx={{background: '#fff', width: '100%', borderRadius: '4px', padding: '10px 20px'}}>
                                    {this.state.modifingOpinion === "어휘 유창성" ?
                                        <TextField
                                            id="outlined-bare"
                                            className={classes.textField2}
                                            placeholder=''
                                            margin="normal"
                                            variant="outlined"
                                            multiline
                                            maxRows={5}
                                            onChange={(e) => this.setState({modifingOpinionText: e.target.value})}
                                            value={this.state.modifingOpinionText}
                                        />
                                        :
                                        <Typography className={classes.reDetailText}>{opinions["어휘 유창성"]}</Typography>
                                    }
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', position: 'absolute', bottom: '30px', right: '35px'}}>
                                    {this.state.modifingOpinion !== "어휘 유창성" ?
                                        <Button className={classes.reBtnWrite} disableRipple onClick={() => {
                                            this.setState({
                                                modifingOpinion: "어휘 유창성",
                                                modifingOpinionText: opinions["어휘 유창성"],
                                            });
                                        }}>수정</Button>
                                        :
                                        <Button className={classes.reBtnSave} disableRipple onClick={() => {
                                            classStore.saveOpinion(this.state.modifingOpinion, this.state.modifingOpinionText);

                                            this.setState({
                                                modifingOpinion: "",
                                                modifingOpinionText: "",
                                            });
                                        }}>저장</Button>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    }

                    {concision && cohesion && this.state.selectedFirstResult === "내용 구성력" &&
                        <Box className={classes.detailBox}>
                            <Box className={classes.boxTopWrap}>
                                <Box className={classes.boxTopText}>
                                    <Box className={classes.textAreaDetail}>
                                        <Box style={{display:'flex', alignItems: 'center'}}>
                                            <Typography className={classes.innerTitle}>
                                                간결성
                                            </Typography>

                                            <Typography className={classes.textExplain} style={{marginLeft:20}}>
                                                - 문서의 내용전달이 간결하게 이루어지고 있는지를 평가하는 것으로 중복된 단어의 수와 함께 형용사와 부사의 사용의 적절성을 기준으로 측정됨
                                                <br/>* 문서 내 전체 사용단어 대비 대명사를 제외한 수식어의 비율
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={classes.graphWrap}>
                                    <Box className={classes.graphInner}>
                                        <Box className={classes.graphStart}>0</Box>
                                        <Box className={classes.graphMiddle}>평균 : {sliderAverageValue5}</Box>
                                        <Box className={classes.graphAverage} style={{left:sliderAverageValue5}}>
                                            <Box className={classes.graphAverageInner}></Box>
                                        </Box>
                                        <div className={classes.root}>
                                            <PrettoSlider5 aria-label="slider" value={concision["info"] ? (concision["info"]["중복사용 수식어"]/concision["info"]["전체 수식어"])*100 : 0}/>
                                        </div>
                                        <Box className={classes.graphFinish}>100</Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box className={classes.BoxFlex}>
                                    <Box className={clsx(classes.BoxFlex, classes.sectionLeft)}>
                                        {/*<Box className={classes.subInfo}>(※ 0~1 사이의 값으로 높을수록 좋음)</Box>*/}
                                        <Box className={classes.sectionScoreInner}>
                                            <Box className={classes.sectionScore}>{concision["info"]["중복사용 수식어"]}/{concision["info"]["전체 수식어"]}</Box>
                                            <Box className={classes.sectionRiseWrap}><span>중복 사용 수식어 / 전체 수식어</span></Box>
                                        </Box>
                                    </Box>
                                    <Box className={clsx(classes.BoxFlex, classes.sectionRight)}>
                                        <Box className={classes.rightInner}>
                                            <Box className={classes.boxListContent}>
                                                <Typography className={classes.sectionBoxTitle}
                                                            style={{marginBottom: '20px'}}>
                                                    사용빈도가 높은 형용사 부사
                                                </Typography>
                                                <Box style={{display: 'flex'}}>
                                                    <Box className={classes.listContent}>
                                                        {concision["형용사"].map((data, index) => {
                                                            if(index < 5){
                                                                return <Box className={classes.listWrap} key={index}>
                                                                    <Box className={classes.listNumber}>{index + 1}</Box>
                                                                    <Box className={classes.listWord}>{data["word"].split('/')[0]}</Box>
                                                                </Box>
                                                            }
                                                        })}
                                                    </Box>
                                                    <Box className={classes.listContent}>
                                                        {concision["부사"].map((data, index) => {
                                                            if(index < 5){
                                                                return <Box className={classes.listWrap} key={index}>
                                                                    <Box className={classes.listNumber}>{index + 1}</Box>
                                                                    <Box className={classes.listWord}>{data["word"].split('/')[0]}</Box>
                                                                </Box>
                                                            }
                                                        })}
                                                    </Box>
                                                </Box>
                                            </Box>

                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box style={{marginTop: 20}}/>

                            <Box className={classes.boxTopWrap}>
                                <Box className={classes.boxTopText}>
                                    <Box className={classes.textAreaDetail}>
                                        <Box style={{display:'flex', alignItems: 'center'}}>
                                            <Typography className={classes.innerTitle}>
                                                결속성
                                            </Typography>

                                            <Typography className={classes.textExplain} style={{marginLeft:20}}>
                                                - 문서 내 문단 및 문장의 연결이 자연스러운지와 함께 문장안에서 사용된 단어들이 적절하게 연결되고 있는 지를 평가함
                                                <br/>* 문서 내에서 활용된 결속어휘의 수 및 문서 내 활용명사 전체의 매게 중심성(Betweenness centralization) 수치
                                                <br/>** Betweenness centralization:문장 내 단어가 다른 단어들의 연결에 기여하는 정도를 <br/>측정하는 지표
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={classes.graphWrap}>
                                    <Box className={classes.graphInner}>
                                        <Box className={classes.graphStart}>0</Box>
                                        <Box className={classes.graphMiddle}>평균 : {sliderAverageValue6}</Box>
                                        <Box className={classes.graphAverage} style={{left:sliderAverageValue6}}>
                                            <Box className={classes.graphAverageInner}></Box>
                                        </Box>
                                        <div className={classes.root}>
                                            <PrettoSlider6
                                                aria-label="slider"
                                                value={cohesion["network"] ? cohesion["network"].toFixed(1) : 0}
                                                min={0}
                                                max={1}
                                            />
                                        </div>
                                        <Box className={classes.graphFinish}>1</Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box className={classes.BoxFlex}>
                                    <Box className={clsx(classes.BoxFlex, classes.sectionLeftHalf)}>
                                        <Box className={classes.subInfo}>(※ 0~1 사이의 값으로 높을수록 좋음)</Box>
                                        <Box className={clsx(classes.sectionScoreInner, classes.borderLineRight)}
                                             style={{paddingRight: '50px', marginRight: '50px'}}>
                                            <Box className={classes.sectionScore}>{cohesion["결속 어휘 수(접속사)"]}</Box>
                                            <Box className={classes.sectionRiseWrap}><span>결속 어휘 수 (접속사)</span></Box>
                                        </Box>
                                        <Box className={classes.sectionScoreInner}>
                                            <Box className={classes.sectionScore}>{cohesion["network"].toFixed(1)}</Box>
                                            <Box className={classes.sectionRiseWrap}><span>다른 단어와의 연결성</span></Box>
                                        </Box>
                                    </Box>
                                    <Box className={clsx(classes.BoxFlex, classes.sectionRight)}>
                                        <Box className={classes.rightInnerText}>
                                            <Box className={classes.boxListContent}>
                                                <Typography className={classes.sectionBoxTitle}
                                                            style={{marginBottom: '20px'}}>
                                                    연결성이 높은 주요 명사
                                                </Typography>
                                                <Box style={{display: 'flex'}}>
                                                    <Box className={classes.listContent}>
                                                        {cohesion["words"] && cohesion["words"].map((word, index) => {
                                                            if(index < 5){
                                                                return <Box className={classes.listWrap} key={index}>
                                                                    <Box className={classes.listNumber}>{index + 1}</Box>
                                                                    <Box className={classes.listWord}>{word["word"].split('/')[0]}</Box>
                                                                </Box>
                                                            }
                                                        })}
                                                    </Box>
                                                    <Box className={classes.listContent}>
                                                        {cohesion["words"] && cohesion["words"].length > 5 && cohesion["words"].map((word, index) => {
                                                            if(index >= 5 && index < 10){
                                                                return <Box className={classes.listWrap} key={index}>
                                                                    <Box className={classes.listNumber}>{index + 1}</Box>
                                                                    <Box className={classes.listWord}>{word["word"].split('/')[0]}</Box>
                                                                </Box>
                                                            }
                                                        })}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{border: '1px solid #d3d9e1', display: 'flex', marginTop: '10px', height: '174px'}}>
                                <Box className={classes.aiBoxLeftTitle}>
                                    <Typography className={classes.reDetailTitle}>평가 설명</Typography>
                                </Box>
                                <Box sx={{background: '#fff', width: '100%', borderRadius: '4px', padding: '10px 20px', overflowY: 'auto'}}>
                                    <Typography className={classes.reDetailText}>{explains["내용 구성력"]}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{border: '1px solid #d3d9e1', display: 'flex', marginTop: '10px', height: '174px', position: 'relative'}}>
                                <Box className={classes.aiBoxLeftTitle}>
                                    <Typography className={classes.reDetailTitle}>평가 의견</Typography>
                                </Box>
                                <Box sx={{background: '#fff', width: '100%', borderRadius: '4px', padding: '10px 20px'}}>
                                    {this.state.modifingOpinion === "내용 구성력" ?
                                        <TextField
                                            id="outlined-bare"
                                            className={classes.textField2}
                                            placeholder=''
                                            margin="normal"
                                            variant="outlined"
                                            multiline
                                            maxRows={5}
                                            onChange={(e) => this.setState({modifingOpinionText: e.target.value})}
                                            value={this.state.modifingOpinionText}
                                        />
                                        :
                                        <Typography className={classes.reDetailText}>{opinions["내용 구성력"]}</Typography>
                                    }
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', position: 'absolute', bottom: '30px', right: '35px'}}>
                                    {this.state.modifingOpinion !== "내용 구성력" ?
                                        <Button className={classes.reBtnWrite} disableRipple onClick={() => {
                                            this.setState({
                                                modifingOpinion: "내용 구성력",
                                                modifingOpinionText: opinions["내용 구성력"],
                                            });
                                        }}>수정</Button>
                                        :
                                        <Button className={classes.reBtnSave} disableRipple onClick={() => {
                                            classStore.saveOpinion(this.state.modifingOpinion, this.state.modifingOpinionText);

                                            this.setState({
                                                modifingOpinion: "",
                                                modifingOpinionText: "",
                                            });
                                        }}>저장</Button>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    }
                </Box>
                }
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('authStore', 'classStore', 'roomStore', 'userStore', 'noticeStore', 'neoPenStore')(observer(WrittenEvaluationResult))),
);

