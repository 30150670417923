import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, CircularProgress, FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as CheckCircleAgreeOffIcon } from '../../common/images/CheckCircleAgreeOffIcon.svg';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../common/images/CheckCircleAgreeOnIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { LMS_GROUP_USER_TYPE_NAME_TAG, LmsGroupUserType } from '../../stores/ClassStore';
import { DATE_UTIL } from '../../common/util/date.util';
import MoreButton from '../../components/common/MoreButton';
import { injectIntl } from 'react-intl';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { SORT_BY } from './ClassMainComponent';
import { SORT_TYPE } from '../../stores/PaginationStore';

const styles = _theme => ({
    root: {},
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 17px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    listStyle: {
        marginBottom: 60,
        '&:last-child': {
            marginBottom: 0,
        },
        '& h5': {
            borderBottom: '1px solid #d3d7db',
            fontSize: '0.875rem',
            fontWeight: 700,
            paddingBottom: 8,
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 26px',
    },
    checkBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#a9adb4',
        fontSize: '0.875rem',
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 4,
        },
    },
    checkBoxStyleOn: {
        color: '#0097FF',
    },
    name: {
        fontSize: '0.875rem',
        fontWeight: 500,
    },
    infoText: {
        fontSize: '0.75rem',
        color: '#979797',
        marginTop: 7,
    },
    tagStyle: {
        color: '#fff',
        backgroundColor: '#00c880',
        fontSize: '0.625rem',
        padding: '2px 6px',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        marginLeft: '8px',
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    lightTooltip: {
        backgroundColor: '#FFFFF5',
        color: '#000',
        border: '1px solid #000',
        fontSize: '0.688rem',
        borderRadius: 0,
        marginLeft: 0,
        marginTop: -10,
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
    },
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
    iconButton: {
        '&:hover': {
            background: 'transparent',
        },
    },
    userBox: {
        marginLeft: '7px',
    },
    avatar: {
        background: '#a3a8af',
        '& svg': {
            width: 25,
            height: 25,
            '& path': {
                fill: '#fff',
            },
        },
    },
    adminTagStyle: {
        color: '#fff',
        backgroundColor: '#5778ed',
        fontSize: '0.625rem',
        padding: '2px 6px',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        marginLeft: '8px',
    },
});

const USER_TYPE_NAME_TAG = {
    KO: {},
};

class ClassMemberManagementComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkBox: true,
            sort: SORT_BY.name,
            isFiltered: false,
            anchorElManager: null,
            anchorGeneral: null,

            teacher: true,
        };
    }

    handleChangeSort = event => {
        this.setState({ sort: event.target.value });
    };

    handleChangeCheckBox = () => {
        const { isGroupUsersLoading, getUserIdsInLiveRooms } = this.props;
        if (isGroupUsersLoading) return;
        this.setState(
            prevState => ({ isFiltered: !prevState.isFiltered }),
            () => {
                if (this.state.isFiltered) {
                    getUserIdsInLiveRooms();
                }
            },
        );
    };

    handleClickManager = event => {
        this.setState({ anchorElManager: event.currentTarget });
    };
    handleClickGeneral = event => {
        this.setState({ anchorGeneral: event.currentTarget });
    };

    handleClose = () => {
        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
        });
    };

    handleClickViewRoomsBtn = user => {
        this.props.viewUserRegisteredSchedules(user);
    };

    filteredCurrentRoomUsers = (user, _selectedGroupUserType) => {
        const { userIdsInLiveRooms } = this.props;
        // const isFilteredByType =
        //     selectedGroupUserType === LmsGroupUserType.admin
        //         ? user.type === LmsGroupUserType.admin || user.type === LmsGroupUserType.leader
        //         : user.type === selectedGroupUserType;
        let isFilteredByInLiveRooms = true;
        if (this.state.isFiltered && userIdsInLiveRooms.length) {
            const findIndex = userIdsInLiveRooms.indexOf(user.userId);
            isFilteredByInLiveRooms = findIndex !== -1;
        }

        // if (isFilteredByType && isFilteredByInLiveRooms) {
        if (isFilteredByInLiveRooms) {
            return user;
        }
    };

    render() {
        const { classes, intl, loginUser, isGroupUsersLoading, groupMembers, groupLeaderAndOperators, forwardedRef } = this.props;
        const { anchorElManager, anchorGeneral } = this.state;
        const manager = Boolean(anchorElManager);
        const general = Boolean(anchorGeneral);

        const adminList = groupLeaderAndOperators.filter(user => this.filteredCurrentRoomUsers(user, LmsGroupUserType.operator));
        const memberList = groupMembers.filter(user => this.filteredCurrentRoomUsers(user, LmsGroupUserType.member));

        return (
            <div className={classes.root}>
                {isGroupUsersLoading && !groupLeaderAndOperators.length ? (
                    <Box style={{ textAlign: 'center', marginTop: 100, height: 400 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box className={classes.filterStyle}>
                            <Box
                                className={!this.state.isFiltered ? classes.checkBoxStyle : clsx(classes.checkBoxStyle, classes.checkBoxStyleOn)}
                                onClick={this.handleChangeCheckBox}
                            >
                                {!this.state.isFiltered ? <CheckCircleAgreeOffIcon /> : <CheckCircleAgreeOnIcon />}
                                현재 일정 참가자만
                            </Box>
                            <Box>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        value={this.props.sort}
                                        onChange={e => this.props.changeSort(e.target.value)}
                                        displayEmpty
                                        IconComponent={() => (
                                            <Box style={{ width: 16, height: 16, marginLeft: 5 }}>
                                                <ArrowDownIcon />{' '}
                                            </Box>
                                        )}
                                    >
                                        <MenuItem value={SORT_TYPE.name} className={classes.menuItem}>
                                            이름순
                                        </MenuItem>
                                        <MenuItem value={SORT_TYPE.createdDatetime} className={classes.menuItem}>
                                            최근 가입순
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>

                        <Box className={classes.listStyle}>
                            <Typography variant="h5">교사</Typography>
                            {adminList.map((user, _i) => (
                                <Box key={user.userId} className={classes.listItemStyle}>
                                    <Box className={classes.flexCenter}>
                                        <Avatar className={classes.avatar} src={user.image}>
                                            <AsideUserIcon />
                                        </Avatar>
                                        <Box className={classes.userBox} display={'flex'} flexDirection="column">
                                            <Box>
                                                <span className={classes.name}>{user.name}</span>
                                                <span className={user.type === LmsGroupUserType.leader ? classes.tagStyle : classes.adminTagStyle}>
                                                    {LMS_GROUP_USER_TYPE_NAME_TAG.KO[user.type]}
                                                </span>
                                            </Box>
                                            <span className={classes.infoText}>
                                                {DATE_UTIL.getTimeZoneDate(user.createdDatetime, DATE_UTIL.FORMAT.SINGLE_DIGIT_DOT_DATE_FORMAT)} |
                                                가입
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box>
                                        {/*<Tooltip title="출석현황" classes={{ tooltip: classes.lightTooltip }}>*/}
                                        {/*    <IconButton className={classes.iconButton} disableRipple>*/}
                                        {/*        {' '}*/}
                                        {/*        <UserListIcon />{' '}*/}
                                        {/*    </IconButton>*/}
                                        {/*</Tooltip>*/}
                                        {/*<Tooltip title="수업진도" classes={{ tooltip: classes.lightTooltip }}>*/}
                                        {/*    <IconButton className={classes.iconButton} disableRipple>*/}
                                        {/*        <ChartLineUpIcon />*/}
                                        {/*    </IconButton>*/}
                                        {/*</Tooltip>*/}
                                        <MoreButton
                                            menuItems={
                                                user.userId === loginUser.id || this.props.groupUserAuthority.GroupMember
                                                    ? [
                                                          {
                                                              name: intl.formatMessage({ id: 'on_view_registered_schedules' }),
                                                              menuFunc: () => this.handleClickViewRoomsBtn(user),
                                                          },
                                                          // {
                                                          //     name: intl.formatMessage({ id: 'leave_group' }),
                                                          //     menuFunc: () => null,
                                                          // },
                                                      ]
                                                    : [
                                                          {
                                                              name: intl.formatMessage({ id: 'on_view_registered_schedules' }),
                                                              menuFunc: () => this.handleClickViewRoomsBtn(user),
                                                          },
                                                      ]
                                            }
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Box>

                        <Box className={classes.listStyle}>
                            <Typography variant="h5">학생</Typography>
                            {memberList.map((user, i) => {
                                const isLastMember = memberList.length === i + 1;
                                return (
                                    <Box
                                        key={user.userId}
                                        className={classes.listItemStyle}
                                        ref={forwardedRef && isLastMember ? forwardedRef : undefined}
                                    >
                                        <Box className={classes.flexCenter}>
                                            <Avatar className={classes.avatar} src={user.image}>
                                                <AsideUserIcon />
                                            </Avatar>
                                            <Box className={classes.userBox} display={'flex'} flexDirection="column">
                                                <span className={classes.name}>{user.name}</span>
                                                <span className={classes.infoText}>
                                                    {DATE_UTIL.getTimeZoneDate(user.createdDatetime, DATE_UTIL.FORMAT.SINGLE_DIGIT_DOT_DATE_FORMAT)} |
                                                    가입
                                                </span>
                                            </Box>
                                        </Box>
                                        {/*{user.userId === loginUser.id ||*/}
                                        {/*    (this.props.groupUserAuthority.GroupMember && (*/}
                                        {/*        <MoreButton*/}
                                        {/*            menuItems={*/}
                                        {/*                user.userId === loginUser.id*/}
                                        {/*                    ? [*/}
                                        {/*                          // {*/}
                                        {/*                          //     name: intl.formatMessage({ id: 'on_view_registered_schedules' }),*/}
                                        {/*                          //     menuFunc: e => this.handleClickViewRoomsBtn(user),*/}
                                        {/*                          // },*/}
                                        {/*                          {*/}
                                        {/*                              name: intl.formatMessage({ id: 'leave_group' }),*/}
                                        {/*                              menuFunc: () => this.props.leaveGroup(),*/}
                                        {/*                          },*/}
                                        {/*                      ]*/}
                                        {/*                    : this.props.groupUserAuthority.GroupMember*/}
                                        {/*                    ? [*/}
                                        {/*                          {*/}
                                        {/*                              name: '클래스에서 내보내기',*/}
                                        {/*                              menuFunc: e => this.handleClickViewRoomsBtn(user),*/}
                                        {/*                          },*/}
                                        {/*                      ]*/}
                                        {/*                    : null*/}
                                        {/*            }*/}
                                        {/*        />*/}
                                        {/*    ))}*/}
                                    </Box>
                                );
                            })}
                        </Box>
                    </>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(injectIntl(ClassMemberManagementComponent));
