import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { ResponsivePie } from '@nivo/pie';
import { FormattedMessage, injectIntl } from 'react-intl';

const style = theme => ({
    root: {
        display: 'flex',
        width: '100%',
        height: window.innerHeight / 1.8,
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        fontWeight: 600,
        color: '#5a6069',
    },
    textStyle1: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        fontWeight: 300,
        color: '#5a6069',
    },

    chart: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        '& > div > div > svg > g > g:last-child > g > text': {
            fontSize: '20px !important',
            fontFamily: 'Montserrat !important',
            fill: '#fff !important',
            fontWeight: '600 !important',
        },
    },
    chartBox: {
        position: 'absolute',
        right: 0,
    },
    circleStyle1: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        background: '#0024ff',
        content: '',
        marginRight: 5,
    },
    circleStyle2: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        background: '#4fb6fe',
        content: '',
        marginRight: 5,
    },
});

// const lineGenerator = line()
//     .x(d => d.x)
//     .y(d => d.y)

class AttendanceChart extends Component {
    render() {
        const { classes, intl, data } = this.props;
        const chartData = [
            {
                id: intl.formatMessage({ id: 'no_response' }),
                label: intl.formatMessage({ id: 'no_response' }),
                value: data.totalCount - data.responseCount,
                per: (((data.totalCount - data.responseCount) / data.totalCount) * 100).toFixed(2) + '%',
                color: '#4fb6fe',
            },
            {
                id: intl.formatMessage({ id: 'response' }),
                label: intl.formatMessage({ id: 'response' }),
                value: data.responseCount,
                per: ((data.responseCount / data.totalCount) * 100).toFixed(2) + '%',
                color: '#0047ac',
            },
        ];
        const MyResponsivePie = ({ chartData }) => (
            <ResponsivePie
                data={chartData}
                margin={{ top: 0, right: 60, bottom: 0, left: 0 }}
                startAngle={0}
                innerRadius={0.55}
                borderColor={{ from: 'color', modifiers: [['darker', '0']] }}
                enableRadialLabels={false}
                radialLabelsLinkOffset={-15}
                radialLabelsLinkDiagonalLength={0}
                radialLabelsLinkHorizontalLength={0}
                radialLabelsLinkStrokeWidth={0}
                enableArcLinkLabels={false}
                radialLabelsLinkColor={{ from: 'color', modifiers: [] }}
                isInteractive={true}
                arcLinkLabelsDiagonalLength={15}
                arcLinkLabelsStraightLength={10}
                sliceLabelsSkipAngle={14}
                sliceLabelsTextColor="#ffffff"
                sliceLabel={function (e) {
                    console.log('e', e.data.per);
                    return e.data.per;
                }}
                legends={[]}
                colors={{ datum: 'data.color' }}
            />
        );

        return (
            <div className={classes.root}>
                <Grid item xs={3} xl={2} style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                    <Box>
                        {chartData.map((d, i) => (
                            <Box key={i} display="flex" justifyContent="space-between">
                                <Typography className={classes.textStyle}>{d.label}</Typography>
                                <Typography className={classes.textStyle1}>
                                    {d.value}
                                    <FormattedMessage id="person" />
                                </Typography>
                            </Box>
                        ))}
                        <Box display="flex" justifyContent="space-between">
                            <Typography className={classes.textStyle}>
                                <FormattedMessage id="total_number_of_participants" />
                            </Typography>
                            <Typography className={classes.textStyle1}>
                                {chartData.map(d => d.value).reduce((a, b) => a + b)}
                                <FormattedMessage id="person" />
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={9} xl={10} className={classes.chart}>
                    {MyResponsivePie({ chartData: chartData })}

                    <Box className={classes.chartBox}>
                        <Box display="flex" alignItems="center" pb={1}>
                            <div className={classes.circleStyle1} />
                            <Typography className={classes.textStyle1}>
                                <FormattedMessage id="response" />
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <div className={classes.circleStyle2} />
                            <Typography className={classes.textStyle1}>
                                <FormattedMessage id="no_response" />
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(AttendanceChart));
