import { colors } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { getTimeStringFormatted, makeNPageIdStr, PenManager, PLAYSTATE, IPageSOBP, ViewControlSample, ZoomFitEnum } from 'nl-lib';
// import { getTimeStringFormatted, makeNPageIdStr, PenManager, PLAYSTATE, IPageSOBP, ViewControlSample, ZoomFitEnum } from '../../nl-lib';
import { withRouter } from '../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import PlayController from './PlayController';
import ThumbnailPanel from './ThumbnailPanel';

const g_annotationColor = '#FF2020';

const palette = {
    myColor: {
        main: colors.blue[500],
    },
    primary: {
        main: '#7D89EF',
    },
    secondary: {
        // main: "#B3DDED",
        main: '#fb6c71',
    },
    error: {
        main: '#FF7777',
    },
    warning: {
        main: '#FFC569',
    },
    info: {
        main: '#65BEFF',
    },
    success: {
        main: '#87C651',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
        position: 'relative',
        minHeight: 200,
        zIndex: 510,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: colors.blue[800],
        },
    },
}));

interface Props {
    name?: string;
}

const getPagesArray = (pages: IPageSOBP[], from: number, to: number) => {
    const a = pages.slice(from - 1, to);
    return a;
};

function ReplayWindow(props: Props) {
    const { width: graph_width, height: graph_height, ref: graph_ref } = useResizeDetector();
    const { width: div_width, height: div_height, ref: grid_ref } = useResizeDetector();

    const nPS = props.neoPenStore;
    const replayStorage = nPS.replayStorage;

    const playBarHeight = 110;

    // const eduClassId = useEduclassId();

    // const location = useLocation();
    // const paramMap = parseQuery(location.search);
    // const { eduClassId, curriculumId } = paramMap;
    // const analyzer = useStudyData({
    //     eduClassId,
    //     curriculumId,
    //     section: paramMap.section,
    //     owner: paramMap.owner,
    //     book: paramMap.book,
    //     ncodeStartPage: paramMap.ncodeStartPage,
    //     ncodeEndPage: paramMap.ncodeEndPage,
    // });

    // const { fromTime, toTime, pages: pages_from_query, sessionId, courseId } = parseQuery(location.search);
    // const studentId = useStudentId();
    // const { teacher } = useTeacher();

    // const pages = pages_from_query;

    // 아래 함수에서 getStrokeData는 서버로부터 받아오는 값,
    // 이미 학생 스트로크를 로컬 indexed DB에 저장해 두었으므로,
    // indexed DB에서 읽어와야 하겠다.
    //
    // 2021/05/27 이상규
    // const {
    //     data: strokeResponseData,
    //     error: strokeError,
    //     isLoading,
    //     reload: reloadStrokeData,
    // } = useAsync({
    //     promiseFn: getStrokeData,
    //     studentId,
    //     // date: { fromTime, toTime },
    //     pages,
    //     sessionId,
    //     courseId,
    // });

    // const currLesson = useSelector((state: RootState) => state.currLesson.selected);
    const mainSize = 500; //useSelector((state: RootState) => state.winSize.main).size;
    const barSize = 200; //useSelector((state: RootState) => state.winSize.appbar).size;

    const [virtualPens, setVirtualPens] = useState([]);
    useEffect(() => {
        // ShowUIProgressBackdrop();

        const pm = PenManager.getInstance();
        const vp = pm.getVirtualPen();
        setVirtualPens([vp]);

        // pm.addEventListener(PenEventName.ON_CONNECTED, onPenConnectionChanged);
        // pm.addEventListener(PenEventName.ON_DISCONNECTED, onPenConnectionChanged);

        // const r = new ReplayStorage();
        // r.fromInkstore(null, BTW_STROKE_IDLE_THRESHHOLD);
        // setReplayStorage(r);
    }, []);

    // const [strokeGraphElem, setStrokeGraphElem] = useState(null as NeoStrokeGraph);

    // const history = useHistory();

    // 학생 데이터를 읽어 오기
    // useEffect(() => {
    //     if (!isLoading) {
    //         if (strokeResponseData) {
    //             // ShowInfoToast('필기 데이터를 불러오고 있습니다.');
    //
    //             // 타이머는 돌고 있어서 여기로 콜백이 되는데, replayStorage가 달라져버린 경우가 생긴다. 특히 undefined
    //             setPlayState(PLAYSTATE.stop);
    //
    //             // 0~15 섹션만 리플레이를 지원한다. 2021/09/04
    //             const validSections = Array.from({ length: 16 }).map((v, i) => i);
    //
    //             // 2021/12/27, 2048 ~ 16개 섹션도 읽을 수 있게
    //             validSections.push(...Array.from({ length: 16 }).map((v, i) => i + 2048));
    //
    //             // console.log(strokeResponseData);
    //             const r = new ReplayStorage();
    //             r.fromInkstore({
    //                 resultElements: strokeResponseData,
    //                 ignorableItemTime: 5 * 60 * 1000,
    //                 validSections,
    //                 shouldCalcSpeed: false,
    //                 targetPages: pages,
    //             });
    //
    //             // 2021/12/07
    //             r.strokes.map(st => {
    //                 if (st.writerId !== studentId) {
    //                     st.color = g_annotationColor;
    //                 }
    //             });
    //
    //             // stroke graph
    //             // const sg = new NeoStrokeGraph("stroke_graph", r);
    //             // console.log(`graph_height = ${graph_height}`);
    //
    //             // sg.init({ width: graph_width, height: graph_height });
    //
    //             // preloadPdfRoughImage(r);
    //
    //             // setStrokeGraphElem(sg);
    //
    //             // 백그라운드 이미지를 등록
    //             const sobps = r.pageChunks.map(c => c.pageInfo);
    //
    //             ShowInfoToast('페이지 배경을 등록하고 있습니다.', 500);
    //             autoResigterPdfBackgroundForReplay(sobps).then(() => {
    //                 HideToastMessage();
    //                 HideUIProgressBackdrop();
    //
    //                 setReplayStorage(r);
    //                 setActivePage(r.pageChunks[0].pageInfo);
    //             });
    //         } else {
    //             HideUIProgressBackdrop();
    //
    //             // 필기가 없으면 이전페이지로 돌아가자
    //             confirm({
    //                 title: `재생할 필기 데이터가 없습니다.`,
    //                 description: `대상 학생의 필기 데이터가 없습니다.\r\n이전 페이지로 이동합니다.`,
    //                 confirmationText: '확인',
    //                 cancellationText: undefined,
    //                 confirmationButtonProps: { autoFocus: true },
    //             })
    //                 .then(() => {
    //                     // history.goBack();
    //                 })
    //                 .catch(() => {
    //                     // history.goBack();
    //                 });
    //             // history.goBack();
    //         }
    //     }
    // }, [strokeResponseData, isLoading]);

    // const preloadPdfRoughImage = (r: ReplayStorage) => {
    //     const msi = MappingStorage.getInstance();
    //     const pageInfo = r.pageChunks[0].pageInfo;
    //     const m = msi.getAssociatedPdfPage(pageInfo);
    //     const loadingTask = NeoPdfManager.getInstance().getDocument({
    //         url: m.pdf.url,
    //         filename: m.pdf.filename,
    //         purpose: 'REPLAY DOCUMENT: to be opend by ReplayWindow',
    //     });
    //
    //     loadingTask.then(pdf => {
    //         // const promises = [];
    //
    //         for (let i = 0; i < r.pageChunks.length; i++) {
    //             const chunk = r.pageChunks[i];
    //             const pg = chunk.pageInfo;
    //             const mg = msi.getAssociatedPdfPage(pg);
    //
    //             const pageNo = mg.pageMapping.pdfDesc.pageNo;
    //             const page = pdf.getPage(pageNo);
    //             // const { width, height } = page.viewport;
    //
    //             // 페이지의 thumbnail을 생성한다.
    //             const { section, owner, book, page: basePage } = mg.pdf.printPageInfo;
    //             const img_url = completeBackgroundImageUrl(mg.pdf.background_image_url_rule, { section, owner, book, page: page.pageNo });
    //             page.setPreRenderedImage(img_url);
    //             // page.generateThumbnail(width / 2, height / 2).then(thumbnail => {
    //             //   console.log(`thumb nail generated = ${pageNo}, ${makeNPageIdStr(pg)}`);
    //             // });
    //
    //             // promises.push(pr);
    //         }
    //     });
    // };

    // console.log(div_width, div_height);

    const [cropRect, setCropRect] = useState(undefined);
    const [activePage, setActivePage] = useState(undefined);

    const onCropRangeChagned = (sobpRc: INcodeSOBPRect) => {
        setCropRect({
            pageInfo: undefined,
            rect: sobpRc.rect,
        });
    };

    /**
     * Replay controller의 슬라이드 바를 움직였을 때
     * @param {number} deltaTime
     */
    const [activeChunk, setActiveChunk] = useState(0);
    const [forceDeltaTime, setForceDeltaTime] = useState(0);

    const handleForceDeltaTimeChanged = (deltaTime: number) => {
        // 타이머는 돌고 있어서 여기로 콜백이 되는데, replayStorage가 달라져버린 경우가 생긴다. 특히 undefined
        if (!replayStorage) {
            setPlayState(PLAYSTATE.stop);
            return;
        }

        const chunkIndex = replayStorage.getChunkIndex(deltaTime);
        setActiveChunk(chunkIndex);

        const pageInfo = replayStorage.pageChunks[chunkIndex].pageInfo;
        console.log(`Main replay, chunk_idx=${chunkIndex} timeToMove=${deltaTime} pageInfo=${makeNPageIdStr(pageInfo)} `);
        setActivePage(pageInfo);

        setForceDeltaTime(deltaTime);
        setDeltaTime(deltaTime);

        // console.log(`ERROR: (handleForceDeltaTimeChanged) ${getTimeStringFormatted(deltaTime)} ==> ${chunkIndex} / ${makeNPageIdStr(pageInfo)}`);
    };

    /**
     * Replay controller에서 속도 바뀜을 눌렀을 때
     */
    const speedConst = [1, 2, 4, 6, 8, 12, 16, 20, 32];
    const [speed, setSpeed] = useState(1);

    const handleSpeedChanged = () => {
        const curr = speedConst.findIndex(item => item === speed);
        const next = (curr + 1) % speedConst.length;
        setSpeed(speedConst[next]);
        console.log(`replaySpeed = ${curr} => ${next} , X${speedConst[next]}`);
    };

    const handleZoomInOut = (zoomIn: boolean) => {
        console.log(zoomIn);
    };

    const [fitType, setFitType] = useState(ZoomFitEnum.FULL);
    const handleFitChanged = (fit: ZoomFitEnum) => {
        setFitType(fit);
    };

    const [playState, setPlayState] = useState(PLAYSTATE.stop);
    const handleReplayStateChanged = (state: PLAYSTATE) => {
        console.log(`pageChange: ReplayWindow: handlePlayState ${makeNPageIdStr(activePage)}`);
        setPlayState(state);
        console.log(state);
    };

    const [deltaTime, setDeltaTime] = useState(0);
    const handleAutoReplayTimeChanged = (deltaTime: number) => {
        setDeltaTime(deltaTime);
    };

    // const handlePdfLoad = (event: IHandleFileLoadNeededEvent) => {
    //     const msi = MappingStorage.getInstance();
    //     console.log(event);
    // };

    const thumbnailScale = 0.7;
    const thumbnailMarginTop = 20 * thumbnailScale;

    const wndHeight = mainSize.height - barSize.height ? mainSize.height - barSize.height : playBarHeight;
    const replayHeight = wndHeight - playBarHeight;

    // const transitionDuration = {
    //     enter: theme.transitions.duration.enteringScreen,
    //     exit: theme.transitions.duration.leavingScreen,
    // };

    const [thumbnailViewIndex, setThumbnailViewIndex] = useState(0);
    const toggleThumbnailView = () => {
        setThumbnailViewIndex((thumbnailViewIndex + 1) % 2);
    };

    const classes = useStyles();
    const fabs = [
        {
            color: 'primary',
            className: clsx(classes.fab, classes.fabGreen),
            icon: <ChevronRightIcon />,
            label: 'Add',
        },
        {
            color: 'inherit',
            // className: classes.fab,
            className: clsx(classes.fab, classes.fabGreen),
            icon: <ChevronLeftIcon />,
            label: 'Expand',
        },
    ];

    // 2022/01/04
    // const numPens = useSelector((state: RootState) => state.pensReducer.numPens);
    // const [pens, setPens] = useState([]);
    // useEffect(() => {
    //     const ps = PenManager.getInstance().getConnectedPens();
    // setPens(ps);

    // ps.map(pen => {
    // pen.setSurfaceOwnerId(studentId);
    // pen.setWriterId(teacher?.teacherInfo.id);
    // pen.setColor(g_annotationColor);
    // });
    // }, [numPens]);

    // // 2022/01/04
    // const onPenConnectionChanged = (opt: IPenToViewerEvent) => {
    //   const pen = opt.pen;
    //   if (opt.event.event === PenCommEventEnum.ON_CONNECTED) {
    //     pen.setSurfaceOwnerId(studentId);
    //     pen.setWriterId(teacher?.teacherInfo.id);
    //     pen.setColor(g_annotationColor);
    //   }
    // }

    // useEffect(() => {
    //     if (teacher) {
    //         console.log(`teacher is ${teacher?.teacherInfo.id}`);
    //         const ps = PenManager.getInstance().getConnectedPens();
    //         setPens(ps);
    //
    //         ps.map(pen => {
    //             pen.setWriterId(teacher?.teacherInfo.id);
    //             pen.setColor(g_annotationColor);
    //         });
    //     } else {
    //         console.log(`teacher is null`);
    //     }
    // }, [teacher]);

    console.log('replayStorage', replayStorage);

    return (
        <div
            id="replayWholeWindow"
            style={{
                background: '#f9f9f9',
                width: '100%',
                flexDirection: 'column',
                overflow: 'hidden',
                height: '100%',
            }}
            ref={grid_ref}
        >
            <div id="replayWnd" style={{ flexGrow: 0, flexShrink: 0, height: '100%' }}>
                <div
                    id="replaywnd-flex-mother"
                    style={{
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'row',
                        overflow: 'hidden',
                        boxSizing: 'border-box',
                    }}
                >
                    <div style={{ height: '100%', flexGrow: 1, flexShrink: 1, boxSizing: 'border-box' }}>
                        <div
                            id="navBar"
                            ref={graph_ref}
                            style={{
                                flexGrow: 0,
                                flexShrink: 0,
                                flexBasis: playBarHeight,
                                alignSelf: 'stretch',
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    height: playBarHeight,
                                }}
                            >
                                <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}>
                                    <PlayController
                                        replayStorage={replayStorage}
                                        playTimeHandler={handleForceDeltaTimeChanged}
                                        replaySpeedHandler={handleSpeedChanged}
                                        onZoomInOut={handleZoomInOut}
                                        onFitChanged={handleFitChanged}
                                        onReplayStateChanged={handleReplayStateChanged}
                                        caption={getTimeStringFormatted(deltaTime)}
                                        replaySpeed={speed}
                                        scale={1}
                                        activeChunk={activeChunk}
                                        deltaTime={deltaTime}
                                        playState={playState}
                                        // analyzer={analyzer}
                                        // reloadStrokeData={reloadStrokeData}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            id="replaywnd-flex-rightpan"
                            style={{
                                background: '#eee',
                                height: 'calc(100% - 110px)',
                                maxHeight: 'calc(100% - 110px)',
                                flexGrow: 1,
                                flexShrink: 1,
                                boxSizing: 'border-box',
                            }}
                        >
                            <ViewControlSample
                                id="replay"
                                // pens={virtualPens}
                                pens={nPS.pens}
                                pageInfos={[]}
                                activePage={activePage}
                                disableAutoPageChange={false}
                                disableMousePen={false}
                                disableAutoFocus={false}
                                disablePanZoom={true}
                                showGrid={false}
                                onCropRangeChagned={onCropRangeChagned}
                                cropRect={cropRect}
                                // laserPointer={false}
                                disableViewerOption={true}
                                fitType={fitType}
                                // onPdfRequested={handlePdfLoad}
                                playState={playState}
                                replayStorage={replayStorage}
                                replaySpeed={speed}
                                onReplayTimeChanged={handleAutoReplayTimeChanged}
                                onReplayStateChanged={handleReplayStateChanged}
                                forceDeltaTime={forceDeltaTime}
                                // 2021/08/11
                                enableOptionButton={false}
                                onViewPageChanged={undefined}
                                onZoomChanged={nPS.onZoomChanged}
                                // debugInfo={true}
                                onPdfPageFault={nPS.onPdfPageFault}
                                // annotatedId={studentId}
                                // annotatorId={teacher?.teacherInfo.id}
                                enablePlateInput={true}
                                // 2022/01/07
                                laserPointer={true}
                                zoom={nPS.zoom}
                                fitType={nPS.zoomFitType}
                                isPriorityView={true}
                            />
                        </div>
                    </div>

                    {thumbnailViewIndex === 0 ? (
                        <div
                            style={{
                                // overflow: "auto",
                                background: '#fff',
                                flexGrow: 0,
                                flexShrink: 0,
                                flexBasis: 210 * thumbnailScale,
                                alignSelf: 'stretch',
                            }}
                        >
                            <ThumbnailPanel
                                pens={virtualPens}
                                thumbnailScale={thumbnailScale}
                                marginTop={thumbnailMarginTop}
                                replayStorage={replayStorage}
                                playTimeHandler={handleForceDeltaTimeChanged}
                                // analyzer={analyzer}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>

            {fabs.map((fab, index) => (
                <Zoom
                    key={index}
                    in={thumbnailViewIndex === index}
                    timeout={30}
                    style={{
                        zIndex: 500,
                        transitionDelay: `${thumbnailViewIndex === index ? 30 : 0}ms`,
                    }}
                >
                    <Fab aria-label={fab.label} className={fab.className} color={fab.color} size="small" onClick={toggleThumbnailView}>
                        {fab.icon}
                    </Fab>
                </Zoom>
            ))}
        </div>
    );
}

export default withRouter(inject('neoPenStore')(observer(ReplayWindow)));
