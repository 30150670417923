import React, { Component } from 'react';
import { Box, Dialog, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { withStyles } from '@material-ui/core/styles';
import { SearchCategory, SearchScope } from '../../common/SearchItems';
import { inject } from 'mobx-react';
import { withRouter } from '../../components/WithRouter';
import { getNoticeContents } from '../../common/Validation';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 390,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 25,
        '@media all and (max-width: 600px)': {
            marginBottom: 10,
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    boxStyle: {
        padding: '12px 10px',
        border: '1px solid #d9dbde',
        borderRadius: 8,
        height: 240,
        boxSizing: 'border-box',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#bebebe',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '3px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
    },
    textStyle: {
        fontSize: '1rem',
        color: '#333',
        whiteSpace: 'pre-wrap',
        '@media all and (max-width: 600px)': {
            fontSize: '0.875rem',
        },
    },
    spanStyle: {
        textDecoration: 'underline',
    },
    chipBox: {
        textAlign: 'center',
        margin: '14px 0 0',
        '& > div': {
            backgroundColor: '#eee',
            color: '#656565',
            margin: '0 6px 10px 0',
            fontSize: '0.938rem',
            border: '1px solid #eee',
            cursor: 'pointer',
            borderRadius: 16,
            padding: '2px 10px',
            '&:hover': {
                border: '1px solid #dbf0ff',
                backgroundColor: '#dbf0ff!important',
                color: '#0097ff!important',
                // fontWeight: 600,
            },
            '&:focus': {
                border: '1px solid #eee',
                backgroundColor: '#eee',
                color: '#656565',
            },
        },
        '@media all and (max-width: 600px)': {
            '& > div': {
                margin: '0 6px 10px 0',
                fontSize: '0.75rem',
            },
        },
    },
});

class IntroductionDialogComponent extends Component {
    constructor(props) {
        super(props);
    }

    handleSearchByTagName = (e, tagName) => {
        e.stopPropagation();
        const { classStore, roomStore, authStore, postStore, searchStore, navigate, handleClose } = this.props;
        classStore.initSearchClass();
        roomStore.initRoom();
        postStore.initPost();
        searchStore.initCurrentPage();
        searchStore.changeSearchKeyword(tagName);
        classStore.getGroupListByKeyword(tagName, {
            moveToSearchPage: () => navigate(`/search/${SearchScope.All}/${SearchCategory.Study}/${tagName}`),
            getRoomList: () =>
                roomStore.getRoomListGroupByStartTime(undefined, authStore.loginUser.id, 'all', 1, undefined, undefined, undefined, tagName),
            getPostList: () => postStore.getPostList(undefined, authStore.loginUser.id, tagName, 1, 16),
        });
        handleClose();
    };

    render() {
        const { classes, selectedGroupDetail } = this.props;

        return (
            <Dialog open={this.props.dialogOpen} className={classes.dialogBox}>
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.titleBox}>
                    <Typography className={classes.titleText}>소개</Typography>
                    <IconButton className={classes.iconButton} disableRipple onClick={this.props.handleClose}>
                        {' '}
                        <DialogCloseIcon />
                    </IconButton>
                </Box>

                <Box className={classes.boxStyle}>
                    <Typography className={classes.textStyle}>{getNoticeContents(selectedGroupDetail.group.comment)}</Typography>
                </Box>

                <Box display="flex" alignItems="center" flexWrap="wrap" className={classes.chipBox}>
                    {selectedGroupDetail.tagList &&
                        selectedGroupDetail.tagList.length > 0 &&
                        selectedGroupDetail.tagList.map(t => {
                            return (
                                <Box key={`${t.tagId}_${t.name}`} onClick={e => this.handleSearchByTagName(e, t.name)}>
                                    {t.name}
                                </Box>
                            );
                        })}
                </Box>
            </Dialog>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('classStore', 'roomStore', 'authStore', 'postStore', 'searchStore')(IntroductionDialogComponent)),
);
