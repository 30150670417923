import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Paper, Popper, Typography } from '@material-ui/core';
import { ReactComponent as CalendarBlank } from '../../common/images/CalendarBlank.svg';
import { ReactComponent as AlarmIcon } from '../../common/images/AlarmIcon.svg';
import { FormattedMessage, injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { DATE_UTIL } from '../../common/util/date.util';
import InvitationCalendarBox from './InvitationCalendarBox';

const style = theme => ({
    root: {},
    calendarBox: {
        width: '100%',
    },
    calendarBoxIn: {
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid #d3d7db',
        paddingBottom: 5,
        '& svg': {
            width: 20,
            height: 20,
        },
        '@media all and (max-width: 600px)': {
            width: '32%',
            paddingBottom: 2,
            '& svg': {
                width: 15,
                height: 15,
            },
        },
    },
    calendarBtn: {
        width: '100%',
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '& span': {
            display: 'flex',
            justifyContent: 'flex-start',
        },
    },
    calenderText1: {
        fontSize: '0.750rem',
        color: '#333',
        marginBottom: 0,
        textAlign: 'left',
        fontWeight: 400,
        '@media all and (max-width: 600px)': {
            fontSize: '0.625rem',
        },
    },
    calenderText2: {
        fontSize: '1rem',
        color: '#a3a8af',
        marginLeft: 5,
        fontWeight: 300,
        '@media all and (max-width: 600px)': {
            fontSize: '0.688rem',
        },
    },
    calenderText3: {
        fontSize: '1rem',
        color: '#333',
        marginLeft: 5,
        fontWeight: 400,
        '@media all and (max-width: 600px)': {
            fontSize: '0.688rem',
        },
    },
    popper: {
        zIndex: 1300,
        '@media all and (max-width: 600px)': {
            left: '20px !important',
            // top: '-60px !important',
        },
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.common.white} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
        '@media all and (max-width: 600px)': {
            display: 'none',
        },
    },
    paper: {
        padding: 0,
        maxWidth: 500,
        overflow: 'auto',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
    },
});

class SelectExpireDatetime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arrow: true,
            arrowRef: null,
            open: false,
        };
    }

    handleClickButton = () => {
        this.setState(state => ({
            open: !state.open,
        }));
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const { classes, classStore } = this.props;
        const { open, arrow, arrowRef } = this.state;
        return (
            <div>
                <Box display="flex" className={classes.calendarBox}>
                    <Button
                        ref={node => {
                            this.anchorEl = node;
                        }}
                        onClick={this.handleClickButton}
                        className={classes.calendarBtn}
                        disableRipple
                    >
                        <Box className={classes.calendarBoxIn}>
                            <Typography className={classes.calenderText1}>날짜 설정</Typography>
                            <Box display="flex" alignItems="center">
                                <CalendarBlank />
                                {classStore.selectedInviteExpireDatetime !== '' ? (
                                    <React.Fragment>
                                        <Typography className={classes.calenderText3}>
                                            {DATE_UTIL.convertUTCToTimeZone(
                                                classStore.selectedInviteExpireDatetime,
                                                DATE_UTIL.FORMAT.SINGLE_DOT_DATE_FORMAT,
                                            )}
                                        </Typography>
                                    </React.Fragment>
                                ) : (
                                    <Typography className={classes.calenderText2}>
                                        <FormattedMessage id="select_date" />
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box className={classes.calendarBoxIn}>
                            <Typography className={classes.calenderText1}>시간 설정</Typography>
                            <Box display="flex" alignItems="center">
                                <AlarmIcon />
                                {classStore.selectedInviteExpireDatetime !== '' ? (
                                    <React.Fragment>
                                        <Typography className={classes.calenderText3}>
                                            {DATE_UTIL.convertUTCToTimeZone(
                                                classStore.selectedInviteExpireDatetime,
                                                DATE_UTIL.FORMAT.SIMPLE_TIME_AMPM_FORMAT,
                                            )}
                                        </Typography>
                                        {/*<Typography className={classes.calenderText2}>*/}
                                        {/*    ~</Typography>*/}
                                        {/*<Typography className={classes.calenderText2}>*/}
                                        {/*    {dayjs(classStore.invite.endDatetime).format(DATE_UTIL.FORMAT.FULL_DATE_AMPM_DOT_FORMAT)}*/}
                                        {/*</Typography>*/}
                                    </React.Fragment>
                                ) : (
                                    <Typography className={classes.calenderText2}>
                                        <FormattedMessage id="select_time" />
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Button>
                    <Popper
                        open={open}
                        anchorEl={this.anchorEl}
                        placement="bottom"
                        disablePortal={false}
                        className={classes.popper}
                        modifiers={{
                            preventOverflow: {
                                enabled: true,
                                boundariesElement: 'scrollParent',
                            },
                            arrow: {
                                enabled: true,
                                element: arrowRef,
                            },
                        }}
                    >
                        {arrow ? <span className={classes.arrow} ref={this.handleArrowRef} /> : null}
                        {/*<ClickAwayListener onClickAway={this.handleClose}>*/}
                        <Paper className={classes.paper}>
                            {/*<ClickAwayListener onClickAway={this.handleClose}>*/}
                            {/*    <>{open && <CalendarBox onClickSave={this.handleClose} />}</>*/}
                            {/*</ClickAwayListener>*/}
                            {/*{open && <CalendarBox onClickSave={this.handleClose} classTab={this.props.classTab} />}*/}
                            {open && <InvitationCalendarBox onClickSave={this.handleClose} classTab={this.props.classTab} />}
                        </Paper>
                        {/*</ClickAwayListener>*/}
                    </Popper>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(inject('classStore')(observer(SelectExpireDatetime))));
