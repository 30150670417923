import React, { useEffect, useState } from 'react';
import data from './GalleryImage';
import CameraIcon from '../../../common/images/CameraIcon.svg';
import GalleryItem from './GalleryItem';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const logPrefix = ' [ Gallery ] ';
const useStyles = makeStyles(_theme => ({
    root: {
        '& .imgBox': {
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
            },
        },
    },
}));

const Gallery = props => {
    const classes = useStyles();
    const { groupImage } = props.classStore;
    const [imgData] = useState(data);
    const [currItem, setCurrItem] = useState(imgData[0]);
    const { id, image, title } = currItem;

    useEffect(() => {
        if (!groupImage) {
            props.classStore.changeGroupImage(image);
        }
    }, []);

    const onView = id => {
        const selectedImg = imgData.find(item => item.id === id);
        setCurrItem(selectedImg);
        props.classStore.changeGroupImage(selectedImg.image);
    };

    const directSelection = () => {
        setCurrItem({ id: 0 });
    };

    const handleChangeCoverFile = () => {
        const inputElement = document.getElementById('background-button-file');

        if (inputElement) {
            const reader = new FileReader();
            reader.readAsDataURL(inputElement.files[0]);
            reader.onload = e => {
                const imageElement = document.getElementById('coverImage');
                imageElement.src = e.target.result;
            };
            reader.onloadend = e => {
                setCurrItem({ id: 8, image: e.target.result, title: 'directSelectCoverImage' });
                props.classStore.changeGroupImage(e.target.result);
            };
            inputElement.value = '';
        } else {
            console.log(logPrefix, "Can't found input element");
        }
    };

    return (
        <div className={classes.root}>
            <div className="wrap">
                <article>
                    <div className="imgBox" style={{ objectFit: null }}>
                        <img id="coverImage" src={props.classStore.groupImage ? props.classStore.groupImage : image} alt={title} />
                    </div>
                    <p className="textStyle">
                        클래스를 대표하는 커버를 아래에서 선택하거나 직접 등록하세요. <br /> 커버 이미지는 최소 650 x 267 px 이상에 최적화되어
                        있습니다.
                    </p>
                    <ul className="imgList">
                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            <input
                                id="background-button-file"
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleChangeCoverFile}
                            />
                            <label htmlFor={'background-button-file'}>
                                <li className="imgButton" onClick={directSelection}>
                                    <img src={CameraIcon} alt="camera icon" />
                                    <p>직접선택</p>
                                </li>
                            </label>
                        </Box>
                        {imgData.map(item => (
                            <GalleryItem key={item.id} item={item} onView={onView} className={item.id === id ? 'imgSelect' : 'img'} />
                        ))}
                    </ul>
                </article>
            </div>
        </div>
    );
};

export default inject('classStore', 'authStore')(injectIntl(observer(Gallery)));
