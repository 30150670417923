import React, { useEffect, useState } from 'react';
import InputEmoji from 'react-input-emoji';

export default function InputEmojiComponent({ value, onChange, searchMention }) {
    const [text, setText] = useState('');

    useEffect(() => {
        setText(getNoticeContents1(value));
    }, []);

    const getNoticeContents1 = content => {
        const mentionRegex = /((@\[)[a-zA-Zㄱ-ㅎ가-힣0-9]{1,50}]\(?userId:[0-9]{1,}\))/g;
        let userId = '';
        let userName = '';
        const userNameRegex = /@\[(.*?)]/g;
        const userIdRegex = /]\(userId:.*?\)/g;
        const replace = content => {
            const convertContent = content.replace(mentionRegex, function (url) {
                console.log('url', url);
                userName = url
                    .match(userNameRegex)[0]
                    .replace(/[@\[\]]/g, '')
                    .trim();
                userId = url
                    .match(userIdRegex)[0]
                    .replace(/[\](userId:)]/g, '')
                    .trim();

                const span = document.createElement('span');
                span.setAttribute('class', 'react-input-emoji--mention--text');
                span.setAttribute('data-mention-id', userId);
                span.setAttribute('data-mention-name', userName);
                span.innerHTML = `@${userName}`;
                const parentElement = document.getElementsByClassName('react-input-emoji--input')[1];
                parentElement.appendChild(span);
                return `<span class="react-input-emoji--mention--text" data-mention-id="${userId}" data-mention-name="${userName}">@${userName}</span>`;
            });
            return convertContent;
        };
        return replace(content);
    };

    function handleOnChange(text) {
        onChange(text);
    }

    return (
        <React.Fragment>
            <InputEmoji
                value={text}
                searchMention={searchMention}
                onChange={text => {
                    handleOnChange(text);
                }}
                borderRadius={0}
                maxLength={2000}
                placeholder="댓글을 입력해주세요."
            />
        </React.Fragment>
    );
}
