import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { ReactComponent as UsersThreeIcon } from '../../../common/images/UsersThreeIcon.svg';
import { ReactComponent as EyeTinyIcon } from '../../../common/images/EyeIcon.svg';
import { ReactComponent as HandsClappingIcon } from '../../../common/images/HandsClappingIcon.svg';
import { ReactComponent as ChatCircleDotsIcon } from '../../../common/images/ChatCircleDotsIcon.svg';
import { inject, observer } from 'mobx-react';
import { DATE_UTIL } from '../../../common/util/date.util';
import { withRouter } from '../../../components/WithRouter';
import clsx from 'clsx';

const styles = theme => ({
    root: {
        width: '100%',
        margin: '0 auto',
        padding: '20px 20px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'column',
        '@media all and (min-width: 601px)': {
            padding: '20px 30px',
        },
    },
    titleText: {
        fontSize: '1rem',
        marginBottom: 20,
        '@media all and (min-width: 601px)': {
            fontSize: '1.25rem',
            marginBottom: 25,
        },
    },
    textStyle: {
        fontSize: '0.75rem',
        '@media all and (min-width: 601px)': {
            fontSize: '1rem',
        },
    },
    noBoardBox: {
        paddingBottom: 150,
    },
    boardBoxIn: {
        width: '100%',
        paddingBottom: 0,
    },
    boardBoxStyle: {
        '@media all and (min-width: 601px)': {
            minHeight: 180,
        },
        width: '49%',
        minHeight: 125,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        marginBottom: 10,
        border: '1px solid rgba(163, 168, 175, 0.5)',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        '&:nth-child(4n+0)': {
            marginRight: 0,
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    landscapeBoardBoxStyle: {
        width: 'calc((100% / 3) - 7px)',
        marginRight: 10,
        '&:nth-child(3n+0)': {
            marginRight: 0,
        },
    },
    roomNameBox: {
        padding: '3px 10px',
        borderBottom: '1px solid #d3d7db',
        '& svg': {
            width: 15,
            height: 18,
            marginRight: 8,
        },
        '@media all and (min-width: 601px)': {
            padding: '7px 15px',
            '& svg': {
                width: 19,
                height: 22,
            },
        },
    },
    roomNameText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.875rem',
        },
        width: '95%',
        fontSize: '0.625rem',
        color: '#000',
    },
    contentsBox: {
        padding: '5px 10px 0px',
        boxSizing: 'border-box',
    },
    boardTitleText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.938rem',
        },
        fontSize: '0.813rem',
        color: '#000',
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        margin: '7px 0 13px',
        '& span': {
            color: '#00c880',
            fontWeight: 700,
        },
    },
    count: {
        '@media all and (min-width: 601px)': {
            margin: '30px 0 20px',
        },
        display: 'flex',
        margin: '15px 0 10px',
        '& div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.625rem',
            color: '#7F7F7F',
            marginRight: 6,
            '& svg': {
                width: 14,
                height: 14,
                opacity: 0.5,
                marginRight: 4,
            },
            '@media all and (min-width: 601px)': {
                fontSize: '0.75rem',
                marginRight: 7,
                '& svg': {
                    width: 22,
                    height: 22,
                },
            },
        },
    },
    writerContent: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.75rem',
        },
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.625rem',
        color: '#5c5c5c',
    },
    gutters: {
        '@media all and (min-width: 601px)': {
            margin: '0 20px',
            height: 21,
        },
        margin: '0 10px',
        display: 'block',
        height: 18,
        width: 1,
        backgroundColor: 'rgba(0,0,0,0.2)',
    },
});

class MobileSearchBoardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            board: true,
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const { postStore, authStore, searchStore, keyword } = this.props;
        if (prevProps.isScrollEnd !== this.props.isScrollEnd && this.props.isScrollEnd && postStore.totalPostCount > postStore.postList.length) {
            searchStore.changeCurrentPage();
            postStore.getPostList(0, authStore.loginUser.id, keyword, searchStore.currentPage, 16);
        }
    }

    handleClickDetail = (groupId, postId) => {
        window.scrollTo(0, 0);
        this.props.navigate(`/class/${groupId}/postdetail/${postId}`);
    };

    render() {
        const { classes, postStore, landscape, mobileLandscape } = this.props;
        const { postList, isPostListLoading, totalPostCount } = postStore;

        return (
            <div className={classes.root}>
                <Typography className={classes.titleText}>검색결과({totalPostCount > 999 ? '999+' : totalPostCount})</Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="space-between" style={{ width: '100%' }}>
                    {!isPostListLoading ? (
                        !postList.length > 0 ? (
                            <Box className={classes.noBoardBox}>
                                <Typography className={classes.textStyle}>검색결과가 없습니다.</Typography>
                            </Box>
                        ) : (
                            <Box display="flex" justifyContent="space-between" flexWrap="wrap" className={classes.boardBoxIn}>
                                {postList.map((post, i) => (
                                    <Box
                                        key={i}
                                        className={
                                            landscape && mobileLandscape
                                                ? clsx(classes.boardBoxStyle, classes.landscapeBoardBoxStyle)
                                                : classes.boardBoxStyle
                                        }
                                        onClick={() => this.handleClickDetail(post.groupId, post.postId)}
                                    >
                                        <Box display="flex" alignItems="center" className={classes.roomNameBox}>
                                            <UsersThreeIcon />
                                            <Typography className={classes.roomNameText} noWrap>
                                                {post.groupName}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.contentsBox}>
                                            <Typography className={classes.boardTitleText}>
                                                {/*<span>[{post.boardType}]</span> */}
                                                {post.title}
                                            </Typography>
                                            <Box className={classes.count}>
                                                <Box>
                                                    <EyeTinyIcon /> {post.viewCount}{' '}
                                                </Box>
                                                <Box>
                                                    <HandsClappingIcon /> {post.likeList.length}{' '}
                                                </Box>
                                                <Box>
                                                    <ChatCircleDotsIcon /> {post.commentCount}{' '}
                                                </Box>
                                            </Box>
                                            <Box className={classes.writerContent}>
                                                {post.userName} <span className={classes.gutters}> </span>{' '}
                                                {DATE_UTIL.convertUTCToTimeZone(post.updatedDatetime, DATE_UTIL.FORMAT.DOT_DATE_FORMAT)}
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        )
                    ) : (
                        <Box style={{ textAlign: 'center', marginTop: 100 }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('postStore', 'authStore', 'searchStore')(observer(MobileSearchBoardComponent))));
