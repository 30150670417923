import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as StudyLiveTopBarLogo } from '../../common/images/StudyLiveTopBarLogo.svg';
import { Box, Typography } from '@material-ui/core';
import Footer from '../footer/Footer';

const styles = theme => ({
    root: {},
    topBar: {
        background: '#fff',
        borderBottom: '1px solid #c0c2c3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '30px 0',
        boxSizing: 'border-box',
    },
    contentsBox: {
        '@media all and (min-width: 1500px)': {
            width: 1440,
        },
        width: 1200,
        margin: '0 auto',
        paddingTop: 50,
        paddingBottom: 70,
    },
    titleText: {
        fontSize: '1.938rem',
        fontWeight: 'bold',
        marginBottom: 40,
    },
    textStyle1: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
        marginBottom: 20,
    },
    textStyle2: {
        fontSize: '1rem',
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'justify',
    },
    marginBottom: {
        marginBottom: 30,
        fontWeight: 'normal',
    },
});

class TermsOfUse extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Box className={classes.topBar} id={'top'}>
                    <StudyLiveTopBarLogo />
                </Box>
                <Box className={classes.contentsBox}>
                    <Typography className={classes.titleText}>이용약관</Typography>

                    <Typography className={classes.textStyle1}>제 1 장 환영합니다!</Typography>

                    <Typography className={classes.textStyle2}>제 1조 (목적)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        스터디라이브(이하 ‘서비스’)를 이용해 주셔서 감사합니다. 서비스는 여러분이 다양한 인터넷과 모바일을 통해 좀 더 편리하게 이용할
                        수 있도록 '스터디라이브 계정 약관(이하 '본 약관')을 마련하였습니다. 본 약관은 여러분이 스터디라이브 계정 서비스를 이용하는 데
                        필요한 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인 사항을 규정하고 있으므로 조금만 시간을 내서 주의 깊게 읽어주시기
                        바랍니다.
                    </Typography>

                    <Typography className={classes.textStyle2}>제 2조 (약관의 효력 및 변경)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        1. 본 약관의 내용은 스터디라이브 웹사이트에 게시하거나 기타의 방법으로 공지하고, 본 약관에 동의한 여러분 모두에게 그 효력이
                        발생합니다. <br /> <br />
                        2. 서비스는 필요한 경우 관련법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다. 본 약관이 변경되는 경우 서비스는
                        변경사항을 시행일자 7일 전부터 여러분에게 서비스 공지사항에서 공지 또는 통지하는 것을 원칙으로 합니다.
                        <br /> <br />
                        3. 서비스가 전항에 따라 공지 또는 통지를 하면서 공지 또는 통지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면
                        승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 여러분의 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다.
                        여러분이 개정약관에 동의하지 않을 경우 여러분은 이용계약을 해지할 수 있습니다.
                    </Typography>

                    <Typography className={classes.textStyle2}>제 3조 (약관 외 준칙)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        본 약관에 규정되지 않은 사항에 대해서는 관련법령 또는 서비스가 정한 개별 이용약관, 운영정책 및 규칙 등(이하 ‘세부지침’)의
                        규정에 따릅니다.
                    </Typography>

                    <Typography className={classes.textStyle2}>제 4조 (용어의 정의)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        1. 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. 스터디라이브 계정: 서비스가 제공하는 로그인계정과 비밀번호로 회원 인증, 회원정보 변경, 회원
                        가입 및 탈퇴 등을 관리할 수 있도록 서비스가 정한 로그인계정을 말합니다.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. 회원: 스터디라이브 계정이 적용된 서비스 또는 스터디라이브 계정 웹사이트에서 본 약관에
                        동의하고, 스터디라이브 계정을 이용하는 자를 말합니다.
                    </Typography>

                    <Typography className={classes.textStyle1}>제 2 장 스터디라이브 계정 이용 계약</Typography>

                    <Typography className={classes.textStyle2}>제 5조 (계약의 성립)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        1. 스터디라이브 계정 이용 신청은 스터디라이브 계정 웹사이트 회원가입 화면에서 여러분이 스터디라이브 계정 정보에 일정 정보를
                        입력하는 방식 이나 여러분의 외부계정의 정보를 전송 하는 방식으로 이루어집니다.
                        <br /> <br />
                        2. 스터디라이브 계정 이용계약은 여러분이 본 약관의 내용에 동의한 후 본 조 제1항에서 정한 이용신청을 하면 서비스가 입력된 일정
                        정보를 인증한 후 가입을 승낙함으로써 체결됩니다.
                    </Typography>

                    <Typography className={classes.textStyle2}>제 6조 (스터디라이브 계정 이용의 제한)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        1. 제5조에 따른 가입 신청자에게 서비스는 원칙적으로 스터디라이브 계정의 이용을 승낙합니다. 다만, 서비스는 아래 각 호의
                        경우에는 그 사유가 해소될 때까지 승낙을 유보하거나 승낙하지 않을 수 있습니다.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. 서비스가 본 약관 또는 세부지침에 의해 여러분의 스터디라이브 계정을 삭제하였던 경우
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. 여러분이 다른 사람의 명의나 이메일 주소 등 개인정보를 이용하여 스터디라이브 계정을 생성하려
                        한 경우
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. 스터디라이브 계정 생성 시 필요한 정보를 입력하지 않거나 허위의 정보를 입력한 경우
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. 제공 서비스 설비 용량에 현실적인 여유가 없는 경우
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;v. 서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는 경우
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vi. 기타 서비스가 재정적, 기술적으로 필요하다고 인정하는 경우
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vii. 서비스로부터 회원자격정지 조치 등을 받은 회원이 그 조치기간에 이용계약을 임의로 해지하고
                        재이용을 신청하는 경우
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;viii. 기타 관련법령에 위배되거나 세부지침 등 서비스가 정한 기준에 반하는 경우
                        <br /> <br />
                        2. 만약, 여러분이 위 조건에 위반하여 스터디라이브 계정을 생성한 것으로 판명된 때에는 서비스는 즉시 여러분의 스터디라이브 계정
                        이용을 정지시키거나 스터디라이브 계정을 삭제하는 등 적절한 제한을 할 수 있습니다.
                    </Typography>

                    <Typography className={classes.textStyle1}>제 3 장 스터디라이브 계정 이용</Typography>

                    <Typography className={classes.textStyle2}>제 7조 (스터디라이브 계정 제공)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        1. 서비스가 스터디라이브 계정에서 제공하는 계정 서비스(이하 “스터디라이브 계정 서비스”) 내용은 아래와 같습니다.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. 통합 로그인: 스터디라이브 계정이 적용된 개별 서비스에서 하나의 계정과 비밀번호로 로그인할 수
                        있는 회원 인증 서비스를 이용할 수 있습니다.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. 계정 정보 통합 관리 : 개별 서비스 이용을 위해 스터디라이브 계정 정보를 통합 관리합니다.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. 동기화 기능: 스터디라이브 계정이 적용된 개별 서비스에서 데이터를 공유할 수 있도록 동기화
                        서비스를 이용할 수 있습니다.
                        <br /> <br />
                        2. 더 나은 서비스의 제공을 위하여 여러분에게 서비스의 이용과 관련된 각종 고지, 관리 메시지 및 기타 광고를 비롯한 다양한 정보를
                        서비스화면 내에 표시하거나 여러분의 이메일로 전송할 수 있습니다. 광고성 정보 전송의 경우에는 사전에 수신에 동의한 경우에만
                        전송합니다.
                    </Typography>

                    <Typography className={classes.textStyle2}>제 8조 (스터디라이브 계정 서비스의 변경 및 종료)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        1. 서비스는 스터디라이브 계정 서비스를 365일, 24시간 쉬지 않고 제공하기 위하여 최선의 노력을 다합니다. 다만, 아래 각 호의 경우
                        스터디라이브 계정 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. 스터디라이브 계정 서비스용 설비의 유지•보수 등을 위한 정기 또는 임시 점검의 경우
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 스터디라이브 계정 이용에 지장이
                        있는 경우
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. 관계사와의 계약 종료, 정부의 명령/규제 등 서비스의 제반 사정으로 스터디라이브 계정 서비스를
                        유지할 수 없는 경우
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
                        <br /> <br />
                        2. 전항에 의한 스터디라이브 계정 서비스 중단의 경우에는 여러분에게 통지 내지 공지하겠습니다. 다만, 서비스에서도 예측할 수
                        없거나 통제할 수 없는 사유(서비스의 과실이 없는 디스크 내지 서버 장애, 시스템 다운 등)로 인해 사전 통지 내지 공지가 불가능한
                        경우에는 그러하지 아니합니다. 이러한 경우에도 서비스가 상황을 파악하는 즉시 최대한 빠른 시일 내에 복구하도록 노력하겠습니다.
                        <br /> <br />
                        3. 여러분에게 중대한 영향을 미치는 서비스의 변경 사항이나 종료는 스터디라이브 계정에 등록된 이메일 주소를 통해서 알려
                        주겠습니다. 등록된 이메일 주소가 없는 경우 서비스 내 알림 메시지를 띄우는 등의 별도의 전자적 수단을 통해서 알려 주겠습니다.
                    </Typography>

                    <Typography className={classes.textStyle2}>제 9조 (스터디라이브 계정 관리)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        1. 스터디라이브 계정은 여러분 본인만 이용할 수 있으며, 다른 사람이 여러분의 스터디라이브 계정을 이용하도록 허락할 수 없습니다.
                        그리고 여러분은 다른 사람이 여러분의 스터디라이브 계정을 무단으로 사용할 수 없도록 직접 비밀번호를 관리하여야 합니다. 서비스는
                        다른 사람이 여러분의 스터디라이브 계정을 무단으로 사용하는 것을 막기 위하여 비밀번호 입력 및 추가적인 본인 확인 절차를
                        거치도록 할 수 있습니다. 만약 무단 사용이 발견된다면, 고객센터를 통하여 서비스에게 알려주시기 바라며, 서비스는 무단 사용을
                        막기 위한 방법을 여러분에게 안내하도록 하겠습니다.
                        <br />
                        <br />
                        2. 여러분은 스터디라이브 계정 웹사이트 내 스터디라이브 계정 설정 화면을 통하여 여러분의 스터디라이브 계정 정보를 열람하고
                        수정할 수 있습니다. 다만, 스터디라이브 계정 서비스의 제공 및 관리를 위해 필요한 스터디라이브 계정, 기타 본인확인정보 등 일부
                        정보는 수정이 불가능할 수 있으며, 수정하는 경우에는 추가적인 본인 확인 절차가 필요할 수 있습니다. 여러분이 이용 신청 시
                        알려주신 내용에 변동이 있을 때, 직접 수정하시거나 이메일, 고객센터를 통하여 서비스에 알려 주시기 바랍니다.
                        <br /> <br />
                        3. 여러분이 스터디라이브 계정 정보를 적시에 수정하지 않아 발생하는 문제에 대하여 서비스는 책임을 부담하지 아니합니다.
                    </Typography>

                    <Typography className={classes.textStyle1}>제 4 장 계약당사자의 의무</Typography>

                    <Typography className={classes.textStyle2}>제 10조 (회원의 의무)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        1. 여러분이 스터디라이브 계정 서비스를 이용할 때 아래 각 호의 행위는 하여서는 안 됩니다.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. 이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 회원의 스터디라이브 계정 및 비밀번호를
                        도용, 부정하게 사용하거나, 다른 사람의 명의를 사용하는 행위
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. 타인의 명예를 손상시키거나 불이익을 주는 행위
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. 게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. 서비스 또는 제3자의 저작권 등 기타 권리를 침해하는 행위
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;v. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vi. 스터디라이브 계정 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는
                        컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vii. 스터디라이브 계정 서비스의 운영을 고의로 방해하거나 안정적 운영을 방해할 수 있는 정보 및
                        수신자의 명시적인 수신거부의사에 반하여 광고성 정보 또는 스팸메일(Spam Mail)을 전송하는 행위
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;viii. 서비스의 동의 없이 소프트웨어의 일부를 복사, 수정, 배포, 판매, 양도, 대여, 담보 제공하거나
                        타인에게 그 이용을 허락하는 행위와 소프트웨어를 역설계하거나 소스 코드의 추출을 시도하는 등 서비스를 복제, 분해 또는
                        모방하거나 기타 변형하는 행위
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ix. 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위나 다른 회원의 개인정보를
                        수집, 저장, 공개하는 행위
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;x. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위의 정보를
                        유통시키는 행위
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;xi. 윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위나 수치심이나 혐오감 또는
                        공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상을 계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을 방해하는 행위
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;xii. 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 포함)의 전송 또는 게시
                        행위
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;xiii. 서비스 또는 관계사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여
                        글을 게시하거나 E-mail 등을 발송하는 행위
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;xiv. 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된
                        소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 E-mail 등으로 발송하는 행위나
                        기타 불법한 행위
                        <br />
                        <br />
                        2. 여러분은 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도•증여할 수 없으며, 담보로 제공할 수 없습니다.
                        <br />
                        <br />
                        3. 혹시라도 여러분이 관련 법령, 서비스의 모든 약관 또는 정책을 준수하지 않는다면, 서비스는 여러분의 위반행위 등을 조사할 수
                        있고, 여러분의 서비스 이용을 잠시 또는 계속하여 중단하거나, 재가입에 제한을 둘 수도 있습니다.
                    </Typography>

                    <Typography className={classes.textStyle2}>제 11조 (개인정보의 보호)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        여러분의 개인정보의 안전한 처리는 서비스에게 있어 가장 중요한 일 중 하나입니다. 여러분의 개인정보는 서비스의 원활한 제공을
                        위하여 여러분이 동의한 목적과 범위 내에서만 이용됩니다. 법령에 의하거나 여러분이 별도로 동의하지 아니하는 한 서비스가 여러분의
                        개인정보를 제3자에게 제공하는 일은 결코 없으므로, 안심하셔도 좋습니다. 서비스가 여러분의 개인정보를 안전하게 처리하기 위하여
                        기울이는 노력이나 기타 자세한 사항은 스터디라이브 개인정보처리방침을 참고하여 주십시오.
                    </Typography>

                    <Typography className={classes.textStyle2}>제 12조 (회원에 대한 통지 및 공지)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        서비스는 여러분과의 의견 교환을 소중하게 생각합니다. 여러분은 언제든지 고객센터(ts.kim@learningspqrk.io)에 방문하여 의견을
                        개진할 수 있습니다. 서비스 이용자 전체에 대한 공지는 칠(7)일 이상 서비스 공지사항란에 게시함으로써 효력이 발생합니다.
                        여러분에게 중대한 영향을 미치는 공지는 스터디라이브 계정에 등록된 이메일 주소를 통해서 알려 주겠습니다. 등록된 이메일 주소가
                        없는 경우 서비스 내 알림 메시지를 띄우는 등의 별도의 전자적 수단을 통해서 알려 주겠습니다.
                    </Typography>

                    <Typography className={classes.textStyle1}>제 5 장 이용계약 해지 등</Typography>

                    <Typography className={classes.textStyle2}>제 13조 (이용계약 해지)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        1. 여러분이 스터디라이브 계정 이용을 더 이상 원치 않는 때에는 언제든지 서비스 내 제공되는 메뉴를 이용하여 이용계약의 해지
                        신청을 할 수 있으며, 서비스는 법령이 정하는 바에 따라 신속히 처리하겠습니다.
                        <br />
                        <br />
                        2. 서비스는 법령에서 정하는 기간 동안 여러분이 서비스를 이용하기 위해 스터디라이브 계정 로그인 혹은 접속한 기록이 없는 경우
                        여러분이 등록한 이메일주소 또는 연락처로 연락 하는 등 기타 유효한 수단으로 통지 후 여러분의 스터디라이브 계정 정보를
                        파기하거나 분리 보관할 수 있으며, 이로 인해 스터디라이브 계정 서비스 이용을 위한 필수적인 정보가 부족할 경우 이용계약이 해지될
                        수도 있습니다.
                        <br />
                        <br />
                        3. 이용계약이 해지되면 법령 및 개인정보 처리방침에 따라 여러분의 정보를 보유하는 경우를 제외하고는 여러분의 스터디라이브 계정
                        정보 및 스터디라이브 계정으로 이용하였던 서비스 데이터는 삭제됩니다. 다만, 여러분이 서비스 내에서 작성한 게시물 등 모든
                        데이터의 삭제와 관련한 사항은 별도 내부규정에 따를 수 있습니다. 4. 이용계약이 해지된 경우라도 여러분은 다시 서비스에 대하여
                        이용계약의 체결을 신청할 수 있습니다.
                    </Typography>

                    <Typography className={classes.textStyle2}>제 14조 (손해배상)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        1. 서비스는 법령상 허용되는 한도 내에서 본 약관에 명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지 않습니다.
                        또한, 서비스는 제3자가 제공하거나 회원이 작성하는 등의 방법으로 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에
                        대해서는 보증을 하지 않으며, 서비스의 과실 없이 발생된 여러분의 손해에 대하여는 책임을 부담하지 아니합니다.
                        <br />
                        <br />
                        2. 서비스는 서비스의 과실로 인하여 여러분이 손해를 입게 될 경우 본 약관 및 관련 법령에 따라 여러분의 손해를 배상하겠습니다.
                        다만 서비스는 서비스의 과실 없이 발생된 아래와 같은 손해에 대해서는 책임을 부담하지 않습니다. 또한 서비스는 법률상 허용되는
                        한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다.
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i. 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii. 여러분의 귀책사유로 서비스 이용에 장애가 발생한 경우
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii. 서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iv. 제3자가 불법적으로 서비스의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;v. 제3자가 서비스 서버에 대한 전송 또는 서비스 서버로부터의 전송을 방해함으로써 발생하는 손해
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vi. 제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;vii. 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제3자가 서비스를 이용하는
                        과정에서 발생된 손해
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;viii. 기타 서비스의 고의 또는 과실이 없는 사유로 인해 발생한 손해
                    </Typography>

                    <Typography className={classes.textStyle2}>제 15조 (분쟁의 해결)</Typography>
                    <Typography className={clsx(classes.textStyle2, classes.marginBottom)}>
                        본 약관 또는 서비스는 대한민국법령에 의하여 규정되고 이행됩니다. 서비스 이용과 관련하여 여러분 간에 분쟁이 발생하면 이의
                        해결을 위해 성실히 협의할 것입니다. 그럼에도 불구하고 해결되지 않으면 민사소송법상의 관할법원에 소를 제기할 수 있습니다.
                    </Typography>
                </Box>
                <Footer />
            </div>
        );
    }
}

export default withStyles(styles)(TermsOfUse);
