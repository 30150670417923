import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    Menu,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { ReactComponent as MoreIcon } from '../../common/images/DotsThreeOutlineVerticalIcon.svg';
import { TAB_NAME } from './StudentsManagementComponent';
import { USER_TYPE } from '../../stores/UserStore';
import { SORT_BY } from '../class/ClassMainComponent';
import StudentListComponent from './StudentListComponent';
import { DATE_UTIL } from '../../common/util/date.util';
import { SORT_TYPE } from '../../stores/PaginationStore';
import {OfflineDataUploadState, PenState} from "../../stores/NeoPenStore";
import {inject, observer} from "mobx-react";

const styles = theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
        '& table button.MuiButton-root': {
            minWidth: 'unset',
            padding: 0,
        },
    },
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        '&:after': {
            content: '""',
            width: '100%',
            height: 3,
            backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: -1,
        },
        '& button': {
            minWidth: 100,
            fontSize: '0.938rem',
            '&.Mui-selected': {
                fontWeight: 700,
                color: '#0097ff',
            },
        },
        '& .MuiTabs-indicator': {
            height: 3,
            backgroundColor: '#0097ff',
        },
    },
    btnStyle: {
        '@media all and (min-width: 1500px)': {
            width: 235,
            marginLeft: 90,
        },
        width: 230,
        height: 40,
        marginLeft: 30,
        backgroundColor: '#0097FF',
        color: '#fff',
        borderRadius: 7,
        '& svg': {
            marginRight: 4,
        },
        '&:hover': {
            backgroundColor: '#0097FF',
        },
    },
    downloadBtnStyle: {
        border: '1px solid #bfbfbf',
        borderRadius: 3,
        display: 'flex',
        marginRight: 12,
        minWidth: 32,
        '& svg': {
            width: 18,
            height: 18,
            '& path': {
                stroke: '#000',
            },
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    addBtnStyle: {
        border: '1px solid #bfbfbf',
        borderRadius: 4,
        marginLeft: 10,
        color: '#000',
        padding: '2px 8px',
        fontWeight: 400,
        fontSize: '0.875rem',
        '&:hover': {
            background: 'transparent',
        },
    },
    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    checkBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#a9adb4',
        fontSize: '0.875rem',
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 4,
        },
    },
    checkBoxStyleOn: {
        color: '#0097FF',
    },
    caption: {
        backgroundColor: 'transparent',
        color: '#fff',
        padding: '0px 4px',
        fontSize: '0.625rem',
        fontFamily: 'Montserrat!important',
        marginLeft: 7,
        marginBottom: 2,
        textTransform: 'uppercase',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            marginRight: 3,
        },
    },
    captionLive: {
        width: 30,
        backgroundColor: '#FB4A59',
    },
    captionWait: {
        width: 20,
        backgroundColor: '#a3a8af',
    },
    captionEnd: {
        width: 20,
        backgroundColor: '#1a5177',
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 26px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    noPointerListItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 26px',
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        justifyContent: 'flex-start',
        width: '100%',
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .aside-lmsUser': {
            fill: '#fff',
        },
    },
    listItemTitle: {
        fontSize: '0.875rem',
        display: 'flex',
        fontWeight: 600,
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    checkStyle: {
        fontSize: '0.75rem',
        color: '#016ab2',
    },
});
class StudentGroupManagmentContentsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: TAB_NAME.Member,
        };
    }

    componentDidMount() {
        this.props.changeSort(SORT_TYPE.name);
    }

    componentWillUnmount() {
        this.props.initSelectedGroupMemberAndOperators();
    }

    handleChange = (event, tabs) => {
        this.setState({ tabs });
    };

    filteredGuestUser = groupUser => {
        if (this.props.isFiltered) {
            if (groupUser.userType === USER_TYPE.Guest) {
                return groupUser;
            }
        } else {
            return groupUser;
        }
    };

    handleChangeSortAndFetchData = event => {
        const sortType = event.target.value;
        this.props.changeSortAndFetchData(sortType);
    };

    render() {
        const { classes, loginUser, selectedGroupMemberAndOperators } = this.props;
        const { anchorGeneral, tabs } = this.state;
        const general = Boolean(anchorGeneral);
        // const filteredGroupUsers = selectedGroupMemberAndOperators
        //     .filter(this.filteredGuestUser)
        //     .sort((a, b) => SORT_UTIL.compareMembers(a, b, this.props.sort));

        const filteredGroupUsers = selectedGroupMemberAndOperators.filter(this.filteredGuestUser);
        const nPS = this.props.neoPenStore;

        return (
            <StudentListComponent
                groupId={this.props.groupId}
                loginUser={this.props.loginUser}
                setSelectedChildrenTab={this.props.setSelectedChildrenTab}
                requestGroupUserExportExcel={this.props.requestGroupUserExportExcel}
                groupUserAuthority={this.props.groupUserAuthority}
                findAvatar={this.props.findAvatar}
                isLeader={this.props.isLeader}
                isTeamLoading={this.props.isTeamLoading}
            >
                <div className={classes.root}>
                    {!filteredGroupUsers.length && this.props.isGroupUserLoading ? (
                        <Box style={{ textAlign: 'center', marginTop: 100, marginBottom: 1000 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <Box className={classes.filterStyle}>
                                <Box></Box>
                                <Box>
                                    <FormControl className={classes.formControl}>
                                        <Select
                                            value={this.props.sort}
                                            displayEmpty
                                            onChange={this.handleChangeSortAndFetchData}
                                            IconComponent={() => (
                                                <Box style={{ width: 16, height: 16, marginLeft: 5 }}>
                                                    <ArrowDownIcon />
                                                </Box>
                                            )}
                                        >
                                            <MenuItem value={SORT_BY.name} className={classes.menuItem}>
                                                이름순
                                            </MenuItem>
                                            <MenuItem value={SORT_BY.createdDatetime} className={classes.menuItem}>
                                                가입일순
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            {filteredGroupUsers && filteredGroupUsers.length ? (
                                filteredGroupUsers.map((member, i) => {
                                    const isMyStudent = member.userType === USER_TYPE.Guest && member.parentId && member.parentId === loginUser.id;
                                    return (
                                        <Box
                                            key={`${i}_${member.name}`}
                                            className={isMyStudent ? classes.listItemStyle : classes.noPointerListItemStyle}
                                        >
                                            <Box
                                                className={classes.flexCenter}
                                                onClick={() => {
                                                    if (isMyStudent) {
                                                        this.props.handleClickGeneralCheck(undefined, member);
                                                        this.props.handleClickViewAccount();
                                                    }
                                                }}
                                            >
                                                <Avatar className={classes.avatar} src={member.image}>
                                                    <AsideUserIcon />
                                                </Avatar>
                                                <Box flexDirection="column">
                                                    <Typography variant="h5" className={classes.listItemTitle}>
                                                        {member.name}
                                                    </Typography>
                                                    <Typography variant="body2" className={classes.listItemDate}>
                                                        {DATE_UTIL.convertUTCToTimeZone(
                                                            member.createdDatetime,
                                                            DATE_UTIL.FORMAT.SINGLE_DIGIT_DOT_DATE_FORMAT,
                                                        )}
                                                        가입
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            {/*{member.userType === USER_TYPE.Guest && member.parentId && member.parentId === loginUser.id && (*/}

                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                <Button
                                                    style={{width: 150}}
                                                    onClick={() => {
                                                        if (nPS.penState === PenState.Disconnected) {
                                                            nPS.selectUser(member);

                                                            nPS.setOfflineDataUploadState(OfflineDataUploadState.Connecting);

                                                            nPS.onConnectionChange();
                                                        }
                                                    }}
                                                > {(nPS.penState === PenState.Connecting && member.userId === nPS.selectedUser.userId)
                                                    ? '펜 연결중...'
                                                    : (nPS.penState === PenState.Connected && member.userId === nPS.selectedUser.userId)
                                                        ? '데이터 업로드 중...'
                                                        : '업로드'}
                                                </Button>

                                                <IconButton
                                                    disableRipple
                                                    //aria-owns={anchorEl ? 'simple-menu' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={
                                                        isMyStudent
                                                            ? e => this.props.handleClickGeneralCheck(e, member)
                                                            : e => this.props.handleClickGeneral(e, member)
                                                    }
                                                >
                                                    <MoreIcon />
                                                </IconButton>

                                                {/*<IconButton*/}
                                                {/*    disableRipple*/}
                                                {/*    //aria-owns={anchorEl ? 'simple-menu' : undefined}*/}
                                                {/*    aria-haspopup="true"*/}
                                                {/*    onClick={e => this.props.handleClickGeneralCheck(e, member)}*/}
                                                {/*>*/}
                                                {/*    <MoreIcon />*/}
                                                {/*</IconButton>*/}
                                            </Box>
                                            {/*)}*/}
                                        </Box>
                                    );
                                })
                            ) : (
                                <Box className={classes.listItemStyle}>
                                    <Box className={classes.flexCenter}>
                                        <Box className={classes.avatar}>
                                            <AsideUserIcon />
                                        </Box>
                                        <Typography>등록된 계정이 없습니다.</Typography>
                                    </Box>
                                </Box>
                            )}

                            <Menu
                                id="simple-menu"
                                anchorEl={this.props.anchorGeneral}
                                open={this.props.anchorGeneral !== undefined ? this.props.anchorGeneral : false}
                                onClose={this.props.handleClose}
                                className={classes.menuBox}
                            >
                                {/*<MenuItem onClick={this.props.handleClose} className={classes.menuItem}>*/}
                                {/*    스마트펜 정보*/}
                                {/*</MenuItem>*/}
                                {/*<MenuItem onClick={this.props.handleClose} className={classes.menuItem}>*/}
                                {/*    삭제*/}
                                {/*</MenuItem>*/}
                                <MenuItem onClick={this.props.handleForcedLeaveGroupUser} className={classes.menuItem}>
                                    클래스에서 내보내기
                                </MenuItem>
                            </Menu>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.props.anchorGeneralCheck}
                                open={this.props.anchorGeneralCheck !== undefined ? this.props.anchorGeneralCheck : false}
                                onClose={this.props.handleClose}
                                className={classes.menuBox}
                            >
                                <MenuItem onClick={this.props.handleClickViewAccount} className={classes.menuItem}>
                                    계정 복사
                                </MenuItem>
                                <MenuItem onClick={this.props.handleClickModifyAccount} className={classes.menuItem}>
                                    편집
                                </MenuItem>
                                <MenuItem onClick={this.props.handleForcedLeaveGroupUser} className={classes.menuItem}>
                                    클래스에서 내보내기
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </div>
            </StudentListComponent>
        );
    }
}

export default inject('neoPenStore')(withStyles(styles)(observer(StudentGroupManagmentContentsComponent)));
