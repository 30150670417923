import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Box, Button, DialogActions, DialogContent, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as Close } from '../../common/images/Close.svg';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
    dialogBox: {
        '& .MuiDialogContent-root': {
            padding: 0,
        },
        '& .MuiDialogActions-root': {
            padding: 16,
        },
    },
    dialogTitleBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 16,
        color: '#303030',
        fontWeight: 800,
    },
    textStyle: {
        borderTop: '1px solid #bfbfbf',
        borderBottom: '1px solid #bfbfbf',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#303030',
        lineHeight: 2,
        padding: '16px 20px',
    },
    btnStyle: {
        // background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor),
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        padding: '8px 12px',
        border: '1px solid #000',
        color: '#000',
        background: '#fff',
        '&:hover': {
            // background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor),
            fontWeight: 800,
            background: '#fff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    saveButton: {
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        marginLeft: 25,
        color: '#fff',
        border: 0,
        '&:hover': {
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
}));

export default function YesOrNoDialog(props) {
    const classes = useStyles();
    const { open, title, msg, changeDialogOpen } = props;

    const handleSubmit = () => {
        const { changeDialogOpen, submit } = props;
        submit();
        changeDialogOpen(false);
    };

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'xs'} className={classes.dialogBox}>
            <DialogTitle>
                <Box className={classes.dialogTitleBox}>
                    <Typography className={classes.titleStyle}>{title}</Typography>

                    <IconButton
                        onClick={() => {
                            changeDialogOpen(false);
                        }}
                        style={{ padding: 5 }}
                    >
                        <Close style={{ width: 20, height: 20 }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" component="h2" className={classes.textStyle}>
                    {msg.split('\n').map((line, index) => {
                        return (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        );
                    })}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        changeDialogOpen(false);
                    }}
                    className={classes.btnStyle}
                >
                    <FormattedMessage id="cancel" />
                </Button>
                <Button onClick={handleSubmit} className={clsx(classes.btnStyle, classes.saveButton)}>
                    <FormattedMessage id="ok" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
