import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { entries } from 'mobx';
import { Box, Button, FormControl, IconButton, Menu, MenuItem, Select, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as FolderPlusFillIcon } from '../../common/images/FolderPlusFillIcon.svg';
import { ReactComponent as ChartBarPurpleIcon } from '../../common/images/ChartBarPurpleIcon.svg';
import clsx from 'clsx';
import { ReactComponent as CheckCircleAgreeOffIcon } from '../../common/images/CheckCircleAgreeOffIcon.svg';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../common/images/CheckCircleAgreeOnIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { ReactComponent as NotePencilIcon } from '../../common/images/NotePencilIcon.svg';
import { ReactComponent as MoreIcon } from '../../common/images/DotsThreeOutlineVerticalIcon.svg';
import ClassManagementRecordInfoComponent from './ClassManagementRecordInfoComponent';
import { PATH_UTIL } from '../../common/util/path.util';
import { ReactComponent as PencilIcon } from '../../common/images/PencilIcon.svg';
import { ReactComponent as PlayIcon } from '../../common/images/PlayIcon.svg';
import { withRouter } from '../../components/WithRouter';
import { ReactComponent as FolderNotchIcon } from '../../common/images/FolderNotchIcon.svg';
import MoreButton from '../../components/common/MoreButton';
import { MovingObjectType } from '../../stores/NeoPenStore';

const styles = theme => ({
    root: {},
    optionBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    btnDefault: {
        padding: 0,
        marginBottom: 4,
        marginRight: 20,
    },
    btnStylePurple: {
        border: '1px solid #664cff',
        borderRadius: 7,
        marginLeft: 10,
        color: '#664cff',
        padding: '4px 8px',
        fontWeight: 600,
        fontSize: '0.938rem',
        letterSpacing: -0.14,
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 4,
        },
    },
    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    tableBtnStyle: {
        '&:hover': {
            background: 'transparent',
        },
    },
    checkBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#a9adb4',
        fontSize: '0.875rem',
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 4,
        },
    },
    checkBoxStyleOn: {
        color: '#0097FF',
    },
    formControl: {
        minWidth: 100,
        width: 100,
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 26px 17px 26px',
        boxSizing: 'border-box',
    },
    listItemOpenStyle: {
        borderRadius: 12,
        boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
    },
    listItemOpenInStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '17px 26px 17px 26px',
        background: '#f8f8f8',
        borderRadius: '12px 12px 0 0',
        '& .more-icon': {
            fill: '#000',
        },
    },
    flexCenter: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexGrow: {
        width: 'calc(100% - 50px)',
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listItemTitle: {
        width: '100%',
        fontSize: '0.875rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        whiteSpace: 'nowrap',
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    tagRedStyle: {
        fontSize: '0.625rem',
        color: '#fff',
        backgroundColor: '#fb4a59',
        padding: '3px 4px',
        fontWeight: 700,
    },
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
    iconButton: {
        '&:hover': {
            background: 'transparent',
        },
    },
    btnStyle: {
        border: '1px solid #bfbfbf',
        borderRadius: 4,
        marginLeft: 10,
        color: '#000',
        padding: '1px 8px',
        fontWeight: 400,
        fontSize: '0.875rem',
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 4,
            width: 20,
            height: 20,
            '& .neo-pen, & path': {
                fill: '#000',
            },
        },
    },
});

const LightTooltip = withStyles(_theme => ({
    tooltip: {
        padding: '4px 4px',
        border: '0.3px solid #000000',
        background: '#fffff5',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        color: '#000',
        borderRadius: '0',
        marginLeft: 5,
        marginTop: 5,
    },
}))(Tooltip);

class ClassManagementRecordComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorGeneral: null,
            isFiltered: false,
            writeDataFilter: 'date',
        };
    }

    handleChangeCheckBox = () => {
        this.props.neoPenStore.selectPaperGroup(undefined);

        this.setState({ isFiltered: !this.state.isFiltered });
    };

    handleChangeDataSort = event => {
        this.setState({ writeDataFilter: event.target.value });

        this.props.neoPenStore.sortPaperGroupByModifiedAt(event.target.value);
    };

    handleClickListOpen = paperGroup => {
        const nPS = this.props.neoPenStore;

        if (nPS.selectedPaperGroup === paperGroup) {
            nPS.selectPaperGroup(undefined);

            return;
        }

        nPS.selectPaperGroup(paperGroup);
    };

    handleClickGeneral = (event, paperGroup) => {
        this.props.neoPenStore.selectPaperGroup(paperGroup);

        this.setState({ anchorGeneral: event.currentTarget });
    };

    handleClose = () => {
        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
        });
    };

    handleClickWritingBoard = e => {
        window.open('/writingBoard/' + this.props.selectedGroupId);
    };

    componentDidMount = async () => {
        const nPS = this.props.neoPenStore;
        const cS = this.props.classStore;

        if (nPS.groups.length === 0) {
            const user = this.props.authStore.loginUser;

            nPS.setUser(user);

            await cS.getGroupList(user);

            nPS.setGroups(cS.groupList);
        }

        const allUsersWithGroupId = {};

        if (Object.keys(nPS.groupUserAllNotes).length === 0) {
            const allMemberEmails = {};

            for (let group of nPS.groups) {
                await cS.getGroupDetail(group.id);

                allUsersWithGroupId[group.id] = cS.selectedGroupDetail.lmsUser;

                for (let user of cS.selectedGroupDetail.lmsUser) {
                    if (allMemberEmails[user.email] === undefined) allMemberEmails[user.email] = [];

                    allMemberEmails[user.email].push(group.id);
                }
            }

            nPS.getAllUserNotes(allMemberEmails, nPS.user.email);

            nPS.setAllUsersWithGroupId(allUsersWithGroupId);
        }
    };

    render() {
        const { classes } = this.props;
        const { isUploading } = this.props.classFileStore;
        const { anchorGeneral } = this.state;
        const general = Boolean(anchorGeneral);

        const nPS = this.props.neoPenStore;

        // let breadcrumbs = [];
        // let tempFolder = nPS.currentFolder;
        //
        // while (tempFolder !== undefined && tempFolder.id !== null) {
        //     let currentFolder = tempFolder;
        //
        //     breadcrumbs.push(
        //         <Button className={classes.breadcrumbsBtnStyle} key={tempFolder.id + 'breadcrumbs'} onClick={() => nPS.selectFolder(currentFolder)}>
        //             {tempFolder.name}
        //         </Button>,
        //     );
        //
        //     tempFolder = nPS.folders[tempFolder.parentId];
        // }
        //
        // breadcrumbs = breadcrumbs.reverse();

        const sortedNotes = [];

        entries(nPS.groupUserAllNotes).map(([key, noteList]) => {
            if (noteList.length === 0) return;

            const paperGroup = noteList[0].paperGroup;
            const group = noteList[0].group;
            let isModified = false;

            for (const n of noteList) {
                if (paperGroup.lastStrokeAt === undefined) paperGroup.lastStrokeAt = n.note.lastStrokeAt;

                if (paperGroup.lastStrokeAt < n.note.lastStrokeAt) paperGroup.lastStrokeAt = n.note.lastStrokeAt;

                if (n.note.modifiedIds !== undefined && n.note.modifiedIds.length >= 2) isModified = true;
            }
            console.log('entries(nPS.groupUserAllNotes)', paperGroup, noteList.length);
            sortedNotes.push({ paperGroup: paperGroup, group: group, hasDataMemberCnt: noteList.length, isModified: isModified });
        });

        if (this.state.writeDataFilter === 'date') {
            sortedNotes.sort((a, b) => b.paperGroup.lastStrokeAt - a.paperGroup.lastStrokeAt);
        }

        if (this.state.writeDataFilter === 'alpha') {
            sortedNotes.sort((a, b) => {
                if (a.paperGroup.title < b.paperGroup.title) {
                    return -1;
                }

                if (b.paperGroup.title > b.paperGroup.title) {
                    return 1;
                }

                return 0;
            });
        }

        return (
            <div className={classes.root}>
                {this.props.selectedGroupId !== 0 && (
                    <Box className={classes.optionBox}>
                        <Button
                            component={'label'}
                            className={classes.btnStylePurple}
                            disabled={isUploading}
                            disableRipple
                            onClick={this.handleClickWritingBoard}
                        >
                            <ChartBarPurpleIcon />
                            필기 현황
                        </Button>
                    </Box>
                )}

                <Box className={classes.filterStyle} style={{ paddingBottom: 0 }}>
                    {/*<Button onClick={() => nPS.selectFolder(nPS.folders[null])} className={classes.tableBtnStyle} disableRipple>*/}
                    {/*    TOP |*/}
                    {/*</Button>*/}
                    {/*{breadcrumbs.length > 0 && breadcrumbs}*/}
                    <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                        {/*<Box*/}
                        {/*    onClick={this.handleChangeCheckBox}*/}
                        {/*    className={!this.state.isFiltered ? classes.checkBoxStyle : clsx(classes.checkBoxStyle, classes.checkBoxStyleOn)}*/}
                        {/*    style={{ marginRight: 20 }}*/}
                        {/*>*/}
                        {/*    {!this.state.isFiltered ? <CheckCircleAgreeOffIcon /> : <CheckCircleAgreeOnIcon />}*/}
                        {/*    첨삭한 필기만*/}
                        {/*</Box>*/}

                        <FormControl className={classes.formControl}>
                            <Select
                                value={this.state.writeDataFilter}
                                displayEmpty
                                onChange={this.handleChangeDataSort}
                                IconComponent={() => (
                                    <Box style={{ width: 16, height: 16, marginLeft: 5 }}>
                                        <ArrowDownIcon />
                                    </Box>
                                )}
                            >
                                <MenuItem value="date" className={classes.menuItem}>
                                    최근 작성일 순
                                </MenuItem>
                                <MenuItem value="alpha" className={classes.menuItem}>
                                    가나다 순
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                <Box>
                    {sortedNotes.map((note, i) => {
                        const paperGroup = note.paperGroup;
                        const group = note.group;

                        const isSelected = nPS.selectedPaperGroup === paperGroup;

                        let hasDataMemberCnt = note.hasDataMemberCnt;
                        console.log('note.note.modifiedIds.length', note);

                        if (this.props.selectedGroupId !== 0 && this.props.selectedGroupId !== group.id) return <Box />;

                        return (
                            <Box key={i} className={isSelected ? classes.listItemOpenStyle : ''}>
                                {(!this.state.isFiltered || (this.state.isFiltered && note.isModified)) && (
                                    <Box
                                        className={isSelected ? classes.listItemOpenInStyle : classes.listItemStyle}
                                        onClick={() => this.handleClickListOpen(paperGroup)}
                                    >
                                        <Box className={clsx(classes.flexCenter, classes.widthBox)} style={{ cursor: 'pointer' }}>
                                            <Box className={classes.avatar}>
                                                <NotePencilIcon />
                                            </Box>
                                            <Box className={classes.flexGrow}>
                                                <LightTooltip title={paperGroup.title.trim().replace(/(.pdf)$/, '')} placement="bottom-start">
                                                    <Typography variant="h5" className={classes.listItemTitle}>
                                                        {paperGroup.title.trim().replace(/(.pdf)$/, '')}
                                                    </Typography>
                                                </LightTooltip>
                                                <Typography variant="body2" className={classes.listItemDate}>
                                                    {`${group.name} | `}
                                                    {paperGroup.lastStrokeAt === undefined
                                                        ? ''
                                                        : `${nPS.convertTimestamp(paperGroup.lastStrokeAt).split(',')[0]}`}
                                                    {` | ${hasDataMemberCnt}명 작성`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                )}

                                {isSelected && (
                                    <ClassManagementRecordInfoComponent
                                        isOpen={isSelected}
                                        paperGroup={paperGroup}
                                        groupUsers={nPS.allUsersWithGroupId[group.id]}
                                    />
                                )}
                            </Box>
                        );
                    })}
                    {/* 등록된 필기기록이 없을경우*/}
                    {/*<Box>*/}
                    {/*    <Typography className={classes.emptyText}>등록된 필기 기록이 없습니다.</Typography>*/}
                    {/*</Box>*/}
                </Box>
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('neoPenStore', 'authStore', 'classStore', 'classFileStore')(observer(ClassManagementRecordComponent))),
);
