import React, { Component } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CalendarShape from './CalendarShape';
import StartTime from './StartTime';
import MeetingTime from './MeetingTime';
import dayjs from 'dayjs';
import { DATE_UTIL } from '../../../common/util/date.util';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { ClassMainPath } from '../../../common/ClassMainPath';

const style = theme => ({
    root: {
        padding: 15,
        borderRadius: 10,
        display: 'flex',
        '& .MuiButton-root': {
            minWidth: 0,
        },
    },
    paddingLeft: {
        paddingLeft: 15,
        '@media all and (max-width: 600px)': {
            paddingLeft: 10,
        },
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        fontSize: '0.875rem',
        fontWeight: 800,
        textAlign: 'center',
    },
    buttonStyle: {
        padding: '7px 23px',
        borderRadius: 6,
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        fontFamily: 'NanumSquareRoundOTF',
        color: '#fff',
        fontSize: '0.875rem',
        '&:hover': {
            fontWeight: 800,
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            padding: '5px 23px',
            borderRadius: 5,
            fontSize: '0.75rem',
        },
    },
    titleStyle1: {
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        fontSize: '0.875rem',
        fontWeight: 600,
        marginTop: 20,
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    calenderText2: {
        '@media all and (max-width: 600px)': {
            fontSize: '0.688rem',
        },
    },
});

class CalendarBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDatetime: {
                date: dayjs(new Date(DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT)).setHours(0, 0, 0)).tz(
                    DATE_UTIL.getTimeZone(),
                    true,
                ),
                hour: '',
                min: '',
                am: 'AM',
            },
            endDatetime: {
                hour: '',
                min: '',
            },
            checkNull: false,
        };
    }
    onChangeDate = props => {
        this.setState({ startDatetime: { ...this.state.startDatetime, date: props } }, () =>
            this.props.roomStore.onChangeTemporaryDate(this.state, this.props.intl),
        );
    };

    onChangeStartTime = (time, minute, radio) => {
        this.setState({ startDatetime: { ...this.state.startDatetime, hour: time, min: minute, am: radio } }, this.InvalidationDate);
    };

    onChangeStartMin = minute => {
        this.setState({ startDatetime: { ...this.state.startDatetime, min: minute } }, this.InvalidationDate);
    };

    onChangeAm = radio => {
        this.setState({ startDatetime: { ...this.state.startDatetime, am: radio } });
    };

    onChangeEndTime = (time, minute) => {
        this.setState({ endDatetime: { hour: time, min: minute } }, this.InvalidationDate);
    };

    // onChangeEndMin = props => {
    //     this.setState({ endDatetime: { ...this.state.endDatetime, min: props } }, this.InvalidationDate);
    // };

    InvalidationDate = () => {
        let checkNull = false;
        if (this.state.startDatetime.hour === '' || this.state.startDatetime.hour === '시간') {
            checkNull = true;
        } else if (this.state.startDatetime.min === '' || this.state.startDatetime.min === '분') {
            checkNull = true;
        } else if (this.state.endDatetime.hour === '' || this.state.endDatetime.hour === '시간') {
            checkNull = true;
        } else if (
            this.state.endDatetime.min === '' ||
            (this.state.endDatetime.hour === '00' && (this.state.endDatetime.min === '00' || this.state.endDatetime.min === ''))
        ) {
            checkNull = true;
        }
        if (!checkNull) {
            this.props.roomStore.onChangeTemporaryDate(this.state, this.props.intl);
        }
        this.setState({ checkNull: checkNull });
    };

    handleSave = timeInfo => {
        this.props.roomStore.onChangeDate(timeInfo, this.props.intl);
        this.props.onClickSave();
    };

    handleCancel = () => {
        this.props.onClickSave();
    };

    render() {
        const { classes, roomStore, classTab } = this.props;
        const isMemberTab = classTab === ClassMainPath.member;
        return (
            <div className={classes.root}>
                <CalendarShape onChangeDate={this.onChangeDate} />
                <Box className={classes.paddingLeft}>
                    <StartTime
                        onChangeStartTime={this.onChangeStartTime}
                        onChangeStartMin={this.onChangeStartMin}
                        onChangeAm={this.onChangeAm}
                        isMemberTab={isMemberTab}
                    />
                    <MeetingTime onChangeEndTime={this.onChangeEndTime} />
                    <Typography className={classes.titleStyle1}>종료 시각</Typography>
                    {roomStore.temporaryStartDatetime !== '' && !this.state.checkNull ? (
                        <Typography className={classes.calenderText2}>
                            {dayjs(roomStore.temporaryEndDatetime).format(DATE_UTIL.FORMAT.FULL_DATE_AMPM_DOT_FORMAT)}
                        </Typography>
                    ) : (
                        <Typography className={classes.calenderText2}>&nbsp;</Typography>
                    )}
                    <Box display="flex" justifyContent="center" pt={2}>
                        <Button className={classes.buttonStyle} disabled={this.state.checkNull} onClick={() => this.handleSave(this.state)}>
                            완료
                        </Button>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(inject('roomStore')(observer(CalendarBox))));
