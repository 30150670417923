import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, InputBase, Paper } from '@material-ui/core';
import { ReactComponent as MagnifyingGlassIcon } from '../../common/images/MagnifyingGlassIcon.svg';
import { inject, observer } from 'mobx-react';
import { withRouter } from '../../components/WithRouter';

const styles = _theme => ({
    root: {},
    searchBox: {
        display: 'flex',
        boxShadow: 'none',
        border: '1px solid #C4C4C4',
        padding: '4px 9px',
        margin: '50px 0 20px',
        borderRadius: 7,
        '& .MuiInputBase-root': {
            width: '100%',
        },
        '& input': {
            width: '98%',
            fontSize: '0.75rem',
        },
    },
    iconButton: {
        padding: 8,
        backgroundColor: '#f8f8f8',
        '&:hover': {
            backgroundColor: '#f8f8f8',
        },
    },
});

const RESPONSE_KEY_CODE = {
    ENTER: 13,
};

class ClassContentSearchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchKeyword: '',
        };
    }

    handleKeyDown = e => {
        const { isSearchRoomLoading } = this.props.roomStore;
        if (
            e.keyCode === RESPONSE_KEY_CODE.ENTER &&
            !isSearchRoomLoading &&
            this.state.searchKeyword !== '' &&
            this.state.searchKeyword &&
            this.state.searchKeyword.trim() !== ''
        ) {
            this.handleSearchRooms();
        }
    };

    handleChangeSearchKeyword = e => {
        this.setState({ searchKeyword: e.target.value });
    };

    handleSearchRooms = () => {
        const groupId = this.props.classPath.id;
        this.props.handleChangeSearch(this.state.searchKeyword);
        this.props.scrollMove();
        this.props.roomStore.getRoomListBySubSearchKeyword(groupId, this.state.searchKeyword, 1);
    };

    render() {
        const { classes, roomStore } = this.props;
        const { isSearchRoomLoading } = roomStore;

        return (
            <div>
                <Paper className={classes.searchBox}>
                    <InputBase
                        placeholder={'클래스 내 일정, 작성자, 태그 검색'}
                        onChange={this.handleChangeSearchKeyword}
                        onKeyDown={this.handleKeyDown}
                    />
                    <IconButton
                        className={classes.iconButton}
                        aria-label="Search"
                        disableRipple
                        onClick={this.handleSearchRooms}
                        disabled={
                            isSearchRoomLoading ||
                            this.state.searchKeyword === '' ||
                            (this.state.searchKeyword && this.state.searchKeyword.trim() === '')
                        }
                    >
                        <MagnifyingGlassIcon />
                    </IconButton>
                </Paper>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('roomStore')(observer(ClassContentSearchComponent))));
