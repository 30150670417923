import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { ReactComponent as EyeTinyIcon } from '../../../common/images/EyeIcon.svg';
import { ReactComponent as HandsClappingIcon } from '../../../common/images/HandsClappingIcon.svg';
import { ReactComponent as ChatCircleDotsIcon } from '../../../common/images/ChatCircleDotsIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../../common/images/ArrowDownIcon.svg';
import { ReactComponent as NoFilesIcon } from '../../../common/images/NoFilesIcon.svg';
import { withRouter } from '../../../components/WithRouter';
import RoomTestImg1 from '../../../common/images/RoomTestImg1.png';
import { inject, observer } from 'mobx-react';
import { DATE_UTIL } from '../../../common/util/date.util';
import { loadingSate } from '../../../stores/RoomStore';
import { postSortType } from '../../../stores/PostStore';
import { PATH_UTIL } from '../../../common/util/path.util';
import { ClassMainPath } from '../../../common/ClassMainPath';

const styles = _theme => ({
    root: {
        wordBreak: 'break-all',
    },
    categoryStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        marginTop: 30,
    },
    tabStyle: {
        fontWeight: 300,
        '& button': {
            minWidth: 'auto',
            fontSize: '0.875rem',
            padding: 0,
            marginRight: 30,
            color: '#a9adb4',
            '&.Mui-selected span': {
                color: '#000',
                fontWeight: 700,
            },
            '& span': {
                fontWeight: 300,
            },
        },
        '& .MuiTabs-indicator': {
            display: 'none',
        },
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.813rem',
        color: '#0d0d0d',
        minHeight: 40,
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 15,
        '& svg': {
            marginBottom: 2,
        },
    },
    titleText: {
        fontSize: '1.063rem',
        fontWeight: 500,
        cursor: 'pointer',
    },
    notifyStyle: {
        fontSize: '0.875rem',
        color: '#004FC4',
        fontWeight: 600,
        backgroundColor: '#E5F0FF',
        borderRadius: 30,
        padding: '4px 6px',
        marginRight: 7,
        minWidth: 28,
    },
    subtitleStyle: {
        fontSize: '1.063rem',
        color: '#00c880',
        marginRight: 4,
        fontWeight: 600,
    },
    iconMargin: {
        marginLeft: 5,
    },
    tabLine: {
        width: 1,
        height: 20,
        background: 'rgba(0, 0, 0, 0.2)',
        margin: '0 24px 0 12px',
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    cardBox: {
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
        border: '1px solid #d3d7db',
        borderRadius: 10,
        marginBottom: 20,
    },
    boxInPadding: {
        cursor: 'pointer',
        padding: '20px 18px 15px',
    },
    boxContents: {
        margin: '25px 0 22px',
    },
    subTextBox: {
        fontSize: '0.938rem',
        color: '#000',
    },
    imgBox: {
        marginTop: 20,
        '& > img': {
            borderRadius: 15,
            width: '100%',
            height: 372,
            objectFit: 'cover',
        },
    },
    buttonStyle: {
        minWidth: 20,
        padding: 0,
        fontSize: '0.875rem',
        color: '#a3a8af',
        fontWeight: 400,
        marginLeft: 5,
        '&:hover': {
            background: 'transparent',
        },
    },
    textStyle: {
        fontSize: '0.75rem',
        color: '#5c5c5c',
    },
    textLine: {
        paddingRight: 20,
        borderRight: '1px solid #e1e1e1',
        marginRight: 20,
    },
    boxFooter: {
        background: 'rgba(237, 237, 237, 0.6)',
        borderRadius: 10,
    },
    ftCount: {
        padding: '13px 14px',
        borderBottom: '1px solid #fff',
        display: 'flex',
        '& div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.75rem',
            color: '#7F7F7F',
            marginRight: 7,
            '& svg': {
                width: 14,
                height: 14,
                opacity: 0.5,
                marginRight: 4,
            },
        },
    },
    ftAdded: {
        padding: '5px 60px',
        '@media (min-width: 319px) and (max-width: 374px)': {
            padding: '5px 50px',
        },
        '& button': {
            width: '50%',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: '0.813rem',
            '&:hover': {
                background: 'transparent',
                fontWeight: 800,
            },
            '& svg': {
                marginRight: 4,
                opacity: 0.5,
            },
            '&.Mui-disabled': {
                color: '#000',
            },
        },
    },
    active: {
        color: '#0097ff',
        fontWeight: 700,
    },
    iconActive: {
        '& path': {
            fill: '#0097ff',
        },
    },
    noFilesBox: {},
    iconBox: {
        marginBottom: 13,
        width: 44,
        height: 44,
        background: '#e9e9e9',
        borderRadius: '50%',
        '& svg': {
            width: 27,
            height: 27,
        },
        '& .aside-user': {
            fill: '#000',
        },
    },
    memberNoText: {
        fontSize: '0.873rem',
        color: '#a9adb4',
    },
});

class MobileFreeBoardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            board: 0,
            page: 1,
            labelWidth: 0,
            GoodToggle: false,
            cardViewList: [
                {
                    notice: true,
                    badge: false,
                    topic: '일정',
                    title: '수업 중 유의사항',
                    text: '자유 게시판영화 ‘매트릭스’ 보신 적 있나요?\n그렇다면 주인공인 네오가 ‘훈련’을 받는 장면을 기억하실 거예요. 해변에서 달리고, 점점 더 강해지도록 운동하는 장면을 보여주는 대신, 다른 캐릭터가 네오의 뇌에 직접 프로그램을 집어 넣습니다. 몇 초 후, 네오는 눈을 뜨고몇 초 후',
                    name: '강효주',
                    date: '2021.12.15',
                    imgShow: false,
                    img: '',
                },
                {
                    notice: false,
                    badge: true,
                    topic: '이것저것',
                    title: '수업 중 유의사항',
                    text: '자유 게시판영화 ‘매트릭스’ 보신 적 있나요?',
                    name: '강효주',
                    date: '2021.12.15',
                    imgShow: false,
                    img: '',
                },
                {
                    notice: false,
                    badge: true,
                    topic: '정보/팁',
                    title: '언제 외국어 쉽게 익히는 법',
                    text: '자유 게시판영화 ‘매트릭스’ 보신 적 있나요?',
                    name: '강효주',
                    date: '2021.12.15',
                    imgShow: true,
                    img: RoomTestImg1,
                },
            ],
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, _prevState: Readonly<S>, _snapshot: SS) {
        const { postStore } = this.props;
        if (prevProps.isScrollEnd !== this.props.isScrollEnd && this.props.isScrollEnd && postStore.totalPostCount > this.props.posts.length) {
            const pathName = window.location.pathname;
            const groupId = PATH_UTIL.getClassId(pathName);
            this.setState({ page: this.state.page + 1 }, () => {
                this.props.postStore.getPostList(groupId, this.props.authStore.loginUser.id, undefined, this.state.page, 10);
            });
        }
    }

    handleChangeBoard = (_event, board) => {
        this.setState({ board });
    };

    handleChange = event => {
        const pathName = window.location.pathname;
        const groupId = PATH_UTIL.getClassId(pathName);
        this.props.postStore.setPostSortType(event.target.value);
        this.props.postStore.initPostList();
        this.setState({ page: 1 }, () => {
            this.props.postStore.getPostList(groupId, this.props.authStore.loginUser.id, undefined, this.state.page, 10);
        });
    };

    handleChangeGoodToggle = (loginUser, groupId, postId, likeId) => {
        this.props.postStore.changeLikeState(loginUser, groupId, postId, likeId);
    };

    ellipsisText = text => (text.length > 147 ? text.substring(0, 147) + '...' : text);

    render() {
        const { classes, posts, handleClickPostDetail, postStore, classTab } = this.props;
        const { isPostListLoading } = postStore;
        const { board } = this.state;

        return (
            <div className={classes.root}>
                {/*<Box className={classes.categoryStyle}>*/}
                {/*    <Tabs value={board} onChange={this.handleChangeBoard} className={classes.tabStyle}>*/}
                {/*        <Tab label="전체" disableRipple />*/}
                {/*        {topicList.length > 0 &&*/}
                {/*            topicList.map(topic => {*/}
                {/*                return <Tab key={topic.id} label={topic.name} disableRipple />;*/}
                {/*            })}*/}
                {/*        /!*<Tab label="이것저것" disableRipple />*!/*/}
                {/*        /!*<Tab label="일정" disableRipple />*!/*/}
                {/*        /!*<Tab label="기사/뉴스" disableRipple />*!/*/}
                {/*        /!*<Tab label="정보/팁" disableRipple />*!/*/}
                {/*        /!*<Tab label="홍보/광고" disableRipple />*!/*/}
                {/*    </Tabs>*/}

                <Box display="flex" alignItems="center" justifyContent="flex-end" mt={3}>
                    {/*<IconButton className={classes.iconButton} onClick={this.props.handleChangeTopicDialogOpen} disableRipple>*/}
                    {/*    <PencilLine />*/}
                    {/*</IconButton>*/}
                    {/*<span className={classes.tabLine} />*/}
                    {classTab === ClassMainPath.community && (
                        <FormControl className={classes.formControl}>
                            <Select
                                value={this.props.postStore.currentSortType}
                                onChange={this.handleChange}
                                displayEmpty
                                IconComponent={() => (
                                    <Box style={{ width: 16, height: 16, marginLeft: 5 }}>
                                        <ArrowDownIcon />{' '}
                                    </Box>
                                )}
                            >
                                <MenuItem value={postSortType.Recent} className={classes.menuItem}>
                                    최신순
                                </MenuItem>
                                <MenuItem value={postSortType.Recommend} className={classes.menuItem}>
                                    추천순
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}
                </Box>

                {board === 0 && !isPostListLoading ? (
                    posts.length > 0 ? (
                        posts.map((post, i) => (
                            <Box key={i} className={classes.cardBox}>
                                <Box className={classes.boxInPadding} onClick={e => handleClickPostDetail(e, post.postId)}>
                                    <Box className={classes.title}>
                                        {post.notice && <span className={classes.notifyStyle}>공지</span>}
                                        {/*<span className={classes.subtitleStyle}>[{post.topicName}]</span>*/}
                                        <Typography className={classes.titleText}>{post.title}</Typography>
                                        {/*{true && <BedgeNewIcon className={classes.iconMargin} />}*/}
                                    </Box>
                                    <Box className={classes.boxContents}>
                                        <span className={classes.subTextBox}>{this.ellipsisText(post.body)}</span>
                                        {post.body.length > 147 && (
                                            <Button
                                                className={classes.buttonStyle}
                                                onClick={e => handleClickPostDetail(e, post.postId)}
                                                disableRipple
                                            >
                                                더보기
                                            </Button>
                                        )}
                                        {post.imgShow === true && (
                                            <Box className={classes.imgBox}>
                                                <img src={post.img} alt="image" />
                                            </Box>
                                        )}
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Typography className={clsx(classes.textStyle, classes.textLine)}>{post.userName}</Typography>
                                        <Typography className={classes.textStyle}>
                                            {DATE_UTIL.convertUTCToTimeZone(post.updatedDatetime, DATE_UTIL.FORMAT.DOT_DATE_FORMAT)}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.boxFooter}>
                                    <Box className={classes.ftCount}>
                                        <Box>
                                            <EyeTinyIcon /> {post.viewCount}{' '}
                                        </Box>
                                        <Box>
                                            <HandsClappingIcon
                                                className={
                                                    post.likeList.find(user => user.userId === this.props.authStore.loginUser.id)
                                                        ? classes.iconActive
                                                        : null
                                                }
                                            />{' '}
                                            {post.likeList ? post.likeList.length : 0}{' '}
                                        </Box>
                                        <Box>
                                            <ChatCircleDotsIcon /> {post.commentCount}{' '}
                                        </Box>
                                    </Box>
                                    <Box className={classes.ftAdded}>
                                        <Button
                                            disableRipple
                                            disabled={this.props.postStore.likeLoading === loadingSate.PENDING}
                                            onClick={() =>
                                                this.handleChangeGoodToggle(
                                                    this.props.authStore.loginUser,
                                                    post.groupId,
                                                    post.postId,
                                                    post.likeList.find(user => user.userId === this.props.authStore.loginUser.id)
                                                        ? post.likeList.find(user => user.userId === this.props.authStore.loginUser.id).likeId
                                                        : 0,
                                                )
                                            }
                                            // className={
                                            //     post.likeList.find(user => user.userId === this.props.authStore.loginUser.id) ? classes.active : null
                                            // }
                                        >
                                            <HandsClappingIcon
                                                className={
                                                    post.likeList.find(user => user.userId === this.props.authStore.loginUser.id)
                                                        ? classes.iconActive
                                                        : null
                                                }
                                            />{' '}
                                            좋아요
                                        </Button>
                                        <Button onClick={e => handleClickPostDetail(e, post.postId)} disableRipple>
                                            <ChatCircleDotsIcon /> 댓글 달기
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.noFilesBox}>
                            <Box display="flex" alignItems="center" justifyContent="center" className={classes.iconBox}>
                                <NoFilesIcon />
                            </Box>
                            <Typography className={classes.memberNoText}>게시된 글이 없습니다.</Typography>
                        </Box>
                    )
                ) : (
                    <Box style={{ textAlign: 'center', marginTop: 50, marginBottom: 1000 }}>
                        <CircularProgress />
                    </Box>
                )}
                {/*{board === 1 && (*/}
                {/*    <Box>*/}
                {/*        {this.state.cardViewList.map((cardView, i) => (*/}
                {/*            <Box key={i} className={classes.cardBox}>*/}
                {/*                <Box className={classes.boxInPadding}>*/}
                {/*                    <Box className={classes.title}>*/}
                {/*                        {cardView.notice === true && <span className={classes.notifyStyle}>공지</span>}*/}
                {/*                        <span className={classes.subtitleStyle}>[{cardView.topic}]</span>*/}
                {/*                        <Typography variant="h4">{cardView.title}</Typography>*/}
                {/*                        {cardView.badge === true && <BedgeNewIcon className={classes.iconMargin} />}*/}
                {/*                    </Box>*/}
                {/*                    <Box className={classes.boxContents}>*/}
                {/*                        <span className={classes.subTextBox}>{this.ellipsisText(cardView.text)}</span>*/}
                {/*                        {cardView.text.length > 147 && (*/}
                {/*                            <Button className={classes.buttonStyle} onClick={this.props.handleChangeNoticeBoard} disableRipple>*/}
                {/*                                더보기*/}
                {/*                            </Button>*/}
                {/*                        )}*/}
                {/*                        {cardView.imgShow === true && (*/}
                {/*                            <Box className={classes.imgBox}>*/}
                {/*                                <img src={cardView.img} alt="image" />*/}
                {/*                            </Box>*/}
                {/*                        )}*/}
                {/*                    </Box>*/}
                {/*                    <Box display="flex" alignItems="center">*/}
                {/*                        <Typography className={clsx(classes.textStyle, classes.textLine)}>{cardView.name}</Typography>*/}
                {/*                        <Typography className={classes.textStyle}>{cardView.date}</Typography>*/}
                {/*                    </Box>*/}
                {/*                </Box>*/}
                {/*                <Box className={classes.boxFooter}>*/}
                {/*                    <Box className={classes.ftCount}>*/}
                {/*                        <Box>*/}
                {/*                            <EyeTinyIcon /> {}{' '}*/}
                {/*                        </Box>*/}
                {/*                        <Box>*/}
                {/*                            <HandsClappingIcon className={this.state.GoodToggle ? classes.iconActive : null} /> 5{' '}*/}
                {/*                        </Box>*/}
                {/*                        <Box>*/}
                {/*                            <ChatCircleDotsIcon /> 2{' '}*/}
                {/*                        </Box>*/}
                {/*                    </Box>*/}
                {/*                    <Box className={classes.ftAdded}>*/}
                {/*                        <Button*/}
                {/*                            disableRipple*/}
                {/*                            onClick={this.handleChangeGoodToggle}*/}
                {/*                            className={this.state.GoodToggle ? classes.active : null}*/}
                {/*                        >*/}
                {/*                            <HandsClappingIcon /> 좋아요*/}
                {/*                        </Button>*/}
                {/*                        <Button onClick={this.props.handleChangeNoticeBoard} disableRipple>*/}
                {/*                            <ChatCircleDotsIcon /> 댓글 달기*/}
                {/*                        </Button>*/}
                {/*                    </Box>*/}
                {/*                </Box>*/}
                {/*            </Box>*/}
                {/*        ))}*/}
                {/*    </Box>*/}
                {/*)}*/}
                {/*{board === 2 && (*/}
                {/*    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.noFilesBox}>*/}
                {/*        <Box display="flex" alignItems="center" justifyContent="center" className={classes.iconBox}>*/}
                {/*            <NoFilesIcon />*/}
                {/*        </Box>*/}
                {/*        <Typography className={classes.memberNoText}>게시된 글이 없습니다.</Typography>*/}
                {/*    </Box>*/}
                {/*)}*/}
                {/*{board === 3 && (*/}
                {/*    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.noFilesBox}>*/}
                {/*        <Box display="flex" alignItems="center" justifyContent="center" className={classes.iconBox}>*/}
                {/*            <NoFilesIcon />*/}
                {/*        </Box>*/}
                {/*        <Typography className={classes.memberNoText}>게시된 글이 없습니다.</Typography>*/}
                {/*    </Box>*/}
                {/*)}*/}
                {/*{board === 4 && (*/}
                {/*    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.noFilesBox}>*/}
                {/*        <Box display="flex" alignItems="center" justifyContent="center" className={classes.iconBox}>*/}
                {/*            <NoFilesIcon />*/}
                {/*        </Box>*/}
                {/*        <Typography className={classes.memberNoText}>게시된 글이 없습니다.</Typography>*/}
                {/*    </Box>*/}
                {/*)}*/}
                {/*{board === 5 && (*/}
                {/*    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.noFilesBox}>*/}
                {/*        <Box display="flex" alignItems="center" justifyContent="center" className={classes.iconBox}>*/}
                {/*            <NoFilesIcon />*/}
                {/*        </Box>*/}
                {/*        <Typography className={classes.memberNoText}>게시된 글이 없습니다.</Typography>*/}
                {/*    </Box>*/}
                {/*)}*/}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('roomStore', 'postStore', 'authStore', 'commentStore')(observer(MobileFreeBoardComponent))));
