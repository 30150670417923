import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, FormControl, MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { ReactComponent as NotePencilIcon } from '../../common/images/NotePencilIcon.svg';
import { ReactComponent as FolderNotchOpenIcon } from '../../common/images/FolderNotchOpenIcon.svg';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import PaperGroupFolderBreadcrumbs from '../neoPen/PaperGroupFolderBreadcrumbs';
const styles = theme => ({
    root: {
        width: 290,
        marginRight: 40,
        border: '1px solid #c4c4c4',
        borderRadius: 7,
        height: 'calc(100vh - 290px)',
        boxSizing: 'border-box',
        '& h4': {
            fontSize: '1.125rem',
            color: '#0d0d0d',
            borderBottom: '1px solid #d3d7db',
            padding: '12px 15px 20px 15px',
        },
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contents: {
        padding: '12px 15px',
    },
    topStyle: {
        borderBottom: '1px solid #d3d7db',
        marginBottom: 16,
    },
    tableBtnStyle: {
        padding: 0,
        minWidth: 'auto',
        '&:hover': {
            background: 'transparent',
        },
    },
    formControl: {
        minWidth: 100,
        width: 100,
        display: 'flex',
        marginLeft: 'auto',
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    folderStyle: {
        width: '100%',
        borderBottom: '1px solid #d3d7db',
        paddingBottom: 6,
    },
    iconStyle: {
        marginRight: 8,
    },
    FolderInfo: {
        fontSize: '0.75rem',
        color: '#666',
    },
    folderStyleButton: {
        width: '100%',
        '&:hover': {
            background: 'transparent',
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '12px 0',
        width: '100%',
        '&:hover': {
            background: 'transparent',
        },
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatarSelected: {
        backgroundColor: '#0097ff',
    },
    listItemTitle: {
        width: 190,
        fontSize: '0.875rem',
        color: '#000',
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'left',
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    emptyText: {
        fontSize: '0.875rem',
        color: '#000',
        marginTop: 30,
        textAlign: 'center',
    },
    lightTooltip: {
        backgroundColor: '#FFFFF5',
        color: '#000',
        border: '1px solid #000',
        fontSize: '0.688rem',
        borderRadius: 0,
        marginLeft: 0,
        marginTop: 0,
    },
});
class WritingBoardCategoryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            writeDataFilter: 'date',
            selectList: true,
        };

        this.loadPaperGroups();
    }

    loadPaperGroups = async () => {
        const nPS = this.props.neoPenStore;
        const nPSS = this.props.neoPenStatisticsStore;

        const user = this.props.authStore.loginUser;

        nPS.setUser(user);
        nPSS.setTeacherId(user.id);

        await nPS.getAllPaperGroups(this.props.groupId, user.email);

        nPS.getAllFolders(this.props.groupId);

        if (nPS.activePaperGroups.length > 0) {
            nPS.selectPaperGroup(nPS.activePaperGroups[0]);

            nPSS.getStatisticsByGroupIdAndPaperGroupId(this.props.groupId, nPS.activePaperGroups[0].paperGroupId);
        }
    };

    handleChangeDataSort = event => {
        this.setState({ writeDataFilter: event.target.value });

        this.props.neoPenStore.sortFolder(event.target.value);
        this.props.neoPenStore.sortPaperGroupByModifiedAt(event.target.value);
    };

    handleChangeSelectList = () => {
        this.setState({ selectList: !this.state.selectList });
    };

    render() {
        const { classes } = this.props;

        const nPS = this.props.neoPenStore;
        const nPSS = this.props.neoPenStatisticsStore;

        const viewingPaperGroups = nPS.activePaperGroups.filter(paperGroup => paperGroup.folderId === nPS.currentFolder.id);

        return (
            <div className={classes.root}>
                <Typography variant="h4">필기 기록 서식</Typography>
                <Box className={classes.contents}>
                    <Box className={classes.topStyle}>
                        <PaperGroupFolderBreadcrumbs />
                    </Box>
                    <FormControl className={classes.formControl}>
                        <Select
                            value={this.state.writeDataFilter}
                            displayEmpty
                            onChange={this.handleChangeDataSort}
                            IconComponent={() => (
                                <Box style={{ width: 16, height: 16, marginLeft: 5 }}>
                                    <ArrowDownIcon />
                                </Box>
                            )}
                        >
                            <MenuItem value="date" className={classes.menuItem}>
                                최근 작성일 순
                            </MenuItem>
                            <MenuItem value="alpha" className={classes.menuItem}>
                                가나다 순
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Box style={{ height: 'calc(100vh - 290px - 150px)', overflowY: 'auto' }}>
                        <Box display="flex" flexDirection="column">
                            {nPS.currentFolder.childIds.map(folderId => (
                                <Tooltip
                                    title={nPS.folders[folderId].name}
                                    placement="bottom"
                                    classes={{
                                        tooltip: classes.lightTooltip,
                                    }}
                                >
                                    <Button
                                        onClick={() => nPS.selectFolder(nPS.folders[folderId])}
                                        disableRipple
                                        className={classes.folderStyleButton}
                                    >
                                        <Box className={classes.folderStyle}>
                                            <Box className={classes.flexCenter} style={{ flexGrow: 1 }}>
                                                <Box className={classes.iconStyle} style={{ flexGrow: 0 }}>
                                                    <FolderNotchOpenIcon />
                                                </Box>
                                                <Box flexDirection="column" style={{ flexGrow: 1 }}>
                                                    <Typography className={classes.listItemTitle}>{nPS.folders[folderId].name}</Typography>
                                                    <Box display="flex" alignItems="flex-end">
                                                        <Typography variant="body2" className={classes.FolderInfo}>
                                                            {nPS.folders[folderId].updatedDatetime.split('T')[0]}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Button>
                                </Tooltip>
                            ))}
                        </Box>

                        {viewingPaperGroups.map(paperGroup => {
                            return (
                                <Tooltip
                                    title={paperGroup.title}
                                    placement="bottom"
                                    classes={{
                                        tooltip: classes.lightTooltip,
                                    }}
                                >
                                    <Button
                                        className={classes.listItemStyle}
                                        disableRipple
                                        onClick={() => {
                                            nPS.selectPaperGroup(paperGroup);

                                            nPSS.getStatisticsByGroupIdAndPaperGroupId(this.props.groupId, paperGroup.paperGroupId);
                                        }}
                                    >
                                        <Box className={classes.flexCenter} style={{ flexGrow: 1 }}>
                                            <Box
                                                className={
                                                    nPS.selectedPaperGroup && nPS.selectedPaperGroup.id === paperGroup.id
                                                        ? clsx(classes.avatar, classes.avatarSelected)
                                                        : classes.avatar
                                                }
                                                style={{ flexGrow: 0 }}
                                            >
                                                <NotePencilIcon />
                                            </Box>
                                            <Box flexDirection="column" style={{ flexGrow: 1 }}>
                                                <Typography className={classes.listItemTitle}>{paperGroup.title}</Typography>
                                                <Box display="flex" alignItems="flex-end">
                                                    <Typography variant="body2" className={classes.listItemDate}>
                                                        {paperGroup.createdDatetime.split('T')[0]}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Button>
                                </Tooltip>
                            );
                        })}
                        {viewingPaperGroups.length === 0 && (
                            <Box>
                                <Typography className={classes.emptyText}>등록된 서식이 없습니다.</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withStyles(styles)(inject('neoPenStore', 'neoPenStatisticsStore', 'authStore', 'classStore')(observer(WritingBoardCategoryComponent)));
