import React, { Component } from 'react';
import { Avatar, Box, Button, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as SmileyFillIcon } from '../../../common/images/SmileyFillIcon.svg';
import { ReactComponent as AlarmFillIcon } from '../../../common/images/AlarmFillIcon.svg';
import { ReactComponent as HandsClappingIcon } from '../../../common/images/HandsClappingIcon.svg';
import { ReactComponent as ChatCircleDotsIcon } from '../../../common/images/ChatCircleDotsIcon.svg';
import { ReactComponent as ChartBarFillIcon } from '../../../common/images/ChartBarFillIcon.svg';
import clsx from 'clsx';
import { withRouter } from '../../../components/WithRouter';
import dayjs from 'dayjs';
import { DATE_UTIL } from '../../../common/util/date.util';
import { loadingSate, roomState } from '../../../stores/RoomStore';
import { inject, observer } from 'mobx-react';
import LinkView from './MobileLinkView';
import { ReactComponent as AsideUserIcon } from '../../../common/images/AsideUserIcon.svg';
import { ReactComponent as NoFilesIcon } from '../../../common/images/NoFilesIcon.svg';
import { ToastsStore } from 'react-toasts';
import { SCHEDULE_SITUATION_TAG_NAME } from '../../class/ScheduleCardListItemComponent';
import CaptionComponent from '../../../components/common/CaptionComponent';

const styles = theme => ({
    root: {
        '& ul, ol': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    listBox: {
        border: '1px solid #c4c4c4',
        borderRadius: 10,
        boxShadow: '0 1px 1px 0 #BEBEBE',
        marginBottom: 20,
    },
    liveBox: {
        borderColor: '#00C880',
        borderTopWidth: 7,
    },
    FutureBox: {
        borderColor: '#a3a8af',
        borderTopWidth: 7,
    },
    listBoxContent: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        padding: '1rem 1.125rem',
        cursor: 'pointer',
        '& h5': {
            fontSize: '0.938rem',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            wordBreak: 'break-all',
        },
        '& h6': {
            fontSize: '0.938rem',
            lineHeight: 1.3,
        },
    },
    subText: {
        marginTop: 5,
        width: '100%',
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
    },
    listBoxTitleEnd: {
        textDecoration: 'line-through',
    },
    caption: {
        backgroundColor: 'transparent',
        color: '#fff',
        padding: '3px 6px 2px',
        fontSize: '0.75rem',
        fontFamily: 'Montserrat!important',
        marginLeft: 7,
        textTransform: 'uppercase',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            marginRight: 3,
        },
    },
    captionLive: {
        width: 30,
        margin: '0 0 5px 0',
        backgroundColor: '#FB4A59',
    },
    captionEnd: {
        width: 30,
        margin: '0 0 5px 0',
        backgroundColor: '#505050',
    },
    captionFuture: {
        width: 30,
        margin: '0 0 5px 0',
        backgroundColor: '#a3a8af',
    },
    listStyle: {
        margin: '8px 0!important',
        '& li': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.813rem',
            marginTop: 7,
            letterSpacing: '-0.2px',
            '&:first-child': {
                marginTop: 0,
            },
            '& svg': {
                marginRight: 4,
            },
        },
    },
    avatarList: {
        display: 'flex',
        '& li': {
            marginTop: 5,
            marginRight: '-6px',
            '&>div': {
                width: 30,
                height: 30,
                zIndex: 10,
                border: '1px solid #fff',
                overflow: 'hidden',
            },
            '&:last-child': {
                zIndex: 20,
            },
        },
    },
    avatar: {
        background: '#a3a8af',
        boxShadow: 'rgb(0 0 0 / 38%) 0px 1px 1px',
        '& svg': {
            width: 18,
            height: 18,
            '& path': {
                fill: '#fff',
            },
        },
    },
    avatarLastStyle: {
        width: 30,
        height: 30,
        backgroundColor: '#F1F1F1',
        border: '1px solid #fff',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
        fontSize: '0.688rem',
    },
    asideControl: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    iconButtonBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginBottom: 15,
        '& button': {
            padding: 0,
            marginBottom: 4,
        },
    },
    lightTooltip: {
        backgroundColor: '#FFFFF5',
        color: '#000',
        border: '1px solid #000',
        fontSize: '0.688rem',
        borderRadius: 0,
        marginLeft: 0,
        marginTop: 15,
    },
    btnOutlineStyle: {
        width: '100%',
        border: () => (theme.configs.MainBtnColor ? `1px solid ${theme.configs.MainBtnColor}` : '1px solid #0097FF'),
        color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        fontWeight: 600,
        padding: '6px 12px',
        borderRadius: 7,
        fontSize: '0.813rem',
        '&:hover': {
            fontWeight: 800,
            background: 'transparent',
        },
    },
    boxFooter: {
        backgroundColor: '#EDEDED',
        borderRadius: 10,
    },
    ftCount: {
        padding: '10px 60px',
        borderBottom: '1px solid #fff',
        display: 'flex',
        '& div': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.75rem',
            color: '#7F7F7F',
            marginRight: 7,
            '& svg': {
                width: 14,
                height: 14,
                opacity: 0.5,
                marginRight: 4,
            },
        },
    },
    iconColor: {
        '& .like-icon': {
            fill: '#0097ff',
        },
    },
    numberText: {
        fontSize: '0.938rem',
        color: '#000',
        marginLeft: 5,
        marginTop: 2,
    },
    ftAdded: {
        padding: '5px 60px',
        '@media (min-width: 319px) and (max-width: 374px)': {
            padding: '5px 50px',
        },
        '& button': {
            width: '50%',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: '0.813rem',
            color: '#000',
            '&:hover': {
                background: 'transparent',
                fontWeight: 800,
            },
            '& svg': {
                width: 20,
                height: 20,
                marginRight: 4,
                opacity: 0.5,
            },
            '&.Mui-disabled': {
                color: '#000',
            },
        },
    },
    emptyBox2: {
        flexDirection: 'column',
        height: 100,
        alignItems: 'center',
        justifyContent: 'center !important',
    },
    emptyBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 731px)',
        '& p': {
            color: '#a9adb4',
            marginTop: 14,
        },
    },
    iconStyle: {
        width: 44,
        height: 44,
        backgroundColor: '#e9e9e9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 40,
    },
    btnChartStyle: {
        width: 26,
        padding: '12px 0',
        justifyContent: 'flex-start',
    },
});

class MobileScheduleCardListItemComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '라이브',
            like: false,
            isLoading: false,
            hasMore: false,
        };
    }

    handleChangeLikeButton = (groupId, roomId, likeId) => {
        this.props.changeLikeState(groupId, roomId, likeId);
    };

    handleClickDetail = roomId => {
        this.props.handleClickDetail(roomId);
    };

    checkRoomState = (startDatetime, endDatetime) => {
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedStartDatetime = DATE_UTIL.convertUTCToTimeZone(startDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedEndDatetime = DATE_UTIL.convertUTCToTimeZone(endDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        if (dayjs(convertedEndDatetime).isBefore(currentDatetime)) {
            return roomState.Ended;
        } else if (
            dayjs(currentDatetime).isBetween(convertedStartDatetime, convertedEndDatetime) ||
            dayjs(currentDatetime).isSame(convertedStartDatetime) ||
            dayjs(currentDatetime).isSame(convertedEndDatetime)
        ) {
            return roomState.Live;
        } else {
            return roomState.Future;
        }
    };

    handleClickEnter = (event, url) => {
        event.stopPropagation();
        window.open(url);
    };

    handleClickStatistics = (e, room) => {
        e.preventDefault();
        e.stopPropagation();
        // window.open(`/class/${room.groupId}/rooms/${room.id}/statistics`);
        window.open(`/statistics/${room.id}`);
    };

    ellipsisText = text => (text.length > 147 ? text.substring(0, 147) + '...' : text);

    scheduleSituationTranslator = (situation, language) => {
        const result = SCHEDULE_SITUATION_TAG_NAME[language][situation];
        if (result) return result;
        return '';
    };

    render() {
        const { classes, roomListGroupByStartTime, loginUser, likeLoading, isMember, situation, isLeader } = this.props;
        const situationTranslateText = this.scheduleSituationTranslator(situation, 'KO');

        return (
            <div className={classes.root}>
                {roomListGroupByStartTime.length > 0 ? (
                    <>
                        {roomListGroupByStartTime.map((room, i) => {
                            const me = room.members.find(m => m.userId === this.props.authStore.loginUser.id);
                            const state = this.checkRoomState(room.startDatetime, room.endDatetime);
                            const linkViewOpen = this.props.roomStore.getTimelineLinkViewOpen(room.id);
                            return (
                                <Box
                                    key={i}
                                    className={clsx(
                                        classes.listBox,
                                        state === roomState.Live && classes.liveBox,
                                        state === roomState.Future && classes.FutureBox,
                                    )}
                                >
                                    <Box className={classes.listBoxContent} onClick={() => this.handleClickDetail(room.id)}>
                                        <Box>
                                            <CaptionComponent state={state} />
                                            <Box style={state === roomState.Ended ? { opacity: 0.5 } : {}}>
                                                <Typography variant="h5" className={clsx(state === roomState.Ended && classes.listBoxTitleEnd)}>
                                                    {room.name}
                                                </Typography>
                                                <Typography className={classes.subText}>{room.comment}</Typography>
                                                <ul className={classes.listStyle}>
                                                    <li>
                                                        <SmileyFillIcon />
                                                        {/*서비스 기획팀 |*/}
                                                        {room.userName}
                                                    </li>
                                                    <li>
                                                        <AlarmFillIcon />
                                                        {DATE_UTIL.convertUTCToTimeZone(
                                                            room.startDatetime,
                                                            DATE_UTIL.FORMAT.FULL_DATE_AMPM_DOT_FORMAT,
                                                        )}{' '}
                                                        ~
                                                        {DATE_UTIL.convertUTCToTimeZone(room.endDatetime, DATE_UTIL.FORMAT.FULL_DATE_AMPM_DOT_FORMAT)}
                                                    </li>
                                                </ul>
                                                <ul className={classes.avatarList}>
                                                    {room.members && room.members.length > 0 && (
                                                        <React.Fragment>
                                                            {room.members.slice(0, 5).map((user, i) => (
                                                                <li key={i}>
                                                                    <Avatar className={classes.avatar} src={user.image}>
                                                                        <AsideUserIcon />
                                                                    </Avatar>
                                                                </li>
                                                            ))}
                                                            {room.members.length > 5 && (
                                                                <li>
                                                                    <div className={classes.avatarLastStyle}>+{room.members.length - 5}</div>
                                                                </li>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </ul>
                                            </Box>
                                        </Box>

                                        <Box className={classes.asideControl}>
                                            {state !== roomState.Ended && (
                                                <Box className={classes.iconButtonBox}>
                                                    {/*<Tooltip title="링크" placement="right" classes={{ tooltip: classes.lightTooltip }}>*/}
                                                    {room.guests && room.guests.length > 0 && (
                                                        <LinkView
                                                            linkViewOpen={linkViewOpen}
                                                            sendMailDatetime={room.sendMailDatetime}
                                                            guests={room.guests}
                                                            handleClickButton={() => this.props.roomStore.openTimelineLinkView(room.id)}
                                                            handleClose={this.props.roomStore.closeAllTimelineLinkView}
                                                        />
                                                    )}
                                                    {/*    <IconButton>*/}
                                                    {/*        <LinkFillIcon />*/}
                                                    {/*    </IconButton>*/}
                                                    {/*</Tooltip>*/}
                                                    {/*<Tooltip title="학습결과" placement="right" classes={{ tooltip: classes.lightTooltip }}>*/}
                                                    {/*    <IconButton>*/}
                                                    {/*        <ChartBarFillIcon />*/}
                                                    {/*    </IconButton>*/}
                                                    {/*</Tooltip>*/}
                                                    {/*<Tooltip title="학생관리" placement="right" classes={{ tooltip: classes.lightTooltip }}>*/}
                                                    {/*    <IconButton>*/}
                                                    {/*        <StudentFillIcon />*/}
                                                    {/*    </IconButton>*/}
                                                    {/*</Tooltip>*/}
                                                </Box>
                                            )}
                                            {state === roomState.Ended && isLeader && room.statistics && (
                                                <IconButton
                                                    disableRipple
                                                    className={classes.btnChartStyle}
                                                    onClick={e => this.handleClickStatistics(e, room)}
                                                >
                                                    <ChartBarFillIcon />
                                                </IconButton>
                                            )}
                                            {state === roomState.Live && me && (
                                                <Button
                                                    className={classes.btnOutlineStyle}
                                                    disableRipple
                                                    onClick={e => this.handleClickEnter(e, me.entranceUrl)}
                                                >
                                                    참석하기
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box className={classes.boxFooter}>
                                        <Box className={classes.ftCount}>
                                            <Box>
                                                <HandsClappingIcon
                                                    className={
                                                        room.likeList && room.likeList.find(user => user.userId === loginUser)
                                                            ? classes.iconColor
                                                            : null
                                                    }
                                                />{' '}
                                                {room.likeList && room.likeList.length}{' '}
                                            </Box>
                                            <Box>
                                                <ChatCircleDotsIcon /> {room.commentCount}{' '}
                                            </Box>
                                        </Box>
                                        <Box className={classes.ftAdded}>
                                            <Button
                                                disableRipple
                                                disabled={likeLoading === loadingSate.PENDING}
                                                onClick={() =>
                                                    this.handleChangeLikeButton(
                                                        room.groupId,
                                                        room.id,
                                                        room.likeList.find(user => user.userId === loginUser)
                                                            ? room.likeList.find(user => user.userId === loginUser).likeId
                                                            : 0,
                                                    )
                                                }
                                            >
                                                <HandsClappingIcon
                                                    className={
                                                        room.likeList && room.likeList.find(user => user.userId === loginUser)
                                                            ? classes.iconColor
                                                            : null
                                                    }
                                                />{' '}
                                                좋아요
                                            </Button>
                                            <Button
                                                disableRipple
                                                onClick={
                                                    isMember
                                                        ? () => this.handleClickDetail(room.id)
                                                        : () => ToastsStore.error('클래스에 가입하여야 댓글을 사용할 수 있습니다.', 3000)
                                                }
                                            >
                                                <ChatCircleDotsIcon /> 댓글 달기
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        })}
                    </>
                ) : (
                    <Box className={clsx(classes.listBox, classes.FutureBox)}>
                        <Box className={clsx(classes.listBoxContent, classes.emptyBox2)}>
                            <NoFilesIcon />
                            <Typography style={{ marginTop: 10, fontSize: '0.813rem' }}>{situationTranslateText} 수업이 없습니다.</Typography>
                        </Box>
                    </Box>
                )}
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('authStore', 'roomStore', 'commentStore', 'avatarStore')(observer(MobileScheduleCardListItemComponent))),
);
