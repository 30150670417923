import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiDialogTitle-root': {
            padding: '10px 20px',
        },
        '& .MuiDialog-paper': {
            position: 'relative',
            borderRadius: 12,
            padding: '5px 0',
            backgroundColor: '#fdf1f0',
        },
        '& .MuiTypography-h6': {
            fontFamily: 'NanumSquareRoundOTF',
            fontWeight: 600,
        },
        '& .MuiDialogContentText-root': {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.125rem',
            color: '#000',
            letterSpacing: '-0.25px',
        },
    },
    closeBtn: {
        position: 'absolute',
        top: 5,
        right: 7,
        '&:hover': {
            background: 'transparent',
        },
    },
    link: {
        color: '#0097ff',
        textDecoration: 'underline',
        marginLeft: 4,
    },
});

class CoverSelectDialogComponent extends Component {
    render() {
        const { classes, dialogOpen, handleClose } = this.props;

        return (
            <div className={classes.root}>
                <Dialog open={dialogOpen} className={classes.dialogBox}>
                    <DialogTitle>커버 직접 선택시</DialogTitle>
                    <label htmlFor={'background-button-file'}>
                        <IconButton disableRipple onClick={handleClose} className={classes.closeBtn}>
                            <DialogCloseIcon />
                        </IconButton>
                    </label>
                    <DialogContent>
                        <DialogContentText>커버 이미지는 최소 650x267 픽셀(px) 이상이어야 합니다.</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(CoverSelectDialogComponent);
