import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    IconButton,
    OutlinedInput,
    TextareaAutosize,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import clsx from 'clsx';
import { ReactComponent as UnCheckedIcon } from '../../common/images/UnCheckedIcon.svg';
import { ReactComponent as CheckedIcon } from '../../common/images/CheckedIcon.svg';
import { ReactComponent as PaperclipIcon } from '../../common/images/PaperclipIcon.svg';
import { inject, observer } from 'mobx-react';
import { PATH_UTIL } from '../../common/util/path.util';
import { TagType } from '../../stores/ClassStore';
import { ToastsStore } from 'react-toasts';
import { UTIL } from '../../common/util/common.util';
import YesOrNoDialog from '../../components/common/YesOrNoDialog';
import ConfirmDialog from '../../components/common/ConfirmDialog';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            padding: 24,
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 600px)': {
                width: 790,
            },
        },
        '& .MuiDialog-paper': {
            '@media all and (max-width: 600px)': {
                margin: 20,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
        '& .MuiSvgIcon-root': {
            '@media all and (max-width: 600px)': {
                width: 20,
                height: 20,
            },
        },
    },
    titleBox: {
        marginBottom: 30,
        '@media all and (max-width: 600px)': {
            marginBottom: 10,
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        marginLeft: 7,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    formControlBox: {
        margin: '20px 0',
        '@media all and (max-width: 600px)': {
            margin: '10px 0',
        },
    },
    buttonSelect: {
        width: 330,
        borderBottom: '1px solid #d9dbde',
        fontSize: '1rem',
        color: '#404040',
        '& span': {
            justifyContent: 'space-between',
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    popoverBox: {
        '& .MuiPopover-paper': {
            width: 330,
            boxShadow: '0 5px 7px 0 rgba(0, 0, 0, 0.45)',
            borderRadius: 4,
        },
        '& li:hover': {
            background: '#e8e8e8',
        },
        '& .MuiTypography-root': {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '0.875rem',
            color: '#333',
        },
    },
    textField: {
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #d9dbde',
            },
        '& .MuiOutlinedInput-root': {
            borderRadius: 7,
            '&:hover': {
                borderColor: '#d9dbde',
            },
        },
        '& .MuiListItemIcon-root': {
            minWidth: 25,
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px 10px',
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#a3a8af',
                opacity: 1,
                fontWeight: 300,
            },
        },
        '@media all and (max-width: 600px)': {
            '& .MuiOutlinedInput-input': {
                padding: '10px 10px',
                fontSize: '0.813rem',
            },
        },
    },
    textFieldMargin: {
        margin: '0 0 0 20px',
        '@media all and (max-width: 600px)': {
            margin: '0 0 0 5px',
        },
    },
    textareaStyle: {
        margin: '20px 0',
        border: '1px solid #d9dbde',
        borderRadius: 7,
        padding: '10px 10px',
        overflowY: 'auto',
        resize: 'none',
        boxSizing: 'border-box',
        fontSize: '1rem',
        color: '#333',
        background: 'transparent',
        '&::placeholder': {
            color: '#a3a8af',
            opacity: 1,
            fontWeight: 300,
        },
        '&:focus': {
            outline: 'none',
        },
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#bebebe',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '3px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    checkedBox: {
        margin: 0,
        '& .MuiIconButton-root': {
            padding: 0,
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '1rem',
            color: '#333',
            marginLeft: 10,
            fontWeight: 600,
            '@media all and (max-width: 600px)': {
                fontSize: '0.813rem',
            },
        },
        '& .Mui-disabled': {
            opacity: 0.3,
        },
        '& .Mui-checked': {
            color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
    importantBox: {
        margin: '12px 0 5px',
    },
    importantText: {
        fontSize: '0.813rem',
        color: '#848484',
    },
    controlBox: {
        borderRadius: 5,
        background: '#f8f8f8',
        padding: '10px 20px',
        marginTop: 21,
    },
    fileNameText: {
        fontSize: '0.875rem',
        color: '#000',
        marginLeft: 8,
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    fileSizeText: {
        fontSize: '0.75rem',
        color: '#848484',
        marginLeft: 8,
        '@media all and (max-width: 600px)': {
            fontSize: '0.688rem',
        },
    },
    input: {
        display: 'none',
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    iconColor: {
        '& .image-square, .paperclip': {
            fill: 'rgba(0, 188, 106, 0.6)',
        },
    },
    buttonStyle: {
        width: 442,
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),

        color: '#fff',
        fontSize: '1.125rem',
        padding: '12px 0',
        borderRadius: 7,
        marginTop: 25,
        '&:hover': {
            fontWeight: 800,
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            marginTop: 20,
            fontSize: '0.75rem',
            padding: '6px 0',
            borderRadius: 4,
        },
    },
    fileImgBox: {
        width: 200,
        height: 140,
        borderRadius: 5,
        overflow: 'hidden',
        '& img': {
            width: '100%',
            maxWidth: '100%',
            objectFit: 'cover',
        },
    },
    scrollBox: {
        maxHeight: 'calc(100vh - 150px )',
        overflowY: 'auto',
        paddingRight: 24,
        '&::-webkit-scrollbar': {
            width: '15px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#bebebe',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '5px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
        '@media all and (max-width: 600px)': {
            paddingRight: 0,
        },
    },
});

const LightTooltip = withStyles(() => ({
    tooltip: {
        padding: '10px 10px',
        border: '0.3px solid #000000',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.875rem',
        borderRadius: 5,
        marginTop: 5,
    },
}))(Tooltip);

class WritingDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false,
            imgFile: '',
            file: false,
            fileName: '',
            fileSize: '',
            previewURL: '',

            anchorEl: null,

            anything: false,
            schedule: false,
            news: false,
            tip: false,
            advertisement: false,
        };
    }

    handleImageFileChange = event => {
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        const fileSizeMega = file.size / 1024 / 1024;
        if (fileSizeMega > 500) {
            this.props.postStore.setOverSize(true);
            ToastsStore.warning('500MB 이하 파일만 사용가능합니다.', 3000);
        } else {
            reader.onloadend = () => {
                this.setState({
                    // imgFile: file,
                    previewURL: reader.result,
                });
                this.props.postStore.setOverSize(false);
                this.props.postStore.changePostAttachments(file);
            };
            reader.readAsDataURL(file);
        }
    };

    handleFileChange = event => {
        // this.setState({ file: true });
        event.preventDefault();
        let file = event.target.files[0];
        const fileSizeMega = file.size / 1024 / 1024;
        if (fileSizeMega > 500) {
            this.props.postStore.setOverSize(true);
            ToastsStore.warning('500MB 이하 파일만 사용가능합니다.', 3000);
        } else {
            this.props.postStore.setOverSize(false);
            if (this.props.isModify) {
                if (this.props.postStore.post.postFiles && this.props.postStore.post.postFiles.length) {
                    ToastsStore.warning('일반파일은 1개만 등록 가능합니다. 파일 제거후 다시 시도해주세요.', 3000);
                } else {
                    this.props.postStore.changePostAttachments(file);
                    this.handleModifyPostFile();
                }
            } else {
                this.props.postStore.changePostAttachments(file);
            }
        }
        event.target.value = '';
    };

    handleModifyPostFile = () => {
        this.props.postStore.changePostFile();
    };

    handleRemoveUploadFile = () => {
        this.props.postStore.initPostAttachments();
    };

    handleRemovePostFile = fileIndex => {
        const { postAttachments } = this.props.postStore;
        if (postAttachments.file) {
            this.props.postStore.initPostAttachments();
        }

        this.props.postStore.removePostFile(fileIndex);
    };

    handleClickSelect = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorElMike: null,
        });
    };

    handleClickAnything = () => {
        this.setState({
            anything: true,
            schedule: false,
            news: false,
            tip: false,
            advertisement: false,
            anchorEl: null,
        });
    };

    handleClickSchedule = () => {
        this.setState({
            anything: false,
            schedule: true,
            news: false,
            tip: false,
            advertisement: false,
            anchorEl: null,
        });
    };

    handleClickNews = () => {
        this.setState({
            anything: false,
            schedule: false,
            news: true,
            tip: false,
            advertisement: false,
            anchorEl: null,
        });
    };

    handleClickTip = () => {
        this.setState({
            anything: false,
            schedule: false,
            news: false,
            tip: true,
            advertisement: false,
            anchorEl: null,
        });
    };

    handleClickAdvertisement = () => {
        this.setState({
            anything: false,
            schedule: false,
            news: false,
            tip: false,
            file: false,
            advertisement: true,
            anchorEl: null,
            closeAlertDialog: false,
        });
    };

    handleDialogClose = () => {
        const { postAttachments } = this.props.postStore;
        if (postAttachments.file) {
            this.props.postStore.initPostAttachments();
        }
        this.props.handleClose();
        this.setState({ file: false });
    };

    handlePostSave = () => {
        const pathName = window.location.pathname;
        const path = PATH_UTIL.getClassId(pathName);
        !this.props.isModify
            ? this.props.postStore.createPost(path, this.props.authStore.loginUser.id, {
                  dialogClose: () => this.props.handleClose(),
                  getTagList: groupId => this.props.classStore.getTagList(groupId, TagType.POST),
              })
            : this.props.postStore.modifyPost(path, this.props.authStore.loginUser.id, {
                  dialogClose: () => this.props.handleClose(),
                  getTagList: groupId => this.props.classStore.getTagList(groupId, TagType.POST),
              });
    };

    writingDialogClose = () => {
        const { post } = this.props.postStore;
        if (post.title !== '' || post.body !== '' || post.tagList.map(tag => tag.name).join('') !== '') {
            this.setState({ closeAlertDialog: true });
        } else {
            this.props.handleClose();
        }
    };

    render() {
        const { classes, postStore, isModify, postNoticeCount } = this.props;
        const { post, isPostCreateValidation, postAttachments, openConfirmDialog, errMsg, isWritingPostState } = postStore;
        const { anchorEl } = this.state;
        const open = this.props.writingDialogOpen;

        let image_preview = null;
        if (this.state.imgFile !== '') {
            image_preview = <img src={this.state.previewURL} align="image" alt={'imgFile'} />;
        }

        return (
            <div className={classes.root}>
                <Dialog open={this.props.writingDialogOpen} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" className={classes.titleBox}>
                        <Typography className={classes.titleText}>{isModify ? '글쓰기 수정' : '글쓰기'}</Typography>
                        <IconButton className={classes.iconButton} onClick={this.writingDialogClose} disableRipple>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>
                    <Box className={classes.scrollBox}>
                        {/*<Button*/}
                        {/*    onClick={this.handleClickSelect}*/}
                        {/*    aria-owns={open ? 'simple-popper' : undefined}*/}
                        {/*    className={classes.buttonSelect}*/}
                        {/*    endIcon={<ArrowDownIcon />}*/}
                        {/*    disableRipple*/}
                        {/*>*/}
                        {/*    {this.state.anything === true*/}
                        {/*        ? '이것저것'*/}
                        {/*        : this.state.schedule === true*/}
                        {/*        ? '일정'*/}
                        {/*        : this.state.news === true*/}
                        {/*        ? '기사/뉴스'*/}
                        {/*        : this.state.tip === true*/}
                        {/*        ? '정보/팁'*/}
                        {/*        : this.state.advertisement === true*/}
                        {/*        ? '홍보/광고'*/}
                        {/*        : '토픽을 선택해주세요.'}*/}
                        {/*</Button>*/}
                        {/*<Popover*/}
                        {/*    open={open}*/}
                        {/*    anchorEl={this.state.anchorEl}*/}
                        {/*    onClose={this.handleClose}*/}
                        {/*    anchorOrigin={{*/}
                        {/*        vertical: 'bottom',*/}
                        {/*        horizontal: 'center',*/}
                        {/*    }}*/}
                        {/*    transformOrigin={{*/}
                        {/*        vertical: 'top',*/}
                        {/*        horizontal: 'center',*/}
                        {/*    }}*/}
                        {/*    className={classes.popoverBox}*/}
                        {/*>*/}
                        {/*    <MenuList id="video-devices-button-menu">*/}
                        {/*        <MenuItem onClick={this.handleClickAnything}>*/}
                        {/*            {this.state.anything === true ? (*/}
                        {/*                <ListItemIcon>*/}
                        {/*                    <SelectCheckIcon />*/}
                        {/*                </ListItemIcon>*/}
                        {/*            ) : (*/}
                        {/*                <ListItemIcon />*/}
                        {/*            )}*/}
                        {/*            <ListItemText primary="이것저것" />*/}
                        {/*        </MenuItem>*/}
                        {/*        <MenuItem onClick={this.handleClickSchedule}>*/}
                        {/*            {this.state.schedule === true ? (*/}
                        {/*                <ListItemIcon>*/}
                        {/*                    <SelectCheckIcon />*/}
                        {/*                </ListItemIcon>*/}
                        {/*            ) : (*/}
                        {/*                <ListItemIcon />*/}
                        {/*            )}*/}
                        {/*            <ListItemText primary="일정" />*/}
                        {/*        </MenuItem>*/}
                        {/*        <MenuItem onClick={this.handleClickNews}>*/}
                        {/*            {this.state.news === true ? (*/}
                        {/*                <ListItemIcon>*/}
                        {/*                    <SelectCheckIcon />*/}
                        {/*                </ListItemIcon>*/}
                        {/*            ) : (*/}
                        {/*                <ListItemIcon />*/}
                        {/*            )}*/}
                        {/*            <ListItemText primary="기사/뉴스" />*/}
                        {/*        </MenuItem>*/}
                        {/*        <MenuItem onClick={this.handleClickTip}>*/}
                        {/*            {this.state.tip === true ? (*/}
                        {/*                <ListItemIcon>*/}
                        {/*                    <SelectCheckIcon />*/}
                        {/*                </ListItemIcon>*/}
                        {/*            ) : (*/}
                        {/*                <ListItemIcon />*/}
                        {/*            )}*/}
                        {/*            <ListItemText primary="정보/팁" />*/}
                        {/*        </MenuItem>*/}
                        {/*        <MenuItem onClick={this.handleClickAdvertisement}>*/}
                        {/*            {this.state.advertisement === true ? (*/}
                        {/*                <ListItemIcon>*/}
                        {/*                    <SelectCheckIcon />*/}
                        {/*                </ListItemIcon>*/}
                        {/*            ) : (*/}
                        {/*                <ListItemIcon />*/}
                        {/*            )}*/}
                        {/*            <ListItemText primary="홍보/광고" />*/}
                        {/*        </MenuItem>*/}
                        {/*    </MenuList>*/}
                        {/*</Popover>*/}
                        <Box display="flex" flexDirection="column" className={classes.formControlBox}>
                            <FormControl className={classes.textField} variant="outlined">
                                <OutlinedInput
                                    value={post.title}
                                    inputProps={{ 'aria-label': 'title input box', maxLength: 100 }}
                                    id="outlined-title"
                                    labelWidth={0}
                                    placeholder="제목 (최대 100자 이내)"
                                    onChange={e => this.props.postStore.changePostTitle(e)}
                                />
                            </FormControl>

                            <TextareaAutosize
                                value={post.body}
                                name="contents"
                                minRows={5}
                                maxRows={5}
                                maxLength={5000}
                                aria-label="content input box"
                                className={classes.textareaStyle}
                                placeholder="내용 (최대 5000자 이내) "
                                onChange={e => this.props.postStore.changePostBody(e)}
                            />

                            {/* 태그 */}
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                {Array.from({ length: 3 }, (_, i) => (
                                    <FormControl className={clsx(classes.textField, i !== 0 && classes.textFieldMargin)} variant="outlined" key={i}>
                                        <OutlinedInput
                                            value={i < post.tagList.length && post.tagList[i] ? post.tagList[i].name : ''}
                                            inputProps={{ 'aria-label': 'room tag input box', maxLength: 25 }}
                                            id={`${i}`}
                                            labelWidth={0}
                                            placeholder="태그(최대 25자)"
                                            onChange={e => this.props.postStore.changePostTag(e)}
                                        />
                                    </FormControl>
                                ))}
                            </Box>
                        </Box>
                        {this.props.classStore.groupUserAuthority.PostNotice && (
                            <>
                                {postNoticeCount <= 4 || isModify ? (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={postNoticeCount > 4}
                                                // icon={<UnCheckedIcon />}
                                                // checkedIcon={<CheckedIcon />}
                                                value="check"
                                                checked={postStore.post.notice}
                                                onChange={e => this.props.postStore.changePostNotice(e.target.checked)}
                                            />
                                        }
                                        label="공지로 등록"
                                        className={classes.checkedBox}
                                    />
                                ) : (
                                    <LightTooltip
                                        title={'5개의 공지가 등록되어있습니다. 현재 글을 게시 후, 기존 공지는 해제하고 새로운 공지로 체크해주세요.'}
                                        placement="bottom-start"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={postNoticeCount > 4}
                                                    icon={<UnCheckedIcon />}
                                                    checkedIcon={<CheckedIcon />}
                                                    value="check"
                                                    checked={postStore.post.notice}
                                                    onChange={e => this.props.postStore.changePostNotice(e.target.checked)}
                                                />
                                            }
                                            label="공지로 등록"
                                            className={classes.checkedBox}
                                        />
                                    </LightTooltip>
                                )}
                                {(postNoticeCount <= 4 || isModify) && (
                                    <Box className={classes.importantBox}>
                                        <Typography className={classes.importantText}>*목록 최상단에 고정됩니다.</Typography>
                                        <Typography className={classes.importantText}>*공지는 5개까지 지정할 수 있습니다.</Typography>
                                    </Box>
                                )}
                            </>
                        )}

                        <Box className={classes.controlBox}>
                            {/*<input*/}
                            {/*    type="file"*/}
                            {/*    accept="image/jpg,impge/png,image/jpeg,image/gif"*/}
                            {/*    name="image"*/}
                            {/*    id="image-button-file"*/}
                            {/*    onChange={this.handleImageFileChange}*/}
                            {/*    className={classes.input}*/}
                            {/*/>*/}
                            <input
                                type="file"
                                accept="*.*"
                                name="file"
                                id="post-button-file"
                                onChange={this.handleFileChange}
                                className={classes.input}
                            />
                            {/*<label htmlFor="image-button-file">*/}
                            {/*    <IconButton className={classes.iconButton} disableRipple component="span" style={{ marginRight: 20 }}>*/}
                            {/*        {this.state.imgFile === '' ? <ImageSquareIcon /> : <ImageSquareIcon className={classes.iconColor} />}*/}
                            {/*    </IconButton>*/}
                            {/*</label>*/}
                            <label htmlFor="post-button-file">
                                <IconButton className={classes.iconButton} disableRipple component="span">
                                    {postAttachments.file ? <PaperclipIcon className={classes.iconColor} /> : <PaperclipIcon />}
                                </IconButton>
                            </label>
                        </Box>
                        {/* 이미지일 경우 */}
                        {this.state.imgFile === '' ? null : (
                            <Box display="flex" justifyContent="center" className={classes.controlBox}>
                                <Box className={classes.fileImgBox}>{image_preview}</Box>
                            </Box>
                        )}

                        {/*파일일 경우 */}
                        {!isModify ? (
                            postAttachments.file !== '' ? (
                                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.controlBox}>
                                    <Box display="flex" alignItems="flex-start">
                                        <PaperclipIcon />
                                        <Box>
                                            <Typography className={classes.fileNameText}>{postAttachments.file.name}</Typography>
                                            <Typography className={classes.fileSizeText}>{UTIL.formatBytes(postAttachments.file.size)}</Typography>
                                        </Box>
                                    </Box>
                                    <IconButton className={classes.iconButton} disableRipple onClick={this.handleRemoveUploadFile}>
                                        <DialogCloseIcon />
                                    </IconButton>
                                </Box>
                            ) : null
                        ) : post.postFiles && post.postFiles.length ? (
                            post.postFiles.map((file, index) => {
                                return (
                                    <Box key={index} display="flex" justifyContent="space-between" alignItems="center" className={classes.controlBox}>
                                        <Box display="flex" alignItems="flex-start">
                                            <PaperclipIcon />
                                            <Box>
                                                <Typography className={classes.fileNameText}>{file.name}</Typography>
                                                <Typography className={classes.fileSizeText}>{UTIL.formatBytes(file.size)}</Typography>
                                            </Box>
                                        </Box>
                                        <IconButton className={classes.iconButton} disableRipple onClick={() => this.handleRemovePostFile(index)}>
                                            <DialogCloseIcon />
                                        </IconButton>
                                    </Box>
                                );
                            })
                        ) : null}

                        <Box display="flex" justifyContent="center">
                            <Button
                                className={classes.buttonStyle}
                                disableRipple
                                disabled={!isPostCreateValidation || isWritingPostState}
                                onClick={this.handlePostSave}
                            >
                                게시
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
                <ConfirmDialog
                    open={openConfirmDialog}
                    title={'알림'}
                    msg={errMsg}
                    changeDialogOpen={() => this.props.postStore.closeConfirmDialog()}
                />
                <YesOrNoDialog
                    open={this.state.closeAlertDialog || false}
                    title={isModify ? '글쓰기 수정' : '글쓰기'}
                    msg={'작성을 취소하시겠습니까?'}
                    changeDialogOpen={() => this.setState({ closeAlertDialog: false })}
                    submit={() => this.handleDialogClose()}
                />
            </div>
        );
    }
}

export default withStyles(styles)(inject('postStore', 'authStore', 'classStore')(observer(WritingDialogComponent)));
