import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Info2 } from '../common/images/Info2.svg';
import { useLocation } from 'react-router-dom';
import LicenseKeySearchButton from './LicenseKeySearchButton';

const useStyles = makeStyles(theme => ({
    infoBox: props => ({
        width: '100%',
        // height: 115,
        height: '100%',
        borderRadius: 5,
        boxSizing: 'border-box',
        padding: '14px 17px',
        background: 'rgba(242, 245, 248, 0.5)',
        marginTop: props?.marginTop,
        '& p': {
            fontSize: '0.875rem',
            color: '#333',
            letterSpacing: '-0.2px',
            lineHeight: 1.4,
            marginBottom: 8,
            '& span': {
                fontWeight: 'bold',
            },
            '& a': {
                color: '#0097ff',
            },
            // '&:last-child': {
            //     marginTop: 8,
            // },
        },
        '& button': {
            padding: 0,
            background: '#0097ff',
            width: 120,
            height: 28,
            borderRadius: 4,
            fontSize: '0.875rem',
            color: '#fff',
            fontWeight: 'bold',
            letterSpacing: '-0.2px',
            '&:hover': {
                background: '#0097ff',
            },
        },
        '& svg': {
            marginRight: 4,
        },
    }),
}));

const getInfoText = ({ pathname = '/signin' }) => {
    switch (pathname) {
        case '/signin':
            return (
                <Typography>
                    제휴 맺은 학교의 교사는 로그인 이후
                    <br />
                    <span>서비스 라이선 키 등록/인증</span>이 한번 필요합니다.
                </Typography>
            );
        case '/signup':
            return (
                <Typography>
                    제휴 맺은 학교의 교사는
                    <span>서비스 라이선스 키</span>를 선택해주세요.
                </Typography>
            );
        case '/profilesettings':
            return (
                <Typography>
                    라이선스 키는 등록 이후 <span>1년간 유효 합니다.</span>
                    <br />
                    만료되기 전 서비스 담당자에게{' '}
                    <a href="mailto:daewook86@neolab.net" target="_blank" rel={'noopener noreferrer'}>
                        메일
                    </a>
                    로 문의 및 요청해 주세요.
                </Typography>
            );
        default:
            return (
                <Typography>
                    제휴 맺은 학교의 교사는 로그인 이후
                    <br />
                    <span>서비스 라이선 키 등록/인증</span>이 한번 필요합니다.
                </Typography>
            );
    }
};

const LicenseKeyInfoBox = ({ marginTop = 30 }) => {
    const classes = useStyles({ marginTop });
    const location = useLocation();
    const isProfileSettingsPath = location?.pathname === '/profilesettings';
    const InfoText = getInfoText({ pathname: location?.pathname });

    return (
        <Box className={classes.infoBox}>
            <Box display="flex">
                <Info2 />
                <Box>
                    {InfoText}

                    {location?.pathname === '/signin' && (
                        <Typography>
                            {isProfileSettingsPath ? '만료되기 전 서비스 담당자에게 ' : `일반 사용자는 서비스 담당자에게 `}
                            <a target="_blank" href="mailto:daewook86@neolab.net">
                                메일
                            </a>
                            로 문의 및<br />
                            요청해주세요.
                        </Typography>
                    )}
                </Box>
            </Box>
            {location?.pathname === '/signup' && <LicenseKeySearchButton />}
        </Box>
    );
};

export default LicenseKeyInfoBox;
