import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 500,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 30,
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
    },
    textStyle: {},
    buttonStyle: {
        width: 200,
        margin: '26px auto 0',
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        padding: '12px 0',
        borderRadius: 7,
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
});

class PrintPreviewDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Dialog open={this.props.printPreviewOpen} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.titleBox}>
                        <Typography className={classes.titleText}>수학 혼합 계산 학습지</Typography>
                        <IconButton className={classes.iconButton} onClick={this.props.handleClosePrintPreviewDialog} disableRipple>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        <Typography variant="body1" className={classes.textStyle}>
                            지난 4일 정부는 '6인·9시' 거리두기를 2주 연장한다고 발표하면서 "확진자가 증가해도 위중증·치명률이 안정적으로 유지되고
                            의료체계 여력이 충분하면, 방역 규제를 단계적으로 해제해 일상회복을 다시 시도할 것"이라는 방침을 밝혔다.
                        </Typography>
                    </Box>
                    <Button className={classes.buttonStyle} disableRipple>
                        프린트
                    </Button>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(PrintPreviewDialogComponent);
