import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import { ReactComponent as MagnifyingGlassMinus } from '../../../common/images/MagnifyingGlassMinus.svg';
import { withRouter } from '../../../components/WithRouter';
import { USER_TYPE } from '../../../stores/UserStore';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 383px)',
    },
    iconBox: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        background: '#e9e9e9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            width: 20,
            height: 20,
        },
        '@media all and (min-width: 601px)': {
            width: 44,
            height: 44,
            '& svg': {
                width: 28,
                height: 28,
            },
        },
    },
    textStyle: {
        fontSize: '1rem',
        color: '#a9adb4',
        margin: '10px 0 20px',
        '@media all and (min-width: 601px)': {
            fontSize: '1.063rem',
            margin: '14px 0 30px',
        },
    },
    buttonStyle: {
        width: '50%',
        height: 40,
        borderRadius: 8,
        background: '#0097ff',
        color: '#fff',
        fontSize: '0.813rem',
        fontWeight: 600,
        '&:hover': {
            background: '#0097ff',
        },
        '@media all and (min-width: 601px)': {
            width: 342,
            height: 48,
            fontSize: '1.125rem',
        },
    },
});

class MobileSearchCodingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleClickCreateClass = () => {
        this.props.navigate('/mobileRoomCreate');
    };

    render() {
        const { classes, loginUser } = this.props;

        return (
            <div className={classes.root}>
                <Box className={classes.iconBox}>
                    <MagnifyingGlassMinus />
                </Box>

                <Typography className={classes.textStyle}>검색 결과가 없습니다.</Typography>
                {loginUser.type !== USER_TYPE.Guest && (
                    <Button className={classes.buttonStyle} disableRipple onClick={this.handleClickCreateClass}>
                        클래스 만들기
                    </Button>
                )}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(MobileSearchCodingComponent));
