import { makeAutoObservable } from 'mobx';

export default class SearchStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }
    searchKeyword = '';
    currentPage = 1;
    subCurrentPage = 1;

    initSearchKeyword = () => {
        this.searchKeyword = '';
    };

    changeSearchKeyword = keyword => {
        this.searchKeyword = keyword;
    };

    initCurrentPage = () => (this.currentPage = 1);

    initSubCurrentPage = () => (this.subCurrentPage = 1);

    changeCurrentPage = () => (this.currentPage = this.currentPage + 1);

    changeSubCurrentPage = () => (this.subCurrentPage = this.subCurrentPage + 1);
}
