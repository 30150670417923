import React from 'react';
import { ReactComponent as StudyLiveLogo } from '../../common/images/StudyLiveLogo.svg';
import { Box, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    loadingComp: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

function LoadingComponent(props) {
    const classes = useStyles();
    return (
        <div className={classes.loadingComp}>
            <StudyLiveLogo />
            <LinearProgress />
        </div>
    );
}

export default LoadingComponent;
