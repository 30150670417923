import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Badge, Box, Button, CircularProgress, FormControl, InputBase, MenuItem, Select, Typography } from '@material-ui/core';
import { ReactComponent as ButtonPlusIcon } from '../../../common/images/ButtonPlusIcon.svg';
import { ReactComponent as NewPostIcon } from '../../../common/images/NewPostIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../../common/images/ArrowDownIcon.svg';
import { ReactComponent as AsideSettingIcon } from '../../../common/images/AsideSettingIcon.svg';
import RoomTestImg3 from '../../../common/images/RoomTestImg3.png';
import { withRouter } from '../../../components/WithRouter';
import HiddenGroupManagementDialogComponent from '../../dialog/HiddenGroupManagementDialogComponent';

import { inject, observer } from 'mobx-react';
import { ReactComponent as ArrowDownTinyIcon } from '../../../common/images/ArrowDownTinyIcon.svg';
import { ClassSortType, GroupJoinRequestPath, LoadingState } from '../../../stores/ClassStore';
import { Skeleton } from '@material-ui/lab';
import { ClassMainPath } from '../../../common/ClassMainPath';
import { SearchCategory, SearchScope } from '../../../common/SearchItems';
import { USER_TYPE } from '../../../stores/UserStore';
import CoachMarkForm from '../../../components/common/CoachMarkForm';

const styles = _theme => ({
    root: {
        background: '#f3f3f3',
    },
    titleText: {
        '@media all and (min-width: 601px)': {
            fontSize: '1.125rem',
        },
        fontSize: '1rem',
        color: '#000',
    },
    menuText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.75rem',
        },
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.625rem',
        color: '#0d0d0d',
        padding: '10px',
        minHeight: 30,
        '&:hover': {
            background: '#d3d7db',
        },
    },
    roomCreate: {
        '@media all and (min-width: 601px)': {
            height: 300,
        },
        width: '49%',
        height: 170,
        position: 'relative',
        borderRadius: 10,
        marginBottom: 10,
        boxShadow: ' 0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        borderRight: 10,
    },
    landscapeRoomCreate: {
        width: 'calc((100% / 3) - 7px)',
        marginRight: 10,
        '&:nth-child(3n+0)': {
            marginRight: 0,
        },
    },
    roomCreateButton: {
        width: '100%',
        height: '100%',
        background: '#fff',
        position: 'absolute',
        borderRadius: 10,
        padding: 0,
        '&:hover': {
            background: '#fff',
        },
        '& span': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    roomButton: {
        overflow: 'hidden',
        '& span': {
            height: 'inherit',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        '& img': {
            height: 78,
            width: '100%',
            objectFit: 'cover',
            '@media all and (min-width: 601px)': {
                height: 160,
            },
        },
    },
    skeletonBox: {
        height: 78,
        '@media all and (min-width: 601px)': {
            height: 160,
        },
    },
    inviteBox: {
        width: '100%',
        height: '100%',
        padding: '10px 10px',
        boxSizing: 'border-box',
        position: 'absolute',
        top: 0,
        zIndex: 100,
        display: 'flex',
        borderRadius: 10,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        '@media all and (min-width: 601px)': {
            padding: '40px 10px',
        },
    },
    inviteBoxIn: {
        '@media all and (min-width: 601px)': {
            height: '170px',
        },
        height: '110px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    inviteText: {
        '@media all and (min-width: 601px)': {
            fontSize: '1.125rem',
        },
        color: '#fff',
        fontSize: '0.875rem',
        fontWeight: '500',
        textAlign: 'center',
    },
    greenColor: {
        color: '#00c880',
        display: 'inline !important',
    },
    buttonStyle: {
        width: '100%',
        height: 30,
        background: '#0097FF',
        color: '#fff',
        borderRadius: 7,
        fontSize: '0.813rem',
        marginTop: 10,
        '&:hover': {
            background: '#0097FF',
        },
        '& span': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '@media all and (min-width: 601px)': {
            height: 38,
            fontSize: '0.875rem',
        },
    },
    roomTextBox: {
        '@media all and (min-width: 601px)': {
            height: 'calc(300px - 160px)',
        },
        width: '100%',
        height: 'calc(170px - 78px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '10px',
        boxSizing: 'border-box',
    },
    buttonIcon: {
        width: 34,
        height: 34,
        borderRadius: '50%',
        background: '#e1e1e1',
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            width: 20,
            height: 20,
        },
        '@media all and (min-width: 601px)': {
            width: 80,
            height: 80,
            marginBottom: 21,
            '& svg': {
                width: 30,
                height: 30,
            },
        },
    },
    buttonText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.938rem',
        },
        width: '100%',
        fontSize: '0.813rem',
        color: '#000',
        fontWeight: 600,
    },
    buttonTitle: {
        width: '100%',
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
    buttonSubText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.75rem',
        },
        fontSize: '0.625rem',
        color: '#828282',
    },
    roomBoxIn: {
        width: '100%',
        padding: '22px 10px 10px',
        margin: '0 auto',
        boxSizing: 'border-box',
        position: 'relative',
    },
    titleBox: {
        marginBottom: 5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    chipBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 12,
    },
    chip: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.75rem',
            minWidth: 50,
            height: '21px !important',
        },
        minWidth: 'calc((100% / 3) - 12px)',
        height: '18px !important',
        backgroundColor: '#eee',
        color: '#656565',
        marginRight: 6,
        padding: '0px 7px',
        fontSize: '0.625rem',
        border: '1px solid #eee',
        borderRadius: 50,
        boxSizing: 'border-box',
        '&:last-child': {
            marginRight: 0,
        },
        '&:hover': {
            border: '1px solid #dbf0ff',
            backgroundColor: '#dbf0ff!important',
            color: '#0097ff!important',
        },
    },
    btnStyle: {
        padding: 0,
        fontSize: '0.625rem',
        marginLeft: 5,
        '& p': {
            fontSize: '0.625rem',
        },
        '& svg': {
            width: 13,
            height: 13,
            marginRight: 4,
        },
        '&:hover': {
            background: 'transparent',
        },
        '& span': {
            alignItems: 'center',
        },
        '@media all and (min-width: 601px)': {
            fontSize: '0.75rem',
            marginLeft: 15,
            '& p': {
                fontSize: '0.75rem',
            },
            '& svg': {
                width: 18,
                height: 18,
            },
        },
    },
    moreBtn: {
        margin: 'auto',
    },
    coachBox: {
        position: 'absolute',
        '@media all and (min-width: 601px)': {
            right: 400,
            top: 10,
        },
        right: 320,
        top: 10,
    },
    addMemberCoachBox: {
        position: 'absolute',
        '@media all and (min-width: 600px)': {
            top: 320,
        },
        left: 10,
        top: 190,
    },
    addMemberCoachBox2: {
        position: 'absolute',
        '@media all and (min-width: 900px)': {
            left: 0,
            top: 320,
        },
        '@media all and (min-width: 600px)': {
            right: 350,
            top: 320,
        },
        right: 270,
        top: 190,
    },
    ownersChip: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.75rem',
            minWidth: 50,
            height: '21px !important',
        },
        minWidth: 35,
        height: '18px !important',
        fontSize: '0.625rem',
        color: '#34eb83',
        marginLeft: 6,
        padding: '0px 7px',
        border: '1px solid #34eb83',
        borderRadius: 50,
        boxSizing: 'border-box',
    },
});

const BootstrapInput = withStyles(_theme => ({
    root: {},
    input: {
        borderRadius: 0,
        position: 'relative',
        backgroundColor: 'transparent',
        border: '0',
        fontSize: '0.625rem',
        fontWeight: 600,
        color: '#000',
        '&:focus': {
            background: 'transparent',
        },
        '&.MuiSelect-select': {
            paddingRight: 5,
        },
        '& .MuiInputBase-input': {
            padding: 0,
        },
        '@media all and (min-width: 601px)': {
            fontSize: '0.75rem',
        },
    },
}))(InputBase);

const StyledBadgeMember = withStyles(_theme => ({
    badge: {
        right: 7,
        top: 3,
        border: `1px solid #fff`,
        width: 10,
        height: 10,
        borderRadius: '50%',
        background: '#00c880',
    },
}))(Badge);

class MobileMyRoomComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomList: [
                {
                    img: RoomTestImg3,
                    title: '커뮤니티 가이드',
                    post: false,
                    postText: '',
                    lastTime: false,
                    lastTimeText: '',
                    chip: '스터디',
                },
            ],
            dialogOpen: false,
            isExplanationBoxHidden: true,
        };
    }

    componentDidMount() {
        this.props.classStore.initSelectedGroupFolder();
        // this.props.classStore.initClass();
        this.props.classStore.getGroupList(this.props.authStore.loginUser);
        this.props.classStore.getInviteGroupList(this.props.authStore.loginUser);
        // this.props.userStore.getUserLoginHistory(this.props.authStore.loginUser.email);
    }

    componentWillUnmount() {
        if (this.props.classStore.groupListFirstLoading === LoadingState.SUCCESS) this.props.neoPenStore.setGroups(this.props.classStore.groupList);

        this.props.classStore.initClass();
    }

    handleChange = e => {
        this.props.classStore.changeSortType(e.target.value, () => this.props.classStore.getGroupList(this.props.authStore.loginUser));
    };

    handleClickRoomType = () => {
        this.props.navigate('/roomcreate');
    };

    handleClickOpenDialog = () => {
        this.setState({ dialogOpen: true });
        this.props.classStore.onClickGroupHideList();
    };

    handleCloseDialog = () => {
        this.setState({ dialogOpen: false });
    };

    handleSave = () => {
        this.props.classStore.changeHideGroup(this.props.authStore.loginUser, () => this.setState({ dialogOpen: false }));
    };

    handleClickMoreBtn = () => {
        this.props.classStore.getGroupList(this.props.authStore.loginUser);
    };

    handleFoldMoreBtn = () => {
        this.props.classStore.handleFoldBtn();
    };

    handleSearchByTagName = (e, tagName) => {
        e.preventDefault();
        e.stopPropagation();
        const { classStore, roomStore, authStore, postStore, searchStore, navigate } = this.props;
        roomStore.initRoom();
        postStore.initPost();
        searchStore.initCurrentPage();
        searchStore.changeSearchKeyword(tagName);
        classStore.getGroupListByKeyword(tagName, {
            moveToSearchPage: () => navigate(`/search/${SearchScope.All}/${SearchCategory.Study}/${tagName}`),
            getRoomList: () =>
                roomStore.getRoomListGroupByStartTime(undefined, authStore.loginUser.id, 'all', 1, undefined, undefined, undefined, tagName),
            getPostList: () => postStore.getPostList(undefined, authStore.loginUser.id, tagName, 1, 16),
        });
    };

    handleClickServiceCenter = () => {
        this.props.navigate('/servicecenter');
    };

    ellipsisText = text => (text.length > 14 ? text.substr(0, 14) + '...' : text);

    render() {
        const { classes, navigate, isMobile } = this.props;
        const { loginUser } = this.props.authStore;
        const { groupList, groupListLoading, groupListCount, groupCurrentCount, simpleGroupList, inviteGroupList } = this.props.classStore;
        const { coachState } = this.props.userStore;
        const hideGroupList = simpleGroupList.filter(g => g.hideGroup);

        return (
            <div className={classes.root}>
                <Box className={classes.roomBoxIn}>
                    <Box className={classes.titleBox}>
                        <Box display="flex" alignItems="center">
                            <Typography className={classes.titleText}>내 클래스</Typography>
                            <Button disableRipple onClick={this.handleClickOpenDialog} className={classes.btnStyle}>
                                {hideGroupList && hideGroupList.length > 0 ? (
                                    <React.Fragment>
                                        <StyledBadgeMember
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            variant="dot"
                                            overlap={'rectangular'}
                                        >
                                            <AsideSettingIcon />
                                        </StyledBadgeMember>
                                        숨김 목록 관리
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <AsideSettingIcon /> 숨김 목록 관리
                                    </React.Fragment>
                                )}
                            </Button>
                            <HiddenGroupManagementDialogComponent
                                dialogOpen={this.state.dialogOpen}
                                handleClose={this.handleCloseDialog}
                                handleSave={this.handleSave}
                            />
                        </Box>
                        <FormControl className={classes.margin}>
                            <Select
                                defaultValue={ClassSortType.create}
                                onChange={this.handleChange}
                                input={<BootstrapInput name="age" id="age-customized-select" />}
                                IconComponent={() => <ArrowDownIcon />}
                            >
                                <MenuItem value={ClassSortType.create} className={classes.menuText}>
                                    클래스 생성일순
                                </MenuItem>
                                <MenuItem value={ClassSortType.update} className={classes.menuText}>
                                    최근 업데이트순
                                </MenuItem>
                                <MenuItem value={ClassSortType.abc} className={classes.menuText}>
                                    가나다순
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <Box className={classes.coachBox}>
                            <CoachMarkForm
                                open={loginUser.type !== USER_TYPE.Guest && !coachState.hasChild}
                                title={'학생 등록하기'}
                                msg={"클래스 관리 페이지로 이동하여 '학생 관리'에서\n담당하는 모든 학생의 계정 생성 및 펜 정보를\n입력해주세요."}
                                currentStep={1}
                                totalStep={3}
                                btnText={'학생 등록 바로가기'}
                                submit={() => this.props.navigate('/classmanagement/1')}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" flexWrap="wrap" justifyContent={!isMobile ? 'flex-start' : 'space-between'}>
                        {loginUser.type !== USER_TYPE.Guest && (
                            <Box className={!isMobile ? clsx(classes.roomCreate, classes.landscapeRoomCreate) : classes.roomCreate}>
                                <Button className={classes.roomCreateButton} disableRipple onClick={this.handleClickRoomType.bind(this)}>
                                    <Box className={classes.buttonIcon}>
                                        <ButtonPlusIcon />
                                    </Box>
                                    <Typography className={classes.buttonText}>클래스 만들기</Typography>
                                </Button>
                                <Box className={classes.addMemberCoachBox}>
                                    <CoachMarkForm
                                        open={!coachState.hasGroup && coachState.hasChild && loginUser.type !== USER_TYPE.Guest}
                                        title={'클래스 만들기'}
                                        msg={
                                            '학생 등록이 완료되면 학습 및 주제에 따른 클래스를 만들고 수업도 등록해보세요. \n\n클래스가 만들어지면 학생을 초대하여 수업을 시작할 수 있습니다.'
                                        }
                                        currentStep={3}
                                        totalStep={3}
                                        btnText={'클래스 만들기'}
                                        submit={this.handleClickRoomType.bind(this)}
                                        arrPosition={100}
                                    />
                                </Box>
                            </Box>
                        )}

                        {inviteGroupList.length > 0 &&
                            inviteGroupList.map((g, i) => (
                                <Box key={i} className={!isMobile ? clsx(classes.roomCreate, classes.landscapeRoomCreate) : classes.roomCreate}>
                                    <Button className={clsx(classes.roomCreateButton, classes.roomButton)} disableRipple>
                                        <img src={g.image} alt="group" />
                                        <Box display="flex" flexDirection="column" alignItems="flex-start" className={classes.roomTextBox}>
                                            <Box display="flex" flexDirection="column" alignItems="flex-start" style={{ width: '100%' }}>
                                                <Box className={classes.chipBox}>
                                                    {g.tagList.map((tag, tagIndex) => (
                                                        <Typography
                                                            key={tagIndex}
                                                            className={classes.chip}
                                                            noWrap
                                                            onClick={e => this.handleSearchByTagName(e, tag.name)}
                                                        >
                                                            {tag.name}
                                                        </Typography>
                                                    ))}
                                                </Box>
                                                <Typography className={clsx(classes.buttonText, classes.buttonTitle)}>{g.group.name}</Typography>
                                            </Box>
                                            {g.post === true && (
                                                <Box display="flex" alignItems="center">
                                                    <NewPostIcon style={{ marginRight: 5, width: 10, height: 10 }} />
                                                    <Typography className={classes.buttonSubText}>{g.postText}</Typography>
                                                </Box>
                                            )}
                                            {g.lastTime === true && <Typography className={classes.buttonSubText}>{g.lastTimeText}</Typography>}
                                        </Box>
                                    </Button>

                                    {/* 초대받은 클래스*/}
                                    <Box className={classes.inviteBox}>
                                        <Box className={classes.inviteBoxIn}>
                                            <Typography className={classes.inviteText}>
                                                <span className={classes.greenColor}>{this.ellipsisText(g.lmsUser[0].name)}</span>
                                                &nbsp;선생님이 나를
                                                <span className={classes.greenColor}>&nbsp;{this.ellipsisText(g.group.name)}</span>
                                                &nbsp;클래스에 초대했습니다.
                                            </Typography>
                                        </Box>

                                        <Button
                                            className={classes.buttonStyle}
                                            disableRipple
                                            onClick={() => {
                                                this.props.classStore.setSelectedGroupDetail(g);
                                                this.props.classStore.joinGroup(this.props.authStore.loginUser.id, GroupJoinRequestPath.Invitation, {
                                                    initClass: () => this.props.classStore.initClass(),
                                                    getGroupList: () => this.props.classStore.getGroupList(this.props.authStore.loginUser),
                                                    getHideGroupList: () => this.props.classStore.getHideGroupList(this.props.authStore.loginUser.id),
                                                    getInviteGroupList: () =>
                                                        this.props.classStore.getInviteGroupList(this.props.authStore.loginUser),
                                                });
                                            }}
                                        >
                                            클래스 가입하기
                                        </Button>
                                    </Box>
                                </Box>
                            ))}

                        {this.props.classStore.groupListFirstLoading !== LoadingState.PENDING
                            ? groupList.map((g, i) => (
                                  <Box key={i} className={!isMobile ? clsx(classes.roomCreate, classes.landscapeRoomCreate) : classes.roomCreate}>
                                      <Button
                                          className={clsx(classes.roomCreateButton, classes.roomButton)}
                                          disableRipple
                                          onClick={() => {
                                              this.props.classStore.setSelectedGroupId(g.group.id);
                                              this.props.orgStore.setGroupColor(g.group.color);
                                              // navigate(`/class/${g.group.id}/${ClassMainPath.todaySchedule}`);
                                              navigate(`/class/${g.group.id}/${ClassMainPath.penRecord}`);
                                          }}
                                      >
                                          <img src={g.image} alt="group" />
                                          <Box display="flex" flexDirection="column" alignItems="flex-start" className={classes.roomTextBox}>
                                              <Box display="flex" flexDirection="column" alignItems="flex-start" style={{ width: '100%' }}>
                                                  <Box className={classes.chipBox}>
                                                      {g.tagList.map((tag, tagIndex) => (
                                                          <Typography
                                                              key={tagIndex}
                                                              className={classes.chip}
                                                              noWrap
                                                              onClick={e => this.handleSearchByTagName(e, tag.name)}
                                                          >
                                                              {tag.name}
                                                          </Typography>
                                                      ))}
                                                  </Box>
                                                  <Box display={'flex'} alignItems={'center'}>
                                                      <Typography className={clsx(classes.buttonText, classes.buttonTitle)}>
                                                          {g.group.name}
                                                      </Typography>
                                                      {/*{g.ownGroup && (*/}
                                                      {/*    <Typography className={classes.ownersChip} noWrap>*/}
                                                      {/*        운영*/}
                                                      {/*    </Typography>*/}
                                                      {/*)}*/}
                                                  </Box>
                                              </Box>
                                              {g.post === true && (
                                                  <Box display="flex" alignItems="center">
                                                      <NewPostIcon style={{ marginRight: 5 }} />
                                                      <Typography className={classes.buttonSubText}>{g.postText}</Typography>
                                                  </Box>
                                              )}
                                              {g.lastTime === true && <Typography className={classes.buttonSubText}>{g.lastTimeText}</Typography>}
                                          </Box>
                                      </Button>
                                      {i === 0 && (
                                          <Box className={classes.addMemberCoachBox2}>
                                              <CoachMarkForm
                                                  open={
                                                      !coachState.hasMember &&
                                                      coachState.hasGroup &&
                                                      coachState.hasChild &&
                                                      loginUser.type !== USER_TYPE.Guest
                                                  }
                                                  title={'학생 초대하기'}
                                                  msg={
                                                      "클래스에 학생을 초대하여 수업을 시작해보세요. \n\n교사가 클래스 관리에서 등록한 학생은 '클래스>\n멤버 탭>학생 초대' 목록에서 나타납니다.\n\n학생은 본인 계정으로 로그인 후 '초대 메시지>\n가입' 버튼을 눌러 클래스에 가입할 수 있습니다."
                                                  }
                                                  currentStep={1}
                                                  totalStep={3}
                                                  btnText={'학생 초대하기'}
                                                  submit={() => {
                                                      this.props.classStore.setSelectedGroupId(g.group.id);
                                                      this.props.orgStore.setGroupColor(g.group.color);
                                                      navigate(`/class/${g.group.id}/${ClassMainPath.member}`);
                                                  }}
                                              />
                                          </Box>
                                      )}
                                  </Box>
                              ))
                            : this.props.classStore.groupListCount > 0 &&
                              Array.from(new Array(this.props.classStore.groupListCount > 6 ? 6 : this.props.classStore.groupListCount)).map(
                                  (_, i) => (
                                      <Box key={i} className={!isMobile ? clsx(classes.roomCreate, classes.landscapeRoomCreate) : classes.roomCreate}>
                                          <Button className={clsx(classes.roomCreateButton, classes.roomButton)} disableRipple>
                                              <Skeleton variant="rect" width={'100%'} className={classes.skeletonBox} />
                                              <Box display="flex" flexDirection="column" alignItems="flex-start" className={classes.roomTextBox}>
                                                  <Box display="flex" flexDirection="column" alignItems="flex-start">
                                                      <Skeleton className={classes.chipBox}>
                                                          <Typography className={classes.chip} noWrap>
                                                              ... ...
                                                          </Typography>
                                                      </Skeleton>
                                                      <Skeleton width={150} />
                                                  </Box>
                                              </Box>
                                          </Button>
                                      </Box>
                                  ),
                              )}

                        <Box className={!isMobile ? clsx(classes.roomCreate, classes.landscapeRoomCreate) : classes.roomCreate}>
                            <Button
                                className={clsx(classes.roomCreateButton, classes.roomButton)}
                                disableRipple
                                onClick={this.handleClickServiceCenter}
                            >
                                <img src={RoomTestImg3} alt="group" />
                                <Box display="flex" flexDirection="column" alignItems="flex-start" className={classes.roomTextBox}>
                                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                                        <Box className={classes.chipBox}>
                                            <Typography className={classes.chip} noWrap>
                                                가이드
                                            </Typography>
                                        </Box>
                                        <Typography className={clsx(classes.buttonText, classes.buttonTitle)}>커뮤니티 가이드</Typography>
                                    </Box>
                                </Box>
                            </Button>
                        </Box>
                    </Box>
                    <Box display="flex">
                        {groupListCount > 6 &&
                            (groupListLoading !== LoadingState.PENDING ? (
                                <Box className={classes.moreBtn}>
                                    {groupListCount === groupCurrentCount ? (
                                        <Button className={classes.btnStyle} disableRipple onClick={this.handleFoldMoreBtn}>
                                            <ArrowDownTinyIcon style={{ transform: 'rotate(180deg)' }} />
                                            <Typography>접기</Typography>
                                        </Button>
                                    ) : (
                                        groupListCount > groupCurrentCount && (
                                            <Button className={classes.btnStyle} disableRipple onClick={this.handleClickMoreBtn}>
                                                <ArrowDownTinyIcon />
                                                <Typography>더보기</Typography>
                                            </Button>
                                        )
                                    )}
                                </Box>
                            ) : (
                                <Box className={classes.moreBtn} style={{ height: 36.5 }}>
                                    <CircularProgress size={20} />
                                </Box>
                            ))}
                    </Box>
                </Box>
            </div>
        );
    }
}
export default withRouter(
    withStyles(styles)(
        inject(
            'authStore',
            'classStore',
            'roomStore',
            'postStore',
            'userStore',
            'searchStore',
            'orgStore',
            'neoPenStore',
        )(observer(MobileMyRoomComponent)),
    ),
);
