import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Drawer, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as Close } from '../../../../common/images/Close.svg';
import { withRouter } from '../../../../components/WithRouter';
import { ReactComponent as Mathematics } from '../../../../common/images/Mathematics.svg';
import { inject, observer } from 'mobx-react';
import MobileNeoPenPageViewer from '../../../neoPen/MobileNeoPenPageViewer';
import clsx from 'clsx';

const styles = theme => ({
    root: {},
    drawerBox: {
        '& .MuiDrawer-paper': {
            borderRadius: '10px 10px 0 0',
            boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.25)',
            padding: '10px 10px 20px',
            boxSizing: 'border-box',
            '& svg': {
                width: 20,
                height: 20,
                '& path': {
                    strokeWidth: 2,
                },
            },
            '@media all and (min-width: 600px)': {
                '& svg': {
                    width: 30,
                    height: 30,
                },
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    topBar: {
        '&::before': {
            content: "''",
            width: 20,
            height: 20,
        },
    },
    titleText: {
        fontSize: '0.875rem',
        '@media all and (min-width: 600px)': {
            fontSize: '1rem',
        },
    },
    spanText: {
        color: '#6d6d70',
    },
    contentsBox: {
        width: '100%',
    },
    sliderBox: {
        overflow: 'auto ',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    //
    scrollStyleLandscape: {
        '@media screen and (max-width: 1280px)': {
            width: '100%',
            // height: 'inherit',
            overflowX: 'auto',
            overflowY: 'hidden',
        },
    },
    scrollStyle: {
        width: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        display: 'flex !important',
    },
    videoBox: {
        height: 160,
        border: '1px solid #b4b4b4',
        background: '#f5f5f5',
        margin: 'auto',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#f5f5f5',
        },
    },
    //
    listBox: {
        maxHeight: 162,
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    listBoxIn: {
        display: 'flex',
        alignItems: 'center',
        padding: '3px 0',
        cursor: 'pointer',
    },
    listText: {
        width: 'calc(100% - 20px)',
        fontSize: '0.875rem',
        color: '#777777',
    },
    selectListBoxIn: {
        '& path': {
            fill: '#6E75D7',
        },
        '& p': {
            color: '#6e75d7',
        },
    },
    pdfBox: {
        width: 85,
        height: 110,
        background: '#eee',
        marginRight: 7,
        position: 'relative',
        cursor: 'pointer',
        '&:last-child': {
            marginRight: 0,
        },
        '@media all and (min-width: 600px)': {
            width: 105,
            height: 150,
        },
    },
    pdfPageNumber: {
        position: 'absolute',
        bottom: 5,
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: 17,
        height: 17,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        background: '#777',
        fontSize: '0.688rem',
        color: '#fff',
        '@media all and (min-width: 600px)': {
            width: 20,
            height: 20,
            fontSize: '0.813rem',
        },
    },
});

class MobilePdfNotesDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes, PdfNotesDrawer, handleCloseDrawer } = this.props;

        const nPS = this.props.neoPenStore;

        const paperGroup = nPS.selectedPaperGroup;
        let contents = [];

        nPS.realTimePaperGroups.forEach(pG => {
            if (nPS.isStrokeInPaperGroup(nPS.activePage, pG)) {
                contents.push(
                    <Box className={clsx(classes.listBoxIn, classes.selectListBoxIn)}>
                        <Mathematics />
                        <Typography className={classes.listText} noWrap>
                            {pG.title}
                        </Typography>
                    </Box>,
                );
            } else {
                contents.push(
                    <Box className={classes.listBoxIn}>
                        <Button
                            className={clsx(classes.listBox)}
                            disableRipple
                            onClick={() => {
                                for (let pageInfo of nPS.realTimePageInfos) {
                                    if (nPS.isStrokeInPaperGroup(pageInfo, pG)) {
                                        nPS.setActivePage(pageInfo);

                                        break;
                                    }
                                }
                            }}
                        >
                            <Mathematics />
                            <Typography className={classes.listText} noWrap>
                                {pG.title}
                            </Typography>
                        </Button>
                    </Box>,
                );
            }
        });

        return (
            <div className={classes.root}>
                <Drawer anchor="bottom" open={PdfNotesDrawer} onClose={handleCloseDrawer} className={classes.drawerBox}>
                    <div tabIndex={0} role="button" onClick={handleCloseDrawer} onKeyDown={handleCloseDrawer}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.topBar}>
                            {/*<Typography className={classes.titleText}>*/}
                            {/*    {paperGroup !== undefined && (*/}
                            {/*        <Box>*/}
                            {/*            {paperGroup.title.trim().replace(/(.pdf)$/, '')}*/}
                            {/*            <span className={classes.spanText}>(</span>*/}
                            {/*            {nPS.activePage.page - paperGroup.pageStart + 1}*/}
                            {/*            <span className={classes.spanText}>/{paperGroup.pageEnd - paperGroup.pageStart + 1})</span>*/}
                            {/*        </Box>*/}
                            {/*    )}*/}
                            {/*</Typography>*/}
                            <IconButton className={classes.iconButton} onClick={handleCloseDrawer} disableRipple>
                                <Close />
                            </IconButton>
                        </Box>
                        <Box className={classes.contentsBox}>
                            <Box className={classes.sliderBox}>
                                <MobileNeoPenPageViewer />
                            </Box>

                            {contents.length > 0 && <Box className={classes.listBox}>{contents}</Box>}
                        </Box>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('neoPenStore')(observer(MobilePdfNotesDrawer))));
