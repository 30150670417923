import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Tab, Tabs } from '@material-ui/core';
import { ReactComponent as PlusCircleIcon } from '../../common/images/PlusCircleIcon.svg';
import ScheduleRegistrationComponent from '../dialog/ScheduleRegistrationComponent';
import YesOrNoDialog from '../../components/common/YesOrNoDialog';
import { inject, observer } from 'mobx-react';
import { ClassMainPath } from '../../common/ClassMainPath';
import { GroupJoinRequestPath } from '../../stores/ClassStore';
import { ToastsStore } from 'react-toasts';
import { withRouter } from '../../components/WithRouter';
import { PATH_UTIL } from '../../common/util/path.util';
import CoachMarkForm from '../../components/common/CoachMarkForm';
import { USER_TYPE } from '../../stores/UserStore';
import { ReactComponent as BedgeNewIcon } from '../../common/images/BedgeNewIcon.svg';
import { NOTIFICATION_TYPE } from '../../stores/NoticeStore';

const styles = theme => ({
    root: {
        '& *': {
            fontFamily: 'NanumSquareRoundOTF!important',
        },
        backgroundColor: '#fff',
        borderBottom: '1px solid #D3D5D5',
    },
    wrap: {
        '@media all and (min-width: 1500px)': {
            width: 1440,
        },
        width: 1200,
        margin: '0 auto',
        padding: '17px 30px 17px 30px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    trigger: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 0 auto',
        '& button': {
            '@media all and (min-width: 1500px)': {
                minWidth: 95,
            },
            minWidth: 80,
            minHeight: 40,
            position: 'relative',
            opacity: 1,
            marginRight: 10,
            '&:hover': {
                fontWeight: 700,
            },
            '&:disabled': {
                opacity: 1,
            },
        },
        '& button.Mui-selected': {
            backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
            color: '#fff',
            fontWeight: 700,
            borderRadius: 50,
            overflow: 'inherit',
        },
        '& .MuiTabs-indicator': {
            display: 'none',
            width: 0,
        },
    },
    btnStyle: {
        '@media all and (min-width: 1500px)': {
            width: 235,
            marginLeft: 90,
        },
        width: 230,
        height: 40,
        marginLeft: 30,
        backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        color: '#fff',
        borderRadius: 7,
        '& svg': {
            marginRight: 4,
        },
        '&:hover': {
            fontWeight: 800,
            backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
    emptyBtnStyle: {
        '@media all and (min-width: 1500px)': {
            width: 235,
            marginLeft: 90,
        },
        width: 230,
        height: 40,
        marginLeft: 30,
    },
    iconStyle: {
        position: 'absolute',
        right: '-2px',
        top: '-2px',
        zIndex: 10,
    },
    addMemberCoachBox: {
        position: 'absolute',
        '@media all and (min-width: 1500px)': {
            top: 65,
            left: 295,
        },
        top: 65,
        left: 225,
    },
    noDisplay: {
        display: 'none',
    },
});

class ClassTabTriggerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            openClassJoinConfirmDialog: false,
            anchorEl: null,
        };
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (prevProps.isMember !== this.props.isMember) {
            const { loginUser, noticeStore } = this.props;
            const pathName = window.location.pathname;
            const groupId = PATH_UTIL.getClassId(pathName);
            if (this.props.isMember) {
                const type = NOTIFICATION_TYPE.NoteCorrection;
                noticeStore.requestNotReadNotificationsByGroupUser(groupId, loginUser.email, type);
            } else {
                this.props.classStore.checkIsJoinApprove(loginUser.id, groupId);
            }
        }
    }

    handleClickOpen = () => {
        this.props.roomStore.initCreateRoom();
        this.setState({ dialogOpen: true });
    };

    handleClose = () => {
        this.props.handleInitRoom();
        this.setState({ dialogOpen: false });
    };

    handleChange = (_e, value) => {
        this.props.handleChange(value);
        if (this.props.classTab !== value) {
            this.props.roomStore.initRoom();
            this.props.roomStore.initTag();
        }
    };

    handleClickJoinBtn = () => {
        const { isWaitJoinApproval } = this.props.classStore;

        if (isWaitJoinApproval) {
            const { loginUser } = this.props.authStore;
            this.props.classStore.requestCancelGroupJoin(loginUser.email, {
                alert: () => {
                    ToastsStore.warning('잘못된 요청입니다. 클래스 정보를 확인해주세요.', 1500);
                },
            });
        } else {
            this.setState({ openClassJoinConfirmDialog: true });
        }
    };

    render() {
        const { classes, classTab, isMember, isLeader } = this.props;
        const { groupUserAuthority, isWaitJoinApproval, isCheckJoinApprovalLoading, isInitializingGroupDetail } = this.props.classStore;
        const { coachState } = this.props.userStore;
        const { loginUser } = this.props.authStore;
        const { hasNotReadNoteCorrections } = this.props.noticeStore;
        const isOpenCoachMark =
            !coachState.hasMember &&
            coachState.hasGroup &&
            coachState.hasChild &&
            loginUser.type !== USER_TYPE.Guest &&
            classTab !== ClassMainPath.member;

        return (
            <div className={classes.root}>
                <Box className={classes.wrap}>
                    <Box style={{ position: 'relative' }}>
                        <Tabs value={classTab} onChange={this.handleChange} className={classes.trigger}>
                            <Tab
                                label={'필기 수업'}
                                value={ClassMainPath.penRecord}
                                style={!isMember ? { display: 'none' } : null}
                                // disabled={classTab === ClassMainPath.penRecord}
                                disableRipple
                                icon={<BedgeNewIcon className={hasNotReadNoteCorrections ? classes.iconStyle : classes.noDisplay} />}
                            />
                            <Tab
                                label="오늘 수업"
                                value={ClassMainPath.todaySchedule}
                                // disabled={classTab === ClassMainPath.todaySchedule}
                                disableRipple
                                // icon={<BedgeNewIcon className={classes.iconStyle} />}
                                style={{ display: 'none' }}
                            />
                            <Tab
                                label="화상 수업"
                                value={ClassMainPath.allSchedule}
                                // disabled={classTab === ClassMainPath.allSchedule}
                                disableRipple
                                // icon={<BedgeNewIcon className={classes.iconStyle} />}
                            />
                            <Tab
                                label="글쓰기 평가"
                                value={ClassMainPath.evaluation}
                                // disabled={classTab === ClassMainPath.allSchedule}
                                disableRipple
                                // icon={<BedgeNewIcon className={classes.iconStyle} />}
                                style={!isLeader ? { display: 'none' } : null}
                            />
                            <Tab
                                label="캘린더"
                                value={ClassMainPath.calendarSchedule}
                                // disabled={classTab === ClassMainPath.calendarSchedule}
                                disableRipple
                                style={{ display: 'none' }}
                            />
                            <Tab
                                label="자료실"
                                value={ClassMainPath.referenceRoom}
                                // disabled={classTab === ClassMainPath.referenceRoom}
                                disableRipple
                                style={!isMember ? { display: 'none' } : null}
                                // icon={<BedgeNewIcon className={classes.iconStyle} />}
                            />
                            <Tab
                                label="커뮤니티"
                                value={ClassMainPath.community}
                                // disabled={classTab === ClassMainPath.community}
                                disableRipple
                            />
                            <Tab
                                style={{ position: 'relative' }}
                                label="멤버"
                                value={ClassMainPath.member}
                                // disabled={classTab === ClassMainPath.member}
                                disableRipple
                            />
                            <Tab
                                label="설정"
                                value={ClassMainPath.setting}
                                // disabled={classTab === ClassMainPath.setting}
                                disableRipple
                                style={{ display: 'none' }}
                            />
                            <Tab
                                label="일정 상세"
                                value={ClassMainPath.scheduleDetail}
                                // disabled={classTab === ClassMainPath.scheduleDetail}
                                disableRipple
                                style={{ display: 'none' }}
                            />
                            <Tab
                                label="게시글 상세"
                                value={ClassMainPath.postDetail}
                                // disabled={classTab === ClassMainPath.postDetail}
                                disableRipple
                                style={{ display: 'none' }}
                            />
                            <Tab
                                label="태그"
                                value={ClassMainPath.tag}
                                // disabled={classTab === ClassMainPath.tag}
                                disableRipple
                                style={{ display: 'none' }}
                            />
                            <Tab
                                label="검색"
                                value={ClassMainPath.search}
                                // disabled={classTab === ClassMainPath.tag}
                                disableRipple
                                style={{ display: 'none' }}
                            />
                        </Tabs>
                        <Box className={classes.addMemberCoachBox}>
                            <CoachMarkForm
                                open={isOpenCoachMark}
                                title={'학생 초대하기'}
                                msg={
                                    "멤버 탭으로 이동하여 클래스 관리에서 등록한\n학생을'멤버 초대하기' 버튼을 눌러 '학생 초대'\n목록에서 선택 초대해보세요."
                                }
                                currentStep={2}
                                totalStep={3}
                                btnText={'학생 초대하기'}
                                submit={e => this.handleChange(e, ClassMainPath.member)}
                            />
                        </Box>
                    </Box>

                    {isMember === undefined || isInitializingGroupDetail || isCheckJoinApprovalLoading ? (
                        <div className={classes.emptyBtnStyle} disableripple="true"></div>
                    ) : isMember && classTab === ClassMainPath.allSchedule && groupUserAuthority.RoomCreate ? (
                        <Button className={classes.btnStyle} onClick={this.handleClickOpen} disableripple="true">
                            <PlusCircleIcon /> 화상수업 개설하기
                        </Button>
                    ) : !isMember ? (
                        <Button className={classes.btnStyle} disableripple="true" onClick={this.handleClickJoinBtn}>
                            <PlusCircleIcon /> {isWaitJoinApproval ? '가입 요청 취소' : '클래스 가입하기'}
                        </Button>
                    ) : (
                        <div className={classes.emptyBtnStyle} disableripple="true"></div>
                    )}
                </Box>

                <ScheduleRegistrationComponent
                    handleClose={this.handleClose}
                    dialogOpen={this.state.dialogOpen}
                    classTab={this.props.classTab}
                    scrollMove={this.props.scrollMove}
                />
                <YesOrNoDialog
                    open={this.state.openClassJoinConfirmDialog}
                    title={'클래스 가입'}
                    msg={'해당 클래스에 가입하시겠습니까?'}
                    changeDialogOpen={() => this.setState({ openClassJoinConfirmDialog: false })}
                    submit={() =>
                        this.props.classStore.joinGroup(this.props.authStore.loginUser.id, GroupJoinRequestPath.Search, {
                            requestGroupDetailAndCheckMember: groupId => {
                                this.props.requestGroupDetail(groupId);
                            },
                            alert: () => {
                                ToastsStore.info('가입 요청이 승인대기 중입니다.', 3000, classes.toasts);
                            },
                        })
                    }
                />
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('authStore', 'classStore', 'roomStore', 'userStore', 'noticeStore')(observer(ClassTabTriggerComponent))),
);
