import React, { Component, memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MyRoomComponent from './MyRoomComponent';
import TodayScheduleComponent from './TodayScheduleComponent';
import Footer from '../footer/Footer';
import { Box } from '@material-ui/core';
import LicenseNotificationDialogComponent from '../dialog/LicenseNotificationDialogComponent';
import LicenseDialogComponent from '../dialog/LicenseDialogComponent';
import WithdrawalDialogComponent from '../dialog/WithdrawalDialogComponent';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import LicenseKeyInformation from './LicenseKeyInformation';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { FIND_PASSWORD_EMAIL_SEND_RESULT_STATE } from '../../stores/UserStore';

const styles = _theme => ({
    root: {
        display: 'flex',
        height: 'calc(100vh - 87px)',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

class Rooms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseNotificationDialog: true,
            licenseDialog: false,
        };
    }

    handleCloseLicenseDialog = () => {
        this.props.userStore?.changeCreateLicenseKeyState(FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.None);
        this.props.userStore?.changeCreateLicenseKeyResultDialogOpenState(false);
    };

    render() {
        const { intl, classes, isScrollEnd, userStore } = this.props;
        const isOpenDialog = userStore?.isCreateUserLicenseKeyResultDialogState;
        const createUserLicenseKeyState = userStore?.createUserLicenseKeyState;
        console.log('[Rooms] this.props : >> ', this.props);

        return (
            <LicenseKeyInformation>
                <div className={classes.root}>
                    <Box>
                        <MyRoomComponent />
                        <TodayScheduleComponent isScrollEnd={isScrollEnd} />
                    </Box>
                    <Footer />
                    {/*<LicenseNotificationDialogComponent open={this.state.licenseNotificationDialog} close={this.handleCloseLicenseDialog} />*/}
                    {/*<LicenseDialogComponent />*/}
                    <ConfirmDialog
                        open={isOpenDialog}
                        title={intl.formatMessage({ id: 'notification' })}
                        msg={
                            createUserLicenseKeyState === FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.Success
                                ? '서비스 라이선스 키가 등록되었습니다.'
                                : '서비스 라이선스 키가 유효하지 않습니다. 라이선스 키를 다시 입력하거나 서비스 담당자에게 문의해주세요.'
                        }
                        changeDialogOpen={this.handleCloseLicenseDialog}
                    />
                </div>
            </LicenseKeyInformation>
        );
    }
}

export default memo(withStyles(styles)(inject('userStore')(injectIntl(observer(Rooms)))));
