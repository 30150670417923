import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Tab, Tabs } from '@material-ui/core';

const styles = theme => ({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '20px 10px 15px',
        boxSizing: 'border-box',
        margin: '0 auto',
    },
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        minHeight: 0,
        '&:after': {
            content: '""',
            width: '100%',
            height: 3,
            backgroundColor: '#d3d4d5',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: 0,
        },
        '& button': {
            minWidth: 70,
            fontSize: '0.688rem',
            color: '#000',
            opacity: 1,
            minHeight: 30,
            '&.Mui-selected': {
                color: '#0097ff',
            },
        },
        '& .MuiTabs-indicator': {
            height: 2,
            backgroundColor: '#0097ff',
            zIndex: 1,
        },
        '& .MuiTab-root': {
            padding: '0 0px',
        },
        '@media all and (min-width: 601px)': {
            minHeight: 48,
            '& button': {
                minWidth: 100,
                minHeight: 48,
                fontSize: '0.938rem',
            },
            '& .MuiTabs-indicator': {
                height: 3,
            },
        },
    },
});

class MobileSearchMyGroupTabComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Box className={classes.appBar}>
                    <Tabs value={this.props.searchTab} onChange={this.props.handleChange} className={classes.tabStyle}>
                        <Tab label="수업" disableRipple />
                        <Tab label="게시판" disableRipple />
                    </Tabs>
                </Box>
            </div>
        );
    }
}

export default withStyles(styles)(MobileSearchMyGroupTabComponent);
