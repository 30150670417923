import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, CircularProgress, FormControl, IconButton, Input, MenuItem, Popover, Select, Typography } from '@material-ui/core';
import { ReactComponent as PaperclipIcon } from '../../common/images/PaperclipIcon.svg';
import { ReactComponent as SmileyIcon } from '../../common/images/SmileyIcon.svg';
import clsx from 'clsx';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { ReactComponent as Emoticon2 } from '../../common/images/Emoticon2.svg';
import { ReactComponent as Emoticon5 } from '../../common/images/Emoticon5.svg';
import { ReactComponent as Emoticon6 } from '../../common/images/Emoticon6.svg';
import Emoticon1 from '../../common/images/Emoticon1.png';
import Emoticon3 from '../../common/images/Emoticon3.png';
import Emoticon4 from '../../common/images/Emoticon4.png';
import Emoticon7 from '../../common/images/Emoticon7.png';
import { ReactComponent as Emoticon8 } from '../../common/images/Emoticon8.svg';
import ProfileDialogComponent from '../dialog/ProfileDialogComponent';
import { inject, observer } from 'mobx-react';
import { sortType } from '../../stores/RoomStore';
import { LMS_GROUP_USER_TYPE_NAME_TAG, LmsGroupUserType } from '../../stores/ClassStore';
import InputEmoji from 'react-input-emoji';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import MoreButton from '../../components/common/MoreButton';
import { injectIntl } from 'react-intl';
import InputEmojiComponent from '../../components/InputEmojiComponent';
import { CommentType } from '../../stores/CommentStore';
import { ClassMainPath } from '../../common/ClassMainPath';
import YesOrNoDialog from '../../components/common/YesOrNoDialog';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { ToastsStore } from 'react-toasts';
import { UTIL } from '../../common/util/common.util';
import { ReactComponent as DownloadIcon } from '../../common/images/DownloadIcon.svg';
import { FILE_POSTED_TYPE } from '../../stores/ClassFileStore';

const styles = theme => ({
    root: {
        '& .react-emoji ': {
            fontSize: 20,
            '& .react-input-emoji--container': {
                margin: '0 0 10px',
            },
            '& .react-input-emoji--button': {
                position: 'absolute',
                bottom: -35,
                left: 40,
                '& .react-input-emoji--button--icon': {
                    width: 20,
                    height: 20,
                    opacity: 0.6,
                },
            },
            '& .react-emoji-picker--container .react-emoji-picker--wrapper .react-emoji-picker .emoji-mart': {
                width: '338px !important',
            },
            '& .react-emoji-picker--wrapper': {
                bottom: -95,
                left: 75,
                zIndex: 2,
            },
        },
    },
    flexBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tagWriter: {
        fontSize: '0.625rem',
        backgroundColor: '#5778ed',
        color: '#fff',
        padding: '2px 6px',
        marginLeft: 4,
        verticalAlign: 'middle',
    },
    tagWriterColor: {
        backgroundColor: '#00c880',
    },
    commentBox: {
        border: '1px solid #e1e1e1',
        padding: '12px 8px 7px 6px',
        borderRadius: 7,
        marginBottom: 38,
        position: 'relative',
    },
    commentBtnBox: {
        borderRadius: 5,
        backgroundColor: '#f8f8f8',
        padding: '6px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    iconButton: {
        padding: 5,
        marginRight: 5,
        '&:hover': {
            background: 'transparent',
        },
    },
    btnStyle: {
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        color: '#fff',
        padding: '2px 10px',
        '&:hover': {
            fontWeight: 800,
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    btnStyle2: {
        fontSize: '0.75rem',
        fontWeight: 600,
        padding: 0,
        '& svg': {
            marginLeft: 4,
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiPaper-root': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 5,
        },
    },
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    commTitle: {
        borderBottom: '1px solid #e1e1e1',
        paddingBottom: 7,
        marginBottom: 17,
    },
    commentContent: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        borderBottom: '1px solid #e1e1e1',
        marginBottom: 19,
        paddingBottom: 19,
    },
    commentList: {
        display: 'flex',
    },
    comments: {
        objectFit: 'contain',
        marginLeft: 10,
        '&>h5': {
            fontSize: '0.875rem',
            fontWeight: 600,
            margin: '12px 0 16px',
        },
        '&>p': {
            marginBottom: 10,
        },
        '& .MuiTypography-caption': {
            display: 'flex',
            alignItems: 'center',
            color: '#848484',
        },
    },
    mention: {
        color: '#0097ff',
    },
    inputFile: {
        display: 'none',
    },
    iconColor: {
        '&  .image-square, .paperclip, .smiley-icon': {
            fill: 'rgba(0, 188, 106, 0.6)',
        },
    },
    popoverBox: {
        '& .MuiPopover-paper': {
            width: 160,
            boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.25)',
            borderRadius: 4,
            padding: '15px 22px',
        },
    },
    popoverBoxEmotions: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.25)',
            borderRadius: 4,
            padding: '15px 12px 15px 22px',
            '& .MuiTypography-caption': {
                color: '#848484',
                marginLeft: 10,
            },
            '& .MuiBox-root': {
                marginRight: 10,
            },
        },
    },
    emotionBox: {
        padding: 0,
        minWidth: 20,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 5,
            '&:last-child': {
                marginRight: 0,
            },
        },
        '& img': {
            width: '19px',
            height: '19px',
        },
    },
    emotionIcon: {
        padding: 0,
        '&:hover': {
            cursor: 'pointer',
            background: 'transparent',
        },
        '& img': {
            width: 18,
            height: 18,
        },
    },
    myPickEmotionIcon: {
        padding: 3,
        background: 'rgba(196, 196, 196, 0.6)',
        boxShadow: 'inset 0px 1px 1px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            background: 'rgba(196, 196, 196, 0.6)',
        },
    },
    controlBox: {
        borderRadius: 5,
        background: '#f8f8f8',
        padding: '10px 20px',
        marginTop: 21,
    },
    fileImgBox: {
        width: 200,
        height: 140,
        borderRadius: 5,
        overflow: 'hidden',
        '& img': {
            width: '100%',
            maxWidth: '100%',
            objectFit: 'cover',
        },
    },
    fileVideoBox: {
        width: 200,
        height: 140,
        background: '#eee',
        marginBottom: 10,
    },
    fileBox: {
        background: '#f8f8f8',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 10px',
        borderRadius: 7,
        marginBottom: 10,
        '& svg': {
            padding: '0px 5px',
            '& .paperclip': {
                fill: '#000',
            },
        },
    },
    fileNameText: {
        fontSize: '0.875rem',
        color: '#000',
        marginLeft: 8,
    },
    fileSizeText: {
        fontSize: '0.75rem',
        color: '#848484',
        marginLeft: 8,
    },
    linkText: {
        color: '#0097ff',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    modalBox: {
        position: 'fixed',
        zIndex: 1000,
        paddingTop: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    modalBoxIn: {
        width: 850,
        margin: 'auto',
    },
    modalClose: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    modalContent: {
        width: 800,
        height: 560,
        margin: 'auto',
        display: 'block',
    },
    avatar: {
        cursor: 'pointer',
        background: '#a3a8af',
        '& svg': {
            width: 25,
            height: 25,
            '& path': {
                fill: '#fff',
            },
        },
    },
    imageClose: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 30,
        height: 30,
        background: '#f8f8f8',
        margin: 3,
        '&:hover': {
            background: '#f8f8f8',
        },
    },
    autoCompleteBox: {
        '& .MuiInputBase-root': {
            padding: '2.5px 6px',
        },
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #d9dbde',
            },
        '& .MuiAutocomplete-input': {
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#a3a8af',
                opacity: 1,
                fontWeight: 300,
            },
        },
        '& .MuiChip-root': {
            background: 'transparent',
            '& .MuiChip-label': {
                color: '#0097ff',
                fontSize: '1rem',
                paddingLeft: 0,
                paddingRight: 5,
            },
            '& svg': {
                display: 'none',
            },
        },
        '& .MuiAutocomplete-popupIndicator': {
            display: 'none',
        },
    },
    cancelBtn: {
        marginLeft: 'auto',
        marginRight: 15,
        background: '#c2c2c2',
        color: '#fff',
        '&:hover': {
            fontWeight: 800,
            background: '#c2c2c2',
        },
        padding: '2px 10px',
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    emojiBox: {
        height: 29,
    },
    emojiCount: {
        marginLeft: 10,
        fontSize: '1rem',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    emojiViewCount: {
        display: 'flex',
        alignItems: 'center',
        margin: '7px 0 7px 0',
        '& img': {
            width: 24,
            height: 24,
        },
    },
    fileIcon: {
        marginLeft: 10,
        marginRight: 0,
        justifyContent: 'center',
    },
    commentWrapper: {
        marginBottom: 10,
        fontSize: '0.8rem',
    },
});

class CommentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            age: sortType.TIME,
            text: '',
            selectedUser: null,
            GoodToggle: false,
            emoticonCommentAnchorEl: null,
            emoticonCommentAnchorEl1: null,

            openDeleteConfirmDialog: false,

            imageModal: false,
            profileDialog: false,

            file: false,
            imgFile: [],
            video: '',
            link: '',
            previewURL: [],
            comment: '',
            modifyCommentId: '',
            commentText: '',
            selectedTab: '',
            myPick: 0,
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, _prevState: Readonly<S>, _snapshot: SS) {
        const { commentStore, roomStore, postStore, groupId, typeId, type } = this.props;
        let isEndData =
            type === CommentType.ROOM
                ? roomStore.roomDummy.commentCount === commentStore.commentList.length
                : postStore.postDummy.commentCount === commentStore.commentList.length;
        if (prevProps.isScrollEnd !== this.props.isScrollEnd && this.props.isScrollEnd && !isEndData && typeId) {
            if (type === CommentType.ROOM) {
                commentStore.getCommentList(groupId, typeId, CommentType.ROOM, undefined, this.state.age);
            } else {
                commentStore.getCommentList(groupId, typeId, CommentType.POST, undefined, this.state.age);
            }
        }
    }

    componentWillUnmount() {
        this.props.commentStore.initComment();
    }

    handleModifyComment = comment => {
        this.setState(prevState => ({ ...prevState, modifyCommentId: comment.commentId, comment: { ...comment } }));
        this.props.commentStore.setModifyComment(comment);
    };

    handleModifyCancel = () => {
        this.setState({ modifyCommentId: '', comment: '' });
        this.props.commentStore.initModifyComment();
    };

    handleClickImageModal = () => {
        this.setState({ imageModal: true });
    };

    handleClickProfileDialog = user => {
        this.setState({ selectedUser: user, profileDialog: true });
    };

    handleCloseImageModal = () => {
        this.setState({ imageModal: false });
    };

    handleChange = (event, commentType) => {
        this.setState({ age: event.target.value }, () => {
            this.props.commentStore.getCommentList(this.props.groupId, this.props.typeId, commentType, 0, this.state.age);
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
            emoticonCommentAnchorEl: null,
            emoticonCommentAnchorEl1: null,
            emoticon: false,
            profileDialog: false,
        });
    };

    handleRemove = comment => {
        this.setState({ openDeleteConfirmDialog: true, comment: comment });
    };

    handleClickEmoticonCommentAnchorEl = (event, comment) => {
        comment.emojiHistory && comment.emojiHistory.filter(e => e.userId === this.props.authStore.loginUser.id).length > 0
            ? this.setState({
                  myPick: comment.emojiHistory.filter(e => e.userId === this.props.authStore.loginUser.id)[0].emojiId,
                  comment: comment,
                  emoticonCommentAnchorEl: event.currentTarget,
              })
            : this.setState({ myPick: 0, comment: comment, emoticonCommentAnchorEl: event.currentTarget });
    };

    handleClickEmoticonCommentCountAnchorEl = (event, comment) => {
        this.setState({
            comment: comment,
            emoticonCommentAnchorEl1: event.currentTarget,
        });
    };

    handleImageFileChange = event => {
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.onloadend = () => {
            this.setState(state => {
                const imgFile = state.imgFile.concat(file);
                return { imgFile };
            });
            this.setState(state => {
                const previewURL = state.previewURL.concat(reader.result);
                return { previewURL };
            });
        };
        reader.readAsDataURL(file);
    };

    handleFileChange = event => {
        event.preventDefault();
        let file = event.target.files[0];
        const fileSizeMega = file.size / 1024 / 1024;
        if (fileSizeMega > 500) {
            this.props.commentStore.setOverSize(true);
            ToastsStore.warning('500MB 이하 파일만 사용가능합니다.', 3000);
        } else {
            this.props.commentStore.setOverSize(false);
            const isModify = this.state.modifyCommentId !== '';
            if (isModify) {
                if (this.props.commentStore.modifyComment.commentFiles.length) {
                    ToastsStore.warning('일반파일은 1개만 등록 가능합니다. 파일 제거후 다시 시도해주세요.', 3000);
                } else {
                    this.props.commentStore.changeCommentAttachments(file);
                    this.handleModifyCommentFile();
                }
            } else {
                this.props.commentStore.changeCommentAttachments(file);
            }
        }

        event.target.value = '';
    };

    handleModifyCommentFile = () => {
        this.props.commentStore.changeCommentFile();
    };

    handleRemoveImageFile = index => {
        this.setState(state => {
            const imgFile = state.imgFile.splice(index + 1, 1);
            return { imgFile };
        });
        this.setState(state => {
            const previewURL = state.previewURL.splice(index + 1, 1);
            return { previewURL };
        });
    };

    handleRemoveUploadFile = () => {
        this.props.commentStore.initCommentAttachments();
    };

    handleDownLoadCommentFile = commentFile => {
        this.props.classFileStore.downloadFile(
            commentFile.groupId,
            FILE_POSTED_TYPE.Comment,
            commentFile.commentId,
            commentFile.fileId,
            commentFile.name,
        );
    };

    handleRemovePostFile = fileIndex => {
        const { commentAttachments } = this.props.commentStore;
        if (commentAttachments.file) {
            this.props.commentStore.initCommentAttachments();
        }

        this.props.commentStore.removeCommentFile(fileIndex);
    };

    handleClickEmotion = e => {
        const { commentStore, authStore } = this.props;
        if (Number(e.currentTarget.id) === this.state.myPick) {
            commentStore.deleteEmoji(this.state.comment, e.currentTarget.id, authStore.loginUser.id);
        } else {
            if (this.state.myPick !== 0) {
                commentStore.modifyEmoji(this.state.comment, e.currentTarget.id, authStore.loginUser.id);
            } else {
                commentStore.selectEmoji(this.state.comment, e.currentTarget.id, authStore.loginUser.id);
            }
        }
        this.setState({ emoticonCommentAnchorEl: null, comment: null, myPick: 0 });
    };

    handleClickMentionText = e => {
        if (e.target.id === 'mention-tag') {
            const user = this.props.classStore.selectedGroupDetail.lmsUser.find(lu => String(lu.userId) === e.target.ariaValueText);
            this.handleClickProfileDialog(user);
        }
    };

    render() {
        const { classes, classStore, commentStore, currentTab, groupId, typeId, isMember, scrollMove } = this.props;
        const { isCommentLoading, modifyComment, commentAttachments } = commentStore;
        const { commentList } = commentStore;
        const { emoticonCommentAnchorEl, emoticonCommentAnchorEl1 } = this.state;
        const commentType = currentTab['*'].split('/')[0] === ClassMainPath.scheduleDetail ? CommentType.ROOM : CommentType.POST;
        const emoticonCommentAnchorElOpen = Boolean(emoticonCommentAnchorEl);
        const emoticonCommentAnchorElOpen1 = Boolean(emoticonCommentAnchorEl1);
        const attendantsList = classStore.selectedGroupDetail.lmsUser.filter(user => user.userId !== this.props.authStore.loginUser.id);
        const getClickableLink = link => {
            return link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;
        };

        let image_preview = [];
        if (this.state.imgFile.length > 0) {
            this.state.imgFile.map((_, i) => {
                image_preview[i] = <img key={i} src={this.state.previewURL[i]} align="image" alt={'imgFile'} />;
            });
        }

        const mentionConverter = content => {
            const mentionRegex = /((@\[)[a-zA-Zㄱ-ㅎ가-힣0-9]{1,50}]\(?userId:[0-9]{1,}\))/g;
            let userId = '';
            let userName = '';
            const userNameRegex = /@\[(.*?)]/g;
            const userIdRegex = /]\(userId:.*?\)/g;
            const replace = () => {
                return content.replace(mentionRegex, function (url) {
                    userName = url
                        .match(userNameRegex)[0]
                        .replace(/[@\[\]]/g, '')
                        .trim();
                    userId = url
                        .match(userIdRegex)[0]
                        .replace(/[\](userId:)]/g, '')
                        .trim();
                    return `<a style="color: #006edc; cursor:pointer" id="mention-tag" aria-valuetext=${userId}>${userName}</a>`;
                    // const convertContent = content.replace(mentionRegex, function (url) {
                    //     userName = url
                    //         .match(userNameRegex)[0]
                    //         .replace(/[@\[\]]/g, '')
                    //         .trim();
                    //     userId = url
                    //         .match(userIdRegex)[0]
                    //         .replace(/[\](userId:)]/g, '')
                    //         .trim();
                    //     return `<a style="color: #006edc; cursor:pointer" id="mention-tag" aria-valuetext=${userId}>${userName}</a>`;
                });
                // return convertContent;
            };
            return replace();
        };

        const getNoticeContents = content => {
            const urlRegex =
                /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
            const replace = () => {
                const convertContent = content.replace(urlRegex, function (url) {
                    return (
                        '<a style="text-decoration: none" href="' +
                        getClickableLink(url) +
                        '" target="blank"  rel=”noopener” style="margin:0" >' +
                        url +
                        '</a> '
                    );
                });
                const htmlArr = [];
                convertContent.split('\n').forEach(function (text) {
                    const textHtml = "<p style='margin:0'>" + text + '</p>';
                    htmlArr.push(textHtml);
                });
                return { __html: htmlArr.join('') };
            };
            return <Box dangerouslySetInnerHTML={replace()} onClick={e => this.handleClickMentionText(e)} />;
        };

        const isAvailableInputComment = commentType === CommentType.ROOM ? classStore.groupUserAuthority.RoomComment : isMember;

        return (
            <div className={classes.root}>
                {isAvailableInputComment && (
                    <Box className={classes.commentBox}>
                        <InputEmoji
                            searchMention={text => {
                                return attendantsList
                                    .filter(d => d.name.indexOf(text.replace('@', '')) !== -1)
                                    .map(d => {
                                        // const user = { ...d, id: d.userId, image: d.image };
                                        // return user;
                                        return { ...d, id: d.userId, image: d.image };
                                    });
                            }}
                            id="comment-textField"
                            value={this.props.commentStore.comment.text}
                            placeholder="댓글을 남겨주세요."
                            borderRadius={0}
                            multiline
                            maxLength={2000}
                            onChange={text => {
                                this.props.commentStore.changeCommentText(text);
                            }}
                        />
                        <Box className={classes.commentBtnBox}>
                            <Box>
                                {/*<input*/}
                                {/*    type="file"*/}
                                {/*    accept="image/jpg,impge/png,image/jpeg,image/gif"*/}
                                {/*    name="image"*/}
                                {/*    id="image-button-file"*/}
                                {/*    onChange={this.handleImageFileChange}*/}
                                {/*    className={classes.inputFile}*/}
                                {/*/>*/}
                                <input
                                    type="file"
                                    accept="*.*"
                                    name="file"
                                    id="button-file"
                                    onChange={this.handleFileChange}
                                    className={classes.inputFile}
                                />
                                {/*<label htmlFor="image-button-file">*/}
                                {/*    <IconButton component="span" className={classes.iconButton} disableRipple>*/}
                                {/*        {!this.state.imgFile.length > 0 ? <ImageSquareIcon /> : <ImageSquareIcon className={classes.iconColor} />}*/}
                                {/*    </IconButton>*/}
                                {/*</label>*/}

                                {/*<IconButton component="span" className={classes.iconButton} disableRipple>*/}
                                {/*    {this.state.video === '' ? <MonitorPlayIcon /> : <MonitorPlayIcon className={classes.iconColor} />}*/}
                                {/*</IconButton>*/}

                                <label htmlFor="button-file">
                                    <IconButton component="span" className={classes.iconButton} disableRipple>
                                        {commentAttachments.file !== '' ? <PaperclipIcon className={classes.iconColor} /> : <PaperclipIcon />}
                                    </IconButton>
                                </label>
                                {/*<IconButton className={classes.iconButton} disableRipple>*/}
                                {/*    {this.state.link === '' ? <LinkGreyTinyIcon /> : <LinkGreyTinyIcon className={classes.iconColor} />}*/}
                                {/*</IconButton>*/}
                            </Box>
                            <Button
                                disableRipple
                                className={classes.btnStyle}
                                disabled={isCommentLoading || (this.props.commentStore.comment.text === '' && commentAttachments.file === '')}
                                onClick={() => {
                                    scrollMove();
                                    this.props.commentStore.setCurrentPage();
                                    this.props.commentStore.createComment(
                                        this.props.authStore.loginUser,
                                        currentTab.id,
                                        commentType,
                                        typeId,
                                        this.state.age,
                                        commentType === CommentType.ROOM
                                            ? { getCommentCount: () => this.props.roomStore.getCommentCount(typeId) }
                                            : { getCommentCount: () => this.props.postStore.getCommentCount(typeId) },
                                    );
                                }}
                            >
                                보내기
                            </Button>
                        </Box>
                        {/*{!this.state.imgFile.length > 0 ? null : (*/}
                        {/*    <Box display="flex" justifyContent="flex-start" className={classes.controlBox}>*/}
                        {/*        {this.state.imgFile.map((_, i) => (*/}
                        {/*            <Box key={i} position="relative" className={classes.fileImgBox}>*/}
                        {/*                {image_preview[i]}*/}
                        {/*                <IconButton*/}
                        {/*                    className={clsx(classes.iconButton, classes.imageClose)}*/}
                        {/*                    disableRipple*/}
                        {/*                    // onClick={() => this.handleRemoveImageFile(i)}*/}
                        {/*                    onClick={() => this.handleRemoveFile(i)}*/}
                        {/*                >*/}
                        {/*                    <DialogCloseIcon />*/}
                        {/*                </IconButton>*/}
                        {/*            </Box>*/}
                        {/*        ))}*/}
                        {/*    </Box>*/}
                        {/*)}*/}
                        {this.state.modifyCommentId === '' && commentAttachments.file !== '' ? (
                            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.controlBox}>
                                <Box display="flex" alignItems="flex-start">
                                    <PaperclipIcon />
                                    <Box>
                                        <Typography className={classes.fileNameText}>{commentAttachments.file.name}</Typography>
                                        <Typography className={classes.fileSizeText}>{UTIL.formatBytes(commentAttachments.file.size)}</Typography>
                                    </Box>
                                </Box>
                                <IconButton className={classes.iconButton} disableRipple onClick={this.handleRemoveUploadFile}>
                                    <DialogCloseIcon />
                                </IconButton>
                            </Box>
                        ) : null}
                    </Box>
                )}
                {!isCommentLoading ? (
                    commentList &&
                    commentList.length > 0 && (
                        <>
                            <Box className={classes.commentContent}>
                                {/*<Box className={clsx(classes.flexBetween, classes.commTitle)}>*/}
                                <FormControl className={classes.formControl}>
                                    <Select
                                        value={this.state.age}
                                        onChange={e => this.handleChange(e, commentType)}
                                        input={<Input name="age" id="age-label-placeholder" />}
                                        displayEmpty
                                        IconComponent={() => (
                                            <Box style={{ width: 16, height: 16, marginRight: 8 }}>
                                                <ArrowDownIcon />{' '}
                                            </Box>
                                        )}
                                    >
                                        <MenuItem value={sortType.TIME} className={classes.menuItem}>
                                            시간순
                                        </MenuItem>
                                        <MenuItem value={sortType.RECENT} className={classes.menuItem}>
                                            최신순
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {/*<Button disableRipple className={classes.btnStyle2}>*/}
                                {/*    {' '}*/}
                                {/*    마지막 댓글로 이동 <ArrowLineDownIcon />*/}
                                {/*</Button>*/}
                                {/*</Box>*/}
                            </Box>
                            {commentList.map((comment, _i) => {
                                return comment.commentId !== this.state.modifyCommentId ? (
                                    <Box key={comment.commentId}>
                                        <Box className={classes.commentContent}>
                                            <Box className={classes.commentList}>
                                                <Box display="flex">
                                                    <Avatar
                                                        className={classes.avatar}
                                                        src={comment.user.image}
                                                        onClick={() => this.handleClickProfileDialog(comment.user)}
                                                    >
                                                        <AsideUserIcon />
                                                    </Avatar>
                                                    <Box className={classes.comments}>
                                                        <Typography variant="h5">
                                                            {comment.user.name}{' '}
                                                            {comment.type !== LmsGroupUserType.member && (
                                                                <span
                                                                    className={clsx(
                                                                        classes.tagWriter,
                                                                        comment.type === LmsGroupUserType.leader && classes.tagWriterColor,
                                                                    )}
                                                                >
                                                                    {' '}
                                                                    {LMS_GROUP_USER_TYPE_NAME_TAG.KO[comment.type]}
                                                                </span>
                                                            )}
                                                        </Typography>
                                                        <Box className={classes.commentWrapper}>
                                                            {getNoticeContents(mentionConverter(comment.text))}
                                                        </Box>
                                                        {/*<Typography variant="body2">{comment.text}</Typography>*/}
                                                        {/*멀티미디어*/}
                                                        {/*<Button className={classes.fileImgBox} onClick={this.handleClickImageModal} disableRipple>*/}
                                                        {/*    <img src={RoomTestImg1} alt="img" />*/}
                                                        {/*</Button>*/}
                                                        {/*{this.state.imageModal === true && (*/}
                                                        {/*    <Box className={classes.modalBox}>*/}
                                                        {/*        <Box display="flex" alignItems="flex-start" className={classes.modalBoxIn}>*/}
                                                        {/*            <img className={classes.modalContent} src={RoomTestImg1} alt="img" />*/}
                                                        {/*            <IconButton className={classes.modalClose} onClick={this.handleCloseImageModal} disableRipple>*/}
                                                        {/*                <ModalCloseIcon />*/}
                                                        {/*            </IconButton>*/}
                                                        {/*        </Box>*/}
                                                        {/*    </Box>*/}
                                                        {/*)}*/}
                                                        {/*파일첨부*/}
                                                        {comment.commentFiles &&
                                                            comment.commentFiles.length > 0 &&
                                                            comment.commentFiles.map(file => {
                                                                return (
                                                                    <Box key={`fileBox-${file.name}`} className={classes.fileBox}>
                                                                        {/*<Box display="flex">*/}
                                                                        <PaperclipIcon />
                                                                        <Box>
                                                                            <Typography variant="body2">{file.name}</Typography>
                                                                            <Typography variant="caption">{UTIL.formatBytes(file.size)}</Typography>
                                                                        </Box>
                                                                        {/*</Box>*/}
                                                                        <IconButton
                                                                            className={clsx(classes.emotionIcon, classes.fileIcon)}
                                                                            disableRipple
                                                                            onClick={() => this.handleDownLoadCommentFile(file)}
                                                                        >
                                                                            <DownloadIcon />
                                                                        </IconButton>
                                                                    </Box>
                                                                );
                                                            })}
                                                        {/* 링크일때 */}
                                                        {/*<Typography variant="body2" className={classes.linkText}>*/}
                                                        {/*    https://www.onthelive.kr*/}
                                                        {/*</Typography>*/}
                                                        {/* 동영상일떄 */}
                                                        {/*<Box className={classes.fileVideoBox} />*/}
                                                        <Box display="flex" alignItems="center">
                                                            <Typography variant="caption">{comment.dateBefore} •</Typography>

                                                            {comment.emojiHistory.length > 0 ? (
                                                                <Box display="flex" alignItems="center" className={classes.emojiBox}>
                                                                    <Box
                                                                        className={clsx(classes.emotionBox, classes.emotionIcon)}
                                                                        onClick={e => this.handleClickEmoticonCommentAnchorEl(e, comment)}
                                                                    >
                                                                        {comment.emojiHistory.filter(e => e.emojiId === 1).length > 0 && (
                                                                            <img src={Emoticon1} alt={'good'} />
                                                                        )}
                                                                        {comment.emojiHistory.filter(e => e.emojiId === 2).length > 0 && (
                                                                            <Emoticon2 />
                                                                        )}
                                                                        {comment.emojiHistory.filter(e => e.emojiId === 3).length > 0 && (
                                                                            <img src={Emoticon3} alt={'Applause'} />
                                                                        )}
                                                                        {comment.emojiHistory.filter(e => e.emojiId === 4).length > 0 && (
                                                                            <img src={Emoticon4} alt={'HandUp'} />
                                                                        )}
                                                                        {comment.emojiHistory.filter(e => e.emojiId === 5).length > 0 && (
                                                                            <Emoticon5 />
                                                                        )}
                                                                        {comment.emojiHistory.filter(e => e.emojiId === 6).length > 0 && (
                                                                            <Emoticon6 />
                                                                        )}
                                                                        {comment.emojiHistory.filter(e => e.emojiId === 7).length > 0 && (
                                                                            <img src={Emoticon7} alt={'CoveringMyEyes'} />
                                                                        )}
                                                                        {comment.emojiHistory.filter(e => e.emojiId === 8).length > 0 && (
                                                                            <Emoticon8 />
                                                                        )}
                                                                    </Box>
                                                                    <Typography
                                                                        variant="h6"
                                                                        className={classes.emojiCount}
                                                                        onClick={e => this.handleClickEmoticonCommentCountAnchorEl(e, comment)}
                                                                    >
                                                                        {comment.emojiHistory.length}
                                                                    </Typography>
                                                                </Box>
                                                            ) : (
                                                                <IconButton
                                                                    className={classes.emotionIcon}
                                                                    disableRipple
                                                                    aria-owns={emoticonCommentAnchorElOpen ? 'simple-popper' : undefined}
                                                                    aria-haspopup="true"
                                                                    onClick={e => this.handleClickEmoticonCommentAnchorEl(e, comment)}
                                                                >
                                                                    <SmileyIcon />
                                                                </IconButton>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                {(this.props.authStore.loginUser.id === comment.userId ||
                                                    classStore.groupUserAuthority.MemberComment) && (
                                                    <MoreButton
                                                        display="flex"
                                                        menuItems={
                                                            this.props.authStore.loginUser.id === comment.userId
                                                                ? [
                                                                      {
                                                                          name: '수정',
                                                                          menuFunc: () => this.handleModifyComment(comment),
                                                                      },
                                                                      {
                                                                          name: '삭제',
                                                                          menuFunc: () => this.handleRemove(comment),
                                                                      },
                                                                  ]
                                                                : [
                                                                      {
                                                                          name: '삭제',
                                                                          menuFunc: () => this.handleRemove(comment),
                                                                      },
                                                                  ]
                                                        }
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box key={comment.commentId} className={classes.commTitle}>
                                        <Box className={classes.commentBox}>
                                            <InputEmojiComponent
                                                value={modifyComment.text}
                                                onChange={commentStore.changeModifyCommentText}
                                                users={attendantsList}
                                                searchMention={text => {
                                                    return attendantsList
                                                        .filter(d => d.name.indexOf(text.replace('@', '')) !== -1)
                                                        .map(d => {
                                                            // const user = { ...d, id: d.userId, image: d.image };
                                                            // return user;
                                                            return { ...d, id: d.userId, image: d.image };
                                                        });
                                                }}
                                            />

                                            <Box className={classes.commentBtnBox}>
                                                <Box>
                                                    {/*<input*/}
                                                    {/*    type="file"*/}
                                                    {/*    accept="image/jpg,impge/png,image/jpeg,image/gif"*/}
                                                    {/*    name="image"*/}
                                                    {/*    id="image-button-file"*/}
                                                    {/*    onChange={this.handleFileOnChange}*/}
                                                    {/*    className={classes.inputFile}*/}
                                                    {/*/>*/}
                                                    <input
                                                        type="file"
                                                        accept="*.*"
                                                        name="file"
                                                        id="modify-button-file"
                                                        onChange={this.handleFileChange}
                                                        className={classes.inputFile}
                                                    />
                                                    {/*<label htmlFor="image-button-file">*/}
                                                    {/*    <IconButton component="span" className={classes.iconButton} disableRipple>*/}
                                                    {/*        {!this.state.imgFile.length > 0 ? (*/}
                                                    {/*            <ImageSquareIcon />*/}
                                                    {/*        ) : (*/}
                                                    {/*            <ImageSquareIcon className={classes.iconColor} />*/}
                                                    {/*        )}*/}
                                                    {/*    </IconButton>*/}
                                                    {/*</label>*/}

                                                    {/*<IconButton component="span" className={classes.iconButton} disableRipple>*/}
                                                    {/*    {this.state.video === '' ? <MonitorPlayIcon /> : <MonitorPlayIcon className={classes.iconColor} />}*/}
                                                    {/*</IconButton>*/}

                                                    <label htmlFor="modify-button-file">
                                                        <IconButton component="span" className={classes.iconButton} disableRipple>
                                                            <PaperclipIcon className={commentAttachments.file !== '' ? classes.iconColor : null} />
                                                        </IconButton>
                                                    </label>
                                                    {/*<IconButton className={classes.iconButton} disableRipple>*/}
                                                    {/*    {this.state.link === '' ? (*/}
                                                    {/*        <LinkGreyTinyIcon />*/}
                                                    {/*    ) : (*/}
                                                    {/*        <LinkGreyTinyIcon className={classes.iconColor} />*/}
                                                    {/*    )}*/}
                                                    {/*</IconButton>*/}
                                                </Box>
                                                <Button disableRipple className={classes.cancelBtn} onClick={this.handleModifyCancel}>
                                                    취소
                                                </Button>
                                                <Button
                                                    disableRipple
                                                    className={classes.btnStyle}
                                                    disabled={isCommentLoading || (modifyComment.text === '' && commentAttachments.file === '')}
                                                    onClick={() => {
                                                        scrollMove();
                                                        commentStore.requestModifyComment(
                                                            groupId,
                                                            commentType,
                                                            typeId,
                                                            this.props.authStore.loginUser,
                                                            this.state.age,
                                                            {
                                                                closeModify: () => {
                                                                    this.setState({ modifyCommentId: '' });
                                                                },
                                                            },
                                                        );
                                                    }}
                                                >
                                                    보내기
                                                </Button>
                                            </Box>
                                            {modifyComment.commentFiles && modifyComment.commentFiles.length
                                                ? modifyComment.commentFiles.map((file, index) => {
                                                      return (
                                                          <Box
                                                              className={classes.fileBox}
                                                              style={{
                                                                  background: '#fff',
                                                                  display: 'flex',
                                                                  marginTop: '10px',
                                                                  marginBottom: 0,
                                                                  border: '10px solid #f8f8f8',
                                                              }}
                                                          >
                                                              <Box display="flex">
                                                                  <PaperclipIcon />
                                                                  <Box>
                                                                      <Typography variant="body2">{file.name}</Typography>
                                                                      <Typography variant="caption">{UTIL.formatBytes(file.size)}</Typography>
                                                                  </Box>
                                                              </Box>
                                                              <IconButton
                                                                  className={classes.emotionIcon}
                                                                  disableRipple
                                                                  onClick={() => this.handleRemovePostFile(index)}
                                                              >
                                                                  <DialogCloseIcon />
                                                              </IconButton>
                                                          </Box>
                                                      );
                                                  })
                                                : null}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </>
                    )
                ) : (
                    <Box style={{ height: 36.5, textAlign: 'center', marginBottom: 1000 }}>
                        <CircularProgress size={20} />
                    </Box>
                )}

                <Popover
                    id="simple-popper"
                    open={emoticonCommentAnchorElOpen}
                    anchorEl={emoticonCommentAnchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    className={classes.popoverBox}
                >
                    <Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <IconButton
                                id={'1'}
                                className={clsx(classes.emotionIcon, this.state.myPick === 1 && classes.myPickEmotionIcon)}
                                disableRipple
                                onClick={e => this.handleClickEmotion(e)}
                            >
                                <img src={Emoticon1} alt={'good'} />
                            </IconButton>
                            <IconButton
                                id={'2'}
                                className={clsx(classes.emotionIcon, this.state.myPick === 2 && classes.myPickEmotionIcon)}
                                disableRipple
                                onClick={e => this.handleClickEmotion(e)}
                            >
                                <Emoticon2 />
                            </IconButton>
                            <IconButton
                                id={'3'}
                                className={clsx(classes.emotionIcon, this.state.myPick === 3 && classes.myPickEmotionIcon)}
                                disableRipple
                                onClick={e => this.handleClickEmotion(e)}
                            >
                                <img src={Emoticon3} alt={'Applause'} />
                            </IconButton>
                            <IconButton
                                id={'4'}
                                className={clsx(classes.emotionIcon, this.state.myPick === 4 && classes.myPickEmotionIcon)}
                                disableRipple
                                onClick={e => this.handleClickEmotion(e)}
                            >
                                <img src={Emoticon4} alt={'HandUp'} />
                            </IconButton>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                            <IconButton
                                id={'5'}
                                className={clsx(classes.emotionIcon, this.state.myPick === 5 && classes.myPickEmotionIcon)}
                                disableRipple
                                onClick={e => this.handleClickEmotion(e)}
                            >
                                <Emoticon5 />
                            </IconButton>
                            <IconButton
                                id={'6'}
                                className={clsx(classes.emotionIcon, this.state.myPick === 6 && classes.myPickEmotionIcon)}
                                disableRipple
                                onClick={e => this.handleClickEmotion(e)}
                            >
                                <Emoticon6 />
                            </IconButton>
                            <IconButton
                                id={'7'}
                                className={clsx(classes.emotionIcon, this.state.myPick === 7 && classes.myPickEmotionIcon)}
                                disableRipple
                                onClick={e => this.handleClickEmotion(e)}
                            >
                                <img src={Emoticon7} alt={'CoveringMyEyes'} />
                            </IconButton>
                            <IconButton
                                id={'8'}
                                className={clsx(classes.emotionIcon, this.state.myPick === 8 && classes.myPickEmotionIcon)}
                                disableRipple
                                onClick={e => this.handleClickEmotion(e)}
                            >
                                <Emoticon8 />
                            </IconButton>
                        </Box>
                    </Box>
                </Popover>

                <Popover
                    id="emotion-popper"
                    open={emoticonCommentAnchorElOpen1}
                    anchorEl={emoticonCommentAnchorEl1}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    className={classes.popoverBoxEmotions}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
                        {this.state.comment && this.state.comment.emojiHistory.filter(e => e.emojiId === 1).length > 0 && (
                            <Box className={classes.emojiViewCount}>
                                <img src={Emoticon1} alt={'good'} />
                                <Typography variant="caption">{this.state.comment.emojiHistory.filter(e => e.emojiId === 1).length}</Typography>
                            </Box>
                        )}
                        {this.state.comment && this.state.comment.emojiHistory.filter(e => e.emojiId === 2).length > 0 && (
                            <Box className={classes.emojiViewCount}>
                                <Emoticon2 />
                                <Typography variant="caption">{this.state.comment.emojiHistory.filter(e => e.emojiId === 2).length}</Typography>
                            </Box>
                        )}
                        {this.state.comment && this.state.comment.emojiHistory.filter(e => e.emojiId === 3).length > 0 && (
                            <Box className={classes.emojiViewCount}>
                                <img src={Emoticon3} alt={'Applause'} />
                                <Typography variant="caption">{this.state.comment.emojiHistory.filter(e => e.emojiId === 3).length}</Typography>
                            </Box>
                        )}
                        {this.state.comment && this.state.comment.emojiHistory.filter(e => e.emojiId === 4).length > 0 && (
                            <Box className={classes.emojiViewCount}>
                                <img src={Emoticon4} alt={'HandUp'} />
                                <Typography variant="caption">{this.state.comment.emojiHistory.filter(e => e.emojiId === 4).length}</Typography>
                            </Box>
                        )}
                        {this.state.comment && this.state.comment.emojiHistory.filter(e => e.emojiId === 5).length > 0 && (
                            <Box className={classes.emojiViewCount}>
                                <Emoticon5 />
                                <Typography variant="caption">{this.state.comment.emojiHistory.filter(e => e.emojiId === 5).length}</Typography>
                            </Box>
                        )}
                        {this.state.comment && this.state.comment.emojiHistory.filter(e => e.emojiId === 6).length > 0 && (
                            <Box className={classes.emojiViewCount}>
                                <Emoticon6 />
                                <Typography variant="caption">{this.state.comment.emojiHistory.filter(e => e.emojiId === 6).length}</Typography>
                            </Box>
                        )}
                        {this.state.comment && this.state.comment.emojiHistory.filter(e => e.emojiId === 7).length > 0 && (
                            <Box className={classes.emojiViewCount}>
                                <img src={Emoticon7} alt={'CoveringMyEyes'} />
                                <Typography variant="caption">{this.state.comment.emojiHistory.filter(e => e.emojiId === 7).length}</Typography>
                            </Box>
                        )}
                        {this.state.comment && this.state.comment.emojiHistory.filter(e => e.emojiId === 8).length > 0 && (
                            <Box className={classes.emojiViewCount}>
                                <Emoticon8 />
                                <Typography variant="caption">{this.state.comment.emojiHistory.filter(e => e.emojiId === 8).length}</Typography>
                            </Box>
                        )}
                    </Box>
                </Popover>

                <ProfileDialogComponent
                    profileDialog={this.state.profileDialog}
                    handleClose={this.handleClose}
                    selectedUser={this.state.selectedUser}
                />
                <YesOrNoDialog
                    open={this.state.openDeleteConfirmDialog}
                    title={'댓글 삭제'}
                    msg={'해당 댓글을 삭제하시겠습니까?'}
                    changeDialogOpen={() => this.setState({ openDeleteConfirmDialog: false })}
                    submit={() => {
                        this.props.commentStore.deleteComment(
                            groupId,
                            typeId,
                            commentType,
                            this.state.comment,
                            this.props.authStore.loginUser.id,
                            commentType === CommentType.ROOM
                                ? { getCommentCount: () => this.props.roomStore.getCommentCount(typeId), scrollMove: () => scrollMove(437) }
                                : { getCommentCount: () => this.props.postStore.getCommentCount(typeId), scrollMove: () => scrollMove(437) },
                        );
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(
    inject('roomStore', 'authStore', 'classStore', 'commentStore', 'classFileStore', 'postStore')(injectIntl(observer(CommentComponent))),
);
