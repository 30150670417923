import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, FormControl, FormHelperText, OutlinedInput, Typography } from '@material-ui/core';
import EmailCertificationDialogComponent from '../dialog/EmailCertificationDialogComponent';
import MailAuthenticationFailedDialogComponent from '../dialog/MailAuthenticationFailedDialogComponent';
import { inject, observer } from 'mobx-react';
import MakeAvatar from './MakeAvatar';
import { ReactComponent as PersonIcon } from '../../common/images/PersonIcon.svg';
import { FormattedMessage, injectIntl } from 'react-intl';
import ChangePassword from './ChangePassword';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import WithdrawalDialogComponent from '../dialog/WithdrawalDialogComponent';
import { checkSpace } from '../../common/Validation';
import { withRouter } from '../../components/WithRouter';
import { ToastsStore } from 'react-toasts';
import LicenseKeyInfoBox from '../../components/LicenseKeyInfoBox';
import LicenseKeyInputBox from '../../components/LicenseKeyInputBox';
import { USER_TYPE } from '../../stores/UserStore';
import LicenseKeySearchButton from '../../components/LicenseKeySearchButton';
import { Stack } from '@mui/material';

const styles = _theme => ({
    root: {
        width: 650,
        padding: '88px 0 55px',
        margin: '0 auto',
    },
    titleBox: {
        borderBottom: '3px solid #000',
        marginBottom: 52,
    },
    titleText: {
        fontSize: '1.875rem',
        color: '#000',
        paddingBottom: 25,
    },
    marginBottom: {
        marginBottom: 30,
    },
    marginBottom2: {
        marginBottom: 40,
    },
    emailAgreeTextStyle: {
        marginLeft: 8,
        fontSize: '1.063rem',
        color: '#000',
        fontWeight: 600,
    },
    textStyle: {
        width: 100,
        marginRight: 47,
        fontSize: '1.063rem',
        color: '#000',
        fontWeight: 600,
    },
    nameBox: {
        width: 503,
        marginRight: 0,
    },
    departmentTextStyle: {
        // width: 100,
        marginRight: 27,
        fontSize: '1.063rem',
        color: '#000',
        fontWeight: 600,
    },
    textStyleRequired: {
        '&:after': {
            content: '"*"',
            color: '#ff0000',
        },
    },
    emailButton: {
        width: 141,
        height: 46,
        background: '#0097ff',
        borderRadius: 7,
        padding: 0,
        marginLeft: 10,
        color: '#fff',
        fontSize: '1.063rem',
        fontWeight: 'bold',
        '&:hover': {
            background: '#0097ff',
        },
    },
    emailSubText: {
        fontSize: '0.875rem',
        color: '#9d9d9d',
        letterSpacing: '-0.17px',
        marginTop: 5,
    },
    textField: {
        // width:'100%',
        minWidth: '350px',
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #c0c2c3',
            },
        '& .MuiOutlinedInput-root': {
            width: '100%',
            borderRadius: 4,
            '&:hover': {
                borderColor: '#c0c2c3',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '13px 18px',
            color: '#000',
            fontSize: '1.063rem',
            '&::placeholder': {
                color: '#9d9d9d',
                opacity: 1,
            },
        },
    },
    textFieldMulti: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        width: '50%',
    },
    tinyInput: {
        width: '40px!important',
        flexDirection: 'row',
        '&>input': {
            padding: '13px 12px!important',
            textAlign: 'center',
        },
        margin: '12px',
    },
    lineStyle: {
        width: '100%',
        height: 12,
        borderBottom: '1px solid #c0c2c3',
        marginBottom: 37,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    avatar: {
        width: 150,
        height: 150,
        background: '#a3a8af',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 2px',
    },
    imgButton: {
        width: 150,
        height: 150,
        borderRadius: '50%',
        background: '#f3f3f3',
        border: '1px dashed #a3a8af',
    },
    imgButtonDelete: {
        width: 110,
        height: 40,
        borderRadius: 7,
        border: '1px solid #0097ff',
        color: '#0097ff',
        marginLeft: 24,
        fontSize: '0.938rem',
        fontWeight: 600,
        background: 'transparent',
        boxShadow: 'none',
        '&:hover': {
            background: 'transparent',
            boxShadow: 'none',
        },
    },
    btnStyle: {
        // width:110,
        height: 40,
        borderRadius: 7,
        border: '1px solid #0097ff',
        color: '#0097ff',
        marginLeft: 24,
        fontSize: '0.938rem',
        fontWeight: 600,
        padding: '13px 18px',
        '&:hover': {
            background: 'transparent',
        },
    },
    input: {
        display: 'none',
    },
    buttonStyle: {
        width: 205,
        height: 44,
        fontSize: '1.063rem',
        fontWeight: 600,
        borderRadius: 7,
        border: '1px solid #000',
        color: '#000',
        background: '#fff',
        '&:hover': {
            background: '#fff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    cameraBtnStyle: {
        right: -40,
        top: -15,
        padding: 0,
        background: 'transparent',
        position: 'relative',
        '&:hover': {
            background: 'transparent',
        },
    },
    saveButton: {
        background: '#0097ff',
        marginLeft: 20,
        color: '#fff',
        border: 0,
        '&:hover': {
            background: '#0097ff',
        },
    },
    helperText: {
        color: '#a3a8af',
        letterSpacing: '-.24px',
        fontSize: '0.75rem',
        marginLeft: 0,
    },
    disableBg: {
        '&:hover, &.MuiCheckbox-colorSecondary.Mui-checked:hover': {
            background: 'transparent',
        },
    },
    selectStyle: {
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
    },
    option: {
        // fontSize: 12,
        // fontFamily: 'NanumSquareRoundOTF',
        // color: '#303030',
        // width: 100,
        // marginRight: 47,
        fontSize: '1.063rem',
        color: '#000',
        fontWeight: 600,
    },
    linkStyle: {
        fontSize: '1rem',
        '&:hover': {
            cursor: 'pointer',
        },
        color: '#ff0000',
        textDecoration: 'underline',
    },
    boxStyle: {
        padding: '30px 0 0',
    },
    licenseBox: {
        width: 248,
        height: 46,
        boxSizing: 'border-box',
        border: '1px solid #c0c2c3',
        borderRadius: 4,
        background: '#fff',
        padding: '13px 18px',
        display: 'flex',
        alignItems: 'center',
    },
    hyphenText: {
        '&.MuiTypography-root': {
            fontSize: '1rem',
            color: '#a3a8af',
            letterSpacing: '-0.2px',
            margin: '0 8px 0 5px',
            '@media all and (max-width: 600px)': {
                fontSize: '0.813rem',
            },
        },
    },
    form: {
        width: 38,
        '& .MuiInputBase-root': {
            boxSizing: 'border-box',
            padding: '0',
            background: 'transparent',
        },
        '& .MuiOutlinedInput-input': {
            padding: 0,
            boxSizing: 'border-box',
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 0,
        },
        '& input': {
            fontSize: '1rem',
            color: '#000',
            letterSpacing: '-0.2px',
            '&::placeholder': {
                fontSize: '1rem',
                color: '#9d9d9d',
                letterSpacing: '-0.2px',
                opacity: 1,
            },
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
            {
                border: '0',
            },
    },
    infoBox: {
        width: '100%',
        borderRadius: 5,
        boxSizing: 'border-box',
        padding: '14px 17px',
        background: 'rgba(242, 245, 248, 0.5)',
        display: 'flex',
        marginTop: 25,
        '& p': {
            fontSize: '0.875rem',
            color: '#333',
            letterSpacing: '-0.2px',
            lineHeight: 1.4,
            '& span': {
                fontWeight: 'bold',
            },
            '& a': {
                color: '#0097ff',
            },
            '&:last-child': {
                marginTop: 8,
            },
        },
        '& svg': {
            marginRight: 4,
        },
    },
});

const LOG_PREFIX = ' [ProfileSettingsComponent] ';

class ProfileSettingsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            profileImage: false,
            labelWidth: '100%',
            timezone: '',
            languages: '',
            open: false,
            openMail: false,
            isEdit: false,
            withdrawalDialog: false,
            license1: '1234',
            license2: '5678',
            license3: '9100',
            license4: '1112',
            licenseKeyDialog: false,
        };
    }

    componentDidMount() {
        const { loginUser } = this.props.authStore;
        const findUserEmail = loginUser.email;

        this.props.userStore.getUserInfo(findUserEmail);
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleChangeTimezone = value => {
        this.props.userStore.changeEditUserTimezone(value);
    };

    handleChangeLanguages = event => {
        const selectedLanguage = event.target.value;
        this.props.userStore.changeEditUserLanguage(selectedLanguage);
    };

    handleSendAuthenticationEmail = () => {
        const { editedUser } = this.props.userStore;
        this.props.authStore.sendAuthenticationEmail(editedUser.email, {
            successAction: () => this.handleClickOpen(),
            failedAction: () => ToastsStore.warning('이메일 발송이 되지 않았습니다. 다시 시도해주세요.', 1500),
        });
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClickSave = () => {
        const { userDetail } = this.props.userStore;
        this.props.avatarStore._uploadAvatarImage();
        this.props.userStore.modifyUserInfo(userDetail.email, {
            closeEditMode: () => this.handleEditMode(false),
            reSetLoginUserName: userEmail => {
                // this.props.authStore.reSetLoginUserName(newName);
                this.props.userStore.getUserInfo(userEmail);
            },
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
            openMail: false,
            withdrawalDialog: false,
        });
    };

    handleCheckAllowEmail = () => {
        this.props.userStore.changeEditUserAllowEmailState();
    };

    handleChangeLicense1(e) {
        const license1 = e.target.validity.valid ? e.target.value : this.state.license1;
        this.setState({ license1 });
    }

    handleChangeLicense2(e) {
        const license2 = e.target.validity.valid ? e.target.value : this.state.license2;
        this.setState({ license2 });
    }

    handleChangeLicense3(e) {
        const license3 = e.target.validity.valid ? e.target.value : this.state.license3;
        this.setState({ license3 });
    }

    handleChangeLicense4(e) {
        const license4 = e.target.validity.valid ? e.target.value : this.state.license4;
        this.setState({ license4 });
    }

    handleEditMode = isEdit => {
        const { userStore, avatarStore, authStore } = this.props;

        this.setState({ isEdit: isEdit });
        if (isEdit) {
            userStore.setEditUserInfo();
            avatarStore.initSaveImage();
        } else {
            userStore.initEditUserInfo();
            avatarStore.getAvatar(authStore.loginUser.id);
        }
    };

    handleChangeDepartment = e => {
        checkSpace(e);
        this.props.userStore.changeEditUserDepartment(e.target.name, e.target.value);
    };

    handleChangeName = e => {
        this.props.userStore.changeEditUserName(e.target.value);
    };

    handleChangeEmail = e => {
        checkSpace(e);
        this.props.userStore.changeEditUserEmail(e.target.value);
    };

    handleClickWithdrawalDialogOpen = () => {
        this.setState({
            withdrawalDialog: true,
        });
    };

    handleWithDrawUser = () => {
        const { userDetail } = this.props.userStore;
        const { invalidateLogin, invalidateSavedEmail, invalidateAuthToken } = this.props.authStore;

        this.props.userStore.withdrawUser(userDetail.email, {
            invalidateLoginInfo: () => {
                invalidateSavedEmail();
                invalidateLogin();
                invalidateAuthToken();
            },
            moveWithdrawConfirm: () => {
                this.props.navigate('/withdrawal');
            },
            errorMessage: () => {
                ToastsStore.error('서버와 통신이 원활하지 않습니다. 다시 시도해주세요.', 1500);
            },
            closeWithdrawDialog: () => {
                this.handleClose();
            },
        });
    };

    getModifyUserNameHelpText = () => {
        const { incorrectModifyUserName, isEmptyModifyUserName } = this.props.userStore;

        if (isEmptyModifyUserName) {
            return '이름을 입력해주세요.';
        } else if (incorrectModifyUserName) {
            return '이름에 올바르지 않은 문자가 포함되어 있습니다.';
        }

        return '';
    };

    handleClickLicenseKeyDialog = () => {
        this.setState({ licenseKeyDialog: true });
    };

    handleCloseLicenseKeyDialog = () => {
        this.setState({ licenseKeyDialog: false });
    };

    render() {
        const { classes, intl } = this.props;
        const { userDetail, userLicenseKey } = this.props.userStore;
        const { editedUserLicenseKey, editedUser, isAvailableModifyUser, incorrectModifyUserName, isEmptyModifyUserName } = this.props.userStore;
        const { modifyResultDialogOpen, modifyResultDialogMsgId, changeModifyResultDialogState, changeEditUserLicenseKey } = this.props.userStore;
        const { loadAvatarImage, saveAvatarImage } = this.props.avatarStore;
        console.log('[Profile] userDetail : >> ', userDetail);
        return (
            <div className={classes.root}>
                <Box className={classes.titleBox}>
                    <Typography className={classes.titleText}>
                        <FormattedMessage id={'profile_settings'} />
                    </Typography>
                </Box>
                <Box display="flex" className={classes.marginBottom2}>
                    <Typography className={classes.textStyle}>프로필 이미지</Typography>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Avatar src={loadAvatarImage} alt="profile image" className={classes.avatar}>
                            <PersonIcon style={{ width: 72, height: 72 }} />
                        </Avatar>
                        {this.state.isEdit && (
                            <Box className={classes.cameraBtnStyle}>
                                <MakeAvatar />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" className={classes.marginBottom}>
                    <Typography className={clsx(classes.textStyle, this.state.isEdit && classes.textStyleRequired)} style={{ paddingTop: '12px' }}>
                        <FormattedMessage id={'email'} />
                    </Typography>
                    <Typography className={clsx(classes.textStyle)} style={{ paddingTop: '12px' }}>
                        {userDetail.email}
                    </Typography>
                    {/*{this.state.isEdit ? (*/}
                    {/*    <Box>*/}
                    {/*        <Box display="flex" alignItems="center">*/}
                    {/*            <FormControl*/}
                    {/*                error={incorrectModifyUserName || isEmptyModifyUserName}*/}
                    {/*                required*/}
                    {/*                className={classes.textField}*/}
                    {/*                variant="outlined"*/}
                    {/*            >*/}
                    {/*                <OutlinedInput*/}
                    {/*                    inputProps={{ 'aria-label': 'email input box', maxLength: 50 }}*/}
                    {/*                    id="outlined-company"*/}
                    {/*                    labelWidth={0}*/}
                    {/*                    placeholder="이메일을 입력하세요"*/}
                    {/*                    value={userDetail.email}*/}
                    {/*                    onChange={this.handleChangeEmail}*/}
                    {/*                />*/}
                    {/*            </FormControl>*/}
                    {/*            <Button className={classes.emailButton} disableRipple>*/}
                    {/*                인증 메일 발송*/}
                    {/*            </Button>*/}
                    {/*        </Box>*/}
                    {/*        <Typography className={classes.emailSubText}>*이메일 변경하면 인증 확인 절차가 필요합니다.</Typography>*/}
                    {/*    </Box>*/}
                    {/*) : (*/}
                    {/*    <Typography className={clsx(classes.textStyle)} style={{ paddingTop: '12px' }}>*/}
                    {/*        {userDetail.email}*/}
                    {/*    </Typography>*/}
                    {/*)}*/}
                </Box>
                {/* 다이얼로그 */}

                <EmailCertificationDialogComponent open={this.state.open} onClose={this.handleClose} handleClose={this.handleClose} />

                {/* /다이얼로그 */}

                <Box display="flex" alignItems="center" className={classes.marginBottom}>
                    <Typography className={clsx(classes.textStyle, this.state.isEdit && classes.textStyleRequired)}>비밀번호</Typography>
                    <ChangePassword />
                </Box>

                <Box display="flex" alignItems="center" className={classes.marginBottom}>
                    <Box>
                        <Typography className={clsx(classes.textStyle, this.state.isEdit && classes.textStyleRequired)}>이름</Typography>
                    </Box>
                    {this.state.isEdit ? (
                        <FormControl
                            error={incorrectModifyUserName || isEmptyModifyUserName}
                            required
                            className={classes.textField}
                            variant="outlined"
                        >
                            <OutlinedInput
                                inputProps={{ 'aria-label': 'name input box', maxLength: 50 }}
                                id="outlined-company"
                                labelWidth={0}
                                placeholder="이름을 입력하세요"
                                value={editedUser.name}
                                onChange={this.handleChangeName}
                            />
                            <FormHelperText
                                style={{ fontSize: 'x-small' }}
                                hidden={!incorrectModifyUserName && !isEmptyModifyUserName}
                                id={'component-error-text'}
                            >
                                {this.getModifyUserNameHelpText()}
                            </FormHelperText>
                        </FormControl>
                    ) : (
                        <Typography className={clsx(classes.textStyle, classes.nameBox)}>{userDetail.name}</Typography>
                    )}
                </Box>
                {userDetail?.type !== USER_TYPE.Guest && (
                    <Box display="flex" className={classes.marginBottom}>
                        <Box style={this.state.isEdit ? { marginTop: 10 } : {}}>
                            <Typography className={clsx(classes.textStyle, this.state.isEdit && classes.textStyleRequired)}>라이선스 키</Typography>
                        </Box>
                        <Box>
                            {this.state.isEdit ? (
                                <Stack>
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <LicenseKeyInputBox
                                            marginTop={0}
                                            licenseKey={editedUserLicenseKey?.value}
                                            onChangeLicenseKey={changeEditUserLicenseKey}
                                            handleClickLicenseKeyDialog={this.handleClickLicenseKeyDialog}
                                            handleCloseLicenseKeyDialog={this.handleCloseLicenseKeyDialog}
                                            licenseKeyDialog={this.state.licenseKeyDialog}
                                        />
                                        <LicenseKeySearchButton label={'변경'} marginTop={0} />
                                    </Stack>
                                    <LicenseKeyInfoBox />
                                </Stack>
                            ) : (
                                <Typography className={clsx(classes.textStyle, classes.nameBox)}>{userLicenseKey}</Typography>
                            )}
                        </Box>
                    </Box>
                )}

                <Box className={classes.lineStyle} />

                <Box display="flex" justifyContent="flex-end">
                    {this.state.isEdit ? (
                        <>
                            <Button className={classes.buttonStyle} disableripple={true?.toString()} onClick={() => this.handleEditMode(false)}>
                                <FormattedMessage id="cancel" />
                            </Button>

                            <Button
                                className={clsx(classes.buttonStyle, classes.saveButton)}
                                disableripple={true?.toString()}
                                disabled={!isAvailableModifyUser && !saveAvatarImage}
                                onClick={this.handleClickSave}
                            >
                                <FormattedMessage id="save" />
                            </Button>
                        </>
                    ) : (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'space-between'}
                            className={classes.marginBottom}
                            style={{ width: '100%' }}
                        >
                            <Typography onClick={this.handleClickWithdrawalDialogOpen} className={classes.linkStyle}>
                                탈퇴하기
                            </Typography>
                            <Button
                                className={clsx(classes.buttonStyle, classes.saveButton)}
                                disableripple={true?.toString()}
                                onClick={() => this.handleEditMode(true)}
                            >
                                <FormattedMessage id="modify" />
                            </Button>
                        </Box>
                    )}

                    <MailAuthenticationFailedDialogComponent openMail={this.state.openMail} handleClose={this.handleClose} />
                    <ConfirmDialog
                        open={modifyResultDialogOpen}
                        title={intl.formatMessage({ id: 'notification' })}
                        msg={intl.formatMessage({ id: `${modifyResultDialogMsgId}` })}
                        changeDialogOpen={isOpen => changeModifyResultDialogState(isOpen)}
                    />
                    <WithdrawalDialogComponent
                        handleClose={this.handleClose}
                        withdrawalDialog={this.state.withdrawalDialog}
                        withDrawUser={this.handleWithDrawUser}
                    />
                </Box>
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('authStore', 'userStore', 'avatarStore', 'organizationStore')(injectIntl(observer(ProfileSettingsComponent)))),
);
