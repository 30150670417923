import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    LinearProgress,
    MenuItem,
    Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { ReactComponent as FolderPlusIcon } from '../../common/images/FolderPlusFillIcon.svg';
import { ReactComponent as FileIcon } from '../../common/images/FileIcon.svg';
import { ReactComponent as ClipboardTextIcon } from '../../common/images/ClipboardTextIcon.svg';
import { ReactComponent as CaretRightIcon } from '../../common/images/CaretRightIcon.svg';
import { ReactComponent as DownloadSimpleIcon } from '../../common/images/DownloadSimpleIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { ReactComponent as FolderNotchIcon } from '../../common/images/FolderNotchIcon.svg';
import SurveyDialogComponent from '../dialog/SurveyDialogComponent';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { ALLOWED_FOLDER_BREADCRUMBS_LENGTH, CLASS_STORE_MESSAGE_ID, GROUP_FOLDER_TYPE, LoadingState } from '../../stores/ClassStore';
import { DATE_UTIL } from '../../common/util/date.util';
import MoreButton from '../../components/common/MoreButton';
import { FILE_LOAD_STATE, FILE_POSTED_TYPE } from '../../stores/ClassFileStore';
import { checkDot, checkFullStop, checkSpace, checkTextValueLength } from '../../common/Validation';
import ConfirmDialog from '../../components/common/ConfirmDialog';
import { UTIL } from '../../common/util/common.util';
import ReferenceRoomModifyInput from './ReferenceRoomModifyInput';
import YesOrNoDialog from '../../components/common/YesOrNoDialog';
import CustomBreadcrumbs from '../../components/CustomBreadcrumbs';
import { ToastsStore } from 'react-toasts';
import { PATH_UTIL } from '../../common/util/path.util';
import { withRouter } from '../../components/WithRouter';
import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE } from '../../stores/PaginationStore';
import WithInfiniteScroll from '../../components/WithInfiniteScroll';

const styles = theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
        '& table button.MuiButton-root': {
            minWidth: 'unset',
            padding: 0,
        },
        '& label.MuiButton-root.Mui-disabled': {
            border: '1px solid #979797 !important',
        },
    },
    textStyle: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    optionBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        '&:after': {
            content: '""',
            width: '100%',
            height: 3,
            backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: -1,
        },
        '& button': {
            minWidth: 100,
            fontSize: '0.938rem',
            '&.Mui-selected': {
                fontWeight: 700,
                color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
            },
        },
        '& .MuiTabs-indicator': {
            height: 3,
            backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
    btnStyle: {
        border: () => (theme.configs.MainBtnColor ? `1px solid ${theme.configs.MainBtnColor}` : '1px solid #0097FF'),
        borderRadius: 7,
        marginLeft: 10,
        color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        padding: '7px 23px',
        fontWeight: 600,
        '&:hover': {
            background: 'transparent',
        },
    },
    iconButton: {
        marginLeft: 14,
        '&:hover': {
            background: 'transparent',
        },
    },
    fileInfo: {
        fontSize: '0.75rem',
        color: '#979797',
        marginTop: 7,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        whiteSpace: 'nowrap',
    },
    tableBtnStyle: {
        '&:hover': {
            background: 'transparent',
        },
    },
    downloadBtn: {
        '& p': {
            marginTop: 3,
            fontSize: '0.75rem',
            color: '#4d5a69',
        },
        '& svg': {
            marginRight: 6,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    textField: {
        padding: '5px 3px',
        border: '1px solid #c4c4c4',
        borderRadius: 3,
    },
});

let id = 0;
function createData(fileName, date, userName, size, state) {
    id += 1;
    return { id, fileName, date, userName, size, state };
}
const rows = [
    createData('Working', '2021.12.01', '김온더', '10M', '시작하기'),
    createData('Working2', '2021.12.01', '김온더', '20M', '작성중'),
    createData('Working3', '2021.12.01', '김온더', '20M', '제출완료'),
];

const RESPONSE_KEY_CODE = {
    ESC: 27,
    ENTER: 13,
};

const LOG_PREFIX = ' [ReferenceRoomComponent] ';

const LightTooltip = withStyles(_theme => ({
    tooltip: {
        padding: '4px 4px',
        border: '0.3px solid #000000',
        background: '#fffff5',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        color: '#000',
        borderRadius: '0',
        marginLeft: 5,
        marginTop: 5,
    },
}))(Tooltip);

class ReferenceRoomComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 0,
            anchorEl: null,
            filter: '전체',
            inputChange: false,
            surveyDialogOpen: false,
            isCreateFolder: false,
            open: false,
            isCheckDeleteDialogOpen: false,
            deleteFileName: '',
            tooltipOpenFileId: 0,
        };
        this.anchorRef = React.createRef();
        this.interSectRef = React.createRef();
    }

    componentDidMount() {
        this.requestReferenceRoomInfo();
    }

    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        const { classStore } = this.props;

        if (classStore.groupRootFolderId && !classStore.selectedFolderId) {
            classStore.changeSelectedFolderId(classStore.groupRootFolderId);
        }
    }

    componentWillUnmount() {
        const { classStore, classFileStore } = this.props;
        classStore.initGroupReferenceRoom();
        classFileStore.initClassFileStore();
    }

    getGroupFolderAndFilesOnFirstPage = () => {
        console.log(LOG_PREFIX, 'Start getGroupFolderAndFilesOnFirstPage');
        this.props.classStore.getGroupFolderAndFiles({
            changePage: () => {
                this.props.changePage(DEFAULT_PAGE);
            },
        });
    };

    fetchGroupFolderAndFiles = page => {
        const { classStore } = this.props;

        classStore.getGroupFolderAndFiles(null, page, DEFAULT_ROWS_PER_PAGE);
    };

    requestReferenceRoomInfo = () => {
        const { classStore, location } = this.props;
        const { selectedGroupId, selectedFolderId, groupRootFolderId } = classStore;

        if (!selectedGroupId) {
            const findGroupId = PATH_UTIL.getClassId(location.pathname);
            classStore.setSelectedGroupId(findGroupId);
        }

        if (!selectedFolderId) {
            classStore.changeSelectedFolderId(groupRootFolderId);
        }

        classStore.getGroupFolderAndFiles();
    };

    handleClickFolder = (_e, row) => {
        const { classStore } = this.props;

        classStore.changeSelectedFolderId(row.fileFolderId);
        classStore.addFolderBreadcrumb(row.fileFolderId, row.name, {
            changePage: () => {
                this.props.changePage(DEFAULT_PAGE);
            },
        });

        this.getGroupFolderAndFilesOnFirstPage();
    };

    handleClickTopBtn = () => {
        const { classStore } = this.props;
        if (classStore.isCreateFolder) {
            this.handleInitCreateFolder();
        }
        if (this.state.inputChange) {
            this.handleInitEditFolder();
            this.handleInitEditFile();
            this.setState({ inputChange: false });
        }

        if (classStore.groupRootFolderId !== classStore.selectedFolderId) {
            classStore.changeSelectedFolderId(classStore.groupRootFolderId);
            this.getGroupFolderAndFilesOnFirstPage();
            classStore.initFolderBreadcrumbs();
        }
    };

    handleClickDeleteBtn = (row, isFolder) => {
        if (row) {
            const { classStore, classFileStore } = this.props;

            if (isFolder) {
                classStore.changeDeleteFolderId(row.fileFolderId);
            } else {
                classFileStore.changeSelectedFileId(row.fileId);

                const groupId = classStore.selectedGroupDetail.group.id;
                const postedType = FILE_POSTED_TYPE.Folder;

                classFileStore.setDeleteFileInfo(groupId, postedType, classStore.selectedFolderId, row.fileId, row.name);
            }
            this.setState({ isCheckDeleteDialogOpen: true, deleteFileName: row.name });
        }
    };

    handleTabChange = (_event, tabs) => {
        this.setState({ tabs });
    };

    handleChangeSort = event => {
        this.setState({ filter: event.target.value });
    };

    handleClickInputChange = (row, isFolder) => {
        if (isFolder) {
            this.props.classStore.changeEditFolderId(row.fileFolderId);
        } else {
            this.props.classFileStore.changeSelectedFileId(row.fileId);
        }
        this.setState({ inputChange: true });
    };

    handleChangeSurveyDialogOpen = () => {
        this.setState({ surveyDialogOpen: true });
    };

    handleCloseSurveyDialogOpen = () => {
        this.setState({ surveyDialogOpen: false });
    };

    handleClickCreateFolderBtn = () => {
        const { classes, intl } = this.props;
        const { isCreateFolder, folderBreadcrumbs } = this.props.classStore;
        if (folderBreadcrumbs.length >= ALLOWED_FOLDER_BREADCRUMBS_LENGTH) {
            ToastsStore.error(intl.formatMessage({ id: 'msg.not_allowed_create_folder' }), 3000, classes.toasts);
        } else {
            if (this.state.inputChange) {
                this.handleInitEditFolder();
                this.handleInitEditFile();
                this.setState({ inputChange: false });
            }
            if (!isCreateFolder) {
                this.props.classStore.changeCreateFolder(true);
            }
        }
    };

    handleChangeCreateFolderName = event => {
        this.checkFileOrFolderNameValidation(event);
        const userEnteredFolderName = event.target.value;
        this.props.classStore.changeCreateFolderName(userEnteredFolderName);
    };

    handleCreateFolder = event => {
        if (event.keyCode === RESPONSE_KEY_CODE.ESC) {
            this.handleInitCreateFolder();
        } else if (event.keyCode === RESPONSE_KEY_CODE.ENTER) {
            const { isExistFolder } = this.props.classStore;
            if (isExistFolder) {
                this.props.classStore.changeFolderLoadState(LoadingState.FAILED);
                this.props.classStore.changeFolderLoadResultDialogMsgId(CLASS_STORE_MESSAGE_ID.Error.exist_folder_name);
            } else {
                this.props.classStore.createGroupFileFolder({
                    closeCreateFolder: () => this.handleInitCreateFolder(),
                    initPagination: () => this.props.paginationStore.initPagination(),
                });
            }
        }
    };

    handleInitCreateFolder = () => {
        const empty = '';
        this.props.classStore.changeCreateFolderName(empty);
        this.props.classStore.changeCreateFolder(false);
    };

    handleChangeFolderName = event => {
        this.checkFileOrFolderNameValidation(event);
        const userEnteredFolderName = event.target.value;
        this.props.classStore.changeEditFolderName(userEnteredFolderName);
    };

    handleChangeFileName = (event, fileName) => {
        this.checkFileOrFolderNameValidation(event);
        const originalFileName = UTIL.getDotSplitFileName(fileName).name;
        const newFileName = fileName.replace(originalFileName, event.target.value);

        this.props.classFileStore.changeEditFileName(newFileName);
    };

    checkFileOrFolderNameValidation = e => {
        checkSpace(e);
        checkFullStop(e);
        checkDot(e);
        checkTextValueLength(e);
    };

    handleModifyFolderName = event => {
        if (event.keyCode === RESPONSE_KEY_CODE.ESC) {
            this.setState({ inputChange: false });
            this.handleInitEditFolder();
        } else if (event.keyCode === RESPONSE_KEY_CODE.ENTER) {
            const { isExistFolder } = this.props.classStore;
            if (isExistFolder) {
                this.props.classStore.changeFolderLoadState(LoadingState.FAILED);
                this.props.classStore.changeFolderLoadResultDialogMsgId(CLASS_STORE_MESSAGE_ID.Error.exist_folder_name);
            } else {
                this.props.classStore.modifyGroupFileFolder({
                    getGroupFolderAndFiles: () => this.getGroupFolderAndFilesOnFirstPage(),
                    closeModifyFolder: () => this.handleInitEditFolder(),
                });
            }
        }
    };

    handleModifyFileName = (event, file) => {
        if (event.keyCode === RESPONSE_KEY_CODE.ESC) {
            this.setState({ inputChange: false });
            this.handleInitEditFile();
        } else if (event.keyCode === RESPONSE_KEY_CODE.ENTER) {
            if (file) {
                const { loginUser } = this.props.authStore;
                const { selectedGroupDetail, selectedFolderId } = this.props.classStore;
                const { editFileName } = this.props.classFileStore;

                const groupId = selectedGroupDetail.group.id;
                const userEmail = loginUser.email;
                const postedType = FILE_POSTED_TYPE.Folder;

                this.props.classFileStore.setModifyFileInfo(groupId, postedType, selectedFolderId, file.fileId, file.name, editFileName);

                this.props.classFileStore.modifyFile(userEmail, {
                    getGroupFolderAndFiles: () => this.getGroupFolderAndFilesOnFirstPage(),
                    closeModifyFile: () => this.handleInitEditFile(),
                });
            }
        }
    };

    handleInitEditFolder = _e => {
        this.props.classStore.initEditFolder();
    };

    handleInitEditFile = _e => {
        this.props.classFileStore.initEditFile();
    };

    handleChangeUploadFile = event => {
        const { classFileStore } = this.props;

        const file = event.target.files[0];

        const fileSizeMega = file.size / 1024 / 1024;
        if (fileSizeMega > 100) {
            classFileStore.setOverSize(true);
        } else {
            classFileStore.setOverSize(false);
            classFileStore.changeUploadFile(file);
        }

        this.handleUpload();

        event.target.value = '';
    };

    handleUpload = () => {
        const { authStore, classFileStore, classStore } = this.props;
        const { selectedGroupDetail, selectedFolderId } = classStore;

        const groupId = selectedGroupDetail.group.id;
        const userEmail = authStore.loginUser.email;
        const postedType = FILE_POSTED_TYPE.Folder;
        classFileStore.uploadFile(groupId, userEmail, postedType, selectedFolderId, {
            getGroupFolderAndFiles: () => this.getGroupFolderAndFilesOnFirstPage(),
        });
    };

    handleDownload = (_e, file) => {
        const { classFileStore } = this.props;
        const { isDownLoading } = classFileStore;

        if (!file || isDownLoading) {
            console.log(LOG_PREFIX, 'Not allowed download');
            return false;
        }

        const postedType = FILE_POSTED_TYPE.Folder;
        // classFileStore.changeSelectedFileId(file.fileId);
        classFileStore.downloadFile(file.groupId, postedType, file.fileFolderId, file.fileId, file.name);
    };

    handleCloseResultDialog = () => {
        const { classStore, classFileStore } = this.props;
        if (classFileStore.isFileLoadResultDialogOpen) {
            classFileStore.initFileLoadResult();
        } else if (classStore.isFolderLoadResultDialogOpen) {
            classStore.initFolderLoadResult();
        }
    };

    handleErrorFromFolder = msgId => {
        this.props.classStore.changeFolderLoadState(LoadingState.FAILED);
        this.props.classStore.changeFolderLoadResultDialogMsgId(msgId);
    };

    handleErrorFromFile = msgId => {
        this.props.classFileStore.changeFileUploadState(FILE_LOAD_STATE.UploadFailed);
        this.props.classFileStore.changeFileLoadResultDialogMsgId(msgId);
    };

    handleDeleteFileOrFolder = () => {
        const { classStore, classFileStore, authStore } = this.props;
        const { deleteFolderId } = classStore;
        const { selectedFileId } = classFileStore;
        if (deleteFolderId !== 0 && selectedFileId === 0) {
            classStore.deleteGroupFileFolder({
                getGroupFolderAndFiles: () => this.getGroupFolderAndFilesOnFirstPage(),
            });
        } else if (deleteFolderId === 0 && selectedFileId !== 0) {
            const { loginUser } = authStore;
            classFileStore.deleteFile(loginUser.email, {
                getGroupFolderAndFiles: () => this.getGroupFolderAndFilesOnFirstPage(),
            });
        }
    };

    handleCancelDeleteFileOrFolder = isOpen => {
        const { classStore, classFileStore } = this.props;
        const { deleteFolderId } = classStore;
        const { selectedFileId } = classFileStore;
        if (deleteFolderId !== 0 && selectedFileId === 0) {
            classStore.changeDeleteFolderId(0);
        } else if (deleteFolderId === 0 && selectedFileId !== 0) {
            classFileStore.initDeleteFile();
        }
        this.setState({ isCheckDeleteDialogOpen: isOpen });
    };

    handleFileNameTooltipOpen = file => {
        if (this.state.tooltipOpenFileId) {
            this.setState({ tooltipOpenFileId: 0 });
        } else {
            if (file && file.name.length > 50) {
                this.setState({ tooltipOpenFileId: file.fileId ? file.fileId : file.fileFolderId });
            }
        }
    };

    render() {
        const { classes, intl, authStore, classStore, classFileStore } = this.props;
        const { loginUser } = authStore;
        const { groupFolderAndFiles, findFolderBreadcrumbs, editFolderId, groupUserAuthority } = classStore;
        const { isCreateFolder, isFolderLoadResultDialogOpen, folderLoadResultDialogMsgId } = classStore;
        const { selectedFileId, isUploading, isFileLoadResultDialogOpen, fileLoadResultDialogMsgId, isDownLoading } = classFileStore;
        const { tabs } = this.state;

        return (
            <WithInfiniteScroll
                ref={this.interSectRef}
                fetchData={this.fetchGroupFolderAndFiles}
                isLoading={classStore.isFolderAndFilesLoading}
                noMore={classStore.isEndFolderAndFiles}
            >
                <div className={classes.root}>
                    <Tabs value={tabs} onChange={e => this.handleTabChange(e, tabs)} className={classes.tabStyle}>
                        <Tab label="파일" disableRipple />
                        {/*<Tab label="설문조사" disableRipple />*/}
                    </Tabs>

                    {tabs === 0 && (
                        <div>
                            <Box className={classes.optionBox}>
                                <Box style={{ position: 'relative' }}>
                                    {groupUserAuthority.FolderCreate && (
                                        <LightTooltip title={'새 폴더 만들기'} placement={'bottom'}>
                                            <IconButton
                                                disableRipple
                                                className={classes.iconButton}
                                                onClick={() => this.handleClickCreateFolderBtn()}
                                                aria-label={'새 폴더 만들기'}
                                            >
                                                <FolderPlusIcon />
                                            </IconButton>
                                        </LightTooltip>
                                    )}
                                    <Button
                                        component={'label'}
                                        className={classes.btnStyle}
                                        htmlFor={'upload-button-file'}
                                        disabled={isUploading}
                                        disableRipple
                                    >
                                        {'파일올리기'}
                                    </Button>

                                    <input
                                        id="upload-button-file"
                                        type="file"
                                        accept=".txt, .pdf, .hwp, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .gif, .jpg, .jpeg, .png, .mp4, .zip"
                                        style={{ display: 'none' }}
                                        onChange={this.handleChangeUploadFile}
                                    />
                                    {isUploading && (
                                        <CircularProgress
                                            size={24}
                                            style={{
                                                color: '#0097FF',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '70%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                            <Table className={classes.table} style={{ width: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th" scope="row" align="left" colSpan={4}>
                                            <Box display={'flex'}>
                                                <Button onClick={this.handleClickTopBtn} className={classes.tableBtnStyle} disableRipple>
                                                    TOP |
                                                </Button>
                                                <CustomBreadcrumbs data={findFolderBreadcrumbs} />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isCreateFolder && (
                                        <TableRow>
                                            <TableCell align="center" width={20}>
                                                <FolderNotchIcon />
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    <input
                                                        type="text"
                                                        autoFocus
                                                        className={classes.textField}
                                                        onChange={e => this.handleChangeCreateFolderName(e)}
                                                        onKeyDown={e => this.handleCreateFolder(e)}
                                                        onBlur={this.handleInitCreateFolder}
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableCell with={80} />
                                            <TableCell align="center" width={20}>
                                                <MoreButton
                                                    menuItems={[
                                                        {
                                                            name: intl.formatMessage({ id: 'cancel' }),
                                                            menuFunc: () => this.handleInitCreateFolder(),
                                                        },
                                                    ]}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {groupFolderAndFiles.length
                                        ? groupFolderAndFiles.map((row, index) => {
                                              const isFolder = !row.fileId && Boolean(GROUP_FOLDER_TYPE[row.type]);
                                              const isLastFile = groupFolderAndFiles.length === index + 1;
                                              const isSelectedFolderOrFile = isFolder
                                                  ? editFolderId === row.fileFolderId
                                                  : selectedFileId === row.fileId;
                                              return (
                                                  <TableRow key={`${row.groupId}_${row.name}`} ref={isLastFile ? this.interSectRef : undefined}>
                                                      <TableCell align="center" width={20}>
                                                          {isFolder ? <FolderNotchIcon /> : <FileIcon />}
                                                      </TableCell>
                                                      <TableCell style={{ maxWidth: 0 }}>
                                                          {this.state.inputChange && isSelectedFolderOrFile ? (
                                                              <ReferenceRoomModifyInput
                                                                  focus={this.state.inputChange}
                                                                  name={row.name}
                                                                  isFolder={isFolder}
                                                                  changeEditName={e =>
                                                                      isFolder
                                                                          ? this.handleChangeFolderName(e)
                                                                          : this.handleChangeFileName(e, row.name)
                                                                  }
                                                                  modifyName={e =>
                                                                      isFolder ? this.handleModifyFolderName(e) : this.handleModifyFileName(e, row)
                                                                  }
                                                                  cancelEditName={e => {
                                                                      this.setState({ inputChange: false });
                                                                      if (isFolder) this.handleInitEditFolder(e);
                                                                      else this.handleInitEditFile(e);
                                                                  }}
                                                              />
                                                          ) : (
                                                              <Tooltip
                                                                  title={row.name}
                                                                  open={row.fileId === this.state.tooltipOpenFileId}
                                                                  disableFocusListener
                                                                  disableTouchListener
                                                              >
                                                                  <div
                                                                      className={classes.textStyle}
                                                                      style={{
                                                                          textOverflow: 'ellipsis',
                                                                          overflow: 'hidden',
                                                                          whiteSpace: 'nowrap',
                                                                      }}
                                                                      onClick={e =>
                                                                          isFolder ? this.handleClickFolder(e, row) : this.handleDownload(e, row)
                                                                      }
                                                                      onMouseEnter={() => this.handleFileNameTooltipOpen(row)}
                                                                      onMouseOut={() => this.handleFileNameTooltipOpen(row)}
                                                                  >
                                                                      {row.name}
                                                                  </div>
                                                              </Tooltip>
                                                          )}

                                                          <div className={classes.fileInfo}>
                                                              {isFolder
                                                                  ? `${DATE_UTIL.getTimeZoneDate(
                                                                        row.createdDatetime,
                                                                        DATE_UTIL.FORMAT.DOT_DATE_FORMAT,
                                                                    )} | ${row.size}${intl.formatMessage({ id: 'number_of_files' })}`
                                                                  : `${DATE_UTIL.getTimeZoneDate(
                                                                        row.createdDatetime,
                                                                        DATE_UTIL.FORMAT.DOT_DATE_FORMAT,
                                                                    )} | ${UTIL.formatBytes(row.size)} | ${row.userName}`}
                                                          </div>
                                                      </TableCell>
                                                      {!isFolder ? (
                                                          <TableCell width={80}>
                                                              <Box style={{ position: 'relative' }}>
                                                                  <Button
                                                                      className={clsx(classes.tableBtnStyle, classes.downloadBtn)}
                                                                      disableRipple
                                                                      style={{ fontSize: '0.75rem' }}
                                                                      onClick={e => this.handleDownload(e, row)}
                                                                      disabled={isDownLoading}
                                                                  >
                                                                      <DownloadSimpleIcon /> <Typography>다운로드</Typography>
                                                                  </Button>
                                                                  {isDownLoading && row.fileId === selectedFileId && (
                                                                      <Box sx={{ width: '100%' }}>
                                                                          <LinearProgress />
                                                                      </Box>
                                                                  )}
                                                              </Box>
                                                          </TableCell>
                                                      ) : (
                                                          <TableCell width={80} />
                                                      )}
                                                      <TableCell component="th" scope="row" align="center" width={20} style={{ padding: 0 }}>
                                                          {(loginUser.id === row.userId || groupUserAuthority.FolderCreate) && (
                                                              <MoreButton
                                                                  menuItems={[
                                                                      {
                                                                          name: intl.formatMessage({ id: 'change_name' }),
                                                                          menuFunc: () => this.handleClickInputChange(row, isFolder),
                                                                      },
                                                                      {
                                                                          name: intl.formatMessage({ id: 'delete' }),
                                                                          menuFunc: () => this.handleClickDeleteBtn(row, isFolder),
                                                                      },
                                                                  ]}
                                                              />
                                                          )}
                                                      </TableCell>
                                                  </TableRow>
                                              );
                                          })
                                        : !isCreateFolder && (
                                              <TableRow>
                                                  <TableCell component="th" scope="row" align="center" width={20} />
                                                  <TableCell colSpan="3" align={'center'}>
                                                      등록된 폴더 혹은 파일이 없습니다.
                                                  </TableCell>
                                              </TableRow>
                                          )}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                    {tabs === 1 && (
                        <div>
                            <Box className={classes.optionBox}>
                                <IconButton disableRipple>
                                    <FolderPlusIcon />
                                </IconButton>
                                <Button className={classes.btnStyle} onClick={this.handleChangeSurveyDialogOpen} disableRipple>
                                    + 설문조사 만들기
                                </Button>
                            </Box>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Button className={classes.tableBtnStyle} disableRipple>
                                                TOP
                                            </Button>
                                        </TableCell>
                                        <TableCell colSpan="2">
                                            Work <CaretRightIcon style={{ marginBottom: '-2px' }} /> <b>기획팀</b>{' '}
                                        </TableCell>
                                        <TableCell align="right" style={{ paddingRight: 0 }}>
                                            <FormControl className={classes.formControl}>
                                                <Select
                                                    value={this.state.filter}
                                                    onChange={this.handleChangeSort}
                                                    displayEmpty
                                                    IconComponent={() => (
                                                        <Box
                                                            style={{
                                                                width: 16,
                                                                height: 16,
                                                                marginLeft: 5,
                                                            }}
                                                        >
                                                            <ArrowDownIcon />{' '}
                                                        </Box>
                                                    )}
                                                >
                                                    <MenuItem value="전체" className={classes.menuItem}>
                                                        전체
                                                    </MenuItem>
                                                    <MenuItem value="시작하기" className={classes.menuItem}>
                                                        시작하기
                                                    </MenuItem>
                                                    <MenuItem value="작성중" className={classes.menuItem}>
                                                        작성중
                                                    </MenuItem>
                                                    <MenuItem value="제출완료" className={classes.menuItem}>
                                                        제출완료
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map(row => (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row" align="center" width={20}>
                                                <MoreButton
                                                    menuItems={[
                                                        {
                                                            name: '공유 링크 복사',
                                                            menuFunc: () => console.log('developing...'),
                                                        },
                                                        {
                                                            name: '포함 일정 바로보기',
                                                            menuFunc: () => console.log('developing...'),
                                                        },
                                                    ]}
                                                />
                                            </TableCell>
                                            <TableCell align="center" width={20}>
                                                <ClipboardTextIcon />
                                            </TableCell>
                                            <TableCell>
                                                {row.fileName}
                                                <div className={classes.fileInfo}>
                                                    {row.date} | {row.size} | {row.userName}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right" style={{ fontSize: '0.75rem' }}>
                                                {row.state === '시작하기' ? (
                                                    <span style={{ color: '#dc524f' }}>시작하기</span>
                                                ) : row.state === '작성중' ? (
                                                    <span style={{ color: '#0097ff' }}>작성중</span>
                                                ) : row.state === '제출완료' ? (
                                                    <span style={{ color: '#a0a7ab' }}>제출완료</span>
                                                ) : null}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                    <SurveyDialogComponent
                        handleCloseSurveyDialogOpen={this.handleCloseSurveyDialogOpen}
                        surveyDialogOpen={this.state.surveyDialogOpen}
                    />
                    <ConfirmDialog
                        open={isFileLoadResultDialogOpen || isFolderLoadResultDialogOpen}
                        title={intl.formatMessage({ id: 'notification' })}
                        msg={
                            isFileLoadResultDialogOpen
                                ? intl.formatMessage({ id: `${fileLoadResultDialogMsgId}` })
                                : isFolderLoadResultDialogOpen
                                ? intl.formatMessage({ id: `${folderLoadResultDialogMsgId}` })
                                : null
                        }
                        changeDialogOpen={() => this.handleCloseResultDialog()}
                    />
                    <YesOrNoDialog
                        open={this.state.isCheckDeleteDialogOpen}
                        title={intl.formatMessage({ id: 'notification' })}
                        msg={intl.formatMessage({ id: 'msg.check_delete' }, { name: this.state.deleteFileName })}
                        submit={this.handleDeleteFileOrFolder}
                        changeDialogOpen={isOpen => this.handleCancelDeleteFileOrFolder(isOpen)}
                    />
                </div>
            </WithInfiniteScroll>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('authStore', 'classStore', 'classFileStore', 'paginationStore')(injectIntl(observer(ReferenceRoomComponent)))),
);
