import { makeAutoObservable } from 'mobx';

export default class TopBarStore {
    constructor() {
        makeAutoObservable(this);
    }

    isSearchBarRender = true;
    setIsSearchBarRender = isSearchBarRender => (this.isSearchBarRender = isSearchBarRender);
}
