import Rooms from '../views/myRoom/Rooms';
import MobileRooms from '../views/mobile/myRoom/MobileRooms';
import MobileRoomCreateComponent from '../views/mobile/myRoom/MobileRoomCreateComponent';
import RoomCreateComponent from '../views/myRoom/RoomCreateComponent';
import MobileRoomModifyComponent from '../views/mobile/setting/MobileRoomModifyComponent';
import RoomModifyComponent from '../views/setting/RoomModifyComponent';
import ServiceCenter from '../views/serviceCenter/ServiceCenter';
import ProfileSettingsComponent from '../views/profileSettings/ProfileSettingsComponent';
import MobileProfileSettingsComponent from '../views/mobile/profileSettings/MobileProfileSettingsComponent';
import ProfileViewComponent from '../views/profileSettings/ProfileViewComponent';
import MobileClassMainComponent from '../views/mobile/class/MobileClassMainComponent';
import ClassMainComponent from '../views/class/ClassMainComponent';
import MobileSearchComponent from '../views/mobile/search/MobileSearchComponent';
import SearchComponent from '../views/search/SearchComponent';
import WithdrawalConfirmComponent from '../views/profileSettings/WithdrawalConfirmComponent';
import MobileMyManagementComponent from '../views/mobile/dashboard/MobileMyManagementComponent';
import MyManagementComponent from '../views/dashboard/MyManagementComponent';
import MobileMyLearningComponent from '../views/mobile/dashboard/MobileMyLearningComponent';
import MyLearningComponent from '../views/dashboard/MyLearningComponent';
import MobilePenRecordComponent from '../views/mobile/class/MobilePenRecordComponent';
import PenRecordComponent from '../views/class/PenRecordComponent';
import InvitationComponent from '../views/invitation/InvitationComponent';
import StudyLive from '../views/studylive/StudyLive';
import MobileStudyLive from '../views/mobile/studylive/MobileStudyLive';
import TermsOfUse from '../views/ToS/TermsOfUse';
import PrivacyPolicy from '../views/privacyPolicy/PrivacyPolicy';
import MobileStatistics from '../views/Statistics/MobileStatistics';
import Statistics from '../views/Statistics/Statistics';
import MobileHome from '../views/mobile/home/MobileHome';
import Home from '../views/home/Home';
import MobileSignIn from '../views/mobile/home/signIn/MobileSignIn';
import SignIn from '../views/home/signIn/SignIn';
import MobileSignUp from '../views/mobile/home/signUp/MobileSignUp';
import SignUp from '../views/home/signUp/SignUp';
import MobilePasswordFindComponent from '../views/mobile/home/password/MobilePasswordFindComponent';
import PasswordFindComponent from '../views/home/password/PasswordFindComponent';
import MobilePasswordResetComponent from '../views/mobile/home/password/MobilePasswordResetComponent';
import PasswordResetComponent from '../views/home/password/PasswordResetComponent';
import MobilePasswordResetCompleteComponent from '../views/mobile/home/password/MobilePasswordResetCompleteComponent';
import PasswordResetCompleteComponent from '../views/home/password/PasswordResetCompleteComponent';
import SignUpDialogComponent from '../views/home/signUp/SignUpDialogComponent';
import SignUpEmailAuthenticationDialogComponent from '../views/home/signUp/SignUpEmailAuthenticationDialogComponent';
import SocialAgreeComponent from '../views/home/signUp/SocialAgreeComponent';
import Oauth from '../views/home/oauth/Oauth';
import { Navigate } from 'react-router-dom';
import RealTimePenComponent from '../views/class/RealTimePenComponent';
import MobileRealTimePenComponent from '../views/class/MobileRealTimePenComponent';
import MobileSignUpEmailAuthenticationDialogComponent from '../views/mobile/home/signUp/MobileSignUpEmailAuthenticationDialogComponent';
import MobileSignUpDialogComponent from '../views/mobile/home/signUp/MobileSignUpDialogComponent';
import PenViewerComponent from '../views/class/PenViewerComponent';
import WritingBoardMainComponent from '../views/writingBoard/WritingBoardMainComponent';
import MobilePenViewerComponent from '../views/mobile/class/MobilePenViewerComponent';
import WrittenEvaluationResult from '../views/class/WrittenEvaluationResult';

export const getMobileRoutes = (
    isLogin,
    { configs, isMobile, isTablet, loginState, isScrollEnd, fixedTopBarHeight, scrollMove, calcScrollEnd, scrollHeight },
) => {
    const publicMobileRoutes = [
        {
            path: '/',
            element: <MobileHome isTablet={isTablet} />,
        },
        {
            path: '/home',
            element: <MobileHome isTablet={isTablet} />,
        },
        {
            path: '/servicecenter',
            element: <ServiceCenter configs={configs} isMobile={isMobile} loginState={loginState} />,
        },
        {
            path: '/signin',
            element: <MobileSignIn configs={configs} />,
        },
        {
            path: '/signup',
            element: <MobileSignUp configs={configs} />,
        },
        {
            path: '/passwordfind',
            element: <MobilePasswordFindComponent configs={configs} />,
        },
        {
            path: '/passwordreset/*',
            element: <MobilePasswordResetComponent configs={configs} />,
        },
        {
            path: '/passwordcomplete',
            element: <MobilePasswordResetCompleteComponent configs={configs} />,
        },
        {
            path: '/signupdialog/*',
            element: <MobileSignUpDialogComponent configs={configs} />,
        },
        {
            path: '/withdrawal',
            element: <WithdrawalConfirmComponent />,
        },
        {
            path: '/signup/email/authentication/:userEmail',
            element: <MobileSignUpEmailAuthenticationDialogComponent configs={configs} />,
        },
        {
            path: '/socialagree/:platform',
            element: <SocialAgreeComponent configs={configs} />,
        },
        {
            path: '/oauth/:platform/:operation',
            element: <Oauth configs={configs} />,
        },
        {
            path: '/invite/:accessToken',
            element: <InvitationComponent configs={configs} />,
        },
        {
            path: '/studylive',
            element: <MobileStudyLive />,
        },
        {
            path: '/termsofuse',
            element: <TermsOfUse />,
        },
        {
            path: '/privacypolicy',
            element: <PrivacyPolicy />,
        },
        {
            path: '/*',
            element: <MobileSignIn configs={configs} />,
        },
    ];

    const privateMobileRoutes = [
        {
            path: '/rooms',
            element: <MobileRooms isScrollEnd={isScrollEnd} isMobile={isMobile} />,
        },
        {
            path: '/roomcreate',
            element: <MobileRoomCreateComponent configs={configs} />,
        },
        {
            path: '/roommodify/:id',
            element: <MobileRoomModifyComponent configs={configs} />,
        },
        {
            path: '/servicecenter',
            element: <ServiceCenter configs={configs} isMobile={isMobile} loginState={loginState} />,
        },
        {
            path: '/profilesettings',
            element: <MobileProfileSettingsComponent configs={configs} />,
        },
        {
            path: '/profileview',
            element: <ProfileViewComponent configs={configs} />,
        },
        {
            path: '/class/:id/*',
            element: (
                <MobileClassMainComponent
                    configs={configs}
                    isScrollEnd={isScrollEnd}
                    fixedTopBarHeight={fixedTopBarHeight}
                    scrollMove={scrollMove}
                    calcScrollEnd={calcScrollEnd}
                    scrollHeight={scrollHeight}
                />
            ),
        },
        {
            path: '/search/:scope/:category/:keyword',
            element: <MobileSearchComponent configs={configs} isScrollEnd={isScrollEnd} />,
        },
        {
            path: '/withdrawal',
            element: <WithdrawalConfirmComponent />,
        },
        {
            path: '/classmanagement/*',
            element: <MobileMyManagementComponent isMobile={isMobile} />,
        },
        {
            path: '/mylearning',
            element: <MobileMyLearningComponent />,
        },
        {
            path: '/penview/:groupId/:userEmail/:paperGroupId',
            element: <MobilePenViewerComponent />,
        },
        {
            path: '/penrecordview/:groupId/:userEmail/:paperGroupId',
            element: <MobilePenRecordComponent />,
        },
        {
            path: '/realtimeview/:userEmail',
            element: <MobileRealTimePenComponent />,
        },
        {
            path: '/writingBoard/:groupId',
            element: <WritingBoardMainComponent />,
        },
        {
            path: '/invite/:accessToken',
            element: <InvitationComponent configs={configs} />,
        },
        {
            path: '/studylive',
            element: <MobileStudyLive />,
        },
        {
            path: '/termsofuse',
            element: <TermsOfUse />,
        },
        {
            path: '/privacypolicy',
            element: <PrivacyPolicy />,
        },
        {
            path: '/statistics/:roomId',
            element: <MobileStatistics />,
        },
        {
            path: '/*',
            element: <Navigate to={'/rooms'} replace />,
        },
    ];

    return isLogin ? privateMobileRoutes : publicMobileRoutes;
};

export const getWebRoutes = (
    isLogin,
    { configs, isMobile, isTablet, loginState, isScrollEnd, fixedTopBarHeight, scrollMove, calcScrollEnd, scrollHeight },
) => {
    const publicRoutes = [
        {
            path: '/',
            element: <Home isTablet={isTablet} />,
        },
        {
            path: '/home',
            element: <Home isTablet={isTablet} />,
        },
        {
            path: '/servicecenter',
            element: <ServiceCenter configs={configs} isMobile={isMobile} loginState={loginState} />,
        },
        {
            path: '/signin',
            element: <SignIn configs={configs} />,
        },
        {
            path: '/signup',
            element: <SignUp configs={configs} />,
        },
        {
            path: '/passwordfind',
            element: <PasswordFindComponent configs={configs} />,
        },
        {
            path: '/passwordreset/*',
            element: <PasswordResetComponent configs={configs} />,
        },
        {
            path: '/passwordcomplete',
            element: <PasswordResetCompleteComponent configs={configs} />,
        },
        {
            path: '/signupdialog/*',
            element: <SignUpDialogComponent configs={configs} />,
        },
        {
            path: '/withdrawal',
            element: <WithdrawalConfirmComponent />,
        },
        {
            path: '/signup/email/authentication/:userEmail',
            element: <SignUpEmailAuthenticationDialogComponent configs={configs} />,
        },
        {
            path: '/socialagree/:platform',
            element: <SocialAgreeComponent configs={configs} />,
        },
        {
            path: '/oauth/:platform/:operation',
            element: <Oauth configs={configs} />,
        },
        {
            path: '/passwordreset/*',
            element: <PasswordResetComponent configs={configs} />,
        },
        {
            path: '/passwordcomplete',
            element: <PasswordResetCompleteComponent configs={configs} />,
        },
        {
            path: '/invite/:accessToken',
            element: <InvitationComponent configs={configs} />,
        },
        {
            path: '/studylive',
            element: <StudyLive />,
        },
        {
            path: '/termsofuse',
            element: <TermsOfUse />,
        },
        {
            path: '/privacypolicy',
            element: <PrivacyPolicy />,
        },
        {
            path: 'publishing/note/evaluation/result',
            element: <WrittenEvaluationResult />,
        },
        {
            path: '/*',
            element: <SignIn configs={configs} />,
        },
    ];

    const privateRoutes = [
        {
            path: '/rooms',
            element: <Rooms isScrollEnd={isScrollEnd} isMobile={isMobile} />,
        },
        {
            path: '/roomcreate',
            element: <RoomCreateComponent configs={configs} />,
        },
        {
            path: '/roommodify/:id',
            element: <RoomModifyComponent configs={configs} />,
        },
        {
            path: '/servicecenter',
            element: <ServiceCenter configs={configs} isMobile={isMobile} loginState={loginState} />,
        },
        {
            path: '/profilesettings',
            element: <ProfileSettingsComponent configs={configs} />,
        },
        {
            path: '/profileview',
            element: <ProfileViewComponent configs={configs} />,
        },
        {
            path: '/class/:id/*',
            element: (
                <ClassMainComponent
                    configs={configs}
                    isScrollEnd={isScrollEnd}
                    fixedTopBarHeight={fixedTopBarHeight}
                    scrollMove={scrollMove}
                    calcScrollEnd={calcScrollEnd}
                    scrollHeight={scrollHeight}
                />
            ),
        },
        {
            path: '/search/:scope/:category/:keyword',
            element: <SearchComponent configs={configs} isScrollEnd={isScrollEnd} />,
        },
        {
            path: '/withdrawal',
            element: <WithdrawalConfirmComponent />,
        },
        {
            path: '/classmanagement/*',
            element: <MyManagementComponent isMobile={isMobile} />,
        },
        {
            path: '/mylearning',
            element: <MyLearningComponent />,
        },
        {
            path: '/penview/:groupId/:userEmail/:paperGroupId',
            element: <PenViewerComponent />,
        },
        {
            path: '/penrecordview/:groupId/:userEmail/:paperGroupId',
            element: <PenRecordComponent />,
        },
        {
            path: '/realtimeview/:userEmail',
            element: <RealTimePenComponent />,
        },
        {
            path: '/writingBoard/:groupId',
            element: <WritingBoardMainComponent />,
        },
        {
            path: '/invite/:accessToken',
            element: <InvitationComponent configs={configs} />,
        },
        {
            path: '/studylive',
            element: <StudyLive />,
        },
        {
            path: '/termsofuse',
            element: <TermsOfUse />,
        },
        {
            path: '/privacypolicy',
            element: <PrivacyPolicy />,
        },
        {
            path: '/statistics/:roomId',
            element: <Statistics />,
        },
        {
            path: '/evaluation/:groupId/:userEmail/:paperGroupId',
            element: <WrittenEvaluationResult />,
        },
        {
            path: '/*',
            element: <Navigate to={'/rooms'} replace />,
        },
    ];

    return isLogin ? privateRoutes : publicRoutes;
};
