import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import { ApiErrorCode } from '../common/ApiErrorCode';
import dayjs from 'dayjs';
import { DATE_UTIL } from '../common/util/date.util';
import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE } from './PaginationStore';

export const CLASS_TEAM_STORE_LOADING_STATE = {
    READY: 'READY',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
};

export const TEAM_OPERATION = {
    None: 'none',
    Create: 'create',
    Modify: 'modify',
    Delete: 'delete',
};

const EmptyTeam = {
    teamId: 0,
    parentTeamId: 0,
    name: '',
    groupId: 0,
    enabled: false,
};

const EmptyTeamUser = {
    teamId: 0,
    userId: 0,
    type: '',
    enabled: false,
};

const CLASS_API_URL = '/api/v1/group';

const logPrefix = ' [ ClassTeamStore ] ';

export const CLASS_TEAM_STORE_MESSAGE_ID = {
    Default: { empty: 'default_empty' },
    Error: {
        already_exist_name: 'msg.already_exist_name',
        failed_update: 'msg.failed_update',
    },
};

export default class ClassTeamStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    team = { ...EmptyTeam };
    teams = [];
    teamsTotalCount = 0;
    teamMembers = [];
    teamOperation = TEAM_OPERATION.None;
    msgId = CLASS_TEAM_STORE_MESSAGE_ID.Default.empty;
    isLoadingState = CLASS_TEAM_STORE_LOADING_STATE.READY;

    initTeams = () => {
        this.teams = [];
        this.teamsTotalCount = 0;
    };

    get isLoading() {
        return this.isLoadingState === CLASS_TEAM_STORE_LOADING_STATE.PENDING;
    }

    get groupTeams() {
        return this.teams.slice();
    }

    resetTeams = teams => {
        this.teams = teams;
    };

    addTeams = teams => {
        if (teams.length) {
            this.teams = this.teams.concat(teams);
        }
    };
    changeTeamOperation = operation => {
        this.teamOperation = operation;
    };

    setTeamInfo = team => {
        console.log('set Team info = {}', team);
        this.team = { ...team };

        this.teamMembers = [...team.teamUserList];
    };

    changeTeamName = name => {
        this.team = { ...this.team, name: name };
    };

    initTeamStore = () => {
        console.log('Do initTeamStore');
        this.team = { ...EmptyTeam };
        this.teamMembers = [];
        this.teamOperation = TEAM_OPERATION.None;
    };

    changeTeamMembers = member => {
        if (!member) {
            return false;
        }
        console.log('changeTeamMembers : >> ', member);
        const result = this.teamMembers.findIndex(m => m.userId === member.userId);
        console.log('findIndex result : >> ', result);
        if (result === -1) {
            this.teamMembers = this.teamMembers.concat(member);
        } else {
            this.teamMembers.splice(result, 1);
        }
    };

    setIsLiveToRooms = rooms => {
        if (!rooms || !rooms.length) {
            return;
        }
        rooms.forEach(room => {
            const now = DATE_UTIL.getTimeZoneDateNoFormat(dayjs());
            const startDatetime = DATE_UTIL.getTimeZoneDateNoFormat(room.startDatetime);
            const endDatetime = DATE_UTIL.getTimeZoneDateNoFormat(room.endDatetime);

            const isEnd = now.isAfter(endDatetime);
            const isLive = now.isBetween(startDatetime, endDatetime, null, '[]');
            const isBeforeStart = now.isBefore(startDatetime);

            room.isEnd = isEnd;
            room.isLive = isLive;
            room.isBeforeStart = isBeforeStart;
        });
    };

    get changedTeamMembers() {
        return this.teamMembers.slice();
    }

    get isEndTeams() {
        return this.teamsTotalCount === this.teams.length;
    }

    *getTeams(groupId, userEmail, page, rowsPerPage, sort, callbacks) {
        console.log(logPrefix, `Start getTeams groupId=${groupId}, userEmail=${userEmail}`);
        this.isLoadingState = CLASS_TEAM_STORE_LOADING_STATE.PENDING;
        const params = { userEmail: userEmail, page: page, rowsPerPage: rowsPerPage, sort: sort };
        console.log(logPrefix, 'getTeams params = ', params);

        try {
            const response = yield axios.get(this.serverContextPath + `${CLASS_API_URL}/${groupId}/teams`, {
                params: { ...params },
            });
            console.log(logPrefix, 'Success getTeams');
            console.log(logPrefix, 'Result = ', response.data);

            this.teamsTotalCount = response.data.groupTeamsTotalCount;

            callbacks && callbacks.successAction(response.data.groupTeams, response.data.groupTeamsTotalCount);

            this.isLoadingState = CLASS_TEAM_STORE_LOADING_STATE.SUCCESS;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getTeams', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getTeams', e.request);
            } else {
                console.log(logPrefix, 'Failed getTeams', e.message);
            }

            this.isLoadingState = CLASS_TEAM_STORE_LOADING_STATE.FAILED;
        } finally {
            this.isLoadingState = CLASS_TEAM_STORE_LOADING_STATE.READY;
        }
    }

    *createTeam(groupId, callbacks) {
        console.log(logPrefix, 'Start create team, callbacks : >>', callbacks);
        const params = { ...this.team };
        params.groupId = groupId;
        params.teamUserList = [...this.teamMembers];

        console.log(logPrefix, 'CreateTeam params = {}', params);
        try {
            const response = yield axios.post(this.serverContextPath + `${CLASS_API_URL}/team/create`, params);
            console.log(logPrefix, 'Success create team ');
            console.log(logPrefix, 'response data = ', response.data);

            callbacks && callbacks.getTeams && callbacks.getTeams();
            callbacks && callbacks.closeAddGroupDialog && callbacks.closeAddGroupDialog();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed createTeam', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
                if (e.response.data.errorCode === ApiErrorCode.AlreadyExistName) {
                    callbacks && callbacks.alertError();
                }
            } else if (e.request) {
                console.log(logPrefix, 'Failed createTeam', e.request);
            } else {
                console.log(logPrefix, 'Failed createTeam', e.message);
            }
        }
    }

    *deleteTeam(_userEmail, callbacks) {
        console.log('Start delete team');

        const params = { ...this.team, teamUserList: [...this.teamMembers] };
        console.log('Request delete team = ', params);

        try {
            yield axios.put(this.serverContextPath + `${CLASS_API_URL}/team/delete`, params);
            callbacks && callbacks.getTeams && callbacks.getTeams();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed deleteTeam', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed deleteTeam', e.request);
            } else {
                console.log(logPrefix, 'Failed deleteTeam', e.message);
            }
        }
    }

    *modifyTeam(_userEmail, callbacks) {
        console.log(logPrefix, 'Start modifyTeam teamInfo = {}');
        const params = { ...this.team, teamUserList: [...this.teamMembers] };

        try {
            yield axios.put(this.serverContextPath + `${CLASS_API_URL}/team/modify`, params);

            callbacks && callbacks.getTeams && callbacks.getTeams();
            callbacks && callbacks.closeAddGroupDialog && callbacks.closeAddGroupDialog();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed modifyTeam', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
                if (e.response.data.errorCode === ApiErrorCode.AlreadyExistName) {
                    this.msgId = CLASS_TEAM_STORE_MESSAGE_ID.Error.already_exist_name;
                    callbacks && callbacks.alertError();
                }
            } else if (e.request) {
                console.log(logPrefix, 'Failed modifyTeam', e.request);
            } else {
                console.log(logPrefix, 'Failed modifyTeam', e.message);
            }
        }
    }

    *getUserRegisteredRoom(groupId, userEmail, callbacks, page = DEFAULT_PAGE, rowsPerPage = DEFAULT_ROWS_PER_PAGE) {
        console.log(
            logPrefix,
            `getUserRegisteredRoom starting, groupId = ${groupId}, userEmail = ${userEmail}, page=${page}, rowsPerPage=${rowsPerPage}`,
        );
        this.isLoadingState = CLASS_TEAM_STORE_LOADING_STATE.PENDING;
        try {
            const response = yield axios.get(this.serverContextPath + `${CLASS_API_URL}/${groupId}/rooms/${userEmail}/registered`, {
                params: { page: page, rowsPerPage: rowsPerPage },
            });
            if (page === 0 && (!response.data || !response.data.length)) {
                callbacks && callbacks.alertMessage();
                return false;
            }

            console.log(logPrefix, 'Success getUserRegisteredRoom : >> ', response.data);
            const nextPage = page + 1;
            callbacks && callbacks.changePage && callbacks.changePage(nextPage);
            callbacks && callbacks.setRoomOwner && callbacks.setRoomOwner();
            callbacks && callbacks.setResult && callbacks.setResult(response.data, page);
            this.isLoadingState = CLASS_TEAM_STORE_LOADING_STATE.SUCCESS;
            callbacks && callbacks.moveToResultView && callbacks.moveToResultView();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getUserRegisteredRoom', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getUserRegisteredRoom', e.request);
            } else {
                console.log(logPrefix, 'Failed getUserRegisteredRoom', e.message);
            }
            this.isLoadingState = CLASS_TEAM_STORE_LOADING_STATE.FAILED;
            callbacks && callbacks.alertMessage();
        } finally {
            this.isLoadingState = CLASS_TEAM_STORE_LOADING_STATE.READY;
        }
    }

    *getUserRegisteredRoomTotalCount(groupId, userId, callbacks) {
        console.log(logPrefix, `getUserRegisteredRoom starting, groupId = ${groupId}, userEmail = ${userId}`);
        try {
            const response = yield axios.get(this.serverContextPath + `${CLASS_API_URL}/${groupId}/rooms/${userId}/registered/totalcount`);

            callbacks && callbacks.setRoomsByOwnerTotalCount(response.data);
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getUserRegisteredRoom', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getUserRegisteredRoom', e.request);
            } else {
                console.log(logPrefix, 'Failed getUserRegisteredRoom', e.message);
            }
        }
    }

    *getTeamsTotalCount(groupId) {
        try {
            const response = yield axios.get(this.serverContextPath + `${CLASS_API_URL}/${groupId}/teams/count`);
            console.log(logPrefix, 'Success getTeamsTotalCount');
            console.log(logPrefix, 'Result = ', response.data);

            this.teamsTotalCount = response.data;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getTeamsTotalCount', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getTeamsTotalCount', e.request);
            } else {
                console.log(logPrefix, 'Failed getTeamsTotalCount', e.message);
            }
        }
    }
}
