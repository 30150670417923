import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../../components/WithRouter';
import { Box, Button, Tooltip, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { ReactComponent as PenRecordMinus } from '../../../common/images/PenRecordMinus.svg';
import { ReactComponent as PenRecordPlus } from '../../../common/images/PenRecordPlus.svg';
import { ReactComponent as WidthView } from '../../../common/images/WidthView.svg';
import { ReactComponent as HeightView } from '../../../common/images/HeightView.svg';

import { ZoomFitEnum } from 'nl-lib';
// import { ZoomFitEnum } from '../../../nl-lib';
import { ToastsStore } from 'react-toasts';
import MobileReplayWindowMain from '../../neoPen/MobileReplayWindowMain';
import { NOTICE_TYPE } from '../../../stores/NoticeStore';

const styles = _theme => ({
    root: {},
    topBox: {
        padding: 10,
        boxSizing: 'border-box',
        borderBottom: '1px solid #c0c2c3',
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            width: 27,
            height: 27,
        },
    },
    afterContent: {
        '&::after': {
            content: "''",
            width: 370,
            height: 27,
        },
    },
    logoImage: {
        width: 'auto',
        height: 'auto',
        maxWidth: 140,
        maxHeight: 32,
        objectFit: 'contain',
        // cursor: 'pointer',
        verticalAlign: 'middle',
        marginRight: 36,
    },
    textStyle: {
        color: '#000000',
        fontWeight: 'bold',
        fontSize: '1.125rem',
    },
    topCenterText: {
        fontWeight: 'normal',
        fontSize: '1.25rem',
    },
    penButton: {
        width: 126,
        height: 40,
        background: '#fff',
        border: '1px solid #c4c4c4',
        borderRadius: 2,
        marginRight: 15,
        '& span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 5,
        },
    },
    penButtonRight: {
        marginRight: 40,
        background: '#0097ff',
        border: 0,
        '&:hover': {
            background: '#0097ff',
        },
        '& span': {
            color: '#fff',
        },
    },
    penText: {
        fontSize: '0.938rem',
        color: '#1e2121',
    },

    controlBox: {
        padding: 10,
        borderBottom: '1px solid #c0c2c3',
    },
    dateText: {
        fontWeight: 'normal',
        fontSize: '0.938rem',
        margin: '0 5px 0 30px',
        color: '#2c2c2c',
    },
    radioBox: {
        marginRight: 30,
        '& .MuiIconButton-root': {
            padding: 5,
            '&:hover': {
                background: 'transparent',
            },
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '1rem',
            color: '#000',
        },
    },
    contentsLeftBox: {
        width: '100%',
        height: 'calc(100vh - 81px - 22px)',
        background: '#eee',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    buttonBox: {
        // marginRight: 76,
    },
    buttonStyle: {
        minWidth: 35,
        background: 'transparent',
        padding: 5,
        margin: '0 5px',
        '&:hover': {
            background: 'transparent',
        },
    },
    buttonStyleMargin: {
        margin: 0,
    },
    formControl: {
        marginRight: 8,
        '& > div': {
            fontSize: '0.75rem',
            fontWeight: 600,
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& fieldset ': {
            border: 0,
        },
        '& .MuiSelect-select.MuiSelect-select, .MuiInputBase-input': {
            padding: 0,
            paddingRight: '0px !important',
        },
    },
    menuItem: {
        fontSize: '0.75rem !important',
        color: '#000 !important',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db !important',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db !important',
        },
    },
    numberBox: {
        width: 50,
        height: 25,
        borderRadius: 4,
        padding: '3px 7px',
        border: '1px solid #c2c2c2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    numberText: {
        fontSize: '1rem',
        color: '#6d6d70',
    },
    pdfBox: {
        width: 413,
        height: 685,
        background: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contentsRightBox: {
        width: 150,
        height: 'calc(100vh - 81px - 42px)',
        overflow: 'auto',
        background: '#fff',
        padding: '0 0 0 8px',
        boxSizing: 'border-box',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#bebebe',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '3px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
    },
    rightBoxIn: {
        width: '100%',
        height: 173,
        background: '#eee',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 7,
        boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.25)',
        marginBottom: 10,
    },
    pdfSmall: {
        width: 78,
        height: 133,
        background: '#fff',
        marginTop: 5,
    },
    pdfNumber: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        marginTop: 7,
    },
    toasts: {
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    lineStyle: {
        width: 1,
        height: 22,
        background: '#d9d9d9',
        margin: '0 25px 0 5px',
    },
    btnStyle: {
        border: '1px solid #bfbfbf',
        borderRadius: 4,
        marginLeft: 10,
        color: '#000',
        padding: '3px 10px 3px 8px',
        fontWeight: 400,
        fontSize: '0.875rem',
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 4,
        },
    },
});

const LightTooltip = withStyles(() => ({
    tooltip: {
        padding: '4px 4px',
        border: '0.3px solid #000000',
        background: '#fffff5',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.875rem',
        color: '#000',
        borderRadius: '0',
        marginTop: 5,
    },
}))(Tooltip);

class MobilePenRecordComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: 'date',
            value: 'checkA',
        };
    }

    componentDidMount = () => {
        console.log('[PenRecordComponent] : this.props.params  : >> ', this.props.params);

        this.initPenRecordData()
            .then(() => {
                console.log('Success initPenRecordData');
            })
            .catch(() => {
                console.log('Failed initPenRecordData');
            });
    };

    initPenRecordData = async () => {
        console.log('[PenRecordComponent] : this.props.params  : >> ', this.props.params);

        const selectedGroupId = this.props.params.groupId;
        const selectedUserEmail = this.props.params.userEmail;
        const selectedPaperGroupId = this.props.params.paperGroupId;

        const nPS = this.props.neoPenStore;

        if (nPS.selectedUser !== undefined) {
            await nPS.interpreteInkstoreDataToReplayStore();
        } else {
            const user = this.props.authStore.loginUser;

            nPS.setUser(user);
            nPS.getSinglePaperGroupByPaperGroupId(selectedGroupId, selectedPaperGroupId);

            const groupLeaderRes = await nPS.getGroupLeader(user.email, selectedGroupId);

            if (groupLeaderRes !== undefined && groupLeaderRes.type === 'Leader') nPS.setIsLeader(true);

            const selectedUser = await nPS.getUserInfo(selectedUserEmail);
            nPS.selectUser(selectedUser);

            await nPS.getUserNotes([selectedUser], true);

            await nPS.interpreteInkstoreDataToReplayStore();
        }
    };

    handleChangeSort = event => {
        this.setState({ filter: event.target.value });

        this.props.neoPenStore.sortPageInfo(event.target.value);
    };

    handleChange = e => {
        const { neoPenStore } = this.props;

        this.setState({ value: e.target.value });

        if (e.target.value === 'checkA') neoPenStore.setRenderingUserStrokeId(undefined);

        if (e.target.value === 'checkB') neoPenStore.setRenderingUserStrokeId(neoPenStore.user.email, neoPenStore.isLeader);

        if (e.target.value === 'checkC') neoPenStore.setRenderingUserStrokeId(neoPenStore.user.email, !neoPenStore.isLeader);
    };

    handleClickBack = () => {
        this.props.navigate(-1);
    };

    requestCorrectionUpload = async () => {
        const { classes, neoPenStore: nPS } = this.props;
        ToastsStore.info('첨삭 업로드를 시작합니다.', 3000, classes.toasts);

        await nPS.uploadToInk();

        ToastsStore.info('첨삭 업로드가 완료되었습니다.', 3000, classes.toasts);
    };

    handleClickUpload = () => {
        console.log('handleClickUpload');
        const { classes } = this.props;

        this.requestCorrectionUpload()
            .then(() => {
                console.log('Success CorrectionUpload');
                const { groupId, userEmail, paperGroupId } = this.props.params;
                this.props.noticeStore.requestCreateNotification(groupId, userEmail, paperGroupId, NOTICE_TYPE.NoteCorrection);
            })
            .catch(() => {
                console.log('requestCorrectionUpload error');
                ToastsStore.error('첨삭 업로드를 완료하지 못했습니다. 잠시후 다시 시도해주세요.', 3000, classes.toasts);
            });
    };

    render() {
        const { classes } = this.props;
        const { orgImages } = this.props.orgStore;

        if (this.props.neoPenStore.selectedUser === undefined) return <Box />;

        const nPS = this.props.neoPenStore;

        const paperGroup = nPS.selectedPaperGroup;
        const note = nPS.getNote(nPS.selectedPaperGroup, nPS.selectedUser.email);

        console.log('note : ', note);

        if (note === undefined) return <Box />;

        let lastDateStr = '';

        if (note.lastStrokeAt !== undefined) {
            const lastDateTimestamp = note.lastStrokeAt;

            lastDateStr = nPS.convertTimestamp(lastDateTimestamp);
        }

        return (
            <div className={classes.root}>
                <Box className={classes.topBox}>
                    <Box display="flex" justifyContent="flex-end">
                        {/*<IconButton className={classes.iconButton} onClick={this.handleClickBack.bind(this)} disableRipple>*/}
                        {/*    <Close />*/}
                        {/*</IconButton>*/}
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={nPS.isLeader ? null : classes.afterContent}>
                        <Box display="flex" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
                            {/*<img src={orgImages.Logo} alt="LogoImage" className={classes.logoImage} />*/}
                            <Typography className={classes.textStyle}>필기 재생</Typography>
                        </Box>
                        {/*<Box>*/}
                        {/*    <Typography className={clsx(classes.textStyle, classes.topCenterText)}>*/}
                        {/*        {paperGroup && `${paperGroup.title.trim().replace(/(.pdf)$/, '')} (${nPS.selectedUser.name})`}*/}
                        {/*    </Typography>*/}
                        {/*</Box>*/}
                        {/*{nPS.isLeader && (*/}
                        {/*    <Box display="flex" alignItems="center" style={{ width: 360 }}>*/}
                        {/*        {nPS.penState === PenState.Connected && (*/}
                        {/*            <Button className={classes.penButton} disableRipple onClick={nPS.onConnectionChange}>*/}
                        {/*                <MinusCircleIcon />*/}
                        {/*                <span className={classes.penText}>펜 해제</span>*/}
                        {/*            </Button>*/}
                        {/*        )}*/}

                        {/*        {nPS.penState === PenState.Connecting && (*/}
                        {/*            <Button className={classes.penButton} disableRipple>*/}
                        {/*                <ArrowsClockwiseIcon />*/}
                        {/*                <span className={classes.penText}>연결중</span>*/}
                        {/*            </Button>*/}
                        {/*        )}*/}

                        {/*        {nPS.penState === PenState.Disconnected && (*/}
                        {/*            <Button className={classes.penButton} disableRipple onClick={nPS.onConnectionChange}>*/}
                        {/*                <PenPlusCircleIcon />*/}
                        {/*                <span className={classes.penText}>펜 연결</span>*/}
                        {/*            </Button>*/}
                        {/*        )}*/}

                        {/*        <Button*/}
                        {/*            className={clsx(classes.penButton, classes.penButtonRight)}*/}
                        {/*            disableRipple*/}
                        {/*            // onClick={async () => {*/}
                        {/*            //     ToastsStore.info('첨삭 업로드를 시작합니다.', 3000, classes.toasts);*/}
                        {/*            //*/}
                        {/*            //     await nPS.uploadToInk();*/}
                        {/*            //*/}
                        {/*            //     ToastsStore.info('첨삭 업로드가 완료되었습니다.', 3000, classes.toasts);*/}
                        {/*            // }}*/}
                        {/*            onClick={this.handleClickUpload}*/}
                        {/*        >*/}
                        {/*            <span className={classes.penText}>첨삭 업로드</span>*/}
                        {/*        </Button>*/}
                        {/*    </Box>*/}
                        {/*)}*/}
                    </Box>
                </Box>

                <Box display="flex" justifyContent="flex-end" className={classes.controlBox}>
                    {/*<Box display="flex" alignItems="center">*/}
                    {/*    <Typography className={clsx(classes.textStyle, classes.dateText)}>{lastDateStr}</Typography>*/}
                    {/*    {nPS.isLeader && (*/}
                    {/*        <LightTooltip title={'페이지 새로고침'} placement="bottom">*/}
                    {/*            <Button className={classes.buttonStyle} disableRipple>*/}
                    {/*                <ArrowsClockwise />*/}
                    {/*            </Button>*/}
                    {/*        </LightTooltip>*/}
                    {/*    )}*/}

                    {/*    <LightTooltip title={'필기한 기록을 다운로드'} placement="bottom">*/}
                    {/*        <Button*/}
                    {/*            className={clsx(classes.buttonStyle, classes.buttonStyleMargin)}*/}
                    {/*            disableRipple*/}
                    {/*            onClick={() => {*/}
                    {/*                nPS.savePdfWithExternalStroke();*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            <DownloadSimple />*/}
                    {/*        </Button>*/}
                    {/*    </LightTooltip>*/}

                    {/*    <Box className={classes.lineStyle} />*/}
                    {/*    <RadioGroup aria-label="position" name="position" value={this.state.value} onChange={this.handleChange} row>*/}
                    {/*        <FormControlLabel*/}
                    {/*            control={<Radio icon={<UnCheckCircleIcon />} checkedIcon={<CheckCircleIcon />} value="checkA" />}*/}
                    {/*            label="전체"*/}
                    {/*            className={classes.radioBox}*/}
                    {/*        />*/}
                    {/*        <FormControlLabel*/}
                    {/*            control={<Radio icon={<UnCheckCircleIcon />} checkedIcon={<CheckCircleIcon />} value="checkB" />}*/}
                    {/*            label="첨삭 중심으로 보기"*/}
                    {/*            className={classes.radioBox}*/}
                    {/*        />*/}
                    {/*        <FormControlLabel*/}
                    {/*            control={<Radio icon={<UnCheckCircleIcon />} checkedIcon={<CheckCircleIcon />} value="checkC" />}*/}
                    {/*            label="학생 필기 중심으로 보기"*/}
                    {/*            className={classes.radioBox}*/}
                    {/*        />*/}
                    {/*    </RadioGroup>*/}
                    {/*</Box>*/}

                    <Box display="flex" alignItems="center">
                        {/*{nPS.isLeader && <NeoPenScreenWriteComponent />}*/}

                        <Box display="flex" alignItems="center" className={classes.buttonBox}>
                            <Button className={classes.buttonStyle} disableRipple onClick={nPS.handleZoomIn}>
                                <PenRecordPlus />
                            </Button>
                            <Box className={classes.numberBox}>
                                <Typography className={classes.numberText}>{parseInt(nPS.zoom * 100)}%</Typography>
                            </Box>
                            <Button className={classes.buttonStyle} disableRipple onClick={nPS.handleZoomOut}>
                                <PenRecordMinus />
                            </Button>

                            <Button className={classes.buttonStyle} disableRipple onClick={() => nPS.setZoomFitType(ZoomFitEnum.WIDTH)}>
                                <WidthView />
                            </Button>

                            <Button className={classes.buttonStyle} disableRipple onClick={() => nPS.setZoomFitType(ZoomFitEnum.HEIGHT)}>
                                <HeightView />
                            </Button>
                        </Box>

                        <Box>
                            {/*<FormControl className={classes.formControl}>*/}
                            {/*    <Select*/}
                            {/*        value={this.state.filter}*/}
                            {/*        displayEmpty*/}
                            {/*        onChange={this.handleChangeSort}*/}
                            {/*        IconComponent={() => (*/}
                            {/*            <Box style={{ width: 16, height: 16, marginLeft: 5 }}>*/}
                            {/*                <ArrowDownIcon />*/}
                            {/*            </Box>*/}
                            {/*        )}*/}
                            {/*    >*/}
                            {/*        <MenuItem value="date" className={classes.menuItem}>*/}
                            {/*            최근 작성일 순*/}
                            {/*        </MenuItem>*/}
                            {/*        <MenuItem value="alpha" className={classes.menuItem}>*/}
                            {/*            페이지 번호순*/}
                            {/*        </MenuItem>*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}
                        </Box>
                    </Box>
                </Box>

                {/*<Box display="flex">*/}
                <Box className={classes.contentsLeftBox}>
                    <MobileReplayWindowMain />
                </Box>
                {/*</Box>*/}
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('orgStore', 'neoPenStore', 'classStore', 'authStore', 'userStore', 'noticeStore')(observer(MobilePenRecordComponent))),
);
