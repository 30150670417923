import { inject, observer } from 'mobx-react';
import React from 'react';
import { isSamePage, makeNPageIdStr, PLAYSTATE, ViewControlSample } from 'nl-lib';
// import { isSamePage, makeNPageIdStr, PLAYSTATE, ViewControlSample } from '../../nl-lib';
import { Button, Tooltip, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ViewerType } from '../../stores/NeoPenStore';
import clsx from 'clsx';
import { ReactComponent as Mathematics } from '../../common/images/Mathematics.svg';

const styles = () => ({
    videoBox: {
        width: '100%',
        height: 195,
        boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.25)',
        background: '#f5f5f5',
        margin: 'auto',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box',
        '&:hover': {
            background: '#f5f5f5',
        },
    },

    svgColor: {},
    videoBoxSelected: {
        height: 195,
        border: '3px solid #6e75d7',
        background: '#f5f5f5',
        margin: 'auto',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box',
        boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            background: '#f5f5f5',
        },
        // '@media screen and (max-width: 1300px)': {
        //     height : 50,
        //     width: '100%',
        //     overflowX: 'scroll',
        //     margin: '5px auto 0',
        // },
    },
    pdfBoxStyle: {
        height: 35,
        margin: '2px auto',
        padding: '3.5px 5.4px 5.2px 6px',
        borderRadius: 6,
        backgroundColor: '#eaeafa',
    },

    pdfBoxHoverStyle: {
        height: 35,
        margin: '2px auto',
        padding: '3.5px 5.4px 5.2px 6px',
        borderRadius: 6,
        cursor: 'pointer',
        backgroundColor: '#e6e6e6',
    },

    pdfBoxStyle1: {
        height: 35,
        margin: '2px auto',
        padding: '3.5px 5.4px 5.2px 6px',
        borderRadius: 6,
        // backgroundColor: '#eaeafa',
    },
    viewerBox: {
        margin: '8px auto 0',
    },

    textStyle2: {
        fontFamily: 'Noto Sans KR',
        fontSize: 5,
        color: '#fff',
        fontWeight: 500,
    },

    textStyle1: {
        fontFamily: 'Noto Sans KR',
        fontSize: 5,
        color: '#777',
        fontWeight: 500,
    },

    listHeader: {
        display: 'flex',
        flexBasis: '100%',
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.35)',
        fontSize: '12px',
        margin: '8px 0px',
        minWidth: 'max-content',
        '&:before': {
            content: "''",
            flexGrow: '1',
            background: 'rgba(0, 0, 0, 0.35)',
            height: '1px',
            fontSize: '0px',
            lineHeight: '0px',
            margin: '0px 16px',
        },
        '&:after': {
            content: "''",
            flexGrow: '1',
            background: 'rgba(0, 0, 0, 0.35)',
            height: '1px',
            fontSize: '0px',
            lineHeight: '0px',
            margin: '0px 16px',
        },
    },

    listHeaderSelected: {
        display: 'flex',
        flexBasis: '100%',
        alignItems: 'center',
        color: '#d93d1a',
        fontSize: '12px',
        margin: '8px 0px',
        minWidth: 'max-content',
        '&:before': {
            content: "''",
            flexGrow: '1',
            background: '#d93d1a',
            height: '1px',
            fontSize: '0px',
            lineHeight: '0px',
            margin: '0px 16px',
        },
        '&:after': {
            content: "''",
            flexGrow: '1',
            background: '#d93d1a',
            height: '1px',
            fontSize: '0px',
            lineHeight: '0px',
            margin: '0px 16px',
        },
    },

    pdfText: {
        color: '#222222',
        padding: '0 5px',
        width: 80,
    },
    pdfTextClick: {
        color: '#222222',
        padding: '0 5px',
        fontWeight: 'bold',
        width: 80,
    },
    textStyle: {
        fontFamily: 'Noto Sans KR',
        fontSize: '12px',
        textAlign: 'left',
    },
    pdfStyle: {
        width: 15,
        height: 15,
        borderRadius: '50%',
        background: '#6e75d7',
        margin: 'auto',
    },
    pdfStyle1: {
        width: 25,
        height: 15,
        borderRadius: 7,
        background: '#6e75d7',
        margin: 'auto',
    },
    scrollStyle: {
        width: '100%',
        height: 'inherit',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingBottom: 5,
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 10,
            marginBottom: 10,
        },
    },
    scrollStyleLandscape: {
        '@media screen and (max-width: 1280px)': {
            width: '100%',
            height: 'inherit',
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#bebebe',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 10,
            marginBottom: 10,
        },
    },
    boxStyle: {
        width: '100%',
        height: 195,
        background: '#f5f5f5',
        padding: 9,
        boxSizing: 'border-box',
        boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.25)',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginBottom: 15,
        '&::-webkit-scrollbar': {
            width: '3px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 10,
            marginBottom: 10,
        },
    },
    boxTitle: {
        borderBottom: '1px solid #d3d7db',
        marginBottom: 5,
    },
    boxTitleText: {
        fontSize: '0.75rem',
        fontWeight: 600,
        marginBottom: 5,
    },
    listBox: {
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        '&:hover': {
            background: '#d3d7db',
        },
    },
    selectListBox: {
        background: '#eaeafa',
        '& path': {
            fill: '#6E75D7',
        },
        '& p': {
            fontWeight: 600,
        },
        '&:hover': {
            background: '#eaeafa',
        },
    },
    listText: {
        width: 87,
        fontSize: '0.75rem',
    },
    popoverBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 5px 7px 0 rgba(0, 0, 0, 0.45)',
            borderRadius: 4,
        },
        '& .MuiMenuItem-root': {
            justifyContent: 'flex',
            padding: '10px 28px',
            fontFamily: 'Noto Sans KR',
            color: '#6c6c70',
            fontSize: 15,
            '&:hover': {
                background: '#e8e8e8',
            },
        },
    },
});

class NeoPenPageViewer extends React.Component {
    state = {
        anchorEl: null,
        pdfMoreBtn: false,
    };

    pageList = [];
    listRef = React.createRef();
    pdfMoreButtonRef = React.createRef();

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.listRef !== undefined && this.listRef.current) this.listRef.current.scrollToItem(this.activePageIndex, 'smart');
    }

    handleClickView = pageInfo => {
        const nPS = this.props.neoPenStore;

        console.log('handleClickView', pageInfo);

        nPS.setActivePage(pageInfo);
    };

    handleMoreChangeOpen = event => {
        this.setState({
            pdfMoreBtn: true,
            anchorEl: event.currentTarget,
        });
    };

    handleMoreChangeClose = () => {
        this.setState({
            pdfMoreBtn: false,
            anchorEl: null,
        });
    };

    renderRow = ({ data, index, style }) => {
        const { classes, isMobile } = this.props;
        const nPS = this.props.neoPenStore;

        const pens = [...nPS.pens];
        const surfaceOwnerId = nPS.surfaceOwnerId;

        const pageInfoString = makeNPageIdStr(data[index].pageInfo) + surfaceOwnerId;

        const isSelected = data[index].isSelected;

        let pageNum = data[index].pageInfo.page + 1;

        if (nPS.viewerType === ViewerType.Normal) {
            pageNum -= nPS.selectedPaperGroup.pageStart;
        } else if (nPS.viewerType === ViewerType.RealTime) {
            const paperGroup = nPS.getPaperGroup(data[index].pageInfo);

            pageNum -= paperGroup.pageStart;
        }

        let pageImgUrl = nPS.thumbnailUrlWithSobp[makeNPageIdStr(data[index].pageInfo)];

        return (
            <Box key={pageInfoString + 'Box1' + index} style={style} onClick={() => this.handleClickView(data[index].pageInfo)}>
                <Box
                    key={pageInfoString + 'Box2' + index}
                    className={isSelected ? classes.videoBoxSelected : classes.videoBox}
                    style={{ pointerEvents: 'none' }}
                >
                    {pageImgUrl &&
                        <Box
                          style={{width:"100%", height:"100%", objectFit:"contain"}}
                          component="img"
                          alt="page viewer"
                          src={pageImgUrl}
                        />
                    }
                </Box>

                {!isSelected ? (
                    <Typography key={pageInfoString + 'PageTypography'} className={classes.textStyle1} style={{ textAlign: 'center' }}>
                        {pageNum}
                    </Typography>
                ) : pageNum <= 99 ? (
                    <div className={classes.pdfStyle}>
                        <Typography key={pageInfoString + 'PageTypography' + index} className={classes.textStyle2} style={{ textAlign: 'center' }}>
                            <b>{pageNum}</b>
                        </Typography>
                    </div>
                ) : (
                    <div className={classes.pdfStyle1}>
                        <Typography key={pageInfoString + 'PageTypography' + index} className={classes.textStyle2} style={{ textAlign: 'center' }}>
                            <b>{pageNum}</b>
                        </Typography>
                    </div>
                )}
            </Box>
        );
    };

    render() {
        const { classes, isMobile } = this.props;
        const nPS = this.props.neoPenStore;

        this.pageList = [];
        let contents = [];
        if (nPS.viewerType === ViewerType.Normal && nPS.pageInfos === undefined) return <Box />;

        if (nPS.viewerType === ViewerType.Normal) {
            nPS.pageInfos.forEach(pageInfo => {
                let isSelected = false;

                if (isSamePage(pageInfo, nPS.activePage)) {
                    isSelected = true;

                    this.activePageIndex = this.pageList.length;
                }

                this.pageList.push({ pageInfo: pageInfo, isSelected: isSelected });
            });
        } else if (nPS.viewerType === ViewerType.RealTime) {
            console.log('nPS.realTimePaperGroups', nPS.realTimePaperGroups);

            let selectedPaperGroup = undefined;

            nPS.realTimePaperGroups.forEach(paperGroup => {
                if (nPS.isStrokeInPaperGroup(nPS.activePage, paperGroup)) {
                    selectedPaperGroup = paperGroup;

                    contents.push(
                        <Tooltip title={paperGroup.title}>
                            <Button className={clsx(classes.listBox, classes.selectListBox)} disableRipple>
                                <Mathematics />
                                <Typography className={classes.listText} noWrap>
                                    {paperGroup.title}
                                </Typography>
                            </Button>
                        </Tooltip>,
                    );
                } else {
                    contents.push(
                        <Tooltip title={paperGroup.title}>
                            <Button
                                className={clsx(classes.listBox)}
                                disableRipple
                                onClick={() => {
                                    for (let pageInfo of nPS.realTimePageInfos) {
                                        if (nPS.isStrokeInPaperGroup(pageInfo, paperGroup)) {
                                            nPS.setActivePage(pageInfo);

                                            break;
                                        }
                                    }
                                }}
                            >
                                <Mathematics />
                                <Typography className={classes.listText} noWrap>
                                    {paperGroup.title}
                                </Typography>
                            </Button>
                        </Tooltip>,
                    );
                }
            });

            nPS.realTimePageInfos.forEach(pageInfo => {
                let isSelected = false;

                if (isSamePage(pageInfo, nPS.activePage)) {
                    isSelected = true;

                    this.activePageIndex = this.pageList.length;
                }

                if (selectedPaperGroup !== undefined && nPS.isStrokeInPaperGroup(pageInfo, selectedPaperGroup)) {
                    this.pageList.push({ pageInfo: pageInfo, isSelected: isSelected });
                }
            });
        }

        let pdfHeight = 215;

        if (nPS.viewerType === ViewerType.Normal) pdfHeight = 0;

        let thumbnailLoadedLen = Object.keys(nPS.thumbnailUrlWithSobp).length;

        console.log("thumbnailLoadedLen", thumbnailLoadedLen);

        return (
            <Box display="flex" flexDirection="column" className={classes.scrollStyleLandscape}>
                {/*<div style={{ height: `calc(100vh -  54px - ${pdfHeight}px)` }}>*/}
                <div style={{ height: `calc(((100vh - 81px) - 54px) - ${pdfHeight}px)` }}>
                    {/* 내필기 리스트 추가 */}
                    {nPS.viewerType === ViewerType.RealTime && (
                        <Box className={classes.boxStyle}>
                            <Box className={classes.boxTitle}>
                                <Typography className={classes.boxTitleText}>내필기</Typography>
                            </Box>

                            {contents.length > 0 && contents}
                        </Box>
                    )}

                    <AutoSizer disableWidth>
                        {({ height }) => (
                            <List
                                className={classes.scrollStyle}
                                ref={this.listRef}
                                width={'100%'}
                                height={height}
                                itemSize={220}
                                itemCount={this.pageList.length}
                                itemData={this.pageList}
                            >
                                {this.renderRow}
                            </List>
                        )}
                    </AutoSizer>
                </div>
            </Box>
        );
    }
}

export default withStyles(styles)(inject('neoPenStore')(observer(NeoPenPageViewer)));
