import React, { useEffect, useRef } from 'react';
import { UTIL } from '../../../common/util/common.util';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(_theme => ({
    textField: {
        padding: '5px 3px',
        border: '1px solid #c4c4c4',
        borderRadius: 3,
    },
}));

function MobileReferenceRoomModifyInput(props) {
    const classes = useStyles();
    const textInput = useRef(null);

    useEffect(() => {
        if (textInput) {
            textInput.current.focus();
        }
    }, [textInput]);

    const handleChange = e => {
        e.stopPropagation();
        props.changeEditName(e);
    };

    const handleKeydown = e => {
        e.stopPropagation();
        props.modifyName(e);
    };

    const handleBlur = e => {
        props.cancelEditName(e);
    };

    const handleClick = e => {
        e.stopPropagation();
    };

    const handleSelectInputValue = () => {
        if (textInput) {
            textInput.current.select();
        }
    };

    return (
        <div>
            <input
                ref={textInput}
                type="text"
                className={classes.textField}
                defaultValue={UTIL.getDotSplitFileName(props.name).name}
                onClick={handleClick}
                onChange={handleChange}
                onKeyDown={handleKeydown}
                onBlur={handleBlur}
                onFocus={handleSelectInputValue}
            />
        </div>
    );
}

export default MobileReferenceRoomModifyInput;
