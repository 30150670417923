import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 490,
            padding: 24,
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 30,
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        marginLeft: 7,
    },
    iconButton: {
        padding: 5,
        '&:hover': {
            background: 'transparent',
        },
    },
    buttonStyle: {
        width: '100%',
        height: 48,
        borderRadius: 8,
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        fontWeight: 500,
        marginTop: 30,
        '&:hover': {
            background: '#0097ff',
        },
    },
});

class InviteEmailSendDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Dialog open={this.props.inviteLink} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.titleBox}>
                        <Typography className={classes.titleText}>초대장</Typography>
                        <IconButton className={classes.iconButton} onClick={this.props.handleCloseInviteLinkDialog} disableRipple>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography variant="body1">모든 참석자들에게 초대 이메일을 발송하시겠습니까?</Typography>
                    </Box>
                    <Box>
                        <Button className={classes.buttonStyle} onClick={this.props.handleCloseInviteLinkDialog} disableRipple>
                            초대 이메일 보내기
                        </Button>
                    </Box>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(InviteEmailSendDialogComponent);
