import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import {
    fetchGzippedFileWithBlob,
    generatePodV2,
    IBrushType,
    InkStorage,
    isSamePage,
    makeInkStoreStrokeFromNeoStroke,
    makeNPageIdStr,
    manualRegisterAtMappingStorage,
    NeoStroke,
    PenEventName,
    PenManager,
    ReplayStorage,
    retreivePaperInfoFromNproj,
    savePDF,
    StrokeStatus,
    ZoomFitEnum,
    NeoPdfManager,
} from 'nl-lib';
// } from '../nl-lib';
import * as store from './AuthStore';
import printJS from 'print-js';
import { saveAs } from 'file-saver';
import emojiRegex from "emoji-regex";

export const PrintState = {
    Ready: 'Ready',
    Printing: 'Printing',
    Finished: 'Finished',
};

export const PenProtocol = {
    Connected: 'Connected',
    Disconnected: 'Disconnected',
    ConnectFail: 'ConnectFail',
    ConnectTry: 'ConnectTry',
    PenStatus: 'PenStatus',
    LowBattery: 'LowBattery',
    PowerOff: 'PowerOff',
    NotConnected: 'NotConnected',
};

export const PenState = {
    Disconnected: 'Disconnected',
    Connecting: 'Connecting',
    Connected: 'Connected',
    Using: 'Using',
};

export const OfflineDataUploadState = {
    None: 'None',
    Connecting: 'Connecting',
    Connected: 'Connected',
    Uploading: 'Uploading',
    UploadFinish: 'UploadFinish',
    UploadNone: 'UploadNone',
};

export const SelectNeolabNoteState = {
    None: 'None',
    Getting: 'Getting',
    Finish: 'Finish',
};

export const ViewerType = {
    Normal: 'Normal',
    RealTime: 'RealTime',
};

export const MovingObjectType = {
    Folder: 'Folder',
    PaperGroup: 'PaperGroup',
};

export const PaperGroupType = {
    Study: 'Study',
    Exam: 'Exam',
}

const logPrefix = '[NeoPenStore]';

export default class NeoPenStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;

        makeAutoObservable(this);
    }

    surfaceOwnerId = undefined;
    user = undefined;

    zoomFitType = ZoomFitEnum.HEIGHT;
    zoom = 1;

    pageInfos = [];
    realTimePageInfos = [];

    selectedPdf = undefined;

    activePage = undefined;

    paperGroupsWithCorrection = [];
    paperGroups = [];
    activePaperGroups = [];
    publicPaperGroups = [];

    selectedPaperGroup = undefined;
    selectedUser = undefined;
    selectedGroup = undefined;

    loadedPaperGroupId = [];

    pens = [];

    groups = [];
    allUsersWithGroupId = {};

    userNotes = {};
    singleUserAllNotes = [];
    groupUserAllNotes = {};

    isInit = false;

    offlineDatas = {};

    paperAttachments = {};

    printState = {};
    printProgress = {};
    printingPaperGroups = [];

    replayStorage = new ReplayStorage();
    forceDeltaTime = 0;

    macAddress = '';

    isUploadingPdf = false;

    offlineDataUploadStateQueue = [];
    offlineDataUploadState = OfflineDataUploadState.None;
    offlineDataUploadTooltipOpened = false;

    color = '#000000';
    thickness = 0.45;
    brushType = IBrushType.PEN;
    isLaserEnabled = false;

    penState = PenState.Disconnected;

    renderingUserStrokeId = undefined;

    opacityConverterFunc = undefined;

    selectNeolabNoteDialogOpen = false;
    selectNeolabNoteState = SelectNeolabNoteState.None;
    selectNeolabNoteMsg = '전용 노트의 한 페이지를 펜으로 눌러주세요.';
    selectNeolabNoteTitle = '';
    selectNeolabNotePaperGroup = undefined;
    selectNeolabNoteThumbnailUrl = undefined;

    viewerType = ViewerType.Normal;
    realTimePaperGroups = [];
    allPaperGroups = [];

    folders = {};
    currentFolder = { id: null, itemCount: 0, childIds: [] };

    lastUploadTime = '';

    autoUploadTimeout = undefined;

    isSelectFolderDialogOpened = false;
    selectedObjectIdToMove = undefined;
    movingObjectType = undefined;

    realTimeViewAnchorEl = null;

    isLeader = false;
    groupUsers = [];

    neoSobpPdfDoc = {};
    thumbnailUrlWithSobp = {};

    initPaperGroupsWithCorrection = () => {
        this.paperGroupsWithCorrection = [];
    };

    changeInitState = state => {
        this.isInit = state;
    };

    initNeoPen(user, macAddress) {
        if (this.isInit && this.user.email === user.email) return;

        this.isInit = true;

        console.log('initNeoPen', user, macAddress);

        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('SetUserId', user.email);

            window.flutter_inappwebview.callHandler('SetPenMacAddress', macAddress); // Logout 해야함
        }

        window.onOfflineDataNone = this.onOfflineDataNone;
        window.onOfflineDataStart = this.onOfflineDataStart;
        window.onOfflineDataEnd = this.onOfflineDataEnd;
        window.onOfflineData = this.onOfflineData;

        window.onPenMsg = this.onPenMessage;

        this.macAddress = macAddress;
        this.user = user;
        this.surfaceOwnerId = user.email;

        const pM = PenManager.getInstance();

        pM.addEventListener(PenEventName.ON_CONNECTED, this.onPenConnected);
        pM.addEventListener(PenEventName.ON_DISCONNECTED, this.onPenDisconnected);

        const is = InkStorage.getInstance();
        is.addEventListener(PenEventName.ON_PEN_DOWN, this.onPenDown, undefined);

        this.offlineDataUploadTooltipOpened = true;
    }

    selectPdf = pdf => (this.selectedPdf = pdf);

    *uploadPdf(title, groupId, folderId, requestUserEmail, isPublic, type) {
        this.isUploadingPdf = true;

        let bodyFormData = new FormData();

        bodyFormData.append('pdfFile', this.selectedPdf, title.trim() + '.pdf');

        let path = `/api/v1/neolab/paper/${groupId}?requestUserEmail=${requestUserEmail}&isPublic=${isPublic}&type=${type}`;

        if (folderId !== null) path += `&folderId=${folderId}`;

        try {
            yield axios.post(this.serverContextPath + path, bodyFormData);
        } catch {
            this.isUploadingPdf = false;

            return '서식 등록에 실패했습니다.';
        }

        yield this.getAllPaperGroups(groupId, requestUserEmail);

        this.isUploadingPdf = false;

        return '서식 등록이 완료되었습니다.';
    }

    *uploadPaperGroup(groupId, folderId, requestUserEmail, paperGroup, title, type) {
        let uploadedPaperGroup = this.getPaperGroupByPaperGroupId(paperGroup.id);

        this.isUploadingPdf = true;

        if (uploadedPaperGroup !== undefined) {
            if (!uploadedPaperGroup.deleted) {
                this.isUploadingPdf = false;

                return '이미 등록된 서식입니다.';
            }

            uploadedPaperGroup.title = title;
            uploadedPaperGroup.folderId = folderId;
            uploadedPaperGroup.deleted = false;
            uploadedPaperGroup.type = type;

            yield axios.put(this.serverContextPath + `/api/v1/group/smartpen/papergroup?requestUserEmail=${this.user.email}`, uploadedPaperGroup);

            yield this.getAllPaperGroups(groupId, requestUserEmail);

            this.isUploadingPdf = false;

            return '서식 등록이 완료되었습니다.';
        }

        console.log('uploadPaperGroup', {
            id: paperGroup.id,
            section: paperGroup.section,
            owner: paperGroup.owner,
            bookCode: paperGroup.bookCode,
            pageStart: paperGroup.pageStart,
            pageEnd: paperGroup.pageEnd,
            title: title,
            tag: '',
        });

        let path = `/api/v1/neolab/paper/neolab-note/${groupId}?requestUserEmail=${requestUserEmail}&type=${type}`;

        if (folderId !== null) path += `&folderId=${folderId}`;

        yield axios.post(this.serverContextPath + path, {
            id: paperGroup.id,
            section: paperGroup.section,
            owner: paperGroup.owner,
            bookCode: paperGroup.bookCode,
            pageStart: paperGroup.pageStart,
            pageEnd: paperGroup.pageEnd,
            title: title,
            tag: '',
            attachments: null,
        });

        yield this.getAllPaperGroups(groupId, requestUserEmail);

        this.isUploadingPdf = false;

        return '서식 등록이 완료되었습니다.';
    }

    setZoomFitType = fitType => (this.zoomFitType = fitType);

    handleZoomIn = () => {
        this.zoomFitType = ZoomFitEnum.FREE;

        if (this.zoom * 1.2 < 3.0) {
            this.zoom *= 1.2;
        }
    };

    handleZoomOut = () => {
        this.zoomFitType = ZoomFitEnum.FREE;

        if (this.zoom / 1.2 > 0.25) {
            this.zoom /= 1.2;
        }
    };

    onZoomChanged = zoom => {
        this.zoom = zoom;
    };

    sortPageInfo = sortingMethod => {
        if (sortingMethod === 'alpha') {
            this.pageInfos.sort((infoA, infoB) =>
                infoA.section !== infoB.section
                    ? infoA.section - infoB.section
                    : infoA.owner !== infoB.owner
                    ? infoA.owner - infoB.owner
                    : infoA.book !== infoB.book
                    ? infoA.book - infoB.book
                    : infoA.page !== infoB.page
                    ? infoA.page - infoB.page
                    : 1,
            );

            this.realTimePageInfos.sort((infoA, infoB) =>
                infoA.section !== infoB.section
                    ? infoA.section - infoB.section
                    : infoA.owner !== infoB.owner
                    ? infoA.owner - infoB.owner
                    : infoA.book !== infoB.book
                    ? infoA.book - infoB.book
                    : infoA.page !== infoB.page
                    ? infoA.page - infoB.page
                    : 1,
            );
        }

        if (sortingMethod === 'date') {
            this.pageInfos.sort((infoA, infoB) => {
                if (infoA.lastModified === undefined) return 1;

                if (infoB.lastModified === undefined) return -1;

                return infoB.lastModified - infoA.lastModified;
            });

            this.realTimePageInfos.sort((infoA, infoB) => {
                if (infoA.lastModified === undefined) return 1;

                if (infoB.lastModified === undefined) return -1;

                return infoB.lastModified - infoA.lastModified;
            });
        }
    };

    getAllPaperGroupsWholeGroup = async () => {
        for (let group of this.groups) {
            const paperGroupRes = await axios.get(
                this.serverContextPath + `/api/v1/group/smartpen/papergroup/${group.id}/all?requestUserEmail=${this.user.email}`,
                { requestUserEmail: this.user.userEmail },
            );

            const paperGroups = paperGroupRes.data;

            this.allPaperGroups.push(...paperGroups);
        }

        console.log('allPaperGroups', this.allPaperGroups);
    };

    *getPaperGroupsWithCorrection(groupId, requestUserEmail) {
        console.log('getPaperGroupsWithCorrection start ... ', groupId, requestUserEmail);

        const response = yield axios.get(
            this.serverContextPath + `/api/v1/group/smartpen/papergroup/${groupId}/withcorrection?requestUserEmail=${requestUserEmail}`,
            {
                requestUserEmail: requestUserEmail,
            },
        );

        this.paperGroupsWithCorrection = response.data;
        // this.activePaperGroups = this.paperGroups.filter(paperGroup => !paperGroup.deleted);
        // this.publicPaperGroups = this.activePaperGroups.filter(paperGroup => paperGroup.public);

        console.log('getPaperGroupsWithCorrection fin ... ', response.data, this.paperGroups);

        this.sortPaperGroup('date');
    }

    *getAllPaperGroups(groupId, requestUserEmail) {
        console.log('getAllPaperGroups start ... ', groupId, requestUserEmail);

        const response = yield axios.get(
            this.serverContextPath + `/api/v1/group/smartpen/papergroup/${groupId}/all?requestUserEmail=${requestUserEmail}`,
            {
                requestUserEmail: requestUserEmail,
            },
        );

        this.paperGroups = response.data;
        this.activePaperGroups = this.paperGroups.filter(paperGroup => !paperGroup.deleted);
        this.publicPaperGroups = this.activePaperGroups.filter(paperGroup => paperGroup.public);

        console.log('getAllPaperGroups fin ... ', response.data, this.activePaperGroups);

        this.sortPaperGroup('date');
    }

    *getSinglePaperGroupByPaperGroupId(groupId, paperGroupId) {
        console.log('getPaperGroupByPaperGroupId start ... ', paperGroupId);

        const response = yield axios.get(
            this.serverContextPath +
                `/api/v1/group/smartpen/papergroup/id?paperGroupId=${paperGroupId}&groupId=${groupId}&requestUserEmail=${this.user.email}`,
        );

        const paperGroup = response.data;

        console.log('paperGroup : ', paperGroup);

        this.paperGroups.push(paperGroup);
        this.selectPaperGroup(paperGroup);
    }

    interpreteInkstoreData = async () => {
        const note = this.getNote(this.selectedPaperGroup, this.selectedUser.email);

        console.log('getNote', note);

        if (note === undefined) return;

        let noteUUID = note.id;

        if (noteUUID === undefined) return;

        const response = await axios.get(
            this.serverContextPath + `/api/v1/neolab/ink/${noteUUID}?requestUserEmail=${this.user.email}&userEmail=${this.selectedUser.email}`,
        );

        console.log('interpreteInkstoreData response', response.data);

        const ink = InkStorage.getInstance();

        ink.fromInkStore(response.data, true);

        console.log('fromInkStore process fin');

        for (let i = 0; i < this.pageInfos.length; i++) {
            const sobpStr = makeNPageIdStr(this.pageInfos[i]);

            if (ink.pageEndStroke.has(sobpStr)) this.pageInfos[i].lastModified = ink.pageEndStroke.get(sobpStr).startTime;
        }

        this.sortPageInfo('date');

        if(this.pageInfos.length > 0) {
            this.setActivePage(this.pageInfos[0]);
        }

        this.renewLastUploadTime();
    };

    interpreteInkstoreDataToReplayStore = async () => {
        this.renderingUserStrokeId = undefined;

        const note = this.getNote(this.selectedPaperGroup, this.selectedUser.email);

        console.log('getNote', note);

        if (note === undefined) return;

        let noteUUID = note.id;

        if (noteUUID === undefined) return;

        let neoStrokes = [];

        const response = await axios.get(
            this.serverContextPath + `/api/v1/neolab/ink/${noteUUID}?requestUserEmail=${this.user.email}&userEmail=${this.selectedUser.email}`,
        );

        console.log('interpreteInkstoreDataToReplayStore response', response.data);

        for (let i = 0; i < response.data.length; i++) {
            const { section, owner, bookCode: book, pageNumber: page } = response.data[i];
            const pageInfo = { section, owner, book, page };

            for (let j = 0; j < response.data[i].strokes.length; j++) {
                let stroke = response.data[i].strokes[j];

                const neoStroke = NeoStroke.fromInkstoreStroke({ stroke, pageInfo });

                neoStrokes.push(neoStroke);
            }
        }

        if (neoStrokes.length === 0) return;

        let tempReplayStorage = new ReplayStorage();

        tempReplayStorage.fromNeoStrokes(neoStrokes, 5 * 60 * 1000);

        this.replayStorage = tempReplayStorage;

        neoStrokes.sort((a, b) => b.startTime - a.startTime);

        this.forceDeltaTime = neoStrokes[0].startTime;

        this.sortPageInfo('date');
    };
    interpreteInkstoreDataContinue = async paperGroup => {
        const note = this.getNote(paperGroup, this.selectedUser.email);

        if (note === undefined) return;
        if (note.id === undefined) return;

        const response = await axios.get(
            this.serverContextPath + `/api/v1/neolab/ink/${note.id}?requestUserEmail=${this.user.email}&userEmail=${this.selectedUser.email}`,
        );

        console.log('interpreteInkstoreDataContinue response', response.data);

        if (!response.data || response.data.length === 0) return;

        const ink = InkStorage.getInstance();

        ink.fromInkStore(response.data, false);

        console.log('fromInkStore process fin');

        const newPageInfos = [];

        for (let sobpStr of ink.pageEndStroke.keys()) {
            let isContained = false;

            for (let realTimePageInfo of this.realTimePageInfos) {
                if (sobpStr === makeNPageIdStr(realTimePageInfo)) isContained = true;
            }

            if (!isContained) {
                const sobp = sobpStr.split('.');

                newPageInfos.push({
                    section: parseInt(sobp[0]),
                    owner: parseInt(sobp[1]),
                    book: parseInt(sobp[2]),
                    page: parseInt(sobp[3]),
                });
            }

            console.log('sobpStr', sobpStr, isContained);
        }

        this.realTimePageInfos.push(...newPageInfos);

        console.log('this.realTimePageInfos', this.realTimePageInfos);

        for (let i = 0; i < this.realTimePageInfos.length; i++) {
            const sobpStr = makeNPageIdStr(this.realTimePageInfos[i]);

            if (ink.pageEndStroke.has(sobpStr)) this.realTimePageInfos[i].lastModified = ink.pageEndStroke.get(sobpStr).startTime;
        }

        this.sortPageInfo('date');
    };

    setActivePage = pageInfo => {
        if(pageInfo.section === -1) {
            setTimeout(() => {
                this.activePage = {
                    section : this.activePage.section,
                    owner : this.activePage.owner,
                    book : this.activePage.book,
                    page : this.activePage.page,
                }
            });

            return;
        }


        console.log('setActivePage ...', pageInfo);

        if (!isSamePage(pageInfo, this.activePage)) {
            this.activePage = pageInfo;

            console.log('setActivePage changed ', this.activePage);
        }
    };

    selectUser = user => {
        console.log('neo pen store selectUser', user);
        this.selectedUser = user;
    };

    selectPaperGroup(paperGroup) {
        if (paperGroup === undefined) {
            this.activePage = undefined;
            this.pageInfos = [];
            this.selectedPaperGroup = undefined;

            return;
        }

        this.selectedPaperGroup = paperGroup;

        this.setActivePage({
            section: paperGroup.section,
            owner: paperGroup.owner,
            book: paperGroup.bookCode,
            page: paperGroup.pageStart,
        });

        this.pageInfos = [];

        for (let page = paperGroup.pageStart; page <= paperGroup.pageEnd; page++) {
            const sobp = {
                section: paperGroup.section,
                owner: paperGroup.owner,
                book: paperGroup.bookCode,
                page: page,
            };

            this.pageInfos.push(sobp);
        }

        console.log('selectPaperGroup : ', this.selectedPaperGroup);
    }

    deletePaperGroup = async () => {
        const paperGroup = this.selectedPaperGroup;

        console.log('deletePaperGroup', paperGroup);

        for (let i = 0; i < this.paperGroups.length; i++) {
            if (this.paperGroups[i].paperGroupId === paperGroup.paperGroupId) {
                this.paperGroups[i].deleted = true;
            }
        }

        for (let i = 0; i < this.activePaperGroups.length; i++) {
            if (this.activePaperGroups[i].paperGroupId === paperGroup.paperGroupId) {
                this.activePaperGroups.splice(i, 1);
            }
        }

        for (let i = 0; i < this.publicPaperGroups.length; i++) {
            if (this.publicPaperGroups[i].paperGroupId === paperGroup.paperGroupId) {
                this.publicPaperGroups.splice(i, 1);
            }
        }

        paperGroup.deleted = true;

        await axios.put(this.serverContextPath + `/api/v1/group/smartpen/papergroup?requestUserEmail=${this.user.email}`, paperGroup);
    };

    updatePaperGroupPublicState = async () => {
        const paperGroup = this.selectedPaperGroup;

        const isPublic = !paperGroup.public;

        this.selectedPaperGroup.public = isPublic;

        if (isPublic) {
            this.publicPaperGroups.push(paperGroup);
        } else {
            for (let i = 0; i < this.publicPaperGroups.length; i++) {
                if (this.publicPaperGroups[i].paperGroupId === paperGroup.paperGroupId) {
                    this.publicPaperGroups.splice(i, 1);
                }
            }
        }

        console.log('updatePaperGroupPublicState', paperGroup, isPublic);

        for (let i = 0; i < this.paperGroups.length; i++) {
            if (this.paperGroups[i].paperGroupId === paperGroup.paperGroupId) {
                this.paperGroups.public = isPublic;
            }
        }

        paperGroup.public = isPublic;

        await axios.put(this.serverContextPath + `/api/v1/group/smartpen/papergroup?requestUserEmail=${this.user.email}`, paperGroup);
    };

    getPaperGroup(sobp) {
        console.log('getPaperGroup', sobp);

        if (this.viewerType === ViewerType.RealTime) {
            for (let i = 0; i < this.allPaperGroups.length; i++) {
                if (
                    this.allPaperGroups[i].section === sobp.section &&
                    this.allPaperGroups[i].owner === sobp.owner &&
                    this.allPaperGroups[i].bookCode === sobp.book &&
                    this.allPaperGroups[i].pageStart <= sobp.page &&
                    this.allPaperGroups[i].pageEnd >= sobp.page
                ) {
                    return this.allPaperGroups[i];
                }
            }
        }

        if (this.singleUserAllNotes.length > 0) {
            for (let n of this.singleUserAllNotes) {
                if (
                    n.paperGroup.section === sobp.section &&
                    n.paperGroup.owner === sobp.owner &&
                    n.paperGroup.bookCode === sobp.book &&
                    n.paperGroup.pageStart <= sobp.page &&
                    n.paperGroup.pageEnd >= sobp.page
                ) {
                    return n.paperGroup;
                }
            }
        }

        for (let i = 0; i < this.paperGroups.length; i++) {
            if (
                this.paperGroups[i].section === sobp.section &&
                this.paperGroups[i].owner === sobp.owner &&
                this.paperGroups[i].bookCode === sobp.book &&
                this.paperGroups[i].pageStart <= sobp.page &&
                this.paperGroups[i].pageEnd >= sobp.page
            ) {
                return this.paperGroups[i];
            }
        }

        return undefined;
    }

    getPaperGroupByPaperGroupId(paperGroupId) {
        console.log('getPaperGroupByPaperGroupId', paperGroupId);

        if (this.viewerType === ViewerType.RealTime) {
            for (let i = 0; i < this.allPaperGroups.length; i++) {
                if (this.allPaperGroups[i].paperGroupId === paperGroupId) {
                    return this.allPaperGroups[i];
                }
            }
        }

        for (let i = 0; i < this.paperGroups.length; i++) {
            if (this.paperGroups[i].paperGroupId === paperGroupId) {
                return this.paperGroups[i];
            }
        }

        return undefined;
    }

    getSingleUserNotes = async userEmail => {
        console.log('getSingleUserNotes start ... ', userEmail);

        this.singleUserAllNotes = [];
        this.allPaperGroups = [];

        const noteListRes = await axios.get(
            this.serverContextPath + `/api/v1/neolab/note/user?requestUserEmail=${this.user.email}&userEmail=${userEmail}`,
        );
        const noteList = noteListRes.data;

        for (let group of this.groups) {
            const paperGroupRes = await axios.get(
                this.serverContextPath + `/api/v1/group/smartpen/papergroup/${group.id}/all?requestUserEmail=${userEmail}`,
                { requestUserEmail: userEmail },
            );

            const paperGroups = paperGroupRes.data;

            this.allPaperGroups.push(...paperGroups);

            console.log('getSingleUserNotes getPaperGruop ... ', paperGroups);

            for (let note of noteList) {
                for (let paperGroup of paperGroups) {
                    console.log('getSingleUserNotes compare ... ', note.paperGroupId, paperGroup.paperGroupId);

                    if (note.paperGroupId === paperGroup.paperGroupId) {
                        this.singleUserAllNotes.push({
                            userEmail: userEmail,
                            group: group,
                            paperGroup: paperGroup,
                            note: note,
                        });
                    }
                }
            }
        }

        console.log('getSingleUserNotes fin ... ', this.singleUserAllNotes);

        this.sortSingleUserNotes('date');
    };

    getAllUserNotes = async (allMemberEmails, userEmail) => {
        console.log('getAllUserNotes start ... ', allMemberEmails);

        this.groupUserAllNotes = {};
        this.allPaperGroups = [];

        const noteList = [];

        for (const email of Object.keys(allMemberEmails)) {
            const noteListRes = await axios.get(this.serverContextPath + `/api/v1/neolab/note/user?requestUserEmail=${userEmail}&userEmail=${email}`);

            noteList.push(...noteListRes.data);
        }

        console.log('noteList', noteList);

        for (let group of this.groups) {
            const paperGroupRes = await axios.get(
                this.serverContextPath + `/api/v1/group/smartpen/papergroup/${group.id}/all?requestUserEmail=${userEmail}`,
                { requestUserEmail: userEmail },
            );

            const paperGroups = paperGroupRes.data;

            this.allPaperGroups.push(...paperGroups);

            console.log('getAllUserNotes getPaperGroup ... ', paperGroups);

            for (let note of noteList) {
                for (let paperGroup of paperGroups) {
                    if (note.paperGroupId === paperGroup.paperGroupId && allMemberEmails[note.userId].includes(paperGroup.groupId)) {
                        if (this.groupUserAllNotes[paperGroup.id] === undefined) this.groupUserAllNotes[paperGroup.id] = [];

                        this.groupUserAllNotes[paperGroup.id].push({
                            userEmail: note.userId,
                            group: group,
                            paperGroup: paperGroup,
                            note: note,
                        });
                    }
                }
            }
        }

        console.log('getAllUserNotes fin ... ', this.groupUserAllNotes);

        this.sortSingleUserNotes('date');
    };

    resetGroupData() {
        this.selectedPaperGroup = undefined;
        this.currentFolder = { id: null, itemCount: 0, childIds: [] };

        this.paperGroups = [];
        this.activePaperGroups = [];
        this.publicPaperGroups = [];

        this.userNotes = {};
        this.folders = {};
    }

    getUserNotes = async (users, getOtherNotes) => {
        this.userNotes = {};

        console.log('getUserNotes start ... ', users, getOtherNotes);

        if (getOtherNotes) {
            for (const user of users) {
                const noteListRes = await axios.get(
                    this.serverContextPath + `/api/v1/neolab/note/user?requestUserEmail=${this.user.email}&userEmail=${user.email}`,
                );

                await this.setUserNotes(user, noteListRes.data);
            }
        } else {
            const noteListRes = await axios.get(
                this.serverContextPath + `/api/v1/neolab/note/user?requestUserEmail=${this.user.email}&userEmail=${this.user.email}`,
            );

            await this.setUserNotes(this.user, noteListRes.data);
        }

        console.log('getUserNotes fin ... ', this.userNotes);

        this.sortPaperGroupByModifiedAt('date');
    };

    setUserNotes = async (user, noteList) => {
        console.log('setUserNotes ... noteList', user, noteList);

        noteList.forEach(note => {
            if (note.active) {
                const paperGroupId = note.paperGroupId;
                let paperGroup = this.getPaperGroupByPaperGroupId(paperGroupId);

                console.log('setUserNotes', paperGroupId, paperGroup);

                if (paperGroup !== undefined) {
                    if (this.userNotes[paperGroupId] === undefined) this.userNotes[paperGroupId] = [];

                    if (paperGroup.lastStrokeAt === undefined || paperGroup.lastStrokeAt < note.lastStrokeAt)
                        paperGroup.lastStrokeAt = note.lastStrokeAt;

                    this.userNotes[paperGroupId].push({
                        userEmail: user.email,
                        note: note,
                    });
                }
            }
        });
    };

    // getSingleNote = async (paperGroupId, user) => {
    //     const noteListRes = await axios.get(
    //         this.serverContextPath + `/api/v1/neolab/note/${paperGroupId}?requestUserEmail=${this.user.email}&userEmail=${user.email}`,
    //     );
    //
    //     console.log('noteListRes', noteListRes, paperGroupId, user);
    //
    //     await this.setUserNotes(user, noteListRes.data);
    // };

    getNote = (paperGroup, userEmail) => {
        if (paperGroup === undefined) return;

        if (this.singleUserAllNotes.length !== 0) {
            for (let n of this.singleUserAllNotes) {
                if (n.userEmail === userEmail && n.paperGroup.paperGroupId === paperGroup.paperGroupId) {
                    return n.note;
                }
            }
        }

        console.log('this.userNotes[paperGroup.paperGroupId]', this.userNotes);

        if (this.userNotes[paperGroup.paperGroupId] !== undefined) {
            for (let userNote of this.userNotes[paperGroup.paperGroupId]) {
                if (userNote.userEmail === userEmail) {
                    return userNote.note;
                }
            }
        }

        return undefined;
    };

    sortSingleUserNotes(sortingMethod) {
        console.log('sortSingleUserNotes start ... ', sortingMethod);

        if (sortingMethod === 'date') {
            this.singleUserAllNotes.sort((a, b) => {
                if (a.note.lastStrokeAt === null) return 1;
                if (b.note.lastStrokeAt === null) return -1;

                return a.note.lastStrokeAt - b.note.lastStrokeAt;
            });
        }

        if (sortingMethod === 'alpha') {
            this.singleUserAllNotes.sort((a, b) => {
                if (a.paperGroup.title < b.paperGroup.title) {
                    return -1;
                }

                if (b.paperGroup.title > b.paperGroup.title) {
                    return 1;
                }

                return 0;
            });
        }
    }

    sortPaperGroupByModifiedAt(sortingMethod) {
        console.log('sortPaperGroupByModifiedAt start ... ', sortingMethod);

        if (sortingMethod === 'date') {
            this.paperGroups.sort((a, b) => new Date(b.updatedDatetime) - new Date(a.updatedDatetime));
            this.activePaperGroups.sort((a, b) => new Date(b.updatedDatetime) - new Date(a.updatedDatetime));
            this.publicPaperGroups.sort((a, b) => new Date(b.updatedDatetime) - new Date(a.updatedDatetime));
        }

        if (sortingMethod === 'alpha') {
            this.paperGroups.sort((a, b) => (a.title > b.title ? 1 : -1));
            this.activePaperGroups.sort((a, b) => (a.title > b.title ? 1 : -1));
            this.publicPaperGroups.sort((a, b) => (a.title > b.title ? 1 : -1));
        }
    }

    sortFolder(sortingMethod) {
        console.log('sortFolder start ... ', sortingMethod);

        if (sortingMethod === 'date') {
            for (let folderId of Object.keys(this.folders)) {
                this.folders[folderId].childIds.sort((idA, idB) => {
                    if (this.folders[idA].createdDatetime > this.folders[idB].createdDatetime) {
                        return -1;
                    }
                    if (this.folders[idA].createdDatetime < this.folders[idB].createdDatetime) {
                        return 1;
                    }
                    return 0;
                });
            }
        }

        if (sortingMethod === 'alpha') {
            for (let folderId of Object.keys(this.folders)) {
                this.folders[folderId].childIds.sort((idA, idB) => {
                    if (this.folders[idA].name < this.folders[idB].name) {
                        return -1;
                    }
                    if (this.folders[idA].name > this.folders[idB].name) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
    }

    sortPaperGroup(sortingMethod) {
        console.log('sortPaperGroup start ... ', sortingMethod);

        if (sortingMethod === 'date') {
            this.publicPaperGroups.sort((a, b) => {
                if (a.updatedDatetime > b.updatedDatetime) {
                    return -1;
                }
                if (b.updatedDatetime < b.updatedDatetime) {
                    return 1;
                }
                return 0;
            });
            this.activePaperGroups.sort((a, b) => {
                if (a.updatedDatetime > b.updatedDatetime) {
                    return -1;
                }
                if (b.updatedDatetime < b.updatedDatetime) {
                    return 1;
                }
                return 0;
            });
        }

        if (sortingMethod === 'alpha') {
            this.publicPaperGroups.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (b.title > b.title) {
                    return 1;
                }
                return 0;
            });

            this.activePaperGroups.sort((a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (b.title > b.title) {
                    return 1;
                }
                return 0;
            });
        }

        console.log('sortPaperGroup fin ... ', sortingMethod);
    }

    onPdfPageFault = async sobp => {
        if(sobp.section === -1) return;

        console.log(`PDF page fault: ${makeNPageIdStr(sobp)}`);

        const paperGroup = this.getPaperGroup(sobp);

        if (paperGroup === undefined) return;

        if (this.loadedPaperGroupId.includes(paperGroup.paperGroupId)) return;

        if (this.viewerType === ViewerType.Normal && paperGroup.paperGroupId !== this.selectedPaperGroup.paperGroupId) return;

        this.loadedPaperGroupId.push(paperGroup.paperGroupId);

        if (this.viewerType === ViewerType.RealTime) {
            if (this.userNotes[paperGroup.paperGroupId] === undefined) await this.getUserNotes([this.selectedUser], true);

            this.interpreteInkstoreDataContinue(paperGroup);
        }

        this.realTimePaperGroups.push(paperGroup);

        const response = await axios.get(
            this.serverContextPath + `/api/v1/neolab/paper/${paperGroup.paperGroupId}?requestUserEmail=${this.user.email}`,
        );

        console.log('paperhub onPdfPageFault response : ', response);

        const attachments = response.data.attachments;

        let pdfUri = undefined;
        let nprojUri = undefined;

        attachments.forEach(attachment => {
            if (attachment.mimeType === 'application/pdf') pdfUri = attachment.downloadUri;
            if (attachment.mimeType === 'application/xml') nprojUri = attachment.downloadUri;
        });

        console.log('uris : ', pdfUri, nprojUri);

        if (pdfUri === undefined || nprojUri === undefined) return;

        let pdfUriBuffer = new Buffer(pdfUri);
        let pdfUriBase64 = pdfUriBuffer.toString('base64');

        let nprojUriBuffer = new Buffer(nprojUri);
        let nprojUriBase64 = nprojUriBuffer.toString('base64');

        const pathname = window.location.origin;

        const pdfDownloadUri = pathname + `/api/v1/neolab/pdf?redirect=${pdfUriBase64}`;
        const nprojDownloadUri = pathname + `/api/v1/neolab/nproj?redirect=${nprojUriBase64}`;

        const registerItem = await retreivePaperInfoFromNproj(nprojDownloadUri, pdfDownloadUri);

        console.log(`get registerItem`, registerItem);

        if (registerItem) {
            registerItem.pdf_url_for_mapper = registerItem.pdf_url_from_paperhub;

            await manualRegisterAtMappingStorage(registerItem);

            let doc = await NeoPdfManager.getInstance().getDocument({
                url: registerItem.pdf_url_for_mapper,
                filename: registerItem.filename,
                purpose: 'Main document',
            });

            if (this.neoSobpPdfDoc[paperGroup.paperGroupId] === undefined) {
                this.neoSobpPdfDoc[paperGroup.paperGroupId] = true;

                let pdfSobp = {
                    section: paperGroup.section,
                    owner: paperGroup.owner,
                    book: paperGroup.bookCode,
                    page: paperGroup.pageStart,
                };

                console.log('pdfSobp', pdfSobp);

                let viewport = doc.getPage(1).viewport;

                console.log('viewport', viewport, doc.getPage(1));

                for (let i = 0; i < registerItem.numPages; i++) {
                    let thumbnailUrl = await this.getThumbnail(doc, pdfSobp.page, viewport.width / 5, viewport.height / 5);

                    if (thumbnailUrl) this.thumbnailUrlWithSobp[makeNPageIdStr(pdfSobp)] = thumbnailUrl;

                    pdfSobp.page++;
                }

                console.log('doc ', doc);
            }
        }
    };

    onPenConnected = e => {
        console.log('-=-=onPenConnected', e.pen.mac, this.surfaceOwnerId, this.pens);

        e.pen.surfaceOwnerId = this.surfaceOwnerId;

        e.pen.writerId = this.surfaceOwnerId;

        e.pen.setPenRendererType(this.brushType);

        e.pen.setColor(this.color);
        e.pen.setThickness(this.thickness);

        this.addPen(e.pen);

        if (e.inputType.type === 'real' || e.inputType.type === 'from_sdk') {
            this.setPenState(PenState.Connected);

            if (e.inputType.type === 'real') e.pen.setHoverMode(this.isLaserEnabled);
        }

        // if (window.PenRequest === undefined) {
        // const maxPressure = e.pen.getMaxPressure();
        //
        //
        // axios.post(this.serverContextPath + `/api/v1/rooms/${this.roomId}/${this.writerId}/neopen/maxPressure`, {
        //     mac: e.pen.mac + ": onPenConnected",
        //     maxPressure: maxPressure
        // });
        // }
    };

    onPenDisconnected = e => {
        if (e.inputType.type === 'real' || e.inputType.type === 'from_sdk') {
            console.log('onPenDisconnected', e);

            for (let i = 0; i < this.pens.length; i++) {
                if (this.pens[i].mac === e.mac) {
                    this.pens.splice(i, 1);

                    break;
                }
            }

            this.setPenState(PenState.Disconnected);

            // this.onNeoPenStateChanged(PenState.Disconnected);
        }
    };

    addPen = p => this.pens.push(p);

    printPOD = async () => {
        console.log('this.selectedPaperGroup', this.selectedPaperGroup);

        if (this.selectedPaperGroup === undefined) return '서식이 잘못 선택되었습니다.';

        const paperGroup = this.selectedPaperGroup;

        const paperGroupResponse = await axios.get(
            this.serverContextPath + `/api/v1/neolab/paper/${paperGroup.paperGroupId}?requestUserEmail=${this.user.email}`,
        );

        console.log('paperhub printPOD response : ', paperGroupResponse);

        const attachments = paperGroupResponse.data.attachments;
        const progressStatus = paperGroupResponse.data.progressStatus;

        console.log('progressStatus : ', progressStatus);

        if (progressStatus === 'CREATED' || progressStatus === 'PROCESSING') return '프린트 준비 중입니다. 잠시 뒤에 다시 시도해주세요.';

        if (this.printState[paperGroup.paperGroupId] === undefined) this.printState[paperGroup.paperGroupId] = PrintState.Ready;

        if (this.printState[paperGroup.paperGroupId] === PrintState.Printing) return '해당 서식은 이미 프린트 중입니다.';

        this.printProgress[paperGroup.paperGroupId] = 0;
        this.printState[paperGroup.paperGroupId] = PrintState.Ready;

        this.printingPaperGroups.push(paperGroup);

        let pdfUri = undefined;
        let nprojUri = undefined;

        attachments.forEach(attachment => {
            if (attachment.mimeType === 'application/pdf') pdfUri = attachment.downloadUri;
            if (attachment.mimeType === 'application/xml') nprojUri = attachment.downloadUri;
        });

        console.log('uris : ', pdfUri, nprojUri);

        if (pdfUri === undefined || nprojUri === undefined) return '프린트 불가능한 서식입니다.';

        let pdfUriBuffer = new Buffer(pdfUri);
        let pdfUriBase64 = pdfUriBuffer.toString('base64');

        let nprojUriBuffer = new Buffer(nprojUri);
        let nprojUriBase64 = nprojUriBuffer.toString('base64');

        const pathname = window.location.origin;

        const pdfDownloadUri = pathname + `/api/v1/neolab/pdf?redirect=${pdfUriBase64}`;

        const nprojResponse = await axios.get(this.serverContextPath + `/api/v1/neolab/nproj?redirect=${nprojUriBase64}`);

        const paperAttachmentResponse = await axios.get(
            this.serverContextPath + `/api/v1/neolab/paper/${paperGroup.paperGroupId}/gzip?requestUserEmail=${this.user.email}`,
        );

        this.paperAttachments[paperGroup.paperGroupId] = paperAttachmentResponse.data.papers;

        console.log('paperAttachments', this.paperAttachments[paperGroup.paperGroupId]);

        const token = sessionStorage.getItem(store.SessionStorageTokenKey);
        console.log('lms token', token);

        const args = {
            pdf_url: pdfDownloadUri,
            pdf_filename: paperGroup.title,
            // startSobp: startSobpExample,
            requestNcodeGzipFunc: this.requestNcodeGzipFunc,
            nprojXml: nprojResponse.data,
            // ncodePadding: { x_nu: 0, y_nu: 0 },
            // onProgress: onGeneratePodProgress,
        };

        const ret = await generatePodV2(args);

        console.log('generatePodV2 finish', ret);

        if (ret.ok) {
            const pdfBlob = ret.blob;
            // download sample
            saveAs(pdfBlob, paperGroup.title);

            // 인쇄
            const urlCreator = window.URL || window.webkitURL;
            const ncodedUrl = urlCreator.createObjectURL(pdfBlob);

            printJS({
                printable: ncodedUrl,
                type: 'pdf',
                onError: function (error) {
                    alert('Error found => ' + error.message);
                },
                showModal: false,
            });

            this.printState[paperGroup.paperGroupId] = PrintState.Finished;
            this.printProgress[paperGroup.paperGroupId] = 0;
            this.paperAttachments[paperGroup.paperGroupId] = undefined;

            for (let i = 0; i < this.printingPaperGroups.length; i++) {
                if (this.printingPaperGroups[i].paperGroupId === paperGroup.paperGroupId) {
                    this.printingPaperGroups.splice(i, 1);
                }
            }
        } else {
            return '프린트에 실패했습니다.';
        }

        return '프린트가 완료되었습니다.';
    };

    requestNcodeGzipFunc = async (sobp, size, gzipped) => {
        let paperGroup = undefined;

        for (let p of this.printingPaperGroups) {
            if (
                sobp.section === p.section &&
                sobp.owner === p.owner &&
                sobp.bookCode === p.book &&
                sobp.page >= p.pageStart &&
                sobp.page <= p.pageEnd
            ) {
                paperGroup = p;
            }
        }

        if (paperGroup === undefined) {
            console.log('requestNcodeGzipFunc error paperGroup not founded', sobp);

            return;
        }

        console.log('requestNcodeGzipFunc', sobp, size, gzipped);

        const ret = {
            ok: false,
            status: -1,

            gzippedBlob: undefined,
            url: undefined,
            ncodeImageText: undefined,
        };

        const token = sessionStorage.getItem(store.SessionStorageTokenKey);

        const options = {
            method: 'GET',

            headers: {
                'Accept-Encoding': 'application/gzip',
                'X-Auth-Token': token,
            },
        };

        for (let paper of this.paperAttachments[paperGroup.paperGroupId]) {
            if (paper.section === sobp.section && paper.owner === sobp.owner && paper.bookCode === sobp.book && paper.pageNumber === sobp.page) {
                console.log('find SOBP', paper);

                let gzipUriBuffer = new Buffer(paper.gzipUrl);
                let gzipUriBase64 = gzipUriBuffer.toString('base64');

                const pathname = window.location.origin;
                const gzipDownloadUrl = pathname + `/api/v1/neolab/gzip?redirect=${gzipUriBase64}`;

                try {
                    const { ncodeImageText, blob, ok, status } = await fetchGzippedFileWithBlob(gzipDownloadUrl, options);

                    if (ok) {
                        ret.ok = true;
                        ret.status = status;
                        ret.gzippedBlob = await blob;
                        ret.ncodeImageText = ncodeImageText;

                        // ret.url = url;
                        const urlCreator = window.URL || window.webkitURL;
                        const ncodedUrl = urlCreator.createObjectURL(ret.gzippedBlob);
                        ret.url = ncodedUrl;
                    } else {
                        ret.status = 400;
                    }
                } catch (e) {
                    ret.status = 400;
                }

                this.printState[paperGroup.paperGroupId] = PrintState.Printing;
                this.printProgress[paperGroup.paperGroupId] =
                    ((sobp.page - paperGroup.pageStart) / (paperGroup.pageEnd - paperGroup.pageStart)) * 100;

                return ret;
            }
        }
    };
    onOfflineDataNone = () => {
        console.log('-=-=onOfflineDataNone');

        this.setOfflineDataUploadState(OfflineDataUploadState.UploadNone);
    };

    onOfflineDataStart = () => {
        console.log('-=-=onOfflineDataStart');

        this.setOfflineDataUploadState(OfflineDataUploadState.Uploading);

        this.offlineDatas = {};
    };

    onOfflineDataEnd = async () => {
        console.log('-=-=onOfflineDataEnd');

        for (const [userEmail, pages] of Object.entries(this.offlineDatas)) {
            console.log('uploadToInk : ', userEmail);

            const uploadUrl = this.serverContextPath + `/api/v1/neolab/ink?requestUserEmail=${this.user.email}&prevUserEmail=${userEmail}`;

            await axios.post(
                uploadUrl,
                {
                    pages: pages,
                },
            ).catch(async () => {
                await this.timeout(1000);

                axios.post(
                    uploadUrl,
                    {
                        pages: pages,
                    },
                ).catch(async () => {
                    await this.timeout(1000);

                    axios.post(
                        uploadUrl,
                        {
                            pages: pages,
                        },
                    );
                });
            });
        }

        this.offlineDatas = {};

        this.setOfflineDataUploadState(OfflineDataUploadState.UploadFinish);

        this.getUserNotes(this.groupUsers, this.isLeader);
    };

    onOfflineData = data => {
        console.log('-=-=onOfflineData user : ', data.prevUserId);
        console.log('offlineData page : ', data.section, data.owner, data.bookCode, data.pageNumber);

        this.setOfflineDataUploadState(OfflineDataUploadState.Uploading);

        let userEmail = data.prevUserId;

        if (this.offlineDatas[userEmail] === undefined) this.offlineDatas[userEmail] = [];

        this.offlineDatas[userEmail].push(data);
    };

    onPenMessage = data => {
        const macAddress = data.macAddress;
        const status = data.status;
        const subData = data.subData;

        console.log('onPenMessage : ', macAddress, status, subData);

        if (status === PenProtocol.ConnectTry) {
            return;
        }

        if (status === PenProtocol.ConnectFail) {
            this.setOfflineDataUploadState(OfflineDataUploadState.None);

            return;
        }

        if (status === PenProtocol.Connected) {
            window.onPenConnected({ mac: macAddress });

            this.setOfflineDataUploadState(OfflineDataUploadState.Connected);

            return;
        }

        if (status === PenProtocol.Disconnected) {
            window.onPenDisconnected({ mac: macAddress });

            this.setOfflineDataUploadState(OfflineDataUploadState.None);

            return;
        }

        if (status === PenProtocol.LowBattery) {
            this.penBattery = subData.battery;

            return;
        }

        if (status === PenProtocol.PenStatus) {
            // if (this.penState === PenState.Disconnected) {
            window.onPenConnected({ mac: macAddress });

            // axios.post(this.serverContextPath + `/api/v1/rooms/${this.roomId}/${this.writerId}/neopen/maxPressure`, {
            //     mac: macAddress + ": onPenMaxPress",
            //     maxPressure: subData.maxPress
            // });
            // }
            return;
        }

        if (status === PenProtocol.NotConnected) {
            // return;
        }
    };

    setOfflineDataUploadState = state => {
        this.offlineDataUploadState = state;

        if (state === OfflineDataUploadState.UploadFinish || state === OfflineDataUploadState.UploadNone) {
            setTimeout(() => {
                this.offlineDataUploadState = OfflineDataUploadState.None;
            }, 4000);
        }
    };

    setUser = u => (this.user = u);

    setGroups = groups => {
        this.groups = [];

        for (let g of groups) this.groups.push(g.group);
    };

    setColor = color => {
        this.color = color;
        this.pens.forEach(p => {
            if (p.inputType.type === 'real' || p.inputType.type === 'mouse' || p.inputType.type === 'from_sdk') {
                p.setColor(color);
            }
        });
    };

    setThickness = thickness => {
        this.thickness = thickness;
        this.pens.forEach(p => {
            if (p.inputType.type === 'real' || p.inputType.type === 'mouse' || p.inputType.type === 'from_sdk') {
                p.setThickness(thickness);
            }
        });

        return thickness;
    };

    setBrushType = brushType => {
        this.brushType = brushType;

        this.pens.forEach(p => {
            if (p.inputType.type === 'real' || p.inputType.type === 'mouse' || p.inputType.type === 'from_sdk') {
                p.setPenRendererType(brushType);

                this.setColor(this.color);
            }
        });
    };

    onLaserChanged = () => {
        this.isLaserEnabled = !this.isLaserEnabled;

        this.pens.forEach(p => {
            if (p.inputType.type === 'real') {
                p.setHoverMode(this.isLaserEnabled);
            }
        });
    };

    setPenState = state => {
        console.log('setPenState', state);

        if (state === PenState.Connecting) {
            setTimeout(() => {
                if (this.penState === PenState.Connecting) this.setPenState(PenState.Disconnected);
            }, 7000);
        }

        this.penState = state;
    };

    uploadToInk = async () => {
        const is = InkStorage.getInstance();
        let pages = {};

        let strokes = is.strokes;

        strokes = strokes.filter(st => !st.uploaded);

        if (this.viewerType === ViewerType.Normal) {
            strokes = strokes.filter(st => this.isStrokeInPaperGroup(st, this.selectedPaperGroup) && st.status !== StrokeStatus.ERASED);
        }

        for (let s of strokes) {
            s.uploaded = true;

            const sobpStr = makeNPageIdStr({ section: s.section, owner: s.owner, book: s.book, page: s.page });

            if (pages[sobpStr] === undefined) pages[sobpStr] = [];

            pages[sobpStr].push(makeInkStoreStrokeFromNeoStroke(s));
        }

        let pageWithStrokes = [];

        for (let sobpStr of Object.keys(pages)) {
            const sobp = sobpStr.split('.');
            const s = parseInt(sobp[0]);
            const o = parseInt(sobp[1]);
            const b = parseInt(sobp[2]);
            const p = parseInt(sobp[3]);

            const page = { section: s, owner: o, bookCode: b, pageNumber: p, strokes: pages[sobpStr] };

            console.log("pages[sobpStr]", pages[sobpStr]);

            pageWithStrokes.push(page);
        }

        const uploadUrl = this.serverContextPath + `/api/v1/neolab/ink?requestUserEmail=${this.user.email}&prevUserEmail=${this.selectedUser.email}`;

        await axios.post(
            uploadUrl,
            {
                pages: pageWithStrokes,
            },
        ).catch(async () => {
            await this.timeout(1000);

            axios.post(
                uploadUrl,
                {
                    pages: pageWithStrokes,
                },
            ).catch(async () => {
                await this.timeout(1000);

                axios.post(
                    uploadUrl,
                    {
                        pages: pageWithStrokes,
                    },
                );
            });
        });

        this.renewLastUploadTime();

        console.log('uploadToInk', pageWithStrokes);
    };

    setRenderingUserStrokeId = (userEmail, viewOwnStroke) => {
        console.log('setRenderingUserStrokeId', userEmail, viewOwnStroke);

        this.opacityConverterFunc = (stroke, originalOpacity) => {
            if (userEmail === undefined) return originalOpacity;

            if (viewOwnStroke) {
                if (stroke.writerId !== userEmail) return originalOpacity * 0.2;
            } else if (stroke.writerId === userEmail) return originalOpacity * 0.2;

            return originalOpacity;
        };
    };

    isStrokeInPaperGroup = (stroke, paperGroup) => {
        return (
            stroke.section === paperGroup.section &&
            stroke.owner === paperGroup.owner &&
            stroke.book === paperGroup.bookCode &&
            stroke.page >= paperGroup.pageStart &&
            stroke.page <= paperGroup.pageEnd
        );
    };

    getPaperGroupFromPaperHubBySOBP = async (email, sobp) => {
        const response = await axios.get(
            this.serverContextPath +
                `/api/v1/neolab/paper?requestUserEmail=${email}&section=${sobp.section}&owner=${sobp.owner}&book=${sobp.book}&page=${sobp.page}`,
        );

        if (response.data.resultElements === undefined) {
            return undefined;
        }

        return response.data.resultElements[0];
    };

    onPenDown = e => {
        const activePage = this.activePage;

        setTimeout(async () => {
            console.log('onPenDown', e, e.stroke.page);

            if(e.stroke.section === -1)
                return;

            if (this.selectNeolabNoteDialogOpen) {
                if (e.stroke.section === 10) {
                    this.selectNeolabNoteMsg = '선택한 서식이 전용 노트가 아닙니다.';

                    return;
                }

                this.selectNeolabNoteState = SelectNeolabNoteState.Getting;
                this.selectNeolabNoteMsg = '노트를 불러오는 중입니다...';

                const response = await axios.get(
                    this.serverContextPath +
                        `/api/v1/neolab/paper?requestUserEmail=${this.user.email}&section=${e.stroke.section}&owner=${e.stroke.owner}&book=${e.stroke.book}&page=${e.stroke.page}`,
                );

                if (response.data.resultElements === undefined) {
                    this.selectNeolabNoteMsg = '노트 불러오는데 실패했습니다.';

                    return;
                }

                this.selectNeolabNotePaperGroup = response.data.resultElements[0];
                console.log('getPaperGroupWithSOBP', this.selectNeolabNotePaperGroup);

                this.selectNeolabNoteTitle = this.selectNeolabNotePaperGroup.title;

                for (let attachment of this.selectNeolabNotePaperGroup.attachments) {
                    if (attachment.mimeType === 'image/png') {
                        let thumbnailUriBuffer = new Buffer(attachment.downloadUri);
                        let thumbnailUriBase64 = thumbnailUriBuffer.toString('base64');

                        const pathname = window.location.origin;

                        this.selectNeolabNoteThumbnailUrl = pathname + `/api/v1/neolab/thumbnail?redirect=${thumbnailUriBase64}`;

                        console.log('this.selectNeolabNoteThumbnailUrl', this.selectNeolabNoteThumbnailUrl);
                    }
                }

                this.selectNeolabNoteState = SelectNeolabNoteState.Finish;
                this.selectNeolabNoteMsg = '게시를 눌러 노트를 등록해주세요.';

                return;
            }

            const newSOBP = {
                section: e.stroke.section,
                owner: e.stroke.owner,
                book: e.stroke.book,
                page: e.stroke.page,
            };

            if (this.viewerType === ViewerType.Normal) {
                if (this.isStrokeInPaperGroup(e.stroke, this.selectedPaperGroup)) {
                    this.setActivePage(newSOBP);
                }

                for (let i = 0; i < this.pageInfos.length; i++) {
                    if (isSamePage(this.pageInfos[i], newSOBP)) {
                        const info = this.pageInfos.splice(i, 1)[0];

                        info.lastModified = Date.now();

                        this.pageInfos.unshift(info);

                        break;
                    }
                }
            } else if (this.viewerType === ViewerType.RealTime) {
                if (!isSamePage(activePage, newSOBP)) {

                    this.addToRealTimePageInfo(newSOBP);

                    this.setActivePage(newSOBP);

                    this.selectedPaperGroup = this.getPaperGroup(newSOBP);
                }

                if (this.autoUploadTimeout !== undefined) clearTimeout(this.autoUploadTimeout);

                this.autoUploadTimeout = setTimeout(() => {
                    this.uploadToInk();
                }, 5000);
            }
        }, 0);
    };

    onConnectionChange = async () => {
        const pM = PenManager.getInstance();

        if (this.penState === PenState.Disconnected) {
            if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler('PenConnect', this.macAddress);
            } else {
                const new_pen = pM.createPen();
                this.setPenState(PenState.Connecting);

                new_pen.addEventListener(PenEventName.ON_CONNECTED, () => {
                    console.log('PenEventName.ON_CONNECTED to upload offline data');

                    if (this.offlineDataUploadState === OfflineDataUploadState.Connecting) {
                        this.setRealTimeViewAnchorEl(null);
                        this.reqOfflineData();
                    }
                });

                const ret = await new_pen.connect(true);

                if (ret) {
                    console.log(`pen connection completed`);
                } else {
                    new_pen.removeEventListenerAll();
                    console.log(`pen connection failed`);
                }
            }

            return;
        }

        if (this.penState === PenState.Connected || this.penState === PenState.Using) {
            pM.getPens().forEach(p => {
                if (p.inputType.type === 'real') p.disconnect();
            });

            if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler('PenDisconnect', {});
            }

            this.setPenState(PenState.Disconnected);

            // return;
        }
    };

    nextPage = () => {
        if (this.viewerType === ViewerType.Normal) {
            if (this.activePage.page + 1 <= this.selectedPaperGroup.pageEnd) {
                this.setActivePage({ ...this.activePage, page: this.activePage.page + 1 });
            }
        } else if (this.viewerType === ViewerType.RealTime) {
            for (let i = 0; i < this.realTimePageInfos.length - 1; i++) {
                if (isSamePage(this.realTimePageInfos[i], this.activePage)) {
                    let paperGroupOrigin = this.getPaperGroup(this.realTimePageInfos[i]);
                    let paperGroupNext = this.getPaperGroup(this.realTimePageInfos[i + 1]);

                    if (paperGroupOrigin.id === paperGroupNext.id) this.setActivePage(this.realTimePageInfos[i + 1]);

                    return;
                }
            }
        }
    };

    prevPage = () => {
        if (this.viewerType === ViewerType.Normal) {
            if (this.activePage.page - 1 >= this.selectedPaperGroup.pageStart) {
                this.setActivePage({ ...this.activePage, page: this.activePage.page - 1 });
            }
        } else if (this.viewerType === ViewerType.RealTime) {
            for (let i = 1; i < this.realTimePageInfos.length; i++) {
                if (isSamePage(this.realTimePageInfos[i], this.activePage)) {
                    let paperGroupOrigin = this.getPaperGroup(this.realTimePageInfos[i]);
                    let paperGroupNext = this.getPaperGroup(this.realTimePageInfos[i - 1]);

                    if (paperGroupOrigin.id === paperGroupNext.id) this.setActivePage(this.realTimePageInfos[i - 1]);

                    return;
                }
            }
        }
    };

    selectNeolabNoteDialogOpenChanged = () => {
        if (this.selectNeolabNoteDialogOpen === true) {
            this.selectNeolabNotePaperGroup = undefined;
            this.selectNeolabNoteThumbnailUrl = undefined;
            this.selectNeolabNoteState = SelectNeolabNoteState.None;
            this.selectNeolabNoteTitle = '';

            if (this.penState === PenState.Connected) {
                const pM = PenManager.getInstance();

                pM.getPens().forEach(p => {
                    if (p.inputType.type === 'real') p.disconnect();
                });

                this.setPenState(PenState.Disconnected);
            }
        }

        this.selectNeolabNoteDialogOpen = !this.selectNeolabNoteDialogOpen;
    };

    setNeoNoteTitle = title => {
        this.selectNeolabNoteTitle = title;
    };

    *getUserInfo(findUserEmail) {
        console.log(`Start get userInfo, find user email = ${findUserEmail}`);
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/users/${findUserEmail}`);
            console.log('Success getUserInfo = ', response.data);
            if (response.data !== null) {
                return response.data.user;
            }
        } catch (e) {
            if (e.response) {
                console.log('Failed getUserInfo', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log('Failed getUserInfo', e.request);
            } else {
                console.log('Failed getUserInfo', e.message);
            }
        }
    }

    *getGroupLeader(userEmail, groupId) {
        console.log(logPrefix, `Start getGroupLeader groupId = ${groupId}`);

        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/group/${groupId}/find/leader/${userEmail}`);

            console.log(logPrefix, `Success get group leader =`, response.data);

            // callbacks && callbacks.checkIsLeader(response.data);

            return response.data;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getGroupLeader', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getGroupLeader', e.request);
            } else {
                console.log(logPrefix, 'Failed getGroupLeader', e.message);
            }
        }
    }

    addToRealTimePageInfo(pageInfo) {
        console.log("addToRealTimePageInfo", this.realTimePageInfos);

        if (pageInfo.section === -1) return;

        for(let i = 0; i < this.realTimePageInfos.length; i++) {
            if(isSamePage(this.realTimePageInfos[i], pageInfo)) {
                const info = this.realTimePageInfos.splice(i, 1)[0];

                info.lastModified = Date.now();

                this.realTimePageInfos.unshift(info);

                break;
            }
        }

        let isIncludedSOBP = false;

        for (const sobp of this.realTimePageInfos) {
            if (isSamePage(sobp, pageInfo)) {
                isIncludedSOBP = true;

                break;
            }
        }

        if (!isIncludedSOBP) {
            pageInfo.lastModified = new Date().getTime();

            this.realTimePageInfos.unshift(pageInfo);
        }
    }

    setIsLeader = isLeader => (this.isLeader = isLeader);

    setViewerType = type => (this.viewerType = type);

    renewLastUploadTime = () => {
        let daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        const currentDate = new Date();

        this.lastUploadTime = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}(${
            daysOfWeek[currentDate.getDay()]
        }) ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
    };

    reqOfflineData = async () => {
        this.setOfflineDataUploadState(OfflineDataUploadState.Uploading);

        const physicalPens = this.pens.filter(pen => pen.inputType.type === 'real');

        console.log('physicalPens.length', physicalPens.length);
        // requestOfflineDataList의 사용법은 다음과 같다
        //
        // requestOfflineDataList()                   전체 오프라인 데이터의 리스트 수집
        // requestOfflineDataList( section, owner )   해당 section, owner 만
        // requestOfflineDataList( section, owner, book )   해당 section, owner, book 만

        let pages = [];

        for (let physicalPen of physicalPens) {
            console.log('physicalPen', physicalPen);

            const list = await physicalPen.requestOfflineDataList();
            console.log('handleOfflineData');
            console.log(list);

            if (list?.length) {
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    const { Section: s, Owner: o, Note: b, Pages: pgs } = item;

                    // section, owner, book, pages:number[],
                    // 맨 마지막 true는 offline data 전송후 펜에서 자동 삭제
                    //            false는 펜에서 삭제하지 않고 그대로 두는 것
                    const ret = await physicalPen.reqOfflineData(s, o, b, pgs, true);

                    console.log('originStroke', ret.strokes);

                    for (let stroke of ret.strokes) {
                        let isAdded = false;

                        for (let p of pages) {
                            if (
                                p.section === stroke.section &&
                                p.owner === stroke.owner &&
                                p.bookCode === stroke.book &&
                                p.pageNumber === stroke.page
                            ) {
                                p.strokes.push(
                                    makeInkStoreStrokeFromNeoStroke({
                                        ...stroke,
                                        thickness: 0.35,
                                        writerId: this.user.email,
                                    }),
                                );
                                isAdded = true;
                            }
                        }

                        if (!isAdded) {
                            pages.push({
                                section: s,
                                owner: o,
                                bookCode: b,
                                pageNumber: stroke.page,
                                strokes: [
                                    makeInkStoreStrokeFromNeoStroke({
                                        ...stroke,
                                        thickness: 0.35,
                                        writerId: this.user.email,
                                    }),
                                ],
                            });
                        }
                    }
                }
            }
        }

        if (pages.length > 0) {
            if(this.selectedUser) {
                await axios.post(this.serverContextPath + `/api/v1/neolab/ink?requestUserEmail=${this.user.email}&prevUserEmail=${this.selectedUser.email}`, {
                    pages: pages,
                });
            } else {
                await axios.post(this.serverContextPath + `/api/v1/neolab/ink?requestUserEmail=${this.user.email}&prevUserEmail=${this.user.email}`, {
                    pages: pages,
                });
            }
        }

        const pM = PenManager.getInstance();

        pM.getPens().forEach(p => {
            if (p.inputType.type === 'real') p.disconnect();
        });

        this.getUserNotes(this.groupUsers, this.isLeader);

        if (pages.length === 0) this.setOfflineDataUploadState(OfflineDataUploadState.UploadNone);
        else this.setOfflineDataUploadState(OfflineDataUploadState.UploadFinish);
    };

    savePdfWithExternalStroke() {
        const currentPaperGroup = this.getPaperGroup(this.activePage);

        if (currentPaperGroup === undefined) return;

        const pdfTitle = currentPaperGroup.title;

        let pageInfos = this.pageInfos;
        if (this.viewerType === ViewerType.RealTime) pageInfos = this.realTimePageInfos;

        savePDF(
            pdfTitle,
            {
                emptyPod: false,
                emptyPublic: false,
                surfaceOwnerIds: undefined,
                sobps: pageInfos,
            },
            this.replayStorage.strokes,
        );
    }

    getPdfDownloadUrl = async paperGroup => {
        const response = await axios.get(
            this.serverContextPath + `/api/v1/neolab/paper/${paperGroup.paperGroupId}?requestUserEmail=${this.user.email}`,
        );

        const attachments = response.data.attachments;

        let pdfUri = undefined;

        attachments.forEach(attachment => {
            if (attachment.mimeType === 'application/pdf') pdfUri = attachment.downloadUri;
        });

        console.log('uris : ', pdfUri);

        if (pdfUri === undefined) return;

        return pdfUri;
    };

    getAllFolders = async groupId => {
        const currentFolderId = this.currentFolder ? this.currentFolder.id : null;

        const res = await axios.get(
            this.serverContextPath + `/api/v1/group/smartpen/papergroup/folder?requestUserEmail=${this.user.email}&groupId=${groupId}`,
        );

        console.log('res getAllFolders', res);

        const resFolders = res.data;

        this.folders[null] = { id: null, itemCount: 0, childIds: [] };

        for (let folder of resFolders) {
            const parentId = folder.parentId;

            if (this.folders[parentId] === undefined) this.folders[parentId] = {};

            if (this.folders[parentId].itemCount === undefined) this.folders[parentId].itemCount = 0;

            this.folders[parentId].itemCount += 1;

            if (this.folders[parentId].childIds === undefined) this.folders[parentId].childIds = [];

            this.folders[parentId].childIds.push(folder.id);

            this.folders[folder.id] = { ...folder, childIds: [], itemCount: 0 };

            for (let paperGroup of this.paperGroups) {
                if (this.folders[paperGroup.folderId] === undefined) continue;

                if (this.folders[paperGroup.folderId].itemCount === undefined) this.folders[paperGroup.folderId].itemCount = 0;

                this.folders[paperGroup.folderId].itemCount += 1;
            }
        }

        this.currentFolder = this.folders[currentFolderId];

        this.sortFolder('date');

        console.log('getAllFolders fin', this.folders);
    };

    selectFolder = folder => (this.currentFolder = folder);

    createFolder = async (groupId, parentId, name, type) => {
        await axios.post(this.serverContextPath + `/api/v1/group/smartpen/papergroup/folder?requestUserEmail=${this.user.email}`, {
            groupId: groupId,
            parentId: parentId,
            name: name,
            type: type,
        });

        await this.getAllFolders(groupId);
    };

    renameFolder = async (folderId, name) => {
        console.log('renameFolder', folderId, name);

        this.folders[folderId].name = name;

        await axios.put(this.serverContextPath + `/api/v1/group/smartpen/papergroup/folder?targetFolderId=${folderId}&newFolderName=${name}`);
    };

    renamePaperGroup = async (paperGroup, name) => {
        console.log('renamePaperGroup', name);

        paperGroup.title = name;

        await axios.put(this.serverContextPath + `/api/v1/group/smartpen/papergroup?requestUserEmail=${this.user.email}`, paperGroup);
    };

    setSelectFolderDialogOpened = (val, movingObjectType, objectId) => {
        this.isSelectFolderDialogOpened = val;
        this.movingObjectType = movingObjectType;
        this.selectedObjectIdToMove = objectId;
    };

    moveObjectToFolder = async (groupId, selectedFolderId) => {
        let objectType = this.movingObjectType;
        let url = `/api/v1/group/smartpen/papergroup/folder/move?requestUserEmail=${this.user.email}&objectType=${objectType}&objectId=${this.selectedObjectIdToMove}`;

        if (selectedFolderId !== null) url += `&selectedFolderId=${selectedFolderId}`;

        await axios.put(url);

        this.setSelectFolderDialogOpened(false, undefined, undefined);

        if (objectType === MovingObjectType.PaperGroup) {
            await this.getAllPaperGroups(groupId, this.user.email);
        }

        if (objectType === MovingObjectType.Folder) {
            await this.getAllFolders(groupId);
        }
    };

    setAllUsersWithGroupId = allUsersWithGroupId => (this.allUsersWithGroupId = allUsersWithGroupId);

    setRealTimeViewAnchorEl = val => (this.realTimeViewAnchorEl = val);

    setGroupUsers = val => {
        console.log('setGroupUsers', val);
        this.groupUsers = val;
    };

    selectGroup = group => (this.selectedGroup = group);

    convertTimestamp(timestamp) {
        let d = new Date(timestamp),
            yyyy = d.getFullYear(),
            mm = ('0' + (d.getMonth() + 1)).slice(-2),
            dd = ('0' + d.getDate()).slice(-2),
            hh = d.getHours(),
            h = hh,
            min = ('0' + d.getMinutes()).slice(-2),
            ampm = 'AM',
            time;

        if (hh > 12) {
            h = hh - 12;
            ampm = 'PM';
        } else if (hh === 12) {
            h = 12;
            ampm = 'PM';
        } else if (hh === 0) {
            h = 12;
        }

        time = yyyy + '.' + mm + '.' + dd + ', ' + h + ':' + min + ' ' + ampm;

        return time;
    }
    getThumbnail = async (pdf, pagenum, width, height) => {
        const page = pdf.getPage(pagenum);
        const job = await page.generateThumbnail(width, height, true);

        if (job) return job.url;

        return undefined;
    };

    convertPenData = async () => {
        const is = InkStorage.getInstance();

        let strokes = is.strokes;

        strokes = strokes.filter(s => isSamePage(s, this.activePage));
        strokes = strokes.sort((s1, s2) => s1.startTime - s2.startTime);

        const inkStoreStrokes = [];

        for (let s of strokes) {
            const inkStoreStroke = makeInkStoreStrokeFromNeoStroke(s);

            inkStoreStrokes.push(inkStoreStroke);
        }

        const requestData = {
            "pages" : [{
                "section": 3,
                "owner": 27,
                "bookCode": 603,
                "pageNumber": 43,

                "recognition": {
                    "xDpi": 107.14286041259766,
                    "yDpi": 107.14286041259766,
                    "width": 932.7,
                    "height": 1223.8,
                    "scale": 10,
                    "contentType": "Text",
                    "language": "ko_KR",
                    "configuration": {},
                    "analyzer": {
                        "separateShapesAndText": true,
                        "hide": true,
                        "removeShape": true,
                        "blockSizeSamplingSteps": 3,
                        "kindOfEngine": 0,
                        "paperWidth": 102.8,
                        "paperHeight": 131.9
                    }
                },

                "strokes": inkStoreStrokes,
            }],

            "mimeType": "application/vnd.neolab.ndp2.stroke+json"
        };

        const res = await axios.post(`/api/v1/neolab/recognition/${this.user.email}`, requestData);
        console.log("res", res.data.taskId);

        const resultText = await this.checkRecognitionTask(res.data.taskId);

        const regex = emojiRegex();

        return resultText.replaceAll(regex, "");
    }

    checkRecognitionTask = async (taskId) => {
        await this.timeout(3000);

        const res = await axios.get(`/api/v1/neolab/recognition/task/${taskId}/${this.user.email}`);

        console.log("checkRecognitionTask check", res.data);

        if(res.data.contents.status !== "PENDING") {
            console.log("checkRecognitionTask fin", res.data, res.data.contents.result[0].iink.label);

            return res.data.contents.result[0].iink.label;
        }

        return this.checkRecognitionTask(taskId);
    }

    timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }
}
