import React, { Component, memo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MobileMyRoomComponent from './MobileMyRoomComponent';
import MobileTodayScheduleComponent from './MobileTodayScheduleComponent';
// import Footer from '../footer/Footer';
import { Box } from '@material-ui/core';
import LicenseNotificationDialogComponent from '../../dialog/LicenseNotificationDialogComponent';
import LicenseDialogComponent from '../../dialog/LicenseDialogComponent';
import ConfirmDialog from '../../../components/common/ConfirmDialog';
import { FIND_PASSWORD_EMAIL_SEND_RESULT_STATE } from '../../../stores/UserStore';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import LicenseKeyInformation from '../../myRoom/LicenseKeyInformation';

const styles = _theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: 56,
        '@media all and (min-width: 601px)': {
            marginTop: 66,
        },
    },
});

class MobileRooms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseNotificationDialog: true,
            licenseDialog: false,
        };
    }

    // handleCloseLicenseDialog = () => {
    //     this.setState({
    //         licenseNotificationDialog: false,
    //         licenseDialog: false,
    //
    //     });
    // };

    handleCloseLicenseDialog = () => {
        this.props.userStore?.changeCreateLicenseKeyState(FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.None);
        this.props.userStore?.changeCreateLicenseKeyResultDialogOpenState(false);
    };

    render() {
        const { intl, classes, isScrollEnd, isMobile, userStore } = this.props;
        const isOpenDialog = userStore?.isCreateUserLicenseKeyResultDialogState;
        const createUserLicenseKeyState = userStore?.createUserLicenseKeyState;

        return (
            <LicenseKeyInformation>
                <div className={classes.root}>
                    <Box>
                        <MobileMyRoomComponent isMobile={isMobile} />
                        <MobileTodayScheduleComponent isScrollEnd={isScrollEnd} isMobile={isMobile} />
                        {/*{window.flutter_inappwebview && <UploadOfflineDataButton />}*/}
                        {/*<LicenseDialogComponent />*/}

                        <ConfirmDialog
                            open={isOpenDialog}
                            title={intl.formatMessage({ id: 'notification' })}
                            msg={
                                createUserLicenseKeyState === FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.Success
                                    ? '서비스 라이선스 키가 등록되었습니다.'
                                    : '서비스 라이선스 키가 유효하지 않습니다. 라이선스 키를 다시 입력하거나 서비스 담당자에게 문의해주세요.'
                            }
                            changeDialogOpen={this.handleCloseLicenseDialog}
                        />
                    </Box>
                </div>
            </LicenseKeyInformation>
        );
    }
}

export default memo(withStyles(styles)(inject('userStore')(injectIntl(observer(MobileRooms)))));
