import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Typography } from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    root: {},
    topBox: {
        padding: '20px 10px 20px 32px',
        boxSizing: 'border-box',
        borderBottom: '1px solid #c0c2c3',
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            width: 27,
            height: 27,
        },
    },
    afterContent: {
        '&::after': {
            content: "''",
            width: 370,
            height: 27,
        },
    },
    logoImage: {
        width: 'auto',
        height: 'auto',
        maxWidth: 140,
        maxHeight: 32,
        objectFit: 'contain',
        // cursor: 'pointer',
        verticalAlign: 'middle',
        marginRight: 36,
    },
    textStyle: {
        color: '#000000',
        fontWeight: 'bold',
        fontSize: '1.125rem',
    },
    topCenterText: {
        fontWeight: 'normal',
        fontSize: '1.25rem',
    },
    penButton: {
        width: 126,
        height: 40,
        background: '#fff',
        border: '1px solid #c4c4c4',
        borderRadius: 2,
        marginRight: 15,
        '& span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 5,
        },
    },
    penButtonRight: {
        marginRight: 40,
        background: '#0097ff',
        border: 0,
        '&:hover': {
            background: '#0097ff',
        },
        '& span': {
            color: '#fff',
        },
    },
    penText: {
        fontSize: '0.938rem',
        color: '#1e2121',
    },

    controlBox: {
        padding: 10,
    },
    dateText: {
        fontWeight: 'normal',
        fontSize: '0.938rem',
        margin: '0 5px 0 30px',
        color: '#2c2c2c',
    },
    radioBox: {
        marginRight: 30,
    },
    contentsLeftBox: {
        width: '100%',
        height: 'calc(100vh - 81px - 70px)',
        background: '#eee',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 0',
        boxSizing: 'border-box',
    },
    buttonBox: {
        marginRight: 76,
    },
    buttonStyle: {
        minWidth: 35,
        background: 'transparent',
        padding: 5,
        margin: '0 5px',
        '&:hover': {
            background: 'transparent',
        },
    },
    buttonStyleMargin: {
        margin: 0,
    },
    formControl: {
        marginRight: 8,
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    numberBox: {
        width: 50,
        height: 25,
        borderRadius: 4,
        padding: '3px 7px',
        border: '1px solid #c2c2c2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    numberText: {
        fontSize: '1rem',
        color: '#6d6d70',
    },
    pdfBox: {
        width: 413,
        height: 685,
        background: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    contentsRightBox: {
        width: 150,
        height: 'calc(100vh - 81px - 70px)',
        overflow: 'auto',
        background: '#fff',
        padding: '0 0 0 8px',
        boxSizing: 'border-box',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#bebebe',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '3px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
    },
    rightBoxIn: {
        width: '100%',
        height: 173,
        background: '#eee',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 7,
        boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.25)',
        marginBottom: 10,
    },
    pdfSmall: {
        width: 78,
        height: 133,
        background: '#fff',
        marginTop: 5,
    },
    pdfNumber: {
        fontSize: '0.75rem',
        fontWeight: 'bold',
        marginTop: 7,
    },
    toasts: {
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    lineStyle: {
        width: 1,
        height: 22,
        background: '#d9d9d9',
        margin: '0 25px 0 5px',
    },
    btnStyle: {
        border: '1px solid #bfbfbf',
        borderRadius: 4,
        marginLeft: 10,
        color: '#000',
        padding: '3px 10px 3px 8px',
        fontWeight: 400,
        fontSize: '0.875rem',
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 4,
        },
    },
}));

function SkeletonPenViewer(props) {
    const classes = useStyles();
    return (
        <div>
            <Box className={classes.topBox}>
                <Box alignItems="center" className={classes.afterContent}>
                    <Box display={'flex'} alignItems="center" justifyContent={'space-between'}>
                        <Skeleton variant={'circle'} animation={'wave'}>
                            <Avatar />
                        </Skeleton>
                        <Skeleton variant={'text'} width={'30%'} animation={'wave'}>
                            <Typography className={classes.textStyle}>.</Typography>
                        </Skeleton>
                        <Skeleton variant={'text'} width={'30%'} animation={'wave'}>
                            <Typography className={classes.textStyle}>.</Typography>
                        </Skeleton>
                        <Skeleton variant={'text'} width={'30%'} animation={'wave'}>
                            <Typography className={classes.textStyle}>.</Typography>
                        </Skeleton>
                    </Box>
                    <Box alignContent={'center'}>
                        <Skeleton variant={'text'} width={'60%'} animation={'wave'}>
                            <Typography className={classes.textStyle}>.</Typography>
                        </Skeleton>
                        <Skeleton variant={'text'} width={'40%'} animation={'wave'}>
                            <Typography className={classes.textStyle}>.</Typography>
                        </Skeleton>
                    </Box>
                </Box>
            </Box>

            <Box justifyContent="space-between" className={classes.controlBox}>
                <Box display="flex" alignItems="center">
                    <Skeleton variant={'text'} width={'10%'} animation={'wave'} />

                    <Box className={classes.lineStyle} />
                    <Skeleton variant={'circle'} className={classes.radioBox} animation={'wave'}>
                        <Avatar />
                    </Skeleton>
                    <Skeleton variant={'circle'} className={classes.radioBox} animation={'wave'}>
                        <Avatar />
                    </Skeleton>
                    <Skeleton variant={'circle'} className={classes.radioBox} animation={'wave'}>
                        <Avatar />
                    </Skeleton>
                    <Skeleton variant={'circle'} className={classes.radioBox} animation={'wave'}>
                        <Avatar />
                    </Skeleton>
                </Box>
            </Box>
            <Box display="flex">
                <Box className={classes.contentsLeftBox}>
                    <Skeleton variant={'rect'} animation={'wave'} />
                </Box>
                <Box className={classes.contentsRightBox}>
                    <Skeleton variant={'rect'} height={'100%'} animation={'wave'} />
                </Box>
            </Box>
        </div>
    );
}

export default SkeletonPenViewer;
