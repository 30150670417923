import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, Dialog, FormControl, IconButton, OutlinedInput, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../../../common/images/DialogCloseIcon.svg';
import { ButtonNames } from '../MobileSettingComponent';
import { injectIntl } from 'react-intl';
import { ReactComponent as PersonIcon } from '../../../../common/images/PersonIcon.svg';
import MobileMakeAvatar from '../../profileSettings/MobileMakeAvatar';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 600px)': {
                width: 370,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    boxStyle: {
        padding: '30px 0 0',
        '@media all and (max-width: 600px)': {
            padding: '10px 0 0',
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    buttonStyle: {
        right: -40,
        top: -15,
        padding: 0,
        background: 'transparent',
        position: 'relative',
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            right: -25,
            top: -15,
        },
    },
    avatarBox: {
        width: 150,
        height: 150,
        borderRadius: '50%',
        // background:'#a3a8af',
        // background: `url(${TestAvatar})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        background: '#a3a8af',
        '& svg': {
            width: 72,
            height: 72,
        },
        '@media all and (max-width: 600px)': {
            width: 100,
            height: 100,
            '& svg': {
                width: 50,
                height: 50,
            },
        },
    },
    cameraBox: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: 35,
        height: 35,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#e1e1e1',
        border: '2px solid #fff',
    },
    input: {
        display: 'none',
    },
    textField: {
        width: '100%',
        marginTop: 30,
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #d9dbde',
            },
        '& .MuiOutlinedInput-root': {
            width: '100%',
            borderRadius: 7,
            '&:hover': {
                borderColor: '#d9dbde',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px 10px',
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#9d9d9d',
                opacity: 1,
            },
        },
        '@media all and (max-width: 600px)': {
            '& .MuiOutlinedInput-input': {
                padding: '10px 10px',
                fontSize: '0.813rem',
            },
        },
    },
    textStyle: {
        width: '100%',
        fontSize: '0.75rem',
        color: '#a3a8af',
        fontWeight: 300,
        marginTop: 10,
        marginBottom: 30,
        textAlign: 'left',
    },
    textBold: {
        fontWeight: 600,
        textDecoration: 'underline',
    },
    buttonStyle1: {
        width: '100%',
        height: 48,
        borderRadius: 8,
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            height: 33,
            padding: '6px 0',
            fontSize: '0.75rem',
            borderRadius: 4,
        },
    },
});

class MobileProfileEditDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleClickClose = e => {
        const init = '';
        e.target.value = init;
        this.props.handleChangeUserName(e);
        this.props.handleClose(e.currentTarget.name);
    };

    handleClickModifyBtn = e => {
        this.props.handleModifyUserName();
        this.props.handleModifyUserAvatar();
    };

    render() {
        const { classes, intl, loadAvatarImage, handleChangeUserName, isModify, saveAvatarImage } = this.props;
        const { user } = this.props;

        return (
            <Dialog open={this.props.profileDialogOpen} className={classes.dialogBox}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.titleText}>{intl.formatMessage({ id: 'profile_settings' })}</Typography>
                    <IconButton className={classes.iconButton} name={ButtonNames.ProfileDialogOpen} onClick={this.handleClickClose} disableRipple>
                        <DialogCloseIcon />
                    </IconButton>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.boxStyle}>
                    <Avatar alt="profile image" className={classes.avatarBox}>
                        {loadAvatarImage ? <img id="profileImage" src={loadAvatarImage} alt={''} /> : <PersonIcon />}
                        {/*{UTIL.getFirstCapitalLetter(userDetail.name)}*/}
                    </Avatar>
                    <Box className={classes.buttonStyle}>
                        {/*<Box className={classes.cameraBox}>*/}
                        <MobileMakeAvatar />
                        {/*</Box>*/}
                    </Box>

                    {/*<input accept="image/*" className={classes.input} id="contained-button-file" multiple type="file" />*/}
                    {/*<label htmlFor="contained-button-file">*/}
                    {/*    <Button component="span" className={classes.buttonStyle} disableRipple>*/}
                    {/*        <span className={classes.avatarBox}></span>*/}
                    {/*        <span className={classes.cameraBox}>*/}
                    {/*            <CameraIcon />*/}
                    {/*        </span>*/}
                    {/*    </Button>*/}
                    {/*</label>*/}
                    <FormControl className={classes.textField} variant="outlined">
                        <OutlinedInput
                            inputProps={{
                                'aria-label': 'company input box',
                                maxLength: 50,
                            }}
                            id="outlined-company"
                            labelWidth={0}
                            placeholder="이름을 입력하세요"
                            defaultValue={user.name}
                            onChange={handleChangeUserName}
                        />
                    </FormControl>
                    <Typography className={classes.textStyle}>
                        *프로필 이름 및 사진을 변경하면 <span className={classes.textBold}>기본 프로필</span>
                        도 <br /> 자동 변경됩니다.
                    </Typography>
                    <Button
                        className={classes.buttonStyle1}
                        onClick={this.handleClickModifyBtn}
                        disableRipple
                        disabled={!isModify && !saveAvatarImage}
                    >
                        확인
                    </Button>
                </Box>
            </Dialog>
        );
    }
}

export default withStyles(styles)(injectIntl(MobileProfileEditDialogComponent));
