import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Paper,
    Popper,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { ReactComponent as EyeIcon } from '../../../common/images/EyeIcon.svg';
import { ReactComponent as Close } from '../../../common/images/Close.svg';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ReactComponent as CheckCircleAgreeOffIcon } from '../../../common/images/CheckCircleAgreeOffLineIcon.svg';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../../common/images/CheckCircleAgreeOnFillIcon.svg';

const style = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: 10,
        maxWidth: 350,
        overflow: 'auto',
        boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.4)',
        marginLeft: 70,
        '@media (min-width: 319px) and (max-width: 374px)': {
            maxWidth: 310,
            marginLeft: 20,
        },
        '& .MuiTypography-h6': {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#000',
            fontWeight: 600,
        },
        '& .MuiDialogTitle-root': {
            padding: '0 0 10px',
            borderBottom: '1px solid #c0c2c3',
            marginBottom: 20,
        },
        '& .MuiDialogContent-root': {
            padding: 0,
        },
        '& .MuiButton-root.Mui-disabled': {
            background: '#fff',
            color: '#88889D',
            border: '1px solid #A3A8AF',
        },
    },
    buttonText: {
        textDecoration: 'underline',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0097ff',
        textAlign: 'left',
        padding: 0,
        minWidth: 0,
        '&:hover': {
            background: 'transparent',
            textDecoration: 'underline',
        },
    },
    popper: {
        zIndex: 1,
        marginLeft: 20,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
        '@media all and (max-width: 374px)': {
            display: 'none',
        },
    },
    dialogTitleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        fontWeight: 600,
    },
    textStyle1: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        fontWeight: 600,
        width: 95,
    },
    inputStyle: {
        width: 170,
        padding: '6px 10px',
        borderRadius: 4,
        fontSize: '0.75rem',
        border: '1px solid #b7bcd6',
        background: '#fff',
        '&:focus': {
            outline: 'none',
        },
    },
    inputText: {
        marginLeft: 115,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#8d909e',
    },
    checkStyle: {
        marginLeft: 100,
        fontFamily: 'NanumSquareRoundOTF',
        color: '#8d909e',
    },
    checkBox: {
        '& svg': {
            width: 18,
            height: 18,
        },
    },
    checkText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.625rem',
        color: '#8d909e',
        marginRight: 10,
    },
    buttonStyle: {
        width: 100,
        padding: '5px 0',
        // background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor),
        background: '#0097ff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#fff',
        fontWeight: 600,
        '&:hover': {
            // background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor),
            background: '#0097ff',
        },
    },
    iconBtn: {
        padding: '0 0 0 5px',
        marginLeft: 10,
        '& svg': {
            width: 15,
            height: 15,
        },
    },
    disableBg: {
        '&:hover, &.MuiCheckbox-colorSecondary.Mui-checked:hover': {
            background: 'transparent',
        },
    },
});

class MobileChangePassword extends Component {
    state = {
        arrow: true,
        arrowRef: null,
        disablePortal: false,
        flip: true,
        placement: 'bottom',
    };

    handleClickButton = () => {
        this.props.userStore.changePasswordDialogState(true);
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handleClose = () => {
        this.props.userStore.changePasswordDialogState(false);
        this.props.userStore.initPasswordChange();
    };
    handleChangePassword = () => {
        const { userDetail } = this.props.userStore;
        this.props.userStore.modifyUserPassword(userDetail.email);
    };
    handleChangeInputState = id => {
        if (document.getElementById(`${id}`).getAttribute('type') !== 'text') {
            document.getElementById(`${id}`).setAttribute('type', 'text');
        } else {
            document.getElementById(`${id}`).setAttribute('type', 'password');
        }
    };

    render() {
        const { classes, intl, userStore } = this.props;
        const { placement, disablePortal, flip, arrow, arrowRef } = this.state;
        return (
            <div className={classes.root}>
                <Button
                    innerRef={node => {
                        this.anchorEl = node;
                    }}
                    onClick={this.handleClickButton}
                    className={classes.buttonText}
                >
                    <FormattedMessage id="change" />
                </Button>
                <Popper
                    open={userStore.passwordChangeDialogOpen}
                    anchorEl={this.anchorEl}
                    placement={placement}
                    disablePortal={disablePortal}
                    className={classes.popper}
                    modifiers={{
                        flip: {
                            enabled: flip,
                        },
                        arrow: {
                            enabled: arrow,
                            element: arrowRef,
                        },
                        preventOverflow: {
                            enabled: false,
                            boundariesElement: 'scrollParent',
                        },
                    }}
                >
                    {arrow ? <span className={classes.arrow} ref={this.handleArrowRef} /> : null}
                    <Paper className={classes.paper}>
                        <DialogTitle>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography className={classes.dialogTitleText}>
                                    <FormattedMessage id="change_password" />
                                </Typography>
                                <IconButton onClick={this.handleClose} style={{ padding: 5 }}>
                                    <Close style={{ width: 20, height: 20 }} />
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText component="div">
                                <Box display="flex" alignItems="center" pb={1}>
                                    <Typography className={classes.textStyle1}>
                                        <FormattedMessage id="current_password" />
                                    </Typography>
                                    <Box display="flex" flexDirection="column">
                                        <Box display="flex" alignItems="center">
                                            <form>
                                                <input
                                                    name="password"
                                                    type="password"
                                                    tabIndex={1}
                                                    id="password"
                                                    autoFocus={true}
                                                    placeholder={intl.formatMessage({ id: 'msg.insert_current_password' })}
                                                    className={classes.inputStyle}
                                                    onChange={e => userStore.changeCurrentPassword(e.target.value)}
                                                />
                                            </form>
                                            <IconButton
                                                id="password"
                                                onClick={e => this.handleChangeInputState(e.currentTarget.id)}
                                                className={classes.iconBtn}
                                            >
                                                <EyeIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center" pb={1}>
                                    <Typography className={classes.textStyle1}>
                                        <FormattedMessage id="new_password" />
                                    </Typography>
                                    <Box display="flex" flexDirection="column">
                                        <Tooltip
                                            open={userStore.newPasswordEqualsValidate}
                                            title={'사용할 수 없는 비밀번호 입니다.'}
                                            placement={'top'}
                                            arrow
                                            disableFocusListener
                                            disableTouchListener
                                        >
                                            <Box display="flex" alignItems="center">
                                                <form>
                                                    <input
                                                        name="newPassword"
                                                        type="password"
                                                        id="newPassword"
                                                        tabIndex={2}
                                                        placeholder={intl.formatMessage({ id: 'msg.insert_new_password' })}
                                                        className={classes.inputStyle}
                                                        onChange={e => userStore.changeNewPassword(e.target.value)}
                                                    />
                                                </form>
                                                <IconButton
                                                    id="newPassword"
                                                    onClick={e => this.handleChangeInputState(e.currentTarget.id)}
                                                    className={classes.iconBtn}
                                                >
                                                    <EyeIcon />
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center" pb={1}>
                                    <Typography className={classes.textStyle1}>
                                        <FormattedMessage id="new_password_confirmation" />
                                    </Typography>
                                    <Box display="flex" flexDirection="column">
                                        <Box display="flex" alignItems="center">
                                            <form>
                                                <input
                                                    name="againPassword"
                                                    type="password"
                                                    id="againPassword"
                                                    tabIndex={3}
                                                    placeholder={intl.formatMessage({ id: 'msg.insert_new_password_confirmation' })}
                                                    className={classes.inputStyle}
                                                    onChange={e => userStore.changeConfirmNewPassword(e.target.value)}
                                                />
                                            </form>
                                            <IconButton
                                                id="againPassword"
                                                onClick={e => this.handleChangeInputState(e.currentTarget.id)}
                                                className={classes.iconBtn}
                                            >
                                                <EyeIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.checkBox}>
                                    {userStore.newPasswordValidateCombination ? <CheckCircleAgreeOnIcon /> : <CheckCircleAgreeOffIcon />}
                                    <Typography className={classes.checkText}>영문, 숫자, 특수문자 중 2가지 이상 조합</Typography>
                                    {userStore.newPasswordValidateLength ? <CheckCircleAgreeOnIcon /> : <CheckCircleAgreeOffIcon />}
                                    <Typography className={classes.checkText}>10자 이상</Typography>
                                    {/*<Typography className={classes.inputText}>*/}
                                    {/*    <FormattedMessage id="msg.validation_password" />*/}
                                    {/*</Typography>*/}
                                    {/*<MarkQuestion style={{ marginLeft: 2 }} />*/}
                                </Box>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                className={classes.buttonStyle}
                                onClick={this.handleChangePassword}
                                disabled={!userStore.isPossibleChangePassword || userStore.getIsLoading}
                                tabIndex={4}
                            >
                                <FormattedMessage id="save" />
                            </Button>
                        </DialogActions>
                    </Paper>
                </Popper>
            </div>
        );
    }
}

export default withStyles(style)(inject('authStore', 'userStore')(injectIntl(observer(MobileChangePassword))));
