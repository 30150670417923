import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { ReactComponent as PencilSimpleLine } from '../../common/images/PencilSimpleLine.svg';
import ByFormatComponent from './ByFormatComponent';
import ByStudentComponent from './ByStudentComponent';
import { inject, observer } from 'mobx-react';
import { withRouter } from '../../components/WithRouter';
const styles = theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 1110,
        },
        width: 870,
        boxSizing: 'border-box',
    },
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        '&:after': {
            content: '""',
            width: '100%',
            height: 3,
            backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: -1,
        },
        '& button': {
            minWidth: 100,
            fontSize: '0.938rem',
            '&.Mui-selected': {
                fontWeight: 700,
                color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
            },
        },
        '& .MuiTabs-indicator': {
            height: 3,
            backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
});
class WritingBoardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { tabs: 0 };

        const cS = this.props.classStore;

        cS.getGroupDetail(this.props.groupId);
    }
    handleTabChange = (_event, tabs) => {
        this.setState({ tabs });
    };

    render() {
        const { classes } = this.props;
        const { tabs } = this.state;
        return (
            <div className={classes.root}>
                <Tabs value={tabs} onChange={this.handleTabChange} className={classes.tabStyle}>
                    <Tab label="서식별" disableRipple />
                    <Tab label="학생별" disableRipple />
                </Tabs>
                {tabs === 0 && <ByFormatComponent gruopId={this.props.groupId} />}
                {tabs === 1 && <ByStudentComponent gruopId={this.props.groupId} />}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('neoPenStatisticsStore', 'neoPenStore', 'classStore')(observer(WritingBoardComponent))));
