import React, { Component } from 'react';
import clsx from 'clsx';
import { Box, Button, Dialog, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 390,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    textStyle: {
        fontSize: '1.25rem',
        color: '#000',
        textAlign: 'center',
        margin: '70px 0 95px',
    },
    buttonStyle: {
        width: 120,
        height: 40,
        borderRadius: 4,
        fontSize: '1.125rem',
        border: '1px solid #000',
        color: '#000',
        background: '#fff',
        '&:hover': {
            background: '#fff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    btnStyle: {
        background: '#0097ff',
        marginLeft: 10,
        color: '#fff',
        border: 0,
        '&:hover': {
            background: '#0097ff',
        },
    },
});

class WrittenDeleteDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes, title } = this.props;

        return (
            <div className={classes.root}>
                <Dialog open={this.props.deleteDialogOpen} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <IconButton className={classes.iconButton} onClick={this.props.handleDeleteDialogClose} disableRipple>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>

                    <Typography className={classes.textStyle}>{title} 서식을 삭제하시겠습니까?</Typography>

                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Button className={clsx(classes.buttonStyle, classes.btnStyle)} onClick={this.props.handleDelete} disableRipple>
                            삭제
                        </Button>
                    </Box>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(WrittenDeleteDialogComponent);
