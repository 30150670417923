import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, FormControl, IconButton, OutlinedInput, TextareaAutosize, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { inject, observer } from 'mobx-react';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 490,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 30,
    },
    titleText: {
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
    },
    textField: {
        marginBottom: 20,
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #d9dbde',
            },
        '& .MuiOutlinedInput-root': {
            borderRadius: 7,
            '&:hover': {
                borderColor: '#d9dbde',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px 10px',
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#a3a8af',
                opacity: 1,
                fontWeight: 300,
            },
        },
        '@media all and (max-width: 600px)': {
            marginBottom: 10,
            '& .MuiOutlinedInput-input': {
                padding: '10px 10px',
                fontSize: '0.813rem',
            },
        },
    },
    textareaStyle: {
        border: '1px solid #d9dbde',
        borderRadius: 7,
        padding: '10px 10px',
        overflowY: 'auto',
        resize: 'none',
        boxSizing: 'border-box',
        fontSize: '1rem',
        color: '#333',
        background: 'transparent',
        '&::placeholder': {
            color: '#a3a8af',
            opacity: 1,
            fontWeight: 300,
        },
        '&:focus': {
            outline: 'none',
        },
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#bebebe',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '3px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    buttonStyle: {
        marginTop: 20,
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        color: '#fff',
        fontSize: '1.125rem',
        padding: '12px 0',
        borderRadius: 7,
        '&:hover': {
            fontWeight: 800,
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            marginTop: 10,
            fontSize: '0.75rem',
            padding: '5px 0',
            borderRadius: 5,
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
});

class NoticeDialogComponent extends Component {
    handleSubmit = () => {
        if (this.props.isCreate) {
            this.props.classStore.createGroupNotice(this.props.authStore.loginUser);
            this.props.handleClose();
        } else {
            this.props.classStore.modifyGroupNotice(this.props.authStore.loginUser);
            this.props.handleClose();
        }
    };

    handleClickClose = () => {
        this.props.handleClose();
        this.props.classStore.initNotice();
    };

    render() {
        const { classes, isCreate } = this.props;
        const { isSaveNotice, isEditSaveNotice } = this.props.classStore;

        return (
            <div className={classes.root}>
                <Dialog open={this.props.dialogOpen} className={classes.dialogBox} disableScrollLock={true}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.titleBox}>
                        {isCreate ? (
                            <Typography className={classes.titleText}>공지사항 등록</Typography>
                        ) : (
                            <Typography className={classes.titleText}>공지사항 수정</Typography>
                        )}
                        <IconButton className={classes.iconButton} disableRipple onClick={this.handleClickClose}>
                            {' '}
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>

                    {isCreate ? (
                        <Box display="flex" flexDirection="column">
                            <FormControl className={classes.textField} variant="outlined">
                                <OutlinedInput
                                    inputProps={{ 'aria-label': 'title input box', maxLength: 40 }}
                                    id="outlined-title"
                                    labelWidth={0}
                                    placeholder="제목 입력 (최대 40자 이내)"
                                    onChange={e => this.props.classStore.changeGroupNoticeTitle(e.target.value)}
                                />
                            </FormControl>

                            <TextareaAutosize
                                name="contents"
                                minRows={5}
                                maxRows={5}
                                maxLength={1000}
                                aria-label="content input box"
                                className={classes.textareaStyle}
                                placeholder="내용 입력 (최대 1000자 이내)"
                                onChange={e => this.props.classStore.changeGroupNoticeContent(e.target.value)}
                            />

                            <Button className={classes.buttonStyle} disableRipple onClick={this.handleSubmit} disabled={!isSaveNotice}>
                                게시
                            </Button>
                        </Box>
                    ) : (
                        <Box display="flex" flexDirection="column">
                            <FormControl className={classes.textField} variant="outlined">
                                <OutlinedInput
                                    value={this.props.classStore.editNotice.title}
                                    inputProps={{ 'aria-label': 'title input box', maxLength: 40 }}
                                    id="outlined-title"
                                    labelWidth={0}
                                    placeholder="제목 입력 (최대 40자 이내)"
                                    onChange={e => this.props.classStore.changeGroupEditNoticeTitle(e.target.value)}
                                />
                            </FormControl>

                            <TextareaAutosize
                                name="contents"
                                value={this.props.classStore.editNotice.content}
                                minRows={5}
                                maxRows={5}
                                maxLength={1000}
                                aria-label="content input box"
                                className={classes.textareaStyle}
                                placeholder="내용 입력 (최대 1000자 이내)"
                                onChange={e => this.props.classStore.changeGroupEditNoticeContent(e.target.value)}
                            />

                            <Button className={classes.buttonStyle} disableRipple onClick={this.handleSubmit} disabled={!isEditSaveNotice}>
                                게시
                            </Button>
                        </Box>
                    )}
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(inject('authStore', 'classStore')(observer(NoticeDialogComponent)));
