import { makeAutoObservable } from 'mobx';

export const TooltipType = {
    Print: 'Print',
    LimitUpload: 'LimitUpload',
    MobileRealTimeOpen: 'MobileRealTimeOpen',
    UploadOfflineData: 'UploadOfflineData',
};

export default class TooltipStore {
    constructor() {
        makeAutoObservable(this);

        Object.values(TooltipType).map(type => (this.isClosed[type] = false));
    }

    isClosed = {};

    closeTooltip = type => {
        this.isClosed[type] = true;
    };

    openTooltip = type => {
        this.isClosed[type] = false;
    };
}
