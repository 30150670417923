import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    LinearProgress,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Popover,
    Select,
    Tab,
    TableCell,
    TableRow,
    Tabs,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { ReactComponent as MoreIcon } from '../../common/images/DotsThreeOutlineVerticalIcon.svg';
import { ReactComponent as NotePencilIcon } from '../../common/images/NotePencilIcon.svg';
import { ReactComponent as LockLaminatedIcon } from '../../common/images/LockLaminatedIcon.svg';
import { ReactComponent as CheckCircleAgreeOffIcon } from '../../common/images/CheckCircleAgreeOffIcon.svg';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../common/images/CheckCircleAgreeOnIcon.svg';
import { ReactComponent as X } from '../../common/images/X.svg';
import { ReactComponent as Info } from '../../common/images/Info.svg';
import RecordInfoComponent from './RecordInfoComponent';
import {PrintState, MovingObjectType, PaperGroupType} from '../../stores/NeoPenStore';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import FormRegistrationDialogComponent from '../dialog/FormRegistrationDialogComponent';
import PrintPreviewDialogComponent from '../dialog/PrintPreviewDialogComponent';
import NotePlayDialogComponent from '../dialog/NotePlayDialogComponent';
import { withRouter } from '../../components/WithRouter';
import WrittenDeleteDialogComponent from '../dialog/WrittenDeleteDialogComponent';
import FormRegistrationNeoNoteDialogComponent from '../dialog/FormRegistrationNeoNoteDialogComponent';
import RegisterNoteIcon from '../../common/images/RegisterNoteIcon.png';
import { ReactComponent as FilePdfIcon } from '../../common/images/FilePdfIcon.svg';
import { ReactComponent as Mathematics } from '../../common/images/Mathematics.svg';
import { ReactComponent as FolderPlusFillIcon } from '../../common/images/FolderPlusFillIcon.svg';
import { ReactComponent as ChartBarPurpleIcon } from '../../common/images/ChartBarPurpleIcon.svg';
import { ToastsStore } from 'react-toasts';
import { PATH_UTIL } from '../../common/util/path.util';
import MathTextBookRegistrationDialogComponent from '../dialog/MathTextBookRegistrationDialogComponent';
import SelectFolderDialogComponent from '../dialog/SelectFolderDialogComponent';
import RecordComponent from './RecordComponent';
import { ReactComponent as FolderNotchIcon } from '../../common/images/FolderNotchIcon.svg';
import MoreButton from '../../components/common/MoreButton';
import { CLASS_STORE_MESSAGE_ID, LoadingState } from '../../stores/ClassStore';
import { TooltipType } from '../../stores/TooltipStore';
import { Skeleton } from '@material-ui/lab';
import PaperGroupFolderBreadcrumbs from '../neoPen/PaperGroupFolderBreadcrumbs';
import FormTabComponentForEvaluation from './FormTabComponentForEvaluation';
import ResultTabComponentForEvaluation from './ResultTabComponentForEvaluation';

const styles = theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
        '& table button.MuiButton-root': {
            minWidth: 'unset',
            padding: 0,
        },
    },
    flexCenter: {
        width: 'calc(100% - 94px - 46px)',
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
    },
    optionBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    formControl: {
        minWidth: 100,
        width: 100,
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        '&:after': {
            content: '""',
            width: '100%',
            height: 3,
            backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: -1,
        },
        '& button': {
            minWidth: 100,
            fontSize: '0.938rem',
            '&.Mui-selected': {
                fontWeight: 700,
                color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
            },
        },
        '& .MuiTabs-indicator': {
            height: 3,
            backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
    btnStyle: {
        border: '1px solid #bfbfbf',
        borderRadius: 4,
        marginLeft: 10,
        color: '#000',
        padding: '1px 8px',
        fontWeight: 400,
        fontSize: '0.875rem',
        '&:hover': {
            background: 'transparent',
        },
    },
    btnPrint: {
        marginRight: 20,
    },
    btnStyle2: {
        border: () => (theme.configs.MainBtnColor ? `1px solid ${theme.configs.MainBtnColor}` : '1px solid #0097FF'),
        borderRadius: 7,
        color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        padding: '7px 23px',
        fontWeight: 600,
        '&:hover': {
            fontWeight: 800,
            background: 'transparent',
        },
    },
    breadcrumbsBtnStyle: {
        marginLeft: 10,
        '&:hover': {
            background: 'transparent',
        },
    },
    popoverBox: {
        '& .MuiPopover-paper': {
            '@media all and (min-width: 1500px)': {
                width: 185,
            },
            width: 180,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
            borderRadius: 7,
            padding: '8px 0',
        },
        '& .MuiListItem-root': {
            padding: '3px 10px',
            '&:hover': {
                background: '#d3d7db',
            },
        },
        '& .MuiListItemIcon-root': {
            minWidth: 20,
        },
        '& .MuiListItemText-root': {
            paddingLeft: 0,
            '& span': {
                fontSize: '0.813rem',
            },
        },
    },
    tableBtnStyle: {
        width: '100%',
        '& span': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& p': {
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                textAlign: 'left',
                whiteSpace: 'nowrap',
            },
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    checkBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#a9adb4',
        fontSize: '0.875rem',
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 4,
        },
    },
    checkBoxStyleOn: {
        color: '#0097FF',
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 26px',
        boxSizing: 'border-box',
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listItemTitle: {
        width: '100%',
        fontSize: '0.875rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        whiteSpace: 'nowrap',
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    progressBarStyle: {
        width: 'calc(100% - 100px)',
        margin: '0 0 0 10px',
        '& .MuiLinearProgress-root': {
            backgroundColor: '#E5E5E6',
            borderRadius: 10,
        },
        '& .MuiLinearProgress-bar': {
            backgroundColor: '#FFC56E',
        },
    },
    progressText: {
        color: '#333',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 4,
        '& p': {
            fontSize: '0.625rem',
        },
        '& p:first-child': {
            color: '#AEB3B9',
        },
    },
    emptyText: {
        fontSize: '0.875rem',
        color: '#000',
        marginTop: 30,
        textAlign: 'center',
    },
    toasts: {
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    btnDefault: {
        padding: 0,
        marginBottom: 4,
        marginRight: 20,
    },
    explanationBox: {
        width: 210,
        padding: '10px',
        background: '#2078e8',
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 3,
        position: 'absolute',
        zIndex: 100,
        bottom: -77,
        left: -73,
        '&::before': {
            backgroundColor: '#2078e8',
            content: "''",
            display: 'block',
            height: '13px',
            position: 'absolute',
            top: -6,
            left: 109,
            transform: 'rotate( -227deg ) skew( 0deg )',
            width: '13px',
            // boxShadow: '2px -2px 2px 0 rgb(178 178 178 / 20%)',
            zIndex: 200,
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    explanationBox2: {
        width: 230,
        zIndex: 10000,
        bottom: -123,
        left: -45,
        '&::before': {
            top: -6,
            left: 120,
        },
    },
    stepContents: {
        fontSize: '0.813rem',
        color: '#fff',
        fontWeight: 300,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    tableBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px solid #d3d7db',
        height: 80,
        '& td': {
            borderBottom: 0,
            padding: 0,
        },
    },
    folderBox: {
        width: 'calc(100% - 56px - 46px)',
    },
    skeletonStyle: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 26px',
    },
});
const LightTooltip = withStyles(_theme => ({
    tooltip: {
        padding: '4px 4px',
        border: '0.3px solid #000000',
        background: '#fffff5',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        color: '#000',
        borderRadius: '0',
        marginLeft: 5,
        marginTop: 5,
    },
}))(Tooltip);

const RESPONSE_KEY_CODE = {
    ESC: 27,
    ENTER: 13,
};

class WrittenEvaluationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 0,
            anchorEl: null,
            writeDataFilter: 'date',
            paperGroupFilter: 'date',
            recordDialogOpen: false,
            recordVideoOpen: false,
            formRegistrationOpen: false,
            open: false,
            anchorGeneral: null,
            writtenRecordAnchorEl: null,
            printPreviewOpen: false,
            isFiltered: false,
            completed: 0,
            deleteDialogOpen: false,
            mathTextBookDialogOpen: false,
            isLoadingPreview: false,
            menuInfoTooltip: false,

            isCreateFolder: false,
            newFolderName: '',

            renamingFolderId: undefined,
            renamingFolderName: '',

            renamingPaperGroupId: undefined,
            renamingPaperGroupName: '',
        };
        this.anchorRef = React.createRef();
        this.openRecordVideoModal = this.openRecordVideoModal.bind(this);
    }

    handleTabChange = (_event, tabs) => {
        const nPS = this.props.neoPenStore;

        nPS.selectPaperGroup(undefined);

        if (nPS.folders[null] !== undefined) nPS.selectFolder(nPS.folders[null]);

        if (tabs === 1) nPS.sortFolder(this.state.writeDataFilter);

        this.setState({ tabs });
    };

    handleChangeDataSort = event => {
        this.setState({ writeDataFilter: event.target.value });

        this.props.neoPenStore.sortFolder(event.target.value);
        this.props.neoPenStore.sortPaperGroupByModifiedAt(event.target.value);
    };

    handleOpenFormRegistration = () => {
        this.setState({
            formRegistrationOpen: true,
        });
    };

    handleMathTextBookDialogOpen = () => {
        this.handleClosePopover();
        this.setState({
            mathTextBookDialogOpen: true,
        });
    };

    handleCloseMathTextBookDialog = () => {
        this.setState({
            mathTextBookDialogOpen: false,
        });
    };

    handleCloseFormRegistration = () => {
        this.setState({
            formRegistrationOpen: false,
        });
    };

    handleOpenPreview = async paperGroup => {
        if (this.state.isLoadingPreview) return;

        this.setState({ isLoadingPreview: true });

        const url = await this.props.neoPenStore.getPdfDownloadUrl(paperGroup);

        window.open(url, '_blank');

        this.setState({ isLoadingPreview: false });
    };

    handleOpenPrintPreviewDialog = async paperGroup => {
        const { classes } = this.props;
        const nPS = this.props.neoPenStore;

        nPS.selectPaperGroup(paperGroup);

        if (nPS.selectedPaperGroup.section !== 10) {
            ToastsStore.info('프린트를 지원하지 않는 서식입니다.', 3000, classes.toasts);

            return;
        }

        const statusMsg = await nPS.printPOD();

        ToastsStore.info(statusMsg, 3000, classes.toasts);
    };

    handleClosePrintPreviewDialog = () => {
        this.setState({
            printPreviewOpen: false,
        });
    };

    openRecordVideoModal() {
        this.setState({ recordVideoOpen: true });
    }

    handleChangeLock = () => {
        this.props.neoPenStore.updatePaperGroupPublicState();

        this.setState(_state => ({
            anchorGeneral: null,
        }));
    };

    handleClosePopover = () => {
        this.props.tooltipStore.closeTooltip(TooltipType.LimitUpload);

        this.setState({
            writtenRecordAnchorEl: null,
        });
    };

    handleDelete = () => {
        this.props.neoPenStore.deletePaperGroup();

        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
            deleteDialogOpen: false,
        });
    };

    handleDeleteDialogOpen = () => {
        this.setState({
            deleteDialogOpen: true,
        });
    };

    handleDeleteDialogClose = () => {
        this.setState({
            deleteDialogOpen: false,
        });
    };

    handleClickPopover = event => {
        this.setState({
            writtenRecordAnchorEl: event.currentTarget,
            menuInfoTooltip: true,
        });
    };

    handleMoveFolderDialog = () => {
        this.handleClose();

        const nPS = this.props.neoPenStore;

        nPS.setSelectFolderDialogOpened(true, MovingObjectType.PaperGroup, nPS.selectedPaperGroup.id);
    };

    handleClickGeneral = (event, paperGroup) => {
        this.props.neoPenStore.selectPaperGroup(paperGroup);

        this.setState({ anchorGeneral: event.currentTarget });
    };

    handleClose = () => {
        this.props.tooltipStore.closeTooltip(TooltipType.LimitUpload);

        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
        });
    };

    handleChangeCheckBox = () => {
        this.setState({ isFiltered: !this.state.isFiltered });
    };

    selectPaperGroupMenusInMoreBtn = () => {
        const { isLeader } = this.props;
        const nPS = this.props.neoPenStore;

        if (isLeader) {
            return [
                {
                    name: nPS.selectedPaperGroup !== undefined && !nPS.selectedPaperGroup.public ? '공개로 변경' : '비공개로 변경',
                    func: () => this.handleChangeLock(),
                },
                {
                    name: '이름 바꾸기',
                    func: () => {
                        this.setState({ renamingPaperGroupId: nPS.selectedPaperGroup.id, renamingPaperGroupName: nPS.selectedPaperGroup.title });
                        this.handleClose();
                    },
                },
                { name: '이동', func: () => this.handleMoveFolderDialog() },
                { name: '삭제', func: () => this.handleDeleteDialogOpen() },
            ];
        }

        return [];
    };

    render() {
        const { classes, isLeader, tooltipStore } = this.props;
        const { isUploading } = this.props.classFileStore;
        const { tabs, anchorGeneral, writtenRecordAnchorEl, menuInfoTooltip } = this.state;
        const general = Boolean(anchorGeneral);
        const openPopover = Boolean(writtenRecordAnchorEl);

        const nPS = this.props.neoPenStore;

        const paperGroupMenusInMoreBtn = this.selectPaperGroupMenusInMoreBtn();
        let viewingPaperGroups = isLeader ? (this.state.isFiltered ? nPS.publicPaperGroups : nPS.activePaperGroups) : nPS.publicPaperGroups;

        viewingPaperGroups = viewingPaperGroups.filter(
            paperGroup => paperGroup.folderId === (nPS.currentFolder === undefined ? null : nPS.currentFolder.id),
        );

        return (
            <div className={classes.root}>
                <Tabs value={tabs} onChange={this.handleTabChange} className={classes.tabStyle}>
                    <Tab label={'평가결과'} disableRipple />
                    <Tab label={'서식'} disableRipple />
                </Tabs>

                {tabs === 0 && <ResultTabComponentForEvaluation isLeader={isLeader} />}
                {tabs === 1 && <FormTabComponentForEvaluation isLeader={isLeader} />}

                <NotePlayDialogComponent
                    open={this.state.recordVideoOpen}
                    onClose={() => this.setState({ recordVideoOpen: false })}
                    videoSrc={'../../common/images/testVideo.mp4'}
                    videoWidth={1024}
                    videoHeight={720}
                />

                <FormRegistrationDialogComponent
                    handleCloseFormRegistration={this.handleCloseFormRegistration}
                    formRegistrationOpen={this.state.formRegistrationOpen}
                    type={PaperGroupType.Exam}
                />

                <FormRegistrationNeoNoteDialogComponent
                    handleCloseFormRegistration={() => {
                        nPS.selectNeolabNoteDialogOpenChanged();
                    }}
                    formRegistrationOpen={nPS.selectNeolabNoteDialogOpen}
                    type={PaperGroupType.Exam}
                />

                <PrintPreviewDialogComponent
                    handleClosePrintPreviewDialog={this.handleClosePrintPreviewDialog}
                    printPreviewOpen={this.state.printPreviewOpen}
                />

                <WrittenDeleteDialogComponent
                    title={this.props.neoPenStore.selectedPaperGroup ? this.props.neoPenStore.selectedPaperGroup.title : ''}
                    handleDelete={this.handleDelete}
                    deleteDialogOpen={this.state.deleteDialogOpen}
                    handleDeleteDialogClose={this.handleDeleteDialogClose}
                />

                <MathTextBookRegistrationDialogComponent
                    handleCloseMathTextBookDialog={this.handleCloseMathTextBookDialog}
                    mathTextBookDialogOpen={this.state.mathTextBookDialogOpen}
                />

                <SelectFolderDialogComponent moveFolderDialog={nPS.isSelectFolderDialogOpened} type={PaperGroupType.Exam} />
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(
        inject('neoPenStore', 'authStore', 'classStore', 'classFileStore', 'tooltipStore')(injectIntl(observer(WrittenEvaluationComponent))),
    ),
);
