import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, ListItemIcon, ListItemText, MenuItem, Popover, Tab, Tabs, Tooltip } from '@material-ui/core';
import MobileClassManagementComponent from './MobileClassManagementComponent';
import MobileStudentsManagementComponent from './MobileStudentsManagementComponent';
import { ReactComponent as PlusCircleIcon } from '../../../common/images/PlusCircleIcon.svg';
import CreateAccountDialogComponent from '../../dialog/CreateAccountDialogComponent';
import { inject, observer } from 'mobx-react';
import { withRouter } from '../../../components/WithRouter';
import { GroupClassType } from '../../../stores/ClassStore';
import { checkSpace } from '../../../common/Validation';
import { ToastsStore } from 'react-toasts';
import { MEMBER_COMPONENT_TAB_NAME } from './MobileStudentListComponent';
import { PATH_UTIL } from '../../../common/util/path.util';
import { DEFAULT_PAGE, INIT_EMPTY_SEARCH_KEYWORD } from '../../../stores/PaginationStore';
import CoachMarkForm from '../../../components/common/CoachMarkForm';
import { USER_TYPE } from '../../../stores/UserStore';
import { ReactComponent as FilTextIcon } from '../../../common/images/FilTextIcon.svg';
import { ReactComponent as NoFilesIcon2 } from '../../../common/images/NoFilesIcon2.svg';
import ConfirmDialog from '../../../components/common/ConfirmDialog';

const styles = _theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    contents: {
        height: 'calc(100vh - 87px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: 57,
    },
    contentsIn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        '&:after': {
            content: '""',
            width: '100%',
            height: 3,
            // backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: -1,
        },
        '& button': {
            minWidth: 100,
            fontSize: '1.125rem',
            color: '#000',
            '&.Mui-selected': {
                fontWeight: 700,
            },
        },
        '& .MuiTabs-indicator': {
            height: 3,
            backgroundColor: '#0097ff',
            borderRadius: 10,
        },
    },
    wrap: {
        // '@media all and (min-width: 1500px)': {
        //     width: 730,
        // },
        // width: 620,
        width: 'calc(100vw - 32px)',
        margin: '20px auto',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    searchStyle: {
        '@media all and (min-width: 1500px)': {
            transform: 'translate(690px, 0)',
        },
        position: 'absolute',
        top: '-40px',
        right: '50%',
        transform: 'translate(570px, 0)',
    },
    gutter: {
        backgroundColor: 'rgba(192, 194, 195, 0.7)',
        width: '100%',
        height: 1,
    },

    titleCaption: {
        fontSize: '0.938rem',
    },
    btnStyle: {
        width: '100%',
        height: 40,
        marginTop: 20,
        backgroundColor: '#0097FF',
        color: '#fff',
        borderRadius: 7,
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
        '& svg': {
            marginRight: 4,
        },
        '&:hover': {
            backgroundColor: '#0097FF',
        },
    },
    btnStyle2: {
        width: '100%',
        height: 40,
        backgroundColor: '#0097FF',
        color: '#fff',
        borderRadius: 7,
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
        '& svg': {
            marginRight: 4,
        },
        '&:hover': {
            backgroundColor: '#0097FF',
        },
    },

    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: 12,
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listItemTitle: {
        fontSize: '0.875rem',
        display: 'flex',
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    downloadBtnStyle: {
        border: '1px solid #bfbfbf',
        borderRadius: 3,
        display: 'flex',
        minWidth: 32,
        '& svg': {
            width: 18,
            height: 18,
            '& path': {
                stroke: '#000',
            },
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    popoverBox: {
        '& .MuiPopover-paper': {
            width: '100%',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
            borderRadius: 7,
            padding: '8px 0',
        },
        '& .MuiListItem-root': {
            padding: '3px 10px',
            '&:hover': {
                background: '#d3d7db',
            },
        },
        '& .MuiListItemIcon-root': {
            minWidth: 20,
        },
        '& .MuiListItemText-root': {
            paddingLeft: 0,
            '& span': {
                fontSize: '0.813rem',
            },
        },
    },
    menuItemText: {
        fontSize: ' 0.688rem',
        color: '#0097ff',
        textDecoration: 'underline',
        // width: '100%',
        textAlign: 'right',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        background: 'transparent',
        '&:hover': {
            background: 'transparent',
            textDecoration: 'underline',
        },
    },
    classCoachBox: {
        position: 'absolute',
        '@media all and (min-width: 1500px)': {
            left: -150,
        },
        top: 60,
        left: 0,
    },
    ClassCoachBox2: {
        position: 'absolute',
        '@media all and (min-width: 600px)': {
            left: `calc(50vw - 190px)`,
        },
        top: 75,
        left: `calc(50vw - 140px)`,
    },
    MemberCoachBox: {
        position: 'absolute',
        top: 200,
        left: `calc(50vw - 150px)`,
    },
});
const LightTooltip = withStyles(theme => ({
    tooltip: {
        padding: '4px 4px',
        border: '0.3px solid #000000',
        background: '#fffff5',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        color: '#000',
        borderRadius: '0',
        marginLeft: 5,
        marginTop: 5,
    },
}))(Tooltip);

class MobileMyManagementComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 0,
            filter: '최근 작성일 순',
            createAccount: false,
            createAccountAnchorEl: null,
            isLeader: false,
            isNoClassFilter: false,
        };
    }

    componentDidMount() {
        const path = window.location.pathname;
        const tabs = PATH_UTIL.getGroupPath(path) ? PATH_UTIL.getGroupPath(path) : 0;
        if (tabs !== 0) {
            this.setState({ tabs: 1 });
        }
        this.props.classStore.getGroupList(this.props.authStore.loginUser, GroupClassType.MyClass);
    }

    componentDidUpdate(_prevProps, prevState, _snapshot) {
        if (prevState.tabs !== this.state.tabs) {
            if (this.state.tabs === 1) {
                this.requestAllUsers();
            } else {
                if (this.state.isNoClassFilter) {
                    this.handleChangeNoClassFilter();
                }
            }
        }
        if (prevState.createAccount !== this.state.createAccount && !this.state.createAccount) {
            this.props.managementStore.initNewGuest();
        }
    }

    componentWillUnmount() {
        const { paginationStore, classStore } = this.props;
        paginationStore.initPagination();
        classStore.initClass();
    }

    handleChangeNoClassFilter = () => {
        this.setState(prevState => ({ isNoClassFilter: !prevState.isNoClassFilter }));
    };

    handleChangeNoClassFilterAndFetchData = () => {
        console.log('handleChangeNoClassFilterAndFetchData');

        this.setState(
            prevState => ({ isNoClassFilter: !prevState.isNoClassFilter }),
            () => {
                this.requestAllUsers();
            },
        );
    };

    handleChangeSortAndFetchData = (sortType, selectedGroupId) => {
        const { paginationStore } = this.props;
        paginationStore.changeSort(sortType);

        if (selectedGroupId > 0) {
            this.requestSelectedGroupMembers(selectedGroupId);
        } else if (selectedGroupId === 0) {
            this.requestAllUsers();
        }
    };

    handleChange = (_event, tabs) => {
        const { paginationStore } = this.props;
        paginationStore.initPagination();
        this.setState({ tabs });
    };

    handleClose = () => {
        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
        });
    };

    handleClickGeneral = event => {
        this.setState({ anchorGeneral: event.currentTarget });
    };

    handleClickCreateAccount = () => {
        this.setState({
            createAccount: true,
            createAccountAnchorEl: null,
        });
    };

    handleClickPopover = event => {
        this.setState({
            createAccountAnchorEl: event.currentTarget,
        });
    };

    handleClosePopover = () => {
        this.setState({
            createAccountAnchorEl: null,
        });
    };

    handleClickCreateAccountClose = () => {
        this.setState({
            createAccount: false,
        });
    };

    handleCreateClass = () => {
        const { navigate } = this.props;
        navigate('/roomcreate');
    };

    requestInitGroupMemberAndTeamInfo = (groupId, childrenTab) => {
        const { paginationStore, classTeamStore, classStore } = this.props;

        paginationStore.initPagination();

        classStore.initSelectedGroupMemberAndOperators();
        classTeamStore.initTeams();

        this.requestSelectedGroupMembers(groupId);

        if (childrenTab === MEMBER_COMPONENT_TAB_NAME.Group) {
            this.requestGroupTeams(groupId);
            classStore.getGroupMemberAndOperatorsTotalCount(groupId);
        } else {
            this.requestSelectedGroupMembers(groupId);
            classTeamStore.getTeamsTotalCount(groupId);
        }
    };

    requestAllUsers = () => {
        const { authStore, paginationStore, managementStore } = this.props;
        const { loginUser } = authStore;
        const { page, rowsPerPage, sort, searchKeyword } = paginationStore;
        const { isNoClassFilter } = this.state;

        managementStore.getAllUsers(loginUser.email, page, rowsPerPage, sort, isNoClassFilter, searchKeyword, {
            setTotalCount: count => {
                paginationStore.changeTotalCount(count);
            },
        });
    };

    requestSelectedGroupMembers = groupId => {
        const { classes, authStore, paginationStore, classStore } = this.props;
        const { loginUser } = authStore;
        const { page, rowsPerPage, sort } = paginationStore;

        classStore.getGroupMemberAndOperators(loginUser.email, groupId, page, rowsPerPage, sort, {
            successAction: (groupUsers, totalCount) => {
                paginationStore.changeTotalCount(totalCount);

                classStore.resetSelectedGroupMembersAndOperators(groupUsers);
            },
            alert: () => {
                ToastsStore.error('서버와의 연결이 원활하지 않습니다. 다시 시도해 주세요.', 3000, classes.toasts);
            },
        });
    };

    requestGroupTeams = groupId => {
        const { authStore, paginationStore, classTeamStore } = this.props;
        const { loginUser } = authStore;
        const { page, rowsPerPage, sort } = paginationStore;

        classTeamStore.getTeams(groupId, loginUser.email, page, rowsPerPage, sort, {
            successAction: (teams, totalCount) => {
                classTeamStore.resetTeams(teams);
                paginationStore.changeTotalCount(totalCount);
            },
        });
    };

    requestUserInfo = () => {
        const { managementStore, userStore } = this.props;
        const { selectedUser } = managementStore;
        userStore.getUserInfo(selectedUser.email);
    };

    changeRowsPerPage = (value, selectedGroupId, tabName) => {
        const { paginationStore } = this.props;
        paginationStore.changeRowsPerPage(value);
        paginationStore.changePage(DEFAULT_PAGE);

        if (selectedGroupId > 0) {
            if (tabName === MEMBER_COMPONENT_TAB_NAME.Member) {
                this.requestSelectedGroupMembers(selectedGroupId);
            } else {
                this.requestGroupTeams(selectedGroupId);
            }
        } else if (selectedGroupId === 0) {
            this.requestAllUsers();
        }
    };

    changePage = (value, selectedGroupId, tabName) => {
        const { paginationStore } = this.props;
        paginationStore.changePage(value);

        if (selectedGroupId > 0) {
            if (tabName === MEMBER_COMPONENT_TAB_NAME.Member) {
                this.requestSelectedGroupMembers(selectedGroupId);
            } else {
                this.requestGroupTeams(selectedGroupId);
            }
        } else if (selectedGroupId === 0) {
            this.requestAllUsers();
        }
    };

    checkExistEmail = () => {
        const { classes, managementStore, authStore } = this.props;
        const { loginUser } = authStore;

        managementStore.checkExistEmail(loginUser.email, {
            info: () => {
                ToastsStore.info('사용 가능한 이메일 입니다.', 3000, classes.toasts);
            },
            alert: () => {
                ToastsStore.error('중복된 이메일 입니다. 다시 입력해주세요.', 3000, classes.toasts);
            },
            error: () => {
                ToastsStore.error('잘못된 이메일 형식입니다. 다시 입력해주세요.', 3000, classes.toasts);
            },
        });
    };

    checkExistSmartPenAddress = () => {
        const { classes, managementStore, authStore } = this.props;
        const { loginUser } = authStore;

        managementStore.checkExistSmartPenAddress(loginUser.email, {
            info: () => {
                ToastsStore.info('사용 가능한 스마트펜 입니다.', 1500, classes.toasts);
            },
            alert: () => {
                ToastsStore.error('스마트펜 정보를 확인하여 다시 입력해주세요.', 1500, classes.toasts);
            },
            error: () => {
                ToastsStore.error('서버와의 통신이 원활하지 않습니다. 다시 시도해주세요.', 1500, classes.toasts);
            },
        });
    };

    createGuest = () => {
        const { classes, authStore, managementStore, userStore } = this.props;
        const { loginUser } = authStore;
        const { newGuestNameValidation } = managementStore;

        if (!newGuestNameValidation) {
            ToastsStore.error('입력하신 정보를 확인해주세요.', 3000, classes.toasts);
            return false;
        }
        managementStore.createGuest(loginUser.email, {
            closeDialog: () => {
                this.handleClickCreateAccountClose();
            },
            getAllUsers: () => {
                this.requestAllUsers();
            },
            initCoachMarkState: () => {
                userStore.checkCoachState(loginUser.email);
            },
            error: () => {
                ToastsStore.error('서버와의 연결이 원활하지 않습니다. 다시 시도해 주세요.', 1500, classes.toasts);
            },
        });
    };

    handleChangeNewGuestEmail = e => {
        checkSpace(e);
        this.props.managementStore.changeNewGuestEmail(e.target.value);
    };

    handleChangeNewGuestPassword = e => {
        checkSpace(e);
        this.props.managementStore.changeNewGuestPassword(e.target.value);
    };

    handleChangeNewGuestName = e => {
        this.props.managementStore.changeNewGuestName(e.target.value);
    };

    handleChangeSmartPenAddress = (value, index) => {
        this.props.managementStore.changeNewGuestSmartPenMacAddress(value, index);
    };

    handleCreateRandomPassword = () => {
        const { managementStore } = this.props;
        const pw = managementStore.randomPassword();
        managementStore.changeNewGuestPassword(pw);
    };

    setSelectedUserEmail = email => {
        this.props.managementStore.setSelectedUserEmail(email);
    };

    initUserInfo = () => {
        this.props.userStore.initUserInfo();
    };

    handleResetPassword = resetGuest => {
        const { authStore, managementStore } = this.props;
        const { loginUser } = authStore;
        managementStore.resetGuestPassword(loginUser.email, resetGuest, {
            info: () => {
                ToastsStore.info('비밀번호를 초기화하였습니다.', 1500);
            },
            error: () => {
                ToastsStore.error('서버와의 연결이 원활하지 않습니다. 잠시 후 다시 시도해 주세요.', 1500);
            },
        });
    };

    handleModifyGuestSmartPenAddress = modifyGuest => {
        const { managementStore, authStore } = this.props;
        const { loginUser } = authStore;
        managementStore.modifyGuestSmartPenAddress(loginUser.email, modifyGuest, {
            getAllUsers: () => {
                this.requestAllUsers();
            },
            error: () => {
                ToastsStore.error('서버와의 연결이 원활하지 않습니다. 다시 시도해 주세요.', 1500);
            },
        });
    };

    handleAllUsersExcelExport = () => {
        const { managementStore, authStore } = this.props;
        const { loginUser } = authStore;
        managementStore.requestAllUsersExcelExport(loginUser.email, {
            error: () => {
                ToastsStore.error('서버와의 연결이 원활하지 않습니다. 다시 시도해 주세요.', 1500);
            },
        });
    };

    handleGroupUsersExcelExport = groupId => {
        const { managementStore, authStore } = this.props;
        const { loginUser } = authStore;
        managementStore.requestGroupUsersExcelExport(loginUser.email, groupId, {
            error: () => {
                ToastsStore.error('서버와의 연결이 원활하지 않습니다. 다시 시도해 주세요.', 1500);
            },
        });
    };

    requestForcedLeaveGroupUser = groupId => {
        const { managementStore, classStore } = this.props;
        const { selectedUser } = managementStore;
        classStore.requestLeaveGroup(selectedUser.email, groupId, {
            doSuccessAction: () => {
                this.requestSelectedGroupMembers(groupId);
            },
            alert: () => {
                ToastsStore.warning('잘못된 요청입니다. 클래스 정보를 확인해주세요.', 1500);
            },
            error: () => {
                ToastsStore.error('서버와의 연결이 원활하지 않습니다. 잠시 후 다시 시도해 주세요.', 1500);
            },
        });
    };

    findAvatar = user => {
        if (!user.image) {
            this.props.avatarStore.findAvatar(user.userId, {
                setImage: data => {
                    user.image = data;
                },
            });
        }
    };

    resetAllUsers = users => {
        const { managementStore } = this.props;
        managementStore.resetAllUsers(users);
    };

    checkGroupLeader = groupId => {
        const { classStore, authStore } = this.props;
        classStore.getGroupLeader(groupId, authStore.loginUser.email, {
            checkIsLeader: findLeader => {
                const isMeLeader = authStore.loginUser.id === findLeader.userId;

                this.setState({ isLeader: isMeLeader });
            },
        });
    };

    setSelectedUserAvatar = selectedGroupId => {
        const { managementStore } = this.props;
        managementStore.setSelectedUserAvatar(selectedGroupId);
    };

    setSelectedUser = user => {
        const { managementStore } = this.props;

        managementStore.setSelectedUser(user);
    };

    handleParsingGuestExcel = e => {
        const file = e.target.files[0];
        const { excelStore, managementStore, authStore } = this.props;

        excelStore.parsingGuestExcel(file, {
            successAction: data => {
                const result = excelStore.validatorParsingGuestData(data);
                if (result) {
                    managementStore.createGuests(data, authStore.loginUser.email, {
                        success: () => {
                            ToastsStore.info('학생 정보가 등록 완료되었습니다.', 1500);
                        },
                        alert: failedGuestEmails => {
                            const emptyText = '[]';
                            const title = '업로드 결과';
                            const errMsg = `학생 정보가 등록 완료되었습니다.\n아래 목록에서 중복되거나 잘못 입력된 계정은\n확인 후 다시 등록해주세요.\n${
                                failedGuestEmails ? failedGuestEmails.join(',\n') : emptyText
                            }\n`;
                            excelStore.setExcelConfirmDialog(errMsg, title);
                        },
                        getAllUsers: () => {
                            this.requestAllUsers();
                        },
                        error: () => {
                            const title = '업로드 실패';
                            const errMsg = '엑셀파일 읽기에 실패하였습니다. \n입력하신 정보를 확인해주세요.';
                            excelStore.setExcelConfirmDialog(errMsg, title);
                        },
                    });
                }
            },
        });

        e.target.value = '';
    };

    requestSearchGuestUserByKeyword = keyword => {
        this.handleChangeSearchKeywordForGuestUser(keyword);
        this.requestAllUsers();
    };

    handleChangeSearchKeywordForGuestUser = (keyword = INIT_EMPTY_SEARCH_KEYWORD) => {
        const { paginationStore } = this.props;

        paginationStore.changeSearchKeyword(keyword);
    };

    render() {
        const { classes, isMobile, classStore, roomStore, managementStore, paginationStore, authStore, classTeamStore, userStore, excelStore } =
            this.props;
        const {
            allUsers,
            isAvailableSaveGuest,
            newGuest,
            newGuestEmailValidation,
            newGuestPenAddressValidation,
            isAvailableSmartPen,
            editSmartPenAddress,
            initNewGuest,
            isAvailableEmail,
            initAllUsers,
            isExcelLoading,
            selectedUser,
            allUsersTotalCount,
        } = managementStore;
        const { classGroupList, groupUserAuthority, isGroupUserLoading } = classStore;
        const { page, rowsPerPage, totalCount, sort, searchKeyword, isSearching } = paginationStore;
        const { loginUser } = authStore;
        const { initTeams } = classTeamStore;
        const { userDetail, userSmartPenAddress, coachState } = userStore;
        const { myClassRoomList, myClassRoomCount } = roomStore;
        const { excelConfirmDialog, changeExcelConfirmDialogOpen } = excelStore;

        const { tabs, anchorGeneral, createAccountAnchorEl } = this.state;

        const openPopover = Boolean(createAccountAnchorEl);

        const general = Boolean(anchorGeneral);

        return (
            <div className={classes.root}>
                <Box className={classes.contents}>
                    <Box className={classes.contentsIn}>
                        <Box className={classes.wrap}>
                            <Box style={{ position: 'relative' }}>
                                <Tabs value={tabs} onChange={this.handleChange} className={classes.tabStyle}>
                                    <Tab label="클래스 관리" disableRipple />
                                    <Tab label="학생 관리" disableRipple />
                                </Tabs>
                                {tabs === 1 && (
                                    <Box className={classes.classCoachBox}>
                                        <CoachMarkForm
                                            open={!coachState.hasGroup && coachState.hasChild && loginUser.type !== USER_TYPE.Guest}
                                            title={'클래스 만들기'}
                                            msg={
                                                '학생 등록이 완료되면 학습 및 주제에 따른 클래스를 만들고 수업도 등록해보세요. \n\n클래스가 만들어지면 학생을 초대하여 수업을 시작할 수 있습니다.'
                                            }
                                            currentStep={3}
                                            totalStep={3}
                                            btnText={'클래스 만들기'}
                                            submit={this.handleCreateClass}
                                            arrPosition={50}
                                        />
                                    </Box>
                                )}
                            </Box>
                            {tabs === 0 && (
                                <>
                                    <Box style={{ position: 'relative' }}>
                                        <Button className={classes.btnStyle} disableRipple onClick={this.handleCreateClass}>
                                            <PlusCircleIcon />
                                            클래스 만들기
                                        </Button>
                                        <Box className={classes.ClassCoachBox2}>
                                            <CoachMarkForm
                                                open={!coachState.hasGroup && coachState.hasChild && loginUser.type !== USER_TYPE.Guest}
                                                title={'클래스 만들기'}
                                                msg={
                                                    '학생 등록이 완료되면 학습 및 주제에 따른 클래스를 만들고 수업도 등록해보세요. \n\n클래스가 만들어지면 학생을 초대하여 수업을 시작할 수 있습니다.'
                                                }
                                                currentStep={3}
                                                totalStep={3}
                                                btnText={'클래스 만들기'}
                                                submit={this.handleCreateClass}
                                            />
                                        </Box>
                                    </Box>
                                </>
                            )}
                            {tabs === 1 && (
                                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                                    {/*<LightTooltip title="학생 일괄 등록 양식 받기 (.xlsx)" placement="bottom">*/}
                                    {/*    <Button className={classes.downloadBtnStyle} disableRipple onClick={excelStore.exportLmsExcelSample}>*/}
                                    {/*        <NoFilesIcon />*/}
                                    {/*    </Button>*/}
                                    {/*</LightTooltip>*/}

                                    {/*<Box>*/}
                                    {/*    <LightTooltip title="학생 일괄 등록 (.xlsx)" placement="bottom">*/}
                                    {/*        <Button*/}
                                    {/*            component={'label'}*/}
                                    {/*            className={classes.downloadBtnStyle}*/}
                                    {/*            disableRipple*/}
                                    {/*            htmlFor={'upload-excel-file'}*/}
                                    {/*        >*/}
                                    {/*            <DownloadSimpleIcon />*/}
                                    {/*        </Button>*/}
                                    {/*    </LightTooltip>*/}
                                    {/*    <input*/}
                                    {/*        id="upload-excel-file"*/}
                                    {/*        type="file"*/}
                                    {/*        accept=".xls, .xlsx"*/}
                                    {/*        style={{ display: 'none' }}*/}
                                    {/*        onChange={this.handleParsingGuestExcel}*/}
                                    {/*        // onChange={this.handleChangeUploadFile}*/}
                                    {/*    />*/}
                                    {/*</Box>*/}
                                    <Button
                                        aria-owns={openPopover ? 'simple-popper' : undefined}
                                        className={classes.btnStyle2}
                                        onClick={this.handleClickPopover}
                                        aria-haspopup="true"
                                        variant="contained"
                                        disableRipple
                                        disabled={excelStore.isExcelParing}
                                    >
                                        <Box display="flex">
                                            <PlusCircleIcon />
                                            학생 추가하기
                                        </Box>
                                    </Button>
                                    <Box className={classes.MemberCoachBox}>
                                        <CoachMarkForm
                                            open={!coachState.hasChild && loginUser.type !== USER_TYPE.Guest}
                                            title={'학생 등록하기'}
                                            msg={
                                                "'학생 추가하기' 버튼을 눌러 담당하는 모든 학생 \n의 계정 생성 및 펜 정보를 입력해주세요. \n\n학생 및 펜 정보 csv 파일로 모든 정보를 일괄 업로드 할 수 있습니다."
                                            }
                                            currentStep={2}
                                            totalStep={3}
                                            btnText={'학생 추가하기'}
                                            submit={this.handleClickCreateAccount}
                                        />
                                    </Box>
                                    <Popover
                                        id="simple-popper"
                                        open={openPopover}
                                        anchorEl={createAccountAnchorEl}
                                        onClose={this.handleClosePopover}
                                        className={classes.popoverBox}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <MenuItem onClick={this.handleClickCreateAccount}>
                                            <ListItemIcon>
                                                <FilTextIcon />
                                            </ListItemIcon>
                                            <ListItemText inset primary="학생 개별 등록" />
                                        </MenuItem>

                                        <MenuItem
                                            onClick={this.handleClosePopover}
                                            htmlFor={'upload-excel-file'}
                                            disabled={excelStore.isExcelParing}
                                            component={'label'}
                                        >
                                            <ListItemIcon>
                                                <NoFilesIcon2 />
                                            </ListItemIcon>
                                            <ListItemText inset primary="학생 일괄 등록(.xlsx)" />
                                        </MenuItem>
                                        <Box display={'flex'} justifyContent={'end'}>
                                            <Button
                                                className={classes.menuItemText}
                                                onClick={() => {
                                                    excelStore.exportLmsExcelSample();
                                                    this.handleClosePopover();
                                                }}
                                                disabled={excelStore.isExcelSampleDownloading}
                                                disableRipple
                                            >
                                                일괄 등록양식 받기
                                            </Button>
                                        </Box>
                                    </Popover>
                                    <input
                                        id="upload-excel-file"
                                        type="file"
                                        accept=".xls, .xlsx"
                                        style={{ display: 'none' }}
                                        onChange={this.handleParsingGuestExcel}
                                        // onChange={this.handleChangeUploadFile}
                                    />
                                </Box>
                            )}
                        </Box>
                        {tabs === 0 && (
                            <MobileClassManagementComponent
                                classGroupList={classGroupList}
                                myClassRoomList={myClassRoomList}
                                myClassRoomCount={myClassRoomCount}
                                parentTab={tabs}
                                isMobile={isMobile}
                            />
                        )}
                        {tabs === 1 && (
                            <MobileStudentsManagementComponent
                                parentTab={tabs}
                                loginUser={loginUser}
                                classGroupList={classGroupList}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                totalCount={totalCount}
                                changeRowsPerPage={this.changeRowsPerPage}
                                changePage={this.changePage}
                                allMembers={allUsers}
                                requestUserInfo={this.requestUserInfo}
                                setSelectedUserEmail={this.setSelectedUserEmail}
                                initUserInfo={this.initUserInfo}
                                userDetail={userDetail}
                                smartPenAddress={userSmartPenAddress}
                                sort={sort}
                                changeSortAndFetchData={this.handleChangeSortAndFetchData}
                                resetPassword={this.handleResetPassword}
                                changeNewGuestSmartPenAddress={this.handleChangeSmartPenAddress}
                                checkExistSmartPenAddress={this.checkExistSmartPenAddress}
                                isAvailableSmartPen={isAvailableSmartPen}
                                editSmartPenAddress={editSmartPenAddress}
                                initNewGuest={initNewGuest}
                                modifyGuestSmartPenAddres={this.handleModifyGuestSmartPenAddress}
                                requestAllUsersExportExcel={this.handleAllUsersExcelExport}
                                requestGroupUserExportExcel={this.handleGroupUsersExcelExport}
                                requestInitGroupMemberAndTeamInfo={this.requestInitGroupMemberAndTeamInfo}
                                requestForcedLeaveGroupUser={this.requestForcedLeaveGroupUser}
                                findAvatar={this.findAvatar}
                                initAllUsers={initAllUsers}
                                groupUserAuthority={groupUserAuthority}
                                isExcelLoading={isExcelLoading}
                                checkGroupLeader={this.checkGroupLeader}
                                isLeader={this.state.isLeader}
                                setSelectedUser={this.setSelectedUser}
                                selectedUser={selectedUser}
                                initTeams={initTeams}
                                isGroupUserLoading={isGroupUserLoading}
                                requestAllUsers={this.requestAllUsers}
                                allUsersTotalCount={allUsersTotalCount}
                                isTeamLoading={classTeamStore.isLoading}
                                isAllUsersLoading={managementStore.isAllUsersLoading}
                                selectedGroupMemberAndOperators={classStore.selectedGroupMemberAndOperators}
                                initSelectedGroupMemberAndOperators={classStore.initSelectedGroupMemberAndOperators}
                                changeSort={paginationStore.changeSort}
                                isNoClassFilter={this.state.isNoClassFilter}
                                changeNoClassFilterAndFetchData={this.handleChangeNoClassFilterAndFetchData}
                                initNoClassFilter={this.handleChangeNoClassFilter}
                                isMobile={isMobile}
                                requestSearchGuestUserByKeyword={this.requestSearchGuestUserByKeyword}
                                changeSearchKeyword={this.handleChangeSearchKeywordForGuestUser}
                                searchKeyword={searchKeyword}
                                isSearchingGuest={isSearching}
                            />
                        )}
                    </Box>
                </Box>

                <CreateAccountDialogComponent
                    newGuest={newGuest}
                    isAvailableSaveGuest={isAvailableSaveGuest}
                    changeNewGuestSmartPenAddress={this.handleChangeSmartPenAddress}
                    changeNewGuestName={this.handleChangeNewGuestName}
                    changeNewGuestPassword={this.handleChangeNewGuestPassword}
                    changeNewGuestEmail={this.handleChangeNewGuestEmail}
                    checkExistEmail={this.checkExistEmail}
                    checkExistSmartPenAddress={this.checkExistSmartPenAddress}
                    createAccount={this.state.createAccount}
                    createGuest={this.createGuest}
                    handleClickCreateAccountClose={this.handleClickCreateAccountClose}
                    validationEmail={newGuestEmailValidation}
                    validationPenAddress={newGuestPenAddressValidation}
                    createRandomPassword={this.handleCreateRandomPassword}
                    isAvailableEmail={isAvailableEmail}
                    notEmptyNewGuestPenAddress={managementStore.notEmptyNewGuestPenAddress}
                    isAvailableSmartPen={managementStore.isAvailableSmartPen}
                />

                <ConfirmDialog
                    open={excelConfirmDialog.open}
                    title={excelConfirmDialog.title}
                    msg={excelConfirmDialog.msg}
                    changeDialogOpen={changeExcelConfirmDialogOpen}
                />
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(
        inject(
            'classStore',
            'classTeamStore',
            'authStore',
            'roomStore',
            'managementStore',
            'paginationStore',
            'userStore',
            'avatarStore',
            'excelStore',
        )(observer(MobileMyManagementComponent)),
    ),
);
