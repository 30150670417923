import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Checkbox, Dialog, FormControlLabel, IconButton, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastsStore } from 'react-toasts';
import { injectIntl } from 'react-intl';
import SelectExpireDatetime from '../invitation/SelectExpireDatetime';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            padding: 24,
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 600px)': {
                width: 500,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 30,
        '@media all and (max-width: 600px)': {
            marginBottom: 10,
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    iconButton: {
        padding: 5,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    textStyle: {
        fontSize: '1rem',
        color: '#333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.875rem',
        },
    },
    urlBox: {
        background: '#fff',
        border: '1px solid #d9dbde',
        borderRadius: 7,
        padding: '5px 12px',
        margin: '20px 0 12px',
    },
    urlText: {
        textDecoration: 'underline',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    checkedBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        margin: 0,
        '& .MuiIconButton-root': {
            padding: 0,
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '1rem',
            color: '#333',
            marginLeft: 10,
            fontWeight: 600,
            '@media all and (max-width: 600px)': {
                fontSize: '0.875rem',
            },
        },
        '& .Mui-checked': {
            color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '& .MuiSvgIcon-root': {
            '@media all and (max-width: 600px)': {
                fontSize: '1rem',
            },
        },
    },
    buttonStyle: {
        width: '100%',
        height: 48,
        borderRadius: 8,
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        color: '#fff',
        fontSize: '1.125rem',
        fontWeight: 300,
        marginTop: 30,
        '&:hover': {
            fontWeight: 800,
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            height: 33,
            padding: '6px 0',
            fontSize: '0.75rem',
            borderRadius: 4,
            marginTop: 20,
        },
    },
    popper: {
        zIndex: 1400,
        left: '12px !important',
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.common.white} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    paper: {
        padding: 0,
        maxWidth: 500,
        overflow: 'auto',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
    },
});

class InvitationDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arrow: true,
            arrowRef: null,
            open: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<P>, _prevState: Readonly<S>, _snapshot: SS) {
        if (prevProps.invitationDialogOpen !== this.props.invitationDialogOpen) {
            if (this.props.invitationDialogOpen) {
                if (this.props.invite && this.props.invite.expireDatetime) {
                    this.props.setInviteExpireDatetime(this.props.invite.expireDatetime);
                }
            } else {
                if (this.state.open) {
                    this.setState({ open: false });
                }
            }
        }
    }

    handleClickButton = e => {
        e.preventDefault();
        this.setState(state => ({
            open: !state.open,
        }));
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    handleClickCopyBtn = () => {
        const { classes, intl } = this.props;
        ToastsStore.info(intl.formatMessage({ id: 'msg.success_url_copy' }), 3000, classes.toasts);
    };

    render() {
        const { classes, invite } = this.props;

        return (
            <div className={classes.root}>
                <Dialog open={this.props.invitationDialogOpen} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.titleBox}>
                        <Typography className={classes.titleText}>링크로 초대하기</Typography>
                        <IconButton className={classes.iconButton} onClick={this.props.handleClose} disableRipple>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        <Typography className={classes.textStyle}>
                            링크를 받은 사람은 누구나 이 모임의 정보를 확인하고 가입을 요청할 수 있습니다. 초대링크를 복사하여 원하는 곳에 링크를
                            공유하세요.
                        </Typography>
                        <Box className={classes.urlBox}>
                            <Tooltip title={invite.url} disableTouchListener>
                                <Typography className={clsx(classes.textStyle, classes.urlText)}>{invite.url}</Typography>
                            </Tooltip>
                        </Box>
                        <FormControlLabel
                            inputRef={node => {
                                this.anchorEl = node;
                            }}
                            control={<Checkbox checked={this.state.open} />}
                            label="만료 일시 지정"
                            className={classes.checkedBox}
                            onClick={e => this.handleClickButton(e)}
                        />
                        <Box hidden={!this.state.open} style={{ marginTop: '10px' }}>
                            <SelectExpireDatetime
                                classTab={this.props.classTab}
                                requestChangeInvitationExpireTime={this.props.requestChangeInvitationExpireTime}
                            />
                        </Box>
                        <Box>
                            <CopyToClipboard text={invite.url} onCopy={this.handleClickCopyBtn}>
                                <Button className={classes.buttonStyle} disableRipple>
                                    URL 복사
                                </Button>
                            </CopyToClipboard>
                        </Box>
                    </Box>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(injectIntl(InvitationDialogComponent));
