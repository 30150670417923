import React from 'react';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const GalleryItem = ({ item, onView, className }) => {
    const { image, title, id } = item;
    return (
        <ToggleButtonGroup>
            <li onClick={() => onView(id)} className={className}>
                <img src={image} alt={title} />
            </li>
        </ToggleButtonGroup>
    );
};

export default GalleryItem;
