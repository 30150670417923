import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import MobileConnectChart from './MobileConnectChart';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

const style = theme => ({
    root: {},
    titleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.1rem',
        fontWeight: 600,
        color: '#0d0d0d',
        padding: '0 20px',
    },
});

class MobileConnectStatus extends Component {
    render() {
        const { classes, userHistoryList } = this.props;

        const chartData = [{ id: 'history', data: [] }];
        userHistoryList.forEach(uh => {
            chartData[0].data.push({ x: uh.historyCount, y: uh.userCount });
        });

        return (
            <div className={classes.root}>
                <Typography className={classes.titleText}>
                    <FormattedMessage id="access_status" />
                </Typography>
                <MobileConnectChart data={chartData} />
            </div>
        );
    }
}

export default withStyles(style)(inject('statisticsStore')(observer(MobileConnectStatus)));
