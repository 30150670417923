import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Badge, Box, Button, CircularProgress, FormControl, InputBase, MenuItem, Select, Typography } from '@material-ui/core';
import { ReactComponent as ButtonPlusIcon } from '../../common/images/ButtonPlusIcon.svg';
import { ReactComponent as NewPostIcon } from '../../common/images/NewPostIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { ReactComponent as AsideSettingIcon } from '../../common/images/AsideSettingIcon.svg';
import RoomTestImg3 from '../../common/images/RoomTestImg3.png';
import { withRouter } from '../../components/WithRouter';
import HiddenGroupManagementDialogComponent from '../dialog/HiddenGroupManagementDialogComponent';

import { inject, observer } from 'mobx-react';
import { ReactComponent as ArrowDownTinyIcon } from '../../common/images/ArrowDownTinyIcon.svg';
import { ClassSortType, GroupJoinRequestPath, LoadingState } from '../../stores/ClassStore';
import { Skeleton } from '@material-ui/lab';
import { ClassMainPath } from '../../common/ClassMainPath';
import { SearchCategory, SearchScope } from '../../common/SearchItems';
import { USER_TYPE } from '../../stores/UserStore';
import CoachMarkForm from '../../components/common/CoachMarkForm';

const LocalStorageExplanationBoxHiddenTokenKey = '_ONTHELIVE_EXPLANATIONBOX_HIDDEN_';

const styles = _theme => ({
    root: {
        background: '#f3f3f3',
    },
    titleText: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1.875rem',
        },
        fontSize: '1.563rem',
        color: '#000',
    },
    titleButton: {
        background: 'transparent',
        boxShadow: 'none',
        '&:hover': {
            background: 'transparent',
            boxShadow: 'none',
        },
    },
    menuText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.938rem',
        color: '#0d0d0d',
        '&:hover': {
            background: '#d3d7db',
        },
    },
    roomCreate: {
        '@media all and (min-width: 1500px)': {
            width: 322,
            height: 350,
            marginRight: 30,
            marginBottom: 30,
        },
        width: 270,
        height: 300,
        position: 'relative',
        borderRadius: 10,
        marginRight: 20,
        marginBottom: 20,
        boxShadow: ' 0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        '&:nth-child(4n+0)': {
            marginRight: 0,
        },
    },
    roomCreateButton: {
        width: '100%',
        height: '100%',
        background: '#fff',
        position: 'absolute',
        borderRadius: 10,
        padding: 0,
        '&:hover': {
            background: '#fff',
        },
        '& span': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    roomButton: {
        overflow: 'hidden',
        '& span': {
            height: 'inherit',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        '& img': {
            '@media all and (min-width: 1500px)': {},
            height: 180,
            width: '100%',
            objectFit: 'cover',
        },
    },
    inviteBox: {
        width: '100%',
        height: '100%',
        padding: '45px 26px 14px',
        boxSizing: 'border-box',
        position: 'absolute',
        zIndex: 100,
        display: 'flex',
        borderRadius: 10,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
    },
    inviteBoxIn: {
        height: '235px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    inviteText: {
        color: '#fff',
        fontSize: '1.125rem',
        fontWeight: '500',
        textAlign: 'center',
    },
    greenColor: {
        color: '#00c880',
        display: 'inline !important',
    },
    buttonStyle: {
        width: 272,
        height: 38,
        background: '#0097FF',
        color: '#fff',
        borderRadius: 7,
        fontSize: '0.875rem',
        '&:hover': {
            background: '#0097FF',
        },
        '& span': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    roomTextBox: {
        '@media all and (min-width: 1500px)': {
            height: 'calc(350px - 180px)',
        },
        width: '100%',
        height: 'calc(300px - 180px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '12px 20px 14px',
        boxSizing: 'border-box',
    },
    buttonIcon: {
        width: 100,
        height: 100,
        borderRadius: '50%',
        background: '#e1e1e1',
        marginBottom: 21,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonText: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1.125rem',
        },
        width: '100%',
        fontSize: '0.938rem',
        color: '#000',
        fontWeight: 600,
    },
    buttonTitle: {
        width: '100%',
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
    buttonSubText: {
        fontSize: '0.75rem',
        color: '#828282',
    },
    roomBoxIn: {
        '@media all and (min-width: 1500px)': {
            width: 1440,
            padding: '48px 30px 55px',
        },
        width: 1200,
        padding: '35px 30px 55px',
        margin: '0 auto',
        boxSizing: 'border-box',
        position: 'relative',
    },
    titleBox: {
        marginBottom: 21,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    explanationBox: {
        '@media all and (min-width: 1500px)': {
            width: 418,
            padding: 24,
            bottom: -155,
        },
        width: 380,
        padding: 20,
        background: '#2078e8',
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        position: 'absolute',
        zIndex: 100,
        bottom: -140,
        left: 15,
        '&::before': {
            backgroundColor: '#2078e8',
            content: "''",
            display: 'block',
            height: '13px',
            position: 'absolute',
            top: -7,
            left: '35px',
            transform: 'rotate( -45deg ) skew( 0deg )',
            width: '13px',
            boxShadow: '2px -2px 2px 0 rgb(178 178 178 / 20%)',
            zIndex: 200,
        },
    },
    stepText: {
        '@media all and (min-width: 1500px)': {
            fontSize: '0.875rem',
        },
        fontSize: '0.813rem',
        color: '#abd0fe',
        fontWeight: 300,
    },
    stepContents: {
        '@media all and (min-width: 1500px)': {
            fontSize: '1.125rem',
        },
        fontSize: '1rem',
        color: '#fff',
        margin: '14px 0 13px',
        fontWeight: 300,
    },
    stepCheckBtn: {
        '@media all and (min-width: 1500px)': {
            fontSize: '0.938rem',
        },
        fontSize: '0.875rem',
        padding: 0,
        background: 'transparent',
        color: '#a3a8af',
        fontWeight: 300,
        '&:hover': {
            background: 'transparent',
        },
    },
    stepBtn: {
        width: 110,
        height: 40,
        border: '1px solid #0097ff',
        background: '#fff',
        borderRadius: 7,
        color: '#2078e8',
        fontWeight: 600,
        '&:hover': {
            background: '#fff',
        },
    },
    chipBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 12,
    },
    chip: {
        minWidth: 50,
        height: '21px !important',
        backgroundColor: '#eee',
        color: '#656565',
        marginRight: 6,
        padding: '0px 7px',
        fontSize: '0.75rem',
        border: '1px solid #eee',
        borderRadius: 50,
        boxSizing: 'border-box',
        '&:last-child': {
            marginRight: 0,
        },
        '&:hover': {
            border: '1px solid #dbf0ff',
            backgroundColor: '#dbf0ff!important',
            color: '#0097ff!important',
        },
    },
    btnStyle: {
        fontSize: '0.938rem',
        '& svg': {
            marginRight: 4,
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    moreBtn: {
        margin: 'auto',
    },
    inviteGroupWrap: {
        '& img': {
            opacity: 0.5,
        },
    },
    btnStyle1: {
        minWidth: 100,
        fontSize: '0.905rem',
        padding: 5,
        border: '1px solid #BFBFBF',
        borderRadius: 3,
        '& span': {
            display: 'initial',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    btnAccept: {
        color: '#0097ff',
        border: '1px solid #0097ff',
    },
    coachBox: {
        position: 'absolute',
        '@media all and (min-width: 1500px)': {
            left: -320,
            top: -70,
        },
        left: -270,
        top: -50,
    },
    addMemberCoachBox: {
        position: 'absolute',
        '@media all and (min-width: 1500px)': {
            left: -60,
            top: 380,
        },
        left: -40,
        top: 320,
    },
    ownersChip: {
        minWidth: 50,
        height: '21px !important',
        color: '#34eb83',
        marginLeft: 6,
        padding: '0px 7px',
        fontSize: '0.75rem',
        border: '1px solid #34eb83',
        borderRadius: 50,
        boxSizing: 'border-box',
    },
});

const BootstrapInput = withStyles(_theme => ({
    root: {},
    input: {
        borderRadius: 0,
        position: 'relative',
        backgroundColor: 'transparent',
        border: '0',
        fontSize: '0.938rem',
        fontWeight: 400,
        color: '#000',
        '&:focus': {
            background: 'transparent',
        },
        '&.MuiSelect-select': {
            paddingRight: 5,
        },
    },
}))(InputBase);

const StyledBadgeMember = withStyles(_theme => ({
    badge: {
        right: 7,
        top: 3,
        border: `1px solid #fff`,
        width: 10,
        height: 10,
        borderRadius: '50%',
        background: '#00c880',
    },
}))(Badge);

class MyRoomComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomList: [
                {
                    img: RoomTestImg3,
                    title: '커뮤니티 가이드',
                    post: false,
                    postText: '',
                    lastTime: false,
                    lastTimeText: '',
                    chip: '스터디',
                },
            ],
            dialogOpen: false,
            isExplanationBoxHidden: true,
        };
    }

    componentDidMount() {
        this.props.classStore.initSelectedGroupFolder();
        // this.props.classStore.initClass();
        this.props.classStore.getGroupList(this.props.authStore.loginUser);
        this.props.classStore.getInviteGroupList(this.props.authStore.loginUser);
        // this.props.userStore.getUserLoginHistory(this.props.authStore.loginUser.email);
    }

    componentWillUnmount() {
        if (this.props.classStore.groupListFirstLoading === LoadingState.SUCCESS) this.props.neoPenStore.setGroups(this.props.classStore.groupList);

        this.props.classStore.initClass();
    }

    handleChange = e => {
        this.props.classStore.changeSortType(e.target.value, () => this.props.classStore.getGroupList(this.props.authStore.loginUser));
    };

    handleClickRoomType = () => {
        this.props.navigate('/roomcreate');
    };

    handleClickOpenDialog = () => {
        this.setState({ dialogOpen: true });
        this.props.classStore.onClickGroupHideList();
    };

    handleCloseDialog = () => {
        this.setState({ dialogOpen: false });
    };

    handleSave = () => {
        this.props.classStore.changeHideGroup(this.props.authStore.loginUser, () => this.setState({ dialogOpen: false }));
    };

    handleClickMoreBtn = () => {
        this.props.classStore.getGroupList(this.props.authStore.loginUser);
    };

    handleFoldMoreBtn = () => {
        this.props.classStore.handleFoldBtn();
    };

    handleCloseExplanationBox = () => {
        const hiddenToken = localStorage.getItem(LocalStorageExplanationBoxHiddenTokenKey);
        if (!hiddenToken) {
            localStorage.setItem(LocalStorageExplanationBoxHiddenTokenKey, this.state.isExplanationBoxHidden);
        }
    };

    handleSearchByTagName = (e, tagName) => {
        e.preventDefault();
        e.stopPropagation();
        const { classStore, roomStore, authStore, postStore, searchStore, navigate } = this.props;
        classStore.initSearchClass();
        roomStore.initRoom();
        postStore.initPost();
        searchStore.initCurrentPage();
        searchStore.changeSearchKeyword(tagName);
        classStore.getGroupListByKeyword(tagName, {
            moveToSearchPage: () => navigate(`/search/${SearchScope.All}/${SearchCategory.Study}/${tagName}`),
            getRoomList: () =>
                roomStore.getRoomListGroupByStartTime(undefined, authStore.loginUser.id, 'all', 1, undefined, undefined, undefined, tagName),
            getPostList: () => postStore.getPostList(undefined, authStore.loginUser.id, tagName, 1, 16),
        });
    };

    handleClickServiceCenter = () => {
        this.props.navigate('/servicecenter');
    };

    ellipsisText = text => (text.length > 25 ? text.substr(0, 25) + '...' : text);

    render() {
        const { classes, navigate } = this.props;
        const { groupList, groupListLoading, groupListCount, groupCurrentCount, simpleGroupList, inviteGroupList } = this.props.classStore;
        const { coachState } = this.props.userStore;
        const { loginUser } = this.props.authStore;
        const hideGroupList = simpleGroupList.filter(g => g.hideGroup);

        return (
            <div className={classes.root}>
                <Box className={classes.roomBoxIn}>
                    <Box className={classes.titleBox}>
                        <Box display="flex">
                            <Typography className={classes.titleText}>내 클래스</Typography>
                            <Button disableRipple onClick={this.handleClickOpenDialog} className={classes.btnStyle}>
                                {hideGroupList && hideGroupList.length > 0 ? (
                                    <React.Fragment>
                                        <StyledBadgeMember
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            variant="dot"
                                            overlap={'rectangular'}
                                        >
                                            <AsideSettingIcon />
                                        </StyledBadgeMember>
                                        숨김 목록 관리
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <AsideSettingIcon /> 숨김 목록 관리
                                    </React.Fragment>
                                )}
                            </Button>
                            <HiddenGroupManagementDialogComponent
                                dialogOpen={this.state.dialogOpen}
                                handleClose={this.handleCloseDialog}
                                handleSave={this.handleSave}
                            />
                        </Box>
                        <Box style={{ position: 'relative' }}>
                            <FormControl className={classes.margin}>
                                <Select
                                    defaultValue={ClassSortType.create}
                                    onChange={this.handleChange}
                                    input={<BootstrapInput name="age" id="age-customized-select" />}
                                    IconComponent={() => <ArrowDownIcon />}
                                >
                                    <MenuItem value={ClassSortType.create} className={classes.menuText}>
                                        클래스 생성일순
                                    </MenuItem>
                                    <MenuItem value={ClassSortType.update} className={classes.menuText}>
                                        최근 업데이트순
                                    </MenuItem>
                                    <MenuItem value={ClassSortType.abc} className={classes.menuText}>
                                        가나다순
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <Box className={classes.coachBox}>
                                <CoachMarkForm
                                    open={loginUser.type !== USER_TYPE.Guest && !coachState.hasChild}
                                    title={'학생 등록하기'}
                                    msg={"클래스 관리 페이지로 이동하여 '학생 관리'에서\n담당하는 모든 학생의 계정 생성 및 펜 정보를\n입력해주세요."}
                                    currentStep={1}
                                    totalStep={3}
                                    btnText={'학생 등록 바로가기'}
                                    submit={() => this.props.navigate('/classmanagement/1')}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexWrap="wrap">
                        {loginUser.type !== USER_TYPE.Guest && (
                            <Box style={{ position: 'relative' }}>
                                <Box className={classes.roomCreate}>
                                    <Button className={classes.roomCreateButton} disableRipple onClick={this.handleClickRoomType.bind(this)}>
                                        <Box className={classes.buttonIcon}>
                                            <ButtonPlusIcon />
                                        </Box>
                                        <Typography className={classes.buttonText}>클래스 만들기</Typography>
                                    </Button>
                                </Box>
                                <Box className={classes.addMemberCoachBox}>
                                    <CoachMarkForm
                                        open={!coachState.hasGroup && coachState.hasChild && loginUser.type !== USER_TYPE.Guest}
                                        title={'클래스 만들기'}
                                        msg={
                                            '학생 등록이 완료되면 학습 및 주제에 따른 클래스를 만들고 수업도 등록해보세요. \n\n클래스가 만들어지면 학생을 초대하여 수업을 시작할 수 있습니다.'
                                        }
                                        currentStep={3}
                                        totalStep={3}
                                        btnText={'클래스 만들기'}
                                        submit={this.handleClickRoomType.bind(this)}
                                    />
                                </Box>
                            </Box>
                        )}

                        {inviteGroupList.length > 0 &&
                            inviteGroupList.map((g, i) => (
                                <Box key={i} className={classes.roomCreate}>
                                    <Button className={clsx(classes.roomCreateButton, classes.roomButton)} disableRipple>
                                        <img src={g.image} alt="group" />
                                        <Box display="flex" flexDirection="column" alignItems="flex-start" className={classes.roomTextBox}>
                                            <Box display="flex" flexDirection="column" alignItems="flex-start" style={{ width: '100%' }}>
                                                <Box className={classes.chipBox}>
                                                    {g.tagList.map((tag, tagIndex) => (
                                                        <Typography
                                                            key={tagIndex}
                                                            className={classes.chip}
                                                            noWrap
                                                            onClick={e => this.handleSearchByTagName(e, tag.name)}
                                                        >
                                                            {tag.name}
                                                        </Typography>
                                                    ))}
                                                </Box>
                                                <Typography className={clsx(classes.buttonText, classes.buttonTitle)}>{g.group.name}</Typography>
                                            </Box>
                                            {g.post === true && (
                                                <Box display="flex" alignItems="center">
                                                    <NewPostIcon style={{ marginRight: 5 }} />
                                                    <Typography className={classes.buttonSubText}>{g.postText}</Typography>
                                                </Box>
                                            )}
                                            {g.lastTime === true && <Typography className={classes.buttonSubText}>{g.lastTimeText}</Typography>}
                                        </Box>
                                    </Button>

                                    {/* 초대받은 클래스*/}
                                    <Box className={classes.inviteBox}>
                                        <Box className={classes.inviteBoxIn}>
                                            <Typography className={classes.inviteText}>
                                                <span className={classes.greenColor}>{this.ellipsisText(g.lmsUser[0].name)}</span>
                                                &nbsp;선생님이 나를
                                                <span className={classes.greenColor}>&nbsp;{this.ellipsisText(g.group.name)}</span>
                                                &nbsp;클래스에 초대했습니다.
                                            </Typography>
                                        </Box>
                                        <Button
                                            className={classes.buttonStyle}
                                            disableRipple
                                            onClick={() => {
                                                this.props.classStore.setSelectedGroupDetail(g);
                                                this.props.classStore.joinGroup(this.props.authStore.loginUser.id, GroupJoinRequestPath.Invitation, {
                                                    initClass: () => this.props.classStore.initClass(),
                                                    getGroupList: () => this.props.classStore.getGroupList(this.props.authStore.loginUser),
                                                    getHideGroupList: () => this.props.classStore.getHideGroupList(this.props.authStore.loginUser.id),
                                                    getInviteGroupList: () =>
                                                        this.props.classStore.getInviteGroupList(this.props.authStore.loginUser),
                                                });
                                            }}
                                        >
                                            클래스 가입하기
                                        </Button>
                                    </Box>
                                </Box>
                            ))}

                        {this.props.classStore.groupListFirstLoading !== LoadingState.PENDING
                            ? groupList.map((g, i) => {
                                  return (
                                      <Box key={i} className={classes.roomCreate}>
                                          <Button
                                              className={clsx(classes.roomCreateButton, classes.roomButton)}
                                              disableRipple
                                              onClick={() => {
                                                  this.props.classStore.setSelectedGroupId(g.group.id);
                                                  this.props.orgStore.setGroupColor(g.group.color);
                                                  // navigate(`/class/${g.group.id}/${ClassMainPath.todaySchedule}`);
                                                  navigate(`/class/${g.group.id}/${ClassMainPath.penRecord}`);
                                              }}
                                          >
                                              <img src={g.image} alt="group" />
                                              <Box display="flex" flexDirection="column" alignItems="flex-start" className={classes.roomTextBox}>
                                                  <Box display="flex" flexDirection="column" alignItems="flex-start" style={{ width: '100%' }}>
                                                      <Box className={classes.chipBox}>
                                                          {g.tagList.map((tag, tagIndex) => (
                                                              <Typography
                                                                  key={tagIndex}
                                                                  className={classes.chip}
                                                                  noWrap
                                                                  onClick={e => this.handleSearchByTagName(e, tag.name)}
                                                              >
                                                                  {tag.name}
                                                              </Typography>
                                                          ))}
                                                      </Box>
                                                      <Box display={'flex'} alignItems={'center'}>
                                                          <Typography className={clsx(classes.buttonText, classes.buttonTitle)}>
                                                              {g.group.name}
                                                          </Typography>
                                                          {/*{g.ownGroup && (*/}
                                                          {/*    <Typography className={classes.ownersChip} noWrap>*/}
                                                          {/*        운영*/}
                                                          {/*    </Typography>*/}
                                                          {/*)}*/}
                                                      </Box>
                                                  </Box>
                                                  {g.post === true && (
                                                      <Box display="flex" alignItems="center">
                                                          <NewPostIcon style={{ marginRight: 5 }} />
                                                          <Typography className={classes.buttonSubText}>{g.postText}</Typography>
                                                      </Box>
                                                  )}
                                                  {g.lastTime === true && <Typography className={classes.buttonSubText}>{g.lastTimeText}</Typography>}
                                              </Box>
                                          </Button>
                                          {i === 0 && (
                                              <Box className={classes.addMemberCoachBox}>
                                                  <CoachMarkForm
                                                      open={
                                                          !coachState.hasMember &&
                                                          coachState.hasGroup &&
                                                          coachState.hasChild &&
                                                          loginUser.type !== USER_TYPE.Guest
                                                      }
                                                      title={'학생 초대하기'}
                                                      msg={
                                                          "클래스에 학생을 초대하여 수업을 시작해보세요. \n\n교사가 클래스 관리에서 등록한 학생은 '클래스>\n멤버 탭>학생 초대' 목록에서 나타납니다.\n\n학생은 본인 계정으로 로그인 후 '초대 메시지>\n가입' 버튼을 눌러 클래스에 가입할 수 있습니다."
                                                      }
                                                      currentStep={1}
                                                      totalStep={3}
                                                      btnText={'학생 초대하기'}
                                                      submit={() => {
                                                          this.props.classStore.setSelectedGroupId(g.group.id);
                                                          this.props.orgStore.setGroupColor(g.group.color);
                                                          navigate(`/class/${g.group.id}/${ClassMainPath.member}`);
                                                      }}
                                                  />
                                              </Box>
                                          )}
                                      </Box>
                                  );
                              })
                            : this.props.classStore.groupListCount > 0 &&
                              Array.from(new Array(this.props.classStore.groupListCount > 6 ? 6 : this.props.classStore.groupListCount)).map(
                                  (_, i) => (
                                      <Box key={i} className={classes.roomCreate}>
                                          <Button className={clsx(classes.roomCreateButton, classes.roomButton)} disableRipple>
                                              <Skeleton variant="rect" width={'100%'} height={180} />
                                              <Box display="flex" flexDirection="column" alignItems="flex-start" className={classes.roomTextBox}>
                                                  <Box display="flex" flexDirection="column" alignItems="flex-start">
                                                      <Skeleton className={classes.chipBox}>
                                                          <Typography className={classes.chip} noWrap>
                                                              ... ...
                                                          </Typography>
                                                      </Skeleton>
                                                      <Skeleton width={150} />
                                                  </Box>
                                              </Box>
                                          </Button>
                                      </Box>
                                  ),
                              )}

                        <Box className={classes.roomCreate}>
                            <Button
                                className={clsx(classes.roomCreateButton, classes.roomButton)}
                                disableRipple
                                onClick={this.handleClickServiceCenter}
                            >
                                <img src={RoomTestImg3} alt="group" />
                                <Box display="flex" flexDirection="column" alignItems="flex-start" className={classes.roomTextBox}>
                                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                                        <Box className={classes.chipBox}>
                                            <Typography className={classes.chip} noWrap>
                                                가이드
                                            </Typography>
                                        </Box>
                                        <Typography className={clsx(classes.buttonText, classes.buttonTitle)}>커뮤니티 가이드</Typography>
                                    </Box>
                                </Box>
                            </Button>
                        </Box>
                    </Box>
                    <Box display="flex">
                        {groupListCount > 6 &&
                            (groupListLoading !== LoadingState.PENDING ? (
                                <Box className={classes.moreBtn}>
                                    {groupListCount === groupCurrentCount ? (
                                        <Button className={classes.btnStyle} disableRipple onClick={this.handleFoldMoreBtn}>
                                            <ArrowDownTinyIcon style={{ transform: 'rotate(180deg)' }} />
                                            <Typography>접기</Typography>
                                        </Button>
                                    ) : (
                                        groupListCount > groupCurrentCount && (
                                            <Button className={classes.btnStyle} disableRipple onClick={this.handleClickMoreBtn}>
                                                <ArrowDownTinyIcon />
                                                <Typography>더보기</Typography>
                                            </Button>
                                        )
                                    )}
                                </Box>
                            ) : (
                                <Box className={classes.moreBtn} style={{ height: 36.5 }}>
                                    <CircularProgress size={20} />
                                </Box>
                            ))}
                    </Box>
                </Box>
            </div>
        );
    }
}
export default withRouter(
    withStyles(styles)(
        inject('authStore', 'classStore', 'roomStore', 'postStore', 'userStore', 'searchStore', 'orgStore', 'neoPenStore')(observer(MyRoomComponent)),
    ),
);
