import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as MinusCircleIcon } from '../../common/images/MinusCircleIcon.svg';
import clsx from 'clsx';
import { ReactComponent as Close } from '../../common/images/Close.svg';
import { ReactComponent as PenPageIcon } from '../../common/images/PenPageIcon.svg';
import { ReactComponent as WidthHeightIcon } from '../../common/images/WidthHeightIcon.svg';
import { ReactComponent as NeoPenIconColor } from '../../common/images/NeoPenIconColor.svg';
import { ReactComponent as PenRecordPlus } from '../../common/images/PenRecordPlus.svg';
import { ReactComponent as PenRecordMinus } from '../../common/images/PenRecordMinus.svg';
import { ReactComponent as ArrowIcon } from '../../common/images/ArrowIcon.svg';
import MobileViewTypeDrawer from '../mobile/class/penRecord/MobileViewTypeDrawer';
import MobileSelectDrawer from '../mobile/class/penRecord/MobileSelectDrawer';
import MobileScreenWriteDrawer from '../mobile/class/penRecord/MobileScreenWriteDrawer';
import MobilePdfNotesDrawer from '../mobile/class/penRecord/MobilePdfNotesDrawer';
import { withRouter } from '../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { PenState, ViewerType } from '../../stores/NeoPenStore';
import { ReactComponent as ArrowsClockwiseIcon } from '../../common/images/ArrowsClockwiseIcon.svg';
import { ReactComponent as PenPlusCircleIcon } from '../../common/images/PenPlusCircleIcon.svg';
import { InkStorage, ZoomFitEnum } from 'nl-lib';
// import { InkStorage, ZoomFitEnum } from '../../nl-lib';
import NeoPenViewer from '../neoPen/NeoPenViewer';
import NeoPenViewerBackImg from '../../common/images/NeoPenViewerBackImg.png';
import { ReactComponent as FloppyDiskBack } from '../../common/images/FloppyDiskBack.svg';
import { ReactComponent as DownloadSimple } from '../../common/images/DownloadSimple.svg';

const styles = _theme => ({
    root: {
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        padding: '8px 12px',
        boxSizing: 'border-box',
        borderBottom: '1px solid #c0c2c3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        '& svg': {
            width: 22,
            height: 22,
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    titleText: {
        fontSize: '1rem',
        color: '#000',
    },
    titleDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    topBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 16px',
    },
    penButton: {
        border: '1px solid #c4c4c4',
        borderRadius: 2,
        width: 85,
        height: 35,
        padding: 0,
        '& span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 5,
        },
    },
    penButton2: {
        background: '#00C880',
        '&:hover': {
            background: '#00C880',
        },
        '& span': {
            color: '#fff',
        },
        '& path': {
            stroke: '#fff',
        },
    },
    penButtonRight: {
        marginRight: 0,
    },
    penText: {
        fontSize: '0.813rem',
        color: '#1e2121',
    },
    selectBox: {
        padding: 0,
        minWidth: 40,
        '&:hover': {
            background: 'transparent',
        },
        '& span': {
            display: 'flex',
            alignItems: 'center',
        },
        '& path': {
            strokeWidth: 1,
        },
    },
    selectText: {
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    contentsBox: {
        position: 'relative',
        padding: '10px 16px',
        height: 'calc(100vh - 59px - 55px - 49px)',
        background: '#eee',
        boxSizing: 'border-box',
    },
    contentsTopBox: {
        '& svg': {
            width: 18,
            height: 18,
            '& .neo-pen': {
                fill: '#6d6d70',
            },
        },
    },
    iconBox: {
        width: 20,
        display: 'flex',
        alignItems: 'center',
    },
    floppyDiskBackIcon: {
        '& svg': {
            width: 20,
            height: 20,
        },
    },
    numberBox: {
        width: 45,
        height: 20,
        borderRadius: 4,
        padding: '3px 0',
        border: '1px solid #c2c2c2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 8px',
    },
    numberText: {
        fontSize: '0.813rem',
    },
    arrowSvg: {
        margin: '0 10px 2px',
        '& path': {
            strokeWidth: 1,
        },
    },
    paperBox: {
        width: '90%',
        height: 'calc(100% - 28px - 20px - 20px)',
        background: '#fff',
        boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.25)',
        margin: '10px auto',
    },
    widthHeightBox: {
        marginLeft: 10,
    },
    bottomBox: {
        padding: '15px 16px',
        boxSizing: 'border-box',
        '& svg': {
            width: 18,
            height: 18,
        },
    },
    neoPenViewerInBox: {
        background: '#fff',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxSizing: 'border-box',
        '& img': {
            width: '90%',
            margin: '0 auto',
        },
        '@media all and (min-width: 601px)': {
            '& img': {
                width: '100%',
                margin: '0 auto',
            },
            padding: '16px 60px',
        },
    },
    neoPenViewerTitleText: {
        width: '90%',
        fontSize: '0.938rem',
        color: '#000',
        margin: ' 0 10px 5px',
        textAlign: 'left',
        '@media all and (min-width: 700px)': {
            fontSize: '1.063rem',
        },
    },
    neoPenViewerTextStyle: {
        fontSize: '0.938rem',
        color: '#000',
        margin: '0 10px 10px',
        '@media all and (min-width: 700px)': {
            fontSize: '1.375rem',
        },
    },
    tooltipBox: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        left: -150,
        top: 32,
        width: 155,
        padding: '8px 10px',
        background: '#2078e8',
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 3,
        whiteSpace: 'pre-wrap',
        zIndex: 100,
        '&::before': {
            backgroundColor: '#2078e8',
            content: "''",
            display: 'block',
            height: '10px',
            position: 'absolute',
            top: -5,
            left: 156,
            transform: 'rotate( -45deg ) skew( 0deg )',
            width: '10px',
            boxShadow: '2px -2px 2px 0 rgb(178 178 178 / 20%)',
            zIndex: 200,
        },
        '@media all and (max-width: 374px)': {},
    },
    closeBtn: {
        padding: 0,
        '& svg': {
            width: 20,
            height: 20,
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    tooltipText: {
        fontSize: '0.813rem',
        fontWeight: 500,
        color: '#fff',
        marginTop: 5,
    },
    '@media all and (min-width: 601px)': {
        titleBox: {
            '& svg': {
                width: 25,
                height: 25,
            },
            '&::before': {
                width: 25,
                height: 25,
            },
        },
        titleText: {
            fontSize: '1.25rem',
        },
        titleDate: {
            fontSize: '0.875rem',
        },
        penButton: {
            width: 110,
            height: 40,
        },
        penText: {
            fontSize: '1rem',
        },
        selectText: {
            fontSize: '1rem',
        },
        contentsBox: {
            height: 'calc(100vh - 62px - 60px - 53px)',
        },
        contentsTopBox: {
            '& svg': {
                width: 23,
                height: 23,
            },
        },
        iconBox: {
            width: 20,
            display: 'flex',
            alignItems: 'center',
        },
        numberBox: {
            width: 50,
            height: 20,
            margin: '0 15px',
        },
        numberText: {
            fontSize: '1rem',
        },
        arrowSvg: {
            margin: '0 15px 2px',
        },
        widthHeightBox: {
            marginLeft: 10,
            '& svg': {
                width: 26,
                height: 26,
            },
        },
        bottomBox: {
            '& svg': {
                width: 23,
                height: 23,
            },
        },
    },
    '@media all and (max-width: 374px)': {
        selectText: {
            fontSize: '0.75rem',
            marginLeft: 3,
        },
        penButton: {
            width: 75,
            height: 30,
            marginRight: 8,
            '& svg': {
                marginRight: 3,
            },
        },
        penText: {
            fontSize: '0.75rem',
        },
        penButtonRight: {
            marginRight: 0,
        },
        neoPenViewerInBox: {
            '& img': {
                width: '80%',
                margin: '0 auto',
            },
        },
        neoPenViewerTitleText: {
            fontSize: '0.75rem',
            color: '#000',
            margin: ' 0 10px 5px',
        },
        neoPenViewerTextStyle: {
            fontSize: '0.75rem',
            margin: '0 10px 10px',
        },
    },
});
class MobileRealTimePenComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            widthHeight: true,
            viewTypeDrawer: false,
            viewType: '전체',
            selectDrawer: false,
            selectText: '최근 작성일 순',
            screenWriteDrawer: false,
            PdfNotesDrawer: false,
            isAlertOpen: true,
        };
    }

    componentDidMount = async () => {
        console.log('[PenRecordComponent] : this.props.params  : >> ', this.props.params);

        window.addEventListener('beforeunload', function (event) {
            const is = InkStorage.getInstance();
            let strokes = is.strokes;

            let isSaved = true;

            for (let s of strokes) {
                if (!s.uploaded) {
                    isSaved = false;
                }
            }

            if (!isSaved) event.returnValue = '아직 자동 저장되지 않은 데이터가 있습니다.';
        });

        const nPS = this.props.neoPenStore;

        const selectedUserEmail = this.props.params.userEmail;

        nPS.setViewerType(ViewerType.RealTime);

        nPS.renewLastUploadTime();

        const selectedUser = await nPS.getUserInfo(selectedUserEmail);
        nPS.selectUser(selectedUser);

        const user = this.props.authStore.loginUser;
        nPS.setUser(user);

        await this.props.userStore.getUserInfo(user.email);
        const macAddress = this.props.userStore.fullSmartPenAddress;

        nPS.initNeoPen(user, macAddress);

        if (window.flutter_inappwebview) {
            const loginUser = this.props.authStore.loginUser;

            await this.props.userStore.getUserInfo(loginUser.email);

            const macAddress = this.props.userStore.fullSmartPenAddress;

            let connection = await window.flutter_inappwebview.callHandler('PenConnection', {});

            console.log('-=-=mobileConnection', connection);
            if (connection === 'Connected') {
                nPS.setPenState(PenState.Connected);

                window.onPenConnected({ mac: macAddress });
            }
        }

        const cS = this.props.classStore;

        await cS.getGroupList(user);

        nPS.setGroups(cS.groupList);

        await nPS.getAllPaperGroupsWholeGroup();
    };

    handleChange = e => {
        const { neoPenStore } = this.props;

        this.setState({ value: e.target.value });

        if (e.target.value === 'checkA') neoPenStore.setRenderingUserStrokeId(undefined);

        if (e.target.value === 'checkB') neoPenStore.setRenderingUserStrokeId(neoPenStore.user.email);

        if (e.target.value === 'checkC') neoPenStore.setRenderingUserStrokeId(neoPenStore.selectedUser.email);
    };

    handleClickWidthHeight = () => {
        const nPS = this.props.neoPenStore;

        if (this.state.widthHeight) nPS.setZoomFitType(ZoomFitEnum.WIDTH);
        else nPS.setZoomFitType(ZoomFitEnum.HEIGHT);

        this.setState({ widthHeight: !this.state.widthHeight });
    };

    handleClickBack = () => {
        this.props.navigate(-1);
    };

    handleClickViewType = () => {
        this.setState({
            viewTypeDrawer: true,
        });
    };

    handleClickSelect = () => {
        this.setState({
            selectDrawer: true,
        });
    };

    handleClickScreenWriteDrawer = () => {
        this.setState({
            screenWriteDrawer: true,
        });
    };

    handleClickPdfNotesDrawer = () => {
        this.setState({
            PdfNotesDrawer: true,
        });
    };

    handleChangeViewType = viewType => {
        const nPS = this.props.neoPenStore;

        if (viewType === '전체') nPS.setRenderingUserStrokeId(undefined);

        if (viewType === '첨삭 중심보기') nPS.setRenderingUserStrokeId(nPS.user.email, nPS.isLeader);

        if (viewType === '학생 필기 중심보기') nPS.setRenderingUserStrokeId(nPS.user.email, !nPS.isLeader);

        this.setState({ viewType });
    };

    handleChangeSelect = selectText => {
        this.setState({ selectText });
    };

    handleCloseDrawer = () => {
        this.setState({
            viewTypeDrawer: false,
            selectDrawer: false,
            screenWriteDrawer: false,
            PdfNotesDrawer: false,
        });
    };

    render() {
        const { classes } = this.props;
        const { widthHeight, viewType } = this.state;

        const nPS = this.props.neoPenStore;

        const paperGroup = nPS.selectedPaperGroup;

        let name = '';

        if (nPS.selectedUser !== undefined) name = nPS.selectedUser.name;

        return (
            <div className={classes.root}>
                <Box className={classes.titleBox}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography className={clsx(classes.textStyle, classes.topCenterText)}>({name})님의 필기</Typography>
                        <Typography className={classes.titleDate}>{nPS.lastUploadTime}</Typography>
                    </Box>
                    {/*<Box className={classes.iconButton} />*/}

                    {/*<IconButton className={classes.iconButton} disableRipple>*/}
                    {/*    <Close />*/}
                    {/*</IconButton>*/}
                </Box>
                <Box className={classes.topBox}>
                    <Box>
                        <Button className={classes.selectBox} onClick={this.handleClickViewType} disableRipple>
                            <Typography className={classes.selectText}>{this.state.viewType}</Typography>
                            <ArrowIcon style={{ transform: 'rotate(270deg)', marginTop: -4, marginLeft: 5 }} />
                        </Button>
                    </Box>

                    {window.flutter_inappwebview === undefined && (
                        <Box display="flex" alignItems="center">
                            {nPS.penState === PenState.Connected && (
                                <Button className={clsx(classes.penButton, classes.penButton2)} disableRipple onClick={nPS.onConnectionChange}>
                                    <MinusCircleIcon />
                                    <span className={classes.penText}>펜 해제</span>
                                </Button>
                            )}

                            {nPS.penState === PenState.Connecting && (
                                <Button className={classes.penButton} disableRipple>
                                    <ArrowsClockwiseIcon />
                                    <span className={classes.penText}>연결중</span>
                                </Button>
                            )}

                            {nPS.penState === PenState.Disconnected && (
                                <Button className={classes.penButton} disableRipple onClick={nPS.onConnectionChange}>
                                    <PenPlusCircleIcon />
                                    <span className={classes.penText}>펜 연결</span>
                                </Button>
                            )}
                        </Box>
                    )}

                    {nPS.isLeader && (
                        <Box display="flex" alignItems="center">
                            {nPS.penState === PenState.Connected && (
                                <Button className={clsx(classes.penButton, classes.penButton2)} disableRipple onClick={nPS.onConnectionChange}>
                                    <MinusCircleIcon />
                                    <span className={classes.penText}>펜 해제</span>
                                </Button>
                            )}

                            {nPS.penState === PenState.Connecting && (
                                <Button className={classes.penButton} disableRipple>
                                    <ArrowsClockwiseIcon />
                                    <span className={classes.penText}>연결중</span>
                                </Button>
                            )}

                            {nPS.penState === PenState.Disconnected && (
                                <Button className={classes.penButton} disableRipple onClick={nPS.onConnectionChange}>
                                    <PenPlusCircleIcon />
                                    <span className={classes.penText}>펜 연결</span>
                                </Button>
                            )}
                        </Box>
                    )}
                </Box>

                <Box className={classes.contentsBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.contentsTopBox}>
                        <Box className={classes.iconBox} style={{ width: 50 }}>
                            <IconButton className={classes.iconButton} onClick={this.handleClickScreenWriteDrawer} disableRipple>
                                <NeoPenIconColor />
                            </IconButton>
                        </Box>

                        <Box display="flex" alignItems="center">
                            <IconButton className={classes.iconButton} disableRipple onClick={nPS.handleZoomIn}>
                                <PenRecordPlus />
                            </IconButton>
                            <Box className={classes.numberBox}>
                                <Typography className={classes.numberText}>{parseInt(nPS.zoom * 100)}%</Typography>
                            </Box>
                            <IconButton className={classes.iconButton} disableRipple onClick={nPS.handleZoomOut}>
                                <PenRecordMinus />
                            </IconButton>
                            <Box className={classes.widthHeightBox}>
                                <IconButton className={classes.iconButton} onClick={this.handleClickWidthHeight} disableRipple>
                                    {this.state.widthHeight ? <WidthHeightIcon /> : <WidthHeightIcon style={{ transform: 'rotate(90deg)' }} />}
                                </IconButton>
                            </Box>
                        </Box>
                        <Box style={{ position: 'relative' }}>
                            <IconButton
                                className={clsx(classes.iconButton, classes.floppyDiskBackIcon)}
                                onClick={async () => {
                                    await nPS.uploadToInk();
                                }}
                                disableRipple
                            >
                                <FloppyDiskBack />
                            </IconButton>

                            <IconButton className={clsx(classes.iconButton, classes.floppyDiskBackIcon)} style={{ marginLeft: 10 }} disableRipple>
                                <DownloadSimple />
                            </IconButton>

                            {/*{this.state.isAlertOpen && (*/}
                            {/*    <Box className={classes.tooltipBox}>*/}
                            {/*        <Typography className={classes.tooltipText}>필기한 기록을 직접 저장</Typography>*/}
                            {/*        <Box display="flex" justifyContent="flex-end">*/}
                            {/*            <IconButton*/}
                            {/*                className={classes.closeBtn}*/}
                            {/*                disableRipple*/}
                            {/*                onClick={() => {*/}
                            {/*                    this.setState({ isAlertOpen: false });*/}
                            {/*                }}*/}
                            {/*            >*/}
                            {/*                <ModalCloseIcon />*/}
                            {/*            </IconButton>*/}
                            {/*        </Box>*/}
                            {/*    </Box>*/}
                            {/*)}*/}
                        </Box>
                    </Box>
                    <Box className={classes.paperBox}>
                        {nPS.activePage === undefined ? (
                            <Box className={classes.neoPenViewerInBox}>
                                <Typography className={classes.neoPenViewerTitleText}>Hello, {name}</Typography>
                                <Typography className={classes.neoPenViewerTextStyle}>
                                    펜을 연결하고 등록된 서식 위에 필기해보세요.
                                    <br />
                                    필기한 기록이 화면에 바로 보여집니다.
                                </Typography>
                                <img src={NeoPenViewerBackImg} alt="기본 이미지" />
                            </Box>
                        ) : (
                            <NeoPenViewer />
                        )}
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <IconButton className={clsx(classes.iconButton, classes.arrowSvg)} disableRipple onClick={nPS.prevPage}>
                            <ArrowIcon />
                        </IconButton>
                        {nPS.activePage !== undefined && paperGroup !== undefined && (
                            <Typography className={classes.numberText}>{nPS.activePage.page - paperGroup.pageStart + 1}</Typography>
                        )}
                        <IconButton className={clsx(classes.iconButton, classes.arrowSvg)} disableRipple onClick={nPS.nextPage}>
                            <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.bottomBox}>
                    <Box>
                        <IconButton className={classes.iconButton} onClick={this.handleClickPdfNotesDrawer} disableRipple>
                            <PenPageIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        {/*<Button className={classes.selectBox} onClick={this.handleClickSelect} disableRipple>*/}
                        {/*    <CheckCircleIcon />*/}
                        {/*    <Typography className={classes.selectText}>{this.state.selectText}</Typography>*/}
                        {/*</Button>*/}
                    </Box>
                </Box>

                <MobileViewTypeDrawer
                    handleCloseDrawer={this.handleCloseDrawer}
                    viewTypeDrawer={this.state.viewTypeDrawer}
                    viewType={this.state.viewType}
                    handleChangeViewType={this.handleChangeViewType}
                />
                <MobileSelectDrawer
                    handleCloseDrawer={this.handleCloseDrawer}
                    selectDrawer={this.state.selectDrawer}
                    selectText={this.state.selectText}
                    handleChangeSelect={this.handleChangeSelect}
                />
                <MobileScreenWriteDrawer handleCloseDrawer={this.handleCloseDrawer} screenWriteDrawer={this.state.screenWriteDrawer} />
                <MobilePdfNotesDrawer handleCloseDrawer={this.handleCloseDrawer} PdfNotesDrawer={this.state.PdfNotesDrawer} />
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('orgStore', 'neoPenStore', 'classStore', 'authStore', 'userStore')(observer(MobileRealTimePenComponent))),
);
