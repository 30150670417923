import React, { Component } from 'react';
import { Box, Button, Checkbox, Dialog, FormControlLabel, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as UnCheckedIcon } from '../../common/images/UnCheckedIcon.svg';
import { ReactComponent as CheckedIcon } from '../../common/images/CheckedIcon.svg';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { getAfterExpectedDatePayment, LICENSE_ALERT_EXPIRED_DATE_SAVED_KEY } from '../myRoom/LicenseKeyInformation';
import dayjs from 'dayjs';
import { DATE_UTIL } from '../../common/util/date.util';
import { LICENSE_KEY_DIGITS, LICENSE_KEY_PATTERN, LICENSE_KEY_STR_PATTERN, UTIL } from '../../common/util/common.util';
import { withRouter } from '../../components/WithRouter';
import LicenseKeyInputBox from '../../components/LicenseKeyInputBox';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 620,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleText: {
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    contentsBox: {
        margin: '24px 0 32px',
    },
    textStyle: {
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
        fontSize: '1rem',
        color: '#333',
        letterSpacing: '-0.32px',
        lineHeight: 1.4,
    },
    textStyle2: {
        fontWeight: 'bold',
        margin: '20px 0',
    },
    licenseBox: {
        width: 440,
        height: 40,
        boxSizing: 'border-box',
        border: '1px solid #c0c2c3',
        borderRadius: 8,
        marginTop: 8,
        background: '#fff',
        padding: '0 10px',
        display: 'flex',
        alignItems: 'center',
        '@media all and (max-width: 600px)': {
            width: '100%',
        },
    },
    hyphenText: {
        '&.MuiTypography-root': {
            fontSize: '1rem',
            color: '#a3a8af',
            letterSpacing: '-0.2px',
            margin: '0 8px 0 5px',
            '@media all and (max-width: 600px)': {
                fontSize: '0.813rem',
            },
        },
    },
    form: {
        // width: 38,
        width: 'inherit',
        '& .MuiInputBase-root': {
            boxSizing: 'border-box',
            padding: '0',
            background: 'transparent',
        },
        '& .MuiOutlinedInput-input': {
            padding: 0,
            boxSizing: 'border-box',
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 0,
        },
        '& input': {
            fontSize: '1rem',
            color: '#333',
            letterSpacing: '-0.2px',
            '&::placeholder': {
                fontSize: '1rem',
                color: '#a3a8af',
                letterSpacing: '-0.2px',
                opacity: 1,
                '@media all and (max-width: 600px)': {
                    fontSize: '0.813rem',
                },
            },
            '@media all and (max-width: 600px)': {
                fontSize: '0.813rem',
            },
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
            {
                border: '0',
            },
    },
    buttonStyle: {
        width: 207,
        height: 48,
        background: '#fff',
        border: '1px solid #c2c2c2',
        borderRadius: 8,
        fontSize: '1.125rem',
        color: '#c2c2c2',
        fontWeight: 'bold',
        padding: 0,
        '&:hover': {
            background: '#fff',
        },
        '@media all and (max-width: 600px)': {
            width: 100,
            height: 31,
            fontSize: '0.75rem',
            borderRadius: 5,
        },
    },
    buttonStyle2: {
        width: 207,
        height: 48,
        background: '#0097FF',
        borderRadius: 8,
        fontSize: '1.125rem',
        color: '#fff',
        fontWeight: 'bold',
        marginLeft: 14,
        '&:hover': {
            background: '#0097FF',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
            '&:hover': {
                background: '#fff',
            },
        },
        '@media all and (max-width: 600px)': {
            width: 100,
            height: 31,
            fontSize: '0.75rem',
            borderRadius: 5,
        },
    },
    checkBox: {
        '&.MuiFormControlLabel-root': {
            margin: '8px 0 0',
        },
        '& .MuiButtonBase-root': {
            padding: 0,
        },
        '& .MuiTypography-root': {
            fontSize: '0.875rem',
            color: '#000',
            marginLeft: 8,
        },
    },
});

class LicenseDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            license1: '',
            license2: '',
            license3: '',
            license4: '',
            isChecked: false,
            licenseKey: '',
        };
    }

    handleChangeLicense1 = e => {
        const license1 = e.target.validity.valid ? e.target.value : this.state.license1;
        this.setState({ license1 });
    };

    // handleChangeLicense = e => {
    //     console.log('handleChangeLicense : >> ', e.target.value);
    //     if (!e.target.value) {
    //         this.setState({ licenseKey: '' });
    //         return;
    //     }
    //     // const regex = /^[A-Za-z0-9-]+$/;
    //     if (!LICENSE_KEY_STR_PATTERN?.test(e.target.value)) {
    //         return;
    //     }
    //
    //     // let licenseKey = e.target.validity.valid ? e.target.value : this.state.licenseKey;
    //     let licenseKey = e.target.value;
    //     console.log('handleChangeLicense licenseKey : >> ', licenseKey);
    //     if (licenseKey?.replaceAll('-', '')?.length > LICENSE_KEY_DIGITS) {
    //         return;
    //     }
    //
    //     if (licenseKey?.replaceAll('-', '')?.length === LICENSE_KEY_DIGITS) {
    //         licenseKey = UTIL.licenseKeySplitter({ str: licenseKey });
    //     }
    //     this.setState({ licenseKey });
    // };

    handleChangeLicense = licenseKey => {
        console.log('handleChangeLicense : >> ', licenseKey);

        this.setState({ licenseKey });
    };

    handleChangeLicense2 = e => {
        const license2 = e.target.validity.valid ? e.target.value : this.state.license2;
        this.setState({ license2 });
    };

    handleChangeLicense3 = e => {
        const license3 = e.target.validity.valid ? e.target.value : this.state.license3;
        this.setState({ license3 });
    };

    handleChangeLicense4 = e => {
        const license4 = e.target.validity.valid ? e.target.value : this.state.license4;
        this.setState({ license4 });
    };

    handleCloseDialog = () => {
        const isAfterExpectedDatePayment = getAfterExpectedDatePayment();
        if (isAfterExpectedDatePayment) {
            this.props.authStore?.doLogout({
                moveToHome: () => {
                    this.props.navigate('/');
                },
            });
            return;
        }

        if (this.state.isChecked) {
            const expiredDate = dayjs().add(7, 'day');
            localStorage.setItem(LICENSE_ALERT_EXPIRED_DATE_SAVED_KEY, DATE_UTIL.getTimeZoneDate(expiredDate, DATE_UTIL.FORMAT.DATE_FORMAT));
        }
        this.props.userStore?.changeLicenseAlertOpenState(false);
    };

    handleClickCheckBox = () => {
        this.setState(prevState => ({ isChecked: !prevState?.isChecked }));
    };

    handleClickRegisterLicenseKeyButton = () => {
        console.log('handleClickRegisterLicenseKeyButton');
        this.props.userStore?.createUserLicenseKey(this.state.licenseKey);
    };

    render() {
        const { classes, open, close, userStore } = this.props;
        const isOpen = userStore?.isLicenseAlertOpen;
        const isAfterExpectedDatePayment = getAfterExpectedDatePayment();
        console.log('[LicenseDialogComponent] isOpen : >> ', isOpen);
        console.log('[LicenseDialogComponent] isAfterExpectedDatePayment : >> ', isAfterExpectedDatePayment);

        return (
            <Dialog open={isOpen} className={classes.dialogBox}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.titleText}>
                        {isAfterExpectedDatePayment ? '라이선스 키 입력' : '서비스 사용 정책 변경 알림'}
                    </Typography>
                    <IconButton className={classes.iconButton} onClick={this.handleCloseDialog} disableRipple>
                        <DialogCloseIcon />
                    </IconButton>
                </Box>

                <Box className={classes.contentsBox}>
                    {!isAfterExpectedDatePayment && (
                        <>
                            <Typography className={classes.textStyle}>
                                안녕하세요. 스터디라이브입니다.
                                <br />
                                스터디라이브를 이용해주시고 응원해주셔서 진심으로 감사드립니다.
                            </Typography>
                            <Typography className={clsx(classes.textStyle, classes.textStyle2)}>
                                Studylive 무료 서비스는 2023년 7월 20일부터 서비스를 종료합니다.
                            </Typography>
                            <Typography className={classes.textStyle}>
                                서비스 계속 사용을 위해,
                                <br />
                                제휴 맺은 학교의 교사는 발급 받은 서비스 라이선스 키를 로그인 이후 한번 등록
                                <br />
                                일반 사용자는 서비스 담당자에게{' '}
                                <a target="_blank" href="mailto:daewook86@neolab.net">
                                    메일
                                </a>
                                로 요청하고 발급 받으면 로그인 이후 한번 등록
                            </Typography>
                        </>
                    )}
                    <Typography className={classes.textStyle} style={{ marginTop: !isAfterExpectedDatePayment ? 30 : 0 }}>
                        발급받은 서비스 라이선스 키를 입력해 주세요.
                    </Typography>
                    {/*<Box width={'100%'}>*/}
                    <LicenseKeyInputBox minWidth={0} licenseKey={this.state.licenseKey} onChangeLicenseKey={this.handleChangeLicense} />
                    {/*</Box>*/}

                    {/*<Box className={classes.licenseBox}>*/}
                    {/*    <Box className={classes.form}>*/}
                    {/*        <TextField*/}
                    {/*            id="license1"*/}
                    {/*            type="text"*/}
                    {/*            // inputProps={{ maxLength: 19, inputMode: 'text', pattern: '/^[A-Za-z0-9+]*$/' }}*/}
                    {/*            inputProps={{ maxLength: 19 }}*/}
                    {/*            name="license1"*/}
                    {/*            onChange={this.handleChangeLicense}*/}
                    {/*            value={this.state.licenseKey}*/}
                    {/*            label=""*/}
                    {/*            variant="outlined"*/}
                    {/*            placeholder="xxxx - xxxx - xxxx - xxxx"*/}
                    {/*            fullWidth*/}
                    {/*        />*/}
                    {/*    </Box>*/}
                    {/*</Box>*/}
                </Box>

                <Box display="flex" justifyContent="center">
                    <Button className={classes.buttonStyle} onClick={this.handleCloseDialog} disableRipple>
                        {isAfterExpectedDatePayment ? '나가기' : '다음에'}
                    </Button>
                    <Button
                        className={classes.buttonStyle2}
                        onClick={this.handleClickRegisterLicenseKeyButton}
                        // disabled={!this.state.licenseKey}
                        disabled={!LICENSE_KEY_PATTERN.test(this.state.licenseKey)}
                        disableRipple
                    >
                        등록
                    </Button>
                </Box>
                {!isAfterExpectedDatePayment && (
                    <Box display="flex" justifyContent="center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.isChecked}
                                    value="checked"
                                    icon={<UnCheckedIcon />}
                                    checkedIcon={<CheckedIcon />}
                                    disableRipple
                                />
                            }
                            label={'1주일간 알림 받지 않기'}
                            className={clsx(classes.checkBox, classes.displayNone2)}
                            onClick={this.handleClickCheckBox}
                        />
                    </Box>
                )}
            </Dialog>
        );
    }
}

export default withRouter(withStyles(styles)(inject('userStore', 'authStore')(observer(LicenseDialogComponent))));
