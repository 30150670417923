import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from '../../components/WithRouter';
import { Box, Typography, IconButton } from '@material-ui/core';
import { ReactComponent as TopLogo } from '../../common/images/StudyLiveTopBarLogo.svg';
import { TooltipType } from '../../stores/TooltipStore';
import { ReactComponent as Info } from '../../common/images/Info.svg';
import { ReactComponent as X } from '../../common/images/X.svg';
import { inject, observer } from 'mobx-react';

const styles = theme => ({
    root: {
        width: '100%',
        padding: '25px 0',
        boxSizing: 'border-box',
        borderBottom: '1px solid #c0c2c3',
        marginBottom: 50,
    },
    rootIn: {
        '@media all and (min-width: 1500px)': {
            width: 1440,
        },
        width: 1200,
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // '&::after': {
        //     width: 292,
        //     height: 27,
        //     content: "''",
        // },
    },
    textStyle: {
        color: '#000',
        fontSize: '1.125rem',
        fontWeight: 'bold',
        marginLeft: 36,
        marginRight: 5,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    explanationBox: {
        width: 305,
        padding: '10px',
        background: '#2078e8',
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 3,
        position: 'absolute',
        zIndex: 100,
        bottom: -55,
        left: -151,
        '&::before': {
            backgroundColor: '#2078e8',
            content: "''",
            display: 'block',
            height: '13px',
            position: 'absolute',
            top: -6,
            left: 155,
            transform: 'rotate( -227deg ) skew( 0deg )',
            width: '13px',
            // boxShadow: '2px -2px 2px 0 rgb(178 178 178 / 20%)',
            zIndex: 200,
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    stepContents: {
        fontSize: '0.813rem',
        color: '#fff',
        fontWeight: 300,
    },
    titleText: {
        // width: 'calc(100% - 292px - 292px)',
        width: 'calc(100% - 335px)',
        color: '#000',
        fontSize: '1.25rem',
        textAlign: 'center',
    },
});
class WritingBoardTopBarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toolTip: true,
        };
    }

    handleClickOpen = () => {
        this.setState({
            toolTip: true,
        });
    };

    handleClose = () => {
        this.setState({
            toolTip: false,
        });
    };

    render() {
        const { classes } = this.props;

        const cS = this.props.classStore;
        const groupName = cS.selectedGroupDetail.group.name;

        return (
            <div className={classes.root}>
                <Box className={classes.rootIn}>
                    <Box display="flex" alignItems="center">
                        <TopLogo />
                        <Box display="flex" alignItems="center">
                            <Typography className={classes.textStyle}>학생 필기 현황</Typography>
                            <Box style={{ position: 'relative' }}>
                                <IconButton className={classes.iconButton} onClick={this.handleClickOpen} disableRipple>
                                    <Info />
                                </IconButton>
                                {this.state.toolTip && (
                                    <Box className={classes.explanationBox}>
                                        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                                            <Typography className={classes.stepContents}>1~2시간 전의 필기 데이터를 집계하여 제공합니다.</Typography>
                                            <IconButton className={classes.iconButton} onClick={this.handleClose} disableRipple>
                                                <X />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Typography className={classes.titleText}>{groupName}</Typography>
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('classStore')(observer(WritingBoardTopBarComponent))));
