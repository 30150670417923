import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Tab, Tabs } from '@material-ui/core';
import AddGroupDialogComponent from '../dialog/AddGroupDialogComponent';
import { ReactComponent as FolderPlusIcon } from '../../common/images/FolderPlusFillIcon.svg';
import ClassMemberGroupManagementComponent from '../class/ClassMemberGroupManagementComponent';
import MemberGroupSearchDialogComponent from '../dialog/MemberGroupSearchDialogComponent';
import { inject, observer } from 'mobx-react';
import { checkSpace, checkTextValueLength } from '../../common/Validation';
import { ToastsStore } from 'react-toasts';
import { injectIntl } from 'react-intl';
import { TEAM_OPERATION } from '../../stores/ClassTeamStore';
import { withRouter } from '../../components/WithRouter';
import { ReactComponent as DownloadSimpleIcon } from '../../common/images/DownloadSimpleIcon.svg';
import { SORT_TYPE } from '../../stores/PaginationStore';

const styles = _theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
        '& ul, ol': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        '&:after': {
            content: '""',
            width: '100%',
            height: 3,
            backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: -1,
        },
        '& button': {
            minWidth: 100,
            fontSize: '0.938rem',
            '&.Mui-selected': {
                fontWeight: 700,
                color: '#0097ff',
            },
        },
        '& .MuiTabs-indicator': {
            height: 3,
            backgroundColor: '#0097ff',
        },
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    optionBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 9,
        '& button': {
            marginLeft: 14,
        },
    },
    btnStyle: {
        border: '1px solid #0097ff',
        borderRadius: 7,
        color: '#0097ff',
        padding: '7px 23px',
        fontWeight: 600,
        '&:hover': {
            background: 'transparent',
        },
    },
    btnStyle2: {
        // border: '1px solid #bfbfbf',
        fontSize: '0.875rem',
        borderRadius: 7,
        color: '#000',
        padding: '3px 10px',
        fontWeight: 600,
        '&:hover': {
            background: 'transparent',
        },
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
    },
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
});

const LOG_PREFIX = ' [ StudentListComponent ] ';

export const MEMBER_COMPONENT_TAB_NAME = {
    Member: 'member',
    Group: 'group',
};
class StudentListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: MEMBER_COMPONENT_TAB_NAME.Member,
            invitationDialogOpen: false,
            addGroupDialogOpen: false,
            memberGroupSearch: false,
        };
    }

    componentDidMount() {
        this.props.classStore.getAllGroupUsers(this.props.loginUser.email, this.props.groupId);
    }

    componentDidUpdate(prevProps, prevState, _snapshot) {
        if (prevState.tabs !== this.state.tabs) {
            this.props.paginationStore.changeSort(SORT_TYPE.name);
        }

        if (prevProps.groupId !== this.props.groupId && this.props.groupId !== 0) {
            this.props.classStore.getAllGroupUsers(this.props.loginUser.email, this.props.groupId);
        }
    }

    handleChangeAddGroupDialogOpen = () => {
        this.setState({ addGroupDialogOpen: true });
    };

    handleChangeMemberGroupSearch = () => {
        this.setState({ memberGroupSearch: true });
    };

    handleClose = () => {
        this.setState({
            tabs: MEMBER_COMPONENT_TAB_NAME.Member,
            anchorEl: null,
            invitationDialogOpen: false,
            addGroupDialogOpen: false,
            memberGroupSearch: false,
        });
    };

    handleTabChange = (_event, tabs) => {
        this.setState({ tabs });
        this.props.setSelectedChildrenTab(tabs);
    };

    handleChangeTeamName = e => {
        checkSpace(e);
        checkTextValueLength(e);
        this.props.classTeamStore.changeTeamName(e.target.value);
    };

    handleChangeTeamMembers = member => {
        this.props.classTeamStore.changeTeamMembers(member);
    };

    handleInitTeamStore = () => {
        this.props.classTeamStore.initTeamStore();
    };

    handleCreateTeam = () => {
        const { classes, intl, groupId } = this.props;

        this.props.classTeamStore.createTeam(groupId, {
            getTeams: () => {
                this.requestTeams();
            },
            closeAddGroupDialog: () => {
                this.handleCloseAddGroupDialog();
            },
            alertError: () => {
                ToastsStore.error(intl.formatMessage({ id: 'msg.already_exist_name' }), 3000, classes.toasts);
            },
        });
    };

    handleCloseAddGroupDialog = () => {
        this.setState({ addGroupDialogOpen: false });
        this.handleInitTeamStore();
    };

    handleCloseMemberSearchDialog = () => {
        this.setState({ memberGroupSearch: false });
    };

    handleCloseInvitationDialog = () => {
        this.setState({ invitationDialogOpen: false });
    };

    handleSetTeamInfo = team => {
        this.props.classTeamStore.setTeamInfo(team);
    };

    handleDeleteTeam = () => {
        this.props.classTeamStore.deleteTeam(this.props.loginUser.email, {
            getTeams: () => {
                this.requestTeams();
            },
        });
    };

    handleModifyTeam = () => {
        const { loginUser, intl, classes } = this.props;
        const { msgId } = this.props.classTeamStore;
        this.props.classTeamStore.modifyTeam(loginUser.email, {
            getTeams: () => {
                this.requestTeams();
            },
            alertError: () => {
                ToastsStore.error(intl.formatMessage({ id: `${msgId}` }), 3000, classes.toasts);
            },
            closeAddGroupDialog: () => {
                this.handleCloseAddGroupDialog();
            },
        });
    };

    requestTeams = () => {
        const { page, rowsPerPage, sort } = this.props.paginationStore;
        this.props.classTeamStore.getTeams(this.props.groupId, this.props.loginUser.email, page, rowsPerPage, sort, {
            successAction: teams => {
                this.props.classTeamStore.resetTeams(teams);
            },
        });
    };

    handleChangeSortAndFetchData = sortType => {
        this.props.paginationStore.changeSort(sortType);

        this.requestTeams();
    };

    handleLeaveGroup = () => {
        const { loginUser, groupId } = this.props;
        this.props.classStore.requestLeaveGroup(loginUser.email, groupId, {
            doSuccessAction: () => {
                this.props.navigate('/rooms');
            },
            alert: () => {
                ToastsStore.warning('잘못된 요청입니다. 클래스 정보를 확인해주세요.', 1500);
            },
            error: () => {
                ToastsStore.error('서버와의 연결이 원활하지 않습니다. 잠시 후 다시 시도해 주세요.', 1500);
            },
        });
    };

    requestAllGroupUsers = () => {
        const { loginUser, classStore } = this.props;
        const { selectedGroupId } = classStore;
        classStore.getAllGroupUsers(loginUser.email, selectedGroupId);
    };

    render() {
        const { classes, loginUser, children, classStore, classTeamStore, managementStore, isLeader, paginationStore } = this.props;
        const { groupUsers, selectedAllGroupUsers, selectedGroupMemberAndOperators, selectedGroupMemberAndOperatorsTotalCount } = classStore;
        const { team, teamMembers, changedTeamMembers, changeTeamOperation, teamOperation, groupTeams, teamsTotalCount } = classTeamStore;
        const { isExcelLoading } = managementStore;

        const { tabs } = this.state;
        const groupMembersNotRegisteredTeam = selectedAllGroupUsers.length
            ? selectedAllGroupUsers.filter(m => teamMembers.findIndex(teamMember => teamMember.userId === m.userId) === -1)
            : [];

        return (
            <div className={classes.root}>
                <Tabs value={tabs} onChange={this.handleTabChange} className={classes.tabStyle}>
                    <Tab value={MEMBER_COMPONENT_TAB_NAME.Member} label={`멤버 (${selectedGroupMemberAndOperatorsTotalCount})`} disableRipple />
                    <Tab value={MEMBER_COMPONENT_TAB_NAME.Group} label={`그룹 (${teamsTotalCount})`} disableRipple />
                </Tabs>

                <Box className={classes.optionBox}>
                    <Button
                        className={classes.downloadBtnStyle}
                        disableRipple
                        onClick={this.props.requestGroupUserExportExcel}
                        disabled={!selectedGroupMemberAndOperators.length || isExcelLoading}
                    >
                        <DownloadSimpleIcon />
                    </Button>
                    <Button
                        className={classes.btnStyle2}
                        onClick={() => {
                            changeTeamOperation(TEAM_OPERATION.Create);
                            this.handleChangeAddGroupDialogOpen();
                        }}
                        disableRipple
                        startIcon={<FolderPlusIcon />}
                    >
                        새 그룹 만들기
                    </Button>
                </Box>

                {tabs === MEMBER_COMPONENT_TAB_NAME.Member && children}
                {tabs === MEMBER_COMPONENT_TAB_NAME.Group && (
                    <ClassMemberGroupManagementComponent
                        loginUser={loginUser}
                        groupTeams={groupTeams}
                        setTeamInfo={this.handleSetTeamInfo}
                        deleteTeam={this.handleDeleteTeam}
                        openModifyTeamDialog={this.handleChangeAddGroupDialogOpen}
                        initTeamStore={this.handleInitTeamStore}
                        changeTeamOperation={changeTeamOperation}
                        groupUsers={groupUsers}
                        groupUserAuthority={this.props.groupUserAuthority}
                        findAvatar={this.props.findAvatar}
                        isLeader={isLeader}
                        selectedTeam={team}
                        isTeamLoading={this.props.isTeamLoading}
                        sort={paginationStore.sort}
                        changeSortAndFetchData={this.handleChangeSortAndFetchData}
                        changeSort={paginationStore.changeSort}
                    />
                )}
                <AddGroupDialogComponent
                    operation={teamOperation}
                    loginUser={loginUser}
                    team={team}
                    teamMembers={changedTeamMembers}
                    groupMembers={groupMembersNotRegisteredTeam}
                    addGroupDialogOpen={this.state.addGroupDialogOpen}
                    closeAddGroupDialog={this.handleCloseAddGroupDialog}
                    openMemberGroupSearchDialog={this.handleChangeMemberGroupSearch}
                    changeTeamName={this.handleChangeTeamName}
                    changeTeamMembers={this.handleChangeTeamMembers}
                    initTeamStore={this.handleInitTeamStore}
                    createTeam={this.handleCreateTeam}
                    modifyTeam={this.handleModifyTeam}
                />
                <MemberGroupSearchDialogComponent
                    teamMembers={changedTeamMembers}
                    memberGroupSearch={this.state.memberGroupSearch}
                    handleClose={this.handleCloseMemberSearchDialog}
                    changeTeamMembers={this.handleChangeTeamMembers}
                    requestAllGroupUsers={this.requestAllGroupUsers}
                    allGroupUsers={classStore.allGroupUsers}
                />
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(
        inject('classTeamStore', 'classStore', 'roomStore', 'paginationStore', 'managementStore')(injectIntl(observer(StudentListComponent))),
    ),
);
