import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Box, Typography } from '@material-ui/core';
import MainImage from '../../common/images/main_bg2.jpg';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import Footer from '../footer/Footer';
import ReplayWindow from '../neoPen/ReplayWindowMain';

const styles = theme => ({
    root: {},
    mainBox: {
        backgroundImage: () => (theme.images.Main !== undefined ? `url(${theme.images.Main})` : `url(${MainImage})`),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        // width: '100vw',
        height: `calc(100vh - 61px - 99px)`,
        position: 'relative',
    },
    titleBox: {
        display: 'flex',
        paddingTop: '18%',
        paddingLeft: '10%',
        paddingRight: '10%',
        justifyContent: 'flex-end',
        textAlign: 'right',
        flexDirection: 'column',
    },
    tabletTitleBox: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingLeft: '5%',
            paddingRight: '5%',
            display: 'flex',
            justifyContent: 'space-between',
        },
        width: '100%',
        position: 'absolute',
        bottom: '10%',
        paddingLeft: '10%',
        paddingRight: '10%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    qrBox: {
        width: 'min-content',
    },
    tabletQrBox: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '74%',
            paddingRight: '10%',
            paddingTop: '15%',
            bottom: '25%',
        },
        width: '100%',
        position: 'absolute',
        bottom: '29%',
        paddingTop: '10%',
        paddingLeft: '80%',
    },
    captionText: {
        fontSize: '2.25rem',
        color: '#fff',
        fontWeight: 600,
        letterSpacing: '-2px',
        textShadow: '0 0 7px rgba(0, 0, 0, 0.25)',
        marginBottom: 20,
    },
    halfHighlight: {
        background: 'linear-gradient(180deg,rgba(255,255,255,0) 50%, #e8441e 50%)',
        padding: '6px 4px',
    },
    titleText: {
        fontSize: '3.563rem',
        fontWeight: 700,
        color: () => (theme.configs.MainTextColor ? theme.configs.MainTextColor : '#fff'),
        lineHeight: 1.32,
        textShadow: '0 0 7px rgba(0, 0, 0, 0.25)',
        '& span': {
            color: '#fc9599',
        },
    },
    subText: {
        fontSize: '1.313rem',
        color: () => (theme.configs.SubTextColor ? theme.configs.SubTextColor : '#fff'),
        marginTop: 21,
        textShadow: '0 0 7px rgba(0, 0, 0, 0.25)',
    },
    inputBtnStyle: {
        [theme.breakpoints.down('sm')]: {
            width: 100,
            height: 24,
            fontSize: 12,
            color: '#dc524f',
            background: '#ffe4e3',
            '&:hover': {
                color: '#dc524f',
                background: '#ffe4e3',
            },
        },
        width: 150,
        height: 34,
        flexGrow: 0,
        borderRadius: 21,
        color: '#dc524f',
        background: '#ffe4e3',
        marginTop: 10,
        '&:hover': {
            background: '#dc524f',
            color: theme.configs.Color,
        },
    },
});

class Home extends React.Component {
    render() {
        const { classes, isTablet } = this.props;

        return (
            <div className={classes.root}>
                <Box className={classes.mainBox}>
                    <Box className={isTablet ? classes.tabletTitleBox : classes.titleBox}>
                        <Typography className={classes.captionText}>
                            <span className={classes.halfHighlight}>늘 쓰던 대로, 모든 것을 새롭게!</span>
                        </Typography>
                        <Typography className={classes.titleText}>
                            오프라인 학습경험을 온라인으로 <br />
                            이어주는 <span>스터디라이브</span>
                        </Typography>
                        <Typography className={classes.subText}>스마트펜을 활용한 사용자 중심의 학습경험을 강화했습니다. </Typography>
                    </Box>
                </Box>
                <Footer />
            </div>
        );
    }
}

export default withStyles(styles)(inject('orgStore')(injectIntl(observer(Home))));
