import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Checkbox, Dialog, FormControlLabel, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { ReactComponent as WarningCircleIcon } from '../../common/images/WarningCircleIcon.svg';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 490,
            padding: 24,
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 30,
    },
    titleText: {
        '@media all and (min-width: 601px)': {
            fontSize: '1.125rem',
        },
        fontSize: '1rem',
        color: '#000',
        fontWeight: 600,
        marginLeft: 7,
    },
    iconButton: {
        padding: 5,
        '&:hover': {
            background: 'transparent',
        },
    },
    textStyle: {
        color: '#ff0000',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    textStyle2: {
        padding: '12px 16px',
        backgroundColor: '#eee',
        borderRadius: 6,
        margin: '16px 0',
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    checkBoxStyle: {
        '& .MuiTypography-body1': {
            '@media all and (max-width: 600px)': {
                fontSize: '0.813rem',
            },
            '@media (min-width: 319px) and (max-width: 374px)': {
                letterSpacing: '-0.5px',
            },
        },
        '&.MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#0097ff',
        },
        '&.MuiIconButton-colorSecondary:hover': {
            background: 'transparent',
        },
        '& .MuiSvgIcon-root': {
            '@media all and (max-width: 600px)': {
                width: 20,
                height: 20,
            },
        },
    },
    buttonStyle: {
        width: '100%',
        height: 48,
        borderRadius: 8,
        background: '#c7c9cc',
        color: '#fff',
        fontSize: '1.125rem',
        fontWeight: 500,
        marginTop: 30,
        '&:hover': {
            background: '#c7c9cc',
        },
        '@media all and (max-width: 600px)': {
            height: 33,
            padding: '6px 0',
            fontSize: '0.75rem',
            borderRadius: 4,
        },
    },
    buttonStyleActive: {
        width: '100%',
        height: 48,
        borderRadius: 8,
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        fontWeight: 500,
        marginTop: 30,
        '&:hover': {
            background: '#0097ff',
        },
    },
});

class WithdrawalDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
        };
    }

    handleChange = () => {
        this.setState({
            checked: !this.state.checked,
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Dialog open={this.props.withdrawalDialog} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.titleBox}>
                        <Typography className={classes.titleText}>서비스 탈퇴</Typography>
                        <IconButton className={classes.iconButton} onClick={this.props.handleClose} disableripple={'true'}>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>
                    <Box display="flex" justifyContent="center" flexDirection="column">
                        <Typography variant="body1" className={classes.textStyle}>
                            <WarningCircleIcon style={{ marginRight: 4 }} /> 탈퇴 전 꼭 확인하세요.
                        </Typography>
                        <Typography variant="body2" className={classes.textStyle2}>
                            서비스를 탈퇴하면,
                            <br /> 내 계정이 삭제되며 가입한 모든 모임과 내가 작성한 일정, 게시물, 댓글 등 모두 사라지고 복구가 불가능합니다. 필요하면
                            계정 삭제 전에 지우거나 수정, 보관해주세요.
                        </Typography>
                    </Box>
                    <Box className={classes.checkBoxStyle}>
                        <FormControlLabel
                            disableripple={'true'}
                            control={<Checkbox checked={this.state.checked} onChange={this.handleChange} value="탈퇴" />}
                            label="이 서비스에서 탈퇴하겠습니다."
                        />
                    </Box>
                    <Box>
                        <Button
                            className={this.state.checked ? classes.buttonStyleActive : classes.buttonStyle}
                            // onClick={this.props.handleClose}
                            onClick={this.props.withDrawUser}
                            disableripple={'true'}
                            disabled={!this.state.checked}
                        >
                            탈퇴하기
                        </Button>
                    </Box>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(WithdrawalDialogComponent);
