import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as UsersThreeIcon } from '../../../common/images/UsersThreeIcon.svg';
import { ReactComponent as AlarmIcon } from '../../../common/images/AlarmIcon.svg';
import dayjs from 'dayjs';
import { DATE_UTIL } from '../../../common/util/date.util';
import { inject, observer } from 'mobx-react';
import { roomState } from '../../../stores/RoomStore';
import { withRouter } from '../../../components/WithRouter';
import { ReactComponent as MobileLivIcon } from '../../../common/images/MobileLivIcon.svg';
import { ReactComponent as MobileExpectedIcon } from '../../../common/images/MobileExpectedIcon.svg';
import { ReactComponent as MobileEndIcon } from '../../../common/images/MobileEndIcon.svg';

const styles = theme => ({
    root: {
        width: '100%',
        margin: '0 auto',
        padding: '20px 20px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'column',
        '@media all and (min-width: 601px)': {
            padding: '20px 30px',
        },
    },
    titleText: {
        fontSize: '1rem',
        marginBottom: 20,
        '@media all and (min-width: 601px)': {
            fontSize: '1.25rem',
            marginBottom: 25,
        },
    },
    noScheduleBox: {
        paddingBottom: 150,
    },
    textStyle: {
        fontSize: '0.75rem',
        '@media all and (min-width: 601px)': {
            fontSize: '1rem',
        },
    },
    scheduleBoxIn: {
        width: '100%',
        paddingBottom: 0,
    },
    scheduleBoxStyle: {
        '@media all and (min-width: 601px)': {
            height: 250,
            borderTop: '7px solid #a3a8af',
        },
        width: '49%',
        height: 160,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        marginBottom: 10,
        borderTop: '5px solid #a3a8af',
        border: '1px solid rgba(163, 168, 175, 0.5)',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
    },
    liveScheduleBox: {
        '@media all and (min-width: 601px)': {
            borderTop: '7px solid #00c880',
        },
        borderTop: '5px solid #00c880',
        border: '1px solid rgba(0, 200, 128, 0.5)',
    },
    landscapeScheduleBoxStyle: {
        width: 'calc((100% / 3) - 7px)',
        marginRight: 10,
        '&:nth-child(3n+0)': {
            marginRight: 0,
        },
    },
    roomNameBox: {
        padding: '3px 10px',
        borderBottom: '1px solid #d3d7db',
        '& svg': {
            width: 15,
            height: 18,
            marginRight: 8,
        },
        '@media all and (min-width: 601px)': {
            padding: '7px 15px',
            '& svg': {
                width: 19,
                height: 22,
            },
        },
    },
    roomNameText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.875rem',
        },
        width: '95%',
        fontSize: '0.625rem',
        color: '#000',
    },
    contentsBox: {
        padding: '5px 10px 0px',
        boxSizing: 'border-box',
    },
    box: {
        width: 40,
        height: 16,
        background: '#a3a8af',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    liveBox: {
        background: '#fb4a59',
    },
    boxText: {
        fontFamily: 'Montserrat',
        fontSize: '0.625rem',
        color: '#fff',
        fontWeight: 600,
    },
    circle: {
        width: 4,
        height: 4,
        borderRadius: '50%',
        background: '#fff',
        marginRight: 4,
    },
    scheduleTitleText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.938rem',
            margin: '7px 0 13px',
        },
        fontSize: '0.813rem',
        color: '#000',
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        margin: '0',
    },
    alarmBox: {
        '& svg': {
            width: 15,
            height: 15,
            '& .st0': {
                fill: '#00c880',
            },
        },
        '@media all and (min-width: 601px)': {
            '& svg': {
                width: 22,
                height: 22,
            },
        },
    },
    timeText: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.875rem',
        },
        fontSize: '0.625rem',
        color: '#656565',
        marginLeft: 5,
    },
    buttonStyle: {
        '@media all and (min-width: 601px)': {
            fontSize: '0.813rem',
            height: 34,
            marginTop: 20,
            borderRadius: 7,
        },
        width: '100%',
        height: 28,
        border: '1px solid #656565',
        color: '#656565',
        borderRadius: 5,
        fontWeight: 600,
        fontSize: '0.625rem',
        marginTop: 10,
        '&:hover': {
            background: 'transparent',
        },
    },
    liveButtonStyle: {
        border: '1px solid #0097ff',
        color: '#0097ff',
    },
    commentBox: {
        '@media all and (min-width: 601px)': {
            minHeight: 59,
        },
        minHeight: 35,
    },
});

class MobileSearchScheduleComponent extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const { roomStore, authStore, searchStore } = this.props;
        const { searchKeyword } = searchStore;
        if (
            prevProps.isScrollEnd !== this.props.isScrollEnd &&
            this.props.isScrollEnd &&
            roomStore.roomTotalCount > roomStore.roomListGroupByStartTime.length
        ) {
            roomStore.changeCurrentPage();
            roomStore.getRoomListGroupByStartTime(
                undefined,
                authStore.loginUser.id,
                'all',
                roomStore.currentPage,
                undefined,
                undefined,
                undefined,
                searchKeyword,
            );
        }
    }

    checkRoomState = (startDatetime, endDatetime) => {
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedStartDatetime = DATE_UTIL.convertUTCToTimeZone(startDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedEndDatetime = DATE_UTIL.convertUTCToTimeZone(endDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        if (dayjs(convertedEndDatetime).isBefore(currentDatetime)) {
            return roomState.Ended;
        } else if (
            dayjs(currentDatetime).isBetween(convertedStartDatetime, convertedEndDatetime) ||
            dayjs(currentDatetime).isSame(convertedStartDatetime) ||
            dayjs(currentDatetime).isSame(convertedEndDatetime)
        ) {
            return roomState.Live;
        } else {
            return roomState.Future;
        }
    };

    handleClickEnter = (event, url) => {
        event.stopPropagation();
        // url = url + '&tz=' + 'UTC' + '&local=' + DATE_UTIL.getLanguage();
        window.open(url);
    };

    handleClickDetail = (groupId, roomId) => {
        window.scrollTo(0, 0);
        this.props.navigate(`/class/${groupId}/scheduledetail/${roomId}`);
    };

    render() {
        const { classes, roomStore, landscape, mobileLandscape } = this.props;
        const { roomListGroupByStartTime, roomTotalCount } = roomStore;

        return (
            <div className={classes.root}>
                <Typography className={classes.titleText}>{`검색결과(${roomTotalCount > 999 ? '999+' : roomTotalCount})`}</Typography>
                <Box display="flex" flexWrap="wrap" justifyContent="space-between" style={{ width: '100%' }}>
                    {!roomListGroupByStartTime.length ? (
                        <Box className={classes.noScheduleBox}>
                            <Typography className={classes.textStyle}>검색결과가 없습니다.</Typography>
                        </Box>
                    ) : (
                        <Box display="flex" justifyContent="space-between" flexWrap="wrap" className={classes.scheduleBoxIn}>
                            {roomListGroupByStartTime.map((schedule, i) => {
                                const state = this.checkRoomState(schedule.startDatetime, schedule.endDatetime);
                                return (
                                    <Box
                                        key={i}
                                        className={
                                            state === roomState.Live
                                                ? landscape && mobileLandscape
                                                    ? clsx(classes.scheduleBoxStyle, classes.liveScheduleBox, classes.landscapeScheduleBoxStyle)
                                                    : clsx(classes.scheduleBoxStyle, classes.liveScheduleBox)
                                                : landscape && mobileLandscape
                                                ? clsx(classes.scheduleBoxStyle, classes.landscapeScheduleBoxStyle)
                                                : classes.scheduleBoxStyle
                                        }
                                    >
                                        <Box display="flex" alignItems="center" className={classes.roomNameBox}>
                                            <UsersThreeIcon />
                                            <Typography className={classes.roomNameText} noWrap>
                                                {schedule.groupName}
                                            </Typography>
                                        </Box>
                                        <Box className={classes.contentsBox}>
                                            <Box className={classes.iconBox}>
                                                {state === roomState.Live ? (
                                                    <MobileLivIcon />
                                                ) : state === roomState.Future ? (
                                                    <MobileExpectedIcon />
                                                ) : (
                                                    <MobileEndIcon />
                                                )}
                                            </Box>
                                            <Box className={classes.commentBox}>
                                                <Typography className={classes.scheduleTitleText}>{schedule.name}</Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center" className={classes.alarmBox}>
                                                <AlarmIcon />
                                                <Typography className={classes.timeText}>
                                                    {DATE_UTIL.convertUTCToTimeZone(schedule.startDatetime, DATE_UTIL.FORMAT.SIMPLE_TIME_AMPM_FORMAT)}{' '}
                                                    ~ {DATE_UTIL.convertUTCToTimeZone(schedule.endDatetime, DATE_UTIL.FORMAT.SIMPLE_TIME_AMPM_FORMAT)}
                                                </Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="center">
                                                {state === roomState.Live ? (
                                                    <Button
                                                        className={clsx(classes.buttonStyle, classes.liveButtonStyle)}
                                                        disableRipple
                                                        onClick={e =>
                                                            this.handleClickEnter(
                                                                e,
                                                                schedule.members.find(m => m.userId === this.props.authStore.loginUser.id)
                                                                    .entranceUrl,
                                                            )
                                                        }
                                                    >
                                                        {'참석하기'}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className={classes.buttonStyle}
                                                        disableRipple
                                                        onClick={() => this.handleClickDetail(schedule.groupId, schedule.id)}
                                                    >
                                                        {'살펴보기'}
                                                    </Button>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('roomStore', 'authStore', 'searchStore')(observer(MobileSearchScheduleComponent))));
