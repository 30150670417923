import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, FormControl, InputAdornment, OutlinedInput, Typography } from '@material-ui/core';
import { ReactComponent as LoginUserIcon } from '../../../../common/images/LoginUserIcon.svg';
import { withRouter } from '../../../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { FormHelperText } from '@mui/material';
import { FIND_PASSWORD_EMAIL_SEND_RESULT_STATE } from '../../../../stores/UserStore';
import { checkSpace } from '../../../../common/Validation';

const styles = _theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
    },
    backButtonBox: {
        width: '100%',
        display: 'flex',
        marginBottom: 13,
        marginTop: 45,
    },
    backButton: {
        padding: 0,
        fontSize: '1rem',
        color: '#000',
        '& span': {
            marginRight: 8,
            marginBottom: 1,
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    boxLine: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 0 30px',
        boxSizing: 'border-box',
        borderRadius: 12,
        '@media  (min-width: 481px) and (max-width: 1199px)': {
            padding: '50px 0 30px',
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#333',
        textAlign: 'center',
        marginBottom: 20,
        '@media all and (min-width: 601px)': {
            fontSize: '1.75rem',
        },
    },
    textStyle: {
        fontSize: '0.875rem',
        color: '#808080',
        '@media all and (min-width: 601px)': {
            fontSize: '1rem',
        },
    },
    textStyle1: {
        fontSize: '1.125rem',
        color: '#808080',
        textAlign: 'center',
    },
    textField: {
        marginTop: 20,
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #d9dbde',
            },
        '& .MuiOutlinedInput-root': {
            width: 340,
            '@media (min-width: 319px) and (max-width: 374px)': {
                width: '100%',
            },
            borderRadius: 7,
            '&:hover': {
                borderColor: '#d9dbde',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '13px 0px',
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#9d9d9d',
                opacity: 1,
            },
        },
    },
    errorTextField: {
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #ff0000',
            },
    },
    errorText: {
        fontSize: '0.813rem',
        color: '#ff0000',
        margin: '0 0 0 5px',
    },
    buttonStyle: {
        width: 340,
        background: '#0097ff',
        color: '#fff',
        fontSize: '1rem',
        padding: '8px 0',
        borderRadius: 7,
        marginTop: 30,
        '@media (min-width: 319px) and (max-width: 374px)': {
            width: '80%',
        },
        '@media all and (min-width: 601px)': {
            fontSize: '1.125rem',
            padding: '15px 0',
        },
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
});

class MobilePasswordFindComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordFind: false,
        };
    }

    componentDidMount() {
        this.props.userStore.initFindPasswordState();
    }

    componentWillUnmount() {
        this.props.userStore.initFindPasswordState();
    }

    handleClick = () => {
        this.props.userStore.requestSendFindPasswordEmail();
    };

    handleClickLogin = () => {
        this.props.navigate('/signin');
    };

    changeEmail = e => {
        checkSpace(e);
        this.props.userStore.changeFindPasswordEmail(e.target.value);
    };

    render() {
        const { classes } = this.props;
        const { isValidateFindPasswordEmail, findPasswordEmail, sendFindPasswordEmail, findPasswordEmailSendResult } = this.props.userStore;

        return (
            <div className={classes.root}>
                {/*<Box className={classes.backButtonBox}>*/}
                {/*    <Button className={classes.backButton} startIcon={<BackArrowIcon />} disableRipple onClick={this.handleClickLogin}>*/}
                {/*        로그인*/}
                {/*    </Button>*/}
                {/*</Box>*/}

                <Box className={classes.boxLine}>
                    <Typography className={classes.titleText}>비밀번호 찾기</Typography>
                    {findPasswordEmailSendResult === FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.Success ? (
                        <Box style={{ paddingTop: 20, paddingBottom: 30 }}>
                            <Typography className={classes.textStyle1}>
                                <span style={{ color: '#333' }}>{findPasswordEmail}</span> 로
                            </Typography>
                            <Typography className={classes.textStyle1}>재설정 링크를 보내드렸습니다.</Typography>
                            <Typography className={classes.textStyle1}>비밀번호를 재설정해주세요.</Typography>
                        </Box>
                    ) : (
                        <>
                            <Typography className={classes.textStyle}>가입한 이메일 주소를 입력해주세요.</Typography>
                            <Typography className={classes.textStyle}>비밀번호 재설정을 위한 보안 링크를 보내드립니다.</Typography>

                            <FormControl className={classes.textField} variant="outlined">
                                <OutlinedInput
                                    inputProps={{
                                        'aria-label': 'email input box',
                                    }}
                                    id="outlined-email"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <LoginUserIcon />
                                        </InputAdornment>
                                    }
                                    labelWidth={0}
                                    placeholder="이메일"
                                    onChange={this.changeEmail}
                                    value={findPasswordEmail}
                                    // onPaste={e => {
                                    //     e.preventDefault();
                                    //     return false;
                                    // }}
                                />
                                {/* 이메일 형식이 아니면 */}

                                <Box display={'flex'} alignItems={'center'}>
                                    <FormHelperText
                                        error
                                        id="component-error-text"
                                        hidden={findPasswordEmail === '' || !!isValidateFindPasswordEmail}
                                    >
                                        {/*<Vector />*/}
                                        형식이 잘못되었습니다.
                                    </FormHelperText>
                                </Box>
                            </FormControl>

                            <Button className={classes.buttonStyle} disableRipple onClick={this.handleClick} disabled={!sendFindPasswordEmail}>
                                {findPasswordEmailSendResult === FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.Pending ? (
                                    <CircularProgress size={'2rem'} />
                                ) : (
                                    '재설정 메일 받기'
                                )}
                            </Button>

                            {/* 로그인 실패시*/}
                            {findPasswordEmailSendResult === FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.Failed && (
                                <Box display={'flex'} alignItems={'center'}>
                                    {/*<Vector />*/}
                                    <Typography className={classes.errorText}>
                                        일치하는 로그인 정보가 없습니다. 다시 확인하여 주시기 바랍니다.
                                    </Typography>
                                </Box>
                            )}
                            {findPasswordEmailSendResult === FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.NotAcceptableId && (
                                <Box display={'flex'} alignItems={'center'}>
                                    {/*<Vector />*/}
                                    <Typography className={classes.errorText}>
                                        학생 계정을 사용중 입니다. 담당 선생님에게 요청하여 비밀번호를 초기화 해주세요.
                                    </Typography>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('userStore')(observer(MobilePasswordFindComponent))));
