import { Box } from '@material-ui/core';
import React from 'react';

export const LICENSE_KEY_DIGITS = 16;
export const LICENSE_KEY_PATTERN = /^[A-Za-z0-9]{4}[-\s\.]?[A-Za-z0-9]{4}[-\s\.]?[A-Za-z0-9]{4}[-\s\.]?[A-Za-z0-9]{4}$/;
export const LICENSE_KEY_STR_PATTERN = /^[A-Za-z0-9-]+$/;

class CommonUtil {
    timer = undefined;

    getFirstCapitalLetter(name) {
        if (name) {
            return name.substring(0, 1).toUpperCase();
        }
    }

    getDotSplitFileName(fileName) {
        console.log('getDotSplitFileName = ', fileName);
        const fileInfo = { name: fileName, ext: '' };
        if (fileName && fileName.trim()) {
            const fileIndex = fileName.lastIndexOf('.');
            if (fileIndex > 0) {
                // const result = fileName.split('.');
                // fileInfo.name = result[0];
                // fileInfo.ext = result[1];
                fileInfo.name = fileName.substring(0, fileIndex);
                fileInfo.ext = fileName.substring(fileIndex);
            }
        }
        console.log('getDotSplitFileName result : >> ', fileInfo);
        return fileInfo;
    }

    keywordContains(keyword, search, start) {
        if (!keyword || !search) {
            return false;
        }
        if (typeof start !== 'number') {
            start = 0;
        }
        if (start + search.length > keyword.length) {
            return false;
        } else {
            return keyword.indexOf(search, start) !== -1;
        }
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    textEllipsis(value, allowedLength) {
        console.log('textEllipsis value = ', value);
        console.log('textEllipsis value length = ', value.length);
        if (typeof value !== 'string') return false;
        const ellipsisText = '...';
        let result = value;
        if (result.length > allowedLength) {
            const replaceText = result.slice(0, allowedLength - ellipsisText.length);
            // const replaceText = result.substring(allowedLength);
            console.log('textEllipsis :>>', replaceText);
            // result = result.replace(replaceText, ellipsisText);
            result = replaceText.concat(ellipsisText);
        }
        return result;
    }

    textNewline(value) {
        if (typeof value !== 'string') return false;
        const replace = value => {
            const htmlArr = [];
            value.split('\n').forEach(function (text) {
                const textHtml = "<p style='margin:0'>" + text + '</p>';
                htmlArr.push(textHtml);
            });
            return { __html: htmlArr.join('') };
        };
        return <Box dangerouslySetInnerHTML={replace(value)} />;
    }

    // getRoomState(userId, room) {
    //     const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
    //     const isMade = userId === room.userId;
    //
    //     return {
    //         isConference: room.type === 'Conference',
    //         isLive:
    //             dayjs(currentDatetime).isBetween(room.startDatetime, room.endDatetime) ||
    //             dayjs(currentDatetime).isSame(room.startDatetime) ||
    //             dayjs(currentDatetime).isSame(room.endDatetime),
    //         isCurrent:
    //             dayjs(currentDatetime).isBetween(room.startDatetime, room.endDatetime) ||
    //             dayjs(currentDatetime).isSame(room.startDatetime) ||
    //             dayjs(currentDatetime).isSame(room.endDatetime),
    //         isEnded: dayjs(room.endDatetime).isBefore(currentDatetime),
    //         isMade: isMade,
    //         isEnter: isMade
    //             ? dayjs(currentDatetime).isSameOrBefore(room.endDatetime)
    //             : dayjs(currentDatetime).isBetween(dayjs(room.startDatetime).subtract(30, 'minute'), room.endDatetime),
    //     };
    // }

    // getUserImage(room) {
    //     let image = null;
    //     if (room && room.members.length > 0) {
    //         const owner = room.members.find(member => member.type === MemberType.Owner);
    //         if (owner) {
    //             image = owner.image;
    //         }
    //     }
    //     return image;
    // }

    debouncedFunc(func, delay) {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = undefined;
        }

        this.timer = setTimeout(func, delay);
    }

    licenseKeySplitter({ str = '' }) {
        if (!str) {
            return;
        }
        console.log('licenseKeySlice str = ', str);
        const reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
        const removeReg = str.replace(reg, '').toUpperCase();
        if (!removeReg || removeReg?.length !== LICENSE_KEY_DIGITS) {
            return;
        }

        const standardIndex = LICENSE_KEY_DIGITS / 4;

        const joinedDash = [
            removeReg.slice(0, standardIndex),
            removeReg.slice(standardIndex, standardIndex * 2),
            removeReg.slice(standardIndex * 2, standardIndex * 3),
            removeReg.slice(standardIndex * 3),
        ].join('-');
        console.log('licenseKeySlice result = ', joinedDash);

        return joinedDash;
    }
}

export const UTIL = new CommonUtil();
