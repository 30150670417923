import React, { useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    LinearProgress,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Popover,
    Select,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { TooltipType } from '../../stores/TooltipStore';
import RegisterNoteIcon from '../../common/images/RegisterNoteIcon.png';
import clsx from 'clsx';
import PaperGroupFolderBreadcrumbs from '../neoPen/PaperGroupFolderBreadcrumbs';
import MoreButton from '../../components/common/MoreButton';
import {MovingObjectType, PaperGroupType, PrintState} from '../../stores/NeoPenStore';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as FolderPlusFillIcon } from '../../common/images/FolderPlusFillIcon.svg';
import { ReactComponent as FilePdfIcon } from '../../common/images/FilePdfIcon.svg';
import { ReactComponent as Info } from '../../common/images/Info.svg';
import { ReactComponent as Mathematics } from '../../common/images/Mathematics.svg';
import { ReactComponent as MoreIcon } from '../../common/images/DotsThreeOutlineVerticalIcon.svg';
import { ReactComponent as CheckCircleAgreeOffIcon } from '../../common/images/CheckCircleAgreeOffIcon.svg';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../common/images/CheckCircleAgreeOnIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { ReactComponent as LockLaminatedIcon } from '../../common/images/LockLaminatedIcon.svg';
import { ReactComponent as FolderNotchIcon } from '../../common/images/FolderNotchIcon.svg';
import { ReactComponent as X } from '../../common/images/X.svg';
import { ReactComponent as NotePencilIcon } from '../../common/images/NotePencilIcon.svg';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import FormRegistrationDialogComponent from "../dialog/FormRegistrationDialogComponent";
import {ToastsStore} from "react-toasts";
import {useParams} from "react-router-dom";
import {withRouter} from "../../components/WithRouter";
import WrittenDeleteDialogComponent from "../dialog/WrittenDeleteDialogComponent";

const useStyles = makeStyles(theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
        '& table button.MuiButton-root': {
            minWidth: 'unset',
            padding: 0,
        },
    },
    flexCenter: {
        width: 'calc(100% - 94px - 46px)',
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
    },
    optionBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    formControl: {
        minWidth: 100,
        width: 100,
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        '&:after': {
            content: '""',
            width: '100%',
            height: 3,
            backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: -1,
        },
        '& button': {
            minWidth: 100,
            fontSize: '0.938rem',
            '&.Mui-selected': {
                fontWeight: 700,
                color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
            },
        },
        '& .MuiTabs-indicator': {
            height: 3,
            backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
    btnStyle: {
        border: '1px solid #bfbfbf',
        borderRadius: 4,
        marginLeft: 10,
        color: '#000',
        padding: '1px 8px',
        fontWeight: 400,
        fontSize: '0.875rem',
        '&:hover': {
            background: 'transparent',
        },
    },
    btnPrint: {
        marginRight: 20,
    },
    btnStyle2: {
        border: () => (theme.configs.MainBtnColor ? `1px solid ${theme.configs.MainBtnColor}` : '1px solid #0097FF'),
        borderRadius: 7,
        color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        padding: '7px 23px',
        fontWeight: 600,
        '&:hover': {
            fontWeight: 800,
            background: 'transparent',
        },
    },
    breadcrumbsBtnStyle: {
        marginLeft: 10,
        '&:hover': {
            background: 'transparent',
        },
    },
    popoverBox: {
        '& .MuiPopover-paper': {
            '@media all and (min-width: 1500px)': {
                width: 185,
            },
            width: 180,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
            borderRadius: 7,
            padding: '8px 0',
        },
        '& .MuiListItem-root': {
            padding: '3px 10px',
            '&:hover': {
                background: '#d3d7db',
            },
        },
        '& .MuiListItemIcon-root': {
            minWidth: 20,
        },
        '& .MuiListItemText-root': {
            paddingLeft: 0,
            '& span': {
                fontSize: '0.813rem',
            },
        },
    },
    tableBtnStyle: {
        width: '100%',
        '& span': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& p': {
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                textAlign: 'left',
                whiteSpace: 'nowrap',
            },
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    checkBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#a9adb4',
        fontSize: '0.875rem',
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 4,
        },
    },
    checkBoxStyleOn: {
        color: '#0097FF',
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 26px',
        boxSizing: 'border-box',
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listItemTitle: {
        width: '100%',
        fontSize: '0.875rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        whiteSpace: 'nowrap',
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    progressBarStyle: {
        width: 'calc(100% - 100px)',
        margin: '0 0 0 10px',
        '& .MuiLinearProgress-root': {
            backgroundColor: '#E5E5E6',
            borderRadius: 10,
        },
        '& .MuiLinearProgress-bar': {
            backgroundColor: '#FFC56E',
        },
    },
    progressText: {
        color: '#333',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 4,
        '& p': {
            fontSize: '0.625rem',
        },
        '& p:first-child': {
            color: '#AEB3B9',
        },
    },
    emptyText: {
        fontSize: '0.875rem',
        color: '#000',
        marginTop: 30,
        textAlign: 'center',
    },
    toasts: {
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    btnDefault: {
        padding: 0,
        marginBottom: 4,
        marginRight: 20,
    },
    explanationBox: {
        width: 210,
        padding: '10px',
        background: '#2078e8',
        boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 3,
        position: 'absolute',
        zIndex: 100,
        bottom: -77,
        left: -73,
        '&::before': {
            backgroundColor: '#2078e8',
            content: "''",
            display: 'block',
            height: '13px',
            position: 'absolute',
            top: -6,
            left: 109,
            transform: 'rotate( -227deg ) skew( 0deg )',
            width: '13px',
            // boxShadow: '2px -2px 2px 0 rgb(178 178 178 / 20%)',
            zIndex: 200,
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    explanationBox2: {
        width: 230,
        zIndex: 10000,
        bottom: -123,
        left: -45,
        '&::before': {
            top: -6,
            left: 120,
        },
    },
    stepContents: {
        fontSize: '0.813rem',
        color: '#fff',
        fontWeight: 300,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    tableBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px solid #d3d7db',
        height: 80,
        '& td': {
            borderBottom: 0,
            padding: 0,
        },
    },
    folderBox: {
        width: 'calc(100% - 56px - 46px)',
    },
    skeletonStyle: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 26px',
    },
}));

const RESPONSE_KEY_CODE = {
    ESC: 27,
    ENTER: 13,
};

const LightTooltip = withStyles(_theme => ({
    tooltip: {
        padding: '4px 4px',
        border: '0.3px solid #000000',
        background: '#fffff5',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        color: '#000',
        borderRadius: '0',
        marginLeft: 5,
        marginTop: 5,
    },
}))(Tooltip);

const FormTabComponentForEvaluation = ({ isLeader, tooltipStore, neoPenStore }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isCreateFolder, setIsCreateFolder] = useState(false);
    const [newFolderName, setNewFolderName] = useState('');
    const [menuInfoTooltip, setMenuInfoTooltip] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [writeDataFilter, setWriteDataFilter] = useState('date');
    const [formRegistrationOpen, setFormRegistrationOpen] = useState(false);
    const [anchorGeneral, setAnchorGeneral] = useState(null);

    const [renamingFolderId, setRenamingFolderId] = useState(undefined);
    const [renamingFolderName, setRenamingFolderName] = useState("");

    const [renamingPaperGroupId, setRenamingPaperGroupId] = useState(undefined);
    const [renamingPaperGroupName, setRenamingPaperGroupName] = useState("");

    const [isLoadingPreview, setIsLoadingPreview] = useState(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const classes = useStyles();
    const openPopover = Boolean(anchorEl);
    const general = Boolean(anchorGeneral);

    const params = useParams();

    const handleClosePopover = () => {
        tooltipStore.closeTooltip(TooltipType.LimitUpload);
        setAnchorEl(null);
    };

    const handleClose = () => {
        tooltipStore.closeTooltip(TooltipType.LimitUpload);

        setAnchorGeneral(null);
    };

    const handleClickPopover = event => {
        setAnchorEl(event.currentTarget);
        setMenuInfoTooltip(true);
    };

    const handleChangeCheckBox = () => {
        setIsFiltered(prevState => !prevState);
    };

    const handleClickGeneral = (event, paperGroup) => {
        neoPenStore.selectPaperGroup(paperGroup);

        setAnchorGeneral(event.currentTarget);
    };

    const handleOpenPreview = async paperGroup => {
        if (isLoadingPreview) return;

        setIsLoadingPreview(true);

        const url = await neoPenStore.getPdfDownloadUrl(paperGroup);

        window.open(url, '_blank');

        setIsLoadingPreview(false);
    };

    const handleCreateFolder = event => {
        if (event.keyCode === RESPONSE_KEY_CODE.ESC) {
            setIsCreateFolder(false);
        } else if (event.keyCode === RESPONSE_KEY_CODE.ENTER && newFolderName.length > 0) {
            const groupId = params.id;

            neoPenStore.createFolder(groupId, nPS.currentFolder.id, newFolderName, PaperGroupType.Exam);
            setIsCreateFolder(false);
        }
    };

    const handleRenameFolder = event => {
        if (event.keyCode === RESPONSE_KEY_CODE.ESC) {
            setRenamingFolderId(undefined);
            setRenamingFolderName('');
        } else if (event.keyCode === RESPONSE_KEY_CODE.ENTER && renamingFolderName.length > 0) {
            neoPenStore.renameFolder(renamingFolderId, renamingFolderName);

            setRenamingFolderId(undefined);
            setRenamingFolderName('');
        }
    };

    const handleCloseInfoTooltip = () => {
        tooltipStore.closeTooltip(TooltipType.Print);
    };

    const handleChangeDataSort = event => {
        setWriteDataFilter(event.target.value);

        neoPenStore.sortFolder(event.target.value);
        neoPenStore.sortPaperGroupByModifiedAt(event.target.value);
    };

    const handleChangeLock = () => {
        neoPenStore.updatePaperGroupPublicState();

        setAnchorGeneral(null);
    };

    const handleMoveFolderDialog = () => {
        handleClose();

        neoPenStore.setSelectFolderDialogOpened(true, MovingObjectType.PaperGroup, neoPenStore.selectedPaperGroup.id);
    };
    const selectPaperGroupMenusInMoreBtn = () => {
        const nPS = neoPenStore;

        if (isLeader) {
            return [
                {
                    name: nPS.selectedPaperGroup !== undefined && !nPS.selectedPaperGroup.public ? '공개로 변경' : '비공개로 변경',
                    func: () => handleChangeLock(),
                },
                {
                    name: '이름 바꾸기',
                    func: () => {
                        setRenamingPaperGroupId(nPS.selectedPaperGroup.id);
                        setRenamingPaperGroupName(nPS.selectedPaperGroup.title);

                        handleClose();
                    },
                },
                { name: '이동', func: () => handleMoveFolderDialog() },
                { name: '삭제', func: () => setDeleteDialogOpen(true) },
            ];
        }

        return [];
    };

    const handleDelete = () => {
        neoPenStore.deletePaperGroup();

        setAnchorGeneral(null);
        setDeleteDialogOpen(false);
    };


    const handleRenamePaperGroup = (event, paperGroup) => {
        if (event.keyCode === RESPONSE_KEY_CODE.ESC) {
            setRenamingPaperGroupId(undefined);
            setRenamingPaperGroupName('');
        } else if (event.keyCode === RESPONSE_KEY_CODE.ENTER && renamingPaperGroupName.length > 0) {
            neoPenStore.renamePaperGroup(paperGroup, renamingPaperGroupName);

            setRenamingPaperGroupId(undefined);
            setRenamingPaperGroupName('');
        }
    };

    const handleOpenPrintPreviewDialog = async paperGroup => {
        neoPenStore.selectPaperGroup(paperGroup);

        if (neoPenStore.selectedPaperGroup.section !== 10) {
            ToastsStore.info('프린트를 지원하지 않는 서식입니다.', 3000, classes.toasts);

            return;
        }

        const statusMsg = await neoPenStore.printPOD();

        ToastsStore.info(statusMsg, 3000, classes.toasts);
    };

    const paperGroupMenusInMoreBtn = selectPaperGroupMenusInMoreBtn();

    const nPS = neoPenStore;

    let viewingPaperGroups = isLeader ? (isFiltered ? nPS.publicPaperGroups : nPS.activePaperGroups) : nPS.publicPaperGroups;

    viewingPaperGroups = viewingPaperGroups.filter(paperGroup => paperGroup.folderId === (nPS.currentFolder === undefined ? null : nPS.currentFolder.id))
        .filter(paperGroup => paperGroup.type === PaperGroupType.Exam);

    return (
        <div>
            <Box className={classes.optionBox}>
                {isLeader && (
                    <LightTooltip title="새 폴더 만들기" placement="bottom" className={classes.btnDefault}>
                        <IconButton
                            disableRipple
                            onClick={() => {
                                if (!isCreateFolder) {
                                    setIsCreateFolder(true);
                                    setNewFolderName('');
                                }
                            }}
                        >
                            <FolderPlusFillIcon />
                        </IconButton>
                    </LightTooltip>
                )}
                {isLeader && (
                    <Box style={{ position: 'relative' }}>
                        <Button
                            aria-owns={openPopover ? 'simple-popper' : undefined}
                            onClick={handleClickPopover}
                            className={classes.btnStyle2}
                            disableRipple
                        >
                            서식등록
                        </Button>
                        <Popover
                            id="simple-popper"
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            className={classes.popoverBox}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setAnchorEl(null);
                                    tooltipStore.closeTooltip(TooltipType.LimitUpload);

                                    nPS.selectNeolabNoteDialogOpenChanged();
                                }}

                                component={'label'}
                            >
                                <ListItemIcon>
                                    <img src={RegisterNoteIcon} alt="전용 노트 아이콘" />
                                </ListItemIcon>
                                <ListItemText inset primary="전용 노트 등록" />
                            </MenuItem>
                            <MenuItem
                                onClick={e => {
                                    e.stopPropagation();

                                    setAnchorEl(null);
                                    setFormRegistrationOpen(true);

                                    tooltipStore.closeTooltip(TooltipType.LimitUpload);
                                }}
                                component={'label'}
                                // disabled={isUploading}
                            >
                                <ListItemIcon>
                                    <FilePdfIcon />
                                </ListItemIcon>
                                <ListItemText
                                    inset
                                    primary={
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            직접 등록(.pdf)
                                            <Info
                                            onClick={e => {
                                                e.stopPropagation();
                                                tooltipStore.openTooltip(TooltipType.LimitUpload);
                                            }}
                                            />
                                        </Box>
                                    }
                                />
                            </MenuItem>
                        </Popover>
                        {!tooltipStore.isClosed[TooltipType.LimitUpload] && menuInfoTooltip && (
                            <Box className={clsx(classes.explanationBox, classes.explanationBox2)}>
                                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                                    <Typography className={classes.stepContents}>최대 100MB 까지 업로드 가능합니다.</Typography>
                                    <IconButton className={classes.iconButton} onClick={() => tooltipStore.closeTooltip(TooltipType.LimitUpload)} disableRipple>
                                        <X />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>

            <Box className={classes.filterStyle} style={{ paddingBottom: 0 }}>
                <PaperGroupFolderBreadcrumbs />

                <Box display="flex" alignItems="center">
                    <Box
                        className={isFiltered ? classes.checkBoxStyle : clsx(classes.checkBoxStyle, classes.checkBoxStyleOn)}
                        onClick={handleChangeCheckBox}
                        style={{ marginRight: 20 }}
                    >
                        {isFiltered ? <CheckCircleAgreeOffIcon /> : <CheckCircleAgreeOnIcon />}
                        공개된 서식만
                    </Box>

                    <FormControl className={classes.formControl}>
                        <Select
                            value={writeDataFilter}
                            displayEmpty
                            onChange={handleChangeDataSort}
                            IconComponent={() => (
                                <Box style={{ width: 16, height: 16, marginLeft: 5 }}>
                                    <ArrowDownIcon />
                                </Box>
                            )}
                        >
                            <MenuItem value="date" className={classes.menuItem}>
                                최근 등록일 순
                            </MenuItem>
                            <MenuItem value="alpha" className={classes.menuItem}>
                                가나다 순
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <Box>
                {isCreateFolder && (
                    <Box style={{ width: '100%' }}>
                        <TableRow className={classes.tableBox}>
                            <TableCell align="center" style={{ width: 56 }}>
                                <FolderNotchIcon />
                            </TableCell>
                            <TableCell className={classes.folderBox}>
                                <div>
                                    <input
                                        type="text"
                                        autoFocus
                                        className={classes.textField}
                                        onChange={e => setNewFolderName(e.target.value)}
                                        onKeyDown={e => handleCreateFolder(e)}
                                    />
                                </div>
                            </TableCell>
                            <TableCell align="center">
                                <MoreButton
                                    menuItems={[
                                        {
                                            name: '취소',
                                            menuFunc: () => setIsCreateFolder(false),
                                        },
                                    ]}
                                />
                            </TableCell>
                        </TableRow>
                    </Box>
                )}

                {nPS.currentFolder !== undefined &&
                    nPS.currentFolder.childIds.map(folderId => {
                        if(nPS.folders[folderId].type !== PaperGroupType.Exam)
                            return <Box/>;

                        return (
                            <Box style={{ width: '100%' }}>
                                <TableRow className={classes.tableBox}>
                                    <TableCell align="center" style={{ width: 56 }}>
                                        <FolderNotchIcon />
                                    </TableCell>
                                    <TableCell className={classes.folderBox}>
                                        {renamingFolderId === folderId ? (
                                            <div>
                                                <input
                                                    type="text"
                                                    autoFocus
                                                    className={classes.textField}
                                                    onChange={e => setRenamingFolderName(e.target.value)}
                                                    onKeyDown={e => handleRenameFolder(e)}
                                                    value={renamingFolderName}
                                                />
                                            </div>
                                        ) : (
                                            <Button
                                                className={classes.tableBtnStyle}
                                                disableRipple
                                                onClick={() => nPS.selectFolder(nPS.folders[folderId])}
                                            >
                                                <Typography>{nPS.folders[folderId].name}</Typography>
                                                <Typography variant="body2" className={classes.listItemDate}>
                                                    {nPS.folders[folderId].createdDatetime.split('T')[0]}
                                                </Typography>
                                            </Button>
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {isLeader && (
                                            <MoreButton
                                                menuItems={[
                                                    {
                                                        name: '이동',
                                                        menuFunc: () => nPS.setSelectFolderDialogOpened(true, MovingObjectType.Folder, folderId),
                                                    },
                                                    {
                                                        name: '이름 바꾸기',
                                                        menuFunc: () => {
                                                            setRenamingFolderId(folderId);
                                                            setRenamingFolderName(nPS.folders[folderId].name);
                                                        }
                                                    },
                                                ]}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            </Box>
                        );
                    })}

                {nPS.isUploadingPdf && (
                    <Box key={'loadingSkeleton'}>
                        <Box className={classes.skeletonStyle}>
                            <Skeleton variant="circle" animation="wave" width={40} height={40} style={{ marginRight: 10 }} />
                            <Box>
                                <Skeleton variant="rect" animation="wave" width={300} height={15} />
                                <Skeleton variant="rect" animation="wave" width={118} height={10} style={{ marginTop: 5 }} />
                            </Box>
                        </Box>
                    </Box>
                )}

                {viewingPaperGroups.map((paperGroup, i) => {
                    let lastDate = paperGroup.updatedDatetime.split('T')[0].replaceAll('-', '.');

                    return (
                        <Box key={i} className={classes.listItemStyle}>
                            <Box className={classes.flexCenter} style={{ flexGrow: 1 }}>
                                <Box className={classes.avatar} style={{ flexGrow: 0 }}>
                                    <NotePencilIcon />
                                </Box>

                                {renamingPaperGroupId === paperGroup.id ? (
                                    <div>
                                        <input
                                            type="text"
                                            autoFocus
                                            className={classes.textField}
                                            onChange={e => setRenamingPaperGroupName(e.target.value)}
                                            onKeyDown={e => handleRenamePaperGroup(e, paperGroup)}
                                            value={renamingPaperGroupName}
                                        />
                                    </div>
                                ) : (
                                    <Box flexDirection="column" style={{ flexGrow: 1, width: 'calc(100% - 50px)' }}>
                                        <LightTooltip
                                            title={'`' + paperGroup.title.trim().replace(/(.pdf)$/, '') + '` 클릭하여 미리보기'}
                                            placement="bottom-start"
                                        >
                                            <Typography
                                                variant="h5"
                                                className={classes.listItemTitle}
                                                onClick={() => handleOpenPreview(paperGroup)}
                                                style={{ cursor: isLoadingPreview ? 'wait' : 'pointer' }}
                                            >
                                                {paperGroup.title.trim().replace(/(.pdf)$/, '')}
                                                {!paperGroup.public ? <LockLaminatedIcon style={{ marginLeft: 4 }} /> : ''}
                                            </Typography>
                                        </LightTooltip>
                                        <Box display="flex" alignItems="flex-end" style={{ height: 22 }}>
                                            <Typography variant="body2" className={classes.listItemDate}>
                                                {lastDate}
                                            </Typography>
                                            {(nPS.printState[paperGroup.paperGroupId] === PrintState.Ready ||
                                                nPS.printState[paperGroup.paperGroupId] === PrintState.Printing) && (
                                                <Box className={classes.progressBarStyle}>
                                                    <Box className={classes.progressText}>
                                                        <Typography variant="body2">인코딩 중...</Typography>
                                                    </Box>
                                                    <LinearProgress variant="determinate" value={nPS.printProgress[paperGroup.paperGroupId]} />
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                            </Box>

                            <Box style={{ position: 'relative' }}>
                                {paperGroup.section !== 10 ? (
                                    <Tooltip title={'Ncode 적용된 서식이므로 프린트가 필요 없습니다.'}>
                                        <div>
                                            <Button
                                                disableRipple
                                                className={clsx(classes.btnStyle, classes.btnPrint)}
                                                onClick={() => handleOpenPrintPreviewDialog(paperGroup)}
                                                disabled={true}
                                            >
                                                <Typography>프린트</Typography>
                                            </Button>
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Button
                                        disableRipple
                                        className={clsx(classes.btnStyle, classes.btnPrint)}
                                        onClick={() => handleOpenPrintPreviewDialog(paperGroup)}
                                    >
                                        <Typography>프린트</Typography>
                                    </Button>
                                )}
                                {i === 0 && !tooltipStore.isClosed[TooltipType.Print] && (
                                    <Box className={classes.explanationBox}>
                                        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                                            <Typography className={classes.stepContents}>
                                                서식을 필기 수업에 사용하기 위해 ‘프린트' 버튼을 꼭 눌러주세요.
                                            </Typography>
                                            <IconButton className={classes.iconButton} onClick={handleCloseInfoTooltip} disableRipple>
                                                <X />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                )}
                            </Box>

                            {isLeader && (
                                <IconButton disableRipple aria-haspopup="true" onClick={event => handleClickGeneral(event, paperGroup)}>
                                    <MoreIcon />
                                </IconButton>
                            )}
                            <Menu id="simple-menu" anchorEl={anchorGeneral} open={general} onClose={handleClose} className={classes.menuBox}>
                                {paperGroupMenusInMoreBtn.map((item, index) => {
                                    return (
                                        <MenuItem key={`${item.name}_${index}`} onClick={() => item.func()} className={classes.menuItem}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        </Box>
                    );
                })}

                {viewingPaperGroups.length === 0 && (
                    <Box>
                        <Typography className={classes.emptyText}>등록된 서식이 없습니다.</Typography>
                    </Box>
                )}
            </Box>

            <WrittenDeleteDialogComponent
                title={neoPenStore.selectedPaperGroup ? neoPenStore.selectedPaperGroup.title : ''}
                handleDelete={() => handleDelete()}
                deleteDialogOpen={deleteDialogOpen}
                handleDeleteDialogClose={() => setDeleteDialogOpen(state => !state)}
            />

            <FormRegistrationDialogComponent
                handleCloseFormRegistration={() => setFormRegistrationOpen(false)}
                formRegistrationOpen={formRegistrationOpen}
                type={PaperGroupType.Exam}
            />
        </div>
    );
};

export default withRouter(inject('tooltipStore', 'neoPenStore')(observer(FormTabComponentForEvaluation)));
