import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, InputBase } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../common/images/SearchIcon.svg';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { withRouter } from '../../../components/WithRouter';
import { SearchCategory, SearchScope } from '../../../common/SearchItems';
import Button from '@material-ui/core/Button';
import { MOBILE_APP_BAR_MODE } from '../topBar/MobileAppBar';

const style = _theme => ({
    root: {
        '@media all and (min-width: 601px)': {
            height: 66,
        },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '10px 0  10px 13px',
        height: 56,
        background: '#fff',
        borderBottom: '1px solid #c0c2c3',
        position: 'fixed',
        width: '100%',
        zIndex: 10000,
    },
    search: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#eee',
        padding: '3px 6px 3px',
        borderRadius: 3,
        '& .MuiInputBase-input::placeholder': {
            '@media all and (min-width: 601px)': {
                fontSize: '1.125rem',
            },
            opacity: 1,
            fontSize: '0.813rem',
            color: '#92979e',
        },
        '& .MuiInputBase-input': {
            padding: '6px 7px 4px',
        },
        '& svg': {
            width: 16,
            height: 26,
            '& path': {
                stroke: '#a3a8af',
            },
        },
        '@media all and (min-width: 601px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    inputRoot: {
        '@media all and (min-width: 601px)': {
            fontSize: '1.125rem',
        },
        color: '#000',
        fontSize: '0.813rem',
        width: '100%',
        marginRight: 10,
    },
    btnStyle: {
        background: 'transparent',
        fontSize: '0.813rem',
        '&:hover': {
            background: 'transparent',
        },
    },
});

class MobileTopSearch extends Component {
    constructor(props) {
        super(props);
    }

    handleSearchButton = () => {
        const { classStore, roomStore, postStore, searchStore } = this.props;
        const { isSearchGroupListLoading } = classStore;
        const { isLoadRoomLoading } = roomStore;
        const { isPostListLoading } = postStore;
        const { searchKeyword } = searchStore;

        this.props.navigate(`/search/${SearchScope.All}/${SearchCategory.Study}/${searchKeyword}`);

        // if (!isSearchGroupListLoading && !isLoadRoomLoading && !isPostListLoading) {
        //     classStore.initSearchClass();
        //     roomStore.initRoom();
        //     postStore.initPost();
        //     searchStore.initCurrentPage();
        //
        //     roomStore.setRoomRowsPerPage(16);
        //
        //     this.props.classStore.getGroupListByKeyword(searchKeyword, {
        //         moveToSearchPage: () => this.props.navigate(`/search/${SearchScope.All}/${SearchCategory.Study}/${searchKeyword}`),
        //         getRoomList: () =>
        //             this.props.roomStore.getRoomListGroupByStartTime(
        //                 undefined,
        //                 this.props.authStore.loginUser.id,
        //                 'all',
        //                 1,
        //                 undefined,
        //                 undefined,
        //                 undefined,
        //                 searchKeyword,
        //             ),
        //         getPostList: () => this.props.postStore.getPostList(undefined, this.props.authStore.loginUser.id, searchKeyword, 1, 16),
        //     });
        // } else {
        //     alert('입력이 너무 빠릅니다.');
        // }
    };

    handleChangeSearchKeyword = value => {
        this.props.searchStore.changeSearchKeyword(value);
    };

    handleKeyUpEnter = e => {
        if (e.key === 'Enter') {
            this.handleSearchButton();
        }
    };

    handleClickBack = () => {
        const { searchKeyword } = this.props.searchStore;
        if (searchKeyword) {
            const initValue = '';
            this.props.searchStore.changeSearchKeyword(initValue);
            this.props.navigate(-1);
        } else {
            this.props.changeMode(MOBILE_APP_BAR_MODE.normal);
        }
    };

    render() {
        const { classes, intl, searchStore } = this.props;
        const { searchKeyword } = searchStore;

        return (
            <div className={classes.root}>
                <Box className={classes.search}>
                    <SearchIcon />
                    <InputBase
                        value={searchKeyword}
                        placeholder={intl.formatMessage({ id: 'search' })}
                        className={classes.inputRoot}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={event => this.handleChangeSearchKeyword(event.target.value)}
                        onKeyUp={this.handleKeyUpEnter}
                    />
                </Box>
                <Button className={classes.btnStyle} onClick={this.handleClickBack.bind(this)} disableRipple>
                    취소
                </Button>
            </div>
        );
    }
}

export default withRouter(
    withStyles(style)(
        inject('orgStore', 'localeStore', 'classStore', 'authStore', 'roomStore', 'postStore', 'searchStore')(injectIntl(observer(MobileTopSearch))),
    ),
);
