import React, { useMemo, useState } from 'react';
import { Avatar, Box, Button, FormControl, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from '../../../common/images/ArrowDownIcon.svg';
import TestAvatar from '../../../common/images/TestAvatar.jpg';
import AddAdminDialogComponent from './dialog/MobileAddAdminDialogComponent';
import AdminDeleteDialogComponent from './dialog/MobileAdminDeleteDialogComponent';
import { SORT_BY } from '../class/MobileClassMainComponent';
import { LmsGroupUserType } from '../../../stores/ClassStore';
import { SORT_UTIL } from '../../../common/util/sort.util';
import { SettingsInnerPath } from './MobileSettingComponent';

const useStyles = makeStyles(theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
    },
    titleText: {
        fontSize: '0.938rem',
        color: '#000',
        marginBottom: 10,
    },
    optionBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginBottom: 32,
    },
    btnStyle: {
        border: '1px solid #0097ff',
        borderRadius: 7,
        color: '#0097ff',
        padding: '7px 23px',
        fontWeight: 600,
        '&:hover': {
            background: 'transparent',
        },
    },
    textStyle: {
        fontSize: '0.875rem',
        color: '#000',
    },
    spanStyle: {
        fontWeight: 600,
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
    },
    listTopBorder: {
        borderTop: '1px solid #e1e1e1',
        marginTop: 7,
    },
    listBox: {
        padding: '15px 0 15px 25px',
        borderBottom: '1px solid #e1e1e1',
    },
    avatar: {
        width: 40,
        height: 40,
        marginRight: 15,
        background: '#a3a8af',
        '& svg': {
            width: 25,
            height: 25,
            '& path': {
                fill: '#fff',
            },
        },
    },
    nameText: {
        fontSize: '0.875rem',
        fontWeight: 600,
        color: '#000',
    },
    boxStyle: {
        fontFamily: 'Montserrat',
        fontSize: '0.625rem',
        fontWeight: 'bold',
        padding: '2px 5px 0px',
        background: '#00c880',
        color: '#fff',
        marginLeft: 7,
    },
    buttonStyle: {
        background: '#c2c2c2',
        borderRadius: 4,
        width: 70,
        height: 30,
        color: '#fff',
        fontSize: '0.75rem',
        '&:hover': {
            background: '#c2c2c2',
        },
    },
}));

function MobileAddAdminComponent(props) {
    const classes = useStyles();
    const [sort, setSort] = useState(SORT_BY.name);
    const [addAdminDialogOpen, setAddAdminDialogOpen] = useState(false);
    const [adminDeleteDialogOpen, setAdminDeleteDialogOpen] = useState(false);
    const [selectedExceptAdmin, setSelectedExceptAdmin] = useState(undefined);
    const sortOperators = () => {
        return props.groupLeaderAndOperators.sort((a, b) => SORT_UTIL.compareMembers(a, b, sort));
    };
    const sortedOperators = useMemo(sortOperators, [props.groupLeaderAndOperators, sort]);

    const handleChangeSort = event => {
        setSort(event.target.value);
    };

    const handleChangeAddAdminDialog = isOpen => {
        // setUserRequests({ addAdminDialogOpen: isOpen });
        setAddAdminDialogOpen(isOpen);
    };

    const handleChangeAdminDeleteDialog = (isOpen, admin) => {
        // setUserRequests({ adminDeleteDialogOpen: isOpen });
        admin && setSelectedExceptAdmin({ ...admin });
        setAdminDeleteDialogOpen(isOpen);
    };

    const handleExceptAdmin = () => {
        props.exceptAdmin(selectedExceptAdmin);
        setAdminDeleteDialogOpen(false);
    };

    const handleMoveToMemberAuthorityPage = () => {
        console.log('handleMoveToMemberAuthorityPage,', props);
        props.moveTo(SettingsInnerPath.permission);
    };

    return (
        <>
            <Box>
                <Typography className={classes.titleText}>
                    <b>관리자</b> • {sortedOperators.length}명
                </Typography>
            </Box>
            <Box className={classes.optionBox}>
                <Button className={classes.btnStyle} onClick={() => handleChangeAddAdminDialog(true)} disableRipple>
                    관리자 추가
                </Button>
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography className={classes.textStyle}>
                    관리자는
                    <span className={classes.spanStyle} onClick={handleMoveToMemberAuthorityPage}>
                        멤버 권한 설정
                    </span>
                    에서 지정할 수 있습니다.
                </Typography>
                <FormControl className={classes.formControl}>
                    <Select
                        value={sort}
                        onChange={handleChangeSort}
                        displayEmpty
                        IconComponent={() => (
                            <Box
                                style={{
                                    width: 16,
                                    height: 16,
                                    marginLeft: 5,
                                }}
                            >
                                <ArrowDownIcon />{' '}
                            </Box>
                        )}
                    >
                        <MenuItem value={SORT_BY.name} className={classes.menuItem}>
                            이름순
                        </MenuItem>
                        <MenuItem value={SORT_BY.updateDatetime} className={classes.menuItem}>
                            요청 최근순
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Box className={classes.listTopBorder}>
                {sortedOperators.map(admin => {
                    return (
                        <>
                            <Box
                                key={`${admin.userId}_${admin.name}`}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                className={classes.listBox}
                            >
                                <Box display="flex" alignItems="center">
                                    <Avatar src={TestAvatar} alt="member image" className={classes.avatar} />
                                    <Box display="flex" alignItems="center">
                                        <Typography className={classes.nameText}>{admin.name}</Typography>
                                        {admin.type === LmsGroupUserType.leader && <Typography className={classes.boxStyle}>주최자</Typography>}
                                    </Box>
                                </Box>
                                {admin.type === LmsGroupUserType.operator && (
                                    <Box display="flex" alignItems="center">
                                        <Button
                                            className={classes.buttonStyle}
                                            onClick={() => handleChangeAdminDeleteDialog(true, admin)}
                                            disableRipple
                                        >
                                            제외
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </>
                    );
                })}

                {/*<Box display="flex" justifyContent="space-between" alignItems="center" className={classes.listBox}>*/}
                {/*    <Box display="flex" alignItems="center">*/}
                {/*        <Avatar src={TestAvatar} alt="member image" className={classes.avatar} />*/}
                {/*        <Box display="flex" alignItems="center">*/}
                {/*            <Typography className={classes.nameText}>배수지</Typography>*/}
                {/*            <Typography className={classes.boxStyle}>이동욱</Typography>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}

                {/*    <Box display="flex" alignItems="center">*/}
                {/*        <Button className={classes.buttonStyle} onClick={() => handleChangeAdminDeleteDialog(true)} disableRipple>*/}
                {/*            제외*/}
                {/*        </Button>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
            </Box>
            {/* dialog */}
            <AddAdminDialogComponent
                addAdminDialogOpen={addAdminDialogOpen}
                handleCloseAddAdminDialog={() => handleChangeAddAdminDialog(false)}
                members={props.groupMembers}
                addAdmin={props.addAdmin}
            />
            <AdminDeleteDialogComponent
                adminDeleteDialogOpen={adminDeleteDialogOpen}
                handleCloseAddAdminDialog={() => handleChangeAdminDeleteDialog(false)}
                exceptAdmin={handleExceptAdmin}
            />
        </>
    );
}

export default MobileAddAdminComponent;
