import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Stack } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    btnStyle: {
        marginLeft: 10,
        '&:hover': {
            background: 'transparent',
        },
    },
}));
function CustomBreadcrumbs(props) {
    const classes = useStyles();
    const breadcrumbs =
        props.data && props.data.length > 0
            ? props.data.map(d => {
                  return (
                      <Button className={classes.btnStyle} key={d.id} onClick={() => d.func(d.id)}>
                          {d.name}
                      </Button>
                  );
              })
            : [];

    return (
        // <>
        <Stack direction={'row'} spacing={2}>
            <Breadcrumbs hidden={!props.data || !props.data.length} separator={<NavigateNextIcon fontSize={'small'} />} aria-label={'breadcrumbs'}>
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
        // </>
    );
}

export default CustomBreadcrumbs;
