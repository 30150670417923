import AuthStore from './AuthStore';
import OrgStore from './OrgStore';
import LocaleStore from './LocaleStore';
import ClassStore from './ClassStore';
import RoomStore from './RoomStore';
import UserStore from './UserStore';
import AvatarStore from './AvatarStore';
import OrganizationStore from './OrganizationStore';
import ClassFileStore from './ClassFileStore';
import ClassTeamStore from './ClassTeamStore';
import PostStore from './PostStore';
import CommentStore from './CommentStore';
import ManagementStore from './ManagementStore';
import PaginationStore from './PaginationStore';
import SearchStore from './SearchStore';
import NeoPenStore from './NeoPenStore';
import NoticeStore from './NoticeStore';
import ExcelStore from './ExcelStore';
import StatisticsStore from './StatisticsStore';
import TopBarStore from './TopBarStore';
import NeoPenStatisticsStore from './NeoPenStatisticsStore';
import TooltipStore from './TooltipStore';
import CommonServiceStore from './CommonServiceStore';

export default function configureStore(serverContextPath) {
    return {
        authStore: new AuthStore(serverContextPath),
        orgStore: new OrgStore(serverContextPath),
        localeStore: new LocaleStore(serverContextPath),
        classStore: new ClassStore(serverContextPath, new AvatarStore(serverContextPath)),
        roomStore: new RoomStore(serverContextPath),
        userStore: new UserStore(serverContextPath),
        avatarStore: new AvatarStore(serverContextPath),
        organizationStore: new OrganizationStore(serverContextPath),
        classFileStore: new ClassFileStore(serverContextPath),
        classTeamStore: new ClassTeamStore(serverContextPath),
        commentStore: new CommentStore(serverContextPath),
        postStore: new PostStore(serverContextPath),
        managementStore: new ManagementStore(serverContextPath, new AvatarStore(serverContextPath)),
        paginationStore: new PaginationStore(),
        searchStore: new SearchStore(),
        noticeStore: new NoticeStore(serverContextPath),
        neoPenStore: new NeoPenStore(serverContextPath),
        neoPenStatisticsStore: new NeoPenStatisticsStore(serverContextPath),
        excelStore: new ExcelStore(serverContextPath),
        statisticsStore: new StatisticsStore(serverContextPath),
        topBarStore: new TopBarStore(),
        tooltipStore: new TooltipStore(),
        commonServiceStore: new CommonServiceStore(serverContextPath),
    };
}
