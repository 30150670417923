import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Box, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { DATE_UTIL } from '../../common/util/date.util';
import { AM_PM } from './InvitationCalendarBox';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormGroup-root': {
            flexDirection: 'row',
        },
        '& .MuiFormControlLabel-root': {
            width: '45%',
        },
        '& .MuiIconButton-root': {
            width: 35,
        },
        '& .MuiFormControlLabel-label': {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#1f1f1f',
            marginTop: 8,
        },
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        fontSize: 14,
        fontWeight: 600,
    },
    formControlBox: {
        width: '100%',
        '& .MuiOutlinedInput-input': {
            padding: '12px 14px',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        },
    },
    selectBox: {
        width: '100%',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        borderRadius: 4,
        '& :focus': {
            outline: 'none',
        },
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#303030',
    },

    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
    '@media all and (max-width: 600px)': {
        root: {
            '& .MuiFormControlLabel-root': {
                width: '45%',
            },
            '& .MuiIconButton-root': {
                width: 20,
                padding: 0,
                marginTop: 8,
            },
            '& .MuiFormControlLabel-label': {
                fontSize: '0.813rem',
                marginTop: 8,
            },
        },
        formControlBox: {
            paddingTop: 8,
            '& .MuiOutlinedInput-input': {
                padding: '5px 5px',
            },
        },
        selectBox: {
            fontSize: '0.813rem',
            '& .MuiSelect-iconOutlined': {
                right: 0,
            },
        },
        optionStyle: {
            fontSize: '0.813rem',
        },
        icon: {
            borderRadius: '50%',
            width: 13,
            height: 13,
        },
        checkedIcon: {
            '&:before': {
                width: 13,
                height: 13,
            },
        },
        titleStyle: {
            fontSize: '0.813rem',
        },
    },
    '@media all and (max-width: 365px)': {
        root: {
            '& .MuiFormControlLabel-root': {
                width: '100%',
            },
        },
        selectBox: {
            '& .MuiSelect-iconOutlined': {
                top: 2,
            },
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
}));

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

export default function ExpireTime(props) {
    const intl = useIntl();
    const classes = useStyles();
    const localMin = () => {
        let min = parseInt(DATE_UTIL.getTimeZoneDate(dayjs(), 'mm'));
        if (min % 5 > 0) {
            min = min - (min % 5);
        }
        let stringToMinute = String(min);
        if (stringToMinute.length === 1) {
            stringToMinute = '0'.concat(stringToMinute);
        }
        return stringToMinute;
    };
    const [time, setTime] = React.useState(DATE_UTIL.getTimeZoneDate(dayjs(), 'hh'));
    const [minute, setMinute] = React.useState('');
    const [radio, setRadio] = React.useState(DATE_UTIL.getTimeZoneDate(dayjs(), 'HH') >= 12 ? 'PM' : 'AM');

    const changeStartTime = props.onChangeStartTime;

    useEffect(() => {
        setMinute(localMin);
    }, []);

    useEffect(() => {
        changeStartTime(time, minute, radio);
    }, [time, minute, radio, changeStartTime]);

    return (
        <div className={classes.root}>
            <Typography className={classes.titleStyle}>만료 시각</Typography>

            <Box display="flex" alignItems="center">
                <FormControl variant={'outlined'} className={classes.formControlBox}>
                    <Select
                        value={time}
                        onChange={event => {
                            setTime(event.target.value);
                        }}
                        className={classes.selectBox}
                    >
                        <MenuItem value="시간" className={classes.optionStyle}>
                            {intl.formatMessage({ id: 'hour' })}
                        </MenuItem>
                        <MenuItem value="01" className={classes.optionStyle}>
                            1
                        </MenuItem>
                        <MenuItem value="02" className={classes.optionStyle}>
                            2
                        </MenuItem>
                        <MenuItem value="03" className={classes.optionStyle}>
                            3
                        </MenuItem>
                        <MenuItem value="04" className={classes.optionStyle}>
                            4
                        </MenuItem>
                        <MenuItem value="05" className={classes.optionStyle}>
                            5
                        </MenuItem>
                        <MenuItem value="06" className={classes.optionStyle}>
                            6
                        </MenuItem>
                        <MenuItem value="07" className={classes.optionStyle}>
                            7
                        </MenuItem>
                        <MenuItem value="08" className={classes.optionStyle}>
                            8
                        </MenuItem>
                        <MenuItem value="09" className={classes.optionStyle}>
                            9
                        </MenuItem>
                        <MenuItem value="10" className={classes.optionStyle}>
                            10
                        </MenuItem>
                        <MenuItem value="11" className={classes.optionStyle}>
                            11
                        </MenuItem>
                        <MenuItem value="12" className={classes.optionStyle}>
                            12
                        </MenuItem>
                    </Select>
                </FormControl>
                <Typography style={{ paddingLeft: 10, paddingRight: 10 }}> : </Typography>
                <FormControl variant={'outlined'} className={classes.formControlBox}>
                    <Select
                        value={minute}
                        onChange={event => {
                            setMinute(event.target.value);
                        }}
                        className={classes.selectBox}
                    >
                        <MenuItem value="분" className={classes.optionStyle}>
                            {intl.formatMessage({ id: 'minute' })}
                        </MenuItem>
                        <MenuItem value="00" className={classes.optionStyle}>
                            00
                        </MenuItem>
                        <MenuItem value="05" className={classes.optionStyle}>
                            05
                        </MenuItem>
                        <MenuItem value="10" className={classes.optionStyle}>
                            10
                        </MenuItem>
                        <MenuItem value="15" className={classes.optionStyle}>
                            15
                        </MenuItem>
                        <MenuItem value="20" className={classes.optionStyle}>
                            20
                        </MenuItem>
                        <MenuItem value="25" className={classes.optionStyle}>
                            25
                        </MenuItem>
                        <MenuItem value="30" className={classes.optionStyle}>
                            30
                        </MenuItem>
                        <MenuItem value="35" className={classes.optionStyle}>
                            35
                        </MenuItem>
                        <MenuItem value="40" className={classes.optionStyle}>
                            40
                        </MenuItem>
                        <MenuItem value="45" className={classes.optionStyle}>
                            45
                        </MenuItem>
                        <MenuItem value="50" className={classes.optionStyle}>
                            50
                        </MenuItem>
                        <MenuItem value="55" className={classes.optionStyle}>
                            55
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <FormControl component="div" style={{ display: 'flex', alignItems: 'center', marginLeft: 10, width: '100%' }}>
                <RadioGroup
                    value={radio}
                    aria-label="time"
                    name="customized-radios"
                    onChange={event => {
                        setRadio(event.target.value);
                    }}
                    style={{ width: '100%', display: 'flex', alignItems: 'center' }}
                >
                    <FormControlLabel value={AM_PM.AM} control={<StyledRadio />} label={intl.formatMessage({ id: 'am' })} />
                    <FormControlLabel value={AM_PM.PM} control={<StyledRadio />} label={intl.formatMessage({ id: 'pm' })} />
                </RadioGroup>
            </FormControl>
        </div>
    );
}
