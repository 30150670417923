import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from '@nivo/core';
import { inject, observer } from 'mobx-react';
import { ReactComponent as ProhibitIcon } from '../../../common/images/ProhibitIcon.svg';
import { FormattedMessage } from 'react-intl';

const style = theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: window.innerHeight / 3,
        '@media all and (min-width: 1400px)': {
            height: window.innerHeight / 1.8,
        },
    },
    chartBox: {
        position: 'absolute',
        right: 0,
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        fontWeight: 600,
        color: '#5a6069',
    },
    textStyle1: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        fontWeight: 300,
        color: '#5a6069',
    },
    circleStyle1: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        background: '#0047ac',
        content: '',
        marginRight: 5,
    },
    circleStyle2: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        background: '#0076de',
        content: '',
        marginRight: 5,
    },
    circleStyle3: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        background: '#b7defe',
        content: '',
        marginRight: 5,
    },
    imageText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 18,
        fontWeight: 800,
        color: '#0d0d0d',
    },
    noDataBox: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate( -50%, -50% )',
    },
});

class DeviceChart extends Component {
    render() {
        const { classes, data, statisticsStore } = this.props;

        const CustomBottomTick = tick => {
            const theme = useTheme();
            const TEXT_MAX_LENGTH = 6;
            const getShortenedText = (length, text) => (text.length > length ? text.substr(0, TEXT_MAX_LENGTH) + '...' : text);

            return (
                <g transform={`translate(${tick.x},${tick.y + 22})`}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            ...theme.axis.ticks.text,
                            fill: '#7b8288',
                            fontSize: '1rem',
                            fontFamily: 'NanumSquareRoundOTF',
                            fontWeight: 600,
                        }}
                    >
                        {getShortenedText(TEXT_MAX_LENGTH, tick.value)}
                    </text>
                </g>
            );
        };

        const CustomLeftTick = tick => {
            const theme = useTheme();
            return (
                <g transform={`translate(${tick.x - 22},${tick.y})`}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            ...theme.axis.ticks.text,
                            fill: '#7b8288',
                            fontSize: '1rem',
                            fontFamily: 'NanumSquareRoundOTF',
                            fontWeight: 600,
                        }}
                    >
                        {tick.value}
                    </text>
                </g>
            );
        };

        const TotalLabels = ({ bars, yScale }) => {
            const labelMargin = 20;
            return bars.map(({ data: { data, indexValue }, x, width }, i) => {
                const yScaleStandard = data.count;
                return (
                    <g transform={`translate(${x}, ${yScale(yScaleStandard) - labelMargin})`} key={`${indexValue}-${i}`}>
                        <text
                            // add any class to the label here
                            className="bar-total-label"
                            x={width / 2}
                            y={labelMargin / 2}
                            textAnchor="middle"
                            alignmentBaseline="central"
                            // add any style to the label here
                            style={{
                                fill: '#0047ac',
                                fontSize: '1rem',
                                fontFamily: 'NanumSquareRoundOTF',
                                fontWeight: 600,
                            }}
                        >
                            {data.count}
                            <FormattedMessage id="person" />
                        </text>
                    </g>
                );
            });
        };

        const MyResponsiveBar = ({ data }) => (
            <ResponsiveBar
                data={data}
                keys={['count']}
                indexBy="name"
                margin={{ top: 50, right: 40, bottom: 50, left: 80 }}
                padding={0.3}
                // groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                borderRadius={5}
                borderColor={{ from: 'color', modifiers: [['darker', '0.8']] }}
                colors={({ id, data }) => data.color}
                label={false}
                isInteractive={true}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                axisBottom={{
                    renderTick: CustomBottomTick,
                }}
                axisLeft={{
                    renderTick: CustomLeftTick,
                }}
                layers={['grid', 'axes', 'bars', TotalLabels, 'markers', 'legends']}
                tooltip={props => {
                    return (
                        <Typography style={{ fontFamily: 'NanumSquareRoundOTF', color: '#0047ac', fontSize: '1rem' }}>
                            <span style={{ fontWeight: 600 }}>{props.data.users}</span>
                        </Typography>
                    );
                }}
            />
        );

        return (
            <div className={classes.root}>
                {data.length === 0 && !statisticsStore.getIsLoading && (
                    <div style={{ position: 'relative', width: '100%' }}>
                        <img style={{ width: '100%', height: '100%' }} src="/images/NoData_Device.png" alt="No Data" />
                        <div className={classes.noDataBox}>
                            <ProhibitIcon />
                            <Typography className={classes.imageText}>
                                <FormattedMessage id="msg.no_display_data" />
                            </Typography>
                        </div>
                    </div>
                )}
                {data.length > 0 && MyResponsiveBar({ data: data })}
                {data.length > 0 && (
                    <Box className={classes.chartBox}>
                        <Box display="flex" alignItems="center" style={{ marginBottom: 5 }}>
                            <div className={classes.circleStyle1} />
                            <Typography className={classes.textStyle1}>
                                <FormattedMessage id="desktop" />
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" style={{ marginBottom: 5 }}>
                            <div className={classes.circleStyle2} />
                            <Typography className={classes.textStyle1}>
                                <FormattedMessage id="android" />
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" style={{ marginBottom: 5 }}>
                            <div className={classes.circleStyle3} />
                            <Typography className={classes.textStyle1}>ios</Typography>
                        </Box>
                    </Box>
                )}
            </div>
        );
    }
}

export default withStyles(style)(inject('statisticsStore')(observer(DeviceChart)));
