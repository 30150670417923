import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { Box, Button, FormControl, IconButton, Menu, MenuItem, Select, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as FolderPlusFillIcon } from '../../common/images/FolderPlusFillIcon.svg';
import { ReactComponent as ChartBarPurpleIcon } from '../../common/images/ChartBarPurpleIcon.svg';
import clsx from 'clsx';
import { ReactComponent as CheckCircleAgreeOffIcon } from '../../common/images/CheckCircleAgreeOffIcon.svg';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../common/images/CheckCircleAgreeOnIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { ReactComponent as NotePencilIcon } from '../../common/images/NotePencilIcon.svg';
import { ReactComponent as MoreIcon } from '../../common/images/DotsThreeOutlineVerticalIcon.svg';
import RecordInfoComponent from './RecordInfoComponent';
import { PATH_UTIL } from '../../common/util/path.util';
import { ReactComponent as PencilIcon } from '../../common/images/PencilIcon.svg';
import { ReactComponent as PlayIcon } from '../../common/images/PlayIcon.svg';
import { withRouter } from '../../components/WithRouter';
import { ReactComponent as FolderNotchIcon } from '../../common/images/FolderNotchIcon.svg';
import MoreButton from '../../components/common/MoreButton';
import {MovingObjectType, PaperGroupType} from '../../stores/NeoPenStore';
import { Skeleton } from '@material-ui/lab';
import PaperGroupFolderBreadcrumbs from '../neoPen/PaperGroupFolderBreadcrumbs';
import { ReactComponent as ArrowsClockwise } from '../../common/images/ArrowsClockwise.svg';
import { NOTE_CORRECTION_HANDLE_OPERATION } from '../../stores/ClassStore';

const styles = theme => ({
    root: {},
    optionBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    btnDefault: {
        padding: 0,
        marginBottom: 4,
        marginRight: 20,
    },
    btnStylePurple: {
        border: '1px solid #664cff',
        borderRadius: 7,
        marginLeft: 10,
        color: '#664cff',
        padding: '4px 8px',
        fontWeight: 600,
        fontSize: '0.938rem',
        letterSpacing: -0.14,
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 4,
        },
    },
    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 0px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    tableBtnStyle: {
        width: '100%',
        '& span': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& p': {
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                textAlign: 'left',
                whiteSpace: 'nowrap',
            },
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    checkBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#a9adb4',
        fontSize: '0.875rem',
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 4,
        },
    },
    checkBoxStyleOn: {
        color: '#0097FF',
    },
    formControl: {
        minWidth: 100,
        width: 100,
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontSize: '0.75rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 26px',
        boxSizing: 'border-box',
    },
    listItemOpenStyle: {
        borderRadius: 12,
        boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
    },
    listItemOpenInStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '17px 0 17px 26px',
        background: '#f8f8f8',
        borderRadius: '12px 12px 0 0',
        '& .more-icon': {
            fill: '#000',
        },
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flexGrow: {
        width: 'calc(100% - 50px)',
    },
    avatar: {
        width: 40,
        height: 40,
        backgroundColor: '#a3a8af',
        marginRight: 10,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    listItemTitle: {
        maxWidth: 'calc(100% - 43px)',
        fontSize: '0.875rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        whiteSpace: 'nowrap',
    },
    listItemDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    tagRedStyle: {
        fontSize: '0.625rem',
        color: '#fff',
        backgroundColor: '#fb4a59',
        padding: '3px 4px',
        fontWeight: 700,
        marginLeft: 7,
    },
    tagGreenStyle: {
        backgroundColor: '#00c880',
    },
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
    iconButton: {
        '&:hover': {
            background: 'transparent',
        },
        '& path': {
            stroke: '#6C6C70',
        },
    },
    btnStyle: {
        border: '1px solid #bfbfbf',
        borderRadius: 4,
        marginLeft: 10,
        color: '#000',
        padding: '1px 8px',
        fontWeight: 400,
        fontSize: '0.875rem',
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 4,
            width: 20,
            height: 20,
            '& .neo-pen, & path': {
                fill: '#000',
            },
        },
    },
    tableBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '1px solid #d3d7db',
        height: 80,
        '& td': {
            borderBottom: 0,
            padding: 0,
        },
    },
    folderBox: {
        width: 'calc(100% - 56px - 46px)',
    },
    emptyText: {
        fontSize: '0.875rem',
        color: '#000',
        marginTop: 30,
        textAlign: 'center',
    },
    breadcrumbsBtnStyle: {
        '&:hover': {
            background: 'transparent',
        },
    },
});

const LightTooltip = withStyles(_theme => ({
    tooltip: {
        padding: '4px 4px',
        border: '0.3px solid #000000',
        background: '#fffff5',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        color: '#000',
        borderRadius: '0',
        marginLeft: 5,
        marginTop: 5,
    },
}))(Tooltip);

const RESPONSE_KEY_CODE = {
    ESC: 27,
    ENTER: 13,
};

class RecordComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorGeneral: null,
            isFiltered: false,
            writeDataFilter: 'date',
            listOpenedPaperGroup: undefined,

            renamingFolderId: undefined,
            renamingFolderName: '',

            renamingPaperGroupId: undefined,
            renamingPaperGroupName: '',
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isLeader) {
            if (prevState.listOpenedPaperGroup !== this.state.listOpenedPaperGroup && this.state.listOpenedPaperGroup) {
                const groupId = PATH_UTIL.getClassId(this.props.location.pathname);
                const { id } = this.state.listOpenedPaperGroup;
                this.props.classStore.requestGetPaperGroupCorrections(groupId, id);
            }
        }

        if (prevState.isFiltered !== this.state.isFiltered) {
            const nPS = this.props.neoPenStore;
            const groupId = PATH_UTIL.getClassId(this.props.location.pathname);
            const { loginUser } = this.props.authStore;
            if (this.state.isFiltered) {
                nPS.getPaperGroupsWithCorrection(groupId, loginUser.email);
            } else {
                nPS.initPaperGroupsWithCorrection();
            }
        }
    }

    handleChangeCheckBox = () => {
        this.setState({ isFiltered: !this.state.isFiltered, listOpenedPaperGroup: undefined });
    };

    handleChangeDataSort = event => {
        this.setState({ writeDataFilter: event.target.value });

        this.props.neoPenStore.sortFolder(event.target.value);
        this.props.neoPenStore.sortPaperGroupByModifiedAt(event.target.value);
    };

    handleClickListOpen = paperGroup => {
        console.log('handleClickListOpen : ', paperGroup);
        const isLeader = this.props.isLeader;

        if (isLeader) {
            if (this.state.listOpenedPaperGroup === paperGroup) {
                this.setState({ listOpenedPaperGroup: undefined });

                return;
            }

            this.setState({ listOpenedPaperGroup: paperGroup });
        }
    };

    handleClickGeneral = event => {
        this.setState({ anchorGeneral: event.currentTarget });
    };

    handleClose = () => {
        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
        });
    };

    handleClickWritingBoard = e => {
        const groupId = PATH_UTIL.getClassId(this.props.location.pathname);

        window.open('/writingBoard/' + groupId);
    };

    handleMoveFolderDialog = () => {
        const nPS = this.props.neoPenStore;

        nPS.setSelectFolderDialogOpened(true, MovingObjectType.PaperGroup, nPS.selectedPaperGroup.id);
    };

    handleRenameFolder = event => {
        if (event.keyCode === RESPONSE_KEY_CODE.ESC) {
            this.setState({ renamingFolderId: undefined, renamingFolderName: '' });
        } else if (event.keyCode === RESPONSE_KEY_CODE.ENTER && this.state.renamingFolderName.length > 0) {
            const nPS = this.props.neoPenStore;

            nPS.renameFolder(this.state.renamingFolderId, this.state.renamingFolderName);
            this.setState({ renamingFolderId: undefined, renamingFolderName: '' });
        }
    };

    handleRenamePaperGroup = (event, paperGroup) => {
        if (event.keyCode === RESPONSE_KEY_CODE.ESC) {
            this.setState({ renamingPaperGroupId: undefined, renamingPaperGroupName: '' });
        } else if (event.keyCode === RESPONSE_KEY_CODE.ENTER && this.state.renamingPaperGroupName.length > 0) {
            const nPS = this.props.neoPenStore;

            nPS.renamePaperGroup(paperGroup, this.state.renamingPaperGroupName);
            this.setState({ renamingPaperGroupId: undefined, renamingPaperGroupName: '' });
        }
    };

    filteredWithCorrectionPaperGroups = paper => {
        const nPS = this.props.neoPenStore;
        if (!this.state.isFiltered) return paper;
        if (!nPS.paperGroupsWithCorrection.length) return paper;

        const findPaperIndex = nPS.paperGroupsWithCorrection.findIndex(pgWithCorrection => pgWithCorrection.id === paper.id);
        if (findPaperIndex >= 0) {
            return paper;
        }
    };

    componentDidMount() {
        this.props.neoPenStore.sortFolder(this.state.writeDataFilter);
        if (!this.props.isLeader) {
            const groupId = PATH_UTIL.getClassId(this.props.location.pathname);
            const { loginUser } = this.props.authStore;
            this.props.classStore.requestGetPaperGroupCorrectionsForUser(groupId, loginUser.email);
        }
    }

    componentWillUnmount() {
        if (this.props.classStore.paperGroupCorrections.length) {
            this.props.classStore.initPaperGroupCorrections();
        }
    }

    render() {
        const { classes, isLeader } = this.props;
        const { isUploading } = this.props.classFileStore;
        const { anchorGeneral } = this.state;

        const nPS = this.props.neoPenStore;
        const cs = this.props.classStore;
        const { loginUser } = this.props.authStore;

        const viewingPaperGroups = nPS.paperGroups
            .filter(paperGroup => paperGroup.folderId === (nPS.currentFolder === undefined ? null : nPS.currentFolder.id))
            .filter(this.filteredWithCorrectionPaperGroups)
            .filter(paperGroup => paperGroup.type === PaperGroupType.Study);
        let viewingDataCnt = 0;

        console.log("viewingPaperGroups", viewingPaperGroups);

        return (
            <div className={classes.root}>
                <Box className={classes.optionBox}>
                    {isLeader && (
                        <Box display="flex" alignItems="center">
                            <LightTooltip title={'실시간 필기 기록이 있다면 눌러서 새로고침'} placement="bottom">
                                <IconButton
                                    className={classes.iconButton}
                                    disableRipple
                                    onClick={() => nPS.getUserNotes(nPS.groupUsers, nPS.isLeader)}
                                >
                                    <ArrowsClockwise />
                                </IconButton>
                            </LightTooltip>
                            <Button
                                component={'label'}
                                className={classes.btnStylePurple}
                                disabled={isUploading}
                                disableRipple
                                onClick={this.handleClickWritingBoard}
                            >
                                <ChartBarPurpleIcon />
                                학생 필기 현황
                            </Button>
                        </Box>
                    )}
                </Box>

                <Box className={classes.filterStyle} style={{ paddingBottom: 0 }}>
                    <PaperGroupFolderBreadcrumbs />

                    <Box display="flex" alignItems="center">
                        {/*{!isLeader && (*/}
                        {/*    <Box*/}
                        {/*        onClick={this.handleChangeCheckBox}*/}
                        {/*        className={!this.state.isFiltered ? classes.checkBoxStyle : clsx(classes.checkBoxStyle, classes.checkBoxStyleOn)}*/}
                        {/*        style={{ marginRight: 20 }}*/}
                        {/*    >*/}
                        {/*        {!this.state.isFiltered ? <CheckCircleAgreeOffIcon /> : <CheckCircleAgreeOnIcon />}*/}
                        {/*        선생님이 첨삭한 필기만*/}
                        {/*    </Box>*/}
                        {/*)}*/}
                        {/*{isLeader && (*/}
                        {/*    <Box*/}
                        {/*        onClick={this.handleChangeCheckBox}*/}
                        {/*        className={!this.state.isFiltered ? classes.checkBoxStyle : clsx(classes.checkBoxStyle, classes.checkBoxStyleOn)}*/}
                        {/*        style={{ marginRight: 20 }}*/}
                        {/*    >*/}
                        {/*        {!this.state.isFiltered ? <CheckCircleAgreeOffIcon /> : <CheckCircleAgreeOnIcon />}*/}
                        {/*        첨삭한 필기만*/}
                        {/*    </Box>*/}
                        {/*)}*/}
                        <FormControl className={classes.formControl}>
                            <Select
                                value={this.state.writeDataFilter}
                                displayEmpty
                                onChange={this.handleChangeDataSort}
                                IconComponent={() => (
                                    <Box style={{ width: 16, height: 16, marginLeft: 5 }}>
                                        <ArrowDownIcon />
                                    </Box>
                                )}
                            >
                                <MenuItem value="date" className={classes.menuItem}>
                                    최근 작성일 순
                                </MenuItem>
                                <MenuItem value="alpha" className={classes.menuItem}>
                                    가나다 순
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>

                <Box>
                    {nPS.currentFolder !== undefined &&
                        nPS.currentFolder.childIds.map(folderId => {
                            if(nPS.folders[folderId].type !== PaperGroupType.Study)
                                return <Box/>;

                            return (
                                <Box style={{ width: '100%' }}>
                                    <TableRow className={classes.tableBox}>
                                        <TableCell align="center" style={{ width: 56 }}>
                                            <FolderNotchIcon />
                                        </TableCell>
                                        <TableCell className={classes.folderBox}>
                                            {this.state.renamingFolderId === folderId ? (
                                                <div>
                                                    <input
                                                        type="text"
                                                        autoFocus
                                                        className={classes.textField}
                                                        onChange={e => this.setState({ renamingFolderName: e.target.value })}
                                                        onKeyDown={e => this.handleRenameFolder(e)}
                                                        value={this.state.renamingFolderName}
                                                    />
                                                </div>
                                            ) : (
                                                <Button
                                                    className={classes.tableBtnStyle}
                                                    disableRipple
                                                    onClick={() => nPS.selectFolder(nPS.folders[folderId])}
                                                >
                                                    <Typography>{nPS.folders[folderId].name}</Typography>
                                                    <Typography variant="body2" className={classes.listItemDate}>
                                                        {nPS.folders[folderId].createdDatetime.split('T')[0]}
                                                    </Typography>
                                                </Button>
                                            )}
                                        </TableCell>
                                        <TableCell align="center">
                                            {isLeader && (
                                                <MoreButton
                                                    menuItems={[
                                                        {
                                                            name: '이동',
                                                            menuFunc: () => nPS.setSelectFolderDialogOpened(true, MovingObjectType.Folder, folderId),
                                                        },
                                                        {
                                                            name: '이름 바꾸기',
                                                            menuFunc: () => {
                                                                this.handleClose();
                                                                this.setState({
                                                                    renamingFolderId: folderId,
                                                                    renamingFolderName: nPS.folders[folderId].name,
                                                                });
                                                            },
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </Box>
                            );
                        })}

                    {viewingPaperGroups.map((paperGroup, i) => {
                        const currentPaperGroup = paperGroup;
                        const isSelected = this.state.listOpenedPaperGroup === paperGroup && isLeader;
                        let hasData = nPS.userNotes[paperGroup.paperGroupId] !== undefined;

                        let hasDataMemberCnt = 0;
                        if (hasData) {
                            hasDataMemberCnt = nPS.userNotes[paperGroup.paperGroupId].length;
                            viewingDataCnt += 1;
                        }

                        // let isModified = false;
                        //
                        // if (nPS.userNotes[paperGroup.paperGroupId] !== undefined) {
                        //     for (const n of nPS.userNotes[paperGroup.paperGroupId]) {
                        //         if (n.note !== undefined && n.note.modifiedIds !== undefined && n.note.modifiedIds.length >= 2) {
                        //             isModified = true;
                        //         }
                        //     }
                        // }

                        const latestCorrection = cs.getLatestCorrection(paperGroup, loginUser.id);

                        return (
                            <Box key={i} className={isSelected ? classes.listItemOpenStyle : ''}>
                                {hasData && (!this.state.isFiltered || (this.state.isFiltered && latestCorrection)) && (
                                    <Box className={isSelected ? classes.listItemOpenInStyle : classes.listItemStyle}>
                                        <Box
                                            className={clsx(classes.flexCenter, classes.widthBox)}
                                            style={
                                                isLeader
                                                    ? { cursor: 'pointer', width: 'calc(100% - 46px)' }
                                                    : { cursor: 'pointer', width: 'calc(100% - 217px)' }
                                            }
                                            onClick={() => this.handleClickListOpen(paperGroup)}
                                        >
                                            <Box className={classes.avatar}>
                                                <NotePencilIcon />
                                            </Box>

                                            {this.state.renamingPaperGroupId === paperGroup.id ? (
                                                <div>
                                                    <input
                                                        type="text"
                                                        autoFocus
                                                        className={classes.textField}
                                                        onChange={e => this.setState({ renamingPaperGroupName: e.target.value })}
                                                        onKeyDown={e => this.handleRenamePaperGroup(e, paperGroup)}
                                                        // onBlur={this.handleInitCreateFolder}
                                                        value={this.state.renamingPaperGroupName}
                                                    />
                                                </div>
                                            ) : (
                                                <Box className={classes.flexGrow}>
                                                    <Box display="flex" alignItems="center" flexDirection="row" style={{ width: '100%' }}>
                                                        <LightTooltip title={paperGroup.title.trim().replace(/(.pdf)$/, '')} placement="bottom-start">
                                                            <Typography variant="h5" className={classes.listItemTitle}>
                                                                {paperGroup.title.trim().replace(/(.pdf)$/, '')}
                                                            </Typography>
                                                        </LightTooltip>
                                                        {/*{!isLeader && isModified && <span className={classes.tagRedStyle}>첨삭됨</span>}*/}
                                                        {!isLeader && latestCorrection && (
                                                            <span
                                                                className={
                                                                    latestCorrection.state.toLowerCase() ===
                                                                    NOTE_CORRECTION_HANDLE_OPERATION.Upload.toLowerCase()
                                                                        ? classes.tagRedStyle
                                                                        : clsx(classes.tagRedStyle, classes.tagGreenStyle)
                                                                }
                                                            >
                                                                첨삭됨
                                                            </span>
                                                        )}
                                                    </Box>
                                                    <Typography variant="body2" className={classes.listItemDate}>
                                                        {paperGroup.lastStrokeAt === undefined
                                                            ? ''
                                                            : `${nPS.convertTimestamp(paperGroup.lastStrokeAt).split(',')[0]}`}
                                                        {isLeader ? ` | ${hasDataMemberCnt}명 작성` : ''}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>

                                        {!isLeader && (
                                            <Box display="flex" alignItems="center">
                                                <Button
                                                    className={classes.btnStyle}
                                                    onClick={() => {
                                                        const groupId = PATH_UTIL.getClassId(this.props.location.pathname);

                                                        window.open(`/penview/${groupId}/${nPS.user.email}/${paperGroup.paperGroupId}`);
                                                    }}
                                                    disableRipple
                                                >
                                                    <PencilIcon /> 필기 확인
                                                </Button>
                                                <Button
                                                    className={classes.btnStyle}
                                                    onClick={() => {
                                                        const groupId = PATH_UTIL.getClassId(this.props.location.pathname);

                                                        window.open(`/penrecordview/${groupId}/${nPS.user.email}/${paperGroup.paperGroupId}`);
                                                    }}
                                                    disableRipple
                                                >
                                                    <PlayIcon /> 필기 재생
                                                </Button>
                                            </Box>
                                        )}

                                        {isLeader && (
                                            <MoreButton
                                                menuItems={[
                                                    {
                                                        name: '이동',
                                                        menuFunc: () => {
                                                            this.handleClose();
                                                            nPS.selectPaperGroup(currentPaperGroup);
                                                            this.handleMoveFolderDialog();
                                                        },
                                                    },
                                                    {
                                                        name: '이름 바꾸기',
                                                        menuFunc: () => {
                                                            nPS.selectPaperGroup(currentPaperGroup);
                                                            this.setState({
                                                                renamingPaperGroupId: currentPaperGroup.id,
                                                                renamingPaperGroupName: currentPaperGroup.title,
                                                            });
                                                            this.handleClose();
                                                        },
                                                    },
                                                ]}
                                            />
                                        )}
                                    </Box>
                                )}

                                {isSelected && <RecordInfoComponent isOpen={isSelected} selectedPaperGroup={currentPaperGroup} />}
                            </Box>
                        );
                    })}
                    {viewingDataCnt === 0 && (
                        <Box>
                            <Typography className={classes.emptyText}>등록된 필기 기록이 없습니다.</Typography>
                        </Box>
                    )}
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('neoPenStore', 'authStore', 'classStore', 'classFileStore')(observer(RecordComponent))));
