import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, CircularProgress, FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as CheckCircleAgreeOffIcon } from '../../common/images/CheckCircleAgreeOffIcon.svg';
import { ReactComponent as CheckCircleAgreeOnIcon } from '../../common/images/CheckCircleAgreeOnIcon.svg';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import { ReactComponent as UsersThreeIcon } from '../../common/images/UsersThreeIcon.svg';
import { DATE_UTIL } from '../../common/util/date.util';
import MoreButton from '../../components/common/MoreButton';
import { injectIntl } from 'react-intl';
import YesOrNoDialog from '../../components/common/YesOrNoDialog';
import { TEAM_OPERATION } from '../../stores/ClassTeamStore';
import ClassTeamInfoComponent from './ClassTeamInfoComponent';
import { SORT_TYPE } from '../../stores/PaginationStore';

const styles = _theme => ({
    root: {},
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
    },
    filterStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        margin: '30px 0 17px',
        paddingBottom: 9,
        cursor: 'pointer',
    },
    listStyle: {
        marginTop: 40,
        marginBottom: 60,
        '&:last-child': {
            marginBottom: 0,
        },
        '& h5': {
            borderBottom: '1px solid #d3d7db',
            fontSize: '0.875rem',
            fontWeight: 700,
            paddingBottom: 8,
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    listItemStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #d3d7db',
        padding: '17px 0 17px 26px',
        '&:first-child': {
            borderTop: '1px solid #d3d7db',
        },
    },
    checkBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#a9adb4',
        fontSize: '0.875rem',
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 4,
        },
    },
    checkBoxStyleOn: {
        color: '#0097FF',
    },
    avatar: {
        marginRight: 10,
        '& svg path': {
            fill: '#fff',
        },
    },
    name: {
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    groupInfo: {
        fontSize: '0.75rem',
        color: '#979797',
        marginTop: 7,
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
    },
    menuBox: {
        '& .MuiPopover-paper': {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: 7,
            border: '1.5px solid #d4d4d6',
        },
    },
    listItemOpenStyle: {
        borderRadius: 12,
        boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
    },
    listItemOpenInStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '17px 0 17px 26px',
        background: '#f8f8f8',
        borderRadius: '12px 12px 0 0',
        '& .more-icon': {
            fill: '#000',
        },
    },
});

class ClassMemberGroupManagementComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkBox: true,
            isFiltered: false,
            anchorElManager: null,
            anchorGeneral: null,
            isCheckDeleteDialogOpen: false,
            isOpenTeamInfo: false,
            selectedOpenTeamId: 0,
        };
    }

    componentDidMount() {
        this.props.changeSort(SORT_TYPE.name);
    }

    handleChangeSortAndFetchData = event => {
        if (this.state.isOpenTeamInfo) {
            this.handleCloseTeamInfo();
        }
        this.props.changeSortAndFetchData(event.target.value);
    };

    handleChangeCheckBox = () => {
        this.setState({ checkBox: !this.state.checkBox });
    };

    handleClickManager = event => {
        this.setState({ anchorElManager: event.currentTarget });
    };

    handleClickGeneral = event => {
        this.setState({ anchorGeneral: event.currentTarget });
    };

    handleClose = () => {
        this.setState({
            anchorElManager: null,
            anchorGeneral: null,
        });
    };

    handleClickModifyBtn = selectedTeam => {
        const { groupUsers } = this.props;
        const setNameTeamUsers = selectedTeam.teamUserList.map(tu => {
            const findUserInfo = groupUsers.find(lu => lu.userId === tu.userId);
            if (findUserInfo) {
                tu.name = findUserInfo.name;
            }
            return tu;
        });
        selectedTeam.teamUserList = setNameTeamUsers;
        this.props.setTeamInfo(selectedTeam);
        this.props.changeTeamOperation(TEAM_OPERATION.Modify);
        this.props.openModifyTeamDialog();
    };

    handleClickDeleteBtn = deleteTeam => {
        this.props.setTeamInfo(deleteTeam);
        this.props.changeTeamOperation(TEAM_OPERATION.Delete);
        this.setState({ isCheckDeleteDialogOpen: true });
    };

    handleSubmitDeleteTeam = () => {
        this.props.deleteTeam();
        this.setState({ isCheckDeleteDialogOpen: false });
    };

    handleCancelDeleteTeam = () => {
        this.props.initTeamStore();
        this.setState({ isCheckDeleteDialogOpen: false });
    };

    handleClickFilterBtn = () => {
        this.setState(prevState => ({ isFiltered: !prevState.isFiltered }));
    };

    compareTeams = (a, b) => {
        if (this.props.sort === SORT_TYPE.name) {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        } else if (this.props.sort === SORT_TYPE.createdDatetime) {
            if (a.createdDatetime < b.createdDatetime) {
                return 1;
            }
            if (a.createdDatetime > b.createdDatetime) {
                return -1;
            }

            return 0;
        }
    };

    handleOpenTeamInfo = selectedTeam => {
        this.setState(prevState => ({ isOpenTeamInfo: !prevState.isOpenTeamInfo, selectedOpenTeamId: selectedTeam.teamId }));
    };

    handleCloseTeamInfo = () => {
        this.setState({ isOpenTeamInfo: false, selectedOpenTeamId: 0 });
    };

    filteredTeamsExcludeMe = team => {
        const { loginUser } = this.props;
        if (this.state.isFiltered && team && team.teamUserList.length) {
            const findIndex = team.teamUserList.findIndex(user => user.userId === loginUser.id);
            if (findIndex !== -1) return team;
        } else {
            return team;
        }
    };

    render() {
        const { classes, groupTeams, intl, groupUserAuthority, isLeader, selectedTeam, forwardedRef } = this.props;
        const { anchorElManager, anchorGeneral } = this.state;
        const manager = Boolean(anchorElManager);
        const general = Boolean(anchorGeneral);
        const teams = groupTeams.filter(this.filteredTeamsExcludeMe);
        const hasAuthority = isLeader ? isLeader : groupUserAuthority.GroupMember;

        return (
            <div className={classes.root}>
                {this.props.isTeamLoading && !teams.length ? (
                    <Box style={{ textAlign: 'center', marginTop: 100, marginBottom: 1000 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box className={classes.filterStyle}>
                            <Box
                                className={!this.state.isFiltered ? classes.checkBoxStyle : clsx(classes.checkBoxStyle, classes.checkBoxStyleOn)}
                                onClick={this.handleClickFilterBtn}
                            >
                                {!this.state.isFiltered ? <CheckCircleAgreeOffIcon /> : <CheckCircleAgreeOnIcon />}
                                내가 포함된 그룹만 보기
                            </Box>
                            <Box>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        value={this.props.sort}
                                        onChange={this.handleChangeSortAndFetchData}
                                        displayEmpty
                                        IconComponent={() => (
                                            <Box style={{ width: 16, height: 16, marginLeft: 5 }}>
                                                <ArrowDownIcon />{' '}
                                            </Box>
                                        )}
                                    >
                                        <MenuItem value={SORT_TYPE.name} className={classes.menuItem}>
                                            이름순
                                        </MenuItem>
                                        <MenuItem value={SORT_TYPE.createdDatetime} className={classes.menuItem}>
                                            최근 생성일순
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>

                        {teams.length ? (
                            <>
                                <Box className={classes.listStyle}>
                                    {teams.map((team, index) => {
                                        const isLastTeam = teams.length === index + 1;
                                        return (
                                            <Box
                                                key={team.teamId}
                                                className={
                                                    this.state.isOpenTeamInfo && this.state.selectedOpenTeamId === team.teamId
                                                        ? classes.listItemOpenStyle
                                                        : ''
                                                }
                                            >
                                                <Box
                                                    className={
                                                        this.state.isOpenTeamInfo && this.state.selectedOpenTeamId === team.teamId
                                                            ? classes.listItemOpenInStyle
                                                            : classes.listItemStyle
                                                    }
                                                    ref={forwardedRef && isLastTeam ? forwardedRef : undefined}
                                                >
                                                    <Box className={classes.flexCenter} onClick={() => this.handleOpenTeamInfo(team)}>
                                                        <Avatar className={classes.avatar}>
                                                            <UsersThreeIcon />
                                                        </Avatar>
                                                        <Box display="flex" flexDirection="column">
                                                            <span className={classes.name}>{team.name}</span>
                                                            <span className={classes.groupInfo}>
                                                                {DATE_UTIL.getTimeZoneDate(
                                                                    team.createdDatetime,
                                                                    DATE_UTIL.FORMAT.SINGLE_DIGIT_DOT_DATE_FORMAT,
                                                                )}{' '}
                                                                | {team.teamUserList.length}명
                                                            </span>
                                                        </Box>
                                                    </Box>
                                                    {!(this.state.isOpenTeamInfo && this.state.selectedOpenTeamId === team.teamId) && hasAuthority && (
                                                        <MoreButton
                                                            menuItems={[
                                                                {
                                                                    name: intl.formatMessage({ id: 'modify' }),
                                                                    menuFunc: () => this.handleClickModifyBtn(team),
                                                                },
                                                                {
                                                                    name: intl.formatMessage({ id: 'delete' }),
                                                                    menuFunc: () => this.handleClickDeleteBtn(team),
                                                                },
                                                            ]}
                                                        />
                                                    )}
                                                </Box>
                                                {this.state.isOpenTeamInfo && this.state.selectedOpenTeamId === team.teamId && (
                                                    <ClassTeamInfoComponent teamMembers={team.teamUserList} findAvatar={this.props.findAvatar} />
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </>
                        ) : (
                            <Box className={classes.listStyle}>
                                <Box className={classes.flexCenter}>
                                    <Typography>등록된 그룹이 없습니다.</Typography>
                                </Box>
                            </Box>
                        )}
                    </>
                )}
                <YesOrNoDialog
                    open={this.state.isCheckDeleteDialogOpen}
                    title={intl.formatMessage({ id: 'notification' })}
                    msg={intl.formatMessage({ id: 'msg.check_delete' }, { name: selectedTeam.name ? selectedTeam.name : '' })}
                    submit={this.handleSubmitDeleteTeam}
                    changeDialogOpen={this.handleCancelDeleteTeam}
                />
            </div>
        );
    }
}

export default withStyles(styles)(injectIntl(ClassMemberGroupManagementComponent));
