import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { getDateString, getTimeString, makeNPageIdStr, PLAYSTATE, ZoomFitEnum, ViewControlSample } from 'nl-lib';
// import { getDateString, getTimeString, makeNPageIdStr, PLAYSTATE, ZoomFitEnum, ViewControlSample } from '../../nl-lib';
import { inject, observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
    drawer: {
        width: theme.measurements['thumbnail_draw_width'],
        flexShrink: 0,
        flexGrow: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: theme.measurements['drawerWidth'],
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7),
        },
    },
}));
const minHeight = 1;
function ThumbnailPanel(props) {
    // const location = useLocation();
    // const paramMap = parseQuery(location.search);
    // const { eduClassId, curriculumId } = paramMap;
    // const analyzer = useStudyData({ eduClassId, curriculumId });
    // const analyzer = useStudyData({ eduClassId, curriculumId, section: paramMap.section, owner: paramMap.owner, book: paramMap.book, ncodeStartPage: paramMap.ncodeStartPage, ncodeEndPage: paramMap.ncodeEndPage, });
    let _cache = useMemo(
        () =>
            new CellMeasurerCache({
                minHeight: minHeight,
                fixedWidth: true,
            }),
        [minHeight],
    );
    // const [analyzer, setAnalyzer] = useState(undefined as LessonStudyAnalyzer);
    // useEffect(() => {
    //   if (props.analyzer) {
    //     setAnalyzer(props.analyzer);
    //   }
    // }, [props.analyzer]); //, analyzerHook]);
    const { width: div_width, height: div_height, ref: grid_ref } = useResizeDetector();
    const [tw, setTw] = useState(0);
    useEffect(() => {
        const node = grid_ref.current;
        const actualWidth = node.scrollWidth;
        setTw(actualWidth);
    }, [div_width, div_height]);
    const chunks = props.replayStorage ? props.replayStorage.pageChunks : [];
    const paperGroup = chunks.length > 0 ? props.neoPenStore.getPaperGroup(chunks[0].pageInfo) : undefined;

    const tmb_style = {
        marginTop: props.marginTop,
        marginBottom: 0,
        background: '#eee',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 297 * props.thumbnailScale,
        alignSelf: 'stretch',
    };
    const handleMouseDown = (chunk_idx, pageInfo) => {
        const timeToMove = chunks[chunk_idx].startDeltaTime;
        console.log(`chunk_idx=${chunk_idx} timeToMove=${timeToMove} pageInfo=${makeNPageIdStr(pageInfo)} `);
        // console.log(`ERROR: (handleMouseUp) SetTime: ${timeToMove}`);
        props.playTimeHandler(timeToMove);
    };
    const scrollListener = params => {
        // console.log(params);
    };
    let _mostRecentWidth = 0;
    const rowRenderer = ({ parent, key, index, style }) => {
        return (
            <CellMeasurer cache={_cache} columnIndex={0} key={key} parent={parent} rowIndex={index} width={_mostRecentWidth}>
                <div style={style}>
                    <ThumbnailItemRenderer
                        key={index}
                        index={index}
                        chunks={chunks}
                        // analyzer={analyzer}
                        handleMouseDown={handleMouseDown}
                        replayStorage={props.replayStorage}
                        width={tw}
                        height={tw}
                        pens={props.pens}
                        paperGroup={paperGroup}
                    />
                </div>
            </CellMeasurer>
        );
    };
    return (
        <div
            id="thumbnail-relative"
            style={{
                position: 'relative',
                margin: 0,
                padding: 0,
                width: '100%',
                height: '100%',
            }}
            ref={grid_ref}
        >
            <div
                id="thumbnail-absolute"
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    height: '100%',
                    overflow: 'none',
                }}
            >
                <AutoSizer
                    autosize
                    // disableWidth
                >
                    {({ height, width }) => {
                        console.log(`AutoSize width = ${height}`);
                        return (
                            <List
                                rowCount={chunks.length}
                                height={height}
                                width={width}
                                rowRenderer={p => rowRenderer({ ...p })}
                                onScroll={scrollListener}
                                overscanRowCount={2}
                                deferredMeasurementCache={_cache}
                                rowHeight={_cache.rowHeight}
                            />
                        );
                    }}
                </AutoSizer>
            </div>
        </div>
    );
}
const ThumbnailItemRenderer = props => {
    const { index, chunks, paperGroup } = props;
    const chunk = chunks[index];
    const pageInfo = chunk.pageInfo;
    let pageName = pageInfo.page - paperGroup.pageStart + 1;
    let lastPage = paperGroup.pageEnd - paperGroup.pageStart + 1;

    const height = props.height || 150;

    return (
        <div key={index}>
            <Paper
                elevation={4}
                style={{
                    marginBottom: 10,
                    margin: 8,
                    padding: 0,
                    height: height + 30,
                    backgroundColor: '#EEE',
                }}
                onMouseDown={e => {
                    props.handleMouseDown(index, pageInfo);
                }}
            >
                {/* <img src={img_url} style={{ width: "100%" }} /> */}

                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    <div style={{ position: 'absolute', height, left: 0, top: 25, right: 0, bottom: 0, zIndex: 999 }}>
                        <ViewControlSample
                            id="thumbnail"
                            pens={props.pens}
                            pageInfos={[]}
                            activePage={pageInfo}
                            disableAutoPageChange={true}
                            disableMousePen={true}
                            disableAutoFocus={true}
                            disablePanZoom={true}
                            showGrid={false}
                            onCropRangeChagned={undefined}
                            cropRect={undefined}
                            laserPointer={false}
                            disableViewerOption={true}
                            fitType={ZoomFitEnum.FULL}
                            onPdfRequested={undefined}
                            playState={PLAYSTATE.stop}
                            replayStorage={props.replayStorage}
                            replaySpeed={1}
                            onReplayTimeChanged={undefined}
                            onReplayStateChanged={undefined}
                            forceDeltaTime={Number.MAX_VALUE}
                            // onMouseDown={(e) => { handleMouseDown(idx, pageInfo) }}
                            onMouseDown={e => {
                                props.handleMouseDown(index, pageInfo);
                            }}
                            disableAnyMouseAction={false}
                            // 2021/08/11
                            enableOptionButton={false}
                            onViewPageChanged={undefined}
                            onZoomChanged={undefined}
                            disableScrollBar={true}
                            isPriorityView={false}
                        />
                    </div>

                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            bottom: 5,
                            transform: 'translate(-50%, 0%)',
                            zIndex: 999,
                            background: 'rgba(214, 214, 214, 0.6)',
                            borderRadius: 50,
                            minWidth: 38,
                            height: 20,
                            padding: '0 5px',
                            boxSizing: 'border-box',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: pageName.length - 1 < 4 ? 13 : 10,
                                color: '#6d6d70',
                            }}
                        >
                            <span style={{ fontWeight: 600, color: '#000' }}>{`${pageName}`}</span> / {`${lastPage}`}
                        </Typography>
                    </div>

                    <div style={{ position: 'absolute', right: 5, top: 5, zIndex: 999 }}>
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: 10,
                                fontWeight: 500,
                                // color: "#00f",
                                // textShadow: "0px 0px 2px #FFF",
                                // backgroundColor: "#EEEA"
                            }}
                        >
                            {`${getDateString(chunk.startTime_abs)}`}
                        </Typography>
                    </div>

                    <div style={{ position: 'absolute', right: 5, top: 20, zIndex: 999 }}>
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: 10,
                                fontWeight: 500,
                                // color: "#00f",
                                // textShadow: "0px 0px 3px #FFF",
                                // backgroundColor: "#EEED",
                                // width: "100%",
                            }}
                        >
                            {`${getTimeString(chunk.startTime_abs)}`}
                        </Typography>
                    </div>
                </div>
            </Paper>
        </div>
    );
};

export default inject('neoPenStore')(observer(ThumbnailPanel));
