import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ClassContentInfoComponent from './ClassContentInfoComponent';
import ClassContentSearchComponent from './ClassContentSearchComponent';
import ClassAsideLatestComponent from './ClassAsiadeLatestComponent';
import { ClassMainPath } from '../../common/ClassMainPath';

const styles = _theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 235,
            marginLeft: 90,
        },
        width: 230,
        marginLeft: 30,
        '& ul, li': {
            listStyle: 'none',
            padding: 0,
            margin: 0,
        },
    },
});

class ClassAsideComponent extends Component {
    render() {
        const { classes, selectedGroupDetail, isMember, classTab, classPath, handleChangeSearch, scrollMove } = this.props;
        return (
            <div className={classes.root}>
                <ClassContentInfoComponent moveTo={this.props.moveTo} selectedGroupDetail={selectedGroupDetail} isMember={isMember} />
                <ClassContentSearchComponent
                    scrollMove={scrollMove}
                    classPath={classPath}
                    classTab={this.props.classTab}
                    classSelectTag={this.props.classSelectTag}
                    handleChangeSearch={handleChangeSearch}
                    noticeBoard={this.props.noticeBoard}
                />
                {isMember && classTab !== ClassMainPath.referenceRoom && <ClassAsideLatestComponent moveTo={this.props.moveTo} />}
            </div>
        );
    }
}

export default withStyles(styles)(ClassAsideComponent);
