import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { ReactComponent as CheckIcon } from '../../common/images/CheckIcon.svg';
import { withRouter } from '../../components/WithRouter';

const styles = _theme => ({
    root: {
        width: 650,
        height: 'calc(100% - 86px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: '0 auto',
        '& h4': {
            fontSize: '1.75rem',
        },
        '& p': {
            fontSize: '1.125rem',
            color: '#333',
            marginTop: 10,
            marginBottom: 30,
        },
    },
    iconStyle: {
        background: '#e9e9e9',
        width: 45,
        height: 45,
        borderRadius: 45,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 15,
    },
    btnStyle: {
        width: 340,
        height: 48,
        borderRadius: 8,
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        fontWeight: 500,
        marginTop: 30,
        '&:hover': {
            background: '#0097ff',
        },
    },
});

class WithdrawalConfirmComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    moveToHome = () => {
        this.props.navigate('/home');
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <span className={classes.iconStyle}>
                    <CheckIcon />
                </span>
                <Typography variant="h4">서비스 탈퇴가 완료되었습니다.</Typography>
                <Typography variant="body1">그동안 서비스를 이용해주셔서 감사합니다.</Typography>
                <Button className={classes.btnStyle} disableripple onClick={this.moveToHome}>
                    첫 화면으로 가기
                </Button>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(WithdrawalConfirmComponent));
