import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, FormControl, FormControlLabel, OutlinedInput, Radio, RadioGroup, Typography } from '@material-ui/core';
import { ReactComponent as BackArrowIcon } from '../../../common/images/BackArrowIcon.svg';
import { ReactComponent as UnCheckedIcon } from '../../../common/images/UnCheckedIcon.svg';
import { ReactComponent as CheckedIcon } from '../../../common/images/CheckedIcon.svg';
import Gallery from '../../myRoom/imgGallery/Gallery';
import { withRouter } from '../../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import GalleryCheckCircleIcon from '../../../common/images/GalleryCheckCircleIcon.svg';
import CoverSelectDialogComponent from '../../dialog/CoverSelectDialogComponent';
import ConfirmDialog from '../../../components/common/ConfirmDialog';

const styles = theme => ({
    root: {
        width: '100%',
        background: '#f3f3f3',
        paddingBottom: 40,
        marginTop: 56,
        padding: 10,
        boxSizing: 'border-box',
        '@media all and (min-width: 601px)': {
            marginTop: 66,
        },
    },
    titleBox: {
        width: '100%',
        margin: '0 auto 10px',
        paddingTop: 10,
        '@media all and (min-width: 750px)': {
            width: 730,
            margin: '0 auto 25px',
        },
    },
    titleText: {
        fontSize: '1rem',
        color: '#000',
        marginTop: 10,
        '@media all and (min-width: 601px)': {
            fontSize: '1.625rem',
        },
    },
    backButton: {
        padding: 0,
        fontSize: '0.688rem',
        color: '#000',
        '& span': {
            marginRight: 8,
            marginBottom: 1,
        },
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (min-width: 601px)': {
            fontSize: '0.938rem',
        },
    },
    boxStyle: {
        width: '100%',
        borderRadius: 10,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.25)',
        background: '#fff',
        padding: '20px',
        margin: '0 auto',
        boxSizing: 'border-box',
        marginBottom: 20,
        '@media all and (min-width: 750px)': {
            width: 730,
            padding: '25px',
        },
    },
    boxTitleText: {
        fontSize: '0.938rem',
        fontWeight: 600,
        color: '#0d0d0d',
        marginBottom: 5,
        '@media all and (min-width: 601px)': {
            fontSize: '1.25rem',
            marginBottom: 18,
        },
    },
    boxSubtitleText: {
        fontSize: '0.813rem',
        color: 'rgba(0, 0, 0, 0.6)',
        margin: '17px 0 20px',
        '@media all and (min-width: 601px)': {
            fontSize: '0.938rem',
        },
    },
    required: {
        '&:after': {
            content: '"*"',
            color: '#ff0000',
        },
    },
    textField: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #c0c2c3',
            },
        '& .MuiOutlinedInput-root': {
            width: '100%',
            borderRadius: 4,
            '&:hover': {
                borderColor: '#c0c2c3',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '8px 10px',
            color: '#000',
            fontSize: '0.813rem',
            '&::placeholder': {
                color: '#92979e',
                opacity: 1,
            },
        },
        '@media all and (min-width: 601px)': {
            '& .MuiOutlinedInput-input': {
                padding: '13px 18px',
                fontSize: '1rem',
            },
        },
    },
    textFieldMulti: {
        display: 'flex',
        flexDirection: 'row',
        '&>div': {
            width: '32%!important',
            marginLeft: '2%',
            '&:first-child': {
                marginLeft: 0,
            },
        },
    },
    radioContents: {
        width: 300,
        marginRight: 0,
        '@media all and (min-width: 601px)': {
            marginRight: 30,
            '&:last-child': {
                marginRight: 0,
            },
        },
    },
    radioBox: {
        margin: '5px 0 5px',
        '& .MuiIconButton-root': {
            padding: 0,
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '0.813rem',
            color: '#0d0d0d',
            marginLeft: 10,
        },
        '@media all and (min-width: 601px)': {
            margin: '0 0 5px',
            '& .MuiFormControlLabel-label': {
                fontSize: '1.125rem',
            },
        },
    },
    radioText: {
        fontSize: '0.813rem',
        color: 'rgba(0, 0, 0, 0.6)',
        '@media all and (min-width: 601px)': {
            fontSize: '0.938rem',
        },
    },
    buttonStyle: {
        width: '45%',
        height: 35,
        borderRadius: 7,
        fontSize: '0.875rem',
        fontWeight: 600,
        marginTop: 5,
        border: '1px solid #000',
        color: '#000',
        background: '#fff',
        '&:hover': {
            background: '#fff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (min-width: 601px)': {
            fontSize: '1.25rem',
            width: 280,
            height: 60,
            marginTop: 10,
        },
    },
    fullImgBox: {
        width: 670,
        height: 275,
        borderRadius: 10,
        background: 'red',
    },
    saveButton: {
        background: '#0097ff',
        marginLeft: 25,
        color: '#fff',
        border: 0,
        '&:hover': {
            background: '#0097ff',
        },
    },
    gallery: {
        '& .imgList': {
            listStyle: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            paddingLeft: 0,
            marginTop: 0,
            marginBottom: 0,
            '@media all and (min-width: 601px)': {
                justifyContent: 'flex-start',
            },
        },
        '& .imgBox': {
            width: '100%',
            height: 100,
            overflow: 'hidden',
            borderRadius: 10,
            '& img': {
                width: '100%',
                maxWidth: '100%',
                height: '100%',
            },
            '@media all and (min-width: 601px)': {
                height: 275,
            },
        },
        '& .imgList div': {
            width: '49%',
            '@media all and (min-width: 601px)': {
                margin: '0 5px',
                width: 'calc((100% / 3) - 10px)',
            },
        },
        '& .imgList li, .imgList label': {
            width: '100%',
            height: 65,
            margin: '0 0 5px',
            cursor: 'pointer',
            overflow: 'hidden',
            borderRadius: 10,
        },
        '& .img': {
            '& img': {
                width: '100%',
                maxWidth: '100%',
                objectFit: 'cover',
                '@media (min-width: 319px) and (max-width: 374px)': {
                    height: '100%',
                },
            },
        },
        '& .imgSelect': {
            position: 'relative',
            '&::before': {
                display: 'block',
                position: 'absolute',
                width: '100%',
                height: '65px',
                background: 'rgba(0, 151, 255, 0.5)',
                border: '3px solid #0097ff',
                borderRadius: 10,
                content: "''",
                backgroundImage: `url(${GalleryCheckCircleIcon})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                boxSizing: 'border-box',
            },
            '& img': {
                width: '100%',
                maxWidth: '100%',
                objectFit: 'cover',
                '@media (min-width: 319px) and (max-width: 374px)': {
                    height: '100%',
                },
            },
        },
        '& .textStyle': {
            fontSize: '0.813rem',
            color: 'rgba(0, 0, 0, 0.6)',
            margin: '24px 0 11px',
            '@media all and (min-width: 601px)': {
                fontSize: '0.938rem',
            },
        },
        '& .imgButton': {
            background: '#e9e9e9',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& p': {
                fontSize: '0.875rem',
                color: 'rgba(0, 0, 0, 0.8)',
                marginLeft: 5,
            },
        },
    },
});

class MobileRoomCreateComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 'checkA',
            coverSelectDialogOpen: false,
        };
    }

    componentDidMount() {
        this.props.classStore.initClass();
    }

    handleClickMyRoom = () => {
        this.props.navigate(-1);
    };

    handleChange = e => {
        this.setState({ value: e.target.value }, () => this.props.classStore.changeIsPublicGroup(this.state.value === 'checkA'));
    };

    handleChangeGroupName = e => {
        const groupName = e.target.value.trim();
        this.props.classStore.changeGroupName(groupName);
    };

    handleCancel = () => {
        // this.props.navigate('/rooms');
        this.props.navigate(-1);
    };

    handleCreateGroup = () => {
        const { userStore, authStore } = this.props;
        const { loginUser } = authStore;

        this.props.classStore.createGroup(this.props.authStore.loginUser, {
            moveTo: () => this.props.navigate(-1),
            initCoachMarkState: () => {
                userStore.checkCoachState(loginUser.email);
            },
        });
    };

    handleChangeTag = e => {
        this.props.classStore.changeTag(e);
    };

    coverSelectDialogOpen = () => {
        this.setState({ coverSelectDialogOpen: true });
    };

    handleClose = () => {
        this.setState({ coverSelectDialogOpen: false });
        document.getElementById('background-button-file').click();
    };

    render() {
        const { classes, classStore, intl } = this.props;
        const { isGroupNameValidation, isGroupTagValidation, openConfirmDialog, errMsg } = classStore;
        const { type } = this.props.params;
        const isCreate = isGroupNameValidation && isGroupTagValidation;

        return (
            <div className={classes.root}>
                <Box className={classes.titleBox}>
                    <Button className={classes.backButton} startIcon={<BackArrowIcon />} disableRipple onClick={this.handleClickMyRoom.bind(this)}>
                        뒤로가기
                    </Button>
                    <Typography className={classes.titleText}>{type ? intl.formatMessage({ id: type }) : ''} 클래스 만들기</Typography>
                </Box>

                <Box className={classes.boxStyle}>
                    <Typography className={clsx(classes.boxTitleText, classes.required)}>클래스 이름</Typography>
                    <FormControl className={classes.textField} variant="outlined">
                        <OutlinedInput
                            inputProps={{ 'aria-label': 'room name input box', maxLength: 50 }}
                            id="outlined-room-name"
                            labelWidth={0}
                            placeholder="클래스 이름 입력 (최대 50자)"
                            onChange={this.handleChangeGroupName}
                        />
                    </FormControl>
                </Box>

                <Box className={classes.boxStyle}>
                    <Typography className={clsx(classes.boxTitleText, classes.required)}>공개 범위</Typography>

                    <RadioGroup aria-label="position" name="position" value={this.state.value} onChange={this.handleChange} row>
                        <Box className={classes.radioContents}>
                            <FormControlLabel
                                control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} value="checkA" />}
                                label="공개"
                                className={classes.radioBox}
                            />
                            <Typography className={classes.radioText}>
                                누구나 클래스를 검색해 찾을 수 있고, 클래스 소개와 일정을 볼 수 있습니다.
                            </Typography>
                        </Box>

                        <Box className={classes.radioContents}>
                            <FormControlLabel
                                control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} value="checkB" />}
                                label="비공개"
                                className={classes.radioBox}
                            />
                            <Typography className={classes.radioText}>클래스와 수업이 공개되지 않습니다. 초대를 통해 가입할 수 있습니다.</Typography>
                        </Box>
                    </RadioGroup>
                </Box>

                <Box className={classes.boxStyle}>
                    <Typography className={clsx(classes.boxTitleText, classes.required)}>클래스 태그</Typography>
                    <Typography className={classes.boxSubtitleText}>
                        클래스의 성격을 나타내는 대표 태그를 입력하세요.
                        <br />
                        사람들이 나의 클래스나 관심사와 관련된 클래스도 언제든지 쉽게 찾을 수 있습니다.
                    </Typography>
                    <Box className={classes.textFieldMulti}>
                        {Array.from({ length: 3 }, (_, i) => (
                            <FormControl className={classes.textField} variant="outlined" key={i}>
                                <OutlinedInput
                                    inputProps={{ 'aria-label': 'room tag input box', maxLength: 25 }}
                                    id={`${i}`}
                                    labelWidth={0}
                                    placeholder="태그 (최대 25자)"
                                    onChange={this.handleChangeTag}
                                />
                            </FormControl>
                        ))}
                    </Box>
                </Box>

                <Box className={clsx(classes.boxStyle, classes.gallery)}>
                    <Typography className={clsx(classes.boxTitleText, classes.required)}>커버</Typography>
                    <Gallery coverSelectDialogOpen={this.coverSelectDialogOpen} />
                </Box>

                <Box display="flex" justifyContent="center" alignItems="center">
                    <Button className={classes.buttonStyle} disableRipple onClick={this.handleCancel}>
                        취소
                    </Button>
                    <Button
                        className={clsx(classes.buttonStyle, classes.saveButton)}
                        disableRipple
                        onClick={this.handleCreateGroup}
                        disabled={!isCreate}
                    >
                        만들기
                    </Button>
                </Box>

                <CoverSelectDialogComponent handleClose={this.handleClose} dialogOpen={this.state.coverSelectDialogOpen} />
                <ConfirmDialog
                    open={openConfirmDialog}
                    title={'알림'}
                    msg={errMsg}
                    changeDialogOpen={() => this.props.classStore.closeConfirmDialog()}
                />
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('classStore', 'authStore', 'userStore')(injectIntl(observer(MobileRoomCreateComponent)))));
