import React, { Component } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AttendanceCheck from './attendanceCheck/AttendanceCheck';
import QuizResult from './quiz/QuizResult';
import MemberActivity from './memberActivity/MemberActivity';
import ConnectStatus from './ConnectStatus/ConnectStatus';
import Device from './device/Device';
import { inject, observer } from 'mobx-react';
import ImagePopupModal from './memberActivity/ImagePopupModal';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { withRouter } from '../../components/WithRouter';
import { ReactComponent as StudyLiveTopBarLogo } from '../../common/images/StudyLiveTopBarLogo.svg';

const style = () => ({
    root: {},
    topBar: {
        background: '#fff',
        borderBottom: '1px solid #c0c2c3',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '25px 32px',
        boxSizing: 'border-box',
    },
    contents: {
        padding: '28px 108px',
    },
    titleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 18,
        fontWeight: 600,
        color: '#0d0d0d',
        marginBottom: 45,
        marginTop: 47,
    },
    borderBottomStyle: {
        borderBottom: '2px solid #e2ebf1',
        paddingBottom: 50,
        marginBottom: 50,
    },
    titleBox: {
        paddingBottom: 13,
        marginBottom: 18,
        marginRight: 50,
    },
    textStyle: {
        fontFamily: 'Noto Sans KR',
        fontSize: 14,
        fontWeight: 600,
        color: '#0d0d0d',
    },
    titleStyle: {
        fontSize: 24,
        color: '#070b31',
    },
});

class Statistics extends Component {
    constructor(props) {
        super(props);
        this.props.statisticsStore.getStatistics(props.params.roomId);
    }

    // componentDidMount() {
    //     const { topBarStore, params } = this.props;
    //     if (window.location.pathname === `/statistics/${params.roomId}`) {
    //         topBarStore.setIsSearchBarRender(false);
    //     }
    // }

    render() {
        const { classes } = this.props;
        const { attendanceResultList, userDeviceList, userSummaryList, userHistoryList } = this.props.statisticsStore;

        return (
            <div className={classes.root}>
                <Box className={classes.topBar}>
                    <StudyLiveTopBarLogo />
                </Box>
                <Box className={classes.contents}>
                    {/*<Typography className={classes.titleText}><FormattedMessage id="statistics_status"/></Typography>*/}
                    <Box className={classes.titleBox}>
                        <Typography className={clsx(classes.textStyle, classes.titleStyle)}>
                            <FormattedMessage id="statistics_status" />
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container className={classes.borderBottomStyle} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item xs={6}>
                                <AttendanceCheck attendanceResultList={attendanceResultList} />
                            </Grid>
                            {/*<Grid item xs={6}>*/}
                            {/*    <Questions />*/}
                            {/*</Grid>*/}
                        </Grid>
                        <Grid item xs={12} className={classes.borderBottomStyle}>
                            <QuizResult />
                        </Grid>

                        {/*<Grid item xs={12} className={classes.borderBottomStyle}>*/}
                        {/*    <StatisticsSurveyResult />*/}
                        {/*</Grid>*/}

                        <Grid container className={classes.borderBottomStyle} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={6}>
                                <ConnectStatus userHistoryList={userHistoryList} />
                            </Grid>
                            <Grid item xs={6}>
                                <Device userDeviceList={userDeviceList} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: 65 }}>
                            <MemberActivity userSummaryList={userSummaryList} />
                        </Grid>
                        <ImagePopupModal />
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(inject('statisticsStore', 'topBarStore')(observer(Statistics))));
