import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Drawer, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as Close } from '../../../../common/images/Close.svg';
import { ReactComponent as SelectCheckIcon } from '../../../../common/images/SelectCheckIcon.svg';

const styles = theme => ({
    root: {},
    drawerBox: {
        '& .MuiDrawer-paper': {
            borderRadius: '10px 10px 0 0',
            boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.25)',
            padding: '10px 10px 20px',
            boxSizing: 'border-box',
            '& svg': {
                width: 20,
                height: 20,
                '& path': {
                    strokeWidth: 2,
                },
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    selectBox: {
        padding: '5px 0',
    },
    textStyle: {
        fontSize: '1rem',
        marginLeft: 5,
    },
});

class MobileSelectDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes, selectDrawer, handleCloseDrawer, handleChangeSelect, selectText } = this.props;

        return (
            <div className={classes.root}>
                <Drawer anchor="bottom" open={selectDrawer} onClose={handleCloseDrawer} className={classes.drawerBox}>
                    <div tabIndex={0} role="button" onClick={handleCloseDrawer} onKeyDown={handleCloseDrawer}>
                        <Box display="flex" justifyContent="flex-end">
                            <IconButton className={classes.iconButton} onClick={handleCloseDrawer} disableRipple>
                                <Close />
                            </IconButton>
                        </Box>
                        <Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                onClick={() => {
                                    handleChangeSelect('최근 작성일 순');
                                }}
                                className={classes.selectBox}
                            >
                                {selectText === '최근 작성일 순' && <SelectCheckIcon />}

                                <Typography className={classes.textStyle}>최근 작성일 순</Typography>
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                onClick={() => {
                                    handleChangeSelect('가나다 순');
                                }}
                                className={classes.selectBox}
                            >
                                {selectText === '가나다 순' && <SelectCheckIcon />}
                                <Typography className={classes.textStyle}>가나다 순</Typography>
                            </Box>
                        </Box>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default withStyles(styles)(MobileSelectDrawer);
