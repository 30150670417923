import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, FormControl, FormHelperText, OutlinedInput, Typography } from '@material-ui/core';
import EmailCertificationDialogComponent from '../../dialog/EmailCertificationDialogComponent';
import MailAuthenticationFailedDialogComponent from '../../dialog/MailAuthenticationFailedDialogComponent';
import { inject, observer } from 'mobx-react';
import MobileMakeAvatar from './MobileMakeAvatar';
import { ReactComponent as PersonIcon } from '../../../common/images/PersonIcon.svg';
import { FormattedMessage, injectIntl } from 'react-intl';
import MobileChangePassword from './MobileChangePassword';
import ConfirmDialog from '../../../components/common/ConfirmDialog';
import WithdrawalDialogComponent from '../../dialog/WithdrawalDialogComponent';
import { checkSpace } from '../../../common/Validation';
import { withRouter } from '../../../components/WithRouter';
import { ToastsStore } from 'react-toasts';
import LicenseKeyInputBox from '../../../components/LicenseKeyInputBox';
import LicenseKeyInfoBox from '../../../components/LicenseKeyInfoBox';
import { USER_TYPE } from '../../../stores/UserStore';
import LicenseKeySearchButton from '../../../components/LicenseKeySearchButton';
import { Stack } from '@mui/material';

const styles = _theme => ({
    root: {
        width: '100%',
        padding: '25px 10px 55px',
        margin: '0 auto',
        marginTop: 56,
        boxSizing: 'border-box',
        '@media all and (min-width: 601px)': {
            width: 500,
            marginTop: 66,
        },
    },
    titleBox: {
        borderBottom: '3px solid #000',
        marginBottom: 30,
        '@media all and (min-width: 601px)': {
            marginBottom: 52,
        },
    },
    titleText: {
        fontSize: '1rem',
        color: '#000',
        paddingBottom: 15,
        '@media all and (min-width: 601px)': {
            fontSize: '1.5rem',
            paddingBottom: 25,
        },
    },
    marginBottom: {
        marginBottom: 10,
        '@media all and (min-width: 601px)': {
            fontSize: '1.5rem',
            marginBottom: 30,
        },
    },
    marginBottom2: {
        marginBottom: 10,
        '@media all and (min-width: 601px)': {
            marginBottom: 30,
        },
    },
    textStyle: {
        width: 90,
        marginRight: 20,
        fontSize: '0.875rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (min-width: 601px)': {
            width: 100,
            fontSize: '1rem',
            // marginRight: 47,
        },
    },
    textStyle2: {
        width: 'calc(100% - 110px)',
        marginRight: 0,
        fontSize: '0.875rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (min-width: 601px)': {
            width: 'calc(100% - 100px)',
            fontSize: '1rem',
            marginRight: 0,
        },
    },
    textStyleRequired: {
        '&:after': {
            content: '"*"',
            color: '#ff0000',
        },
    },
    textField: {
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #c0c2c3',
            },
        '& .MuiOutlinedInput-root': {
            width: '100%',
            borderRadius: 4,
            '&:hover': {
                borderColor: '#c0c2c3',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '5px 10px',
            color: '#000',
            fontSize: '0.875rem',
            '&::placeholder': {
                color: '#9d9d9d',
                opacity: 1,
            },
        },
        '@media all and (min-width: 601px)': {
            minWidth: '350px',
            '& .MuiOutlinedInput-input': {
                padding: '13px 18px',
                fontSize: '1.063rem',
            },
        },
    },
    lineStyle: {
        width: '100%',
        height: 12,
        borderBottom: '1px solid #c0c2c3',
        marginBottom: 27,
        '@media all and (min-width: 601px)': {
            marginBottom: 37,
        },
    },
    avatar: {
        width: 80,
        height: 80,
        background: '#a3a8af',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 2px',
        '@media all and (min-width: 601px)': {
            width: 120,
            height: 120,
        },
    },
    input: {
        display: 'none',
    },
    buttonStyle: {
        width: 120,
        height: 35,
        fontSize: '0.875rem',
        fontWeight: 600,
        borderRadius: 7,
        border: '1px solid #000',
        color: '#000',
        background: '#fff',
        '&:hover': {
            background: '#fff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (min-width: 601px)': {
            width: 205,
            height: 44,
            fontSize: '1.063rem',
        },
    },
    cameraBtnStyle: {
        right: -20,
        top: -15,
        padding: 0,
        background: 'transparent',
        position: 'relative',
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (min-width: 601px)': {
            right: -40,
        },
    },
    saveButton: {
        background: '#0097ff',
        marginLeft: 20,
        color: '#fff',
        border: 0,
        '&:hover': {
            background: '#0097ff',
        },
    },
    linkStyle: {
        fontSize: '0.75rem',
        '&:hover': {
            cursor: 'pointer',
        },
        color: '#ff0000',
        textDecoration: 'underline',
        '@media all and (min-width: 601px)': {
            fontSize: '0.875rem',
        },
    },
});

const LOG_PREFIX = ' [MobileProfileSettingsComponent] ';

class MobileProfileSettingsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            profileImage: false,
            labelWidth: '100%',
            timezone: '',
            languages: '',
            open: false,
            openMail: false,
            isEdit: false,
            withdrawalDialog: false,
            licenseKeyDialog: false,
        };
    }

    componentDidMount() {
        const { loginUser } = this.props.authStore;
        const findUserEmail = loginUser.email;

        this.props.userStore.getUserInfo(findUserEmail);
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleChangeTimezone = value => {
        this.props.userStore.changeEditUserTimezone(value);
    };

    handleChangeLanguages = event => {
        const selectedLanguage = event.target.value;
        this.props.userStore.changeEditUserLanguage(selectedLanguage);
    };

    handleSendAuthenticationEmail = () => {
        const { editedUser } = this.props.userStore;
        this.props.authStore.sendAuthenticationEmail(editedUser.email, {
            successAction: () => this.handleClickOpen(),
            failedAction: () => ToastsStore.warning('이메일 발송이 되지 않았습니다. 다시 시도해주세요.', 1500),
        });
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClickSave = () => {
        const { userDetail } = this.props.userStore;
        this.props.avatarStore._uploadAvatarImage();
        this.props.userStore.modifyUserInfo(userDetail.email, {
            closeEditMode: () => this.handleEditMode(false),
            reSetLoginUserName: userEmail => {
                // this.props.authStore.reSetLoginUserName(newName);
                this.props.userStore.getUserInfo(userEmail);
            },
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
            openMail: false,
            withdrawalDialog: false,
        });
    };

    handleCheckAllowEmail = () => {
        this.props.userStore.changeEditUserAllowEmailState();
    };

    handleEditMode = isEdit => {
        const { userStore, avatarStore, authStore } = this.props;

        this.setState({ isEdit: isEdit });
        if (isEdit) {
            userStore.setEditUserInfo();
            avatarStore.initSaveImage();
        } else {
            userStore.initEditUserInfo();
            avatarStore.getAvatar(authStore.loginUser.id);
        }
    };

    handleChangeDepartment = e => {
        checkSpace(e);
        this.props.userStore.changeEditUserDepartment(e.target.name, e.target.value);
    };

    handleChangeName = e => {
        this.props.userStore.changeEditUserName(e.target.value);
    };

    handleChangeEmail = e => {
        checkSpace(e);
        this.props.userStore.changeEditUserEmail(e.target.value);
    };

    handleClickWithdrawalDialogOpen = () => {
        this.setState({
            withdrawalDialog: true,
        });
    };

    handleWithDrawUser = () => {
        const { userDetail } = this.props.userStore;
        const { invalidateLogin, invalidateSavedEmail, invalidateAuthToken } = this.props.authStore;

        this.props.userStore.withdrawUser(userDetail.email, {
            invalidateLoginInfo: () => {
                invalidateSavedEmail();
                invalidateLogin();
                invalidateAuthToken();
            },
            moveWithdrawConfirm: () => {
                this.props.navigate('/withdrawal');
            },
            errorMessage: () => {
                ToastsStore.error('서버와 통신이 원활하지 않습니다. 다시 시도해주세요.', 1500);
            },
            closeWithdrawDialog: () => {
                this.handleClose();
            },
        });
    };

    getModifyUserNameHelpText = () => {
        const { incorrectModifyUserName, isEmptyModifyUserName } = this.props.userStore;

        if (isEmptyModifyUserName) {
            return '이름을 입력해주세요.';
        } else if (incorrectModifyUserName) {
            return '이름에 올바르지 않은 문자가 포함되어 있습니다.';
        }

        return '';
    };

    handleClickLicenseKeyDialog = () => {
        this.setState({ licenseKeyDialog: true });
    };

    handleCloseLicenseKeyDialog = () => {
        this.setState({ licenseKeyDialog: false });
    };

    render() {
        const { classes, intl } = this.props;
        const { userDetail, userLicenseKey } = this.props.userStore;
        const { editedUserLicenseKey, editedUser, isAvailableModifyUser, incorrectModifyUserName, isEmptyModifyUserName } = this.props.userStore;
        const { modifyResultDialogOpen, modifyResultDialogMsgId, changeModifyResultDialogState, changeEditUserLicenseKey } = this.props.userStore;
        const { loadAvatarImage, saveAvatarImage } = this.props.avatarStore;

        return (
            <div className={classes.root}>
                <Box className={classes.titleBox}>
                    <Typography className={classes.titleText}>
                        <FormattedMessage id={'profile_settings'} />
                    </Typography>
                </Box>
                <Box display="flex" className={classes.marginBottom2}>
                    <Typography className={classes.textStyle}>프로필 이미지</Typography>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Avatar src={loadAvatarImage} alt="profile image" className={classes.avatar}>
                            <PersonIcon style={{ width: 72, height: 72 }} />
                        </Avatar>
                        {this.state.isEdit && (
                            <Box className={classes.cameraBtnStyle}>
                                <MobileMakeAvatar />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box display="flex" alignItems="flex-start" className={classes.marginBottom}>
                    <Typography className={clsx(classes.textStyle)} style={{ paddingTop: '12px' }}>
                        <FormattedMessage id={'email'} />
                    </Typography>
                    <Typography className={clsx(classes.textStyle, classes.textStyle2)} style={{ paddingTop: '12px' }}>
                        {userDetail.email}
                    </Typography>
                </Box>
                {/* 다이얼로그 */}

                <EmailCertificationDialogComponent open={this.state.open} onClose={this.handleClose} handleClose={this.handleClose} />

                {/* /다이얼로그 */}

                <Box display="flex" alignItems="center" className={classes.marginBottom}>
                    <Typography className={clsx(classes.textStyle, this.state.isEdit && classes.textStyleRequired)}>비밀번호</Typography>
                    <MobileChangePassword />
                </Box>

                <Box display="flex" alignItems="center" className={classes.marginBottom}>
                    <Typography className={clsx(classes.textStyle, this.state.isEdit && classes.textStyleRequired)}>이름</Typography>
                    {this.state.isEdit ? (
                        <FormControl
                            error={incorrectModifyUserName || isEmptyModifyUserName}
                            required
                            className={classes.textField}
                            variant="outlined"
                        >
                            <OutlinedInput
                                inputProps={{ 'aria-label': 'name input box', maxLength: 50 }}
                                id="outlined-company"
                                labelWidth={0}
                                placeholder="이름을 입력하세요"
                                value={editedUser.name}
                                onChange={this.handleChangeName}
                            />
                            <FormHelperText
                                style={{ fontSize: 'x-small' }}
                                hidden={!incorrectModifyUserName && !isEmptyModifyUserName}
                                id={'component-error-text'}
                            >
                                {this.getModifyUserNameHelpText()}
                            </FormHelperText>
                        </FormControl>
                    ) : (
                        <Typography className={clsx(classes.textStyle, classes.textStyle2)}>{userDetail.name}</Typography>
                    )}
                </Box>
                {userDetail?.type !== USER_TYPE.Guest && (
                    <Box display="flex" alignItems={this.state.isEdit ? 'flex-start' : 'center'} className={classes.marginBottom}>
                        <Box style={this.state.isEdit ? { marginTop: 10 } : {}}>
                            <Typography className={clsx(classes.textStyle, this.state.isEdit && classes.textStyleRequired)}>라이선스 키</Typography>
                        </Box>
                        <Box>
                            {this.state.isEdit ? (
                                <Stack>
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <LicenseKeyInputBox
                                            width={'65%'}
                                            minWidth={0}
                                            // useFullWidth={false}
                                            marginTop={0}
                                            licenseKey={editedUserLicenseKey?.value}
                                            onChangeLicenseKey={changeEditUserLicenseKey}
                                            handleClickLicenseKeyDialog={this.handleClickLicenseKeyDialog}
                                            handleCloseLicenseKeyDialog={this.handleCloseLicenseKeyDialog}
                                            licenseKeyDialog={this.state.licenseKeyDialog}
                                        />
                                        <LicenseKeySearchButton label={'변경'} marginTop={0} width={'30%'} />
                                    </Stack>
                                    <LicenseKeyInfoBox />
                                </Stack>
                            ) : (
                                // <Box width={'58%'}>
                                //     <FormControl required variant="outlined" className={classes.textField}>
                                //         <LicenseKeyInputBox
                                //             useFullWidth={false}
                                //             marginTop={0}
                                //             licenseKey={editedUserLicenseKey?.value}
                                //             onChangeLicenseKey={changeEditUserLicenseKey}
                                //             handleClickLicenseKeyDialog={this.handleClickLicenseKeyDialog}
                                //             handleCloseLicenseKeyDialog={this.handleCloseLicenseKeyDialog}
                                //             licenseKeyDialog={this.state.licenseKeyDialog}
                                //         />
                                //     </FormControl>
                                // </Box>
                                <Typography className={clsx(classes.textStyle, classes.textStyle2)} style={{ width: 240 }}>
                                    {userLicenseKey}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                )}

                <Box className={classes.lineStyle} />

                <Box display="flex" justifyContent="flex-end">
                    {this.state.isEdit ? (
                        <>
                            <Button className={classes.buttonStyle} disableRipple onClick={() => this.handleEditMode(false)}>
                                <FormattedMessage id="cancel" />
                            </Button>

                            <Button
                                className={clsx(classes.buttonStyle, classes.saveButton)}
                                disableRipple
                                disabled={!isAvailableModifyUser && !saveAvatarImage}
                                onClick={this.handleClickSave}
                            >
                                <FormattedMessage id="save" />
                            </Button>
                        </>
                    ) : (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'space-between'}
                            className={classes.marginBottom}
                            style={{ width: '100%' }}
                        >
                            <Typography onClick={this.handleClickWithdrawalDialogOpen} className={classes.linkStyle}>
                                탈퇴하기
                            </Typography>
                            <Button className={clsx(classes.buttonStyle, classes.saveButton)} disableRipple onClick={() => this.handleEditMode(true)}>
                                <FormattedMessage id="modify" />
                            </Button>
                        </Box>
                    )}

                    <MailAuthenticationFailedDialogComponent openMail={this.state.openMail} handleClose={this.handleClose} />
                    <ConfirmDialog
                        open={modifyResultDialogOpen}
                        title={intl.formatMessage({ id: 'notification' })}
                        msg={intl.formatMessage({ id: `${modifyResultDialogMsgId}` })}
                        changeDialogOpen={isOpen => changeModifyResultDialogState(isOpen)}
                    />
                    <WithdrawalDialogComponent
                        handleClose={this.handleClose}
                        withdrawalDialog={this.state.withdrawalDialog}
                        withDrawUser={this.handleWithDrawUser}
                    />
                </Box>
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('authStore', 'userStore', 'avatarStore', 'organizationStore')(injectIntl(observer(MobileProfileSettingsComponent)))),
);
