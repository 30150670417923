import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import * as validation from '../common/Validation';
import { validateEmail, validateName, validatePasswordCombination, validatePasswordLength } from '../common/Validation';
import { LoadingState } from './ClassStore';
import { LICENSE_KEY_PATTERN } from '../common/util/common.util';

const USER_API_URL = '/api/v1/users';

const logPrefix = ' [ UserStore ] ';

export const USER_TYPE = {
    Admin: 'Admin',
    User: 'User',
    Guest: 'Guest',
};

const CONFIG_KEY = {
    Language: 'Language',
    Zone: 'Zone',
    PenMacAddress: 'PenMacAddress',
    LicenseKey: 'LicenseKey',
};

export const FIND_PASSWORD_EMAIL_SEND_RESULT_STATE = {
    None: 'None',
    Pending: 'Pending',
    Success: 'Success',
    Failed: 'Failed',
    UserNotFound: 'UserNotFound',
    NotAcceptableId: 'NotAcceptableId',
};

const EmptyUser = {
    id: '',
    email: '',
    name: '',
    password: '',
    organization: '',
    department: '',
    type: '',
    allowEmail: false,
    createdDatetime: '',
    updatedDatetime: '',
};

const EmptyLicenseKeyConfig = {
    configKey: CONFIG_KEY.LicenseKey,
    value: '',
};

const EmptyZoneConfig = {
    configKey: CONFIG_KEY.Zone,
    value: '',
};

const EmptyLanguageConfig = {
    configKey: CONFIG_KEY.Language,
    value: '',
};

const EmptyCoachState = {
    firstLogin: false,
    hasChild: true,
    hasGroup: true,
    hasMember: true,
};

export default class UserStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    user = { ...EmptyUser };
    configs = [];
    editedUser = { ...EmptyUser };
    editedUserTimezone = { ...EmptyZoneConfig };
    editedUserLanguage = { ...EmptyLanguageConfig };
    editedUserLicenseKey = { ...EmptyLicenseKeyConfig };
    currentPassword = '';
    newPassword = '';
    confirmNewPassword = '';
    passwordChangeDialogOpen = false;
    modifyResultDialogOpen = false;
    modifyResultDialogMsgId = 'default_empty';
    userGrade = '';
    userClass = '';
    findPasswordEmail = '';
    findPasswordEmailSendResult = FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.None;
    resetPassword = '';
    confirmationResetPassword = '';
    simpleEditUserName = '';
    isFirstLogin = false;
    coachState = { ...EmptyCoachState };
    isLicenseAlertOpen = false;
    createUserLicenseKeyState = FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.None;
    isCreateUserLicenseKeyResultDialogState = false;

    changeCreateLicenseKeyState = state => {
        console.log('Do changeLicenseAlertOpenState = ', state);
        this.createUserLicenseKeyState = state;
    };
    changeCreateLicenseKeyResultDialogOpenState = state => {
        console.log('Do changeLicenseAlertOpenState = ', state);
        this.isCreateUserLicenseKeyResultDialogState = state;
    };

    changeLicenseAlertOpenState = state => {
        console.log('Do changeLicenseAlertOpenState = ', state);
        this.isLicenseAlertOpen = state;
    };

    initUserStore = () => {
        this.user = { ...EmptyUser };
        this.configs = [];
        this.findPasswordEmail = '';
        this.findPasswordEmailSendResult = FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.None;
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmNewPassword = '';
        this.editedUser = { ...EmptyUser };
        this.editedUserTimezone.value = '';
        this.editedUserLanguage.value = '';
        this.editedUserLicenseKey.value = '';
    };

    get isModifySimpleEditUserName() {
        return validateName(this.simpleEditUserName) && this.simpleEditUserName && this.simpleEditUserName !== this.user.name;
    }

    get isEqualsResetPassword() {
        const isEmpty = this.resetPassword.trim() && this.confirmationResetPassword.trim();
        return !isEmpty && this.resetPassword === this.confirmationResetPassword;
    }

    get sendFindPasswordEmail() {
        const isEmptyEmail = this.findPasswordEmail === '';
        const isValidateEmail = this.isValidateFindPasswordEmail;
        return !isEmptyEmail && isValidateEmail;
    }

    get isValidateFindPasswordEmail() {
        let result = false;
        if (this.findPasswordEmail.trim()) {
            result = validateEmail(this.findPasswordEmail);
        }
        return result;
    }

    get departmentSplitWithComma() {
        return this.userDetail.department.split(',');
    }

    get userDetail() {
        return this.user;
    }

    get userTimezone() {
        let userTimezone = '';
        if (this.configs.length) {
            const gettingUserTimeZone = this.configs.find(config => config.configKey === CONFIG_KEY.Zone);
            if (gettingUserTimeZone) {
                userTimezone = gettingUserTimeZone.value;
            }
        }
        return userTimezone;
    }

    get userLanguage() {
        let userLanguage = '';
        if (this.configs.length) {
            const gettingUserLanguage = this.configs.find(config => config.configKey === CONFIG_KEY.Language);
            if (gettingUserLanguage) {
                userLanguage = gettingUserLanguage.value;
            }
        }
        return userLanguage;
    }

    get userLicenseKey() {
        let userLicenseKey = '';
        if (this.configs.length) {
            const gettingUserLicenseKey = this.configs.find(config => config.configKey === CONFIG_KEY.LicenseKey);
            if (gettingUserLicenseKey) {
                userLicenseKey = gettingUserLicenseKey.value;
            }
        }
        return userLicenseKey;
    }

    get fullSmartPenAddress() {
        let userSmartPenAddress = '';

        if (this.configs.length !== 0) {
            let gettingUserSmartPenAddress = this.configs.find(config => config.configKey === CONFIG_KEY.PenMacAddress);

            if (gettingUserSmartPenAddress) {
                let macAdd = ('9C7BD2' + gettingUserSmartPenAddress.value).toLowerCase();
                let macStr = '';

                for (let i in macAdd) {
                    i = parseInt(i);
                    if (i % 2 === 0 && i !== 0) macStr += ':';
                    macStr += macAdd[i];
                }

                userSmartPenAddress = macStr;
            }
        }
        return userSmartPenAddress;
    }

    get userSmartPenAddress() {
        let userSmartPenAddress = '';
        if (this.configs.length) {
            const gettingUserSmartPenAddress = this.configs.find(config => config.configKey === CONFIG_KEY.PenMacAddress);
            if (gettingUserSmartPenAddress) {
                userSmartPenAddress = gettingUserSmartPenAddress.value;
            }
        }
        return userSmartPenAddress;
    }

    get isAvailableModifyUser() {
        const trimEditedUserName = this.editedUser.name.trim();
        const trimEditedLicenseKey = this.editedUserLicenseKey.value.trim();
        const isValidateLicenseKey =
            LICENSE_KEY_PATTERN.test(this.editedUserLicenseKey.value) && trimEditedLicenseKey && trimEditedLicenseKey !== this.userLicenseKey;
        return isValidateLicenseKey || (trimEditedUserName && this.userDetail.name !== trimEditedUserName && validateName(trimEditedUserName));
    }

    get incorrectModifyUserName() {
        const trimEditedUserName = this.editedUser.name.trim();
        return !validateName(trimEditedUserName);
    }

    get isEmptyModifyUserName() {
        return this.editedUser.name.trim() === '';
    }

    get isPossibleChangePassword() {
        return (
            this.currentPassword.trim() &&
            this.confirmNewPassword.trim() &&
            this.newPasswordValidateCombination &&
            this.newPasswordValidateLength &&
            this.newPassword === this.confirmNewPassword &&
            !this.newPasswordEqualsValidate
        );
    }

    get newPasswordEqualsValidate() {
        const isNotEmptyCurrentPassword = this.currentPassword.trim() !== '';
        const isNotEmptyNewPassword = this.newPassword.trim() !== '';
        return isNotEmptyCurrentPassword && isNotEmptyNewPassword && this.currentPassword.trim() === this.newPassword.trim();
    }

    get newPasswordValidateCombination() {
        const password = this.newPassword;
        let result = false;
        if (password.trim()) {
            result = validatePasswordCombination(password);
        }
        return result;
    }

    get newPasswordValidateLength() {
        const password = this.newPassword;
        let result = false;
        if (password.trim()) {
            result = validatePasswordLength(password);
        }
        return result;
    }

    changeModifyResultDialogMsgId = msgId => {
        this.modifyResultDialogMsgId = msgId;
    };

    changeSimpleEditUserName = userName => {
        this.simpleEditUserName = userName;
    };

    passwordValidation = password => {
        let passwordCombination = validation.validatePasswordCombination(password);
        let passwordLength = validation.validatePasswordLength(password);
        let result = passwordCombination && passwordLength;
        // console.log("[password === '', result, passwordCombination, passwordLength]", [password === '', result, passwordCombination, passwordLength]);
        return [password === '', result, passwordCombination, passwordLength];
    };

    changeResetPassword = password => {
        this.resetPassword = password;
        return this.passwordValidation(password);
    };

    changeConfirmationResetPassword = confirmationPassword => {
        this.confirmationResetPassword = confirmationPassword;
    };

    initUserInfo = () => {
        this.user = { ...EmptyUser };
        this.configs = [];
    };

    initFindPasswordState = () => {
        console.log(logPrefix, 'initFindPasswordState');
        this.findPasswordEmail = '';
        this.findPasswordEmailSendResult = FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.None;
    };

    changeFindPasswordEmail = email => {
        this.findPasswordEmail = email;
    };

    changeModifyResultDialogState = isOpen => {
        this.modifyResultDialogOpen = isOpen;
        if (!isOpen) {
            this.modifyResultDialogMsgId = 'default_empty';
        }
    };

    changePasswordDialogState = isOpen => {
        this.passwordChangeDialogOpen = isOpen;
    };

    changeCurrentPassword = currentPassword => {
        this.currentPassword = currentPassword;
    };

    changeNewPassword = newPassword => {
        this.newPassword = newPassword;
    };

    changeConfirmNewPassword = confirmNewPassword => {
        this.confirmNewPassword = confirmNewPassword;
    };

    initPasswordChange = () => {
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmNewPassword = '';
    };

    initEditUserInfo = () => {
        this.editedUser = { ...EmptyUser };
        this.editedUserTimezone.value = '';
        this.editedUserLanguage.value = '';
        this.editedUserLicenseKey.value = '';
        // this.editedUserAvatar = { ...EmptyUserAvatar };
    };

    setEditUserInfo = () => {
        this.editedUser = { ...this.user };
        this.editedUserTimezone.value = this.userTimezone;
        this.editedUserLanguage.value = this.userLanguage;
        this.editedUserLicenseKey.value = this.userLicenseKey;
        // this.editedUserAvatar = { ...this.avatar };
    };

    changeEditUserAllowEmailState = () => {
        this.editedUser.allowEmail = !this.editedUser.allowEmail;
    };

    changeEditUserLicenseKey = key => {
        console.log('changeEditUserLicenseKey = ', key);
        this.editedUserLicenseKey.value = key;
    };

    changeEditUserLanguage = selectedLanguage => {
        this.editedUserLanguage.value = selectedLanguage;
    };

    changeEditUserTimezone = selectedTimezone => {
        this.editedUserTimezone.value = selectedTimezone;
    };

    changeEditUserDepartment = (key, value) => {
        console.log(logPrefix, 'changeEditUserDepartment = ', key, value);
        this[key] = value;
        // this.editedUser.department = enteredDepartment;
    };

    changeEditUserName = name => {
        this.editedUser.name = name;
    };

    changeEditUserEmail = email => {
        this.editedUser.email = email;
    };

    *modifyUserPassword(findUserEmail) {
        console.log(logPrefix, 'Start get modifyUserPassword');
        try {
            const params = { currentPassword: this.currentPassword, newPassword: this.newPassword };
            const response = yield axios.put(this.serverContextPath + `${USER_API_URL}/password/${findUserEmail}`, params);
            console.log('Success modify user password');
            console.log('result = ', response);
            this.initPasswordChange();
            this.changePasswordDialogState(false);
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed modifyUserPassword', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed modifyUserPassword', e.request);
            } else {
                console.log(logPrefix, 'Failed modifyUserPassword', e.message);
            }
            this.changeModifyResultDialogMsgId('msg.incorrect_password');
            this.changeModifyResultDialogState(true);
        }
    }

    *modifyUserInfo(findUserEmail, callbacks) {
        console.log(logPrefix, 'Start get userInfo');
        try {
            const params = {
                user: { ...this.editedUser },
                configs: [{ ...this.editedUserTimezone }, { ...this.editedUserLanguage }, { ...this.editedUserLicenseKey }],
            };
            const response = yield axios.put(this.serverContextPath + `${USER_API_URL}/${findUserEmail}`, params);

            const responseUser = response.data.user;
            const responseConfigs = response.data.configs;

            if (this.user.name !== responseUser.name) {
                callbacks && callbacks.reSetLoginUserName(responseUser.email);
            }
            this.user = responseUser;
            this.configs = responseConfigs;

            callbacks && callbacks.closeEditMode();

            this.modifyResultDialogMsgId = 'msg.changed_profile';
            this.changeModifyResultDialogState(true);
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed modifyUserInfo', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed modifyUserInfo', e.request);
            } else {
                console.log(logPrefix, 'Failed modifyUserInfo', e.message);
            }

            this.modifyResultDialogMsgId = 'msg.error_changed_profile';

            this.changeModifyResultDialogState(true);
        }
    }

    *createUserLicenseKey(licenseKey) {
        console.log(logPrefix, 'Start createUserLicenseKey license key = ', licenseKey);
        if (!licenseKey) {
            return;
        }

        try {
            const userEmail = this.user?.email;
            if (!userEmail) {
                return;
            }

            this.createUserLicenseKeyState = FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.Pending;
            const params = { userEmail, licenseKey };
            yield axios.post(this.serverContextPath + `${USER_API_URL}/license-key`, params);

            this.createUserLicenseKeyState = FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.Success;

            this.getUserInfo(userEmail);

            this.changeLicenseAlertOpenState(false);
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed createUserLicenseKey', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed createUserLicenseKey', e.request);
            } else {
                console.log(logPrefix, 'Failed createUserLicenseKey', e.message);
            }
            this.createUserLicenseKeyState = FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.Failed;
        } finally {
            this.isCreateUserLicenseKeyResultDialogState = true;
        }
    }

    *getUserInfo(findUserEmail) {
        console.log(logPrefix, `Start get userInfo, find user email = ${findUserEmail}`);
        try {
            const response = yield axios.get(this.serverContextPath + `${USER_API_URL}/${findUserEmail}`);
            console.log('Success getUserInfo = ', response.data);
            if (response.data !== null) {
                if (response.data.user) {
                    this.user = response.data.user;
                    if (this.user.department && this.user.department.split(',').length) {
                        this.userGrade = this.user.department.split(',')[0];
                        this.userClass = this.user.department.split(',')[1];
                    }
                }

                this.configs = [];

                if (response.data.configs.length) {
                    this.configs = response.data.configs;
                }
            }
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getUserInfo', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getUserInfo', e.request);
            } else {
                console.log(logPrefix, 'Failed getUserInfo', e.message);
            }
        }
    }

    *getUserLoginHistory(userEmail) {
        console.log(logPrefix, `Start getUserLoginHistory userEmail = ${userEmail}`);

        try {
            const response = yield axios.get(this.serverContextPath + `${USER_API_URL}/history/login/${userEmail}`);
            console.log(logPrefix, `Success getUserLoginHistory result = {}`, response.data);
            this.isFirstLogin = response.data;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed getUserLoginHistory', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed getUserLoginHistory', e.request);
            } else {
                console.log(logPrefix, 'Failed getUserLoginHistory', e.message);
            }
        }
    }

    *requestSendFindPasswordEmail() {
        this.findPasswordEmailSendResult = FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.Pending;
        const email = this.findPasswordEmail;
        console.log(logPrefix, 'Start requestSendFindPasswordEmail');
        console.log(logPrefix, 'email = {}', email);

        try {
            const response = yield axios.post(this.serverContextPath + `${USER_API_URL}/password/find/${email}`);
            console.log(logPrefix, 'Success requestSendFindPasswordEmail = {}', response);
            this.findPasswordEmailSendResult = FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.Success;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed requestSendFindPasswordEmail', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
                if (e.response.data.errorCode === FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.NotAcceptableId) {
                    this.findPasswordEmailSendResult = FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.NotAcceptableId;
                    return false;
                }
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestSendFindPasswordEmail', e.request);
            } else {
                console.log(logPrefix, 'Failed requestSendFindPasswordEmail', e.message);
            }
            this.findPasswordEmailSendResult = FIND_PASSWORD_EMAIL_SEND_RESULT_STATE.Failed;
        }
    }

    *requestResetPassword(emailAuthToken, email, callbacks) {
        const params = { newPassword: this.resetPassword, confirmPassword: this.confirmationResetPassword };
        console.log(logPrefix, 'Start requestResetPassword params = {}', params);

        try {
            yield axios.put(this.serverContextPath + `${USER_API_URL}/password/reset/${email}`, params, {
                headers: { 'Email-Auth-Token': emailAuthToken },
            });
            callbacks.moveTo('/passwordcomplete');
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed requestResetPassword', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed requestResetPassword', e.request);
            } else {
                console.log(logPrefix, 'Failed requestResetPassword', e.message);
            }
        }
    }

    *modifySimpleUserInfo(callbacks) {
        const params = this.simpleEditUserName;

        try {
            const response = yield axios.put(this.serverContextPath + `${USER_API_URL}/modify/simple/${this.user.email}`, null, {
                params: { userName: params },
            });
            console.log(logPrefix, 'Success modifySimpleUserInfo');
            console.log(logPrefix, 'Result = ', response.data);
            this.user = response.data;
            callbacks && callbacks.closeDialog();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed modifySimpleUserInfo', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed modifySimpleUserInfo', e.request);
            } else {
                console.log(logPrefix, 'Failed modifySimpleUserInfo', e.message);
            }
        }
    }

    *withdrawUser(requestUserEmail, callbacks) {
        try {
            const response = yield axios.put(this.serverContextPath + `${USER_API_URL}/withdraw/${requestUserEmail}`);
            console.log(logPrefix, 'Success withdrawUser');
            console.log(logPrefix, 'Result = ', response.data);
            this.initUserStore();
            callbacks && callbacks.invalidateLoginInfo();
            callbacks && callbacks.moveWithdrawConfirm();
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed withdrawUser', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
                if (e.response.data.errorCode === 'BadRequest') {
                    this.changeModifyResultDialogState(true);
                    this.changeModifyResultDialogMsgId('msg.can_not_withdraw_group_leader');
                    return false;
                }
            } else if (e.request) {
                console.log(logPrefix, 'Failed withdrawUser', e.request);
            } else {
                console.log(logPrefix, 'Failed withdrawUser', e.message);
            }
            callbacks && callbacks.errorMessage();
        }
    }

    *checkCoachState(userEmail) {
        console.log(logPrefix, `Start checkCoachState userEmail = ${userEmail}`);
        try {
            this.coachState = Object.assign({}, EmptyCoachState);
            const response = yield axios.get(this.serverContextPath + `${USER_API_URL}/coach/${userEmail}`);
            console.log(logPrefix, `Success checkCoachState result = {}`, response.data);
            this.coachState = response.data;
        } catch (e) {
            if (e.response) {
                console.log(logPrefix, 'Failed checkCoachState', e.response.data);
                console.log(' >> ', e.response.status);
                console.log(' >> ', e.response.headers);
            } else if (e.request) {
                console.log(logPrefix, 'Failed checkCoachState', e.request);
            } else {
                console.log(logPrefix, 'Failed checkCoachState', e.message);
            }
        }
    }
}
