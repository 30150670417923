import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import WritingBoardCategoryComponent from './WritingBoardCategoryComponent';
import WritingBoardComponent from './WritingBoardComponent';
import { withRouter } from '../../components/WithRouter';
import Box from '@material-ui/core/Box';
import WritingBoardTopBarComponent from './WritingBoardTopBarComponent';
const styles = theme => ({
    root: {
        width: '100%',
        boxSizing: 'border-box',
    },
    rootIn: {
        '@media all and (min-width: 1500px)': {
            width: 1440,
        },
        width: 1200,
        margin: '0 auto',
    },
});
class WritingBoardMainComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;

        const pathArray = this.props.location.pathname.split('/');
        const groupId = parseInt(pathArray[2]);

        return (
            <div className={classes.root}>
                <WritingBoardTopBarComponent />
                <Box className={classes.rootIn}>
                    <Box display="flex" alignItems="flex-start" justifyContnet="flex-start">
                        <WritingBoardCategoryComponent groupId={groupId} />
                        <WritingBoardComponent groupId={groupId} />
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(WritingBoardMainComponent));
