import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MobileProfileEditDialogComponent from './dialog/MobileProfileEditDialogComponent';
import MobileRoomOpenDialogComponent from './dialog/MobileRoomOpenDialogComponent';
import MobileCommunityDialogComponent from './dialog/MobileCommunityDialogComponent';
import { withRouter } from '../../../components/WithRouter';
import MobileWithdrawalDialogComponent from './dialog/MobileWithdrawalDialogComponent';
import MobileDeleteDialogComponent from './dialog/MobileDeleteDialogComponent';
import MobileMemberRequestComponent from './MobileMemberRequestComponent';
import MobileAddAdminComponent from './MobileAddAdminComponent';
import MobileMemberWithdrawalComponent from './MobileMemberWithdrawalComponent';
import MobileMemberPermissionSettingComponent from './MobileMemberPermissionSettingComponent';
import MobileMainControlComponent from './MobileMainControlComponent';
import { inject, observer } from 'mobx-react';
import { checkSpace } from '../../../common/Validation';
import { Route, Routes } from 'react-router-dom';
import { PATH_UTIL } from '../../../common/util/path.util';
import { ClassMainPath } from '../../../common/ClassMainPath';
import { LmsGroupUserType } from '../../../stores/ClassStore';
import { ToastsStore } from 'react-toasts';

export const SettingsInnerPath = {
    approval: 'approval',
    admin: 'admin',
    withdrawal: 'withdrawal',
    permission: 'permission',
};

const styles = _theme => ({
    root: {
        width: 'calc(100vw - 32px)',
        paddingBottom: 70,
    },
});

export const ButtonNames = {
    ProfileDialogOpen: 'profileDialogOpen',
    NoticeDialogOpen: 'noticeDialogOpen',
    RoomOpenDialog: 'roomOpenDialog',
    MemberRequest: 'memberRequest',
    AddAdmin: 'addAdmin',
    MemberWithdrawal: 'memberWithdrawal',
    MemberPermissionSetting: 'memberPermissionSetting',
    CommunityDialogOpen: 'communityDialogOpen',
    WithdrawalDialogOpen: 'withdrawalDialogOpen',
    DeleteDialogOpen: 'deleteDialogOpen',
};

class MobileSettingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            joinSwitch: false,

            //dialog
            profileDialogOpen: false,
            noticeDialogOpen: false,
            roomOpenDialog: false,
            communityDialogOpen: false,
            withdrawalDialogOpen: false,
            deleteDialogOpen: false,
            memberRequest: false,
            addAdmin: false,
            memberWithdrawal: false,
            memberPermissionSetting: false,
            isAvailableApprovalSwitch: false,
        };
    }

    componentDidMount() {
        const { loginUser, selectedGroupDetail, location, params } = this.props;
        // this.props.userStore.getUserInfo(loginUser.email);
        const groupId =
            selectedGroupDetail.group.id !== null && selectedGroupDetail.group.id !== ''
                ? selectedGroupDetail.group.id
                : PATH_UTIL.getClassId(location.pathname);
        this.props.classStore.getGroupJoinApprovals(groupId, loginUser.email);
    }

    handleClickProfileDialogOpen = () => {
        this.setState({ profileDialogOpen: true });
    };

    handleClickNoticeDialogOpen = () => {
        this.setState({ noticeDialogOpen: true });
    };

    handleClickRoomModifyOpen = e => {
        const groupId = this.props.selectedGroupDetail.group.id;
        this.props.navigate(`/roommodify/${groupId}`);
    };

    handleClickRoomOpenDialog = () => {
        this.setState({ roomOpenDialog: true });
    };

    handleClickCommunityDialogOpen = () => {
        this.setState({ communityDialogOpen: true });
    };

    handleClickWithdrawalDialogOpenDialogOpen = () => {
        this.setState({ withdrawalDialogOpen: true });
    };

    handleClickDeleteDialogOpen = () => {
        this.setState({ deleteDialogOpen: true });
    };

    handleChangeDialogOpen = (targetName, isOpen) => {
        this.setState({ [targetName]: isOpen });
    };

    handleChangeComponentOpen = (targetName, _isOpen) => {
        const { navigate } = this.props;
        navigate(targetName);
    };

    handleChangeJoinSwitch = _event => {
        this.handleModifyGroupJoinApprovalState();
    };

    handleChangeUserName = e => {
        checkSpace(e);
        this.props.userStore.changeSimpleEditUserName(e.target.value);
    };

    handleModifyUserName = () => {
        this.props.userStore.modifySimpleUserInfo({
            closeDialog: () => {
                this.handleChangeDialogOpen(ButtonNames.ProfileDialogOpen, false);
            },
        });
    };

    handleModifyUserAvatarImage = () => {
        this.props.avatarStore._uploadAvatarImage();
    };

    handleChangeEditPublicGroup = type => {
        this.props.classStore.changeEditPublicGroup(type);
    };

    handleModifyGroupOpenState = () => {
        const { selectedGroupDetail } = this.props;
        const { userDetail } = this.props.userStore;
        this.props.classStore.modifyGroupOpenState(selectedGroupDetail.group.id, userDetail.email, {
            closeRoomOpenDialog: () => {
                this.handleChangeDialogOpen(ButtonNames.RoomOpenDialog, false);
            },
        });
    };

    handleModifyGroupJoinApprovalState = () => {
        const { selectedGroupDetail } = this.props;
        const { userDetail } = this.props.userStore;
        this.props.classStore.modifyGroupJoinApprovalState(selectedGroupDetail.group.id, userDetail.email);
    };

    handleUserGroupApprovalState = (operation, requestApproval) => {
        const { userDetail } = this.props.userStore;
        this.props.classStore.operateUserJoinApprovalState(operation, requestApproval, userDetail.email, {
            moveToMemberTab: () => {
                this.props.classMainNavigate(ClassMainPath.member, { replace: true });
            },
        });
    };

    handleAddAdmin = selectedNewAdmins => {
        const { userDetail } = this.props.userStore;
        this.props.classStore.changeGroupUserType(selectedNewAdmins, userDetail.email, LmsGroupUserType.operator);
    };

    handleExceptAdmin = adminExceptMember => {
        const { userDetail } = this.props.userStore;
        const params = [adminExceptMember];
        this.props.classStore.changeGroupUserType(params, userDetail.email, LmsGroupUserType.member);
    };

    moveTo = settingInnerPath => {
        this.props.navigate(settingInnerPath);
    };

    requestGroupAuthorities = () => {
        const { selectedGroupDetail, location } = this.props;
        const groupId =
            selectedGroupDetail.group.id !== null && selectedGroupDetail.group.id !== ''
                ? selectedGroupDetail.group.id
                : PATH_UTIL.getClassId(location.pathname);

        this.props.classStore.getGroupAuthorities(groupId);
    };

    handleModifyGroupAuthority = (featureKey, authorityName) => {
        const { loginUser } = this.props.authStore;
        this.props.classStore.modifyGroupAuthority(loginUser.email, featureKey, authorityName, {
            error: () => {
                ToastsStore.error('서버와의 연결이 원활하지 않습니다. 잠시 후 다시 시도해 주세요.', 1500);
            },
        });
    };

    handleDeleteGroup = () => {
        const { loginUser } = this.props.authStore;
        const { selectedGroupDetail } = this.props;
        this.props.classStore.requestDeleteGroup(loginUser.email, selectedGroupDetail.group.id, {
            closeDialog: () => {
                this.handleChangeDialogOpen(ButtonNames.DeleteDialogOpen, false);
            },
            moveToRooms: () => {
                this.props.classMainNavigate('/rooms');
            },
            alert: () => {
                ToastsStore.warning('남아있는 클래스 멤버를 확인해주세요.', 1500);
            },
            error: () => {
                ToastsStore.error('서버와의 연결이 원활하지 않습니다. 잠시 후 다시 시도해 주세요.', 1500);
            },
        });
    };

    handleLeaveGroup = () => {
        const { loginUser } = this.props.authStore;
        const { selectedGroupDetail } = this.props;
        this.props.classStore.requestLeaveGroup(loginUser.email, selectedGroupDetail.group.id, {
            doSuccessAction: () => {
                this.props.classMainNavigate('/rooms');
            },
            alert: () => {
                ToastsStore.warning('잘못된 요청입니다. 클래스 정보를 확인해주세요.', 1500);
            },
            error: () => {
                ToastsStore.error('서버와의 연결이 원활하지 않습니다. 잠시 후 다시 시도해 주세요.', 1500);
            },
        });
    };

    handleForcedLeaveGroupUser = () => {
        const { selectedGroupDetail } = this.props;
        const { selectedForcedLeaveGroupUserEmail } = this.props.classStore;
        this.props.classStore.requestLeaveGroup(selectedForcedLeaveGroupUserEmail, selectedGroupDetail.group.id, {
            doSuccessAction: () => {
                this.props.classStore.getGroupDetail(selectedGroupDetail.group.id);
            },
            alert: () => {
                ToastsStore.warning('잘못된 요청입니다. 클래스 정보를 확인해주세요.', 1500);
            },
            error: () => {
                ToastsStore.error('서버와의 연결이 원활하지 않습니다. 잠시 후 다시 시도해 주세요.', 1500);
            },
        });
    };

    findAvatar = user => {
        if (!user.image) {
            this.props.avatarStore.findAvatar(user.userId, {
                setImage: data => {
                    user.image = data;
                },
            });
        }
        return user;
    };

    render() {
        const { classes, selectedGroupDetail, params } = this.props;
        const { userDetail, isModifySimpleEditUserName } = this.props.userStore;
        const {
            editPublicGroup,
            groupLeader,
            groupMembers,
            isGroupModifyLoading,
            groupJoinApprovals,
            groupLeaderAndOperators,
            groupAuthorities,
            groupAuthorityMap,
            isGroupAuthorityLoading,
            groupAllMembers,
            groupAllMemberAndOperators,
            setSelectedForcedLeaveGroupUserEmail,
            groupUserAuthority,
        } = this.props.classStore;
        const { loadAvatarImage, saveAvatarImage } = this.props.avatarStore;
        const innerPathName = params['*'];
        return (
            <div className={classes.root}>
                {innerPathName === '' && (
                    <MobileMainControlComponent
                        groupLeader={groupLeader}
                        groupAllMembers={groupAllMembers}
                        groupMembers={groupMembers}
                        groupLeaderAndOperators={groupLeaderAndOperators}
                        groupJoinApprovals={groupJoinApprovals}
                        isModifyLoading={isGroupModifyLoading}
                        groupDetail={selectedGroupDetail}
                        userDetail={userDetail}
                        joinSwitch={this.state.joinSwitch}
                        handleChangeDialogOpen={targetName => this.handleChangeDialogOpen(targetName, true)}
                        handleChangeComponentOpen={targetName => this.handleChangeComponentOpen(targetName, true)}
                        handleChangeJoinSwitch={this.handleChangeJoinSwitch}
                        handleClickRoomModifyOpen={this.handleClickRoomModifyOpen}
                        groupUserAuthority={groupUserAuthority}
                        loadAvatarImage={loadAvatarImage}
                    />
                )}

                <Routes>
                    <Route
                        path={SettingsInnerPath.approval}
                        element={
                            <MobileMemberRequestComponent
                                groupJoinApprovals={groupJoinApprovals}
                                changeUserApprovalState={(operation, approval) => this.handleUserGroupApprovalState(operation, approval)}
                            />
                        }
                    />
                    <Route
                        path={SettingsInnerPath.admin}
                        element={
                            <MobileAddAdminComponent
                                moveTo={this.moveTo}
                                groupMembers={groupMembers}
                                groupLeaderAndOperators={groupLeaderAndOperators}
                                groupDetail={selectedGroupDetail}
                                addAdmin={selectedNewAdmins => this.handleAddAdmin(selectedNewAdmins)}
                                exceptAdmin={selectedExceptAdmin => this.handleExceptAdmin(selectedExceptAdmin)}
                            />
                        }
                    />
                    <Route
                        path={SettingsInnerPath.withdrawal}
                        element={
                            <MobileMemberWithdrawalComponent
                                isDeleteDialogOpen={this.state.deleteDialogOpen}
                                closeDeleteDialog={() => this.handleChangeDialogOpen(ButtonNames.DeleteDialogOpen, false)}
                                groupAllMemberAndOperators={groupAllMemberAndOperators}
                                forcedLeaveGroupUser={this.handleForcedLeaveGroupUser}
                                setSelectedForcedLeaveGroupUserEmail={setSelectedForcedLeaveGroupUserEmail}
                                findAvatar={this.findAvatar}
                            />
                        }
                    />
                    <Route
                        path={SettingsInnerPath.permission}
                        element={
                            <MobileMemberPermissionSettingComponent
                                groupAuthorities={groupAuthorities}
                                requestGroupAuthorities={this.requestGroupAuthorities}
                                groupAuthority={groupAuthorityMap}
                                isGroupAuthorityLoading={isGroupAuthorityLoading}
                                modifyGroupAuthority={this.handleModifyGroupAuthority}
                            />
                        }
                    />
                </Routes>

                {/*{memberRequest && <MemberRequestComponent />}*/}

                {/*{addAdmin && <AddAdminComponent />}*/}

                {/*{memberWithdrawal && <MemberWithdrawalComponent />}*/}

                {/*{memberPermissionSetting && <MemberPermissionSettingComponent />}*/}

                {/* dialog */}
                <MobileProfileEditDialogComponent
                    user={userDetail}
                    isModify={isModifySimpleEditUserName}
                    profileDialogOpen={this.state.profileDialogOpen}
                    handleClose={targetName => this.handleChangeDialogOpen(targetName, false)}
                    handleChangeUserName={this.handleChangeUserName}
                    handleModifyUserName={this.handleModifyUserName}
                    handleModifyUserAvatar={this.handleModifyUserAvatarImage}
                    loadAvatarImage={loadAvatarImage}
                    saveAvatarImage={saveAvatarImage}
                />
                {/*<NoticeChangeDialogComponent*/}
                {/*    noticeDialogOpen={this.state.noticeDialogOpen}*/}
                {/*    handleClose={targetName => this.handleChangeDialogOpen(targetName, false)}*/}
                {/*/>*/}
                <MobileRoomOpenDialogComponent
                    groupDetail={selectedGroupDetail}
                    editPublicGroup={editPublicGroup}
                    handleChangeIsPublic={this.handleChangeEditPublicGroup}
                    handleModifyGroupOpenState={this.handleModifyGroupOpenState}
                    roomOpenDialog={this.state.roomOpenDialog}
                    handleClose={targetName => this.handleChangeDialogOpen(targetName, false)}
                />
                <MobileCommunityDialogComponent
                    communityDialogOpen={this.state.communityDialogOpen}
                    handleClose={targetName => this.handleChangeDialogOpen(targetName, false)}
                />
                <MobileWithdrawalDialogComponent
                    userDetail={userDetail}
                    groupLeader={groupLeader}
                    groupMembers={groupMembers}
                    groupAllMembers={groupAllMembers}
                    groupDetail={selectedGroupDetail}
                    withdrawalDialogOpen={this.state.withdrawalDialogOpen}
                    handleClose={targetName => this.handleChangeDialogOpen(targetName, false)}
                    deleteGroup={this.handleDeleteGroup}
                    leaveGroup={this.handleLeaveGroup}
                />
                <MobileDeleteDialogComponent
                    deleteGroup={this.handleDeleteGroup}
                    deleteDialogOpen={this.state.deleteDialogOpen}
                    groupAllMemberAndOperators={groupAllMemberAndOperators}
                    handleClose={targetName => this.handleChangeDialogOpen(targetName, false)}
                    handleChangeMemberWithdrawal={targetName => this.handleChangeComponentOpen(targetName, true)}
                />
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('userStore', 'classStore', 'authStore', 'avatarStore')(observer(MobileSettingComponent))));
