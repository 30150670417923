import React, { Component, forwardRef } from 'react';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { Button, createTheme, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import { inject, observer } from 'mobx-react';
import { DATE_UTIL } from '../../../common/util/date.util';
import QuizDetailDialog from './QuizDetailDialog';
import { ReactComponent as ProhibitIcon } from '../../../common/images/ProhibitIcon.svg';
import { FormattedMessage, injectIntl } from 'react-intl';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const style = theme => ({
    root: {
        marginLeft: 10,
        '& .MuiTableHead-root': {
            marginTop: 75,
        },
    },
    titleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 16,
        fontWeight: 600,
        color: '#0d0d0d',
        marginBottom: 30,
    },
    imageText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 18,
        fontWeight: 800,
        color: '#0d0d0d',
    },
    noDataBox: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate( -50%, -50% )',
    },
    materialTable: {
        ...theme,
    },
});

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
class MemberActivity extends Component {
    // ellipsisText = text => (text.length > 10 ? text.substr(0, 10) + '...' : text);
    render() {
        const { classes, intl, statisticsStore, userSummaryList } = this.props;

        const chartData = [];
        userSummaryList.forEach(us => {
            chartData.push({
                roomId: us.roomId,
                externalKey: us.externalKey,
                userName: us.userName,
                enterDatetime:
                    us.enterDatetime !== null
                        ? DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(us.enterDatetime), 'YYYY. M. D A hh:mm:ss')
                        : '',
                exitDatetime:
                    us.exitDatetime !== null
                        ? DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(us.exitDatetime), 'YYYY. M. D A hh:mm:ss')
                        : '',
                playTime: parseInt(us.playTime / 60) + intl.formatMessage({ id: 'minute' }),
                connectCount: us.historyCount,
                attendCount: us.responseCount + '/' + us.attendanceCount + intl.formatMessage({ id: 'count' }),
                // presentationCount: us.presentationCount + intl.formatMessage({ id: 'count' }),
                // questionCount: us.questionCount + intl.formatMessage({ id: 'count' }),
                quizResults: us.quizResults,
                // surveyResponse: us.surveyResponse,
            });
        });

        const columns = [
            { title: 'roomId', field: 'roomId', hidden: true },
            { title: 'externalKey', field: 'externalKey', hidden: true },
            { title: intl.formatMessage({ id: 'student' }), field: 'userName', maxWidth: 150 },
            { title: intl.formatMessage({ id: 'first_enter_time' }), field: 'enterDatetime', maxWidth: 300 },
            { title: intl.formatMessage({ id: 'last_exit_time' }), field: 'exitDatetime', maxWidth: 300 },
            { title: intl.formatMessage({ id: 'play_time' }), field: 'playTime', maxWidth: 80 },
            { title: intl.formatMessage({ id: 'access_count' }), field: 'connectCount', maxWidth: 100 },
            { title: intl.formatMessage({ id: 'attendance_check_count' }), field: 'attendCount', maxWidth: 100 },
            // { title: intl.formatMessage({ id: 'presentation_count' }), field: 'presentationCount' },
            // { title: intl.formatMessage({ id: 'question_count' }), field: 'questionCount' },
            {
                title: intl.formatMessage({ id: 'quiz_results' }),
                field: 'quizResults',
                maxWidth: 100,
                render: rowData =>
                    rowData.quizResults.length > 0 ? (
                        <Button
                            style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)', color: 'black' }}
                            onClick={() => this.props.statisticsStore.setQuizDetailDialogOpen(true, rowData.quizResults)}
                        >
                            <FormattedMessage id="see_more" />{' '}
                        </Button>
                    ) : (
                        ''
                    ),
            },
            // {
            //     title: intl.formatMessage({ id: 'survey_response' }),
            //     field: 'surveyResponse',
            //     render: rowData =>
            //         rowData.surveyResponse ? <Link to={`/rooms/${rowData.roomId}/users/${rowData.externalKey}/statistics`}>Yes</Link> : 'No',
            // },
        ];

        const tableTheme = createTheme();

        return (
            <div className={classes.root}>
                <Typography className={classes.titleText}>
                    <FormattedMessage id="result_student_activities" />
                </Typography>
                {chartData.length > 0 && (
                    <ThemeProvider theme={tableTheme}>
                        <MaterialTable
                            style={{
                                boxShadow: '0 3.8px 6.6px 0 rgba(0, 0, 0, 0.25)',
                                border: 'solid 0.5px #d4d4d6',
                                padding: 20,
                            }}
                            title="title"
                            icons={tableIcons}
                            columns={columns}
                            data={chartData}
                            options={{
                                paging: true,
                                pageSize: 5,
                                headerStyle: {
                                    wordBreak: 'keep-all',
                                    fontFamily: 'NanumSquareRoundOTF',
                                    fontSize: '1rem',
                                    fontWeight: 600,
                                    color: '#000',
                                },
                                cellStyle: {
                                    wordBreak: 'keep-all',
                                    fontFamily: 'NanumSquareRoundOTF',
                                    fontSize: '1rem',
                                    fontWeight: 300,
                                    color: '#000',
                                    textAlign: 'left',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                },
                                exportFileName: `학생개별활동결과`,
                                exportAllData: true,
                                exportButton: { csv: true },
                            }}
                            localization={{
                                pagination: {
                                    labelRowsSelect: intl.formatMessage({ id: 'table_rows_count' }),
                                    labelDisplayedRows: intl.formatMessage({ id: 'table_bottom_text' }),
                                },
                                toolbar: {
                                    exportTitle: '다운로드',
                                    exportAriaLabel: '다운로드',
                                    exportCSVName: 'CSV 파일 다운로드',
                                },
                            }}
                        />
                    </ThemeProvider>
                )}
                {chartData.length === 0 && !statisticsStore.getIsLoading && (
                    <div style={{ position: 'relative', width: '100%' }}>
                        <img style={{ width: '100%', height: '100%' }} src="/images/NoData_MemberActivity.png" alt="No Data" />
                        <div className={classes.noDataBox}>
                            <ProhibitIcon />
                            <Typography className={classes.imageText}>
                                <FormattedMessage id="msg.no_display_data" />
                            </Typography>
                        </div>
                    </div>
                )}

                <QuizDetailDialog />
            </div>
        );
    }
}

export default withStyles(style)(inject('statisticsStore')(observer(injectIntl(MemberActivity))));
