import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Dialog, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import TestAvatar from '../../common/images/TestAvatar.jpg';
import { ReactComponent as AsideUsersThreeIcon } from '../../common/images/AsideUsersThreeIcon.svg';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 320,
            padding: '11px 12px 0 16px',
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 601px)': {
                width: 380,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 15,
    },
    titleBoxIn: {
        '& svg': {
            width: 27,
            height: 27,
        },
    },
    titleText: {
        fontSize: '1.063rem',
        color: '#000',
        fontWeight: 600,
        marginLeft: 7,
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    boxStyle: {
        maxHeight: 300,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '15px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '5px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
    },
    avatar: {
        width: 40,
        height: 40,
        background: '#a3a8af',
        '& svg': {
            width: 25,
            height: 25,
            '& path': {
                fill: '#fff',
            },
        },
        '@media all and (max-width: 600px)': {
            width: 35,
            height: 35,
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    nameText: {
        fontSize: '0.875rem',
        color: '#0d0d0d',
        fontWeight: 600,
        marginLeft: 10,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 18,
                height: 18,
            },
        },
    },
});

class MemberProfileDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberList: [
                { img: TestAvatar, name: '김민희' },
                { img: '', name: '박은미' },
                { img: '', name: '김준석' },
                { img: TestAvatar, name: '안은찬' },
                { img: '', name: '한선아' },
                { img: '', name: '김민희' },
                { img: '', name: '박은미' },
                { img: '', name: '김준석' },
                { img: '', name: '안은찬' },
                { img: '', name: '한선아' },
            ],
        };
    }

    render() {
        const { classes, members } = this.props;

        return (
            <div className={classes.root}>
                <Dialog open={this.props.memberDialog} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" className={classes.titleBox}>
                        <Box display="flex" alignItems="center" className={classes.titleBoxIn}>
                            <AsideUsersThreeIcon />
                            <Typography className={classes.titleText}>멤버: {members && members.length}명 </Typography>
                        </Box>
                        <IconButton className={classes.iconButton} onClick={this.props.handleClose} disableRipple>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>
                    <Box className={classes.boxStyle}>
                        {members &&
                            members.map((member, i) => (
                                <Box key={i} display="flex" alignItems="center" justifyContent="flex-start" mt={1} mb={1}>
                                    <Avatar src={member.image} alt="profile image" className={classes.avatar}>
                                        <AsideUserIcon />
                                    </Avatar>
                                    <Typography className={classes.nameText}>{member.name}</Typography>
                                </Box>
                            ))}
                    </Box>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(MemberProfileDialogComponent);
