import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MobileClassContentInfoComponent from './MobileClassContentInfoComponent';
import MobileClassContentSearchComponent from './MobileClassContentSearchComponent';

const styles = _theme => ({
    root: {
        margin: '8px auto',
        padding: '0 16px',
        '& ul, li': {
            listStyle: 'none',
            padding: 0,
            margin: 0,
        },
    },
});

class MobileClassAsideComponent extends Component {
    render() {
        const { classes, selectedGroupDetail, isMember, classPath, handleChangeSearch, scrollMove } = this.props;
        return (
            <div className={classes.root}>
                <MobileClassContentInfoComponent moveTo={this.props.moveTo} selectedGroupDetail={selectedGroupDetail} isMember={isMember} />
                <MobileClassContentSearchComponent
                    scrollMove={scrollMove}
                    classPath={classPath}
                    classTab={this.props.classTab}
                    classSelectTag={this.props.classSelectTag}
                    handleChangeSearch={handleChangeSearch}
                    noticeBoard={this.props.noticeBoard}
                />
            </div>
        );
    }
}

export default withStyles(styles)(MobileClassAsideComponent);
