// const OAUTH_PLATFORM_NAME = {
//     KAKAO: 'kakao',
//     NAVER: 'naver',
//     FACEBOOK: 'facebook',
//     GOOGLE: 'google',
// };
const OAUTH_HOST = {
    kakao: 'https://kauth.kakao.com',
    naver: '',
    facebook: '',
    google: '',
};
class OauthUtil {
    constructor() {
        this.redirectURI = '';
        this.OAUTH_URL = {
            kakao: clientId => {
                return `${OAUTH_HOST.kakao}/oauth/authorize?client_id=${clientId}&redirect_uri=${this.redirectURI}&response_type=code`;
            },
            naver: '',
            facebook: '',
            google: '',
        };
    }

    setOAuthRedirectUri = uri => {
        this.redirectURI = uri;
    };

    getOAuthURL = (platform, clientId) => {
        return this.OAUTH_URL[platform](clientId);
    };
}

export const OAUTH_UTIL = new OauthUtil();
