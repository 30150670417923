import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import SurveyCreateComponent from './survey/SurveyCreateComponent';
import { inject, observer } from 'mobx-react';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            [theme.breakpoints.up('xl')]: {
                maxWidth: 1450,
            },
            maxWidth: 1150,
            width: 1240,
            padding: '24px 15px 24px 24px',
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
        },
        '& .MuiDialog-paper': {
            margin: 0,
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 30,
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    createContents: {
        height: 'calc(100vh - 230px )',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 10,
        },
    },
    btnStyle: {
        padding: '9px 38px',
        fontSize: '0.875rem',
        fontWeight: 800,
        marginRight: 10,
        borderRadius: 7,
        border: '1px solid #000',
        color: '#000',
        background: '#fff',
        '&:hover': {
            background: '#fff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    btnStyle1: {
        background: '#0097ff',
        color: '#fff',
        border: 0,
        '&:hover': {
            background: '#0097ff',
        },
    },
});

class SurveyDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Dialog open={this.props.surveyDialogOpen} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" className={classes.titleBox}>
                        <Typography className={classes.titleText}>설문조사 등록</Typography>
                        <IconButton className={classes.iconButton} onClick={this.props.handleCloseSurveyDialogOpen} disableRipple>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        <Box className={classes.createContents}>
                            <SurveyCreateComponent />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            style={{
                                width: '50%',
                                paddingRight: 25,
                                boxSizing: 'border-box',
                            }}
                        >
                            <Button className={classes.btnStyle}>취소</Button>
                            <Button className={clsx(classes.btnStyle, classes.btnStyle1)}>저장</Button>
                        </Box>
                    </Box>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(inject('classStore')(observer(SurveyDialogComponent)));
