import {RadialBar} from '@nivo/radial-bar';
import React from "react";

const GradientRadialBarChart = ({h, s, l, value, id}) => {
    const Metric = ({ center }) => {
        return (
            <text
                x={center[0]}
                y={center[1]}
                textAnchor="middle"
                dominantBaseline="central"
            >
                <tspan style={{ fontSize: 80, fontWeight: "bold", fill: `hsl(${h}, ${s}%, ${l}%)` }}>{value}</tspan>
                <tspan style={{ fontSize: 30, fill: '#000' }}> 점</tspan>
            </text>
        );
    };

    return (
        <RadialBar
            width={250}
            height={250}
            valueFormat={(v) => `${v}%`}
            maxValue={100}
            startAngle={90}
            endAngle={450}
            cornerRadius={30}
            innerRadius={0.6}
            colors={[`url(#${id}gradient)`]}
            tracksColor={`url(#${id}trackGradient)`}
            data={[
                {
                    id: "default",
                    data: [{ x: "percentage", y: `${value}` }]
                }
            ]}
            layers={["tracks", "bars", Metric]}
            defs={[
                {
                    id: `${id}gradient`,
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: `hsl(${h}, ${s}%, ${l}%)` },
                        { offset: 100, color: `hsl(${h + 25}, ${s}%, ${l}%)` }
                    ]
                },
                {
                    id: `${id}trackGradient`,
                    type: 'linearGradient',
                    colors: [
                        { offset: 0, color: `hsl(${h}, ${s}%, ${l + 20}%)` },
                        { offset: 100, color: `hsl(${h + 25}, ${s}%, ${l + 20}%)` }
                    ]
                }
            ]}
            fill={[{ match: '*', id: `${id}gradient` }]}
        />
    );
}

export default GradientRadialBarChart;
