import React from 'react';
import { inject, observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import * as Params from '../../common/util/Params';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { Avatar, Dialog, DialogContent, FormControl, InputLabel, Select } from '@material-ui/core';
import { AvatarHeight, AvatarWidth, BackgroundColor, BackgroundOption } from '../../stores/AvatarStore';
import Popover from '@material-ui/core/Popover';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { ReactComponent as PersonIcon } from '../../common/images/PersonIcon.svg';
import { ReactComponent as VideocamRoundedIcon } from '../../common/images/VideocamRoundedIcon.svg';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ReactComponent as CameraIcon } from '../../common/images/CameraIcon.svg';
// import PrivacyDialog from "../../common/PrivacyDialog";

const styles = theme => ({
    mainContainer: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
        },
        backgroundColor: '#fff',
        width: '100%',
        '& .MuiButton-outlinedPrimary:hover': {
            background: '#fff',
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    standbyButton: {
        borderRadius: '8px',
        minWidth: '100%',
        minHeight: '44px',
        boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.45)',
    },
    contentsBox: {
        marginBottom: 20,
    },
    titleBox: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '0',
        },
        width: '100%',
        marginBottom: '16px',
        padding: '13px 0',
        borderBottom: '1px solid #cdced0',
    },
    titleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '18',
        fontWeight: 600,
        color: '#000',
        textAlign: 'left',
    },
    titleClose: {
        position: 'relative',
        top: '-10px',
        left: '30px',
    },
    box: {
        width: '100%',
        '& .MuiLinearProgress-colorPrimary': {
            background: 'grey',
        },
        '& .MuiLinearProgress-root': {
            height: '13px',
        },
        '& .MuiLinearProgress-barColorPrimary': {
            background: 'rgb(44,196,44)',
        },
    },
    selectBox: {
        height: '37px',
        borderColor: '#cbcaca',
        '& .MuiSelect-select:focus': {
            backgroundColor: 'transparent',
        },
    },
    selectLabel: {
        color: '#333',
    },
    soundBar: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginLeft: 0,
        },
        width: '85%',
        marginLeft: 45,
    },
    playButton: {
        minWidth: '37px',
        width: '37px',
        height: '37px',
        borderRadius: '50%',
        background: '#f2441d',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '15px',
        fontWeight: '600',
        color: '#fff',
        '&:hover': {
            background: '#f2441d',
        },
    },
    bottomButton: {
        [theme.breakpoints.down('xs')]: {
            width: '30%',
            fontSize: 13,
        },
        width: '33%',
        padding: '10px 0',
        background: '#f1f1f1',
        color: '#808080',
        borderRadius: '6px',
        fontFamily: 'NanumSquareRoundOTF',
        marginRight: '10px',
        boxShadow: 'none',
        fontSize: 14,
        '&:hover': {
            background: '#808080',
            color: '#fff',
            boxShadow: 'none',
        },
    },
    footerBanner: {
        '& img': {
            maxWidth: '100%',
        },
    },

    // blinkAni: {
    //     animationName: '$blink',
    //     animationDuration: '1s',
    //     animationTimingFunction: 'step-end',
    //     animationIterationCount: 'infinite'
    // },

    // /* for MS계열 브라우저 */
    // "@keyframes blink": {
    //     "0%": {backgroundColor: "inherit", color: "#f2441d"},
    //     "50%": {backgroundColor: theme.palette.primary.main, color: "white"},
    //     "100%": {backgroundColor: "inherit", color: "#f2441d"}
    // },

    /* for Chrome, Safari */
    // "@-webkit-keyframes blink": {
    //     "0%": {backgroundColor: "inherit", color: "black"},
    //     "50%": {backgroundColor: theme.palette.primary.main, color: "white"},
    //     "100%": {backgroundColor: "inherit", color: "black"}
    // },
    mailBox: {
        [theme.breakpoints.down('xs')]: {
            border: '0',
            boxShadow: 'none',
        },
        width: '100%',
        height: '70px',
        border: 'solid 1px #dedede',
        backgroundColor: '#ffffff',
    },
    colorDiv: {
        width: 54,
        height: 54,
        border: 'none',
    },
    selectedColorDiv: {
        width: 54,
        height: 54,
        border: 'solid 2px #dedede',
    },
    avatarBtn: {
        width: 85,
        padding: '8px 0',
        borderRadius: 4,
        border: 'solid 1px #c0c2c3',
        background: 'transparent',
        marginLeft: 10,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        color: '#0d0d0d',
    },
    closeBtn: {
        width: 85,
        padding: '7px 0',
        borderRadius: 4,
        marginLeft: 10,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        fontWeight: 600,
        border: '1px solid #000',
        color: '#000',
        background: '#fff',
        '&:hover': {
            background: '#fff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    saveBtn: {
        width: 85,
        padding: '7px 0',
        borderRadius: 4,
        marginLeft: 10,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        color: '#fff',
        // background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor),
        background: '#0097ff',
        fontWeight: 600,
        '&:hover': {
            // background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor),
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    fileBtn: {
        width: 85,
        padding: '7px 0',
        borderRadius: 4,
        marginLeft: 10,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 11,
        color: '#fff',
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor),
        fontWeight: 600,
        border: 0,
        '&:hover': {
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor),
            border: 0,
        },
    },
    avatarBgBox: {
        borderRadius: 6,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.45)',
        background: '#fff',
        padding: '27px 10px',
        width: 310,
        '& .MuiFormControlLabel-label': {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#000',
        },
        '& .MuiRadio-colorPrimary.Mui-checked': {
            color: '#0097ff',
        },
    },
    pictureBtn: {
        padding: '8px 17px',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        marginLeft: 15,
        marginTop: 18,
        height: 30,
        borderRadius: 4,
        border: 'solid 1px #c0c2c3',
        background: 'transparent',
        '&:hover': {
            background: 'transparent',
            border: 'solid 1px #c0c2c3',
        },
    },
    fileBox: {
        margin: '10px auto',
        '& .Mui-disabled': {
            border: 0,
        },
    },
    cameraBox: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: 35,
        height: 35,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#e1e1e1',
        border: '2px solid #fff',
    },
    buttonStyle: {
        background: 'transparent',
        position: 'relative',
    },
    textButtonStyle: {
        background: 'transparent',
        height: 40,
        borderRadius: 7,
        border: '1px solid #0097ff',
        color: '#0097ff',
        marginLeft: 24,
        fontSize: '0.938rem',
        fontWeight: 600,
        padding: '13px 18px',
        '&:hover': {
            background: 'transparent',
        },
    },
});

const TEXT_LENGTH = 23;
const LogPrefix = '[MakeProfile] ';

class MakeAvatar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
        this.avatarRef = React.createRef();
        this.backgroundColors = [];
        for (let name in BackgroundColor) {
            this.backgroundColors.push(BackgroundColor[name]);
        }
    }

    componentWillUnmount() {
        this.props.avatarStore.stopVideoTrackInStream();
    }

    getShortenedText = (length, text) => (text.length > length ? text.substr(0, length - 2) + '...' : text);

    closeWindow = () => window.open('/', '_self').close();

    handleClickOpen = () => {
        this.props.avatarStore.initDevices();
        this.props.avatarStore.setDeviceBrowserTypes(Params.getDeviceBrowserType());
        this.props.avatarStore.initialize(
            this.props.authStore.loginUser.id,
            'standbyVideo',
            'backgroundImage',
            'captureCanvas',
            'backgroundCanvas',
            'maskedCanvas',
            'avatarCanvas',
        );
        this.setState({ open: true });
    };

    handleClose = () => {
        this.props.avatarStore.stopVideoTrackInStream();
        this.props.avatarStore.initialize(
            this.props.authStore.loginUser.id,
            'standbyVideo',
            'backgroundImage',
            'captureCanvas',
            'backgroundCanvas',
            'maskedCanvas',
            'avatarCanvas',
        );
        this.setState({ open: false });
    };
    handleCreateAvatar = () => {
        this.props.avatarStore.createAvatar(true);
    };

    handlePrivacyConfirmDialogOpen = isOpen => {
        const { changePrivacyConfirmDialogOpen } = this.props.avatarStore;
        changePrivacyConfirmDialogOpen(isOpen);
    };

    handleSaveAvatar = () => {
        this.props.avatarStore._saveAvatarImage();
        this.handleClose();
    };

    handleChangeAvatarBackgroundOption = (event, optionValue) => {
        const backgroundOption = event.target.value ? event.target.value : optionValue;
        this.props.avatarStore.setBackgroundOption(backgroundOption);
    };

    handleChangeAvatarBackgroundColor = color => {
        this.props.avatarStore.setBackgroundColor(color);
    };

    handleChangeAvatarBackgroundFile = () => {
        console.log(LogPrefix, 'Start handleChangeAvatarBackgroundFile');
        const inputElement = document.getElementById('background-button-file');
        console.log(LogPrefix, 'inputElement : >> ', inputElement);

        if (inputElement) {
            const reader = new FileReader();
            reader.readAsDataURL(inputElement.files[0]);
            reader.onload = e => {
                console.log(LogPrefix, 'FileReader onload', e.target.result);
                const imageElement = document.getElementById('backgroundImage');
                console.log(LogPrefix, 'imageElement', imageElement);
                imageElement.src = e.target.result;
            };
            reader.onloadend = e => {
                console.log(LogPrefix, 'FileReader onloadend', e);
                this.props.avatarStore.setBackgroundImage();
            };
            inputElement.value = '';
        } else {
            console.log(LogPrefix, "Can't found input element");
        }
    };

    render() {
        const { classes, intl, avatarStore } = this.props;
        const { userDetail } = this.props.userStore;
        const { open } = this.state;
        return (
            <>
                <Button color="primary" onClick={this.handleClickOpen} className={classes.buttonStyle}>
                    {/*<FormattedMessage id="change_image" />*/}
                    <span className={classes.cameraBox}>
                        <CameraIcon />
                    </span>
                </Button>
                <Dialog open={open} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogContent>
                        <Box component="main" className={classes.mainContainer}>
                            <Hidden smUp>
                                <Box pb={1} className={classes.titleBox}>
                                    <Typography className={classes.titleText}>
                                        <FormattedMessage id="profile_image_settings" />
                                    </Typography>
                                </Box>
                            </Hidden>
                            <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                    <Box
                                        className={classes.contentsBox}
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Hidden xsDown>
                                            <Box pb={1} className={classes.titleBox}>
                                                <Typography className={classes.titleText}>
                                                    <FormattedMessage id="profile_image_settings" />
                                                </Typography>
                                            </Box>
                                        </Hidden>
                                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" pb={2} pt={1}>
                                            <Avatar
                                                ref={this.avatarRef}
                                                src={avatarStore.avatarImage}
                                                style={{ width: 80, height: 80, background: '#a3a8af' }}
                                            >
                                                {avatarStore.avatarImage ? (
                                                    <img id="profileImage" src={avatarStore.avatarImage} style={{ width: 80, height: 80 }} alt={''} />
                                                ) : (
                                                    <PersonIcon style={{ width: 52, height: 50 }} />
                                                )}
                                                {/*{UTIL.getFirstCapitalLetter(userDetail.name)}*/}
                                            </Avatar>
                                            <Popover
                                                anchorEl={this.avatarRef.current}
                                                open={avatarStore.optionOpen}
                                                onClose={() => avatarStore.setOptionOpen(false)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                style={{ marginLeft: 10 }}
                                            >
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                    className={classes.avatarBgBox}
                                                >
                                                    <Typography
                                                        style={{
                                                            marginLeft: 5,
                                                            marginBottom: 20,
                                                            fontSize: 14,
                                                            color: '#000',
                                                            fontWeight: 'bold',
                                                            fontFamily: 'NanumSquareRoundOTF',
                                                        }}
                                                    >
                                                        <FormattedMessage id="profile_image_settings" />
                                                    </Typography>
                                                    <Box
                                                        display="flex"
                                                        flexDirection="row"
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                        mb={1}
                                                    >
                                                        <RadioGroup
                                                            row
                                                            value={avatarStore.backgroundOption}
                                                            onChange={this.handleChangeAvatarBackgroundOption}
                                                        >
                                                            <FormControlLabel
                                                                value={BackgroundOption.None}
                                                                label={intl.formatMessage({ id: 'no_background' })}
                                                                labelPlacement="end"
                                                                control={
                                                                    <Radio
                                                                        color="primary"
                                                                        style={{
                                                                            padding: 3,
                                                                        }}
                                                                    />
                                                                }
                                                                style={{ margin: 0, padding: 0 }}
                                                            />
                                                            <FormControlLabel
                                                                value={BackgroundOption.Color}
                                                                label={intl.formatMessage({ id: 'specify_background_color' })}
                                                                labelPlacement="end"
                                                                control={
                                                                    <Radio
                                                                        color="primary"
                                                                        style={{
                                                                            padding: 3,
                                                                            marginLeft: 3,
                                                                        }}
                                                                    />
                                                                }
                                                                style={{ margin: 0, padding: 0 }}
                                                            />
                                                            <FormControlLabel
                                                                value={BackgroundOption.Image}
                                                                label={intl.formatMessage({ id: 'file_selection' })}
                                                                labelPlacement="end"
                                                                control={
                                                                    <Radio
                                                                        color="primary"
                                                                        style={{
                                                                            padding: 3,
                                                                            marginLeft: 3,
                                                                        }}
                                                                    />
                                                                }
                                                                style={{ margin: 0, padding: 0 }}
                                                            />
                                                        </RadioGroup>
                                                    </Box>

                                                    {avatarStore.backgroundOption === BackgroundOption.Color && (
                                                        <Box
                                                            display="flex"
                                                            flexDirection="row"
                                                            justifyContent="flex-start"
                                                            alignItems="flex-start"
                                                            flexWrap="wrap"
                                                        >
                                                            {this.backgroundColors.map(color => (
                                                                <div
                                                                    key={`background-color-${color}`}
                                                                    style={{ backgroundColor: color }}
                                                                    className={
                                                                        color === avatarStore.backgroundColor
                                                                            ? classes.selectedColorDiv
                                                                            : classes.colorDiv
                                                                    }
                                                                    onClick={() => this.handleChangeAvatarBackgroundColor(color)}
                                                                />
                                                            ))}
                                                        </Box>
                                                    )}

                                                    {/*{avatarStore.backgroundOption === BackgroundOption.Image && (*/}
                                                    {/*    <Box*/}
                                                    {/*        display="flex"*/}
                                                    {/*        flexDirection="row"*/}
                                                    {/*        justifyContent="center"*/}
                                                    {/*        alignItems="center"*/}
                                                    {/*        className={classes.fileBox}*/}
                                                    {/*    >*/}
                                                    {/*        <input*/}
                                                    {/*            id="background-button-file"*/}
                                                    {/*            type="file"*/}
                                                    {/*            accept="image/*"*/}
                                                    {/*            style={{ display: 'none' }}*/}
                                                    {/*            onChange={this.handleChangeAvatarBackgroundFile}*/}
                                                    {/*        />*/}
                                                    {/*        <label htmlFor="background-button-file">*/}
                                                    {/*            <Button*/}
                                                    {/*                variant="outlined"*/}
                                                    {/*                color="primary"*/}
                                                    {/*                component="span"*/}
                                                    {/*                className={classes.fileBtn}*/}
                                                    {/*                style={{ width: 120 }}*/}
                                                    {/*                disabled={avatarStore.creating}*/}
                                                    {/*            >*/}
                                                    {/*                <FormattedMessage id="file_selection" />*/}
                                                    {/*            </Button>*/}
                                                    {/*        </label>*/}
                                                    {/*    </Box>*/}
                                                    {/*)}*/}
                                                </Box>
                                            </Popover>
                                            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                                <Button
                                                    disabled={avatarStore.creating}
                                                    onClick={this.handleCreateAvatar}
                                                    className={classes.avatarBtn}
                                                >
                                                    <FormattedMessage id="direct_shooting" />
                                                </Button>
                                            </Box>
                                            <Box>
                                                <input
                                                    id="background-button-file"
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={this.handleChangeAvatarBackgroundFile}
                                                />
                                                <label htmlFor="background-button-file">
                                                    <Button
                                                        value={BackgroundOption.Image}
                                                        component={'span'}
                                                        disabled={avatarStore.creating}
                                                        // onClick={() => avatarStore.setOptionOpen(true)}
                                                        onClick={e => this.handleChangeAvatarBackgroundOption(e, BackgroundOption.Image)}
                                                        className={classes.avatarBtn}
                                                    >
                                                        <FormattedMessage id="choose_photo" />
                                                    </Button>
                                                </label>
                                            </Box>

                                            {/* 아바타 이미지를 만들기 위한 중간 작업 용 */}
                                            <img
                                                id="backgroundImage"
                                                style={{ display: 'none', width: AvatarWidth, height: AvatarHeight }}
                                                alt={''}
                                            />
                                            <canvas
                                                id="captureCanvas"
                                                width={AvatarWidth}
                                                height={AvatarHeight}
                                                style={{
                                                    display: 'none',
                                                    width: AvatarWidth,
                                                    height: AvatarHeight,
                                                }}
                                            />
                                            <canvas
                                                id="backgroundCanvas"
                                                width={AvatarWidth}
                                                height={AvatarHeight}
                                                style={{
                                                    display: 'none',
                                                    width: AvatarWidth,
                                                    height: AvatarHeight,
                                                }}
                                            />
                                            <canvas
                                                id="maskedCanvas"
                                                width={AvatarWidth}
                                                height={AvatarHeight}
                                                style={{
                                                    display: 'none',
                                                    width: AvatarWidth,
                                                    height: AvatarHeight,
                                                }}
                                            />
                                            <canvas
                                                id="avatarCanvas"
                                                width={AvatarWidth}
                                                height={AvatarHeight}
                                                style={{
                                                    display: 'none',
                                                    width: AvatarWidth,
                                                    height: AvatarHeight,
                                                }}
                                            />
                                        </Box>

                                        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" pb={2} px={2}>
                                            <video id={'standbyVideo'} playsInline autoPlay muted style={{ maxWidth: 300 }} />
                                            {!avatarStore.videoMediaStream && (
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    style={{ position: 'absolute' }}
                                                >
                                                    <Typography variant="subtitle1">
                                                        {avatarStore.isVideoMediaFoundError
                                                            ? '아이폰, 아이패드는 사파리 브라우저'
                                                            : '비디오 장치가 있는 경우 비디오 장치 인식 후에'}
                                                    </Typography>
                                                    <Typography variant="subtitle1">
                                                        {avatarStore.isVideoMediaFoundError
                                                            ? '이외의 경우는 크롬 브라우저를 이용해 주세요.'
                                                            : '수업 입장 버튼을 클릭 해 주세요.'}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>

                                        <Box
                                            className={classes.box}
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="space-around"
                                            alignItems="stretch"
                                            pr={2}
                                            pl={2}
                                            mb={2}
                                        >
                                            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" pr={1}>
                                                    <VideocamRoundedIcon fontSize={'large'} />
                                                </Box>

                                                <Box display="flex" flexGrow={1} flexDirection="row" justifyContent="flex-start" alignItems="center">
                                                    <FormControl variant="outlined" style={{ flexGrow: '1' }} fullWidth>
                                                        <InputLabel htmlFor="outlined-video-device-native" className={classes.selectLabel}>
                                                            <FormattedMessage id="video_device" />
                                                        </InputLabel>
                                                        <Select
                                                            native
                                                            fullWidth
                                                            value={avatarStore.selectedVideoDeviceId}
                                                            label="VideoDevice"
                                                            inputProps={{
                                                                name: 'VideoDevice',
                                                                id: 'outlined-video-device-native',
                                                            }}
                                                            onChange={e => avatarStore.selectVideoDeviceId(e.target.value)}
                                                            className={classes.selectBox}
                                                        >
                                                            {avatarStore.videoDevices &&
                                                                avatarStore.videoDevices.length &&
                                                                avatarStore.videoDevices.map(device => (
                                                                    <option key={`video-device-${device.deviceId}`} value={device.deviceId}>
                                                                        {device.label
                                                                            ? this.getShortenedText(TEXT_LENGTH, device.label)
                                                                            : '알수없는 장치'}
                                                                    </option>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box
                                            className={classes.box}
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="flex-end"
                                            alignItems="stretch"
                                            mb={2}
                                            pr={2}
                                        >
                                            <Button disabled={avatarStore.creating} onClick={this.handleClose} className={classes.closeBtn}>
                                                <FormattedMessage id="cancel" />
                                            </Button>
                                            <Button
                                                disabled={avatarStore.creating}
                                                onClick={this.handleSaveAvatar}
                                                // onClick={() => this.handlePrivacyConfirmDialogOpen(true)}
                                                className={classes.saveBtn}
                                            >
                                                <FormattedMessage id="save" />
                                            </Button>
                                        </Box>
                                        {/*<Hidden xsDown>*/}
                                        {/*    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center"*/}
                                        {/*         style={{margin: '0 auto'}} pb={2}>*/}
                                        {/*        <InformationIcon style={{marginRight: 5, marginTop: 1}}/>*/}
                                        {/*        <Typography variant="body1" style={{*/}
                                        {/*            fontWeight: 'bold',*/}
                                        {/*            marginRight: 16,*/}
                                        {/*            fontSize: '13px',*/}
                                        {/*            color: '#747478'*/}
                                        {/*        }}>내 시스템 정보 </Typography>*/}
                                        {/*        <Typography variant="body1"*/}
                                        {/*                    style={{marginRight: 5, fontSize: '12px', color: '#747478'}}> 디바이스*/}
                                        {/*            : {standbyStore.deviceBrowserTypes.deviceType === 'etc' ? 'PC' : standbyStore.deviceBrowserTypes.deviceType}</Typography>*/}
                                        {/*        <Typography variant="body1" style={{fontSize: '12px', color: '#747478'}}> 브라우저*/}
                                        {/*            : {standbyStore.deviceBrowserTypes.browserType}</Typography>*/}
                                        {/*    </Box>*/}
                                        {/*</Hidden>*/}

                                        {/*<Hidden smUp>*/}
                                        {/*    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center"*/}
                                        {/*         style={{margin: '0 auto'}} pb={2}>*/}
                                        {/*        <InformationIcon style={{marginRight: 5, marginTop: 1}}/>*/}
                                        {/*        <Typography variant="body1" style={{*/}
                                        {/*            fontWeight: 'bold',*/}
                                        {/*            marginRight: 10,*/}
                                        {/*            fontSize: '12px',*/}
                                        {/*            color: '#747478'*/}
                                        {/*        }}> 내 시스템 정보 </Typography>*/}
                                        {/*        <Typography variant="body1"*/}
                                        {/*                    style={{marginRight: 10, fontSize: '11px', color: '#747478'}}> 디바이스*/}
                                        {/*            : {standbyStore.deviceBrowserTypes.deviceType === 'etc' ? 'PC' : standbyStore.deviceBrowserTypes.deviceType}</Typography>*/}
                                        {/*        <Typography variant="body1" style={{fontSize: '11px', color: '#747478'}}> 브라우저*/}
                                        {/*            : {standbyStore.deviceBrowserTypes.browserType}</Typography>*/}
                                        {/*    </Box>*/}
                                        {/*</Hidden>*/}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </DialogContent>
                    {/*<PrivacyDialog open={avatarStore.openPrivacyConfirmDialog}*/}
                    {/*               title={"개인정보 수집 / 이용 동의서"}*/}
                    {/*               changeDialogOpen={(isOpen) => avatarStore.changePrivacyConfirmDialogOpen(isOpen)}*/}
                    {/*               submit={() => this.handleSaveAvatar()}*/}
                    {/*/>*/}
                </Dialog>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(inject('authStore', 'avatarStore', 'userStore')(injectIntl(observer(MakeAvatar))));
