import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as MinusCircleIcon } from '../../../common/images/MinusCircleIcon.svg';
import clsx from 'clsx';
import { ReactComponent as PenPageIcon } from '../../../common/images/PenPageIcon.svg';
import { ReactComponent as WidthHeightIcon } from '../../../common/images/WidthHeightIcon.svg';
import { ReactComponent as NeoPenIconColor } from '../../../common/images/NeoPenIconColor.svg';
import { ReactComponent as PenRecordPlus } from '../../../common/images/PenRecordPlus.svg';
import { ReactComponent as PenRecordMinus } from '../../../common/images/PenRecordMinus.svg';
import { ReactComponent as ArrowIcon } from '../../../common/images/ArrowIcon.svg';
import MobileViewTypeDrawer from './penRecord/MobileViewTypeDrawer';
import MobileSelectDrawer from './penRecord/MobileSelectDrawer';
import MobileScreenWriteDrawer from './penRecord/MobileScreenWriteDrawer';
import MobilePdfNotesDrawer from './penRecord/MobilePdfNotesDrawer';
import { withRouter } from '../../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { PenState } from '../../../stores/NeoPenStore';
import { ReactComponent as ArrowsClockwiseIcon } from '../../../common/images/ArrowsClockwiseIcon.svg';
import { ReactComponent as PenPlusCircleIcon } from '../../../common/images/PenPlusCircleIcon.svg';
import { ToastsStore } from 'react-toasts';
import { InkStorage, StrokeStatus, ZoomFitEnum } from 'nl-lib';
// import { InkStorage, StrokeStatus, ZoomFitEnum } from '../../../nl-lib';
import NeoPenViewer from '../../neoPen/NeoPenViewer';
import { ReactComponent as DownloadSimple } from '../../../common/images/DownloadSimple.svg';
import { NOTICE_TYPE } from '../../../stores/NoticeStore';

const styles = _theme => ({
    root: {
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        padding: '8px 12px',
        boxSizing: 'border-box',
        borderBottom: '1px solid #c0c2c3',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        '& svg': {
            width: 22,
            height: 22,
        },
        '&::before': {
            content: "''",
            width: 22,
            height: 22,
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    floppyDiskBackIcon: {
        '& svg': {
            width: 20,
            height: 20,
        },
    },
    titleText: {
        fontSize: '1rem',
        color: '#000',
    },
    titleDate: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    topBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 16px',
    },
    penButton: {
        border: '1px solid #c4c4c4',
        borderRadius: 2,
        width: 85,
        height: 35,
        padding: 0,
        marginRight: 10,
        '& span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 5,
        },
    },
    penButton2: {
        background: '#00C880',
        '&:hover': {
            background: '#00C880',
        },
        '& span': {
            color: '#fff',
        },
        '& path': {
            stroke: '#fff',
        },
    },
    penButtonRight: {
        marginRight: 0,
    },
    penText: {
        fontSize: '0.813rem',
        color: '#1e2121',
    },
    selectBox: {
        padding: 0,
        minWidth: 40,
        '&:hover': {
            background: 'transparent',
        },
        '& span': {
            display: 'flex',
            alignItems: 'center',
        },
        '& path': {
            strokeWidth: 1,
        },
    },
    selectText: {
        fontSize: '0.875rem',
        fontWeight: 600,
    },
    contentsBox: {
        position: 'relative',
        padding: '10px 16px',
        height: 'calc(100vh - 59px - 55px - 49px)',
        background: '#eee',
        boxSizing: 'border-box',
    },
    contentsTopBox: {
        '& svg': {
            width: 18,
            height: 18,
            '& .neo-pen': {
                fill: '#6d6d70',
            },
        },
        // '&::after': {
        //     content: "''",
        //     width: 28,
        //     height: 28,
        // },
    },
    iconBox: {
        width: 28,
        display: 'flex',
        alignItems: 'center',
    },
    numberBox: {
        width: 45,
        height: 20,
        borderRadius: 4,
        padding: '3px 0',
        border: '1px solid #c2c2c2',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 8px',
    },
    numberText: {
        fontSize: '0.813rem',
    },
    arrowSvg: {
        margin: '0 10px 2px',
        '& path': {
            strokeWidth: 1,
        },
    },
    paperBox: {
        width: '90%',
        height: 'calc(100% - 28px - 20px - 20px)',
        background: '#fff',
        boxShadow: '0 0 6.9px 0 rgba(0, 0, 0, 0.25)',
        margin: '10px auto',
    },
    widthHeightBox: {
        marginLeft: 10,
    },
    bottomBox: {
        padding: '15px 16px',
        boxSizing: 'border-box',
        '& svg': {
            width: 18,
            height: 18,
        },
    },
    '@media all and (min-width: 601px)': {
        titleBox: {
            '& svg': {
                width: 25,
                height: 25,
            },
            '&::before': {
                width: 25,
                height: 25,
            },
        },
        titleText: {
            fontSize: '1.25rem',
        },
        titleDate: {
            fontSize: '0.875rem',
        },
        penButton: {
            width: 110,
            height: 40,
        },
        penText: {
            fontSize: '1rem',
        },
        selectText: {
            fontSize: '1rem',
        },
        contentsBox: {
            height: 'calc(100vh - 62px - 60px - 53px)',
        },
        contentsTopBox: {
            '& svg': {
                width: 23,
                height: 23,
            },
        },
        iconBox: {
            width: 28,
            display: 'flex',
            alignItems: 'center',
        },
        numberBox: {
            width: 50,
            height: 20,
            margin: '0 15px',
        },
        numberText: {
            fontSize: '1rem',
        },
        arrowSvg: {
            margin: '0 15px 2px',
        },
        widthHeightBox: {
            marginLeft: 10,
            '& svg': {
                width: 26,
                height: 26,
            },
        },
        bottomBox: {
            '& svg': {
                width: 23,
                height: 23,
            },
        },
    },
    '@media all and (max-width: 374px)': {
        selectText: {
            fontSize: '0.75rem',
            marginLeft: 3,
        },
        penButton: {
            width: 75,
            height: 30,
            marginRight: 8,
            '& svg': {
                marginRight: 3,
            },
        },
        penText: {
            fontSize: '0.75rem',
        },
        penButtonRight: {
            marginRight: 0,
        },
    },
});
class MobilePenViewerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            widthHeight: true,
            viewTypeDrawer: false,
            viewType: '전체',
            selectDrawer: false,
            selectText: '최근 작성일 순',
            screenWriteDrawer: false,
            PdfNotesDrawer: false,
        };
    }

    componentDidMount = async () => {
        console.log('[MobilePenViewerComponent] : this.props.params  : >> ', this.props.params);

        window.addEventListener('beforeunload', event => {
            const is = InkStorage.getInstance();
            const nPS = this.props.neoPenStore;

            let strokes = is.strokes;

            strokes = strokes.filter(st => !st.uploaded && nPS.isStrokeInPaperGroup(st, nPS.selectedPaperGroup) && st.status !== StrokeStatus.ERASED);

            let isSaved = true;

            for (let s of strokes) {
                if (!s.uploaded) {
                    isSaved = false;
                }
            }

            if (!isSaved) event.returnValue = '아직 저장되지 않은 데이터가 있습니다.';
        });

        this.initPenRecordData()
            .then(() => {
                console.log('Success initPenRecordData');
            })
            .catch(() => {
                console.log('Failed initPenRecordData');
            });
    };

    initPenRecordData = async () => {
        console.log('[PenRecordComponent] : this.props.params  : >> ', this.props.params);

        const selectedGroupId = this.props.params.groupId;
        const selectedUserEmail = this.props.params.userEmail;
        const selectedPaperGroupId = this.props.params.paperGroupId;

        const nPS = this.props.neoPenStore;

        if (nPS.selectedUser !== undefined) {
            await nPS.interpreteInkstoreData();
        } else {
            const user = this.props.authStore.loginUser;

            await this.props.userStore.getUserInfo(user.email);
            const macAddress = this.props.userStore.fullSmartPenAddress;

            nPS.initNeoPen(user, macAddress);

            if (window.flutter_inappwebview) {
                const loginUser = this.props.authStore.loginUser;

                await this.props.userStore.getUserInfo(loginUser.email);

                const macAddress = this.props.userStore.fullSmartPenAddress;

                let connection = await window.flutter_inappwebview.callHandler('PenConnection', {});

                console.log('-=-=mobileConnection', connection);
                if (connection === 'Connected') {
                    nPS.setPenState(PenState.Connected);

                    window.onPenConnected({ mac: macAddress });
                }
            }

            nPS.getSinglePaperGroupByPaperGroupId(selectedGroupId, selectedPaperGroupId);

            const groupLeaderRes = await nPS.getGroupLeader(user.email, selectedGroupId);

            if (groupLeaderRes !== undefined && user.id === groupLeaderRes.userId) nPS.setIsLeader(true);

            const selectedUser = await nPS.getUserInfo(selectedUserEmail);
            nPS.selectUser(selectedUser);

            await nPS.getUserNotes([selectedUser], true);

            await nPS.interpreteInkstoreData();
        }
    };

    handleChange = e => {
        const { neoPenStore } = this.props;

        this.setState({ value: e.target.value });

        if (e.target.value === 'checkA') neoPenStore.setRenderingUserStrokeId(undefined);

        if (e.target.value === 'checkB') neoPenStore.setRenderingUserStrokeId(neoPenStore.user.email, neoPenStore.isLeader);

        if (e.target.value === 'checkC') neoPenStore.setRenderingUserStrokeId(neoPenStore.user.email, neoPenStore.isLeader);
    };

    handleClickWidthHeight = () => {
        const nPS = this.props.neoPenStore;

        if (this.state.widthHeight) nPS.setZoomFitType(ZoomFitEnum.WIDTH);
        else nPS.setZoomFitType(ZoomFitEnum.HEIGHT);

        this.setState({ widthHeight: !this.state.widthHeight });
    };

    handleClickBack = () => {
        this.props.navigate(-1);
    };

    handleClickViewType = () => {
        this.setState({
            viewTypeDrawer: true,
        });
    };

    handleClickSelect = () => {
        this.setState({
            selectDrawer: true,
        });
    };

    handleClickScreenWriteDrawer = () => {
        this.setState({
            screenWriteDrawer: true,
        });
    };

    handleClickPdfNotesDrawer = () => {
        this.setState({
            PdfNotesDrawer: true,
        });
    };

    handleChangeViewType = viewType => {
        const nPS = this.props.neoPenStore;

        if (viewType === '전체') nPS.setRenderingUserStrokeId(undefined);

        if (viewType === '첨삭 중심보기') nPS.setRenderingUserStrokeId(nPS.user.email, nPS.isLeader);

        if (viewType === '학생 필기 중심보기') nPS.setRenderingUserStrokeId(nPS.user.email, !nPS.isLeader);

        this.setState({ viewType });
    };

    handleChangeSelect = selectText => {
        this.setState({ selectText });
    };

    handleCloseDrawer = () => {
        this.setState({
            viewTypeDrawer: false,
            selectDrawer: false,
            screenWriteDrawer: false,
            PdfNotesDrawer: false,
        });
    };

    requestCorrectionUpload = async () => {
        const { classes, neoPenStore: nPS } = this.props;
        ToastsStore.info('첨삭 업로드를 시작합니다.', 3000, classes.toasts);

        await nPS.uploadToInk();

        ToastsStore.info('첨삭 업로드가 완료되었습니다.', 3000, classes.toasts);
    };

    handleClickUpload = () => {
        console.log('handleClickUpload');
        const { classes } = this.props;

        this.requestCorrectionUpload()
            .then(() => {
                console.log('Success CorrectionUpload');
                const { groupId, userEmail, paperGroupId } = this.props.params;
                this.props.noticeStore.requestCreateNotification(groupId, userEmail, paperGroupId, NOTICE_TYPE.NoteCorrection);
            })
            .catch(() => {
                console.log('requestCorrectionUpload error');
                ToastsStore.error('첨삭 업로드를 완료하지 못했습니다. 잠시후 다시 시도해주세요.', 3000, classes.toasts);
            });
    };

    render() {
        const { classes } = this.props;
        const { widthHeight, viewType } = this.state;

        if (this.props.neoPenStore.selectedUser === undefined) return <Box />;

        const nPS = this.props.neoPenStore;

        const paperGroup = nPS.selectedPaperGroup;
        const note = nPS.getNote(nPS.selectedPaperGroup, nPS.selectedUser.email);

        console.log('note : ', note);

        if (note === undefined) return <Box />;

        let lastDateStr = '';

        if (note.lastStrokeAt !== undefined) {
            const lastDateTimestamp = note.lastStrokeAt;

            lastDateStr = nPS.convertTimestamp(lastDateTimestamp);
        }

        return (
            <div className={classes.root}>
                <Box className={classes.titleBox}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography className={clsx(classes.textStyle, classes.topCenterText)}>
                            {paperGroup && `${paperGroup.title.trim().replace(/(.pdf)$/, '')} (${nPS.selectedUser.name})`}
                        </Typography>
                        <Typography className={classes.titleDate}>{lastDateStr}</Typography>
                    </Box>
                    <Box className={classes.iconButton} />

                    {/*<IconButton className={classes.iconButton} onClick={this.handleClickBack} disableRipple>*/}
                    {/*    <Close />*/}
                    {/*</IconButton>*/}
                </Box>
                <Box className={classes.topBox}>
                    <Box>
                        <Button className={classes.selectBox} onClick={this.handleClickViewType} disableRipple>
                            <Typography className={classes.selectText}>{this.state.viewType}</Typography>
                            <ArrowIcon style={{ transform: 'rotate(270deg)', marginTop: -4, marginLeft: 5 }} />
                        </Button>
                    </Box>
                    {nPS.isLeader && (
                        <Box display="flex" alignItems="center">
                            {nPS.penState === PenState.Connected && (
                                <Button className={clsx(classes.penButton, classes.penButton2)} disableRipple onClick={nPS.onConnectionChange}>
                                    <MinusCircleIcon />
                                    <span className={classes.penText}>펜 해제</span>
                                </Button>
                            )}

                            {nPS.penState === PenState.Connecting && (
                                <Button className={classes.penButton} disableRipple>
                                    <ArrowsClockwiseIcon />
                                    <span className={classes.penText}>연결중</span>
                                </Button>
                            )}

                            {nPS.penState === PenState.Disconnected && (
                                <Button className={classes.penButton} disableRipple onClick={nPS.onConnectionChange}>
                                    <PenPlusCircleIcon />
                                    <span className={classes.penText}>펜 연결</span>
                                </Button>
                            )}
                            <Button className={clsx(classes.penButton, classes.penButtonRight)} disableRipple onClick={this.handleClickUpload}>
                                <span className={classes.penText}>첨삭 업로드</span>
                            </Button>
                        </Box>
                    )}
                </Box>

                <Box className={classes.contentsBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.contentsTopBox}>
                        <Box className={classes.iconBox}>
                            <IconButton className={classes.iconButton} onClick={this.handleClickScreenWriteDrawer} disableRipple>
                                <NeoPenIconColor />
                            </IconButton>
                        </Box>

                        <Box display="flex" alignItems="center">
                            <IconButton className={classes.iconButton} disableRipple onClick={nPS.handleZoomIn}>
                                <PenRecordPlus />
                            </IconButton>
                            <Box className={classes.numberBox}>
                                <Typography className={classes.numberText}>{parseInt(nPS.zoom * 100)}%</Typography>
                            </Box>
                            <IconButton className={classes.iconButton} disableRipple onClick={nPS.handleZoomOut}>
                                <PenRecordMinus />
                            </IconButton>
                            <Box className={classes.widthHeightBox}>
                                <IconButton className={classes.iconButton} onClick={this.handleClickWidthHeight} disableRipple>
                                    {this.state.widthHeight ? <WidthHeightIcon /> : <WidthHeightIcon style={{ transform: 'rotate(90deg)' }} />}
                                </IconButton>
                            </Box>
                        </Box>

                        <IconButton className={clsx(classes.iconButton, classes.floppyDiskBackIcon)} disableRipple>
                            <DownloadSimple />
                        </IconButton>
                    </Box>
                    <Box className={classes.paperBox}>
                        <NeoPenViewer />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <IconButton className={clsx(classes.iconButton, classes.arrowSvg)} disableRipple onClick={nPS.prevPage}>
                            <ArrowIcon />
                        </IconButton>
                        <Typography className={classes.numberText}>
                            {nPS.activePage.page - nPS.selectedPaperGroup.pageStart + 1}/
                            {nPS.selectedPaperGroup.pageEnd - nPS.selectedPaperGroup.pageStart + 1}
                        </Typography>
                        <IconButton className={clsx(classes.iconButton, classes.arrowSvg)} disableRipple onClick={nPS.nextPage}>
                            <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.bottomBox}>
                    <Box>
                        <IconButton className={classes.iconButton} onClick={this.handleClickPdfNotesDrawer} disableRipple>
                            <PenPageIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        {/*<Button className={classes.selectBox} onClick={this.handleClickSelect} disableRipple>*/}
                        {/*    <CheckCircleIcon />*/}
                        {/*    <Typography className={classes.selectText}>{this.state.selectText}</Typography>*/}
                        {/*</Button>*/}
                    </Box>
                </Box>

                <MobileViewTypeDrawer
                    handleCloseDrawer={this.handleCloseDrawer}
                    viewTypeDrawer={this.state.viewTypeDrawer}
                    viewType={this.state.viewType}
                    handleChangeViewType={this.handleChangeViewType}
                />
                <MobileSelectDrawer
                    handleCloseDrawer={this.handleCloseDrawer}
                    selectDrawer={this.state.selectDrawer}
                    selectText={this.state.selectText}
                    handleChangeSelect={this.handleChangeSelect}
                />
                <MobileScreenWriteDrawer handleCloseDrawer={this.handleCloseDrawer} screenWriteDrawer={this.state.screenWriteDrawer} />
                <MobilePdfNotesDrawer handleCloseDrawer={this.handleCloseDrawer} PdfNotesDrawer={this.state.PdfNotesDrawer} />
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('noticeStore', 'orgStore', 'neoPenStore', 'classStore', 'authStore', 'userStore')(observer(MobilePenViewerComponent))),
);
