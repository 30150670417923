import React, { useEffect } from 'react';
import { Avatar, Box, Button, Checkbox, Dialog, IconButton, InputBase, makeStyles, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { ReactComponent as MagnifyingGlassIcon } from '../../common/images/MagnifyingGlassIcon.svg';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { UTIL } from '../../common/util/common.util';

const useStyles = makeStyles(theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            minWidth: 320,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 601px)': {
                width: 400,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    textStyle: {
        fontSize: '1rem',
        color: '#000',
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    searchBox: {
        display: 'flex',
        boxShadow: 'none',
        border: '1px solid #C4C4C4',
        padding: '4px 9px',
        margin: '10px 0 20px',
        borderRadius: 7,
        '& .MuiInputBase-root': {
            width: '100%',
        },
        '& input': {
            width: '98%',
            fontSize: '1rem',
        },
        '@media all and (max-width: 600px)': {
            padding: '5px 10px',
            margin: '10px 0 20px',
            '& input': {
                fontSize: '0.813rem',
            },
        },
    },
    searchIconButton: {
        padding: 8,
        backgroundColor: '#f8f8f8',
        '&:hover': {
            backgroundColor: '#f8f8f8',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 18,
                height: 18,
            },
        },
    },
    boxStyle: {
        height: 250,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '15px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '5px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
    },
    titleBoxIn: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 2,
        },
        '& p': {
            color: '#a3a8af',
            fontSize: '0.813rem',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 18,
                height: 18,
            },
        },
    },
    avatar: {
        background: '#a3a8af',
        width: 45,
        height: 45,
        '& svg': {
            width: 28,
            height: 28,
            '& path': {
                fill: '#fff',
            },
        },
        '@media all and (max-width: 600px)': {
            width: 35,
            height: 35,
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    nameText: {
        color: '#333',
        fontWeight: 600,
        marginLeft: 10,
        fontSize: '1rem',
        '@media all and (max-width: 600px)': {
            fontSize: '0.875rem',
        },
    },
    checkedBox: {
        '& svg': {
            width: 18,
            height: 18,
            '& path': {
                color: '#a3a8af',
            },
        },
        '&:hover, .Mui-checked:hover': {
            background: 'transparent!important',
        },
        '&.Mui-checked': {
            '& svg path': {
                color: '#0097ff',
            },
        },
        '& .MuiSvgIcon-root': {
            '@media all and (max-width: 600px)': {
                fontSize: '1rem',
            },
        },
    },
    buttonStyle: {
        marginTop: 26,
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        color: '#fff',
        fontSize: '1.125rem',
        padding: '12px 0',
        borderRadius: 7,
        '&:hover': {
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            height: 33,
            padding: '6px 0',
            fontSize: '0.75rem',
            borderRadius: 4,
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
}));

function MemberGroupSearchDialogComponent(props) {
    const classes = useStyles();
    const [searchKeyword, setSearchKeyword] = React.useState('');
    const [selectedMembers, setSelectedMembers] = React.useState([]);

    useEffect(() => {
        return function cleanUp() {
            if (searchKeyword) {
                setSearchKeyword('');
            }
        };
    }, [props.memberGroupSearch]);

    const filteredBySearchKeyword = member => {
        if (!searchKeyword.trim()) return member;
        return member && UTIL.keywordContains(member.name, String(searchKeyword), 0);
    };

    const handleChangeCheckBox = member => {
        setSelectedMembers([...selectedMembers, member]);
        props.changeTeamMembers(member);
    };

    const handleClickCloseBtn = () => {
        if (selectedMembers.length) {
            selectedMembers.forEach(member => {
                props.changeTeamMembers(member);
            });
        }
        props.handleClose();
    };

    return (
        <div className={classes.root}>
            <Dialog open={props.memberGroupSearch} className={classes.dialogBox}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.titleText}>멤버</Typography>
                    <IconButton className={classes.iconButton} onClick={handleClickCloseBtn} disableRipple>
                        <DialogCloseIcon />
                    </IconButton>
                </Box>

                <Typography className={classes.textStyle}>아래 멤버에서 선택해주세요.</Typography>
                <Box className={classes.searchBox}>
                    <InputBase placeholder="이름검색" onChange={e => setSearchKeyword(e.target.value)} />
                    <IconButton className={classes.searchIconButton} aria-label="Search" disableRipple>
                        <MagnifyingGlassIcon />
                    </IconButton>
                </Box>

                <Box display="flex" alignItems="center" className={classes.titleBoxIn}>
                    <AsideUserIcon />
                    <Typography>{`${props.allGroupUsers.length}명`} </Typography>
                </Box>
                <Box className={classes.boxStyle}>
                    {props.allGroupUsers.filter(filteredBySearchKeyword).map((member, i) => (
                        <Box key={`${member.userId}_${member.name}`} display="flex" alignItems="center" justifyContent="space-between" mt={1} mb={2}>
                            <Box display="flex" alignItems="center">
                                <Avatar src={member.image} alt="profile image" className={classes.avatar}>
                                    <AsideUserIcon />
                                </Avatar>
                                <Typography className={classes.nameText}>{member.name}</Typography>
                            </Box>
                            <Checkbox
                                className={classes.checkedBox}
                                disableRipple
                                checked={props.teamMembers.findIndex(m => m.userId === member.userId) !== -1}
                                onChange={() => handleChangeCheckBox(member)}
                            />
                        </Box>
                    ))}
                </Box>

                <Button className={classes.buttonStyle} disableRipple onClick={props.handleClose}>
                    선택
                </Button>
            </Dialog>
        </div>
    );
}

export default React.memo(MemberGroupSearchDialogComponent);
