class PathUtil {
    getPath(locationPathname) {
        return locationPathname.split('/')[1];
    }

    getGroupPath(locationPathname) {
        const pathArray = locationPathname.split('/');
        return pathArray[2] !== undefined ? pathArray[2] : undefined;
    }

    getGroupTypePath(locationPathname) {
        const pathArray = locationPathname.split('/');
        return pathArray[2] !== undefined ? pathArray[2] : undefined;
    }

    getGroupId(locationPathname) {
        const pathArray = locationPathname.split('/');
        return pathArray[3] !== undefined && pathArray[2] === 'teams' ? parseInt(pathArray[3]) : 0;
    }

    getClassId(locationPathname) {
        const pathArray = locationPathname.split('/');
        return pathArray[2] !== undefined && pathArray[1] !== undefined && (pathArray[1] === 'class' || 'roomModify') ? parseInt(pathArray[2]) : 0;
    }

    getRoomId(locationPathname) {
        const pathArray = locationPathname.split('/');
        return pathArray[2] !== undefined && pathArray[1] !== undefined && pathArray[1] === 'scheduleDetail' ? parseInt(pathArray[2]) : 0;
    }

    getSearchKeyword(locationPathname) {
        const pathArray = locationPathname.split('/');
        return pathArray[4] !== undefined && pathArray[3] !== undefined && pathArray[3] === 'search' ? pathArray[4] : '';
    }

    getOriginURL() {
        const location = window.location;
        return `${location.protocol}//${location.host}`;
    }

    getSurveyId(locationPathname) {
        const pathArray = locationPathname.split('/');
        return pathArray[3] !== undefined ? parseInt(pathArray[3]) : 0;
    }

    getMainTabName(locationPathname) {
        const pathArray = locationPathname.split('/');
        let findPathName = '';
        if (pathArray[3]) {
            findPathName = pathArray[3];
        }

        return !findPathName ? undefined : findPathName;
    }

    getUserEmail(locationPathname) {
        const pathArray = locationPathname.split('/');

        return pathArray[1] !== undefined && pathArray[2] !== undefined ? pathArray[2] : '';
    }

    getPaperGroupId(locationPathname) {
        const pathArray = locationPathname.split('/');

        return pathArray[1] !== undefined && pathArray[2] !== undefined && pathArray[3] !== undefined ? pathArray[3] : '';
    }
}

export const PATH_UTIL = new PathUtil();
