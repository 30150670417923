import { fabric } from 'fabric';
// import { drawLinePath } from "./DrawCurves";
// const timeTickDuration = 20; // ms
const BG_COLOR = 'rgba(0, 200, 128, 1.0)';
const STROKE_PULSE_COLOR = 'rgba(125, 137, 239, 1.0)';
// const BG_COLOR_DISABLED = "rgba(125, 137, 239, 0.1)";
// const STROKE_PULSE_COLOR_DISABLED = "rgba(125, 137, 239, 0.6)";
const NOW_PAYING_BAR_COLOR = 'rgba(255, 0, 0, 0.7)';
// const CURR_PAGE_COLOR = "rgba(0, 0, 0, 0.1)";
const CURR_PAGE_COLOR = 'rgba(125, 137, 239, 0.5)';
const OTHER_PAGE_COLOR = 'rgba(125, 137, 239, 0.1)';
const PAGE_SEPARATOR_COLOR = 'rgba(0, 0, 0, 0.6)';
const PAGE_NUMBER_COLOR = 'rgba(255, 255, 255, 1)';
const PAGE_NUMBER_BGCOLOR = 'rgba(0, 0, 0, 0.6)';
// const getStrokesTimeInfo = (strokeStream: ReplayStorage) => {
//   // 전체 필기, 시작시간, 끝시간
//   const whole_stroke_start_time = strokeStream.strokes[0].dotArray[0].time;
//   const whole_last_stroke = strokeStream.strokes[strokeStream.strokes.length - 1];
//   const whole_last_dot =
//     whole_last_stroke.dotArray[whole_last_stroke.dotArray.length - 1];
//   const whole_stroke_end_time = whole_last_dot.time;
//   return {
//     start: whole_stroke_start_time,
//     end: whole_stroke_end_time,
//     duration: whole_stroke_end_time - whole_stroke_start_time,
//     strokes: strokeStream.strokes,
//   };
// };
export default class NeoStrokeGraph {
    constructor(canvasName, rs) {
        // this._analyzer = analyzer;
        this.beginTime_whole = 0;
        this.endTime_whole = 0;
        this.strokeChunk = [];
        // public setAnalyzer = (analyzer: LessonStudyAnalyzer) => {
        //   this._analyzer = analyzer;
        //
        //   const canvas = this.canvas;
        //   canvas.clear();
        //   this.initGraph(canvas);
        // }
        this.init = size => {
            this.canvas = new fabric.Canvas(this.canvasName, {
                backgroundColor: BG_COLOR,
                // selectionColor: 'blue',
                selection: false,
                controlsAboveOverlay: true,
                centeredScaling: true,
                allowTouchScrolling: true,
                selectionLineWidth: 4,
                width: size.width,
                height: size.height,
            });
            this.size = {
                ...size,
                width: size.width - 2,
            };
            this.initGraph(this.canvas);
        };
        this.resizeCanvas = size => {
            const canvas = this.canvas;
            canvas.clear();
            // var objects = canvas.getObjects();
            // for (var i = 0; i < objects.length; i++) {
            //   //console.log(objects[i]);
            //   canvas.remove(objects[i]);
            // }
            // canvas.renderAll();
            canvas.setWidth(size.width);
            canvas.setHeight(size.height);
            this.size = {
                ...size,
                width: size.width - 2,
            };
            const self = this;
            self.initGraph(canvas);
            // canvas.on("after:render", function (e) {
            //   canvas.setWidth(self.size.width);
            //   canvas.setHeight(self.size.height);
            //   self.initGraph(canvas);
            // });
        };
        this.initGraph = canvas => {
            canvas.clear();
            const size = this.size;
            const rs = this.replayStorage;
            this.beginTime_whole = rs.startTime;
            this.endTime_whole = rs.endTime + 1;
            this.duration = rs.duration;
            this.strokes = rs.strokes;
            this.n_entries = size.width + 1;
            this.seg_width = this.n_entries / (this.duration + 10);
            this.resetPathArray();
            this.initPathArray(rs);
            this.drawChunkRect(this.strokeChunk);
        };
        this.setActiveChunk = chunkIndex => {
            this.pageRects.forEach((rect, index) => {
                if (index === chunkIndex) {
                    rect.set('fill', CURR_PAGE_COLOR);
                    rect.dirty = true;
                    // rect.fill = CURR_PAGE_COLOR;
                } else {
                    rect.set('fill', OTHER_PAGE_COLOR);
                    rect.dirty = true;
                    // rect.fill = "#F00";
                }
            });
            this.canvas.requestRenderAll();
        };
        /**
         * @return {number} page start time, relative
         */
        this.drawChunkRect = strokes_chunk => {
            // let chunks = this.chunkPageStrokes(this.strokeStream.strokes);
            const chunks = strokes_chunk;
            const pageRects = [];
            const numberRects = [];
            const duration = this.endTime_view - this.beginTime_view + 1;
            const n_entries = this.size.width + 1;
            const seg_width = n_entries / (duration + 10);
            const page_start_time = -1;
            for (let i = 0; i < chunks.length; i++) {
                const chunk = chunks[i];
                const first = chunk.strokes[0];
                const last = chunk.strokes[chunk.strokes.length - 1];
                // 2021/12/28
                // if (first.startTime < this.beginTime_view) continue;
                const rel_time_start = first.startTime - this.beginTime_view;
                const rel_time_end = last.startTime + last.duration - this.beginTime_view;
                let rel_before_next = rel_time_end;
                if (i !== chunks.length - 1) {
                    const next_chunk = chunks[i + 1];
                    const next_first = next_chunk.strokes[0];
                    rel_before_next = next_first.startTime - this.beginTime_view - 1;
                }
                const x0 = rel_time_start * seg_width;
                const x1 = rel_time_end * seg_width;
                const x2 = rel_before_next * seg_width;
                const fill_color = OTHER_PAGE_COLOR;
                /** @type {fabric.Rect} - Page Rect */
                const rect = new fabric.Rect({
                    left: x0,
                    top: 0,
                    height: this.size.height,
                    width: x1 - x0,
                    fill: fill_color,
                    stroke: PAGE_SEPARATOR_COLOR,
                    strokeWidth: 1,
                    hasControls: false,
                    lockMovementX: true,
                    lockMovementY: true,
                });
                pageRects.push(rect);
                this.canvas.add(rect);
                // 2022/01/24 비활성화 코드 comment out
                // const circ = new fabric.Circle({
                //   left: x0,
                //   top: 0,
                //   radius: 6,
                //   fill: PAGE_SEPARATOR_COLOR,
                //   stroke: "",
                //   strokeWidth: 0,
                //   hasControls: false,
                //   lockMovementX: true,
                //   lockMovementY: true,
                // });
                // // pageRects.push(circ);
                // const rect2 = new fabric.Rect({
                //   left: x0,
                //   top: 0,
                //   width: 15,
                //   height: 20,
                //   fill: PAGE_NUMBER_BGCOLOR,
                //   stroke: "",
                //   strokeWidth: 0,
                //   hasControls: false,
                //   lockMovementX: true,
                //   lockMovementY: true,
                // });
                // // pageRects.push(rect2);
                // const pageNum = chunk.strokes[0].page + 1; // kitty
                // 2022/01/24, 웅진에서 페이지번호 삭제
                // if (isStudyLive()) {
                //   const pageName = getPageTitleForController(chunk.strokes[0], this._analyzer);
                //   const txt = new fabric.Text(` ${pageName} `, {
                //     fontFamily: "",
                //     fontSize: 12,
                //     width: 30,
                //     height: 10,
                //     left: x0 + 1,
                //     top: 0,
                //     textBackgroundColor: PAGE_NUMBER_BGCOLOR,
                //     fill: PAGE_NUMBER_COLOR,
                //     stroke: "",
                //     strokeWidth: 0,
                //     hasControls: false,
                //     lockMovementX: true,
                //     lockMovementY: true,
                //   });
                //   numberRects.push(txt);
                //   this.canvas.add(txt);
                // }
            }
            this.clearPageRects();
            this.pageRects = pageRects;
            this.numberRects = numberRects;
            // this.setPageRects(pageRects);
            // console.log(chunks);
            return page_start_time;
        };
        this.clearPageRects = () => {
            if (this.pageRects) {
                this.pageRects.forEach(rect => {
                    this.canvas.remove(rect);
                });
            }
            if (this.numberRects) {
                this.numberRects.forEach(rect => {
                    this.canvas.remove(rect);
                });
            }
        };
        // Drawing iOS Data Format
        /**
         *
         */
        this.resetPathArray = () => {
            if (this.canvas) {
                this.pathArray.forEach(path => {
                    this.canvas.remove(path);
                });
            }
            this.pathArray = [];
        };
        this.initPathArray = strokes_t => {
            const size = this.size;
            const strokes = strokes_t.strokes;
            const start_time = strokes_t.startTime;
            const duration = strokes_t.duration;
            const n_entries = size.width + 1;
            // const density_bars = Array.apply(null, Array(n_entries + 1)).map(
            const density_bars = Array.from({ length: n_entries + 1 }, () => 0);
            const seg_width = n_entries / duration;
            strokes.forEach((stroke, index) => {
                const dots = stroke.dotArray;
                dots.forEach(dot => {
                    const t = Math.floor((dot.time - start_time) * seg_width);
                    const before = density_bars[t];
                    density_bars[t] = before + 1;
                });
            });
            const max = Math.max(...density_bars);
            const relative_density = density_bars.map(v => v / max);
            const pathOption = {
                objectCaching: false,
                stroke: STROKE_PULSE_COLOR,
                strokeWidth: 1 / seg_width,
                strokeLineCap: 'round',
                fill: STROKE_PULSE_COLOR,
            };
            const h1 = size.height - 1;
            relative_density.forEach((v, i) => {
                const x = i;
                const y0 = h1;
                const y1 = h1 - v * 20;
                const y2 = this.size.height - v * 20;
                if (v > 0) {
                    const line = new fabric.Line([0, 0, 0, v * 20], {
                        left: x,
                        top: y2,
                        fill: '',
                        stroke: STROKE_PULSE_COLOR,
                        strokeWidth: 1,
                        hasControls: false,
                        lockMovementX: true,
                        lockMovementY: true,
                    });
                    if (this.canvas) {
                        this.canvas.add(line);
                    }
                }
            });
            const nowPlayingLine = new fabric.Line([0, 0, 0, size.height], {
                left: this.playingTIme,
                top: 0,
                fill: '',
                stroke: NOW_PAYING_BAR_COLOR,
                strokeWidth: 2,
                hasControls: false,
                lockMovementX: true,
                lockMovementY: true,
            });
            this.playingLine = nowPlayingLine;
            this.canvas.add(nowPlayingLine);
            // this.canvas.requestRenderAll();
            this.canvas.requestRenderAll();
        };
        this.setPlayingTime = ms => {
            this.playingTIme = ms;
            if (this.canvas && this.playingLine) {
                this.playingLine.set({ left: ms * this.seg_width, top: 0 });
                this.canvas.requestRenderAll();
            }
        };
        this.setTimeRange = arg => {
            const { begin, end } = arg;
            this.beginTime_view = begin;
            this.endTime_view = end;
            console.log(this.beginTime_view, this.endTime_view);
            const canvas = this.canvas;
            canvas.clear();
            this.initGraph(canvas);
        };
        this.replayStorage = rs;
        this.playingTIme = -1;
        this.strokes = rs.strokes;
        this.duration = 0;
        this.n_entries = 0;
        this.seg_width = 1;
        this.size = null;
        this.canvasName = canvasName;
        this.canvas = null;
        this.pathArray = [];
        this.strokeChunk = rs.pageChunks;
        // 2021/12/28
        this.beginTime_whole = rs.startTime;
        this.endTime_whole = rs.endTime + 1;
        this.beginTime_view = this.beginTime_whole;
        this.endTime_view = this.endTime_whole;
    }
}
