import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import axios from 'axios';
import { base64StringToBlob } from 'blob-util';

const logPrefix = ' [ PostStore ] ';

export const loadingSate = {
    READY: 'READY',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
};

export const postSortType = {
    Recent: 'Recent',
    Recommend: 'Recommend',
};

const EmptyTag = {
    id: '',
    groupId: '',
    userId: '',
    name: '',
};

const EmptyPost = {
    postId: '',
    groupId: '',
    userId: '',
    topicId: '',
    title: '',
    body: '',
    tagList: Array(3).fill({ ...EmptyTag }),
    notice: false,
    likeList: [],
    createdDatetime: '',
    updatedDatetime: '',
    postFiles: [],
};

const EmptyPostAttachments = {
    file: '',
};

export default class PostStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    tag = Object.assign({}, EmptyTag);
    post = Object.assign({}, EmptyPost);
    postDummy = Object.assign({}, EmptyPost);
    postList = [];
    postAttachments = { ...EmptyPostAttachments };
    isOverSize = false;
    totalPostCount = 0;
    postNoticeCount = 0;

    currentSortType = postSortType.Recent;

    likeLoading = loadingSate.READY;
    postListLoading = loadingSate.READY;

    postWritingState = loadingSate.READY;

    openConfirmDialog = false;

    selectedTag = {};

    get isPostListLoading() {
        return this.postListLoading === loadingSate.PENDING;
    }

    get isPostCreateValidation() {
        // return this.isPostTitleValidation && this.isPostBodyValidation && this.isPostTagValidation && this.postListLoading !== loadingSate.PENDING;
        return this.isPostTitleValidation && this.isPostBodyValidation && this.postListLoading !== loadingSate.PENDING;
    }

    get isPostTitleValidation() {
        const target = this.post.title;
        return target !== null && target !== '' && target !== undefined;
    }

    get isPostBodyValidation() {
        const target = this.post.body;
        return target !== null && target !== '' && target !== undefined;
    }

    get isPostTagValidation() {
        const target = this.post.tagList.map(tag => tag.name).join('');
        return target !== null && target !== '';
    }

    changePostFile = () => {
        console.log('this.post.postFiles : >> ', this.post.postFiles);
        const param = { fileId: 0, name: this.postAttachments.file.name, type: this.postAttachments.file.type, size: this.postAttachments.file.size };
        if (this.post.postFiles.length) {
            this.post.postFiles[0] = param;
        } else {
            this.post.postFiles.push(param);
        }
        this.post = { ...this.post };
    };

    removePostFile = fileIndex => {
        this.post.postFiles.splice(fileIndex, 1);
        this.post = { ...this.post, postFiles: [...this.post.postFiles] };
    };

    setOverSize = isOverSize => {
        this.isOverSize = isOverSize;
    };

    changePostAttachments = file => {
        this.postAttachments.file = file;
    };

    initPostAttachments = () => {
        this.postAttachments = { ...EmptyPostAttachments };
        this.isOverSize = false;
    };

    initPost = () => {
        console.log(logPrefix, 'init Post...');
        this.post = Object.assign({}, EmptyPost);
        this.postDummy = Object.assign({}, EmptyPost);
        this.postList = [];
        this.currentSortType = postSortType.Recent;
        this.postAttachments = Object.assign({}, EmptyPostAttachments);
        this.isOverSize = false;
        this.totalPostCount = 0;
    };

    initPostList = () => {
        this.postList = [];
    };

    initPostDummy = () => {
        this.postDummy = Object.assign({}, EmptyPost);
        this.postAttachments = Object.assign({}, EmptyPostAttachments);
    };

    setPostSortType = sortType => {
        this.currentSortType = sortType;
    };

    changePostTopic = e => {
        this.post.topicId = e.target.value;
    };

    changePostTitle = e => {
        this.post.title = e.target.value;
    };

    changePostBody = e => {
        this.post.body = e.target.value;
    };

    changePostTag = e => {
        if (this.postDummy && this.postDummy.tagList.length >= parseInt(e.target.id) + 1) {
            this.post.tagList[e.target.id].name = e.target.value;
        } else {
            const t = _.cloneDeep(this.tag);
            t.name = e.target.value.trim();
            this.post.tagList[e.target.id] = t;
        }
    };

    changePostNotice = value => {
        this.post.notice = value;
    };

    setPostDummy = callBack => {
        console.log('this.postDummy', this.postDummy);
        this.post = _.cloneDeep(this.postDummy);
        callBack && callBack.changeState();
    };

    updatePost = () => {
        this.postDummy = _.cloneDeep(this.post);
    };

    closeConfirmDialog = () => {
        this.openConfirmDialog = false;
    };

    get isInputValidation() {
        let checkInput = true;
        const tagNameList = this.post.tagList.filter(tag => tag.name !== '').map(tag => tag.name.trim());
        const set = new Set(tagNameList);
        if (this.post.title === '') {
            checkInput = false;
            this.errMsg = '게시글 제목을 입력하세요.';
        } else if (tagNameList.length !== set.size) {
            checkInput = false;
            this.errMsg = '중복된 태그는 사용할 수 없습니다.';
        }
        this.openConfirmDialog = !checkInput;
        return checkInput;
    }

    deDuplicationFromList = arr => {
        const prevList = [...arr];
        const map = new Map();

        for (const obj of prevList) {
            map.set(JSON.stringify(obj), obj);
        }

        const uniqueArr = [...map.values()];
        console.log('unique arr : >> ', uniqueArr);
        return uniqueArr;
    };

    get isWritingPostState() {
        return this.postWritingState !== loadingSate.READY;
    }

    *getPostList(groupId, userId, keyWord, page, rowsPerPage) {
        console.log(
            logPrefix,
            'getPostList starting... groupId={}, userId={}, keyWord={}, page={}, rowsPerPage={}',
            groupId,
            userId,
            keyWord,
            page,
            rowsPerPage,
        );
        if (page === 1 || page === null || page === undefined) {
            this.postListLoading = loadingSate.PENDING;
        }
        try {
            let url =
                this.serverContextPath +
                `/api/v1/post/list?group-id=${groupId !== undefined ? groupId : 0}&user-id=${userId}&sort-type=${this.currentSortType}`;
            if (keyWord !== null && keyWord !== '' && keyWord !== undefined) {
                url = url + `&search-keyword=${keyWord}`;
            }
            if (page !== null && page !== '' && page !== undefined) {
                url = url + `&page=${page}`;
            }
            if (rowsPerPage !== null && rowsPerPage !== '' && rowsPerPage !== undefined) {
                url = url + `&rows-per-page=${rowsPerPage}`;
            }
            const response = yield axios.get(url);
            if (response.data) {
                // 중복제거 임시조치
                const deDuplicationData = this.deDuplicationFromList(response.data.postList);
                // this.totalPostCount = response.data.postCount;
                this.totalPostCount = deDuplicationData.length;
                this.postNoticeCount = response.data.postNoticeCount;
                this.postList = this.postList.concat(deDuplicationData);
            }
            console.log(logPrefix, 'getPostList response={}', response.data);
            this.postListLoading = loadingSate.SUCCESS;
        } catch (e) {
            console.log(logPrefix, 'getPostList Failed ...');
            this.postListLoading = loadingSate.FAILED;
        }
    }

    *getPostListByTag(selectedTag) {
        console.log(logPrefix, 'getPostListByTag : >>', selectedTag);
        this.postListLoading = loadingSate.PENDING;
        try {
            this.postList = [];
            this.selectedTag = selectedTag;
            console.log(logPrefix, 'getPostListByTag starting ... selectedTag={}', selectedTag);
            const param = selectedTag.idList;
            const response = yield axios.post(this.serverContextPath + `/api/v1/post/detail/tag`, param);
            console.log(logPrefix, 'getPostListByTag end response ={}', response.data);
            this.postList = response.data;

            this.postListLoading = loadingSate.SUCCESS;
        } catch (e) {
            console.log(logPrefix, 'getRoomListBySelectedTag Failed ...');
            this.postListLoading = loadingSate.FAILED;
        }
    }

    *createPost(groupId, userId, callBack) {
        console.log(logPrefix, 'createPost starting... groupId={}, userId={}', groupId, userId);
        this.postWritingState = loadingSate.PENDING;
        try {
            if (!this.isInputValidation) {
                return false;
            }
            this.post.title = this.post.title.trim();
            this.post.body = this.post.body.trim();
            this.post.tagList.map(t => {
                t.name = t.name.trim();
                return t;
            });
            const postParam = Object.assign({}, this.post);
            postParam.userId = userId;
            postParam.groupId = groupId;
            const param = new FormData();
            const blobPost = new Blob([JSON.stringify(postParam)], { type: 'application/json' });

            if (this.postAttachments.file !== '' && !this.isOverSize) {
                console.log('this.postAttachments.file : >> ', this.postAttachments.file);
                const fileData = Buffer.from(yield this.postAttachments.file.arrayBuffer()).toString('base64');
                const blobFile = base64StringToBlob(fileData, 'application/octet-stream');
                param.append('attachments', blobFile, this.postAttachments.file.name);
            }

            param.append('post', blobPost);

            console.log('param', param);

            const response = yield axios.post(this.serverContextPath + '/api/v1/post/create', param);
            this.post = Object.assign({}, EmptyPost);
            this.postAttachments = { ...EmptyPostAttachments };
            this.postList = response.data.postList;
            this.postNoticeCount = response.data.postNoticeCount;
            callBack && callBack.getTagList(groupId);
            callBack && callBack.dialogClose();

            this.postWritingState = loadingSate.SUCCESS;
        } catch (e) {
            console.log(logPrefix, 'createPost Failed ...');
            console.log(logPrefix, e);
            this.postWritingState = loadingSate.FAILED;
        } finally {
            this.postWritingState = loadingSate.READY;
        }
    }

    *modifyPost(groupId, userId, callBack) {
        console.log(logPrefix, 'modifyPost starting... groupId={}, userId={}', groupId, userId, this.post);
        this.postWritingState = loadingSate.PENDING;
        try {
            if (!this.isInputValidation) {
                return false;
            }
            const postParam = Object.assign({}, this.post);
            postParam.userId = userId;
            postParam.groupId = groupId;

            const param = new FormData();
            const blobPost = new Blob([JSON.stringify(postParam)], { type: 'application/json' });

            if (this.postAttachments.file !== '' && !this.isOverSize) {
                const fileData = Buffer.from(yield this.postAttachments.file.arrayBuffer()).toString('base64');
                const blobFile = base64StringToBlob(fileData, 'application/octet-stream');
                param.append('attachments', blobFile, this.postAttachments.file.name);
            }

            param.append('post', blobPost);

            const response = yield axios.post(this.serverContextPath + '/api/v1/post/modify', param);
            this.post = response.data;
            this.postAttachments = { ...EmptyPostAttachments };
            this.updatePost();
            callBack && callBack.getTagList(groupId);
            callBack && callBack.dialogClose();

            this.postWritingState = loadingSate.SUCCESS;
        } catch (e) {
            console.log(logPrefix, 'modifyPost Failed ...');
            this.postWritingState = loadingSate.FAILED;
        } finally {
            this.postWritingState = loadingSate.READY;
        }
    }

    *increasePostViews(postId, userId, callBack) {
        console.log(logPrefix, 'increasedPostViews starting... postId={}, userId={}', postId, userId);
        try {
            const response = yield axios.post(this.serverContextPath + `/api/v1/post/${postId}/${userId}`);
            console.log(logPrefix, 'increasedPostViews response={}', response.data);
            this.post = response.data;
            this.postDummy = _.cloneDeep(response.data);
            callBack && callBack.selectCommentList(response.data.groupId);
        } catch (e) {
            console.log(logPrefix, 'increasedPostViews Failed ...');
        }
    }

    *changeLikeState(loginUser, groupId, postId, likeId) {
        console.log(logPrefix, 'changeLikeState starting... loginUser={}, groupId={}, postId={}, likeId={}', loginUser, groupId, postId, likeId);
        this.likeLoading = loadingSate.PENDING;
        try {
            const parma = {
                groupId: groupId,
                userId: loginUser.id,
            };
            const response = yield axios.post(this.serverContextPath + `/api/v1/post/${postId}/like/${likeId}`, parma);
            console.log(logPrefix, 'changeLikeState response={}', response.data);
            this.postList.find(postData => postData.postId === postId).likeList = response.data;
            this.post.likeList = response.data;
            this.updatePost();
        } catch (e) {
            console.log(logPrefix, 'changeLikeState Failed ...');
        } finally {
            this.likeLoading = loadingSate.SUCCESS;
        }
    }

    *deletePost(post, userId, callBack) {
        console.log(logPrefix, 'deletePost starting... postId={}, userId={}', post, userId);
        try {
            const params = Object.assign({}, post);
            params.userId = userId;
            const response = yield axios.post(this.serverContextPath + `/api/v1/post/delete`, params);
            callBack && callBack.getTagList();
            callBack && callBack.moveToPrevPage();
            console.log(logPrefix, 'deletePost response={}', response.data);
        } catch (e) {
            console.log(logPrefix, 'deletePost Failed ...');
        }
    }

    *getCommentCount(postId) {
        console.log(logPrefix, 'getPostCommentCount starting... postId={}', postId);
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/post/comment/count/${postId}`);
            console.log(logPrefix, 'getPostCommentCount response={}', response.data);
            this.post.commentCount = response.data;
            this.postDummy.commentCount = response.data;
        } catch (e) {
            console.log(logPrefix, 'getPostCommentCount Failed ...');
        }
    }
}
