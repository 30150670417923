import React, { Component, useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { MyClassRoomSortType, RoomSortType } from '../../stores/RoomStore';
import { DATE_UTIL } from '../../common/util/date.util';
import dayjs from 'dayjs';
import Box from '@material-ui/core/Box';
import { makeStyles, Popover, TextField, Typography } from '@material-ui/core';
import { ToggleButtonGroup } from '@material-ui/lab';
import ToggleButton from '@material-ui/lab/ToggleButton';
import clsx from 'clsx';
import { UTIL } from '../../common/util/common.util';
import Button from '@material-ui/core/Button';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';

const useStyles = makeStyles(_theme => ({
    root: {
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    box: {
        borderRadius: 4,
        background: '#fff',
        padding: '22px 22px 4px',
        boxSizing: 'border-box',
        boxShadow: '0 5px 7px 0 rgba(0, 0, 0, 0.45)',
    },
    popOverTextStyle: {
        fontSize: '0.875rem',
        fontWeight: 600,
        color: '#303030',
        marginBottom: 8,
    },
    textStyle: {
        fontSize: '0.75rem',
        fontWeight: 600,
        marginRight: 5,
    },
    toggleButton: {
        marginBottom: 18,
        '& .MuiToggleButton-root': {
            padding: '3px 13px',
            minWidth: 64,
        },
        '& .MuiToggleButton-root.Mui-selected': {
            boxShadow:
                'inset 0 2px 4px 0 #bfbfbf, inset 0 1px 0 0 #bfbfbf, inset 1px 0 0 0 #bfbfbf, inset 0 -1px 0 0 #bfbfbf, inset -1px 0 0 0 #bfbfbf',
            background: '#fafafa',
            '& .MuiToggleButton-label': {
                opacity: 1,
            },
        },
        '& .MuiToggleButton-label': {
            fontSize: '0.875rem',
            color: '#303030',
            opacity: 0.5,
        },
    },
    buttonWidth: {
        width: '100%',
        '& .MuiToggleButton-root': {
            minWidth: '50%',
        },
    },
    datePickers: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 18,
        '& input': {
            padding: '7px 10px',
            fontSize: '0.75rem',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline':
            {
                borderColor: '#a7afb2',
                borderWidth: '1px',
            },
    },
    buttonStyle: {
        padding: 0,
        marginBottom: 10,
        '&:hover': {
            background: 'transparent',
        },
    },
}));

const LOOK_UP_PERIOD_TYPE = {
    WEEK: 'week',
    MONTHS: 'months',
    LAST_MONTH: 'last_month',
};

const TOGGLE_BTN_NAMES = {
    All: '전체',
    Live: '진행중',
    Future: '진행예정',
    Ended: '종료된',
    Recent: '최근순',
    Past: '과거순',
};

const today = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT);

const convertingDatetimeByLookUpPeriod = period => {
    let startDatetime = today;
    let endDatetime = DATE_UTIL.getTimeZoneDate(dayjs(startDatetime).add(period, 'months'), DATE_UTIL.FORMAT.DATE_FORMAT);
    if (period < 0) {
        startDatetime = DATE_UTIL.getTimeZoneDate(dayjs(today).add(period, 'months').startOf('month'), DATE_UTIL.FORMAT.DATE_FORMAT);
        endDatetime = DATE_UTIL.getTimeZoneDate(dayjs(startDatetime).endOf('month'), DATE_UTIL.FORMAT.DATE_FORMAT);
    }

    return { start: startDatetime, end: endDatetime };
};

const convertingTitle = (period, situation, order) => {
    const periodName = period === 0 ? '사용자 설정' : period > 0 ? `${period}개월` : `지난달`;

    return `${periodName} • ${TOGGLE_BTN_NAMES[situation]} • ${TOGGLE_BTN_NAMES[order]}`;
};

function DateFilterComponent(props) {
    const { open, closeDateFilter } = props;
    const { startDatetime, endDatetime, changeStartDatetime, changeEndDatetime, resetDatetime } = props;
    const { situation, changeSituation } = props;
    const { order, changeOrder } = props;
    const { fetchData } = props;
    const [lookupPeriod, setLookupPeriod] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);

    const classes = useStyles();

    const handleLookupPeriod = (_e, value) => {
        if (value !== null) {
            setLookupPeriod(value);
        }
    };

    const convertingStartAndEndDatetime = useMemo(() => convertingDatetimeByLookUpPeriod(lookupPeriod), [lookupPeriod]);

    const handleChangeStartTime = e => {
        const {
            target: { value },
        } = e;

        changeStartDatetime(value);
    };

    const handleChangeEndTime = e => {
        const {
            target: { value },
        } = e;

        changeEndDatetime(value);
    };

    const handleChangeSituation = (_e, value) => {
        if (value !== null) {
            changeSituation(value);
        }
    };

    const handleChangeOrder = (_e, value) => {
        if (value !== null) {
            changeOrder(value);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = e => {
        setAnchorEl(e.currentTarget);
    };

    const title = useMemo(() => {
        return convertingTitle(lookupPeriod, situation, order);
    }, [lookupPeriod, situation, order]);

    useEffect(() => {
        if (lookupPeriod !== 0) {
            const { start, end } = convertingStartAndEndDatetime;
            UTIL.debouncedFunc(() => fetchData(start, end), 1000);

            resetDatetime(start, end);
        }
    }, [lookupPeriod]);

    useEffect(() => {
        if (lookupPeriod == 0) {
            UTIL.debouncedFunc(fetchData, 1000);
        }
    }, [startDatetime, endDatetime]);

    useEffect(() => {
        console.log('check props do fetchData');
        UTIL.debouncedFunc(fetchData, 1000);
    }, [situation, order]);

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="flex-end">
                <Button aria-owns={open ? 'simple-popper' : undefined} onClick={handleClick} className={classes.buttonStyle} disableRipple>
                    <Typography className={classes.textStyle}>{title}</Typography>
                    <ArrowDownIcon />
                </Button>
            </Box>
            <Popover
                id="simple-popper"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box className={classes.box}>
                    <Typography className={classes.popOverTextStyle}>조회기간</Typography>
                    <ToggleButtonGroup value={lookupPeriod} exclusive onChange={handleLookupPeriod} className={classes.toggleButton}>
                        <ToggleButton value={1}>1개월</ToggleButton>
                        <ToggleButton value={3}>3개월</ToggleButton>
                        <ToggleButton value={-1}>지난달</ToggleButton>
                        <ToggleButton value={0}>직접 입력</ToggleButton>
                    </ToggleButtonGroup>

                    {lookupPeriod === 0 && (
                        <Box className={classes.datePickers}>
                            <form noValidate>
                                <TextField
                                    id="start-date"
                                    // label="일정날짜"
                                    type="date"
                                    value={startDatetime}
                                    variant="outlined"
                                    onChange={handleChangeStartTime}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </form>
                            <span style={{ margin: '0 8px' }}>~</span>
                            <form noValidate>
                                <TextField
                                    id="end-date"
                                    // label="일정날짜"
                                    type="date"
                                    value={endDatetime}
                                    variant="outlined"
                                    onChange={handleChangeEndTime}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </form>
                        </Box>
                    )}

                    <Typography className={classes.popOverTextStyle}>전체 구분</Typography>
                    <ToggleButtonGroup value={situation} exclusive onChange={handleChangeSituation} className={classes.toggleButton}>
                        <ToggleButton value={RoomSortType.All}>전체</ToggleButton>
                        <ToggleButton value={RoomSortType.Live}>진행중</ToggleButton>
                        <ToggleButton value={RoomSortType.Future}>진행예정</ToggleButton>
                        <ToggleButton value={RoomSortType.Ended}>종료된</ToggleButton>
                    </ToggleButtonGroup>

                    <Typography className={classes.popOverTextStyle}>정렬</Typography>
                    <ToggleButtonGroup
                        value={order}
                        exclusive
                        onChange={handleChangeOrder}
                        className={clsx(classes.toggleButton, classes.buttonWidth)}
                    >
                        <ToggleButton value={MyClassRoomSortType.Recent}>최근순</ToggleButton>
                        <ToggleButton value={MyClassRoomSortType.Past}>과거순</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Popover>
        </div>
    );
}

export default DateFilterComponent;
