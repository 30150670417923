import React, { useMemo } from 'react';
import { roomState } from '../../stores/RoomStore';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as DotIcon } from '../../common/images/DotIcon.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(_theme => ({
    caption: {
        backgroundColor: 'transparent',
        color: '#fff',
        padding: '3px 6px 2px',
        fontSize: '0.75rem',
        fontFamily: 'Montserrat!important',
        marginLeft: 7,
        textTransform: 'uppercase',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            marginRight: 3,
        },
    },
    captionLive: {
        width: 35,
        margin: '0 0 5px 0',
        backgroundColor: '#FB4A59',
    },
    captionEnd: {
        width: 35,
        margin: '0 0 5px 0',
        backgroundColor: '#505050',
    },
    captionFuture: {
        width: 35,
        margin: '0 0 5px 0',
        backgroundColor: '#a3a8af',
    },
}));

const CAPTION_NAME_BY_ROOM_STATE = {
    Ended: '종료',
    Live: 'LIVE',
    Future: '예정',
};

const CLASS_NAME_BY_ROOM_STATE = {
    Ended: 'captionEnd',
    Live: 'captionLive',
    Future: 'captionFuture',
};

function selectClassName(state) {
    return CLASS_NAME_BY_ROOM_STATE[state];
}

function selectCaptionName(state) {
    if (state === roomState.Live) {
        return (
            <>
                <DotIcon />
                {CAPTION_NAME_BY_ROOM_STATE[state]}
            </>
        );
    }
    return CAPTION_NAME_BY_ROOM_STATE[state];
}

export default function CaptionComponent({ state }) {
    const classes = useStyles();
    const captionStyleName = useMemo(() => selectClassName(state), [state]);
    const captionName = useMemo(() => selectCaptionName(state), [state]);
    return <Box className={clsx(classes.caption, classes[captionStyleName])}>{captionName}</Box>;
}
