import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Box, Drawer, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import { DATE_UTIL } from '../../common/util/date.util';
import { DEFAULT_PAGE } from '../../stores/PaginationStore';
import { MESSAGE_UTIL } from '../../common/util/message.util';
import { NOTIFICATION_TYPE } from '../../stores/NoticeStore';
import { withRouter } from '../../components/WithRouter';
import { ClassMainPath } from '../../common/ClassMainPath';

const styles = theme => ({
    root: {},
    drawerPaper: {
        '& .MuiBackdrop-root': {
            background: 'transparent',
        },
        '& .MuiDrawer-paper': {
            width: 234,
            // marginTop: 86,
            boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.25)',
            borderTop: '1px solid #c0c2c3',
            '@media all and (max-width: 1200px)': {
                borderTop: '0',
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
        overflow: 'hidden',
        height: 'auto',
    },
    titleBox: {
        padding: '10px 8px',
        background: '#f0f1f1',
    },
    titleText: {
        fontSize: '1rem',
        fontWeight: 600,
        color: '#0d0d0d',
        '@media all and (min-width: 601px)': {
            fontSize: '1.063rem',
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            width: 20,
            height: 20,
        },
        '@media all and (min-width: 601px)': {
            width: 24,
            height: 24,
        },
    },
    contentsBox: {
        padding: '18px 10px 13px',
        borderBottom: '1px dashed #a3a8af',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    contentsColor: {
        background: 'rgba(0, 151, 255, 0.2)',
    },
    marginBottom: {
        marginBottom: 15,
    },
    textStyle: {
        fontSize: '0.75rem',
        color: '#828282',
        lineHeight: 1.2,
    },
    contentsText: {
        fontSize: '0.875rem',
        color: '#0d0d0d',
        lineHeight: 1.3,
    },
    beforeBox: {
        display: 'flex',
        '&::before': {
            display: 'block',
            width: 60,
            height: 26,
            background: 'transparent',
            content: "''",
        },
    },
    boldText: {
        fontWeight: 600,
    },
    imgBox: {
        width: 40,
        height: 25,
        borderRadius: 2,
        background: '#fff',
        marginRight: 10,
        '& img': {
            width: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
        },
    },
    oldContentBox: {
        opacity: 0.6,
    },
});

class NotificationComponent extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (prevProps.notificationOpen !== this.props.notificationOpen && !this.props.notificationOpen) {
            this.props.changePage(DEFAULT_PAGE);
        }
    }

    handleScroll = _e => {
        const scrollHeight = this.container.parentElement.scrollHeight;
        const scrollTop = this.container.parentElement.scrollTop;
        const clientHeight = this.container.parentElement.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight) {
            const nextPage = this.props.page + 1;
            this.props.changePage(nextPage);

            this.props.requestNotifications(nextPage, this.props.rowsPerPage);
        }
    };

    handleClickNotification = notice => {
        if (!notice) {
            return false;
        }

        let moveToUrl = `/class/${notice.groupId}`;

        if (notice.type === NOTIFICATION_TYPE.CreateRoom) {
            moveToUrl = `${moveToUrl}/${ClassMainPath.allSchedule}`;
        } else {
            moveToUrl = `${moveToUrl}/${ClassMainPath.penRecord}`;
        }

        this.props.navigate(moveToUrl);
    };

    render() {
        const { classes, notifications, loginUser } = this.props;

        return (
            <Drawer
                onClose={this.props.handleNotificationClose}
                anchor="right"
                open={this.props.notificationOpen}
                className={classes.drawerPaper}
                onScrollCapture={e => this.handleScroll(e)}
            >
                <div id="notice" className={classes.root} ref={el => (this.container = el)}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.titleBox} ref={this.noticeRef}>
                        <Typography className={classes.titleText}>알림</Typography>
                        <IconButton onClick={this.props.handleNotificationClose} className={classes.iconButton}>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>
                    {notifications &&
                        notifications.length > 0 &&
                        notifications.map(notice => {
                            return (
                                <Box
                                    key={`${notice.id}_${notice.groupId}_${notice.userId}`}
                                    className={clsx(classes.contentsBox, notice.notified && classes.oldContentBox)}
                                    onClick={() => this.handleClickNotification(notice)}
                                >
                                    <Box display="flex" className={classes.marginBottom}>
                                        <Box className={classes.imgBox}>
                                            <img src={notice.groupImage} alt="class" />
                                        </Box>
                                        <Box>
                                            <Typography className={classes.textStyle}>{notice.groupName}</Typography>
                                            <Typography className={classes.textStyle}>
                                                {DATE_UTIL.getNoticeTimeDifferentMessage(notice.createdDatetime)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.beforeBox}>
                                        <Typography className={classes.contentsText}>
                                            {MESSAGE_UTIL.getNotificationMessage(notice.type, loginUser.name)}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        })}
                </div>
            </Drawer>
        );
    }
}

export default withRouter(withStyles(styles)(NotificationComponent));
