import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    IconButton,
    InputBase,
    OutlinedInput,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../common/images/DialogCloseIcon.svg';
import clsx from 'clsx';
import { inject, observer } from 'mobx-react';
import { ToastsStore } from 'react-toasts';
import { CircularProgress } from '@mui/material';
import { PenState, SelectNeolabNoteState } from '../../stores/NeoPenStore';
import { ReactComponent as MinusCircleIcon } from '../../common/images/MinusCircleIcon.svg';
import { ReactComponent as ArrowsClockwiseIcon } from '../../common/images/ArrowsClockwiseIcon.svg';
import { ReactComponent as PenPlusCircleIcon } from '../../common/images/PenPlusCircleIcon.svg';
import DigitalPaper from '../../common/images/DigitalPaper.png';
import { ReactComponent as MagnifyingGlassIcon } from '../../common/images/MagnifyingGlassIcon.svg';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import RegisterNoteIcon from '../../common/images/RegisterNoteIcon.png';
import { ReactComponent as UnCheckedIcon } from '../../common/images/UnCheckedIcon.svg';
import { ReactComponent as CheckedIcon } from '../../common/images/CheckedIcon.svg';
import NoteImg1 from '../../common/images/NoteImg1.png';
import { ReactComponent as NoteImg2 } from '../../common/images/NoteImg2.svg';

//TODO : 개발 서버, 운영서버 다름
import neolabNotes from '../../neolab/neolab_notes_paper_group.json';
// import neolabNotes from '../../neolab/neolab_notes_paper_group_dev.json';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 310,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 600px)': {
                width: 500,
            },
        },
        '& .MuiDialog-paper': {
            '@media all and (max-width: 600px)': {
                margin: 0,
            },
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleBox: {
        marginBottom: 30,
        '@media all and (max-width: 600px)': {
            marginBottom: 10,
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    boxStyle: {
        padding: '30px 0 0',
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    buttonStyle: {
        width: '90%',
        margin: '26px auto 0',
        background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        color: '#fff',
        fontSize: '1.125rem',
        padding: '10px 0',
        borderRadius: 7,
        '&:hover': {
            fontWeight: 800,
            background: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            height: 33,
            padding: '6px 0',
            fontSize: '0.75rem',
            borderRadius: 4,
        },
    },
    input: {
        display: 'none',
    },
    textField: {
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #c4c4c4',
            },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            '&:hover': {
                borderColor: '#c4c4c4',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '5px 7px',
            color: '#000',
            fontSize: '0.75rem',
            '&::placeholder': {
                color: '#a3a8af',
                opacity: 1,
                fontWeight: 300,
            },
        },
        '& .MuiOutlinedInput-multiline': {
            padding: '0!important',
        },
        '@media all and (max-width: 600px)': {
            '& .MuiOutlinedInput-input': {
                fontSize: '0.75rem',
            },
        },
    },
    boxMargin: {
        marginBottom: 24,
        marginTop: 10,
        '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            marginRight: 0,
        },
        '@media all and (max-width: 600px)': {
            marginBottom: 15,
        },
    },
    penBox: {
        width: '100%',
        height: 190,
        border: '2px dashed #d3d4d5',
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    penTitleText: {
        fontSize: '0.875rem',
        color: '#0097ff',
        fontWeight: 600,
        marginBottom: 19,
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    penBoxText: {
        width: 200,
        fontSize: '0.75rem',
        color: '#6c6969',
        fontWeight: 300,
        marginLeft: 10,
    },
    textFieldWidth: {
        width: '100%',
        margin: '0 auto',
        '@media all and (max-width: 600px)': {
            width: '100%',
        },
        '& .MuiInputBase-root': {
            borderRadius: 4,
            '& input': {
                width: '100%',
            },
        },
    },
    textStyle: {
        fontSize: '0.875rem',
        color: '#000',
    },
    textStyle2: {
        fontSize: '0.75rem',
        color: '#000',
        marginTop: 12,
    },
    textStyleRequired: {
        '&:after': {
            content: '"*"',
            color: '#ff0000',
        },
    },
    textBold: {
        fontWeight: 600,
        textDecoration: 'underline',
    },
    autoCompleteBox: {
        marginBottom: 10,
        '& .MuiInputBase-root': {
            padding: '2.5px 6px',
            backgroundColor: '#f2f3f7',
            color: '#b6b6b6',
        },
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #f2f3f7',
            },
        '& .MuiAutocomplete-input': {
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#a3a8af',
                opacity: 1,
                fontWeight: 300,
            },
        },
        '& .MuiAutocomplete-popupIndicator': {
            display: 'none',
        },
    },
    searchBtn: {
        padding: '6px 10px',
        borderRadius: 4,
        fontSize: '0.875rem',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    btnStyle: {
        border: '1px solid #d9dbde',
        borderRadius: 8,
        marginLeft: 10,
        color: '#000',
        padding: '8px 12px',
        fontWeight: 400,
        fontSize: '0.875rem',
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
            height: 35,
            borderRadius: 4,
        },
    },
    downloadInputStyle: {
        '&>div': {
            width: 'calc(100% - 85px)',
        },
    },
    helperText: {
        color: '#ff0000',
        fontSize: '0.813rem',
        marginTop: 4,
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    helperText2: {
        color: '#a3a8af',
        fontSize: '0.813rem',
        marginTop: 4,
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    radioStyle: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        '& label:first-child': {
            marginRight: 40,
        },
        '& .MuiRadio-colorPrimary.Mui-checked': {
            color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
        '@media all and (max-width: 600px)': {
            '& .MuiSvgIcon-root': {
                fontSize: '1rem',
            },
            '& .MuiFormControlLabel-label': {
                fontSize: '0.813rem',
            },
        },
    },
    toasts: {
        '@media all and (max-width: 600px)': {
            fontSize: '0.813rem',
        },
    },
    penButton: {
        width: 126,
        height: 40,
        background: '#fff',
        border: '1px solid #c4c4c4',
        borderRadius: 2,
        '& span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&:hover': {
            background: 'transparent',
        },
        '& svg': {
            marginRight: 5,
        },
        '@media all and (max-width: 600px)': {
            width: 100,
            height: 35,
        },
    },
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        '&:after': {
            content: '""',
            width: 240,
            height: 3,
            backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: 0,
        },
        '& button': {
            minWidth: 120,
            minHeight: 40,
            fontSize: '0.938rem',
            boxSizing: 'border-box',
            '&.Mui-selected': {
                fontWeight: 700,
                color: '#0097ff',
            },
        },
        '& .MuiTabs-indicator': {
            height: 3,
            backgroundColor: '#0097ff',
            zIndex: 100,
        },
    },
    listTitleText: {
        fontSize: '1rem',
        color: '#333',
        margin: '40px 0 14px',
    },
    searchBox: {
        display: 'flex',
        boxShadow: 'none',
        border: '1px solid #C4C4C4',
        padding: '4px 9px',
        margin: '12px 0 21px',
        borderRadius: 7,
        '& .MuiInputBase-root': {
            width: '100%',
        },
        '& input': {
            width: '100%',
            fontSize: '1rem',
        },
    },
    searchIconButton: {
        padding: 6,
        backgroundColor: '#f8f8f8',
        '&:hover': {
            backgroundColor: '#f8f8f8',
        },
    },
    numberText: {
        fontSize: '0.813rem',
        marginLeft: 4,
        color: '#a3a8af',
    },
    checkedBox: {
        margin: 0,
    },
    listBox: {
        maxHeight: 250,
        marginTop: 20,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '15px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '5px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
        '& .Mui-checked': {
            color: '#0097FF',
        },
        '@media all and (max-width: 600px)': {
            marginTop: 0,
        },
    },
    noteName: {
        fontSize: '1rem',
        color: '#333',
        fontWeight: 600,
        marginLeft: 10,
        cursor: 'pointer',
    },
    lightTooltip: {
        backgroundColor: '#FFFFF5',
        color: '#000',
        border: '1px solid #000',
        fontSize: '0.688rem',
        borderRadius: 0,
        marginLeft: 0,
        marginTop: 0,
    },
    inputBox: {
        width: 300,
        height: 28,
        border: '1px solid #bfbfbf',
        borderRadius: 4,
        boxSizing: 'border-box',
        marginLeft: 10,
        padding: '2px 5px',
        '& div': {
            width: '100%',
        },
        '& input': {
            padding: 0,
            width: '100%',
        },
        '@media all and (max-width: 800px)': {
            width: '100%',
        },
    },
});

class FormRegistrationNeoNoteDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberGroupSearch: false,
            originPdfName: '',
            value: '공개',
            classTab: 0,
            searchingStr: '',
            selectedNotes: new Map(),
            thumbnails: undefined,
        };
    }

    handleClose = () => {
        this.setState({ memberGroupSearch: false });
    };

    handleChangeValue = event => {
        this.setState({ value: event.target.value });
    };

    handleChangeTabs = (event, classTab) => {
        this.setState({ classTab });
    };

    handleClickNoteName = note => {
        const renamingNote = { ...note };

        renamingNote.isRenaming = true;

        this.setState({
            selectedNotes: new Map(this.state.selectedNotes).set(note.title, renamingNote),
        });
    };

    handleSearchChange = event => {
        this.setState({ searchingStr: event.target.value });
    };

    handleAllCheckBoxChanged = (event, checked) => {
        let filteredNotes = neolabNotes;

        if (this.state.searchingStr.length > 0)
            filteredNotes = neolabNotes.filter(note => note.title.toLowerCase().includes(this.state.searchingStr.toLowerCase()));

        if (checked) {
            let checkedMap = new Map(this.state.selectedNotes);

            for (let note of filteredNotes) checkedMap.set(note.title, note);

            this.setState({ selectedNotes: checkedMap });
        } else {
            this.setState({ selectedNotes: new Map() });
        }
    };

    handleCheckBoxChanged = (checked, note) => {
        if (checked) {
            this.setState({
                selectedNotes: new Map(this.state.selectedNotes).set(note.title, note),
            });
        } else {
            const uncheckedMap = new Map(this.state.selectedNotes);

            uncheckedMap.delete(note.title);

            this.setState({
                selectedNotes: uncheckedMap,
            });
        }
    };

    handleRename = (event, note) => {
        const originTitle = note.title;
        const renamedNote = this.state.selectedNotes.get(originTitle);

        renamedNote.title = event.target.value;

        this.setState({
            selectedNotes: new Map(this.state.selectedNotes).set(originTitle, renamedNote),
        });
    };

    importAll(r) {
        let images = {};
        r.keys().map(item => {
            images[item.replace('./', '')] = r(item);
        });

        return images;
    }

    componentDidMount() {
        if (this.state.thumbnails === undefined)
            this.setState({ thumbnails: this.importAll(require.context('../../neolab/thumbnails', true, /\.png$/)) });
    }

    render() {
        const { classes, type } = this.props;
        const { classTab } = this.state;

        const nPS = this.props.neoPenStore;

        const userEmail = this.props.authStore.loginUser.email;
        const groupId = this.props.classStore.selectedGroupDetail.group.id;

        let filteredNotes = neolabNotes;

        if (this.state.searchingStr.length > 0)
            filteredNotes = filteredNotes.filter(note => note.title.toLowerCase().includes(this.state.searchingStr.toLowerCase()));

        return (
            <div className={classes.root}>
                <Dialog open={this.props.formRegistrationOpen} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.titleBox}>
                        <Typography className={classes.titleText}>전용 노트 등록</Typography>
                        <IconButton className={classes.iconButton} onClick={this.props.handleCloseFormRegistration} disableRipple>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>

                    <Tabs value={classTab} onChange={this.handleChangeTabs} className={classes.tabStyle}>
                        <Tab label="목록에서 선택" disableRipple />
                        <Tab label="직접 등록" disableRipple />
                    </Tabs>

                    {classTab === 0 && (
                        <Box>
                            <Typography className={classes.listTitleText}>등록할 노트를 선택해주세요.</Typography>
                            <Box className={classes.searchBox}>
                                <InputBase placeholder="이름검색" onChange={this.handleSearchChange} />
                                <IconButton className={classes.searchIconButton} aria-label="Search" disableRipple>
                                    <MagnifyingGlassIcon />
                                </IconButton>
                            </Box>
                            <Box>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <Box display="flex" alignItems="center">
                                        <img src={RegisterNoteIcon} alt="전용 노트 아이콘" />{' '}
                                        <span className={classes.numberText}>{filteredNotes.length}개</span>
                                    </Box>
                                    {/* 전체 선택 */}
                                    <FormControlLabel
                                        control={<Checkbox icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} value="checkA" />}
                                        label=""
                                        className={classes.checkedBox}
                                        onChange={this.handleAllCheckBoxChanged}
                                    />
                                </Box>

                                <Box className={classes.listBox}>
                                    {filteredNotes.map((note, index) => {
                                        const selectedNote = this.state.selectedNotes.get(note.title);

                                        return (
                                            <Box
                                                key={`${index}_${note.title}`}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                mb={2}
                                            >
                                                <Box display="flex" alignItems="center">
                                                    {this.state.thumbnails !== undefined && (
                                                        <img
                                                            width={23}
                                                            height={33}
                                                            src={
                                                                this.state.thumbnails[
                                                                    note.title
                                                                        .replace('/', '_')
                                                                        .replace('[', '_')
                                                                        .replace(']', '_')
                                                                        .replace('\n', '') + '.png'
                                                                ].default
                                                            }
                                                            alt="image"
                                                        />
                                                    )}

                                                    {selectedNote !== undefined && selectedNote.isRenaming ? (
                                                        <Box className={classes.inputBox}>
                                                            <InputBase
                                                                placeholder=""
                                                                onChange={event => this.handleRename(event, note)}
                                                                value={selectedNote.title}
                                                            />
                                                        </Box>
                                                    ) : (
                                                        <Tooltip
                                                            title="이름바꾸기"
                                                            placement="bottom"
                                                            classes={{
                                                                tooltip: classes.lightTooltip,
                                                            }}
                                                        >
                                                            <Box onClick={() => this.handleClickNoteName(note)}>
                                                                <Typography className={classes.noteName}>{note.title}</Typography>
                                                            </Box>
                                                        </Tooltip>
                                                    )}
                                                </Box>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<UnCheckedIcon />}
                                                            checkedIcon={<CheckedIcon />}
                                                            onChange={(event, checked) => this.handleCheckBoxChanged(checked, note)}
                                                            checked={this.state.selectedNotes.has(note.title)}
                                                            value="checkA"
                                                        />
                                                    }
                                                    label=""
                                                    className={classes.checkedBox}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {classTab === 1 && (
                        <Box>
                            <Box
                                className={clsx(classes.textField, classes.downloadInputStyle)}
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                {nPS.penState === PenState.Connected && (
                                    <Button className={classes.penButton} disableRipple onClick={nPS.onConnectionChange}>
                                        <MinusCircleIcon />
                                        <span className={classes.penText}>펜 해제</span>
                                    </Button>
                                )}

                                {nPS.penState === PenState.Connecting && (
                                    <Button className={classes.penButton} disableRipple>
                                        <ArrowsClockwiseIcon />
                                        <span className={classes.penText}>연결중</span>
                                    </Button>
                                )}

                                {nPS.penState === PenState.Disconnected && (
                                    <Button className={classes.penButton} disableRipple onClick={nPS.onConnectionChange}>
                                        <PenPlusCircleIcon />
                                        <span className={classes.penText}>펜 연결</span>
                                    </Button>
                                )}
                            </Box>

                            <Box display="flex" flexDirection="column" className={classes.boxMargin}>
                                <Box className={classes.penBox}>
                                    <Box display="flex" alignItems="center">
                                        {nPS.selectNeolabNoteThumbnailUrl === undefined ? (
                                            <Box>
                                                <Typography className={classes.penTitleText}>{nPS.selectNeolabNoteMsg}</Typography>
                                                <Box display="flex" alignItems="center">
                                                    <img src={DigitalPaper} alt="DigitalPaper" />
                                                    <Typography className={classes.penBoxText}>
                                                        디지털페이퍼 인증 마크를 확인해주세요.
                                                        <br />
                                                        마크는 손필기를 디지털로 저장해주는 Ncode 적용 인증 마크입니다.
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        ) : (
                                            <img src={nPS.selectNeolabNoteThumbnailUrl} alt="thumbnail" height={180} />
                                        )}
                                    </Box>
                                </Box>
                                <FormControl className={clsx(classes.textField, classes.textFieldWidth)} variant="outlined">
                                    <OutlinedInput
                                        inputProps={{ 'aria-label': 'title input box' }}
                                        id="outlined-title"
                                        labelWidth={0}
                                        placeholder="전용 노트 기본 이름이 나타납니다."
                                        value={nPS.selectNeolabNoteTitle}
                                        onChange={event => nPS.setNeoNoteTitle(event.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            <Box>
                                <Typography className={classes.textStyle}>[전용 노트 등록 방법]</Typography>
                                <Typography className={classes.textStyle2}>
                                    ① 펜의 전원을 켜고 연결하여 전용 노트의 한 페이지를 펜으로 눌러주세요.
                                    <br />
                                    ② 전용 노트를 인식하면 커버 이미지가 상단에 나타납니다.
                                    <br />
                                    ③ 하단 ‘등록' 버튼을 누르면 서식 목록에 추가됩니다.
                                    <br />④ 해당 전용 노트에 기록하면 필기 내용을 ‘필기 기록탭'에서 확인할 수 있습니다.
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {!nPS.isUploadingPdf ? (
                        <Button
                            className={classes.buttonStyle}
                            disableRipple
                            disabled={classTab === 0 ? this.state.selectedNotes.size === 0 : nPS.selectNeolabNotePaperGroup === undefined}
                            onClick={async () => {
                                let isValidTitle = false;

                                const paperGroups = [];

                                if (classTab === 0) {
                                    this.state.selectedNotes.forEach(val => {
                                        if (val.isRenaming && val.title.length === 0) isValidTitle = true;

                                        paperGroups.push(val);
                                    });

                                    if (isValidTitle) {
                                        ToastsStore.info('비어있는 제목이 존재합니다.', 3000, classes.toasts);

                                        return;
                                    }

                                    let successCnt = 0;
                                    let failCnt = 0;

                                    let firstSuccessTitle = undefined;
                                    let firstFailTitle = undefined;

                                    for (const paperGroup of paperGroups) {
                                        const message = await nPS.uploadPaperGroup(
                                            groupId,
                                            nPS.currentFolder.id,
                                            userEmail,
                                            paperGroup,
                                            paperGroup.title,
                                            type
                                        );

                                        if (message === '서식 등록이 완료되었습니다.') {
                                            if (!firstSuccessTitle) firstSuccessTitle = paperGroup.title;

                                            successCnt += 1;
                                        } else {
                                            if (!firstFailTitle) firstFailTitle = paperGroup.title;

                                            failCnt += 1;
                                        }
                                    }

                                    if (successCnt === 1) ToastsStore.info(`${firstSuccessTitle} 서식 등록이 완료되었습니다.`, 3000, classes.toasts);
                                    else if (successCnt >= 2)
                                        ToastsStore.info(
                                            `${firstSuccessTitle} 외 ${successCnt - 1}개 서식 등록이 완료되었습니다.`,
                                            3000,
                                            classes.toasts,
                                        );

                                    if (failCnt === 1) ToastsStore.info(`${firstFailTitle} 이미 등록된 서식입니다.`, 3000, classes.toasts);
                                    else if (failCnt >= 2)
                                        ToastsStore.info(`${firstFailTitle} 외 ${failCnt - 1}개 이미 등록된 서식입니다.`, 3000, classes.toasts);
                                }

                                if (classTab === 1) {
                                    const message = await nPS.uploadPaperGroup(
                                        groupId,
                                        nPS.currentFolder.id,
                                        userEmail,
                                        nPS.selectNeolabNotePaperGroup,
                                        nPS.selectNeolabNoteTitle,
                                        type
                                    );

                                    ToastsStore.info(nPS.selectNeolabNoteTitle + ' ' + message, 3000, classes.toasts);
                                }

                                nPS.selectNeolabNoteDialogOpenChanged();

                                this.setState({ searchingStr: '', selectedNotes: new Map() });
                            }}
                        >
                            {classTab === 0 ? '선택 등록(' + this.state.selectedNotes.size + ')' : '등록'}
                        </Button>
                    ) : (
                        <CircularProgress />
                    )}
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(inject('neoPenStore', 'authStore', 'classStore')(observer(FormRegistrationNeoNoteDialogComponent)));
