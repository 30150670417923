import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ScheduleCardListItemComponent from './ScheduleCardListItemComponent';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import dayjs from 'dayjs';
import { roomState } from '../../stores/RoomStore';
import { DATE_UTIL } from '../../common/util/date.util';
import { withRouter } from '../../components/WithRouter';
import { PATH_UTIL } from '../../common/util/path.util';
import { ToastsStore } from 'react-toasts';
import { injectIntl } from 'react-intl';
import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE } from '../../stores/PaginationStore';

const styles = _theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
        '& h4': {
            fontSize: '1.5rem',
            marginTop: 20,
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                width: 24,
                height: 24,
                marginRight: 4,
            },
        },
        '& h6': {
            fontSize: '1.2rem',
            marginTop: 25,
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                width: 24,
                height: 24,
                marginRight: 4,
            },
        },
    },
    titleStyle: {
        fontSize: '1.375rem',
        fontWeight: 600,
    },
    textStyle: {
        fontSize: '0.875rem',
        color: '#7d7d7d',
        margin: '14px 0 17px',
    },
    dailyBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 8,
        padding: 20,
        marginTop: 20,
        cursor: 'pointer',
    },
    dailyBox1: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 8,
        padding: 20,
        marginTop: 20,
        cursor: 'pointer',
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        fontSize: '0.875rem',
        width: 80,
        '& b': {
            fontSize: '1.875rem',
            fontWeight: 700,
        },
    },
    content: {
        width: '65%',
        '& h5': {
            fontSize: '1.063rem',
            fontWeight: 600,
            margin: '3px 0',
        },
    },
    caption: {
        backgroundColor: 'transparent',
        color: '#fff',
        padding: '2px 6px',
        fontSize: '0.625rem',
        fontFamily: 'Montserrat!important',
        textTransform: 'uppercase',
        fontWeight: 800,
        display: 'inline-flex',
        alignItems: 'center',
        '& svg': {
            marginRight: 3,
        },
    },
    captionEnd: {
        backgroundColor: '#505050',
    },
    captionFuture: {
        backgroundColor: '#a3dfff',
    },
    captionLive: {
        backgroundColor: '#FB4A59',
    },
    btnOutlineStyle: {
        border: '1px solid #0097FF',
        color: '#0097FF',
        fontWeight: 600,
        padding: '6px 27px',
        borderRadius: 7,
        '&:hover': {
            background: 'transparent',
        },
    },
    btnOutlineStyleColor: {
        color: '#4f5660',
        border: '1px solid #4f5660',
    },
});

class ClassSelectOwnerComponent extends Component {
    constructor(props) {
        super(props);

        this.groupId = PATH_UTIL.getClassId(props.location.pathname);
    }
    componentDidMount() {
        this.requestUserRegisteredSchedules();

        this.requestUserRegisteredScheduleTotalCount();
    }

    componentDidUpdate(prevProps: Readonly<P>, _prevState: Readonly<S>, _snapshot: SS) {
        const { classTeamStore, roomStore, paginationStore } = this.props;
        const { roomsByOwnerTotalCount, roomsByOwner } = roomStore;

        const isScrollEnd = prevProps.calcScrollEnd !== this.props.calcScrollEnd && this.props.calcScrollEnd;
        const isEndRooms = roomsByOwnerTotalCount === roomsByOwner.length;

        if (isEndRooms || classTeamStore.isLoading) return false;

        if (isScrollEnd) {
            this.requestUserRegisteredSchedules(paginationStore.page, paginationStore.rowsPerPage);
        }
    }

    componentWillUnmount() {
        this.initRoomByOwner();
    }

    requestUserRegisteredScheduleTotalCount = () => {
        const { roomStore, classTeamStore } = this.props;
        const { selectedRoomOwner, setRoomsByOwnerTotalCount } = roomStore;

        classTeamStore.getUserRegisteredRoomTotalCount(this.groupId, selectedRoomOwner.userId, {
            setRoomsByOwnerTotalCount: count => {
                setRoomsByOwnerTotalCount(count);
            },
        });
    };

    requestUserRegisteredSchedules = (page = DEFAULT_PAGE, rowsPerPage = DEFAULT_ROWS_PER_PAGE) => {
        const { intl, classTeamStore, roomStore, paginationStore } = this.props;
        const { selectedRoomOwner } = roomStore;

        classTeamStore.getUserRegisteredRoom(
            this.groupId,
            selectedRoomOwner.email,
            {
                changePage: nextPage => {
                    paginationStore.changePage(nextPage);
                },
                setResult: (rooms, currentPage) => {
                    roomStore.setRoomsByOwner(rooms, currentPage);
                },
                alertMessage: () => {
                    ToastsStore.error(intl.formatMessage({ id: 'msg.no_registered_schedule' }), 1500);
                },
            },
            page,
            rowsPerPage,
        );
    };

    initRoomByOwner = () => {
        this.props.roomStore.initSelectRoomsByOwner();
    };

    checkRoomState = (startDatetime, endDatetime) => {
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedStartDatetime = DATE_UTIL.convertUTCToTimeZone(startDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedEndDatetime = DATE_UTIL.convertUTCToTimeZone(endDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        if (dayjs(convertedEndDatetime).isBefore(currentDatetime)) {
            return roomState.Ended;
        } else if (
            dayjs(currentDatetime).isBetween(convertedStartDatetime, convertedEndDatetime) ||
            dayjs(currentDatetime).isSame(convertedStartDatetime) ||
            dayjs(currentDatetime).isSame(convertedEndDatetime)
        ) {
            return roomState.Live;
        } else {
            return roomState.Future;
        }
    };

    handleClickDetail = roomId => {
        this.props.handleClickDetail(roomId);
    };

    render() {
        const { classes, handleClickDetail, paginationStore, roomStore, classTeamStore, authStore } = this.props;
        const { roomsByOwner, roomsByOwnerTotalCount, selectedRoomOwner } = roomStore;
        const { isLoading } = classTeamStore;

        return (
            <div className={classes.root}>
                <Typography variant="h4">
                    {/*<AsideUserIcon /> */}
                    <b>#{selectedRoomOwner.name}</b>
                </Typography>
                {isLoading && paginationStore.page === DEFAULT_PAGE ? (
                    <Box style={{ textAlign: 'center', marginTop: 100, height: 400 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <React.Fragment>
                        <Typography variant="body2" className={classes.textStyle}>
                            {roomsByOwnerTotalCount}개의 일정이 등록되어 있습니다.
                        </Typography>
                        <ScheduleCardListItemComponent
                            handleClickDetail={handleClickDetail}
                            roomListGroupByStartTime={roomsByOwner}
                            loginUser={authStore.loginUser.id}
                            likeLoading={roomStore.likeLoading}
                            changeLikeState={(groupId, roomId, likeId) => roomStore.changeLikeState(authStore.loginUser, groupId, roomId, likeId)}
                            handleClickDetail={this.handleClickDetail}
                            isMember={this.props.isMember}
                        />
                        {isLoading && (
                            <Box style={{ textAlign: 'center' }}>
                                <CircularProgress />
                            </Box>
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(inject('roomStore', 'authStore', 'classTeamStore', 'paginationStore')(injectIntl(observer(ClassSelectOwnerComponent)))),
);
