import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MobileSearchTopComponent from './MobileSearchTopComponent';
import MobileSearchAllGroupComponent from './MobileSearchAllGroupComponent';
import MobileSearchMyGroupComponent from './MobileSearchMyGroupComponent';
import { withRouter } from '../../../components/WithRouter';
import { inject, observer } from 'mobx-react';

const styles = theme => ({
    root: {
        minHeight: '100%',
        height: '100vh',
        background: '#f3f3f3',
    },
});

const LOG_PREFIX = ' [SearchComponent] ';

class MobileSearchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTab: 0,
        };
    }

    componentDidMount() {
        this.handleSearchButton();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevSearchKeyword = prevProps.params.keyword;
        const currentSearchKeyword = this.props.params.keyword;

        if (prevSearchKeyword !== currentSearchKeyword) {
            this.handleSearchButton();
        }
    }

    componentWillUnmount() {
        const { searchKeyword } = this.props.searchStore;

        if (searchKeyword) {
            this.props.searchStore.initSearchKeyword();
        }
    }

    handleSearchButton = () => {
        const { classStore, roomStore, postStore, searchStore } = this.props;
        const { isSearchGroupListLoading } = classStore;
        const { isLoadRoomLoading } = roomStore;
        const { isPostListLoading } = postStore;
        const { searchKeyword } = searchStore;

        if (!isSearchGroupListLoading && !isLoadRoomLoading && !isPostListLoading) {
            classStore.initSearchClass();
            roomStore.initRoom();
            postStore.initPost();
            searchStore.initCurrentPage();

            roomStore.setRoomRowsPerPage(16);

            this.props.classStore.getGroupListByKeyword(searchKeyword, {
                moveToSearchPage: () => null,
                getRoomList: () =>
                    this.props.roomStore.getRoomListGroupByStartTime(
                        undefined,
                        this.props.authStore.loginUser.id,
                        'all',
                        1,
                        undefined,
                        undefined,
                        undefined,
                        searchKeyword,
                    ),
                getPostList: () => this.props.postStore.getPostList(undefined, this.props.authStore.loginUser.id, searchKeyword, 1, 16),
            });
        } else {
            this.setState({ openConfirmDialog: true });
        }
    };

    handleChange = (event, searchTab) => {
        this.setState({ searchTab });
    };

    render() {
        const { classes, isScrollEnd, params } = this.props;
        const width = window.innerWidth;
        const landscape = window.innerWidth > window.innerHeight;
        const mobileLandscape = width <= 1200;

        return (
            <div className={classes.root}>
                <MobileSearchTopComponent searchTab={this.state.searchTab} handleChange={this.handleChange} />
                {this.state.searchTab === 0 && (
                    <MobileSearchAllGroupComponent keyword={params.keyword} landscape={landscape} mobileLandscape={mobileLandscape} />
                )}
                {this.state.searchTab === 1 && (
                    <MobileSearchMyGroupComponent
                        isScrollEnd={isScrollEnd}
                        keyword={params.keyword}
                        landscape={landscape}
                        mobileLandscape={mobileLandscape}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(
        inject('orgStore', 'localeStore', 'classStore', 'authStore', 'roomStore', 'postStore', 'searchStore')(observer(MobileSearchComponent)),
    ),
);
