import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Tab, Tabs } from '@material-ui/core';
import { withRouter } from '../../components/WithRouter';
import { inject, observer } from 'mobx-react';

const styles = theme => ({
    root: {},
    tabStyle: {
        display: 'inline-flex',
        position: 'relative',
        marginBottom: 20,
        '&:after': {
            content: '""',
            width: '100%',
            height: 3,
            backgroundColor: '#eee',
            display: 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            zIndex: -1,
        },
        '& button': {
            minWidth: 100,
            fontSize: '0.938rem',
            '&.Mui-selected': {
                fontWeight: 700,
                // color: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
                color: '#000',
            },
        },
        '& .MuiTabs-indicator': {
            height: 3,
            backgroundColor: () => (theme.configs.MainBtnColor ? theme.configs.MainBtnColor : '#0097FF'),
        },
    },
});

class ClassManagementTabComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes, classManagementTabs, myClassRoomCount } = this.props;

        const nPS = this.props.neoPenStore;

        let writingCount = 0;

        for (const noteList of Object.values(nPS.groupUserAllNotes)) {
            if (this.props.selectedGroupId === 0 || (noteList.length > 0 && noteList[0].group.id === this.props.selectedGroupId)) {
                writingCount += 1;
            }
        }

        return (
            <div className={classes.root}>
                <Tabs value={classManagementTabs} onChange={this.props.handleChangeClassManagementTabs} className={classes.tabStyle}>
                    <Tab label={`필기 수업 (${writingCount})`} disableRipple />
                    <Tab label={`화상 수업 (${myClassRoomCount})`} disableRipple />
                </Tabs>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('neoPenStore')(observer(ClassManagementTabComponent))));
