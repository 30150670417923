import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { withRouter } from '../../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';
import { ToastsStore } from 'react-toasts';

const styles = theme => ({
    root: {
        width: '100%',
        // height: 'calc(100vh - 600px)',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boxLine: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1px solid #e5e6e8',
        padding: '50px 90px 30px',
        boxSizing: 'border-box',
        borderRadius: 12,
        marginTop: 45,
    },
    titleText: {
        fontSize: '1.75rem',
        color: '#333',
        textAlign: 'center',
        marginBottom: 40,
    },
    textStyle: {
        fontSize: '1.125rem',
        color: '#333',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '10px',
    },
    emailText: {
        fontWeight: 600,
        textDecoration: 'underline',
    },
    buttonStyle: {
        width: 340,
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        padding: '15px 0',
        borderRadius: 7,
        marginTop: 40,
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
    reSendText: {
        color: '#0097ff',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
    },
});

class SignUpEmailAuthenticationDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleClickReSend = () => {
        const { params } = this.props;
        this.props.authStore.sendAuthenticationEmail(params.userEmail, {
            successAction: () => ToastsStore.info('이메일을 재발송 하였습니다.', 1500),
            failedAction: () => ToastsStore.warning('이메일 발송이 되지 않았습니다. 다시 시도해주세요.', 1500),
        });
    };

    render() {
        const { classes, params } = this.props;

        return (
            <div className={classes.root}>
                <Box className={classes.boxLine}>
                    {/* 회원가입시 */}
                    <Typography className={classes.textStyle} component={'div'}>
                        <Typography className={clsx(classes.textStyle, classes.emailText)}>{params.userEmail}</Typography>로 보내 드린 인증 메일을
                        확인해주세요.
                    </Typography>
                    <br />
                    <Typography className={classes.textStyle} component={'div'}>
                        인증 메일을 못 받으셨나요?
                        <Typography component={'a'} className={clsx(classes.textStyle, classes.reSendText)} onClick={this.handleClickReSend}>
                            다시받기
                        </Typography>
                    </Typography>
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('authStore')(injectIntl(observer(SignUpEmailAuthenticationDialogComponent)))));
