import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from '../../common/images/ArrowDownIcon.svg';
import MemberWithdrawalDialogComponent from './dialog/MemberWithdrawalDialogComponent';
import { ReactComponent as AsideUserIcon } from '../../common/images/AsideUserIcon.svg';
import { SORT_TYPE } from '../../stores/PaginationStore';
import { DATE_UTIL } from '../../common/util/date.util';

const styles = _theme => ({
    root: {
        '@media all and (min-width: 1500px)': {
            width: 730,
        },
        width: 620,
        paddingBottom: 70,
    },
    titleText: {
        fontSize: '0.938rem',
        color: '#000',
        marginBottom: 35,
    },
    textStyle: {
        fontSize: '0.875rem',
        color: '#000',
    },
    formControl: {
        '&>div': {
            fontSize: '0.75rem',
            fontWeight: 600,
            '&:before, &:after': {
                content: '',
                display: 'none',
                width: 0,
                size: 0,
            },
        },
        '& .MuiSelect-select:focus': {
            background: 'transparent',
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 0,
        },
    },
    menuItem: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.75rem',
        color: '#0d0d0d',
        '&:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected:hover': {
            background: '#d3d7db',
        },
        '&.Mui-selected': {
            background: 'transparent',
        },
    },
    listTopBorder: {
        borderTop: '1px solid #e1e1e1',
        marginTop: 7,
    },
    listBox: {
        padding: '15px 0 15px 25px',
        borderBottom: '1px solid #e1e1e1',
    },
    avatar: {
        width: 40,
        height: 40,
        marginRight: 15,
        background: '#a3a8af',
        '& svg': {
            width: 25,
            height: 25,
            '& path': {
                fill: '#fff',
            },
        },
    },
    nameText: {
        fontSize: '0.875rem',
        fontWeight: 600,
        color: '#000',
    },
    dateText: {
        fontSize: '0.75rem',
        color: '#a3a8af',
    },
    buttonStyle: {
        background: '#ff2a3d',
        borderRadius: 4,
        width: 70,
        height: 30,
        color: '#fff',
        fontSize: '0.75rem',
        '&:hover': {
            background: '#ff2a3d',
        },
    },
});

class MemberWithdrawalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: SORT_TYPE.name,
            memberWithdrawalDialogOpen: false,
        };
    }

    componentDidMount() {
        const { isDeleteDialogOpen, closeDeleteDialog } = this.props;
        if (isDeleteDialogOpen) {
            closeDeleteDialog();
        }
    }

    handleChangeSort = event => {
        this.setState({ sort: event.target.value });
    };

    handleClickMemberWithdrawalDialogOpen = groupUserEmail => {
        this.setState({ memberWithdrawalDialogOpen: true });
        this.props.setSelectedForcedLeaveGroupUserEmail(groupUserEmail);
    };

    handleClose = () => {
        this.setState({
            memberWithdrawalDialogOpen: false,
        });
    };

    compareMembers = (a, b) => {
        if (this.state.sort === SORT_TYPE.name) {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        } else if (this.state.sort === SORT_TYPE.createdDatetime) {
            if (a.createdDatetime < b.createdDatetime) {
                return 1;
            }
            if (a.createdDatetime > b.createdDatetime) {
                return -1;
            }

            return 0;
        }
    };

    setMemberAvatar = members => {
        if (members.length) {
            return members.map(member => this.props.findAvatar(member)).sort(this.compareMembers);
        }
        return members;
    };

    render() {
        const { classes, groupAllMemberAndOperators } = this.props;
        const renderGroupAllMembers = this.setMemberAvatar([...groupAllMemberAndOperators]);

        return (
            <>
                <Box>
                    <Typography className={classes.titleText}>
                        <b>강제 탈퇴</b>
                    </Typography>
                </Box>
                {renderGroupAllMembers && renderGroupAllMembers.length ? (
                    <>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography className={classes.textStyle}>강제탈퇴 처리하면 복구 할 수 없습니다.</Typography>
                            <FormControl className={classes.formControl}>
                                <Select
                                    value={this.state.sort}
                                    onChange={this.handleChangeSort}
                                    displayEmpty
                                    IconComponent={() => (
                                        <Box
                                            style={{
                                                width: 16,
                                                height: 16,
                                                marginLeft: 5,
                                            }}
                                        >
                                            <ArrowDownIcon />{' '}
                                        </Box>
                                    )}
                                >
                                    <MenuItem value={SORT_TYPE.name} className={classes.menuItem}>
                                        이름순
                                    </MenuItem>
                                    <MenuItem value={SORT_TYPE.createdDatetime} className={classes.menuItem}>
                                        가입 최근순
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className={classes.listTopBorder}>
                            {renderGroupAllMembers.map(member => {
                                return (
                                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.listBox}>
                                        <Box display="flex" alignItems="center">
                                            <Avatar src={member.image} alt="member image" className={classes.avatar}>
                                                <AsideUserIcon />
                                            </Avatar>
                                            <Box>
                                                <Typography className={classes.nameText}>{member.name}</Typography>
                                                <Typography className={classes.dateText}>{`${DATE_UTIL.convertUTCToTimeZone(
                                                    member.createdDatetime,
                                                    DATE_UTIL.FORMAT.SINGLE_DIGIT_DOT_DATE_FORMAT_KOREAN,
                                                )} 가입함`}</Typography>
                                            </Box>
                                        </Box>

                                        <Box>
                                            <Button
                                                className={classes.buttonStyle}
                                                onClick={() => this.handleClickMemberWithdrawalDialogOpen(member.email)}
                                                disableRipple
                                            >
                                                강제탈퇴
                                            </Button>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </>
                ) : (
                    <Box className={classes.listTopBorder}>
                        <Typography>멤버가 없습니다.</Typography>
                    </Box>
                )}
                {/*<Box className={classes.listTopBorder}>*/}
                {/*    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.listBox}>*/}
                {/*        <Box display="flex" alignItems="center">*/}
                {/*            <Avatar src={TestAvatar} alt="member image" className={classes.avatar}>*/}
                {/*                <AsideUserIcon />*/}
                {/*            </Avatar>*/}
                {/*            <Box>*/}
                {/*                <Typography className={classes.nameText}>변요한</Typography>*/}
                {/*                <Typography className={classes.dateText}>2021년 12월 21일 요청</Typography>*/}
                {/*            </Box>*/}
                {/*        </Box>*/}

                {/*        <Box>*/}
                {/*            <Button className={classes.buttonStyle} onClick={this.handleClickMemberWithdrawalDialogOpen} disableRipple>*/}
                {/*                강제탈퇴*/}
                {/*            </Button>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*</Box>*/}

                <MemberWithdrawalDialogComponent
                    memberWithdrawalDialogOpen={this.state.memberWithdrawalDialogOpen}
                    handleClose={this.handleClose}
                    forcedLeaveGroupUser={this.props.forcedLeaveGroupUser}
                />
            </>
        );
    }
}

export default withStyles(styles)(MemberWithdrawalComponent);
