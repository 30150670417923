import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, FormControl, IconButton, InputAdornment, OutlinedInput, Typography } from '@material-ui/core';
import { ReactComponent as LoginPasswordIcon } from '../../../common/images/LoginPasswordIcon.svg';
import PasswordCheckComponent from '../PasswordCheckComponent';
import { checkSpace } from '../../../common/Validation';
import { withRouter } from '../../../components/WithRouter';
import { getURLParams } from '../../../common/util/Params';
import { inject, observer } from 'mobx-react';
import { ReactComponent as LoginEyeIcon } from '../../../common/images/LoginEyeIcon.svg';
import { ReactComponent as LoginEyeSlashIcon } from '../../../common/images/LoginEyeSlashIcon.svg';

const styles = theme => ({
    root: {
        width: '100%',
        // height: 'calc(100vh - 130px)',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boxLine: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #e5e6e8',
        padding: '50px 90px 30px',
        boxSizing: 'border-box',
        borderRadius: 12,
        marginTop: 45,
    },
    titleText: {
        fontSize: '1.75rem',
        color: '#333',
        textAlign: 'center',
        marginBottom: 20,
    },
    textStyle: {
        fontSize: '1rem',
        color: '#808080',
        textAlign: 'center',
    },
    textField: {
        marginTop: 20,
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #d9dbde',
            },
        '& .MuiOutlinedInput-root': {
            width: 340,
            borderRadius: 7,
            '&:hover': {
                borderColor: '#d9dbde',
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: '13px 0px',
            color: '#333',
            fontSize: '1rem',
            '&::placeholder': {
                color: '#9d9d9d',
                opacity: 1,
            },
        },
    },
    buttonStyle: {
        width: 340,
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        padding: '15px 0',
        borderRadius: 7,
        marginTop: 30,
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
});

class PasswordResetComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { passwordValidation: [true, false, false, false], showNewPassword: false, showConfirmPassword: false };
    }

    changeResetNewPassword = e => {
        checkSpace(e);
        this.setState({
            passwordValidation: this.props.userStore.changeResetPassword(e.target.value),
        });
    };

    changeConfirmPassword = e => {
        checkSpace(e);
        this.props.userStore.changeConfirmationResetPassword(e.target.value);
    };

    handleResetPassword = () => {
        const { ...params } = getURLParams(window.location.search);
        this.props.userStore.requestResetPassword(params.token, params.email, {
            moveTo: path => this.props.navigate(path),
        });
    };

    handleClickShowNewPassword = () => {
        this.setState(state => ({ showNewPassword: !state.showNewPassword }));
    };

    handleClickShowConfirmPassword = () => {
        this.setState(state => ({ showConfirmPassword: !state.showConfirmPassword }));
    };

    render() {
        const { classes } = this.props;
        const { resetPassword, confirmationResetPassword } = this.props.userStore;

        return (
            <div className={classes.root}>
                <Box className={classes.boxLine}>
                    <Typography className={classes.titleText}>비밀번호 재설정</Typography>
                    <Typography className={classes.textStyle}>거의 완료되었습니다.</Typography>
                    <Typography className={classes.textStyle}>새로운 비밀번호를 입력해주세요.</Typography>

                    <FormControl className={classes.textField} variant="outlined">
                        <OutlinedInput
                            inputProps={{ 'aria-label': 'password input box' }}
                            id="outlined-password"
                            type={this.state.showNewPassword ? 'text' : 'password'}
                            startAdornment={
                                <InputAdornment position="start">
                                    <LoginPasswordIcon />
                                </InputAdornment>
                            }
                            labelWidth={0}
                            placeholder="비밀번호"
                            onChange={this.changeResetNewPassword}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton className={classes.iconButton} onClick={this.handleClickShowNewPassword} disableRipple>
                                        {this.state.showNewPassword ? <LoginEyeIcon /> : <LoginEyeSlashIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                    <PasswordCheckComponent passwordValidation={this.state.passwordValidation} />

                    <FormControl className={classes.textField} variant="outlined">
                        <OutlinedInput
                            inputProps={{
                                'aria-label': 'Password confirmation field',
                            }}
                            id="outlined-password"
                            type={this.state.showConfirmPassword ? 'text' : 'password'}
                            startAdornment={
                                <InputAdornment position="start">
                                    <LoginPasswordIcon />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton className={classes.iconButton} onClick={this.handleClickShowConfirmPassword} disableRipple>
                                        {this.state.showConfirmPassword ? <LoginEyeIcon /> : <LoginEyeSlashIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={0}
                            placeholder="비밀번호 확인"
                            onChange={this.changeConfirmPassword}
                        />
                    </FormControl>

                    <Button
                        className={classes.buttonStyle}
                        disableRipple
                        disabled={!this.state.passwordValidation[1] || resetPassword !== confirmationResetPassword}
                        onClick={this.handleResetPassword}
                    >
                        비밀번호 재설정
                    </Button>
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('userStore')(observer(PasswordResetComponent))));
