import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Dialog, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../../../common/images/DialogCloseIcon.svg';
import { ButtonNames, SettingsInnerPath } from '../MobileSettingComponent';

const styles = _theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
            '@media all and (min-width: 600px)': {
                width: 382,
            },
        },
        '& .MuiDialog-paper': {
            margin: 15,
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
        '@media all and (max-width: 600px)': {
            fontSize: '1rem',
        },
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
        '@media all and (max-width: 600px)': {
            '& svg': {
                width: 20,
                height: 20,
            },
        },
    },
    warningText: {
        fontSize: '1rem',
        fontWeight: 600,
        color: '#ff0000',
        margin: '30px 0 5px',
        '@media all and (max-width: 600px)': {
            fontSize: '0.875rem',
        },
    },
    textStyle: {
        fontSize: '0.813rem',
        color: '#333',
        '@media all and (max-width: 600px)': {
            fontSize: '0.75rem',
        },
    },
    textMargin: {
        marginTop: 30,
    },
    buttonStyle: {
        width: '100%',
        height: 48,
        borderRadius: 8,
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        marginTop: 38,
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
        '@media all and (max-width: 600px)': {
            height: 33,
            padding: '6px 0',
            fontSize: '0.75rem',
            borderRadius: 4,
        },
    },
});

class MobileDeleteDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleClickClose = e => {
        this.props.handleClose(e.currentTarget.name);
    };

    handleClickForcedWithdrawBtn = e => {
        this.props.handleChangeMemberWithdrawal(e.currentTarget.name);
    };

    handleClickDeleteGroup = () => {
        this.props.deleteGroup();
    };

    render() {
        const { classes, groupAllMemberAndOperators } = this.props;
        const isRemainsMember = groupAllMemberAndOperators.length > 0;

        return (
            <Dialog open={this.props.deleteDialogOpen} className={classes.dialogBox}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.titleText}>클래스 삭제</Typography>
                    <IconButton className={classes.iconButton} name={ButtonNames.DeleteDialogOpen} onClick={this.handleClickClose} disableRipple>
                        <DialogCloseIcon />
                    </IconButton>
                </Box>

                <Box>
                    <Typography className={classes.warningText}>삭제 전 꼭 확인하세요.</Typography>

                    {/* 멤버가 본인 혼자인 경우 */}
                    {/*<Typography className={classes.textStyle}>회원님이 마지막 멤버이므로 지금 나가면 모임이 삭제됩니다.</Typography>*/}
                    {/*<Typography className={classes.textStyle}>모든 데이터는 삭제와 동시에 복구할 수 없습니다.</Typography>*/}
                    {/*<Typography className={classes.textStyle}>필요하면  내가 작성한 일정, 게시물, 댓글 등 보관하거나<br/> 삭제해주세요.</Typography>*/}

                    {/*멤버가 본인 포함 2명 이상인 경우*/}
                    {isRemainsMember ? (
                        <>
                            <Typography className={classes.textStyle}>
                                클래스를 삭제하려면 멤버들에게 공지, 게시물 또는 직접 메시지로 알림을 통해 탈퇴 요청하시고 삭제를 진행해주세요.
                            </Typography>
                            <Typography className={classes.textStyle}>모든 데이터는 삭제와 동시에 복구할 수 없습니다.</Typography>
                            <Typography className={classes.textStyle}>
                                필요하면 내가 작성한 일정, 게시물, 댓글 등 보관하거나
                                <br /> 삭제해주세요.
                            </Typography>
                            <Typography className={clsx(classes.textStyle, classes.textMargin)}>
                                강제탈퇴를 진행하려면 아래 버튼을 눌러 진행해주세요.
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography className={classes.textStyle}>회원님이 마지막 멤버이므로 지금 나가면 클래스가 삭제됩니다.</Typography>
                            <Typography className={classes.textStyle}>모든 데이터는 삭제와 동시에 복구할 수 없습니다.</Typography>
                            <Typography className={classes.textStyle}>
                                필요하면 내가 작성한 일정, 게시물, 댓글 등 보관하거나
                                <br /> 삭제해주세요.
                            </Typography>
                        </>
                    )}

                    {/* 멤버가 본인 포함 2명 이상인 경우 -> 강제 탈퇴하기, 멤버가 본인 혼자인 경우 -> 삭제하기 */}
                    <Button
                        className={classes.buttonStyle}
                        name={SettingsInnerPath.withdrawal}
                        onClick={isRemainsMember ? this.handleClickForcedWithdrawBtn : this.handleClickDeleteGroup}
                        disableRipple
                    >
                        {isRemainsMember ? '강제 탈퇴하기' : '삭제하기'}
                    </Button>
                </Box>
            </Dialog>
        );
    }
}

export default withStyles(styles)(MobileDeleteDialogComponent);
