import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import { ReactComponent as OntheliveLogo } from '../../common/images/StudyLiveTopBarLogo.svg';
import { withRouter } from '../../components/WithRouter';
import { inject, observer } from 'mobx-react';
import { SessionStorageTokenKey, State } from '../../stores/AuthStore';
import { ClassMainPath } from '../../common/ClassMainPath';

const styles = theme => ({
    root: {
        width: '100%',
        // height: 'calc(100vh - 130px)',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boxStyle: {
        width: 340,
        background: '#fff',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        marginTop: 18,
        '& img': {
            width: 340,
            height: 200,
            borderRadius: 10,
        },
    },
    logoStyle: {
        width: 165,
        height: 38,
    },
    textBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '18px 14px 22px',
    },
    textStyle: {
        fontSize: '1.063rem',
        color: '#000',
        lineHeight: 1.3,
        textAlign: 'center',
        marginBottom: 16,
        whiteSpace: 'pre-wrap',
    },
    spanStyle: {
        fontWeight: 800,
    },
    buttonStyle: {
        width: 240,
        height: 48,
        background: '#0097ff',
        fontSize: '1rem',
        color: '#fff',
        borderRadius: 7,
        '&:hover': {
            background: '#0097ff',
        },
    },
});

class InvitationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExpiredInvitation: false,
        };
    }

    componentDidMount() {
        const { params } = this.props;
        this.props.classStore.getInviteGroup(params.accessToken);
    }

    handleClick = () => {
        const { loginState } = this.props.authStore;
        const { inviteGroup } = this.props.classStore;
        const loginToken = sessionStorage.getItem(SessionStorageTokenKey);
        const isLogin = loginState === State.Authenticated && loginToken;
        this.props.navigate(isLogin ? `/class/${inviteGroup.groupId}/${ClassMainPath.todaySchedule}` : '/signin');
    };

    handleClickBack = () => {
        const { loginState } = this.props.authStore;
        const loginToken = sessionStorage.getItem(SessionStorageTokenKey);
        const isLogin = loginState === State.Authenticated && loginToken;
        this.props.navigate(isLogin ? `/rooms` : '/home');
    };

    selectRenderComponent = () => {
        const { classes, classStore } = this.props;

        if (classStore.isExpiredInvitation) {
            return (
                <Box className={classes.boxStyle}>
                    <Box className={classes.textBox}>
                        <Typography className={classes.textStyle}>{`만료된 초대 URL이거나 \r\n허용되지 않은 접근입니다.`}</Typography>
                        <Button className={classes.buttonStyle} disableRipple onClick={this.handleClickBack}>
                            돌아가기
                        </Button>
                    </Box>
                </Box>
            );
        }

        return (
            <Box className={classes.boxStyle}>
                <img src={classStore.inviteGroup.groupImage} alt="room image" />
                <Box className={classes.textBox}>
                    <Typography className={classes.textStyle}>
                        <span className={classes.spanStyle}>{classStore.inviteGroup.userName}</span> 님이
                        <span className={classes.spanStyle}>{classStore.inviteGroup.groupName}</span> 클래스로 초대합니다.
                    </Typography>
                    <Button className={classes.buttonStyle} disableRipple onClick={this.handleClick}>
                        클래스 초대장 보기
                    </Button>
                </Box>
            </Box>
        );
    };

    render() {
        const { classes } = this.props;
        const { inviteGroup, isExpiredInvitation } = this.props.classStore;
        const RenderInvitation = this.selectRenderComponent();
        return (
            <div className={classes.root}>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <OntheliveLogo className={classes.logoStyle} />
                    {RenderInvitation}
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('classStore', 'authStore')(observer(InvitationComponent))));
