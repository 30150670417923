import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { ClassMainPath } from '../../common/ClassMainPath';
import { FILE_POSTED_TYPE } from '../../stores/ClassFileStore';
import { ReactComponent as FileIcon } from '../../common/images/FileIcon.svg';
import { PATH_UTIL } from '../../common/util/path.util';
import { withRouter } from '../../components/WithRouter';

const styles = _theme => ({
    root: {},
    latestBox: {
        border: '1px solid #C4C4C4',
        borderRadius: 7,
        boxShadow: '0 1px 1px 0 #BEBEBE',
    },
    latestTitle: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        borderBottom: '1px solid #D3D7DB',
        padding: '10px 14px',
        '& h4': {
            fontSize: '1.125rem',
        },
        '& button': {
            color: '#A3A8AF',
            fontSize: '0.75rem',
            padding: 0,
            '& span': {
                display: 'initial',
                textAlign: 'right',
            },
            '&:hover': {
                backgroundColor: '#fff',
            },
        },
    },
    latestList: {
        '& li': {
            padding: '12px 14px!important',
            borderTop: '1px solid #F8F8F8',
            color: '#4D5A69',
            fontSize: '0.75rem',
            display: 'flex',
            alignItems: 'center',
            '&:first-child': {
                borderTop: 0,
            },
            '& svg': {
                marginRight: 5,
                width: 24,
                height: 24,
            },
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
});
class ClassAsideLatestComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpenFileId: 0,
        };

        this.classStore = props.classStore;
        this.classFileStore = props.classFileStore;
        this.groupId = PATH_UTIL.getClassId(props.location.pathname);
    }

    componentDidMount() {
        this.classStore.getGroupCurrentRegistrationFiles(this.groupId);
    }

    handleMoveToReferenceTab = _e => {
        this.props.moveTo(ClassMainPath.referenceRoom);
    };

    handleDownloadFile = file => {
        if (file) {
            this.classFileStore.downloadFile(file.groupId, FILE_POSTED_TYPE.Folder, file.fileFolderId, file.fileId, file.name);
        }
    };

    handleFileNameTooltipOpen = file => {
        if (this.state.tooltipOpenFileId) {
            this.setState({ tooltipOpenFileId: 0 });
        } else {
            if (file && file.name.length > 20) {
                this.setState({ tooltipOpenFileId: file.fileId });
            }
        }
    };

    render() {
        const { classes, classFileStore, classStore } = this.props;
        return (
            <div>
                <Box className={classes.latestBox}>
                    <Box className={classes.latestTitle}>
                        <Typography variant="h4">최근 자료</Typography>
                        {/*<CircularProgress size={20} />*/}
                        {classFileStore.isDownLoading ? (
                            <CircularProgress size={20} />
                        ) : (
                            <Button className={classes.btnStyle} onClick={this.handleMoveToReferenceTab}>
                                더보기
                            </Button>
                        )}
                    </Box>
                    <ul className={classes.latestList}>
                        {classStore.groupCurrentFiles && classStore.groupCurrentFiles.length ? (
                            classStore.groupCurrentFiles.map((file, index) => {
                                return (
                                    <Tooltip
                                        key={`${index}_${file.name}`}
                                        title={file.name}
                                        open={file.fileId === this.state.tooltipOpenFileId}
                                        disableFocusListener
                                        disableTouchListener
                                    >
                                        <li onClick={() => this.handleDownloadFile(file)}>
                                            <Box>
                                                <FileIcon />
                                            </Box>
                                            <Box
                                                style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                                onMouseEnter={() => this.handleFileNameTooltipOpen(file)}
                                                onMouseOut={() => this.handleFileNameTooltipOpen(file)}
                                            >
                                                {file.name}
                                            </Box>
                                        </li>
                                    </Tooltip>
                                );
                            })
                        ) : (
                            <li>등록된 자료가 없습니다.</li>
                        )}
                    </ul>
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('classStore', 'classFileStore')(observer(ClassAsideLatestComponent))));
