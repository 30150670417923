import { makeAutoObservable } from 'mobx';
import axios from 'axios';

const logPrefix = '[NeoPenStatisticsStore]';

export default class NeoPenStatisticsStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;

        makeAutoObservable(this);
    }

    statisticsData = {};
    totalStatisticsData = {};

    teacherId = undefined;

    getStatisticsByGroupIdAndPaperGroupId = async (groupId, paperGroupId) => {
        const keyIds = this.getKeyFromIds(groupId, paperGroupId);

        if (this.statisticsData[keyIds] === undefined) {
            let allStrokeCount = 0;
            let allStrokeWritingTime = 0;

            const staticsListRes = await axios.get(this.serverContextPath + `/api/v1/statistics/` + groupId + '/' + paperGroupId + '/strokes/all');

            const staticsList = staticsListRes.data;

            console.log('staticsList', staticsList);

            const dataWithUserId = {};

            for (const staticsData of staticsList) {
                if (staticsData.userId !== this.teacherId) {
                    allStrokeCount += staticsData.totalStrokeCount;
                    allStrokeWritingTime += staticsData.totalStrokeWritingTime;

                    dataWithUserId[staticsData.userId] = staticsData;
                }
            }

            this.totalStatisticsData[keyIds] = { name: '총합', amount: allStrokeCount, time: allStrokeWritingTime };
            this.statisticsData[keyIds] = dataWithUserId;
        }
    };

    getKeyFromIds = (groupId, paperGroupId) => groupId + "'" + paperGroupId;

    setTeacherId = id => (this.teacherId = id);
}
