import React, { Component } from 'react';
import ClassSelectTagComponent from './ClassSelectTagComponent';
import ClassSelectOwnerComponent from './ClassSelectOwnerComponent';
import { withRouter } from '../../components/WithRouter';

export const CLASSIFICATION_TYPE = {
    None: 'none',
    Tag: 'tag',
    Owner: 'owner',
};

class ScheduleClassificationComponent extends Component {
    render() {
        const { classification, classSelectTag, classTab, handleClickDetail, handleClickPostDetail, isLeader } = this.props;
        const isSelectedTagComponent = classification === CLASSIFICATION_TYPE.Tag;

        return (
            <>
                {isSelectedTagComponent ? (
                    <ClassSelectTagComponent
                        classSelectTag={classSelectTag}
                        classTab={classTab}
                        handleClickDetail={handleClickDetail}
                        handleClickPostDetail={handleClickPostDetail}
                        isMember={this.props.isMember}
                        isLeader={isLeader}
                    />
                ) : (
                    <ClassSelectOwnerComponent
                        handleClickDetail={handleClickDetail}
                        isMember={this.props.isMember}
                        isScrollEnd={this.props.isScrollEnd}
                        scrollHeight={this.props.scrollHeight}
                        calcScrollEnd={this.props.calcScrollEnd}
                    />
                )}
            </>
        );
    }
}

export default withRouter(ScheduleClassificationComponent);
