import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ScheduleCardListItemComponent from './MobileScheduleCardListItemComponent';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import dayjs from 'dayjs';
import { roomState } from '../../../stores/RoomStore';
import { DATE_UTIL } from '../../../common/util/date.util';
import { withRouter } from '../../../components/WithRouter';
import { injectIntl } from 'react-intl';
import { ReactComponent as MagnifyingGlassIcon } from '../../../common/images/MagnifyingGlassIcon.svg';
import { PATH_UTIL } from '../../../common/util/path.util';

const styles = _theme => ({
    root: {
        width: 'calc(100vw - 32px)',
        paddingBottom: 70,
        '& h4': {
            fontSize: '1.5rem',
            marginTop: 20,
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                width: 24,
                height: 24,
                marginRight: 4,
            },
        },
        '& h6': {
            fontSize: '1.2rem',
            marginTop: 25,
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                width: 24,
                height: 24,
                marginRight: 4,
            },
        },
    },
    titleStyle: {
        fontSize: '1.375rem',
        fontWeight: 600,
    },
    textStyle: {
        fontSize: '0.875rem',
        color: '#7d7d7d',
        margin: '14px 0 17px',
    },
    dailyBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 8,
        padding: 20,
        marginTop: 20,
        cursor: 'pointer',
    },
    dailyBox1: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
        borderRadius: 8,
        padding: 20,
        marginTop: 20,
        cursor: 'pointer',
    },
    date: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        fontSize: '0.875rem',
        width: 80,
        '& b': {
            fontSize: '1.875rem',
            fontWeight: 700,
        },
    },
    content: {
        width: '65%',
        '& h5': {
            fontSize: '1.063rem',
            fontWeight: 600,
            margin: '3px 0',
        },
    },
    caption: {
        backgroundColor: 'transparent',
        color: '#fff',
        padding: '2px 6px',
        fontSize: '0.625rem',
        fontFamily: 'Montserrat!important',
        textTransform: 'uppercase',
        fontWeight: 800,
        display: 'inline-flex',
        alignItems: 'center',
        '& svg': {
            marginRight: 3,
        },
    },
    captionEnd: {
        backgroundColor: '#505050',
    },
    captionFuture: {
        backgroundColor: '#a3dfff',
    },
    captionLive: {
        backgroundColor: '#FB4A59',
    },
    btnOutlineStyle: {
        border: '1px solid #0097FF',
        color: '#0097FF',
        fontWeight: 600,
        padding: '6px 27px',
        borderRadius: 7,
        '&:hover': {
            background: 'transparent',
        },
    },
    btnOutlineStyleColor: {
        color: '#4f5660',
        border: '1px solid #4f5660',
    },
});

class MobileClassSelectSearchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        };
        const pathName = window.location.pathname;
        this.groupId = PATH_UTIL.getClassId(pathName);
        this.keyword = PATH_UTIL.getSearchKeyword(pathName);
    }

    componentDidMount() {
        this.props.searchStore.initSubCurrentPage();
        this.props.roomStore.getRoomListBySubSearchKeyword(this.groupId, this.keyword, 1);
    }

    componentDidUpdate(prevProps: Readonly<P>, _prevState: Readonly<S>, _snapshot: SS) {
        const { roomStore, searchStore } = this.props;
        if (
            prevProps.isScrollEnd !== this.props.isScrollEnd &&
            this.props.isScrollEnd &&
            roomStore.searchRoomListCount > roomStore.searchRoomList.length
        ) {
            searchStore.changeSubCurrentPage();
            roomStore.getRoomListBySubSearchKeyword(this.groupId, this.keyword, searchStore.subCurrentPage);
        }
    }

    checkRoomState = (startDatetime, endDatetime) => {
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedStartDatetime = DATE_UTIL.convertUTCToTimeZone(startDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const convertedEndDatetime = DATE_UTIL.convertUTCToTimeZone(endDatetime, DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        if (dayjs(convertedEndDatetime).isBefore(currentDatetime)) {
            return roomState.Ended;
        } else if (
            dayjs(currentDatetime).isBetween(convertedStartDatetime, convertedEndDatetime) ||
            dayjs(currentDatetime).isSame(convertedStartDatetime) ||
            dayjs(currentDatetime).isSame(convertedEndDatetime)
        ) {
            return roomState.Live;
        } else {
            return roomState.Future;
        }
    };

    handleClickDetail = roomId => {
        this.props.handleClickDetail(roomId);
    };

    render() {
        const { classes, currentTab } = this.props;
        const { searchRoomList, isSearchRoomLoading, searchRoomListCount } = this.props.roomStore;
        const keyword = currentTab['*'].split('/')[1];

        return (
            <div className={classes.root}>
                <Typography variant="h4">
                    <MagnifyingGlassIcon />
                    <b>{keyword}</b>
                </Typography>
                {!isSearchRoomLoading ? (
                    <React.Fragment>
                        <Typography variant="body2" className={classes.textStyle}>
                            {searchRoomListCount}개의 일정이 등록되어 있습니다.
                        </Typography>
                        <ScheduleCardListItemComponent
                            roomListGroupByStartTime={searchRoomList}
                            loginUser={this.props.authStore.loginUser.id}
                            likeLoading={this.props.roomStore.likeLoading}
                            changeLikeState={(groupId, roomId, likeId) =>
                                this.props.roomStore.changeLikeState(this.props.authStore.loginUser, groupId, roomId, likeId)
                            }
                            handleClickDetail={this.handleClickDetail}
                            isMember={this.props.isMember}
                        />
                    </React.Fragment>
                ) : (
                    <Box style={{ textAlign: 'center', marginTop: 100, height: 400, marginBottom: 1000 }}>
                        <CircularProgress />
                    </Box>
                )}
            </div>
        );
    }
}

export default withRouter(
    withStyles(styles)(injectIntl(inject('roomStore', 'authStore', 'commentStore', 'searchStore')(observer(MobileClassSelectSearchComponent)))),
);
