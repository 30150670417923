import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, InputBase } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { withRouter } from '../../components/WithRouter';
import { PATH_UTIL } from '../../common/util/path.util';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { ReactComponent as CloseXBtnIcon } from '../../common/images/CloseXBtnIcon.svg';
import { ReactComponent as PrinterIcon } from '../../common/images/PrinterIcon.svg';
const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        background: '#f5f5f5',
        height: 61,
    },
    hiddenRoot: {
        display: 'none',
    },
    appBar: {
        '@media all and (min-width: 1500px)': {
            width: 1440,
        },
        width: 1200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '13px 40px',
        boxSizing: 'border-box',
    },
    selectBox: {
        width: '100%',
        fontSize: '0.875rem',
        '& svg': {
            marginLeft: -25,
        },
    },
    buttonStyle: {
        background: 'transparent',
        color: '#0d0d0d',
        fontWeight: 'bold',
        fontSize: '1rem',
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    iconButton: {
        fontSize: '0.938rem',
        color: '#000',
        fontWeight: 600,
        padding: 0,
        marginLeft: 20,
        '&:hover': {
            background: 'transparent',
        },
    },
    toastsStyle: {
        '& .toast': {
            minHeight: '25px !important',
            borderRadius: '30px !important',
            fontSize: '1rem!important',
            color: '#fff!important',
            backgroundColor: '#858585!important',
        },
    },
    studentResult: {
      fontSize: '1.25rem',
      '& span': {
          fontWeight: 'bold'
}
    },
});

const BootstrapInput = withStyles(theme => ({
    root: {
        marginRight: 35,
    },
    input: {
        background: '#fff',
        border: '1px solid #bfbfbf',
        padding: '8px 10px',
        borderRadius: 4,
        fontSize: '0.875rem',
        '&:focus': {
            borderRadius: 4,
            background: '#fff',
        },
        '&.MuiSelect-select': {
            paddingRight: 25,
        },
    },
}))(InputBase);

class TopBarResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLocale: 'ko',
            anchorEl: null,
        };
    }

    componentDidMount() {
        const {params, neoPenStore} = this.props;

        const user = this.props.authStore.loginUser;

        neoPenStore.initNeoPen(user);

        this.setState({ selectedLocale: this.props.localeStore.locale });

        const groupId = params.groupId;
        const paperGroupId = params.paperGroupId;

        neoPenStore.getSinglePaperGroupByPaperGroupId(groupId, paperGroupId);
    }

    setStudent = async () => {
        const {params, neoPenStore} = this.props;

        const user = await neoPenStore.getUserInfo(params.userEmail);

        neoPenStore.selectUser(user);
    }

    render() {
        const { classes, navigate, neoPenStore } = this.props;
        const { orgImages } = this.props.orgStore;
        const path = PATH_UTIL.getPath(this.props.location.pathname);

        const name = this.props.authStore.loginUser.name;
        const title = neoPenStore.selectedPaperGroup ? neoPenStore.selectedPaperGroup.title : "";

        return (
            <div className={path === 'studylive' || path === 'termsofuse' || path === 'privacypolicy' ? classes.hiddenRoot : classes.root}>
                <Box className={classes.appBar}>
                    <Box display="flex" alignItems="center">
                        {orgImages.Logo !== undefined && orgImages.Logo !== '' && (
                            <img
                                src={orgImages.Logo}
                                alt="LogoImage"
                                style={{
                                    width: 'auto',
                                    height: 'auto',
                                    maxWidth: 140,
                                    maxHeight: 32,
                                    objectFit: 'contain',
                                    cursor: 'pointer',
                                    verticalAlign: 'middle',
                                }}
                                onClick={() => navigate('/')}
                            />
                        )}
                    </Box>
                    <Box className={classes.studentResult}><span>{neoPenStore.selectedUser ? neoPenStore.selectedUser.name : ""}</span> 학생의 <span>‘{title}’</span> 평가 결과</Box>
                    <Box display="flex" alignItems="center">
                        <Button disableRipple>
                            <PrinterIcon />
                        </Button>
                        <Button disableRipple onClick={() => window.close()}>
                            <CloseXBtnIcon />
                        </Button>
                    </Box>
                </Box>
                <ToastsContainer position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} lightBackground className={classes.toastsStyle} />
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(inject('orgStore', 'localeStore', 'authStore', 'neoPenStore')(injectIntl(observer(TopBarResult)))));
