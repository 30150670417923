import React, { Component } from 'react';
import { Avatar, Box, Button, Checkbox, Dialog, FormControlLabel, IconButton, InputBase, Typography } from '@material-ui/core';
import { ReactComponent as DialogCloseIcon } from '../../../common/images/DialogCloseIcon.svg';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as MagnifyingGlassIcon } from '../../../common/images/MagnifyingGlassIcon.svg';
import { ReactComponent as AsideUserIcon } from '../../../common/images/AsideUserIcon.svg';
import TestAvatar from '../../../common/images/TestAvatar.jpg';
import { ReactComponent as UnCheckedIcon } from '../../../common/images/UnCheckedIcon.svg';
import { ReactComponent as CheckedIcon } from '../../../common/images/CheckedIcon.svg';

const styles = theme => ({
    root: {},
    dialogBox: {
        '& .MuiPaper-root': {
            width: 390,
            padding: 24,
            boxSizing: 'border-box',
            borderRadius: 10,
            boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.25)',
        },
        '& *': {
            fontFamily: 'NanumSquareRoundOTF',
        },
    },
    titleText: {
        fontSize: '1.25rem',
        color: '#000',
        fontWeight: 600,
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            background: 'transparent',
        },
    },
    textStyle: {
        fontSize: '1rem',
        color: '#333',
    },
    marginTop: {
        marginTop: 27,
    },
    searchBox: {
        display: 'flex',
        boxShadow: 'none',
        border: '1px solid #C4C4C4',
        padding: '4px 9px',
        margin: '12px 0 21px',
        borderRadius: 7,
        '& .MuiInputBase-root': {
            width: '100%',
        },
        '& input': {
            width: '100%',
            fontSize: '1rem',
        },
    },
    searchIconButton: {
        padding: 6,
        backgroundColor: '#f8f8f8',
        '&:hover': {
            backgroundColor: '#f8f8f8',
        },
    },
    numberText: {
        fontSize: '0.813rem',
        marginLeft: 4,
        color: '#a3a8af',
    },
    listBox: {
        maxHeight: 280,
        marginTop: 20,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '15px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
            border: '5px solid transparent',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            marginTop: 5,
        },
    },
    marginBottom: {
        marginBottom: 15,
    },
    avatar: {
        width: 45,
        height: 45,
        background: '#a3a8af',
        '& svg': {
            width: 28,
            height: 28,
            '& path': {
                fill: '#fff',
            },
        },
    },
    nameText: {
        marginLeft: 7,
        fontSize: '1rem',
        color: '#333',
        fontWeight: 600,
    },
    checkedBox: {
        '& .MuiIconButton-root': {
            padding: 0,
        },
    },
    buttonStyle1: {
        width: '100%',
        height: 48,
        borderRadius: 8,
        background: '#0097ff',
        color: '#fff',
        fontSize: '1.125rem',
        marginTop: 25,
        '&:hover': {
            background: '#0097ff',
        },
        '&.Mui-disabled': {
            background: '#c2c2c2',
            color: '#fff',
        },
    },
});

class AddAdminDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchName: '',
            selectedMembers: [],
        };
    }

    handleChangeSearchName = e => {
        this.setState({ searchName: e.target.value });
    };

    handleFilterSearchName = member => {
        if (this.state.searchName.trim() !== '') {
            if (member.name.match(this.state.searchName)) return member;
        } else {
            return member;
        }
    };

    handleChangeCheckBox = member => {
        if (this.state.selectedMembers.length) {
            const findIndex = this.state.selectedMembers.findIndex(prevMember => prevMember.userId === member.userId);
            console.log('findIndex : >> ', findIndex);
            if (findIndex !== -1) {
                const copiedSelectedMembers = this.state.selectedMembers.slice();
                copiedSelectedMembers.splice(findIndex, 1);
                this.setState({ selectedMembers: copiedSelectedMembers });
            } else {
                this.setState(prevState => ({ selectedMembers: prevState.selectedMembers.concat(member) }));
            }
        } else {
            this.setState(prevState => ({ selectedMembers: prevState.selectedMembers.concat(member) }));
        }
    };

    handleSelectBtn = () => {
        this.props.addAdmin(this.state.selectedMembers);
        this.props.handleCloseAddAdminDialog();
    };

    render() {
        const { classes, members } = this.props;
        const filteredMembers = members.filter(this.handleFilterSearchName);
        console.log('filteredMembers', this.state.selectedMembers);

        return (
            <div className={classes.root}>
                <Dialog open={this.props.addAdminDialogOpen} className={classes.dialogBox}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography className={classes.titleText}>관리자 추가</Typography>
                        <IconButton className={classes.iconButton} onClick={this.props.handleCloseAddAdminDialog} disableRipple>
                            <DialogCloseIcon />
                        </IconButton>
                    </Box>

                    <Box className={classes.marginTop}>
                        <Typography className={classes.textStyle}>아래 멤버에서 선택해주세요.</Typography>
                        <Box className={classes.searchBox}>
                            <InputBase placeholder="이름검색" onChange={this.handleChangeSearchName} />
                            <IconButton className={classes.searchIconButton} aria-label="Search" disableRipple>
                                <MagnifyingGlassIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <Box>
                        <Box display="flex" alignItems="center">
                            <AsideUserIcon /> <span className={classes.numberText}>{members.length}명</span>
                        </Box>
                        <Box className={classes.listBox}>
                            {filteredMembers.length ? (
                                filteredMembers.map(member => {
                                    return (
                                        <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.marginBottom}>
                                            <Box display="flex" alignItems="center">
                                                <Avatar src={TestAvatar} alt="profile image" className={classes.avatar}>
                                                    <AsideUserIcon />
                                                </Avatar>
                                                <Typography className={classes.nameText}>{member.name}</Typography>
                                            </Box>
                                            <FormControlLabel
                                                control={<Checkbox icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} value="checkA" />}
                                                label=""
                                                className={classes.checkedBox}
                                                onChange={() => this.handleChangeCheckBox(member)}
                                                checked={
                                                    this.state.selectedMembers.findIndex(
                                                        selectedMember => selectedMember.userId === member.userId,
                                                    ) !== -1
                                                }
                                            />
                                        </Box>
                                    );
                                })
                            ) : (
                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                    {/*<Box display="flex" alignItems="center" justifyContent="center" className={classes.iconBox}>*/}
                                    {/*    <AsideUserIcon />*/}
                                    {/*</Box>*/}

                                    <Typography className={classes.memberNoText}>해당하는 멤버가 없습니다.</Typography>
                                </Box>
                            )}
                        </Box>

                        <Button
                            className={classes.buttonStyle1}
                            disableRipple
                            disabled={!this.state.selectedMembers.length}
                            onClick={this.handleSelectBtn}
                        >
                            선택 {`(${this.state.selectedMembers.length})`}
                        </Button>
                    </Box>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(AddAdminDialogComponent);
