import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as PasswordNumberCheckedIcon } from '../../../common/images/PasswordNumberCheckedIcon.svg';
import { ReactComponent as PasswordNumberCheckedErrorIcon } from '../../../common/images/PasswordNumberCheckedErrorIcon.svg';
import { ReactComponent as PasswordNumberUnCheckedIcon } from '../../../common/images/PasswordNumberUnCheckedIcon.svg';

const styles = theme => ({
    root: {},
    checkedMargin: {
        marginTop: 10,
    },
    checkedText: {
        fontSize: '0.875rem',
        color: '#9d9d9d',
        marginLeft: 10,
    },
    checkedOnText: {
        color: '#0097ff',
    },
    checkedErrorText: {
        color: '#ff0000',
    },
});

class MobilePasswordCheckComponent extends Component {
    render() {
        const { classes, passwordValidation } = this.props;
        return (
            <div className={classes.root}>
                {passwordValidation[2] === true ? (
                    <Box display="flex" alignItems="center" className={classes.checkedMargin}>
                        <PasswordNumberCheckedIcon />
                        <Typography className={clsx(classes.checkedText, classes.checkedOnText)}>영문, 숫자, 특수문자 중 2가지 이상 조합</Typography>
                    </Box>
                ) : passwordValidation[0] === false ? (
                    <Box display="flex" alignItems="center" className={classes.checkedMargin}>
                        <PasswordNumberCheckedErrorIcon />
                        <Typography className={clsx(classes.checkedText, classes.checkedErrorText)}>
                            영문, 숫자, 특수문자 중 2가지 이상 조합
                        </Typography>
                    </Box>
                ) : (
                    <Box display="flex" alignItems="center" className={classes.checkedMargin}>
                        <PasswordNumberUnCheckedIcon />
                        <Typography className={classes.checkedText}>영문, 숫자, 특수문자 중 2가지 이상 조합</Typography>
                    </Box>
                )}

                {passwordValidation[3] === true ? (
                    <Box display="flex" alignItems="center" className={classes.checkedMargin}>
                        <PasswordNumberCheckedIcon />
                        <Typography className={clsx(classes.checkedText, classes.checkedOnText)}>10자 ~ 20자</Typography>
                    </Box>
                ) : passwordValidation[0] === false ? (
                    <Box display="flex" alignItems="center" className={classes.checkedMargin}>
                        <PasswordNumberCheckedErrorIcon />
                        <Typography className={clsx(classes.checkedText, classes.checkedErrorText)}>10자 ~ 20자</Typography>
                    </Box>
                ) : (
                    <Box display="flex" alignItems="center" className={classes.checkedMargin}>
                        <PasswordNumberUnCheckedIcon />
                        <Typography className={classes.checkedText}>10자 ~ 20자</Typography>
                    </Box>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(MobilePasswordCheckComponent);
