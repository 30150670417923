import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import { getNoticeContents } from '../../../common/Validation';
import { ClassMainPath } from '../../../common/ClassMainPath';
import { withRouter } from '../../../components/WithRouter';
import IntroductionDialogComponent from '../../dialog/IntroductionDialogComponent';
import clsx from 'clsx';

const styles = _theme => ({
    root: {
        width: '100%',
        margin: '16px auto',
        padding: '0 16px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        borderBottom: '1px solid #ddd',
    },
    visualBox: {
        width: '100%',
        height: 120,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 6,
        margin: '0 0 auto',
        overflow: 'hidden',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    classTitle: {
        cursor: 'pointer',
    },
    titleBox: {
        width: '100%',
        '&>h3': {
            fontSize: '1.25rem',
            fontWeight: 600,
            marginBottom: 10,
            marginTop: 10,
        },
    },
    subTextBox: {
        fontSize: '0.875rem',
        color: '#000',
        width: '100%',
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'pre-wrap',
    },
    subTextBoxLength: {
        width: 'calc(100% - 34px)',
        '@media all and (min-width: 601px)': {
            width: 'calc(100% - 40px)',
        },
    },
    buttonStyle: {
        minWidth: 20,
        padding: '0',
        '& span': {
            fontSize: '0.75rem',
            color: '#a3a8af',
            fontWeight: 400,
            '@media all and (min-width: 601px)': {
                fontSize: '0.875rem',
            },
        },
        '&:hover': {
            background: 'transparent',
        },
    },
});

class MobileClassTitleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moreButton: false,
            dialogOpen: false,
        };
    }

    handleClickOpen = () => {
        this.setState({ dialogOpen: true });
    };

    handleClose = () => {
        this.setState({ dialogOpen: false });
    };

    handleClickTitle = () => {
        this.props.handleChange(ClassMainPath.todaySchedule);
        this.props.scrollMove(0);
    };

    render() {
        const { classes, selectedGroupDetail } = this.props;

        return (
            <div className={classes.root}>
                <Box className={classes.visualBox}>
                    {/*<BackgroundImageIcon />*/}
                    <img src={selectedGroupDetail.image} />
                </Box>
                <Box className={classes.titleBox}>
                    <Typography variant="h3" className={classes.classTitle} onClick={this.handleClickTitle}>
                        {selectedGroupDetail.group.name}
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <span
                            id="text-box"
                            className={
                                selectedGroupDetail.group.comment.length > 0 ? clsx(classes.subTextBox, classes.subTextBoxLength) : classes.subTextBox
                            }
                        >
                            {getNoticeContents(selectedGroupDetail.group.comment)}
                        </span>

                        {selectedGroupDetail.group.comment.length > 0 && (
                            <Button className={classes.buttonStyle} onClick={this.handleClickOpen} disableRipple>
                                더보기
                            </Button>
                        )}
                    </Box>
                </Box>

                <IntroductionDialogComponent
                    dialogOpen={this.state.dialogOpen}
                    handleClose={this.handleClose}
                    selectedGroupDetail={selectedGroupDetail}
                />
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(MobileClassTitleComponent));
